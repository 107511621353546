import React, { useEffect, useContext, useMemo } from 'react';
import EmployeeHeader from './employeeHeader';
import EmployeeProfile from './employeeProfile5';
import EmployeeTimeOffStatus from './employeeTimeOffStatus';
import LeaveHistoryTable from './leaveHistoryTable';
import PollHistoryTable from './pollProfile/pollHistoryTable';
import EmployeeSkeleton from './employeeSkeleton';
import ErrorPage from '../Error/ErrorPage';
import { updateActiveTab } from 'components/Redux/activeTab';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from './employeeProfileStyles';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import AuthContext from '../../context/AuthContext';
import EmployeePieChart from './employeePieChart';
import { Tab, Tabs, Typography } from '@material-ui/core';
import PollsInsights from './pollProfile/pollsInsights';
import Divider from '@mui/material/Divider';
import { useQuery, gql, useSubscription } from '@apollo/client';
// import { toAWSDateFormat } from '../../helpers/date';
import ShoutoutHistory from './shoutoutProfile/shoutoutHistory';
import ShoutoutInsights from './shoutoutProfile/shoutoutInsights';
import { useFlagsmith } from 'flagsmith-react';
import { localToISO, getCurrentCountryDateTime } from '../../helpers/timezone';
import { query } from 'helpers/profileApi';
import { fetchApproveLeave } from 'helpers/subscriptions/dashboardSubscriptions';
export default function SelfProfile() {
    const classes = useStyles();

    const history = useHistory<any>();
    const dispatch = useDispatch();
    const state = useSelector((state: any) => state.selfProfile);
    const state2 = useSelector((state2: any) => state2);
    const mycontext = useContext(AuthContext);
    const [currentPoll, setcurrentPoll] = React.useState<any>(0);
    const [timeoff, setTimeoff] = React.useState<any>([]);
    const { hasFeature } = useFlagsmith();
    const timeoffdata = useSubscription(fetchApproveLeave(mycontext.organizationId));
    const [loading, setIsLoading] = React.useState(false);
    const [error, setIsError] = React.useState(false);
    // const time = new Date();
    // const currenttime = React.useState<any>(
    //     time.toLocaleString('en-US', {
    //         hour: 'numeric',
    //         minute: 'numeric',
    //         second: 'numeric',
    //         hour12: false,
    //     }),
    // );
    // const [choices, setChoices] = React.useState<any>([]);
    const [value, setValue] = React.useState(0);

    const handleTabs = (event: any, newValue: number) => {
        setValue(newValue);
    };
    useEffect(() => {
        dispatch(updateActiveTab('/employees'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [created] = React.useState<any>(
        localToISO(
            getCurrentCountryDateTime(mycontext.country?.zoneName).date,
            getCurrentCountryDateTime(mycontext.country?.zoneName).time,
            mycontext.country,
        ),
    );
    useMemo(() => {
        if (!timeoffdata.loading) {
            const data: any = timeoffdata?.data?.timeoffdb_Leaves;
            if (timeoffdata.error) {
                setIsError(true);
            } else {
                setIsLoading(false);
                setTimeoff(data);
            }
        }
    }, [timeoffdata]);
    // console.log(timeoff);
    // const created = localToISO(
    //     getCurrentCountryDateTime(mycontext.country?.zoneName).date,
    //     getCurrentCountryDateTime(mycontext.country?.zoneName).time,
    //     mycontext.country,
    // );

    // const query = gql`query MyQuery {
    //                     pollsdb_Polls(where: {orgId: {_eq: "${mycontext.organizationId}"}, createdBy: {_eq: "${mycontext.EmployeeId}"}, _and: { pollEndsDateTime: {_lt: "${created}"}}}) {
    //                         adminChoices
    // createdBy
    // createdOn
    // isAnonymous
    // isSingleVote
    // pollEndTime
    // pollEndsDateTime
    // pollId
    // orgId
    // question
    // poll_results {
    //   userChoice
    // }
    //                     }
    //                     }
    //                     `;

    // const query1 = gql`query myquery {
    //                     pollsdb_Polls(where: {orgId: {_eq: "${mycontext.organizationId}"}, createdBy: {_eq: "${mycontext.EmployeeId
    //     }"}, createdOn: {_lt: "${toAWSDateFormat(new Date())}"}}) {
    //                         adminChoices
    // createdBy
    // createdOn
    // isAnonymous
    // isSingleVote
    // pollEndTime
    // pollId
    // pollEndsDateTime
    // orgId
    // question
    // poll_results {
    //   userChoice
    // }
    //                     }
    //                     }`;

    const query2 = gql`query MyQuery {
  shoutoutdb_Shoutout(where: {organizationId: {_eq: "${mycontext.organizationId}"}, createdBy: {_eq: "${mycontext.EmployeeId}"}}) {
    createdOn
    tags
    shoutoutId
    Employee {
      firstName
      profileImage
    }
    shoutout_employee_teams {
      Employee {
        firstName
        profileImage
      }
      Team{
        teamName
        
      }
    }
  }
}
`;

    const query3 = gql`query MyQuery {
  shoutoutdb_Shoutout(where: {organizationId: {_eq: "${mycontext.organizationId}"}, shoutout_employee_teams: {_or: [{employeeId: {_eq: "${mycontext.EmployeeId}"}}, {Team: {team_employees: {employeeId: {_eq: "${mycontext.EmployeeId}"}}}}]}}) {
    createdOn
    tags
    shoutoutId
    Employee {
      firstName
      profileImage
    }
    shoutout_employee_teams {
      Employee {
        firstName
        profileImage
      }
      Team {
        teamName
        
      }
    }
  }
}
`;

    const { loading: pollsLoading, error: pollsError, data: polls } = useQuery(
        query(mycontext.organizationId, mycontext.EmployeeId, created),
    );
    // const { loading: pollsLoading1, error: pollsError1, data: polls1 } = useQuery(query1);
    const { loading: shoutoutsgivenLoading, error: shoutoutsgivenError, data: shoutoutsgiven } = useQuery(query2);
    const { loading: shoutoutsreceivedLoading, error: shoutoutsreceivedError, data: shoutoutsreceived } = useQuery(
        query3,
    );

    if (
        state.selfProfileDataStatus === 'loading' ||
        state.selfEmpTeamsStatus === 'loading' ||
        state.selfEmpTotHrStatus === 'loading' ||
        state.selfOrgLeaveStatus === 'loading' ||
        state.selfTimeOffHistoryStatus === 'loading' ||
        state2.orgEmployees.status === 'loading' ||
        state2.polls.polls === 'loading' ||
        pollsLoading ||
        loading ||
        shoutoutsgivenLoading ||
        shoutoutsreceivedLoading
    ) {
        return <EmployeeSkeleton />;
    } else if (
        state.selfProfileDataStatus === 'failed' ||
        state.selfEmpTeamsStatus === 'failed' ||
        state.selfEmpTotHrStatus === 'failed' ||
        state.selfOrgLeaveStatus === 'failed' ||
        state.selfTimeOffHistoryStatus === 'failed' ||
        state2.orgEmployees.status === 'failed' ||
        state2.polls.polls === 'failed' ||
        pollsError ||
        error ||
        shoutoutsgivenError ||
        shoutoutsreceivedError
    ) {
        return <ErrorPage msg="Something went wrong!" />;
    } else {
        const arrsg = shoutoutsgiven?.shoutoutdb_Shoutout;
        const arrsr = shoutoutsreceived?.shoutoutdb_Shoutout;

        const result = arrsg?.concat(
            arrsr?.filter((bo: any) => arrsg.every((ao: any) => ao.shoutoutId !== bo.shoutoutId)),
        );

        const frontend = arrsr?.filter((g: any) => g.tags === '#frontend');
        const teamwork = arrsr?.filter((g: any) => g.tags === '#teamwork');
        const backend = arrsr?.filter((g: any) => g.tags === '#backend');

        const arr1 = polls?.pollsdb_Polls;
        // const arr2 = polls1?.pollsdb_Polls;
        // const arr3 = [...arr1, ...arr2];
        const c: any = [];
        arr1?.map(async (val: any, i: any) => {
            const a: any = [];
            const obj: any = [];
            await val.adminChoices.map((n: string) => {
                let c = 0;
                val?.poll_results?.map((d: any) => {
                    if (d.userChoice.includes(n)) {
                        c = c + 1;
                        return a.push({
                            userChoice: n,
                        });
                    }
                    return null;
                });
                obj.push({
                    name: n,
                    value: c,
                });
                return null;
            });
            c.push(obj);
        });

        const employeesData: any = state2?.tenantEmployees?.tenantEmployees.filter(
            (a: any) => a?.empId === mycontext.EmployeeId,
        );
        // console.log(employeesData);
        const orgWorkingHours = state2.settings?.settings?.workingHours;
        localStorage.setItem('workAnniversary', state.basicProfile.workAnniversary);
        let totalDaysPerYear: any = 0;
        state.selfOrgLeaves.forEach((item: any) => {
            totalDaysPerYear += item.daysPerYear;
        });

        const totalAvailableLeaves: any = state.selfOrgLeaves.filter((e: any) => e.daysPerYear !== 0);

        const totalUsedLeaves: any = state.selfEmpTotHr.filter((e: any) => {
            return totalAvailableLeaves.some((f: any) => {
                return f.leaveTypeName === e.leaveType;
            });
        });
        const demoData: any = totalUsedLeaves.reduce((previousValue: any, currentValue: any) => {
            return previousValue + currentValue.totalHoursLeave;
        }, 0);
        return mycontext.subscriptionExpired === 'cancelled' &&
            Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
            <SubscriptionHardExpired
                content="Subscription expired, Please contact your admin to renew subscription"
                showRenew={false}
            />
        ) : (
            <div className={classes.main}>
                <EmployeeHeader backTo={history.location.state?.backTo} />
                <div className={classes.accordionGridStyle1}>
                    <div className={classes.gridmain}>
                        <div>
                            <EmployeeProfile
                                firstName={state.basicProfile.Employee.firstName}
                                lastName={state.basicProfile.Employee.lastName}
                                employeeId={state.basicProfile.Employee.employeeId}
                                role={state.basicProfile.role}
                                approverTeams={state.selfEmpTeams.approvers}
                                employeeTeams={state.selfEmpTeams.employees}
                                image={state.basicProfile.Employee.profileImage}
                                totalDaysPerYear={totalDaysPerYear}
                                demoData={demoData}
                                orgWorkingHours={orgWorkingHours}
                                dateOfBirth={state.basicProfile.Employee.dateOfBirth}
                                workAnniversary={employeesData[0]?.workAnniversary}
                                isDOB={employeesData[0]?.isDOB}
                                isYear={employeesData[0]?.isYear}
                            />
                        </div>
                        <div className={classes.background}>
                            <Tabs
                                value={value}
                                onChange={handleTabs}
                                indicatorColor="primary"
                                // textColor="primary"
                                variant="scrollable"
                                className={classes.tabs}
                            >
                                <Tab label="Time-Offs" className={classes.tab} id="timeoff" />
                                {hasFeature('employeeProfilePolls') ? (
                                    <Tab label="Polls" className={classes.tab} id="polls" />
                                ) : null}
                                {hasFeature('employeeProfileShoutOut') ? (
                                    <Tab label="Shoutouts" className={classes.tab} id="shoutouts" />
                                ) : null}

                                {hasFeature('employeeProfileStandup') ? (
                                    <Tab label="Standups" className={classes.tab} id="standups" />
                                ) : null}
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <LeaveHistoryTable
                                    timeOffDetails={state.selfOrgLeaves}
                                    tableContent={state.selfTimeOffHist}
                                    orgEmployees={state2.orgEmployees.employees}
                                    timeoff={timeoff}
                                    basicDetail={state?.basicProfile}
                                />
                            </TabPanel>

                            {hasFeature('employeeProfilePolls') ? (
                                <TabPanel value={value} index={1}>
                                    <PollHistoryTable setcurrentPoll={setcurrentPoll} empPolls={arr1} />
                                </TabPanel>
                            ) : null}
                            {hasFeature('employeeProfileShoutOut') ? (
                                <TabPanel value={value} index={2}>
                                    <ShoutoutHistory shoutouts={result} />
                                </TabPanel>
                            ) : null}
                            {hasFeature('employeeProfileStandup') ? (
                                <TabPanel value={value} index={3}>
                                    <div className={classes.polls}>
                                        <Typography className={classes.headerTitle}>
                                            Standups history will appear hear
                                        </Typography>
                                    </div>
                                </TabPanel>
                            ) : null}
                        </div>
                    </div>
                    <div>
                        <TabPanel value={value} index={0}>
                            <div className={classes.bg}>
                                <Typography className={classes.headerTitle1}>Time-Off Insights</Typography>
                                <div className={classes.rightbox}>
                                    <EmployeePieChart
                                        orgWorkingHours={orgWorkingHours}
                                        totalUsedDays={demoData}
                                        totalAvailableDays={totalDaysPerYear}
                                    />
                                    <div style={{ marginLeft: 20, marginRight: 20, paddingTop: 20 }}>
                                        <Divider className={classes.divider1} />
                                    </div>
                                    <EmployeeTimeOffStatus
                                        orgWorkingHours={orgWorkingHours}
                                        leaveType={state.selfOrgLeaves}
                                        totalLeaveHours={state.selfEmpTotHr}
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        {hasFeature('employeeProfilePolls') ? (
                            <TabPanel value={value} index={1}>
                                <PollsInsights empPolls={arr1} currentPoll={currentPoll} />
                            </TabPanel>
                        ) : null}

                        {hasFeature('employeeProfileShoutOut') ? (
                            <TabPanel value={value} index={2}>
                                <ShoutoutInsights
                                    shoutoutreceivedcount={arrsr.length}
                                    shoutoutgivencount={arrsg.length}
                                    frontend={frontend.length}
                                    backend={backend.length}
                                    teamwork={teamwork.length}
                                />
                            </TabPanel>
                        ) : null}

                        {hasFeature('employeeProfileStandup') ? (
                            <TabPanel value={value} index={3}>
                                <div className={classes.insightbg}>
                                    <div>
                                        <Typography className={classes.headerTitle1}>Standup Insights</Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '410px',
                                        }}
                                    >
                                        <Typography className={classes.headerTitle}>
                                            Standup Insights will appear hear
                                        </Typography>
                                    </div>
                                </div>
                            </TabPanel>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

function TabPanel(props: any) {
    const { children, value, index } = props;
    return <div>{value === index && <h1>{children}</h1>}</div>;
}
