import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        border: {
            width: '100%',
            // border: '1px solid',
            display: 'flex',
            justifyContent: 'center',
            ailgnItem: 'center',
            flexDirection: 'column',
            '@media only screen and (min-width:900px)': {
                background: theme.palette.background.paper,
                height: 'calc(100vh - 175px)',
            },
        },
        newBorder: {
            width: '100%',
            height: '100%',
            // backgroundColor: 'pink',
            padding: '0 30px',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            overflowX: 'hidden',
            background: theme.palette.background.paper,
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#D9DBE5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        main: {
            display: 'flex',
            // backgroundColor: 'orange',
            justifyContent: 'space-between',
            width: '100%',
            height: 'calc(100vh - 260px)',
            padding: '20px 0px 0px 0px',
            overflowY: 'auto',
            overflowX: 'hidden',
            background: theme.palette.background.paper,
            '&::-webkit-scrollbar': {
                width: 0,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#D9DBE5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
            '@media only screen and (max-width:900px)': {
                background: theme.palette.background.paper,
                height: '70%',
            },
        },
        field: {
            position: 'relative',
            padding: '20px 0px 0px 0px',
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
        },
        text: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
        },
        firstfield: {
            // justifyContent: 'space-around',
            // lineHeight: '30%',
            // '@media screen and (max-width: 1024px)': {
            //     width: '100%',
            // },
        },
        inputTextField: {
            paddingBottom: 20,
            '& div': {
                fontSize: '14px',
                '& input': {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: 'normal',
                    fontWeight: theme.typography.fontWeightBold,
                    borderRadius: '2px',
                    letterSpacing: '0.03',
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.paper,
                },
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
        },
        addanother: {
            width: '100%',
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'left',
            paddingRight: '45%',
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        mainDiv: {
            width: '100%',
            // backgroundColor: "yellow",
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: 20,
            '@media only screen and (max-width:899px)': {
                position: 'fixed',
                bottom: 0,
                height: 40,
                display: 'flex',
                justifyContent: 'space-evenly',
                padding: 0,
                marginLeft: -10,
            },
        },
        closeIcon: {
            position: 'relative',
            color: 'white',
            height: '12px',
            '&:hover': {
                color: 'white',
            },
        },
        btn: {
            position: 'relative',
            height: 35,
            width: 150,
            textTransform: 'capitalize',
            padding: '0 50px',
            fontSize: 14,
            marginRight: 10,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
            '@media only screen and (max-width:899px)': {
                width: '90%',
                marginRight: 0,
                borderRadius: 0,
                height: 40,
            },
        },
        btn1: {
            position: 'relative',
            display: 'flex-end',
            textAlign: 'center',
            width: 150,
            height: '35px',
            textTransform: 'capitalize',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '@media only screen and (max-width:899px)': {
                width: '100%',
                borderRadius: 0,
                height: 40,
                paddingRight: 10,
            },
        },
        deleteIcon: {
            position: 'absolute',
            right: -25,
            marginTop: 10,
            color: theme.palette.primary.contrastText,
        },
        mobile: {
            width: '100%',
            height: 'calc(100vh - 400px)',
            background: theme.palette.background.paper,
            marginTop: 10,
            paddingTop: 20,
            marginBottom: 50,
        },
        isDisable: {
            position: 'relative',
            display: 'flex-end',
            textAlign: 'center',
            width: 150,
            height: '35px',
            '@media only screen and (max-width:899px)': {
                width: '100%',
                borderRadius: 0,
                height: 40,
                paddingRight: 10,
            },
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.text.secondary}`,
            '& span': {
                fontWeight: 'bold',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                letterSpacing: '0.05em',
                color: theme.palette.text.secondary,
            },
            '&:hover, &:focus': {
                // backgroundColor: theme.palette.primary.main,
                cursor: 'not-allowed',
                textDecoration: 'none',
            },
        },
    }),
);

export default useStyles;
