import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    headerTitle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '16px',
        lineHeight: '25px',
        letterSpacing: '0.05em',
        color: theme.palette.text.primary,
        // '@media screen and (max-width: 780px)': {
        //     color: theme.palette.text.primary,
        // },
        // '@media screen and (min-width: 1024px)': {
        //     paddingLeft: '1%',
        //     color: theme.palette.text.primary,
        // },
    },
    text: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
    },
    title: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '14px',
        color: theme.palette.text.primary,
        justifyContent: 'center',
        display: 'flex',
        paddingTop: '10px',
        // '@media screen and (max-width: 780px)': {
        //     color: theme.palette.text.primary,
        // },
        // '@media screen and (min-width: 1024px)': {
        //     paddingLeft: '1%',
        //     color: theme.palette.text.primary,
        // },
    },
    tableScrollbar: {
        boxShadow: 'none',
        maxHeight: '220px',
        padding: '0px 10px 5px 10px',
        '&::-webkit-scrollbar': {
            width: 3,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.grey,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },
    leaveHistoryTableHover: {
        border: 'none',
        '&:hover': {
            boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
            color: theme.palette.primary.main,
            border: 'none',
            outline: 'none',
            borderRadius: 5,
            cursor: 'pointer',
            backgroundColor: theme.palette.info.main,
        },
    },
    leaveTableColumns: {
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '0.02em',
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
    },
    leaveTableColumnsReason: {
        maxWidth: '500px',
        width: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '0.02em',
        paddingRight: 10,
        color: theme.palette.text.primary,
    },
    searchBorder: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '&:hover fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '&.Mui-focused fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
    },
    searchInput: {
        height: 35,
        width: '100%',
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '&:hover, &:focus': {
            outline: 'none',
            color: theme.palette.text.primary,
        },
        // '@media screen and (min-width: 768px)': {
        //     marginLeft: '10px',
        // },
        '@media screen and (min-width: 768px) and (max-width: 1024px)': {
            fontSize: 12,
            padding: '0px 14px',
            '&:hover, &:focus': {
                outline: 'none',
                color: theme.palette.text.primary,
            },
        },
    },
    bg: {
        background: theme.palette.background.paper,

        height: 'calc(100vh - 130px)',
        '@media screen and (min-width: 780px) and (max-width: 1023px)': {
            marginTop: '10px',
        },
    },

    headerTitle1: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '16px',
        lineHeight: '25px',
        letterSpacing: '0.05em',
        paddingLeft: '5%',
        paddingTop: '5%',
        color: theme.palette.text.primary,
    },
    question: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '14px',

        paddingLeft: '5%',
        paddingTop: '5%',
        color: theme.palette.text.primary,

        // textOverflow: 'auto'
    },
    heading1: {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.primary.contrastText,
    },
    choices: {
        fontSize: '14px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.primary.contrastText,
        paddingLeft: '5%',
        paddingTop: '3%',
        paddingBottom: '3%',
    },
    choice: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '14px',
        color: theme.palette.text.primary,
        paddingLeft: '3%',
    },
    choicescontainer: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        height: 'auto',

        maxHeight: '170px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 3,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.grey,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },
    gridCommonStyle1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        marginTop: '5px',

        // maxWidth: '94vw',
        // padding: '2%',
        // border: '1px solid red'
    },
    commonStyle2: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    commonStyle3: {
        display: 'grid',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    pollHistoryCardTitle: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.05em',
        color: theme.palette.secondary.contrastText,
    },
    pollHistoryCardTitle1: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.05em',
        color: theme.palette.secondary.contrastText,
        paddingBottom: '5px',
        paddingTop: '5px',
        // border: '1px solid red'
    },
    pollTableColumnsReason: {
        maxWidth: '350px',
        width: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '0.02em',
        paddingRight: 10,
        color: theme.palette.text.primary,
    },
    pollTableCards: {
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '0.02em',
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
    },
    nopollinsights: {
        height: '78vh',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        '@media screen and (min-width: 780px) and (max-width: 1023px)': {
            marginTop: '20px',
            height: '30vh',
        },

        '@media screen and (max-width: 768px)': {
            height: '30vh',
        },
    },
    filterBtn: {
        height: 35,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        // color: theme.palette.text.primary,
        // backgroundColor: theme.palette.background.paper,
        // border: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.background.paper,
        whiteSpace: 'nowrap',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        marginLeft: 10,
        outline: 'none',
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    avatarimage: {
        height: 30,
        width: 30,
        fontSize: 12,
        border: 'none',
        backgroundColor: '#EDEFFA',
        color: '#444444',
        textTransform: 'uppercase',
    },
    fab: {
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        height: 20,
        width: 20,
        fontSize: 12,
        display: 'inline-flex',
        '&:hover': {
            color: theme.palette.secondary.contrastText,
            transform: 'scale(1.2)',
            // textDecoration: 'underline',
        },
    },
    filterList: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        maxHeight: 210,
        overFlowY: 'auto',
        margin: 0,
        '&::-webkit-scrollbar': {
            width: 1,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.secondary.main,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 5,
        },
    },
    filterName: {
        padding: '2px 10px',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        fontSize: 14,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    },
}));

export { useStyles };
