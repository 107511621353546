import React, { useState, useContext } from 'react';
// import { ModalBody } from 'reactstrap';
// import CloseIcon from '@material-ui/icons/Close';
import { ModalStyles } from './ModalStyle';
import MyModal from '../Modal/Modal';
import {
    // FormControl,
    FormLabel,
    // Input,
    Grid,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Hidden,
    Popover,
    // useTheme,
} from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import { Addtimeoff, updateTimeOffPolicyPendingAction } from '../../helpers/hasuraapi';
import AuthContext from '../../context/AuthContext';
// import { addTimeOff } from '../Redux/timeOffPolicyReducer';
// import { useDispatch } from 'react-redux';
import Picker from 'emoji-picker-react';
import IconButton from '@material-ui/core/IconButton';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

interface Props {
    setIsUpdating?: any;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
    userSubscriptionDetails?: any;
    checkSubscription?: any;
}
const AddTimeOff = (props: Props) => {
    const classes = ModalStyles();

    // const dispatch = useDispatch();

    // const theme = useTheme();
    const [modal, setModal] = useState(false);
    const year = new Date().getFullYear();
    const currentDays = year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0) ? 366 : 365;
    const mycontext = useContext(AuthContext);
    const toggle = () => setModal(!modal);
    const [leaveTypename, setleaveTypeName] = useState('');
    const [isPaid, setIsPaid] = useState<boolean>(true);
    const [dayText, setDaysText] = useState('20');
    const [daysperyear, setDaysPerYear] = useState(0);
    const [leaveNameValid, setleaveNameValid] = useState<boolean>(false);
    const [leaveDaysValid, setleaveDaysValid] = useState<boolean>(false);
    const [dayTextBooVal, setdayTextBooVal] = React.useState(true);

    const [picker, setPicker] = React.useState<boolean>(false);
    const [leaveEmoji, setLeaveEmoji] = React.useState({
        unified: '1f334',
        emoji: '🌴',
        activeSkinTone: 'neutral',
        names: ['palm tree', 'palm_tree'],
        originalUnified: '1f334',
    });
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleDaysPerYear = (event: any) => {
        setDaysPerYear(event.target.value === 'Unlimited' ? 0 : parseInt(dayText));
        if (parseInt(dayText) > currentDays) {
            setleaveDaysValid(true);
        } else {
            setleaveDaysValid(false);
        }
        // setUnlimitedDay(event.target.value === 'Unlimited' ? !unlimitedDay : unlimitedDay);
        setdayTextBooVal(daysperyear === 0 ? false : true);
    };

    const handleDaysText = (event: any) => {
        setDaysText(event.target.value);
        setDaysPerYear(parseInt(event.target.value === '' ? '20' : event.target.value));
        // if (!event.target.value) {
        //     setleaveDaysValid(true);
        // } else if (parseInt(event.target.value) <= 0) {
        //     setleaveDaysValid(true);
        // } else if (parseInt(event.target.value) > currentDays) {
        //     setleaveDaysValid(true);
        // } else {
        //     setleaveDaysValid(false);
        // }

        if (!event.target.value || parseInt(event.target.value) <= 0 || parseInt(event.target.value) > currentDays) {
            setleaveDaysValid(true);
        } else {
            setleaveDaysValid(false);
        }

        // setDaysPerYear(parseInt(event.target.value === '' ? '20' : event.target.value > currentDays ? 20 : event.target.value));
        // console.log('Days Event Text=' + event.target.value);
    };

    const handleIsPaid = (event: any) => {
        setIsPaid(event.target.value === 'Paid' ? true : false);
    };

    // const refreshPage = () => {
    //     props.setIsUpdating(false);
    //     window.location.reload(false);
    // };
    const handleReset = () => {
        setleaveNameValid(false);
        setleaveDaysValid(false);
        setleaveTypeName('');
        setLeaveEmoji({
            unified: '1f334',
            emoji: '🌴',
            activeSkinTone: 'neutral',
            names: ['palm tree', 'palm_tree'],
            originalUnified: '1f334',
        });
        setIsPaid(true);
        setDaysText('20');
        setDaysPerYear(0);
        setdayTextBooVal(true);
        toggle();
    };
    const handleSubmit = async () => {
        try {
            props.checkSubscription(props.userSubscriptionDetails);
            if (leaveTypename !== '') {
                if (!leaveDaysValid) {
                    toggle();
                    props.setIsUpdating(true);
                    const row = {
                        leaveTypeName: leaveTypename,
                        organizationId: mycontext.organizationId,
                        isPaid: isPaid,
                        daysPerYear: daysperyear,
                        unlimitedDays: daysperyear === 0 ? true : false,
                        token: mycontext.token,
                        emoji: leaveEmoji.emoji,
                        activeSkinTone: leaveEmoji.activeSkinTone,
                        unified: leaveEmoji.unified,
                        originalUnified: leaveEmoji.originalUnified,
                    };
                    const data = await Addtimeoff(row);
                    if (data.error) {
                        throw new Error('Something went wrong, try again!');
                    } else {
                        await updateTimeOffPolicyPendingAction(mycontext.organizationId, mycontext.token);
                        props.setSeverity('success');
                        props.setAlertm(true);
                        setleaveTypeName('');
                        setLeaveEmoji({
                            unified: '1f334',
                            emoji: '🌴',
                            activeSkinTone: 'neutral',
                            names: ['palm tree', 'palm_tree'],
                            originalUnified: '1f334',
                        });
                        props.setAlertMessage('TimeOff added successfuly!');
                        // dispatch(
                        //     addTimeOff({
                        //         daysPerYear: daysperyear,
                        //         isPaid: isPaid,
                        //         leaveTypeId: data.returning[0].leaveTypeId,
                        //         leaveTypeName: leaveTypename,
                        //         organizationId: mycontext.organizationId,
                        //         approvalRequired: true,
                        //         active: true,
                        //         unlimitedDays: false,
                        //         defaultEnable: false,
                        //     }),
                        // );
                        props.setIsUpdating(false);
                        setleaveNameValid(false);
                        setleaveDaysValid(false);
                    }
                } else {
                    setleaveNameValid(false);
                    setleaveDaysValid(true);
                }
                // refreshPage();
            } else {
                setleaveNameValid(true);
            }
        } catch (err) {
            setModal(false);
            props.setSeverity('error');
            props.setAlertm(true);
            props.setAlertMessage(err.message);
            setleaveNameValid(true);
            setleaveDaysValid(leaveDaysValid);
            return err;
        }
    };

    const handleEmojiClick = async (event: any, eObj: any) => {
        setLeaveEmoji(eObj);
        setPicker(false);
        setAnchorEl(null);
    };

    const handlePicker = (event: any) => {
        setPicker(!picker);
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Popover
                id="picker-popover"
                open={picker}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePicker}
            >
                <Picker
                    groupVisibility={{ flags: false, symbols: false, recently_used: false }}
                    onEmojiClick={handleEmojiClick}
                />
            </Popover>
            <Hidden smDown>
                <Grid item sm={5} className={classes.holiday}>
                    <Button id="addTimeOff" className={classes.addtotimeoffbtn} onClick={toggle}>
                        Create Policy
                    </Button>
                </Grid>
            </Hidden>
            <div className={classes.screenSize}>
                <Button variant="contained" className={classes.btnFixed} onClick={toggle} id="addTimeOff">
                    Create Policy
                </Button>
            </div>
            <MyModal setOpen={toggle} open={modal} onModalClosed={handleReset}>
                <div>
                    <div style={{ marginTop: '20px' }}>
                        <Grid container className={classes.modalPaper} spacing={1}>
                            <Grid item xs={8}>
                                <FormLabel htmlFor="timeoff-name" className={classes.formLabelStyle2}>
                                    Time-Off Category
                                </FormLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <FormLabel htmlFor="leave-emoji" className={classes.formLabelStyle2}>
                                    Emoji
                                </FormLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="timeoff-name"
                                    placeholder="Enter Time-Off Category"
                                    variant="outlined"
                                    fullWidth
                                    error={leaveNameValid}
                                    helperText={
                                        leaveNameValid ? 'Please enter time-off name' : 'Max length is of 30 characters'
                                    }
                                    required
                                    margin="dense"
                                    className={classes.inputStyle}
                                    value={leaveTypename}
                                    onChange={event => setleaveTypeName(event.target.value)}
                                    inputProps={{
                                        maxLength: 30,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    placeholder="Select Emoji"
                                    id="leave-emoji"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    className={classes.emojiStyle}
                                    value={leaveEmoji.emoji}
                                    // onChange={event => console.log("Event on change: ", event)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disableRipple={true}
                                                    onClick={handlePicker}
                                                    className={classes.emojiIcon}
                                                    aria-label="emoji"
                                                >
                                                    <EmojiEmotionsIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormLabel className={classes.formLabelStyle}>
                                    Days per year (
                                    {leaveDaysValid
                                        ? 'Please enter valid count'
                                        : `Max number of days is ${currentDays}`}
                                    )
                                </FormLabel>
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={12}>
                                    <RadioGroup
                                        row
                                        value={daysperyear === 0 ? 'Unlimited' : 'Days'}
                                        onChange={handleDaysPerYear}
                                    >
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="Unlimited"
                                                control={
                                                    <Radio
                                                        name="Unlimited"
                                                        color="primary"
                                                        size="small"
                                                        id="unlimited"
                                                    />
                                                }
                                                label={
                                                    <FormLabel className={classes.radioNameStyle}>Unlimited</FormLabel>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="Days"
                                                control={
                                                    <Radio name="Days" color="primary" size="small" id="enterDays" /> //id="selectDays"
                                                }
                                                label={
                                                    <TextField
                                                        type="number"
                                                        disabled={dayTextBooVal}
                                                        // inputRef={input => {
                                                        //     if (input != null) {
                                                        //         input.focus();
                                                        //     }
                                                        // }}
                                                        className={classes.inputStyle2}
                                                        value={dayText}
                                                        error={leaveDaysValid}
                                                        // helperText={
                                                        //     leaveDaysValid ? 'Please enter valid count' : `Max number of days is ${currentDays}`
                                                        // }
                                                        onChange={handleDaysText}
                                                        variant="outlined"
                                                        size="small"
                                                        id="daysInput"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end"
                                                                    className={classes.inputadorementtext}
                                                                >
                                                                    Days
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Hidden>
                            <Hidden smUp>
                                <Grid item xs={12}>
                                    <RadioGroup
                                        row
                                        value={daysperyear === 0 ? 'Unlimited' : 'Days'}
                                        onChange={handleDaysPerYear}
                                    >
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                value="Unlimited"
                                                control={
                                                    <Radio
                                                        name="Unlimited"
                                                        color="primary"
                                                        size="small"
                                                        id="unlimited"
                                                    />
                                                }
                                                label={
                                                    <FormLabel className={classes.radioNameStyle}>Unlimited</FormLabel>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <FormControlLabel
                                                value="Days"
                                                control={
                                                    <Radio name="Days" color="primary" size="small" id="enterDays" />
                                                }
                                                label={
                                                    <TextField
                                                        type="number"
                                                        disabled={dayTextBooVal}
                                                        // inputRef={input => {
                                                        //     if (input != null) {
                                                        //         input.focus();
                                                        //     }
                                                        // }}
                                                        className={classes.inputStyle2}
                                                        value={dayText}
                                                        error={leaveDaysValid}
                                                        // helperText={
                                                        //     leaveDaysValid ? 'Please enter valid count' : `Max number of days is ${currentDays}`
                                                        // }
                                                        onChange={handleDaysText}
                                                        variant="outlined"
                                                        size="small"
                                                        id="inputDays"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end"
                                                                    className={classes.inputadorementtext}
                                                                >
                                                                    Days
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Hidden>

                            <Grid item xs={12}>
                                <FormLabel className={classes.formLabelStyle}>Time-off type</FormLabel>
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={12}>
                                    <RadioGroup row value={isPaid ? 'Paid' : 'UnPaid'} onChange={handleIsPaid}>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="Paid"
                                                control={<Radio name="Paid" color="primary" size="small" id="paid" />}
                                                label={<FormLabel className={classes.radioNameStyle}>Paid</FormLabel>}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="UnPaid"
                                                control={
                                                    <Radio name="UnPaid" color="primary" size="small" id="unPaid" />
                                                }
                                                label={<FormLabel className={classes.radioNameStyle}>Unpaid</FormLabel>}
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Hidden>
                            <Hidden smUp>
                                <Grid item xs={12}>
                                    <RadioGroup row value={isPaid ? 'Paid' : 'UnPaid'} onChange={handleIsPaid}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                value="Paid"
                                                control={<Radio name="Paid" color="primary" size="small" />}
                                                label={<FormLabel className={classes.radioNameStyle}>Paid</FormLabel>}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value="UnPaid"
                                                control={<Radio name="UnPaid" color="primary" size="small" />}
                                                label={<FormLabel className={classes.radioNameStyle}>Unpaid</FormLabel>}
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button id="cancel" variant="outlined" className={classes.btnOutlined} onClick={handleReset}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button id="confirm-delete" variant="contained" className={classes.btn} onClick={handleSubmit}>
                            Create
                        </Button>
                    </div>
                </div>
            </MyModal>
        </>
    );
};

export default AddTimeOff;
