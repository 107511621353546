import React, { useState, useContext } from 'react';
import {
    Grid,
    Paper,
    FormLabel,
    Hidden,
    TextField,
    FormControl,
    Select,
    MenuItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import useStyles from './newRegisterStyles';
import {
    employeeDetails,
    joinedTeams,
    membersofTeam,
    channelsofTeam,
    MembersofChannel,
    getTenantDetails,
    getAllTenantUsers,
} from './graph';
import jwt_decode from 'jwt-decode';
// import { adminQuestion } from '../../helpers/hasuraapi3';
import { toAWSDateFormat } from '../../helpers/date';
import {
    useHistory,
    // useLocation
} from 'react-router-dom';
import { PopUp } from '../Modal/Modal';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { giveTz } from '../../helpers/timezone';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
    registerAdmin,
    // createOrganizationRole1,
    // createOrganizationSettings,
    // createDefaultOrganizationTeam1,
    // insertTeamEmployee,
    // createDefaultLeaveType,
    isEmployeeInDB,
    // registerEmployee,
    // registerTenantEmployee,
    getTenantId,
    GetOrganizations,
    getTenantSubscription,
    registerTenant,
    checkIfSuperadmin,
    getTenantIdForClient,
} from '../../helpers/hasuraapi';
import Page3Img from '../../Assets/images/Page3Img.png';
import udyamo from '../../Assets/images/udyamo.png';
// import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';
import AuthContext from '../../context/AuthContext';
import { useFlagsmith } from 'flagsmith-react';
import { TeamplusLoader } from 'components/Loader/loader';
import Stepper from './stepper';
import getUuidByString from 'uuid-by-string';

const RenderUTC = (option: any) => {
    return (
        <React.Fragment>
            {option.countryName} ({option.zoneName})
        </React.Fragment>
    );
};

export default function MicrosoftNewRegistration() {
    const classes = useStyles();
    const [tenantname, settenantname] = useState('');
    const mycontext = useContext(AuthContext);
    const [idToken, setIdToken] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [filteredDepts, setfilteredDepts] = useState<any[]>([]);
    const [deptData, setDeptData] = useState<any[]>([
        { department: { id: '', name: '' }, location: {}, teams: [], selectedTeams: [], departmentUsers: [] },
    ]);
    // const location: any = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const allCountries = useSelector((state: any) => state.country);
    const [currentDept, setcurrentDept] = useState<any[]>([]);
    const [hasuraToken, setHasuraToken] = useState('');
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [severity, setSeverity] = useState<string>('');
    const { identify, getTrait, setTrait, hasFeature } = useFlagsmith();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [notselectedDept, setnotselectedDept] = useState<any[]>([]);
    const [subscriptionTokenError, setSubscriptionTokenError] = useState<boolean>(false);
    const [subscriptionTokenErrorMessage, setSubscriptionTokenErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    const [task, setTask] = useState({
        task1: false,
        task2: false,
        task3: false,
        task4: false,
        task5: false,
        task6: false,
        task7: false,
    });

    const loginRequest = {
        scopes: ['User.ReadBasic.All', 'Group.Read.All', 'ChannelMember.Read.All', 'Directory.Read.All'],
    };

    const request = {
        ...loginRequest,
        account: accounts[0],
    };
    const handleChangeMain = async (index: any, event: any, state: any, field: string) => {
        const values = [...deptData];
        if (state !== null) {
            if (field === 'Dept') {
                values[index].department = { id: state.id, name: state.displayName };
                const channels: any = await channelsofTeam(accessToken, state.id).then(async (res1: any) => {
                    // return res1.value;
                    return res1;
                });
                values[index].teams = channels;
                const users = await membersofTeam(accessToken, state.id).then((res: any) => {
                    return {
                        org: state.id,
                        // members: res.value,
                        members: res,
                    };
                });
                values[index].departmentUsers = users;
                channels.map(async (c: any, key: any) => {
                    if (c.displayName === 'General') {
                        values[index].selectedTeams = [c];
                    }
                    const user = await MembersofChannel(accessToken, state.id, c.id).then(async (res1: any) => {
                        // return res1.value;
                        return res1;
                    });
                    values[index].teams[key].users = user;

                    return null;
                });
            } else if (field === 'Location') {
                values[index].location = state;
            }
            // else if (field === 'Teams') {
            else {
                const value = event.target.value;
                values[index].selectedTeams = value;
            }
        }

        setDeptData(values);
    };
    const filterB = (array1: any, array2: any) => {
        return array1.filter((object1: any) => {
            return !array2.some((object2: any) => {
                return object1.id === object2.department.id;
            });
        });
    };

    const handleAddFields = () => {
        const values = [...deptData];
        values.push({
            department: { id: '', name: '' },
            location: {},
            teams: [],
            selectedTeams: [],
            departmentUsers: [],
        });
        setDeptData(values);
        const b = filterB(filteredDepts, values);
        setnotselectedDept(b);
    };

    const handleRemoveFields = (event: any) => {
        const values = [...deptData];
        if (values.length > 1) {
            const filteredArray = values.filter((item: any, index: any) => index !== event);
            setDeptData(filteredArray);
        }
    };
    const initialDatafetch = async (accessToken: any, idToken: any) => {
        const decoded: any = jwt_decode(idToken);
        const subscriptionId = localStorage.getItem('subscriptionId');
        const idClaims: any = jwt_decode(idToken);
        const response = await fetch(
            'https://sls-swec-dev-tokenforprod.azurewebsites.net/api/token?code=qvfMsYlPFRGK5CVuHluzs1NbFY6Y6PT9SRtDsnhP4zUQAzFuN4xntA==',
            {
                method: 'POST',
                body: JSON.stringify({
                    token: idToken,
                    source: 'teams',
                    secret: 'dEhpc0lzbXkkRWNSMytrRXlGT1J0ZUFtcExVc0B1dGgzTishKGFUaTBu',
                }),
            },
        )
            .then(async (res: any) => {
                const d = await res.json();
                return d.body;
            })
            .catch(err => {
                return err;
            });
        const Tid = await getTenantId(decoded.tid, response.token);
        const tenantSubscription = await getTenantSubscription(Tid[0]?.id, response.token);
        const clientTenantId = await getTenantIdForClient(decoded?.tid, response.token);
        if (
            (subscriptionId === 'undefined' || subscriptionId === null || subscriptionId === undefined) &&
            (tenantSubscription?.length === 0 ||
                (tenantSubscription[0]?.extensions?.code && decoded.tid !== clientTenantId?.tenantId))
        ) {
            setSubscriptionTokenError(true);
            setSubscriptionTokenErrorMessage('');
            setLoading(false);
        } else {
            const identifyUser = async (id: string, email: string) => {
                await identify(id);
                const hasEmail = !!getTrait('email');
                if (!hasEmail) {
                    setTrait('email', email);
                }
            };
            identifyUser(decoded.preferred_username, decoded.preferred_username);

            // if (hasFeature('allow-create-organization')) {

            await getTenantDetails(accessToken).then(async (r: any) => {
                if (r.error) {
                    setIsError(true);
                    setErrorMessage(r?.error?.message);
                } else {
                    settenantname(r?.value[0]?.displayName);
                    localStorage.setItem('tenantName', r?.value[0]?.displayName);
                    await joinedTeams(accessToken).then(async (res: any) => {
                        if (res.error) {
                            setIsError(true);
                            setErrorMessage(res?.error?.message);
                        } else {
                            // setOrganizations(res.value);
                            setOrganizations(res);
                        }
                    });
                }
            });
            const myorg = await GetOrganizations(response.token);
            setcurrentDept(myorg?.data?.usermanagementdb_Organization);

            setHasuraToken(response.token);
            await employeeDetails(accessToken).then(async (res: any) => {
                const isUser: any = await isEmployeeInDB(res.id, response.token);
                if (isUser === 0) {
                    const decoded: any = jwt_decode(idToken);
                    const stringArray = decoded.name.split(' ');
                    const employee: any = {
                        email: decoded.preferred_username,
                        firstName: stringArray[0],
                        lastName: stringArray[1] !== undefined ? stringArray[1] : '',
                        joining: toAWSDateFormat(new Date()),
                        token: response.token,
                        empId: decoded.oid,
                    };
                    const emp = await registerAdmin(employee);

                    mycontext.login({
                        username: employee.email,
                        EmployeeId: emp.employeeId,
                        fname: stringArray[0],
                        lastName: stringArray[1] !== undefined ? stringArray[1] : '',
                        // isAdmin: data?.isAdmin === 'admin' ? true : false,
                        // isApprover: data?.isapprover.toLowerCase() === 'Yes'.toLowerCase() ? true : false,
                        isLogedIn: false,
                        joining: '',
                        profile: '',
                        // teamname: data?.teamname,
                        statustype: '',
                        email: employee.email,
                        isGoogle: false,
                        token: response.token,
                        uuid: emp.employeeId,
                        standupTempId: '',
                        tenantId: idClaims?.tid,
                        isSuperAdmin: true,
                    });
                    mycontext.addTenantId(idClaims?.tid);
                } else {
                    // console.log('USER ALREADY EXISTS');
                }
            });
            setLoading(false);
        }
        // }
        // }
    };
    const filterA = (array1: any, array2: any) => {
        return array1.filter((object1: any) => {
            return !array2.some((object2: any) => {
                return object1.id === object2.organizationId;
            });
        });
    };

    React.useEffect(() => {
        (async () => {
            const a = filterA(organizations, currentDept);
            setfilteredDepts(a);
            const b = filterB(a, deptData);
            setnotselectedDept(b);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDept]);

    React.useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                instance.acquireTokenSilent(request).then(async (response: any) => {
                    setIdToken(response.idToken);
                    setAccessToken(response.accessToken);
                    await initialDatafetch(response.accessToken, response.idToken);
                });
            } else {
                instance.acquireTokenSilent(request).then(async (response: any) => {
                    setIdToken(response.idToken);
                    setAccessToken(response.accessToken);
                    await initialDatafetch(response.accessToken, response.idToken);
                });
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (loading) {
        return <TeamplusLoader />;
    }
    const handleBack = async () => {
        const decoded: any = jwt_decode(idToken);
        history.push({
            pathname: '/myorganizations',
            state: {
                id: decoded.oid,
                token: hasuraToken,
                isSignup: true,
                tenantId: decoded.tid,
            },
        });
    };

    const registerMain = async () => {
        const dataIsEmpty: any = [];
        deptData.map((data: any) => {
            if (data?.selectedTeams?.length === 0 || !data?.location?.countryCode || data?.department?.id === '') {
                dataIsEmpty.push('Yes');
            } else {
                dataIsEmpty.push('No');
            }
            return null;
        });

        if (dataIsEmpty.includes('Yes')) {
            setAlert(true);
            setSeverity('error');
            setAlertMessage('Looks like either Organization, Location or Teams is not selected');
            setTask({ ...task, task1: true });
            setIsLoading(false);
        } else {
            setIsLoading(true);
            const decoded: any = jwt_decode(idToken);
            const tenantUsers = await getAllTenantUsers(accessToken);

            const myTenantId = getUuidByString(decoded.tid);
            const superAdmins = await checkIfSuperadmin(myTenantId, hasuraToken);

            const allEmps = tenantUsers
                .map((user: any) => {
                    return {
                        employeeId: user.id,
                        firstName: user.displayName.split(' ')[0],
                        lastName: user.displayName.split(' ').length > 1 ? user.displayName.split(' ')[1] : '',
                        email: user.userPrincipalName,
                    };
                })
                .filter((a: any) => a.employeeId !== decoded.oid);

            const tenantEmps = tenantUsers.map((user: any) => {
                return {
                    tenantId: myTenantId,
                    employeeId: user.id,
                    role: user.id === decoded.oid ? 'superadmin' : 'employee',
                };
            });

            const allDepts: any[] = [];
            const allDeptEmps: any[] = [];
            const allTeams: any[] = [];
            const allTEmps: any[] = [];
            const allSettings: any[] = [];
            const allLeaveTypes: any[] = [];
            const templates: any[] = [];

            deptData.map((a: any) => {
                allDepts.push({
                    tenantId: myTenantId,
                    organizationId: a.department.id,
                    organizationName: a.department.name,
                    country: a.location,
                });

                a.departmentUsers.members.map((u: any) => {
                    const isAdmin = superAdmins.some((admins: any) => admins.employeeId === u.id);
                    allDeptEmps.push({
                        organizationId: a.departmentUsers.org,
                        employeeId: u.id,
                        role: u.id === decoded.oid || isAdmin ? 'admin' : 'employee',
                        location: a.location,
                    });
                    return null;
                });

                a.selectedTeams.map(async (b: any) => {
                    const team = getUuidByString(b.id);
                    allTeams.push({
                        organizationId: a.department.id,
                        teamId: team,
                        teamName: b.displayName,
                        msChannelId: b.id,
                    });
                    a.teams.filter((t: any) => {
                        if (t.id === b.id) {
                            t.users.map((u: any) => {
                                allTEmps.push({
                                    teamId: team,
                                    employeeId: u.userId,
                                    isApprover: false,
                                });
                                return null;
                            });
                        }
                        return null;
                    });
                });

                allSettings.push({
                    organizationId: a.department.id,
                    dateFormat: 'DD-MM-YYYY',
                    workWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
                    workingHours: 5,
                });

                allLeaveTypes.push({
                    organizationId: a.department.id,
                    leaveTypeName: 'Sick',
                    isPaid: true,
                    daysPerYear: 0,
                    approvalRequired: true,
                    active: true,
                    unlimitedDays: true,
                    defaultEnable: true,
                    forYear: `${new Date().getFullYear()}`,
                    leaveTypeEmoticon: {
                        emoji: '🌴',
                        activeSkinTone: 'neutral',
                        originalUnified: '1f334',
                        unified: '1f334',
                    },
                });

                allLeaveTypes.push({
                    organizationId: a.department.id,
                    leaveTypeName: 'Casual',
                    isPaid: false,
                    daysPerYear: 25,
                    approvalRequired: true,
                    active: true,
                    unlimitedDays: false,
                    defaultEnable: true,
                    forYear: `${new Date().getFullYear()}`,
                    leaveTypeEmoticon: {
                        emoji: '🌴',
                        activeSkinTone: 'neutral',
                        originalUnified: '1f334',
                        unified: '1f334',
                    },
                });

                templates.push({
                    questions: ['What did you accomplish yesterday?', 'What is your goal for today?', 'Blockers?'],
                    employeeId: decoded.oid,
                    organizationId: a.department.id,
                });

                return null;
            });

            await registerTenant({
                id: myTenantId,
                name: tenantname,
                workspaceId: decoded.tid,
                emps: allEmps,
                users: tenantEmps,
                token: hasuraToken,
                departments: allDepts,
                deptEmps: allDeptEmps,
                teams: allTeams,
                teamEmps: allTEmps,
                settings: allSettings,
                leaveTypes: allLeaveTypes,
                templates: templates,
            });

            setTask({
                ...task,
                task1: true,
                task2: true,
                task3: true,
                task4: true,
                task5: true,
                task6: true,
                task7: true,
            });
            setIsLoading(false);
            history.push({
                pathname: '/myorganizations',
                state: {
                    id: decoded.oid,
                    token: hasuraToken,
                    isSignup: true,
                    tenantId: decoded.tid,
                },
            });
            setAlert(true);
            setSeverity('success');
            setAlertMessage('Organization created successfully!');
        }
    };

    if (isLoading) {
        return (
            <>
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                    }}
                >
                    <Stepper task={task} isRegister={false} />
                </div>
            </>
        );
    }
    if (subscriptionTokenError || isError) {
        return (
            <Grid container style={{ display: 'flex' }}>
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img src={Page3Img} alt="Page3Img" style={{ height: '75vh' }} />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img src={udyamo} alt="udyamoLogo" style={{ height: '2.5vh' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} xl={7} className={classes.secondGrid}>
                    <Paper style={{ boxShadow: 'none' }} className={classes.orgPaperStyle}>
                        <Grid container>
                            <Grid container direction="column">
                                <Grid container className={classes.contentGrid}>
                                    <Grid item xs={12} className={classes.paraGridStyle}>
                                        {subscriptionTokenError ? (
                                            subscriptionTokenErrorMessage === '' ? (
                                                <FormLabel
                                                    className={classes.paraContent}
                                                    style={{ marginBottom: '15px' }}
                                                >
                                                    <p>
                                                        Looks like you don&apos;t have an account with us, kindly visit
                                                        microsoft teams store to purchase a subscription and continue.{' '}
                                                    </p>
                                                    <p> P.S. We have a 30 day trial period so don&apos;t worry :)</p>
                                                </FormLabel>
                                            ) : (
                                                <FormLabel className={classes.paraContent}>
                                                    <p>{subscriptionTokenErrorMessage}</p>
                                                    <p> P.S. We have a 30 day trial period so don&apos;t worry :)</p>
                                                </FormLabel>
                                            )
                                        ) : (
                                            <FormLabel className={classes.paraContent}>
                                                <p>
                                                    Looks like you dont have sufficient permissions to access this page.
                                                    Only Admin can access this page
                                                </p>
                                                <p>{errorMessage}</p>
                                                {localStorage.getItem('subscriptionToken') && (
                                                    <p style={{ color: 'red' }}>
                                                        You will not be charged for this transaction
                                                    </p>
                                                )}
                                            </FormLabel>
                                        )}
                                    </Grid>
                                    {subscriptionTokenError && (
                                        <Grid
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <div
                                                className={classes.registerNew}
                                                onClick={() => {
                                                    window.open(
                                                        'https://appsource.microsoft.com/en-us/marketplace/apps?exp=ubp8&search=teamplus&page=1',
                                                    );
                                                }}
                                            >
                                                Buy A Subscription
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            paddingTop: 10,
                                        }}
                                    >
                                        <p
                                            onClick={() => handleBack()}
                                            style={{
                                                fontSize: 12,
                                                color: '#7A7A7A',
                                                fontFamily: 'Nunito Sans',
                                                fontWeight: 500,
                                                cursor: 'pointer',
                                                marginBottom: 30,
                                            }}
                                        >
                                            Take me back
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
    return (
        <>
            <Grid container style={{ display: 'flex' }}>
                <Grid item xs={12} xl={12} className={classes.secondGrid}>
                    <Paper style={{ boxShadow: 'none' }} className={classes.orgPaperStyle}>
                        <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                        <Grid container>
                            <Grid container direction="row">
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <FormLabel className={classes.title}>{tenantname}</FormLabel>
                                </Grid>
                                <Grid container style={{ padding: '10px' }} className={classes.contentGrid}>
                                    <Grid item xs={12} className={classes.paraGridStyle}>
                                        {hasFeature('allow-create-organization') ? (
                                            <FormLabel className={classes.paraContent}>
                                                By registering your team from Microsoft Teams, you can directly import
                                                all its members and channels to TeamPlus. The location that you choose
                                                will be set by default to all the employees. They can later change it
                                                from their Profile.
                                            </FormLabel>
                                        ) : (
                                            <FormLabel className={classes.paraContent1}>
                                                Looks like you are new to Teamplus. Thanks for showing interest in
                                                Teamplus, One of our representative will contact you soon !
                                            </FormLabel>
                                        )}
                                    </Grid>
                                </Grid>
                                {deptData.map((data: any, i: any) => {
                                    return (
                                        <Grid container className={classes.rowTemplate} key={i}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Autocomplete
                                                    onChange={(event, state) =>
                                                        handleChangeMain(i, event, state, 'Dept')
                                                    }
                                                    autoComplete={true}
                                                    autoSelect={true}
                                                    id="controllable-departments"
                                                    style={{ width: '90%' }}
                                                    options={notselectedDept}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) => option.displayName}
                                                    renderOption={(option: any) => (
                                                        <React.Fragment>{option.displayName}</React.Fragment>
                                                    )}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Choose Department"
                                                            value={params}
                                                            className={classes.formFields}
                                                            style={{ width: '100%' }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Autocomplete
                                                    onChange={(event, state) =>
                                                        handleChangeMain(i, event, state, 'Location')
                                                    }
                                                    autoComplete={true}
                                                    autoSelect={true}
                                                    id="controllable-locations"
                                                    style={{ width: '90%' }}
                                                    options={allCountries.countries}
                                                    autoHighlight
                                                    getOptionLabel={(option: any) =>
                                                        option.countryName + ' ' + option.zoneName
                                                    }
                                                    renderOption={(option: any) => RenderUTC(option)}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            value={params}
                                                            label="Choose Location"
                                                            className={classes.formFields}
                                                            style={{ width: '100%' }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FormControl style={{ width: '100%' }}>
                                                    <Select
                                                        id="categorySelect"
                                                        multiple
                                                        value={data?.selectedTeams}
                                                        onChange={(event, state) =>
                                                            handleChangeMain(i, event, state, 'Teams')
                                                        }
                                                        displayEmpty={true}
                                                        color="primary"
                                                        className={classes.inputSelectStyleOther}
                                                        renderValue={(d: any) =>
                                                            data?.selectedTeams.length > 0 ? (
                                                                <div>
                                                                    {data?.selectedTeams.map(
                                                                        (s: any, index: number) => (
                                                                            <span key={index}>
                                                                                {s.displayName}
                                                                                {index !==
                                                                                    data?.selectedTeams.length - 1 && (
                                                                                    <>, &nbsp;</>
                                                                                )}
                                                                            </span>
                                                                        ),
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <span>Import Teams </span>
                                                            )
                                                        }
                                                    >
                                                        {data?.teams?.map(
                                                            (channel: any) => (
                                                                // channel.membershipType === 'standard' ? (
                                                                <MenuItem
                                                                    key={channel.id}
                                                                    value={channel}
                                                                    disabled={
                                                                        channel.displayName === 'General' ? true : false
                                                                    }
                                                                >
                                                                    <ListItemIcon>
                                                                        <Checkbox
                                                                            // defaultChecked={true}
                                                                            // checked={true}

                                                                            disableFocusRipple={false}
                                                                            checked={
                                                                                (data?.selectedTeams as any).includes(
                                                                                    channel,
                                                                                )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={channel.displayName} />
                                                                </MenuItem>
                                                            ),
                                                            // ) : (
                                                            // <></>
                                                            // ),
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className={classes.deleteIconStyles}>
                                                <DeleteIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleRemoveFields(i);
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    );
                                })}

                                <Grid container>
                                    <div style={{ padding: '10px' }}>
                                        <p
                                            onClick={handleAddFields}
                                            style={{
                                                fontSize: 16,

                                                fontFamily: 'Nunito Sans',

                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                                color: '#3C48B5',
                                                fontWeight: 600,
                                            }}
                                        >
                                            + Add more departments
                                        </p>
                                    </div>
                                </Grid>

                                <Grid className={classes.buttongrid}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            className={classes.registerNew}
                                            // onClick={() => goTeams()}
                                            onClick={() => registerMain()}
                                        >
                                            Register a new Organisation
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <p
                                            onClick={() => handleBack()}
                                            style={{
                                                fontSize: 12,
                                                color: '#7A7A7A',
                                                fontFamily: 'Nunito Sans',
                                                fontWeight: 500,
                                                cursor: 'pointer',
                                                padding: '5px',
                                            }}
                                        >
                                            &nbsp;Take me back
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
