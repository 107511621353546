import { gql } from '@apollo/client';

export function fetchHolidaysSubscription(organizationId: string) {
    return gql`
        subscription Holidays {
                timeoffdb_Holiday(where: {organizationId: {_eq: "${organizationId}"}}) {
                    holidayDay
                    holidayName
                    holidayId
                    endDate
                    startDate
                    isMultidayHoliday
                }
            }
    `;
}
