/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography, Hidden, Grid, Divider } from '@material-ui/core';
import { HolidaytableStyles } from './HolidayTableStyle';
import MyModal from '../Modal/Modal';
import { IconButton, Button, Paper } from '@material-ui/core';
import AddHoliday, { DeleteModal } from './EditDeleteModals';
import { deleteHoliday, updateHoliday } from '../../helpers/hasuraapi';
import {
    toYearFormat,
    toDayFormat,
    ToStringFormat,
    toAWSDateFormat,
    validatePastDate,
    validateDate,
} from '../../helpers/date';
import AuthContext from '../../context/AuthContext';
import { PopUp } from '../Modal/Modal';
// import ErrorPage from '../Error/ErrorPage';
// import Skeleton from 'react-loading-skeleton';
import Skeleton from '@material-ui/lab/Skeleton';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import Loader from '../Loader/loader';
import { editHoliday, removeHoliday } from '../Redux/holidaysReducer';

interface Props {
    rows: any[];
    holidayName?: string;
    date?: string;
    year?: number;
    startDate?: string;
    endDate?: string;
    day?: string;
    teams?: string;
    actions?: string;
    isLoading?: boolean;
    error?: boolean;
    errorMessage?: string;
    setIsUpdating?: any;
}

export default function HolidayTable(props: Props) {
    const classes = HolidaytableStyles();
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [openA, setOpenA] = React.useState(false);
    const [openB, setOpenB] = React.useState(false);
    const [isDelete, setDelete] = React.useState(false);
    const mycontext = useContext(AuthContext);
    const [isUpdating, setIsUpdating] = React.useState(false);

    const [state, setState] = React.useState({
        checkedA: false,
    });
    const handleChangeFirst = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const [holidayname, setHolidayName] = React.useState('');
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertm, setAlertm] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [date, setDate] = React.useState<Date>();
    const [startdate, setstartDate] = React.useState<Date>(new Date());
    const [enddate, setendDate] = React.useState<Date>(new Date());
    const [selected, setSelected] = React.useState<any>(0);
    const [holidays, setHolidays] = useState<any>([]);
    const [error, setError] = useState<boolean>(false);
    const [holidaytext, setHolidayText] = useState(false);
    const [mulitpleDateError, setMulitpleDateError] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>('');

    const data1 = useSelector((state: any) => state.holidays);
    useEffect(() => {
        setHolidays(props.rows);
    }, [data1, props.rows, mycontext.organizationId]);

    const toggle = () => {
        setError(false);
        setModal(!modal);
    };
    const updateSingleHoliday = async () => {
        props.setIsUpdating(true);
        const row = {
            holidayId: selected,
            holidayName: holidayname,
            date: toAWSDateFormat(date),
            day: toDayFormat(date),
            startDate: toAWSDateFormat(startdate),
            organizationId: mycontext.organizationId,
            endDate: toAWSDateFormat(enddate),
            year: startdate ? toYearFormat(startdate) : toYearFormat(date),
            isMultidayHoliday: state.checkedA,
        };
        try {
            const diff1 = validateDate(row.startDate, row.endDate);
            const diff2 = validatePastDate(row.startDate, new Date().toDateString());
            const diff3 = validatePastDate(row.endDate, new Date().toDateString());
            const diff4 = validatePastDate(row.date, new Date().toDateString());
            if (holidayname === '') {
                setHolidayText(true);
                props.setIsUpdating(false);
            } else if (state.checkedA === true && toAWSDateFormat(startdate) === toAWSDateFormat(enddate)) {
                setMulitpleDateError(true);
                setErrorMsg('You cannot select same Date for multiple days');
                props.setIsUpdating(false);
            } else if (
                row.isMultidayHoliday === false &&
                (diff4.error === true || diff2.error === true || diff3.error === true)
            ) {
                setMulitpleDateError(true);
                setErrorMsg('Cant select previous date to add a Holiday!');
                props.setIsUpdating(false);
            } else if (row.isMultidayHoliday === true && diff1.error === true) {
                setMulitpleDateError(true);
                setErrorMsg('Invalid Holiday Date');
                props.setIsUpdating(false);
            } else if (row.isMultidayHoliday === true && (diff2.error === true || diff3.error === true)) {
                setMulitpleDateError(true);
                setErrorMsg('Cant select previous date to add a Holiday!');
                props.setIsUpdating(false);
            } else {
                const data = await updateHoliday(row, mycontext.token);
                if (data.error) {
                    setSeverity('error');
                    setAlertm(true);
                    setAlertMessage('Something went wrong, try again!');
                    props.setIsUpdating(false);
                } else {
                    setHolidays((prev: any) => {
                        const updatedHoliday = prev.map((r: any) => {
                            if (r.holidayId === selected) {
                                return {
                                    holidayId: selected,
                                    holidayName: holidayname,
                                    date: date,
                                    day: toDayFormat(date),
                                    startDate: startdate,
                                    endDate: enddate,
                                    year: startdate ? toYearFormat(startdate) : toYearFormat(date),
                                    isMultidayHoliday: state.checkedA,
                                    organizationId: mycontext.organizationId,
                                };
                            }
                            return r;
                        });
                        return updatedHoliday;
                    });
                    dispatch(
                        editHoliday({
                            holidayId: selected,
                            holidayName: holidayname,
                            holidayDay: toDayFormat(date),
                            startDate: startdate,
                            endDate: enddate,
                            isMultidayHoliday: state.checkedA,
                        }),
                    );
                    setSeverity('success');
                    setAlertm(true);
                    setAlertMessage('Holiday updated successfuly!');
                    setIsUpdating(false);
                    setOpenA(false);
                    setModal(!modal);
                    setMulitpleDateError(false);
                    props.setIsUpdating(false);
                    // window.location.reload();
                }
            }
        } catch (err) {
            setSeverity('error');
            setAlertm(true);
            setAlertMessage(err);
        }
    };

    const handleEditHoliday = (row: any) => {
        setSelected(row.holidayId);
        setHolidayName(row.holidayName);
        setDate(row.date);
        setstartDate(row.startDate === 'null' ? new Date() : row.startDate);
        setendDate(row.endDate === 'null' ? new Date() : row.endDate);
        setState({
            ...state,
            checkedA: row.isMultidayHoliday,
        });
        setModal(!modal);
    };

    const handleCancel = () => {
        setModal(false);
        setError(false);
        setHolidayName('');
    };

    const deleteModal = (row: any) => {
        setDelete(true);
        setHolidayName(row.holidayName);
        setSelected(row.holidayId);
    };

    const handleDelete = async (id: any) => {
        props.setIsUpdating(true);
        const data = await deleteHoliday(id, mycontext.token);
        if (data.error) {
            setSeverity('error');
            setAlertm(true);
            setAlertMessage('Something went wrong, try again!');
            setDelete(false);
        } else {
            setHolidays((prev: any) => {
                const updatedHoliday = prev.filter((holidays: any) => {
                    return holidays.holidayId !== id;
                });
                return updatedHoliday;
            });
            dispatch(
                removeHoliday({
                    holidayId: id,
                }),
            );
            setSeverity('success');
            setAlertm(true);
            setAlertMessage('Holiday deleted successfuly!');
            setIsUpdating(false);
            setDelete(false);
            props.setIsUpdating(false);
            // window.location.reload();
        }
    };

    const Holiday = (h: any) => {
        const holiday: any = h.holiday;
        return (
            <>
                <Grid container style={{ margin: 0, padding: 10 }}>
                    <Grid item xs={5} className={classes.holidayLeft}>
                        Holiday Name
                    </Grid>
                    <Grid item xs={7} className={classes.holidayText}>
                        {holiday.holidayName}
                    </Grid>
                    {holiday.isMultidayHoliday === null ? null : holiday.isMultidayHoliday ? (
                        <>
                            <Grid item xs={5} className={classes.holidayLeft}>
                                Start Date
                            </Grid>
                            <Grid item xs={7} className={classes.holidayText}>
                                {ToStringFormat(holiday.startDate, mycontext.dateFormat)}
                            </Grid>
                            <Grid item xs={5} className={classes.holidayLeft}>
                                End Date
                            </Grid>
                            <Grid item xs={7} className={classes.holidayText}>
                                {ToStringFormat(holiday.endDate, mycontext.dateFormat)}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={5} className={classes.holidayLeft}>
                                Date(s)
                            </Grid>
                            <Grid item xs={7} className={classes.holidayText}>
                                {ToStringFormat(holiday.startDate, mycontext.dateFormat)}
                            </Grid>
                        </>
                    )}

                    {holiday.isMultidayHoliday === null ? null : holiday.isMultidayHoliday ? (
                        <>
                            <Grid item xs={5} className={classes.holidayLeft}>
                                Start Day
                            </Grid>
                            <Grid item xs={7} className={classes.holidayText}>
                                {toDayFormat(holiday.startDate)}
                            </Grid>
                            <Grid item xs={5} className={classes.holidayLeft}>
                                End Day
                            </Grid>
                            <Grid item xs={7} className={classes.holidayText}>
                                {toDayFormat(holiday.endDate)}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={5} className={classes.holidayLeft}>
                                Day
                            </Grid>
                            <Grid item xs={7} className={classes.holidayText}>
                                {toDayFormat(holiday.startDate)}
                            </Grid>
                        </>
                    )}
                    {mycontext.isAdmin && (
                        <>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    className={classes.deleteBtn}
                                    onClick={() => deleteModal(holiday)}
                                >
                                    Delete
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    className={classes.editBtn}
                                    onClick={() => handleEditHoliday(holiday)}
                                >
                                    Edit
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        );
    };

    return (
        <>
            <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
            <Loader isUpdating={isUpdating} />
            <AddHoliday
                modal={modal}
                toggle={toggle}
                open={openA}
                setOpen={setOpenA}
                holidayname={holidayname}
                setHolidayName={setHolidayName}
                date={date}
                setDate={setDate}
                startdate={startdate}
                setstartDate={setstartDate}
                enddate={enddate}
                setendDate={setendDate}
                state={state}
                handleChangeFirst={handleChangeFirst}
                handleSubmit={updateSingleHoliday}
                setEdit={handleCancel}
                error={error}
                holidaytext={holidaytext}
                mulitpleDateError={mulitpleDateError}
                errorMsg={errorMsg}
            />
            <MyModal setOpen={setDelete} open={isDelete} onModalClosed={setDelete}>
                <DeleteModal
                    isDelete={isDelete}
                    setDelete={setDelete}
                    open={openB}
                    handleDelete={handleDelete}
                    selected={selected}
                    setOpen={setOpenB}
                    name={holidayname}
                />
            </MyModal>
            <Hidden smDown>
                {holidays.length === 0 ? (
                    <>
                        <TableContainer className={classes.tableContainer}>
                            <Table className={classes.table} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.holidaynamecolumn} align="center">
                                            #
                                        </TableCell>
                                        <TableCell className={classes.tablecolumnname} align="left">
                                            {props.holidayName}
                                        </TableCell>
                                        <TableCell className={classes.tablecolumnname} align="center">
                                            {props.date}
                                        </TableCell>
                                        <TableCell className={classes.tablecolumnname} align="center">
                                            {props.day}
                                        </TableCell>

                                        {mycontext.isAdmin && (
                                            <TableCell className={classes.tablecolumnname} align="center">
                                                {props.actions}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                    {props.isLoading && (
                                        <Hidden mdDown>
                                            {[1, 2, 3, 4, 5].map((d: number) => (
                                                <TableRow key={d}>
                                                    <TableCell align="center" style={{ borderBottom: 'none' }}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    <TableCell align="left" style={{ borderBottom: 'none' }}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ borderBottom: 'none' }}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ borderBottom: 'none' }}>
                                                        <Skeleton height={20} />
                                                    </TableCell>

                                                    {mycontext.isAdmin && (
                                                        <TableCell align="center" style={{ borderBottom: 'none' }}>
                                                            <Skeleton height={20} />
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                        </Hidden>
                                    )}
                                </TableHead>
                            </Table>
                        </TableContainer>
                        {props.isLoading || (
                            <div className={classes.rowmessage}>
                                <img src={upcomingTimeoff} alt="No Holidays Found" className={classes.notfoundImage} />
                                <Typography className={classes.fontright}>
                                    {mycontext.isAdmin
                                        ? 'No holidays found. Either import or add holiday.'
                                        : 'No holidays found.'}
                                </Typography>
                            </div>
                        )}
                    </>
                ) : (
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader className={classes.table} size="small" aria-label="small table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        className={classes.holidaynamecolumn}
                                        align="center"
                                        style={{ padding: '6px' }}
                                    >
                                        #
                                    </TableCell>
                                    <TableCell className={classes.tablecolumnname} align="left">
                                        {props.holidayName}
                                    </TableCell>
                                    <TableCell className={classes.tablecolumnname} align="center">
                                        {props.date}
                                    </TableCell>
                                    <TableCell className={classes.tablecolumnname} align="center">
                                        {props.day}
                                    </TableCell>

                                    {mycontext.isAdmin && (
                                        <TableCell className={classes.tablecolumnname} align="center">
                                            {props.actions}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {holidays.map((row: any, i: number) => (
                                    <TableRow key={i} className={classes.row}>
                                        <TableCell style={{ borderBottom: 'none', padding: '6px' }} align="center">
                                            {i + 1}
                                        </TableCell>
                                        {props.holidayName && (
                                            <TableCell
                                                className={classes.cellName}
                                                component="th"
                                                scope="row"
                                                align="left"
                                            >
                                                {row.holidayName}
                                            </TableCell>
                                        )}
                                        {row.isMultidayHoliday ? (
                                            <TableCell className={classes.cell} align="center">
                                                {ToStringFormat(row.startDate, mycontext.dateFormat)}&nbsp;to&nbsp;
                                                {ToStringFormat(row.endDate, mycontext.dateFormat)}
                                            </TableCell>
                                        ) : (
                                            <TableCell className={classes.cell} align="center">
                                                {ToStringFormat(row.startDate, mycontext.dateFormat)}
                                            </TableCell>
                                        )}
                                        {props.day != null && row.isMultidayHoliday ? (
                                            <TableCell className={classes.cell} align="center">
                                                {toDayFormat(row.startDate) + ' to ' + toDayFormat(row.endDate)}
                                            </TableCell>
                                        ) : (
                                            <TableCell className={classes.cell} align="center">
                                                {toDayFormat(row.startDate)}
                                            </TableCell>
                                        )}
                                        {mycontext.isAdmin && (
                                            <TableCell className={classes.cell} align="center" key={i}>
                                                <IconButton
                                                    id={'edit-holiday' + i}
                                                    className={classes.shiftIcon}
                                                    onClick={() => handleEditHoliday(row)}
                                                >
                                                    <FiEdit className={classes.editIcon} />
                                                </IconButton>

                                                <IconButton
                                                    id={'delete-holiday' + i}
                                                    className={classes.shiftIcon}
                                                    onClick={() => deleteModal(row)}
                                                >
                                                    <RiDeleteBinLine className={classes.deleteIcon} />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Hidden>
            <div className={classes.screenSize}>
                {holidays.length === 0 ? (
                    props.isLoading ? (
                        <div className={classes.screenSize}>
                            {[1, 2, 3, 4].map((d: number) => (
                                <Paper className={classes.paper} key={d}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Skeleton height={20} />
                                        </Grid>

                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Skeleton height={20} />
                                        </Grid>

                                        {mycontext.isAdmin && (
                                            <>
                                                <Grid item xs={6}>
                                                    <Skeleton height={30} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Skeleton height={30} />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Paper>
                            ))}
                        </div>
                    ) : (
                        <div className={classes.rowmessage}>
                            <img src={upcomingTimeoff} alt="No Holidays Found" className={classes.notfoundImage} />
                            <Typography className={classes.holidayLeft}>No Holidays Found</Typography>
                        </div>
                    )
                ) : (
                    holidays.map((h: any, i: number) => (
                        <div key={i}>
                            <Holiday holiday={h} />
                            <Grid item xs={12}>
                                <Divider style={{ backgroundColor: '#EEEEEE', height: '2px', marginTop: 10 }} />
                            </Grid>
                        </div>
                    ))
                )}
            </div>
        </>
    );
}
