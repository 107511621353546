import React, { ReactNode } from 'react';
import useStyles from './AuthLayoutStyle';

interface LayoutProps {
    btn: string;
    children?: ReactNode;
}

export default function AuthLayout(props: LayoutProps): JSX.Element {
    const classes = useStyles();
    const { children } = props;
    return <div className={classes.root}>{children}</div>;
}
