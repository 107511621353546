import { gql } from '@apollo/client';

export const getTeamList = (organizationId: string) => {
    return gql`
        subscription Teams {
            usermanagementdb_Team(where: {organizationId: {_eq: "${organizationId}"}}, order_by: {teamName: asc}) {
                teamId
                teamName
                createdAt
                organizationId
                isbotAdded
                msChannelId
                channelServiceUrl
                team_employees {
                    Employee {
                        employeeId
                        email
                        firstName
                        lastName
                        status
                        profileImage
                        organization_employees(where: {role: {_eq: "admin"}, organizationId: {_eq: "${organizationId}"}}) {
                        role
                        }
                    }
                    isApprover
                }
            }
        }
    `;
};
