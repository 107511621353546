import React from 'react';
import useStyles from './style';
import { Link } from 'react-router-dom';
import { Hidden } from '@material-ui/core';

interface MainProps {
    img?: any;
    title?: string;
    subtitle?: string;
    btnText?: string;
    isAdmin?: boolean;
    imgSmall?: any;
}
export default function Main(props: MainProps) {
    const classes = useStyles();
    return (
        <div className={props.isAdmin ? classes.mainAdmin : classes.mainEmployee}>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <img src={props.img} alt="" className={classes.mainImg} />
            </Hidden>
            <Hidden only={['xl']}>
                <img src={props.imgSmall} alt="" className={classes.mainImg} />
            </Hidden>
            <div className={classes.mainContent}>
                <p className={classes.mainHeading}>{props.title}</p>
                <p className={classes.mainSubtitle}>{props.subtitle}</p>
                <button className={classes.mainBtn}>
                    <Link to="/main" style={{ textDecoration: 'none', color: 'white' }}>
                        {props.btnText}
                    </Link>
                </button>
            </div>
        </div>
    );
}
