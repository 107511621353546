/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { addAllHolidaysToDb, importHoliday2 } from 'helpers/hasuraapi';
// import { getCalendarLeaves } from 'helpers/subscriptions/calendarSubscriptions';
import AuthContext from 'context/AuthContext';
// import {
//     useQuery,
//     // useSubscription,
// } from '@apollo/client';
// import { gql } from '@apollo/client';
// import { useHistory } from 'react-router-dom';
// import { getCalendarLeaves } from 'helpers/subscriptions/calendarSubscriptions';

// const standupSubscriptions = (standupTempId: string) => {
//     return gql`subscription standups {
//             standupdb_StandupNotes(where: {standupTempId: {_eq: "${standupTempId}"}}, order_by: {standupNoteDate: desc}) {
//                 standupNoteDate
//                 employeeId
//                 answers
//                 isAccomplished
//                 standupNoteId
//                 standupNoteTime
//                 standupTempId
//                 Employee {
//                     firstName
//                     lastName
//                     profileImage
//                 }

//             }
//         }
//     `;
// };

// const standupQuery = (standupTempId: string) => {
//     return gql`query MyQuery {
//             standupdb_StandupNotes(where: {standupTempId: {_eq: "${standupTempId}"}}, order_by: {standupNoteDate: desc}) {
//                 standupNoteDate
//                 employeeId
//                 answers
//                 isAccomplished
//                 standupNoteId
//                 standupNoteTime
//                 standupTempId
//                 Employee {
//                     firstName
//                     lastName
//                     profileImage
//                 }

//             }
//         }
//     `;
// };

// const standupTemplateQuery = (organizationId: string) => {
//     return gql`query MyQuery {
//             standupdb_StandupTemplate(where: {organizationId: {_eq: "${organizationId}"}}) {
//                 employeeId
//                 organizationId
//                 questions
//                 standupTempId
//             }
//         }
//     `;
// };

// const fetchCalendarLeaves = (organizationId: string) => {
//     return gql`query MyQuery {
//             timeoffdb_Leaves(where: {organizationId: {_eq: "${organizationId}"}}) {
//                 leaveId
//                 category
//                 employeeId
//                 leaveAppliedBy
//                 reason
//                 leaveStatus
//                 noOfHours
//                 startDate
//                 endDate
//                 startTime
//                 endTime
//                 gmt
//                 isoString
//                 denierId
//                 denialReason
//                 approverId
//                 Employee {
//                     firstName
//                     lastName
//                     employeeId
//                     profileImage
//                 }
//             }
//         }`;
// };

export default function Demo2() {
    const mycontext = useContext(AuthContext);
    const [state, setState] = React.useState({
        loading: false,
        error: false,
        data: [],
    });
    // const history = useHistory();
    // const { loading: loading2, error: error2, data: data2 } = useQuery(standupTemplateQuery(mycontext.organizationId));
    // const { loading, error, data, subscribeToMore } = useQuery(standupQuery('96a71185-4b78-4de2-8be0-58ed4c823882'));
    // const { loading, error, data } = useSubscription(getCalendarLeaves(mycontext.organizationId), {
    //     fetchPolicy: 'cache-only',
    // });
    // const subscribeToNewComments = () => {
    //     subscribeToMore({
    //         document: getCalendarLeaves(mycontext.organizationId),
    //         updateQuery: (prev, { subscriptionData }) => {
    //             // console.log('subscriptiondata', prev, subscriptionData);
    //             if (!subscriptionData.data) return prev;
    //             const newFeedItem = subscriptionData.data.timeoffdb_Leaves;
    //             // console.log(
    //             //     Object.assign(
    //             //         {},
    //             //         {
    //             //             timeoffdb_Leaves: [...prev.timeoffdb_Leaves, ...newFeedItem],
    //             //         },
    //             //     ),
    //             // );
    //             return { timeoffdb_Leaves: newFeedItem };
    //         },
    //     });
    // };

    // const subscriptionToNewStandups = () => {
    //     subscribeToMore({
    //         document: standupSubscriptions('96a71185-4b78-4de2-8be0-58ed4c823882'),
    //         updateQuery: (prev, { subscriptionData }) => {
    //             if (!subscriptionData.data) return prev;
    //             const newFeedItem = subscriptionData.data.standupdb_StandupNotes;
    //             return { standupdb_StandupNotes: newFeedItem };
    //         },
    //     });
    // };

    React.useEffect(() => {
        const getData = async () => {
            const holidaysCY: any = await importHoliday2(mycontext.token, 'CA', '2024');
            // console.log('holidaysCY', holidaysCY);
            if (holidaysCY.error) {
                setState({ ...state, error: true, loading: false });
            } else {
                const object: any = [];
                holidaysCY.map((h: any) => {
                    object.push({
                        holidayName: h.name,
                        holidayDate: h.date.iso,
                        holidayDay: null,
                        holidayYear: h.date.datetime.year,
                        countryName: h.country.name,
                        countryCode: h.country.id,
                    });
                    return null;
                });
                setState({ data: object, error: false, loading: false });
            }
        };
        getData();
        // subscriptionToNewStandups();
    }, []);

    // if (loading) return <h2>Loading</h2>;
    // if (error) return <h2>Error</h2>;
    // console.log(data, data2);
    const addAllHolidays = async () => {
        // console.log('Calling holidays');
        // console.log(state.data);
        // const data = await addAllHolidaysToDb(state.data, mycontext.token);
        await addAllHolidaysToDb(state.data, mycontext.token);
        // console.log(data);
    };
    return (
        <div>
            <h2>Displaying all pending leaves</h2>
            <button onClick={() => addAllHolidays()}>Add all</button>
            {/* {state.data.map((h: any, i: number) => (
                <p key={i + 1}>{h.name}</p>
            ))} */}
        </div>
    );
}
