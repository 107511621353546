/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Teams from 'components/TeamUI/Teams';
// import Dashboard from 'components/DashboardPage/Dashboard';
import Dashboard from 'components/MainDashboard/Dashboard';
// import OtherDashboard from 'components/DashboardPage/OtherDashboard';
import Login from 'components/Login/Login';
// import Register from 'components/Register/Register';
import HolidayPage from 'components/Holiday/Holiday';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import EmployeesPage from 'components/TeamMembers/Employees';
import EmployeeMain from 'components/Employees/otherEmployeeMain4';
import SelfProfile from 'components/Employees/newSelfProfile';
// import EmployeesPage from 'components/TeamMembers/Employees';
import SettingComponent from 'components/Settings/Setting';
// import Reports from 'components/Reports/Reports';
// import MemberProfile from 'components/MemberProfile/memberProfile';
// import UserProfile from 'components/MemberProfile/userProfile';
import Layout from 'components/Layout/Layout';
import RegisterAdminMain from 'components/Admin/registerAdminMain';
import AuthLayout from 'components/Layout/AuthLayout';
// import Auth from 'components/Admin/Auth';
import GoogleSignIn from 'components/Admin/GooleSingIn';
import ErrorPage from 'components/Error/ErrorPage';
import ForgotPassword from 'components/ForgotPassword/main';
// import GetUser from 'components/Login/GetUser';
import RegisterUser from 'components/Register/registerMain';
import LandingPage from 'components/LandingPage/LandingPage';
import Admin from 'components/LandingPage/Admin';
import Employee from 'components/LandingPage/Employee';
import Faq from 'components/LandingPage/Faq';
import Help from 'components/LandingPage/Help';
import UserGuide from 'components/LandingPage/UserGuide';
import EmployeeGuide from 'components/LandingPage/EmployeeGuide';
import { empdata, admindata, approverdata } from 'components/LandingPage/GuideData';
import Policy from 'components/LandingPage/Policy';
import TeamplusPolicy from 'components/LandingPage/TeamplusPolicy';
import TermsOfService from 'components/LandingPage/TermsOfService';
import RefundCancellation from 'components/LandingPage/RefundCancellation';
import SlackLandingPage from 'components/LandingPage/SlackLandingPage';
import Standups from 'components/Standups/Standups';
// import Polls from 'components/Polls/Polls';
// import PollsModal from 'components/Polls/modal';
// import UserProfile from 'components/Profile/EmployeeProfile';
import MyOrganizations2 from '../components/Register/UserOrganizations2';
import Calendar from 'components/calendar/bigCalender2';
import Contact from 'components/ContactSupport/Contact';
import ThankYouPage from 'components/Settings/ThankYouPage';
import SlackRegistration from 'components/Admin/slackRegistration';
import RegisterGoogleEmployee from 'components/Register/RegisterGoogleEmployee';
import GoogleSignupEmployee from 'components/Register/GoogleSignupEmployee';
import RegisterGetUser from 'components/Admin/registerGetUserAdmin';
import AuthContext from 'context/AuthContext';
import { useFlagsmith } from 'flagsmith-react';
import { useSubscription } from '@apollo/client';
import { getCalendarLeaves, getCalendarLeavesForApprover } from '../helpers/subscriptions/calendarSubscriptions';
import {
    getDashboardLeaves,
    getEmployeeDashboardLeaves,
    pendingLeavesForApprover,
} from '../helpers/subscriptions/dashboardSubscriptions';
import {
    getOrganizationSubscription_Subscription,
    getUsersSubscription,
} from '../helpers/subscriptions/subscriptionSubscription';
import {
    getEmployeeList,
    inviteStatus,
    getTenantEmployee,
    getTenantMembers,
} from '../helpers/subscriptions/employeeSubscriptions';
import { getTeamList } from '../helpers/subscriptions/teamsSubscriptions';
import { fetchHolidaysSubscription } from '../helpers/subscriptions/holidaySubscription';
import { getSettings } from '../helpers/subscriptions/settingsSubscription';
import { getTimeoffPolicies } from 'helpers/subscriptions/timeoffPolicySubscriptions';
import { getUserStatus } from 'helpers/subscriptions/userStatusSubscriptions';
import {
    getFormDetailsLeaveType,
    getFormDetailsOrgEmployees,
    getFormDetailsTotalHours,
    getFormDetailsTotalHoursOrg,
} from 'helpers/subscriptions/formDetailsSubscriptions';
import { getPendingActions } from 'helpers/subscriptions/pendingActionsSubscriptions';
import { getShoutout } from 'helpers/subscriptions/shoutoutSubscription';

import { fetchPendingLeaves, fetchTodaysLeaves, fetchUpcomingLeaves } from 'components/Redux/dashboardLeavesReducer';
import { fetchEmployeeLeaves } from 'components/Redux/employeeLeavesReducer';
import { fetchApproverLeaves } from 'components/Redux/approverDashboardLeavesReducer';
import { getEmployeePolls, getPolls } from 'helpers/subscriptions/pollSubscriptions';
import { fetchHolidays } from '../components/Redux/holidaysReducer';
import { fetchSettings } from '../components/Redux/settingsReducer';
import { getTimeOff } from 'components/Redux/timeOffPolicyReducer';
import { fetchSubscription, fetchUserSubscriptionDetails } from 'components/Redux/subscriptionReducer';
import { getStandupByDate } from 'helpers/subscriptions/standupSubscriptions';
// import { fetchFormDetails } from 'components/Redux/formDetailsReducer';
import { fetchFormDetails } from 'components/Redux/formDetailsReducer';
import { fetchAllPendingActions } from 'components/Redux/pendingActionsReducer';
import { fetchAllShoutouts } from 'components/Redux/shoutoutReducer';
import { fetchTeams } from 'components/Redux/teamsReducer';

import { updateUserStatus } from '../components/Redux/userStatusReducer';
import { Switch, Route, Redirect } from 'react-router-dom';
import { initialDate, toAWSDateFormat } from 'helpers/date';
import { fetchAllPolls } from 'components/Redux/pollsReducer';
// import useGeolocation from '../Hooks/useGeolocation';
import { getMyCountry } from '../helpers/hasuraapi';
import { fetchAllStandups } from '../components/Redux/StandupRedux';
// import useGeolocation from '../Hooks/useGeolocation';
// import { updateUserLocation } from '../helpers/hasuraapi';
// import { updateUserStatus } from '../components/Redux/userStatusReducer';
// import { Switch, Route, Redirect } from 'react-router-dom';
// import { toAWSDateFormat } from 'helpers/date';
// import { fetchAllLeaves } from 'components/Redux/dashboardLeavesReducer';
// import MainTabs from 'components/Polls/tabs';
// import Response from 'components/Polls/response';
// import PollResult from 'components/Polls/pollresults';
import Demo from './demo2';
import { getCountries } from 'components/Redux/countryReducer';
// import Plugin from 'components/Plugin/Plugin';
import UserLicense from 'components/Settings/UserLicense';
import { TeamplusLoader } from '../components/Loader/loader';
import { getEmployeesList, getInviteStatus } from '../components/Redux/employeeListReducer';
import { fetchTenantEmployees } from 'components/Redux/tenantEmployeesReducer';
import { getCurrentCountryDateTime, localToISO } from 'helpers/timezone';
import FetchingData from './FetchingData';
import FetchData from './FetchData';
import EmployeeTeams from 'components/TeamUI/EmployeeTeams';
import GlobalSettingsNew from 'components/Settings/GlobalSettings';
import { fetchTenantMembers } from 'components/Redux/tenantMemberReducer';
import { SubscriptionPast, SubscriptionToday } from 'helpers/celebrationApi';
import {
    fetchAllPastCelebration,
    fetchAllTodayCelebration /*fetchAllUpcomingCelebration*/,
} from 'components/Redux/celebrationReducer';
// import { getCurrentCountryDate } from 'helpers/timezone';
// import { newurl, newurlSubscription } from '../helpers/hasuraapi';
// import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider, createHttpLink, split } from '@apollo/client';
// import { getMainDefinition } from '@apollo/client/utilities';
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
// import { createClient } from 'graphql-ws';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { SubscriptionClient } from 'subscriptions-transport-ws';
// import { setContext } from '@apollo/client/link/context';

export default function AuthRoutes() {
    const mycontext = useContext(AuthContext);
    const { hasFeature } = useFlagsmith();
    const dispatch = useDispatch();
    const [date, setDate] = React.useState({
        startDate: initialDate('week', 0, mycontext.country.zoneName),
        endDate: localToISO(getCurrentCountryDateTime(mycontext.country.zoneName).date, '23:59:00', mycontext.country),
        title: 'This Week',
    });
    // const [endDate, setEndDate] = React.useState('');
    const state = useSelector((state: any) => state);
    const [startDate] = useState(localToISO(toAWSDateFormat(new Date()), '00:00:00', mycontext.country).split('T')[0]);
    const [endDate] = useState(localToISO(toAWSDateFormat(new Date()), '23:59:00', mycontext.country).split('T')[0]);
    // const [isSetting, setIsSetting] = useState<boolean>(true);
    // const location = useGeolocation();
    // console.log(endDate);
    // calendar leaves
    // console.log(date.endDate, date.startDate);
    const calendarLeaves = useSubscription(getCalendarLeaves(mycontext.organizationId));
    // console.log(calendarLeaves)
    // subscription for calendar leaves
    const approverCalendarLeaves = useSubscription(
        getCalendarLeavesForApprover(mycontext.organizationId, mycontext.EmployeeId),
    );
    // console.log(approverCalendarLeaves)

    // Employee list
    const employeeList = useSubscription(getEmployeeList(mycontext.organizationId));
    const empInviteStatus = useSubscription(inviteStatus(mycontext.organizationId));
    useMemo(() => {
        if (!employeeList.loading) {
            dispatch(getEmployeesList(employeeList));
        }
    }, [employeeList]);
    useMemo(() => {
        if (!empInviteStatus.loading) {
            dispatch(getInviteStatus(empInviteStatus));
        }
    }, [empInviteStatus]);
    // Teams list
    const teams = useSubscription(getTeamList(mycontext.organizationId));
    // console.log('employee', teams);
    // subscription for dashboard leaves
    const dashboardPendingLeaves = useSubscription(
        getDashboardLeaves('pending', mycontext.organizationId, startDate, endDate),
    );
    const dashboardTodaysLeaves = useSubscription(
        getDashboardLeaves('todays', mycontext.organizationId, startDate, endDate),
    );
    const dashboardUpcomingLeaves = useSubscription(
        getDashboardLeaves('upcoming', mycontext.organizationId, startDate, endDate),
    );
    // const standups = useSubscription(getStandups(mycontext.standupTempId));
    // console.log(standups);
    const standups = useSubscription(getStandupByDate(mycontext.standupTempId, date.endDate, date.startDate));
    // console.log(standups);
    // useMemo(() => {
    //     if (mycontext.organizationId && mycontext.token) {
    //         const fetchData = async () => {
    //             const data: any = await fetchQuestion(mycontext?.organizationId, mycontext?.token);
    //             // setData(data);
    //             // // console.log(data?.organizationId)
    //             if (data?.standupTempId) {
    //                 mycontext?.addStandupTempId(data?.standupTempId);
    //             }
    //             if (data.questions.length > 0) {
    //                 dispatch(fetchStandupQuestions({
    //                     questions: data.questions,
    //                     tags: data.tags,
    //                 }));
    //             }
    //             // setAllTags(data?.tags);
    //         };
    //         fetchData();
    //     }
    // }, [mycontext.organizationId, mycontext.token])
    // useMemo(async () => {
    //     if (location.loaded) {
    //         mycontext.addLocation({
    //             country: location.coordinates.country,
    //             code: location.coordinates.code,
    //             timezone: location.coordinates.timezone,
    //         });
    //     }
    //     // console.log('my country ', location.coordinates.country);
    //     if (mycontext.location.country !== location.coordinates.country) {
    //         if (mycontext.EmployeeId !== null || mycontext.EmployeeId !== '') {
    //             await updateUserLocation(
    //                 mycontext.organizationId,
    //                 mycontext.EmployeeId,
    //                 {
    //                     country: location.coordinates.country,
    //                     code: location.coordinates.code,
    //                     timezone: location.coordinates.timezone,
    //                 },
    //                 mycontext.token,
    //             );
    //         }
    //     }
    // }, [location]);

    useMemo(() => {
        if (!teams?.loading) {
            dispatch(fetchTeams(teams));
        }
    }, [teams]);
    useMemo(() => {
        if (!dashboardPendingLeaves?.loading) {
            dispatch(fetchPendingLeaves(dashboardPendingLeaves));
        }
    }, [dashboardPendingLeaves]);
    useMemo(() => {
        if (!dashboardTodaysLeaves?.loading) {
            dispatch(fetchTodaysLeaves(dashboardTodaysLeaves));
        }
    }, [dashboardTodaysLeaves]);
    useMemo(() => {
        if (!dashboardUpcomingLeaves?.loading) {
            dispatch(fetchUpcomingLeaves(dashboardUpcomingLeaves));
        }
    }, [dashboardUpcomingLeaves]);
    // subscription for approver pending leaves
    const approverPendingLeaves = useSubscription(
        pendingLeavesForApprover(mycontext.organizationId, mycontext.EmployeeId),
    );

    useMemo(() => {
        if (!approverPendingLeaves?.loading) {
            dispatch(fetchApproverLeaves(approverPendingLeaves));
        }
    }, [approverPendingLeaves]);
    // subscription for employee dashboard
    const employeeDashboardLeaves = useSubscription(
        getEmployeeDashboardLeaves(mycontext.organizationId, mycontext.EmployeeId),
    );

    useMemo(() => {
        if (!employeeDashboardLeaves?.loading) {
            dispatch(fetchEmployeeLeaves(employeeDashboardLeaves));
        }
    }, [employeeDashboardLeaves]);

    useMemo(() => {
        if (!standups.loading) {
            dispatch(fetchAllStandups(standups));
        }
    }, [standups]);
    // subscription for holidays
    const holidays = useSubscription(fetchHolidaysSubscription(mycontext.organizationId));

    // subscription for general settings
    const settings = useSubscription(getSettings(mycontext.organizationId));

    // subscription for timeoff policies
    const timeoffPolicies = useSubscription(getTimeoffPolicies(mycontext.organizationId));
    const subscriptionData = useSubscription(getOrganizationSubscription_Subscription(mycontext.tenantTableId));
    const usersSubscriptionData = useSubscription(
        getUsersSubscription(mycontext.EmployeeId, mycontext.tenantId, mycontext.organizationId),
    );
    const tenantEmployeeList = useSubscription(getTenantEmployee(mycontext.tenantTableId));
    // console.log(tenantEmployeeList);
    const tenantMemberList = useSubscription(getTenantMembers(mycontext.tenantId));
    const polls = useSubscription(getPolls(mycontext.organizationId));
    const employeePoll = useSubscription(getEmployeePolls(mycontext.organizationId, mycontext.EmployeeId));
    const todayCelebrations = useSubscription(SubscriptionToday(mycontext.tenantTableId, toAWSDateFormat(new Date())));
    const pastCelebrations = useSubscription(
        SubscriptionPast(
            mycontext.tenantTableId,
            toAWSDateFormat(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)),
            toAWSDateFormat(new Date()),
        ),
    );
    // console.log(pastCelebrations);
    // console.log('cale', dashboardPendingLeaves, dashboardTodaysLeaves, dashboardUpcomingLeaves);
    // if (!settings?.loading) {
    //     console.log('executing');
    //     if (settings.data) {
    //         console.log('me executing');
    //         // setIsSetting(false);
    //         mycontext.addOrganizationName(settings.data?.timeoffdb_GeneralSettings[0]?.Organization?.organizationName);
    //         mycontext.addSettings(settings.data?.timeoffdb_GeneralSettings[0]?.displayLogo);
    //         mycontext.updateDateFormat(settings.data?.timeoffdb_GeneralSettings[0]?.dateFormat);
    //         mycontext.addOtherSettings({
    //             approverCalledAs: settings.data?.timeoffdb_GeneralSettings[0]?.approverCalledAs,
    //             employeeCalledAs: settings.data?.timeoffdb_GeneralSettings[0]?.employeeCalledAs,
    //             teamsCalledAs: settings.data?.timeoffdb_GeneralSettings[0]?.teamsCalledAs,
    //             workingHours: settings.data?.timeoffdb_GeneralSettings[0]?.workingHours,
    //         });
    //     }
    //     dispatch(fetchSettings(settings));
    // }
    if (!holidays?.loading) {
        dispatch(fetchHolidays(holidays));
    }
    if (!timeoffPolicies?.loading) {
        dispatch(getTimeOff(timeoffPolicies));
    }
    useMemo(() => {
        if (!subscriptionData?.loading) {
            dispatch(fetchSubscription(subscriptionData));
        }
    }, [subscriptionData]);
    if (!usersSubscriptionData?.loading) {
        dispatch(fetchUserSubscriptionDetails(usersSubscriptionData));
    }
    useMemo(() => {
        if (!tenantEmployeeList?.loading) {
            dispatch(fetchTenantEmployees(tenantEmployeeList));
        }
    }, [tenantEmployeeList]);

    useMemo(() => {
        if (!tenantMemberList?.loading) {
            dispatch(fetchTenantMembers(tenantMemberList));
        }
    }, [tenantMemberList]);
    // subscription for user status
    const userStatus = useSubscription(getUserStatus(mycontext.organizationId, mycontext.EmployeeId));
    if (userStatus?.data?.usermanagementdb_organization_employee.length !== 0) {
        dispatch(updateUserStatus(userStatus?.data?.usermanagementdb_organization_employee[0]));
    }

    useMemo(() => {
        if (settings.data) {
            // setIsSetting(false);
            mycontext.addOrganizationName(settings.data?.timeoffdb_GeneralSettings[0]?.Organization?.organizationName);
            mycontext.addTenantName(settings.data?.timeoffdb_GeneralSettings[0]?.Organization?.Tenant?.name);
            mycontext.addTenantId(settings.data?.timeoffdb_GeneralSettings[0]?.Organization?.Tenant?.workspaceId);
            mycontext.addSettings(settings.data?.timeoffdb_GeneralSettings[0]?.Organization?.Tenant?.displayLogo);
            mycontext.addOrganizationLogo(settings.data?.timeoffdb_GeneralSettings[0]?.Organization?.Tenant?.logo);
            mycontext.updateDateFormat(settings.data?.timeoffdb_GeneralSettings[0]?.dateFormat);
            mycontext.addOtherSettings({
                approverCalledAs: settings.data?.timeoffdb_GeneralSettings[0]?.approverCalledAs,
                employeeCalledAs: settings.data?.timeoffdb_GeneralSettings[0]?.employeeCalledAs,
                teamsCalledAs: settings.data?.timeoffdb_GeneralSettings[0]?.teamsCalledAs,
                workingHours: settings.data?.timeoffdb_GeneralSettings[0]?.workingHours,
            });
        }
        dispatch(fetchSettings(settings));
    }, [settings]);
    // subscription for form details
    const formLeaveType = useSubscription(getFormDetailsLeaveType(mycontext.organizationId));
    const orgEmployees = useSubscription(getFormDetailsOrgEmployees(mycontext.organizationId));
    const totalHours = useSubscription(getFormDetailsTotalHours(mycontext.organizationId, mycontext.EmployeeId));
    const totalHoursOrg = useSubscription(getFormDetailsTotalHoursOrg(mycontext.organizationId));
    const actions = useSubscription(getPendingActions(mycontext.organizationId));

    useMemo(() => {
        if (mycontext.isAdmin) {
            dispatch(fetchAllPolls(polls));
        } else {
            dispatch(fetchAllPolls(employeePoll));
        }
    }, [polls, employeePoll]);
    // console.log(polls);
    // // console.log(formLeaveType, orgEmployees);
    // if (formLeaveType?.error && orgEmployees?.error && totalHours?.error && totalHoursOrg?.error) {
    //     dispatch(fetchFormDetails(formLeaveType));
    // }
    // if (!formLeaveType?.loading && !orgEmployees?.loading && !totalHours?.loading && !totalHoursOrg?.loading) {
    //     const d = {
    //         timeoffdb_LeaveType: formLeaveType?.data?.timeoffdb_LeaveType,
    //         usermanagementdb_organization_employee: orgEmployees?.data?.usermanagementdb_organization_employee,
    //         timeoffdb_get_totalhours: totalHours?.data?.timeoffdb_get_totalhours,
    //         timeoffdb_get_totalhours_org: totalHoursOrg?.data?.timeoffdb_get_totalhours_org,
    //     };
    //     dispatch(fetchFormDetails(d));
    // }
    useMemo(() => {
        if (!todayCelebrations?.loading) {
            // console.log('test todaycelebration');
            dispatch(fetchAllTodayCelebration(todayCelebrations));
        }
    }, [todayCelebrations]);

    useMemo(() => {
        if (!pastCelebrations?.loading) {
            // console.log('test pastcelebration');
            dispatch(fetchAllPastCelebration(pastCelebrations));
        }
    }, [pastCelebrations]);

    useMemo(() => {
        const d = {
            timeoffdb_LeaveType: formLeaveType?.data?.timeoffdb_LeaveType,
            usermanagementdb_organization_employee: orgEmployees?.data?.usermanagementdb_organization_employee,
            timeoffdb_get_totalhours: totalHours?.data?.timeoffdb_get_totalhours,
            timeoffdb_get_totalhours_org: totalHoursOrg?.data?.timeoffdb_get_totalhours_org,
        };
        dispatch(fetchFormDetails(d));
    }, [formLeaveType, orgEmployees, totalHours, totalHoursOrg]);

    // shoutout subscription
    const shoutout = useSubscription(getShoutout(mycontext.organizationId));
    useMemo(() => {
        dispatch(fetchAllShoutouts(shoutout));
    }, [shoutout]);

    useMemo(() => {
        dispatch(fetchAllPendingActions(actions));
    }, [actions]);

    useMemo(() => {
        if (!settings.loading) {
            dispatch(fetchSettings(settings));
        }
    }, [settings]);
    useMemo(() => {
        if (mycontext.organizationId && mycontext.EmployeeId && mycontext.token) {
            const getCountry = async () => {
                const obj = {
                    organizationId: mycontext.organizationId,
                    employeeId: mycontext.EmployeeId,
                    token: mycontext.token,
                };
                const d = await getMyCountry(obj);
                // console.log('d ', d);
                if (d !== undefined) {
                    mycontext.addCountry(d?.location);
                }
            };
            if (window.navigator.onLine) {
                getCountry();
            }
        }
    }, [mycontext.organizationId, mycontext.EmployeeId, mycontext.token]);

    // React.useEffect(() => {
    //     dispatch(getCountries());
    // }, []);
    React.useEffect(() => {
        if (mycontext.token) {
            const obj = {
                token: mycontext.token,
            };
            dispatch(getCountries(obj));
        }
    }, [mycontext.token]);
    //  Using apollo client
    // Without apollo client
    // const cl = new SubscriptionClient(newurlSubscription, {
    //     reconnect: true,
    //     lazy: true,
    //     connectionParams: {
    //         headers: {
    //             'content-type': 'application/json',
    //             Authorization: `Bearer ${localStorage.getItem('token')}`,
    //         },
    //     },
    // });
    // const wsLink = new WebSocketLink(cl);

    if (
        shoutout.loading ||
        formLeaveType.loading ||
        orgEmployees.loading ||
        totalHours.loading ||
        totalHoursOrg.loading ||
        actions.loading ||
        userStatus.loading ||
        polls.loading ||
        employeePoll.loading ||
        holidays.loading ||
        settings.loading ||
        timeoffPolicies.loading ||
        subscriptionData.loading ||
        employeeDashboardLeaves.loading ||
        approverPendingLeaves.loading ||
        dashboardPendingLeaves.loading ||
        dashboardUpcomingLeaves.loading ||
        dashboardTodaysLeaves.loading ||
        // standups2.loading ||
        state.orgEmployees.status === 'loading' ||
        // calendarLeaves.loading ||
        // approverCalendarLeaves.loading ||
        state.employeesList.employeesList.initialStatus === 'loading' ||
        // state.employeesList.employeesList.filteredStatus === 'loading' ||
        state.employeesList.employeesList.initialInviteStatus === 'loading' ||
        // state.employeesList.employeesList.filteredInviteStatus === 'loading' ||
        // state.employeesList.employeesList.teamStatus === 'loading' ||
        state.orgLeaves.status === 'loading' ||
        state.empOnLeaves.status === 'loading' ||
        state.formDetails.status === 'loading' ||
        state.employeeStatus.status === 'loading' ||
        state.leaveTypes.status === 'loading' ||
        state.employeeLeaves.status === 'loading' ||
        state.teams.status === 'loading' ||
        state.graph.status === 'loading' ||
        state.userProfile.leaveHistoryStatus === 'loading' ||
        state.userProfile.userProfileStatus === 'loading' ||
        state.userProfile.empTeamStatus === 'loading' ||
        state.userProfile.newEmpTeamStatus === 'loading' ||
        state.userProfile.status === 'loading' ||
        state.memberProfile.leaveHistoryStatus === 'loading' ||
        state.memberProfile.userProfileStatus === 'loading' ||
        state.memberProfile.empTeamStatus === 'loading' ||
        state.memberProfile.newEmpTeamStatus === 'loading' ||
        // state.memberProfile.userDataStatus === 'loading' ||
        state.memberProfile.status === 'loading' ||
        state.selfProfile.selfProfileDataStatus === 'loading' ||
        state.selfProfile.selfEmpTeamsStatus === 'loading' ||
        state.selfProfile.selfEmpTotHrStatus === 'loading' ||
        state.selfProfile.selfOrgLeaveStatus === 'loading' ||
        state.selfProfile.selfTimeOffHistoryStatus === 'loading' ||
        state.celebrations.todaycelebrationsStatus === 'loading' ||
        state.celebrations.pastCelebrationsStatus === 'loading'
    ) {
        return <TeamplusLoader />;
    }
    return (
        <div style={{ background: '#fff', minHeight: '100vh' }}>
            <Switch>
                {/* {isLogedIn && <Redirect exact from="/" to="/dashboard" />} */}
                {mycontext.isLogedIn && <Redirect exact from="/myorganizationsslack" to="/fetchingData" />}
                {mycontext.isLogedIn && <Redirect exact from="/main" to="/dashboard" />}
                {/* {mycontext.isLogedIn && <Redirect exact from="/myorganizations" to="/dashboard" />} */}
                {/* {mycontext.isLogedIn && <Redirect exact from="/login" to="/myorganizations" />} */}
                {mycontext.isLogedIn && !mycontext.isAdmin && <Redirect exact from="/settings" to="/dashboard" />}
                {mycontext.isLogedIn && !mycontext.isAdmin && <Redirect exact from="/plugins" to="/dashboard" />}
                {/* {mycontext.isLogedIn && !mycontext.isAdmin && <Redirect exact from="/userLicense" to="/dashboard" />} */}
                {mycontext.isLogedIn && (!mycontext.isAdmin || !mycontext.isSuperAdmin) && (
                    <Redirect exact from="/userLicense" to="/dashboard" />
                )}
                {mycontext.isLogedIn && (!mycontext.isAdmin || !mycontext.isSuperAdmin) && (
                    <Redirect exact from="/globalsettings" to="/dashboard" />
                )}
                {mycontext.isLogedIn && !mycontext.isAdmin && <Redirect exact from="/reports" to="/dashboard" />}
                {mycontext.isLogedIn && <Redirect exact from="/slackRegistration" to="/dashboard" />}
                {mycontext.isLogedIn && <Redirect exact from="/registerGetUser" to="/dashboard" />}
                {/* {mycontext.isLogedIn && !isAdmin && <Redirect exact from="/teams" to="/dashboard" />} */}
                {/* {mycontext.isLogedIn && !isAdmin && <Redirect exact from="/holiday" to="/dashboard" />} */}
                {/* {!mycontext.isLogedIn && <Redirect exact from="/" to="/" />} */}
                {!mycontext.isLogedIn && <Redirect exact from="/settings" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/profile" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/teams" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/dashboard" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/add/leave" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/requests" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/leave" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/reports" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/profile" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/user/profile" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/employees" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/employees/profile" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/standups" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/holiday" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/thankyou" to="/login" />}
                {/* {!mycontext.isLogedIn && <Redirect exact from="/googleSignUpEmployee" to="/login" />} */}
                {!mycontext.isLogedIn && <Redirect exact from="/registerGetUser" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/" to="/login" />}
                {/* {!mycontext.isLogedIn && (
                    <Route exact path="/">
                        <LandingPage />
                    </Route>
                )} */}
                <Route exact path="/login">
                    <AuthLayout btn="login">
                        <Login heading="Sign In" avatar={<LockOutlinedIcon />} btn="Sign In" link="Sign Up" />
                    </AuthLayout>
                </Route>
                <Route exact path="/timeoff">
                    <AuthLayout btn="login">
                        <Login
                            heading="Sign In"
                            avatar={<LockOutlinedIcon />}
                            btn="Sign In"
                            link="Sign Up"
                            botType="timeoff"
                        />
                    </AuthLayout>
                </Route>
                <Route exact path="/poll">
                    <AuthLayout btn="login">
                        <Login
                            heading="Sign In"
                            avatar={<LockOutlinedIcon />}
                            btn="Sign In"
                            link="Sign Up"
                            botType="poll"
                        />
                    </AuthLayout>
                </Route>
                <Route exact path="/engage">
                    <AuthLayout btn="login">
                        <Login
                            heading="Sign In"
                            avatar={<LockOutlinedIcon />}
                            btn="Sign In"
                            link="Sign Up"
                            botType="engage"
                        />
                    </AuthLayout>
                </Route>
                <Route exact path="/standup">
                    <AuthLayout btn="login">
                        <Login
                            heading="Sign In"
                            avatar={<LockOutlinedIcon />}
                            btn="Sign In"
                            link="Sign Up"
                            botType="standup"
                        />
                    </AuthLayout>
                </Route>
                <Route exact path="/myorganizations">
                    <MyOrganizations2 />
                </Route>
                {/* <Route exact path="/myorganizationsslack">
                    <MyOrganizations2 />
                </Route> */}
                {mycontext.isLogedIn && (
                    <Route exact path="/thankyou">
                        <ThankYouPage />
                    </Route>
                )}
                {/* {!mycontext.isLogedIn && ( */}
                <Route exact path="/registerUser">
                    <AuthLayout btn="register">
                        <RegisterUser />
                    </AuthLayout>
                </Route>
                {/* )} */}
                {!mycontext.isLogedIn && (
                    <Route exact path="/forgotpassword">
                        <AuthLayout btn="Forgot password">
                            <ForgotPassword />
                        </AuthLayout>
                    </Route>
                )}
                <Route exact path="/slackRegistration">
                    <SlackRegistration />
                </Route>
                <Route exact path="/fetchingData">
                    <FetchingData />
                </Route>
                <Route exact path="/fetchData">
                    <FetchData />
                </Route>
                <Route exact path="/registerGoogleEmployee">
                    <RegisterGoogleEmployee />
                </Route>
                {!mycontext.isLogedIn && (
                    <Route exact path="/googleSignUpEmployee">
                        <GoogleSignupEmployee />
                    </Route>
                )}
                <Route exact path="/registerGetUser">
                    <RegisterGetUser />
                </Route>
                <Route exact path="/">
                    <LandingPage />
                </Route>
                <Route exact path="/admin">
                    <Admin />
                </Route>
                <Route exact path="/employee">
                    <Employee />
                </Route>
                <Route exact path="/faqs">
                    <Faq />
                </Route>
                <Route exact path="/help">
                    <Help />
                </Route>
                <Route exact path="/userguide">
                    <UserGuide />
                </Route>
                <Route exact path="/adminguide">
                    <EmployeeGuide data={admindata} title="Admin Guide" />
                </Route>
                <Route exact path="/employeeguide">
                    <EmployeeGuide data={empdata} title="Employee Guide" />
                </Route>
                <Route exact path="/approverguide">
                    <EmployeeGuide data={approverdata} title="Approver Guide" />
                </Route>
                <Route exact path="/privacypolicy">
                    <TeamplusPolicy />
                </Route>
                <Route exact path="/privacy-policy">
                    <Policy />
                </Route>
                <Route exact path="/terms-of-service">
                    <TermsOfService />
                </Route>
                <Route exact path="/refundpolicy">
                    <RefundCancellation />
                </Route>
                <Route exact path="/teamplus-bot">
                    <SlackLandingPage />
                </Route>
                {!mycontext.isLogedIn && (
                    <Route exact path="/googleSignIn">
                        <GoogleSignIn />
                    </Route>
                )}
                <Route exact path="/main">
                    <RegisterAdminMain />
                </Route>
                {mycontext.isLogedIn && (
                    <Route exact path="/teams">
                        <Layout currentLink="/teams" mainLink="/dashboard" label="Teams" title="Teams">
                            {mycontext.isAdmin ? <Teams teams={teams} /> : <EmployeeTeams teams={teams} />}
                        </Layout>
                    </Route>
                )}
                {/* {mycontext.isLogedIn && (
                    <Route exact path="/demo">
                        <Layout currentLink="/demo" mainLink="/dashboard" label="Teams" title="Teams">
                            <Demo />
                        </Layout>
                    </Route>
                )} */}
                {mycontext.isLogedIn && (
                    <Route exact path="/demo">
                        <Layout currentLink="/demo" mainLink="/dashboard" label="Teams" title="Teams">
                            <Demo />
                        </Layout>
                    </Route>
                )}
                {mycontext.isLogedIn && (
                    <Route path="/dashboard">
                        <Layout currentLink="/dashboard" mainLink="/dashboard" label="Dashboard" title="Dashboard">
                            {/* {mycontext.isAdmin ? (
                                <Dashboard
                                    pending={dashboardPendingLeaves}
                                    todays={dashboardTodaysLeaves}
                                    upcoming={dashboardUpcomingLeaves}
                                />
                            ) : (
                                <OtherDashboard
                                    employee={employeeDashboardLeaves}
                                    pending={approverPendingLeaves}
                                    todays={dashboardTodaysLeaves}
                                    upcoming={dashboardUpcomingLeaves}
                                />
                            )} */}
                            {/* <Dashboard
                                dashboardPendingLeaves={dashboardPendingLeaves}
                                dashboardTodaysLeaves={dashboardTodaysLeaves}
                                dashboardUpcomingLeaves={dashboardUpcomingLeaves}
                                employeeDashboardLeaves={employeeDashboardLeaves}
                                approverPendingLeaves={approverPendingLeaves}
                            /> */}
                            <Dashboard date={date} setDate={setDate} team={teams.data?.usermanagementdb_Team} />
                        </Layout>
                    </Route>
                )}
                {mycontext.isLogedIn && (
                    <Route exact path="/holiday">
                        <Layout currentLink="/holiday" mainLink="/dashboard" label="Holidays" title="Holidays">
                            <HolidayPage />
                        </Layout>
                    </Route>
                )}
                {mycontext.isLogedIn && (
                    <Route exact path="/employees">
                        <Layout currentLink="/employees" mainLink="/dashboard" label="Employees" title="Employees">
                            <EmployeesPage employee={employeeList} inviteStatus={empInviteStatus} teams={teams} />
                            {/* <EmployeeMain /> */}
                        </Layout>
                    </Route>
                )}
                {mycontext.isLogedIn && mycontext.isAdmin && (
                    <Route exact path="/settings">
                        <Layout currentLink="/settings" mainLink="/dashboard" label="Settings" title="Settings">
                            <SettingComponent settings={settings} />
                            {/* <SettingComponent holidays={holidays} settings={settings} timeoffs={timeoffPolicies} /> */}
                        </Layout>
                    </Route>
                )}
                {mycontext.isLogedIn && (
                    <Route exact path="/calender">
                        <Layout currentLink="/calender" mainLink="/dashboard" label="Calender" title="Calender">
                            <Calendar leaves={calendarLeaves} approverLeaves={approverCalendarLeaves} />
                        </Layout>
                    </Route>
                )}
                {mycontext.isLogedIn && (
                    <Route exact path="/contact">
                        <Layout
                            currentLink="/contact"
                            mainLink="/dashboard"
                            label="Contact Support"
                            title="Contact Support"
                        >
                            <Contact />
                        </Layout>
                    </Route>
                )}
                {mycontext.isLogedIn && (
                    <Route exact path="/user/profile">
                        <Layout currentLink="/user/profile" mainLink="/dashboard" label="Profile" title="Profile">
                            {/* <Suspense fallback={<h1>Loading</h1>}> */}
                            <SelfProfile />
                            {/* </Suspense> */}
                        </Layout>
                    </Route>
                )}
                {mycontext.isLogedIn && (
                    <Route exact path="/employees/:userId">
                        <Layout
                            currentLink="/employees/profile"
                            mainLink="/dashboard"
                            label="EmpProfile"
                            title="EmpProfile"
                        >
                            {/* <Suspense fallback={<h1>Loading</h1>}> */}
                            <EmployeeMain />
                            {/* </Suspense> */}
                        </Layout>
                    </Route>
                )}
                {/* {mycontext.isLogedIn && mycontext.features.isPolls && (
                    <Route exact path="/polls">
                        <Layout currentLink="polls" mainLink="/polls" label="Polls" title="Polls">
                            <Polls />
                        </Layout>
                    </Route>
                )} */}
                {/* {mycontext.isLogedIn && mycontext.isSuperAdmin && ( */}
                {mycontext.isLogedIn && mycontext.isSuperAdmin && mycontext.isAdmin && (
                    <Route exact path="/globalsettings">
                        <Layout
                            currentLink="/globalsettings"
                            mainLink="/dashboard"
                            label="Global Settings"
                            title="Global Settings"
                        >
                            <GlobalSettingsNew />
                        </Layout>
                    </Route>
                )}

                {mycontext.isLogedIn && hasFeature('isstandup') && (
                    <Route exact path="/standups">
                        <Layout currentLink="standups" mainLink="/standups" label="Standups" title="Standups">
                            <Standups />
                        </Layout>
                    </Route>
                )}
                {/* {mycontext.isLogedIn && (
                                <Route exact path="/poll3">
                                    <Layout
                                        currentLink="standups"
                                        mainLink="/standups"
                                        label="Standups"
                                        title="Standups"
                                    >
                                        <Polls3 data={pollData} isLoading={pollLoading} isError={pollError} />
                                    </Layout>
                                </Route>
                            )} */}
                {/* {mycontext.isLogedIn && (
                    <Route exact path="/polls">
                        <Layout currentLink="polls" mainLink="/polls" label="Polls" title="Polls">
                            <MainTabs />
                        </Layout>
                    </Route>
                )} */}
                {/* {mycontext.isLogedIn && (
                    <Route exact path="/polls/:pollId">
                        <Layout
                            currentLink="/polls/:pollId"
                            mainLink="/polls"
                            label="Poll/:PollId"
                            title="Poll/:PollId"
                        >
                            {mycontext.isAdmin || mycontext.pollResult ? <PollResult /> : <Response />}
                        </Layout>
                    </Route>
                )} */}
                {/* {mycontext.isLogedIn && mycontext.isAdmin && (
                    <Route exact path="/plugins">
                        <Layout currentLink="/plugins" mainLink="/dashboard" label="Plugins" title="Plugins">
                            <Plugin />
                        </Layout>
                    </Route>
                )} */}
                {mycontext.isLogedIn && mycontext.isAdmin && mycontext.isSuperAdmin && (
                    <Route exact path="/userLicense">
                        <Layout
                            currentLink="/userLicense"
                            mainLink="/dashboard"
                            label="userLicense"
                            title="userLicense"
                        >
                            <UserLicense />
                        </Layout>
                    </Route>
                )}
                {mycontext.isLogedIn && (
                    <Route>
                        <Layout currentLink="/profile" mainLink="/dashboard" label="Profile" title="Profile">
                            <ErrorPage />
                        </Layout>
                    </Route>
                )}
                {!mycontext.isLogedIn && <ErrorPage />}
            </Switch>
        </div>
    );
}
