import React, { useState, useContext } from 'react';
import {
    FormControl,
    Paper,
    FormLabel,
    Typography,
    Button,
    MenuItem,
    Grid,
    Hidden,
    IconButton,
    InputBase,
    Avatar,
    Popover,
    // List,
    // ListItem,
    // ListItemText,
    useTheme,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import myStyle from './TeamStyle';
// import { SearchBar } from '../DashboardPage/DashboardPart';
import TeamsTable from './TeamsTable';
import { PopUp } from '../Modal/Modal';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AuthContext from '../../context/AuthContext';
import { ToStringFormat } from '../../helpers/date';
import { getFullName } from '../../helpers/getInitials';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import FilterLogo from './FilterLogo.svg';

interface Props {
    current?: any;
    setCurrent?: any;
    setRenderTeampg?: any;
    renderTeampg?: any;
    teamDetails?: any;
    setTeamDetails?: any;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
    renderEdit?: any;
    setRenderEdit?: any;
    selectedTeam?: any;
    setSelectedTeam?: any;
}

function TeamDetails(props: Props) {
    const classes = myStyle();
    const theme = useTheme();
    const mycontext = useContext(AuthContext);
    const [value, setValue] = useState('');
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertm, setAlertm] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [listAnchor, setListAnchor] = useState<any>(null);
    const [currentList, setCurrentList] = useState<any>([]);
    const [anchorFilterEl, setAnchorFilterEl] = useState<null | HTMLElement>(null);
    const [anchorRoleEl1, setAnchorRoleEl1] = useState<null | HTMLElement>(null);
    // const [anchorStatusEl2, setAnchorStatusEl2] = useState<null | HTMLElement>(null);
    const [filterFor, setFilterFor] = useState<string>('');
    const [menuSelected, setMenuSelected] = useState<string>('');
    const [teamEmployeeList, setTeamEmployeeList] = useState<any>(props.teamDetails.team_employees);

    const handleFilter = (event: any) => {
        setAnchorFilterEl(event.currentTarget);
    };

    const handleFilterMenuClose = (event: any) => {
        const filterOn = event.target.innerText;
        if (filterOn === 'Approvers') {
            const newTEmp = props.teamDetails.team_employees.filter((m: any) => m.isApprover);
            setTeamEmployeeList(newTEmp);
        } else if (filterOn === 'Members') {
            const newTEmp = props.teamDetails.team_employees.filter((m: any) => !m.isApprover);
            setTeamEmployeeList(newTEmp);
        }
        // else if (filterOn === 'Available') {
        //     const newTEmp = props.teamDetails.team_employees.filter(
        //         (orgrol: any) => orgrol.Employee.status === filterOn.toLowerCase(),
        //     );
        //     setTeamEmployeeList(newTEmp);
        // } else if (filterOn === 'Time-Off') {
        //     const newTEmp = props.teamDetails.team_employees.filter(
        //         (orgrol: any) => orgrol.Employee.status === filterOn.toLowerCase(),
        //     );
        //     setTeamEmployeeList(newTEmp);
        // }
        else {
            setTeamEmployeeList(props.teamDetails.team_employees);
        }

        setFilterFor(filterOn);
        setAnchorFilterEl(null);
        setAnchorRoleEl1(null);
        // setAnchorStatusEl2(null);
        setMenuSelected('');
    };

    const handleRoleMenu = (event: any) => {
        setAnchorRoleEl1(event.currentTarget);
        setMenuSelected(event.target.innerText);
        // setAnchorStatusEl2(null);
    };

    // const handleStatusMenu = (event: any) => {
    //     setAnchorStatusEl2(event.currentTarget);
    //     setMenuSelected(event.target.innerText);
    //     setAnchorRoleEl1(null);
    // };

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const handleListOpen = (event: any, data: any) => {
        setListAnchor(event.currentTarget);
        setCurrentList(data);
    };

    const handleListClose = () => {
        setListAnchor(null);
        setCurrentList([]);
    };

    const searchedMembers = teamEmployeeList.filter((member: any) => {
        return (
            (member.Employee.firstName + ' ' + member.Employee.lastName).toLowerCase().includes(value?.toLowerCase()) ||
            (member.isApprover ? 'Approver' : 'Member').toLowerCase().includes(value?.toLowerCase())
        );
    });
    return (
        <>
            <Popover
                className={classes.menusty}
                anchorEl={anchorFilterEl}
                open={Boolean(anchorFilterEl)}
                onClose={handleFilterMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{ width: '100%' }}
            >
                <MenuItem
                    className={menuSelected === 'Role' ? classes.menuitemSelected : classes.menuitem}
                    value={'Role'}
                    onClick={handleRoleMenu}
                >
                    <Grid container>
                        <Grid item sm={10} xs={10}>
                            Role
                        </Grid>
                        <Grid item sm={2} xs={2}>
                            <span
                                className="iconify"
                                data-icon="eva:arrow-right-fill"
                                style={{ fill: '#C5C5C5' }}
                            ></span>
                        </Grid>
                    </Grid>
                </MenuItem>
                {/* <MenuItem
                    className={menuSelected === 'Status' ? classes.menuitemSelected : classes.menuitem}
                    value={'Status'}
                    onClick={handleStatusMenu}
                >
                    <Grid container>
                        <Grid item sm={10} xs={10}>
                            Status
                        </Grid>
                        <Grid item sm={2} xs={2}>
                            <span
                                className="iconify"
                                data-icon="eva:arrow-right-fill"
                                style={{ color: '#C5C5C5' }}
                            ></span>
                        </Grid>
                    </Grid>
                </MenuItem> */}
            </Popover>
            <Popover
                className={classes.menusty}
                anchorEl={anchorRoleEl1}
                open={Boolean(anchorRoleEl1)}
                onClose={handleFilterMenuClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{ width: '100%' }}
            >
                <Paper>
                    <MenuItem className={classes.menuitem} value={'All'} onClick={handleFilterMenuClose}>
                        All
                    </MenuItem>
                    <MenuItem className={classes.menuitem} value={'Approvers'} onClick={handleFilterMenuClose}>
                        Approvers
                    </MenuItem>
                    <MenuItem className={classes.menuitem} value={'Members'} onClick={handleFilterMenuClose}>
                        Members
                    </MenuItem>
                </Paper>
            </Popover>
            {/* <Menu
                className={classes.menusty}
                anchorEl={anchorStatusEl2}
                getContentAnchorEl={null}
                open={Boolean(anchorStatusEl2)}
                onClose={handleFilterMenuClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem className={classes.menuitem} value={'Available'} onClick={handleFilterMenuClose}>
                    {' '}
                    Available{' '}
                </MenuItem>
                <MenuItem className={classes.menuitem} value={'Time-off'} onClick={handleFilterMenuClose}>
                    {' '}
                    Time-off{' '}
                </MenuItem>
            </Menu> */}
            <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
            <Popover
                id="approversList"
                open={Boolean(listAnchor)}
                anchorEl={listAnchor}
                onClose={handleListClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div>
                    <Typography
                        className={classes.teamMenuTitle}
                        style={{
                            margin: '3px 10px',
                            outline: 'none',
                        }}
                    >
                        Approver List
                    </Typography>
                    <div className={classes.teamList}>
                        {currentList
                            .filter((m: any) => m.isApprover)
                            .map((l: any, index: number) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        margin: '3px 0px',
                                    }}
                                    key={l.Employee.employeeId}
                                >
                                    {l.Employee.profileImage !== null ? (
                                        <Avatar
                                            alt={l.Employee.firstName}
                                            src={l.Employee.profileImage}
                                            className={classes.avatarimage}
                                        />
                                    ) : (
                                        <Avatar className={classes.avatarimage}>
                                            {getFullName(l.Employee.firstName + ' ' + l.Employee.lastName)}
                                        </Avatar>
                                    )}
                                    &nbsp;
                                    <Typography className={classes.textName}>
                                        {l.Employee.firstName} {l.Employee.lastName}
                                    </Typography>
                                </div>
                            ))}
                    </div>
                </div>
            </Popover>
            <Hidden mdDown>
                <Paper elevation={0} className={classes.selectorPaper}>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                        style={{ padding: '10px 0px', marginBottom: 20 }}
                    >
                        <Grid item sm={4}>
                            <FormControl>
                                <FormLabel className={classes.formLabelStyle}>Team Name:</FormLabel>
                                <FormLabel className={classes.formLabelStyle2}>{props.teamDetails.teamName}</FormLabel>
                            </FormControl>
                        </Grid>

                        <Grid item sm={4}>
                            <FormControl>
                                <FormLabel className={classes.formLabelStyle} id="demo-simple-select-label">
                                    Team Approvers:
                                </FormLabel>
                                <FormLabel className={classes.formLabelStyle2}>
                                    {props.teamDetails.team_employees.filter((m: any) => m.isApprover).length === 0 ? (
                                        <span>-</span>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <span>
                                                {props.teamDetails.team_employees
                                                    .filter((m: any) => m.isApprover)
                                                    .slice(0, 2)
                                                    .map((emp: any, index: any) => (
                                                        <span key={index}>
                                                            {emp.Employee.firstName} {emp.Employee.lastName}
                                                            {index + 1 <
                                                            props.teamDetails.team_employees.filter(
                                                                (m: any) => m.isApprover,
                                                            ).length
                                                                ? ','
                                                                : null}{' '}
                                                        </span>
                                                    ))}
                                            </span>
                                            <span>
                                                {props.teamDetails.team_employees.filter((m: any) => m.isApprover)
                                                    .length > 2 && (
                                                    <Avatar
                                                        className={classes.fab}
                                                        onClick={(e: any) =>
                                                            handleListOpen(e, props.teamDetails.team_employees)
                                                        }
                                                    >
                                                        +
                                                        {props.teamDetails.team_employees.filter(
                                                            (m: any) => m.isApprover,
                                                        ).length - 2}
                                                    </Avatar>
                                                )}
                                            </span>
                                        </div>
                                    )}
                                </FormLabel>
                            </FormControl>
                        </Grid>

                        <Grid item sm={4}>
                            <FormControl>
                                <FormLabel className={classes.formLabelStyle} id="demo-simple-select-label">
                                    Created:
                                </FormLabel>
                                <FormLabel className={classes.formLabelStyle2}>
                                    {ToStringFormat(props.teamDetails.createdAt, mycontext.dateFormat)}
                                </FormLabel>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" style={{ height: 40, marginBottom: 20 }}>
                        <Grid container item sm={6} alignItems="center" style={{ height: 40 }}>
                            <Typography className={classes.teamTitle}>Employee List</Typography>
                        </Grid>
                        <Grid container item sm={4} justify="flex-end" alignItems="center" style={{ height: 40 }}>
                            {/* <SearchBar value={value} setValue={setValue} mode="rect" icon={true} /> */}
                            <TextField
                                id="searchname"
                                placeholder="Search"
                                variant="outlined"
                                size="small"
                                value={value}
                                onChange={(event: any) => setValue(event.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className={classes.searchIcon}>
                                            <span
                                                style={{
                                                    marginRight: 10,
                                                    fontSize: 20,
                                                    fill: theme.palette.primary.contrastText,
                                                }}
                                                className="iconify"
                                                data-icon="octicon:search-16"
                                            ></span>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        root: classes.searchInput1,
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: theme.palette.primary.contrastText,
                                    },
                                }}
                                className={classes.searBorder}
                            />
                        </Grid>
                        <Grid container item sm={2} justify="flex-end">
                            {/* justify="flex-end" alignItems="center" style={{ height: 40 }} */}
                            <Button
                                id="filter"
                                variant="outlined"
                                onClick={handleFilter}
                                // disableRipple
                                // disableFocusRipple
                                // disableElevation
                                // aria-describedby="filter-popup"
                                className={classes.myFilter}
                                startIcon={
                                    <span
                                        className="iconify"
                                        data-icon="bx:bx-filter-alt"
                                        data-width="24"
                                        data-height="24"
                                        style={{ fill: theme.palette.primary.contrastText }}
                                    ></span>
                                }
                                endIcon={
                                    <span
                                        className="iconify"
                                        data-icon="bx:bxs-down-arrow"
                                        style={{ fill: theme.palette.primary.contrastText }}
                                        data-width="8"
                                        data-height="8"
                                    ></span>
                                }
                            >
                                Filter By
                            </Button>
                            {/* <Button
                                variant="outlined"
                                onClick={handleFilter}
                                startIcon={<img src={FilterLogo} alt="Filter Logo" />}
                                endIcon={<ArrowDropDownIcon className={classes.IconSty1} />}
                                className={`${classes.filterButton} ${classes.filterButtonWidth}`}
                                style={{ backgroundColor: '#ffffff' }}
                                // style={{ backgroundColor: '#ffffff' }}
                            >
                                Filter by
                            </Button> */}
                        </Grid>
                    </Grid>
                    <div>
                        <TeamsTable
                            current={props.current}
                            teamID={props.teamDetails.teamId}
                            // teamEmployeeList={teamEmployeeList}
                            teamEmployeeList={searchedMembers}
                            filterFor={filterFor}
                            setAlertMessage={setAlertMessage}
                            setAlertm={setAlertm}
                            setSeverity={setSeverity}
                        />
                    </div>
                </Paper>
            </Hidden>

            <Hidden lgUp>
                <div style={{ marginBottom: 40 }}>
                    <Paper elevation={0} style={{ padding: '15px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" className={classes.formLabelStyle}>
                                    Team Name
                                </Typography>
                                <Typography variant="subtitle2" className={classes.formLabelStyle2}>
                                    {props.teamDetails.teamName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" className={classes.formLabelStyle}>
                                    Team Approvers
                                </Typography>
                                {props.teamDetails.team_employees.filter((m: any) => m.isApprover).length === 0 ? (
                                    <span className="iconify" data-icon="bi:dash-lg" style={{ fill: '#333333' }}></span>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>
                                            {props.teamDetails.team_employees
                                                .filter((m: any) => m.isApprover)
                                                .slice(0, 2)
                                                .map((emp: any, index: any) => (
                                                    <span key={index}>
                                                        {emp.Employee.firstName} {emp.Employee.lastName}
                                                        {index + 1 <
                                                            props.teamDetails.team_employees.filter(
                                                                (m: any) => m.isApprover,
                                                            ).length && ','}
                                                        {/* ? ','
                                                            : null} */}
                                                    </span>
                                                ))}
                                        </span>
                                        <span>
                                            {props.teamDetails.team_employees.filter((m: any) => m.isApprover).length >
                                                2 && (
                                                <Avatar
                                                    className={classes.fab}
                                                    onClick={(e: any) =>
                                                        handleListOpen(e, props.teamDetails.team_employees)
                                                    }
                                                >
                                                    +
                                                    {props.teamDetails.team_employees.filter((m: any) => m.isApprover)
                                                        .length - 2}
                                                </Avatar>
                                            )}
                                        </span>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" className={classes.formLabelStyle}>
                                    CreatedAt
                                </Typography>
                                <Typography variant="subtitle2" className={classes.formLabelStyle2}>
                                    {ToStringFormat(props.teamDetails.createdAt, mycontext.dateFormat)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <div className={classes.row} style={{ marginTop: 10 }}>
                        <div className={classes.tab}>Employees ({props.teamDetails.team_employees.length})</div>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Grid container justify="space-between" spacing={2}>
                            <Grid item xs={10}>
                                <Paper
                                    elevation={0}
                                    style={{ display: 'flex', alignItems: 'center', width: '95%', height: 40 }}
                                >
                                    <IconButton style={{ padding: 10 }} aria-label="menu">
                                        <SearchOutlinedIcon style={{ color: theme.palette.primary.contrastText }} />
                                    </IconButton>
                                    <InputBase
                                        id="search-input"
                                        className={classes.searchInput}
                                        value={value}
                                        placeholder="Search"
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'Search' }}
                                        style={{ padding: 10 }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid container item xs={2} justify="flex-end" alignItems="center">
                                <Paper elevation={0} style={{ height: 40 }}>
                                    <Button
                                        id="filter"
                                        variant="outlined"
                                        disableRipple
                                        disableFocusRipple
                                        disableElevation
                                        aria-describedby="filter-popup"
                                        onClick={handleFilter}
                                        style={{
                                            padding: 12,
                                            margin: 0,
                                            color: theme.palette.primary.contrastText,
                                            width: '80%',
                                            border: 'none',
                                            outline: 'none',
                                        }}
                                        startIcon={
                                            <span
                                                className="iconify"
                                                data-icon="bx:bx-filter-alt"
                                                data-width="20"
                                                data-height="20"
                                                style={{ fill: theme.palette.primary.contrastText }}
                                            ></span>
                                        }
                                        endIcon={
                                            <span
                                                className="iconify"
                                                data-icon="bx:bxs-down-arrow"
                                                style={{ color: theme.palette.primary.contrastText }}
                                                data-width="10"
                                                data-height="10"
                                            ></span>
                                        }
                                    ></Button>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ marginBottom: 40 }}>
                        <TeamsTable
                            current={props.current}
                            teamID={props.teamDetails.teamId}
                            // teamEmployeeList={teamEmployeeList}
                            teamEmployeeList={searchedMembers}
                            // searchvalue={value}
                            filterFor={filterFor}
                            setAlertMessage={setAlertMessage}
                            setAlertm={setAlertm}
                            setSeverity={setSeverity}
                        />
                    </div>
                </div>
            </Hidden>
        </>
    );
}

export default TeamDetails;
