import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEmployeeRole2 } from 'helpers/hasuraapi';

export const getMyOrganizations: any = createAsyncThunk(
    'getMyOrganizations/getMyOrganizationsSlice',
    async (user: any) => {
        const data: any = await getEmployeeRole2(user.employeeId, user.token);
        const jsonData: any = await data.json();
        return jsonData.data.usermanagementdb_organization_employee;
    },
);

export const getMyOrganizationsSlice = createSlice({
    name: 'getMyOrganizations',
    initialState: { organizations: [], status: 'loading' },
    reducers: {},
    extraReducers: {
        [getMyOrganizations.pending]: state => {
            state.status = 'loading';
        },
        [getMyOrganizations.fulfilled]: (state, action) => {
            state.organizations = action.payload;
            state.status = 'success';
        },
        [getMyOrganizations.rejected]: state => {
            state.status = 'failed';
        },
    },
});

export default getMyOrganizationsSlice.reducer;
