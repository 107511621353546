import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import SignUp from './SignUp';
// import Experience from './Experience';
import Features from './Features';
import Howitworks from './Howitworks';
import useStyles from './style';
// import Main from './Main';
import { Hidden } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import config from '../../config.json';
// import EmployeeExp from '../../Assets/dashboard/employeeExp.png';
// import Desktop from '../../Assets/dashboard/main_employee.png';
import AddTimeOff from '../../Assets/images/addTimeOff.png';
import MobileTimeOff from '../../Assets/images/mobile1.png';
import SubImage from '../../Assets/images/submain.png';
// import DesktopSmall from '../../Assets/dashboard/employee_mobile.png';

const features = [
    {
        id: 1,
        title: 'Easy Apply',
        description: 'Employees can apply for timeoff simply through a single command from slack.',
        img: 'features/easy.png',
    },
    {
        id: 2,
        title: 'Better Collaboration',
        description: 'Every employee knows when any of their team members are on time-off so as to coordinate better.',
        img: 'features/better.png',
    },
    {
        id: 3,
        title: 'Enhances Productivity',
        description: `Performing all activities on one single platform helps employees to focus on their core activities.`,
        img: 'features/confusion.png',
    },
    {
        id: 4,
        title: 'Unified Platform',
        description:
            'Employees can keep everyone in know-how of their availability, record and know daily goals, give and receive appreciation of their work in one unified platform.',
        img: 'features/calendar.png',
    },
];

const list = [
    {
        id: 1,
        task: 'Sign In to your organization on TeamPlus.',
    },
    {
        id: 2,
        task: 'Install TeamPlus with Add to Slack Button.',
    },
    {
        id: 3,
        task: 'Apply for time-off with a single command.',
    },
    {
        id: 4,
        task: 'Approve/Deny the time-off(s) requests.',
    },
    {
        id: 5,
        task: 'Let your team know your availability.',
    },
];

export default function SlackLandingPage() {
    const dispatch = useDispatch();
    const classes = useStyles();
    useEffect(() => {
        dispatch({ type: 'ACTIVE', payload: '/teamplus-bot' });
    }, [dispatch]);
    return (
        <div style={{ overflowY: 'hidden' }}>
            <Header />
            <div className={classes.slackMain}>
                <Hidden smDown>
                    <img src={AddTimeOff} alt="" className={classes.slackImg} />
                </Hidden>
                <Hidden smUp>
                    <img src={MobileTimeOff} alt="" className={classes.slackImg} />
                </Hidden>
                <div className={classes.mainContent}>
                    <p className={classes.slackSubtitle}>
                        Unified platform for your time-offs and interactions minus complications
                    </p>
                    {/* <p className={classes.slackHeading}>TeamPlus</p> */}
                    <p className={classes.slackCaption}>
                        Bridging the gap between your member interactions, right from time-offs to appreciating your
                        team’s work, to understand the availability and daily goals of your colleagues in one unified
                        application.
                    </p>
                    <button className={classes.mainBtn}>
                        <a
                            href={`https://slack.com/oauth?client_id=${config.slack.CLIENT_ID}&scope=incoming-webhook%2Ccommands%2Cbot&user_scope=&redirect_uri=https://4fxm4bwhy0.execute-api.eu-north-1.amazonaws.com/production/auth/slack/callback&state=&granular_bot_scope=0&single_channel=0&install_redirect=&tracked=1&team=`}
                            // href={`https://slack.com/oauth/authorize?scope=incoming-webhook&user_scope=&redirect_uri=https://teamplus.udyamo.com/settings&client_id=${config.slack.CLIENT_ID}`}
                            // className={classes.slackLink}
                            style={{ textDecoration: 'none', color: 'white' }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ height: 20, width: 20, marginRight: 12 }}
                                viewBox="0 0 122.8 122.8"
                            >
                                <path
                                    d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                                    fill="#e01e5a"
                                ></path>
                                <path
                                    d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                                    fill="#36c5f0"
                                ></path>
                                <path
                                    d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                                    fill="#2eb67d"
                                ></path>
                                <path
                                    d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                                    fill="#ecb22e"
                                ></path>
                            </svg>
                            Add to Slack
                        </a>
                    </button>
                </div>
            </div>
            <Howitworks list={list} img={SubImage} />
            <Features
                heading="The best time management & collaboration experience"
                spam="for your team"
                isEmployee={true}
                features={features}
            />
            {/* <Experience
                img={EmployeeExp}
                title="It's cake walk for "
                spam="admins too"
                subtitle="TeamPlus is easy-to-use and hassle-free for admins and managers as it is for the employees. Here's what we've in store for you."
                btnText="TeamPlus Admin Experience"
            /> */}
            <SignUp title="Collaborating with your team couldn't get any smoother. Try now." />
            <Footer />
        </div>
    );
}
