/* eslint-disable react/jsx-key */
import React, { ReactNode, useContext } from 'react';
// import { useSelector } from 'react-redux';
import SideDrawer, { MobileDrawer } from '../Drawer/SideDrawer';
// import Footer from '../Footer/Footer';
import Header from '../../components/Header/Header';
import useStyles from './LayoutStyle';
// import Navbar from '../Navbar/Navbar';
// import ThemeMode from '../ThemeMode/ThemeMode';
// import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { Hidden } from '@material-ui/core';
import { AddMoreMobile } from '../chatbox/chatbox';
import AuthContext from '../../context/AuthContext';
import { SubscriptionPopUp } from '../Modal/Modal';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
// import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';

interface LayoutProps {
    children?: ReactNode;
    currentLink?: string;
    mainLink?: string;
    label?: string;
    title?: string;
}

export default function Layout(props: LayoutProps): JSX.Element {
    const { children } = props;
    const classes = useStyles();
    const [isSmall, setSmall] = React.useState(false);
    const subscriptions: any = useSelector((state: any) => state.subscription);
    const showSnackbar =
        subscriptions?.softExpiredSubscriptions.length !== 0 || subscriptions?.hardExpiredSubscriptions.length !== 0;
    const licensesCount = subscriptions?.usedLicenses;
    const mycontext = useContext(AuthContext);
    return (
        <div className={classes.root}>
            <Header />
            {mycontext.isAdmin && mycontext.isSuperAdmin ? (
                <Link
                    to={{
                        pathname: '/userLicense',
                    }}
                    style={{ textDecoration: 'none' }}
                >
                    <SubscriptionPopUp
                        open={
                            showSnackbar ||
                            licensesCount['timeoff'] < 0 ||
                            licensesCount['standup'] < 0 ||
                            licensesCount['poll'] < 0 ||
                            licensesCount['engage'] < 0
                        }
                        message={
                            licensesCount['timeoff'] < 0 ||
                            licensesCount['standup'] < 0 ||
                            licensesCount['poll'] < 0 ||
                            licensesCount['engage'] < 0
                                ? `Your license count for ${
                                      licensesCount['timeoff'] < 0
                                          ? 'timeoff'
                                          : licensesCount['standup'] < 0
                                          ? 'standup'
                                          : licensesCount['poll'] < 0
                                          ? 'poll'
                                          : 'engage'
                                  } bot has changed, please remove ${
                                      licensesCount['timeoff'] < 0
                                          ? Math.abs(licensesCount['timeoff'])
                                          : licensesCount['standup'] < 0
                                          ? Math.abs(licensesCount['standup'])
                                          : licensesCount['poll'] < 0
                                          ? Math.abs(licensesCount['poll'])
                                          : Math.abs(licensesCount['engage'])
                                  }
                                    license, Some Actions will not work `
                                : subscriptions?.softExpiredSubscriptions.length !== 0 &&
                                  subscriptions?.hardExpiredSubscriptions.length !== 0
                                ? `Your subscription is expired, Some actions will not work. Click here to renew your subscription ! `
                                : `Your subscription is suspended, Click here to renew your subscription before it expires ! `
                        }
                        severity={
                            subscriptions?.softExpiredSubscriptions.length !== 0 &&
                            subscriptions?.hardExpiredSubscriptions.length !== 0
                                ? 'error'
                                : 'warning'
                        }
                    />
                </Link>
            ) : (
                <SubscriptionPopUp
                    open={
                        showSnackbar ||
                        licensesCount['timeoff'] < 0 ||
                        licensesCount['standup'] < 0 ||
                        licensesCount['poll'] < 0 ||
                        licensesCount['engage'] < 0
                    }
                    message={
                        licensesCount['timeoff'] < 0 ||
                        licensesCount['standup'] < 0 ||
                        licensesCount['poll'] < 0 ||
                        licensesCount['engage'] < 0
                            ? `Your license count for ${
                                  licensesCount['timeoff'] < 0
                                      ? 'timeoff'
                                      : licensesCount['standup'] < 0
                                      ? 'standup'
                                      : licensesCount['poll'] < 0
                                      ? 'poll'
                                      : 'engage'
                              } bot has changed, please contact your admin to adjust the license count `
                            : subscriptions?.softExpiredSubscriptions.length !== 0 &&
                              subscriptions?.hardExpiredSubscriptions.length !== 0
                            ? `Your subscription is expired, Contact your admin to renew the subscription ! `
                            : `Your subscription is suspended, Contact you admin to renew it ! `
                    }
                    severity={
                        subscriptions?.softExpiredSubscriptions.length !== 0 &&
                        subscriptions?.hardExpiredSubscriptions.length !== 0
                            ? 'error'
                            : 'warning'
                    }
                />
            )}
            <Hidden mdDown>
                <div className={!isSmall ? classes.first : classes.firstSmall}>
                    {/* <div className={classes.iconDiv} onClick={() => setSmall(prev => !prev)}> */}
                    {/* <Hidden xsDown implementation="css">
                            {!isSmall ? (
                                <ArrowBackIosOutlinedIcon className={classes.icon} />
                            ) : (
                                <ArrowForwardIosOutlinedIcon className={classes.icon} />
                            )}
                        </Hidden> */}
                    {/* </div> */}
                    <SideDrawer isSmall={isSmall} setSmall={setSmall} />
                </div>
            </Hidden>
            <MobileDrawer />
            <div className={!isSmall ? classes.second : classes.secondSmall}>
                {/* <Header
                    currentLink={props.currentLink}
                    mainLink={props.mainLink}
                    label={props.label}
                    title={props.title}
                    isSmall={isSmall}
                /> */}
                <main className={classes.main}>
                    {/* {mycontext.expires ? (
                        <div className={classes.errorDiv}>
                            Your session has expired, please logout and login again to fetch recent data.
                        </div>
                    ) : null} */}
                    {children}
                    {mycontext.isAdmin && <AddMoreMobile />}
                    {/* <div className={classes.chatBtn}></div> */}
                </main>
                {/* <div style={{ height: '60px', width: '100%' }}>
                    <Footer />
                </div> */}
            </div>
        </div>
    );
}
