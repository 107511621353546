import React from 'react';
// import { useEffect, useContext, useState } from 'react';
import useStyles from './ThankYouPageStyle';
// import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
// import { useHistory } from 'react-router-dom';
// import AuthContext from '../../context/AuthContext';
// import { storeSubscription } from 'helpers/hasuraapi';
import Footer from 'components/LandingPage/Footer';
import successImage from './success.png';
// import { newSubscription } from 'components/Redux/subscriptionReducer';
// import { fetchSubscriptionDetailsChargebee } from '../../helpers/hasuraapi';
// import ErrorPage from '../Error/ErrorPage';
export default function ThankYouPage() {
    // const dispatch = useDispatch();
    const classes = useStyles();
    // const history = useHistory();
    // const mycontext = useContext(AuthContext);
    // const search = window.location.search;
    // const params = new URLSearchParams(search);
    // const sub_id = params.get('sub_id');
    // const invoice_id = params.get('invoice_id');
    // const [error, setError] = useState<boolean>(false);
    // const [subsDetails, setSubsDetails] = useState({
    //     subscription: {
    //         subscriptionId: '',
    //         currentTermStart: 0,
    //         currentTermEnd: 0,
    //         nextBillingAt: 0,
    //         status: '',
    //     },
    // });
    // async function storeDetails(subsDetails: any) {
    //     const obj = {
    //         token: mycontext.token,
    //         subscriptionId: sub_id,
    //         EmployeeId: mycontext.EmployeeId,
    //         organizationId: mycontext.organizationId,
    //         invoiceId: invoice_id,
    //         currentTermStart: subsDetails.subscription?.current_term_start,
    //         currentTermEnd: subsDetails.subscription?.current_term_end,
    //         nextBillingAt: subsDetails.subscription?.next_billing_at,
    //         status: subsDetails.subscription?.status,
    //     };
    //     await storeSubscription(obj);
    //     dispatch(
    //         newSubscription({
    //             usermanagementdb_Subscription: [
    //                 {
    //                     organizationId: mycontext.organizationId,
    //                     subscriptionId: sub_id,
    //                     invoiceId: invoice_id,
    //                     currentTermStart: subsDetails.subscription?.current_term_start,
    //                     currentTermEnd: subsDetails.subscription?.current_term_end,
    //                     nextBillingAt: subsDetails.subscription?.next_billing_at,
    //                     status: subsDetails.subscription?.status,
    //                 },
    //             ],
    //         }),
    //     );

    //     history.push('/dashboard');
    // }
    // async function fetchSubscriptionDetails(subscriptionId: any) {
    //     try {
    //         const result = await fetchSubscriptionDetailsChargebee(subscriptionId);
    //         if (result.http_status_code !== 404) {
    //             setSubsDetails(subsDetails => ({
    //                 ...subsDetails,
    //                 subscription: {
    //                     ...subsDetails.subscription,
    //                     subscriptionId: result.subscription.id,
    //                     currentTermStart: result.subscription.current_term_start,
    //                     currentTermEnd: result.subscription.current_term_end,
    //                     nextBillingAt: result.subscription.next_billing_at,
    //                     status: result.subscription.status,
    //                 },
    //             }));
    //             await storeDetails(result);
    //         } else {
    //             setSubsDetails({ ...subsDetails });
    //         }
    //     } catch (e) {
    //         setError(true);
    //     }
    // }

    // useEffect(() => {
    //     try {
    //         if (sub_id && invoice_id) {
    //             fetchSubscriptionDetails(sub_id);
    //         }
    //     } catch (e) {
    //         setError(true);
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [mycontext.token, mycontext.EmployeeId, mycontext.organizationId]);
    // if (error) {
    //     return <ErrorPage />;
    // } else {
    return (
        <>
            <Grid container className={classes.mainDiv}>
                <Grid item xs={12}>
                    <h2 className={classes.heading}>Thank You!</h2>
                    <h2 className={classes.subheading}>Your subscription has been activated successfully</h2>
                    <h2 className={classes.subheading}>
                        You will be redirected soon,&nbsp;Don&#39;t refresh the page !
                    </h2>
                    <div className={classes.successImage}>
                        <img src={successImage} width="100" height="100" alt="thank you" />
                    </div>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}
