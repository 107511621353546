import React, { useContext } from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    // MenuItem,
    Hidden,
    useTheme,
    Button,
    Popover,
    // Paper,
    // useTheme,
    Tooltip,
    Avatar,
} from '@material-ui/core';
import { useStyles } from '../pollProfileStyles';
import { withStyles } from '@material-ui/core/styles';
import upcomingTimeOff from '../../../Assets/images/upcomingTimeoff.png';
import FilterLogo from '../../Employees/FilterLogo.svg';
import { ToStringFormat } from '../../../helpers/date';
import { convertedDateTime } from 'helpers/timezone';
import AuthContext from '../../../context/AuthContext';
import { useHistory } from 'react-router-dom';

// import { Icon } from '@iconify/react';
// import Employee from 'components/LandingPage/Employee';
import getFullName from '../../../helpers/getInitials';
import { toMonth } from '../../../helpers/date';

const StyledTableCell = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: 600,
    },
    body: {
        fontSize: '12px',
    },
}))(TableCell);

const CustomTooltip = withStyles({
    tooltip: {
        backgroundColor: '#3D49B6',
        color: 'white',
        fontSize: 11,
        fontFamily: 'Nunito Sans',
    },
})(Tooltip);
// const LightTooltip = withStyles((theme: Theme) => ({
//     arrow: {
//         color: theme.palette.info.dark,
//     },
//     tooltip: {
//         backgroundColor: theme.palette.info.dark,
//         // color: theme.palette.text.primary,
//         color: theme.palette.info.contrastText,
//         fontWeight: 500,
//         boxShadow: theme.shadows[1],
//         fontSize: 14,
//     },
// }))(Tooltip);

interface ShoutoutHistory {
    shoutouts?: any;
}
export default function ShoutoutOther(props: ShoutoutHistory) {
    const classes = useStyles();
    const theme = useTheme();
    const mycontext = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [open, setOpen] = React.useState<boolean>(false);
    const [openSub, setOpenSub] = React.useState(false);
    const [anchorElSub, setAnchorElSub] = React.useState<HTMLElement | null>(null);
    const [active, setActive] = React.useState<string>('');
    const allTags = ['#teamwork', '#frontend', '#backend'];
    const allMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const [currentEmp, setCurrentEmp] = React.useState<string>('');
    const history = useHistory();
    const handlePicker = (e: any) => {
        setOpen(!open);
        setAnchorEl(e.currentTarget);
    };

    const handlePickerSub = (event: any, value: string) => {
        if (value !== 'All') {
            setOpenSub(!openSub);
            setAnchorElSub(event.currentTarget);
            setActive(value);
        } else {
            setCurrentEmp('');
        }
    };

    return (
        <div>
            <Popover
                id="picker-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePicker}
                data-testid="filterModal"
            >
                <ul className={classes.filterList}>
                    <li onClick={(e: any) => handlePickerSub(e, 'All')} className={classes.filterName}>
                        All
                    </li>
                    <li
                        onClick={(e: any) => handlePickerSub(e, 'month')}
                        // onMouseLeave={() => {
                        //     setOpenSub(false);
                        //     setAnchorElSub(null);
                        //     setActive(true);
                        // }}
                        className={classes.filterName}
                    >
                        Month
                    </li>
                    <li
                        onClick={(e: any) => handlePickerSub(e, 'tags')}
                        // onMouseLeave={() => {
                        //     setOpenSub(false);
                        //     setAnchorElSub(null);
                        //     setActive(true);
                        // }}
                        className={classes.filterName}
                    >
                        Tags
                    </li>
                </ul>
            </Popover>

            <Popover
                id="picker-popover"
                open={openSub}
                anchorEl={anchorElSub}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePickerSub}
                data-testid="filterSubModal"
            >
                <ul className={classes.filterList}>
                    {active === 'month'
                        ? allMonths.map((e: any, i: number) => (
                              <li
                                  key={i}
                                  onClick={() => {
                                      setOpenSub(false);
                                      setAnchorElSub(null);
                                      setCurrentEmp(e);
                                  }}
                                  className={classes.filterName}
                              >
                                  <span style={{ marginLeft: 10 }}>{e}</span>
                              </li>
                          ))
                        : allTags?.map((e: string, i: number) => (
                              <li
                                  key={i}
                                  onClick={() => {
                                      setOpenSub(false);
                                      setAnchorElSub(null);
                                      setCurrentEmp(e);
                                  }}
                                  className={classes.filterName}
                              >
                                  <span>{e}</span>
                              </li>
                          ))}
                </ul>
            </Popover>
            <Grid container>
                <Hidden only={['xs', 'sm']}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                            display: 'flex',
                            padding: '0px 10px 5px 10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid item xs={6} sm={6}>
                            <Typography className={classes.headerTitle}>Shoutouts</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            style={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                        >
                            <Button
                                variant="outlined"
                                startIcon={
                                    <img
                                        src={FilterLogo}
                                        alt="Filter Logo"
                                        style={{ height: 14, width: 14, color: theme.palette.text.primary }}
                                    />
                                }
                                onClick={handlePicker}
                                className={classes.filterBtn}
                                data-testid="filter"
                            >
                                Filter by
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ height: '55vh' }}>
                        <TableContainer className={classes.tableScrollbar}>
                            <Table stickyHeader aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">#</StyledTableCell>
                                        <StyledTableCell align="justify">From</StyledTableCell>
                                        <StyledTableCell align="justify">To</StyledTableCell>
                                        <StyledTableCell align="justify">Date</StyledTableCell>
                                        <StyledTableCell align="justify">Value</StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                {props.shoutouts?.length > 0 ? (
                                    <>
                                        {currentEmp === '' ? (
                                            <>
                                                {props.shoutouts.map((row: any, i: number) => (
                                                    <TableBody key={i}>
                                                        <TableRow
                                                            // key={i}
                                                            className={classes.leaveHistoryTableHover}
                                                            onClick={() => {
                                                                history.push({
                                                                    pathname: '/dashboard',
                                                                    state: {
                                                                        id: row.shoutoutId,
                                                                        val: '3',
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            <StyledTableCell
                                                                // className={classes.tableCellStyle}
                                                                align="center"
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {i + 1}
                                                            </StyledTableCell>

                                                            <StyledTableCell align="justify">
                                                                <CustomTooltip
                                                                    title={row.Employee?.firstName}
                                                                    placement="top"
                                                                    key={'av-' + i}
                                                                    arrow
                                                                >
                                                                    <Avatar
                                                                        src={row.Employee?.profileImage}
                                                                        style={{ height: 30, width: 30 }}
                                                                    />
                                                                </CustomTooltip>
                                                            </StyledTableCell>

                                                            <StyledTableCell align="justify">
                                                                {row.shoutout_employee_teams[0]?.Employee === null ? (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        {row.shoutout_employee_teams.map(
                                                                            (a: any, i7: number) =>
                                                                                i7 < 5 && (
                                                                                    <CustomTooltip
                                                                                        title={a?.Team?.teamName}
                                                                                        placement="top"
                                                                                        key={'av-' + i7}
                                                                                        arrow
                                                                                    >
                                                                                        <Avatar
                                                                                            className={
                                                                                                classes.avatarimage
                                                                                            }
                                                                                            style={
                                                                                                i7 !== 0
                                                                                                    ? {
                                                                                                          left: `-${i7 *
                                                                                                              8}px`,
                                                                                                      }
                                                                                                    : {
                                                                                                          left: '0px',
                                                                                                      }
                                                                                            }
                                                                                        >
                                                                                            {getFullName(
                                                                                                a.Team?.teamName +
                                                                                                    ' ' +
                                                                                                    'T',
                                                                                            )}
                                                                                        </Avatar>
                                                                                    </CustomTooltip>
                                                                                ),
                                                                        )}
                                                                        {row.shoutout_employee_teams.length > 5 && (
                                                                            <Avatar
                                                                                className={classes.fab}
                                                                                style={{ left: '-24px' }}
                                                                            >
                                                                                +
                                                                                {row.shoutout_employee_teams.length - 5}
                                                                            </Avatar>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        {row.shoutout_employee_teams.map(
                                                                            (a: any, i7: number) =>
                                                                                i7 < 5 &&
                                                                                (a.Employee.profileImage ? (
                                                                                    <CustomTooltip
                                                                                        title={a.Employee?.firstName}
                                                                                        placement="top"
                                                                                        key={'av-' + i7}
                                                                                        arrow
                                                                                    >
                                                                                        <Avatar
                                                                                            className={
                                                                                                classes.avatarimage
                                                                                            }
                                                                                            alt={a.Employee?.firstName}
                                                                                            src={
                                                                                                a.Employee.profileImage
                                                                                            }
                                                                                            style={
                                                                                                i7 !== 0
                                                                                                    ? {
                                                                                                          left: `-${i7 *
                                                                                                              8}px`,
                                                                                                      }
                                                                                                    : {
                                                                                                          left: '0px',
                                                                                                      }
                                                                                            }
                                                                                        />
                                                                                    </CustomTooltip>
                                                                                ) : (
                                                                                    <CustomTooltip
                                                                                        title={a.Employee.firstName}
                                                                                        placement="top"
                                                                                        key={'av-' + i7}
                                                                                        arrow
                                                                                    >
                                                                                        <Avatar
                                                                                            className={
                                                                                                classes.avatarimage
                                                                                            }
                                                                                            style={
                                                                                                i7 !== 0
                                                                                                    ? {
                                                                                                          left: `-${i7 *
                                                                                                              8}px`,
                                                                                                      }
                                                                                                    : {
                                                                                                          left: '0px',
                                                                                                      }
                                                                                            }
                                                                                        >
                                                                                            {getFullName(
                                                                                                a.Employee?.firstName +
                                                                                                    ' ' +
                                                                                                    a.Employee
                                                                                                        ?.lastName,
                                                                                            )}
                                                                                        </Avatar>
                                                                                    </CustomTooltip>
                                                                                )),
                                                                        )}
                                                                        {row.shoutout_employee_teams.length > 5 && (
                                                                            <Avatar
                                                                                className={classes.fab}
                                                                                style={{ left: '-24px' }}
                                                                            >
                                                                                +
                                                                                {row.shoutout_employee_teams.length - 5}
                                                                            </Avatar>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="justify">
                                                                <Typography className={classes.leaveTableColumns}>
                                                                    {ToStringFormat(
                                                                        convertedDateTime(
                                                                            row.createdOn,
                                                                            mycontext.country,
                                                                        ).date,
                                                                        mycontext.dateFormat,
                                                                    )}
                                                                </Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="justify">
                                                                <Typography className={classes.leaveTableColumns}>
                                                                    {row.tags}
                                                                </Typography>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                ))}
                                            </>
                                        ) : props.shoutouts.filter(
                                              (p: any) => currentEmp === p?.tags || currentEmp === toMonth(p.createdOn),
                                          ).length > 0 ? (
                                            <>
                                                {props.shoutouts
                                                    .filter(
                                                        (p: any) =>
                                                            currentEmp === p?.tags ||
                                                            currentEmp === toMonth(p.createdOn),
                                                    )
                                                    .map((row: any, i: number) => (
                                                        <TableBody key={i}>
                                                            <TableRow
                                                                // key={i}
                                                                className={classes.leaveHistoryTableHover}
                                                                onClick={() => {
                                                                    history.push({
                                                                        pathname: '/dashboard',
                                                                        state: {
                                                                            id: row.shoutoutId,
                                                                            val: '3',
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                <StyledTableCell
                                                                    // className={classes.tableCellStyle}
                                                                    align="center"
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    {i + 1}
                                                                </StyledTableCell>

                                                                <StyledTableCell align="justify">
                                                                    <Avatar
                                                                        src={row.Employee.profileImage}
                                                                        style={{ height: 30, width: 30 }}
                                                                    />
                                                                </StyledTableCell>

                                                                <StyledTableCell align="justify">
                                                                    {row.shoutout_employee_teams[0]?.Employee ===
                                                                    null ? (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            {row.shoutout_employee_teams.map(
                                                                                (a: any, i7: number) =>
                                                                                    i7 < 5 && (
                                                                                        <CustomTooltip
                                                                                            title={a?.Team?.teamName}
                                                                                            placement="top"
                                                                                            key={'av-' + i7}
                                                                                            arrow
                                                                                        >
                                                                                            <Avatar
                                                                                                className={
                                                                                                    classes.avatarimage
                                                                                                }
                                                                                                style={
                                                                                                    i7 !== 0
                                                                                                        ? {
                                                                                                              left: `-${i7 *
                                                                                                                  8}px`,
                                                                                                          }
                                                                                                        : {
                                                                                                              left:
                                                                                                                  '0px',
                                                                                                          }
                                                                                                }
                                                                                            >
                                                                                                {getFullName(
                                                                                                    a?.Team?.teamName +
                                                                                                        ' ' +
                                                                                                        'T',
                                                                                                )}
                                                                                            </Avatar>
                                                                                        </CustomTooltip>
                                                                                    ),
                                                                            )}
                                                                            {row.shoutout_employee_teams.length > 5 && (
                                                                                <Avatar
                                                                                    className={classes.fab}
                                                                                    style={{ left: '-24px' }}
                                                                                >
                                                                                    +
                                                                                    {row.shoutout_employee_teams
                                                                                        .length - 5}
                                                                                </Avatar>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            {row.shoutout_employee_teams.map(
                                                                                (a: any, i7: number) =>
                                                                                    i7 < 5 &&
                                                                                    (a.Employee.profileImage ? (
                                                                                        <CustomTooltip
                                                                                            title={
                                                                                                a.Employee?.firstName
                                                                                            }
                                                                                            placement="top"
                                                                                            key={'av-' + i7}
                                                                                            arrow
                                                                                        >
                                                                                            <Avatar
                                                                                                className={
                                                                                                    classes.avatarimage
                                                                                                }
                                                                                                alt={
                                                                                                    a.Employee
                                                                                                        ?.firstName
                                                                                                }
                                                                                                src={
                                                                                                    a.Employee
                                                                                                        .profileImage
                                                                                                }
                                                                                                style={
                                                                                                    i7 !== 0
                                                                                                        ? {
                                                                                                              left: `-${i7 *
                                                                                                                  8}px`,
                                                                                                          }
                                                                                                        : {
                                                                                                              left:
                                                                                                                  '0px',
                                                                                                          }
                                                                                                }
                                                                                            />
                                                                                        </CustomTooltip>
                                                                                    ) : (
                                                                                        <CustomTooltip
                                                                                            title={a.Employee.firstName}
                                                                                            placement="top"
                                                                                            key={'av-' + i7}
                                                                                            arrow
                                                                                        >
                                                                                            <Avatar
                                                                                                className={
                                                                                                    classes.avatarimage
                                                                                                }
                                                                                                style={
                                                                                                    i7 !== 0
                                                                                                        ? {
                                                                                                              left: `-${i7 *
                                                                                                                  8}px`,
                                                                                                          }
                                                                                                        : {
                                                                                                              left:
                                                                                                                  '0px',
                                                                                                          }
                                                                                                }
                                                                                            >
                                                                                                {getFullName(
                                                                                                    a.Employee
                                                                                                        ?.firstName +
                                                                                                        ' ' +
                                                                                                        a.Employee
                                                                                                            ?.lastName,
                                                                                                )}
                                                                                            </Avatar>
                                                                                        </CustomTooltip>
                                                                                    )),
                                                                            )}
                                                                            {row.shoutout_employee_teams.length > 5 && (
                                                                                <Avatar
                                                                                    className={classes.fab}
                                                                                    style={{ left: '-24px' }}
                                                                                >
                                                                                    +
                                                                                    {row.shoutout_employee_teams
                                                                                        .length - 5}
                                                                                </Avatar>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="justify">
                                                                    <Typography className={classes.leaveTableColumns}>
                                                                        {ToStringFormat(
                                                                            convertedDateTime(
                                                                                row.createdOn,
                                                                                mycontext.country,
                                                                            ).date,
                                                                            mycontext.dateFormat,
                                                                        )}
                                                                    </Typography>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="justify">
                                                                    <Typography className={classes.leaveTableColumns}>
                                                                        {row.tags}
                                                                    </Typography>
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    ))}
                                            </>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={6}
                                                        style={{ textAlign: 'center', borderBottom: 'none' }}
                                                    >
                                                        <img
                                                            src={upcomingTimeOff}
                                                            alt="Paper"
                                                            style={{ height: '100px', width: '100px' }}
                                                        />
                                                        <Typography
                                                            style={{
                                                                fontSize: '13px',
                                                                letterSpacing: '0.03em',
                                                                color: theme.palette.text.primary,
                                                                fontWeight: theme.typography.fontWeightBold,
                                                            }}
                                                        >
                                                            No shoutout(s) found.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                style={{ textAlign: 'center', borderBottom: 'none' }}
                                            >
                                                <img
                                                    src={upcomingTimeOff}
                                                    alt="Paper"
                                                    style={{ height: '100px', width: '100px' }}
                                                />
                                                <Typography
                                                    style={{
                                                        fontSize: '13px',
                                                        letterSpacing: '0.03em',
                                                        color: theme.palette.text.primary,
                                                        fontWeight: theme.typography.fontWeightBold,
                                                    }}
                                                >
                                                    No shoutout(s) found.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Grid>
                </Hidden>
            </Grid>
            <Grid container>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', padding: '5px', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Grid item xs={6}>
                            <Typography className={classes.headerTitle}>Shoutouts</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                        >
                            <Button
                                variant="outlined"
                                startIcon={
                                    <img
                                        src={FilterLogo}
                                        alt="Filter Logo"
                                        style={{ height: 14, width: 14, color: theme.palette.text.primary }}
                                    />
                                }
                                onClick={handlePicker}
                                className={classes.filterBtn}
                                data-testid="filter"
                            >
                                Filter by
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{}}>
                        {props.shoutouts?.length > 0 ? (
                            <>
                                {currentEmp === '' ? (
                                    <>
                                        {props.shoutouts.map((row: any, i: number) => (
                                            <Grid
                                                container
                                                className={classes.gridCommonStyle1}
                                                style={{ padding: '5%' }}
                                                key={i}
                                                onClick={() => {
                                                    history.push({
                                                        pathname: '/dashboard',
                                                        state: {
                                                            id: row.shoutoutId,
                                                            val: '3',
                                                        },
                                                    });
                                                }}
                                            >
                                                <Grid item xs={6} className={classes.commonStyle3}>
                                                    <Typography className={classes.pollHistoryCardTitle1}>
                                                        From
                                                    </Typography>
                                                    <Avatar
                                                        src={row.Employee.profileImage}
                                                        style={{ height: 30, width: 30 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.commonStyle3}>
                                                    <Typography className={classes.pollHistoryCardTitle1}>
                                                        To
                                                    </Typography>
                                                    {row.shoutout_employee_teams[0]?.Employee === null ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {row.shoutout_employee_teams.map(
                                                                (a: any, i7: number) =>
                                                                    i7 < 5 && (
                                                                        <CustomTooltip
                                                                            title={a?.Team?.teamName}
                                                                            placement="top"
                                                                            key={'av-' + i7}
                                                                            arrow
                                                                        >
                                                                            <Avatar
                                                                                className={classes.avatarimage}
                                                                                style={
                                                                                    i7 !== 0
                                                                                        ? {
                                                                                              left: `-${i7 * 8}px`,
                                                                                          }
                                                                                        : {
                                                                                              left: '0px',
                                                                                          }
                                                                                }
                                                                            >
                                                                                {getFullName(
                                                                                    // eslint-disable-next-line no-useless-concat
                                                                                    a?.Team?.teamName + ' ' + 'T',
                                                                                )}
                                                                            </Avatar>
                                                                        </CustomTooltip>
                                                                    ),
                                                            )}
                                                            {row.shoutout_employee_teams.length > 5 && (
                                                                <Avatar
                                                                    className={classes.fab}
                                                                    style={{ left: '-24px' }}
                                                                >
                                                                    +{row.shoutout_employee_teams.length - 5}
                                                                </Avatar>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {row.shoutout_employee_teams.map(
                                                                (a: any, i7: number) =>
                                                                    i7 < 5 &&
                                                                    (a.Employee.profileImage ? (
                                                                        <CustomTooltip
                                                                            title={a.Employee?.firstName}
                                                                            placement="top"
                                                                            key={'av-' + i7}
                                                                            arrow
                                                                        >
                                                                            <Avatar
                                                                                className={classes.avatarimage}
                                                                                alt={a.Employee?.firstName}
                                                                                src={a.Employee.profileImage}
                                                                                style={
                                                                                    i7 !== 0
                                                                                        ? {
                                                                                              left: `-${i7 * 8}px`,
                                                                                          }
                                                                                        : {
                                                                                              left: '0px',
                                                                                          }
                                                                                }
                                                                            />
                                                                        </CustomTooltip>
                                                                    ) : (
                                                                        <CustomTooltip
                                                                            title={a.Employee.firstName}
                                                                            placement="top"
                                                                            key={'av-' + i7}
                                                                            arrow
                                                                        >
                                                                            <Avatar
                                                                                className={classes.avatarimage}
                                                                                style={
                                                                                    i7 !== 0
                                                                                        ? {
                                                                                              left: `-${i7 * 8}px`,
                                                                                          }
                                                                                        : {
                                                                                              left: '0px',
                                                                                          }
                                                                                }
                                                                            >
                                                                                {getFullName(
                                                                                    a.Employee?.firstName +
                                                                                        ' ' +
                                                                                        a.Employee?.lastName,
                                                                                )}
                                                                            </Avatar>
                                                                        </CustomTooltip>
                                                                    )),
                                                            )}
                                                            {row.shoutout_employee_teams.length > 5 && (
                                                                <Avatar
                                                                    className={classes.fab}
                                                                    style={{ left: '-24px' }}
                                                                >
                                                                    +{row.shoutout_employee_teams.length - 5}
                                                                </Avatar>
                                                            )}
                                                        </div>
                                                    )}
                                                </Grid>
                                                <Grid item xs={6} className={classes.commonStyle3}>
                                                    <Typography className={classes.pollHistoryCardTitle1}>
                                                        Date
                                                    </Typography>
                                                    <Typography className={classes.leaveTableColumns}>
                                                        {' '}
                                                        {ToStringFormat(
                                                            convertedDateTime(row.createdOn, mycontext.country).date,
                                                            mycontext.dateFormat,
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} className={classes.commonStyle3}>
                                                    <Typography className={classes.pollHistoryCardTitle1}>
                                                        Value
                                                    </Typography>
                                                    <Typography className={classes.leaveTableColumns}>
                                                        {row.tags}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                ) : props.shoutouts.filter(
                                      (p: any) => currentEmp === p?.tags || currentEmp === toMonth(p.createdOn),
                                  ).length > 0 ? (
                                    <>
                                        {props.shoutouts
                                            .filter(
                                                (p: any) =>
                                                    currentEmp === p?.tags || currentEmp === toMonth(p.createdOn),
                                            )
                                            .map((row: any, i: number) => (
                                                <Grid
                                                    container
                                                    className={classes.gridCommonStyle1}
                                                    style={{ padding: '5%' }}
                                                    key={i}
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: '/dashboard',
                                                            state: {
                                                                id: row.shoutoutId,
                                                                val: '3',
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Grid item xs={6} className={classes.commonStyle3}>
                                                        <Typography className={classes.pollHistoryCardTitle1}>
                                                            From
                                                        </Typography>
                                                        <Avatar
                                                            src={row.Employee.profileImage}
                                                            style={{ height: 30, width: 30 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.commonStyle3}>
                                                        <Typography className={classes.pollHistoryCardTitle1}>
                                                            To
                                                        </Typography>
                                                        {row.shoutout_employee_teams[0]?.Employee === null ? (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {row.shoutout_employee_teams.map(
                                                                    (a: any, i7: number) =>
                                                                        i7 < 5 && (
                                                                            <CustomTooltip
                                                                                title={a?.Team?.teamName}
                                                                                placement="top"
                                                                                key={'av-' + i7}
                                                                                arrow
                                                                            >
                                                                                <Avatar
                                                                                    className={classes.avatarimage}
                                                                                    style={
                                                                                        i7 !== 0
                                                                                            ? {
                                                                                                  left: `-${i7 * 8}px`,
                                                                                              }
                                                                                            : {
                                                                                                  left: '0px',
                                                                                              }
                                                                                    }
                                                                                >
                                                                                    {getFullName(
                                                                                        // eslint-disable-next-line no-useless-concat
                                                                                        a?.Team?.teamName + ' ' + 'T',
                                                                                    )}
                                                                                </Avatar>
                                                                            </CustomTooltip>
                                                                        ),
                                                                )}
                                                                {row.shoutout_employee_teams.length > 5 && (
                                                                    <Avatar
                                                                        className={classes.fab}
                                                                        style={{ left: '-24px' }}
                                                                    >
                                                                        +{row.shoutout_employee_teams.length - 5}
                                                                    </Avatar>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {row.shoutout_employee_teams.map(
                                                                    (a: any, i7: number) =>
                                                                        i7 < 5 &&
                                                                        (a.Employee.profileImage ? (
                                                                            <CustomTooltip
                                                                                title={a.Employee?.firstName}
                                                                                placement="top"
                                                                                key={'av-' + i7}
                                                                                arrow
                                                                            >
                                                                                <Avatar
                                                                                    className={classes.avatarimage}
                                                                                    alt={a.Employee?.firstName}
                                                                                    src={a.Employee.profileImage}
                                                                                    style={
                                                                                        i7 !== 0
                                                                                            ? {
                                                                                                  left: `-${i7 * 8}px`,
                                                                                              }
                                                                                            : {
                                                                                                  left: '0px',
                                                                                              }
                                                                                    }
                                                                                />
                                                                            </CustomTooltip>
                                                                        ) : (
                                                                            <CustomTooltip
                                                                                title={a.Employee.firstName}
                                                                                placement="top"
                                                                                key={'av-' + i7}
                                                                                arrow
                                                                            >
                                                                                <Avatar
                                                                                    className={classes.avatarimage}
                                                                                    style={
                                                                                        i7 !== 0
                                                                                            ? {
                                                                                                  left: `-${i7 * 8}px`,
                                                                                              }
                                                                                            : {
                                                                                                  left: '0px',
                                                                                              }
                                                                                    }
                                                                                >
                                                                                    {getFullName(
                                                                                        a.Employee?.firstName +
                                                                                            ' ' +
                                                                                            a.Employee?.lastName,
                                                                                    )}
                                                                                </Avatar>
                                                                            </CustomTooltip>
                                                                        )),
                                                                )}
                                                                {row.shoutout_employee_teams.length > 5 && (
                                                                    <Avatar
                                                                        className={classes.fab}
                                                                        style={{ left: '-24px' }}
                                                                    >
                                                                        +{row.shoutout_employee_teams.length - 5}
                                                                    </Avatar>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.commonStyle3}>
                                                        <Typography className={classes.pollHistoryCardTitle1}>
                                                            Date
                                                        </Typography>
                                                        <Typography className={classes.leaveTableColumns}>
                                                            {' '}
                                                            {ToStringFormat(
                                                                convertedDateTime(row.createdOn, mycontext.country)
                                                                    .date,
                                                                mycontext.dateFormat,
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.commonStyle3}>
                                                        <Typography className={classes.pollHistoryCardTitle1}>
                                                            Value
                                                        </Typography>
                                                        <Typography className={classes.leaveTableColumns}>
                                                            {row.tags}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                    </>
                                ) : (
                                    <Grid
                                        container
                                        // className={classes.mobileUICardTheme}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: theme.palette.background.paper,
                                        }}
                                    >
                                        <img
                                            src={upcomingTimeOff}
                                            alt="Paper"
                                            style={{ height: '100px', width: '100px' }}
                                        />
                                        <Typography
                                            style={{
                                                fontSize: '13px',
                                                letterSpacing: '0.03em',
                                                color: theme.palette.text.primary,
                                                fontWeight: theme.typography.fontWeightBold,
                                            }}
                                        >
                                            No shoutout(s) found.
                                        </Typography>
                                    </Grid>
                                )}
                            </>
                        ) : (
                            <Grid
                                container
                                // className={classes.mobileUICardTheme}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: theme.palette.background.paper,
                                }}
                            >
                                <img src={upcomingTimeOff} alt="Paper" style={{ height: '100px', width: '100px' }} />
                                <Typography
                                    style={{
                                        fontSize: '13px',
                                        letterSpacing: '0.03em',
                                        color: theme.palette.text.primary,
                                        fontWeight: theme.typography.fontWeightBold,
                                    }}
                                >
                                    No shoutout(s) found.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
}
