import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, withStyles } from '@material-ui/core';
import AuthContext from 'context/AuthContext';
import { DateDifference } from 'helpers/date';
import { getCurrentCountryDate, isoToLocal } from 'helpers/timezone';

interface EmojiProps {
    employeeId: string;
}

const CustomTooltip = withStyles({
    tooltip: {
        backgroundColor: '#3D49B6',
        color: 'white',
        fontSize: 11,
        fontFamily: 'Nunito Sans',
    },
})(Tooltip);

const DisplayEmoji = (props: EmojiProps) => {
    const state: any = useSelector((state: any) => state);
    const mycontext = useContext(AuthContext);
    if (state.timeOffPolicy.status === 'loading' || state.dashboardLeaves.status === 'loading') {
        return null;
    }
    if (state.timeOffPolicy.status === 'failed' || state.dashboardLeaves.status === 'failed') {
        return null;
    }
    const category: string =
        state.dashboardLeaves.todaysLeaves
            .filter(
                (l: any) =>
                    DateDifference(
                        isoToLocal(l.isoString.startTime, mycontext.country),
                        getCurrentCountryDate(mycontext.country.zoneName),
                    ) >= 0 &&
                    DateDifference(
                        getCurrentCountryDate(mycontext.country.zoneName),
                        isoToLocal(l.isoString.endTime, mycontext.country),
                    ) > 0,
            )
            .filter((l: any) => l.employeeId === props.employeeId).length === 0
            ? null
            : state.dashboardLeaves.todaysLeaves.filter((l: any) => l.employeeId === props.employeeId)[0].category;
    return (
        <>
            {category === null ? null : (
                <CustomTooltip title={category} placement="top" arrow>
                    <span>
                        {
                            state.timeOffPolicy.timeOffPolicy.filter(
                                (c: any) => c.leaveTypeName.toLowerCase() === category.toLowerCase(),
                            )[0]?.leaveTypeEmoticon?.emoji
                        }
                    </span>
                </CustomTooltip>
            )}
        </>
    );
};

export default DisplayEmoji;
