import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { toDate } from '../../helpers/date';
import { fetchEmployeesOnLeave2 } from 'helpers/hasuraapi';

export const getEmployeesOnLeave: any = createAsyncThunk('employeesOnLeave/getEmployeesOnLeave', async (user: any) => {
    const empLeavesObj = {
        organizationId: user.organizationId,
        token: user.token,
        startDate: toDate(
            moment()
                .clone()
                .startOf('month')
                .startOf('day'),
        ),
        endDate: toDate(
            moment()
                .clone()
                .endOf('month')
                .endOf('day'),
        ),
        leaveStatus: 'approved',
    };
    const data: any = await fetchEmployeesOnLeave2(empLeavesObj);
    const jsonData: any = await data.json();
    return jsonData.data.timeoffdb_Leaves;
});

export const employeesOnLeaveSlice = createSlice({
    name: 'employeesOnLeave',
    initialState: { empOnleaves: [], status: 'loading' },
    reducers: {
        deleteEmpOnLeave: (state, action) => {
            state.empOnleaves = state.empOnleaves.filter((u: any) => u.leaveId !== action.payload.leaveId);
        },
    },
    extraReducers: {
        [getEmployeesOnLeave.pending]: state => {
            state.status = 'loading';
        },
        [getEmployeesOnLeave.fulfilled]: (state, action) => {
            state.empOnleaves = action.payload;
            state.status = 'success';
        },
        [getEmployeesOnLeave.rejected]: state => {
            state.status = 'failed';
        },
    },
});

export const { deleteEmpOnLeave } = employeesOnLeaveSlice.actions;
export default employeesOnLeaveSlice.reducer;
