import React from 'react';
import { useEffect } from 'react';
import {
    Button,
    Paper,
    Grid,
    Hidden,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    // Typography,
    Tooltip,
    TextField,
    // InputBase,
    InputAdornment,
    // Popover,
    MenuItem,
    ListItemText,
    Select,
    // Chip,
    Checkbox,
    Input,
    InputLabel,
    // IconButton,
    // Tabs,
    // Tab,
} from '@material-ui/core';
import useStyles from './TeammemberStyles';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
// import AuthContext from '../../context/AuthContext';
// import { getAllOrganizationTeams } from '../../helpers/hasuraapi';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     anchorOrigin: {
//         vertical: 'bottom',
//         horizontal: 'center',
//     },
//     transformOrigin: {
//         vertical: 'top',
//         horizontal: 'center',
//     },
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

interface InviteProps {
    toggle(): void;
    inviteUsers(e: any, data: any, team?: any): void;
    teams: any;
}

export default function EmailInvite(props: InviteProps) {
    const classes = useStyles();
    // const [teams, setTeams] = React.useState<any>([]);
    // const [defaultTeam, setDefaultTeam] = React.useState<any>(null);
    // const mycontext = useContext(AuthContext);
    const teams = props.teams?.data?.usermanagementdb_Team;
    const defaultTeam = props.teams?.data?.usermanagementdb_Team.filter((t: any) => t.teamName === 'general')[0];
    const inputArr = [
        {
            id: 0,
            email: null,
            cancel: false,
            teams: [],
            isError: false,
        },
        {
            id: 1,
            email: null,
            cancel: true,
            teams: [],
            isError: false,
        },
    ];
    const [arr, setArr] = React.useState<any>(inputArr);

    // const emailRegex = /^([a-z\d.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;

    const handleSelectChange = (e: any, row: any) => {
        const index = row.id;
        setArr((s: any) => {
            const newArr = s.slice();
            newArr[index].teams = e.target.value;
            return newArr;
        });
    };

    useEffect(() => {
        let isMounted = true;
        async function getData() {
            // const teamsData = await getAllOrganizationTeams(mycontext.organizationId, mycontext.token);
            // const defaultT = teamsData.filter((t: any) => t.teamName === 'general')[0];
            // setDefaultTeam(defaultT);

            setArr((s: any) => {
                const newArr = s.slice();
                newArr.map((n: any) => {
                    n.teams = [...n.teams, defaultTeam];
                    return n;
                });
                return newArr;
            });

            // setTeams(teamsData);
        }

        if (isMounted) {
            getData();
        }
        return () => {
            isMounted = false;
        };
    }, [defaultTeam]);

    const addInput = () => {
        setArr((s: any) => {
            return [
                ...s,
                {
                    id: s.length,
                    email: null,
                    cancel: true,
                    teams: [defaultTeam],
                },
            ];
        });
    };

    const removeInput = (i: number) => {
        const newInputs = [...arr];
        newInputs.splice(i, 1);
        setArr(newInputs);
    };

    const handleChange = (e: any, row: any) => {
        e.persist();
        const index = row.id;
        setArr((s: any) => {
            const newArr = s.slice();
            newArr[index].email = e.target.value;
            return newArr;
        });
    };

    const handleValidation = (e: any, row: any) => {
        e.persist();
        const index = row.id;
        if (e.target.value === '') {
            setArr((s: any) => {
                const newArr = s.slice();
                newArr[index].isError = false;
                return newArr;
            });
        } else {
            if (/^([a-z\d.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(e.target.value)) {
                setArr((s: any) => {
                    const newArr = s.slice();
                    newArr[index].isError = false;
                    return newArr;
                });
            } else {
                setArr((s: any) => {
                    const newArr = s.slice();
                    newArr[index].isError = true;
                    return newArr;
                });
            }
        }
    };

    return (
        <div className={classes.inviteContainer}>
            <Hidden smUp>
                <Button
                    id="backBtn"
                    variant="contained"
                    color="primary"
                    disableRipple={true}
                    onClick={props.toggle}
                    className={classes.backBtn}
                >
                    Back
                </Button>
                <Button
                    id="sendBtn"
                    variant="contained"
                    color="primary"
                    disableRipple={true}
                    onClick={(e: any) => props.inviteUsers(e, arr, defaultTeam)}
                    className={classes.sendBtn}
                >
                    Send Invite
                </Button>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
                {arr.map((row: any, i: number) => (
                    <Paper
                        key={i}
                        elevation={0}
                        style={{ padding: '20px 15px', marginBottom: 10, borderRadius: '0px' }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} style={{ padding: 0 }}>
                                {row.cancel && (
                                    <Grid container item justify="flex-end" alignItems="flex-start" xs={12}>
                                        <ClearIcon
                                            id={'clearIcon-' + i}
                                            fontSize="small"
                                            onClick={() => removeInput(i)}
                                            className={classes.cancelIcon}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor={'textField-' + i} className={classes.labels} id="mobheading1">
                                    Email
                                </InputLabel>
                                <TextField
                                    type="email"
                                    className={classes.mobileField}
                                    id={'textField-' + i}
                                    variant="outlined"
                                    placeholder="Enter Email"
                                    value={row.email !== null ? row.email : ''}
                                    fullWidth
                                    onChange={(e: any) => handleChange(e, row)}
                                    onBlur={(e: any) => handleValidation(e, row)}
                                    helperText={row.isError ? 'Enter a valid Email' : ''}
                                    error={row.isError}
                                    InputProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            padding: 5,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor={'select-' + i} className={classes.labels} id="mobheading2">
                                    Invite To Teams
                                </InputLabel>
                                <Select
                                    multiple
                                    displayEmpty
                                    className={classes.mobileField}
                                    id={'select-' + i}
                                    value={row.teams}
                                    fullWidth
                                    disableUnderline
                                    onChange={e => handleSelectChange(e, row)}
                                    input={<Input key={'input-' + i} />}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'center',
                                        },
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                width: 250,
                                            },
                                        },
                                    }}
                                    style={{
                                        border: '1px solid #C4C4C4',
                                        padding: '5px',
                                        borderRadius: '4px',
                                        height: 40,
                                    }}
                                    inputProps={{
                                        'aria-label': 'Without label',
                                        style: {
                                            padding: 5,
                                        },
                                    }}
                                    renderValue={(selected: any) => {
                                        /* istanbul ignore next */ if ((selected as string[]).length === 0) {
                                            return <span>Select Teams</span>;
                                        }

                                        /* istanbul ignore next */ return selected.map((value: any, ii: number) => (
                                            <span key={value.teamId} className={classes.listText}>
                                                {value.teamName}
                                                {ii !== selected.length - 1 && <>, &nbsp;</>}
                                            </span>
                                        ));
                                    }}
                                >
                                    {teams.map((team: any, index: number) => {
                                        /* istanbul ignore next */ return defaultTeam.teamName === team.teamName &&
                                            defaultTeam.teamId === team.teamId ? (
                                            <MenuItem key={team.teamId} value={team} disabled>
                                                <Checkbox
                                                    id={'checkbox-' + index}
                                                    color="primary"
                                                    disabled
                                                    disableRipple={true}
                                                    checked={true}
                                                />
                                                <ListItemText primary={team.teamName} />
                                            </MenuItem>
                                        ) : (
                                            <MenuItem key={team.teamId} value={team}>
                                                <Checkbox
                                                    id={'checkbox-' + index}
                                                    color="primary"
                                                    disableRipple={true}
                                                    checked={row.teams.includes(team) ? true : false}
                                                />
                                                <ListItemText primary={team.teamName} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
                <Button
                    id="inviteMore"
                    variant="outlined"
                    color="primary"
                    className={classes.inviteMore}
                    style={{ textTransform: 'capitalize' }}
                    onClick={addInput}
                    startIcon={<AddIcon />}
                >
                    Invite More
                </Button>
            </Hidden>
            <Hidden only={['xs', 'sm']}>
                <TableContainer component="div" className={classes.emailContainer}>
                    <Table stickyHeader className={classes.emailList} aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.hashId} id="heading1">
                                    #
                                </TableCell>
                                <TableCell align="left" className={classes.tablecolumn} id="heading2">
                                    Email
                                </TableCell>
                                <TableCell align="left" className={classes.tablecolumn} id="heading3">
                                    Invite to Teams &nbsp;
                                    <LightTooltip
                                        title="By default all the members will be added to the general team"
                                        placement="top-start"
                                    >
                                        <InfoIcon id="infoicon" fontSize="inherit" style={{ color: '#515ECE' }} />
                                    </LightTooltip>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ textTransform: 'capitalize' }}>
                            {arr.map((row: any, i: number) => (
                                <TableRow key={i} className={classes.rows}>
                                    <TableCell align="center">{i + 1}</TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            className={classes.textField}
                                            id={'textField-' + i}
                                            variant="outlined"
                                            type="email"
                                            placeholder="Email"
                                            value={row.email !== null ? row.email : ''}
                                            onChange={(e: any) => handleChange(e, row)}
                                            onBlur={(e: any) => handleValidation(e, row)}
                                            helperText={row.isError ? 'Enter a valid Email' : ''}
                                            error={row.isError}
                                            style={{ width: '80%' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <span
                                                            className="iconify"
                                                            data-icon="clarity:email-line"
                                                            data-width="20"
                                                            data-height="20"
                                                            style={{ color: '#888888' }}
                                                        ></span>
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    height: '35px',
                                                    borderRadius: 4,
                                                    padding: 5,
                                                },
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Select
                                            multiple
                                            displayEmpty
                                            className={classes.textField}
                                            id={'select-' + i}
                                            value={row.teams}
                                            fullWidth
                                            disableUnderline
                                            onChange={e => handleSelectChange(e, row)}
                                            input={<Input key={'input-' + i} />}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                },
                                                getContentAnchorEl: null,
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 210,
                                                    },
                                                },
                                            }}
                                            style={{
                                                border: '1px solid #C4C4C4',
                                                padding: '5px',
                                                borderRadius: 4,
                                                height: 40,
                                            }}
                                            inputProps={{
                                                'aria-label': 'Without label',
                                                style: {
                                                    padding: 5,
                                                },
                                            }}
                                            renderValue={(selected: any) => {
                                                /* istanbul ignore next */ if ((selected as string[]).length === 0) {
                                                    /* istanbul ignore next */ return <span>Select Teams</span>;
                                                }

                                                /* istanbul ignore next */ return selected.map(
                                                    (value: any, ii: number) => (
                                                        <span key={value.teamId} className={classes.listText}>
                                                            {value.teamName}
                                                            {ii !== selected.length - 1 && <>, &nbsp;</>}
                                                        </span>
                                                    ),
                                                );
                                            }}
                                        >
                                            {teams.map((team: any, index: number) => {
                                                /* istanbul ignore next */ return defaultTeam.teamName ===
                                                    team.teamName && defaultTeam.teamId === team.teamId ? (
                                                    <MenuItem key={team.teamId} value={team} disabled>
                                                        <Checkbox
                                                            id={'checkbox-' + index}
                                                            color="primary"
                                                            disabled
                                                            disableRipple={true}
                                                            checked={true}
                                                        />
                                                        <ListItemText primary={team.teamName} />
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem key={team.teamId} value={team}>
                                                        <Checkbox
                                                            id={'checkbox-' + index}
                                                            color="primary"
                                                            disableRipple={true}
                                                            checked={row.teams.includes(team) ? true : false}
                                                        />
                                                        <ListItemText primary={team.teamName} />
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {row.cancel && (
                                            <ClearIcon
                                                id={'clearIcon' + i}
                                                fontSize="small"
                                                onClick={/* istanbul ignore next */ () => removeInput(i)}
                                                style={{ color: '#BCBCBC' }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Button
                    id="inviteMore"
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.moreBtn}
                    onClick={addInput}
                    startIcon={<AddIcon />}
                >
                    Invite More
                </Button>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={2}
                    style={{ marginTop: 40 }}
                >
                    {/* <Grid item>
                        <Button
                            id="back"
                            variant="outlined"
                            color="primary"
                            disableRipple
                            onClick={props.toggle}
                            className={classes.back}
                        >
                            Back
                        </Button>
                    </Grid> */}
                    <Grid item>
                        <Button
                            id="send"
                            variant="contained"
                            color="primary"
                            disableRipple
                            className={classes.send}
                            onClick={(e: any) => props.inviteUsers(e, arr, defaultTeam)}
                        >
                            Send Invite
                        </Button>
                    </Grid>
                </Grid>
            </Hidden>
        </div>
    );
}

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        color: '#444444',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        width: 180,
    },
}))(Tooltip);
