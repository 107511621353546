import React, { useState } from 'react';
import { Grid, Paper, Hidden, Typography } from '@material-ui/core';
import useStyles from './newRegisterStyles';
import { PopUp } from '../Modal/Modal';
import { Link, useHistory } from 'react-router-dom';
import { fetchSlackUsers } from '../../helpers/hasuraapi';
import SlackLogin from '../Login/slackPopUp';
import { NameLoader, ThreeDotsLoader } from '../Spinner/Spinner';
import jwt_decode from 'jwt-decode';
import axios, { AxiosRequestConfig } from 'axios';
import config from '../../config.json';

import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

interface NewRegister {
    setPageNumber?: any;
    values?: any;
}

export default function RegisterPartOne(props: NewRegister) {
    const classes = useStyles();
    const history = useHistory();
    localStorage.setItem('inviteUrl', window.location.href);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    // const styleclass = MBScreenStyle();
    const onFailed = (message: any) => {
        setLoading(false);
        setAlert(true);
        setSeverity('error');
        setAlertMessage('Click allow to give access!');
    };
    const onSuccess = async (message: any) => {
        setLoading(true);
        const slackUrl = 'https://slack.com/api/openid.connect.token';
        const client_id = '1290316667744.4507395671634';
        const client_secret = 'a6c4b9fe103b69654201e9d52be7c823';
        const code = message;
        const details = {
            code,
            client_id,
            client_secret,
        };
        const formBody = [];
        for (const property in details) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        const formBodyTwo = formBody.join('&');

        const _headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        const configAxios: AxiosRequestConfig = {
            method: 'POST',
            url: slackUrl,
            data: formBodyTwo,
            headers: _headers,
        };

        const response = await axios(configAxios);
        const authToken = response.data.access_token;
        const decodedSlackToken: any = jwt_decode(response.data.id_token);
        const slackUserId = decodedSlackToken.sub;
        const bodyFormData = new FormData();
        bodyFormData.append('token', authToken);
        const tokenResponse = await fetch(
            'https://sls-swec-dev-tokenforprod.azurewebsites.net/api/token?code=qvfMsYlPFRGK5CVuHluzs1NbFY6Y6PT9SRtDsnhP4zUQAzFuN4xntA==',
            {
                method: 'POST',
                body: JSON.stringify({
                    token: response.data.access_token,
                    source: 'slack',
                    user: slackUserId,
                    secret: 'dEhpc0lzbXkkRWNSMytrRXlGT1J0ZUFtcExVc0B1dGgzTishKGFUaTBu',
                }),
            },
        )
            .then(async res => {
                const d = await res.json();
                return d.body;
            })
            .catch(err => {
                return err;
            });
        const token = tokenResponse;
        const decoded: any = jwt_decode(token.token);
        const slackUsers = await fetchSlackUsers(decoded['accId']);
        setLoading(false);

        if (Object.keys(slackUsers.employees).length === 0) {
            setAlert(true);
            setSeverity('error');
            setAlertMessage('Please try again in sometime');
        } else {
            history.push({
                pathname: '/slackRegistration',
                state: {
                    token: token.token,
                    slackUsers: slackUsers,
                    refreshToken: token.refresh_token,
                },
            });
        }
    };
    /* istanbul ignore next */
    if (loading) {
        return (
            <>
                <Hidden smDown>
                    <div className={classes.rootLoader}>
                        <NameLoader />
                        <ThreeDotsLoader />
                    </div>
                </Hidden>
                <Hidden smUp>
                    <div className={classes.loginMain}>
                        <h3 className={classes.welcome}>Welcome</h3>
                        <h3 className={classes.to}>To</h3>
                        <img
                            src={s3Bucket + registerLoginPageImages.udyamoTeamplusWhite}
                            alt="teamplus"
                            className={classes.teamLogoSmallMobile}
                        />
                    </div>
                </Hidden>
            </>
        );
    }
    return (
        <div style={{ display: 'flex' }}>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.registerPageImage}
                                    alt="Page1Img"
                                    style={{
                                        height: '75vh',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                    alt="udyamoLogo"
                                    style={{
                                        height: '2.5vh',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} xl={7} className={classes.secondGrid}>
                    <Paper style={{ boxShadow: 'none' }} className={classes.paperStyle}>
                        <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                        <Grid container direction="row">
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <Typography variant="subtitle1" className={classes.SignInheading} id="signin-heading">
                                    Register
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: 25,
                                }}
                            >
                                <div>
                                    <SlackLogin
                                        onFailure={onFailed}
                                        onSuccess={onSuccess}
                                        slackClientId={config.client_id_dev}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                style={{
                                    marginBottom: 30,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="caption" className={classes.createLink}>
                                    Already have an account?{' '}
                                    <Link
                                        to={{
                                            pathname: '/login',
                                        }}
                                        style={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Login
                                    </Link>
                                </Typography>
                                <Typography variant="caption" className={classes.createLink}>
                                    Visit to home page?{' '}
                                    <Link
                                        to={{
                                            pathname: '/',
                                        }}
                                        style={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Click here
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {/* </Hidden> */}
        </div>
    );
}
