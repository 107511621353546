import React from 'react';
import useStyles from './ModalStyle';
import { Paper, Modal, Divider, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
// import Success from './Success.png';
// import Danger from './Danger.png';
// import Warning from './Warning.png';

interface Props {
    title?: string;
    setOpen?: any;
    children?: JSX.Element;
    open: boolean;
    prevStep?: any;
    onClosed?: any;
    onModalClosed?: any;
    onclick?: any;
    message?: any;
    severity?: any;
    fromSubscription?: any;
    showSnackBar?: any;
}

export function StepModal(props: Props): JSX.Element {
    const classes = useStyles();
    const close = (): any => {
        props.setOpen(false);
    };
    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClick={() => (props.onclick ? props.onclick() : close())}
        >
            <div className={classes.modal}>
                <Paper className={classes.paper}>
                    <div className={classes.stepHeader}>
                        {props.title}
                        <CloseIcon className="closeIcon" fontSize="small" onClick={() => close()} />
                    </div>
                    <Divider />
                    {props.children}
                </Paper>
            </div>
        </Modal>
    );
}

export function Alert(props: Props): JSX.Element {
    const classes = useStyles();
    const close = (): any => {
        setTimeout(() => {
            props.setOpen(false);
        }, 2000);
    };
    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onChange={close()}
        >
            <div>
                <div className={classes.modal}>
                    <Paper className={classes.paper}>
                        <CloseIcon className="closeIcon" fontSize="small" onClick={() => close()} />
                        <Divider />
                        {props.children}
                    </Paper>
                </div>
            </div>
        </Modal>
    );
}

export function ConfirmAlert(props: Props): JSX.Element {
    const classes = useStyles();
    const close = (): any => {
        props.setOpen(false);
    };

    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClick={() => (props.onclick ? props.onclick() : close())}
        >
            <div>
                <div className={classes.modal}>
                    <Paper className={classes.paper}>
                        {props.title}
                        <CloseIcon className="closeIcon" fontSize="small" onClick={() => close()} />
                        <Divider />
                        {props.children}
                    </Paper>
                </div>
            </div>
        </Modal>
    );
}

export default function MyModal(props: Props): JSX.Element {
    // <MyModal setOpen={toggle} open={isDelete} onModalClosed={toggle}></MyModal>
    const classes = useStyles();
    const close = (): any => {
        if (props.onModalClosed) {
            props.onModalClosed();
        } else {
            props.setOpen(false);
        }
    };
    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false) || props.onClosed}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.modal}>
                <Paper className={classes.paper} style={{ borderRadius: 0 }}>
                    <div className={classes.headerTwo}>
                        {props.title}
                        <CloseIcon fontSize="small" onClick={() => close()} className={classes.icon} />
                    </div>
                    <div className={classes.content}>{props.children}</div>
                </Paper>
            </div>
        </Modal>
    );
}

export function MyModalDemo(props: Props): JSX.Element {
    const classes = useStyles();
    const close = (): any => {
        props.setOpen(false);
        props.prevStep();
    };
    return (
        <Modal
            open={props.open}
            onClose={() => close() || props.onClosed}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.modal}>
                <Paper className={classes.paper} style={{ borderRadius: 0 }}>
                    <div className={classes.header}>
                        <CloseIcon fontSize="small" onClick={() => close()} className={classes.icon} />
                    </div>
                    <div className={classes.content}>{props.children}</div>
                </Paper>
            </div>
        </Modal>
    );
}
export function PopUp(props: Props): JSX.Element {
    const classes = useStyles();
    const handleClose = (): any => {
        if (!props.fromSubscription) {
            setTimeout(() => {
                props.setOpen(false);
            }, 1000);
        }
    };
    // const SuccessIcon = <img src={Success} alt="Success" width={20} height={18} />;
    // const DangerIcon = <img src={Danger} alt="Danger" width={20} height={18} />;
    // const WarningIcon = <img src={Warning} alt="Warning" width={20} height={18} />;
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={classes.snackbar}
                // className={classes.snackbar}
                open={props.open}
                autoHideDuration={1800}
                onClose={handleClose}
            >
                <MuiAlert
                    elevation={6}
                    // icon={false}
                    // iconMapping={{
                    //     success: SuccessIcon,
                    //     error: DangerIcon,
                    //     warning: WarningIcon,
                    // }}
                    variant="filled"
                    severity={props.severity}
                    style={
                        props.fromSubscription
                            ? {
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '100%',
                              }
                            : {
                                  display: 'flex',
                                  // flexDirection: 'row-reverse',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                              }
                    }
                >
                    <div
                        id="message"
                        className={classes.modfontsty}
                        // style={
                        //     props.severity === 'success'
                        //         ? { color: '#fff' }
                        //         : props.severity === 'error'
                        //             ? { color: '#fff' }
                        //             : props.severity === 'warning'
                        //                 ? { color: '#fff' }
                        //                 : {}
                        // }
                    >
                        {props.message}
                    </div>
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export function SubscriptionPopUp(props: Props): JSX.Element {
    const classes = useStyles();
    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className={classes.subscriptionSnackbar}
                open={props.open}
            >
                <Alert severity={props.severity} className={classes.subscriptionSnackbarAlert}>
                    <div id="message" className={classes.modfontsty}>
                        {props.message}
                    </div>
                </Alert>
            </Snackbar>
        </div>
    );
}
