export const s3Bucket = 'https://teamplusimagestorage.blob.core.windows.net/teamplusimagestorage/';

export const images = {
    LeftArrow: 'calender/LeftArrow.svg',
    RightArrow: 'calender/RightArrow.svg',
};

export const sideBarImages = {
    dashboardActive: 'sidebar/DashBoardActive.svg',
    dashboardInactive: 'sidebar/DashboardInactive.svg',
    teamsActive: 'sidebar/TeamsActive.svg',
    teamsInactive: 'sidebar/TeamsInactive.svg',
    employeesActive: 'sidebar/EmployeesActive.svg',
    employeesInactive: 'sidebar/EmployeesInactive.svg',
    calenderActive: 'sidebar/CalenderActive.svg',
    calenderInactive: 'sidebar/CalenderInactive.svg',
    settingsActive: 'sidebar/SettingsActive.svg',
    settingsInactive: 'sidebar/SettingsInactive.svg',
    reportActive: 'sidebar/report_blue.svg',
    reportInActive: 'sidebar/report_white.svg',
    pollActive: 'sidebar/pollActive.svg',
    pollInActive: 'sidebar/pollInactive.svg',
};

export const landingpageImages = {
    simpleSolution: 'landingPage/1.svg',
    theFirst: 'landingPage/2.svg',
    allIn: 'landingPage/allinonedash.svg',
    twoMinute: 'landingPage/twominute.svg',
    limitlessCustomization: 'landingPage/limitedless.svg',
    holidayCalendar: 'landingPage/holiday.svg',
    manyTo: 'landingPage/7.svg',
    mobileFriendly: 'landingPage/mobilefri.svg',
    realTime: 'landingPage/9.svg',
    easyDesignation: 'landingPage/10.svg',
    instantNotifications: 'landingPage/instaantnotif.svg',
    wonderingWhat: 'landingPage/12.svg',
    easyApply: 'landingPage/easyapply.svg',
    betterCollaboration: 'landingPage/bettercoll.svg',
    noConfusion: 'landingPage/noconf.svg',
    convenientIntegration: 'landingPage/conviinteg.svg',
    itsA: 'landingPage/17.svg',
    adminGuide: 'landingPage/adminappro.svg',
    employeeGuide: 'landingPage/employeeguide.svg',
    approverGuide: 'landingPage/approverguide.svg',
    main_admin: 'landingPage/main_admin.png',
    main_employee: 'landingPage/main_employee.png',
    admin_bg: 'landingPage/howitwork_bg_admin.png',
    employee_bg: 'landingPage/howitwork_bg_employee.png',
    admin_mbl: 'landingPage/admin_mobile.png',
    employee_mbl: 'landingPage/employee_mobile.png',
    dell: 'landingPage/dell.png',
    homepage_banner1: 'landingPage/homepage_banner1.png',
    first_mobile: 'landingPage/first_mobile.png',
};

export const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const registerLoginPageImages = {
    bgImage: 'register/bgImg.png',
    loginWelcomeImage: 'register/login_welcome.png',
    registerPageImage: 'register/Page1Img.png',
    userOrgnizationPageImage: 'register/Page3Img.png',
    teamplusLogo: 'register/Teamplus_logo.png',
    teamplusLogoWhite: 'register/teamPlus_white.png',
    teamplusLogoWhiteWithIcon: 'register/teamplus-white.png',
    udyamoBlack: 'register/udyamo.png',
    udyamoBlackWithLogo: 'register/udyamo-teamplus-mobile.png',
    udyamoWhite: 'register/udyamo-white.png',
    udyamoTeamplus: 'register/udyamo-teamplus-logo.png',
    udyamoTeamplusWhite: 'register/udyamo-teamplus-logo-white.png',
    udyamoSplashScreen: 'register/udyamo-splash-screen.png',
    todaysTimeOff: 'register/todaysTimeoff.png',
    upcomingTimeOff: 'register/upcomingTimeOff.png',
};

export const notificationImage = {
    autoApprove: 'notification/autoapprove.svg',
};

export const country_data = [
    { code: 'AD', label: 'Andorra', phone: '376', gmt: 'GMT+0100' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971', gmt: 'GMT+0400' },
    { code: 'AF', label: 'Afghanistan', phone: '93', gmt: 'GMT+0430' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268', gmt: 'GMT-0400' },
    { code: 'AI', label: 'Anguilla', phone: '1-264', gmt: 'GMT-0400' },
    { code: 'AL', label: 'Albania', phone: '355', gmt: 'GMT+0100' },
    { code: 'AM', label: 'Armenia', phone: '374', gmt: 'GMT+0400' },
    { code: 'AO', label: 'Angola', phone: '244', gmt: 'GMT+0100' },
    { code: 'AQ', label: 'Antarctica', phone: '672', gmt: 'GMT' },
    { code: 'AR', label: 'Argentina', phone: '54', gmt: 'GMT-0300' },
    { code: 'AS', label: 'American Samoa', phone: '1-684', gmt: 'GMT-1100' },
    { code: 'AT', label: 'Austria', phone: '43', gmt: 'GMT+0100' },
    { code: 'AU', label: 'Australia', phone: '61', gmt: 'GMT+1000', suggested: true },
    { code: 'AW', label: 'Aruba', phone: '297', gmt: 'GMT-0400' },
    { code: 'AX', label: 'Alland Islands', phone: '358', gmt: 'GMT+0200' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994', gmt: 'GMT+0400' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387', gmt: 'GMT+0100' },
    { code: 'BB', label: 'Barbados', phone: '1-246', gmt: 'GMT-0400' },
    { code: 'BD', label: 'Bangladesh', phone: '880', gmt: 'GMT+0600' },
    { code: 'BE', label: 'Belgium', phone: '32', gmt: 'GMT+0100' },
    { code: 'BF', label: 'Burkina Faso', phone: '226', gmt: 'GMT+0000' },
    { code: 'BG', label: 'Bulgaria', phone: '359', gmt: 'GMT+0200' },
    { code: 'BH', label: 'Bahrain', phone: '973', gmt: 'GMT+0300' },
    { code: 'BI', label: 'Burundi', phone: '257', gmt: 'GMT+0200' },
    { code: 'BJ', label: 'Benin', phone: '229', gmt: 'GMT+0100' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590', gmt: 'GMT-0400' },
    { code: 'BM', label: 'Bermuda', phone: '1-441', gmt: 'GMT-0400' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673', gmt: 'GMT' },
    { code: 'BO', label: 'Bolivia', phone: '591', gmt: 'GMT-0400' },
    { code: 'BR', label: 'Brazil', phone: '55', gmt: 'GMT-0300' },
    { code: 'BS', label: 'Bahamas', phone: '1-242', gmt: 'GMT-0500' },
    { code: 'BT', label: 'Bhutan', phone: '975', gmt: 'GMT+0600' },
    { code: 'BV', label: 'Bouvet Island', phone: '47', gmt: 'GMT' },
    { code: 'BW', label: 'Botswana', phone: '267', gmt: 'GMT+0200' },
    { code: 'BY', label: 'Belarus', phone: '375', gmt: 'GMT+0300' },
    { code: 'BZ', label: 'Belize', phone: '501', gmt: 'GMT-0600' },
    { code: 'CA', label: 'Canada', phone: '1', gmt: 'GMT-0600', suggested: true },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61', gmt: 'GMT+0630' },
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243', gmt: 'GMT+0100' },
    { code: 'CF', label: 'Central African Republic', phone: '236', gmt: 'GMT+0100' },
    { code: 'CG', label: 'Congo, Republic of the', phone: '242', gmt: 'GMT+0200' },
    { code: 'CH', label: 'Switzerland', phone: '41', gmt: 'GMT+0100' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225', gmt: 'GMT+0000' },
    { code: 'CK', label: 'Cook Islands', phone: '682', gmt: 'GMT-1000' },
    { code: 'CL', label: 'Chile', phone: '56', gmt: 'GMT-0300' },
    { code: 'CM', label: 'Cameroon', phone: '237', gmt: 'GMT+0100' },
    { code: 'CN', label: 'China', phone: '86', gmt: 'GMT+0800' },
    { code: 'CO', label: 'Colombia', phone: '57', gmt: 'GMT-0500' },
    { code: 'CR', label: 'Costa Rica', phone: '506', gmt: 'GMT-0600' },
    { code: 'CU', label: 'Cuba', phone: '53', gmt: 'GMT-0500' },
    { code: 'CV', label: 'Cape Verde', phone: '238', gmt: 'GMT-0100' },
    { code: 'CW', label: 'Curacao', phone: '599', gmt: 'GMT-0400' },
    { code: 'CX', label: 'Christmas Island', phone: '61', gmt: 'GMT+0700' },
    { code: 'CY', label: 'Cyprus', phone: '357', gmt: 'GMT+0200' },
    { code: 'CZ', label: 'Czech Republic', phone: '420', gmt: 'GMT+0100' },
    { code: 'DE', label: 'Germany', phone: '49', gmt: 'GMT+0100', suggested: true },
    { code: 'DJ', label: 'Djibouti', phone: '253', gmt: 'GMT+0300' },
    { code: 'DK', label: 'Denmark', phone: '45', gmt: 'GMT+0100' },
    { code: 'DM', label: 'Dominica', phone: '1-767', gmt: 'GMT-0400' },
    { code: 'DO', label: 'Dominican Republic', phone: '1-809', gmt: 'GMT-0400' },
    { code: 'DZ', label: 'Algeria', phone: '213', gmt: 'GMT+0100' },
    { code: 'EC', label: 'Ecuador', phone: '593', gmt: 'GMT-0500' },
    { code: 'EE', label: 'Estonia', phone: '372', gmt: 'GMT+0200' },
    { code: 'EG', label: 'Egypt', phone: '20', gmt: 'GMT+0200' },
    { code: 'EH', label: 'Western Sahara', phone: '212', gmt: 'GMT' },
    { code: 'ER', label: 'Eritrea', phone: '291', gmt: 'GMT+0300' },
    { code: 'ES', label: 'Spain', phone: '34', gmt: 'GMT' },
    { code: 'ET', label: 'Ethiopia', phone: '251', gmt: 'GMT+0300' },
    { code: 'FI', label: 'Finland', phone: '358', gmt: 'GMT+0200' },
    { code: 'FJ', label: 'Fiji', phone: '679', gmt: 'GMT+1200' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500', gmt: 'GMT-0300' },
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '691', gmt: 'GMT+1000' },
    { code: 'FO', label: 'Faroe Islands', phone: '298', gmt: 'GMT+0000' },
    { code: 'FR', label: 'France', phone: '33', gmt: 'GMT+0100', suggested: true },
    { code: 'GA', label: 'Gabon', phone: '241', gmt: 'GMT+0100' },
    { code: 'GB', label: 'United Kingdom', phone: '44', gmt: 'GMT+0000' },
    { code: 'GD', label: 'Grenada', phone: '1-473', gmt: 'GMT-0400' },
    { code: 'GE', label: 'Georgia', phone: '995', gmt: 'GMT+0400' },
    { code: 'GF', label: 'French Guiana', phone: '594', gmt: 'GMT-0300' },
    { code: 'GG', label: 'Guernsey', phone: '44', gmt: 'GMT+0000' },
    { code: 'GH', label: 'Ghana', phone: '233', gmt: 'GMT+0000' },
    { code: 'GI', label: 'Gibraltar', phone: '350', gmt: 'GMT+0100' },
    { code: 'GL', label: 'Greenland', phone: '299', gmt: 'GMT-0400' },
    { code: 'GM', label: 'Gambia', phone: '220', gmt: 'GMT+0000' },
    { code: 'GN', label: 'Guinea', phone: '224', gmt: 'GMT+0000' },
    { code: 'GP', label: 'Guadeloupe', phone: '590', gmt: 'GMT-0400' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240', gmt: 'GMT+0100' },
    { code: 'GR', label: 'Greece', phone: '30', gmt: 'GMT+0200' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500', gmt: 'GMT-0200' },
    { code: 'GT', label: 'Guatemala', phone: '502', gmt: 'GMT-0600' },
    { code: 'GU', label: 'Guam', phone: '1-671', gmt: 'GMT+1000' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245', gmt: 'GMT+0000' },
    { code: 'GY', label: 'Guyana', phone: '592', gmt: 'GMT-0400' },
    { code: 'HK', label: 'Hong Kong', phone: '852', gmt: 'GMT+0800' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672', gmt: 'GMT' },
    { code: 'HN', label: 'Honduras', phone: '504', gmt: 'GMT-0600' },
    { code: 'HR', label: 'Croatia', phone: '385', gmt: 'GMT+0100' },
    { code: 'HT', label: 'Haiti', phone: '509', gmt: 'GMT-0500' },
    { code: 'HU', label: 'Hungary', phone: '36', gmt: 'GMT+0100' },
    { code: 'ID', label: 'Indonesia', phone: '62', gmt: 'GMT+0800' },
    { code: 'IE', label: 'Ireland', phone: '353', gmt: 'GMT+0000' },
    { code: 'IL', label: 'Israel', phone: '972', gmt: 'GMT+0200' },
    { code: 'IM', label: 'Isle of Man', phone: '44', gmt: 'GMT+0000' },
    { code: 'IN', label: 'India', phone: '91', gmt: 'GMT+0530' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246', gmt: 'GMT+0600' },
    { code: 'IQ', label: 'Iraq', phone: '964', gmt: 'GMT+0300' },
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98', gmt: 'GMT+0330' },
    { code: 'IS', label: 'Iceland', phone: '354', gmt: 'GMT+0000' },
    { code: 'IT', label: 'Italy', phone: '39', gmt: 'GMT+0100' },
    { code: 'JE', label: 'Jersey', phone: '44', gmt: 'GMT+0000' },
    { code: 'JM', label: 'Jamaica', phone: '1-876', gmt: 'GMT-0500' },
    { code: 'JO', label: 'Jordan', phone: '962', gmt: 'GMT+0200' },
    { code: 'JP', label: 'Japan', phone: '81', gmt: 'GMT+0900', suggested: true },
    { code: 'KE', label: 'Kenya', phone: '254', gmt: 'GMT+0300' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996', gmt: 'GMT+0600' },
    { code: 'KH', label: 'Cambodia', phone: '855', gmt: 'GMT+0700' },
    { code: 'KI', label: 'Kiribati', phone: '686', gmt: 'GMT+13:00' },
    { code: 'KM', label: 'Comoros', phone: '269', gmt: 'GMT+0300' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869', gmt: 'GMT-0400' },
    { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850', gmt: 'GMT+0900' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82', gmt: 'GMT+0900' },
    { code: 'KW', label: 'Kuwait', phone: '965', gmt: 'GMT+0300' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345', gmt: 'GMT-0500' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7', gmt: 'GMT+0500' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856', gmt: 'GMT+0700' },
    { code: 'LB', label: 'Lebanon', phone: '961', gmt: 'GMT+0200' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758', gmt: 'GMT-0400' },
    { code: 'LI', label: 'Liechtenstein', phone: '423', gmt: 'GMT+0100' },
    { code: 'LK', label: 'Sri Lanka', phone: '94', gmt: 'GMT' },
    { code: 'LR', label: 'Liberia', phone: '231', gmt: 'GMT+0000' },
    { code: 'LS', label: 'Lesotho', phone: '266', gmt: 'GMT+0200' },
    { code: 'LT', label: 'Lithuania', phone: '370', gmt: 'GMT+0200' },
    { code: 'LU', label: 'Luxembourg', phone: '352', gmt: 'GMT+0100' },
    { code: 'LV', label: 'Latvia', phone: '371', gmt: 'GMT+0200' },
    { code: 'LY', label: 'Libya', phone: '218', gmt: 'GMT+0200' },
    { code: 'MA', label: 'Morocco', phone: '212', gmt: 'GMT+0100' },
    { code: 'MC', label: 'Monaco', phone: '377', gmt: 'GMT+0100' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373', gmt: 'GMT+0200' },
    { code: 'ME', label: 'Montenegro', phone: '382', gmt: 'GMT+0100' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590', gmt: 'GMT-0400' },
    { code: 'MG', label: 'Madagascar', phone: '261', gmt: 'GMT+0300' },
    { code: 'MH', label: 'Marshall Islands', phone: '692', gmt: 'GMT+1200' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389', gmt: 'GMT' },
    { code: 'ML', label: 'Mali', phone: '223', gmt: 'GMT+0000' },
    { code: 'MM', label: 'Myanmar', phone: '95', gmt: 'GMT+0630' },
    { code: 'MN', label: 'Mongolia', phone: '976', gmt: 'GMT+0800' },
    { code: 'MO', label: 'Macao', phone: '853', gmt: 'GMT+0800' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670', gmt: 'GMT+1000' },
    { code: 'MQ', label: 'Martinique', phone: '596', gmt: 'GMT-0400' },
    { code: 'MR', label: 'Mauritania', phone: '222', gmt: 'GMT+0000' },
    { code: 'MS', label: 'Montserrat', phone: '1-664', gmt: 'GMT-0400' },
    { code: 'MT', label: 'Malta', phone: '356', gmt: 'GMT+0100' },
    { code: 'MU', label: 'Mauritius', phone: '230', gmt: 'GMT+0400' },
    { code: 'MV', label: 'Maldives', phone: '960', gmt: 'GMT+0500' },
    { code: 'MW', label: 'Malawi', phone: '265', gmt: 'GMT+0200' },
    { code: 'MX', label: 'Mexico', phone: '52', gmt: 'GMT-0700' },
    { code: 'MY', label: 'Malaysia', phone: '60', gmt: 'GMT+0800' },
    { code: 'MZ', label: 'Mozambique', phone: '258', gmt: 'GMT+0200' },
    { code: 'NA', label: 'Namibia', phone: '264', gmt: 'GMT+0200' },
    { code: 'NC', label: 'New Caledonia', phone: '687', gmt: 'GMT+1100' },
    { code: 'NE', label: 'Niger', phone: '227', gmt: 'GMT+0100' },
    { code: 'NF', label: 'Norfolk Island', phone: '672', gmt: 'GMT+1100' },
    { code: 'NG', label: 'Nigeria', phone: '234', gmt: 'GMT+0100' },
    { code: 'NI', label: 'Nicaragua', phone: '505', gmt: 'GMT-0600' },
    { code: 'NL', label: 'Netherlands', phone: '31', gmt: 'GMT+0100' },
    { code: 'NO', label: 'Norway', phone: '47', gmt: 'GMT+0100' },
    { code: 'NP', label: 'Nepal', phone: '977', gmt: 'GMT+0545' },
    { code: 'NR', label: 'Nauru', phone: '674', gmt: 'GMT+1200' },
    { code: 'NU', label: 'Niue', phone: '683', gmt: 'GMT-1100' },
    { code: 'NZ', label: 'New Zealand', phone: '64', gmt: 'GMT+1200' },
    { code: 'OM', label: 'Oman', phone: '968', gmt: 'GMT+0400' },
    { code: 'PA', label: 'Panama', phone: '507', gmt: 'GMT-0500' },
    { code: 'PE', label: 'Peru', phone: '51', gmt: 'GMT-0500' },
    { code: 'PF', label: 'French Polynesia', phone: '689', gmt: 'GMT-0930' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675', gmt: 'GMT+1000' },
    { code: 'PH', label: 'Philippines', phone: '63', gmt: 'GMT+0800' },
    { code: 'PK', label: 'Pakistan', phone: '92', gmt: 'GMT+0500' },
    { code: 'PL', label: 'Poland', phone: '48', gmt: 'GMT+0100' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508', gmt: 'GMT-0300' },
    { code: 'PN', label: 'Pitcairn', phone: '870', gmt: 'GMT-0800' },
    { code: 'PR', label: 'Puerto Rico', phone: '1', gmt: 'GMT-0400' },
    { code: 'PS', label: 'Palestine, State of', phone: '970', gmt: 'GMT+0200' },
    { code: 'PT', label: 'Portugal', phone: '351', gmt: 'GMT+0000' },
    { code: 'PW', label: 'Palau', phone: '680', gmt: 'GMT+0900' },
    { code: 'PY', label: 'Paraguay', phone: '595', gmt: 'GMT-0400' },
    { code: 'QA', label: 'Qatar', phone: '974', gmt: 'GMT+0300' },
    { code: 'RE', label: 'Reunion', phone: '262', gmt: 'GMT+0400' },
    { code: 'RO', label: 'Romania', phone: '40', gmt: 'GMT+0200' },
    { code: 'RS', label: 'Serbia', phone: '381', gmt: 'GMT+0100' },
    { code: 'RU', label: 'Russian Federation', phone: '7', gmt: 'GMT+0700' },
    { code: 'RW', label: 'Rwanda', phone: '250', gmt: 'GMT+0200' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966', gmt: 'GMT+0300' },
    { code: 'SB', label: 'Solomon Islands', phone: '677', gmt: 'GMT+1100' },
    { code: 'SC', label: 'Seychelles', phone: '248', gmt: 'GMT+0400' },
    { code: 'SD', label: 'Sudan', phone: '249', gmt: 'GMT+0200' },
    { code: 'SE', label: 'Sweden', phone: '46', gmt: 'GMT+0100' },
    { code: 'SG', label: 'Singapore', phone: '65', gmt: 'GMT+0800' },
    { code: 'SH', label: 'Saint Helena', phone: '290', gmt: 'GMT+0000' },
    { code: 'SI', label: 'Slovenia', phone: '386', gmt: 'GMT+0100' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47', gmt: 'GMT+0100' },
    { code: 'SK', label: 'Slovakia', phone: '421', gmt: 'GMT+0100' },
    { code: 'SL', label: 'Sierra Leone', phone: '232', gmt: 'GMT+0000' },
    { code: 'SM', label: 'San Marino', phone: '378', gmt: 'GMT+0100' },
    { code: 'SN', label: 'Senegal', phone: '221', gmt: 'GMT+0000' },
    { code: 'SO', label: 'Somalia', phone: '252', gmt: 'GMT+0300' },
    { code: 'SR', label: 'Suriname', phone: '597', gmt: 'GMT-0300' },
    { code: 'SS', label: 'South Sudan', phone: '211', gmt: 'GMT+0200' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239', gmt: 'GMT+0000' },
    { code: 'SV', label: 'El Salvador', phone: '503', gmt: 'GMT-0600' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721', gmt: 'GMT-0400' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963', gmt: 'GMT+0200' },
    { code: 'SZ', label: 'Switzerland', phone: '268', gmt: 'GMT+0100' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649', gmt: 'GMT-0400' },
    { code: 'TD', label: 'Chad', phone: '235', gmt: 'GMT+0100' },
    { code: 'TF', label: 'French Southern Territories', phone: '262', gmt: 'GMT-1000' },
    { code: 'TG', label: 'Togo', phone: '228', gmt: 'GMT+0000' },
    { code: 'TH', label: 'Thailand', phone: '66', gmt: 'GMT+0700' },
    { code: 'TJ', label: 'Tajikistan', phone: '992', gmt: 'GMT+0500' },
    { code: 'TK', label: 'Tokelau', phone: '690', gmt: 'GMT+1300' },
    { code: 'TL', label: 'Timor-Leste', phone: '670', gmt: 'GMT+0900' },
    { code: 'TM', label: 'Turkmenistan', phone: '993', gmt: 'GMT+0500' },
    { code: 'TN', label: 'Tunisia', phone: '216', gmt: 'GMT+0100' },
    { code: 'TO', label: 'Tonga', phone: '676', gmt: 'GMT+1300' },
    { code: 'TR', label: 'Turkey', phone: '90', gmt: 'GMT+0300' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868', gmt: 'GMT-0400' },
    { code: 'TV', label: 'Tuvalu', phone: '688', gmt: 'GMT+1200' },
    { code: 'TW', label: 'Taiwan, Province of China', phone: '886', gmt: 'GMT+0800' },
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '255', gmt: 'GMT+0300' },
    { code: 'UA', label: 'Ukraine', phone: '380', gmt: 'GMT+0200' },
    { code: 'UG', label: 'Uganda', phone: '256', gmt: 'GMT+0300' },
    { code: 'US', label: 'United States', phone: '1', gmt: 'GMT-0600', suggested: true },
    { code: 'UY', label: 'Uruguay', phone: '598', gmt: 'GMT-0300' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998', gmt: 'GMT+0500' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379', gmt: 'GMT+0100' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784', gmt: 'GMT-0400' },
    { code: 'VE', label: 'Venezuela', phone: '58', gmt: 'GMT-0400' },
    { code: 'VG', label: 'British Virgin Islands', phone: '1-284', gmt: 'GMT' },
    { code: 'VI', label: 'US Virgin Islands', phone: '1-340', gmt: 'GMT' },
    { code: 'VN', label: 'Vietnam', phone: '84', gmt: 'GMT+0700' },
    { code: 'VU', label: 'Vanuatu', phone: '678', gmt: 'GMT+1100' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681', gmt: 'GMT+1200' },
    { code: 'WS', label: 'Samoa', phone: '685', gmt: 'GMT+1300' },
    { code: 'XK', label: 'Kosovo', phone: '383', gmt: 'GMT+0100' },
    { code: 'YE', label: 'Yemen', phone: '967', gmt: 'GMT+0300' },
    { code: 'YT', label: 'Mayotte', phone: '262', gmt: 'GMT+0300' },
    { code: 'ZA', label: 'South Africa', phone: '27', gmt: 'GMT+0200' },
    { code: 'ZM', label: 'Zambia', phone: '260', gmt: 'GMT+0200' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263', gmt: 'GMT+0200' },
];
