/* eslint-disable react-hooks/exhaustive-deps */
import {
    Avatar,
    Button,
    Hidden,
    TextField,
    Grid,
    Checkbox,
    Chip,
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Drawer,
    Menu,
    Typography,
    Tooltip,
    Divider,
    // Paper,
    Popover,
} from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import useStyles from './StandupTableStyle';
// import { Autocomplete } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { getFullName } from 'helpers/getInitials';
import CloseIcon from '@material-ui/icons/Close';
import { fetchQuestion } from 'helpers/hasuraapi3';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import AuthContext from 'context/AuthContext';
import AddNotes from './AddNote';
import StandupNote from './StandupsNote';
import { useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import ErrorPage from 'components/Error/ErrorPage';
import { initialDate, ToStringFormat } from 'helpers/date';
import { updateActiveTab } from '../Redux/activeTab';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Icon } from '@iconify/react';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getCurrentCountryDateTime, isoToLocal, localToISO } from 'helpers/timezone';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';

interface Sidebar {
    open?: boolean;
}

export const Auto = (props: {
    open?: boolean;
    setOpen?: any;
    allEmployees?: any;
    name?: any;
    setName?: any;
    setSideBar?: any;
    button?: boolean;
}) => {
    const [profileMenu, setProfileMenu] = React.useState<any>(null);
    const classes = useStyles();
    // const theme = useTheme();
    const handleProfileMenuClose = () => {
        setProfileMenu(null);
    };

    //console.log(props.allEmployees);
    const handleChange = (event: any, value: any) => {
        //console.log('value', value);
        //console.log('index', props?.name?.indexOf(value));
        if (props.button) {
            const data = value;
            const index = props?.name?.indexOf(data);
            if (index > -1) {
                props?.setName([...props?.name?.slice(0, index), ...props?.name?.slice(index + 1)]);
            } else {
                props?.setName([...props?.name, ...[data]]);
            }
        } else {
            const data = value;
            const index = props?.name?.indexOf(data);
            if (index > -1) {
                props?.setName([...props?.name?.slice(0, index), ...props?.name?.slice(index + 1)]);
            } else {
                // props?.setName([...props?.name, ...[data]]);
                props?.setName((prev: any) => [...prev, ...[data]]);
            }
            props.setSideBar({ open: true });
        }
    };

    // // //console.log(val)
    return (
        <div>
            <Menu
                anchorEl={profileMenu}
                open={Boolean(profileMenu)}
                onClose={handleProfileMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={
                    props.button
                        ? {
                              vertical: 'top',
                              horizontal: 'left',
                          }
                        : {
                              vertical: 'top',
                              horizontal: 'right',
                          }
                }
                transformOrigin={
                    props.button
                        ? {
                              vertical: 'bottom',
                              horizontal: 'left',
                          }
                        : {
                              vertical: 'top',
                              horizontal: 'left',
                          }
                }
            >
                <ul style={{ width: 165 }} className={classes.filterList}>
                    {props?.allEmployees?.map((a: any, i: number) => (
                        <li
                            key={i}
                            className={classes.filterName2}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                            onClick={(e: any) => handleChange(e, a)}
                        >
                            <Avatar
                                src={a?.Employee?.profileImage}
                                style={{
                                    fontSize: '12px',
                                    color: 'black',
                                    background: '#EDEFFA',
                                    width: 26,
                                    height: 26,
                                    marginRight: 5,
                                }}
                            >
                                {getFullName(a?.Employee?.firstName + ' ' + a?.Employee?.lastName)}
                            </Avatar>
                            <span style={{ maxWidth: '100%', wordBreak: 'break-word' }}>
                                {a?.Employee?.firstName + ' ' + a?.Employee?.lastName}
                            </span>
                            <Checkbox
                                data-testid="check"
                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                                value={a}
                                // onChange={(e: any) => handleChange(e, a)}
                                checked={props?.name?.includes(a)}
                            />
                        </li>
                    ))}
                </ul>
            </Menu>
            <div data-testid="emp" className={classes.test} onClick={(e: any) => setProfileMenu(e.currentTarget)}>
                <div style={{ width: '100%', display: 'flex', paddingLeft: '2px' }}>
                    {/* {props?.name?.length === 0 ? ( */}
                    <div data-testid="abcde" style={{ paddingLeft: '7px' }}>
                        Employee
                    </div>
                    {/* ) : (
                        <div>
                            {props?.name?.map((e: any, i: number) =>
                                i < 1 ? (
                                    <Chip
                                        data-testid="chip"
                                        key={i}
                                        style={{ height: '25px' }}
                                        label={e?.Employee?.firstName}
                                    />
                                ) : null,
                            )}
                        </div>
                    )} */}
                </div>
                <div>{profileMenu === null ? <ArrowDropDown /> : <ArrowRightIcon />}</div>
            </div>
        </div>
    );
};

export const AutoTeams = (props: {
    open?: boolean;
    setOpen?: any;
    teams?: any;
    teamList?: any;
    setTeamList?: any;
    setSideBar?: any;
    button?: boolean;
}) => {
    const [profileMenu, setProfileMenu] = React.useState<any>(null);
    const classes = useStyles();
    // const theme = useTheme();
    const handleProfileMenuClose = () => {
        setProfileMenu(null);
    };

    // //console.log(props.teamList);
    const handleChange = (event: any, value: any) => {
        // //console.log(value);
        if (props.button) {
            const data = value;
            const index = props?.teamList?.indexOf(data);
            if (index > -1) {
                props?.setTeamList([...props?.teamList?.slice(0, index), ...props?.teamList?.slice(index + 1)]);
            } else {
                props?.setTeamList([...props?.teamList, ...[data]]);
            }
        } else {
            const data = value;
            const index = props?.teamList?.indexOf(data);
            if (index > -1) {
                props?.setTeamList([...props?.teamList?.slice(0, index), ...props?.teamList?.slice(index + 1)]);
            } else {
                // props?.setTeamList([...props?.teamList, ...[data]]);
                props?.setTeamList((prev: any) => [...prev, ...[data]]);
            }
            props?.setSideBar({ open: true });
        }
    };
    return (
        <div>
            <Menu
                anchorEl={profileMenu}
                open={Boolean(profileMenu)}
                onClose={handleProfileMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={
                    props.button
                        ? {
                              vertical: 'top',
                              horizontal: 'left',
                          }
                        : {
                              vertical: 'top',
                              horizontal: 'right',
                          }
                }
                transformOrigin={
                    props.button
                        ? {
                              vertical: 'bottom',
                              horizontal: 'left',
                          }
                        : {
                              vertical: 'top',
                              horizontal: 'left',
                          }
                }
            >
                <ul style={{ width: 165 }} className={classes.filterList}>
                    {props?.teams?.map((a: any, i: number) => (
                        <li
                            key={i}
                            className={classes.filterName2}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                            onClick={(e: any) => handleChange(e, a)}
                        >
                            <span style={{ maxWidth: '100%', wordBreak: 'break-word' }}>{a?.teamName}</span>
                            <Checkbox
                                data-testid="xyz"
                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                                value={a}
                                // onChange={(e: any) => handleChange(e, a)}
                                checked={props?.teamList?.includes(a)}
                            />
                        </li>
                    ))}
                </ul>
            </Menu>
            <div data-testid="teamid" className={classes.test} onClick={(e: any) => setProfileMenu(e.currentTarget)}>
                <div style={{ width: '100%', display: 'flex', paddingLeft: '2px' }}>
                    {/* {props?.teamList?.length === 0 ? ( */}
                    <div style={{ paddingLeft: '7px' }}>Teams</div>
                    {/* ) : (
                        <div>
                            {props?.teamList?.map((e: any, i: number) =>
                                i < 1 ? (
                                    <Chip data-testid="team" key={i} style={{ height: '25px' }} label={e?.teamName} />
                                ) : null,
                            )}
                        </div>
                    )} */}
                </div>
                <div>{profileMenu === null ? <ArrowDropDown /> : <ArrowRightIcon />}</div>
            </div>
        </div>
    );
};

export const AutoTags = (props: {
    open?: boolean;
    setOpen?: any;
    allTags?: any;
    tags?: any;
    setTags?: any;
    setSideBar?: any;
    button?: boolean;
}) => {
    const [profileMenu, setProfileMenu] = React.useState<any>(null);
    const handleProfileMenuClose = () => {
        setProfileMenu(null);
    };
    // const theme = useTheme();
    const classes = useStyles();

    // // //console.log(props.tags);

    const handleChange = (event: any, value: any) => {
        if (props.button) {
            const data = value;
            const index = props?.tags?.indexOf(data);
            if (index > -1) {
                props?.setTags([...props?.tags.slice(0, index), ...props?.tags.slice(index + 1)]);
            } else {
                props?.setTags([...props?.tags, ...[data]]);
            }
        } else {
            const data = value;
            const index = props?.tags?.indexOf(data);
            if (index > -1) {
                props?.setTags([...props?.tags?.slice(0, index), ...props?.tags.slice(index + 1)]);
            } else {
                // props?.setTags([...props?.tags, ...[data]]);
                props?.setTags((prev: any) => [...prev, ...[data]]);
            }
            props.setSideBar({ open: true });
        }
        // props.isMobile ? props.setSideBar({ open: false }) : props.setSideBar({ open: true });
    };
    return (
        <div>
            <Menu
                anchorEl={profileMenu}
                open={Boolean(profileMenu)}
                onClose={handleProfileMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={
                    props.button
                        ? {
                              vertical: 'top',
                              horizontal: 'left',
                          }
                        : {
                              vertical: 'top',
                              horizontal: 'right',
                          }
                }
                transformOrigin={
                    props.button
                        ? {
                              vertical: 'bottom',
                              horizontal: 'left',
                          }
                        : {
                              vertical: 'top',
                              horizontal: 'left',
                          }
                }
            >
                <div className={classes.filterList}>
                    <ul style={{ width: '165px' }}>
                        <>
                            {props?.allTags?.length === 0 ? (
                                <li
                                    className={classes.filterName2}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <span>no tag found</span>
                                </li>
                            ) : (
                                <>
                                    {props?.allTags?.map((a: any, i: number) => (
                                        <li
                                            key={i}
                                            className={classes.filterName2}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                            onClick={(e: any) => handleChange(e, a)}
                                        >
                                            <span>
                                                <Chip
                                                    style={{
                                                        height: '25px',
                                                        backgroundColor: `${a?.color}`,
                                                        color: 'white',
                                                    }}
                                                    label={a?.name}
                                                />
                                            </span>
                                            <Checkbox
                                                data-testid="test"
                                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                                                value={a}
                                                // onChange={(e: any) => handleChange(e, a)}
                                                checked={props?.tags?.includes(a)}
                                            />
                                        </li>
                                    ))}
                                </>
                            )}
                        </>
                    </ul>
                </div>
            </Menu>
            <div data-testid="data" className={classes.test} onClick={(e: any) => setProfileMenu(e.currentTarget)}>
                <div style={{ width: '100%', paddingLeft: '2px', display: 'flex' }}>
                    {/* {props?.tags?.length === 0 ? ( */}
                    <div style={{ paddingLeft: '7px' }}>Tags</div>
                    {/* ) : (
                        props?.tags?.map((e: any, i: number) =>
                            i < 1 ? (
                                <Chip
                                    data-testid="tags"
                                    key={i}
                                    style={{ height: '25px', backgroundColor: `${e?.color}`, color: 'white' }}
                                    label={e?.name}
                                />
                            ) : null,
                        )
                    )} */}
                </div>
                <div>{profileMenu === null ? <ArrowDropDown /> : <ArrowRightIcon />}</div>
            </div>
        </div>
    );
};

interface StandupTableProps {
    open?: any;
    setSidebar?: any;
    setIsMobile?: any;
    click?: boolean;
    setClick?: any;
    date?: string;
    setDate?: any;
    handleClear?: any;
    name?: any;
    setName?: any;
    allEmployee?: any;
    tags?: any;
    setTags?: any;
    allTags?: any;
    setStep?: any;
    setStandupd?: any;
    allStandups?: any;
    loading?: any;
    today?: any;
    setNameOpen?: any;
    nameOpen?: any;
    data?: any;
    textDate?: any;
    setTextDate?: any;
    timeoff?: any;
    teams?: any;
    teamList?: any;
    setTeamList?: any;
    handleModal?: any;
    // setModal?: any
    drawerOpen?: any;
    setMyData?: any;
    setModalDate?: any;
    setTime?: any;
    viewPage?: any;
    setviewPage?: any;
    tabValue?: any;
    settabValue?: any;
    time: any;
}

export function StandupTable(props: StandupTableProps) {
    const classes = useStyles();
    const history: any = useHistory();
    const [profileMenu, setProfileMenu] = React.useState<any>(null);
    const [teamMember, setTeamMember] = React.useState<any>([]);
    const [filterClick, setFilterClick] = React.useState(false);
    const [filterIcon, setFilterIcon] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    // const [seeBy, setSeeBy] = React.useState('This Week');
    const [page, setPage] = React.useState(0);
    const [anchorEl2, setAnchorEl2] = React.useState<any>(null);
    const [standupObject, setStandupObject] = React.useState<any>({});
    const theme = useTheme();
    const mycontext = useContext(AuthContext);
    const end = Object.keys(standupObject).length > page * 7 + 7 ? page * 7 + 7 : Object.keys(standupObject).length;

    const nextPage = (e: any) => {
        setPage(page + 1);
    };
    const prevPage = (e: any) => {
        setPage(page - 1);
    };
    const handleProfileMenuClose = () => {
        setProfileMenu(null);
        setTeamMember([]);
    };
    const handleProfileMenuClick = (event: any, standupObject: any) => {
        setProfileMenu(event?.currentTarget);
        setTeamMember(Object?.values(standupObject)?.map((l: any) => l));
    };
    const timeSet = (e: any) => {
        if (e === 'All') {
            const standupObject2 = props.allStandups.reduce((acc: any, obj: any) => {
                const key = obj['standupNoteDateTime'];
                if (
                    !acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ]
                ) {
                    acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ] = [];
                }
                acc[
                    isoToLocal(key, mycontext.country)
                        .toString()
                        .substring(0, 15)
                ].push(obj);
                return acc;
            }, {});
            setStandupObject(standupObject2);
        }
        if (e === 'This Week') {
            const standupObject2 = props.allStandups.reduce((acc: any, obj: any) => {
                const key = obj['standupNoteDateTime'];
                if (
                    !acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ]
                ) {
                    acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ] = [];
                }
                acc[
                    isoToLocal(key, mycontext.country)
                        .toString()
                        .substring(0, 15)
                ].push(obj);
                return acc;
            }, {});
            setStandupObject(standupObject2);
            // setStandupObject(filterData);
        }
        if (e === 'Previous Week') {
            const standupObject2 = props.allStandups.reduce((acc: any, obj: any) => {
                const key = obj['standupNoteDateTime'];
                if (
                    !acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ]
                ) {
                    acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ] = [];
                }
                acc[
                    isoToLocal(key, mycontext.country)
                        .toString()
                        .substring(0, 15)
                ].push(obj);
                return acc;
            }, {});
            setStandupObject(standupObject2);
        }
        if (e === 'This Month') {
            const standupObject2 = props.allStandups.reduce((acc: any, obj: any) => {
                const key = obj['standupNoteDateTime'];
                if (
                    !acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ]
                ) {
                    acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ] = [];
                }
                acc[
                    isoToLocal(key, mycontext.country)
                        .toString()
                        .substring(0, 15)
                ].push(obj);
                return acc;
            }, {});
            setStandupObject(standupObject2);
            // setPage(0);
            // setFilterClick(false);
        }
        if (e === 'Previous Month') {
            const standupObject2 = props.allStandups.reduce((acc: any, obj: any) => {
                const key = obj['standupNoteDateTime'];
                if (
                    !acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ]
                ) {
                    acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ] = [];
                }
                acc[
                    isoToLocal(key, mycontext.country)
                        .toString()
                        .substring(0, 15)
                ].push(obj);
                return acc;
            }, {});
            setStandupObject(standupObject2);
        }
        if (e === 'This Quarter') {
            const standupObject2 = props.allStandups.reduce((acc: any, obj: any) => {
                const key = obj['standupNoteDateTime'];
                if (
                    !acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ]
                ) {
                    acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ] = [];
                }
                acc[
                    isoToLocal(key, mycontext.country)
                        .toString()
                        .substring(0, 15)
                ].push(obj);
                return acc;
            }, {});
            setStandupObject(standupObject2);
        }
        if (e === 'Previous Quarter') {
            const standupObject2 = props.allStandups.reduce((acc: any, obj: any) => {
                const key = obj['standupNoteDateTime'];
                if (
                    !acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ]
                ) {
                    acc[
                        isoToLocal(key, mycontext.country)
                            .toString()
                            .substring(0, 15)
                    ] = [];
                }
                acc[
                    isoToLocal(key, mycontext.country)
                        .toString()
                        .substring(0, 15)
                ].push(obj);
                return acc;
            }, {});
            setStandupObject(standupObject2);
        }
        setFilterClick(false);
        setPage(0);
    };

    React.useEffect(() => {
        const standupObject2 = props.allStandups.reduce((acc: any, obj: any) => {
            const key = obj['standupNoteDateTime'];
            if (
                !acc[
                    isoToLocal(key, mycontext.country)
                        .toString()
                        .substring(0, 15)
                ]
            ) {
                acc[
                    isoToLocal(key, mycontext.country)
                        .toString()
                        .substring(0, 15)
                ] = [];
            }
            acc[
                isoToLocal(key, mycontext.country)
                    .toString()
                    .substring(0, 15)
            ].push(obj);
            return acc;
        }, {});
        setStandupObject(standupObject2);
    }, [props.allStandups]);

    // const filterChecking = (arr: any, id: any) => {
    //     for (let i = 0; i < arr?.length; i++) {
    //         if (arr[i]?.employeeId === id) {
    //             return false;
    //         }
    //     }
    //     return true;
    // };

    // React.useEffect(() => {
    //     //console.log(props?.allEmployee)
    //     const standupDate = props?.allStandups?.map((e: any) =>
    //         isoToLocal(e?.standupNoteDateTime, mycontext.country)
    //             .toString()
    //             .substring(0, 15),
    //     );
    //     const obj: any = {};
    //     for (let i = 0; i < standupDate?.length; i++) {
    //         obj[standupDate[i]] = props.allEmployee?.map((e: any) => e);
    //     }
    //     //console.log("hello world1", standupDate)
    //     setPresentEmployee(obj);
    // }, [props.allEmployee, props.allStandups]);

    // //console.log(props?.timeoff?.orgleaves)

    const handleClick = (e: any) => {
        props?.setTextDate(false);
        setFilterIcon(!filterIcon);
        setAnchorEl2(e.currentTarget);
    };
    const handleClick2 = (e: any) => {
        setFilterClick(!filterClick);
        setAnchorEl(e.currentTarget);
    };
    const handleProfileClick = (userId: string) => {
        if (userId === mycontext.EmployeeId) {
            history.push({
                pathname: '/user/profile',
                state: {
                    id: userId,
                    userLink: false,
                    backTo: history.location.pathname.slice(1),
                },
            });
        } else {
            history.push({
                pathname: `/employees/${userId}`,
                state: {
                    id: userId,
                    userLink: false,
                    backTo: history.location.pathname.slice(1),
                },
            });
        }
    };

    // const end: any = Object.keys(standupObject).length > start + 7 ? start + 7 : Object.keys(standupObject).length;

    const handleFilterby = (dateTime: any, obj: any, date: any) => {
        // //console.log(dateTime, obj, date);
        props.setModalDate(date);
        props?.setMyData(obj);
        props?.setStandupd(dateTime);
        props.setStep(1);
    };
    useEffect(() => {
        if (!mycontext.isAdmin) {
            props.settabValue('');
        }
    }, []);
    return (
        <>
            <Menu
                anchorEl={profileMenu}
                open={Boolean(profileMenu)}
                onClose={handleProfileMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={classes.scroll1}>
                    {teamMember?.map((e: any, i: number) => (
                        <a
                            className={classes.link}
                            href={`#${e?.employeeId}`}
                            style={e?.standupNoteId === undefined ? { cursor: 'context-menu' } : undefined}
                            key={i}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: '3px 0px',
                                }}
                                onClick={() => {
                                    e?.standupNoteId !== undefined &&
                                        handleFilterby(e?.standupNoteDate, teamMember, props.today);
                                }}
                            >
                                <Avatar
                                    src={e?.Employee?.profileImage}
                                    style={{
                                        height: '28px',
                                        width: '30px',
                                        fontSize: '13px',
                                        background: '#EDEFFA',
                                        color: 'black',
                                    }}
                                >
                                    {getFullName(e?.Employee?.firstName + ' ' + e?.Employee?.lastName)}
                                </Avatar>
                                &nbsp;
                                <Typography>{e?.Employee?.firstName + ' ' + e?.Employee?.lastName}</Typography>
                            </div>
                        </a>
                    ))}
                </div>
            </Menu>
            <Popover
                id="picker-popover"
                open={filterClick}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleClick2}
                data-testid="filterModal"
            >
                <ul className={classes.filterList}>
                    <li
                        onClick={() => {
                            timeSet('All');
                            props.setTime({
                                endDate: localToISO(
                                    getCurrentCountryDateTime(mycontext.country.zoneName).date,
                                    '23:59:00',
                                    mycontext.country,
                                ),
                                startDate: '2022-07-01T00:00:00+00:00',
                                title: 'All',
                            });
                        }}
                        className={classes.filterName}
                    >
                        All
                    </li>
                    <li
                        onClick={() => {
                            timeSet('This Week');
                            props.setTime({
                                endDate: localToISO(
                                    getCurrentCountryDateTime(mycontext.country.zoneName).date,
                                    '23:59:00',
                                    mycontext.country,
                                ),
                                startDate: initialDate('week', 0, mycontext.country.zoneName),
                                title: 'This Week',
                            });
                        }}
                        className={classes.filterName}
                    >
                        This Week
                    </li>
                    <li
                        onClick={() => {
                            timeSet('Previous Week');
                            props.setTime({
                                endDate: initialDate('week', 0, mycontext.country.zoneName),
                                startDate: initialDate('week', 1, mycontext.country.zoneName),
                                title: 'Previous Week',
                            });
                        }}
                        className={classes.filterName}
                    >
                        Previous Week
                    </li>
                    <li
                        onClick={() => {
                            timeSet('This Month');
                            props.setTime({
                                endDate: localToISO(
                                    getCurrentCountryDateTime(mycontext.country.zoneName).date,
                                    '23:59:00',
                                    mycontext.country,
                                ),
                                startDate: initialDate('month', 0, mycontext.country.zoneName),
                                title: 'This Month',
                            });
                        }}
                        className={classes.filterName}
                    >
                        This Month
                    </li>

                    <li
                        onClick={() => {
                            timeSet('Previous Month');
                            props.setTime({
                                endDate: initialDate('month', 0, mycontext.country.zoneName),
                                startDate: initialDate('month', 1, mycontext.country.zoneName),
                                title: 'Previous Month',
                            });
                        }}
                        className={classes.filterName}
                    >
                        Previous Month
                    </li>
                    <li
                        onClick={() => {
                            timeSet('This Quarter');
                            props.setTime({
                                endDate: localToISO(
                                    getCurrentCountryDateTime(mycontext.country.zoneName).date,
                                    '23:59:00',
                                    mycontext.country,
                                ),
                                startDate: initialDate('quarter', 0, mycontext.country.zoneName),
                                title: 'This Quarter',
                            });
                        }}
                        className={classes.filterName}
                    >
                        This Quarter
                    </li>
                    <li
                        onClick={() => {
                            timeSet('Previous Quarter');
                            props.setTime({
                                endDate: initialDate('quarter', 0, mycontext.country.zoneName),
                                startDate: initialDate('quarter', 1, mycontext.country.zoneName),
                                title: 'Previous Quarter',
                            });
                        }}
                        className={classes.filterName}
                    >
                        Previous Quarter
                    </li>
                </ul>
            </Popover>
            <Hidden mdDown>
                <Popover
                    id="picker-popover"
                    open={filterIcon}
                    anchorEl={anchorEl2}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleClick}
                    data-testid="filterModal"
                >
                    <ul className={classes.filterList2}>
                        {(props.date !== '' ||
                            props?.name?.length !== 0 ||
                            props?.tags?.length !== 0 ||
                            props?.teamList?.length !== 0 ||
                            props.textDate === true) && (
                            <div
                                data-testid="clearAll"
                                style={{ marginLeft: '110px', cursor: 'pointer' }}
                                onClick={props?.handleClear}
                                // style={{ width: '100%' }}
                                className={classes.clear}
                            >
                                Clear all
                            </div>
                        )}
                        <li className={classes.filterName}>
                            <AutoTeams
                                setSideBar={props.setSidebar}
                                teamList={props?.teamList}
                                setTeamList={props?.setTeamList}
                                open={props?.nameOpen}
                                setOpen={props?.setNameOpen}
                                teams={props?.teams}
                                button={false}
                            />
                            {/* <ArrowDropDown style={{ display: 'flex', float: "right", marginLeft: "78px" }} /> */}
                        </li>
                        <li className={classes.filterName}>
                            <Auto
                                setSideBar={props.setSidebar}
                                name={props?.name}
                                setName={props?.setName}
                                open={props.nameOpen}
                                setOpen={props.setNameOpen}
                                allEmployees={props?.allEmployee}
                                button={false}
                            />
                        </li>
                        <li
                            // onClick={handleMonth}
                            className={classes.filterDate}
                        >
                            {props.textDate === false ? (
                                <div
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                    onClick={() => props.setTextDate(!props.textDate)}
                                >
                                    <div style={{ marginLeft: '10px' }}>Date</div>
                                    <ArrowDropDown style={{ display: 'flex', float: 'right', marginLeft: '88px' }} />
                                </div>
                            ) : (
                                <TextField
                                    type="date"
                                    data-testid="date"
                                    value={props?.date}
                                    onChange={(e: any) => {
                                        props.setDate(e.target.value);
                                        props.setSidebar({ open: true });
                                    }}
                                    size="small"
                                    area-placeholder="Date"
                                    variant="outlined"
                                    className={classes.inputTextField}
                                />
                            )}
                        </li>
                        <li className={classes.filterName}>
                            <AutoTags
                                tags={props?.tags}
                                allTags={props?.allTags}
                                open={props.nameOpen}
                                setOpen={props.setNameOpen}
                                setTags={props?.setTags}
                                button={false}
                                setSideBar={props.setSidebar}
                            />
                        </li>
                    </ul>
                </Popover>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                        <div style={mycontext.isAdmin ? { display: 'flex' } : { display: 'flex', width: '50%' }}>
                            {mycontext.isAdmin ? (
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 35,
                                    }}
                                >
                                    {' '}
                                    <ArrowBackIcon onClick={() => props.setviewPage(0)} className={classes.backArrow} />
                                    <p className={classes.standup}>Dashboard</p>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <Button
                                data-testid="abcd"
                                style={
                                    filterIcon === false
                                        ? { color: '#AAAAAA', border: `1px solid #AAAAAA` }
                                        : {
                                              border: `1px solid ${theme.palette.text.secondary}`,
                                              color: theme.palette.text.secondary,
                                          }
                                }
                                className={classes.btn}
                                onClick={handleClick}
                                variant="outlined"
                                disableElevation
                                aria-describedby="filter-popup"
                                startIcon={
                                    <Icon
                                        className={props.open ? classes.filterIcon : undefined}
                                        icon="bx:filter-alt"
                                        data-width="20"
                                        data-height="20"
                                    />
                                }
                                endIcon={<ArrowDropDown style={{ display: 'flex' }} />}
                            >
                                Filter By
                            </Button>
                        </div>

                        <div
                            style={
                                !mycontext.isAdmin
                                    ? { display: 'flex', justifyContent: 'flex-end', width: '50%' }
                                    : { display: 'flex', justifyContent: 'space-between' }
                            }
                        >
                            {!mycontext.isAdmin ? (
                                <Button variant="outlined" className={classes.minus} onClick={props.handleModal}>
                                    Add Note
                                </Button>
                            ) : (
                                <div></div>
                            )}
                            <Button
                                data-testid="seeby"
                                onClick={handleClick2}
                                variant="outlined"
                                disableElevation
                                className={classes.btn}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>{props.time?.title}</span>
                                    <ArrowDropDown />
                                </div>
                            </Button>
                        </div>
                    </div>
                    <TableContainer component="div" className={classes.tableBorder}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.cell} align="left">
                                        #
                                    </TableCell>
                                    <TableCell className={classes.cell} align="left">
                                        Date
                                    </TableCell>
                                    {props.tabValue === '' || props.tabValue === 'Standupnotes' ? (
                                        <TableCell className={classes.cell} align="left">
                                            Standup Notes
                                        </TableCell>
                                    ) : null}
                                    {props.tabValue === '' || props.tabValue === 'Goal not achieved' ? (
                                        <TableCell className={classes.cell} align="left">
                                            Goal Not Achieved
                                        </TableCell>
                                    ) : null}
                                    {/* {props.tabValue === '' ? !props.open &&
                                        <TableCell className={classes.cell} align="left">
                                            Remaining Standups
                                        </TableCell> : null
                                    } */}
                                </TableRow>
                            </TableHead>
                            {props.loading ? (
                                <TableBody>
                                    {[1, 2, 3, 4, 5, 6]?.map((i: number) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <Skeleton height={20} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton height={20} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton height={20} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton height={20} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton height={20} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <>
                                    {props?.allStandups?.length === 0 || Object.keys(standupObject).length === 0 ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    id="noResultFound"
                                                    colSpan={6}
                                                    className={classes.fontsty}
                                                    style={{ borderBottom: 'none' }}
                                                >
                                                    <img
                                                        src={upcomingTimeoff}
                                                        alt="No Teams found"
                                                        className={classes.notfoundImage}
                                                    />
                                                    <div className={classes.notfoundText}>No standup found</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {Object.keys(standupObject)
                                                ?.slice(page * 7, page * 7 + 7)
                                                ?.map((v: any, i: number) => (
                                                    <TableRow
                                                        key={i}
                                                        className={classes.shadow}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleFilterby(v, standupObject[v], props.today)}
                                                    >
                                                        <TableCell
                                                            className={classes.cell1}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {1 + page * 7 + i}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.cell1}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {v ===
                                                            getCurrentCountryDateTime(mycontext.country.zoneName)
                                                                .dateTime.toString()
                                                                .substring(0, 15)
                                                                ? 'Today'
                                                                : `${ToStringFormat(v, mycontext.dateFormat)}`}
                                                        </TableCell>
                                                        {props.tabValue === '' || props.tabValue === 'Standupnotes' ? (
                                                            <TableCell className={classes.cell1}>
                                                                <Grid
                                                                    container
                                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                                >
                                                                    {standupObject[v]
                                                                        ?.slice(0, 3)
                                                                        ?.map((e: any, key: any) => (
                                                                            <div key={key}>
                                                                                <Tooltip
                                                                                    title={
                                                                                        e?.Employee?.firstName +
                                                                                        ' ' +
                                                                                        e?.Employee?.lastName
                                                                                    }
                                                                                    placement="top"
                                                                                    classes={{
                                                                                        tooltip: classes.tooltip,
                                                                                    }}
                                                                                    arrow
                                                                                >
                                                                                    <Avatar
                                                                                        className={classes.avatar}
                                                                                        src={e?.Employee?.profileImage}
                                                                                        style={
                                                                                            key !== 0
                                                                                                ? {
                                                                                                      left: `-${key *
                                                                                                          8}px`,
                                                                                                  }
                                                                                                : {
                                                                                                      left: '0px',
                                                                                                  }
                                                                                        }
                                                                                        onClick={() => {
                                                                                            handleProfileClick(
                                                                                                e.employeeId,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {getFullName(
                                                                                            e?.Employee?.firstName +
                                                                                                ' ' +
                                                                                                e?.Employee?.lastName,
                                                                                        )}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            </div>
                                                                        ))}
                                                                    {standupObject[v]?.length > 3 && (
                                                                        <Tooltip
                                                                            title={`Total ${standupObject[v]?.length}`}
                                                                            placement="top"
                                                                            classes={{ tooltip: classes.tooltip }}
                                                                            arrow
                                                                        >
                                                                            <Avatar
                                                                                style={{
                                                                                    left: -10,
                                                                                    width: 20,
                                                                                    height: 15,
                                                                                    fontSize: 12,
                                                                                    background: '#fff',
                                                                                    color: '#444444',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                +{standupObject[v]?.length - 3}
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    )}
                                                                </Grid>
                                                            </TableCell>
                                                        ) : null}

                                                        {props.tabValue === '' ||
                                                        props.tabValue === 'Goal not achieved' ? (
                                                            <TableCell className={classes.cell1}>
                                                                <>
                                                                    {standupObject[v]?.filter(
                                                                        (a: any) => a?.isAccomplished === false,
                                                                    )?.length === 0 ? (
                                                                        <Icon icon="bi:dash" width={25} height={25} />
                                                                    ) : (
                                                                        <Grid
                                                                            container
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            {standupObject[v]
                                                                                ?.filter(
                                                                                    (a: any) =>
                                                                                        a?.isAccomplished === false,
                                                                                )
                                                                                ?.slice(0, 3)
                                                                                ?.map((e: any, key: any) => (
                                                                                    <div key={key}>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                e?.Employee?.firstName +
                                                                                                ' ' +
                                                                                                e?.Employee?.lastName
                                                                                            }
                                                                                            placement="top"
                                                                                            classes={{
                                                                                                tooltip:
                                                                                                    classes.tooltip,
                                                                                            }}
                                                                                            arrow
                                                                                        >
                                                                                            <Avatar
                                                                                                className={
                                                                                                    classes.avatar
                                                                                                }
                                                                                                src={
                                                                                                    e?.Employee
                                                                                                        ?.profileImage
                                                                                                }
                                                                                                style={
                                                                                                    key !== 0
                                                                                                        ? {
                                                                                                              left: `-${key *
                                                                                                                  8}px`,
                                                                                                          }
                                                                                                        : {
                                                                                                              left:
                                                                                                                  '0px',
                                                                                                          }
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    handleProfileClick(
                                                                                                        e.employeeId,
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {getFullName(
                                                                                                    e?.Employee
                                                                                                        ?.firstName +
                                                                                                        ' ' +
                                                                                                        e?.Employee
                                                                                                            ?.lastName,
                                                                                                )}
                                                                                            </Avatar>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                ))}
                                                                            {standupObject[v]?.filter(
                                                                                (a: any) => a?.isAccomplished === false,
                                                                            ).length > 3 && (
                                                                                <Tooltip
                                                                                    title={`Total ${
                                                                                        standupObject[v]?.filter(
                                                                                            (e: any) =>
                                                                                                e.isAccomplished ===
                                                                                                false,
                                                                                        )?.length
                                                                                    }`}
                                                                                    placement="top"
                                                                                    classes={{
                                                                                        tooltip: classes.tooltip,
                                                                                    }}
                                                                                >
                                                                                    <Avatar
                                                                                        style={{
                                                                                            left: -10,
                                                                                            width: 20,
                                                                                            height: 15,
                                                                                            fontSize: 12,
                                                                                            background: '#fff',
                                                                                            color: '#444444',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        onClick={(e: any) =>
                                                                                            handleProfileMenuClick(
                                                                                                e,
                                                                                                standupObject[
                                                                                                    v
                                                                                                ]?.filter(
                                                                                                    (e: any) =>
                                                                                                        e.isAccomplished ===
                                                                                                        false,
                                                                                                ),
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        +
                                                                                        {standupObject[v]?.filter(
                                                                                            (e: any) =>
                                                                                                e.isAccomplished ===
                                                                                                false,
                                                                                        )?.length - 3}
                                                                                    </Avatar>
                                                                                </Tooltip>
                                                                            )}
                                                                        </Grid>
                                                                    )}
                                                                </>
                                                            </TableCell>
                                                        ) : null}
                                                        {/* {props.tabValue === '' ? !props.open &&
                                                            <TableCell className={classes.cell1}>
                                                                <>
                                                                    {presentEmployee[v]?.filter((a: any) =>
                                                                        filterChecking(standupObject[v], a?.employeeId),
                                                                    )?.length === 0 ? (
                                                                        <Icon icon="bi:dash" width={25} height={25} />
                                                                    ) : (
                                                                        <Grid
                                                                            container
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            {presentEmployee[v]
                                                                                ?.filter((a: any) =>
                                                                                    filterChecking(
                                                                                        standupObject[v],
                                                                                        a?.employeeId,
                                                                                    ),
                                                                                )
                                                                                ?.slice(0, 3)
                                                                                ?.map((e: any, key: any) => (
                                                                                    <div key={key}>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                e?.Employee?.firstName +
                                                                                                ' ' +
                                                                                                e?.Employee?.lastName
                                                                                            }
                                                                                            placement="top"
                                                                                            classes={{
                                                                                                tooltip:
                                                                                                    classes.tooltip,
                                                                                            }}
                                                                                            arrow
                                                                                        >
                                                                                            <Avatar
                                                                                                className={
                                                                                                    classes.avatar
                                                                                                }
                                                                                                src={
                                                                                                    e?.Employee
                                                                                                        ?.profileImage
                                                                                                }
                                                                                                style={
                                                                                                    key !== 0
                                                                                                        ? {
                                                                                                            left: `-${key *
                                                                                                                8}px`,
                                                                                                        }
                                                                                                        : {
                                                                                                            left:
                                                                                                                '0px',
                                                                                                        }
                                                                                                }
                                                                                            >
                                                                                                {getFullName(
                                                                                                    e?.Employee
                                                                                                        ?.firstName +
                                                                                                    ' ' +
                                                                                                    e?.Employee
                                                                                                        ?.lastName,
                                                                                                )}
                                                                                            </Avatar>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                ))}
                                                                            {presentEmployee[v]?.filter((a: any) =>
                                                                                filterChecking(
                                                                                    standupObject[v],
                                                                                    a?.employeeId,
                                                                                ),
                                                                            ).length > 3 && (
                                                                                    <Tooltip
                                                                                        title={`Total ${presentEmployee[
                                                                                            v
                                                                                        ]?.filter((a: any) =>
                                                                                            filterChecking(
                                                                                                standupObject[v],
                                                                                                a?.employeeId,
                                                                                            ),
                                                                                        ).length
                                                                                            }`}
                                                                                        placement="top"
                                                                                        classes={{
                                                                                            tooltip: classes.tooltip,
                                                                                        }}
                                                                                        arrow
                                                                                    >
                                                                                        <Avatar
                                                                                            style={{
                                                                                                left: -10,
                                                                                                width: 20,
                                                                                                height: 15,
                                                                                                fontSize: 12,
                                                                                                background: '#fff',
                                                                                                color: '#444444',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                            onClick={(e: any) =>
                                                                                                handleProfileMenuClick(
                                                                                                    e,
                                                                                                    presentEmployee[
                                                                                                        v
                                                                                                    ]?.filter((a: any) =>
                                                                                                        filterChecking(
                                                                                                            standupObject[
                                                                                                            v
                                                                                                            ],
                                                                                                            a?.employeeId,
                                                                                                        ),
                                                                                                    ),
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            +
                                                                                            {presentEmployee[
                                                                                                v
                                                                                            ]?.filter((a: any) =>
                                                                                                filterChecking(
                                                                                                    standupObject[v],
                                                                                                    a?.employeeId,
                                                                                                ),
                                                                                            ).length - 3}
                                                                                        </Avatar>
                                                                                    </Tooltip>
                                                                                )}
                                                                        </Grid>
                                                                    )}
                                                                </>
                                                            </TableCell> : null
                                                        } */}
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    )}
                                </>
                            )}
                        </Table>
                    </TableContainer>
                    {Object.keys(standupObject).length > 7 ? (
                        <div className={props.open ? classes.pagination1 : classes.pagination2}>
                            <span>{`${Math.ceil(page * 7 + 1)}-${Math.ceil(end)} of ${
                                Object.keys(standupObject).length
                            }`}</span>
                            {page === 0 ? (
                                <Button style={{ outline: 'none', color: '#cccccc' }}>
                                    <ArrowBackIos />
                                </Button>
                            ) : (
                                <Button style={{ outline: 'none' }} onClick={prevPage}>
                                    <ArrowBackIos />{' '}
                                </Button>
                            )}
                            {end === Object.keys(standupObject).length ? (
                                <Button style={{ outline: 'none', color: '#cccccc' }}>
                                    <ArrowForwardIos />
                                </Button>
                            ) : (
                                <Button style={{ outline: 'none' }} onClick={nextPage}>
                                    <ArrowForwardIos />
                                </Button>
                            )}
                        </div>
                    ) : null}
                </div>
            </Hidden>
            <Hidden lgUp>
                <div data-testid="abc" style={{ paddingBottom: 30 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <div>
                            {' '}
                            {mycontext.isAdmin ? (
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 35,
                                    }}
                                >
                                    {' '}
                                    <ArrowBackIcon onClick={() => props.setviewPage(0)} className={classes.backArrow} />
                                    <p className={classes.standup}></p>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                        <Button
                            className={classes.btn}
                            style={{ width: 160, height: 40 }}
                            onClick={handleClick2}
                            // variant="outlined"
                            disableElevation
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    alignItems: 'center',
                                }}
                            >
                                <span>{props.time?.title}</span>
                                <ArrowDropDown />
                            </div>
                        </Button>
                        {!mycontext?.isAdmin && (
                            <Button
                                className={classes.filter}
                                variant="outlined"
                                disableElevation
                                aria-describedby="filter-popup"
                                startIcon={
                                    <span
                                        className="iconify"
                                        data-icon="bx:bx-filter-alt"
                                        data-width="20"
                                        data-height="20"
                                    ></span>
                                }
                                onClick={props.drawerOpen}
                            >
                                Filter By
                            </Button>
                        )}
                    </div>

                    {props?.loading ? (
                        <div style={{ paddingTop: '10px' }}>
                            <Card style={{ height: '135px' }} elevation={0}></Card>
                        </div>
                    ) : (
                        <>
                            {props.allStandups?.length === 0 || Object.keys(standupObject).length === 0 ? (
                                <div id="noResultFound" className={classes.fontsty} style={{ borderBottom: 'none' }}>
                                    <img
                                        src={upcomingTimeoff}
                                        alt="No standups found"
                                        className={classes.notfoundImage}
                                    />
                                    <div className={classes.notfoundText}>No standup found</div>
                                </div>
                            ) : (
                                <>
                                    {Object?.keys(standupObject)
                                        ?.slice(page * 7, page * 7 + 7)
                                        ?.map((v: any, i: number) => (
                                            <div key={i} style={{ paddingTop: '10px' }}>
                                                <Card
                                                    style={{ height: '135px' }}
                                                    elevation={0}
                                                    onClick={() => handleFilterby(v, standupObject[v], props.today)}
                                                >
                                                    <CardContent>
                                                        <div className={classes.text2}>
                                                            {v ===
                                                            getCurrentCountryDateTime(mycontext.country.zoneName)
                                                                .dateTime.toString()
                                                                .substring(0, 15)
                                                                ? 'Today'
                                                                : `${ToStringFormat(v, mycontext.dateFormat)}`}
                                                        </div>
                                                    </CardContent>
                                                    <div
                                                        // onClick={() => handleFilterby(v)}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            paddingLeft: '20px',
                                                        }}
                                                    >
                                                        {standupObject[v]?.slice(0, 5)?.map((e: any, key: any) => (
                                                            <Tooltip
                                                                key={key}
                                                                title={
                                                                    e?.Employee?.firstName + ' ' + e?.Employee?.lastName
                                                                }
                                                                placement="top"
                                                                classes={{ tooltip: classes.tooltip }}
                                                                arrow
                                                            >
                                                                <Avatar
                                                                    src={e?.Employee?.profileImage}
                                                                    className={classes.avatar}
                                                                    style={
                                                                        key !== 0
                                                                            ? {
                                                                                  left: `-${key * 8}px`,
                                                                              }
                                                                            : {
                                                                                  left: '0px',
                                                                              }
                                                                    }
                                                                    onClick={() => {
                                                                        handleProfileClick(e.employeeId);
                                                                    }}
                                                                >
                                                                    {getFullName(
                                                                        e?.Employee?.firstName +
                                                                            ' ' +
                                                                            e?.Employee?.lastName,
                                                                    )}
                                                                </Avatar>
                                                            </Tooltip>
                                                        ))}
                                                        {standupObject[v]?.length > 5 && (
                                                            <Button
                                                                style={{ marginLeft: -30 }}
                                                                className={classes.avatarButton}
                                                                onClick={(e: any) =>
                                                                    handleProfileMenuClick(e, standupObject[v])
                                                                }
                                                            >
                                                                + {standupObject[v]?.length - 5} More
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Card>
                                            </div>
                                        ))}
                                </>
                            )}
                        </>
                    )}
                    {Object.keys(standupObject).length > 7 ? (
                        <div className={props.open ? classes.pagination1 : classes.pagination2}>
                            <span>{`${Math.ceil(page * 7 + 1)}-${Math.ceil(end)} of ${
                                Object.keys(standupObject).length
                            }`}</span>
                            {page === 0 ? (
                                <Button style={{ outline: 'none', color: '#cccccc' }}>
                                    <ArrowBackIos />
                                </Button>
                            ) : (
                                <Button style={{ outline: 'none' }} onClick={prevPage}>
                                    <ArrowBackIos />{' '}
                                </Button>
                            )}
                            {end === Object.keys(standupObject).length ? (
                                <Button style={{ outline: 'none', color: '#cccccc' }}>
                                    <ArrowForwardIos />
                                </Button>
                            ) : (
                                <Button style={{ outline: 'none' }} onClick={nextPage}>
                                    <ArrowForwardIos />
                                </Button>
                            )}
                        </div>
                    ) : null}
                </div>
            </Hidden>
        </>
    );
}

interface MobileDrawerProps {
    openDrawer?: any;
    drawerClose?: any;
    allEmployee?: any;
    name?: any;
    setName?: any;
    setSidebar?: any;
    setDate?: any;
    date?: string;
    setIsMobile?: any;
    tags?: any;
    setTags?: any;
    allTags?: any;
    setDrawer?: any;
    setNameOpen?: any;
    nameOpen?: any;
    isMobile?: any;
    teams?: any;
    teamList?: any;
    setTeamList?: any;
}

export const MobileDrawer = (props: MobileDrawerProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const cancel = () => {
        props.setDate('');
        props.setTags([]);
        props.setName([]);
    };

    const handleClose = () => {
        props.setIsMobile(true);
        props.drawerClose(false);
    };

    return (
        <Drawer
            PaperProps={{
                className: classes.scroll,
                style: { borderTopLeftRadius: 10, borderTopRightRadius: 10, marginLeft: 3, marginRight: 3 },
            }}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'top' : 'bottom'}
            open={props.openDrawer}
            onClose={() => props.drawerClose(false)}
        >
            <div style={{ padding: '20px 20px 0px 20px' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid',
                        paddingBottom: '15px',
                    }}
                >
                    <div>
                        <div className={classes.text}>
                            <span
                                className="iconify"
                                data-icon="bx:bx-filter-alt"
                                data-width="20"
                                data-height="20"
                                style={{ marginRight: '7px', marginBottom: '3px' }}
                            ></span>
                            Filter By
                        </div>
                    </div>
                    {(props.date !== '' || props?.name?.length !== 0 || props?.tags?.length !== 0) && (
                        <span
                            onClick={cancel}
                            className={classes.text}
                            style={{ textDecoration: 'underline' }}
                            data-testid="clearAll"
                        >
                            Clear all
                        </span>
                    )}
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className={classes.boxes}>
                        <div className={classes.text2} onClick={props.drawerClose}>
                            Date
                        </div>
                        {/* <div> */}
                        <TextField
                            data-testid="back"
                            className={classes.boxes2}
                            type="date"
                            value={props.date}
                            onChange={(e: any) => props.setDate(e.target.value)}
                            size="small"
                            variant="outlined"
                        />
                        {/* </div> */}
                    </div>
                    <div className={classes.boxes}>
                        <div className={classes.text2}>Name</div>
                        <div className={classes.boxes2}>
                            <Auto
                                name={props?.name}
                                setName={props?.setName}
                                open={props.nameOpen}
                                setOpen={props.setNameOpen}
                                allEmployees={props?.allEmployee}
                                button={true}
                            />
                        </div>
                    </div>
                    <div className={classes.boxes}>
                        <div className={classes.text2}>Tags</div>
                        <div className={classes.boxes2}>
                            <AutoTags
                                tags={props?.tags}
                                allTags={props?.allTags}
                                open={props.nameOpen}
                                setOpen={props.setNameOpen}
                                setTags={props?.setTags}
                                button={true}
                            />
                        </div>
                    </div>
                    <div className={classes.boxes}>
                        <div className={classes.text2}>Teams</div>
                        <div className={classes.boxes2}>
                            <AutoTeams
                                setSideBar={props?.setSidebar}
                                teamList={props?.teamList}
                                setTeamList={props?.setTeamList}
                                open={props?.nameOpen}
                                setOpen={props?.setNameOpen}
                                teams={props?.teams}
                                button={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Button variant="contained" data-testid="ApplyFilter" onClick={handleClose} className={classes.apply}>
                Apply Filter
            </Button>
        </Drawer>
    );
};

interface DrawerContentProps {
    setSidebar?: any;
    isMobile?: boolean;
    date?: any;
    setClick?: any;
    setDate?: any;
    setIsMobile?: any;
    setName?: any;
    name?: any;
    tags?: any;
    setTags?: any;
    data?: any;
    myData?: any;
    drawerOpen?: any;
    allStandups?: any;
    filterData?: any;
    allEmployee?: any;
    alltags?: any;
    drawer?: boolean;
    setDrawer?: any;
    modalAns?: any;
    filterAns?: any;
    teams?: any;
    teamList?: any;
    setTeamList?: any;
}

export const DrawerContent = (props: DrawerContentProps) => {
    const classes = useStyles();
    const theme = useTheme();
    // // //console.log(props?.date);
    const handleClick = () => {
        props.setClick(false);
        props.isMobile ? props.setIsMobile(false) : props.setSidebar({ open: false });
        props.setDate('');
        props.setTags([]);
        props.setName([]);
    };

    return (
        <div>
            <Hidden mdDown>
                <div>
                    {props?.filterData?.length === 0 ? (
                        <div id="noResultFound" className={classes.fontsty} style={{ borderBottom: 'none' }}>
                            <img src={upcomingTimeoff} alt="No standup found" className={classes.notfoundImage} />
                            <div className={classes.notfoundText}>No data found</div>
                        </div>
                    ) : (
                        <div className={classes.scroll0}>
                            {props?.filterData?.map((v: any, i: number) => (
                                <div key={i}>
                                    <Grid container>
                                        <Grid item style={{ position: 'relative', paddingBottom: '10px' }}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingTop: '15px', paddingBottom: '10px' }}
                                            >
                                                <Grid item style={{ padding: '0px 10px 0px 15px' }}>
                                                    <Avatar
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            backgroundColor: '#EDEFFA',
                                                            color: '#444444',
                                                            fontSize: 12,
                                                        }}
                                                        src={v?.Employee?.profileImage}
                                                    >
                                                        {getFullName(
                                                            v?.Employee?.firstName + ' ' + v?.Employee?.lastName,
                                                        )}
                                                    </Avatar>
                                                </Grid>
                                                <Grid item>
                                                    <div
                                                        style={{
                                                            padding: '0px 10px 0px 10px',
                                                            color: theme.palette.text.primary,
                                                        }}
                                                        className={classes.text2}
                                                    >
                                                        {v?.Employee?.firstName} {v?.Employee?.lastName}
                                                    </div>
                                                </Grid>

                                                <Grid item xs={4} style={{ padding: '5px 10px 0px 10px' }}>
                                                    <div style={{ color: theme.palette.text.primary }}>
                                                        {v?.standupNoteDate}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    paddingLeft: '70px',
                                                    marginTop: '-15px',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {v?.tags?.map((a: any, i: number) => (
                                                    <Grid
                                                        item
                                                        key={i}
                                                        style={{
                                                            paddingBottom: '10px',
                                                        }}
                                                    >
                                                        <Chip
                                                            style={{
                                                                background: a?.color,
                                                                color: 'white',
                                                                height: '15px',
                                                                marginRight: '4px',
                                                            }}
                                                            label={a?.name}
                                                        />
                                                    </Grid>
                                                ))}
                                            </div>
                                            <Grid
                                                container
                                                style={{
                                                    paddingTop: '10px',
                                                    paddingLeft: '8px',
                                                }}
                                            >
                                                <Grid item>
                                                    <span
                                                        style={{ color: theme.palette.text.primary }}
                                                        className={classes.text2}
                                                    >
                                                        Did you accomplish yesterday’s task?
                                                    </span>
                                                </Grid>
                                                <p
                                                    style={{
                                                        width: '100%',
                                                        paddingLeft: '8px',
                                                        color: theme.palette.text.primary,
                                                    }}
                                                >
                                                    {v?.isAccomplished === true ? 'Yes' : 'No'}
                                                </p>
                                                <Grid item style={{ paddingTop: '2px', paddingBottom: '14px' }}>
                                                    {v?.answers?.map((f: any, j: number) => (
                                                        <div key={j}>
                                                            <div
                                                                className={classes.text2}
                                                                style={{
                                                                    paddingTop: '5px',
                                                                    color: theme.palette.text.primary,
                                                                }}
                                                            >
                                                                <span style={{ color: theme.palette.text.primary }}>
                                                                    {f?.question}
                                                                </span>
                                                            </div>
                                                            <p
                                                                style={{
                                                                    paddingLeft: '8px',
                                                                    color: theme.palette.text.primary,
                                                                    maxWidth: '100%',
                                                                    wordBreak: 'break-word',
                                                                }}
                                                            >
                                                                {f?.answer}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider
                                        style={{
                                            backgroundColor: theme.palette.background.default,
                                            width: '95%',
                                            marginLeft: '2.5%',
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <CloseIcon data-testid="cross" onClick={handleClick} className={classes.icon} />
                </div>
            </Hidden>
            <Hidden lgUp>
                <div>
                    {props?.filterData?.length === 0 ? (
                        <>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        style={{ display: 'flex', justifyContent: 'center' }}
                                        src={upcomingTimeoff}
                                        alt="No Teams found"
                                        className={classes.notfoundImage}
                                    />
                                </div>
                                <div className={classes.notfoundText}>No data found</div>
                            </div>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        onClick={() => props?.setIsMobile(false)}
                                        color="primary"
                                        className={classes.button2}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        onClick={props?.drawerOpen}
                                        color="primary"
                                        className={classes.button1}
                                    >
                                        Filter By
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <div className={classes.scroll0}>
                            {props?.filterData?.map((v: any, i: number) => (
                                <Grid key={i} container direction="column">
                                    <Card elevation={0} style={{ marginTop: '8px', padding: '20px 20px 5px 20px' }}>
                                        <Grid item>
                                            <Grid
                                                container
                                                style={{ display: 'flex', justifyContent: 'space-between' }}
                                            >
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Avatar
                                                                style={{
                                                                    width: '30px',
                                                                    fontSize: 12,
                                                                    height: '30px',
                                                                    backgroundColor: '#EDEFFA',
                                                                    color: '#444444',
                                                                }}
                                                                src={v?.Employee?.profileImage}
                                                            >
                                                                {getFullName(
                                                                    v?.Employee?.firstName +
                                                                        ' ' +
                                                                        v?.Employee?.lastName,
                                                                )}
                                                            </Avatar>
                                                        </Grid>
                                                        <Grid item>
                                                            <div className={classes.name}>
                                                                {v?.Employee?.firstName} {v?.Employee?.lastName}
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div className={classes.time} style={{ marginTop: '-1px' }}>
                                                                {v?.standupNoteDate}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <div style={{ display: 'flex', paddingLeft: '40px', flexWrap: 'wrap' }}>
                                                {v?.tags?.map((a: any, i: number) => (
                                                    <Grid
                                                        item
                                                        key={i}
                                                        style={{
                                                            paddingBottom: '10px',
                                                        }}
                                                    >
                                                        <Chip
                                                            style={{
                                                                background: a?.color,
                                                                color: 'white',
                                                                height: '15px',
                                                                marginRight: '4px',
                                                            }}
                                                            label={a?.name}
                                                        />
                                                    </Grid>
                                                ))}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className={classes.text3} style={{ marginTop: '10px' }}>
                                                Did you accomplish yesterday’s task ?
                                            </div>
                                            <p style={{ width: '100%' }}>{v?.isAccomplished === true ? 'Yes' : 'No'}</p>
                                            <div className={classes.text1}></div>
                                            <Grid item style={{ paddingTop: '2px' }}>
                                                {v?.answers?.map((f: any, j: number) => (
                                                    <div key={j}>
                                                        <div className={classes.text2}>
                                                            <span>{f?.question}</span>
                                                        </div>
                                                        <p style={{ maxWidth: '100%', wordBreak: 'break-word' }}>
                                                            {f?.answer}
                                                        </p>
                                                    </div>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                onClick={() => props?.setIsMobile(false)}
                                                color="primary"
                                                className={classes.button2}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                onClick={props?.drawerOpen}
                                                // color="primary"
                                                className={classes.button1}
                                            >
                                                Filter By
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                    )}
                </div>
            </Hidden>
            <Hidden lgUp>
                <MobileDrawer
                    openDrawer={props.drawer}
                    drawerClose={props.setDrawer}
                    date={props?.date}
                    setDate={props?.setDate}
                    name={props?.name}
                    setName={props?.setName}
                    allEmployee={props?.allEmployee}
                    setIsMobile={props.setIsMobile}
                    tags={props?.tags}
                    setTags={props?.setTags}
                    allTags={props?.alltags}
                    setDrawer={props.setDrawer}
                    teamList={props.teamList}
                    setTeamList={props.setTeamList}
                    teams={props.teams}
                />
            </Hidden>
        </div>
    );
};

export const Drawers = (props: { sidebar: Sidebar; children: JSX.Element }) => {
    const classes = useStyles();
    return <div className={props.sidebar.open ? classes.drawerOpen : classes.drawerClose}>{props.children}</div>;
};

export default function MainStandup(props: {
    setDate: any;
    viewPage: any;
    setviewPage: any;
    tabValue: any;
    settabValue: any;
    date: any;
}) {
    const classes = useStyles();
    const [sidebar, setSidebar] = React.useState<Sidebar>({
        open: false,
    });
    const [name, setName] = React.useState([]);
    const [click, setClick] = React.useState(false);
    const [date1, setDate1] = React.useState('');
    const state = useSelector((state: any) => state);
    const organiztionEmployee = state?.orgEmployees;
    const standupData = state?.standups;
    const userSubscriptionDetail = state?.subscription.userSubscriptions;
    const timeoff = state?.orgLeaves;
    const teams = state?.teams.teams;
    const [isMobile, setIsMobile] = React.useState<any>(false);
    const [tags, setTags] = React.useState<any>([]);
    const [alltags, setAllTags] = React.useState([]);
    const [menuTags, setMenuTags] = React.useState<any>([]);
    const mycontext = useContext(AuthContext);
    const [modal, setModal] = React.useState(false);
    const [data, setData] = React.useState<any>({});
    const [ans, setAns] = React.useState<any>({});
    const [date, setDate] = React.useState<any>('');
    const [check, setCheck] = React.useState('Yes');
    const [step, setStep] = React.useState(0);
    const [todos, setTodos] = React.useState<any>([]);
    const [drawer, setDrawer] = React.useState(false);
    const [modalAns, setModalAns] = React.useState([]);
    const [myStandup, setMyStandup] = React.useState([]);
    const [filterAns, setFilterAns] = React.useState([]);
    const [textDate, setTextDate] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const [todaysStandup, setTodaysStandup] = React.useState<any>([]);
    // const teams = useSelector((state: any) => state.teams.teams);
    const [teamList, setTeamList] = React.useState<any>([]);
    const drawerOpen = () => {
        setDrawer(true);
    };

    const handleClear = () => {
        setDate1('');
        setTeamList([]);
        setName([]);
        setTags([]);
        setTextDate(false);
        setSidebar({ open: false });
    };

    const [standupd, setStandupd] = React.useState('');
    const [filterData, setFilterData] = React.useState<any>([]);
    const [myData, setMyData] = React.useState<any>([]);
    const [modalDate, setModalDate] = React.useState('');
    const dispatch = useDispatch();

    const handleModal = () => {
        setDate(ToStringFormat(getCurrentCountryDateTime(mycontext.country.zoneName).dateTime, 'YYYY-MM-DD'));
        const z: any = standupData?.standups
            ?.filter(
                (e: any) =>
                    isoToLocal(e?.standupNoteDateTime, mycontext.country)
                        .toString()
                        .substring(0, 15) ===
                        getCurrentCountryDateTime(mycontext.country.zoneName)
                            .dateTime.toString()
                            .substring(0, 15) && e?.employeeId === mycontext?.EmployeeId,
            )
            ?.map((c: any) => c);
        setTodaysStandup(
            standupData?.standups?.filter(
                (e: any) =>
                    isoToLocal(e?.standupNoteDateTime, mycontext.country)
                        .toString()
                        .substring(0, 15) ===
                    getCurrentCountryDateTime(mycontext.country.zoneName)
                        .dateTime.toString()
                        .substring(0, 15),
            ),
        );
        // //console.log(z);
        if (z?.length !== 0) {
            const answers: any = z[0].answers?.map((f: any) => f?.answer);
            // // //console.log(answers);
            for (let i = 0; i < answers?.length; i++) {
                setAns((prev: any) => ({ ...prev, [i]: answers[i] }));
            }
            setMyStandup(z);

            if (z[0]?.isAccomplished === true) {
                setCheck('Yes');
            } else {
                setCheck('No');
            }

            setTodos([]);
            setTodos(z[0]?.tags);
        } else {
            setAns({});
            setTodos([]);
        }
        setModal(true);
    };

    React.useMemo(() => {
        const tagIds: any = tags?.map((a: any) => a?.name);
        const nameIds: any = name?.map((a: any) => a?.employeeId);
        const teamChecking = (teamList: any, id: any) => {
            const check: any = [];
            let a: any = [];
            for (let i = 0; i < teamList.length; i++) {
                a = teamList[i].team_employees.filter((e: any) => e?.Employee?.employeeId === id);
                if (a.length >= 1) {
                    check.push(a);
                }
            }
            if (check.length >= 1) {
                return true;
            }
            return false;
        };
        const fetchData = async () => {
            if (teamList?.length !== 0 && nameIds?.length === 0 && tagIds?.length === 0 && date1 === '') {
                // ..// //console.log(check);
                const c: any = standupData?.standups?.filter((s: any) => teamChecking(teamList, s.employeeId));
                setFilterData(c);
                // // //console.log(filterData);
            } else if (teamList?.length !== 0 && nameIds?.length === 0 && tagIds?.length === 0 && date1 !== '') {
                const c: any = standupData?.standups?.filter(
                    (s: any) => teamChecking(teamList, s.employeeId) && s?.standupNoteDate === date1,
                );
                setFilterData(c);
            } else if (nameIds?.length === 0 && tagIds?.length === 0 && teamList?.length === 0) {
                // console?.log("yes")
                const c: any = standupData?.standups?.filter((s: any) => s?.standupNoteDate === date1);
                const z: any = modalAns?.filter((e: any) => e?.date === date1);
                setFilterAns(z);
                setFilterData(c);
            } else if (nameIds?.length === 0 && date1 === '' && teamList?.length === 0) {
                const c: any = standupData?.standups?.filter(
                    (s: any) => s?.tags?.filter((t: any) => tagIds?.includes(t?.name))?.length !== 0,
                );
                const z: any = modalAns?.filter(
                    (e: any) => e?.tags?.filter((f: any) => tagIds?.includes(f))?.length !== 0,
                );
                setFilterAns(z);
                setFilterData(c);
            } else if (tagIds?.length === 0 && date1 === '' && teamList?.length === 0) {
                const b: any = standupData?.standups?.filter((s: any) => nameIds?.includes(s?.employeeId));
                const z: any = modalAns?.filter((e: any) => nameIds?.includes(e?.employeeId));
                setFilterAns(z);
                setFilterData(b);
            } else if (tagIds?.length === 0 && date1 !== '' && nameIds?.length !== 0 && teamList?.length === 0) {
                const a: any = standupData?.standups?.filter(
                    (s: any) => nameIds?.includes(s?.employeeId) && s?.standupNoteDate === date1,
                );
                const z: any = modalAns?.filter((e: any) => e?.date === date1 && nameIds?.includes(e?.employeeId));
                setFilterAns(z);
                setFilterData(a);
            } else if (tagIds?.length !== 0 && date1 !== '' && nameIds?.length === 0 && teamList?.length === 0) {
                const a: any = standupData?.standups?.filter(
                    (s: any) =>
                        s?.tags?.filter((t: any) => tagIds?.includes(t?.name))?.length !== 0 &&
                        s?.standupNoteDate === date1,
                );
                const z: any = modalAns?.filter(
                    (e: any) => e?.date === date1 && e?.tags?.filter((f: any) => tagIds?.includes(f))?.length !== 0,
                );
                setFilterAns(z);
                setFilterData(a);
            } else if (tagIds?.length !== 0 && date1 === '' && nameIds?.length !== 0 && teamList?.length === 0) {
                const a: any = standupData?.standups?.filter(
                    (s: any) =>
                        s?.tags?.filter((t: any) => tagIds?.includes(t?.name))?.length !== 0 &&
                        nameIds?.includes(s?.employeeId),
                );
                const z: any = modalAns?.filter(
                    (e: any) =>
                        nameIds?.includes(e?.employeeId) &&
                        e?.tags?.filter((f: any) => tagIds?.includes(f))?.length !== 0,
                );
                setFilterAns(z);
                setFilterData(a);
            }
        };
        fetchData();
    }, [name, tags, date1, modalAns, standupData, teamList]);

    React.useEffect(() => {
        dispatch(updateActiveTab('/dashboard'));
        // console.log("standup table", state.subscription);
    }, [dispatch]);

    React.useEffect(() => {
        const fetchData = async () => {
            const data: any = await fetchQuestion(mycontext?.organizationId, mycontext?.token);
            setData(data);
            // // //console.log(data?.organizationId)
            if (data?.standupTempId) {
                mycontext?.addStandupTempId(data?.standupTempId);
            }
            setAllTags(data?.tags);
        };
        fetchData();
    }, [mycontext]);
    // console.log(menuTags);

    React.useEffect(() => {
        const z: any = [];
        //console.log(standupData?.standups.map((a: any) => a));
        // if (standupData?.standups.map((a: any) => a.tags === '' || a.tags === 'null' || a.tags === null)) {
        //     console.log('if');
        //     return;
        // } else {
        standupData?.standups?.map((a: any) =>
            a?.tags?.map((b: any) => {
                z?.push(b);
            }),
        );
        const arr: any = z?.filter((a: any, i: any) => z?.findIndex((s: any) => a?.name === s?.name) === i);
        // // //console.log(arr);
        setMenuTags(arr);
        // }
        // for(let )
    }, [standupData.standups]);
    if (
        standupData?.status === 'failed' ||
        // isError ||
        teams?.status === 'failed' ||
        timeoff?.status === 'failed' ||
        organiztionEmployee?.status === 'failed'
    ) {
        return <ErrorPage title="Something went wrong!" />;
    }

    //console.log('Name', name);
    if (step === 1) {
        return (
            <StandupNote
                setStep={setStep}
                standupd={standupd}
                questions={data?.questions}
                modal={modal}
                setModal={setModal}
                data={data}
                tags={alltags}
                allStandups={standupData?.standups}
                date={date}
                setDate={setDate}
                ans={ans}
                setAns={setAns}
                todos={todos}
                setTodos={setTodos}
                check={check}
                setCheck={setCheck}
                modalAns={modalAns}
                today={ToStringFormat(getCurrentCountryDateTime(mycontext.country.zoneName).dateTime, 'YYYY-MM-DD')}
                myData={myData}
                modalDate={modalDate}
                myteams={state.teams.teams}
            />
        );
    }

    if (isMobile) {
        return (
            <Hidden lgUp>
                <div>
                    <DrawerContent
                        setIsMobile={setIsMobile}
                        isMobile={isMobile}
                        setClick={setClick}
                        date={date1}
                        setDate={setDate1}
                        drawerOpen={drawerOpen}
                        setName={setName}
                        setTags={setTags}
                        name={name}
                        data={data?.questions}
                        tags={tags}
                        filterData={filterData}
                        allEmployee={organiztionEmployee?.employees}
                        alltags={menuTags}
                        drawer={drawer}
                        setDrawer={setDrawer}
                        modalAns={modalAns}
                        filterAns={filterAns}
                        teamList={teamList}
                        teams={teams}
                        setTeamList={setTeamList}
                    />
                </div>
            </Hidden>
        );
    }
    return mycontext?.subscriptionExpired === 'cancelled' &&
        Math?.floor(new Date()?.getTime() / 1000) > parseInt(mycontext?.hardExpireDate) ? (
        mycontext?.isAdmin ? (
            <SubscriptionHardExpired
                content="Looks like your subscription is expired, Please renew it !"
                showRenew={true}
            />
        ) : (
            <SubscriptionHardExpired
                content="Subscription expired, Please contact your admin to renew subscription"
                showRenew={false}
            />
        )
    ) : (
        <div>
            <div className={classes.border}>
                <div className={sidebar?.open ? classes.leftHalf : classes.leftFull}>
                    <StandupTable
                        date={date1}
                        setDate={setDate1}
                        open={sidebar?.open}
                        click={click}
                        setClick={setClick}
                        setIsMobile={setIsMobile}
                        setSidebar={setSidebar}
                        handleClear={handleClear}
                        name={name}
                        setName={setName}
                        allEmployee={organiztionEmployee?.employees}
                        tags={tags}
                        setTags={setTags}
                        allTags={menuTags}
                        allStandups={standupData?.standups}
                        setStep={setStep}
                        setStandupd={setStandupd}
                        data={data}
                        loading={
                            standupData.loading === true ||
                            timeoff?.status === 'loading' ||
                            teams?.status === 'loading' ||
                            organiztionEmployee?.status === 'loading'
                        }
                        today={ToStringFormat(
                            getCurrentCountryDateTime(mycontext.country.zoneName).dateTime,
                            'YYYY-MM-DD',
                        )}
                        nameOpen={open}
                        setNameOpen={setOpen}
                        textDate={textDate}
                        setTextDate={setTextDate}
                        timeoff={timeoff}
                        teamList={teamList}
                        setTeamList={setTeamList}
                        teams={teams}
                        handleModal={handleModal}
                        drawerOpen={drawerOpen}
                        setMyData={setMyData}
                        setModalDate={setModalDate}
                        setTime={props.setDate}
                        time={props.date}
                        viewPage={props.viewPage}
                        setviewPage={props.setviewPage}
                        tabValue={props.tabValue}
                        settabValue={props.settabValue}
                    />
                    {!mycontext?.isAdmin ? (
                        <Button className={classes.add} onClick={handleModal}>
                            Add Note
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            disableElevation
                            aria-describedby="filter-popup"
                            startIcon={
                                <span
                                    className="iconify"
                                    data-icon="bx:bx-filter-alt"
                                    data-width="20"
                                    data-height="20"
                                ></span>
                            }
                            onClick={drawerOpen}
                            className={classes.btn3}
                        >
                            Filter By
                        </Button>
                    )}
                </div>
                <Hidden mdDown>
                    <Drawers sidebar={sidebar}>
                        <div>
                            <DrawerContent
                                setSidebar={setSidebar}
                                date={date}
                                setClick={setClick}
                                setDate={setDate}
                                setName={setName}
                                setTags={setTags}
                                setIsMobile={setIsMobile}
                                drawerOpen={drawerOpen}
                                name={name}
                                tags={tags}
                                data={data}
                                allStandups={standupData?.standups}
                                filterData={filterData}
                                allEmployee={organiztionEmployee?.employees}
                                alltags={menuTags}
                                drawer={drawer}
                                setDrawer={setDrawer}
                                modalAns={modalAns}
                                filterAns={filterAns}
                            />
                        </div>
                    </Drawers>
                </Hidden>
                <Hidden lgUp>
                    <MobileDrawer
                        openDrawer={drawer}
                        drawerClose={setDrawer}
                        date={date1}
                        setDate={setDate1}
                        name={name}
                        setName={setName}
                        allEmployee={organiztionEmployee?.employees}
                        setIsMobile={setIsMobile}
                        tags={tags}
                        setTags={setTags}
                        allTags={menuTags}
                        setDrawer={setDrawer}
                        nameOpen={open}
                        setNameOpen={setOpen}
                        setSidebar={setSidebar}
                        isMobile={isMobile}
                        teamList={teamList}
                        setTeamList={setTeamList}
                        teams={teams}
                    />
                </Hidden>
            </div>
            {!mycontext.isAdmin && (
                <AddNotes
                    modal={modal}
                    setModal={setModal}
                    data={data}
                    tags={alltags}
                    setAllTags={setAllTags}
                    allStandups={standupData?.standups}
                    todaysStandup={todaysStandup}
                    date={date}
                    setDate={setDate}
                    ans={ans}
                    setAns={setAns}
                    todos={todos}
                    setTodos={setTodos}
                    check={check}
                    setCheck={setCheck}
                    modalAns={modalAns}
                    myStandup={myStandup}
                    setMyStandup={setMyStandup}
                    setModalAns={setModalAns}
                    setStep={setStep}
                    userSubscriptionDetail={userSubscriptionDetail.usermanagementdb_tenant_employee[0]}
                    myteams={state.teams.teams}
                />
            )}
        </div>
    );
}
