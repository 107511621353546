import moment from 'moment';

// new Date('2022-09-15').toDateString() + ' 10:00:00 ' + new Date().toString().split(' ')[5] time according to GMT format
// function to change date according to timezone
const changeTimeZone = (date: any, timeZone: any) => {
    if (typeof date === 'string') {
        return new Date(
            new Date(date).toLocaleString('en-US', {
                timeZone,
            }),
        );
    }
    return new Date(
        date.toLocaleString('en-US', {
            timeZone,
        }),
    );
};

function convertTZ(date: any, tzString: any) {
    return new Date(
        (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
            timeZone: tzString || 'Asia/Kolkata',
        }),
    );
}

// Function for modals to count available, current and remaining time for leave
const minuteToHours = (time: number, workingHours: number, noOfDays: number) => {
    const totalMinutes = noOfDays * workingHours * 60;
    const availableMinutes = totalMinutes - time;
    const hrs = Math.floor(availableMinutes / 60);
    const min = Math.floor(availableMinutes % 60);
    const days = Math.floor(hrs / 5);
    const hr = Math.floor(hrs % 5);
    return days.toString() + ' Days(s) ' + hr.toString() + ' Hr ' + min + ' Min';
};

// get time difference between two times in hours and minutes
const getTimeDifferenceMinute = (startTime: any, endTime: any) => {
    const t1: any = new Date(`01/01/2021 ${startTime}:00`);
    let t2: any = new Date(`01/01/2021 ${endTime}:00`);
    if (parseInt(startTime) > parseInt(endTime)) {
        t2 = new Date(`02/01/2021 ${endTime}:00`);
    }
    const hours: number = ((t2 - t1) / (1000 * 60 * 60)) % 24;
    const minutes: number = ((t2.getTime() - t1.getTime()) / (1000 * 60)) % 60;
    if (Math.floor(hours) === 1 && minutes === 0) {
        return Math.floor(hours).toString() + ' Hr';
    }
    if (Math.floor(hours) > 1 && minutes === 0) {
        return Math.floor(hours).toString() + ' Hr';
    }
    if (Math.floor(hours) === 1) {
        return Math.floor(hours).toString() + ' Hr ' + minutes.toString() + ' Min';
    }
    if (Math.floor(hours) > 1) {
        return Math.floor(hours).toString() + ' Hr ' + minutes.toString() + ' Min';
    }
    return minutes.toString() + ' Min';
};

// It will return the hours and minutes for noOf hours field
const getTimeOnlyInMinutes = (startTime: any, endTime: any) => {
    const t1: any = new Date(`01/01/2021 ${startTime}:00`);
    const t2: any = new Date(`01/01/2021 ${endTime}:00`);
    const m: number = (t2.getTime() - t1.getTime()) / (1000 * 60);
    const hours: number = m / 60;
    return { minutes: m, hours: hours.toFixed(2) };
};

// Time difference in hours only
const getTimeDifference = (startTime: any, endTime: any) => {
    const t1: any = new Date('01/01/2021 ' + startTime?.toString()).getHours();
    const t2: any = new Date('01/01/2021 ' + endTime?.toString()).getHours();
    return t2 - t1;
};

const convertTime = (time: any) => {
    time =
        time === null || time === 'null'
            ? '00:00'.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || ['00:00']
            : time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        time = time?.slice(1);
        time[5] = +time[0] < 12 ? ' AM' : ' PM';
        time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
};

const toDayHour = (value: any) => {
    value = value?.toString().split('.');
    const dayhour = value[0]?.toString() + ' Days ' + value[1]?.toString() + ' Hour';
    return dayhour;
};

const toDayHourMinutes = (value: number, workingHours: number) => {
    const days: number = Math.floor(value / workingHours);
    const hours: number = Math.floor(value % workingHours);
    const rm: number = (value % workingHours) - hours;
    const minutes: number = rm * 60;
    if (minutes <= 0 && hours <= 0) {
        return days.toString() + ' Day(s) ';
    }
    if (days <= 0 && hours <= 0) {
        return minutes.toPrecision(2) + ' Min';
    }
    return (
        days.toString() + ' Day(s) ' + Math.floor(hours).toString() + ' Hr ' + Math.floor(minutes).toString() + ' Min'
    );
};

const toTime = (value: number) => {
    const num: string = value.toString() + ' Hr';
    return num;
};

const toDays = (value: number) => {
    const num: string = value.toString();
    return num === '1' ? num + ' Day' : num + ' Days';
};

const onlyCountDayHours = (days: number, workingHours: number) => {
    const d: number = Math.floor(days / workingHours);
    const h: number = days % workingHours;
    const rm: number = (days % workingHours) - h;
    const m: number = rm * 60;
    return { d: d, h: h, m: m };
};

const countDaysHours = (days: number, workingHours: number) => {
    const d: number = Math.floor(days / workingHours);
    const h: number = Math.floor(days % workingHours);
    const rm: number = (days % workingHours) - h;
    const m: number = rm * 60;
    return days % workingHours === 0
        ? d === 1
            ? d.toString() + ' Day'
            : d.toString() + ' Day(s)'
        : d === 1
        ? m === 0
            ? d.toString() + ' Day ' + Math.floor(h).toString() + ' Hr '
            : d.toString() + ' Day ' + Math.floor(h).toString() + ' Hr ' + Math.floor(m).toString() + ' Min'
        : d <= 0
        ? m === 0
            ? Math.floor(h).toString() + ' Hr '
            : Math.floor(h).toString() + ' Hr ' + Math.floor(m).toString() + ' Min'
        : m === 0
        ? d.toString() + ' Day(s) ' + Math.floor(h).toString() + ' Hr '
        : d.toString() + ' Day(s) ' + Math.floor(h).toString() + ' Hr ' + Math.floor(m).toString() + ' Min';
};

const toDate = (date: any) => {
    const d = moment(date).format('L');
    const all = d.split('/');
    const month = all[0];
    const day = all[1];
    const year = all[2];
    const mydate = year + '-' + month + '-' + day;
    return mydate;
};

const toMonth = (date: any) => {
    const d = moment(date).format('L');
    const all = d.split('/');
    const month = all[0];
    let mydate: any;
    if (month === '01') {
        mydate = 'January';
    } else if (month === '02') {
        mydate = 'February';
    } else if (month === '03') {
        mydate = 'March';
    } else if (month === '04') {
        mydate = 'April';
    } else if (month === '05') {
        mydate = 'May';
    } else if (month === '06') {
        mydate = 'June';
    } else if (month === '07') {
        mydate = 'July';
    } else if (month === '08') {
        mydate = 'August';
    } else if (month === '09') {
        mydate = 'September';
    } else if (month === '10') {
        mydate = 'October';
    } else if (month === '11') {
        mydate = 'November';
    } else {
        mydate = 'December';
    }

    return mydate;
};

const FormatDate = (date: any) => {
    const arrayDate = date.split('-');
    return arrayDate[1] + '/' + arrayDate[2] + '/' + arrayDate[0];
};

const toISOFormat = (date: any) => {
    let d = moment(date || moment.now()).format('YYYY-MM-DDTHH:mm:ss.SSS');
    d = d.toString() + 'Z';
    return Date.parse(d) - 5.5 * 60 * 60 * 1000;
};

const ToStringFormat = (date: any, dateFormat: any) => {
    if (dateFormat === 'YYYY-MM-DD') {
        const d = moment(date || moment.now()).format('YYYY-MM-DD');
        return d;
    } else if (dateFormat === 'DD-MM-YYYY') {
        const d = moment(date || moment.now()).format('DD-MM-YYYY');
        return d;
    } else if (dateFormat === 'MM-DD-YYYY') {
        const d = moment(date || moment.now()).format('MM-DD-YYYY');
        return d;
    } else if (dateFormat === 'MMMM Do YYYY') {
        const d = moment(date || moment.now()).format('MMMM Do YYYY');
        return d;
    } else if (dateFormat === 'MMM Do YY') {
        const d = moment(date || moment.now()).format('MMM Do YY');
        return d;
    } else if (dateFormat === 'Do MMMM YYYY') {
        const d = moment(date || moment.now()).format('Do MMMM YYYY');
        return d;
    } else if (dateFormat === 'MMMM Do YYYY') {
        const d = moment(date || moment.now()).format('MMMM Do YYYY');
        return d;
    } else if (dateFormat === 'D MMMM YYYY') {
        const d = moment(date || moment.now()).format('D MMMM, YYYY');
        return d;
    } else {
        const d = moment(date || moment.now()).format('LL');
        return d;
    }
};

const ToStringFormatWithoutYear = (date: any, dateFormat: any) => {
    if (dateFormat === 'YYYY-MM-DD') {
        const d = moment(date || moment.now()).format('MM-DD');
        return d;
    } else if (dateFormat === 'DD-MM-YYYY') {
        const d = moment(date || moment.now()).format('DD-MM');
        return d;
    } else if (dateFormat === 'MM-DD-YYYY') {
        const d = moment(date || moment.now()).format('MM-DD');
        return d;
    } else if (dateFormat === 'MMMM Do YYYY') {
        const d = moment(date || moment.now()).format('MMMM Do');
        return d;
    } else if (dateFormat === 'MMM Do YY') {
        const d = moment(date || moment.now()).format('MMM Do');
        return d;
    } else if (dateFormat === 'Do MMMM YYYY') {
        const d = moment(date || moment.now()).format('Do MMMM');
        return d;
    } else if (dateFormat === 'MMMM Do YYYY') {
        const d = moment(date || moment.now()).format('MMMM Do');
        return d;
    } else if (dateFormat === 'D MMMM YYYY') {
        const d = moment(date || moment.now()).format('D MMMM');
        return d;
    } else {
        const d = moment(date || moment.now()).format('LL');
        return d;
    }
};

const toAWSDateFormat = (date: any) => {
    const d = moment(date || moment.now()).format('YYYY-MM-DD');
    return d;
};

const toBrowserDateFormat = (date: any) => {
    const d = moment(date || moment.now()).format('YYYY/MM/DD');
    return d;
};

const toAWSISODateFormat = (date: any) => {
    return toAWSDateFormat(date.toString().slice(0, 15)) + 'T' + new Date().toString().slice(16, 24);
};

const toYearFormat = (date: any) => {
    const d = moment(date || moment.now()).format('YYYY');
    return d;
};

const toDayFormat = (date: any) => {
    const d = moment(date || moment.now()).format('dddd');
    return d;
};

const toStringDateFormat = (date: any) => {
    const d = moment(date || moment.now()).format('LL');
    return d;
};

const DateDifference = (sDate: any, eDate: any) => {
    // const timeDiff = Math.abs(new Date(eDate).getTime() - new Date(sDate).getTime());
    const timeDiff = new Date(eDate).getTime() - new Date(sDate).getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
};

const checkTimeDifference = (startTime: string, endTime: string) => {
    const st = parseInt(startTime);
    const et = parseInt(endTime);
    if (st > et) {
        return true;
    }
    return false;
};

const DateDifferenceHistory = (sDate: any, eDate: any) => {
    const timeDiff = new Date(eDate).getTime() - new Date(sDate).getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
};

const validateDate = (sDate: any, eDate: any) => {
    const difference = DateDifferenceHistory(sDate, eDate);
    if (difference >= 0) {
        return { error: false };
    }
    return { error: true };
};

const validatePastDate = (sDate: any, eDate: any) => {
    const difference = DateDifferenceHistory(sDate, eDate);
    if (difference <= 0) {
        return { error: false };
    }
    return { error: true };
};

const convertDateString = (date: any) => {
    const dateTimeString: any = date.split('T');
    return { date: dateTimeString[0], time: dateTimeString[1] };
};

const convertTimeInTZ = (time: any, gmt: any) => {
    time = new Date(toBrowserDateFormat(new Date()) + ' ' + time + ':00 ' + gmt)
        .toTimeString()
        .split(' ')[0]
        .substring(0, 5);
    time =
        time === null || time === 'null'
            ? '00:00'.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || ['00:00']
            : time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        time = time?.slice(1);
        time[5] = +time[0] < 12 ? ' AM' : ' PM';
        time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
};

function toISODateTime(date: any, time: any) {
    return new Date(`${date}T${time}`).toISOString();
}

function toLocalDateTime(dateTimeString: any) {
    return convertTime(
        new Date(`${dateTimeString}`)
            .toString()
            .split(' ')[4]
            .slice(0, 5),
    );
}

function initialDate(obj: any, num: number, zoneName: string) {
    if (obj === 'week') {
        const week = moment(convertTZ(new Date().toISOString(), zoneName))
            .subtract(num, obj)
            .startOf('isoWeek')
            .format()
            .replace('05:30', '00:00');
        return week;
    } else if (obj === 'month') {
        const month = moment(convertTZ(new Date().toISOString(), zoneName))
            .subtract(num, obj)
            .startOf('month')
            .format()
            .replace('05:30', '00:00');
        return month;
    } else {
        const quarter = moment(convertTZ(new Date().toISOString(), zoneName))
            .subtract(num, obj)
            .startOf('quarter')
            .format()
            .replace('05:30', '00:00');
        return quarter;
    }
}

export {
    toMonth,
    toDate,
    toISOFormat,
    ToStringFormat,
    toAWSDateFormat,
    toAWSISODateFormat,
    toYearFormat,
    toDayFormat,
    toStringDateFormat,
    DateDifference,
    DateDifferenceHistory,
    FormatDate,
    validateDate,
    validatePastDate,
    getTimeDifference,
    convertTime,
    toDayHour,
    toTime,
    toDays,
    countDaysHours,
    onlyCountDayHours,
    checkTimeDifference,
    convertDateString,
    minuteToHours,
    getTimeDifferenceMinute,
    getTimeOnlyInMinutes,
    toDayHourMinutes,
    ToStringFormatWithoutYear,
    changeTimeZone,
    convertTZ,
    toBrowserDateFormat,
    convertTimeInTZ,
    toISODateTime,
    toLocalDateTime,
    initialDate,
};
