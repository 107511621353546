import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import { useDispatch } from 'react-redux';
// import { Hidden } from '@material-ui/core';
import useStyles from './style';
// import Questions from './questions';

export default function Policy() {
    const dispatch = useDispatch();
    const classes = useStyles();
    useEffect(() => {
        dispatch({ type: 'ACTIVE', payload: '/privacy-policy' });
    }, [dispatch]);

    const PolicyBlock = () => {
        return (
            <div className={classes.policyDiv}>
                <div>
                    <h3 className={classes.policyHeading}>Privacy Policy</h3>
                    <p className={classes.caption}>Effective : 7th January, 2022</p>
                </div>
                <div className={classes.policySubtitle}>
                    <h5>
                        Our main priority is the privacy of our visitors. This Privacy Policy document contains types of
                        information that is collected and recorded by Slack Bot and its utilization.
                    </h5>
                </div>
                <div>
                    <dl>
                        <dt className={classes.titles}>1. Applicability : </dt>
                        <dd className={classes.description}>
                            <p>
                                This Privacy Policy applies only to our online activities and is valid for visitors to
                                our website with regards to the information that they share and/or collect. This policy
                                is not applicable to any information collected offline or via channels other than this
                                website.
                            </p>
                            <p>
                                This Policy is applicable to the decider’s Slack Bot (services), the decider’s app and
                                the decider’s website and other interactions with the decider. This Privacy Policy does
                                not apply to any third-party applications or software that integrate with the Services
                                through the Decider tool (“Third Party Services”), or any other third party products.
                            </p>
                            <p>
                                In addition, a separate agreement governs the delivery, access and use of the Services
                                through the “Customer Agreement” which includes the regulations in relation to
                                processing of any files, messages and customer data. The organization (Any business
                                entity or an individual) that entered into the Customer Agreement with us, controls
                                their instance of services i.e., their decision-making tools or surveys and any
                                associated Customer Data. If any assistance is required about specific decision-making
                                tool settings and privacy practices, please contact the Third-Party Customer whose
                                decision-making tool you intend to use.
                            </p>
                        </dd>
                        <dt className={classes.titles}>2. Collection of Data : </dt>
                        <dd className={classes.description}>
                            We collect information about you only if we need the information for some legitimate
                            purpose. We will have information about you only if
                            <ol type="a" className={classes.alist}>
                                <li>you have provided the information yourself,</li>
                                <li>We have automatically collected the information, or</li>
                                <li>We have obtained the information from a third party. </li>
                            </ol>
                            <p>The type of data collected are as mentioned aforesaid :</p>
                            <ol type="a" className={classes.alist}>
                                <li>
                                    <span className={classes.listTitle}>Customer Data : </span>
                                    <span>
                                        Customers or Individuals granted access to a Workspace by a Customer
                                        (“Authorised Users”) routinely submit Customer Data to Slack when using the
                                        Services.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Personal Data : </span>
                                    <span>
                                        The personal information that you are asked to provide, and the reasons why you
                                        are asked to provide it, will be made clear to you at the point we ask you to
                                        provide your personal information
                                    </span>
                                    <p>
                                        While using Our Service, we may ask you to provide us with certain personally
                                        identifiable information that can be used to contact or identify You. Personally
                                        identifiable information may include the email address, first name and last
                                        name, usage Data.
                                    </p>
                                    <p>
                                        Authorized Users may provide us with their Personal Data directly at instances,
                                        e.g., when they provide feedback or request client support or in any way
                                        communicate with us. We do not collect or use any sensitive personal data unless
                                        the decider (customer) has received an expressed consent regarding the specific
                                        data.
                                    </p>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Usage Data : </span>
                                    <span>
                                        Usage Data is collected automatically when using the Service. The brief types of
                                        usage data collected are :{' '}
                                    </span>
                                    <ul style={{ marginLeft: 40 }}>
                                        <li>Service Metadata</li>
                                        <li>Log Data</li>
                                        <li>Device Information</li>
                                        <li>Location Information</li>
                                    </ul>
                                    <p>
                                        When an Authorised User interacts with the Services, metadata is generated that
                                        provides additional context about the way that Authorised Users work. For
                                        example, Slack logs the Workspaces, channels, people, features, content and
                                        links that you view or interact with, the types of files shared and what
                                        Third-Party Services are used (if any).
                                    </p>
                                    <p>
                                        Usage Data may include information such as your device’s Internet Protocol
                                        address (e.g. IP address), browser type, browser version, the pages of our
                                        Service that you visit, the time and date of Your visit, the time spent on those
                                        pages, unique device identifiers and other diagnostic data.
                                    </p>
                                    <p>
                                        When you access the Service by or through a mobile device, we may collect
                                        certain information automatically, including, but not limited to, the type of
                                        mobile device you use, your mobile device unique ID, the IP address of your
                                        mobile device, your mobile operating system, the type of mobile Internet browser
                                        you use, unique device identifiers and other diagnostic data.
                                    </p>
                                    <p>
                                        We may also collect information that your browser sends whenever you visit our
                                        Service or when you access the Service by or through a mobile device.
                                    </p>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Cookie Information : </span>
                                    <span>
                                        We use a variety of cookies and similar technologies in our Websites and
                                        Services to help us collect Other Information. These cookies are used to store
                                        information including visitors preferences, and the pages on the website that
                                        the visitor accessed or visited. The information is used to optimize the users
                                        experience by customizing our web page content based on visitors browser type
                                        and/or other information.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Third-Party Services : </span>
                                    <span>
                                        A Customer can choose to permit or restrict Third-Party Services for its
                                        Workspace. Typically, Third-Party Services are software that integrate with our
                                        Services, and a customer can permit its Authorised Users to enable and disable
                                        these integrations for its Workspace. Once enabled, the provider of a
                                        Third-Party Service may share certain information with us. Authorised Users
                                        should check the privacy settings and notices in these Third-Party Services to
                                        understand what data may be disclosed to us. When a Third-Party Service is
                                        enabled, Slackbot is authorised to connect and access Other Information made
                                        available to Slackbot in accordance with our agreement with the Third-Party
                                        Provider and any permission(s) granted by the Customer (including by its
                                        Authorised User(s)). We do not, however, receive or store passwords for any of
                                        these Third-Party Services when connecting them to the Services.
                                    </span>
                                    <p>
                                        Slack Bot’s Privacy Policy does not apply to other advertisers or websites.
                                        Thus, we are advising you to consult the respective Privacy Policies of these
                                        third-party ad servers for more detailed information. It may include their
                                        practices and instructions about how to optout of certain options. You can
                                        choose to disable cookies through your individual browser options.
                                    </p>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Contact Information : </span>
                                    <span>
                                        In accordance with the consent process provided by your device or other
                                        third-party API, any contact information that an Authorised User chooses to
                                        import (such as an address book from a device or API) is collected when using
                                        the Services.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Third-Party Data : </span>
                                    <span>
                                        We may receive data if you integrate your slack account with any other third
                                        party application regarding your name, email id for the purpose of registration.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Additional Information Provided : </span>
                                    <span>
                                        We also receive Other Information when submitted to our websites or in other
                                        ways, such as if you interact with Slack or a vendor, request support, interact
                                        with our social media accounts or otherwise communicate with Slackbot.
                                    </span>
                                    <span>
                                        Generally, no one is under a statutory or contractual obligation to provide any
                                        Customer Data or Other Information (collectively <b>“Information”</b>). However,
                                        certain Information is collected automatically and if some Information, such as
                                        Workspace setup details, is not provided, we may be unable to provide the
                                        Services.
                                    </span>
                                </li>
                            </ol>
                        </dd>

                        <dt className={classes.titles}>3. How we use your information : </dt>
                        <dd className={classes.description}>
                            <p>We may use the information we collect for the following : </p>
                            <ol type="a" className={classes.alist}>
                                <li>
                                    <p>To provide, operate and maintain our website</p>
                                </li>
                                <li>
                                    <p>To improve, personalize and expand our website</p>
                                </li>
                                <li>
                                    <p>To understand and analyse your interaction with our website</p>
                                </li>
                                <li>
                                    <p>
                                        To communicate with you, either directly or through one of our partners,
                                        including for customer service, to provide you with updates and other
                                        information relating to the website, and for marketing and promotional purposes
                                    </p>
                                </li>
                                <li>
                                    <p>To send you emails and other communications</p>
                                </li>
                                <li>
                                    <p>To find and prevent fraud and other security issues and abuse</p>
                                </li>
                                <li>
                                    <p>For performance of contract with you and business transfers, if any.</p>
                                </li>
                                <li>
                                    <p>
                                        To communicate with you by responding to your requests, comments and redress the
                                        grievances
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        To ask you to participate in the surveys and provide with the product feedbacks
                                    </p>
                                </li>
                                <li>
                                    <p>As may be required by applicable law, legal process and regulations.</p>
                                </li>
                            </ol>
                        </dd>

                        <dt className={classes.titles}>4. We may share your information : </dt>
                        <dd className={classes.description}>
                            <ol type="a" className={classes.alist}>
                                <li>
                                    <span className={classes.listTitle}>Employee and Independent Contractor : </span>
                                    <span>
                                        Employees and independent contractors of Slackbot have access to the information
                                        on legitimate requirements on a need-to-know basis. We require all employees and
                                        independent contractors of Slackbot to follow this Privacy Policy for personal
                                        information that we share with them.
                                    </span>
                                </li>
                                {/* <li>
                                    <span className={classes.listTitle}>Information from social media sites and other publicly available sources : </span>
                                    <span>When you interact or engage with us on social media sites such as Facebook, Twitter, Google+ and Instagram through posts, comments, questions and other interactions, we may collect such publicly available information, including profile information, to allow us to connect with you, improve our products, or better understand user reactions and issues. We must tell you that once collected, this information may remain with us even if you delete it from the social media sites. We may also add and update information about you, from other publicly available sources.</span>
                                </li> */}
                                <li>
                                    <span className={classes.listTitle}>
                                        Signups using federated authentication service providers :{' '}
                                    </span>
                                    <span>
                                        You can log in to your account using supported federated authentication service
                                        providers such as Google, LinkedIn. These services will authenticate your
                                        identity and give you the option to share certain personal information with us,
                                        such as your name and email address.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>
                                        Information from application log and mobile analytics :{' '}
                                    </span>
                                    <span>
                                        We collect information about your use of our products, services and mobile
                                        applications from application logs and in-house usage analytics tools, and use
                                        it to understand how your business use and needs can improve our products. This
                                        information includes clicks, scrolls, features accessed, access time and
                                        frequency, errors generated, performance data, storage utilized, user settings
                                        and configurations, and devices used to access and their locations.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Testimonials : </span>
                                    <span>
                                        When you authorize us to post testimonials about our products and services on
                                        websites, we may include your name and other personal information in the
                                        testimonial. You will be given an opportunity to review and approve the
                                        testimonial before we post it. If you wish to update or delete your testimonial,
                                        you can contact us.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Legal Obligations and requirements : </span>
                                    <span>
                                        We may disclose information for compliance of the legal obligations and
                                        requirements applicable upon us for the following
                                    </span>
                                    <ul style={{ marginLeft: 40 }}>
                                        <li>
                                            <p>
                                                <b>
                                                    Disclosure in compliance with legal obligations or statutory
                                                    requirements
                                                </b>{' '}
                                                (We may as be required by law preserve or disclose your personal
                                                information and service data to comply with any applicable law,
                                                regulation, legal process or governmental request, including to meet
                                                national security requirements)
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Enforcement of our rights</b> (We may disclose personal information
                                                and service data to a third party if we believe that such disclosure is
                                                necessary for preventing fraud, investigating any suspected illegal
                                                activity, enforcing our agreements or policies, or protecting the safety
                                                of our users)
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Business Transfer</b> (We do not intend to sell our business.
                                                However, in the unlikely event that we sell our business or get acquired
                                                or merged, we will ensure that the acquiring entity is legally bound to
                                                honour our commitments to you. We will notify you via email or through a
                                                prominent notice on our website of any change in ownership or in the
                                                uses of your personal information and service data. We will also notify
                                                you about any choices you may have regarding your personal information
                                                and service data)
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Compliance with privacy Policy</b> (We make every effort, including
                                                periodic reviews, to ensure that personal information you provide is
                                                used in conformity with this Privacy Policy)
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </dd>

                        <dt className={classes.titles}>5. Data Retention : </dt>
                        <dd className={classes.description}>
                            <p>
                                We shall retain your personal data only for as long as is necessary for the purposes set
                                out in this PrivacyPolicy. We will retain and use your Personal Data to the extent
                                necessary to comply with our legal obligations (for example, if we are required to
                                retain your data to comply with applicable laws), resolve disputes, and enforce our
                                legal agreements and policies.
                            </p>
                            <p>
                                The Company will also retain Usage Data for internal analysis purposes. Usage Data is
                                generally retained for a shorter period of time, except when this data is used to
                                strengthen the security or to improve the functionality of our service, or We are
                                legally obligated to retain this data for longer time periods.
                            </p>
                        </dd>

                        <dt className={classes.titles}>6. General Data Protection Rights (GDPR) : </dt>
                        <dd className={classes.description}>
                            <p>Legal Basis for Processing Personal Data under GDPR : </p>
                            <p>We may process Personal Data under the following conditions : </p>
                            <ol type="a" className={classes.alist}>
                                <li>
                                    <span className={classes.listTitle}>Consent : </span>
                                    <span>
                                        You have given Your consent for processing Personal Data for one or more
                                        specific purposes.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Performance of a contract : </span>
                                    <span>
                                        Provision of Personal Data is necessary for the performance of an agreement with
                                        You and/or for any pre-contractual obligations thereof.{' '}
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Legal obligations : </span>
                                    <span>
                                        Processing Personal Data is necessary for compliance with a legal obligation to
                                        which the Company is subject.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Vital interests : </span>
                                    <span>
                                        Processing Personal Data is necessary in order to protect Your vital interests
                                        or of another natural person.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Public interests : </span>
                                    <span>
                                        Processing Personal Data is related to a task that is carried out in the public
                                        interest or in the exercise of official authority vested in the Company.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Legitimate interests : </span>
                                    <span>
                                        Processing Personal Data is necessary for the purposes of the legitimate
                                        interests pursued by the Company.
                                    </span>
                                </li>
                            </ol>
                            <p>
                                In any case, the Company will gladly help to clarify the specific legal basis that
                                applies to the processing, and in particular whether the provision of Personal Data is a
                                statutory or contractual requirement, or a requirement necessary to enter into a
                                contract.
                            </p>
                            <p>
                                We would like to make sure you are fully aware of all of your data protection rights.
                                Every user is entitled to the following :{' '}
                            </p>
                            <ol type="a" className={classes.alist}>
                                <li>
                                    <span className={classes.listTitle}>The right to access : </span>
                                    <span>
                                        {' '}
                                        You have the right to request copies of your personal data. We may charge you a
                                        small fee for this service.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to rectification : </span>
                                    <span>
                                        {' '}
                                        You have the right to request that we correct any information you believe is
                                        inaccurate. You also have the right to request that we complete the information
                                        you believe is incomplete.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to erasure : </span>
                                    <span>
                                        {' '}
                                        You have the right to request that we erase your personal data, under certain
                                        conditions.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to restrict processing : </span>
                                    <span>
                                        {' '}
                                        You have the right to request that we restrict the processing of your personal
                                        data, under certain conditions.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to object to processing</span>
                                    <span>
                                        {' '}
                                        You have the right to object to our processing of your personal data, under
                                        certain conditions.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to data portability</span>
                                    <span>
                                        {' '}
                                        You have the right to request that we transfer the data that we have collected
                                        to another organization, or directly to you, under certain conditions.
                                    </span>
                                </li>
                            </ol>
                            <p>If you would like to exercise any of these rights, please contact us.</p>
                        </dd>

                        <dt className={classes.titles}>
                            7. CCPA Privacy Rights (Do Not Sell My Personal Information) :{' '}
                        </dt>
                        <dd className={classes.description}>
                            <p>Under the CCPA, among other rights, California consumers have the right to : </p>
                            <ol type="a" className={classes.alist}>
                                <li>
                                    Request that a business that collects a consumer&apos;s personal data disclose the
                                    categories and specific pieces of personal data that a business has collected about
                                    consumers.
                                </li>
                                <li>
                                    Request that a business delete any personal data about the consumer that a business
                                    has collected.
                                </li>
                                <li>
                                    Request that a business that sells a consumer&apos;s personal data, not sell the
                                    consumer&apos;s personal data
                                </li>
                                <li>
                                    Right to notice (it is right to be notified which type of personal data is being
                                    collected)
                                </li>
                                <li>
                                    Right to not being discriminated against for exercising any of your common rights.
                                </li>
                            </ol>

                            <p>Under CCPA, personal information does not include : </p>
                            <ol type="a" className={classes.alist}>
                                <li>Publicly available information from government records</li>
                                <li>Deidentified or aggregated consumer information </li>
                                <li>
                                    Request that a business that sells a consumer&apos;s personal data, not sell the
                                    consumer&apos;s personal data
                                </li>
                                <li>Information excluded from the CCPA’s scope, such as : </li>
                                <ul style={{ marginLeft: 40 }}>
                                    <li>
                                        Health or medical information covered by the Health Insurance Portability and
                                        Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                                        Information Act (CMIA) or clinical trial data
                                    </li>
                                    <li>
                                        Personal Information covered by certain sector-specific privacy laws, including
                                        the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or
                                        California Financial Information Privacy Act (FIPA), and the Driver’s Privacy
                                        Protection Act of 1994
                                    </li>
                                </ul>
                            </ol>
                        </dd>

                        <dt className={classes.titles}>8. Age Limitation : </dt>
                        <dd className={classes.description}>
                            <p>
                                To the extent prohibited by applicable law, we do not allow use of our services and
                                websites by anyone younger than 16years old. If you learn that anyone younger than
                                16years old has unlawfully provided us with their personal data, please contact us and
                                we will take the necessary steps to delete such data.
                            </p>
                        </dd>

                        <dt className={classes.titles}>9. Data Security : </dt>
                        <dd className={classes.description}>
                            <p>
                                We take security of data very seriously. We practise utmost care and diligence to
                                protect all and other information provided by you from any loss, misuse and unauthorized
                                access or disclosure. Given the nature of communications and information processing
                                technology, we cannot guarantee that information, during transmission through the
                                Internet or while stored on our systems or otherwise in our care, will be absolutely
                                safe from intrusion from others.
                            </p>
                        </dd>

                        <dt className={classes.titles}>10. Change in this Policy : </dt>
                        <dd className={classes.description}>
                            <p>
                                We may change this Privacy Policy from time to time. Laws, regulations and industry
                                standards evolve, which may make those changes necessary, or we may make changes to our
                                business. We shall keep you informed about the changes to this policy and encourage us
                                to review Privacy Policy for the same.
                            </p>
                            <p>
                                If we make changes that materially after your privacy rights, we will provide additional
                                notice, such as via email. If you disagree with the changes to the new policy, you may
                                deactivate your service account.
                            </p>
                            <p>
                                Contact the customer if you wish to request removal of Personal Data under their
                                control.
                            </p>
                        </dd>
                    </dl>
                </div>
            </div>
        );
    };

    return (
        <div style={{ overflowY: 'hidden' }}>
            <Header />
            <PolicyBlock />
            <Footer />
        </div>
    );
}
