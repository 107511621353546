import React, { useState, useContext } from 'react';
import {
    FormLabel,
    FormControl,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    TextField,
    Button,
    Hidden,
    // Tooltip,
} from '@material-ui/core';
import useStyles from './FormStyle';
// import { useDispatch } from 'react-redux';
// import { useSelector } from 'react-redux';
import MyModal from '../Modal/Modal';
import {
    ToStringFormat,
    getTimeDifference,
    // toDayHour,
    toDays,
    DateDifference,
    countDaysHours,
    onlyCountDayHours,
    toAWSDateFormat,
    // convertTime,
    getTimeOnlyInMinutes,
    getTimeDifferenceMinute,
    toDayHourMinutes,
    // convertTimeInTZ,
    convertTZ,
    convertTime,
} from '../../helpers/date';
import { CountWorkweek } from '../../helpers/dateDifference';
import { giveTz, isoToLocal } from '../../helpers/timezone';
import { isTimePresent, isValidTime, isHoliday } from '../../helpers/dateDifference';
import {
    createLeave,
    // getAdminApprovers,
    storeNotif,
    // getAdminApprovers,
    // getSlackChannel,
    getOtherEmployeeLeaves,
    // getOrganizationWebhook
} from '../../helpers/hasuraapi';
import AuthContext from '../../context/AuthContext';
// import CloseIcon from '@material-ui/icons/CloseRounded';
import ErrorPage from '../Error/ErrorPage';
// import { PopUp } from '../Modal/Modal';
// import { getCalendar } from '../Redux/calendarReducer';
import { DateField2, TimeField } from '../ImportHoliday/FormInputs';
import { sendNotification, sendNotificationOtherEmp } from '../AppNotification/AppNotification';
import {
    sendNotificationForSelfLeave,
    sendNotificationForOthersLeave,
    sendNotificationToEmps,
    sendAutoApproveNotifToSelf,
    storeNotifForAutoAproveSelfLeave,
    storeNotifForAutoApproveOtherLeave,
} from '../WebNotification/Notification';
import { handleTimeoffsForMs } from 'helpers/sendNotif';
import { getCurrentCountryTime, localToISO } from 'helpers/timezone';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardTimePicker,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';
// import { addEmployeeLeave } from '../Redux/employeeLeavesReducer';
// import { addApproverLeave } from '../Redux/approverDashboardLeavesReducer';
// import { addPendingLeave } from '../Redux/dashboardLeavesReducer';
// import { fetchPeople } from '../Redux/peopleReducer';

interface EmployeeFormProps {
    isRequest?: boolean;
    setStep?: any;
    data?: any;
    leaves?: any;
    workWeek?: any;
    holidays?: any;
    userSubscriptionDetail?: any;
}

export function removeStorage() {
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    localStorage.removeItem('category');
    localStorage.removeItem('leaveType');
    localStorage.removeItem('startTime');
    localStorage.removeItem('endTime');
    localStorage.removeItem('oneday');
    localStorage.removeItem('reason');
    localStorage.removeItem('customReason');
}

export default function EmployeeForm(props: EmployeeFormProps) {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const leaveTypes = props.data;
    const leaves = props.leaves;
    const [leaveType, setLeaveType] = useState<string>(
        localStorage.getItem('leaveType') || leaveTypes?.timeoffdb_LeaveType[0]?.leaveTypeName,
    );
    const otherEmployees = leaveTypes?.usermanagementdb_organization_employee?.filter(
        (u: any) => u.Employee.employeeId !== mycontext.EmployeeId,
    );
    const [empName, setEmpName] = useState<string>(
        otherEmployees.length === 0
            ? 'null'
            : otherEmployees[0]?.Employee?.firstName + ' ' + otherEmployees[0]?.Employee?.lastName,
    );
    const [empId, setEmpId] = useState<string>(
        otherEmployees?.length === 0 ? 'null' : otherEmployees[0]?.Employee?.employeeId,
    );
    const [openA, setOpenA] = useState<boolean>(false);
    const [openB, setOpenB] = useState<boolean>(false);

    const [category, setCategory] = useState<string>(localStorage.getItem('category') || 'Full Day');
    const [oneday, setOneday] = useState<boolean>(localStorage.getItem('oneday') === 'true' ? true : false);
    const [approvalRequired, setApprovalRequired] = useState<boolean>(
        leaveTypes.timeoffdb_LeaveType[0]?.approvalRequired,
    );

    const [startDate, setStartDate] = useState<any>(
        localStorage.getItem('startDate')
            ? toAWSDateFormat(localStorage.getItem('startDate'))
            : toAWSDateFormat(convertTZ(new Date().toISOString(), mycontext.country.zoneName)),
    );
    const [endDate, setEndDate] = useState<any>(
        localStorage.getItem('endDate')
            ? toAWSDateFormat(localStorage.getItem('endDate'))
            : toAWSDateFormat(convertTZ(new Date().toISOString(), mycontext.country.zoneName)),
    );
    const [startTime, setStartTime] = useState<any>(
        localStorage.getItem('startTime') || getCurrentCountryTime(mycontext.country.zoneName),
    );
    const [endTime, setEndTime] = useState<any>(
        localStorage.getItem('endTime') || getCurrentCountryTime(mycontext.country.zoneName),
    );
    const reasons = ['Feeling Sick', 'Doctors Appoinment', 'Family Emergency', 'Custom Reason'];
    const [reason, setReason] = useState<string>(localStorage.getItem('reason') || reasons[0]);
    const [customReason, setCustomReason] = useState<string>(localStorage.getItem('customReason') || '');

    //for notification
    const dateString = new Date().toString();
    const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
    const time = new Date().toString().slice(16, 24);
    const dateTime = date + time;

    // useEffect(() => {
    //     return () => {
    //         removeStorage();
    //     };
    // }, []);
    // useEffect(() => {
    //     let isMounted = true;
    //     async function getData() {
    //         const data = await getOrganizationWebhook(mycontext.organizationId, mycontext.token);
    //         if (data.webhookURL !== null) {
    //             mycontext.addWebhookUrl(data.webhookURL);
    //         }
    //         // setLeaveType(leaveTypes.timeoffdb_LeaveType[0]?.leaveTypeName);
    //         // setEmpName(
    //         //     leaveTypes.usermanagementdb_organization_employee[0].Employee?.firstName +
    //         //         ' ' +
    //         //         leaveTypes.usermanagementdb_organization_employee[0].Employee?.lastName,
    //         // );
    //         // setEmpId(leaveTypes.usermanagementdb_organization_employee[0]?.Employee?.employeeId);
    //     }
    //     if (isMounted) {
    //         getData();
    //     }
    //     return () => {
    //         isMounted = false;
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleDates = (v: any) => {
        setStartDate(v);
        setEndDate(v);
    };

    const handleOneDay = (v: boolean) => {
        if (v) {
            setEndDate(startDate);
            setOneday(v);
        }
        setOneday(v);
    };

    const handleLeaveType = (e: any) => {
        setLeaveType(e.target.value as string);
        setApprovalRequired(
            leaveTypes?.timeoffdb_LeaveType.filter((l: any) => l.leaveTypeName === e.target.value)[0].approvalRequired,
        );
    };

    const addLeaveForSelf = async () => {
        localStorage.setItem('startDate', startDate.toString());
        localStorage.setItem('endDate', endDate.toString());
        localStorage.setItem('category', category);
        localStorage.setItem('leaveType', leaveType);
        localStorage.setItem('startTime', startTime.toString());
        localStorage.setItem('endTime', endTime.toString());
        localStorage.setItem('oneday', oneday.toString());
        localStorage.setItem('reason', reason);
        localStorage.setItem('customReason', customReason);
        let obj: any = null;
        let objData: any;
        let notifResp: any;
        setOpenA(false);
        try {
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp'))
                    .length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            if (oneday && category === 'Full Day') {
                if (startDate === null) {
                    throw new Error('Please select valid date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (customReason === '' && reason === reasons[3]) {
                    throw new Error('Please add a reason!');
                }
                if (isHoliday(startDate, endDate, props.holidays)) {
                    throw new Error('You may have holiday on selected date, please select other date!');
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: mycontext.EmployeeId,
                    token: mycontext.token,
                    category: leaveType,
                    startDate: toAWSDateFormat(localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country)),
                    endDate: toAWSDateFormat(localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country)),
                    startTime: null,
                    endTime: null,
                    noOfHours: mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: approvalRequired ? 'pending' : 'approved',
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country),
                        endTime: localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country),
                    },
                };
            } else if (category === 'Full Day') {
                if (startDate === null) {
                    throw new Error('Please select start date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (endDate === null) {
                    throw new Error('Please select end Date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), endDate) < 0) {
                    throw new Error('Please select valid end date!');
                }
                if (customReason === '' && reason === reasons[3]) {
                    throw new Error('Please add a reason!');
                }
                if (DateDifference(startDate, endDate) < 0) {
                    throw new Error('Please select valid end date!');
                }
                if (isHoliday(startDate, endDate, props.holidays)) {
                    throw new Error('You may have holiday on selected date, please select other date!');
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: mycontext.EmployeeId,
                    token: mycontext.token,
                    category: leaveType,
                    startDate: toAWSDateFormat(localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country)),
                    endDate: toAWSDateFormat(localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country)),
                    startTime: null,
                    endTime: null,
                    noOfHours:
                        mycontext.workingHours *
                        (DateDifference(startDate, endDate) + 1 - CountWorkweek(startDate, endDate, props.workWeek)),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: approvalRequired ? 'pending' : 'approved',
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country),
                        endTime: localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country),
                    },
                };
            } else {
                if (startDate === null) {
                    throw new Error('Please select start date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (startTime === null || startTime === '') {
                    throw new Error('Please select startTime!');
                }
                if (endTime === null || endTime === '') {
                    throw new Error('Plese select endTime!');
                }
                if (isValidTime(endTime, convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate)) {
                    throw new Error('Please select valid end time!');
                }
                if (
                    isValidTime(startTime, convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate)
                ) {
                    throw new Error('Please select valid start time!');
                }
                if (parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) < 0) {
                    throw new Error('Please select valid times!');
                }
                if (parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) < 0.5) {
                    throw new Error('You cant add leave less than half hour!');
                }
                if (isHoliday(startDate, endDate, props.holidays)) {
                    throw new Error('You may have holiday on selected date, please select other date!');
                }
                if (
                    getTimeDifference(startTime, endTime) > mycontext.workingHours ||
                    parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) > mycontext.workingHours
                ) {
                    throw new Error(`You can't add leave more than working hours!`);
                }
                if (customReason === '' && reason === reasons[3]) {
                    throw new Error('Please add a reason!');
                }
                if (startTime === 'Inval' || endTime === 'Inval') {
                    throw new Error('Please select valid time!');
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: mycontext.EmployeeId,
                    token: mycontext.token,
                    category: leaveType,
                    startDate: toAWSDateFormat(
                        localToISO(toAWSDateFormat(startDate), `${startTime}:00`, mycontext.country),
                    ),
                    endDate: toAWSDateFormat(
                        localToISO(toAWSDateFormat(startDate), `${endTime}:00`, mycontext.country),
                    ),
                    startTime: startTime,
                    endTime: endTime,
                    noOfHours: parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: approvalRequired ? 'pending' : 'approved',
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), `${startTime}:00`, mycontext.country),
                        endTime: localToISO(toAWSDateFormat(startDate), `${endTime}:00`, mycontext.country),
                    },
                };
            }
            if (
                leaves.find(
                    (l: any) =>
                        toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(startDate) &&
                        l.leaveStatus !== 'denied' &&
                        l.startTime === 'null',
                ) !== undefined
            ) {
                throw new Error('Leave with the same date already present!');
            }
            if (
                leaves.find(
                    (l: any) =>
                        toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(startDate) &&
                        l.leaveStatus !== 'denied' &&
                        l.startTime !== 'null' &&
                        isTimePresent(
                            obj.startTime,
                            isoToLocal(l.isoString?.startTime, mycontext.country)
                                .toTimeString()
                                .split(' ')[0]
                                .substring(0, 5),
                            isoToLocal(l.isoString?.endTime, mycontext.country)
                                .toTimeString()
                                .split(' ')[0]
                                .substring(0, 5),
                        ),
                ) !== undefined
            ) {
                throw new Error('Leave with the same time already present!');
            }
            // if (
            //     leaves.find(
            //         (l: any) =>
            //             l.leaveStatus !== 'denied' &&
            //             l.startTime === 'null' &&
            //             DateDifference(startDate, l.startDate) >= 1 &&
            //             DateDifference(l.endDate, endDate) <= 1,
            //     ) !== undefined
            // ) {
            //     throw new Error('Leave with the same date is present already!');
            // }
            // const obj = {
            //     organizationId: mycontext.organizationId,
            //     employeeId: mycontext.EmployeeId,
            //     token: mycontext.token,
            //     category: leaveType,
            //     startDate: toAWSDateFormat(startDate),
            //     endDate: toAWSDateFormat(endDate),
            //     startTime: startTime,
            //     endTime: endTime,
            //     noOfHours:
            //         startTime !== null
            //             ? getTimeDifference(startTime, endTime)
            //             : mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
            //     reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
            //     leaveStatus: 'pending',
            // };
            const resp = await createLeave(obj);
            if (resp.error) {
                throw new Error('Failed to Add Leave!');
            } else {
                removeStorage();
            }

            // await getPendingLeaves({
            //     organizationId: mycontext.organizationId,
            //     token: mycontext.token,
            // });
            // setSeverity('success');
            // setAlert(true);
            // setAlertMessage('Leave added successfully!');

            // setSeverity('success');
            // setAlert(true);
            // setAlertMessage('Leave added successfully!');
            mycontext.togglePopup({
                alertMessage: 'Leave added successfully!',
                severity: 'success',
            });
            //web notification
            if (approvalRequired === true) {
                // console.log('leaveId: ', resp.returning[0].leaveId);
                objData = {
                    empId: mycontext.EmployeeId,
                    leaveId: resp.returning[0].leaveId,
                    notif: mycontext.fname + ' ' + mycontext.lname + ' has added a time-off.',
                    orgId: mycontext.organizationId,
                    notifDate: dateTime,
                    token: mycontext.token,
                    isAdmin: 'admin',
                    approverId: mycontext.EmployeeId,
                };
                notifResp = await storeNotif(objData);
                if (notifResp.error) {
                    throw new Error('Failed to store notification!');
                }
                sendNotificationForSelfLeave(
                    mycontext.EmployeeId,
                    mycontext.organizationId,
                    mycontext.fname,
                    mycontext.lname,
                    mycontext.token,
                );
            } else {
                const notifObj = {
                    startTime: startTime,
                    endTime: endTime,
                    startDate: startDate,
                    endDate: endDate,
                    fname: mycontext.fname,
                    lname: mycontext.lname,
                    empId: mycontext.EmployeeId,
                    leaveId: resp.returning[0].leaveId,
                    orgId: mycontext.organizationId,
                    notifDate: dateTime,
                    token: mycontext.token,
                    approverId: null,
                };
                await storeNotifForAutoAproveSelfLeave(notifObj);
                //for auto-approved full day leave
                if (startTime === endTime) {
                    const obj = {
                        startTime: 'null',
                        endTime: 'null',
                    };
                    sendNotificationToEmps(
                        mycontext.EmployeeId,
                        mycontext.fname,
                        mycontext.lname,
                        startDate,
                        endDate,
                        obj.startTime,
                        obj.endTime,
                        mycontext.organizationId,
                        mycontext.token,
                    );
                    sendAutoApproveNotifToSelf(
                        mycontext.EmployeeId,
                        mycontext.organizationId,
                        mycontext.token,
                        startDate,
                        endDate,
                        obj.startTime,
                        obj.endTime,
                    );
                } else {
                    //for custom auto-approved leave
                    sendNotificationToEmps(
                        mycontext.EmployeeId,
                        mycontext.fname,
                        mycontext.lname,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                        mycontext.organizationId,
                        mycontext.token,
                    );
                    sendAutoApproveNotifToSelf(
                        mycontext.EmployeeId,
                        mycontext.organizationId,
                        mycontext.token,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                    );
                }
            }
            await sendNotification(
                mycontext.EmployeeId,
                mycontext.organizationId,
                mycontext.token,
                mycontext.fname,
                approvalRequired,
                obj.startDate,
                obj.endDate,
                obj.startTime,
                obj.endTime,
            );
            // handleTimeoffs({
            //     approvalRequired: approvalRequired,
            //     empId: mycontext.EmployeeId,
            //     orgId: mycontext.organizationId,
            //     otherEmp: mycontext.EmployeeId,
            //     token: mycontext.token,
            //     leaveType: leaveType,
            //     reason: reason === reasons[3] ? customReason : reason,
            //     startDate: startDate,
            //     endDate: endDate,
            //     startTime: (oneday && category === 'Full Day') || category === 'Full Day' ? null : startTime,
            //     endTime: (oneday && category === 'Full Day') || category === 'Full Day' ? null : endTime,
            //     leaveId: resp.returning[0].leaveId,
            //     notif_type: 'timeoffRequest',
            //     isoString: resp.returning[0].isoString,
            //     requestFor: 'self',
            //     otherUser: null,
            // });

            handleTimeoffsForMs({
                approvalRequired: approvalRequired,
                empId: mycontext.EmployeeId,
                orgId: mycontext.organizationId,
                otherEmp: mycontext.EmployeeId,
                token: mycontext.token,
                leaveType: leaveType,
                reason: reason === reasons[3] ? customReason : reason,
                startDate: startDate,
                endDate: endDate,
                startTime: (oneday && category === 'Full Day') || category === 'Full Day' ? null : startTime,
                endTime: (oneday && category === 'Full Day') || category === 'Full Day' ? null : endTime,
                leaveId: resp.returning[0].leaveId,
                notif_type: 'timeoffRequest',
                isoString: resp.returning[0].isoString,
                requestFor: 'self',
                otherUser: null,
            });
            // console.log("Shraddha's code");
            // await getDeviceToken(mycontext.EmployeeId, mycontext.organizationId, mycontext.token);
            setTimeout(() => props.setStep(0), 2000);
            // setTimeout(() => props.setStep(0), 1000);
        } catch (err) {
            if (err?.message) {
                mycontext.togglePopup({
                    alertMessage: err?.message,
                    severity: 'error',
                });
            } else {
                mycontext.togglePopup({
                    alertMessage: 'Failed to add leave!',
                    severity: 'error',
                });
            }
        }
    };

    const addLeaveForOther = async () => {
        localStorage.setItem('startDate', startDate.toString());
        localStorage.setItem('endDate', endDate.toString());
        localStorage.setItem('category', category);
        localStorage.setItem('leaveType', leaveType);
        localStorage.setItem('startTime', startTime.toString());
        localStorage.setItem('endTime', endTime.toString());
        localStorage.setItem('oneday', oneday.toString());
        localStorage.setItem('reason', reason);
        localStorage.setItem('customReason', customReason);
        let obj: any = null;

        setOpenB(false);
        try {
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp'))
                    .length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            if (empName === 'null' || empId === 'null') {
                throw new Error('Please select the employee!');
            }
            if (oneday && category === 'Full Day') {
                if (startDate === null) {
                    throw new Error('Please select valid date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (reason === reason[3] && customReason === '') {
                    throw new Error('Please add a reason!');
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: empId,
                    token: mycontext.token,
                    category: leaveType,
                    startDate: toAWSDateFormat(localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country)),
                    endDate: toAWSDateFormat(localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country)),
                    startTime: null,
                    endTime: null,
                    noOfHours: mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: approvalRequired ? 'pending' : 'approved',
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country),
                        endTime: localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country),
                    },
                };
            } else if (category === 'Full Day') {
                if (startDate === null) {
                    throw new Error('Please select start date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (endDate === null) {
                    throw new Error('Please select end Date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), endDate) < 0) {
                    throw new Error('Please select valid end date!');
                }
                if (DateDifference(startDate, endDate) < 0) {
                    throw new Error('Please select valid end date!');
                }
                if (reason === reason[3] && customReason === '') {
                    throw new Error('Please add a reason!');
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: empId,
                    token: mycontext.token,
                    category: leaveType,
                    startDate: toAWSDateFormat(localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country)),
                    endDate: toAWSDateFormat(localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country)),
                    startTime: null,
                    endTime: null,
                    noOfHours:
                        mycontext.workingHours *
                        (DateDifference(startDate, endDate) + 1 - CountWorkweek(startDate, endDate, props.workWeek)),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: approvalRequired ? 'pending' : 'approved',
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country),
                        endTime: localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country),
                    },
                };
            } else {
                if (startDate === null) {
                    throw new Error('Please select start date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (startTime === null || startTime === '') {
                    throw new Error('Please select startTime!');
                }
                if (endTime === null || endTime === '') {
                    throw new Error('Plese select endTime!');
                }
                if (
                    isValidTime(startTime, convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate)
                ) {
                    throw new Error('Please select valid start time!');
                }
                if (isValidTime(endTime, convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate)) {
                    throw new Error('Please select valid end time!');
                }
                if (parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) < 0) {
                    throw new Error('Please select valid times!');
                }
                if (parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) < 0.5) {
                    throw new Error('You cant add leave less than half hour!');
                }
                if (
                    getTimeDifference(startTime, endTime) > mycontext.workingHours ||
                    parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) > mycontext.workingHours
                ) {
                    throw new Error(`You can't add leave more than working hours!`);
                }
                if (reason === reason[3] && customReason === '') {
                    throw new Error('Please add a reason!');
                }
                if (startTime === 'Inval' || endTime === 'Inval') {
                    throw new Error('Please select valid time!');
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: empId,
                    token: mycontext.token,
                    category: leaveType,
                    startDate: toAWSDateFormat(
                        localToISO(toAWSDateFormat(startDate), `${startTime}:00`, mycontext.country),
                    ),
                    endDate: toAWSDateFormat(
                        localToISO(toAWSDateFormat(startDate), `${endTime}:00`, mycontext.country),
                    ),
                    startTime: startTime,
                    endTime: endTime,
                    noOfHours: parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: approvalRequired ? 'pending' : 'approved',
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), `${startTime}:00`, mycontext.country),
                        endTime: localToISO(toAWSDateFormat(startDate), `${endTime}:00`, mycontext.country),
                    },
                };
            }
            // const obj = {
            //     organizationId: mycontext.organizationId,
            //     employeeId: empId,
            //     token: mycontext.token,
            //     category: leaveType,
            //     startDate: toAWSDateFormat(startDate),
            //     endDate: toAWSDateFormat(endDate),
            //     startTime: startTime,
            //     endTime: endTime,
            //     noOfHours:
            //         startTime !== null
            //             ? getTimeDifference(startTime, endTime)
            //             : mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
            //     reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
            //     leaveStatus: 'pending',
            // };
            const userLeaves: any = await getOtherEmployeeLeaves({
                userId: empId,
                organizationId: mycontext.organizationId,
                token: mycontext.token,
            });
            if (
                userLeaves.find(
                    (l: any) =>
                        toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(startDate) &&
                        l.leaveStatus !== 'denied' &&
                        l.startTime === 'null',
                ) !== undefined
            ) {
                throw new Error('Leave with the same date already present!');
            }
            if (
                userLeaves.find(
                    (l: any) =>
                        toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(startDate) &&
                        l.leaveStatus !== 'denied' &&
                        l.startTime !== 'null' &&
                        isTimePresent(
                            obj.startTime,
                            isoToLocal(l.isoString?.startTime, mycontext.country)
                                .toTimeString()
                                .split(' ')[0]
                                .substring(0, 5),
                            isoToLocal(l.isoString?.endTime, mycontext.country)
                                .toTimeString()
                                .split(' ')[0]
                                .substring(0, 5),
                        ),
                ) !== undefined
            ) {
                throw new Error('Leave with the same time already present!');
            }
            const resp = await createLeave(obj);
            if (resp.error) {
                throw new Error('Failed to Add Leave!');
            } else {
                removeStorage();
            }
            // handleTimeoffs({
            //     approvalRequired: approvalRequired,
            //     empId: empId,
            //     orgId: mycontext.organizationId,
            //     otherEmp: mycontext.EmployeeId,
            //     token: mycontext.token,
            //     leaveType: leaveType,
            //     reason: reason === reasons[3] ? customReason : reason,
            //     startDate: startDate,
            //     endDate: endDate,
            //     startTime: (oneday && category === 'Full Day') || category === 'Full Day' ? null : startTime,
            //     endTime: (oneday && category === 'Full Day') || category === 'Full Day' ? null : endTime,
            //     leaveId: resp.returning[0].leaveId,
            //     notif_type: 'timeoffRequest',
            //     isoString: resp.returning[0].isoString,
            //     requestFor: 'other',
            //     otherUser: null,
            // });
            handleTimeoffsForMs({
                approvalRequired: approvalRequired,
                empId: empId,
                orgId: mycontext.organizationId,
                otherEmp: mycontext.EmployeeId,
                token: mycontext.token,
                leaveType: leaveType,
                reason: reason === reasons[3] ? customReason : reason,
                startDate: startDate,
                endDate: endDate,
                startTime: (oneday && category === 'Full Day') || category === 'Full Day' ? null : startTime,
                endTime: (oneday && category === 'Full Day') || category === 'Full Day' ? null : endTime,
                leaveId: resp.returning[0].leaveId,
                notif_type: 'timeoffRequest',
                isoString: resp.returning[0].isoString,
                requestFor: 'other',
                otherUser: null,
            });

            // if (mycontext.webhookUrl !== '' || mycontext.webhookUrl !== null) {
            //     postMessageToSlack();
            // }
            // await getPendingLeaves({
            //     organizationId: mycontext.organizationId,
            //     token: mycontext.token,
            // });
            // await getApproverLeaves({
            //     employeeId: mycontext.EmployeeId,
            //     organizationId: mycontext.organizationId,
            //     token: mycontext.token,
            // });
            // setSeverity('success');
            // setAlert(true);
            // setAlertMessage('Leave added successfully!');

            mycontext.togglePopup({
                alertMessage: 'Leave added successfully!',
                severity: 'success',
            });
            //web notification
            if (approvalRequired === true) {
                // console.log('leaveId1: ', resp.returning[0].leaveId);
                const objData = {
                    empId: mycontext.EmployeeId,
                    leaveId: resp.returning[0].leaveId,
                    notif:
                        mycontext.fname + ' ' + mycontext.lname + ' has added a time-off on behalf of ' + empName + '.',
                    orgId: mycontext.organizationId,
                    notifDate: dateTime,
                    token: mycontext.token,
                    isAdmin: 'admin',
                    approverId: mycontext.EmployeeId,
                };
                const notifResp = await storeNotif(objData);
                if (notifResp.error) {
                    throw new Error('Failed to store notification!');
                }
                sendNotificationForOthersLeave(
                    empId,
                    mycontext.organizationId,
                    mycontext.token,
                    mycontext.fname,
                    mycontext.lname,
                    empName,
                );
            } else {
                const notifObj = {
                    startTime: startTime,
                    endTime: endTime,
                    startDate: startDate,
                    endDate: endDate,
                    empName: empName,
                    empId: empId,
                    leaveId: resp.returning[0].leaveId,
                    orgId: mycontext.organizationId,
                    notifDate: dateTime,
                    token: mycontext.token,
                    approverId: null,
                };
                await storeNotifForAutoApproveOtherLeave(notifObj);
                //full day auto-approved leave for other employee
                if (startTime === endTime) {
                    const obj = {
                        startTime: 'null',
                        endTime: 'null',
                    };
                    sendNotificationToEmps(
                        empId,
                        mycontext.fname,
                        mycontext.lname,
                        startDate,
                        endDate,
                        obj.startTime,
                        obj.endTime,
                        mycontext.organizationId,
                        mycontext.token,
                        empName,
                    );
                    sendAutoApproveNotifToSelf(
                        empId,
                        mycontext.organizationId,
                        mycontext.token,
                        startDate,
                        endDate,
                        obj.startTime,
                        obj.endTime,
                    );
                } else {
                    sendNotificationToEmps(
                        empId,
                        mycontext.fname,
                        mycontext.lname,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                        mycontext.organizationId,
                        mycontext.token,
                        empName,
                    );
                    sendAutoApproveNotifToSelf(
                        empId,
                        mycontext.organizationId,
                        mycontext.token,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                    );
                }
            }
            await sendNotificationOtherEmp(
                empId,
                mycontext.organizationId,
                mycontext.token,
                mycontext.fname,
                empName,
                approvalRequired,
                obj.startDate,
                obj.endDate,
                obj.startTime,
                obj.endTime,
            );

            setTimeout(() => props.setStep(0), 2000);
            // setTimeout(() => refreshPage(), 1000);
        } catch (err) {
            if (err?.message) {
                mycontext.togglePopup({
                    alertMessage: err?.message,
                    severity: 'error',
                });
            } else {
                mycontext.togglePopup({
                    alertMessage: err?.message,
                    severity: 'error',
                });
            }
        }
    };

    const PreviewLeavesPartOther = () => {
        const daysPerYear: any = leaveTypes?.timeoffdb_LeaveType.filter((l: any) => l.leaveTypeName === leaveType)[0]
            ?.daysPerYear;
        const unlimitedDays: any = leaveTypes?.timeoffdb_LeaveType.filter((l: any) => l.leaveTypeName === leaveType)[0]
            ?.unlimitedDays;
        const selectedObject: any = leaveTypes?.timeoffdb_get_totalhours_org?.filter(
            (l: any) => l?.employeeId === empId && l?.leaveType === leaveType,
        )[0];
        const totalHoursLeave: any = selectedObject !== undefined ? selectedObject.totalHoursLeave : 0;
        const workingHours: any = mycontext.workingHours;
        const totalAvailable: any =
            unlimitedDays || daysPerYear === 0 ? 'Unlimited' : daysPerYear * workingHours - totalHoursLeave;
        // const available = totalAvailable === 'Unlimited' ? 'Unlimited' : countDaysHours(totalAvailable, workingHours);
        // const currentBooked =
        //     category !== 'Full Day'
        //         ? getTimeDifference(startTime, endTime).toString() === 'NaN'
        //             ? 0
        //             : toTime(getTimeDifference(startTime, endTime))
        //         : startDate === null || endDate === null
        //         ? 0
        //         : toDays((workingHours * (DateDifference(startDate, endDate) + 1)) / workingHours);
        // const remaining =
        //     category !== 'Full Day'
        //         ? getTimeDifference(startTime, endTime).toString() === 'NaN'
        //             ? 'Calculating'
        //             : totalAvailable === 'Unlimited'
        //             ? 'Unlimited'
        //             : countDaysHours(totalAvailable - getTimeDifference(startTime, endTime), workingHours)
        //         : startDate === null || endDate === null
        //         ? 'Calculating'
        //         : totalAvailable === 'Unlimited'
        //         ? 'Unlimited'
        //         : countDaysHours(
        //               totalAvailable - workingHours * (DateDifference(startDate, endDate) + 1),
        //               workingHours,
        //           );
        return (
            <div className={classes.modalContentDiv2}>
                {category !== 'Full Day' ? (
                    getTimeDifference(startTime, endTime).toString() === 'NaN' ? null : totalAvailable ===
                      'Unlimited' ? null : totalAvailable - parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) >
                      0.5 ? null : (
                        <div className={classes.modalContentDiv}>
                            <span className={classes.errorText}>{`${empName} don't have sufficient leaves`}</span>
                        </div>
                    )
                ) : totalAvailable === 'Unlimited' ? null : onlyCountDayHours(
                      totalAvailable - mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                      mycontext.workingHours,
                  )?.d >= 0 ? null : (
                    <div className={classes.modalContentDiv}>
                        <span className={classes.errorText}>{`${empName} don't have sufficient leaves`}</span>
                    </div>
                )}
                <p>
                    You are submitting a <strong>{leaveType}</strong> time-off request on behalf of{' '}
                    <strong>{empName === 'null' ? 'Unknown' : empName}</strong>
                </p>
                {/* <p>
                    Date: { startDate === endDate ? category !== 'Full Day'
                        ? getTimeDifference(startTime, endTime).toString() === 'NaN'
                            ? '0 Hr'
                            : toTime(getTimeDifference(startTime, endTime))
                        : startDate === null || endDate === null
                        ? '0 Days'
                        : toDays(
                              (mycontext.workingHours * (DateDifference(startDate, endDate) + 1)) /
                                  mycontext.workingHours,
                          )}
                </p> */}
                <p>
                    {startDate === endDate ? (
                        category !== 'Full Day' ? (
                            <>
                                <strong>Date: </strong> {startDate} from{' '}
                                {/* {convertTimeInTZ(startTime, new Date().toString().split(' ')[5])} to{' '}
                                {convertTimeInTZ(endTime, new Date().toString().split(' ')[5])} ({' '} */}
                                {convertTime(startTime)} to {convertTime(endTime)}({' '}
                                {getTimeDifferenceMinute(startTime, endTime)} )
                            </>
                        ) : (
                            <>
                                <strong>Date: </strong>
                                {startDate} ({' '}
                                {toDays(
                                    (mycontext.workingHours * (DateDifference(startDate, endDate) + 1)) /
                                        mycontext.workingHours,
                                )}{' '}
                                )
                            </>
                        )
                    ) : (
                        <>
                            <strong>Date: </strong>
                            {startDate} to {endDate} ({' '}
                            {toDays(
                                (mycontext.workingHours * (DateDifference(startDate, endDate) + 1)) /
                                    mycontext.workingHours,
                            )}{' '}
                            )
                        </>
                    )}
                </p>
                <p>
                    <strong>Reason</strong>: {reason === reasons[3] ? customReason.split('\n').join(' ') : reason}
                </p>
                <div className={classes.leftBtns}>
                    <Button
                        className={classes.btnOutlined2}
                        onClick={() => setOpenB(false)}
                        variant="outlined"
                        color="primary"
                        disableRipple={true}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="submitBtn"
                        className={classes.btn2}
                        variant="contained"
                        color="primary"
                        disableRipple={true}
                        disabled={
                            category !== 'Full Day'
                                ? getTimeDifference(startTime, endTime).toString() === 'NaN'
                                    ? false
                                    : totalAvailable === 'Unlimited'
                                    ? false
                                    : totalAvailable - parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) > 0.5
                                    ? false
                                    : true
                                : startDate === null || endDate === null
                                ? false
                                : totalAvailable === 'Unlimited'
                                ? false
                                : onlyCountDayHours(
                                      totalAvailable -
                                          mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                                      mycontext.workingHours,
                                  )?.d >= 0
                                ? false
                                : true
                        }
                        onClick={() => addLeaveForOther()}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        );
    };

    interface PreviewProps {
        totalAvailable?: any;
        isMobile?: boolean;
    }
    const PreviewLeavesPart = (props: PreviewProps) => {
        return (
            <>
                <div className={props.isMobile ? classes.modalDivTopMobile : classes.modalDivTop}>
                    <p>
                        As of Today:{' '}
                        <span>
                            {ToStringFormat(
                                convertTZ(new Date().toISOString(), mycontext.country.zoneName),
                                'DD-MM-YYYY',
                            )}
                        </span>
                    </p>
                </div>
                <div className={classes.modalDivBottom}>
                    <div className={classes.modalContentDiv}>
                        <p>Available balance</p>
                        <span className={classes.span1}>
                            {props.totalAvailable === 'Unlimited'
                                ? 'Unlimited'
                                : toDayHourMinutes(props.totalAvailable, mycontext.workingHours)}
                        </span>
                    </div>
                    <div className={classes.modalContentDiv}>
                        <p>Currently Booked</p>
                        <span className={classes.span2}>
                            {category !== 'Full Day'
                                ? getTimeDifference(startTime, endTime).toString() === 'NaN'
                                    ? '-'
                                    : getTimeDifferenceMinute(startTime, endTime)
                                : startDate === null || endDate === null
                                ? 0
                                : toDays(
                                      (mycontext.workingHours * (DateDifference(startDate, endDate) + 1)) /
                                          mycontext.workingHours,
                                  ) === 'NaN Days'
                                ? '-'
                                : toDays(
                                      (mycontext.workingHours * (DateDifference(startDate, endDate) + 1)) /
                                          mycontext.workingHours,
                                  )}
                        </span>
                    </div>
                    <div className={classes.modalContentDiv}>
                        <p>Balance after booked Time-off</p>
                        <span className={classes.span3}>
                            {category !== 'Full Day'
                                ? getTimeDifference(startTime, endTime).toString() === 'NaN'
                                    ? '-'
                                    : props.totalAvailable === 'Unlimited'
                                    ? 'Unlimited'
                                    : toDayHourMinutes(
                                          props.totalAvailable -
                                              parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours),
                                          mycontext.workingHours,
                                      )
                                : startDate === null || endDate === null
                                ? 'Calculating'
                                : props.totalAvailable === 'Unlimited'
                                ? 'Unlimited'
                                : countDaysHours(
                                      props.totalAvailable -
                                          mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                                      mycontext.workingHours,
                                  ) === 'NaN Days NaN Hr'
                                ? '-'
                                : countDaysHours(
                                      props.totalAvailable -
                                          mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                                      mycontext.workingHours,
                                  )}
                        </span>
                    </div>
                    <div className={classes.modalContentDiv}>
                        {category !== 'Full Day' ? (
                            getTimeDifference(startTime, endTime).toString() === 'NaN' ? null : props.totalAvailable ===
                              'Unlimited' ? null : props.totalAvailable -
                                  parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) >
                              0.5 ? null : (
                                <span className={classes.errorText}>{`You don't have sufficient leaves`}</span>
                            )
                        ) : startDate === null || endDate === null ? null : props.totalAvailable ===
                          'Unlimited' ? null : onlyCountDayHours(
                              props.totalAvailable - mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                              mycontext.workingHours,
                          )?.d >= 0 ? null : (
                            <span className={classes.errorText}>{`You don't have sufficient leaves`}</span>
                        )}
                    </div>
                    {props.isMobile && (
                        <div>
                            <Button
                                className={classes.btn}
                                id="submitbtn"
                                variant="contained"
                                color="primary"
                                disableRipple={true}
                                disabled={
                                    category !== 'Full Day'
                                        ? getTimeDifference(startTime, endTime).toString() === 'NaN'
                                            ? false
                                            : props.totalAvailable === 'Unlimited'
                                            ? false
                                            : props.totalAvailable -
                                                  parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) >
                                              0.5
                                            ? false
                                            : true
                                        : startDate === null || endDate === null
                                        ? false
                                        : props.totalAvailable === 'Unlimited'
                                        ? false
                                        : onlyCountDayHours(
                                              props.totalAvailable -
                                                  mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                                              mycontext.workingHours,
                                          )?.d >= 0
                                        ? false
                                        : true
                                }
                                onClick={() => addLeaveForSelf()}
                            >
                                Submit
                            </Button>
                        </div>
                    )}
                </div>
            </>
        );
    };

    if (leaveTypes.isLoading) {
        return (
            <div className={classes.main}>
                <div className={classes.allfields2}>
                    <div className={classes.fieldDiv}></div>
                    <div className={classes.fieldDivDateTime}>
                        <div></div>
                        <div className={classes.fieldDiv}></div>
                    </div>

                    {category === 'Full Day' && <div></div>}

                    {category === 'Full Day' && (
                        <div className={classes.fieldDivDateTime}>
                            <div></div>
                            <div></div>
                        </div>
                    )}

                    {category !== 'Full Day' && (
                        <div className={classes.fieldDivDateTimeSecond}>
                            <div></div>
                            <div></div>
                        </div>
                    )}

                    <div className={classes.fieldDiv}></div>
                </div>
                <div className={classes.leftBtns}></div>
            </div>
        );
    } else if (leaveTypes.errMess) {
        return <ErrorPage title="Something went wrong!" />;
    } else {
        const daysPerYear: any = leaveTypes?.timeoffdb_LeaveType.filter((l: any) => l.leaveTypeName === leaveType)[0]
            ?.daysPerYear;
        const unlimitedDays: any = leaveTypes?.timeoffdb_LeaveType.filter((l: any) => l.leaveTypeName === leaveType)[0]
            ?.unlimitedDays;
        const leave: any = leaveTypes?.timeoffdb_get_totalhours.filter((l: any) => l.leaveType === leaveType)[0];
        const totalHoursLeave = leave ? leave?.totalHoursLeave : 0;
        const totalAvailable: any =
            unlimitedDays || daysPerYear === 0 ? 'Unlimited' : daysPerYear * mycontext.workingHours - totalHoursLeave;
        if (props.isRequest) {
            return (
                <>
                    {/* <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} /> */}
                    <MyModal
                        setOpen={setOpenB}
                        open={openB}
                        onModalClosed={() => setOpenB(false)}
                        title="Time-Off for Other Employee"
                    >
                        <>
                            <PreviewLeavesPartOther />
                        </>
                    </MyModal>
                    <div id="other" className={classes.main}>
                        <div className={classes.allfields2}>
                            <div className={classes.fieldDiv}>
                                <FormLabel className={classes.formLabelStyle}>Employee Name</FormLabel>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        id="empSelect"
                                        value={empName === 'null' ? 'No employees found' : empName}
                                        className={classes.inputSelectStyle}
                                        onChange={(e: any) => setEmpName(e.target.value as string)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                style: { marginTop: -10 },
                                            },
                                        }}
                                    >
                                        {otherEmployees.length === 0 ? (
                                            <MenuItem value="No employees found">No employees found</MenuItem>
                                        ) : (
                                            otherEmployees.map((p: any, i: number) => (
                                                <MenuItem
                                                    id={'employee-' + i}
                                                    key={i}
                                                    value={p.Employee?.firstName + ' ' + p.Employee?.lastName}
                                                    onClick={() => setEmpId(p.Employee?.employeeId)}
                                                >
                                                    {p.Employee?.firstName + ' ' + p.Employee?.lastName}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.fieldDivDateTime2}>
                                <div>
                                    <FormLabel className={classes.formLabelStyle}>Time-off category</FormLabel>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            id="categorySelect"
                                            value={leaveType}
                                            className={classes.inputSelectStyleOther}
                                            onChange={handleLeaveType}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                },
                                                getContentAnchorEl: null,
                                                PaperProps: {
                                                    style: { marginTop: -10 },
                                                },
                                            }}
                                        >
                                            {leaveTypes?.timeoffdb_LeaveType.map((p: any, i: number) => (
                                                <MenuItem key={i} value={p.leaveTypeName} id={'category-' + i}>
                                                    {p.leaveTypeName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={classes.mobileToPart}>
                                    <FormLabel className={classes.formLabelStyle}>Type</FormLabel>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            id="fullCustomSelect"
                                            value={category}
                                            className={classes.inputSelectStyleOther}
                                            onChange={(e: any) => {
                                                setCategory(e.target.value as string);
                                                setEndDate(startDate);
                                            }}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                },
                                                getContentAnchorEl: null,
                                                PaperProps: {
                                                    style: { marginTop: -10 },
                                                },
                                            }}
                                        >
                                            <MenuItem value="Full Day" id="fullDay">
                                                Full Day
                                            </MenuItem>
                                            <MenuItem value="Custom Time-off" id="customOff">
                                                Custom Time-off
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {category === 'Full Day' && (
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="checked"
                                                name="oneday"
                                                color="primary"
                                                checked={oneday}
                                                onChange={(e: any) => handleOneDay(e.target.checked)}
                                            />
                                        }
                                        label="Request Time-off for just one day"
                                        className={classes.formLabelStyleOneday}
                                    />
                                </div>
                            )}

                            {category === 'Full Day' && (
                                <div className={classes.fieldDivDateTime}>
                                    <div>
                                        <FormLabel className={classes.formLabelStyle}>From</FormLabel>
                                        <FormControl className={classes.formControl}>
                                            {/* <TextField
                                                id="startdate"
                                                name="startdate"
                                                type="date"
                                                value={startDate}
                                                // defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                                onChange={(e: any) =>
                                                    oneday ? handleDates(e.target.value) : setStartDate(e.target.value)
                                                }
                                                className={classes.dateBorder}
                                            /> */}
                                            <DateField2
                                                date={startDate}
                                                setDate={setStartDate}
                                                id="startdatefield"
                                                oneday={oneday}
                                                handleDates={handleDates}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className={classes.mobileToPart}>
                                        <FormLabel className={classes.formLabelStyle}>To</FormLabel>
                                        <FormControl className={classes.formControl}>
                                            {/* <TextField
                                                id="enddate"
                                                name="enddate"
                                                type="date"
                                                value={endDate}
                                                // defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => setEndDate(e.target.value)}
                                                size="small"
                                                disabled={oneday}
                                                className={classes.dateBorder}
                                            /> */}
                                            <DateField2
                                                date={endDate}
                                                setDate={setEndDate}
                                                id="enddatefield"
                                                disable={oneday}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            )}

                            {category !== 'Full Day' && (
                                <div className={classes.fieldDivDateTimeSecond}>
                                    <div>
                                        <FormLabel className={classes.formLabelStyle}>From</FormLabel>
                                        <FormControl className={classes.formControlTime}>
                                            {/* <TextField
                                                id="startdate"
                                                name="startdate"
                                                type="date"
                                                value={startDate}
                                                defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => handleDates(e.target.value)}
                                                size="small"
                                                className={classes.dateBorder}
                                            />
                                            <TextField
                                                id="startTime"
                                                type="time"
                                                value={startTime}
                                                defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => setStartTime(e.target.value)}
                                                size="small"
                                                className={classes.dateBorder}
                                            /> */}
                                            <DateField2
                                                date={startDate}
                                                setDate={setStartDate}
                                                id="startdatefield"
                                                handleDates={handleDates}
                                                disable={false}
                                                oneday={true}
                                            />
                                            <TimeField time={startTime} setTime={setStartTime} id="timefield" />
                                        </FormControl>
                                    </div>
                                    <div className={classes.mobileToPart2}>
                                        <FormLabel className={classes.formLabelStyle}>To</FormLabel>
                                        <FormControl className={classes.formControlTime}>
                                            {/* <TextField
                                                id="enddate"
                                                name="enddate"
                                                type="date"
                                                value={endDate}
                                                defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => setEndDate(e.target.value)}
                                                size="small"
                                                disabled={true}
                                                className={classes.dateBorder}
                                            />
                                            <TextField
                                                id="endTime"
                                                type="time"
                                                value={endTime}
                                                defaultValue={new Date().getTime()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => setEndTime(e.target.value)}
                                                size="small"
                                                className={classes.dateBorder}
                                            /> */}
                                            <DateField2
                                                date={endDate}
                                                setDate={setEndDate}
                                                id="enddatefield"
                                                disable={true}
                                            />
                                            <TimeField time={endTime} setTime={setEndTime} id="timefield" />
                                        </FormControl>
                                    </div>
                                </div>
                            )}

                            <div className={classes.fieldDiv}>
                                <FormLabel className={classes.formLabelStyle}>Reason</FormLabel>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        id="reasonSelect"
                                        value={reason}
                                        className={classes.inputReason}
                                        onChange={(e: any) => setReason(e.target.value as string)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                style: { marginTop: -10 },
                                            },
                                        }}
                                    >
                                        {reasons.map((r: string, i: number) => (
                                            <MenuItem value={r} key={i} id={'offReason-' + i}>
                                                {r}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {reason === 'Custom Reason' ? (
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <TextField
                                            id="customReason"
                                            type="text"
                                            value={customReason}
                                            variant="outlined"
                                            placeholder="Type your reason here"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className={classes.inputReason}
                                            onChange={(e: any) => setCustomReason(e.target.value)}
                                            size="small"
                                            autoFocus
                                            inputProps={{
                                                maxLength: 130,
                                            }}
                                            multiline
                                            rows={2}
                                            style={{ margin: '10px 0' }}
                                        />
                                    </FormControl>
                                ) : null}
                            </div>
                        </div>
                        <div className={classes.leftBtns}>
                            <Button
                                id="submitBtn"
                                className={classes.btn}
                                variant="outlined"
                                color="primary"
                                disableRipple={true}
                                // onClick={() => addLeaveForOther()}
                                onClick={() => setOpenB(true)}
                            >
                                Preview & Submit
                            </Button>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                {/* <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} /> */}
                <MyModal setOpen={setOpenA} open={openA} onModalClosed={() => setOpenA(false)}>
                    <div className={classes.modalDivMobile}>
                        <PreviewLeavesPart totalAvailable={totalAvailable} isMobile={true} />
                    </div>
                </MyModal>
                <div id="personal" className={classes.main}>
                    <div className={classes.LeftForm}>
                        <div className={classes.allfields}>
                            <div className={classes.fieldDiv}>
                                <FormLabel className={classes.formLabelStyle}>Time-off category</FormLabel>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        value={leaveType}
                                        className={classes.inputSelectStyle}
                                        onChange={handleLeaveType}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                style: { marginTop: -10 },
                                            },
                                        }}
                                    >
                                        {leaveTypes?.timeoffdb_LeaveType.map((p: any, i: number) => (
                                            <MenuItem key={i} value={p.leaveTypeName}>
                                                {p.leaveTypeName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className={classes.fieldDiv2}>
                                <FormLabel className={classes.formLabelStyle}>Type</FormLabel>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        value={category}
                                        className={classes.inputSelectStyle}
                                        onChange={(e: any) => {
                                            setCategory(e.target.value as string);
                                            setEndDate(startDate);
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                style: { marginTop: -10 },
                                            },
                                        }}
                                    >
                                        <MenuItem value="Full Day">Full Day</MenuItem>
                                        <MenuItem value="Custom Time-off">Custom Time-off</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            {category === 'Full Day' && (
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="check"
                                                name="oneday"
                                                color="primary"
                                                checked={oneday}
                                                onChange={(e: any) => handleOneDay(e.target.checked)}
                                            />
                                        }
                                        label="Request Time-off for just one day"
                                        className={classes.formLabelStyleOneday}
                                    />
                                </div>
                            )}

                            {category === 'Full Day' && (
                                <div className={classes.fieldDivDateTime}>
                                    <div>
                                        <FormLabel className={classes.formLabelStyle}>From</FormLabel>
                                        <FormControl className={classes.formControl}>
                                            {/* <TextField
                                                id="startdate"
                                                name="startdate"
                                                type="date"
                                                value={startDate}
                                                defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) =>
                                                    oneday ? handleDates(e.target.value) : setStartDate(e.target.value)
                                                }
                                                size="small"
                                                className={classes.dateBorder}
                                            /> */}
                                            <DateField2
                                                date={startDate}
                                                setDate={setStartDate}
                                                id="enddatefield"
                                                handleDates={handleDates}
                                                disable={false}
                                                oneday={oneday}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className={classes.mobileToPart}>
                                        <FormLabel className={classes.formLabelStyle}>To</FormLabel>
                                        <FormControl className={classes.formControl}>
                                            {/* <TextField
                                                id="enddate"
                                                name="enddate"
                                                type="date"
                                                value={endDate}
                                                defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => setEndDate(e.target.value)}
                                                size="small"
                                                disabled={oneday}
                                                className={classes.dateBorder}
                                            /> */}
                                            <DateField2
                                                date={endDate}
                                                setDate={setEndDate}
                                                id="enddatefield"
                                                disable={oneday}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            )}

                            {category !== 'Full Day' && (
                                <div className={classes.fieldDivDateTimeSecond2}>
                                    <div>
                                        <FormLabel className={classes.formLabelStyle}>From</FormLabel>
                                        <FormControl className={classes.formControlTime}>
                                            {/* <TextField
                                                id="startdate"
                                                name="startdate"
                                                type="date"
                                                value={startDate}
                                                defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => handleDates(e.target.value)}
                                                size="small"
                                                className={classes.dateBorder}
                                            />
                                            <TextField
                                                id="time"addLeaveForSelf
                                                type="time"
                                                value={startTime}
                                                defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => setStartTime(e.target.value)}
                                                size="small"
                                                className={classes.dateBorder}
                                            /> */}
                                            <DateField2
                                                date={startDate}
                                                setDate={setStartDate}
                                                id="startdatefield"
                                                handleDates={handleDates}
                                                disable={false}
                                                oneday={true}
                                            />
                                            <TimeField time={startTime} setTime={setStartTime} id="timefield" />
                                        </FormControl>
                                    </div>
                                    <div className={classes.mobileToPart2}>
                                        <FormLabel className={classes.formLabelStyle}>To</FormLabel>
                                        <FormControl className={classes.formControlTime}>
                                            {/* <TextField
                                                id="enddate"
                                                name="enddate"
                                                type="date"
                                                value={endDate}
                                                defaultValue={new Date().toISOString()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => setEndDate(e.target.value)}
                                                size="small"
                                                disabled={true}
                                                className={classes.dateBorder}
                                            />
                                            <TextField
                                                id="time"
                                                type="time"
                                                value={endTime}
                                                defaultValue={new Date().getTime()}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e: any) => setEndTime(e.target.value)}
                                                size="small"
                                                className={classes.dateBorder}
                                            /> */}
                                            <DateField2
                                                date={endDate}
                                                setDate={setEndDate}
                                                id="enddatefield"
                                                disable={true}
                                                oneday={false}
                                            />
                                            <TimeField time={endTime} setTime={setEndTime} id="timefield" />
                                        </FormControl>
                                    </div>
                                </div>
                            )}

                            <div className={classes.fieldDiv}>
                                <FormLabel className={classes.formLabelStyle}>Reason</FormLabel>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        value={reason}
                                        className={classes.inputReason2}
                                        onChange={(e: any) => setReason(e.target.value as string)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                style: { marginTop: -10 },
                                            },
                                        }}
                                    >
                                        {reasons.map((r: string, i: number) => (
                                            <MenuItem value={r} key={i}>
                                                {r}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {reason === 'Custom Reason' ? (
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        style={{ margin: '10px 0' }}
                                    >
                                        <TextField
                                            type="text"
                                            value={customReason}
                                            variant="outlined"
                                            placeholder="Type your reason here"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className={classes.inputReason2}
                                            onChange={(e: any) => setCustomReason(e.target.value)}
                                            size="small"
                                            autoFocus
                                            inputProps={{
                                                maxLength: 135,
                                            }}
                                            multiline
                                            rows={2}
                                        />
                                    </FormControl>
                                ) : null}
                            </div>
                        </div>
                        {/* <div className={open ? classes.modalDiv : classes.hiddenModaldiv}> */}
                        <div className={classes.modalDiv}>
                            <PreviewLeavesPart totalAvailable={totalAvailable} isMobile={false} />
                        </div>
                        {/* <div className={classes.modalDiv}>
                            <div className={classes.closeBtn} onClick={() => setOpen(false)}>
                                <CloseIcon fontSize="small" style={{ fontSize: 15 }} />
                            </div> 
                            <div className={classes.modalDivTop}>
                                <p>
                                    As of Today: <span>{ToStringFormat(new Date(), 'DD-MM-YYYY')}</span>
                                </p>
                            </div>
                            <div className={classes.modalDivBottom}>
                                <div className={classes.modalContentDiv}>
                                    <p>Available balance</p>
                                    <span className={classes.span1}>
                                        {totalAvailable === 'Unlimited'
                                            ? 'Unlimited'
                                            : countDaysHours(totalAvailable, mycontext.workingHours)}
                                    </span>
                                </div>
                                <div className={classes.modalContentDiv}>
                                    <p>Currently Booked</p>
                                    <span className={classes.span2}>
                                        {category !== 'Full Day'
                                            ? getTimeDifference(startTime, endTime).toString() === 'NaN'
                                                ? 0
                                                : toTime(getTimeDifference(startTime, endTime))
                                            : startDate === null || endDate === null
                                            ? 0
                                            : toDays(
                                                  (mycontext.workingHours * (DateDifference(startDate, endDate) + 1)) /
                                                      mycontext.workingHours,
                                              )}
                                    </span>
                                </div>
                                <div className={classes.modalContentDiv}>
                                    <p>Balance after booked Time-off</p>
                                    <span className={classes.span3}>
                                        {category !== 'Full Day'
                                            ? getTimeDifference(startTime, endTime).toString() === 'NaN'
                                                ? 'Calculating'
                                                : totalAvailable === 'Unlimited'
                                                ? 'Unlimited'
                                                : countDaysHours(
                                                      totalAvailable - getTimeDifference(startTime, endTime),
                                                      mycontext.workingHours,
                                                  )
                                            : startDate === null || endDate === null
                                            ? 'Calculating'
                                            : totalAvailable === 'Unlimited'
                                            ? 'Unlimited'
                                            : countDaysHours(
                                                  totalAvailable -
                                                      mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                                                  mycontext.workingHours,
                                              )}
                                    </span>
                                </div>
                                <div className={classes.modalContentDiv}>
                                    {category !== 'Full Day' ? (
                                        getTimeDifference(startTime, endTime).toString() ===
                                        'NaN' ? null : totalAvailable === 'Unlimited' ? null : onlyCountDayHours(
                                              totalAvailable - getTimeDifference(startTime, endTime),
                                              mycontext.workingHours,
                                          )?.d >= 0 ? null : (
                                            <span
                                                className={classes.errorText}
                                            >{`You don't have sufficient leaves`}</span>
                                        )
                                    ) : startDate === null || endDate === null ? null : totalAvailable ===
                                      'Unlimited' ? null : onlyCountDayHours(
                                          totalAvailable -
                                              mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                                          mycontext.workingHours,
                                      )?.d >= 0 ? null : (
                                        <span className={classes.errorText}>{`You don't have sufficient leaves`}</span>
                                    )}
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className={classes.leftBtns}>
                        <Hidden smUp>
                            <Button
                                className={classes.btnOutlined}
                                variant="outlined"
                                color="primary"
                                disableRipple={true}
                                onClick={() => setOpenA(true)}
                            >
                                Preview
                            </Button>
                        </Hidden>
                        <Hidden smDown>
                            <Button
                                className={classes.btn}
                                id="submitbtn"
                                variant="contained"
                                color="primary"
                                disableRipple={true}
                                disabled={
                                    category !== 'Full Day'
                                        ? getTimeDifference(startTime, endTime).toString() === 'NaN'
                                            ? false
                                            : totalAvailable === 'Unlimited'
                                            ? false
                                            : onlyCountDayHours(
                                                  totalAvailable - getTimeDifference(startTime, endTime),
                                                  mycontext.workingHours,
                                              )?.d >= 0
                                            ? false
                                            : true
                                        : startDate === null || endDate === null
                                        ? false
                                        : totalAvailable === 'Unlimited'
                                        ? false
                                        : onlyCountDayHours(
                                              totalAvailable -
                                                  mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                                              mycontext.workingHours,
                                          )?.d >= 0
                                        ? false
                                        : true
                                }
                                onClick={() => {
                                    addLeaveForSelf();
                                }}
                            >
                                Submit
                            </Button>
                        </Hidden>
                    </div>
                </div>
            </>
        );
    }
}
