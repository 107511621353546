import { makeStyles } from '@material-ui/core/styles';

const myStyle = makeStyles(theme => ({
    list: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.light,
    },
    link: {
        width: '100%',
        height: '60px',
        padding: '10px 30px',
        display: 'grid',
        gridTemplateColumns: 'max-content max-content',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        alignItems: 'center',
        columnGap: '25px',
    },
    icon: {
        display: 'flex',
        color: theme.palette.common.white,
        fontSize: 22,
        margin: '0px 5px',
    },
    title: {
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'capitalize',
        letterSpacing: 1,
    },
    hr: {
        width: '80%',
        backgroundColor: theme.palette.text.secondary,
    },
    imgDiv: {
        width: '100%',
        height: 30,
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginTop: 10,
    },
    udyamoImg: {
        maxWidth: '100%',
        height: 14,
    },
    fielddiv: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: 10,
        marginBottom: '15px',
        marginTop: '15px',
        '@media screen and (max-width: 820px)': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
    },
    reporttitle: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: '14px',
        textTransform: 'capitalize',
        paddingBottom: '5px',
    },
    inputSelectStyleOther: {
        width: '100%',
        height: 35,
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '0.03em',
        color: 'theme.palette.text.primary',
        // border: '1px solid black',
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '& svg': {
            color: theme.palette.primary.contrastText,
        },
    },
    fieldDivDateTime: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: 10,
        marginBottom: '15px',
        '@media screen and (max-width: 820px)': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
    },
    formControl: {
        width: '100%',
    },
    mobileToPart: {
        marginTop: 0,
        '@media screen and (max-width: 768px)': {
            marginTop: 15,
        },
    },
    newButtonGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonStyle: {
        whiteSpace: 'nowrap',
        width: 100,
        height: 35,
        marginTop: 10,
        marginRight: 10,
        // border: `1px solid ${theme.palette.text.secondary}`,
        '& span': {
            fontWeight: 'bold',
            fontStyle: 'normal',
            textTransform: 'capitalize',
            letterSpacing: '0.05em',
            color: theme.palette.text.secondary,
        },
    },
    resetButton: {
        '&:focus': {
            outline: 'none',
        },
    },
    btn2: {
        float: 'right',
        marginTop: 10,
        textTransform: 'capitalize',
        width: 100,
        height: 35,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        outline: 'none',
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
        // '@media screen and (min-width: 768px) and (max-width: 1180px)': {
        //     width: 150,
        //     height: 35,
        //     marginTop: 5,
        //     marginRight: 10
        // },
    },
    btn: {
        color: 'white',
        border: '1.5px solid #FFFFFF',
        fontWeight: 600,
        width: '112px',
        height: '35px',
        '&:focus': {
            border: 'none',
            outline: 'none!important',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },
    },
}));

export default myStyle;
