/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopUp } from '../Modal/Modal';
import { Tab, Tabs, Paper, Hidden, Typography } from '@material-ui/core';
//import { Grid, Button, Paper, Hidden, Tabs, Tab } from '@material-ui/core';
// import GeneralSetting from './GeneralSetting';
// import SlackNotifications from './SlackNotifications';
// import LeaveTypes from './LeaveTypes';
import HolidayPage from '../Holiday/Holiday';
import TimeOffPolicy from '../Time-off-polices/TimeOffPolicy';
import NewSettingComponent from './newSetting';
// import Addtoslack from '../AddToSlack/AddToSlack';
import { LoaderPage } from '../Loader/loader';
import ErrorPage from '../Error/ErrorPage';
// import { updateOrganization } from '../../helpers/apifunctions';
// import { fetchSettings } from '../Redux/settingsReducer';
import AuthContext from '../../context/AuthContext';
import { SettingStyles } from './SettingStyle';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { updateOrganizationWebhook } from '../../helpers/hasuraapi';
import config from '../../config.json';
import Subscription from './Subscription';
import { updateActiveTab } from '../Redux/activeTab';
import { useFlagsmith } from 'flagsmith-react';
// import { fetchHolidays } from '../Redux/holidaysReducer';
// import { fetchSettings } from '../Redux/settingsReducer';
import StandupSetting from 'components/Standups/StandupsSetting';
// import { getTimeOff } from 'components/Redux/timeOffPolicyReducer';

export default function SettingComponent(props: any) {
    const dispatch = useDispatch();
    const mycontext = useContext(AuthContext);
    // const history = useHistory();
    const classes = SettingStyles();
    const state = useSelector((state: any) => state);
    const { identify, isIdentified, getTrait, setTrait, hasFeature } = useFlagsmith();
    const identifyUser = async (id: string, email: string) => {
        await identify(id);
        const hasEmail = !!getTrait('email');
        if (!hasEmail) {
            setTrait('email', email);
        }
    };
    if (!isIdentified) {
        identifyUser(mycontext.email, mycontext.email);
    }
    // console.log('hasF', hasFeature('issubscription'));
    // const [value, setValue] = useState<string>('GS');
    // const [num, setNum] = useState<number>(0);

    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    // const [hookUrl, setHookUrl] = useState<string>('');
    const [severity, setSeverity] = useState<string>('');

    // const [leaveTypes, setLeaveTypes] = useState<any>();
    // const [loading, setLoading] = useState<boolean>(true);
    // const mydata = useSelector((state: any) => state.generalSettings);

    // const a11yProps = (index: number) => {
    //     return {
    //         id: `simple-tab-${index}`,
    //         'aria-controls': `simple-tabpanel-${index}`,
    //     };
    // };
    // const handleChange = (event: any, newValue: number) => {
    //     setNum(newValue);
    // };

    // const updateOrganization1 = async (url: any, orgId1: any, token1: any) => {
    //     await updateOrganization(url, orgId1, token1);
    //     // setHookUrl(url);
    //     mycontext.addWebhookUrl(url);
    //     // setSeverity('success');
    //     // setAlert(true);
    //     setAlertMessage('Slack added successfuly!');
    // };
    const location: any = useLocation();
    function getToken(code: any) {
        if (code === undefined || code === null || code === '') {
            // do nothing
        } else {
            const url = `https://slack.com/api/oauth.access?client_id=${config.slack.CLIENT_ID}&client_secret=${config.slack.CLIENT_SECRET}&code=${code}&redirect_uri=https://teamplus.udyamo.com/settings`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(resp => resp.json())
                .then(async json => {
                    if (json.ok) {
                        const url = json.incoming_webhook.url;
                        mycontext.addWebhookUrl(url);
                        const dataResponse = await updateOrganizationWebhook(
                            {
                                orgId: mycontext.organizationId,
                                webhookURL: url,
                            },
                            mycontext.token,
                        );
                        if (dataResponse.error) {
                            setSeverity('error');
                            setAlert(true);
                            setAlertMessage('Not able to integrate Time-offs with Slack.');
                        } else {
                            if (dataResponse > 0) {
                                setSeverity('success');
                                setAlert(true);
                                setAlertMessage('Successfully integrated Time-offs with your Slack Account!');
                            } else {
                                setSeverity('error');
                                setAlert(true);
                                setAlertMessage('Not able to integrate Time-offs with Slack.');
                            }
                        }
                    } else {
                        setSeverity('error');
                        setAlert(true);
                        setAlertMessage(json.error);
                    }
                })
                .catch(error => {
                    setSeverity('error');
                    setAlert(true);
                    setAlertMessage(error);
                });
        }
    }
    useEffect(() => {
        dispatch(updateActiveTab('/settings'));
        const params = queryString.parse(window.location.search);
        const codeVal = params.code;
        if (codeVal === '' || codeVal === null || codeVal === undefined) {
            // do nothing
        } else {
            getToken(codeVal);
        }
        // if (!props.settings?.loading) {
        //     dispatch(fetchSettings(props.settings));
        // }
    }, []);

    // useEffect(() => {
    //     if (!props.holidays?.loading) {
    //         dispatch(fetchHolidays(props.holidays));
    //     }
    // }, [props.holidays]);

    // useEffect(() => {
    //     if (!props.timeoffs?.loading) {
    //         dispatch(getTimeOff(props.timeoffs));
    //     }
    // }, [props.timeoffs]);
    // const SettingsSkeleton = () => {
    //     return (
    //         <>
    //             <Hidden smDown>
    //                 <Grid container>
    //                     <Grid item sm={3}>
    //                         <Skeleton height={40} />
    //                         <Skeleton height={40} />
    //                         <Skeleton height={40} />
    //                     </Grid>
    //                     <Grid item sm={9} style={{ backgroundColor: '#fff' }}>

    //                     </Grid>
    //                 </Grid>
    //             </Hidden>
    //             <Hidden smUp></Hidden>
    //         </>
    //     );
    // };

    // if (mydata.errMess) {
    //     return <ErrorPage title="Server Down" img="/error.png" msg={mydata.errMess} />;
    // } else if (mydata.data === null) {
    //     return <Skeleton count={2} style={{ width: '100%', height: 200, backgroundColor: '#fff' }} />;
    // } else {
    //     if (mydata.isLoading) {
    //         return <Skeleton count={2} style={{ width: '100%', height: 200, backgroundColor: '#fff' }} />;
    //     }
    // const myList = [
    //     {
    //         title: 'General',
    //         block: <GeneralSetting />,
    //     },
    //     {
    //         title: 'Leave Types',
    //         block: <LeaveTypes leaves={mydata.data.data.getOrganization_LeaveType || []} />,
    //     },
    //     {
    //         title: 'Slack Notifications',
    //         block: <SlackNotifications />,
    //     },
    // ];
    // if (data.value === undefined) {
    //     data.value = 0;
    //     let tabran = localStorage.getItem('SettingTabVal');
    //     console.log("tabran=", Number(tabran));
    //     // localStorage.setItem('SettingTabVal', '0');
    // } else {
    //     data.value = 4;
    //     // localStorage.setItem('SettingTabVal', '4');
    // }
    const [value, setValue] = React.useState(
        location.state ? location.state?.value : Number(localStorage.getItem('SettingTabVal')),
    ); //Number(localStorage.getItem('SettingTabVal'))

    const handleTabs = (event: any, val: any) => {
        // console.log("val=", val);
        localStorage.setItem('SettingTabVal', val);
        const tabval = localStorage.getItem('SettingTabVal');
        setValue(Number(tabval));
    };
    if (props.settings?.loading) {
        return <LoaderPage />;
    }
    if (state.holidays.status === 'failed' || state.timeOffPolicy.status === 'failed') {
        return <ErrorPage title="Server Down" img="/error.png" msg="Something went wrong, please try again" />;
    }
    return (
        <div style={{ width: '100%' }}>
            <PopUp setOpen={setAlert} open={alert} message={alertMessage} severity={severity} />
            {/* <div style={{ padding: 5 }}>
                    <h4 style={{ padding: 5, opacity: 0.6 }}>{alertMessage}</h4>
                    <div style={{ padding: '5px 0', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setAlert(false)}
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    Ok
                                </Button>
                    </div>
                </div> */}

            {/* <Grid container>
                <Hidden mdDown>
                    <Grid item xs={12} sm={3} className={classes.mainSettings}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setValue('GS')}
                            className={value === 'GS' ? classes.btnActive : classes.btn}
                            id="generalSettings"
                        >
                            General Settings
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setValue('LT')}
                            className={value === 'LT' ? classes.btnActive : classes.btn}
                            id="leaveTypes"
                        >
                            Leave Types
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setValue('SN')}
                            className={value === 'SN' ? classes.btnActive : classes.btn}
                            id="slackNotifications"
                        >
                            Slack Notifications
                        </Button>
                    </Grid>
                </Hidden>
                <Hidden lgUp>
                    <Tabs value={num} onChange={handleChange} className={classes.tabs}>
                        <Tab
                            label="General"
                            onClick={() => setValue('GS')}
                            {...a11yProps(0)}
                            className={num === 0 ? classes.activeTab : classes.tab}
                        />
                        <Tab
                            label="Leave Types"
                            onClick={() => setValue('LT')}
                            {...a11yProps(1)}
                            className={num === 1 ? classes.activeTab : classes.tab}
                        />
                        <Tab
                            label="Slack Notifications"
                            onClick={() => setValue('SN')}
                            {...a11yProps(2)}
                            className={num === 2 ? classes.activeTab : classes.tab}
                        />
                    </Tabs>
                </Hidden>
                <Grid item xs={12} lg={9}>
                    <Paper className={classes.paper}>
                        {value === 'GS' && <GeneralSetting />}
                        {value === 'LT' && <LeaveTypes />}
                        {value === 'SN' && <SlackNotifications hookUrl={hookUrl} />}
                    </Paper>
                </Grid>
            </Grid>*/}
            <div className={classes.screenSize}>
                <Hidden smDown>
                    <Paper elevation={0} className={classes.paperstyle}>
                        <Typography className={classes.title}>Settings </Typography>
                    </Paper>
                </Hidden>
                <Paper elevation={0}>
                    <Tabs
                        value={value}
                        onChange={handleTabs}
                        indicatorColor="primary"
                        // textColor="primary"
                        variant="scrollable"
                        className={classes.tabs}
                    >
                        <Tab label="Department Settings" className={classes.tab} id="organisationSettings" />
                        <Tab label="Holidays" className={classes.tab} id="holidays" />
                        <Tab label="Time-off Policies" className={classes.tab} id="timeOffPolicies" />
                        {/* <Tab label="Shoutout" className={classes.tab} id="shoutout" /> */}
                        {/* {mycontext.features.isSubscription ? (
                            <Tab label="Subscription" className={classes.tab} id="subscription" />
                        ) : null} */}
                        {/* <Tab label="Integrations" className={classes.tab} id="addToSlack" /> */}
                        {hasFeature('issubscription') && (
                            <Tab label="Subscription" className={classes.tab} id="subscription" />
                        )}
                        <Tab label="Standups" className={classes.tab} id="standups" />
                    </Tabs>
                </Paper>
                <TabPanel value={value} index={0}>
                    <NewSettingComponent />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <HolidayPage />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TimeOffPolicy />
                </TabPanel>
                {/* {mycontext.features.isSubscription ? (
                    <>
                        <TabPanel value={value} index={3}>
                            <Subscription />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <Addtoslack />
                        </TabPanel>
                    </>
                ) : (
                    <>
                        <TabPanel value={value} index={3}>
                            <Addtoslack />
                        </TabPanel>
                    </>
                )} */}
                {hasFeature('issubscription') ? (
                    <>
                        {/* <TabPanel value={value} index={3}>
                            <Addtoslack />
                        </TabPanel> */}
                        <TabPanel value={value} index={3}>
                            <Subscription />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <StandupSetting />
                        </TabPanel>
                    </>
                ) : (
                    <>
                        {/* <TabPanel value={value} index={3}>
                            <Addtoslack />
                        </TabPanel> */}
                        <TabPanel value={value} index={3}>
                            <StandupSetting />
                        </TabPanel>
                    </>
                )}
            </div>
            {/* <Hidden smDown>
                <div>
                    <Paper elevation={0} className={classes.paperstyle}>
                        <Typography className={classes.title}>Settings</Typography>
                    </Paper>

                    <Paper elevation={0}>
                        <Tabs
                            value={value}
                            onChange={handleTabs}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            className={classes.tabs}
                            scrollButtons="off"
                        >
                            <Tab label="Department Settings" className={classes.tab} id="organisationSettings" />
                            <Tab label="Holidays" className={classes.tab} id="holidays" />
                            <Tab label="Time-off Policies" className={classes.tab} id="timeOffPolicies" />
                            {mycontext.features.isSubscription ? (
                                <Tab label="Subscription" className={classes.tab} id="subscription" />
                            ) : null}

                            <Tab label="Add to Slack" className={classes.tab} id="addToSlack" />
                            {hasFeature('issubscription') && (
                                <Tab label="Subscription" className={classes.tab} id="subscription" />
                            )}
                        </Tabs>
                    </Paper>
                    <TabPanel value={value} index={0}>
                        <NewSettingComponent />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <HolidayPage />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TimeOffPolicy />
                    </TabPanel>
                    {mycontext.features.isSubscription ? (
                        <>
                            <TabPanel value={value} index={3}>
                                <Subscription />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <Addtoslack />
                            </TabPanel>
                        </>
                    ) : (
                        <>
                            <TabPanel value={value} index={3}>
                                <Addtoslack />
                            </TabPanel>
                        </>
                    )}
                    {hasFeature('issubscription') ? (
                        <>
                            <TabPanel value={value} index={3}>
                                <Addtoslack />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <Subscription />
                            </TabPanel>
                        </>
                    ) : (
                        <TabPanel value={value} index={3}>
                            <Addtoslack />
                        </TabPanel>
                    )}
                </div>
            </Hidden> */}
        </div>
    );
}
function TabPanel(props: any) {
    const { children, value, index } = props;
    return <div>{value === index && <h1>{children}</h1>}</div>;
}
