import React, { useState, useEffect, useContext } from 'react';
import useStyles from './RegisterStyle';
// import welcomeRegister from './welcome_register.png';
// import udyamo from './udyamo.png';
import { Grid, Box, Hidden, Button } from '@material-ui/core';
import { TeamplusLoader } from 'components/Loader/loader';
import AuthContext from '../../context/AuthContext';
import {
    getEmployeeRole,
    getEmployee,
    fetchOrganizationSettings,
    // getSubscriptionExpired,
} from '../../helpers/hasuraapi';
import { useHistory, useLocation } from 'react-router-dom';
import { clearUserStatus } from '../Redux/userStatusReducer';
import { useDispatch } from 'react-redux';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';
import { Auth } from 'aws-amplify';
// import { messaging } from '../../init-fcm';
// import { saveDeviceToken } from '../WebNotification/Notification';
// import { newurl } from '../../helpers/hasuraapi';
import { fetchQuestion } from 'helpers/hasuraapi3';
import { clearReducers } from 'components/Redux/StandupRedux';
/* istanbul ignore next */
export const TakeMeBack = () => {
    const history = useHistory();
    const handleBack = () => {
        // mycontext.logout();
        Auth.signOut();
        history.goBack();
    };
    return (
        <p
            onClick={() => handleBack()}
            style={{
                fontSize: 12,
                color: '#7A7A7A',
                fontFamily: 'Nunito Sans',
                fontWeight: 500,
                cursor: 'pointer',
                marginBottom: 30,
            }}
        >
            Take me back
        </p>
    );
};

export default function UserOrganizationsSlackLogin() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const mycontext = useContext(AuthContext);
    const location: any = useLocation();
    const dispatch = useDispatch();
    /* istanbul ignore next */
    // const Notify = async () => {
    //     if (Notification.permission === 'default' || Notification.permission === 'denied') {
    //         Notification.requestPermission().then(async function() {
    //             const tokenNotify: any = null;
    //             if (Notification.permission === 'granted') {
    //                 const tokenNotify = await messaging?.getToken();
    //                 // setToken(token);
    //                 // console.log('line 81', token);
    //                 const obj = {
    //                     empId: location.state.id,
    //                     deviceToken: tokenNotify,
    //                     token: location.state.token,
    //                 };
    //                 await saveDeviceToken(obj);
    //             } else {
    //                 const obj = {
    //                     empId: location.state.id,
    //                     deviceToken: tokenNotify,
    //                     token: location.state.token,
    //                 };
    //                 await saveDeviceToken(obj);
    //             }
    //         });
    //     }
    // };
    const selectOrganization = async (organization: any) => {
        try {
            setIsLoading(true);
            dispatch(clearUserStatus());
            dispatch(clearReducers());
            mycontext.addOrganizationId(organization.Organization.organizationId);
            mycontext.addOrganizationName(organization.Organization.organizationName);
            // mycontext.addOrganizationLogo(organization.Organization.organizationLogo);
            localStorage.setItem('slackWorkspaceId', organization.Organization.workspaceId);
            const data: any = await getEmployee(location.state.id, location.state.token);
            // const obj = {
            //     organizationId: organization.Organization.organizationId,
            //     token: location.state.token,
            // };
            // const isExpired: any = await getSubscriptionExpired(obj);
            // const status =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].status
            //         : '';
            // const warningDate =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].showWarning
            //         : '';
            // const hardExpireDate =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].hardExpireDate
            //         : '';
            // const subscriptionId =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].subscriptionId
            //         : '';
            // mycontext.addRole(organization.role);
            // const data: any = await getEmployee(id, token);
            const orgSettings: any = await fetchOrganizationSettings(
                organization.Organization.organizationId,
                location.state.token,
            );

            // mycontext.updateSubscriptionExpired(subscriptionId);
            // mycontext.updateWarningDate(warningDate);
            // mycontext.updateHardExpireDate(hardExpireDate);
            // mycontext.addSettings(orgSettings?.displayLogo);
            mycontext.addOtherSettings({
                approverCalledAs: orgSettings?.approverCalledAs,
                employeeCalledAs: orgSettings?.employeeCalledAs,
                teamsCalledAs: orgSettings?.teamsCalledAs,
                workingHours: orgSettings?.workingHours,
            });
            mycontext.updateDateFormat(orgSettings.dateFormat);
            const standup: any = await fetchQuestion(organization.Organization.organizationId, location.state.token);
            mycontext.addStandupTempId(standup?.standupTempId);
            mycontext.login({
                username: data.email,
                EmployeeId: data.employeeId,
                fname: data.firstName,
                lname: data.lastName,
                isAdmin: organization.role.toLowerCase() === 'admin'.toLowerCase() ? true : false,
                isApprover: organization.role.toLowerCase() === 'approver'.toLowerCase() ? true : false,
                isLogedIn: true,
                joining: data.joining,
                profile: data.profileImage,
                // teamname: data.teamname,
                statustype: data.status,
                email: data.email,
                isGoogle: false,
                token: location.state.token,
                refreshToken: location.state.refreshToken,
                uuid: location.state.id,
                // subscriptionExpired: status,
                // hardExpireDate: hardExpireDate,
                // warningDate: warningDate,
                // subscriptionId: subscriptionId,
                standupTempId: standup?.standupTempId,
                // sickdaysused: data.sickdaysused,
                // sickdaystotal: data.sickdaystotal,
                // gender: data.gender,
            });
            localStorage.setItem('slackEmployeeId', location.state?.slackEmployeeId);
            // mycontext.addOrganizationId(organization.Organization.organizationId);
            // mycontext.addOrganizationName(organization.Organization.organizationName);
        } catch (e) {
            setIsLoading(false);
            setError(true);
        }
    };

    const handleSelectOrganization = async (organization: any) => {
        try {
            await selectOrganization(organization);
            mycontext.fetchAllData();
            // setIsLoading(false);
            if (location.state) {
                if (location.state.isSignup) {
                    mycontext.firstSignIn({
                        initialSignIn: true,
                    });
                    // setTimeout(() => {
                    //     history.push('/plugins');
                    //     Notify();
                    // }, 10000);
                } else {
                    // setTimeout(() => {
                    //     history.push('/dashboard');
                    // }, 10000);
                }
            } else {
                // setTimeout(() => {
                //     history.push('/dashboard');
                // }, 15000);
            }
            // setTimeout(() => {
        } catch (e) {
            setIsLoading(false);
            setError(true);
        }
    };
    // const { id: string, token: string } = location.state;
    /* istanbul ignore next */
    useEffect(() => {
        const getOrganizations = async () => {
            try {
                const id = location?.state.id;
                const token = location?.state.token;
                const role = await getEmployeeRole(id, token);
                if (role.error) {
                    setError(true);
                    mycontext.logout();
                }
                if (role.length >= 1) {
                    await handleSelectOrganization(role[0]);
                }
            } catch (e) {
                setIsLoading(false);
                setError(true);
            }
        };
        getOrganizations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <TeamplusLoader />;
    }
    if (error) {
        return (
            <Box className={classes.root}>
                <Grid container>
                    <Hidden lgDown>
                        <Grid item xs={5} sm={5} className={classes.registerLeft}>
                            <img
                                src={s3Bucket + registerLoginPageImages.userOrgnizationPageImage}
                                alt="login"
                                style={{ width: '70%' }}
                            />
                            <img
                                src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                alt="udyamo"
                                style={{ height: 15, maxWidth: '100%' }}
                            />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={7} className={classes.registerRight}>
                        <div
                            style={{
                                backgroundColor: '#fff',
                                opacity: 0.5,
                            }}
                        >
                            <p
                                style={{
                                    margin: '10px 10px',
                                    color: '#222222',
                                }}
                            >
                                Something went wrong please try after some time...
                            </p>
                        </div>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => mycontext.logout()}
                            style={{
                                color: 'white',
                                border: '1px solid white',
                                outline: 'none',
                                marginTop: 20,
                            }}
                        >
                            Go Back
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return <TeamplusLoader />;
    //     return (
    //         // <PopUp open={alert1} setOpen={setAlert} message="Password updated successfuly!" severity="success" />
    //         <>
    //             <Box className={classes.root}>
    //                 <Grid container>
    //                     <Hidden lgDown>
    //                         <Grid item xs={5} sm={5} className={classes.userOrganizationLeft}>
    //                             <img
    //                                 src={s3Bucket + registerLoginPageImages.userOrgnizationPageImage}
    //                                 alt="login"
    //                                 style={{ width: '70%' }}
    //                             />
    //                             <img
    //                                 src={s3Bucket + registerLoginPageImages.udyamoBlack}
    //                                 alt="udyamo"
    //                                 style={{ height: 15, maxWidth: '100%' }}
    //                             />
    //                         </Grid>
    //                     </Hidden>
    //                 </Grid>
    //             </Box>
    //         </>
    //     );
}
