import { newurl } from '../../helpers/hasuraapi';

export const peopleLoading = () => ({
    type: 'PEOPLE_LOADING',
});

export const peopleFailed = (errmess: any) => ({
    type: 'PEOPLE_FAILED',
    payload: errmess,
});

export const addPeople = (people: any) => ({
    type: 'ADD_PEOPLE',
    payload: people,
});

export const fetchPeople = async (dispatch: any, organizationId: any, employeeId: any, token: any) => {
    dispatch(peopleLoading());
    const query = {
        query: `query MyQuery {
            timeoffdb_LeaveType(where: {organizationId: {_eq: "${organizationId}"}, active: {_eq: true}}) {
                leaveTypeName
                daysPerYear
            }
            usermanagementdb_organization_employee(where: {organizationId: {_eq: "${organizationId}"}}) {
                Employee {
                    employeeId
                    firstName
                    lastName
                }
            }
            timeoffdb_get_totalhours(args: {employeeid: "${employeeId}", organizationid: "${organizationId}"}) {
                employeeId
                leaveType
                totalHoursLeave
                workingHours
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        if (resp.status !== 200) {
            const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
            throw error;
        }
        const people = await resp.json();
        dispatch(addPeople(people.data));
    } catch (err) {
        return dispatch(peopleFailed(err?.message));
    }
};

export const People = (state = { isLoading: true, errMess: null, people: {} }, action: any) => {
    switch (action.type) {
        case 'ADD_PEOPLE':
            return { ...state, isLoading: false, errMess: null, people: action.payload };

        case 'PEOPLE_LOADING':
            return { ...state, isLoading: true, errMess: null, people: null };

        case 'PEOPLE_FAILED':
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};
