export const msalConfig = {
    auth: {
        clientId: 'a218fb57-53fa-436a-a616-5598dd1734a1',
        authority: 'https://login.microsoftonline.com/common', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        // redirectUri: 'http://localhost:3000/main',
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};
