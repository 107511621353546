import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        border: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'relative',
            width: '100%',
            // height: '100%',
            minHeight: 'calc(100vh - 190px)',
            background: theme.palette.background.paper,
            padding: '18px 25px 25px 25px',
        },
        paper1: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: 10,
            padding: '0px 18px 0px 5px',
            height: 50,
            '@media only screen and (max-width: 600px)': {
                marginBottom: 0,
                padding: 0,
            },
        },
        main: {
            position: 'relative',
            width: '100%',
            height: '100%',
            left: -15,
            background: theme.palette.background.paper,
            marginBottom: 10,
        },
        backArrow: {
            cursor: 'pointer',
            color: theme.palette.text.primary,
            margin: '10px 8px 10px 8px',
            '@media only screen and (max-width: 600px)': {
                margin: '10px 8px 10px -4px',
            },
        },
        item: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            padding: '5px 8px',
        },
        poll: {
            position: 'relative',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
            paddingTop: 8,
            color: theme.palette.text.primary,
            letterSpacing: 0.9,
        },
        maindiv: {
            position: 'relative',
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '@media only screen and (max-width: 600px)': {
                justifyContent: 'space-between',
            },
        },
        heading: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.primary.contrastText,
        },
        subheading: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            paddingTop: 5,
            paddingBottom: 25,
        },
        center: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            paddingTop: 5,
            paddingBottom: 25,
        },
        choices: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.primary.contrastText,
        },
        btnMain: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        btn: {
            position: 'relative',
            textAlign: 'center',
            width: 130,
            // marginTop: 3,
            // marginRight: '40px',
            height: '35px',
            fontSize: 14,
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        btn1: {
            width: 170,
            height: 40,
            // color: '#FFFFFF',
            right: 0,
            fontSize: 14,
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '@media only screen and (max-width:600px)': {
                position: 'fixed',
                bottom: 0,
                width: '100%',
                height: 42,
                borderRadius: 0,
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        btn2: {
            color: '#929292',
        },
        avatarimage: {
            height: 30,
            width: 30,
            fontSize: 12,
            border: 'none',
            backgroundColor: '#EDEFFA',
            color: '#444444',
            textTransform: 'uppercase',
        },
        hidden: {
            height: 50,
            background: theme.palette.info.main,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
        },
    }),
);
export default useStyles;
