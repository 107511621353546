import { makeStyles, Theme } from '@material-ui/core/styles';

export const SettingStyles = makeStyles((theme: Theme) => ({
    smbtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 24px',
        position: 'static',
        width: '144px',
        height: '40px',
        left: '57px',
        top: '16px',
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px rgba(60, 72, 181, 0.2)',
        borderRadius: '5px',
        flex: 'none',
        order: 3,
        flexGrow: 0,
        margin: '0px 12px',
        color: '#AAAAAA',
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
    },
    tabs: {
        minHeight: '30%',
        lineHeight: '25px',
        borderRadius: '5px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        '& div': {
            opacity: '4',
        },
    },
    tab: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        textTransform: 'initial',
        minHeight: '100%',
        fontSize: '15px',
        padding: '10px 20px',
        '&.Mui-selected': {
            outline: 'none',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
        },
    },
    addslack: {
        fontweight: 'bold',
        letterspacing: '0.05em',
        minHeight: '10%',
        marginTop: '8px',
        marginBottom: '8px',
        'line-height': '30%',
        padding: '10px 15px',
        fontSize: '15px',
        '&.Mui-selected': {
            outline: 'none',
        },
        fontFamily: 'Nunito Sans',
    },
    root: {
        flexGrow: 1,
        maxWidth: 800,
        margin: 'auto',
        marginTop: 100,
        backgroundColor: theme.palette.background.paper,
        '@media (max-width:360px)': {
            marginLeft: 12,
        },
        '@media (max-width:414px)': {
            marginLeft: 12,
        },
        '@media (max-width:768px)': {
            marginLeft: 40,
        },
    },
    note: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        color: '#444444',
        margin: '20px 0',
    },
    mainSettings: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 20,
        '@media screen and (max-width: 650px)': {
            paddingRight: 0,
            flexDirection: 'row',
        },
    },
    paper: {
        padding: 20,
        height: 'auto',
        paddingBottom: 0,
        minHeight: 'calc(100vh - 100px)',
        '@media screen and (max-width: 650px)': {
            padding: 10,
        },
    },
    btn: {
        width: '85%',
        height: 50,
        marginLeft: '10%',
        backgroundColor: '#fff',
        color: '#555555',
        textTransform: 'capitalize',
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        outline: 'none',
        margin: '20px 0px',
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 20,
        '&:hover': {
            outline: 'none',
            backgroundColor: '#fff',
            color: '#000',
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (max-width: 650px)': {
            width: 'auto',
            margin: '5px 0',
            fontSize: 14,
        },
    },
    btnActive: {
        width: '85%',
        height: 50,
        marginLeft: '10%',
        float: 'right',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        textTransform: 'capitalize',
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        outline: 'none',
        margin: '20px 0px',
        boxShadow: 'none',
        paddingLeft: 20,
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            outline: 'none',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (max-width: 650px)': {
            width: 'auto',
            margin: '5px 0',
            fontSize: 14,
        },
    },
    tab1: {
        backgroundColor: '#fff',
        color: '#666666',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        outline: 'none',
        cursor: 'pointer',
        borderRadius: 5,
        textTransform: 'capitalize',
        fontSize: 16,
        margin: '0 10px',
        boxShadow: '0 4px 4px 0 rgba(0,0,0,0.1)',
        border: 'none',
        padding: '0 20px',
        '&:focus': {
            outline: 'none',
        },
    },
    activeTab: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: 5,
        textTransform: 'capitalize',
        fontSize: 16,
        cursor: 'pointer',
        outline: 'none',
        boxShadow: '0 4px 4px 0 rgba(0,0,0,0.1)',
        border: 'none',
        padding: '0 20px',
        '&:focus': {
            outline: 'none',
            border: 'none',
        },
    },
    activeTab1: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: 5,
        textTransform: 'capitalize',
        fontSize: 16,
        cursor: 'pointer',
        outline: 'none',
        margin: '0 10px',
        boxShadow: '0 4px 4px 0 rgba(0,0,0,0.1)',
        border: 'none',
        padding: '0 20px',
        '&:focus': {
            outline: 'none',
            border: 'none',
        },
    },
    title: {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        fontStyle: 'normal',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        marginLeft: 20,
    },
    paperstyle: {
        height: 45,
        marginBottom: 10,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: theme.palette.background.paper,
    },
    paperDiv1: {
        //    minHeight: 'calc(100vh - 180px)',
        maxHeight: 'calc(100vh - 130px)',
    },
    screenSize: {
        // display: 'none',
        // '@media screen and (max-width: 767px)': {
        //     display: 'block',
        // },
    },
    outerDiv: {
        minHeight: 'calc(100vh - 140px)',
        maxHeight: 'calc(100vh - 130px)',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 0.5,
            height: 3,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: '#fff',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.secondary.light,
            height: 3,
        },
        padding: 20,
        flexGrow: 1,
    },
    outerTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
    },
    innerTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        paddingLeft: 5,
        paddingTop: 5,
    },
    cardContent: {
        // display: "flex",
        // flexDirection: "row",
        height: 60,
        textAlign: 'center',
        // width: 'fit-content',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    outerGrid: {
        flexGrow: 1,
        marginTop: 10,
    },
    divContainer: {
        display: 'flex',
        overflowX: 'auto',
        overflowY: 'hidden',
        height: 'auto',
        '&::-webkit-scrollbar': {
            width: 0.5,
            height: 3,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: '#fff',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.secondary.light,
            height: 3,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 3,
        },
        gap: 20,
        '@media (max-width: 767px)': {
            gap: 0,
            justifyContent: 'center',
            overflow: 'hidden',
        },
        '@media screen and (min-width:540px) and (max-width: 719px)': {
            overflowX: 'auto',
            overflowY: 'hidden',
            justifyContent: 'center',
        },
    },
    dpaper: {
        textAlign: 'left',
        borderRadius: 10,
        color: theme.palette.text.primary,
        border: '1px solid #CCCCCC',
        height: 190,
        width: 300,
        position: 'relative',
        // '@media screen and (min-width:540px) and (max-width: 719px)': {
        //     minWidth: '300px',
        // },
    },
    rowmessage: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20px',
    },
    activeChip: {
        height: 20,
        backgroundColor: '#B1FFB9',
        color: '#28A15F',
        textTransform: 'capitalize',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: '0px 10px',
        border: '1px solid #28A15F',
        position: 'absolute',
        right: 0,
        top: 0,
        width: 'auto',
        background: theme.palette.primary.main,
    },
    holdChip: {
        backgroundColor: '#F8B7A9',
        color: '#DD2E44',
        textTransform: 'capitalize',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: '0px 10px',
        border: '1px solid #DD2E44',
        position: 'absolute',
        right: 0,
        top: 0,
        width: 'auto',
        height: 20,
        background: theme.palette.primary.main,
    },
    gridContainer: {
        padding: theme.spacing(2),
    },
    progressBar: {
        height: 8,
        borderRadius: 5,
        backgroundColor: '#B1FFB9',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#28A15F',
            borderRadius: 5,
        },
    },
    progressBarRed: {
        height: 8,
        borderRadius: 5,
        backgroundColor: '#B1FFB9',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#f7141b',
            borderRadius: 5,
        },
    },
    updateBtn: {
        height: 30,
        textTransform: 'capitalize',
        fontSize: 12,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    actionBtn: {
        height: 35,
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    btnGroup: {
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        // color: '#111111',
        color: theme.palette.text.primary,
        '&:hover, &:focus': {
            textDecoration: 'none',
            // border: 'none',
            outline: 'none',
            backgroundColor: '#DCDFFF',
        },
    },
    btnGroupMobile: {
        height: 40,
        width: '50%',
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: 0,
        // color: '#111111',
        color: theme.palette.text.primary,
        '&:hover, &:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
            backgroundColor: '#DCDFFF',
        },
    },
    selectedBtn: {
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        color: '#111111',
        // color: theme.palette.text.primary,
        textDecoration: 'none',
        backgroundColor: '#DCDFFF',
        '&:hover, &:focus': {
            textDecoration: 'none',
            // border: 'none',
            outline: 'none',
            backgroundColor: '#DCDFFF',
        },
    },
    selectedBtnMobile: {
        height: 40,
        width: '50%',
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        backgroundColor: '#DCDFFF',
        borderRadius: 0,
        '&:hover, &:focus': {
            textDecoration: 'none',
            borderBottom: `1px solid #3C48B5`,
            outline: 'none',
            backgroundColor: '#DCDFFF',
        },
    },
    searchIcon: {
        '@media screen and (min-width:912px) and (max-width: 1024px)': {
            marginLeft: 10,
        },
    },
    searchInput: {
        height: 35,
        width: '100%',
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '&:hover, &:focus': {
            outline: 'none',
            color: theme.palette.text.primary,
        },
        '@media screen and (min-width: 768px) and (max-width: 1024px)': {
            fontSize: 12,
            padding: '0px 12px',
            '&:hover, &:focus': {
                outline: 'none',
                color: theme.palette.text.primary,
            },
        },
    },
    searchBorder: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '&:hover fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '&.Mui-focused fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
    },
    licenseData: {
        padding: '5px 0px',
        '@media (max-width: 767px)': {
            padding: '5px 0px 45px 0px',
        },
    },
    controlBtns: {
        border: '1px solid #cccccc',
        width: 10,
        padding: 0,
        '& .MuiButton-root': {
            minWidth: 10,
        },
    },
    container: {
        height: 'calc(100vh - 220px)',
        '&::-webkit-scrollbar': {
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: '#FFF',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },
    tables: {
        maxHeight: 'calc(100vh - 220px)',
        color: '#666666',
        width: '100%',
        fontFamily: theme.typography.fontFamily,
    },
    rows: {
        border: 'none',
        padding: 0,
        fontSize: '14px',
        '& .MuiTableCell-root': {
            border: 'none',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.common.black,
        },
        '&:hover': {
            boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
            color: theme.palette.primary.main,
            border: 'none',
            outline: 'none',
            borderRadius: 5,
            cursor: 'pointer',
            backgroundColor: theme.palette.info.main,
        },
    },
    idcolumn: {
        color: '#262F83',
        width: 50,
        background: '#DCDFFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        zIndex: 999,
        padding: 0,
    },
    tablecolumnname: {
        color: '#262F83',
        width: 200,
        background: '#DCDFFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        zIndex: 999,
        padding: 0,
    },
    tableCell: {
        minwidth: 150,
        fontSize: 14,
        padding: 0,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
    },
    selectInput: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        letterSpacing: '0.03em',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
    },
    listHeader: {
        padding: 0,
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 15,
        color: '#444444',
        backgroundColor: theme.palette.background.paper,
    },
    listItemAvatar: {
        '& .MuiListItemAvatar-root': {
            minWidth: '30px',
        },
        '& .MuiListItemAvatar-alignItemsFlexStart': {
            minWidth: 30,
        },
    },
    listStyle: {
        padding: 0,
        // height: 150,
        minHeight: 130,
        maxHeight: 150,
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        fontSize: 14,
        color: '#444444',
        fontWeight: 'bold',
        backgroundColor: theme.palette.background.paper,
        '&::-webkit-scrollbar': {
            width: 2,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.secondary.main,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },
    closeIcon: {
        height: 10,
        width: 10,
        color: '#777777',
        '&:hover, &:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    confirmBtn: {
        height: 35,
        width: 120,
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    cancelBtn: {
        height: 35,
        width: 100,
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    btnFixed: {
        height: 40,
        width: '50%',
        position: 'fixed',
        bottom: 0,
        right: 0,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '16px',
        border: '1px solid #3C48B5',
        borderRadius: 0,
        zIndex: 99,
        backgroundColor: '#3C48B5',
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: '#3C48B5',
            color: theme.palette.common.white,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    stepperDiv: {
        marginTop: 10,
        padding: 0,
        marginBottom: 10,
        '& .MuiMobileStepper-root': {
            backgroundColor: theme.palette.background.paper,
            padding: 0,
        },
        '& .MuiButton-label': {
            padding: 0,
        },
    },
    stepper: {
        '& .MuiMobileStepper-dot': {
            backgroundColor: '#DCDFFF',
        },
        '& .MuiMobileStepper-dotActive': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    controls: {
        padding: 0,
        '&:hover, &:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    divcss: {
        height: 40,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 99,
    },
    notfoundImage: {
        maxWidth: '75%',
        height: '180px',
    },
    btnFixed1: {
        height: 40,
        width: '50%',
        // position: 'fixed',
        bottom: 0,
        // right: 0,
        left: 0,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '14px',
        border: '1px solid #3C48B5',
        borderRadius: 0,
        zIndex: 99,
        color: '#3C48B5',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            color: '#3C48B5',
            backgroundColor: theme.palette.common.white,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
}));
