import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllInvoicesData } from '../../helpers/hasuraapi';

export const getInvoices: any = createAsyncThunk('invoices/getAllInvoices', async (subscriptionId: any) => {
    try {
        const data: any = await fetchAllInvoicesData(subscriptionId);
        const jsonData: any = await data.json();
        return jsonData;
    } catch (e) {
        return [];
    }
});

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: { invoices: [], status: 'loading' },
    reducers: {},
    extraReducers: {
        [getInvoices.pending]: state => {
            state.status = 'loading';
        },
        [getInvoices.fulfilled]: (state, action) => {
            state.invoices = action.payload;
            state.status = 'success';
        },
        [getInvoices.rejected]: state => {
            state.status = 'failed';
        },
    },
});
export default invoiceSlice.reducer;
