import { makeStyles } from '@material-ui/core/styles';
import bgImg from './bgImg.png';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

const useStyles = makeStyles(theme => ({
    otpdesign: {
        '&.css-1xfgs09': {
            width: '2.7rem',
            height: '2.7rem',
            margin: '0px 5px',
            fontSize: '1rem',
            borderRadius: 4,
            fontWeight: 600,
            border: '2px solid #CCCCCC',
            '&:focus': {
                border: '2px solid #3C48B5',
                borderRadius: 4,
            },
        },
    },
    firstGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    deleteAccountButton: {
        border: '1px solid #FF0000',
        width: '100%',
        color: '#FFFFFF',
        backgroundColor: '#FF0000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        fontSize: 16,
        cursor: 'pointer',
        marginBottom: 20,
        letterSpacing: '0.03em',
        fontWeight: 'bold',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FF0000',
        },
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            fontSize: '18px',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '22px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '14px',
        },
    },
    imgOneStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgTwoStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5%',
    },
    secondGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',

        '@media only screen and (min-width: 760px)': {
            backgroundImage: `url(${bgImg})`,
            backgroundSize: 'cover',
            height: '100vh',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            padding: '48px',
        },
        // '@media screen and (min-width: 1024px)': {
        //     backgroundImage: `url(${bgImg})`,
        //     backgroundSize: 'cover',
        // },
    },
    deleteIconStyles: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '@media screen and (max-width: 760px)': {
            marginTop: '10px',
        },
    },
    secondGridSmall: {
        '@media screen and (max-width: 768px)': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '0 20px',
            borderRadius: 0,
        },
        '@media screen and (min-width: 1024px)': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            borderRadius: 0,
        },
    },
    paperStyle: {
        backgroundColor: '#ffffff',
        '@media screen and (max-width: 760px)': {
            display: 'flex',
            alignItems: 'center',
            height: 'inherit',
            padding: '28px',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            padding: '48px',
            width: '90%',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            display: 'flex',
            alignItems: 'center',
            width: '75%',
            padding: '8%',
        },
        '@media screen and (min-width: 1024.5px)': {
            width: '55%',
            padding: '20px 48px 20px 48px',
        },
    },
    orgPaperStyle: {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#FFFFFF',
        '@media screen and (max-width: 768px)': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            padding: '28px',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            padding: '48px',
            width: '90%',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            padding: '4%',
            width: '90%',
        },
        '@media screen and (min-width: 1024.5px)': {
            width: '90%',
            height: 'auto',
            padding: '22px',
        },
    },
    title: {
        color: '#3C48B5',
        fontWeight: 600,
        lineHeight: '62px',
        '@media screen and (max-width: 768px)': {
            fontSize: '22px',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            fontSize: '40px',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '44px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '30px',
        },
    },
    titlePartOne: {
        color: '#3C48B5',
        fontWeight: 600,
        '@media screen and (max-width: 768px)': {
            fontSize: '28px',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            fontSize: '42px',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '48px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '30px',
        },
    },
    formPartNumber: {
        position: 'absolute',
        left: '90%',
        bottom: '40px',
        fontSize: '14px',
    },
    registerContainer: {
        display: 'block',
        flexWrap: 'wrap',
        height: 'auto',
        width: '100%',
        paddingTop: '1%',
        '@media screen and (max-width: 900px)': {
            display: 'flex',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            display: 'flex',
        },
    },
    formGrids: {
        display: 'flex',
        justifyContent: 'center',
        // marginTop: '4%'
    },
    partTwoLabel: {
        display: 'flex',
        justifyContent: 'center',
        // '@media screen and (max-width: 768px)': {
        //     paddingTop: '15%',
        // },
    },
    partOneGrids: {
        display: 'flex',
        justifyContent: 'center',
        '@media screen and (max-width: 760px)': {
            paddingBottom: '5%',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1024px)': {
            paddingBottom: '5%',
            '& div': {
                '& label': {
                    fontSize: '20px',
                    color: '#222222',
                },
                '& div': {
                    '& input': {
                        fontSize: '20px',
                        color: '#222222',
                    },
                },
                '& p': {
                    fontSize: '18px',
                    color: '#222222',
                },
            },
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            '& div': {
                '& label': {
                    fontSize: '24px',
                    color: '#222222',
                },
                '& div': {
                    '& input': {
                        fontSize: '24px',
                        color: '#222222',
                    },
                },
                '& p': {
                    fontSize: '20px',
                    color: '#222222',
                },
            },
        },
        '@media screen and (min-width: 1024.5px)': {
            marginTop: '2%',
            maxHeight: '5%',
            '& div': {
                '& label': {
                    fontSize: '14px',
                    color: '#222222',
                },
                '& div': {
                    '& input': {
                        fontSize: '14px',
                        color: '#222222',
                    },
                },
                '& p': {
                    fontSize: '12px',
                    color: '#222222',
                },
            },
        },
    },
    passswordButtonStyle: {
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    formFields: {
        width: '65%',
        '& label': {
            color: '#444444',
            fontWeight: 600,
            fontSize: '14px',
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
        },
        '& p': {
            '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
                fontSize: '18px',
            },
            '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
                fontSize: '20px',
            },
            '@media screen and (min-width: 1024.5px)': {
                fontSize: '14px',
            },
        },
        '& div': {
            '& input': {
                // color: '#444444',
                fontWeight: 600,
                '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
                    fontSize: '20px',
                },
                '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
                    fontSize: '24px',
                },
                '@media screen and (min-width: 1024.5px)': {
                    fontSize: '14px',
                },
            },
        },
    },
    passwordInput: {
        '& input': {
            color: '#0F0F0F',
            fontWeight: 600,
            '&::-ms-reveal': {
                display: 'none',
            },
        },
    },
    registerButtonGrid: {
        display: 'flex',
        justifyContent: 'center',

        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            alignItems: 'flex-end',
        },
    },
    registerOrgButtonGrid: {
        display: 'flex',
        justifyContent: 'center',
        '@media screen and (max-device-width: 1023.5px)': {
            marginTop: '10%',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            alignItems: 'flex-end',
        },
        '@media screen and (min-width: 1024.5px)': {
            marginTop: '10%',
            padding: '24px 24px 0px 24px',
        },
    },
    googleImg: {
        height: '100%',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: '10vh',
        marginRight: '20vh',
    },
    authBtn: {
        width: '100%',
        height: 40,
        border: '1px solid #AAAAAA',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#333333',
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (max-width: 768px)': {
            fontSize: 12,
        },
    },
    authBtn1: {
        width: 182,
        height: 40,
        // border: '1px solid #AAAAAA',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        // fontWeight: 'bold',
        // color: '#333333',
        color: '#ffffff',
        backgroundColor: '#3c48bf',
        padding: '5px',
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (max-width: 768px)': {
            fontSize: 12,
        },
    },
    createLink: {
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: 600,
        marginTop: 30,
        textDecoration: 'none',
        '&:hover, &:focus': {
            textDecoration: 'none',
        },
    },
    rootLoader: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    loginMain: {
        width: '100%',
        height: '100vh',
        background: `url("${s3Bucket + registerLoginPageImages.bgImage}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    welcome: {
        fontFamily: theme.typography.fontFamily,
        color: '#fff',
        fontSize: 34,
        fontWeight: theme.typography.fontWeightBold,
    },
    to: {
        fontFamily: theme.typography.fontFamily,
        color: '#fff',
        fontSize: 34,
        fontWeight: theme.typography.fontWeightRegular,
        margin: '20px 0',
    },
    teamLogoSmallMobile: {
        maxWidth: '70%',
        height: 70,
        marginBottom: 5,
    },
    SignInheading: {
        textAlign: 'center',
        marginTop: 30,
        fontSize: 36,
        fontWeight: 600,
        color: '#3C48B5',
        fontFamily: theme.typography.fontFamily,
    },
    msButtonStyle: {
        width: '100%',
        height: 40,
        border: '1px solid #AAAAAA',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#333333',
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (max-width: 768px)': {
            fontSize: 12,
        },
    },
    or: {
        display: 'flex',
        alignItems: 'center',
        margin: '3px',
        // marginTop: -30,
    },
    registerButton: {
        backgroundColor: '#3C48B5',
        color: '#FFFFFF',
        width: '100%',
        letterSpacing: '0.03em',
        fontWeight: 'bold',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3C48B5',
        },
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            fontSize: '18px',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '22px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '14px',
        },
    },
    existingUserLabel: {
        fontStyle: 'italic',
        color: '#444444',
        fontWeight: 600,
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '18px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '14px',
        },
    },
    linkStyle: {
        color: '#3C48B5',
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
        },
    },
    root: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    label: {
        textAlign: 'center',
        color: '#444444',
        marginTop: '25%',
    },
    OtpInput: {
        display: 'flex',
        justifyContent: 'center',
        height: '10vh',
        marginTop: '2%',
    },
    verifybutton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '42%',
        paddingBottom: '2%',
    },
    font: {
        fontFamily: theme.typography.fontFamily,
        letterSpacing: '0.5px',
        color: '#444444',
        fontWeight: 600,
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            fontSize: '20px',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '22px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '14px',
        },
    },
    hoverStyle: {
        backgroundColor: '#3C48B5',
        color: '#FFFFFF',
        width: '100%',
        letterSpacing: '0.03em',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        // marginBottom: '5%',
        '&:hover': {
            backgroundColor: '#3C48B5',
        },
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            fontSize: '18px',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '20px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '14px',
        },
    },
    resendOTPLink: {
        color: '#3C48B5',
        cursor: 'pointer',
        fontWeight: 600,
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '18px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '14px',
        },
    },
    contentGrid: {
        display: 'flex',

        flexWrap: 'wrap',
        width: '100%',
    },
    rowTemplate: {
        '@media screen and (max-width: 760px)': {
            display: 'flex',
            flexDirection: 'column',
        },

        padding: '10px',
        display: 'grid',

        gridTemplateColumns: '3fr 3fr 3fr 0.5fr',
    },
    paraGridStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paraContent: {
        textAlign: 'center',
        color: '#444444',
        fontWeight: 600,
        '@media screen and (max-width: 768px)': {
            lineHeight: 1.5,
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            fontSize: '20px',
            lineHeight: 1.5,
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '22px',
            lineHeight: 1.8,
        },
        '@media screen and (min-width: 1024.5px)': {
            lineHeight: '26px',
            fontSize: '14px',
        },
    },
    paraContent1: {
        textAlign: 'center',
        color: '#444444',
        '@media screen and (max-width: 768px)': {
            lineHeight: 1.5,
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            fontSize: '20px',
            lineHeight: 1.5,
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '22px',
            lineHeight: 1.8,
        },
        '@media screen and (min-width: 1024.5px)': {
            lineHeight: '26px',
            fontSize: '14px',
        },
    },
    paraContentBold: {
        textAlign: 'center',
        color: '#222222',
        lineHeight: '18px',
        fontWeight: 600,
        fontSize: '15px',
    },
    addEmployeeField: {
        '& div': {
            backgroundColor: 'white',
            '& input': {
                '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
                    fontSize: '18px',
                },
                '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
                    fontSize: '18px',
                },
                '@media screen and (min-width: 1024.5px)': {
                    fontSize: '14px',
                },
            },
        },
        '@media screen and (max-width: 768px)': {
            width: '72%',
            zIndex: 3,
            '& div': {
                height: '40px',
            },
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            width: '84%',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            zIndex: 2,
            width: '88%',
        },
        '@media screen and (min-width: 1024.5px)': {
            width: '82%',
            zIndex: 3,
        },
    },
    addEmployeeButton: {
        backgroundColor: '#3C48B5',
        color: '#FFFFFF',
        letterSpacing: '0.03em',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        display: 'inline-flex',
        zIndex: 1,
        borderRadius: '30px',

        '&:hover': {
            backgroundColor: '#3C48B5',
        },
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (max-width: 768px)': {
            right: '9%',
            width: '100px',
            height: '40px',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            right: '6%',
            width: '22%',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            right: '5%',
            width: '20%',
            height: '50px',
        },
        '@media screen and (min-width: 1024.5px)': {
            right: '8%',
            width: '28%',
            height: '40px',
        },
    },
    addEmployeeGrid: {
        '&:focus': {
            backgroundColor: 'white',
        },
        '@media screen and (max-width: 768px)': {
            '& div': {
                '& div': {
                    borderRadius: '30px',
                    height: '40px',
                },
            },
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            '& div': {
                '& div': {
                    borderRadius: '30px',
                    height: '50px',
                },
            },
        },
        '@media screen and (min-width: 1024.5px)': {
            '& div': {
                '& div': {
                    borderRadius: '30px',
                    height: '40px',
                },
            },
        },
    },
    chipGrid: {
        flexWrap: 'wrap',
        maxHeight: '10px',
        overflow: 'auto',
        '@media screen and (max-width: 768px)': {
            minHeight: '140px',
        },
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            minHeight: '200px',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            minHeight: '240px',
        },
        '@media screen and (min-width: 1024.5px)': {
            minHeight: '140px',
        },
    },
    chipStyle: {
        height: '24px',
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: 'fit-content',
        maxWidth: '30%',
        marginBottom: '2%',
        marginRight: '2%',
        marginTop: '2%',
        backgroundColor: 'rgba(60, 72, 181, 0.9)',
        cursor: 'pointer',
        '& span': {
            color: theme.palette.common.white,
        },
        '& svg': {
            color: '#d9ddff',
            '&:hover': {
                color: '#FFFFFF',
            },
        },
        // position: 'relative',
        // '& hover': {
        //     '& span': {
        //         position: 'relative',
        //         backgroundColor: theme.palette.secondary.main,
        //         color: '#fff',
        //         fontFamily: theme.typography.fontFamily,
        //         fontSize: 12,
        //     },
        // },
    },
    checkBoxStyle: {
        display: 'flex',
        paddingLeft: '13%',
        // paddingBottom: '2%',
        marginBottom: '5%',
        '& span': {
            color: '#3C48B5',
        },
    },
    checkBoxLabel: {
        '& span': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '22px',
            color: '#444444',
        },
    },
    partFourContent: {
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            padding: '10% 4% 0% 4%',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            padding: '10% 4% 0% 4%',
        },
        '@media screen and (min-width: 1024.5px)': {
            padding: '28px 28px 0px 28px',
        },
    },
    takeMeBackButton: {
        color: '#7A7A7A',
        fontWeight: 500,
        textTransform: 'initial',
        '&:hover': {
            backgroundColor: 'white',
        },
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '16px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '13px',
        },
    },
    addEmailNew: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 25,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        height: 40,
        margin: '0 auto',
        marginBottom: 20,
        '@media screen and (max-width: 650px)': {
            margin: '20px auto',
        },
    },
    textfield: {
        width: '80%',
        height: '40px',
        background: '#FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '30px',
        outline: 'none',
        color: '#888888',
        fontWeight: 600,
        [`& fieldset`]: {
            borderRadius: 25,
            outline: 'none',
            color: '#444444',
        },
        '& div': {
            color: '#444444',
            backgroundColor: 'transparent',
            '& input': {
                paddingTop: '12.5px',
                paddingBottom: '11.5px',
                color: '#444444',
            },
        },
        '&:focus': {
            outline: 'none',
        },
    },
    addBtn: {
        outline: 'none',
        backgroundColor: '#3C48B5',
        color: '#fff',
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        fontWeight: 700,
        width: '20%',
        height: 40,
        borderRadius: '30px',
        boxShadow: 'none',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#3C48B5',
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
        },
    },
    inputEmail: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
    },
    paperStyleOTP: {
        padding: '20px 48px 20px 48px',
        backgroundColor: '#FFFFFF',
        '@media screen and (max-width: 768px)': {
            display: 'flex',
            alignItems: 'center',
        },
        '@media screen and (min-width: 1024px)': {
            width: '55%',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
        },
    },
    registerLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    registerRight: {
        width: '100%',
        height: '100vh',
        // backgroundColor: theme.palette.primary.main,
        backgroundImage: `url("bgImg.png")`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttongrid: {
        '@media screen and (max-width: 760px)': {
            justifyContent: 'center',
            display: 'grid',
        },

        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        width: '100%',
    },
    registerNew: {
        width: '260px',
        border: '1px solid #3C48B5',
        // color: '#3c48b5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        padding: 5,
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer',

        backgroundColor: '#3C48B5',
        color: '#FFFFFF',
        '@media screen and (min-device-width: 767.5px) and (max-device-width: 1023.5px)': {
            fontSize: '18px',
        },
        '@media screen and (min-device-width: 1023.5px) and (max-device-width: 1367.5px)': {
            fontSize: '22px',
        },
        '@media screen and (min-width: 1024.5px)': {
            fontSize: '14px',
        },
    },
    inputSelectStyleOther: {
        width: '90%',
        height: 35,
        marginLeft: '15px',

        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '0.03em',
        color: 'theme.palette.text.primary',
        // border: '1px solid black',
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '& svg': {
            color: theme.palette.primary.contrastText,
        },
        // border:'1px solid red'
        marginTop: '10px',
    },
}));

export default useStyles;
