import { myStandup } from './MsTeamsAdaptiveCards';
import { editStandupNoteMessageId } from 'helpers/hasuraapi3';

const url =
    'https://sls-swec-dev-standupbotapisprod.azurewebsites.net/api/standupapi?code=RmxD1UxhMHRKN0WCthYDfbQ7NMbulzpAm32PtnTgAcPfAzFufy_mDg==';

export const postStandupMessage = async (
    id: string,
    templateQuestions: any,
    standupTempId: string,
    hasuraToken: string,
    channelId: string,
    serviceUrl: string,
    answers: any,
    mycontext: any,
    isAccomplished: boolean,
    standupNoteId: string,
    localTimestamp: string,
    timestamp: string,
) => {
    // console.log('id and questions ', id, templateQuestions, defaultValues, this.obj.id);
    // perform backend post operation first and get standupNoteId amd pass it to card for reff.
    const questionAnswers = {
        question1: isAccomplished ? 'yes' : 'no',
    };
    templateQuestions.forEach((q: any, i: number) => {
        if (answers.find((a: any) => a.question === q)) {
            questionAnswers[`question${i + 2}`] = answers.find((a: any) => a.question === q).answer;
        }
    });

    const standupPreviewCard = myStandup(
        templateQuestions,
        questionAnswers,
        mycontext.EmployeeId,
        mycontext.fname + ' ' + mycontext.lname,
        localTimestamp,
        timestamp,
    );
    // console.log("line 153 standup preview card", standupPreviewCard);
    const payload = {
        type: 'message',
        from: {
            id: mycontext.EmployeeId,
            name: mycontext.fname + ' ' + mycontext.lname,
        },
        conversation: {
            id: channelId,
        },
        summary: `${mycontext.fname + ' ' + mycontext.lname} has added standup note on ${localTimestamp}`,
        attachments: [
            {
                contentType: 'application/vnd.microsoft.card.adaptive',
                content: standupPreviewCard,
            },
        ],
    };
    const mydata = {
        payload: payload,
        serviceUrl: serviceUrl,
        channelId: channelId,
    };
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...mydata, type: 'addReply', messageId: id }),
    });
    const t = await res.json();
    // const t = await sendStandupReply({ ...mydata, messageId: id });
    const messageId = t.id;
    // console.log("my message id", messageId);
    await editStandupNoteMessageId(
        {
            standupNoteId: standupNoteId,
            messageId: messageId,
            threadId: id,
        },
        hasuraToken,
    );
};

export const postFirstStandupMessage = async (
    startMessage: string,
    templateQuestions: any,
    standupTempId: string,
    hasuraToken: string,
    channelId: string,
    serviceUrl: string,
    answers: any,
    mycontext: any,
    isAccomplished: boolean,
    standupNoteId: string,
    localTimestamp: string,
    timestamp: string,
) => {
    const payload = {
        type: 'message',
        from: {
            id: mycontext.EmployeeId,
            name: 'StandUps by TeamPlus',
        },
        conversation: {
            id: channelId,
        },
        summary: startMessage === null || startMessage === 'null' ? 'Good morning, Standup notes' : startMessage,
        text: startMessage === null || startMessage === 'null' ? 'Good morning, Standup notes' : startMessage,
    };
    const mydata = {
        payload: payload,
        serviceUrl: serviceUrl,
        channelId: channelId,
    };
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...mydata, type: 'addMessage' }),
    });
    // console.log("token,", res);
    const d = await res.json();
    // console.log("first standup resp ", d);
    await postStandupMessage(
        d.id,
        templateQuestions,
        standupTempId,
        hasuraToken,
        channelId,
        serviceUrl,
        answers,
        mycontext,
        isAccomplished,
        standupNoteId,
        localTimestamp,
        timestamp,
    );
};

export const updateStandupMessage = async (
    templateQuestions: any,
    newAnswers: any,
    channelId: string,
    mycontext: any,
    localTimestamp: string,
    timestamp: string,
    serviceUrl: string,
    messageId: string,
    isAccomplished: boolean,
) => {
    // const answers: any[] = [];
    // // answers['question1'] = newAnswers.question1;
    // templateQuestions.map((q: any, i: number) => {
    //     answers.push({
    //         question: q,
    //         answer: newAnswers['question' + (i + 2)],
    //     });
    // });
    const questionAnswers = {
        question1: isAccomplished ? 'yes' : 'no',
    };
    templateQuestions.forEach((q: any, i: number) => {
        if (newAnswers.find((a: any) => a.question === q)) {
            questionAnswers[`question${i + 2}`] = newAnswers.find((a: any) => a.question === q).answer;
        }
    });

    // console.log("new answers ", newAnswers, questionAnswers);
    const standupPreviewCard = myStandup(
        templateQuestions,
        questionAnswers,
        mycontext.EmployeeId,
        mycontext.fname + ' ' + mycontext.lname,
        localTimestamp,
        timestamp,
    );
    // console.log("line 153 standup preview card", standupPreviewCard);
    const payload = {
        type: 'message',
        from: {
            id: mycontext.EmployeeId,
            name: mycontext.fname + ' ' + mycontext.lname,
        },
        conversation: {
            id: channelId,
        },
        summary: `${mycontext.fname + ' ' + mycontext.lname} has updated standup note on ${localTimestamp}`,
        attachments: [
            {
                contentType: 'application/vnd.microsoft.card.adaptive',
                content: standupPreviewCard,
            },
        ],
    };
    const mydata = {
        payload: payload,
        serviceUrl: serviceUrl,
        channelId: channelId,
    };
    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...mydata, type: 'updateReply', messageId: messageId }),
    });
    // const resp = await editStandupReply({ ...mydata, messageId: oldData.myStandupNote.msgId });
    // console.log("update standup resp", resp);
    // const rs = await resp.json();
    // console.log("res", rs);
    return resp;
};

export const deleteStandupMessage = async (messageId: string, channelId: string, serviceUrl: string) => {
    // console.log("messageId", messageId, channelId, serviceUrl);
    const mydata = {
        serviceUrl: serviceUrl,
        channelId: channelId,
        messageId: messageId,
    };
    // console.log(",y obj", mydata);
    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...mydata, type: 'deleteReply' }),
    });
    // const resp = await deleteStandupReply(obj);
    return resp;
};
