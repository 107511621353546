import React, { useState, useContext } from 'react';
import { Avatar, Typography, Popover, List, ListItem, ListItemText } from '@material-ui/core';
import { useStyles, profileStyle } from './employeeProfileStyles';
import getFullName from '../../helpers/getInitials';
import DisplayEmoji from '../TeamMembers/DisplayEmoji';
import { ToStringFormat, ToStringFormatWithoutYear } from '../../helpers/date';
import AuthContext from '../../context/AuthContext';

interface ProfileData {
    firstName?: any;
    lastName?: any;
    employeeId?: any;
    role?: any;
    approverTeams?: any;
    employeeTeams?: any;
    image?: any;
    totalDaysPerYear?: any;
    demoData?: any;
    orgWorkingHours?: any;
    dateOfBirth?: any;
    workAnniversary?: any;
    isYear?: boolean;
    isDOB?: boolean;
}

export default function EmployeeProfile3(props: ProfileData) {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const classes2 = profileStyle();
    const [listAnchor, setListAnchor] = useState<any>(null);
    const [currentList, setCurrentList] = useState<any>([]);
    // const handleListOpen = (event: any, arrData: any[]) => {
    //     setListAnchor(event.currentTarget);
    //     setCurrentList(arrData);
    // };

    const handleListClose = () => {
        setListAnchor(null);
        setCurrentList([]);
    };

    let render: any;

    if (props.isDOB === false && props.isYear === false) {
        render = <></>;
    } else if (props.isDOB === true && props.isYear === false) {
        render = (
            <div style={{ display: 'flex', marginTop: '12px' }}>
                <Typography className={classes2.rolesStyle}>Date of birth :&nbsp;</Typography>
                <div style={{ flex: 1, paddingLeft: 2, display: 'flex', flexWrap: 'wrap' }}>
                    {props.dateOfBirth === null ? (
                        <Typography className={classes2.rolesValuesStyle}>--</Typography>
                    ) : (
                        <Typography className={classes2.rolesValuesStyle}>
                            {ToStringFormat(props.dateOfBirth, mycontext.dateFormat)}
                        </Typography>
                    )}
                </div>
            </div>
        );
    } else if (props.isDOB === false && props.isYear === true) {
        render = <></>;
    } else {
        render = (
            <div style={{ display: 'flex', marginTop: '12px' }}>
                <Typography className={classes2.rolesStyle}>Date of birth :&nbsp;</Typography>
                <div style={{ flex: 1, paddingLeft: 2, display: 'flex', flexWrap: 'wrap' }}>
                    {props.dateOfBirth === null ? (
                        <Typography className={classes2.rolesValuesStyle}>--</Typography>
                    ) : (
                        <Typography className={classes2.rolesValuesStyle}>
                            {ToStringFormatWithoutYear(props.dateOfBirth, mycontext.dateFormat)}
                        </Typography>
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <Popover
                id="teamsList2"
                open={Boolean(listAnchor)}
                anchorEl={listAnchor}
                onClose={handleListClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List
                    component="nav"
                    aria-labelledby="team-list-subheader"
                    style={{ minWidth: 100, width: '100%', padding: 0 }}
                >
                    <ListItem key="listTitle" className={classes.teamMenuTitle}>
                        <ListItemText primary="Team List" />
                    </ListItem>
                    <div className={classes.teamList}>
                        {currentList.map((l: any, index: number) => (
                            <ListItem
                                key={index}
                                style={{ padding: '0px 10px', textTransform: 'capitalize', fontSize: 12 }}
                            >
                                <ListItemText primary={l.teamName} />
                            </ListItem>
                        ))}
                    </div>
                </List>
            </Popover>
            <div className={classes.profileMain2}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {props.image ? (
                        <Avatar src={props.image} alt="User Profile" className={classes.userImg} />
                    ) : (
                        <Avatar alt="User Profile" className={classes.userImg}>
                            {getFullName(props.firstName + ' ' + props.lastName)}
                        </Avatar>
                    )}

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 0.95, marginLeft: 10 }}>
                        <div style={{ display: 'flex', minWidth: '100%' }}>
                            <Typography className={classes2.nameStyle}>
                                {props.firstName + ' ' + props.lastName}&nbsp;{' '}
                                <DisplayEmoji employeeId={props.employeeId} />
                            </Typography>
                        </div>
                        <Typography className={classes2.designationTwoStyle}>{props.role}</Typography>
                    </div>
                </div>

                <div>
                    <div style={{ display: 'flex', marginTop: '12px' }}>
                        <Typography className={classes2.rolesStyle}>Joining Date :&nbsp;</Typography>
                        <div style={{ flex: 1, paddingLeft: 2, display: 'flex', flexWrap: 'wrap' }}>
                            {props.workAnniversary === null ? (
                                <Typography className={classes2.rolesValuesStyle}>--</Typography>
                            ) : (
                                <Typography className={classes2.rolesValuesStyle}>
                                    {ToStringFormat(props.workAnniversary, mycontext.dateFormat)}
                                </Typography>
                            )}
                        </div>
                    </div>
                    {render}
                </div>
                {/* {props.approverTeams.length === 0 ? (
                    <div style={{ display: 'none' }}></div>
                ) : (
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <Typography className={classes2.rolesStyle}>Approver :</Typography>
                        <div style={{ flex: 1, paddingLeft: 20, display: 'flex', flexWrap: 'wrap' }}>
                            {props.approverTeams.slice(0, 3).map((element: any, index: any) => (
                                <Typography key={element.teamId} className={classes2.rolesValuesStyle}>
                                    {element.teamName}
                                    {props.approverTeams.length - 1 === index ? null : ','}&nbsp;
                                </Typography>
                            ))}
                            {props.approverTeams.length > 3 && (
                                <Avatar
                                    className={classes.fab}
                                    onClick={(e: any) => handleListOpen(e, props.approverTeams)}
                                >
                                    +{props.approverTeams.length - 3}
                                </Avatar>
                            )}
                        </div>
                    </div>
                )} */}
                {/* {props.employeeTeams.length === 0 ? (
                    <div style={{ display: 'none' }}></div>
                ) : (
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <Typography className={classes2.rolesStyle}>Employee :</Typography>
                        <div style={{ flex: 1, paddingLeft: 20, display: 'flex', flexWrap: 'wrap' }}>
                            {props.employeeTeams.slice(0, 3).map((element: any, index: any) => (
                                <Typography key={element.teamId} className={classes2.rolesValuesStyle}>
                                    {element.teamName}
                                    {props.employeeTeams.length - 1 === index ? null : ','}&nbsp;
                                </Typography>
                            ))}
                            {props.employeeTeams.length > 3 && (
                                <Avatar
                                    className={classes.fab}
                                    onClick={(e: any) => handleListOpen(e, props.employeeTeams)}
                                >
                                    +{props.employeeTeams.length - 3}
                                </Avatar>
                            )}
                        </div>
                    </div>
                )} */}
            </div>
        </>
    );
}
