/* eslint-disable no-var */
import { toAWSDateFormat } from './date';
import moment from 'moment';

const DiffDays = (diffTime: any) => {
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const TellDifference = (startDate: any, endDate: any) => {
    const date1: any = new Date(startDate);
    const date2: any = new Date(endDate);

    const currentDate: any = new Date();
    const diffTime1: any = currentDate - date1;
    const diffTime2: any = currentDate - date2;

    if (DiffDays(diffTime1) >= 0 && DiffDays(diffTime2) <= 0) {
        return true;
    } else {
        return false;
    }
};

export const isDateBetween = (startDate: any, endDate: any, joining: any) => {
    const date1: any = new Date(startDate);
    const date2: any = new Date(endDate);
    const joiningDate: any = new Date(joining);

    const diffTime1: any = joiningDate - date1;
    const diffTime2: any = joiningDate - date2;

    if (DiffDays(diffTime1) >= 0 && DiffDays(diffTime2) <= 0) {
        return true;
    } else {
        return false;
    }
};

export const isTimePresent = (time: any, startTime: any, endTime: any) => {
    const t: any = new Date(`01/01/2021 ${time}:00`);
    const t1: any = new Date(`01/01/2021 ${startTime}:00`);
    const t2: any = new Date(`01/01/2021 ${endTime}:00`);
    const h1: number = ((t1 - t) / (1000 * 60 * 60)) % 24;
    const m1: number = ((t1.getTime() - t.getTime()) / (1000 * 60)) % 60;

    const h2: number = ((t2 - t) / (1000 * 60 * 60)) % 24;
    const m2: number = ((t2.getTime() - t.getTime()) / (1000 * 60)) % 60;
    if (h1 <= 0 && m1 <= 0 && h2 >= 0 && m2 >= 0) {
        return true;
    }
    return false;
};

export const isValidTime = (startTime: any, endTime: any, startDate: any) => {
    const t1: any = new Date(`01/01/2021 ${startTime}:00`);
    const t2: any = new Date(`01/01/2021 ${endTime.toTimeString().substring(0, 5)}:00`);
    const h1: number = ((t2 - t1) / (1000 * 60 * 60)) % 24;
    const m1: number = ((t2.getTime() - t1.getTime()) / (1000 * 60)) % 60;
    if (startDate === toAWSDateFormat(endTime)) {
        if (h1 <= 0 && m1 <= 0) {
            return false;
        } else {
            return true;
        }
    }
    return false;
};

export const countDown = (endTime: any, setTime: any) => {
    const now = new Date('April 13, 2022 12:15:00').getTime();
    var x = setInterval(() => {
        const dateDiff = now - new Date().getTime();
        const days = Math.floor(dateDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((dateDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((dateDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((dateDiff % (1000 * 60)) / 1000);

        if (dateDiff < 0) {
            clearInterval(x);
            setTime('EXPIRED');
            return 'EXPIRED';
        }
        hours === 0
            ? setTime(minutes + 'm ' + seconds + 's ')
            : days === 0
            ? setTime(hours + 'h ' + minutes + 'm ' + seconds + 's ')
            : setTime(days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ');
        return days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
    }, 1000);
};

export const CountWorkweek = (startDate: string, endDate: string, workWeek: any[]) => {
    let count = 0;
    const theDate = new Date(startDate);
    const eDate = new Date(endDate);
    while (theDate <= eDate) {
        if (
            !workWeek.includes(
                moment(theDate)
                    .format('dddd')
                    .toLowerCase(),
            )
        ) {
            count = count + 1;
        }
        theDate.setDate(theDate.getDate() + 1);
    }
    return count;
};

export const isHoliday = (startDate: string, endDate: string, holidays: any[]) => {
    let holiday = false;
    const theDate = new Date(startDate);
    const eDate = new Date(endDate);
    while (theDate <= eDate) {
        if (
            holidays?.find(
                (h: any) =>
                    new Date(h.startDate).toString() === new Date(theDate).toString() ||
                    new Date(h.endDate).toString() === new Date(theDate).toString(),
            ) !== undefined
        ) {
            holiday = true;
        }
        theDate.setDate(theDate.getDate() + 1);
    }
    return holiday;
};

export const isLeavesAvailable = (lTypes: any, workingHours: number, category: string, empId: string) => {
    if (lTypes?.timeoffdb_LeaveType.filter((l: any) => l.leaveTypeName === category)[0]?.unlimitedDays) {
        return false;
    }
    const daysPerYear: any = lTypes?.timeoffdb_LeaveType.filter((l: any) => l.leaveTypeName === category)[0]
        ?.daysPerYear;
    const selectedObject: any = lTypes?.timeoffdb_get_totalhours_org?.filter(
        (l: any) => l?.employeeId === empId && l?.leaveType === category,
    )[0];
    const totalHoursLeave: any = selectedObject !== undefined ? selectedObject.totalHoursLeave : 0;
    const totalAvailable: any = daysPerYear * workingHours - totalHoursLeave;
    return totalAvailable;
};
