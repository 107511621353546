import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        deltitle: {
            fontSize: 22,
            color: theme.palette.info.light,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            // fontWeight: 700,
            zIndex: 0,
        },
        delbod: {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
        },
        inputStyle: {
            '& div': {
                '& input': {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                    borderRadius: '4px',
                    fontWeight: theme.typography.fontWeightBold,
                },
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        label1: {
            paddingTop: 2,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
        },
        label: {
            paddingTop: 2,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '16px',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
        },
        paper: {
            padding: theme.spacing(1),
            paddingBottom: '5px',
            background: theme.palette.background.paper,
            borderRadius: '5px',
        },
        deleteModalContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        labelDelete: {
            color: '#3C48B5',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '22px',
            zIndex: 0,
        },
        labelEdit: {
            color: '#444444',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '22px',
            zIndex: 0,
        },
        dataDelete: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '16px',
            textTransform: 'initial',
            fontFamily: theme.typography.fontFamily,
        },
        btn: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            marginRight: 10,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                margin: '10px 0',
                marginRight: 10,
            },
        },
        btnFixed: {
            height: 40,
            width: '50%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            marginRight: 10,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        btnOutlined: {
            height: 35,
            textTransform: 'capitalize',
            marginRight: 10,
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                margin: '10px 0',
                marginRight: 10,
            },
        },
        heading: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
        },
    }),
);
