import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            // padding: '0px 10px',
            marginTop: 5,
            textAlign: 'center',
            fontFamily: 'Nunito Sans',
            fontSize: 16,
        },
        row: {
            color: 'red',
            padding: '5px 10px',
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            // border: '1px solid red',
            // borderRadius: '5px',
        },
        errorDiv: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.background.paper,
        },
        mainDiv: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.background.paper,
        },
        errorDivMobile: {
            padding: 20,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
        },
        heading: {
            color: theme.palette.text.primary,
            fontSize: '30px',
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        subheading: {
            color: '#444444',
            fontSize: '26px',
            fontFamily: 'Nunito Sans',
            fontWeight: 'bold',
        },
        content: {
            color: theme.palette.text.primary,
            fontSize: '12px',
            fontFamily: 'Nunito Sans',
            marginTop: 30,
        },
        img: {
            width: '80%',
            height: '80%',
            padding: '50px',
            paddingLeft: '0px',
        },
        imgMobile: {
            maxWidth: '100%',
            height: '300px',
        },
        btn: {
            width: 200,
            marginTop: '30px',
            textTransform: 'capitalize',
            padding: '5px 10px',
            color: theme.palette.common.white,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
    }),
);

export default useStyles;
