import React, { useContext } from 'react';
import myStyle from './TeamStyle';
import { Button, Typography } from '@material-ui/core';
import MyModal from '../Modal/Modal';
import AuthContext from '../../context/AuthContext';
import { useDispatch } from 'react-redux';
import { getMyTeamEmployees, changeEmployeeRoles, DeleteTeam } from '../../helpers/hasuraapi';
import Loader from '../Loader/loader';
import { deleteTeam } from '../Redux/teamsReducer';

interface Props {
    isDelete?: boolean;
    setDelete?: any;
    teamId?: string;
    teamname?: string;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
    setCurrentPage?: any;
    currentPage?: any;
}

const DeleteModal = (props: Props) => {
    const classes = myStyle();
    const dispatch = useDispatch();
    const [updating, setUpdating] = React.useState<boolean>(false);
    const mycontext = useContext(AuthContext);

    const handleDelete = async (row: any) => {
        setUpdating(false);
        const data = await DeleteTeam(row);
        try {
            if (data.error) {
                throw new Error('Something went wrong, try again!');
            } else {
                props.setSeverity('success');
                props.setAlertm(true);
                props.setAlertMessage('Team deleted successfuly!');
                if (props.currentPage === 'details') {
                    props.setCurrentPage('teams');
                }
                dispatch(
                    deleteTeam({
                        teamId: row.teamId,
                    }),
                );
            }
        } catch (err) {
            // new Error('Something went wrong, try again!');
            props.setSeverity('error');
            props.setAlertm(true);
            props.setAlertMessage(err.message);
        }
        // window.location.reload();
    };

    const handleSubmit = async () => {
        try {
            const row = {
                teamId: props.teamId,
                teamName: props.teamname,
                organizationId: mycontext.organizationId,
                token: mycontext.token,
            };

            setUpdating(true);
            const teamEmp = await getMyTeamEmployees(props.teamId, mycontext.token);
            if (teamEmp.length > 0) {
                if (teamEmp[0].team_employees.length > 0) {
                    const changeRoles = teamEmp[0].team_employees.map((emp: any) => {
                        let empData: any;
                        if (emp.Employee.team_employees.length < 1) {
                            empData = emp.Employee.employeeId;
                        } else {
                            empData = null;
                        }
                        return empData;
                    });

                    const newRoles = changeRoles.filter((r: any) => r !== null);
                    if (newRoles.length > 0) {
                        const updateRoleResponse = await changeEmployeeRoles(changeRoles, mycontext.token);
                        if (updateRoleResponse.error) {
                            throw new Error('Unable to delete team');
                        } else {
                            handleDelete(row);
                            props.setDelete(false);
                        }
                    } else {
                        handleDelete(row);
                        props.setDelete(false);
                    }
                } else {
                    handleDelete(row);
                    props.setDelete(false);
                }
            } else {
                props.setDelete(false);
                setUpdating(false);
                props.setSeverity('error');
                props.setAlertm(true);
                props.setAlertMessage('No such team found.');
            }
        } catch (err) {
            props.setDelete(false);
            setUpdating(false);
            props.setSeverity('error');
            props.setAlertm(true);
            props.setAlertMessage('Unable to delete team.');
        }
    };
    return (
        <>
            <Loader isUpdating={updating} />
            <MyModal
                setOpen={props.setDelete}
                open={props.isDelete || false}
                onModalClosed={() => props.setDelete(false)}
            >
                <div
                    style={{
                        marginTop: '4%',
                        padding: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography className={classes.deltitle} style={{ marginBottom: '15px' }}>
                        Delete Team
                    </Typography>

                    <p className={classes.delbod} style={{ marginBottom: '20px' }}>
                        Are you sure you want to delete <strong>{props.teamname}</strong> team?
                    </p>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '0px',
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.delmobbtnOutlined}
                            onClick={() => props.setDelete(false)}
                            style={{ marginRight: 10 }}
                            id="cancel"
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.delbtn}
                            onClick={() => handleSubmit()}
                            id="delete"
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </MyModal>
        </>
    );
};

export default DeleteModal;
