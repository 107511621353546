import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Grid } from '@material-ui/core';
import useStyles from './style';
import Header from './Header';
import Footer from './Footer';
import SignUp from './SignUp';
import { useDispatch } from 'react-redux';
import { updateActiveTab } from 'components/Redux/activeTab';
import { s3Bucket, landingpageImages } from '../../helpers/constants';

export default function UserGuide() {
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch({ type: 'ACTIVE', payload: '/faq' });
        dispatch(updateActiveTab('/userguide'));
    }, [dispatch]);

    const data = [
        {
            href: '/adminguide',
            image: s3Bucket + landingpageImages.adminGuide,
            title: 'Admin Guide',
            description: `A single dashboard to view all pending requests, check your team's availability, and know upcoming day-offs.`,
        },
        {
            href: '/employeeguide',
            image: s3Bucket + landingpageImages.employeeGuide,
            title: 'Employee Guide',
            description: `A single dashboard to view all pending requests, check your team's availability, and know upcoming day-offs.`,
        },
        {
            href: '/approverguide',
            image: s3Bucket + landingpageImages.approverGuide,
            title: 'Approver Guide',
            description: `A single dashboard to view all pending requests, check your team's availability, and know upcoming day-offs.`,
        },
    ];

    interface CardProps {
        image: string;
        title: string;
        description: string;
    }
    interface DataCard extends CardProps {
        href: string;
    }

    interface DataProps {
        data: CardProps;
    }
    const CardComponent = (props: DataProps) => {
        return (
            <Card className={classes.userGuideCard}>
                <img src={props.data.image} className={classes.userGuideImg} alt="" />
                <CardContent className={classes.userGuideContent}>
                    <h6>{props.data.title}</h6>
                    <p>{props.data.description}</p>
                </CardContent>
            </Card>
        );
    };
    return (
        <>
            <Header />
            <div className={classes.mainCardComponent}>
                <div className={classes.userGuideHeading}>
                    <h2>User Guide</h2>
                </div>

                <Grid container className={classes.cardContainer}>
                    {data.map((d: DataCard, i: number) => (
                        <Grid item lg={4} md={12} sm={12} xs={12} className={classes.innerCardGrid} key={i}>
                            <Link to={d.href} style={{ textDecoration: 'none' }}>
                                <CardComponent data={d} />
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <SignUp title="The new-age assistant to take good care of your team is here. Try now!" />
            <Footer />
        </>
    );
}
