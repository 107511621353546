import { createSlice } from '@reduxjs/toolkit';
export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        subscription: {},
        userSubscriptions: [],
        softExpiredSubscriptions: [],
        hardExpiredSubscriptions: [],
        usedLicenses: {},
        userSubscriptionStatus: 'loading',
        status: 'loading',
    },
    reducers: {
        newSubscription: (state, action) => {
            state.subscription = action.payload;
        },
        fetchSubscription: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                const subscriptionData = action.payload?.data?.usermanagementdb_Subscription;
                const todaysDate: any = new Date();
                const softExpiredList: any = [];
                const hardExpiredList: any = [];
                const licensesCount = {};
                subscriptionData.forEach((subscription: any) => {
                    if (subscription?.currentTermEnd !== 'undefined' || null || undefined) {
                        let databaseDate: any = '';
                        let diffTime: any = '';
                        let diffDays: any = '';
                        databaseDate = new Date(subscription?.currentTermEnd.split('T')[0]);
                        // console.log('db date', databaseDate);
                        diffTime = Math.abs(todaysDate - databaseDate);
                        // console.log('diff time', diffTime);
                        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        // console.log('diff date', diffDays);
                        // console.log('start date', subscription.planId, subscription.currentTermStart.split('T')[0]);
                        // console.log('end date', subscription.planId, subscription.currentTermEnd.split('T')[0]);
                        switch (true) {
                            case subscription.planId.includes('tbtp'):
                                licensesCount['timeoff'] =
                                    subscription.quantity -
                                    subscription.timeoffplan?.tenant_employees_aggregate?.aggregate.count;
                                if (diffDays > 30) {
                                    softExpiredList.push('timeoff');
                                }
                                if (diffDays > 60) {
                                    hardExpiredList.push('timeoff');
                                }
                                break;
                            case subscription.planId.includes('sbtp'):
                                licensesCount['standup'] =
                                    subscription.quantity -
                                    subscription.standupplan?.tenant_employees_aggregate?.aggregate.count;
                                if (diffDays > 30) {
                                    softExpiredList.push('standup');
                                }
                                if (diffDays > 60) {
                                    hardExpiredList.push('standup');
                                }
                                break;
                            case subscription.planId.includes('pbtp'):
                                licensesCount['poll'] =
                                    subscription.quantity -
                                    subscription.pollplan?.tenant_employees_aggregate?.aggregate.count;

                                if (diffDays > 30) {
                                    softExpiredList.push('poll');
                                }
                                if (diffDays > 60) {
                                    hardExpiredList.push('poll');
                                }
                                break;
                            case subscription.planId.includes('ebtp'):
                                licensesCount['engage'] =
                                    subscription.quantity -
                                    subscription.engageplan?.tenant_employees_aggregate?.aggregate.count;
                                if (diffDays > 30) {
                                    softExpiredList.push('engage');
                                }
                                if (diffDays > 60) {
                                    hardExpiredList.push('engage');
                                }
                                break;
                            default:
                                break;
                        }
                    }
                });
                state.subscription = action.payload?.data;
                state.softExpiredSubscriptions = [...softExpiredList];
                state.hardExpiredSubscriptions = [...hardExpiredList];
                state.usedLicenses = { ...licensesCount };
            }
        },
        fetchUserSubscriptionDetails: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.userSubscriptionStatus = 'loading';
            }
            if (action.payload?.error) {
                state.userSubscriptionStatus = 'failed';
            } else {
                state.userSubscriptionStatus = 'success';
                state.userSubscriptions = action.payload?.data;
            }
        },
    },
});
export const { newSubscription, fetchSubscription, fetchUserSubscriptionDetails } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
