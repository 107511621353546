/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useEffect } from 'react';
import {
    Grid,
    Typography,
    FormLabel,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Avatar,
    Button,
    Paper,
} from '@material-ui/core';
import { useStyles } from './GlobalSettingsStyle';
import EditIcon from '@material-ui/icons/Edit';
import AuthContext from '../../context/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import getInitials from 'helpers/getInitials';
import Loader, { LoaderPage } from 'components/Loader/loader';
import {
    fetchTenantEmployees,
    updateSuperAdminRole,
    updateTenantSettings,
    uploadTenantImage,
    updateAdminRole,
} from 'helpers/hasuraapi';
import MyModal, { PopUp } from 'components/Modal/Modal';
import { updateActiveTab } from 'components/Redux/activeTab';
import { Autocomplete, AutocompleteCloseReason } from '@mui/material';
import ErrorPage from 'components/Error/ErrorPage';

const GlobalSettingsNew = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const mycontext = useContext(AuthContext);
    const [orgName, setOrgName] = React.useState('');
    const [image, setImage] = React.useState<any>(null);
    const [logoOrName, setLogoOrName] = React.useState('orgname');
    const settings: any = useSelector((state: any) => state.settings);
    const [isImage, setIsImage] = React.useState<boolean>(false);
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
    // const [users, setUsers] = React.useState<any>([]);
    const [newAdmins, setNewAdmins] = React.useState<any>([]);
    const [alert, setAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [isRemove, setIsRemove] = React.useState<boolean>(false);
    const [userToRemove, setUserToRemove] = React.useState<any>({
        id: '',
        name: '',
        role: '',
    });
    const editFocus = useRef<any>();
    const tenantMembers: any = useSelector((state: any) => state?.tenantMembers);
    const [users, setUsers] = React.useState<any>(tenantMembers?.tenantMembers);

    useEffect(() => {
        dispatch(updateActiveTab('/settings'));
        async function getData() {
            const userData = await fetchTenantEmployees({
                tenantWorkspace: mycontext.tenantId,
                token: mycontext.token,
            });
            setUsers(userData);
        }

        getData();
        if (settings.status === 'success') {
            const setting = settings.settings;
            setLogoOrName(setting.displayLogo ? 'orglogo' : 'orgName');
            mycontext.addSettings(setting.Organization?.Tenant?.displayLogo);
        }
    }, []);

    const focusInput = () => {
        editFocus.current.click();
    };

    useEffect(() => {
        const getOrganizationSettings = async () => {
            if (settings.status === 'success') {
                const setting = settings.settings;
                setOrgName(setting.Organization?.Tenant?.name);
                setImage(setting.Organization?.Tenant?.logo);
                setLogoOrName(setting.Organization?.Tenant?.displayLogo ? 'orglogo' : 'orgname');
            }
        };
        getOrganizationSettings();
    }, [settings.status]);
    const handleOrgNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setIsDisabled(false);
        setOrgName(event.target.value as string);
    };
    const handleLogoOrNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDisabled(false);
        setLogoOrName((event.target as HTMLInputElement).value);
    };
    const handleFile = (image: any) => {
        if (
            image.type === 'image/jpg' ||
            image.type === 'image/png' ||
            image.type === 'image/gif' ||
            image.type === 'image/jpeg'
        ) {
            setIsDisabled(false);
            setIsImage(true);
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = () => {
                setImage(reader.result);
            };
        } else {
            mycontext.togglePopup({
                alertMessage: 'Images must be of type GIFs, JPGs/JPEGs or PNGs.',
                severity: 'error',
            });
        }
    };

    const toggleModal = () => {
        setIsRemove(!isRemove);
    };

    const addSuperAdmin = async () => {
        newAdmins.map(async (a: any) => {
            const result = await updateSuperAdminRole({
                employeeId: a.employeeId,
                tenantWorkspace: mycontext.tenantId,
                role: 'superadmin',
                token: mycontext.token,
            });
            if (result.errors) {
                setAlert(true);
                setAlertMessage('Something went wrong!');
                setSeverity('error');
            }
            if (result.affected_rows > 0) {
                await updateAdminRole({
                    employeeId: a.employeeId,
                    role: 'admin',
                    token: mycontext.token,
                });
                setAlert(true);
                setAlertMessage('Successfully changed role to superadmin');
                setSeverity('success');
                setUsers((prev: any) =>
                    prev.map((u: any) => {
                        if (u.employeeId === a.employeeId) {
                            return {
                                ...u,
                                role: 'superadmin',
                            };
                        }
                        return u;
                    }),
                );
            }
        });
        setNewAdmins([]);
    };

    const handleSuperAdmin = async (user: any, userrole: string) => {
        const result = await updateSuperAdminRole({
            employeeId: user,
            tenantWorkspace: mycontext.tenantId,
            role: userrole,
            token: mycontext.token,
        });
        if (result.errors) {
            setAlert(true);
            setAlertMessage('Something went wrong!');
            setSeverity('error');
        } else {
            setAlert(true);
            setAlertMessage('Successfully changed role to employee');
            setSeverity('success');
            setUsers((prev: any) =>
                prev.map((u: any) => {
                    if (u.employeeId === user) {
                        return {
                            ...u,
                            role: userrole === 'employee' ? 'employee' : 'superadmin',
                        };
                    }
                    return u;
                }),
            );
            setAlert(true);
            setAlertMessage('Superadmin removed successfully!');
            setSeverity('success');
        }

        toggleModal();
    };

    const User = (props: any) => {
        const isCurrent = props.user.employeeId === mycontext.EmployeeId ? true : false;
        return (
            <div className={classes.userDiv}>
                {props.user.Employee.profileImage === null ? (
                    <Avatar className={classes.avatar}>
                        {getInitials(props.user.Employee.firstName + ' ' + props.user.Employee.lastName)}
                    </Avatar>
                ) : (
                    <Avatar className={classes.avatar} src={props.user.Employee.profileImage} alt="" />
                )}{' '}
                <Typography className={classes.userDivName}>
                    {props.user.Employee.firstName + ' ' + props.user.Employee.lastName}
                </Typography>
                <Button
                    color="primary"
                    onClick={(): void => {
                        setUserToRemove({
                            id: props.user.employeeId,
                            name: props.user.Employee.firstName + ' ' + props.user.Employee.lastName,
                            role: 'employee',
                        });
                        toggleModal();
                    }}
                    disabled={isCurrent}
                    className={classes.removeBtn}
                >
                    Remove Superadmin
                </Button>
            </div>
        );
    };

    const updateSettings = async () => {
        let url: any;
        // const setting = settings.settings;
        try {
            if (!orgName) {
                throw new Error('Organization name is empty, please enter your organization name');
            }
            setIsUpdating(true);
            const data = {
                tenantWorkspace: mycontext.tenantId,
                isLogo: logoOrName === 'orglogo' ? true : false,
                token: mycontext.token,
            };
            if (isImage) {
                url = await uploadTenantImage(mycontext.tenantId, image, mycontext.token);
                mycontext.addOrganizationLogo(url);
            }
            setIsUpdating(false);
            // mycontext.addOrganizationName(orgName);
            await updateTenantSettings(data);
            mycontext.addSettings(logoOrName === 'orglogo' ? true : false);
            // dispatch(
            //     editSettings({
            //         displayLogo: logoOrName === 'orglogo' ? true : false,
            //         Organization: {
            //             organizationName: orgName,
            //             organizationLogo: isImage ? url : setting.Organization.organizationLogo,
            //         },
            //     }),
            // );
            setAlert(true);
            setAlertMessage('Settings updated successfully!');
            setSeverity('success');
            setIsDisabled(true);
        } catch (err) {
            setAlert(true);
            setSeverity('error');
            if (err.message) {
                setAlertMessage(err.message);
            } else {
                setAlertMessage('Failed to update settings!');
            }
        }
    };

    if (settings.status === 'loading') {
        return <LoaderPage />;
    } else if (settings.status === 'failed') {
        return <ErrorPage msg="Something went wrong!" />;
    } else {
        return (
            <>
                <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                <Loader isUpdating={isUpdating} />
                <MyModal open={isRemove} setOpen={toggleModal} onModalClosed={toggleModal}>
                    <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        justifyContent="flex-end"
                        style={{ color: '#111111', width: '100%', padding: '10px 0px', margin: 0 }}
                    >
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
                                Taking away the superadmin role from an employee will result in them no longer being a
                                superadmin of the tenant. However, they may still hold the position of an admin in other
                                departments.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
                                Are you sure you want to remove superadmin role of {userToRemove.name}?
                            </Typography>
                        </Grid>
                        <Grid container item sm={8} xs={7} justifyContent="flex-end" style={{ padding: 0 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={toggleModal}
                                className={classes.cancelBtn}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid container item sm={4} xs={5} justifyContent="flex-end" style={{ padding: 0 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSuperAdmin(userToRemove.id, userToRemove.role)}
                                className={classes.confirmBtn}
                            >
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                </MyModal>
                <Paper elevation={0} className={classes.paperstyle}>
                    <Typography className={classes.title}>Tenant Settings</Typography>
                </Paper>
                <Grid container className={classes.outerDiv}>
                    <Grid item xs={12} sm={6} className={classes.firstDiv}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <FormLabel className={classes.formLabelStyle}>Name of the organization</FormLabel>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="e.g: Apple.Inc"
                                    size="small"
                                    value={orgName}
                                    disabled={true}
                                    className={classes.inputStyle}
                                    onChange={handleOrgNameChange}
                                    id="organisationName"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormLabel className={classes.formLabelStyle}>Organization logo</FormLabel>
                                <div className={classes.orgLogoBoxStyle}>
                                    <div className={classes.imagePreviewStyle}>
                                        {image === null ? (
                                            <div>
                                                <h5
                                                    style={{
                                                        color: '#AAAAAA',
                                                        letterSpacing: '0.03em',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Add your organization&apos;s logo
                                                </h5>
                                            </div>
                                        ) : (
                                            <img src={image} alt="Uploaded logo" />
                                        )}
                                    </div>
                                    <label onClick={focusInput}>
                                        <EditIcon fontSize="small" style={{ color: '#666666', cursor: 'pointer' }} />
                                    </label>
                                    <input
                                        type="file"
                                        ref={editFocus}
                                        id="myInput"
                                        accept="image/*"
                                        placeholder="choose a file"
                                        color="primary"
                                        style={{ textAlign: 'center' }}
                                        hidden
                                        onChange={(e: any) => handleFile(e.target.files[0])}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormLabel className={classes.formLabelStyle}>
                                    What should be displayed on the navigation bar?
                                </FormLabel>
                                <RadioGroup row value={logoOrName} onChange={handleLogoOrNameChange}>
                                    <Grid item xs={12} lg={6}>
                                        <FormControlLabel
                                            value="orgname"
                                            control={<Radio color="primary" id="orgName" />}
                                            label={
                                                <FormLabel className={classes.radioNameStyle}>
                                                    Organization Name
                                                </FormLabel>
                                            }
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <FormControlLabel
                                            value="orglogo"
                                            control={<Radio color="primary" id="orgLogo" />}
                                            label={
                                                <FormLabel className={classes.radioNameStyle}>
                                                    Organization Logo
                                                </FormLabel>
                                            }
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                            <Grid container item xs={12} sm={12} justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={isDisabled ? classes.resetButton2 : classes.updateButtonStyle}
                                    onClick={() => updateSettings()}
                                    id="settingsUpdate"
                                    disabled={isDisabled}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 20 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography className={classes.title}>Make employee Super Admin</Typography>
                                <Typography className={classes.description}>
                                    Granting super admin access to an employee provides them with full access to your
                                    organization&apos;s settings and data.
                                </Typography>
                                <Typography className={classes.description} style={{ marginTop: 5 }}>
                                    If an employee is assigned the role of superadmin, they will automatically become
                                    the admin for the department they are currently in.
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <Autocomplete
                                    disablePortal
                                    disableCloseOnSelect
                                    noOptionsText="No value match"
                                    multiple={true}
                                    fullWidth
                                    id="adminList"
                                    value={newAdmins}
                                    options={users?.filter((u: any) => u.role !== 'superadmin')}
                                    onChange={(event: any, newValue: any, reason: any) => {
                                        if (
                                            event.type === 'keydown' &&
                                            (event as React.KeyboardEvent).key === 'Backspace' &&
                                            reason === 'removeOption'
                                        ) {
                                            return;
                                        }
                                        setNewAdmins(newValue);
                                    }}
                                    onClose={(event: any, reason: AutocompleteCloseReason) => {
                                        if (reason) {
                                            setNewAdmins(newAdmins);
                                        }
                                    }}
                                    sx={{ width: 250 }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            className={classes.autotext}
                                            variant="outlined"
                                            placeholder="Select employees"
                                        />
                                    )}
                                    getOptionLabel={option =>
                                        option?.Employee.firstName + ' ' + option?.Employee.lastName
                                    }
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.employeeId}>
                                            {option?.Employee.firstName + ' ' + option?.Employee.lastName}
                                        </li>
                                    )}
                                    disableClearable
                                    className={classes.autobox}
                                    ListboxProps={{ className: classes.autocompletelist }}
                                />
                            </Grid>
                            <Grid container item xs={4} sm={4}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.addBtn}
                                    onClick={() => addSuperAdmin()}
                                >
                                    Add Super Admin
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>Current Super Admin</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} className={classes.superAdminList}>
                                {users
                                    .filter((u: any) => u.role === 'superadmin')
                                    .map((uData: any) => (
                                        <User key={uData.employeeId} user={uData} />
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
};

export default GlobalSettingsNew;
