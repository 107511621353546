import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import { Hidden } from '@material-ui/core';
import EmployeeProfile from './employeeProfile2';
import EmployeeProfile3 from './employeeProfile3';
import EmployeePieChart from './employeePieChart';
import EmployeeTimeOffStatus from './employeeTimeOffStatus';
import EmployeeHeader from './employeeHeader';
import LeaveHistoryTable from './leaveHistoryTable';
import EmployeeSkeleton from './employeeSkeleton';
import { useStyles } from './employeeProfileStyles';
import { toAWSDateFormat } from '../../helpers/date';
import { useQuery, gql, useSubscription } from '@apollo/client';
import AuthContext from '../../context/AuthContext';
import AccordionSkeleton from './accordionSkeleton';
import ErrorPage from '../Error/ErrorPage';
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveTab } from 'components/Redux/activeTab';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import LeaveHistoryOther from './leaveHistoryOther';
import ShoutoutHistory from './shoutoutProfile/shoutoutHistory';
import ShoutoutInsights from './shoutoutProfile/shoutoutInsights';
import ShoutoutOther from './shoutoutProfile/shoutoutOther';
import {
    Tab,
    Tabs,
    Typography,
    // useTheme,
    // Grid,
} from '@material-ui/core';
import PollsInsights from './pollProfile/pollsInsights';
import OtherEmpPollsHistory from './pollProfile/otherEmpPollsHistory';
import Divider from '@mui/material/Divider';
// import { withStyles } from '@material-ui/core/styles';
// import { Icon } from '@iconify/react';
// import upcomingTimeOff from '../../Assets/images/upcomingTimeoff.png';
import PollHistoryTable from './pollProfile/pollHistoryTable';
import { useFlagsmith } from 'flagsmith-react';
import { query10, query8 } from 'helpers/profileApi';
import { localToISO, getCurrentCountryDateTime } from '../../helpers/timezone';
import { fetchApproveLeave } from 'helpers/subscriptions/dashboardSubscriptions';
// import { array } from '@storybook/addon-knobs';

export default function EmployeeMain() {
    // const theme = useTheme();
    const history = useHistory<any>();
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const dispatch = useDispatch();
    const userId: any = history.location.state.id;
    // const time = new Date();
    // const currenttime = React.useState<any>(
    //     time.toLocaleString('en-US', {
    //         hour: 'numeric',
    //         minute: 'numeric',
    //         second: 'numeric',
    //         hour12: false,
    //     }),
    // );

    const [currenttime] = React.useState(
        localToISO(
            getCurrentCountryDateTime(mycontext.country?.zoneName).date,
            getCurrentCountryDateTime(mycontext.country?.zoneName).time,
            mycontext.country,
        ),
    );
    // const currenttime = (localToISO(
    //     getCurrentCountryDateTime(mycontext.country?.zoneName).date,
    //     getCurrentCountryDateTime(mycontext.country?.zoneName).time,
    //     mycontext.country,)
    // )
    const state = useSelector((state: any) => state);
    const [value, setValue] = React.useState(0);
    const [features, setFeatures] = React.useState(0);
    const [currentPoll, setcurrentPoll] = React.useState<any>(0);
    const [timeoff, setTimeoff] = React.useState<any>([]);
    const { hasFeature } = useFlagsmith();
    const timeoffdata = useSubscription(fetchApproveLeave(mycontext.organizationId));
    const [loading, setIsLoading] = React.useState(false);
    const [error, setIsError] = React.useState(false);
    // console.log(timeoffdata);

    // const classes2 = leaveTableStyle();

    const handleTabs = (event: any, newValue: number) => {
        setValue(newValue);
    };

    const handleFeatures = (event: any, newValue: number) => {
        setFeatures(newValue);
    };

    const orgWorkingHours = state.settings?.settings?.workingHours;
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    React.useMemo(() => {
        if (!timeoffdata.loading) {
            const data: any = timeoffdata?.data?.timeoffdb_Leaves;
            if (timeoffdata.error) {
                setIsError(true);
                // console.log('test');
            } else {
                setIsLoading(false);
                setTimeoff(data);
            }
        }
    }, [timeoffdata]);

    useEffect(() => {
        dispatch(updateActiveTab('/employees'));
        // setSelectedValue(leaveType2[0].leaveTypeName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTeams = (teams: any) => {
        let employeeTeams: any = {
            approvers: [],
            employees: [],
        };
        if (teams.length > 0) {
            teams.forEach((item: any) => {
                if (item.team_employees[0].isApprover) {
                    employeeTeams.approvers.push({
                        teamId: item.teamId,
                        teamName: item.teamName,
                        isApprover: item.team_employees[0].isApprover,
                    });
                } else {
                    employeeTeams.employees.push({
                        teamId: item.teamId,
                        teamName: item.teamName,
                        isApprover: item.team_employees[0].isApprover,
                    });
                }
                return employeeTeams;
            });
        } else {
            employeeTeams = [];
        }
        return employeeTeams;
    };

    const query = gql`
    query MyQuery {
                timeoffdb_Leaves(where: {employeeId: {_eq: "${userId}"}, organizationId: {_eq: "${
        mycontext.organizationId
    }"}, endDate: {_lt: "${toAWSDateFormat(new Date())}"}}) {
                    leaveId
                    startDate
                    endDate
                    startTime
                    endTime
                    category
                    leaveAppliedBy
                    isoString
                    leaveStatus
                    reason
                    approverId
                    denierId
                    gmt
                    noOfHours
                    isoString
                }
    }`;

    const query2 = gql`query MyQuery {
                timeoffdb_Leaves(where: {leaveStatus: {_eq: "approved"}, employeeId: {_eq: "${userId}"}, organizationId: {_eq: "${
        mycontext.organizationId
    }"}, startDate: {_lte: "${toAWSDateFormat(new Date())}"}, endDate: {_gte: "${toAWSDateFormat(new Date())}"}}) {
                    organizationId
                    leaveCount
                    leaveStatus
                    leaveId
                    category
                    reason
                    startDate
                    endDate
                    leaveAppliedBy
                    startTime
                    endTime
                    approverId
                    denierId
                    gmt
                    denialReason
                    gmt
                    isoString
                    noOfHours
                    Employee{
                        firstName
                        lastName
                        employeeId
                        profileImage
                    }
                }
            }`;

    const query3 = gql`query MyQuery {
                timeoffdb_Leaves(where: {leaveStatus: {_eq: "approved"}, employeeId: {_eq: "${userId}"}, organizationId: {_eq: "${
        mycontext.organizationId
    }"}, endDate: {_gte: "${toAWSDateFormat(new Date())}"}}) {
                    organizationId
                    leaveCount
                    leaveStatus
                    leaveId
                    category
                    reason
                    startDate
                    endDate
                    leaveAppliedBy
                    startTime
                    endTime
                    approverId
                    denierId
                    denialReason
                    gmt
                    isoString
                    noOfHours
                    Employee{
                        firstName
                        lastName
                        employeeId
                        profileImage
                    }
                }
            }`;

    const query4 = gql`
        query MyQuery {
            timeoffdb_get_totalhours(
                args: {
                    employeeid: "${userId}"
                    organizationid: "${mycontext.organizationId}"
                }
                order_by: { leaveType: asc }
            ) {
                leaveType
                totalHoursLeave
            }
        }
    `;

    const query5 = gql`
        query MyQuery {
            timeoffdb_LeaveType(
                where: { organizationId: { _eq: "${mycontext.organizationId}" },
                forYear: { _eq: "${new Date().getFullYear()}" }}
                order_by: { leaveTypeName: asc }
            ) {
                leaveTypeName
                daysPerYear
            }
        }
    `;

    const query6 = gql`
        query MyQuery {
            usermanagementdb_organization_employee(where: {employeeId: {_eq: "${userId}"}, organizationId: {_eq: "${mycontext.organizationId}"}}) {
                employeeId
                role
                Employee {
                    firstName
                    lastName
                    profileImage
                    employeeId
                    dateOfBirth
                }
                workAnniversary
                isYear
                isDOB
            }
        }
    `;

    const query7 = gql`
        query MyQuery {
            usermanagementdb_Team(where: {_and: {organizationId: {_eq: "${mycontext.organizationId}"}, team_employees: {employeeId: {_eq: "${userId}"}}}}) {
                teamId
                teamName
                team_employees(where: {employeeId: {_eq: "${userId}"}}) {
                    isApprover
                }
            }
        }
    `;

    //     const query8 = gql`query MyQuery {
    //                         pollsdb_Polls(where: {orgId: {_eq: "${mycontext.organizationId
    //         }"}, createdBy: {_eq: "${userId}"}, _and: {createdOn: {_eq: "${toAWSDateFormat(
    //             new Date(),
    //         )}"}, pollEndTime: {_lte: "${currenttime[0]}"}}}) {
    //                             adminChoices
    //                         createdBy
    //                         createdOn
    //                         isAnonymous
    //                         isSingleVote
    //                         pollEndsDateTime
    //                         pollEndTime
    //                         pollId
    //                         orgId
    //                         question
    //                         poll_results {
    //                         userChoice
    //                         }
    //                                             }
    //                                             }
    //                         `;

    //     const query9 = gql`query myquery {
    //                         pollsdb_Polls(where: {orgId: {_eq: "${mycontext.organizationId
    //         }"}, createdBy: {_eq: "${userId}"}, createdOn: {_lt: "${toAWSDateFormat(new Date())}"}}) {
    //                             adminChoices
    //                         createdBy
    //                         createdOn
    //                         isAnonymous
    //                         isSingleVote
    //                         pollEndTime
    //                         pollEndsDateTime
    //                         pollId
    //                         orgId
    //                         question
    //                         poll_results {
    //                         userChoice
    //                         }
    //                                             }
    //                                             }`;

    //     const query10 = gql`query MyQuery {
    //       pollsdb_Polls(where: {orgId: {_eq: "${mycontext.organizationId
    //         }"}, createdBy: {_eq: "${userId}"}, _and: {createdOn: {_eq: "${toAWSDateFormat(
    //             new Date(),
    //         )}"}, pollEndTime: {_lte: "${currenttime[0]}"}, poll_teams: {Team: {team_employees: {employeeId: {_eq: "${mycontext.EmployeeId
    //         }"}}}}}}) {
    //         adminChoices
    //         createdBy
    //         createdOn
    //         isAnonymous
    //         isSingleVote
    //         pollEndsDateTime
    //         pollEndTime
    //         pollId
    //         orgId
    //         question
    //         poll_results {
    //           userChoice
    //         }
    //       }
    //     }`;

    //     const query11 = gql`query myquery {
    //   pollsdb_Polls(where: {orgId: {_eq: "${mycontext.organizationId
    //         }"}, createdBy: {_eq: "${userId}"}, createdOn: {_lt: "${toAWSDateFormat(
    //             new Date(),
    //         )}"}, poll_teams: {Team: {team_employees: {employeeId: {_eq: "${mycontext.EmployeeId}"}}}}}) {
    //     adminChoices
    //     createdBy
    //     createdOn
    //     isAnonymous
    //     isSingleVote
    //     pollEndTime
    //     pollEndsDateTime
    //     pollId
    //     orgId
    //     question
    //     poll_results {
    //       userChoice
    //     }
    //   }
    // }`;

    const query12 = gql`query MyQuery {
  shoutoutdb_Shoutout(where: {organizationId: {_eq: "${mycontext.organizationId}"}, createdBy: {_eq: "${userId}"}}) {
    createdOn
    tags
    shoutoutId
    Employee {
      firstName
      profileImage
    }
    shoutout_employee_teams {
        isPersonal
      Employee {
        firstName
        profileImage
        employeeId
      }
      Team{
        teamName
        
      }
    }
  }
}`;

    const query13 = gql`query MyQuery {
  shoutoutdb_Shoutout(where: {organizationId: {_eq: "${mycontext.organizationId}"}, shoutout_employee_teams: {_or: [{employeeId: {_eq: "${userId}"}}, {Team: {team_employees: {employeeId: {_eq: "${userId}"}}}}]}}) {
    createdOn
    tags
    shoutoutId
    Employee {
      firstName
      profileImage
    }
    shoutout_employee_teams {
        isPersonal
      Employee {
        firstName
        profileImage
        employeeId
      }
      Team{
        teamName 
      }
    }
  }
}
`;

    const { loading: tableContentLoading, error: tableContentError, data: tableContent } = useQuery(query);
    const { loading: todayLeavesLoading, error: todayLeavesError } = useQuery(query2);
    // const { loading: upcomingLeavesLoading, error: upcomingLeavesError } = useQuery(query3);
    // const { loading: todayLeavesLoading, error: todayLeavesError, data: todayLeaves } = useQuery(query2);
    const { loading: upcomingLeavesLoading, error: upcomingLeavesError, data: upcomingLeaves } = useQuery(query3);
    const { loading: leaveDataLoading, error: leaveDataError, data: leaveData } = useQuery(query4);
    const { loading: leaveTypeLoading, error: leaveTypeError, data: leaveType } = useQuery(query5);
    const { loading: basicProfileLoading, error: basicProfileError, data: basicProfile } = useQuery(query6);
    const { loading: empTeamsLoading, error: empTeamsError, data: empTeams } = useQuery(query7);
    const { loading: pollsLoading, error: pollsError, data: polls } = useQuery(
        query8(mycontext.organizationId, userId, currenttime),
    );
    // const { loading: pollsLoading1, error: pollsError1, data: polls1 } = useQuery(query9(mycontext.organizationId, userId, toAWSDateFormat(
    //     new Date())));
    const { loading: OtherEmpPollsLoading, error: OtherEmpPollsError, data: OtherEmpPolls } = useQuery(
        query10(mycontext.organizationId, userId, currenttime, mycontext.EmployeeId),
    );
    // const { loading: OtherEmpPollsLoading1, error: OtherEmpPollsError1, data: OtherEmpPolls1 } = useQuery(query11(mycontext.organizationId, userId, toAWSDateFormat(
    //     new Date()), mycontext.EmployeeId));
    const { loading: OtherEmpShoutoutLoading, error: OtherEmpShoutoutError, data: OtherEmpShoutout } = useQuery(
        query12,
    );
    const { loading: OtherEmpShoutoutLoading1, error: OtherEmpShoutoutError1, data: OtherEmpShoutout1 } = useQuery(
        query13,
    );

    if (
        (tableContentLoading ||
            todayLeavesLoading ||
            upcomingLeavesLoading ||
            leaveDataLoading ||
            leaveTypeLoading ||
            basicProfileLoading ||
            empTeamsLoading ||
            state.orgEmployees.status === 'loading' ||
            state.settings.status === 'loading' ||
            state.leaveTypes.status === 'loading' ||
            pollsLoading ||
            loading ||
            OtherEmpPollsLoading ||
            // OtherEmpPollsLoading1 ||
            OtherEmpShoutoutLoading ||
            OtherEmpShoutoutLoading1) &&
        mycontext.isAdmin
    )
        return <EmployeeSkeleton />;
    if (
        (tableContentLoading ||
            todayLeavesLoading ||
            upcomingLeavesLoading ||
            leaveDataLoading ||
            leaveTypeLoading ||
            basicProfileLoading ||
            empTeamsLoading ||
            pollsLoading ||
            loading ||
            OtherEmpPollsLoading ||
            // OtherEmpPollsLoading1 ||
            OtherEmpShoutoutError ||
            OtherEmpShoutoutError1) &&
        !mycontext.isAdmin
    )
        return <AccordionSkeleton />;
    if (
        tableContentError ||
        todayLeavesError ||
        upcomingLeavesError ||
        leaveDataError ||
        leaveTypeError ||
        basicProfileError ||
        empTeamsError ||
        state.orgEmployees.status === 'loading' ||
        state.settings.status === 'loading' ||
        state.leaveTypes.status === 'loading' ||
        pollsError ||
        error ||
        OtherEmpPollsError ||
        // OtherEmpPollsError1 ||
        OtherEmpShoutoutError ||
        OtherEmpShoutoutError1
    )
        return <ErrorPage msg="Something went wrong!" />;

    const e: any = [];
    const d: any = [];

    OtherEmpShoutout?.shoutoutdb_Shoutout?.map((abc: any, index: any) => {
        abc.shoutout_employee_teams.map((def: any) => {
            if (def.isPersonal === false) {
                e.push(OtherEmpShoutout.shoutoutdb_Shoutout[index]);
            } else {
                if (def.Employee?.employeeId === mycontext.EmployeeId) {
                    e.push(OtherEmpShoutout.shoutoutdb_Shoutout[index]);
                }
            }
            return null;
        });
        return null;
    });
    OtherEmpShoutout1?.shoutoutdb_Shoutout?.map((abc: any, index: any) => {
        // eslint-disable-next-line
        abc.shoutout_employee_teams.map((def: any) => {
            if (def.isPersonal === false) {
                d.push(OtherEmpShoutout1.shoutoutdb_Shoutout[index]);
            } else {
                if (def.Employee?.employeeId === mycontext.EmployeeId) {
                    d.push(OtherEmpShoutout1.shoutoutdb_Shoutout[index]);
                }
            }
        });
        return null;
    });
    const uniqueData = [...d.reduce((map: any, obj: any) => map.set(obj.shoutoutId, obj), new Map()).values()];
    const uniqueData1 = [...e.reduce((map: any, obj: any) => map.set(obj.shoutoutId, obj), new Map()).values()];

    const sum = [...uniqueData, ...uniqueData1];

    const frontend = OtherEmpShoutout1?.shoutoutdb_Shoutout?.filter((g: any) => g.tags === '#frontend');
    const teamwork = OtherEmpShoutout1?.shoutoutdb_Shoutout?.filter((g: any) => g.tags === '#teamwork');
    const backend = OtherEmpShoutout1?.shoutoutdb_Shoutout?.filter((g: any) => g.tags === '#backend');

    const basicDetails = basicProfile.usermanagementdb_organization_employee[0].Employee;
    const role = basicProfile.usermanagementdb_organization_employee[0].role;
    const workAnniversary = basicProfile.usermanagementdb_organization_employee[0].workAnniversary;
    const teamDetails = getTeams(empTeams.usermanagementdb_Team);
    const details = basicProfile.usermanagementdb_organization_employee[0];

    const totalDaysPerYear: any = leaveType.timeoffdb_LeaveType.reduce(function(previousValue: any, currentValue: any) {
        return previousValue + currentValue.daysPerYear;
    }, 0);

    const totalAvailableLeaves: any = leaveType.timeoffdb_LeaveType.filter((e: any) => e.daysPerYear !== 0);
    const totalUsedLeaves: any = leaveData.timeoffdb_get_totalhours.filter((e: any) => {
        return totalAvailableLeaves.some((f: any) => {
            return f.leaveTypeName === e.leaveType;
        });
    });
    const demoData: any = totalUsedLeaves.reduce((previousValue: any, currentValue: any) => {
        return previousValue + currentValue.totalHoursLeave;
    }, 0);

    const arr1 = polls.pollsdb_Polls;
    // const arr2 = polls1.pollsdb_Polls;
    // const arr3 = [...arr1, ...arr2];
    const arr4 = OtherEmpPolls.pollsdb_Polls;
    // const arr5 = OtherEmpPolls1.pollsdb_Polls;
    // const arr6 = [...arr4, ...arr5];
    const c: any = [];
    if (arr1.length !== 0) {
        arr1?.map(async (val: any, i: any) => {
            const a: any = [];
            const obj: any = [];
            await val.adminChoices?.map((n: string) => {
                let c = 0;
                val?.poll_results?.map((d: any) => {
                    if (d.userChoice.includes(n)) {
                        c = c + 1;
                        return a.push({
                            userChoice: n,
                        });
                    }
                    return null;
                });
                obj.push({
                    name: n,
                    value: c,
                });
                return null;
            });
            // console.log("THIS IS OBJ", obj)
            c.push(obj);
        });
    }

    if (mycontext.isAdmin === true) {
        return mycontext.subscriptionExpired === 'cancelled' &&
            Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
            <SubscriptionHardExpired
                content="Looks like your subscription is expired, Please renew it !"
                showRenew={true}
            />
        ) : (
            <div className={classes.main}>
                <EmployeeHeader backTo={history.location.state.backTo} />

                <div className={classes.accordionGridStyle1}>
                    <div className={classes.gridmain}>
                        <div>
                            <EmployeeProfile
                                firstName={basicDetails?.firstName}
                                lastName={basicDetails?.lastName}
                                employeeId={basicDetails?.employeeId}
                                role={role}
                                approverTeams={teamDetails?.approvers}
                                employeeTeams={teamDetails?.employees}
                                totalDaysPerYear={totalDaysPerYear}
                                demoData={demoData}
                                image={basicDetails.profileImage}
                                orgWorkingHours={orgWorkingHours}
                                dateOfBirth={basicDetails?.dateOfBirth}
                                workAnniversary={workAnniversary}
                                isDOB={details.isDOB}
                                isYear={details.isYear}
                            />
                        </div>
                        <div className={classes.background}>
                            <Tabs
                                value={value}
                                onChange={handleTabs}
                                indicatorColor="primary"
                                // textColor="primary"
                                variant="scrollable"
                                className={classes.tabs}
                            >
                                <Tab label="Time-Offs" className={classes.tab} id="timeoff" />
                                {hasFeature('employeeProfilePolls') ? (
                                    <Tab label="Polls" className={classes.tab} id="polls" />
                                ) : null}
                                {hasFeature('employeeProfileShoutOut') ? (
                                    <Tab label="Shoutouts" className={classes.tab} id="shoutouts" />
                                ) : null}
                                {hasFeature('employeeProfileStandup') ? (
                                    <Tab label="Standups" className={classes.tab} id="standups" />
                                ) : null}
                            </Tabs>
                            {/* </Paper> */}
                            <TabPanel value={value} index={0}>
                                <LeaveHistoryTable
                                    timeOffDetails={leaveType.timeoffdb_LeaveType}
                                    tableContent={tableContent.timeoffdb_Leaves}
                                    orgEmployees={state.orgEmployees.employees}
                                    timeoff={timeoff}
                                    basicDetail={basicDetails}
                                />
                            </TabPanel>
                            {hasFeature('employeeProfilePolls') ? (
                                <TabPanel value={value} index={1}>
                                    <PollHistoryTable setcurrentPoll={setcurrentPoll} empPolls={arr1} />
                                </TabPanel>
                            ) : null}

                            {hasFeature('employeeProfileShoutOut') ? (
                                <TabPanel value={value} index={2}>
                                    <ShoutoutHistory shoutouts={sum} />
                                </TabPanel>
                            ) : null}
                            {hasFeature('employeeProfileStandup') ? (
                                <TabPanel value={value} index={3}>
                                    <div className={classes.polls1}>
                                        <Typography className={classes.headerTitle}>
                                            Standups history will appear hear
                                        </Typography>
                                    </div>
                                </TabPanel>
                            ) : null}
                        </div>
                    </div>
                    <div style={{}}>
                        <TabPanel value={value} index={0}>
                            <div className={classes.bg}>
                                <Typography className={classes.headerTitle1}>Time-Off Insights</Typography>
                                <div className={classes.rightbox}>
                                    <EmployeePieChart
                                        orgWorkingHours={orgWorkingHours}
                                        totalUsedDays={demoData}
                                        totalAvailableDays={totalDaysPerYear}
                                    />
                                    <div style={{ marginLeft: 20, marginRight: 20, paddingTop: 20 }}>
                                        <Divider className={classes.divider1} />
                                    </div>

                                    <EmployeeTimeOffStatus
                                        orgWorkingHours={orgWorkingHours}
                                        totalLeaveHours={leaveData.timeoffdb_get_totalhours}
                                        leaveType={leaveType.timeoffdb_LeaveType}
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        {hasFeature('employeeProfilePolls') ? (
                            <TabPanel value={value} index={1}>
                                <PollsInsights empPolls={arr1} currentPoll={currentPoll} />
                            </TabPanel>
                        ) : null}
                        {hasFeature('employeeProfileShoutOut') ? (
                            <TabPanel value={value} index={2}>
                                <ShoutoutInsights
                                    shoutoutreceivedcount={OtherEmpShoutout1.shoutoutdb_Shoutout.length}
                                    shoutoutgivencount={OtherEmpShoutout.shoutoutdb_Shoutout.length}
                                    frontend={frontend.length}
                                    backend={backend.length}
                                    teamwork={teamwork.length}
                                />
                            </TabPanel>
                        ) : null}

                        {hasFeature('employeeProfileStandup') ? (
                            <TabPanel value={value} index={3}>
                                <div className={classes.insightbg}>
                                    <div>
                                        <Typography className={classes.headerTitle1}>Standup Insights</Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '430px',
                                        }}
                                    >
                                        <Typography className={classes.headerTitle}>
                                            Standup Insights will appear hear
                                        </Typography>
                                    </div>
                                </div>
                            </TabPanel>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    } else
        return mycontext.subscriptionExpired === 'cancelled' &&
            Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
            <SubscriptionHardExpired
                content="Subscription expired, Please contact your admin to renew subscription"
                showRenew={false}
            />
        ) : (
            <div style={{ display: 'grid', gap: '10px' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <EmployeeHeader backTo={history.location.state.backTo} />
                </div>
                <div className={classes.accordionGridStyle}>
                    <div className={classes.approverProfileGrid}>
                        <EmployeeProfile3
                            firstName={basicDetails?.firstName}
                            lastName={basicDetails?.lastName}
                            employeeId={basicDetails?.employeeId}
                            role={role}
                            image={basicDetails.profileImage}
                            approverTeams={teamDetails.approvers}
                            employeeTeams={teamDetails.employees}
                            dateOfBirth={basicDetails?.dateOfBirth}
                            workAnniversary={workAnniversary}
                            isDOB={details.isDOB}
                            isYear={details.isYear}
                        />
                    </div>

                    {/* <Hidden only={['xs', 'sm']}> */}
                    <div className={classes.approverProfileGrid1}>
                        <div className={classes.tabbg}>
                            <Tabs
                                value={features}
                                onChange={handleFeatures}
                                indicatorColor="primary"
                                variant="scrollable"
                            >
                                <Tab label="Time-Offs" {...a11yProps(0)} className={classes.tab} />
                                {hasFeature('employeeProfilePolls') ? (
                                    <Tab label="Polls" {...a11yProps(1)} className={classes.tab} />
                                ) : null}
                                {hasFeature('employeeProfileShoutOut') ? (
                                    <Tab label="Shoutouts" {...a11yProps(2)} className={classes.tab} />
                                ) : null}

                                {hasFeature('employeeProfileStandup') ? (
                                    <Tab label="Standups" {...a11yProps(3)} className={classes.tab} />
                                ) : null}
                            </Tabs>
                        </div>

                        <TabPanel1 value={features} index={0}>
                            <LeaveHistoryOther upcomingLeaves={upcomingLeaves} />
                        </TabPanel1>
                        <TabPanel1 value={features} index={1}>
                            <OtherEmpPollsHistory polls={arr4} userId={userId} />
                        </TabPanel1>
                        <TabPanel1 value={features} index={2}>
                            <ShoutoutOther shoutouts={sum} />
                        </TabPanel1>
                        <TabPanel1 value={features} index={3}>
                            <div
                                style={{
                                    height: '60vh',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}
                            >
                                Standups data will appear hear
                            </div>
                        </TabPanel1>
                    </div>
                    {/* </Hidden> */}
                </div>
            </div>
        );
}

function TabPanel(props: any) {
    const { children, value, index } = props;
    return <div>{value === index && <h1>{children}</h1>}</div>;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel1(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Typography>{children}</Typography>}
        </div>
    );
}
