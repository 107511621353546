import { gql } from '@apollo/client';

export function getSettings(organizationId: string) {
    return gql`
        subscription Settings {
            timeoffdb_GeneralSettings(where: {organizationId: {_eq: "${organizationId}"}}) {
                approverCalledAs
                dateFormat
                employeeCalledAs
                subscriptionPlan
                teamsCalledAs
                workWeek
                workingHours
                settingId
                organizationId
                Organization {
                    organizationName
                    Tenant {
                        workspaceId
                        name
                        logo
                        displayLogo
                    }
                }
            }
        }
    `;
}
