import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
export const QuickTourStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            // color: theme.palette.text.secondary,
            fontSize: 20,
            // color: theme.palette.primary.main,
            color: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            // border: '1px solid red',
        },
        content: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);
