// import AuthContext from 'context/AuthContext';
import React, { useContext } from 'react';
// import { useLocation } from 'react-router-dom';
import PollCreator from './creator';
import ReplyPoll from './employeePoll';
import AuthContext from '../../context/AuthContext';

export default function Response(props: { setStep: any }) {
    // const location: any = useLocation();
    // const pollsData: any = location.state?.poll;
    const mycontext = useContext(AuthContext);
    const pollsData: any = mycontext.currentPoll;
    // console.log('polls', pollsData);
    return (
        <>
            {pollsData.createdBy === mycontext.EmployeeId
                ? !mycontext.isAdmin && <PollCreator setStep={props.setStep} />
                : !mycontext.isAdmin && <ReplyPoll setStep={props.setStep} />}
        </>
    );
}
