import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import SignUp from './SignUp';
import Experience from './Experience';
import Features from './Features';
import Howitworks from './Howitworks';
import Main from './Main';
import { useDispatch } from 'react-redux';
import { updateActiveTab } from 'components/Redux/activeTab';
// import EmployeeExp from '../../Assets/dashboard/employeeExp.png';
// import Desktop from '../../Assets/dashboard/main_employee.png';
// import DesktopSmall from '../../Assets/dashboard/employee_mobile.png';
import { s3Bucket, landingpageImages } from '../../helpers/constants';

const features = [
    {
        id: 1,
        title: 'Easy Apply',
        description:
            'Team Members can apply for time-off or convey their breaks through a simple form on the portal and even through the TeamPlus Slackbot, in just two steps.',
        img: s3Bucket + landingpageImages.easyApply,
    },
    {
        id: 2,
        title: 'Better Collaboration',
        description:
            'Everyone has quick access to every member’s time-offs and holidays–so coordinating on availabilities and schedules doesn’t need a separate email chain or Slack thread.',
        img: s3Bucket + landingpageImages.betterCollaboration,
    },
    {
        id: 3,
        title: 'No Confusion',
        description:
            'Each member’s profile section has the exact number of time-offs available and the number of time-offs used out of the total assigned to them (even if it’s unlimited!).',
        img: s3Bucket + landingpageImages.noConfusion,
    },
    {
        id: 4,
        title: 'Convenient Integration',
        description:
            'TeamPlus easy integrates with your Slack Workspace. So, apply for a time-off becomes as easy as dropping in a Slack message.',
        img: s3Bucket + landingpageImages.convenientIntegration,
    },
];

const list = [
    {
        id: 1,
        task: 'Login to your organization on TeamPlus.',
    },
    {
        id: 2,
        task: 'Add a time-off request.',
    },
    {
        id: 3,
        task: 'View your time-off request status',
    },
    {
        id: 4,
        task: 'Know other employee’s availability status in your organization.',
    },
    {
        id: 5,
        task: 'View all the holidays and time-offs from Calendar.',
    },
];

export default function Employee() {
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch({ type: 'ACTIVE', payload: '/employee' });
        dispatch(updateActiveTab('/employee'));
    }, [dispatch]);
    return (
        <div style={{ overflowY: 'hidden' }}>
            <Header />
            <Main
                img={s3Bucket + landingpageImages.main_employee}
                imgSmall={s3Bucket + landingpageImages.employee_mbl}
                title="Conveying time-offs and availabilities minus the complications"
                subtitle="Keep everyone in the know-how about your availability without lengthy forms and unnecessary emails."
                btnText="Try TeamPlus"
                isAdmin={false}
            />
            <Howitworks list={list} img={s3Bucket + landingpageImages.employee_bg} />
            <Features
                heading="The best time management experience "
                spam="for your team"
                isEmployee={true}
                features={features}
            />
            <Experience
                img={s3Bucket + landingpageImages.itsA}
                title="It's cake walk for "
                spam="admins too"
                subtitle="TeamPlus is easy-to-use and hassle-free for admins and managers as it is for the employees. Here's what we've in store for you."
                btnText="TeamPlus Admin Experience"
            />
            <SignUp title="The new-age assistant to take good care of your teams is here. Try now!" />
            <Footer />
        </div>
    );
}
