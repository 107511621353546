import { createSlice } from '@reduxjs/toolkit';

export const tenantMemberSlice = createSlice({
    name: 'tenantMembers',
    initialState: { tenantMembers: {}, status: 'loading' },
    reducers: {
        fetchTenantMembers: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                state.tenantMembers = action.payload?.data?.usermanagementdb_tenant_employee;
            }
        },
    },
});
export const { fetchTenantMembers } = tenantMemberSlice.actions;

export default tenantMemberSlice.reducer;
