import React, { useState, useContext, useCallback, useEffect } from 'react';
// import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import MyModal from '../Modal/Modal';
import Form from 'react-bootstrap/Form';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './AddHolidayStyle';
import { Button, TextField, FormLabel, FormControlLabel, Checkbox, Hidden, useTheme } from '@material-ui/core';
// import IOSSwitch from '../IOSSwitch/Iosswitch';
import { DateField } from '../ImportHoliday/FormInputs';
import { createHoliday } from '../../helpers/hasuraapi';
import { toYearFormat, validatePastDate, validateDate } from '../../helpers/date';
import { toAWSDateFormat, toDayFormat } from '../../helpers/date';
import AuthContext from '../../context/AuthContext';
import { PopUp } from '../Modal/Modal';
import { addHoliday } from '../Redux/holidaysReducer';
import { useDispatch } from 'react-redux';

interface Props {
    Addholidaybtn: string;
    holidays: any;
    setIsUpdating?: any;
}

export default function AddHoliday(props: Props) {
    const theme = useTheme();
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertm, setAlertm] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [modal, setModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [holidayname, setHolidayName] = useState<string>('');
    const dispatch = useDispatch();

    // const toggle = () => {
    //     setModal(!modal);
    //     setError(false);
    //     setHolidayName('');
    // };
    const [date] = React.useState<Date>(); //setDate
    const [startdate, setstartDate] = React.useState<Date>(new Date());
    const [enddate, setendDate] = React.useState<Date>(new Date());

    const [state, setState] = React.useState({
        checkedA: false,
    });

    /* istanbul ignore next */
    const handleChangeFirst = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setendDate(startdate);
    };

    /* istanbul ignore next */
    const handleError = (event: any) => {
        setError(false);
    };

    /* istanbul ignore next */
    const handleChangeName = (event: any) => {
        setHolidayName(event.target.value);
    };

    const toggle = () => {
        setModal(!modal);
        setError(false);
        setHolidayName('');
        setState({ checkedA: false });
        setstartDate(new Date());
        setendDate(new Date());
    };
    const handleError1 = useCallback(() => {
        if (error === true) {
            setError(false);
            setErrorMsg('');
        }
    }, [startdate, enddate]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        handleError1();
    }, [handleError1]);

    /* istanbul ignore next */
    const submit = async () => {
        props.setIsUpdating(true);
        const obj = {
            holidayName: holidayname,
            date: toAWSDateFormat(date),
            day: toDayFormat(startdate),
            startDate: toAWSDateFormat(startdate),
            endDate: state.checkedA ? toAWSDateFormat(enddate) : toAWSDateFormat(startdate),
            year: toYearFormat(startdate),
            isMultidayHoliday: state.checkedA,
            organizationId: mycontext.organizationId,
        };
        try {
            const diff1 = validateDate(obj.startDate, obj.endDate);
            const diff2 = validatePastDate(obj.startDate, new Date().toDateString());
            const diff3 = validatePastDate(obj.endDate, new Date().toDateString());
            const diff4 = validatePastDate(obj.date, new Date().toDateString());

            if (
                props.holidays.some(
                    (h: any) =>
                        (h.holidayName === obj.holidayName &&
                            h.date === obj.date &&
                            h.isMultidayHoliday === obj.isMultidayHoliday) ||
                        (h.holidayName === obj.holidayName &&
                            h.startDate === obj.startDate &&
                            h.endDate === obj.endDate &&
                            h.isMultidayHoliday === obj.isMultidayHoliday) ||
                        h.holidayName === obj.holidayName,
                )
            ) {
                setModal(false);
                props.setIsUpdating(false);
                throw new Error('Holiday already exists!');
            }
            if (state.checkedA && toAWSDateFormat(startdate) === toAWSDateFormat(enddate)) {
                setError(true);
                setErrorMsg('Start Date and End Date should not be same');
                props.setIsUpdating(false);
            } else if (holidayname === '') {
                setError(true);
                setErrorMsg('Please Enter Holiday Name');
                props.setIsUpdating(false);
            } else if (state.checkedA === true && toAWSDateFormat(startdate) === toAWSDateFormat(enddate)) {
                setError(true);
                setErrorMsg('You cannot select same Date for multiple days');
                props.setIsUpdating(false);
            } else if (
                obj.isMultidayHoliday === false &&
                (diff4.error === true || diff2.error === true || diff3.error === true)
            ) {
                setError(true);
                setErrorMsg('Cant select previous date to add a Holiday!');
                props.setIsUpdating(false);
            } else if (obj.isMultidayHoliday === true && diff1.error === true) {
                setError(true);
                setErrorMsg('Invalid Holiday Date');
                props.setIsUpdating(false);
            } else if (obj.isMultidayHoliday === true && (diff2.error === true || diff3.error === true)) {
                setError(true);
                setErrorMsg('Cant select previous date to add a Holiday!');
                props.setIsUpdating(false);
            } else {
                const data = await createHoliday(obj, mycontext.token);
                if (data.error) {
                    setSeverity('error');
                    setAlertm(true);
                    setAlertMessage('Failed to add holiday, try again!');
                    props.setIsUpdating(false);
                } else {
                    dispatch(
                        addHoliday({
                            holidayId: data.holidayId,
                            startDate: toAWSDateFormat(startdate),
                            endDate: state.checkedA ? toAWSDateFormat(enddate) : toAWSDateFormat(startdate),
                            holidayName: holidayname,
                            holidayDay: toDayFormat(startdate),
                            isMultidayHoliday: state.checkedA,
                        }),
                    );
                    setModal(false);
                    setSeverity('success');
                    setAlertm(true);
                    setAlertMessage('Holiday added successfuly!');
                    props.setIsUpdating(false);
                    // window.location.reload();
                }
            }
        } catch (err) {
            setSeverity('error');
            setAlertm(true);
            setAlertMessage(err?.message);
        }
    };

    return (
        <div>
            <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
            <Hidden smDown>
                <Button id="AddHoliday" variant="contained" onClick={toggle} className={classes.btn} type="submit">
                    {props.Addholidaybtn}
                </Button>
            </Hidden>
            <div className={classes.screenSize}>
                <Button
                    id="AddHoliday"
                    variant="outlined"
                    onClick={toggle}
                    className={classes.btnFixed}
                    disableRipple={true}
                >
                    {props.Addholidaybtn}
                </Button>
            </div>
            <MyModal open={modal} setOpen={toggle} onModalClosed={toggle}>
                <div className={classes.paper}>
                    {/* istanbul ignore next */ error === true ? (
                        <>
                            <FormLabel component="legend" error id="HolidayError">
                                {errorMsg}
                            </FormLabel>
                        </>
                    ) : (
                        ''
                    )}
                    <Grid container></Grid>
                    <Form>
                        <Form.Group controlId="exampleForm.SelectCustom" style={{ marginTop: '10px' }}>
                            <Form.Label className={classes.label1}>Holiday Name</Form.Label>

                            <Form.Group controlId="exampleForm.ControlInput1">
                                <TextField
                                    autoComplete="off"
                                    name="holidayname"
                                    id="holidayname"
                                    placeholder="Holiday Name"
                                    value={holidayname}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={handleChangeName}
                                    margin="dense"
                                    className={classes.inputStyle}
                                />
                            </Form.Group>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: 0 }}>
                            <Grid container>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.checkedA}
                                            onChange={handleChangeFirst}
                                            name="checkedA"
                                            style={{ color: theme.palette.primary.main }}
                                            id="checkfield"
                                        />
                                    }
                                    label="Add holiday for multiple days"
                                />
                            </Grid>
                        </Form.Group>

                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Form.Group controlId="exampleForm.SelectCustom">
                                    <Form.Label className={classes.label1}>From</Form.Label>
                                    <div>
                                        <DateField
                                            setError={handleError}
                                            date={startdate}
                                            setDate={setstartDate}
                                            id="startdatefield"
                                        />
                                    </div>
                                </Form.Group>
                            </Grid>
                            {/* istanbul ignore next */ state.checkedA ? (
                                <Hidden only="xs">
                                    <Grid item sm={6}>
                                        <Form.Group controlId="exampleForm.SelectCustom1">
                                            <Form.Label className={classes.label1}>To</Form.Label>
                                            <DateField
                                                date={enddate}
                                                setDate={setendDate}
                                                id="enddatefield"
                                                setError={handleError}
                                            />
                                        </Form.Group>
                                    </Grid>
                                </Hidden>
                            ) : (
                                <Hidden only="xs">
                                    <Grid item sm={6}>
                                        <Form.Group controlId="exampleForm.SelectCustom1">
                                            <Form.Label className={classes.disablelabel} style={{ color: '#CCCCCC' }}>
                                                To
                                            </Form.Label>
                                            <DateField
                                                date={startdate}
                                                setDate={setstartDate}
                                                id="startdatefield"
                                                setError={handleError}
                                                disable={true}
                                            />
                                        </Form.Group>
                                    </Grid>
                                </Hidden>
                            )}
                        </Grid>

                        <div className={classes.screenSize}>
                            {/* istanbul ignore next */ state.checkedA ? (
                                <Grid item xs={12}>
                                    <Form.Group controlId="exampleForm.SelectCustom1">
                                        <Form.Label className={classes.label1}>To</Form.Label>
                                        <DateField id="enddatefield" date={enddate} setDate={setendDate} />
                                    </Form.Group>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Form.Group controlId="exampleForm.SelectCustom1">
                                        <Form.Label className={classes.label1} style={{ color: '#CCCCCC' }}>
                                            To
                                        </Form.Label>
                                        <DateField
                                            id="startdatefield"
                                            date={startdate}
                                            setDate={setstartDate}
                                            disable={true}
                                        />
                                    </Form.Group>
                                </Grid>
                            )}
                        </div>
                    </Form>

                    <br />

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button variant="outlined" color="primary" onClick={toggle} className={classes.btnOutlined}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submit}
                            className={classes.btn}
                            type="submit"
                            id="createHoliday"
                        >
                            Create
                        </Button>
                    </div>
                </div>
            </MyModal>
        </div>
    );
}
