import React, { useState, useContext, useRef, useEffect } from 'react';
import { Grid, FormLabel, Button, TextField, useTheme, Typography } from '@material-ui/core';
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import MyModal, { PopUp } from '../Modal/Modal';
import { uploadImage, EditNameProfile } from '../../helpers/hasuraapi';
import AuthContext from '../../context/AuthContext';
import { useStyles } from './employeeProfileStyles';
import { useDispatch } from 'react-redux';
import { useDebounceEffect } from './useDebounceEffect';
import { canvasPreview } from './canvasPreview';
// import { updateProfileName } from '../Redux/employeeListReducer';
import { updateImage, updateName } from '../Redux/selfProfileReducer';
import { DateField3 } from '../ImportHoliday/FormInputs';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { updateActiveTab } from 'components/Redux/activeTab';
import { selfProfileData } from 'components/Redux/selfProfileReducer';

import Checkbox from '@material-ui/core/Checkbox';
import { withStyles, Theme } from '@material-ui/core/styles';
import Switch from 'react-switch';

import 'react-image-crop/dist/ReactCrop.css';
import { getCurrentCountryDateTime } from 'helpers/timezone';
import { deleteCelebration } from 'helpers/celebrationApi';
// import { useHistory } from 'react-router-dom';

const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.info.dark,
    },
    tooltip: {
        backgroundColor: theme.palette.info.dark,
        // color: theme.palette.text.primary,
        color: theme.palette.info.contrastText,
        fontWeight: 500,
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: any) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}

interface EmployeeProfileProps {
    open?: any;
    setOpen?: any;
    setIsLoading?: any;
    // setEffect?: any;
}
export function EmployeeProfileModal(props: EmployeeProfileProps) {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const dispatch = useDispatch();
    // const history = useHistory();

    const [showInput, setShowInput] = useState<any>('');
    const [imgSrc, setImgSrc] = useState<any>('');
    // const [imageLoad, setImageLoad] = useState<any>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const [crop, setCrop] = useState<any>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');

    // const [scale, setScale] = useState(1);
    // const [rotate, setRotate] = useState(0);
    // const [aspect, setAspect] = useState<any>(16 / 9);

    /* istanbul ignore next */
    // const onLoad = (image: any) => {
    //     setImageLoad(image);
    //     const aspect = 1;
    //     const width =
    //         image.width / aspect < image.height * aspect
    //             ? 100 - 10
    //             : ((image.height * aspect) / image.width) * 100 - 10;
    //     const height =
    //         image.width / aspect > image.height * aspect ? 100 - 10 : (image.width / aspect / image.height) * 100 - 10;
    //     const y = (100 - height) / 2;
    //     const x = (100 - width) / 2;

    //     setCrop({ unit: '%', width, height, x, y, aspect });
    //     return false;
    // };

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, 16 / 9));
    }

    /* istanbul ignore next */
    function handleFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.
            // if (e.currentTarget.width < 120 || e.currentTarget.height < 50) {
            //     setImgSrc('');
            //     setSeverity('error');
            //     setAlert(true);
            //     setAlertMessage('Image is too small, please upload a bigger image');
            // } else {
            const reader = new FileReader();
            reader.addEventListener('load', () => setImgSrc(reader?.result?.toString() || ''));
            reader.readAsDataURL(e.target.files[0]);
            // }
        }
    }

    /* istanbul ignore next */
    const handleImageCancelClick = () => {
        props.setOpen(false);
        setImgSrc('');
        setShowInput('');
        // setCrop({ x: '30', y: '30', width: '110', height: '190' });
    };

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
            }
        },
        100,
        [completedCrop],
    );

    // function handleToggleAspectClick() {
    //     if (aspect) {
    //         setAspect(undefined);
    //     } else if (imgRef.current) {
    //         const { width, height } = imgRef.current;
    //         setAspect(16 / 9);
    //         setCrop(centerAspectCrop(width, height, 16 / 9));
    //     }
    // }

    /* istanbul ignore next */
    const handleSubmit = async () => {
        props.setIsLoading(true);
        if (imgSrc !== '') {
            const base64Image = previewCanvasRef?.current?.toDataURL('image/jpeg');
            props.setOpen(false);
            const profileImage = await uploadImage(mycontext.EmployeeId, base64Image, mycontext.token);
            mycontext.updateUserProfile(profileImage);
            setImgSrc('');
            setSeverity('success');
            setAlert(true);
            setAlertMessage('Image updated successfully!');
            dispatch(updateImage({ image: profileImage }));
            props.setIsLoading(false);
            setShowInput('');
            // setCrop({ x: '30', y: '30', width: '110', height: '190' });
            // props.setEffect('LMNO');
        } else {
            props.setIsLoading(false);
            setSeverity('error');
            props.setOpen(false);
            setAlert(true);
            setAlertMessage('Cannot update image!');
        }
    };

    return (
        <>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <MyModal
                // title="Upload profile image"
                open={props.open}
                setOpen={props.setOpen}
                onModalClosed={handleImageCancelClick}
            >
                <Grid container>
                    <Grid item xs={12} sm={12} className={classes.uploadfileDiv}>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <FormLabel id={'profileModalLabel'} className={classes.formLabel}>
                                    Upload an image
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {/* istanbul ignore next */ showInput === null ? null : (
                                    <input
                                        type="file"
                                        accept="image/*"
                                        placeholder="choose a file"
                                        color="primary"
                                        className={classes.inputbox}
                                        onChange={handleFile}
                                    />
                                )}
                            </Grid>
                            {/* istanbul ignore next */ Boolean(imgSrc) && (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '200px',
                                        width: '50px',
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '200px',
                                        }}
                                    >
                                        <ReactCrop
                                            crop={crop}
                                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                                            onComplete={c => setCompletedCrop(c)}
                                            aspect={undefined}
                                            style={{ maxHeight: '100%' }}
                                        >
                                            <img
                                                ref={imgRef}
                                                alt="Crop me"
                                                src={imgSrc}
                                                style={{ width: '100%', maxHeight: '200px' }}
                                                onLoad={onImageLoad}
                                            />
                                        </ReactCrop>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            height: '100%',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {Boolean(completedCrop) && (
                                            <p style={{ fontSize: '14px', width: '100%', marginBottom: '0px' }}>
                                                Preview:{' '}
                                            </p>
                                        )}
                                        {Boolean(completedCrop) && (
                                            <canvas
                                                ref={previewCanvasRef}
                                                style={{
                                                    border: '1px solid black',
                                                    objectFit: 'contain',
                                                    width: completedCrop?.width,
                                                    height: completedCrop?.height,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} style={{ paddingTop: '10px' }}>
                                <Button
                                    className={classes.cancelBtn}
                                    variant="outlined"
                                    color="primary"
                                    onClick={/* istanbul ignore next */ () => handleImageCancelClick()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={classes.submitBtn}
                                    variant="contained"
                                    color="primary"
                                    onClick={/* istanbul ignore next */ async () => await handleSubmit()}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MyModal>
        </>
    );
}

interface EditProfileProps {
    openname?: any;
    setOpenname?: any;
    // profileDetail?: any;
    // setEffect?: any;
    setIsLoading?: any;
    workAnniversary?: any;
    setworkAnniversary?: any;
    setdateOfBirth?: any;
    dateOfBirth?: any;
    isYear?: boolean;
    isDOB?: boolean;
}
export function EditProfile(props: EditProfileProps) {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const theme = useTheme();
    const dispatch = useDispatch();
    // const history = useHistory();
    // //console.log("Sa=", props.profileDetail.firstName, props.profileDetail.lastName );
    const [fname, setFname] = React.useState<string>(mycontext.fname);
    const [lname, setLname] = React.useState<string>(mycontext.lname);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    // const [disabled,setdisabled] = useState<boolean>(true);
    const [severity, setSeverity] = useState<string>('');
    const [startDate, setStartDate] = useState<any>(props.workAnniversary);
    const [endDate, setEndDate] = useState<any>(props.dateOfBirth);
    const [showdob, setshowdob] = useState<any>(props.isDOB);
    const [checked, setChecked] = React.useState<any>(props.isYear);

    useEffect(() => {
        dispatch(updateActiveTab('/employees'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showdob]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };
    const handlesetdob = async () => {
        setshowdob(!showdob);
    };
    const handleDates = (v: any) => {
        setStartDate(v);
        setEndDate(v);
    };

    /* istanbul ignore next */
    const handleClick = () => {
        props.setOpenname(false);
        setFname(mycontext.fname);
        setLname(mycontext.lname);
    };

    const handleAlert = async () => {
        await setSeverity('success');
        await setAlert(true);
        await setAlertMessage('Profile updated successfully!');
    };

    /* istanbul ignore next */
    const handleName = async () => {
        if (fname === '' || lname === '') {
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Name field cannot be empty, please enter your name');
        } else {
            props.setIsLoading(true);
            const obj = {
                EmployeeId: mycontext.EmployeeId,
                firstname: fname,
                lastname: lname,
                token: mycontext.token,
                org: mycontext.organizationId,
                tenantId: mycontext.tenantTableId,
                workAnniversary: startDate,
                dob: endDate,
                isDOB: showdob,
                isYear: checked,
            };
            props.setOpenname(false);
            const resp = await EditNameProfile(obj);

            try {
                if (resp.error) {
                    throw new Error('Something went wrong, try again!');
                } else {
                    // props.setIsLoading(true);
                    localStorage.setItem('fname', fname);
                    localStorage.setItem('lname', lname);
                    // localStorage.setItem('workAnniversary', obj.workAnniversary)
                    mycontext.updateName(fname, lname);
                    props.setworkAnniversary(obj.workAnniversary);
                    props.setdateOfBirth(obj.dob);
                    setshowdob(obj.isDOB);
                    setChecked(obj.isYear);
                    dispatch(
                        selfProfileData({
                            organizationId: mycontext.organizationId,
                            employeeId: mycontext.EmployeeId,
                            token: mycontext.token,
                        }),
                    );
                    await dispatch(
                        updateName({ firstName: resp.returning[0].firstName, lastName: resp.returning[0].lastName }),
                    );
                    await props.setIsLoading(false);
                    await handleAlert();

                    // props.setEffect('PQRS');
                }
                if (obj.workAnniversary !== null) {
                    //console.log('if');
                    const event = {
                        payload: {
                            employeeId: mycontext.EmployeeId,
                            tenantId: mycontext.tenantTableId,
                            departmentId: mycontext.organizationId,
                            celebrationDate: obj.workAnniversary,
                            category: 'work',
                            firstName: fname,
                            action: 'insert',
                            timeZone: getCurrentCountryDateTime(mycontext.country.zoneName)
                                .dateTime.toString()
                                .slice(25, 33),
                            user: mycontext.EmployeeId,
                        },
                    };
                    //console.log(event);
                    const res = await fetch('https://celebration-dev.azurewebsites.net/api/celebration-dev?', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(event),
                    });
                    const jsonData = await res.json();
                    //console.log(jsonData);
                    if (jsonData.statusCode === 500) {
                        throw new Error('Something went wrong, try again!');
                    }
                }
                if (obj.dob !== null) {
                    //console.log('eles');
                    if (obj.isDOB) {
                        // let check = true;
                        // try {
                        const event = {
                            payload: {
                                employeeId: mycontext.EmployeeId,
                                tenantId: mycontext.tenantTableId,
                                departmentId: mycontext.organizationId,
                                celebrationDate: obj.dob,
                                category: 'birthday',
                                firstName: fname,
                                action: 'insert',
                                timeZone: getCurrentCountryDateTime(mycontext.country.zoneName)
                                    .dateTime.toString()
                                    .slice(25, 33),
                                user: mycontext.EmployeeId,
                            },
                        };
                        //console.log(event);
                        const res = await fetch('https://celebration-dev.azurewebsites.net/api/celebration-dev?', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(event),
                        });
                        const jsonData = await res.json();
                        //console.log(jsonData);
                        if (jsonData.statusCode === 500) {
                            throw new Error('Something went wrong, try again!');
                        }
                    } else {
                        const resp = await deleteCelebration(mycontext.EmployeeId, mycontext.token, 'birthday');
                        if (resp.error) {
                            throw new Error('Something went wrong, try again!');
                        }
                    }
                }
            } catch (err) {
                new Error('Something went wrong, try again!');
                setSeverity('error');
                setAlert(true);
                setAlertMessage(err);
            }
        }

        // props.setOpenname(false);
    };

    return (
        <>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <MyModal open={props.openname} setOpen={props.setOpenname} onModalClosed={handleClick}>
                <Grid container className={classes.field}>
                    <Grid item xs={12} sm={6}>
                        <Grid container className={classes.smallField}>
                            <Grid item xs={12} sm={11}>
                                <FormLabel className={classes.formLabelForm}>First Name</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={11}>
                                <TextField
                                    className={classes.formControlLeft}
                                    id="fname"
                                    placeholder="First Name"
                                    value={fname}
                                    variant="outlined"
                                    onChange={/* istanbul ignore next */ (e: any) => setFname(e.target.value)}
                                    size="small"
                                    // style={{ backgroundColor: '#f2f3f4' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container className={classes.smallField}>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={11}>
                                <FormLabel className={classes.formLabelForm}> Last Name </FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={11} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <TextField
                                    className={classes.formControlRight}
                                    id="lname"
                                    placeholder="Last Name"
                                    value={lname}
                                    variant="outlined"
                                    onChange={/* istanbul ignore next */ (e: any) => setLname(e.target.value)}
                                    size="small"
                                    // style={{ backgroundColor: '#f2f3f4' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Grid container className={classes.smallField1}>
                            <Grid item xs={12} sm={11}>
                                <FormLabel className={classes.formLabelForm}>Date of birth</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={11}>
                                <DateField3
                                    date={endDate}
                                    setDate={setEndDate}
                                    id="enddatefield"
                                    handleDates={handleDates}
                                    style={{
                                        width: '100%',
                                        '& div': {
                                            '& input': {
                                                fontFamily: 'Nunito Sans',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                letterSpacing: '0.03em',
                                                backgroundColor: theme.palette.background.paper,
                                                color: theme.palette.text.primary,
                                            },
                                        },
                                        '& fieldset': {
                                            border: `1px solid ${theme.palette.primary.contrastText}`,
                                        },
                                        '@media only screen and (max-width: 650px)': {
                                            width: '100%',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={11}>
                                <div style={{ display: 'flex', paddingTop: 5, opacity: showdob ? 1 : 0.6 }}>
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                        disabled={!showdob}
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            transform: 'scale(0.8)',
                                        }}
                                    />
                                    <Typography style={{ fontSize: 12, paddingTop: 2 }}>
                                        Do not show birth year to others?
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container className={classes.smallField1}>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={11}>
                                <FormLabel className={classes.formLabelForm}> Date of joining </FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={11} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <DateField3
                                    date={startDate}
                                    setDate={setStartDate}
                                    id="startdatefield"
                                    handleDates={handleDates}
                                    style={{
                                        width: '100%',
                                        '& div': {
                                            '& input': {
                                                fontFamily: 'Nunito Sans',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                letterSpacing: '0.03em',
                                                backgroundColor: theme.palette.background.paper,
                                                color: theme.palette.text.primary,
                                            },
                                        },
                                        '& fieldset': {
                                            border: `1px solid ${theme.palette.primary.contrastText}`,
                                        },
                                        '@media only screen and (max-width: 650px)': {
                                            width: '100%',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div style={{ display: 'flex', paddingTop: 20 }}>
                            <Typography className={classes.typotext}>
                                Do you want others to view your DoB?&nbsp;
                                <LightTooltip
                                    arrow
                                    placement="right"
                                    title={
                                        <Typography>
                                            Your date of birth will be visible on your profile page. If you disable it,
                                            others can not view it and hence you would not get birthday wishes on your
                                            B-day.
                                        </Typography>
                                    }
                                >
                                    <InfoIcon style={{ color: '#3C48B5', paddingBottom: 3 }} fontSize="small" />
                                </LightTooltip>
                            </Typography>

                            <div style={{ paddingLeft: 20, paddingTop: 2 }}>
                                <Switch
                                    checked={showdob}
                                    onChange={handlesetdob}
                                    // onChange={() => handleleavestatus(!timeoff.unlimitedDays)}
                                    // checked={timeoff.unlimitedDays}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={18}
                                    width={34}
                                    onColor="#3C48B5"
                                    offColor="#CCCCCC"
                                    activeBoxShadow="0 0 0px 0px #CCCCCC"
                                    id="unlimitedTimeOffSwitch"
                                />
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} style={{ marginTop: '35px' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                className={classes.cancelBtn}
                                variant="outlined"
                                color="primary"
                                onClick={/* istanbul ignore next */ () => handleClick()}
                                // onClick={() => setOpenname(false)}
                                data-testid="btn"
                                id="cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                id="submit"
                                className={classes.submitBtn}
                                variant="contained"
                                color="primary"
                                onClick={/* istanbul ignore next */ () => handleName()}
                            >
                                Submit
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </MyModal>
        </>
    );
}
