import React from 'react';
import useStyles from './styles';

interface Props {
    formerrors?: any;
}

function RegisterFormErrors(props: Props): JSX.Element {
    const classes = useStyles();
    if (
        props.formerrors.error &&
        (props.formerrors.error.blankfield ||
            props.formerrors.error.passwordmatch ||
            props.formerrors.error.email ||
            props.formerrors.error.isNumber ||
            props.formerrors.error.passwordLength ||
            props.formerrors.error.otplength)
    ) {
        return (
            <div className={classes.container}>
                <div className={classes.row} id="formError">
                    {props.formerrors.error.passwordmatch ? 'Password value does not match confirm password value' : ''}
                    {props.formerrors.error.blankfield ? 'All fields are required' : ''}
                    {props.formerrors.error.email ? 'Please enter valid email' : ''}
                    {props.formerrors.error.isNumber ? 'Leave days should be between 1 to 50' : ''}
                    {props.formerrors.error.passwordLength
                        ? 'Password length should be greater than or equal to 8 characters'
                        : ''}
                    {props.formerrors.error.otplength ? 'OTP length should be equal to 6 digits' : ''}
                </div>
            </div>
        );
    } else {
        return <div />;
    }
}

export default RegisterFormErrors;
