import React from 'react';
import useStyles from './style';
import { Link } from 'react-router-dom';
import UdyamoLogo from '../../Assets/images/udyamo-white.png';

export default function Footer() {
    const classes = useStyles();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className={classes.footer}>
            <p className={classes.footerText1}>
                <Link to="/faqs" onClick={() => scrollToTop()}>
                    FAQs
                </Link>{' '}
                &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
                <Link to="/userguide" onClick={() => scrollToTop()}>
                    User Guide
                </Link>{' '}
                &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
                <Link to="/privacypolicy" onClick={() => scrollToTop()}>
                    Privacy Policy
                </Link>{' '}
                &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
                <Link to="/terms-of-service" onClick={() => scrollToTop()}>
                    Terms of Service
                </Link>{' '}
                &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
                <Link to="/refundpolicy" onClick={() => scrollToTop()}>
                    Refund & Cancellation
                </Link>
            </p>

            <p className={classes.footerText}>
                TeamPlus is a product developed by{' '}
                <img src={UdyamoLogo} alt="Udyamo" style={{ width: '12%', paddingBottom: '0.5%' }} />. All rights
                reserved. &copy;2021 Udyamo.net
            </p>
        </div>
    );
}
