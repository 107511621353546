import React from 'react';
import useStyles from './style';
import { Link } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import { s3Bucket, landingpageImages } from '../../helpers/constants';
export default function First() {
    const classes = useStyles();
    return (
        <>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <div className={classes.first}>
                    <h2 className={classes.heading}>Collaboration. Communication. Culture.</h2>
                    <p className={classes.subtitle}>
                        An all-encompassing tool for the new-age workspaces for easier collaboration, smoother
                        operations, and better flexibility–and happier teams.{' '}
                    </p>
                    <button className={classes.discoverBtn}>
                        <Link to="/main" style={{ textDecoration: 'none', color: 'white' }}>
                            Discover TeamPlus
                        </Link>
                    </button>
                    <img src={s3Bucket + landingpageImages.homepage_banner1} alt="" className={classes.dashboardImg} />
                </div>
            </Hidden>
            <Hidden only={['xl']}>
                <div className={classes.firstSmall}>
                    <h2 className={classes.headingSmall}>Collaboration. Communication. Culture.</h2>
                    <p className={classes.subtitleSmall}>
                        An all-encompassing tool for the new-age workspaces for easier collaboration, smoother
                        operations, and better flexibility–and happier teams.{' '}
                    </p>
                    <button className={classes.discoverBtnSmall}>
                        <Link to="/main" style={{ textDecoration: 'none', color: 'white' }}>
                            Discover TeamPlus
                        </Link>
                    </button>
                    <img src={s3Bucket + landingpageImages.first_mobile} alt="" className={classes.dashboardImgSmall} />
                </div>
            </Hidden>
        </>
    );
}
