import 'date-fns';
import React, { useContext } from 'react';
import { Select, MenuItem, Grid, FormControl, Chip, useTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import useStyles from './FormInputsStyles';
import MultiSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import AuthContext from '../../context/AuthContext';
import { toAWSDateFormat } from 'helpers/date';
import { MuiThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

interface SelectProps {
    label?: string;
    value?: any;
    setValue?: any;
    options?: any;
    default?: string;
    placeholder?: string;
    id?: string;
}

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#3D49B6',
            contrastText: '#fff',
        },
    },
});

const defaultDarkTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#19191E',
            contrastText: '#fff',
        },
        text: {
            primary: '#E5E7E9',
            disabled: '#fff',
            hint: '#fff',
        },
        background: {
            paper: '#2C2E38',
        },
    },
});
export function SelectComponent(props: SelectProps): JSX.Element {
    const classes = useStyles();
    return (
        <Grid container>
            <FormControl className={classes.formControl}>
                <Select
                    id={props.id}
                    className={classes.selectStyle}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            style: { marginTop: -10 },
                        },
                    }}
                    value={props.value}
                    onChange={(e: any) => props.setValue(e.target.value)}
                    displayEmpty
                    margin="dense"
                    variant="outlined"
                    renderValue={(selected: any) => {
                        if (selected.length === 0) {
                            return <p style={{ color: '#A6ACAF' }}>{props.placeholder}</p>;
                        }

                        return selected;
                    }}
                >
                    {props.options.map((option: any, i: number) => (
                        <MenuItem key={i} value={option.value} className={classes.menuItem}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}

interface DateProps {
    label?: string;
    date?: Date;
    setDate?: any;
    isHalf?: boolean;
    id?: any;
    setError?: any;
    disable?: boolean;
    style?: any;
}

export function DateField(props: DateProps) {
    const classes = useStyles();
    const theme = useTheme();
    const handleDateChange = (date: Date | null) => {
        try {
            if (!date) {
                throw new Error('Dont update date manually!');
            }
            props.setDate(date);
        } catch (err) {
            alert(err.message);
        }
    };
    const mycontext = useContext(AuthContext);

    return (
        <MuiThemeProvider theme={mycontext.themeToggle ? defaultDarkTheme : defaultMaterialTheme}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sm={props.isHalf ? 5 : 10}
                    style={{ backgroundColor: theme.palette.background.paper }}
                >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id={props.id}
                            className={classes.formControl}
                            fullWidth
                            required
                            inputVariant="outlined"
                            variant="inline"
                            format={mycontext.dateFormat === 'YYYY-MM-DD' ? 'yyyy-MM-dd' : 'dd-MM-yyyy'}
                            margin="dense"
                            value={props.date || new Date()}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                                style: {
                                    paddingRight: 0,
                                    color: theme.palette.text.primary,
                                    border: 0,
                                    outline: 'none',
                                },
                                className: classes.dateField,
                            }}
                            InputProps={{
                                style: {
                                    outline: 'none !important',
                                    color: theme.palette.text.primary,
                                    // border: 0,
                                },
                            }}
                            autoOk={true}
                            disabled={props.disable}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </MuiThemeProvider>
    );
}

export function DateField3(props: DateProps2) {
    const classes = useStyles();
    const theme = useTheme();
    const handleDateChange = (date: Date | null) => {
        try {
            if (props.oneday) {
                props.handleDates(toAWSDateFormat(date));
            } else {
                props.setDate(toAWSDateFormat(date));
            }
        } catch (err) {
            alert(err.message);
        }
    };
    const mycontext = useContext(AuthContext);

    return (
        <MuiThemeProvider theme={mycontext.themeToggle ? defaultDarkTheme : defaultMaterialTheme}>
            <Grid container>
                <Grid item xs={12} sm={12} style={{ backgroundColor: theme.palette.background.paper }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id={props.id}
                            className={classes.formControl}
                            fullWidth
                            required
                            inputVariant="outlined"
                            variant="inline"
                            format={mycontext.dateFormat === 'YYYY-MM-DD' ? 'yyyy-MM-dd' : 'dd-MM-yyyy'}
                            margin="dense"
                            value={props.date}
                            emptyLabel="DD-MM-YYYY"
                            onChange={handleDateChange}
                            // PopoverProps={{
                            //     PaperProps: {
                            //         style: {
                            //             paddingRight: 0,
                            //             color: theme.palette.text.primary,
                            //             border: 0,
                            //             outline: 'none',
                            //             background: theme.palette.background.paper
                            //         }
                            //     }
                            // }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                                style: {
                                    paddingRight: 0,
                                    color: theme.palette.text.primary,
                                    border: 0,
                                    outline: 'none',
                                    // color: 'white',
                                },
                                className: classes.dateField,
                            }}
                            InputProps={{
                                style: {
                                    outline: 'none !important',
                                    color: theme.palette.text.primary,
                                    // border: 0,
                                },
                            }}
                            autoOk={true}
                            disabled={props.disable}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </MuiThemeProvider>
    );
}

interface DateProps2 {
    label?: string;
    date?: Date;
    setDate?: any;
    handleDates?: any;
    id?: any;
    setError?: any;
    disable?: boolean;
    oneday?: boolean;
    style?: any;
}

export function DateField2(props: DateProps2) {
    const classes = useStyles();
    const theme = useTheme();
    const handleDateChange = (date: Date | null) => {
        try {
            if (!date) {
                throw new Error('Dont update date manually!');
            }
            if (props.oneday) {
                props.handleDates(toAWSDateFormat(date));
            } else {
                props.setDate(toAWSDateFormat(date));
            }
        } catch (err) {
            alert(err.message);
        }
    };
    const mycontext = useContext(AuthContext);

    return (
        <MuiThemeProvider theme={mycontext.themeToggle ? defaultDarkTheme : defaultMaterialTheme}>
            <Grid container>
                <Grid item xs={12} sm={12} style={{ backgroundColor: theme.palette.background.paper }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id={props.id}
                            className={classes.formControl}
                            fullWidth
                            required
                            inputVariant="outlined"
                            variant="inline"
                            format={mycontext.dateFormat === 'YYYY-MM-DD' ? 'yyyy-MM-dd' : 'dd-MM-yyyy'}
                            margin="dense"
                            value={props.date || new Date()}
                            onChange={handleDateChange}
                            // PopoverProps={{
                            //     PaperProps: {
                            //         style: {
                            //             paddingRight: 0,
                            //             color: theme.palette.text.primary,
                            //             border: 0,
                            //             outline: 'none',
                            //             background: theme.palette.background.paper
                            //         }
                            //     }
                            // }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                                style: {
                                    paddingRight: 0,
                                    color: theme.palette.text.primary,
                                    border: 0,
                                    outline: 'none',
                                    // color: 'white',
                                },
                                className: classes.dateField,
                            }}
                            InputProps={{
                                style: {
                                    outline: 'none !important',
                                    color: theme.palette.text.primary,
                                    // border: 0,
                                },
                            }}
                            autoOk={true}
                            disabled={props.disable}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </MuiThemeProvider>
    );
}

interface TimeProps {
    id?: string;
    time: Date;
    setTime: React.Dispatch<React.SetStateAction<string>>;
    disable?: boolean;
}
export function TimeField(props: TimeProps) {
    const classes = useStyles();
    const theme = useTheme();
    const handleTimeChange = (time: Date | null) => {
        try {
            if (!time) {
                throw new Error('Dont update time manually!');
            }
            props.setTime(time ? time.toTimeString().substring(0, 5) : new Date().toTimeString().substring(0, 5));
        } catch (err) {
            alert(err.message);
        }
    };
    const mycontext = useContext(AuthContext);
    return (
        <>
            <MuiThemeProvider theme={mycontext.themeToggle ? defaultDarkTheme : defaultMaterialTheme}>
                <Grid container>
                    <Grid item xs={12} sm={12} style={{ backgroundColor: theme.palette.background.paper }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                id={props.id}
                                className={classes.formControl}
                                fullWidth
                                required
                                inputVariant="outlined"
                                variant="inline"
                                margin="dense"
                                value={
                                    new Date(`Mon Apr 25 2022 ${props.time}:00 GMT+0530`).getTime() ||
                                    new Date().getTime()
                                }
                                onChange={handleTimeChange}
                                ampm={false}
                                // PopoverProps={{
                                //     PaperProps: {
                                //         style: {
                                //             paddingRight: 0,
                                //             color: theme.palette.text.primary,
                                //             border: 0,
                                //             outline: 'none',
                                //             background: theme.palette.background.paper
                                //         }
                                //     }
                                // }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    style: {
                                        paddingRight: 0,
                                        color: theme.palette.text.primary,
                                        border: 0,
                                        outline: 'none',
                                    },
                                    className: classes.dateField,
                                }}
                                InputProps={{
                                    style: {
                                        outline: 'none !important',
                                        color: theme.palette.text.primary,
                                        // border: 0,
                                    },
                                }}
                                autoOk={true}
                                disabled={props.disable}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        </>
    );
}

export function MultiSelectComponent(props: SelectProps): JSX.Element {
    const classes = useStyles();
    const animatedComponents = makeAnimated();

    return (
        <Grid container style={{ margin: '10px 0px' }}>
            <Grid item xs={12} sm={12}>
                <FormControl className={classes.formControl}>
                    <MultiSelect
                        options={props.options}
                        components={animatedComponents}
                        isMulti
                        onChange={(e: any) => props.setValue(e)}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 3,
                            padding: 5,
                            colors: {
                                ...theme.colors,
                                primary25: '#5565a4',
                                primary: '#374785',
                            },
                        })}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

export function MuiSelectComponent(props: SelectProps): JSX.Element {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <FormControl className={classes.formControl}>
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        multiple
                        value={props.value}
                        onChange={e => props.setValue(e.target.value)}
                        displayEmpty
                        variant="outlined"
                        margin="dense"
                        inputProps={{ 'aria-label': 'With label' }}
                        renderValue={(selected: any) => {
                            if (selected.length === 0) {
                                return (
                                    <span style={{ color: '#A6ACAF', textTransform: 'capitalize' }}>
                                        Please select teams
                                    </span>
                                );
                            }

                            return (
                                <div className={classes.chips}>
                                    {selected.map((value: string) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            );
                        }}
                    >
                        {props.options.map((name: string, i: number) => (
                            <MenuItem key={i} value={name} className={classes.menuItem}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
