import { createStyles, makeStyles, Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        parent: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        root2: {
            width: 'calc(100% - 250px)',
            height: '100%',
            marginTop: '60px',
            position: 'relative',
            float: 'right',
            '@media screen and (max-width: 1020px) and (min-width: 651px)': {
                width: 'calc(100% - 200px)',
            },
            '@media (max-width:650px)': {
                width: '100vw',
            },
        },
        main: {
            maxHeight: 'calc(100vh - 50px)',
            height: '100%',
            '&::-webkit-scrollbar': {
                width: '0.5em',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 3px rgba(0,0,0,0)',
                webkitBoxShadow: 'inset 0 0 3px #3C48B5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#3C48B5',
                outline: 'none',
            },
            width: '100%',
            zIndex: 0,
            backgroundColor: theme.palette.background.default,
            overflow: 'auto',
            padding: '10px 10px',
            position: 'relative',
            '@media screen and (max-width: 800px)': {
                padding: '10px',
                '&::-webkit-scrollbar': {
                    width: '0.2em',
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 3px rgba(0,0,0,0)',
                    webkitBoxShadow: 'inset 0 0 3px #3C48B5',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#3C48B5',
                    outline: 'none',
                },
            },
        },
        root: {
            backgroundColor: theme.palette.background.paper,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        first: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '200px',
            height: '100vh',
            transition: '0.5s',
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            zIndex: 9,
            '@media screen and (max-width: 650px)': {
                width: '0%',
                zIndex: 999,
            },
        },
        firstSmall: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '65px',
            height: '100vh',
            transition: '0.5s',
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '@media screen and (max-width: 650px)': {
                width: '0%',
            },
        },
        second: {
            paddingTop: 50,
            width: 'calc(100% - 200px)',
            height: '100vh',
            position: 'relative',
            left: 200,
            top: 0,
            transition: '0.5s',
            zIndex: 10,
            '@media screen and (max-width: 900px)': {
                left: 0,
                width: '100%',
                paddingTop: 50,
                zIndex: 9,
            },
        },
        secondSmall: {
            width: 'calc(100% - 65px)',
            height: '100vh',
            paddingTop: 50,
            position: 'relative',
            left: 65,
            top: 0,
            transition: '0.5s',
            '@media screen and (max-width: 900px)': {
                left: 0,
                width: '100%',
                paddingTop: 50,
            },
        },
        btn2: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            width: '100%',
            height: '100%',
            position: 'relative',
            outline: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:focus': {
                outline: 'none',
            },
        },
        btn1: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            width: 20,
            height: 20,
            borderRadius: '50%',
            position: 'absolute',
            right: '-200px',
            top: 25,
            outline: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '&:focus': {
                outline: 'none',
            },
        },
        icon: {
            fontSize: 25,
            fontWeight: 800,
            color: theme.palette.primary.main,
        },
        iconDiv: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '50px',
            cursor: 'pointer',
            elevation: 1,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        errorDiv: {
            width: '100%',
            height: '50px',
            padding: '0px 20px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            fontFamily: theme.typography.fontFamily,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
        },
    }),
);
export default useStyles;
