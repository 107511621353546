import React, { useContext } from 'react';
import { Button, Chip, Menu, MenuItem, Checkbox, Avatar, useTheme, Hidden } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import useStyles from './StandupDashboardStyle';
import { PieChart, Pie, Cell, Label } from 'recharts';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { useSubscription } from '@apollo/client';
import { getStandupsByTeams } from 'helpers/subscriptions/standupSubscriptions';
import AuthContext from 'context/AuthContext';
import ErrorPage from 'components/Error/ErrorPage';
// import { LoaderPage } from '../Loader/loader';
import { useSelector, useDispatch } from 'react-redux';
import FilterLogo from '../Employees/FilterLogo.svg';
import MainStandup from '../Standups/StandupTable';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import { getCurrentCountryDateTime, localToISO, convertedDateTime } from 'helpers/timezone';
import { initialDate } from 'helpers/date';
import { Point } from '../DashboardPage/DashboardPart';
import { LoaderPage } from 'components/Loader/loader';
import { updateActiveTab } from '../Redux/activeTab';

interface DonutViewProps {
    data?: any;
    state?: any;
    today?: any;
    allData?: any;
}

export const DonutView = (props: DonutViewProps) => {
    const theme = useTheme();
    const mycontext = useContext(AuthContext);
    const todayData = props?.allData?.filter((a: any, i: number) => {
        return (
            convertedDateTime(a?.standupNoteDateTime, mycontext.country).date ===
            convertedDateTime(props.today, mycontext.country).date
        );
    });
    ////console.log(todayData)
    const Accomplished = todayData?.filter((a: any) => {
        return a?.isAccomplished === true;
    }).length;
    const NotAccomplished = todayData?.filter((a: any) => {
        return a?.isAccomplished === false;
    }).length;
    const classes = useStyles();
    const data = [
        { name: 'Accomplished', value: Accomplished },
        { name: 'Not Accomplished', value: NotAccomplished },
    ];
    const noData = [
        { name: 'noData', value: 1 },
        { name: 'noData1', value: 0 },
    ];
    const COLORS = ['#389141', '#EC9142'];
    const COLORS1 = ['#0096FF'];
    const history = useHistory();
    return (
        <div className={classes.employeeStatistics}>
            <div className={classes.topdiv}>
                <div className={classes.TodayGoalText}>Todays Goal Achieved Status</div>
                <div className={classes.HideButton}>
                    <Link
                        to={{
                            pathname: '/settings',
                            state: {
                                value: 3,
                            },
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.btnStyle1}
                            startIcon={<SettingsIcon style={{ height: '15px', width: '15px' }} />}
                            onClick={() => history.push('/settings')}
                        >
                            settings
                        </Button>
                    </Link>
                </div>
            </div>
            {todayData?.length !== 0 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                        <PieChart
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            width={210}
                            height={100}
                        >
                            <Pie
                                data={data}
                                cx={100}
                                cy={90}
                                startAngle={180}
                                endAngle={0}
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                                <Label
                                    width={120}
                                    position="top"
                                    className={classes.Label}
                                    stroke={theme.palette.text.primary}
                                    strokeWidth={0.3}
                                >
                                    {Accomplished}
                                </Label>
                                <Label
                                    width={100}
                                    position="centerBottom"
                                    className={classes.Label1}
                                    stroke={theme.palette.text.primary}
                                    strokeWidth={0.3}
                                >
                                    Achieved goal
                                </Label>
                            </Pie>
                        </PieChart>
                    </div>
                    <div className={classes.line}>
                        <div className={classes.OnLine}></div>
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            height: '50px',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            data-testid="Pie"
                            style={{ display: 'flex', alignItems: 'center', marginRight: 'auto', marginLeft: 'auto' }}
                        >
                            <div className={classes.bullets} style={{ backgroundColor: '#478B4E' }}></div>
                            <div data-testid="Pie" className={classes.firstDivText}>
                                Goal Achieved: {Accomplished}
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto', marginLeft: 'auto' }}>
                            <div className={classes.bullets} style={{ backgroundColor: '#EE9547' }}></div>
                            <div className={classes.firstDivText}>Goal Not Achieved: {NotAccomplished}</div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div data-testid="donut" style={{ display: 'flex', justifyContent: 'center' }}>
                        <PieChart
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            width={210}
                            height={120}
                        >
                            <Pie
                                data={noData}
                                cx={100}
                                cy={110}
                                startAngle={180}
                                endAngle={0}
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS1[index % COLORS.length]} />
                                ))}

                                <Label
                                    width={120}
                                    position="top"
                                    className={classes.Label}
                                    stroke={theme.palette.text.primary}
                                    strokeWidth={0.3}
                                >
                                    0
                                </Label>
                                <Label
                                    width={100}
                                    position="centerBottom"
                                    className={classes.Label1}
                                    stroke={theme.palette.text.primary}
                                    strokeWidth={0.3}
                                >
                                    Standup
                                </Label>
                            </Pie>
                        </PieChart>
                    </div>
                </>
            )}
        </div>
    );
};

interface GraphView {
    alldata?: any;
    today?: any;
    state?: any;
    standupByTeams?: any;
    setorg?: any;
    setteamid?: any;
    teamid?: any;
    orgId?: any;
    team?: any;
    setteam?: any;
    data?: any;
    employee?: any;
    setemployee?: any;
    employeeName?: any;
    setemployeeName?: any;
    teamName?: any;
    setteamName?: any;
    Time?: any;
    setTime?: any;
    mycontext?: any;
    organization?: any;
    teamIdentity?: any;
}

export const GraphView = (props: GraphView) => {
    const COLORS = [
        '#587EE0',
        '#956FE4',
        '#4FB8B2',
        '#F2A142',
        '#a0d568',
        '#ed5564',
        '#ac92eb',
        '#20639b',
        '#FA8072',
        '#FFA07A',
        '#E9967A',
        '#F08080',
        '#CD5C5C',
        '#DFFF00',
        '#FFBF00',
        '#6495ED',
        '#CCCCFF',
        '#40E0D0',
        '#00FFFF',
        '#FF00FF',
        '#800080',
    ];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const theme = useTheme();
    const [category, setCategory] = React.useState('Teams');
    const [Filter, setFilter] = React.useState('This Week');
    const [data, setData] = React.useState([]);
    const mycontext = useContext(AuthContext);
    const localTime = getCurrentCountryDateTime(mycontext?.country.zoneName).dateTime;

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick1 = (event: any) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const handleClick2 = (event: any) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleChange = (e: any, a: any, i: any) => {
        setCategory('Employees');
        const data = a;
        const index = props?.employeeName?.indexOf(data);
        if (index > -1) {
            props?.setemployeeName([...props?.employeeName?.slice(0, index), ...props?.employeeName?.slice(index + 1)]);
        } else {
            props?.setemployeeName([...props?.employeeName, ...[data]]);
        }
        props.setteamName([]);
    };

    const Change = (e: any, a: any, i: any) => {
        //console.log('useChange', a);
        setCategory('Teams');
        const data = a;
        const index = props?.teamName?.indexOf(data);
        if (index > -1) {
            props.setorg(props?.organization);
            props.setteamid(props?.teamIdentity);
            props.setteam((current: any) =>
                [...current].filter(c => {
                    return c?.id !== a?.teamId;
                }),
            );
            props?.setteamName([...props?.teamName?.slice(0, index), ...props?.teamName?.slice(index + 1)]);
        } else {
            // if (a?.teamName !== 'general') {
            props.setorg(a?.organizationId);
            props.setteamid(a?.teamId);
            props?.setteamName([...props?.teamName, ...[data]]);
            // }
        }
        props.setemployeeName([]);
    };

    React.useEffect(() => {
        if (props?.employeeName?.length !== 0) {
            setCategory('Employee');
        } else {
            setCategory('Teams');
        }

        const data =
            category === 'Employee'
                ? props?.alldata?.filter((a: any, i: number) => {
                      return props?.employeeName?.some((e: any) => e?.employeeId === a?.employeeId && a.isAccomplished);
                  })
                : props?.team;

        //console.log('data render', data);

        const ThisYear = (teamName: any) => {
            const datas = { 0: [] };
            const arr: any = [];
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const workWeek = props?.state?.settings?.settings?.workWeek;
            const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            const currentMonth = months[new Date(localTime).getMonth()];
            const yearly = data?.filter((a: any) => {
                return (
                    parseInt(convertedDateTime(a?.standupNoteDateTime, mycontext.country).date.slice(0, 4)) ===
                        new Date(localTime).getFullYear() &&
                    workWeek?.includes(
                        weekDays[new Date(convertedDateTime(a?.standupNoteDateTime, mycontext.country).date).getDay()],
                    )
                );
            });

            months.map((a, i) => {
                return (datas[a] = yearly?.filter((a: any) => {
                    return (
                        parseInt(convertedDateTime(a?.standupNoteDateTime, mycontext.country).date.slice(5, 7)) ===
                        i + 1
                    );
                }));
            });
            //console.log('datas', datas);
            const obj = {};
            Object.entries(datas).map(([key, values]) => {
                teamName?.forEach((element: any) => {
                    return (obj[category === 'Teams' ? element?.teamName : element?.Employee?.firstName] =
                        months.indexOf(key) <= months.indexOf(currentMonth)
                            ? Array.isArray(values) &&
                              values.filter((a: any) => {
                                  return category === 'Teams'
                                      ? a.id === element.teamId
                                      : a.employeeId === element.employeeId;
                              }).length
                            : undefined);
                });
                return arr.push({ name: key, ...obj });
            });

            return arr;
        };
        const ThisMonth = (teamName: any) => {
            const datas = { 0: [] };
            const arr: any = [];
            const workWeek = props?.state?.settings?.settings?.workWeek;
            const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            const days = [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
                31,
            ];
            const dayss: any = [];
            const numberOfDays = new Date(
                new Date(localTime).getFullYear(),
                new Date(localTime).getMonth() + 1,
                0,
            ).getDate();
            days.slice(0, numberOfDays).map((a, i) => {
                const b = `${new Date(localTime).getFullYear()}-${new Date(localTime).getMonth() + 1}-${a}`;
                return workWeek?.includes(weekDays[new Date(b).getDay()]) ? dayss.push(a) : null;
            });
            const month = data
                ?.filter((a: any) => {
                    return (
                        parseInt(convertedDateTime(a?.standupNoteDateTime, mycontext.country).date.slice(0, 4)) ===
                        new Date(localTime).getFullYear()
                    );
                })
                .filter((a: any) => {
                    return (
                        parseInt(convertedDateTime(a?.standupNoteDateTime, mycontext.country).date.slice(5, 7)) ===
                        new Date(localTime).getMonth() + 1
                    );
                });
            dayss.map((b: any, i: number) => {
                return (datas[b] = month?.filter((a: any) => {
                    return (
                        parseInt(convertedDateTime(a?.standupNoteDateTime, mycontext.country).date.slice(8, 10)) === b
                    );
                }));
            });
            const obj = {};
            Object.entries(datas).map(([key, values]) => {
                teamName?.forEach((element: any) => {
                    return (obj[category === 'Teams' ? element?.teamName : element?.Employee?.firstName] =
                        parseInt(key) <= parseInt(new Date(localTime).toString().slice(8, 10))
                            ? Array.isArray(values) &&
                              values.filter((a: any) => {
                                  return category === 'Teams'
                                      ? a.id === element?.teamId
                                      : a.employeeId === element?.employeeId;
                              }).length
                            : undefined);
                });
                return arr.push({ name: key, ...obj });
            });

            return arr;
        };
        const ThisWeek = (Name: any) => {
            const datas: any = { 0: [] };
            const arr: any = [];
            const first = initialDate('week', 0, props.mycontext?.country?.zoneName);
            const last = new Date(first);
            last.setDate(last.getDate() + 6);
            const start = convertedDateTime(new Date(first).toISOString().slice(0, 10), mycontext.country).date;
            //console.log(start);
            const end = convertedDateTime(last.toISOString().slice(0, 10), mycontext.country).date;
            const workWeek = props?.state?.settings?.settings?.workWeek;
            const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            const arr1: any = [];
            const weeks = (start: any, end: any) => {
                for (let date = new Date(start); date <= new Date(end); date.setDate(date.getDate() + 1)) {
                    arr1.push(new Date(date));
                }
                return arr1;
            };

            const weekday = weeks(new Date(start), new Date(end)).map((v: any) => v.toISOString().slice(0, 10));
            const activeDays = weekday.filter((a: any, i: number) => {
                return workWeek?.includes(weekDays[new Date(a).getDay()]);
            });
            activeDays.map((b: any, i: number) => {
                return (datas[b] = data?.filter((a: any) => {
                    return convertedDateTime(a?.standupNoteDateTime, mycontext.country).date === b;
                }));
            });
            // console.log(data);
            const obj: any = {};
            Object.entries(datas).map(([key, values]) => {
                Name?.forEach((element: any) => {
                    // //console.log(new Date(key), new Date(localTime))
                    return (obj[category === 'Teams' ? element?.teamName : element?.Employee?.firstName] =
                        new Date(key) <= new Date(convertedDateTime(props.today, mycontext.country).date)
                            ? Array.isArray(values) &&
                              values.filter(a => {
                                  return category === 'Teams'
                                      ? a.id === element?.teamId
                                      : a.employeeId === element?.employeeId;
                              }).length
                            : undefined);
                });
                return arr.push({
                    name:
                        weekDays[new Date(key).getDay()].charAt(0).toUpperCase() +
                        weekDays[new Date(key).getDay()].slice(1).substring(0, 2),
                    ...obj,
                });
            });
            // console.log(arr);
            arr[0].name = '0';
            // console.log(arr);

            return arr;
        };
        if (Filter === 'This Week') {
            let a;
            if (category === 'Teams') {
                a = ThisWeek(props.teamName);
            } else {
                a = ThisWeek(props.employeeName);
            }
            setData(a);
        } else if (Filter === 'This Year') {
            let b;
            if (category === 'Teams') {
                b = ThisYear(props.teamName);
            } else {
                b = ThisYear(props.employeeName);
            }
            setData(b);
        } else {
            let c;
            if (category === 'Teams') {
                c = ThisMonth(props.teamName);
            } else {
                c = ThisMonth(props.employeeName);
            }
            setData(c);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, category, Filter]);

    const time = (value: string) => {
        if (value === 'Week') {
            setFilter('This Week');
        } else if (value === 'Year') {
            setFilter('This Year');
        } else {
            setFilter('This Month');
        }
    };

    const classes = useStyles();

    return (
        <div>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                PaperProps={{ className: classes.popoverPropsOne }}
                style={{ transform: 'translateY(37px)' }}
            >
                <MenuItem
                    id="this week"
                    value={'Year'}
                    className={classes.selectedMenus}
                    onClick={() => {
                        time('Week');
                        props.setTime({
                            endDate: localToISO(
                                getCurrentCountryDateTime(props?.mycontext?.country?.zoneName).date,
                                '23:59:00',
                                props?.mycontext?.country,
                            ),
                            startDate: initialDate('week', 0, props.mycontext.country.zoneName),
                        });
                    }}
                >
                    This Week
                </MenuItem>
                <MenuItem
                    id="this month"
                    value={'Month'}
                    className={classes.selectedMenus}
                    onClick={() => {
                        time('Month');
                        props.setTime({
                            endDate: localToISO(
                                getCurrentCountryDateTime(props?.mycontext?.country?.zoneName).date,
                                '23:59:00',
                                props?.mycontext?.country,
                            ),
                            startDate: initialDate('month', 0, props?.mycontext?.country?.zoneName),
                        });
                    }}
                >
                    This Month
                </MenuItem>
                <MenuItem
                    id="this year"
                    value={'Year'}
                    className={classes.selectedMenus}
                    onClick={() => {
                        time('Year');
                        props.setTime({
                            endDate: localToISO(
                                getCurrentCountryDateTime(props?.mycontext?.country?.zoneName).date,
                                '23:59:00',
                                props.mycontext.country,
                            ),

                            startDate: `${localTime.getFullYear()}-01-01`.concat('T00:00:00+00:00'),
                        });
                    }}
                >
                    This Year
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={anchorEl1}
                open={!!anchorEl1}
                onClose={handleClose1}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{ className: classes.popoverPropsTwo }}
                style={{ transform: 'translateY(32px)' }}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>Employees</div>
                <div className={classes.filterscroll}>
                    {props?.state?.orgEmployees?.employees?.map((a: any, i: number) => (
                        <MenuItem
                            key={i}
                            style={{
                                height: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            title={a?.Employee?.firstName + ' ' + a?.Employee?.lastName}
                            onClick={e => {
                                handleChange(e, a, i);
                            }}
                        >
                            <div style={{ position: 'absolute', left: 5 }}>
                                <Checkbox
                                    style={{ height: '0px', width: '0px', transform: 'scale(0.8)' }}
                                    value={a}
                                    checked={props?.employeeName?.includes(a)}
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: '12px',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    left: 28,
                                }}
                            >
                                {a?.Employee?.firstName}
                            </div>
                        </MenuItem>
                    ))}
                </div>
            </Menu>
            <Menu
                anchorEl={anchorEl2}
                open={!!anchorEl2}
                onClose={handleClose2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{ className: classes.popoverPropsTwo }}
                style={{ transform: 'translateY(32px)' }}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>Teams</div>
                <div className={classes.filterscroll}>
                    {props?.state?.teams?.teams?.map((a: any, i: number) => (
                        <MenuItem
                            key={i}
                            style={{ height: '20px', display: 'flex', justifyContent: 'center' }}
                            onClick={e => Change(e, a, i)}
                        >
                            <div style={{ position: 'absolute', left: 0 }}>
                                <Checkbox
                                    style={{ transform: 'scale(0.8)' }}
                                    value={a}
                                    checked={props?.teamName?.includes(a)}
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: '12px',
                                    height: '20px',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    left: 35,
                                }}
                            >
                                {a.teamName}
                            </div>
                        </MenuItem>
                    ))}
                </div>
            </Menu>
            {props?.alldata?.length !== 0 ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 8,
                        }}
                    >
                        <Hidden mdDown>
                            <div className={classes.SecondDivText}>Performance</div>
                        </Hidden>
                        <div>
                            <Button
                                variant="outlined"
                                endIcon={<ArrowDropDownIcon className={classes.IconSty} />}
                                className={classes.filterButton}
                                onClick={handleClick1}
                            >
                                Employees
                            </Button>
                            <Button
                                variant="outlined"
                                endIcon={<ArrowDropDownIcon className={classes.IconSty} />}
                                className={classes.filterButton}
                                onClick={handleClick2}
                            >
                                Teams
                            </Button>
                            <Button
                                variant="outlined"
                                data-testid="filterby"
                                startIcon={<img src={FilterLogo} alt="Filter Logo" className={classes.img} />}
                                endIcon={<ArrowDropDownIcon className={classes.IconSty} />}
                                className={classes.filterButton}
                                onClick={handleClick}
                            >
                                Filter by
                            </Button>
                        </div>
                    </div>
                    <Hidden lgUp>
                        <div className={classes.SecondDivText}>Performance</div>
                    </Hidden>
                    <div className={classes.graphDiv}>
                        <div className={classes.graph}>
                            <div className={classes.graphXAxis}>No. of accomplished task</div>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={data}>
                                    <XAxis
                                        stroke={theme.palette.text.primary}
                                        dataKey="name"
                                        interval={'preserveStartEnd'}
                                    />
                                    <YAxis stroke={theme.palette.text.primary} />
                                    <Tooltip />
                                    {category === 'Teams'
                                        ? props?.teamName?.map((a: any, i: number) => (
                                              <Line
                                                  key={i}
                                                  type="monotone"
                                                  dataKey={a?.teamName}
                                                  stroke={COLORS[i]}
                                                  strokeWidth={2}
                                              />
                                          ))
                                        : props?.employeeName?.map((a: any, i: number) => (
                                              <Line
                                                  key={i}
                                                  dataKey={a.Employee.firstName}
                                                  stroke={COLORS[i]}
                                                  strokeWidth={2}
                                                  type="monotone"
                                              />
                                          ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            {category === 'Teams'
                                ? props?.teamName?.map((a: any, i: number) => (
                                      <div key={i} style={{ marginRight: '12px', display: 'inline-block' }}>
                                          <Point title={a?.teamName} colors={COLORS[i]} />
                                      </div>
                                  ))
                                : props?.employeeName?.map((a: any, i: number) => (
                                      <div key={i} style={{ marginRight: '12px', display: 'inline-block' }}>
                                          <Point title={a?.Employee?.firstName} colors={COLORS[i]} />
                                      </div>
                                  ))}
                        </div>
                    </div>
                </>
            ) : (
                <div className={classes.notfoundgraph}>
                    <img src={upcomingTimeoff} alt="Time-off Data Not Found" className={classes.notfoundImage2} />
                    <div className={classes.notfoundText}>No standup found</div>
                </div>
            )}
        </div>
    );
};

export const AccordianView = (props: { data?: any; state?: any; today?: any; allData: any }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<number | false>(0);
    const [values, setvalue] = React.useState('');
    const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setvalue('');
        setExpanded(newExpanded ? panel : panel + 1);
        if (panel === props.data[0]?.questions?.length - 1) {
            setExpanded(newExpanded ? props.data[0]?.questions?.length - 1 : 0);
        }
        return;
    };

    return (
        <div
            style={
                props.data[0]?.questions?.length <= 2
                    ? {
                          width: '100%',
                          display: 'grid',
                          gridRowGap: '10px',
                          gridTemplateRows: '1fr auto',
                      }
                    : { width: '100%', display: 'grid', gridTemplateRows: '1fr auto' }
            }
            className={classes.accordianMain}
        >
            {props.data[0]?.questions !== undefined ? (
                props.data[0]?.questions?.map((a: any, i: number) => (
                    <div data-testid="accordian" style={{ width: '100%' }} key={i}>
                        <Accordion
                            expanded={expanded === i}
                            onChange={handleChange(i)}
                            style={
                                props.data[0]?.questions?.length <= 2
                                    ? { borderRadius: 5, marginTop: '10px', border: 'none', height: '100%' }
                                    : { borderRadius: 5, marginTop: '10px', border: 'none', maxHeight: '100%' }
                            }
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.ExpandIcon} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionHeaderStyle}
                            >
                                <Typography className={classes.accordionTitle}>{a}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordianDetails}>
                                <div style={{ width: '100%' }}>
                                    <div className={classes.searchbarRounded}>
                                        <span
                                            style={{ marginRight: 10, fontSize: 15, color: '#888888' }}
                                            className="iconify"
                                            data-icon="octicon:search-16"
                                        ></span>
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            value={values}
                                            onChange={(e: any) => setvalue(e.target.value)}
                                            className={classes.searchBarInput}
                                        />
                                    </div>
                                </div>
                                <div className={classes.scrollDivAccordian}>
                                    {props.data[0]?.employee
                                        ?.filter(
                                            (z: any) =>
                                                z?.firstName?.toLowerCase().includes(values.toLowerCase()) ||
                                                z?.lastName?.toLowerCase().includes(values.toLowerCase()),
                                        )
                                        .map((b: any, j: number) => (
                                            <div key={j}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: 15,
                                                    }}
                                                >
                                                    <Avatar alt="" src={b?.profileImage} />
                                                    <div style={{ width: '100%' }}>
                                                        <div style={{ paddingLeft: 15, fontWeight: 600 }}>
                                                            {b?.firstName} {b?.lastName}
                                                        </div>
                                                        <div
                                                            style={{
                                                                paddingLeft: 15,
                                                                wordBreak: 'break-all',
                                                            }}
                                                        >
                                                            {props.data[0]?.answers[j][i]}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))
            ) : (
                <div className={classes.notfoundAccordion}>
                    <img src={upcomingTimeoff} alt="Time-off Data Not Found" className={classes.notfoundImage2} />
                    <div className={classes.notfoundText}>No standup found</div>
                </div>
            )}
        </div>
    );
};

export default function StandupDashboard(props: {
    viewPage: any;
    setviewPage: any;
    tabValue: any;
    settabValue: any;
    setDate: any;
    date: any;
}) {
    const mycontext = useContext(AuthContext);

    const classes = useStyles();
    const history = useHistory();
    const [isError, setError] = React.useState<boolean>(false);
    // const [allData, setallData] = React.useState<any>([]);
    const state = useSelector((state: any) => state);
    const organization = state?.teams?.teams?.find((element: any) => (element?.teamName).toLowerCase() === 'general')
        ?.organizationId;
    const teamIdentity = state?.teams?.teams[0]?.teamId;
    const index = state?.teams?.teams[0];
    const standupData = state?.standups;
    const [org, setorg] = React.useState(organization);
    const [teamid, setteamid] = React.useState(teamIdentity);
    const [team, setteam] = React.useState<any>([]);
    const [employee, setemployee] = React.useState<any>([]);
    const [employeeName, setemployeeName] = React.useState<any>([]);
    const [teamName, setteamName] = React.useState<any>([index]);
    const [Time, setTime] = React.useState<any>({
        endDate: localToISO(
            getCurrentCountryDateTime(mycontext?.country.zoneName).date,
            '23:59:00',
            mycontext?.country,
        ),
        startDate: initialDate('week', 0, mycontext.country.zoneName),
    });
    const workWeek = state?.settings?.settings?.workWeek;
    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const [data, setData] = React.useState<any>([]);
    const isWorkday = workWeek?.includes(
        weekDays[new Date(getCurrentCountryDateTime(mycontext?.country.zoneName).dateTime).getDay()],
    );

    const Page = (Label: string) => {
        props.settabValue(Label);
        props.setviewPage(1);
    };
    // console.log(Time);
    const dispatch = useDispatch();
    const standupByTeams = useSubscription(getStandupsByTeams(org, teamid));

    React.useEffect(() => {
        const todayData: any = standupData?.standups?.filter(
            (a: any, i: number) =>
                convertedDateTime(a?.standupNoteDateTime, mycontext.country).date ===
                convertedDateTime(Time.endDate, mycontext.country).date,
        );
        const question: any = todayData?.map((a: any) => a?.answers?.map((b: any) => b?.question));
        const employee: any = todayData?.map((a: any) => a?.Employee);
        const answers: any = todayData?.map((a: any) => a?.answers?.map((b: any) => b?.answer));
        setData([
            {
                questions: question[todayData?.length - 1],
                employee: employee,
                answers: answers,
            },
        ]);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [standupData?.standups, mycontext.country, Time]);

    React.useMemo(() => {
        if (!standupByTeams?.loading) {
            const allData: any =
                standupByTeams?.data?.standupdb_StandupTemplate[0]?.Organization?.Teams[0]?.team_employees;
            if (allData === undefined) {
                setError(true);
            } else {
                try {
                    if (!team?.some((item: any) => item?.id === teamid) && allData !== undefined) {
                        const a = allData?.map((a: any) => {
                            return {
                                teamid: a?.teamId,
                                standup: a?.Employee?.StandupNotes?.filter((e: any) => {
                                    return e?.isAccomplished === true;
                                }),
                            };
                        });
                        const b = a?.map((i: any) => {
                            return i?.standup?.map((e: any) => {
                                return { id: i?.teamid, ...e };
                            });
                        });
                        const c = b?.reduce((acc: any, el: any) => acc?.concat(el), []);
                        ////console.log(a, b, c)
                        setteam(team?.concat(c));
                    } else {
                        if (allData !== undefined) {
                            const a = allData?.map((a: any) => {
                                return {
                                    teamid: a?.teamId,
                                    standup: a?.Employee?.StandupNotes?.filter((e: any) => {
                                        return e?.isAccomplished === true;
                                    }),
                                };
                            });
                            const b = a?.map((i: any) => {
                                return i?.standup?.map((e: any) => {
                                    return { id: i?.teamid, ...e };
                                });
                            });
                            const c = b?.reduce((acc: any, el: any) => acc?.concat(el), []);
                            setteam((current: any) =>
                                [...current]
                                    ?.filter(c => {
                                        return c?.id !== teamid;
                                    })
                                    ?.concat(...c),
                            );
                        }
                    }
                } catch (err) {
                    ////console.log(err)
                    setError(true);
                }
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [standupByTeams]);

    React.useEffect(() => {
        dispatch(updateActiveTab('/dashboard'));
    }, [dispatch]);
    // console.log(isErro / r);
    if (
        state?.standups?.status === 'loading' ||
        state.orgEmployees.status === 'loading' ||
        state?.orgLeaves?.status === 'loading'
    ) {
        return <LoaderPage />;
    }
    if (
        state?.standups?.status === 'failed' ||
        state.orgEmployees.status === 'failed' ||
        state?.orgLeaves?.status === 'failed' ||
        isError
    ) {
        return <ErrorPage title="Server Down" />;
    }

    if (props.viewPage === 1) {
        return (
            <MainStandup
                setDate={props.setDate}
                viewPage={props.viewPage}
                setviewPage={props.setviewPage}
                tabValue={props.tabValue}
                settabValue={props.settabValue}
                date={props.date}
            />
        );
    }

    return (
        <>
            <div className={classes.ParentDiv}>
                <div className={classes.leftDiv}>
                    <DonutView state={state} today={Time.endDate} allData={isWorkday ? standupData?.standups : []} />
                    <div className={classes.graphLeft}>
                        <GraphView
                            state={state}
                            alldata={standupData?.standups}
                            today={Time.endDate}
                            standupByTeams={standupByTeams}
                            setorg={setorg}
                            setteamid={setteamid}
                            teamid={teamid}
                            orgId={org}
                            team={team}
                            setteam={setteam}
                            employee={employee}
                            setemployee={setemployee}
                            employeeName={employeeName}
                            setemployeeName={setemployeeName}
                            teamName={teamName}
                            setteamName={setteamName}
                            Time={Time}
                            setTime={setTime}
                            mycontext={mycontext}
                            organization={organization}
                            teamIdentity={teamIdentity}
                        />
                    </div>
                </div>
                <div>
                    <div className={classes.ChipDiv}>
                        <Chip
                            label="Standup notes"
                            onClick={() => Page('Standupnotes')}
                            className={classes.Chip}
                            style={{ backgroundColor: '#4FB8B2BF' }}
                        />
                        <Chip
                            label="Goal not achieved"
                            onClick={() => Page('Goal not achieved')}
                            className={classes.Chip}
                            style={{ backgroundColor: '#CC7EF0' }}
                        />
                    </div>
                    <AccordianView
                        state={state}
                        today={Time.endDate}
                        allData={isWorkday ? standupData?.standups : []}
                        data={data}
                    />
                </div>
            </div>
            <div className={classes.filterButtonsDivMobileMain}>
                <Link
                    to={{
                        pathname: '/settings',
                        state: {
                            value: 3,
                        },
                    }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.MobileDiv}
                        startIcon={<SettingsIcon style={{ height: '15px', width: '15px' }} />}
                        onClick={() => history.push('/settings')}
                    >
                        settings
                    </Button>
                </Link>
            </div>
        </>
    );
}
