import React from 'react';

import './spinner.css';

const spinner = () => (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="lds-dual-ring"></div>
    </div>
);

export default spinner;
