import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ModalStyles } from './ModalStyle';
import { Grid, Typography } from '@material-ui/core';
import EditTimeff from './EditTimeOff';
import DeleteTimeff from './DeleteTimeOff';
import Switch from 'react-switch';
import AuthContext from '../../context/AuthContext';
// import Skeleton from 'react-loading-skeleton';
import Skeleton from '@material-ui/lab/Skeleton';
import { timeoffLeaveStatus } from '../../helpers/hasuraapi';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import { activeInactiveTimeOff } from '../Redux/timeOffPolicyReducer';
import { useDispatch } from 'react-redux';

interface Props {
    rows: any[];
    isLoading?: boolean;
    isUpdating?: any;
    error?: boolean;
    errorMessage?: string;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
    setIsUpdating?: any;
    userSubscriptionDetails?: any;
    checkSubscription?: any;
}
function PolicyTable(props: Props) {
    const classes = ModalStyles();
    const mycontext = useContext(AuthContext);

    const dispatch = useDispatch();

    // const [timeoffData, setTimeoffData] = useState<any>([]);
    // const refreshPage = () => {
    //     window.location.reload(false);
    // };
    const handleleavestatus = async (leaveId: string, active: boolean, approvalRequired: boolean) => {
        try {
            props.checkSubscription(props.userSubscriptionDetails);
            const row = {
                leaveTypeId: leaveId,
                organizationId: mycontext.organizationId,
                active: active,
                approvalRequired: approvalRequired,
                token: mycontext.token,
            };
            const data = await timeoffLeaveStatus(row);
            // refreshPage();

            if (data.error) {
                throw new Error('Something went wrong, try again!');
            } else {
                dispatch(
                    activeInactiveTimeOff({
                        leaveTypeId: leaveId,
                        active: data.returning[0].active,
                        approvalRequired: data.returning[0].approvalRequired,
                    }),
                );
            }
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };
    // useEffect(() => {
    //     setTimeoffData(props.rows);
    // }, [props.rows, mycontext.organizationId]);
    // if (props.isUpdating) {
    //     return <h1>Loading</h1>;
    // }
    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                {props.rows.length === 0 ? (
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader size="small" aria-label="small table" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.columnnam}>#</TableCell>
                                    <TableCell className={classes.columnnam}>Time-Off Category</TableCell>
                                    <TableCell className={classes.columnnam}>Paid / Unpaid</TableCell>
                                    <TableCell className={classes.columnnam}>Days Per Year</TableCell>
                                    <TableCell className={classes.columnnam}>Emoji</TableCell>
                                    <TableCell className={classes.columnnam}>Enable / Disable</TableCell>
                                    <TableCell className={classes.columnnam}>Auto Approve</TableCell>
                                    <TableCell className={classes.columnnam} style={{ paddingLeft: '25px' }}>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.isLoading && (
                                    <>
                                        {[1, 2, 3, 4, 5, 6].map((d: number) => (
                                            <TableRow key={d} className={classes.row}>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton height={20} />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton height={20} />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton height={20} />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton height={20} />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton height={20} />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton height={20} />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton height={20} />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton height={20} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                                {props.isLoading || (
                                    <TableRow>
                                        <TableCell colSpan={8} className={classes.fontsty}>
                                            <img
                                                src={upcomingTimeoff}
                                                alt="Time-off Data Not Found"
                                                className={classes.notfoundImage}
                                            />
                                            <Typography>Time-off Data Not Found</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader size="small" aria-label="small table" className={classes.table}>
                            <TableHead>
                                <TableRow key="heading">
                                    <TableCell className={classes.column} style={{ minWidth: '60px' }}>
                                        #
                                    </TableCell>
                                    <TableCell className={classes.column} style={{ textAlign: 'left' }}>
                                        Time-Off Category
                                    </TableCell>
                                    <TableCell className={classes.column}>Paid / Unpaid</TableCell>
                                    <TableCell className={classes.column}>Days Per Year</TableCell>
                                    <TableCell className={classes.column}>Emoji</TableCell>
                                    <TableCell className={classes.columnname}>Enable / Disable</TableCell>
                                    <TableCell className={classes.columnname}>Auto Approve</TableCell>
                                    <TableCell className={classes.columnname}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.rows.map((timeoff: any, index: any) => (
                                    /* istanbul ignore next */
                                    <TableRow key={timeoff.leaveTypeId} className={classes.row}>
                                        <TableCell className={classes.cellName} style={{ padding: '6px' }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell
                                            className={classes.cellName}
                                            style={{ textAlign: 'left', padding: '6px' }}
                                        >
                                            {timeoff.leaveTypeName}
                                        </TableCell>
                                        <TableCell className={classes.cellName}>
                                            {timeoff.isPaid ? 'Paid' : 'Unpaid'}
                                        </TableCell>
                                        <TableCell className={classes.cellName}>
                                            {timeoff.unlimitedDays
                                                ? 'Unlimited'
                                                : timeoff.daysPerYear === 0
                                                ? 'Unlimited'
                                                : timeoff.daysPerYear}
                                        </TableCell>
                                        <TableCell className={classes.cellName} style={{ fontSize: 20 }}>
                                            {timeoff.leaveTypeEmoticon === null
                                                ? '🌴'
                                                : timeoff.leaveTypeEmoticon.emoji}
                                        </TableCell>
                                        <TableCell style={{ borderBottom: 'none', textAlign: 'center' }}>
                                            <Switch
                                                onChange={() =>
                                                    handleleavestatus(
                                                        timeoff.leaveTypeId,
                                                        timeoff.defaultEnable ? timeoff.active : !timeoff.active,
                                                        timeoff.approvalRequired,
                                                    )
                                                }
                                                disabled={timeoff.defaultEnable ? true : false}
                                                checked={timeoff.active}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={18}
                                                width={34}
                                                onColor="#3C48B5"
                                                offColor="#CCCCCC"
                                                activeBoxShadow="0 0 0px 0px #CCCCCC"
                                                id={'enableDisableTimeOff' + index}
                                            />
                                        </TableCell>
                                        <TableCell style={{ borderBottom: 'none', textAlign: 'center' }}>
                                            <Switch
                                                onChange={() =>
                                                    handleleavestatus(
                                                        timeoff.leaveTypeId,
                                                        timeoff.active,
                                                        !timeoff.approvalRequired,
                                                    )
                                                }
                                                checked={!timeoff.approvalRequired}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={18}
                                                width={34}
                                                onColor="#3C48B5"
                                                offColor="#CCCCCC"
                                                activeBoxShadow="0 0 0px 0px #CCCCCC"
                                                id={'enableDisableTimeOff' + index}
                                            />
                                        </TableCell>
                                        <TableCell align="center" style={{ borderBottom: 'none', padding: '6px' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly',
                                                    width: '100%',
                                                }}
                                            >
                                                <Grid item xs={12} sm={2} style={{ display: 'flex' }}>
                                                    <EditTimeff
                                                        leaveTypeId={timeoff.leaveTypeId}
                                                        leaveTypeName={timeoff.leaveTypeName}
                                                        leaveTypeEmoticon={timeoff.leaveTypeEmoticon}
                                                        daysPerYear={timeoff.daysPerYear}
                                                        defaultEnable={timeoff.defaultEnable}
                                                        isPaid={timeoff.isPaid}
                                                        setAlertMessage={props.setAlertMessage}
                                                        setAlertm={props.setAlertm}
                                                        setSeverity={props.setSeverity}
                                                        id={index + 1}
                                                        setIsUpdating={props.setIsUpdating}
                                                        userSubscriptionDetails={props.userSubscriptionDetails}
                                                        checkSubscription={props.checkSubscription}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3} style={{ display: 'flex' }}>
                                                    <DeleteTimeff
                                                        leaveTypeId={timeoff.leaveTypeId}
                                                        leaveTypeName={timeoff.leaveTypeName}
                                                        defaultEnable={timeoff.defaultEnable}
                                                        setAlertMessage={props.setAlertMessage}
                                                        setAlertm={props.setAlertm}
                                                        setSeverity={props.setSeverity}
                                                        id={index + 1}
                                                        setIsUpdating={props.setIsUpdating}
                                                        userSubscriptionDetails={props.userSubscriptionDetails}
                                                        checkSubscription={props.checkSubscription}
                                                    />
                                                </Grid>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
    );
}

export default PolicyTable;
