import {
    createSlice,
    // createAsyncThunk
} from '@reduxjs/toolkit';
// import { getPendingLeavesForApproverDashboard } from 'helpers/hasuraapi';

// export const getApproverLeaves: any = createAsyncThunk(
//     'approverDashboardLeaves/getApproverLeaves',
//     async (user: any) => {
//         const data: any = await getPendingLeavesForApproverDashboard(user.employeeId, user.organizationId, user.token);
//         const jsonData: any = await data.json();
//         return jsonData.data.timeoffdb_get_pendingdeniedapproverleaves;
//     },
// );

export const approverLeavesSlice = createSlice({
    name: 'approverDashboardLeaves',
    initialState: { leaves: [], status: 'loading' },
    reducers: {
        addApproverLeave: (state: any, action: any) => {
            state.leaves.push(action.payload);
        },
        editApproverLeave: (state, action) => {
            state.leaves.map((u: any) => {
                if (u.leaveId === action.payload.leaveId) {
                    u.approverId = action.payload.approverId;
                    u.denialReason = action.payload.denialReason;
                    u.denierId = action.payload.denierId;
                    u.leaveStatus = action.payload.leaveStatus;
                    u.startDate = action.payload.startDate;
                    u.endDate = action.payload.endDate;
                    u.startTime = action.payload.startTime;
                    u.endTime = action.payload.endTime;
                    u.noOfHours = action.payload.noOfHours;
                    u.reason = action.payload.reason;
                    return u;
                }
                return u;
            });
        },
        deleteApproverLeave: (state, action) => {
            state.leaves = state.leaves.filter((u: any) => u.leaveId !== action.payload.leaveId);
        },
        removeApproverLeave: state => {
            state.leaves = [];
            state.status = 'loading';
        },
        fetchApproverLeaves: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                state.leaves = action.payload?.data?.timeoffdb_get_pendingdeniedapproverleaves;
            }
        },
    },
    // extraReducers: {
    //     [getApproverLeaves.pending]: state => {
    //         state.status = 'loading';
    //     },
    //     [getApproverLeaves.fulfilled]: (state, action) => {
    //         state.leaves = action.payload;
    //         state.status = 'success';
    //     },
    //     [getApproverLeaves.rejected]: state => {
    //         state.status = 'failed';
    //     },
    // },
});

export const {
    addApproverLeave,
    editApproverLeave,
    deleteApproverLeave,
    removeApproverLeave,
    fetchApproverLeaves,
} = approverLeavesSlice.actions;
export default approverLeavesSlice.reducer;
