import React, { useState } from 'react';
import { useStyles } from './employeeProfileStyles';
import { timeOffStatusStyles } from './employeeProfileStyles';
import { Grid, Typography, Select, MenuItem } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedValue } from '../Redux/selfProfileReducer';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorPage from '../Error/ErrorPage';
// import Loader from 'components/Loader/loader';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface TimeOffStatusProps {
    orgWorkingHours?: any;
    totalLeaveHours?: any;
    leaveType?: any;
    selectedValue?: any;
    setSelectedValue?: any;
    usedDates?: any;
    getTotalDays?: any;
    userId?: any;
}

// interface Weeks {
//     label: string;
//     value: string;
// }
// const weeks: Weeks[] = [
//     {
//         label: 'Sun',
//         value: 'sunday',
//     },
//     {
//         label: 'Mon',
//         value: 'monday',
//     },
//     {
//         label: 'Tue',
//         value: 'tuesday',
//     },
//     {
//         label: 'Wed',
//         value: 'wednesday',
//     },
//     {
//         label: 'Thu',
//         value: 'thursday',
//     },
//     {
//         label: 'Fri',
//         value: 'friday',
//     },
//     {
//         label: 'Sat',
//         value: 'saturday',
//     },
// ];

export default function EmployeeTimeOffStatus(props: TimeOffStatusProps) {
    const classes = useStyles();
    const classes2 = timeOffStatusStyles();

    const dispatch = useDispatch();

    // const settings = useSelector((state: any) => state.settings);
    const state = useSelector((state: any) => state.selfProfile);
    const [selectedIndex, setSelectedIndex] = useState<number>(
        state.selfOrgLeaves.map((item: any) => item.leaveTypeName).indexOf(state.selectedValue),
    );
    const handleSelect = (event: any) => {
        const arrayIndex = state.selfOrgLeaves.map((item: any) => item.leaveTypeName).indexOf(event.target.value);
        setSelectedIndex(arrayIndex);
        dispatch(setSelectedValue({ value: event.target.value }));
        // props.setSelectedValue(event.target.value);
    };

    if (state.selfOrgLeaveStatus === 'loading') {
        return (
            <div style={{ display: 'flex', flex: '0 0 100%', alignItems: 'center' }}>
                <div style={{ width: '100%', padding: '10px' }}>
                    <Skeleton width="100%" height={20} />
                    <Skeleton width="60%" height={20} />
                </div>
                <div style={{ width: '100%', padding: '10px' }}>
                    <Skeleton width="100%" height={20} />
                    <Skeleton width="60%" height={20} />
                </div>
                <div style={{ width: '100%', padding: '10px' }}>
                    <Skeleton width="100%" height={20} />
                    <Skeleton width="60%" height={20} />
                </div>
            </div>
        );
    } else if (state.selfOrgLeaveStatus === 'failed') {
        return <ErrorPage msg="Something went wrong!" />;
    } else {
        return (
            <div style={{ display: 'grid', gridTemplateRows: '1fr 0.4fr', gridRowGap: 10, paddingTop: 20 }}>
                <div className={classes.timeOffStatusMain}>
                    <div className={classes.timeOffStatusText}>
                        <div style={{ display: 'flex', paddingRight: '10px' }}>
                            <Typography className={classes2.titleOneStyle}>Time-off status for</Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Select
                                value={state.selectedValue}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 160 },
                                    },
                                }}
                                className={classes2.selectStyle}
                                onChange={handleSelect}
                            >
                                {state.selfOrgLeaves.map((obj: any, index: number) => (
                                    <MenuItem value={obj.leaveTypeName} key={index}>
                                        {obj.leaveTypeName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: '5px' }}>
                            <Typography className={classes2.titleOneStyle}>(in days)</Typography>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridGap: '30px',
                            gridAutoFlow: 'column',
                            width: '100%',
                            justifyItems: 'center',
                            marginTop: 10,
                            alignItems: 'center',
                            paddingTop: '10px',
                        }}
                    >
                        <Grid
                            style={{ flexDirection: 'column', height: 'fit-content' }}
                            className={classes.commonStyle1}
                            item
                            xs={4}
                        >
                            <Grid item xs={12} className={classes.commonStyle1}>
                                <Typography className={`${classes2.numbersStyle} ${classes2.numberColorOne}`}>
                                    {state.selfOrgLeaves[selectedIndex]?.daysPerYear === 0 ? (
                                        'Unlimited'
                                    ) : props.totalLeaveHours[selectedIndex] === 0 || undefined || NaN ? (
                                        0
                                    ) : isNaN(
                                          state.selfOrgLeaves[selectedIndex].daysPerYear -
                                              Math.floor(
                                                  props.totalLeaveHours
                                                      .filter((e: any) => state.selectedValue === e.leaveType)
                                                      .map((item: any) => item.totalHoursLeave) / props.orgWorkingHours,
                                              ),
                                      ) ? (
                                        <>
                                            --
                                            {/* <Loader isUpdating={true} /> */}
                                        </>
                                    ) : (
                                        state.selfOrgLeaves[selectedIndex].daysPerYear -
                                        Math.floor(
                                            props.totalLeaveHours
                                                .filter((e: any) => state.selectedValue === e.leaveType)
                                                .map((item: any) => item.totalHoursLeave) / props.orgWorkingHours,
                                        )
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.commonStyle1}>
                                <Typography className={classes2.titleStyle}>Available</Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            style={{ flexDirection: 'column', height: 'fit-content' }}
                            className={classes.commonStyle1}
                            item
                            xs={4}
                        >
                            <Grid item xs={12} className={classes.commonStyle1}>
                                <Typography className={`${classes2.numbersStyle} ${classes2.numberColorTwo}`}>
                                    {props.totalLeaveHours.filter((e: any) => e.leaveType === state.selectedValue)
                                        .length === 0 ? (
                                        0
                                    ) : isNaN(
                                          Math.floor(
                                              props.totalLeaveHours
                                                  .filter((e: any) => state.selectedValue === e.leaveType)
                                                  .map((item: any) => item.totalHoursLeave) / props.orgWorkingHours,
                                          ),
                                      ) ? (
                                        <>
                                            --
                                            {/* <Loader isUpdating={true} /> */}
                                        </>
                                    ) : (
                                        Math.floor(
                                            props.totalLeaveHours
                                                .filter((e: any) => state.selectedValue === e.leaveType)
                                                .map((item: any) => item.totalHoursLeave) / props.orgWorkingHours,
                                        )
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.commonStyle1}>
                                <Typography className={classes2.titleStyle}>Used</Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            style={{ flexDirection: 'column', height: 'fit-content' }}
                            className={classes.commonStyle1}
                            item
                            xs={4}
                        >
                            <Grid item xs={12} className={classes.commonStyle1}>
                                <Typography className={`${classes2.numbersStyle} ${classes2.numberColorThree}`}>
                                    {state.selfOrgLeaves
                                        .filter((e: any) => state.selectedValue === e.leaveTypeName)
                                        .map((item: any) => {
                                            return item.daysPerYear === 0 ? 'Unlimited' : item.daysPerYear;
                                        })}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.commonStyle1}>
                                <Typography className={classes2.titleStyle}>Total</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {/* <div className={classes.weekDiv}>
                    <Typography className={classes2.titleOneStyle}>Work Week</Typography>
                    <div className={classes.workweeks}>
                        {settings.status === 'loading'
                            ? null
                            : weeks.map((item: Weeks, i: number) => (
                                  <div
                                      key={item.label}
                                      className={
                                          settings.settings?.workWeek.includes(item.value)
                                              ? i === 0
                                                  ? classes.activeDayFirst
                                                  : i === 6
                                                  ? classes.activeDayLast
                                                  : classes.activeDay
                                              : i === 0
                                              ? classes.dayFirst
                                              : i === 6
                                              ? classes.dayLast
                                              : classes.day
                                      }
                                  >
                                      {item.label}
                                  </div>
                              ))}
                    </div>
                </div> */}
            </div>
        );
    }
}
