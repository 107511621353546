import React, { useState } from 'react';
import {
    Button,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Typography,
    TableCell,
    useTheme,
    Hidden,
    Container,
    Paper,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { useStyles } from './style';
import Papa from 'papaparse';
import { PopUp } from '../Modal/Modal';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import CancelIcon from '@mui/icons-material/Cancel';

interface UsersDataProps {
    sendUsersData(e: any, userData: any): void;
    setOpen?: any;
    teams?: any;
}

export default function BulkData(props: UsersDataProps) {
    const classes = useStyles();
    const theme = useTheme();
    const [isFile, setIsFile] = useState<boolean>(false);
    const [csvArray, setCsvArray] = React?.useState<any[]>([]);
    const [fileName, setFileName] = React?.useState<string>('');
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertm, setAlertm] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');

    const handleFile = (e: any) => {
        if (e.target.files[0]) {
            try {
                const file = e?.target?.files[0];
                setFileName(file?.name);
                console?.log('name', file);
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    Papa?.parse(file, {
                        header: true,
                        skipEmptyLines: 'greedy',
                        complete: results => {
                            if (results?.errors?.length > 0) {
                                setSeverity('error');
                                setAlertm(true);
                                setAlertMessage('Error while processing csv');
                            } else {
                                if (results?.data?.length > 0) {
                                    const myData = results?.data?.map((row: any, key: number) => {
                                        let employeeId;
                                        let error;
                                        const data = props?.teams?.every((e: any) => e?.Employee?.email !== row?.email);
                                        if (data === true) {
                                            // setSeverity('error');
                                            // setAlertm(true);
                                            error = key;
                                            // setAlertMessage('user email are wrong');
                                        } else {
                                            const data = props?.teams?.every(
                                                (e: any) =>
                                                    e?.Employee?.firstName?.toLowerCase() !==
                                                    row?.firstName?.toLowerCase(),
                                            );
                                            if (data === true) {
                                                // setSeverity('error');
                                                // setAlertm(true);
                                                error = key;
                                                // setAlertMessage('user firstName is wrong');
                                            } else {
                                                const data = props?.teams?.every(
                                                    (e: any) =>
                                                        e?.Employee?.lastName?.toLowerCase() !==
                                                        row?.lastName?.toLowerCase(),
                                                );
                                                if (data === true) {
                                                    // setSeverity('error');
                                                    // setAlertm(true);
                                                    error = key;
                                                    // setAlertMessage('user lastName is wrong');
                                                } else {
                                                    const em = props?.teams?.filter(
                                                        (e: any) => e?.Employee?.email === row?.email,
                                                    );
                                                    employeeId = em[0]?.employeeId;
                                                }
                                            }
                                        }
                                        return {
                                            firstname: row?.firstName,
                                            lastname: row?.lastName,
                                            email: row?.email,
                                            Birthdate: row?.birthdate,
                                            workAnniversary: row?.workAnniversary,
                                            employeeId: employeeId,
                                            error: error,
                                        };
                                    });
                                    //console.log(myData?.filter((e: any) => e?.error !== undefined));
                                    if (myData?.filter((e: any) => e?.error !== undefined).length !== 0) {
                                        setSeverity('error');
                                        setAlertm(true);
                                        setAlertMessage('user data was not correct');
                                        // throw new Error("user data was not correct")
                                    } else {
                                        setCsvArray(myData);
                                        setIsFile(true);
                                    }
                                }
                            }
                        },
                    });
                };
                reader.readAsText(file);
            } catch (error) {
                setSeverity('error');
                setAlertm(true);
                setIsFile(false);
                setAlertMessage(error);
            }
        }
    };

    //console.log(csvArray);
    // const removeInput = (e: any, data: any) => {
    //     e?.preventDefault();
    //     const newArray = csvArray?.filter((arr: any) => arr?.firstname !== data?.firstname);
    //     setCsvArray(newArray);
    // };

    const headers = [
        { label: 'firstName', key: 'firstName' },
        { label: 'lastName', key: 'lastName' },
        { label: 'email', key: 'email' },
        { label: 'birthdate', key: 'birthdate' },
        { label: 'workAnniversary', key: 'workAnniversary' },
    ];
    const data: any = [];

    props.teams?.map((b: any) => {
        const obj = {
            firstName: b?.Employee?.firstName,
            lastName: b?.Employee?.lastName,
            email: b?.Employee?.email,
            birthdate: b?.Employee?.dateOfBirth,
            workAnniversary: b?.workAnniversary,
        };
        data.push(obj);
    });
    const csvReport = {
        data: data,
        header: headers,
        filename: 'sampledata?.csv',
    };

    const handleBack = () => {
        if (isFile) {
            setIsFile(false);
        } else {
            props.setOpen(0);
        }
    };
    if (props.teams?.length === 0) {
        return (
            <>
                <div id="noResultFound" className={classes.fontsty} style={{ borderBottom: 'none' }}>
                    <img src={upcomingTimeoff} alt="No data found" className={classes.notfoundImage} />
                    <div className={classes.notfoundText}>All data filled</div>
                </div>
            </>
        );
    }

    return (
        <div>
            <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
            <Hidden lgUp>
                <Button
                    id="bulkBack"
                    variant="contained"
                    color="primary"
                    disableRipple={true}
                    onClick={handleBack}
                    className={classes.backBtn}
                >
                    Back
                </Button>
                <Button
                    id="bulkSend"
                    data-testid="done"
                    variant="contained"
                    color="primary"
                    disableRipple={true}
                    onClick={(e: any) => props?.sendUsersData(e, csvArray)}
                    className={classes.sendBtn}
                >
                    Done
                </Button>
            </Hidden>
            {isFile && csvArray?.length > 0 ? (
                <Grid container justifyContent="center" spacing={2}>
                    <Hidden mdDown>
                        <Grid item sm={12}>
                            <Grid container alignItems="center">
                                <Grid container item justifyContent="flex-start" sm={6}>
                                    <Typography
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            color: theme?.palette?.text?.primary,
                                        }}
                                    >
                                        {fileName}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent="flex-end" item sm={6}>
                                    <input
                                        onChange={handleFile}
                                        accept=".csv"
                                        style={{ display: 'none' }}
                                        id="new-csv-file"
                                        type="file"
                                        data-testid="input"
                                    />
                                    <label htmlFor="new-csv-file">
                                        <Button
                                            variant="outlined"
                                            id="newDoc"
                                            disableRipple
                                            component="span"
                                            color="primary"
                                            className={classes.downloadBtn}
                                        >
                                            Upload New Document
                                        </Button>
                                    </label>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>
                            <TableContainer component="div" className={classes.innerContainer}>
                                <Table
                                    stickyHeader
                                    className={classes.emailList}
                                    aria-label="simple table"
                                    size="small"
                                    id="table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.cell} align="center">
                                                #
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                firstName
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                lastName
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                email
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                Birthdate
                                            </TableCell>
                                            <TableCell className={classes.cell} align="center">
                                                Joining date
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {csvArray?.map((row: any, i: number) => (
                                            <TableRow key={i}>
                                                <TableCell align="center" className={classes.cell1}>
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell align="left" className={classes.cell1}>
                                                    {row?.firstname}
                                                </TableCell>
                                                <TableCell align="left" className={classes.cell1}>
                                                    {row?.lastname}
                                                </TableCell>
                                                <TableCell align="left" className={classes.cell1}>
                                                    {row?.email.length > 10
                                                        ? `${row?.email.substring(0, 10)}...`
                                                        : row?.email}
                                                </TableCell>
                                                <TableCell align="left" className={classes.cell1}>
                                                    {row?.Birthdate === '' ? (
                                                        <span
                                                            className="iconify"
                                                            data-icon="bi:dash"
                                                            style={{ width: 25, height: 25 }}
                                                        ></span>
                                                    ) : (
                                                        `${row?.Birthdate}`
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.cell1}
                                                    align="left"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    {row?.workAnniversary === '' ? (
                                                        <span
                                                            className="iconify"
                                                            data-icon="bi:dash"
                                                            style={{ width: 25, height: 25 }}
                                                        ></span>
                                                    ) : (
                                                        `${row?.workAnniversary}`
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Hidden>
                    <Hidden lgUp>
                        <Container style={{ padding: 10, marginBottom: 40 }}>
                            {csvArray?.map((row: any, i: number) => (
                                <Paper style={{ marginBottom: 10, borderRadius: '8px', padding: 10 }} key={i}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item style={{ display: 'flex', justifyContent: 'space-between' }} xs={12}>
                                            <Typography variant="subtitle2" className={classes.date}>
                                                {row?.firstname} {row?.lastname}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2" className={classes.titles}>
                                                Email
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.item2}>
                                            {row?.email.length > 10 ? `${row?.email.substring(0, 10)}...` : row?.email}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2" className={classes.titles}>
                                                Birthdate
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.item2}>
                                            {row?.Birthdate === '' ? (
                                                <span
                                                    className="iconify"
                                                    data-icon="bi:dash"
                                                    style={{ width: 25, height: 25 }}
                                                ></span>
                                            ) : (
                                                `${row?.Birthdate}`
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2" className={classes.titles}>
                                                Joining date
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.item2}>
                                            {row?.workAnniversary === '' ? (
                                                <span
                                                    className="iconify"
                                                    data-icon="bi:dash"
                                                    style={{ width: 25, height: 25 }}
                                                ></span>
                                            ) : (
                                                `${row?.workAnniversary}`
                                            )}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                        </Container>
                    </Hidden>
                </Grid>
            ) : (
                <Grid container justifyContent="center" alignItems="center" className={classes.bulkContainer}>
                    <Grid item sm={12} xs={12} className={classes.dashedContainer}>
                        <Grid container spacing={2}>
                            <Hidden xsDown>
                                <Grid item xs={12}>
                                    <Typography className={classes.dragTitle}>
                                        Drag and drop a CSV file or a document to send messages in a bulk
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Grid container item justifyContent="center" xs={12}>
                                <input
                                    onChange={handleFile}
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    id="csv-file"
                                    type="file"
                                    data-testid="handler"
                                />
                                <CSVLink {...csvReport} className={classes.csvLink}>
                                    <Button
                                        variant="outlined"
                                        id="templateBtn"
                                        disableRipple
                                        color="primary"
                                        component="span"
                                        data-testid="download"
                                        className={classes.downloadBtn}
                                    >
                                        Download Template
                                    </Button>
                                </CSVLink>
                                <label data-testid="label" htmlFor="csv-file">
                                    <Button
                                        variant="contained"
                                        id="uploadBtn"
                                        disableRipple
                                        color="primary"
                                        component="span"
                                        data-testid="document"
                                        className={classes.uploadBtn}
                                    >
                                        Upload Document
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Hidden mdDown>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={2}
                    style={{ marginTop: 20 }}
                >
                    <Grid item>
                        {isFile && (
                            <Button
                                id="bulkBack"
                                data-testid="back"
                                variant="contained"
                                color="primary"
                                disableRipple={true}
                                onClick={() => setIsFile(false)}
                                className={classes.send}
                            >
                                Back
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <Button
                            id="sendBtn"
                            variant="contained"
                            color="primary"
                            className={classes.send}
                            onClick={(e: any) => props.sendUsersData(e, csvArray)}
                        >
                            Done
                        </Button>
                    </Grid>
                </Grid>
            </Hidden>
        </div>
    );
}
