import { createSlice } from '@reduxjs/toolkit';

export const activeTabSlice = createSlice({
    name: 'activetab',
    initialState: { activeTab: '/dashboard' },
    reducers: {
        updateActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
});

export const { updateActiveTab } = activeTabSlice.actions;
export default activeTabSlice.reducer;
