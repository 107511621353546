import React from 'react';
import useStyles from './styles';
import { Hidden, Grid } from '@material-ui/core';
import TeamPlusLogo from '../../Assets/images/udyamo-splash-screen.png';
// import DoneIcon from '@material-ui/icons/Done';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Stepper from './stepper';

interface ProgressProps {
    isLogedIn?: boolean;
    task?: any;
    isRegister?: boolean;
}

export default function ProgressPage(props: ProgressProps) {
    const classes = useStyles();
    return (
        <Grid container className={classes.body}>
            <Hidden lgDown>
                <Grid item xl={5}>
                    <Grid container direction="row" className={classes.bgImage}>
                        <h1 className={classes.heading}>
                            <img src={TeamPlusLogo} alt="TeamPlus" style={{ maxWidth: '100%', height: 75 }} />
                        </h1>
                    </Grid>
                </Grid>
            </Hidden>

            {props.isLogedIn ? (
                <Grid
                    item
                    xl={7}
                    sm={12}
                    xs={12}
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <img src="loading.svg" alt="loading" style={{ maxWidth: '80%' }} />
                    <h6
                        style={{
                            marginTop: 50,
                            fontSize: 18,
                            color: '#3D49B6',
                            fontFamily: 'Nunito Sans',
                            fontWeight: 600,
                            textAlign: 'center',
                            width: '80%',
                        }}
                    >
                        Please wait for seconds. We are fetching your details.
                    </h6>
                </Grid>
            ) : (
                <Grid item xl={7}>
                    <div
                        style={{
                            width: '100%',
                            height: '100vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Stepper task={props.task} isRegister={props.isRegister} />
                        {/* <Spinner /> */}
                        {/* <p>
                            {props.task.task1 ? (
                                <>
                                    <DoneIcon fontSize="large" style={{ color: 'green' }} />
                                    done Organization created
                                </>
                            ) : (
                                <>
                                    <InfoOutlinedIcon fontSize="large" />
                                    waiting Creating organisation
                                </>
                            )}
                        </p>
                        <p>
                            {props.task.task2 ? (
                                <>
                                    <DoneIcon fontSize="large" style={{ color: 'green' }} />
                                    done teams created
                                </>
                            ) : (
                                <>
                                    <InfoOutlinedIcon fontSize="large" />
                                    waiting teams
                                </>
                            )}
                        </p>
                        <p>
                            {props.task.task3 ? (
                                <>
                                    <DoneIcon fontSize="large" style={{ color: 'green' }} />
                                    done workweek
                                </>
                            ) : (
                                <>
                                    <InfoOutlinedIcon fontSize="large" />
                                    waiting workweek
                                </>
                            )}
                        </p>
                        <p>
                            {props.task.task4 ? (
                                <>
                                    <DoneIcon fontSize="large" style={{ color: 'green' }} />
                                    done leave types
                                </>
                            ) : (
                                <>
                                    <InfoOutlinedIcon fontSize="large" />
                                    waiting leave types
                                </>
                            )}
                        </p> */}
                    </div>
                </Grid>
            )}
        </Grid>
    );
}
