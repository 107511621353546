import React, { useContext } from 'react';
import { Paper, Grid, Typography, TextField, Avatar, Tooltip } from '@material-ui/core';
import useStyles from './commentsStyle';
import { IconButton } from '@mui/material';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import { ToStringFormat, convertTZ } from '../../helpers/date';
import AuthContext from '../../context/AuthContext';
import { createComment, deleteComment } from '../../helpers/hasuraapi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import moment from 'moment';
import { getFullName } from 'helpers/getInitials';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import { useSubscription } from '@apollo/client';
import { getComment } from 'helpers/subscriptions/pollSubscriptions';
import ErrorPage from 'components/Error/ErrorPage';
import { Header } from '../Polls/pollresults';
import { localToISO, getCurrentCountryDateTime, isoToLocal } from '../../helpers/timezone';

interface UserCommentProps {
    name?: string;
    date?: any;
    comment?: string;
    avatar?: any;
    lname?: string;
    empId?: string;
    id?: any;
    handleDelete?: any;
    mycontext?: any;
    commentDateTime?: any;
}
export const UserComment = (props: UserCommentProps) => {
    const classes = useStyles();
    // const mycontext = useContext(AuthContext);

    return (
        <>
            <Grid container>
                <Grid item xs={11}>
                    <Grid container>
                        <Grid item>
                            <Avatar src={props.avatar} style={{ width: '30px', height: '30px', fontSize: 13 }}>
                                {getFullName(props.name + ' ' + props.lname)}
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.name}>
                                {props.name} {props.lname}{' '}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={ToStringFormat(
                                    isoToLocal(props?.commentDateTime, props.mycontext.country)
                                        .toString()
                                        .substring(3, 15),
                                    props.mycontext.dateFormat,
                                )}
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                                arrow
                            >
                                <Typography className={classes.date}>
                                    | &nbsp;
                                    {moment(isoToLocal(props?.commentDateTime, props.mycontext.country)).from(
                                        convertTZ(new Date().toISOString(), props.mycontext.country?.zoneName),
                                    )}
                                </Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    {props.empId === props.mycontext.EmployeeId && (
                        <IconButton style={{ outline: 'none', right: 3 }}>
                            <RiDeleteBinLine
                                className={classes.icon}
                                onClick={() => props.handleDelete(props.id)}
                                data-testid="delete"
                            />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item className={classes.text}>
                    <Typography>{props.comment} </Typography>
                </Grid>
            </Grid>
        </>
    );
};

interface TextProps {
    mycontext: any;
    setComments: any;
    pollId: any;
}

export const TextFieldComponent = (props: TextProps) => {
    const classes = useStyles();
    const [input, setInput] = React.useState('');
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setInput(event.target.value as string);
    };

    const handleSubmit = async () => {
        setInput('');
        try {
            if (input !== '') {
                const obj = {
                    token: props.mycontext.token,
                    pollId: props.pollId,
                    commentText: input,
                    commentDate: moment()
                        .format()
                        .toString()
                        .substring(0, 19),
                    employeeId: props.mycontext.EmployeeId,
                    Employee: {
                        firstName: props.mycontext.fname,
                        lastName: props.mycontext.lname,
                        profileImage: props.mycontext.profile,
                    },
                    commentDateTime: localToISO(
                        getCurrentCountryDateTime(props.mycontext.country?.zoneName).date,
                        getCurrentCountryDateTime(props.mycontext.country?.zoneName).time,
                        props.mycontext.country,
                    ),
                };
                // console.log(obj);
                props.setComments((prev: any) => [...prev, obj]);
                setInput('');
                const resp = await createComment(obj);
                // console.log('resp', resp);
                return resp;
            } else {
                alert('please type something to submit');
            }
        } catch (err) {
            return err;
        }
    };

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter' && input !== '') {
            // e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <>
            <TextField
                data-testid="text"
                className={classes.field}
                variant="outlined"
                defaultValue="success"
                size="small"
                placeholder="Type your message"
                onKeyPress={handleKeyPress}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton style={{ outline: 'none', color: '#8AA1D4' }} data-testid="send">
                                <SendIcon onClick={() => handleSubmit()} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                value={input}
                onChange={handleChange}
            />
        </>
    );
};

export interface Props {
    setStep?: any;
    pollId?: any;
}
export default function Comment(props: Props) {
    const classes = useStyles();

    // const history = useHistory();
    const [comments, setComments] = React.useState<any>([]);
    const mycontext = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const pollComment = useSubscription(getComment(props.pollId));

    const handleDelete = async (id: any) => {
        // console.log('id', id);
        try {
            const data: any = await deleteComment(id, mycontext.token);
            if (data.error) {
                throw new Error('error');
            }
            const newComments = [...comments];
            const index = comments.findIndex((comment: any) => comment.commentId === id);
            newComments.splice(index, 1);
            setComments(newComments);
        } catch (err) {
            return err;
        }
    };

    // console.log(
    //     moment(isoToLocal(comments[0]?.commentDateTime, mycontext.country)).from(
    //         convertTZ(new Date().toISOString(), mycontext.country?.zoneName),
    //     ),
    // );
    // React.useEffect(() => {
    //     const interval = setInterval(() => {
    //         const fetchData = async () => {
    //             const data: any = await fetchComment(props.pollId, mycontext.token);
    //             // console.log('data', data);
    //             if (data.error) {
    //                 setIsError(true);
    //             } else {
    //                 setIsLoading(false);
    //                 setComments(data);
    //             }
    //         };
    //         fetchData();
    //     }, 1000 * 1);
    //     return () => clearInterval(interval);
    // }, [mycontext, props]);
    React.useMemo(() => {
        if (!pollComment.loading) {
            const data: any = pollComment?.data?.pollsdb_poll_comment;
            // console.log('data', data);
            if (data.error) {
                setIsError(true);
            } else {
                setIsLoading(false);
                setComments(data);
            }
        }
    }, [pollComment]);
    if (isLoading) {
        return <h1>fetching data</h1>;
    }
    if (isError) {
        return <ErrorPage title="Something went wrong!" />;
    }
    return (
        <>
            {/* <Hidden smDown>
                <Paper elevation={0}>
                    <div>
                        <Grid container className={classes.paper1}>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <ArrowBackIcon className={classes.backArrow} onClick={() => props.setStep(0)} />
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.poll}>Comment</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </Hidden>
            <Hidden mdUp>
                <Grid container className={classes.paper1}>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <ArrowBackIcon className={classes.backArrow} onClick={() => props.setStep(0)} />
                            </Grid>
                            <Grid item>
                                <div className={classes.poll}>Comment</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden> */}
            <Header handleClick1={1} setStep={props.setStep} title="Comment" />
            <div className={classes.border}>
                <Paper elevation={0} className={classes.paper2}>
                    <div className={classes.main}>
                        <div className={classes.scroll}>
                            {comments.length === 0 ? (
                                <div id="noResultFound" className={classes.fontsty} style={{ borderBottom: 'none' }}>
                                    <img
                                        // data-testid="commentsDiv"
                                        src={upcomingTimeoff}
                                        alt="No comment found"
                                        className={classes.notfoundImage}
                                    />
                                    <div className={classes.notfoundText}>No comment found</div>
                                </div>
                            ) : (
                                comments.map((c: any, i: number) => (
                                    <UserComment
                                        key={i}
                                        avatar={c.Employee.profileImage}
                                        name={c?.Employee?.firstName}
                                        lname={c?.Employee?.lastName}
                                        date={c.commentDate}
                                        comment={c.commentText}
                                        empId={c.employeeId}
                                        id={c.commentId}
                                        commentDateTime={c?.commentDateTime}
                                        handleDelete={handleDelete}
                                        mycontext={mycontext}
                                    />
                                ))
                            )}
                        </div>
                        <TextFieldComponent mycontext={mycontext} setComments={setComments} pollId={props.pollId} />
                    </div>
                </Paper>
            </div>
        </>
    );
}
