import { createStyles, makeStyles, Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '100%',
        },
        containerSkeleton: {
            height: 'calc(100vh - 140px)',
            backgroundColor: 'white',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        MainMemberTable: {
            // border: '1px solid blue',
            height: 'calc(100vh - 230px)',
            textTransform: 'initial',
            '@media only screen and (max-width: 540px)': {
                // height: '70vh',
                height: 'auto',
            },
        },
        modalMainTitle: {
            fontSize: 22,
            color: theme.palette.info.light,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
        },
        modalContent: {
            fontWeight: theme.typography.fontWeightMedium,
            textAlign: 'center',
            fontSize: 16,
            color: theme.palette.text.primary,
        },
        chipStyle1: {
            padding: '4px 10px',
            backgroundColor: '#A8FCA2',
            color: '#1F8C2D',
            borderRadius: '16px',
            fontSize: '12px',
            fontWeight: 600,
            letterSpacing: '0.04em',
            border: '1px solid #1F8C2D',
            fontFamily: 'Nunito Sans',
        },
        chipStyle2: {
            padding: '4px 10px',
            backgroundColor: '#FAD37D',
            color: '#e0491b',
            borderRadius: '16px',
            fontSize: '12px',
            fontWeight: 600,
            letterSpacing: '0.04em',
            border: '1px solid #e0491b',
            fontFamily: 'Nunito Sans',
        },
        formControl: {
            minWidth: 200,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        menuItem: {
            backgroundColor: '#fff',
            '&:focus': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        btnPrimary: {
            height: 35,
            width: 100,
            margin: '0px 5px',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                outline: 'none',
            },
        },
        modalLabel1: {
            paddingTop: 2,
            width: '70%',
            color: theme.palette.text.primary,
            fontWeight: 900,
            fontSize: '16px',
            textTransform: 'initial',
            fontFamily: theme.typography.fontFamily,
        },
        modalLabel2: {
            paddingTop: 2,
            color: theme.palette.primary.contrastText,
            // fontWeight: theme.typography.fontWeightMedium,
            fontSize: '14px',
            textTransform: 'initial',
            fontFamily: theme.typography.fontFamily,
            letterSpacing: '0.04em',
        },
        grid: {
            marginBottom: 30,
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontStyle: 'normal',
            color: theme.palette.text.primary,
        },
        toolbarRow: {
            display: 'flex',
            height: 45,
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '1px 0px',
            padding: '5px 10px',
            '@media screen and (max-width: 650px)': {
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
        },
        dragTitle: {
            // border: '1px solid blue',
            width: '100%',
            fontSize: 16,
            fontWeight: 600,
            color: '#888888',
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
        },
        main: {
            flexGrow: 1,
            width: '100%',
            // padding: '20px 40px',
            padding: 20,
            '@media screen and (max-width: 800px)': {
                padding: 10,
            },
        },
        table: {
            // marginTop: '30px',
            margin: 'auto',
            width: '100%',
            height: 'calc(100vh - 220px)',
        },
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: 10,
        },
        viewBtn: {
            height: 30,
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            width: '98%',
            cursor: 'pointer',
            padding: '10px 0px',
            fontSize: 12,
            textTransform: 'capitalize',
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
            },
            '&:focus': {
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
                outline: 'none',
            },
            zIndex: 0,
            '@media screen and (max-width: 650px)': {
                fontSize: 14,
            },
        },
        deleteBtn: {
            height: 30,
            backgroundColor: '#fff',
            color: theme.palette.error.main,
            fontSize: 12,
            fontFamily: theme.typography.fontFamily,
            width: '98%',
            cursor: 'pointer',
            textTransform: 'capitalize',
            padding: '10px 0',
            '&:hover': {
                color: theme.palette.error.main,
                backgroundColor: '#fff',
            },
            '&:focus': {
                color: theme.palette.error.main,
                backgroundColor: '#fff',
                outline: 'none',
            },
            zIndex: 0,
            '@media screen and (max-width: 650px)': {
                fontSize: 14,
            },
        },
        teams: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        teamchip: {
            margin: '2px',
            color: '#222222',
            border: '1px solid #666666',
        },
        empLeft: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 12,
            fontWeight: theme.typography.fontWeightMedium,
            textTransform: 'uppercase',
            color: '#666666',
            padding: '10px 0',
            display: 'flex',
            alignItems: 'center',
            justify: 'center',
        },
        empText: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
            padding: '10px 0',
            display: 'flex',
            alignItems: 'center',
        },
        link: {
            width: '100%',
            textDecoration: 'none',
            textAlign: 'center',
            '&:hover, &:focus': {
                textDecoration: 'none',
                outline: 'none',
                color: '#fff',
            },
        },
        currentSelect: {
            color: '#fff',
            background: theme.palette.primary.main,
            border: 'none',
            outline: 'none',
            '&:hover, &:focus': {
                color: '#fff',
                background: theme.palette.primary.main,
                border: 'none',
                outline: 'none',
            },
        },
        selectedMenus: {
            color: theme.palette.text.primary,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.main,
                border: 'none',
                outline: 'none',
                color: '#fff',
                '& a': {
                    color: '#fff',
                },
            },
        },
        filterButton: {
            letterSpacing: '0.05em',
            textTransform: 'initial',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '&:focus': {
                outline: 'none',
            },
        },
        popoverPropsOne: {
            '@media screen and (max-width: 760px)': {
                width: '20%',
            },
            '@media screen and (min-width: 761px) and (max-width: 1024px)': {
                width: '15%',
            },
            '@media screen and (min-width: 1024.5px)': {
                width: '10%',
            },
        },
        popoverPropsTwo: {
            '@media screen and (max-width: 760px)': {
                width: '28%',
            },
            '@media screen and (min-width: 761px) and (max-width: 1024px)': {
                width: '15%',
            },
            '@media screen and (min-width: 1024.5px)': {
                width: '10%',
            },
        },
        moreActions: {
            // outline: 'none',
            '&:hover, &:focus': {
                border: 'none',
                outline: 'none',
            },
            // '&:active':{
            //     outline: 'none',
            // }
        },
        deactivatelink: {
            width: '100%',
            textDecoration: 'none',
            textAlign: 'center',
            color: 'red',
            '&:hover': {
                textDecoration: 'none',
                outline: 'none',
            },
        },
        scrollDiv: {
            height: 'calc(100vh - 250px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
            '@media screen and (max-width: 650px)': {
                height: 'calc(100vh - 100px)',
                paddingBottom: 100,
            },
        },
        TeamMember: {
            marginTop: 30,
            paddingTop: '5px',
        },
        btnlabel: {
            position: 'relative',
            left: '-12px',
            display: 'inline-block',
            padding: '6px 12px',
            border: '3px 0 0 3px',
        },
        teamMemberOptions: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            '@media screen and (max-width: 650px)': {
                flexDirection: 'column',
            },
        },
        username: {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            textTransform: 'capitalize',
        },
        captionStyle: {
            color: theme.palette.secondary.contrastText,
            fontSize: 13,
            fontWeight: 600,
        },
        titles: {
            color: theme.palette.secondary.contrastText,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            marginTop: 10,
        },
        teamNames: {
            textTransform: 'capitalize',
        },
        searchMobile: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        inviteMore: {
            width: '100%',
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
            background: '#FFFFFF',
            border: '1px solid #3C48B5',
            borderRadius: '0px',
            marginBottom: 50,
            '&:hover, &:focus, &:active': {
                color: theme.palette.primary.main,
                background: '#FFFFFF',
            },
        },
        inviteBtn: {
            height: 35,
            // width: '90%',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            textTransform: 'capitalize',
            color: theme.palette.common.white,
            whiteSpace: 'nowrap',
            '&:hover, &:focus': {
                outline: 'none',
                background: theme.palette.primary.main,
                color: '#fff',
            },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 14,
                padding: 0,
                width: '100%',
            },
        },
        invitePageSearch: {
            '@media screen and (max-width: 600px)': {
                width: '48%',
                paddingTop: '6px',
            },
            '@media screen and (min-width: 1024px)': {
                width: '30%',
            },
        },
        sideLink: {
            textDecoration: 'none',
            margin: '0px auto',
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
        },
        PageNames: {
            color: 'black',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            opacity: 0.8,
        },
        largeBtn: {
            height: 40,
            position: 'fixed',
            bottom: 0,
            left: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            marginRight: 10,
            zIndex: 99,
            borderRadius: 4,
            color: '#FFFFFF',
            width: '100%',
            background: theme.palette.primary.main,
            '&:hover, &:focus': {
                background: '#3C48B5',
                border: 'none',
                outline: 'none !important',
            },
        },
        btnFixed: {
            height: 40,
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            color: '#FFFFFF',
            borderRadius: 0,
            zIndex: 99,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#FFFFFF',
            },
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                color: '#FFFFFF',
                textDecoration: 'none',
                outline: 'none',
                border: '1px solid #3C48B5',
            },
        },
        backBtn: {
            height: 40,
            width: '50%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: '#FFFFFF',
            color: theme.palette.primary.main,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            borderRadius: 0,
            zIndex: 99,
            '&:hover, &:focus': {
                textDecoration: 'none',
                border: '1px solid #3C48B5',
                outline: 'none',
                backgroundColor: '#FFFFFF',
                color: theme.palette.primary.main,
            },
        },
        sendBtn: {
            height: 40,
            width: '50%',
            position: 'fixed',
            bottom: 0,
            right: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            borderRadius: 0,
            color: '#FFFFFF',
            zIndex: 99,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                outline: 'none !important',
                border: '1px solid #3C48B5',
            },
        },
        tablecolumnname: {
            // height: 30,
            color: '#262F83',
            // minWidth: 280,
            background: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            zIndex: 999,
        },
        tableColumnApprover: {
            // height: 30,
            color: '#262F83',
            // minWidth: 150,
            background: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            zIndex: 999,
        },
        tableColumnEmployee: {
            // height: 30,
            color: '#262F83',
            // minWidth: 150,
            background: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            zIndex: 999,
        },
        tableColumnAction: {
            // height: 30,
            color: '#262F83',
            // minWidth: 150,
            background: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            zIndex: 999,
        },
        tablecolumn: {
            height: 30,
            color: '#262F83',
            minWidth: 250,
            background: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            zIndex: 999,
        },
        idcolumn: {
            height: 30,
            color: '#262F83',
            minWidth: 100,
            background: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            zIndex: 999,
        },
        idcolumnSkeleton: {
            height: 30,
            color: '#262F83',
            minWidth: 50,
            background: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            zIndex: 999,
        },
        hashId: {
            height: 30,
            color: '#262F83',
            minWidth: 50,
            background: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            zIndex: 999,
        },
        inviteContainer: {
            // border: '1px solid aqua',
            '@media screen and (max-width: 1023px)': {
                // padding: '40px 10px',
                minHeight: 'calc(100vh - 220px)',
                maxHeight: 'calc(100vh - 200px)',
            },
        },
        tablerows: {
            padding: '5px',
        },
        userName: {
            fontWeight: theme.typography.fontWeightBold,
            color: '#333333',
            fontSize: '18px',
            cursor: 'pointer',
        },
        userName1: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '18px',
            color: '#333333',
        },
        rowData: {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '14px',
            // borderBottom: 'none'
        },
        label1: {
            color: 'black',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            opacity: 0.6,
        },
        btnOutlined: {
            height: 35,
            width: 100,
            fontFamily: theme.typography.fontFamily,
            textTransform: 'capitalize',
            fontSize: 16,
            fontWeight: 700,
            backgroundColor: '#FFFFFF',
            color: '#666666',
            '&:hover': {
                backgroundColor: '#FFFFFF',
                color: '#666666',
                outline: 'none',
            },
            '&:focus': {
                border: 'none',
                color: '#666666',
                outline: 'none',
            },
        },
        label: {
            paddingTop: 2,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '16px',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
        },
        errorlabel: {
            paddingTop: 2,
            color: theme.palette.error.main,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '16px',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
        },
        heading: {
            color: '#444444',
            fontSize: '22px',
            fontWeight: 600,
        },
        Items: {
            display: 'flex',
            alignContent: 'right',
            alignItems: 'right',
        },
        formControl2: {
            width: '80%',
            height: 40,
            background: '#FFFFFF',
            boxSizing: 'border-box',
            borderRadius: 30,
            [`& fieldset`]: {
                borderRadius: 25,
                outline: 'none',
            },
            '&:hover, &:focus': {
                outline: 'none',
                border: 'none',
            },
            // color: '#A6ACAF',
            // width: 'auto',
            // margin: '0px 10px',
            // '@media only screen and (max-width: 650px)': {
            //     width: '100%',
            //     margin: '10px 0px',
            // },
        },
        addBtn: {
            outline: 'none',
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            fontWeight: 700,
            width: '20%',
            height: 40,
            borderRadius: 30,
            boxShadow: 'none',
            '&:hover': {
                color: '#FFFFFF',
                outline: 'none',
                backgroundColor: theme.palette.primary.main,
                boxShadow: 'none',
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
            },
        },
        emailsDiv: {
            width: '80%',
            boxSizing: 'border-box',
            borderRadius: 25,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.main,
            height: 40,
            margin: '30px auto',
            marginBottom: '8px',
            '@media screen and (max-width: 650px)': {
                margin: '20px auto',
            },
            '&:hover, &:focus': {
                outline: 'none',
            },
            // width: '100%',
            // // border: `1px solid ${theme.palette.secondary.main}`,
            // padding: '10px 10px',
        },
        chip: {
            padding: '5px 5px',
            textDecoration: 'none',
            listStyle: 'none',
        },
        chipGroup: {
            maxHeight: 80,
            overflow: 'auto',
            marginBottom: 30,
            '&::-webkit-scrollbar': {
                width: '0.2em',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 3px rgba(0,0,0,0)',
                webkitBoxShadow: 'inset 0 0 3px #3C48B5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#3C48B5',
                outline: 'none',
            },
        },
        btn: {
            fontFamily: theme.typography.fontFamily,
            textTransform: 'capitalize',
            marginRight: '10px',
            cursor: 'pointer',
            '&:focus': {
                outline: 'none',
            },
        },
        modalbtn: {
            height: 35,
            width: 100,
            // textTransform: 'capitalize',
            // padding: '0 30px',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            fontWeight: 700,
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
            // marginRight: 10,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#FFFFFF',
                outline: 'none',
            },
            '&:focus': {
                border: 'none',
                color: '#FFFFFF',
                outline: 'none',
            },
            // '@media screen and (max-width: 650px)': {
            //     margin: '10px 0',
            //     marginRight: 10,
            // },
        },
        active: {
            width: 7,
            height: 7,
            backgroundColor: theme.palette.success.main,
            marginRight: 5,
            borderRadius: '50%',
        },
        inactive: {
            width: 7,
            height: 7,
            backgroundColor: theme.palette.success.main,
            marginRight: 5,
            borderRadius: '50%',
        },
        fontright: {
            float: 'right',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            alignItems: 'center',
            opacity: 0.7,
        },
        rowmessage: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px',
        },
        expired: {
            width: '100%',
            height: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // backgroundColor: '#000',
            // opacity: 0.2,
            zIndex: 0,
            position: 'relative',
        },
        card: {
            // position: 'absolute',
            // top: '50%',
            width: 300,
            // height: 300,
            backgroundColor: '#fff',
            boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.5)',
            color: theme.palette.primary.main,
            padding: 50,
            zIndex: 1,
        },
        divAvatar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
        skeleton: {
            width: '50%',
            height: 50,
            backgroundColor: 'silver',
            borderRadius: 3,
        },
        smallWidth: {
            width: '25%',
        },
        bigWidth: {
            width: '30%',
        },
        changeSize: {
            // maxWidth: 250,
            fontSize: '14px',
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                minWidth: 160,
                maxWidth: 300,
            },
        },
        teamNameStyle: {
            textTransform: 'none',
        },
        notfoundImage: {
            maxWidth: '80%',
            height: '150px',
        },
        notfoundText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 600,
        },
        rows: {
            border: 'none',
            '&:hover': {
                boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
                // transform: 'translateY(2px)',
                color: theme.palette.primary.main,
                border: 'none',
                outline: 'none',
                borderRadius: 5,
                cursor: 'pointer',
                backgroundColor: theme.palette.info.main,
                // '& span': {
                //     color: theme.palette.primary.main,
                // },
                // '& makeStyles-shiftIcon-128': {
                //     color: 'green',
                // },
            },
        },
        container: {
            height: 'calc(100vh - 220px)',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        tables: {
            // border: '1px solid orange',
            maxHeight: 'calc(100vh - 220px)',
            color: '#666666',
            fontFamily: theme.typography.fontFamily,
        },
        wrapIcon: {
            verticalAlign: 'middle',
            display: 'inline-flex',
        },
        filterBox: {
            margin: 0,
            padding: 0,
            height: 35,
            minWidth: 150,
            fontSize: 14,
            // fontSize: 12,
            fontWeight: 600,
            // border: '1px solid #C4C4C4',
            color: '#888888',
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: 0,
            },
        },
        searchInput: {
            height: 35,
            width: '100%',
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '&:hover, &:focus': {
                outline: 'none',
                color: theme.palette.text.primary,
            },
            // '@media screen and (min-width: 768px)': {
            //     marginLeft: '10px',
            // },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: '0px 14px',
                '&:hover, &:focus': {
                    outline: 'none',
                    color: theme.palette.text.primary,
                },
            },
        },
        searchBorder: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&:hover fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
        },
        searchIcon: {
            '@media screen and (min-width:912px) and (max-width: 1024px)': {
                marginLeft: 10,
            },
        },
        filterBtn: {
            width: '80%',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            height: 35,
            fontSize: 14,
            fontWeight: 600,
            textTransform: 'capitalize',
            border: '1px solid #C4C4C4',
            padding: 0,
            // borderRadius: 20,
            '&:hover, &:focus': {
                outline: 'none',
                // background: '#fff',
            },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: 0,
            },
        },
        menuList: {
            // width: '80%',
            color: '#888888',
            // height: 35,
            fontSize: 14,
            fontWeight: 600,
            textTransform: 'capitalize',
            border: '1px solid #C4C4C4',
            padding: 0,
        },
        applyFilter: {
            width: '80%',
            textTransform: 'capitalize',
            height: 30,
            fontSize: 16,
            background: theme.palette.primary.main,
            color: '#FFFFFF',
            fontWeight: 600,
            '&:hover, &:focus': {
                outline: 'none',
                background: theme.palette.primary.main,
            },
        },
        popupBox: {
            minWidth: 250,
            maxWidth: 320,
            minHeight: 300,
            maxHeight: 400,
            padding: 20,
        },
        datePicker: {
            '& .MuiInputBase-root': {
                padding: 0,
                '& .MuiButtonBase-root': {
                    padding: 0,
                    paddingLeft: 0,
                },
                '& .MuiInputBase-input': {
                    padding: 0,
                },
            },
            '&:hover, &:focus': {
                outline: 'none',
            },
        },
        controlLabel: {
            color: '#333333',
            fontSize: 12,
            height: 20,
        },
        formLabel: {
            color: '#666666',
            fontSize: 14,
        },
        icons: {
            '&:hover, &:focus': {
                outline: 'none',
                border: 'none',
            },
        },
        mobileNoDataFound: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 18,
            color: '#666666',
            fontFamily: theme.typography.fontFamily,
        },
        headingModal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '20px',
        },
        modalTitle: {
            color: '#3C48B5',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '22px',
            zIndex: 0,
        },
        newModalContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        typographyH6heading: {
            color: theme.palette.text.primary,
            fontSize: '14px',
        },
        paperstyle: {
            height: 45,
            marginBottom: 10,
            // paddingTop: 5,
            // paddingBottom: 5,
        },
        breakcrum: {
            // height: 45,
            marginBottom: 10,
            // padding: '5px 10px',
            // paddingTop: 5,
            // paddingBottom: 5,
        },
        tabs: {
            minHeight: '30%',
            lineheight: '25px',
            // paddingLeft: '10px ',
            borderradius: '5px',
        },
        tab: {
            letterspacing: '0.05em',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.contrastText,
            textTransform: 'initial',
            minHeight: '10%',
            minWidth: 100,
            margin: '8px 20px',
            lineHeight: '30%',
            padding: '10px 15px',
            fontSize: 16,
            '&.Mui-selected': {
                outline: 'none',
            },
            fontStyle: 'normal',
        },
        moreBtn: {
            border: 'none',
            borderRadius: 0,
            borderBottom: '1px solid #3C48B5',
            textTransform: 'capitalize',
            marginTop: 10,
            marginLeft: 40,
            '&:hover, &:focus': {
                outline: 'none',
                border: 'none',
                borderBottom: '1px solid #3C48B5',
            },
        },
        textField: {
            width: 200,
            color: '#888888',
            fontSize: 14,
            '& .MuiSvgIcon-root': {
                color: '#C4C4C4',
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        labels: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 14,
        },
        bulkLabels: {
            color: '#262F83',
            fontSize: 14,
            fontWeight: theme.typography.fontWeightBold,
        },
        mobileField: {
            color: '#999999',
            fontSize: 14,
            '& .MuiSvgIcon-root': {
                color: '#C4C4C4',
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        // outerContainer: {
        //     border: '1px solid aqua',
        // },
        innerContainer: {
            // border: '1px solid green',
            minHeight: 'calc(100vh - 400px)',
            maxHeight: 'calc(100vh - 350px)',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        emailContainer: {
            // border: '1px solid orange',
            marginTop: 20,
            // minHeight: 'calc(100vh - 400px)',
            maxHeight: 'calc(100vh - 350px)',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        dashedContainer: {
            border: '1px dashed #ABABAB',
            width: '100%',
            padding: '70px 50px',
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                padding: '50px 20px',
            },
        },
        bulkContainer: {
            // border: '1px solid red',
            padding: '20px 20px',
            minHeight: 'calc(100vh - 350px)',
            maxHeight: 'calc(100vh - 300px)',
            background: theme.palette.background.paper,
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                minHeight: 'calc(100vh - 350px)',
                maxHeight: 'calc(100vh - 300px)',
                padding: 50,
            },
        },
        emailList: {
            maxHeight: 'calc(100vh - 250px)',
            height: '100%',
            color: '#666666',
            fontFamily: theme.typography.fontFamily,
            '&:last-child th, &:last-child td': {
                borderBottom: 0,
            },
        },
        tabContainer: {
            minHeight: 'calc(100vh - 180px)',
            // maxHeight: 'calc(100vh - 130px)',
        },
        cancelIcon: {
            color: '#BCBCBC',
            height: 15,
        },
        fab: {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
            height: 20,
            width: 20,
            fontSize: 12,
            display: 'inline-flex',
            '&:hover': {
                color: theme.palette.secondary.contrastText,
                transform: 'scale(1.2)',
                // textDecoration: 'underline',
            },
        },
        teamList: {
            maxHeight: '100px',
            overflowY: 'auto',
            margin: '0px 5px',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        downloadBtn: {
            textTransform: 'capitalize',
            marginTop: 5,
            marginBottom: 5,
            height: 40,
            width: 200,
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
            '&:hover, &:focus': {
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
            },
        },
        uploadBtn: {
            textTransform: 'capitalize',
            margin: 5,
            height: 40,
            width: 200,
            color: '#FFFFFF',
            backgroundColor: theme.palette.primary.main,
            '&:hover, &:focus': {
                color: '#FFFFFF',
                backgroundColor: theme.palette.primary.main,
            },
        },
        back: {
            textTransform: 'capitalize',
            height: 40,
            minWidth: 120,
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
            '&:hover, &:focus': {
                textDecoration: 'none',
                border: '1px solid #3C48B5',
                outline: 'none',
                backgroundColor: '#FFFFFF',
                color: theme.palette.primary.main,
            },
        },
        send: {
            textTransform: 'capitalize',
            height: 40,
            minWidth: 150,
            color: '#FFFFFF',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: '#FFFFFF',
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                outline: 'none !important',
                color: '#FFFFFF',
                backgroundColor: theme.palette.primary.main,
            },
        },
        csvLink: {
            textDecoration: 'none',
            '&:hover, &:focus': {
                textDecoration: 'none',
            },
        },
        IconSty: {
            color: theme.palette.primary.contrastText,
        },
        backArrow: {
            width: 25,
            height: 12,
            cursor: 'pointer',
            marginLeft: 10,
            marginRight: 10,
            padding: 0,
            '@media screen and (max-width: 768px)': {
                width: 18,
                height: 8,
            },
        },
        teamMenuTitle: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            fontFamily: theme.typography.fontFamily,
            textAlign: 'left',
        },
        listText: {
            textTransform: 'none',
        },
        myFilter: {
            width: '12%',
            // top: '-6px',
            height: 30,
            fontSize: 14,
            fontWeight: 600,
            textTransform: 'capitalize',
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            padding: 0,
            marginTop: 0,
            '&:hover, &:focus': {
                outline: 'none',
            },
            // '& span': {
            //     // justifyContent: 'space-evenly',
            // },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: 0,
            },
            '@media screen and (max-width: 600px)': {
                width: '22%',
            },
        },
        menusty: {
            borderRadius: 0,
        },
        menuitem: {
            // marginLeft: 5,
            // marginRight: 5,
            // fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            // fontStyle: 'normal',
            // fontSize: '16px',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            textTransform: 'capitalize',
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:active': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
        },

        tableContainer: {
            height: 'calc(100vh - 240px)',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.background.paper,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        tableHeight: {
            maxHeight: 'calc(100vh - 200px)',
            width: '100%',
        },
        row: {
            border: 'none',
            '&:hover': {
                boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
                color: theme.palette.primary.main,
                border: 'none',
                outline: 'none',
                borderRadius: 5,
                cursor: 'default',
                backgroundColor: theme.palette.info.main,
            },
        },
        cellName: {
            padding: '7px',
            // fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            textTransform: 'none',
            borderBottom: 'none',
            // textAlign: 'center',
            color: theme.palette.text.primary,
            letterSpacing: '0.03em',
        },
        column: {
            color: '#262F83',
            backgroundColor: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
            // textAlign: 'center',
        },
        columnnam: {
            color: '#262F83',
            backgroundColor: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
            textAlign: 'center',
        },
        fontsty: {
            textAlign: 'center',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            color: theme.palette.text.primary,
            opacity: 0.7,
        },
        emailEnable: {
            color: theme.palette.primary.contrastText,
            padding: 0,
            '&:hover': {
                color: '#3C48B5',
            },
        },
        emailDisable: {
            color: theme.palette.grey[50],
            padding: 0,
            cursor: 'not-allowed',
        },
        paperfontstyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '120px',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '18px',
            color: '#888888',
            fontStyle: 'normal',
            fontFamily: theme.typography.fontFamily,
        },
        mobpapertext: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '18px',
            fontStyle: 'bold',
            fontFamily: theme.typography.fontFamily,
        },
        displayCenter: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        },
        mobbtnFixed: {
            height: 40,
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            textTransform: 'capitalize',
            padding: '0 50px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            marginRight: 10,
            borderRadius: 0,
            zIndex: 99,
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            // borderColor: theme.palette.primary.main,
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        tableCellStyle: {
            borderBottom: 'none',
        },
        timeOffStatusGrid: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        backArrowStyle: {
            cursor: 'pointer',
            color: theme.palette.text.primary,
            marginRight: '10px',
        },
        actionBtn: {
            height: 35,
            width: 180,
            textTransform: 'capitalize',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
    }),
);
export default useStyles;
