import { gql } from '@apollo/client';

export const getDashboardLeaves = (leaveStatus: string, organisationId: string, sDate: string, eDate: string) => {
    if (leaveStatus === 'pending') {
        return gql`
            subscription DashboardLeaves {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organisationId}"}, leaveStatus: {_eq: "pending"}}) {
                    category
                    employeeId
                    endDate
                    endTime
                    leaveId
                    leaveStatus
                    organizationId
                    reason
                    startDate
                    startTime
                    leaveAppliedBy
                    approverId
                    denierId
                    denialReason
                    gmt
                    noOfHours
                    isoString
                    Employee {
                        email
                        employeeId
                        firstName
                        lastName
                        profileImage
                        team_employees {
                            Team {
                                teamName
                            }
                        }
                    }
                }
            }`;
    }
    if (leaveStatus === 'todays') {
        return gql`
            subscription DashboardLeaves {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organisationId}"}, leaveStatus: {_eq: "approved"}, _or: [
                        {
                            startDate: {_gte: "${sDate}"}, 
                            endDate: {_lte: "${eDate}"}
                        }, 
                        {
                            startDate: {_lte: "${sDate}"}, 
                            endDate: {_gte: "${eDate}"}
                        }
                    ]}) {
                    category
                    employeeId
                    endDate
                    endTime
                    leaveId
                    leaveStatus
                    organizationId
                    reason
                    startDate
                    startTime
                    leaveAppliedBy
                    approverId
                    denierId
                    denialReason
                    gmt
                    noOfHours
                    isoString
                    Employee {
                        email
                        employeeId
                        firstName
                        lastName
                        profileImage
                        team_employees {
                            Team {
                                teamName
                            }
                        }
                    }
                }
            }`;
        //     return gql`
        //         subscription DashboardLeaves {
        //             timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organisationId}"}, leaveStatus: {_eq: "approved"}}) {
        //                 category
        //                 employeeId
        //                 endDate
        //                 endTime
        //                 leaveId
        //                 leaveStatus
        //                 organizationId
        //                 reason
        //                 startDate
        //                 startTime
        //                 leaveAppliedBy
        //                 approverId
        //                 denierId
        //                 denialReason
        //                 gmt
        //                 noOfHours
        //                 isoString
        //                 Employee {
        //                     email
        //                     employeeId
        //                     firstName
        //                     lastName
        //                     profileImage
        //                     team_employees {
        //                         Team {
        //                             teamName
        //                         }
        //                     }
        //                 }
        //             }
        //         }`;
    }
    return gql`
        subscription DashboardLeaves {
            timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organisationId}"}, leaveStatus: {_eq: "approved"}, endDate: {_gt: "${eDate}"}}) {
                category
                employeeId
                endDate
                endTime
                leaveId
                leaveStatus
                organizationId
                reason
                startDate
                startTime
                leaveAppliedBy
                approverId
                denierId
                denialReason
                gmt
                noOfHours
                isoString
                Employee {
                    email
                    employeeId
                    firstName
                    lastName
                    profileImage
                    team_employees {
                        Team {
                            teamName
                        }
                    }
                }
            }
        }`;
    // return gql`
    //     subscription DashboardLeaves {
    //         timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organisationId}"}, leaveStatus: {_eq: "approved"}}) {
    //             category
    //             employeeId
    //             endDate
    //             endTime
    //             leaveId
    //             leaveStatus
    //             organizationId
    //             reason
    //             startDate
    //             startTime
    //             leaveAppliedBy
    //             approverId
    //             denierId
    //             denialReason
    //             gmt
    //             noOfHours
    //             isoString
    //             Employee {
    //                 email
    //                 employeeId
    //                 firstName
    //                 lastName
    //                 profileImage
    //                 team_employees {
    //                     Team {
    //                         teamName
    //                     }
    //                 }
    //             }
    //         }
    //     }`;
};

export const pendingLeavesForApprover = (organizationId: string, employeeId: string) => {
    return gql`
        subscription ApproverPending {
            timeoffdb_get_pendingdeniedapproverleaves(order_by: {startDate: asc}, args: {employeeid: "${employeeId}", organizationid: "${organizationId}"}, where: {leaveStatus: {_eq: "pending"}}) {
                leaveId
                category
                employeeId
                leaveAppliedBy
                reason
                leaveStatus
                noOfHours
                startDate
                endDate
                startTime
                endTime
                denierId
                denialReason
                approverId
                gmt
                isoString
                Employee {
                    employeeId
                    firstName
                    lastName
                    profileImage
                }
            }
        }
    `;
};
export const getEmployeeDashboardLeaves = (organizationId: string, employeeId: string) => {
    return gql`
        subscription EmployeeLeaves {
            timeoffdb_Leaves(order_by: {startDate: asc}, where: {employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}}) {
                organizationId
                leaveCount
                leaveStatus
                leaveId
                category
                reason
                startDate
                endDate
                leaveAppliedBy
                startTime
                endTime
                approverId
                denierId
                denialReason
                gmt
                noOfHours
                isoString
                Employee{
                    firstName
                    lastName
                    employeeId
                }
            }
        }`;
};
export const getOrgEmployees = () => {
    return gql``;
};

export const getOrgHolidays = (organizationId: string) => {
    return gql`
        subscription HolidaySubscription {
            timeoffdb_Holiday(where: {organizationId: {_eq: "${organizationId}"}}) {
                holidayId
                holidayName
                holidayDay
                isMultidayHoliday
                startDate
                endDate
            }
        }`;
};

export const fetchApproveLeave = (organizationId: string) => {
    return gql`
        subscription MyQuery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organizationId}"}, leaveStatus: {_eq: "approved"}}) {
                    leaveId
                    startDate
                    endDate
                    category
                    gmt
                    isoString
                    startTime
                    endTime
                    employeeId
                    noOfHours
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }
    `;
};
