import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './DashboardStyle';
import Modal, { PopUp } from '../Modal/Modal';
import { Button, Typography, TextField, useTheme, Chip, Checkbox, Menu, Popover, Hidden } from '@material-ui/core';
import AuthContext from '../../context/AuthContext';
import {
    // fetchLeavesForMonth,
    fetchLeaveTypesByMonthNew2,
    updateLeaveAdmin,
    updateLeaveAdminMultiple,
    fetchTimeoffStatus,
} from '../../helpers/hasuraapi';
import { toDate, toAWSDateFormat, DateDifference } from '../../helpers/date';
import { getCurrentCountryDate, isoToLocal } from 'helpers/timezone';
// import { isLeavesAvailable } from 'helpers/dateDifference';
import moment from 'moment';
import { EmployeeStatistics, Accordians, Point } from './DashboardPart';
// import Graph from '../Graph/Graph';
import ErrorPage from '../Error/ErrorPage';
import { LoaderPage } from '../Loader/loader';
import { updateActiveTab } from '../Redux/activeTab';
import { getTotalLeaveCount, getLeaveCountToday } from 'components/Redux/employeeStatusReducer';
// import {
//     sendNotificationToMembers,
//     sendNotificationToSelf,
//     sendDenyNotificationToSelf,
//     getTokenFromLeave,
// } from '../AppNotification/AppNotification';
import {
    sendNotificationToEmps,
    sendApproveNotifToSelf,
    sendDeniedNotifToSelf,
    getDenyTokenFromLeave,
    getMultiDenyTokenFromLeave,
    storeApproveNotif,
    storeDenyNotif,
} from '../WebNotification/Notification';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import { handleNotif, handleMsNotification } from 'helpers/sendNotif';
// import { isoToLocal } from 'helpers/timezone';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ArrowDropDown } from '@material-ui/icons';

const COLORS3 = [
    '#587EE0',
    '#D9250D',
    '#4FB8B2',
    '#D9821D',
    '#43DB5C',
    '#7649D6',
    '#C1206E',
    '#E9D62A',
    '#16AAB4',
    '#1740AB',
    '#05833E',
    '#2383CA',
    '#DE2208',
    '#48DC30',
    '#956FE4',
    '#F2A142',
    '#a0d568',
    '#ed5564',
    '#ac92eb',
    '#20639b',
];
const COLORS = ['#956FE4', '#587EE0', '#4FB8B2', '#F2A142', '#a0d568', '#ed5564', '#ac92eb', '#20639b'];

const COLORS2 = ['#EAA85B', '#3F9F6B'];

export default function Dashboard({ teams }: any) {
    const dispatch = useDispatch();
    const state = useSelector((state: any) => state);
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    // const [graphData, setGraphData] = useState<any>({});
    const [leaveTypes, setLeaveTypes] = useState<any>([]);

    const userSubscriptionDetails = state?.subscription.userSubscriptions;
    const totalLeaveCount = state.employeeStatus.totalPeopleCount;
    const leaveCountToday = state.employeeStatus.peopleOnLeaveToday;
    const lTypes = state.leaveTypes.lTypes;
    const orgEmployees = state.orgEmployees.employees;
    const pendingLeaves = state.dashboardLeaves?.pendingLeaves;
    const todaysLeaves = state.dashboardLeaves?.todaysLeaves;
    const upcomingLeaves = state.dashboardLeaves?.upcomingLeaves;
    const workingDay = state?.settings?.settings?.workWeek;
    // const timeOffOrgData2 = state?.orgLeaves?.orgleaves;
    const timeOffOrgData = state?.orgLeaves?.orgleaves;
    const monthsArray = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const [month, setMonth] = useState<string>(monthsArray[new Date().getMonth()]);

    const [teamList, setTeamList] = React?.useState([]);
    const [nameOpen, setNameOpen] = React?.useState(false);
    const [categories, setCategories] = React?.useState([]);
    // const [name, setName] = React?.useState([]);
    const [seeBy, setSeeBy] = React?.useState('Week');
    const [filterClick, setFilterClick] = React?.useState(false);
    const [anchorEl, setAnchorEl] = React?.useState<any>(null);

    useEffect(() => {
        setTeamList(teams?.filter((e: any) => e?.teamName === 'general'));
    }, [teams, setTeamList]);

    const handleWeek = () => {
        setSeeBy('Week');
        setFilterClick(!filterClick);
    };
    const handleMonth = () => {
        setSeeBy('Month');
        setFilterClick(!filterClick);
    };
    const handleClick = (e: any) => {
        setFilterClick(!filterClick);
        setAnchorEl(e?.currentTarget);
    };

    useEffect(() => {
        dispatch(updateActiveTab('/dashboard'));
        /* istanbul ignore next */
        const fetchData = async () => {
            // setIsLoading(true);

            const d: any = [];
            let max: any = timeOffOrgData[0]?.leaveCount;
            // //console.log(timeOffOrgData);
            lTypes.map((l: any) => {
                const a: any = [];
                timeOffOrgData.map((t: any) => {
                    if (l.leaveTypeName === t.leaveTypeName) {
                        if (t?.leaveCount > max) {
                            max = t?.leaveCount;
                        }
                        a.push({
                            teamName: t?.teamName,
                            leaves: t?.leaveCount,
                        });
                    }
                    return null;
                });
                if (a.length !== 0) {
                    d.push({
                        leaveType: l.leaveTypeName,
                        teamsLeaves: a,
                    });
                }
                return null;
            });
            // setGraphData({ data: d, max: max });
            setLeaveTypes(state.leaveTypes.leaveTypes);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.leaveTypes.leaveTypes, state.graph]);

    function getDateDay(day: string) {
        switch (day) {
            case 'sunday':
                return 0;
            case 'monday':
                return 1;
            case 'tuesday':
                return 2;
            case 'wednesday':
                return 3;
            case 'thursday':
                return 4;
            case 'friday':
                return 5;
            default:
                return 6;
        }
    }

    const [graphData, setGraphData] = React.useState<any>([]);

    const filterChecking = (arr: any, name: any, id: any) => {
        for (let i = 0; i < arr?.length; i++) {
            if (arr[i]?.teamName === name) {
                for (let j = 0; j < arr[i]?.team_employees?.length; j++) {
                    if (arr[i]?.team_employees[j]?.Employee?.employeeId === id) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    //console.log(state)

    useMemo(() => {
        const now = new Date(isoToLocal(new Date().toISOString(), mycontext.country));
        const weekDate = new Date(now?.setDate(now?.getDate() - now?.getDay() + 1));
        const weekEndDate = new Date(now?.setDate(now?.getDate() - now?.getDay() + 7));
        const monthDate = new Date(now?.getFullYear(), now?.getMonth(), 2);
        const monthEndDate = new Date(now?.getFullYear(), now?.getMonth() + 1, 1);

        function getDatesInRange(startDate: any, endDate: any, workingDayObject: any) {
            const date = new Date(startDate);
            const dates = [];
            while (date.getTime() <= new Date(endDate).getTime()) {
                dates?.push(new Date(date)?.toString().slice(0, 15));
                date?.setDate(date?.getDate() + 1);
            }
            return dates;
        }

        function teamGraphData(startDate: any, endDate: any, slicer: any) {
            //console.log(startDate, endDate)
            const workingDayObject: any = {};
            workingDay?.map((day: any) => {
                workingDayObject[day[0].toUpperCase() + day.slice(1, 3)] = getDateDay(day);
            });

            const weekRange = getDatesInRange(startDate, endDate, workingDayObject);
            const check2 = {};
            weekRange.forEach((date: string, index: number) => {
                if (Object.values(workingDayObject).includes(new Date(date)?.getDay())) {
                    check2[new Date(date).toString().slice(0, 15)] = [];
                }
                return null;
            });

            timeOffOrgData.map((e: any) => {
                if (e.noOfHours > mycontext.workingHours) {
                    const dates = getDatesInRange(e.isoString.startTime, e.isoString.endTime, workingDayObject);
                    dates.forEach((date: string, index: number) => {
                        if (
                            check2[
                                isoToLocal(date, mycontext.country)
                                    .toString()
                                    .slice(0, 15)
                            ]?.filter((f: any, index: number) => e?.employeeId === f?.employeeId).length > 0
                        ) {
                            const element = check2[
                                isoToLocal(date, mycontext.country)
                                    ?.toString()
                                    ?.slice(0, 15)
                            ]?.find((f: any, index: number) => e?.employeeId === f?.employeeId);
                            const index = check2[
                                isoToLocal(date, mycontext.country)
                                    ?.toString()
                                    ?.slice(0, 15)
                            ]?.findIndex((f: any, index: number) => e?.employeeId === f?.employeeId);
                            if (element?.noOfHours < e?.noOfHours) {
                                check2[
                                    isoToLocal(date, mycontext.country)
                                        .toString()
                                        .slice(0, 15)
                                ].splice(index);
                                check2[
                                    isoToLocal(date, mycontext.country)
                                        .toString()
                                        .slice(0, 15)
                                ]?.push(e);
                            }
                        } else {
                            check2[
                                isoToLocal(date, mycontext.country)
                                    .toString()
                                    .slice(0, 15)
                            ]?.push(e);
                        }
                    });
                } else if (
                    check2[
                        isoToLocal(e?.isoString?.startTime, mycontext.country)
                            .toString()
                            .slice(0, 15)
                    ] &&
                    Object.values(workingDayObject).includes(
                        isoToLocal(e.isoString.startTime, mycontext.country)?.getDay(),
                    )
                ) {
                    if (
                        check2[
                            isoToLocal(e?.isoString?.startTime, mycontext.country)
                                .toString()
                                .slice(0, 15)
                        ].filter((f: any, index: number) => e.employeeId === f.employeeId).length > 0
                    ) {
                        const element = check2[
                            isoToLocal(e?.isoString?.startTime, mycontext.country)
                                .toString()
                                .slice(0, 15)
                        ].find((f: any, index: number) => e.employeeId === f.employeeId);
                        const index = check2[
                            isoToLocal(e?.isoString?.startTime, mycontext.country)
                                .toString()
                                .slice(0, 15)
                        ].findIndex((f: any, index: number) => e.employeeId === f.employeeId);
                        if (element.noOfHours < e.noOfHours) {
                            check2[
                                isoToLocal(e?.isoString?.startTime, mycontext.country)
                                    .toString()
                                    .slice(0, 15)
                            ].splice(index);
                            check2[
                                isoToLocal(e?.isoString?.startTime, mycontext.country)
                                    .toString()
                                    .slice(0, 15)
                            ]?.push(e);
                        }
                    } else {
                        check2[
                            isoToLocal(e?.isoString?.startTime, mycontext.country)
                                .toString()
                                .slice(0, 15)
                        ]?.push(e);
                    }
                }
                return null;
            });

            //console.log(check2)

            const teamWeekData: any = [];
            Object.keys(check2)?.forEach((element: string, i: number) => {
                teamWeekData[i] = { day: element.slice(slicer[0], slicer[1]) };
                teams?.map((e: any) => (teamWeekData[i][`${e?.teamName}`] = 100));
                teamList?.forEach((f: any) =>
                    check2[element]
                        ?.filter((e: any) => filterChecking(teamList, f?.teamName, e?.employeeId))
                        ?.map((e: any) => {
                            if (e.noOfHours >= mycontext.workingHours) {
                                teamWeekData[i][`${f?.teamName}`] -= Math.round(
                                    (mycontext.workingHours / (f?.team_employees.length * mycontext.workingHours)) *
                                        100,
                                );
                            } else {
                                teamWeekData[i][`${f?.teamName}`] -= Math.round(
                                    (e.noOfHours / (f?.team_employees.length * mycontext.workingHours)) * 100,
                                );
                            }
                        }),
                );
            });
            setGraphData(teamWeekData);
        }

        function categoriesGraphData(startDate: any, endDate: any, slicer: any) {
            //console.log(startDate, endDate)
            const CategoryMonthData: any = [];
            const workingDayObject: any = {};
            workingDay?.map((day: any) => {
                workingDayObject[day[0].toUpperCase() + day.slice(1, 3)] = getDateDay(day);
            });
            const monthRange = getDatesInRange(startDate, endDate, workingDayObject);
            const check2 = {};
            monthRange.forEach((date: string, index: number) => {
                if (Object.values(workingDayObject).includes(new Date(date)?.getDay())) {
                    check2[new Date(date).toString().slice(0, 15)] = [];
                }
                return null;
            });

            timeOffOrgData.map((e: any) => {
                if (e.noOfHours > mycontext.workingHours) {
                    const dates = getDatesInRange(e.isoString.startTime, e.isoString.endTime, workingDayObject);
                    // //console.log(dates)
                    dates.forEach((date: string, index: number) => {
                        if (
                            check2[
                                isoToLocal(date, mycontext.country)
                                    .toString()
                                    .slice(0, 15)
                            ] &&
                            Object.values(workingDayObject).includes(isoToLocal(date, mycontext.country)?.getDay())
                        ) {
                            check2[
                                isoToLocal(date, mycontext.country)
                                    .toString()
                                    .slice(0, 15)
                            ].push(e);
                        }
                    });
                } else if (
                    check2[
                        isoToLocal(e?.isoString?.startTime, mycontext.country)
                            .toString()
                            .slice(0, 15)
                    ] &&
                    Object.values(workingDayObject).includes(
                        isoToLocal(e.isoString.startTime, mycontext.country)?.getDay(),
                    )
                ) {
                    check2[
                        isoToLocal(e?.isoString?.startTime, mycontext.country)
                            .toString()
                            .slice(0, 15)
                    ].push(e);
                }
                return null;
            });

            Object.keys(check2)?.forEach((element: string, i: number) => {
                CategoryMonthData[i] = { day: element.slice(slicer[0], slicer[1]) };
                categories?.map((e: any) => (CategoryMonthData[i][`${e?.leaveTypeName}`] = 0));
                categories?.forEach(
                    (f: any) =>
                        (CategoryMonthData[i][f?.leaveTypeName] = check2[element]?.filter(
                            (e: any) => f?.leaveTypeName === e?.category,
                        )?.length),
                );
            });
            setGraphData(CategoryMonthData);
        }

        if (seeBy === 'Month') {
            if (categories.length > 0) {
                categoriesGraphData(monthDate.toISOString().slice(0, 10), monthEndDate.toISOString().slice(0, 10), [
                    8,
                    10,
                ]);
            } else {
                teamGraphData(monthDate.toISOString().slice(0, 10), monthEndDate.toISOString().slice(0, 10), [8, 10]);
            }
        } else {
            if (categories.length > 0) {
                categoriesGraphData(weekDate, weekEndDate, [0, 3]);
            } else {
                teamGraphData(weekDate, weekEndDate, [0, 3]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mycontext.country, timeOffOrgData, teams, workingDay, teamList, mycontext.workingHours, seeBy, categories]);

    //console.log(graphData)

    const fetchLeaveTypeLeaves = async (month: any) => {
        const d = new Date().setMonth(monthsArray.indexOf(month));
        const lTypes = await fetchLeaveTypesByMonthNew2(
            mycontext.organizationId,
            mycontext.token,
            toDate(
                moment(d)
                    .clone()
                    .startOf('month')
                    .startOf('day'),
            ),
            toDate(
                moment(d)
                    .clone()
                    .endOf('month')
                    .endOf('day'),
            ),
        );
        if (lTypes?.error) {
            setLeaveTypes(state.leaveTypes.leaveTypes);
        } else {
            const po: any = [];
            let ar1 = lTypes?.timeoffdb_get_MonthlyLeaves?.concat(lTypes?.timeoffdb_get_leavetype_count4) || [];
            lTypes?.timeoffdb_get_MonthlyLeaves?.map((p: any) => {
                lTypes?.timeoffdb_get_leavetype_count4?.map((c: any) => {
                    if (c.name === p.name) {
                        po.push({
                            name: c.name,
                            value: c.value + p.value,
                        });
                        ar1 = ar1.filter((i: any) => i.name !== c.name);
                    }
                });
            });
            setLeaveTypes([...po, ...ar1]);
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => fetchLeaveTypeLeaves(month), [month]);

    const data2 = [
        { name: 'Time-Off', value: leaveCountToday },
        { name: 'Active', value: totalLeaveCount - leaveCountToday },
    ];
    const [openA, setOpenA] = useState(false);
    const [openC, setOpenC] = useState(false);

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('');

    const [currentLeave, setCurrentLeave] = useState<any>({});
    const [multipleLeave, setMultipleLeave] = useState<any>({});

    const handleApprove = async (leave: any) => {
        const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
        try {
            // if (
            //     isLeavesAvailable(
            //         state.formDetails?.details,
            //         mycontext.workingHours,
            //         leave?.category,
            //         leave?.Employee.employeeId,
            //     )
            // ) {
            //     throw new Error("User don't have sufficient leaves!");
            // }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error("You don't have subscription for timeoff!");
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error("You don't have subscription for timeoff!");
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error('You dont have a license assigned to you. Please contact to your IT admin.');
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error('Your subscription has been suspended. Please contact to your IT admin.');
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error('Your subscription is expired. Please ask your IT admin to renew the plan.');
            }
            const obj = {
                leaveId: leave.leaveId,
                approverId: mycontext.EmployeeId,
                denialReason: null,
                denierId: mycontext.EmployeeId,
                leaveStatus: 'approved',
                token: mycontext.token,
            };
            const resp = await updateLeaveAdmin(obj, mycontext.isAdmin);
            if (resp.errors) {
                setAlert(true);
                setAlertMessage('Failed to approved leave!');
                setSeverity('error');
                return;
            }

            setAlert(true);
            setAlertMessage('Request approved successfully!');
            setSeverity('success');
            // await sendNotificationToMembers(
            //     leave.employeeId,
            //     leave.Employee.firstName,
            //     leave.startDate,
            //     leave.endDate,
            //     leave.startTime,
            //     leave.endTime,
            //     mycontext.organizationId,
            //     mycontext.token,
            // );
            // await sendNotificationToSelf(
            //     leave.employeeId,
            //     leave.startDate,
            //     leave.endDate,
            //     leave.startTime,
            //     leave.endTime,
            //     mycontext.token,
            // );

            //web notification
            const dateString = new Date().toString();
            const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
            const time = new Date().toString().slice(16, 24);
            const dateTime = date + time;

            // //console.log('leave employee: ', leave.Employee.firstName, leave.Employee.lastName);
            const notifObj = {
                startDate: leave.startDate,
                endDate: leave.endDate,
                startTime: leave.startTime,
                endTime: leave.endTime,
                fname: leave.Employee.firstName,
                lname: leave.Employee.lastName,
                afname: mycontext.fname,
                alname: mycontext.lname,
                empId: leave.employeeId,
                leaveId: leave.leaveId,
                orgId: mycontext.organizationId,
                notifDate: dateTime,
                token: mycontext.token,
                approverId: mycontext.EmployeeId,
            };
            await storeApproveNotif(notifObj);
            // handleNotification({
            //     empId: leave.employeeId,
            //     actorId: mycontext.EmployeeId,
            //     orgId: mycontext.organizationId,
            //     leaveId: leave.leaveId,
            //     token: mycontext.token,
            //     startDate: leave.startDate,
            //     endDate: leave.endDate,
            //     startTime: leave.startTime,
            //     endTime: leave.endTime,
            //     isoString: leave.isoString,
            //     category: resp.category,
            //     notif_type: 'approveRequest',
            // });
            handleMsNotification({
                empId: leave.employeeId,
                actorId: mycontext.EmployeeId,
                orgId: mycontext.organizationId,
                leaveId: leave.leaveId,
                token: mycontext.token,
                startDate: leave.startDate,
                endDate: leave.endDate,
                startTime: leave.startTime,
                endTime: leave.endTime,
                isoString: leave.isoString,
                category: resp.category,
                notif_type: 'approveRequest',
            });
            sendNotificationToEmps(
                leave.employeeId,
                leave.Employee.firstName,
                leave.Employee.lastName,
                leave.startDate,
                leave.endDate,
                leave.startTime,
                leave.endTime,
                mycontext.organizationId,
                mycontext.token,
            );
            sendApproveNotifToSelf(
                leave.employeeId,
                mycontext.organizationId,
                mycontext.token,
                leave.startDate,
                leave.endDate,
                leave.startTime,
                leave.endTime,
                mycontext.fname,
                mycontext.lname,
            );

            setTimeout(() => {
                if (leave.startDate <= toAWSDateFormat(new Date()) && leave.endDate >= toAWSDateFormat(new Date())) {
                    dispatch(
                        getTotalLeaveCount({
                            organizationId: mycontext.organizationId,
                            token: mycontext.token,
                        }),
                    );
                    dispatch(
                        getLeaveCountToday({
                            organizationId: mycontext.organizationId,
                            token: mycontext.token,
                            date: toAWSDateFormat(new Date()),
                        }),
                    );
                }
            }, 1000);
        } catch (err) {
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    const handleDeny = (leave: any) => {
        setCurrentLeave(leave);
        setOpenA(true);
    };

    const handleDenyAll = (leave: any) => {
        setMultipleLeave(leave);
        setOpenC(true);
    };

    const handleModal = async (reason: string) => {
        const leaveData = await fetchTimeoffStatus(currentLeave.leaveId, mycontext.token);

        let prevStatus = 'pending';

        if (leaveData !== null) {
            prevStatus = leaveData[0].leaveStatus;
        }
        const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
        try {
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const obj = {
                leaveId: currentLeave.leaveId,
                approverId: mycontext.EmployeeId,
                denialReason: reason.split('\n').join(' '),
                denierId: mycontext.EmployeeId,
                leaveStatus: 'denied',
                token: mycontext.token,
            };
            const resp = await updateLeaveAdmin(obj, mycontext.isAdmin);
            if (resp.errors) {
                setOpenA(false);
                setAlert(true);
                setAlertMessage('Failed to deny leave!');
                setSeverity('error');
                return;
            }

            setOpenA(false);
            setAlert(true);
            setAlertMessage(`${currentLeave.category} Request denied successfully!`);
            // await sendDenyNotificationToSelf(
            //     currentLeave.employeeId,
            //     currentLeave.startDate,
            //     currentLeave.endDate,
            //     currentLeave.startTime,
            //     currentLeave.endTime,
            //     mycontext.token,
            // );
            setSeverity('success');

            //web notification
            const dateString = new Date().toString();
            const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
            const time = new Date().toString().slice(16, 24);
            const dateTime = date + time;

            const notifObj = {
                startDate: currentLeave.startDate,
                endDate: currentLeave.endDate,
                startTime: currentLeave.startTime,
                endTime: currentLeave.endTime,
                fname: mycontext.fname,
                lname: mycontext.lname,
                empId: currentLeave.employeeId,
                leaveId: currentLeave.leaveId,
                orgId: mycontext.organizationId,
                notifDate: dateTime,
                token: mycontext.token,
                approverId: mycontext.EmployeeId,
            };
            await storeDenyNotif(notifObj);
            // handleNotification({
            //     empId: currentLeave.employeeId,
            //     actorId: mycontext.EmployeeId,
            //     orgId: mycontext.organizationId,
            //     leaveId: currentLeave.leaveId,
            //     token: mycontext.token,
            //     startDate: currentLeave.startDate,
            //     endDate: currentLeave.endDate,
            //     startTime: currentLeave.startTime,
            //     endTime: currentLeave.endTime,
            //     category: currentLeave.category,
            //     isoString: currentLeave.isoString,
            //     prevStatus: prevStatus,
            //     notif_type: 'denyRequest',
            //     action: 'deny',
            // });
            sendDeniedNotifToSelf(
                currentLeave.employeeId,
                mycontext.organizationId,
                mycontext.token,
                currentLeave.startDate,
                currentLeave.endDate,
                currentLeave.startTime,
                currentLeave.endTime,
                mycontext.fname,
                mycontext.lname,
            );
            handleMsNotification({
                empId: currentLeave.employeeId,
                actorId: mycontext.EmployeeId,
                orgId: mycontext.organizationId,
                leaveId: currentLeave.leaveId,
                token: mycontext.token,
                startDate: currentLeave.startDate,
                endDate: currentLeave.endDate,
                startTime: currentLeave.startTime,
                endTime: currentLeave.endTime,
                category: currentLeave.category,
                isoString: currentLeave.isoString,
                prevStatus: prevStatus,
                notif_type: 'denyRequest',
            });
            setTimeout(() => {
                if (
                    currentLeave.startDate <= toAWSDateFormat(new Date()) &&
                    currentLeave.endDate >= toAWSDateFormat(new Date())
                ) {
                    dispatch(
                        getTotalLeaveCount({
                            organizationId: mycontext.organizationId,
                            token: mycontext.token,
                        }),
                    );
                    dispatch(
                        getLeaveCountToday({
                            organizationId: mycontext.organizationId,
                            token: mycontext.token,
                            date: toAWSDateFormat(new Date()),
                        }),
                    );
                }
            }, 1000);
        } catch (err) {
            setOpenA(false);
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    /* istanbul ignore next */
    const DenyTimeOff = () => {
        const [reason, setReason] = useState('');
        const theme = useTheme();
        return (
            <div style={{ paddingTop: 20 }}>
                <p style={{ color: theme.palette.text.primary, fontFamily: 'Nunito Sans', fontSize: 14 }}>
                    Are you sure you want to Deny the Time-off request ?
                </p>
                <div className={classes.denyModalContent}>
                    <Typography
                        style={{
                            marginRight: 10,
                            marginTop: 15,
                            color: theme.palette.text.primary,
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightBold,
                            fontSize: 14,
                        }}
                    >
                        Reason (optional):
                    </Typography>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={3}
                        value={reason}
                        onChange={(e: any) => setReason(e.target.value)}
                        placeholder="Type your reason here"
                        variant="outlined"
                        className={classes.textDeco}
                        style={{ flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenA(false)}
                        className={classes.deleteButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="denyRequest"
                        variant="contained"
                        color="primary"
                        className={classes.createBtn}
                        onClick={() => handleModal(reason)}
                    >
                        Deny
                    </Button>
                </div>
            </div>
        );
    };

    const handlePopup = (severity: string, message: string): void => {
        setAlert(true);
        setSeverity(severity);
        setAlertMessage(message);
    };

    const handleDenyMultiple = async (obj: any) => {
        const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
        try {
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const resp = await updateLeaveAdminMultiple(obj, mycontext.isAdmin);
            if (resp.errors) {
                setOpenC(false);
                handlePopup('error', 'Failed to denied leaves!');
            } else {
                setOpenC(false);
                handlePopup('success', 'All Leaves are denied successfuly!');
                // handleNotif({
                //     response: resp,
                //     requestFor: 'slack',
                //     user: {
                //         name: mycontext.fname + ' ' + mycontext.lname,
                //         type: 'name',
                //     },
                //     orgId: mycontext.organizationId,
                //     requestType: 'deny',
                // });
                handleNotif({
                    response: resp,
                    requestFor: 'msteams',
                    prevStatus: obj.prevStatus,
                    user: mycontext.EmployeeId,
                    token: mycontext.token,
                    orgId: mycontext.organizationId,
                    requestType: 'deny',
                });
                obj.leaveIds.map(async (leave: any) => {
                    // await getTokenFromLeave(leave, mycontext.token);
                    await getMultiDenyTokenFromLeave(
                        leave,
                        mycontext.token,
                        mycontext.fname,
                        mycontext.lname,
                        mycontext.organizationId,
                        mycontext.EmployeeId,
                    );
                    await getDenyTokenFromLeave(leave, mycontext.token, mycontext.fname, mycontext.lname);
                    return null;
                });
            }
        } catch (err) {
            setOpenC(false);
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    const DenyTimeOffMultiple = () => {
        const [reason, setReason] = useState('');
        const theme = useTheme();
        return (
            <div style={{ paddingTop: 20 }}>
                <p style={{ color: theme.palette.text.primary, fontFamily: 'Nunito Sans', fontSize: 14 }}>
                    Are you sure you want to Deny the Time-off request ?
                </p>
                <div className={classes.denyModalContent}>
                    <Typography
                        style={{
                            marginRight: 10,
                            marginTop: 15,
                            color: theme.palette.text.primary,
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightBold,
                            fontSize: 14,
                        }}
                    >
                        Reason (optional):
                    </Typography>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={3}
                        value={reason}
                        onChange={(e: any) => setReason(e.target.value)}
                        placeholder="Type your reason here"
                        variant="outlined"
                        style={{ flex: 1 }}
                        className={classes.textDeco}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenC(false)}
                        className={classes.deleteButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="denyRequest"
                        variant="contained"
                        color="primary"
                        className={classes.createBtn}
                        onClick={() =>
                            handleDenyMultiple({ ...multipleLeave, denialReason: reason.split('\n').join(' ') })
                        }
                    >
                        Deny
                    </Button>
                </div>
            </div>
        );
    };

    if (
        state.employeeStatus.status === 'loading' ||
        state.pendingActions.status === 'loading' ||
        state.leaveTypes.status === 'loading' ||
        state.orgEmployees.status === 'loading' ||
        state.dashboardLeaves?.pstatus === 'loading' ||
        state.dashboardLeaves?.tstatus === 'loading' ||
        state.dashboardLeaves?.ustatus === 'loading'
        // props.pending.loading ||
        // props.todays.loading ||
        // props.upcoming.loading
    ) {
        return <LoaderPage />;
    }
    if (
        state.employeeStatus.status === 'failed' ||
        state.pendingActions.status === 'failed' ||
        state.leaveTypes.status === 'failed' ||
        state.orgEmployees.status === 'failed' ||
        state.dashboardLeaves?.pstatus === 'failed' ||
        state.dashboardLeaves?.tstatus === 'failed' ||
        state.dashboardLeaves?.ustatus === 'failed'
        // props.pending.error ||
        // props.todays.error ||
        // props.upcoming.error
    ) {
        return <ErrorPage title="Server Down" />;
    }
    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        <SubscriptionHardExpired
            content="Looks like your subscription is expired, Please renew it !"
            showRenew={true}
        />
    ) : (
        <>
            <Modal open={openA} setOpen={setOpenA} onModalClosed={setOpenA} title="Deny Time-Off Request">
                <DenyTimeOff />
            </Modal>
            <Modal open={openC} setOpen={setOpenC} onModalClosed={setOpenC} title="Deny Multiple Time-off Request">
                <DenyTimeOffMultiple />
            </Modal>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <div className={classes.mainDiv}>
                <div className={classes.leftDiv}>
                    <EmployeeStatistics
                        data={leaveTypes || []}
                        COLORS={COLORS}
                        data2={data2}
                        COLORS2={COLORS2}
                        month={month}
                        setMonth={setMonth}
                        lTypes={lTypes}
                        todayTimeoff={
                            todaysLeaves.filter(
                                (l: any) =>
                                    DateDifference(
                                        isoToLocal(l.isoString.startTime, mycontext.country),
                                        getCurrentCountryDate(mycontext.country.zoneName),
                                    ) >= 0 &&
                                    DateDifference(
                                        getCurrentCountryDate(mycontext.country.zoneName),
                                        isoToLocal(l.isoString.endTime, mycontext.country),
                                    ) > 0,
                            ) || []
                        }
                        // data-testid="emp"
                    />
                    <div className={classes.graphLeft}>
                        {/* <Graph3
                            graphData={graphData}
                            isLoading={isLoading}
                            graphY={graphY}
                            timeOffOrgData={timeOffOrgData}
                        /> */}
                        {/* <Graph graphData={graphData} /> */}

                        <div className={classes.teamPerformanceDiv}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    paddingTop: '3px',
                                }}
                            >
                                <Hidden mdDown>
                                    <div className={classes.accordionTitle}>Performance</div>
                                </Hidden>
                                <div>
                                    <Button className={classes.filterButtons}>
                                        <AutoTeams
                                            teamList={teamList}
                                            setTeamList={setTeamList}
                                            open={nameOpen}
                                            setOpen={setNameOpen}
                                            teams={teams}
                                            button={false}
                                            small={true}
                                        />
                                    </Button>
                                    <Button className={classes.filterButtons}>
                                        <AutoTimeoffCategory
                                            categories={categories}
                                            setCategories={setCategories}
                                            open={nameOpen}
                                            setOpen={setNameOpen}
                                            allCategory={lTypes}
                                            button={false}
                                            small={true}
                                        />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className={classes.filterButtons}
                                        onClick={handleClick}
                                        disableElevation
                                        endIcon={<ArrowDropDown />}
                                    >
                                        {seeBy}
                                    </Button>
                                    <Popover
                                        id="picker-popover"
                                        open={filterClick}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        onClose={handleClick}
                                        data-testid="filterModal"
                                    >
                                        <ul className={classes.filterList}>
                                            <li onClick={handleWeek} className={classes.filterName}>
                                                This Week
                                            </li>
                                            <li onClick={handleMonth} className={classes.filterName}>
                                                This Month
                                            </li>
                                        </ul>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                        <Hidden lgUp>
                            <div className={classes.accordionTitle}>Performance</div>
                        </Hidden>
                        <TeamGraph
                            teams={teams}
                            teamList={teamList}
                            timeoff={timeOffOrgData}
                            employee={orgEmployees}
                            seeBy={seeBy}
                            setSeeBy={setSeeBy}
                            categories={categories}
                            workingDay={workingDay}
                            holidays={true}
                            graphData={graphData}
                        />
                    </div>
                </div>
                <Accordians
                    // pendingLeaves={props.pending.data.timeoffdb_Leaves || []}
                    // todaysLeaves={props.todays.data.timeoffdb_Leaves || []}
                    // upcomingLeaves={props.upcoming.data.timeoffdb_Leaves || []}
                    pendingLeaves={pendingLeaves || []}
                    todaysLeaves={
                        todaysLeaves.filter(
                            (l: any) =>
                                DateDifference(
                                    isoToLocal(l.isoString.startTime, mycontext.country),
                                    getCurrentCountryDate(mycontext.country.zoneName),
                                ) >= 0 &&
                                DateDifference(
                                    getCurrentCountryDate(mycontext.country.zoneName),
                                    isoToLocal(l.isoString.endTime, mycontext.country),
                                ) > 0,
                        ) || []
                    }
                    upcomingLeaves={
                        upcomingLeaves ||
                        // .filter(
                        //     (l: any) =>
                        //         DateDifference(
                        //             getCurrentCountryDate(mycontext.country.zoneName),
                        //             isoToLocal(l.isoString?.endTime, mycontext.country),
                        //         ) > 1,
                        // )
                        []
                    }
                    orgEmployees={orgEmployees || []}
                    COLORS={COLORS}
                    data={lTypes}
                    handleApprove={handleApprove}
                    handleDeny={handleDeny}
                    handleModal={handleModal}
                    isDashboard={true}
                    handlePopup={handlePopup}
                    handleDenyAll={handleDenyAll}
                    userSubscriptionDetail={userSubscriptionDetails?.usermanagementdb_tenant_employee[0]}
                />
            </div>
        </>
    );
}

export const AutoTeams = (props: {
    open?: boolean;
    setOpen?: any;
    teams?: any;
    teamList?: any;
    setTeamList?: any;
    button?: boolean;
    small?: boolean;
}) => {
    const [profileMenu, setProfileMenu] = React.useState<any>(null);
    const classes = useStyles();
    const theme = useTheme();
    // const [teamMember, setTeamMember] = React.useState<any>([]);
    // const [val, setVal] = React.useState<any>([]);
    const handleProfileMenuClose = () => {
        setProfileMenu(null);
    };

    //  // // //console.log(props.allEmployees);
    const handleChange = (event: any, value: any) => {
        // if (props.button) {
        //     const data = value;
        //     const index = props?.teamList?.indexOf(data);
        //     if (index > -1 && data.teamName !== 'general') {
        //         props?.setTeamList([...props?.teamList?.slice(0, index), ...props?.teamList?.slice(index + 1)]);
        //     } else {
        //         props?.setTeamList([...props?.teamList, ...[data]]);
        //     }
        // } else {
        const data = value;
        const index = props?.teamList?.indexOf(data);
        if (index > -1 && data.teamName !== 'general') {
            props?.setTeamList([...props?.teamList?.slice(0, index), ...props?.teamList?.slice(index + 1)]);
        } else if (data.teamName === 'general') {
            return;
        } else {
            props?.setTeamList([...props?.teamList, ...[data]]);
        }
        // }
    };

    //  // // //console.log(val)
    return (
        <div>
            <Menu
                anchorEl={profileMenu}
                open={Boolean(profileMenu)}
                onClose={handleProfileMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                // PaperProps={{ className: classes.popoverPropsTwo }}
                style={{ transform: 'translateY(-5px)' }}
            >
                <ul style={{ width: 110 }} className={classes.filterList}>
                    {props?.teams?.map((a: any, i: number) => (
                        <li
                            key={i}
                            className={classes.filterName2}
                            onClick={(e: any) => handleChange(e, a)}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <Checkbox
                                style={{ height: '0px', width: '0px', transform: 'scale(0.8)', marginRight: '3px' }}
                                value={a}
                                checked={props?.teamList?.includes(a)}
                            />
                            <span>{a?.teamName}</span>
                        </li>
                    ))}
                </ul>
            </Menu>
            <div
                className={props.small === undefined ? classes.test : classes.test2}
                onClick={(e: any) => setProfileMenu(e.currentTarget)}
            >
                <div style={{ width: '100%', display: 'flex', paddingLeft: '2px' }}>
                    {props?.teamList?.length === 0 || props.small !== undefined ? (
                        <div style={{ paddingLeft: '7px', color: theme.palette.text.primary }}>Teams</div>
                    ) : (
                        <div>
                            {props?.teamList?.map((e: any, i: number) =>
                                i < 2 && props.small === undefined ? (
                                    <Chip key={i} style={{ height: '25px' }} label={e?.teamName} />
                                ) : null,
                            )}
                        </div>
                    )}
                </div>
                <div>
                    <ArrowDropDown />
                </div>
            </div>
        </div>
    );
};

export const AutoTimeoffCategory = (props: {
    open?: boolean;
    setOpen?: any;
    allCategory?: any;
    categories?: any;
    setCategories?: any;
    button?: boolean;
    small?: boolean;
}) => {
    const [profileMenu, setProfileMenu] = React.useState<any>(null);
    const classes = useStyles();
    const theme = useTheme();
    // const [teamMember, setTeamMember] = React.useState<any>([]);
    // const [val, setVal] = React.useState<any>([]);
    const handleProfileMenuClose = () => {
        setProfileMenu(null);
    };

    const handleChange = (event: any, value: any) => {
        const data = value;
        const index = props?.categories?.indexOf(data);
        if (index > -1) {
            props?.setCategories([...props?.categories?.slice(0, index), ...props?.categories?.slice(index + 1)]);
        } else {
            props?.setCategories([...props?.categories, ...[data]]);
        }
        // }
    };

    //  // // //console.log(val)
    return (
        <div>
            <Menu
                anchorEl={profileMenu}
                open={Boolean(profileMenu)}
                onClose={handleProfileMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                // PaperProps={{ className: classes.popoverPropsTwo }}
                style={{ transform: 'translateY(-5px)' }}
            >
                <ul style={{ width: 110 }} className={classes.filterList}>
                    {props?.allCategory?.map((a: any, i: number) => (
                        <li
                            key={i}
                            className={classes.filterName2}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                            onClick={(e: any) => handleChange(e, a)}
                        >
                            {/* <Avatar
                                src={a?.Employee?.profileImage}
                                style={{
                                    fontSize: '12px',
                                    color: 'black',
                                    background: '#EDEFFA',
                                    width: 26,
                                    height: 26,
                                    marginRight: 5,
                                }}
                            >
                                {getFullName(a?.Employee?.firstName + ' ' + a?.Employee?.lastName)}
                            </Avatar> */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    style={{ height: '0px', width: '0px', transform: 'scale(0.8)', marginRight: '3px' }}
                                    value={a}
                                    checked={props?.categories?.includes(a)}
                                />
                                <span>{a?.leaveTypeName}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </Menu>
            <div
                className={props.small === undefined ? classes.test : classes.test2}
                onClick={(e: any) => setProfileMenu(e.currentTarget)}
            >
                <div style={{ width: '100%', display: 'flex', paddingLeft: '2px' }}>
                    {props?.categories?.length === 0 || props.small !== undefined ? (
                        <div style={{ paddingLeft: '7px', color: theme.palette.text.primary }}>Timeoff</div>
                    ) : (
                        <div>
                            {props?.categories?.map((e: any, i: number) =>
                                i < 2 ? <Chip key={i} style={{ height: '25px' }} label={e?.leaveTypeName} /> : null,
                            )}
                        </div>
                    )}
                </div>
                <div>
                    <ArrowDropDown />
                </div>
            </div>
        </div>
    );
};

interface GraphProps {
    timeoff: any;
    teams: any;
    employee: any;
    teamList: any;
    seeBy: any;
    setSeeBy: any;
    categories: any;
    workingDay: any;
    holidays: any;
    graphData: any;

    // nameList: any;
}

const TeamGraph = (props: GraphProps) => {
    const theme = useTheme();
    const classes = useStyles();
    // //console.log(props.graphData)

    return (
        <div className={classes.graphDiv1}>
            <div className={classes.graph}>
                {/* <Grid container> */}
                {props.categories.length === 0 ? (
                    <div className={classes.graphXAxis2}> Availability % </div>
                ) : (
                    <div className={classes.graphXAxis}> No. of Timeoff </div>
                )}
                {
                    <ResponsiveContainer width="100%" height="95%">
                        <LineChart data={props?.graphData} margin={{ right: 40, top: 20 }}>
                            <XAxis stroke={theme.palette.text.primary} dataKey={'day'} interval={'preserveStartEnd'} />
                            <YAxis stroke={theme.palette.text.primary} />
                            <Tooltip />
                            {props?.categories?.length === 0
                                ? props?.teamList?.map((e: any, i: any) => (
                                      <Line
                                          // type="monotone"
                                          key={i}
                                          dataKey={e?.teamName}
                                          stroke={COLORS3[i]}
                                          strokeWidth={2}
                                      />
                                  ))
                                : props?.categories?.map((e: any, i: any) => (
                                      <Line
                                          key={i}
                                          type="monotone"
                                          dataKey={e?.leaveTypeName}
                                          stroke={COLORS3[i]}
                                          strokeWidth={2}
                                      />
                                  ))}
                        </LineChart>
                    </ResponsiveContainer>
                }
                {/* </Grid> */}
                {/* </Grid>
                </Hidden> */}
                {/* <Hidden mdUp>
                    {
                        <ResponsiveContainer width="100%" aspect={1.6}>
                            <LineChart
                                data={
                                    props?.graphData
                                }
                                margin={{ left: -25, top: 20 }}
                            >
                                <XAxis
                                    stroke={theme.palette.text.primary}
                                    dataKey={'day'}
                                // interval={'preserveStartEnd'}
                                />
                                <YAxis stroke={theme.palette.text.primary} />
                                <Tooltip />

                                {props?.categories?.length === 0
                                    ? props?.teamList?.map((e: any, i: any) => (
                                        <Line
                                            // type="monotone"
                                            key={i}
                                            dataKey={e?.teamName}
                                            stroke={COLORS3[i]}
                                            strokeWidth={2}
                                        />
                                    ))
                                    : props?.categories?.map((e: any, i: any) => (
                                        <Line
                                            key={i}
                                            type="monotone"
                                            dataKey={e?.leaveTypeName}
                                            stroke={COLORS3[i]}
                                            strokeWidth={2}
                                        />
                                    ))}
                            </LineChart>
                        </ResponsiveContainer>
                    }
                </Hidden> */}
            </div>
            {/* <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // marginTop: '10px',
                    // marginBottom: '5px',
                    color: theme.palette.text.primary,
                }}
            >
                {`${props.seeBy}'s day`}
            </div> */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {props?.categories?.length === 0
                    ? props?.teamList?.map((e: any, i: number) => (
                          <div key={i} style={{ marginRight: '12px', display: 'inline-block' }}>
                              <Point title={e?.teamName} colors={COLORS3[i]} />
                          </div>
                      ))
                    : props?.categories?.map((e: any, i: number) => (
                          <div key={i} style={{ marginRight: '12px', display: 'inline-block' }}>
                              <Point title={e?.leaveTypeName} colors={COLORS3[i]} />
                          </div>
                      ))}
            </div>
        </div>
    );
};
