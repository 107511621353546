import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { timeoffORG, leaveTypeORG, teamORG } from 'helpers/hasuraapi';

export const getTimeOffORGData: any = createAsyncThunk('graph/getTimeOffORGData', async (user: any) => {
    const data: any = await timeoffORG(user.organizationId, user.token);
    const jsonData: any = await data.json();
    return jsonData.data.timeoffdb_get_graphhistogram1;
});

export const getTeamData: any = createAsyncThunk('graph/getTeamData', async (user: any) => {
    const data: any = await teamORG(user.organizationId, user.token);
    const jsonData: any = await data.json();
    return jsonData.data.usermanagementdb_Team;
});

export const getLeaveTypesData: any = createAsyncThunk('graph/getLeaveTypes', async (user: any) => {
    const data: any = await leaveTypeORG(user.organizationId, user.token);
    const jsonData: any = await data.json();
    return jsonData.data.timeoffdb_LeaveType;
});

export const graphSlice = createSlice({
    name: 'graph',
    initialState: { timeOffORGData: [], leavetypeData: [], teamData: [], status: 'loading' },
    reducers: {},
    extraReducers: {
        [getTimeOffORGData.pending]: state => {
            state.status = 'loading';
        },
        [getTimeOffORGData.fulfilled]: (state, action) => {
            state.timeOffORGData = action.payload;
            state.status = 'success';
        },
        [getTimeOffORGData.rejected]: state => {
            state.status = 'failed';
        },
        [getTeamData.pending]: state => {
            state.status = 'loading';
        },
        [getTeamData.fulfilled]: (state, action) => {
            state.teamData = action.payload;
            state.status = 'success';
        },
        [getTeamData.rejected]: state => {
            state.status = 'failed';
        },
        [getLeaveTypesData.pending]: state => {
            state.status = 'loading';
        },
        [getLeaveTypesData.fulfilled]: (state, action) => {
            state.leavetypeData = action.payload;
            state.status = 'success';
        },
        [getLeaveTypesData.rejected]: state => {
            state.status = 'failed';
        },
    },
});

export default graphSlice.reducer;
