import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // Header style
        header: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            display: 'flex',
            padding: '20px 80px',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#3D49B6',
            zIndex: 9999,
        },
        headerSmall: {
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            display: 'flex',
            padding: '10px 30px',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#3C48B5',
            zIndex: 9999,
        },
        toggleDiv: {
            width: '100%',
            position: 'fixed',
            top: 60,
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 15px',
            alignItems: 'left',
            justifyContent: 'space-between',
            backgroundColor: '#3C48B5',
            zIndex: 99,
            textDecoration: 'none',
            listStyle: 'none',
            // transition: '1s',
        },
        toggleDivHidden: {
            width: '100%',
            position: 'fixed',
            top: -350,
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 15px',
            alignItems: 'left',
            justifyContent: 'space-between',
            backgroundColor: '#3C48B5',
            zIndex: 9,
            textDecoration: 'none',
            listStyle: 'none',
            // transition: '1s',
        },
        headerLeft: {
            display: 'flex',
            alignItems: 'center',
        },
        img: {
            height: 50,
            marginRight: 10,
        },
        imgSmall: {
            height: 40,
        },
        toggleBtn: {
            height: 20,
        },
        label: {
            fontSize: 40,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: '#fff',
        },
        headerRight: {
            margin: 'auto 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            textDecoration: 'none',
            listStyle: 'none',
        },
        link: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 18,
            padding: '10px 20px',
            color: '#fff',
            '&:hover': {
                // borderBottom: '2px solid #fff',
                // borderRadius: 2,
                cursor: 'pointer',
            },
            '&:focus': {
                // borderBottom: '2px solid #fff',
                // borderRadius: 2,
                cursor: 'pointer',
            },
            '&:active': {
                // borderBottom: '2px solid #fff',
                // borderRadius: 2,
                cursor: 'pointer',
            },
        },
        activelink: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 18,
            padding: '10px 20px',
            color: '#fff',
            borderBottom: '2px solid #fff',
            borderRadius: 2,
            cursor: 'pointer',
            '&:hover': {
                borderBottom: '2px solid #fff',
                borderRadius: 2,
                cursor: 'pointer',
            },
            '&:focus': {
                borderBottom: '2px solid #fff',
                borderRadius: 2,
                cursor: 'pointer',
            },
            '&:active': {
                borderBottom: '2px solid #fff',
                borderRadius: 2,
                cursor: 'pointer',
            },
        },
        activelinkMobile: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 18,
            padding: '10px 20px',
            color: '#fff',
            cursor: 'pointer',
            backgroundColor: '#4E509E',
            '&:hover': {
                borderBottom: '2px solid #fff',
                borderRadius: 2,
                cursor: 'pointer',
            },
            '&:focus': {
                borderBottom: '2px solid #fff',
                borderRadius: 2,
                cursor: 'pointer',
            },
            '&:active': {
                borderBottom: '2px solid #fff',
                borderRadius: 2,
                cursor: 'pointer',
            },
        },
        button: {
            background: '#fff',
            borderRadius: 5,
            color: '#3D49B6',
            fontSize: 18,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            padding: '5px 20px',
            border: 'none',
            outline: 'none',
            '&:hover': {
                outline: 'none',
                border: 'none',
            },
            '&:focus': {
                outline: 'none',
                border: 'none',
            },
        },
        // Main style`
        mainAdmin: {
            paddingTop: 70,
            width: '100%',
            height: '100vh',
            padding: '20px 100px',
            background: '#3D49B6',
            backgroundImage: 'url("landingPageImages/homepage_bg.png")',
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (max-width: 1199.5px)': {
                height: 'auto',
                padding: '20px',
                paddingTop: 100,
                backgroundImage: 'url("landingPageImages/homepage_bg_mobile.png")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column-reverse',
            },
        },
        mainEmployee: {
            paddingTop: 70,
            width: '100%',
            height: '100vh',
            padding: '20px 100px',
            background: '#3D49B6',
            backgroundImage: 'url("landingPageImages/homepage_bg.png")',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (max-width: 1199.5px)': {
                height: 'auto',
                padding: '20px',
                paddingTop: 100,
                backgroundImage: 'url("landingPageImages/homepage_bg_mobile.png")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column-reverse',
            },
        },
        mainImg: {
            width: '60%',
            maxWidth: '100%',
            height: '80%',
            '@media screen and (max-width: 768.5px)': {
                height: 350,
                width: '80%',
            },
            '@media only screen and (min-width: 768px) and (max-width: 900px)': {
                height: 400,
                width: '50%',
            },
            '@media only screen and (min-width: 901px) and (max-width: 1199.5px)': {
                height: 400,
                width: '40%',
            },
        },
        mainContent: {
            display: 'flex',
            flexDirection: 'column',
            '@media screen and (max-width: 1199.5px)': {
                justifyContent: 'center',
                textAlign: 'center',
            },
        },
        mainHeading: {
            fontSize: 36,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#fff',
            letterSpacing: '0.03em',
            '& span': {
                color: theme.palette.primary.dark,
            },
            '@media screen and (max-width: 1199.5px)': {
                fontSize: 26,
                margin: '10px auto',
                width: '95%',
            },
        },
        mainSubtitle: {
            width: '90%',
            fontSize: 18,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: '#fff',
            letterSpacing: '0.03em',
            margin: '20px 0px',
            '@media screen and (max-width: 768px)': {
                fontSize: 14,
                margin: '20px auto',
                width: '90%',
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                fontSize: 18,
                margin: '20px auto',
                width: '90%',
            },
        },
        mainBtn: {
            blend: 'pass-through',
            height: 40,
            width: 150,
            // background: 'linear-gradient(90deg, #F8F8FD 0%, #EBEBF9 100%)',
            boxShadow: '0px 1px 46px rgba(0, 0, 0, 0.1)',
            borderRadius: 5,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            fontSize: 16,
            letterSpacing: '0.03em',
            color: '#fff',
            borderStyle: 'solid',
            borderWidth: 1,
            borderImageSource:
                'linear-gradient(90deg, #FFFFFF 0%, rgba(237, 237, 237, 0.71) 100%), radial-gradient(154% 967.86% at 50% 50%, #3C48B5 0%, rgba(83, 94, 190, 0.887396) 16.15%, rgba(198, 202, 234, 0.332373) 79.17%, rgba(255, 255, 255, 0.06) 100%)',
            outline: 'none',
            margin: '20px 0',
            background: 'linear-gradient(90deg, #4552C7 0%, #303CA8 100%)',
            // background: 'linear-gradient(74deg, rgba(38, 38, 188, 0.15) -25.45%, rgba(255, 255, 255, 0.07) 85.16%)',
            cursor: 'pointer',
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                outline: 'none',
            },
            '@media screen and (max-width: 1199.5px)': {
                margin: '20px auto',
                width: 160,
                fontSize: 14,
            },
        },
        // Card component
        cardComponent: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingBottom: 50,
            position: 'relative',
            '@media screen and (max-width: 1199px)': {
                padding: '20px',
                paddingBottom: 50,
            },
        },
        floatImage: {
            position: 'absolute',
            width: 100,
            height: 100,
            top: -40,
            right: 150,
            zIndex: 0,
        },
        card: {
            width: '70%',
            display: 'flex',
            padding: 30,
            margin: '20px 0px',
            zIndex: 1,
            justifyContent: 'space-between',
            '&:hover': {
                '& h6': {
                    color: '#636dc4',
                },
                boxShadow: '0px 8px 25px 2px rgba(34, 36, 116, 0.2)',
                borderRadius: 5,
                cursor: 'pointer',
                zIndex: 99,
                backgroundColor: '#fff',
            },
            '@media screen and (max-width: 820px)': {
                width: '100%',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'space-evenly',
                boxShadow: '0px 8px 25px 2px rgba(34, 36, 116, 0.2)',
                borderRadius: 10,
                margin: '10px 0px',
            },
            '@media only screen and (min-width: 821px) and (max-width: 1199.5px)': {
                width: '90%',
                flexDirection: 'column',
                textAlign: 'center',
                padding: 20,
                justifyContent: 'space-evenly',
                boxShadow: '0px 8px 25px 2px rgba(34, 36, 116, 0.2)',
                borderRadius: 10,
                margin: '10px 0px',
            },
        },
        cardReverse: {
            width: '70%',
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: 30,
            margin: '20px 0px',
            justifyContent: 'space-between',
            '&:hover': {
                '& h6': {
                    color: '#636dc4',
                },
                boxShadow: '0px 8px 25px 2px rgba(34, 36, 116, 0.2)',
                borderRadius: 5,
                cursor: 'pointer',
            },
            zIndex: 1,
            '@media screen and (max-width: 820px)': {
                width: '100%',
                flexDirection: 'column',
                textAlign: 'center',
                padding: 20,
                justifyContent: 'space-evenly',
                boxShadow: '0px 8px 25px 2px rgba(34, 36, 116, 0.2)',
                borderRadius: 10,
                margin: '10px 0px',
            },
            '@media only screen and (min-width: 821px) and (max-width: 1199.5px)': {
                width: '90%',
                flexDirection: 'column',
                textAlign: 'center',
                padding: 20,
                justifyContent: 'space-evenly',
                boxShadow: '0px 8px 25px 2px rgba(34, 36, 116, 0.2)',
                borderRadius: 10,
                margin: '10px 0px',
            },
        },
        cardImg: {
            maxWidth: '100%',
            width: 100,
            height: 100,
            margin: 'auto',
        },
        cardContent: {
            display: 'flex',
            flex: 0.9,
            flexDirection: 'column',
            justifyContent: 'center',
            '@media screen and (max-width: 1199.5px)': {
                textAlign: 'center',
            },
        },
        cardContentReverse: {
            display: 'flex',
            flex: 0.9,
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'right',
            '@media screen and (max-width: 1199.5px)': {
                textAlign: 'center',
            },
        },
        cardHeading: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 20,
            fontWeight: 600,
            color: '#333333',
            '@media screen and (max-width: 768px)': {
                margin: '10px 0px',
                color: theme.palette.primary.light,
                fontSize: 18,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                margin: '10px 0px',
                color: theme.palette.primary.light,
                fontSize: 20,
            },
        },
        cardDescription: {
            width: '100%',
            alignSelf: 'flex-start',
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            fontWeight: 400,
            color: '#444444',
            '@media screen and (max-width: 768px)': {
                fontSize: 14,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                fontSize: 18,
            },
        },
        // fisrt style
        first: {
            height: '100vh',
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            boxSizing: 'border-box',
            backgroundColor: '#3D49B6',
            backgroundImage: 'url("landingPageImages/homepage_bg.png")',
            zIndex: 9,
            paddingTop: 90,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            // '@media only screen and (min-width: 768px) and (max-width: 820px)': {
            //     background: 'green',
            // },
        },
        firstSmall: {
            height: 'auto',
            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: '#3C48B5',
            backgroundImage: 'url("landingPageImages/homepage_bg_mobile.png")',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            zIndex: 9,
            paddingTop: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
        },
        leftImg: {
            maxWidth: '100%',
            height: '50%',
            position: 'absolute',
            bottom: -50,
            left: '10%',
            zIndex: 1,
        },
        dashboardImg: {
            maxWidth: '80%',
            height: '60%',
            position: 'absolute',
            bottom: -50,
            left: 'calc((100vw - 80%) / 2)',
            zIndex: 2,
        },
        dashboardImgSmall: {
            maxWidth: '80%',
            height: 350,
            margin: '20px auto',
        },
        rightImg: {
            maxWidth: '100%',
            height: '50%',
            position: 'absolute',
            bottom: -50,
            right: '10%',
            zIndex: 1,
        },
        heading: {
            position: 'absolute',
            top: '20%',
            color: '#fff',
            fontSize: 35,
            fontFamily: theme.typography.fontFamily,
            letterSpacing: '0.03em',
            // '@media only screen and (min-width: 768px) and (max-width: 820px)': {
            //     top: '13%'
            // },
        },
        headingSmall: {
            color: '#fff',
            fontSize: 26,
            fontFamily: theme.typography.fontFamily,
            letterSpacing: '0.03em',
        },
        subtitle: {
            width: '70%',
            position: 'absolute',
            top: '25%',
            color: '#fff',
            fontSize: 20,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            marginTop: 20,
            letterSpacing: '0.03em',
            // '@media only screen and (min-width: 768px) and (max-width: 820px)': {
            //     top: '18%',
            // },
        },
        subtitleSmall: {
            width: '85%',
            color: '#fff',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            margin: '20px auto',
            letterSpacing: '0.03em',
            '@media only screen and (min-width: 768px) and (max-width:1199.5px)': {
                fontSize: 18,
            },
        },
        discoverBtn: {
            position: 'absolute',
            background: 'linear-gradient(90deg, #4954BC 0%, #222D94 100%)',
            top: '36%',
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            marginTop: 30,
            padding: '0px 25px',
            height: 35,
            color: '#fff',
            borderStyle: 'solid',
            borderWidth: 1,
            borderImageSource:
                'linear-gradient(90deg, #FFFFFF 0%, rgba(237, 237, 237, 0.71) 100%),radial-gradient(50.84% 457.38% at 62.56% 50%, #3C48B5 0%, rgba(83, 94, 190, 0.887396) 35.67%, rgba(198, 202, 234, 0.332373) 79.17%, rgba(255, 255, 255, 0.06) 100%)',
            outline: 'none',
            cursor: 'pointer',
            borderRadius: '3px',
            '&:focus': {
                outline: 'none',
            },
            // '@media only screen and (min-width: 768px) and (max-width: 820px)': {
            //     top: '26%'
            // },
        },
        discoverBtnSmall: {
            width: 160,
            margin: '10px auto',
            background: 'linear-gradient(90deg, #4954BC 0%, #222D94 100%)',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            height: 35,
            color: '#fff',
            borderStyle: 'solid',
            borderWidth: 1,
            borderImageSource:
                'linear-gradient(90deg, #FFFFFF 0%, rgba(237, 237, 237, 0.71) 100%),radial-gradient(50.84% 457.38% at 62.56% 50%, #3C48B5 0%, rgba(83, 94, 190, 0.887396) 35.67%, rgba(198, 202, 234, 0.332373) 79.17%, rgba(255, 255, 255, 0.06) 100%)',
            outline: 'none',
            cursor: 'pointer',
            borderRadius: '3px',
            '&:focus': {
                outline: 'none',
            },
        },
        // Top div styles
        topDiv: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: '#fff',
            padding: '20px 100px',
            '@media screen and (max-width: 768px)': {
                textAlign: 'center',
            },
            // '@media only screen and (max-width: 820px)': {
            //     padding: '10px 50px',
            //     border: '1px solid red',
            // },
        },
        topDivSmall: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: '#fff',
            padding: '50px 20px',
        },
        topDivContent: {
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
        },
        topDivContentSmall: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            // alignItems: 'center',
        },
        topDivImage: {
            width: '40%',
            maxWidth: '100%',
            height: '70%',
        },
        topDivImageSmall: {
            width: '70%',
            height: 220,
            margin: '30px auto',
            '@media only screen and (min-width: 768px) and (max-width: 999.5px)': {
                height: 250,
                width: '55%',
                margin: '30px auto',
            },
            '@media only screen and (min-width: 1000px) and (max-width: 1199.5px)': {
                height: 350,
                width: '55%',
                margin: '30px auto',
            },
        },
        topDivHeading: {
            fontSize: 36,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#6A78F8',
            letterSpacing: '0.03em',
            '& span': {
                color: theme.palette.primary.dark,
            },
        },
        topDivHeadingSmall: {
            fontSize: 24,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#6A78F8',
            letterSpacing: '0.03em',
            '& span': {
                color: theme.palette.primary.dark,
            },
        },
        topDivSubtitle: {
            width: '80%',
            fontSize: 18,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: '#252525',
            letterSpacing: '0.03em',
            margin: '10px 0px',
            '@media screen and (max-width: 768px)': {
                width: '100%',
                margin: '20px auto',
                fontSize: 14,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                width: '100%',
                margin: '20px auto',
                fontSize: 18,
            },
        },
        topDivBtn: {
            width: 220,
            height: 40,
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 18,
            letterSpacing: '0.03em',
            borderRadius: 5,
            outline: 'none',
            marginTop: 30,
            '&:hover': {
                outline: 'none',
                border: 'none',
                cursor: 'pointer',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        // Bottom div styles
        bottomDiv: {
            paddingTop: 100,
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            padding: '20px 100px',
            '@media screen and (max-width: 1199.5px)': {
                height: 'auto',
                padding: '20px',
                paddingTop: 70,
            },
        },
        bottomDivTopContent: {
            height: '25%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            '@media screen and (max-width: 1199.5px)': {
                height: 'auto',
            },
        },
        bottomDivTopHeading: {
            fontSize: 36,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#6A78F8',
            letterSpacing: '0.03em',
            // margin: '20px 0px',
            marginBottom: 100,
            '& span': {
                color: '#2a337f',
            },
            '@media screen and (max-width: 1199.5px)': {
                fontSize: 24,
            },
        },
        bottomDivTopSubtitle: {
            width: '95%',
            fontSize: 18,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: '#252525',
            letterSpacing: '0.03em',
            margin: '20px 0px',
            '@media screen and (max-width: 768px)': {
                fontSize: 14,
            },
        },
        bottomDivBottom: {
            height: '80vh',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '@media screen and (max-width: 1199.5px)': {
                width: '100%',
                height: 'auto',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
                paddingBottom: 20,
            },
        },
        bottomDivBottomImg: {
            width: '40%',
            maxWidth: '60%',
            height: '70%',
            '@media screen and (max-width: 768px)': {
                width: '80%',
                maxWidth: '80%',
                height: 200,
                margin: '50px auto',
            },
            '@media only screen and (min-width: 768px) and (max-width: 900px)': {
                width: '60%',
                maxWidth: '80%',
                height: 250,
                margin: '50px auto',
            },
            '@media only screen and (min-width: 901px) and (max-width: 1199.5px)': {
                width: '50%',
                maxWidth: '80%',
                height: 300,
                margin: '50px auto',
            },
        },
        bottomDivBottomImg2: {
            width: '60%',
            maxWidth: '60%',
            height: '70%',
            '@media screen and (max-width: 768px)': {
                width: '90%',
                maxWidth: '90%',
                height: 180,
                margin: '50px auto',
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                width: '70%',
                maxWidth: '90%',
                height: 250,
                margin: '50px auto',
            },
        },
        bottomDivBottomContent: {
            paddingLeft: 50,
            display: 'flex',
            flexDirection: 'column',
            width: '60%',
            '@media screen and (max-width: 1199.5px)': {
                width: '90%',
                padding: 0,
            },
        },
        bottomDivBottomContent2: {
            paddingLeft: 50,
            display: 'flex',
            flexDirection: 'column',
            width: '40%',
            '@media screen and (max-width: 1199.5px)': {
                width: '90%',
                padding: 0,
            },
        },
        bottomDivBottomHeading: {
            fontSize: 25,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#6A78F8',
            letterSpacing: '0.03em',
            '& span': {
                color: theme.palette.primary.dark,
            },
            '@media screen and (max-width: 1199.5px)': {
                fontSize: 18,
            },
        },
        bottomDivBottomSubtitle: {
            fontSize: 18,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: '#232323',
            letterSpacing: '0.03em',
            marginBottom: '25px',
            '@media screen and (max-width: 768px)': {
                fontSize: 14,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                fontSize: 18,
            },
        },
        // how it work styles
        howitwork: {
            position: 'relative',
            padding: '20px 100px',
            width: '100%',
            height: '90vh',
            display: 'flex',
            alignItems: 'center',
        },
        howimg: {
            position: 'absolute',
            top: 70,
            left: 10,
            width: '60%',
            maxWidth: '100%',
            height: '80%',
        },
        howcontent: {
            height: '80%',
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            '& li': {
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                fontSize: 14,
                color: '#252525',
                fontFamily: theme.typography.fontFamily,
                // backgroundColor: theme.palette.primary.main,
                // borderRadius: 50,
                '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                    fontSize: 18,
                },
            },
            '& span': {
                marginRight: 20,
                width: 30,
                height: 30,
                backgroundColor: '#fff',
                borderRadius: '50%',
                boxShadow: '0px 2px 10px 0px #2626BC40',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.primary.main,
                fontSize: 14,
                '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                    fontSize: 18,
                },
            },
            '& p': {
                color: '#0f0f0f',
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: 40,
            },
            '@media screen and (max-width: 768px)': {
                height: '50vh',
                width: '90%',
                margin: '10px auto',
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                height: '50vh',
                width: '90%',
                margin: '5px auto',
            },
        },
        itworkHeading: {
            fontSize: 36,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#6A78F8',
            letterSpacing: '0.03em',
            position: 'relative',
            '& span': {
                color: theme.palette.primary.dark,
            },
            '& img': {
                position: 'absolute',
                right: '40%',
                bottom: -20,
                height: 100,
            },
            '@media screen and (max-width: 767.5px)': {
                fontSize: 26,
            },
            '@media screen and (min-width: 768px) and (max-width: 1199.5px)': {
                fontSize: 30,
            },
        },
        // Task styles
        taskContainer: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundImage: 'url("landingPageImages/task_bg.png")',
            // backgroundColor: theme.palette.primary.main,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            position: 'relative',
            '& img': {
                position: 'absolute',
                left: 0,
                top: 150,
                width: '70%',
                height: '70%',
                zIndex: 9,
            },
        },
        taskDiv: {
            width: '80%',
            height: '100%',
            backgroundColor: '#3D49B6',
            borderRadius: '0 0 0 50%',
            position: 'relative',
            zIndex: 2,
            '& h1': {
                color: '#fff',
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: 40,
                position: 'absolute',
                top: 50,
                left: '10%',
            },
        },
        taskContent: {
            textDecoration: 'none',
            listStyle: 'none',
            position: 'absolute',
            right: 100,
            top: '35%',
            zIndex: 3,
            width: '40%',
            height: '40%',
            overflow: 'hidden',
            display: 'flex',
            '& li': {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                flexShrink: 0,
                '& div': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    '& h6': {
                        flex: 0.95,
                        fontFamily: theme.typography.fontFamily,
                        fontSize: 28,
                        fontWeight: 600,
                        color: '#fff',
                        letterSpacing: '0.03em',
                    },
                    '& h5': {
                        width: 50,
                        fontFamily: theme.typography.fontFamily,
                        fontSize: 35,
                        background: 'url("landingPageImages/num-bg.png")',
                        backgroundSize: 'contain',
                        fontWeight: 600,
                        textAlign: 'center',
                        color: '#fff',
                    },
                },
                '& p': {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: 18,
                    fontWeight: 400,
                    color: '#fff',
                    letterSpacing: '0.03em',
                    marginTop: 30,
                },
            },
        },
        btnDiv: {
            position: 'absolute',
            bottom: '25%',
            right: '100px',
            width: '40%',
            height: '5%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            color: '#fff',
            zIndex: 5,
        },
        statusBar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 50,
            height: '100%',
        },
        active: {
            backgroundColor: '#fff',
        },
        inactive: {
            backgroundColor: '#636dc4',
        },
        taskSmall: {
            width: '100%',
            backgroundColor: '#3C48B5',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            padding: '20px',
            paddingTop: 50,
            color: '#fff',
            '& h1': {
                fontSize: 24,
                fontFamily: theme.typography.fontFamily,
                fontWeight: 600,
            },
            '& ul': {
                textDecoration: 'none',
                listStyle: 'none',
                fontFamily: theme.typography.fontFamily,
            },
            '& li': {
                display: 'flex',
                flexDirection: 'column',
                margin: '40px 0',
                '& div': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 10,
                    '& h5': {
                        fontWeight: 600,
                        fontSize: 20,
                        background: 'url("landingPageImages/num-bg.png")',
                        backgroundSize: 'contain',
                        width: 30,
                        marginRight: 10,
                    },
                    '& h6': {
                        fontWeight: 600,
                        fontSize: 18,
                        '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                            fontWeight: 600,
                            fontSize: 20,
                        },
                    },
                },
                '& p': {
                    fontSize: 14,
                    '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                        fontSize: 18,
                    },
                },
            },
        },
        // features styles
        features: {
            width: '100%',
            height: '100vh',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: '0px 100px',
            position: 'relative',
            '@media screen and (max-width: 1199.5px)': {
                height: 'auto',
                flexDirection: 'column',
                padding: '20px',
            },
        },
        featuresText: {
            width: '40%',
            display: 'flex',
            justifyContent: 'center',
            '@media screen and (max-width: 1199.5px)': {
                width: '90%',
                textAlign: 'center',
            },
        },
        featuresHeading: {
            fontSize: 40,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#6A78F8',
            letterSpacing: '0.03em',
            '& span': {
                color: theme.palette.primary.dark,
            },
            '@media screen and (max-width: 768px)': {
                fontSize: 26,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                fontSize: 30,
            },
        },
        floater: {
            position: 'absolute',
            left: '23%',
            top: '50%',
            width: 75,
            height: 75,
        },
        floaterEmp: {
            position: 'absolute',
            left: '26%',
            top: '60%',
            width: 75,
            height: 75,
        },
        featuresList: {
            width: '70%',
            height: '90vh',
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            backgroundImage: `radial-gradient(${theme.palette.primary.main}, ${theme.palette.primary.light}, #fff, #fff, #fff)`,
            '@media screen and (max-width: 1199.5px)': {
                backgroundImage: 'none',
                width: '100%',
                height: 'auto',
                gridTemplateColumns: '100%',
                margin: '50px 0px',
            },
        },
        featureContainer: {
            padding: '20px',
            margin: '1px',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            '&:hover:nth-child(1)': {
                borderRadius: '10px 10px 0px 0px',
                cursor: 'pointer',
                boxShadow: '5px 5px 37px rgba(75, 77, 255, 0.18)',
            },
            '&:hover:nth-child(2)': {
                borderRadius: '10px 10px 10px 0px',
                cursor: 'pointer',
                boxShadow: '5px 5px 37px rgba(75, 77, 255, 0.18)',
            },
            '&:hover:nth-child(3)': {
                borderRadius: '10px 0px 10px 10px',
                cursor: 'pointer',
                boxShadow: '5px 5px 37px rgba(75, 77, 255, 0.18)',
            },
            '&:hover:nth-child(4)': {
                borderRadius: '0px 10px 10px 10px',
                cursor: 'pointer',
                boxShadow: '5px 5px 37px rgba(75, 77, 255, 0.18)',
            },
            '@media screen and (max-width: 1199.5px)': {
                borderRadius: '5px',
                cursor: 'pointer',
                boxShadow: '5px 5px 37px rgba(75, 77, 255, 0.18)',
                margin: '10px 0px',
            },
        },
        featureimage: {
            maxWidth: '100%',
            // width: 100,
            height: '40%',
        },
        featureTitle: {
            marginTop: 25,
            color: '#222222',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            fontSize: 18,
            letterSpacing: '0.03em',
            '@media screen and (max-width: 768px)': {
                color: theme.palette.primary.light,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                color: theme.palette.primary.light,
                fontSize: 20,
            },
        },
        featureDescription: {
            width: '85%',
            color: '#444444',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: 14,
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                fontSize: 18,
            },
        },
        // Experience style
        experience: {
            width: '100%',
            height: '90vh',
            padding: '20px 100px',
            background: 'linear-gradient(74deg, rgba(38, 38, 188, 0.15) -25.45%, rgba(255, 255, 255, 0.07) 85.16%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (max-width: 1199.5px)': {
                height: 'auto',
                padding: '50px 20px',
                textAlign: 'center',
                background:
                    'linear-gradient(69.95deg, rgba(38, 38, 188, 0.15) -5.29%, rgba(255, 255, 255, 0.07) 110.79%)',
                flexDirection: 'column',
            },
        },
        experienceImg: {
            width: '50%',
            maxWidth: '100%',
            height: '60%',
            '@media screen and (max-width: 768px)': {
                width: '85%',
                height: 180,
                margin: '0 auto',
            },
            '@media only screen and (min-width: 768px) and (max-width: 900px)': {
                width: '65%',
                height: 220,
                margin: '0 auto',
            },
            '@media only screen and (min-width: 901px) and (max-width: 1199.5px)': {
                width: '55%',
                height: 220,
                margin: '0 auto',
            },
        },
        experienceContent: {
            '@media screen and (max-width: 1199.5px)': {
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
            },
            '@media screen and (min-width: 1200px)': {
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 50,
            },
        },
        expHeading: {
            fontSize: 36,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#6A78F8',
            letterSpacing: '0.03em',
            '& span': {
                color: theme.palette.primary.dark,
            },
            '@media screen and (max-width: 768px)': {
                fontSize: 26,
                margin: '0px auto',
                marginBottom: 20,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                fontSize: 30,
                margin: '0px auto',
                marginBottom: 20,
            },
        },
        expSubtitle: {
            width: '90%',
            fontSize: 18,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: '#252525',
            letterSpacing: '0.03em',
            margin: '20px 0px',
            '@media screen and (max-width: 768px)': {
                width: '100%',
                marginTop: 50,
                marginBottom: 20,
                marginLeft: 'auto',
                marginRight: 'auto',
                fontSize: 14,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                width: '100%',
                marginTop: 50,
                marginBottom: 20,
                marginLeft: 'auto',
                marginRight: 'auto',
                fontSize: 18,
            },
        },
        expBtn: {
            blend: 'pass-through',
            width: 300,
            height: 40,
            // background: 'linear-gradient(90deg, #F8F8FD 0%, #EBEBF9 100%)',
            boxShadow: '0px 1px 46px rgba(0, 0, 0, 0.1)',
            borderRadius: 5,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            fontSize: 16,
            letterSpacing: '0.03em',
            color: theme.palette.primary.main,
            borderStyle: 'solid',
            borderWidth: 1,
            borderImageSource:
                'linear-gradient(90deg, #3C48B5 0%, #3939DB 100%),radial-gradient(154% 967.86% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.887396) 18.75%, rgba(255, 255, 255, 0.332373) 79.17%, rgba(255, 255, 255, 0.06) 100%)',
            outline: 'none',
            margin: '20px 0',
            background: 'linear-gradient(74deg, rgba(38, 38, 188, 0.15) -25.45%, rgba(255, 255, 255, 0.07) 85.16%)',
            cursor: 'pointer',
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                outline: 'none',
            },
            '@media screen and (max-width: 768px)': {
                width: '85%',
                margin: '20px auto',
                fontSize: 14,
            },
            '@media only screen and (min-width: 768px) and (max-width: 999px)': {
                width: '85%',
                margin: '20px auto',
                fontSize: 18,
            },
            '@media only screen and (min-width: 1000px) and (max-width: 1199.5px)': {
                width: '65%',
                margin: '20px auto',
                fontSize: 18,
            },
        },
        // Question
        questionContainer: {
            width: '100%',
            minHeight: '100vh',
            height: 'auto',
            padding: '120px 100px',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            // justifyContent: 'center',
            '& h2': {
                fontFamily: theme.typography.fontFamily,
                color: '#0A1470',
                fontSize: 40,
                fontWeight: 600,
                position: 'relative',
                margin: '80px auto',
                zIndex: 2,
            },
        },
        questionContainerSmall: {
            width: '100%',
            height: 'auto',
            padding: '100px 10px',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            // justifyContent: 'center',
            '& h2': {
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.primary.light,
                fontSize: 24,
                fontWeight: 600,
                position: 'relative',
                margin: '50px 0px',
                zIndex: 2,
                '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                    fontSize: 30,
                },
            },
            '& span': {
                color: '#0A1470',
            },
        },
        questionImg: {
            width: 100,
            height: 100,
            position: 'absolute',
            right: -60,
            bottom: -20,
            zIndex: 0,
        },
        questionCard: {
            width: '80%',
            margin: '20px auto',
            display: 'flex',
            flexDirection: 'column',
            '@media screen and (max-width: 900px)': {
                width: '90%',
                height: 'auto',
            },
            '@media only screen and (min-width: 901px) and (max-width: 1199.5px)': {
                width: '70%',
                height: 'auto',
            },
        },
        question: {
            height: '35px',
            background: '#fff',
            borderRadius: '20px',
            margin: '5px 0',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            position: 'relative',
            boxShadow: '1px 3px 30px rgba(0, 0, 0, 0.1)',
            '& div': {
                width: 25,
                height: 25,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                position: 'absolute',
                left: '-5px',
                backgroundColor: '#fff',
                boxShadow: '0px 2px 10px 0px #2626BC40',
            },
            '& span': {
                width: 20,
                height: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                right: '20px',
                fontSize: 25,
                color: '#B2BABB',
                cursor: 'pointer',
                paddingBottom: 2,
            },
            '& h6': {
                position: 'absolute',
                left: 50,
                top: 10,
                fontFamily: theme.typography.fontFamily,
                color: '#444444',
                letterSpacing: '0.03em',
                fontSize: 16,
            },
        },
        activeQuestion: {
            height: '35px',
            background: 'linear-gradient(90deg, #3C48B5 8.06%, #212C93 103.27%)',
            borderRadius: '20px',
            margin: '5px 0',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            position: 'relative',
            boxShadow: '1px 3px 30px rgba(0, 0, 0, 0.1)',
            '& div': {
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                position: 'absolute',
                left: '-5px',
                backgroundColor: '#3D49B6',
                color: '#fff',
                fontSize: 18,
                boxShadow: '0px 2px 10px 0px #2626BC40',
                border: '0.5px solid white',
                // border: '1.5px solid',
                // borderImageSource: 'linear-gradient(90deg, #FFFFFF 100%, rgba(255, 255, 255, 0.1) 92.5%)',
            },
            '& span': {
                width: 20,
                height: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                right: '22px',
                fontSize: 40,
                color: '#fff',
                cursor: 'pointer',
                paddingBottom: 10,
            },
            '& h6': {
                position: 'absolute',
                left: 50,
                top: 8,
                fontFamily: theme.typography.fontFamily,
                color: '#fff',
                letterSpacing: '0.03em',
                fontSize: 16,
            },
        },
        activeQuestionSmall: {
            height: 'auto',
            background: 'linear-gradient(90deg, #3C48B5 8.06%, #212C93 103.27%)',
            borderRadius: '20px',
            margin: '5px 0',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            padding: 10,
            // position: 'relative',
            boxShadow: '1px 3px 30px rgba(0, 0, 0, 0.1)',
            '& div': {
                width: 35,
                height: 35,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                position: 'absolute',
                left: '10px',
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                fontSize: 16,
                fontFamily: theme.typography.fontFamily,
                boxShadow: '0px 2px 10px 0px #2626BC40',
                border: '0.5px solid white',
                '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                    fontSize: 20,
                },
                '@media only screen and (min-width: 600px) and (max-width: 900.5px)': {
                    left: '25px',
                },
                '@media only screen and (min-width: 901px) and (max-width: 1000.5px)': {
                    left: 130,
                },
                '@media only screen and (min-width: 1001px) and (max-width: 1099.5px)': {
                    left: 145,
                },
                '@media only screen and (min-width: 1101px) and (max-width: 1199.5px)': {
                    left: 160,
                },
                // border: '1.5px solid',
                // borderImageSource: 'linear-gradient(90deg, #FFFFFF 100%, rgba(255, 255, 255, 0.1) 92.5%)',
            },
            '& h6': {
                margin: 'auto 0',
                padding: 10,
                paddingLeft: 30,
                fontFamily: theme.typography.fontFamily,
                color: '#fff',
                letterSpacing: '0.03em',
                fontSize: 14,
                '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                    fontSize: 18,
                },
            },
        },
        questionSmall: {
            height: 'auto',
            background: '#fff',
            borderRadius: '20px',
            margin: '5px 0',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            padding: 10,
            paddingLeft: 30,
            position: 'relative',
            boxShadow: '1px 3px 30px rgba(0, 0, 0, 0.1)',
            '& div': {
                width: 30,
                height: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                position: 'absolute',
                left: '-10px',
                backgroundColor: '#fff',
                boxShadow: '0px 2px 10px 0px #2626BC40',
                fontFamily: theme.typography.fontFamily,
            },
            '& span': {
                position: 'absolute',
                right: '20px',
                fontSize: 30,
                color: '#B2BABB',
                cursor: 'pointer',
            },
            '& h6': {
                // position: 'absolute',
                // left: 50,
                // top: 10,
                margin: 'auto 0',
                fontFamily: theme.typography.fontFamily,
                color: '#444444',
                letterSpacing: '0.03em',
                fontSize: 14,
                '@media only screen and (min-width: 768px) and (max-width: 1199.5px)': {
                    fontSize: 18,
                    paddingLeft: '5px',
                },
            },
        },
        answer: {
            background: '#fff',
            padding: 50,
            borderRadius: 5,
            margin: '5px 0',
            boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
            display: 'flex',
            textAlign: 'left',
            '& h6': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 16,
                color: '#444444',
                letterSpacing: '0.03em',
            },
            '@media screen and (max-width: 760px)': {
                padding: 20,
                '& h6': {
                    fontSize: 14,
                },
            },
            '@media only screen and (min-width: 761px) and (max-width: 1199.5px)': {
                padding: 30,
                '& h6': {
                    fontSize: 18,
                },
            },
        },
        answerHidden: {
            background: '#fff',
            padding: 50,
            borderRadius: 5,
            margin: '5px 0',
            boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
            display: 'none',
            textAlign: 'left',
            '& h6': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 16,
                color: '#444444',
                letterSpacing: '0.03em',
            },
        },
        // sign up style
        signup: {
            height: 300,
            width: '100%',
            display: 'flex',
            background: 'url("landingPageImages/bg3.png")',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            // '@media screen and (max-width: 760px)': {
            //     padding: '50px 20px',
            //     flexDirection: 'column',
            //     height: 'auto',
            //     fontSize: 14,
            //     background: 'none',
            //     backgroundColor: '#3C48B5',
            // },
            '@media screen and (max-width: 768px)': {
                padding: '50px 20px',
                flexDirection: 'column',
                height: 'auto',
                fontSize: 14,
                background: 'none',
                backgroundColor: '#3C48B5',
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199px)': {
                padding: '50px 20px',
                flexDirection: 'column',
                height: 'auto',
                fontSize: 18,
                background: 'none',
                backgroundColor: '#3C48B5',
            },
            // background: `linear-gradient(to right, #fff, ${theme.palette.primary.dark} 100%)`,
            // background: 'linear-gradient(to right, #3c48b5 1%, #3c48b5 10%)',
            // background:
            // 'linear-gradient(86.65deg, rgba(42, 53, 152, 0) -0.66%, #3C48B5 34.61%, #313CA3 66.45%, #243096 100.41%);',
        },
        content: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 32,
            paddingLeft: 100,
            // '@media screen and (max-width: 760px)': {
            //     width: '100%',
            //     paddingLeft: 0,
            //     justifyContent: 'center',
            //     textAlign: 'center',
            //     paddingBottom: 20,
            // },
            '@media screen and (max-width: 1199px)': {
                width: '100%',
                paddingLeft: 0,
                justifyContent: 'center',
                textAlign: 'center',
                paddingBottom: 20,
            },
        },
        contentText: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 32,
            fontWeight: 700,
            // '@media screen and (max-width: 760px)': {
            //     fontSize: 20,
            //     fontWeight: 700,
            //     lineHeight: '28px',
            //     width: '80%',
            //     fontFamily: theme.typography.fontFamily,
            // },
            '@media screen and (max-width: 768px)': {
                fontSize: 20,
                fontWeight: 700,
                lineHeight: '28px',
                width: '80%',
                fontFamily: theme.typography.fontFamily,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199px)': {
                fontSize: 22,
                fontWeight: 700,
                lineHeight: '28px',
                width: '80%',
                fontFamily: theme.typography.fontFamily,
            },
        },
        signUpContent: {
            width: '60%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& p': {
                color: '#fff',
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '14px',
                '@media only screen and (min-width: 768px) and (max-width: 1199px)': {
                    color: '#fff',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: theme.typography.fontWeightMedium,
                    fontSize: '18px',
                },
            },
            '& span': {
                color: '#fff',
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: '16px',
                marginLeft: 5,
                cursor: 'pointer',
                '@media only screen and (min-width: 768px) and (max-width: 1199px)': {
                    color: '#fff',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: '20px',
                    marginLeft: 5,
                    cursor: 'pointer',
                },
            },
            // '@media screen and (max-width: 760px)': {
            //     width: '100%',
            // },
            '@media screen and (max-width: 1199px)': {
                width: '100%',
            },
        },
        btn: {
            width: '60%',
            height: 40,
            background: 'linear-gradient(90deg, #4954BC 0%, #222D94 100%)',
            boxShadow: '0px 1px 46px rgba(0, 0, 0, 0.1)',
            borderRadius: 10,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            fontSize: 18,
            letterSpacing: '0.03em',
            color: '#fff',
            borderStyle: 'solid',
            borderWidth: 1,
            borderImageSource:
                'linear-gradient(90deg, #FFFFFF 0%, rgba(237, 237, 237, 0.71) 100%),radial-gradient(50.84% 457.38% at 62.56% 50%, #3C48B5 0%, rgba(83, 94, 190, 0.887396) 35.67%, rgba(198, 202, 234, 0.332373) 79.17%, rgba(255, 255, 255, 0.06) 100%)',
            outline: 'none',
            margin: '20px 0',
            cursor: 'pointer',
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                outline: 'none',
            },
            // '@media screen and (max-width: 760px)': {
            //     width: '90%',
            //     borderRadius: '3px',
            //     fontSize: 15,
            // },
            '@media screen and (max-width: 768px)': {
                width: '90%',
                borderRadius: '3px',
                fontSize: 15,
            },
            '@media only screen and (min-width: 768px) and (max-width: 1199px)': {
                width: '80%',
                borderRadius: '3px',
                fontSize: 17,
            },
            '@media only screen and (min-width: 901px) and (max-width: 1199.5px)': {
                width: '60%',
                borderRadius: '3px',
                fontSize: 17,
            },
        },
        signUpBtn: {
            position: 'relative',
            width: '60%',
            height: 40,
            background: '#fff',
            border: '1px solid #3C48B5',
            borderRadius: 10,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            fontSize: 16,
            letterSpacing: '0.03em',
            color: theme.palette.primary.main,
            cursor: 'pinter',
            margin: '20px 0',
            outline: 'none',
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                outline: 'none',
            },
            // '@media screen and (max-width: 760px)': {
            //     width: '90%',
            //     borderRadius: '3px',
            //     fontSize: 14,
            // },
            '@media screen and (max-width: 768px)': {
                width: '90%',
                borderRadius: '3px',
                fontSize: 14,
            },
            '@media only screen and (min-width: 768px) and (max-width: 900px)': {
                width: '80%',
                borderRadius: '3px',
                fontSize: 17,
            },
            '@media only screen and (min-width: 901px) and (max-width: 1199.5px)': {
                width: '60%',
                borderRadius: '3px',
                fontSize: 17,
            },
        },
        googleImg: {
            position: 'absolute',
            height: 25,
            left: 15,
            top: 7,
        },
        // Footer style
        footer: {
            width: '100%',
            height: 100,
            backgroundColor: '#232334',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (max-width: 760px)': {
                height: 'auto',
                textAlign: 'center',
                padding: '10px 20px',
            },
        },
        footerText: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#ffffff',
            fontSize: 16,
            // lineHeight: 22,
            letterSpacing: '0.03em',
            '@media screen and (max-width: 760px)': {
                fontSize: 9,
                lineHeight: '12px',
                padding: '10px 20px',
            },
        },
        footerText1: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            fontSize: 13,
            color: '#fff',
            letterSpacing: '0.03em',
            '& a': {
                color: '#fff',
                textDecoration: 'none',
            },
            '@media screen and (max-width: 760px)': {
                marginBottom: '-7px',
                fontSize: 7,
                lineHeight: '12px',
                padding: '10px 20px',
            },
        },
        helpContent: {
            width: '100%',
            height: 300,
            background: 'url("landingPageImages/bg3.png")',
            '@media screen and (max-width: 760px)': {
                width: '100%',
            },
        },
        helpHeading: {
            fontSize: 32,
            marginBottom: 20,
            color: '#fff',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            '@media screen and (max-width: 760px)': {
                width: '80%',
                margin: 'auto',
            },
        },
        emailBox: {
            fontSize: 16,
            color: '#fff',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            cursor: 'pointer',
            '@media screen and (max-width: 760px)': {
                width: '80%',
                margin: 'auto',
            },
        },
        policyDiv: {
            minHeight: '100vh',
            height: 'auto',
            width: '100%',
            padding: '150px 100px 50px 100px',
            '@media screen and (max-width: 760px)': {
                padding: '120px 20px 20px 20px',
            },
        },
        policyHeading: {
            fontSize: 26,
            color: '#3C48B5',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
        },
        caption: {
            fontSize: 15,
            color: '#333333',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
        },
        policySubtitle: {
            fontSize: 16,
            color: '#333333',
            padding: '20px 0px',
            fontFamily: theme.typography.fontFamily,
            // fontWeight: theme.typography.fontWeightBold,
            textAlign: 'justify',
        },
        policySubtitleheading: {
            fontSize: 16,
            color: '#333333',
            fontFamily: theme.typography.fontFamily,
            textAlign: 'justify',
        },
        titles: {
            fontSize: 18,
            color: '#3D49B6',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            padding: '5px 0px',
        },
        description: {
            fontSize: 16,
            color: '#333333',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            marginLeft: 20,
            textAlign: 'justify',
            marginBottom: '2.5rem',
        },
        alist: {
            marginLeft: 20,
        },
        ulist: {
            marginLeft: 40,
        },
        listTitle: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: '#333333',
            padding: '5px 0px',
        },
        termsDiv: {
            '& p': {
                fontSize: 16,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightMedium,
                textAlign: 'justify',
            },
            '& li': {
                fontSize: 16,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightMedium,
                textAlign: 'justify',
            },
        },
        slackMain: {
            paddingTop: 100,
            width: '100%',
            height: '100vh',
            padding: '20px 100px',
            background: '#3D49B6',
            backgroundImage: 'url("landingPageImages/homepage_bg.png")',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (max-width: 768px)': {
                height: 'auto',
                padding: '20px',
                paddingTop: 100,
                backgroundImage: 'url("landingPageImages/homepage_bg_mobile.png")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column-reverse',
            },
        },
        slackLink: {
            alignItems: 'center',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            // border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: 4,
            display: 'inline-flex',
            fontSize: 16,
            fontWeight: 600,
            height: 40,
            justifyContent: 'center',
            textDecoration: 'none',
            width: 180,
            '&:hover': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                // border: `1px solid ${theme.palette.text.primary}`,
                textDecoration: 'none',
            },
        },
        slackHeading: {
            fontSize: 36,
            margin: 0,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: '#fff',
            letterSpacing: '0.03em',
            '& span': {
                color: theme.palette.primary.dark,
            },
            '@media screen and (max-width: 768px)': {
                fontSize: 26,
                margin: '10px auto',
                width: '95%',
            },
        },
        slackSubtitle: {
            width: '100%',
            fontSize: 24,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: '#fff',
            letterSpacing: '0.03em',
            margin: '10px 0px',
            '@media screen and (max-width: 768px)': {
                fontSize: 16,
                margin: '10px auto',
                width: '90%',
            },
        },
        slackCaption: {
            width: '100%',
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: '#fff',
            letterSpacing: '0.03em',
            margin: '10px 0px',
            '@media screen and (max-width: 768px)': {
                fontSize: 12,
                margin: '10px auto',
                width: '90%',
            },
        },
        slackImg: {
            width: '60%',
            maxWidth: '100%',
            transform: 'skewY(-2deg)',
            height: '75%',
            marginRight: 20,
            borderRadius: 8,
            '@media screen and (max-width: 768px)': {
                height: 350,
                width: '90%',
            },
        },
        submenu: {
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            '& div': {
                display: 'none',
                '&:hover': {
                    '& ul': {
                        display: 'block',
                        width: 110,
                        height: 69,
                        background: 'white',
                        borderRadius: '3px',
                        textDecoration: 'none',
                        listStyle: 'none',
                    },
                },
            },
            '& ul': {
                display: 'none',
                border: '1px solid #D3D3D3',
            },
            '&:hover': {
                '& div': {
                    display: 'grid',
                    alignContent: 'center',
                    alignItems: 'center',
                    width: 120,
                    height: 80,
                    background: 'transparent',
                    position: 'absolute',
                    bottom: -70,
                    left: 18,
                    paddingTop: 20,
                },
                '& ul': {
                    display: 'block',
                    width: 110,
                    height: 69,
                    background: 'white',
                    borderRadius: '3px',
                    textDecoration: 'none',
                    listStyle: 'none',
                    // position: 'absolute',
                    // bottom: -80,
                    // left: 18,
                    // display: 'flex',
                    // flexDirection: 'column',
                    '& li': {
                        color: '#3D49B6',
                        height: 26,
                        margin: '5px -0.3px 5px -0.2px',
                        paddingLeft: '4px',
                        lineHeight: 2,
                        fontSize: 14,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                        },
                    },
                },
            },
        },
        submenuSmall: {
            '& ul': {
                textDecoration: 'none',
                listStyle: 'none',
                padding: '10px 20px 0px 20px',
            },
        },
        submenuDropdown: {
            height: '28px',
            padding: '0px 0px 0px 3px',
        },
        userGuideHeading: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            paddingBottom: 50,
            '& h2': {
                fontFamily: theme.typography.fontFamily,
                color: '#0A1470',
                fontSize: 40,
                fontWeight: 600,
                zIndex: 2,
            },
            '@media screen and (max-width: 768px), (max-width: 820px), (max-width: 912px)': {
                padding: '50px 0px 0px 0px',
            },
        },
        mainCardComponent: {
            width: '100%',
            height: 'auto',
            padding: '120px 0px',
            '@media screen and (max-width: 768px)': {
                height: 'auto',
                padding: '30px 0px',
            },
            '@media only screen and (min-width: 768.5px) and (max-width: 911.5px)': {
                height: 'auto',
                padding: '70px 0px',
            },
            '@media only screen and (min-width: 912px) and (max-width: 912.5px)': {
                height: '68vh',
                padding: '70px 0px',
            },
        },
        cardContainer: {
            padding: '0px 100px',
            '@media screen and (max-width: 912px)': {
                padding: '120px 20px',
            },
            '@media screen and (max-width: 820px)': {
                padding: '40px 20px',
            },
            '@media screen and (max-width: 768px)': {
                padding: 20,
            },
        },
        innerCardGrid: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        userGuideCard: {
            width: 250,
            height: 350,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            '&:hover': {
                boxShadow: '0 14px 28px rgba(0,0,0,0.25)',
            },
            '@media screen and (max-width: 912px)': {
                boxShadow: '1px 1px 5px 5px rgba(0,0,0,0.05)',
            },
            '@media screen and (max-width: 767.5px)': {
                width: '70%',
                height: 'auto',
                margin: '50px 0px',
                marginLeft: '13vw',
                boxShadow: '1px 1px 5px 5px rgba(0,0,0,0.05)',
                borderRadius: '5px',
            },
            '@media screen and (min-width: 768px) and (max-width: 899.5px)': {
                width: '70%',
                height: 'auto',
                margin: '50px 0px',
                marginLeft: '14vw',
                boxShadow: '1px 1px 5px 5px rgba(0,0,0,0.05)',
                borderRadius: '5px',
            },
        },
        userGuideImg: {
            maxWidth: '100%',
            height: '35%',
            marginTop: '30px',
        },
        userGuideContent: {
            '& h6': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 18,
                fontWeight: 'bolder',
                color: '#3D49B6',
                letterSpacing: '0.03em',
                paddingTop: '20px',
                paddingBottom: '10px',
                '@media only screen and (min-width: 768px) and (max-width: 820px)': {
                    fontSize: 18,
                },
            },
            '& p': {
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
                letterSpacing: '0.03em',
                fontSize: 14,
                '@media only screen and (min-width: 768px) and (max-width: 820px)': {
                    fontSize: 18,
                },
            },
        },
        adminGuideHeading: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            padding: '150px 80px 70px 80px',
            fontFamily: theme.typography.fontFamily,
            '& h2': {
                fontFamily: theme.typography.fontFamily,
                color: '#0A1470',
                fontSize: 34,
                fontWeight: 600,
                textAlign: 'center',
                // backgroundColor: 'yellow',
                // position: 'sticky',
                // top: '200px',
            },
            '& h6': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 20,
                paddingTop: '10px',
                letterspacing: '0.03em',
                textAlign: 'center',
                fontWeight: 500,
            },
            '@media screen and (max-width: 912px), (max-width: 820px), (max-width: 768px)': {
                padding: '150px 20px',
            },
        },
        quesOuterDiv: {
            height: 220,
            width: '100%',
            padding: '5px 0px 10px 0px',
            overflowY: 'auto',
            // boxShadow: '0px 2px rgba(0, 0, 0, 0.05)',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
            '@media screen and (max-width: 912px), (max-width: 820px), (max-width: 768px)': {
                height: 430,
            },
        },
        questionsLeft: {
            padding: '2px 0px',
            '& p': {
                cursor: 'pointer',
                padding: '5px 5px',
                margin: '0px 4px',
                fontWeight: 600,
                letterSpacing: '0.03em',
                '&:hover': {
                    boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.08)',
                },
            },
        },
        questionsLeftActive: {
            padding: '2px 0px',
            '& p': {
                cursor: 'pointer',
                padding: '5px 5px',
                margin: '0px 4px',
                fontWeight: 600,
                letterSpacing: '0.03em',
                color: '#3D49B6',
                '&:hover': {
                    boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.08)',
                },
            },
        },
        quesAnsOuterDiv: {
            padding: '10px 0px 0px 50px',
            height: 'auto',
            width: '75%',
            '& img': {
                float: 'right',
                height: 40,
                width: 40,
                cursor: 'pointer',
            },
            '@media screen and (max-width: 912px), (max-width: 820px)': {
                width: '70%',
            },
            '@media only screen and (max-width: 768px)': {
                width: '65%%',
            },
        },
        notfoundImage: {
            alignSelf: 'center',
            width: '30%',
            height: '30%',
        },
        notfoundText: {
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 16,
            fontWeight: 600,
        },
        searchbarOuterDiv: {
            display: 'flex',
        },
        searchbarInnerDiv: {
            display: 'flex',
            flexDirection: 'column',
            width: '25%',
            height: 330,
            position: 'sticky',
            top: 75,
            boxShadow: '0px 2px rgba(0, 0, 0, 0.07)',
            '@media screen and (max-width: 912px), (max-width: 820px)': {
                width: '30%',
            },
            '@media only screen and (max-width: 768px)': {
                width: '35%',
            },
        },
        searchbarDiv: {
            width: '100%',
            margin: '35px 0px 27px 0px',
            '@media only screen and (max-width: 768px)': {
                width: '95%',
            },
        },
        // searchbarElseDiv: {
        //     width: '100%',
        //     margin: '35px 0px 27px 0px',
        // },
        searchbarRect: {
            width: '95%',
            // background: '#D9DBE5',
            height: 30,
            borderRadius: '5px',
            alignSelf: 'center',
            marginBottom: 5,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 10px',
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            outline: 'none',
            backgroundColor: theme.palette.background.paper,
        },
        searchBarInput: {
            flex: 1,
            outline: 'none',
            border: 'none',
            fontSize: 16,
            fontFamily: 'Nunito Sans',
            color: theme.palette.text.primary,
            // border: `1px solid ${theme.palette.primary.contrastText}`,
            backgroundColor: theme.palette.background.paper,
        },
        adminGuideHeadingSmall: {
            width: '100%',
            minHeight: '850px',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            padding: '100px 15px 40px 15px',
            '& h2': {
                fontFamily: theme.typography.fontFamily,
                color: '#0A1470',
                fontSize: 34,
                fontWeight: 600,
                textAlign: 'center',
            },
            '& h6': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 20,
                letterspacing: '0.03em',
                textAlign: 'center',
                fontWeight: 500,
            },
        },
        searchbarRectSmall: {
            width: '100%',
            height: 30,
            borderRadius: '5px',
            alignSelf: 'center',
            marginBottom: 5,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 10px',
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            outline: 'none',
            backgroundColor: theme.palette.background.paper,
        },
        searchbarInnerDiv1: {
            display: 'flex',
            flexDirection: 'column',
            width: '25%',
            // height: 200,
            position: 'sticky',
            top: 75,
            // boxShadow: '0px 2px rgba(0, 0, 0, 0.07)',
            '@media screen and (max-width: 912px), (max-width: 820px)': {
                width: '30%',
            },
            '@media only screen and (max-width: 768px)': {
                width: '35%',
            },
        },
        searchbarInnerDiv2: {
            display: 'flex',
            flexDirection: 'column',
            width: '25%',
            height: 'auto',
            // position: 'sticky',
            top: 75,
            // position: 'sticky',
            // top: '4rem',
            '@media screen and (max-width: 912px), (max-width: 820px)': {
                width: '30%',
            },
            '@media only screen and (max-width: 768px)': {
                width: '35%',
            },
        },
        resourcesSmall: {
            fontWeight: 'bold',
            color: '#000',
            margin: '25px 0px 10px 0px',
            fontSize: '16px',
            alignSelf: 'center',
            '& a': {
                textDecoration: 'none',
                color: '#000',
            },
            '& span': {
                color: '#3D49B6',
            },
        },
        quesAnsDivSmall: {
            padding: '0px 5px',
            height: 'auto',
            '& p:nth-child(1)': {
                marginBottom: '3px',
                color: '#3D49B6',
                letterSpacing: '0.03em',
                fontSize: 16,
                fontWeight: 700,
            },
            '& p:nth-child(2)': {
                paddingBottom: '25px',
                textAlign: 'justify',
                letterSpacing: '0.03em',
                fontSize: 14,
            },
        },
        notfoundImageSmall: {
            alignSelf: 'center',
            width: '100%',
            height: '120%',
            position: 'relative',
            paddingTop: '50px',
        },
        mark: {
            background: 'yellow',
        },
        refundDescription: {
            fontSize: 16,
            color: '#333333',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            textAlign: 'justify',
        },
        refundDescription1: {
            fontSize: 16,
            color: '#333333',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            marginBottom: 15,
            marginLeft: 20,
        },
        description1: {
            fontSize: 16,
            color: '#333333',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            marginLeft: 50,
            marginBottom: 15,
        },
        marginbottom: {
            marginBottom: '2.5rem',
        },
        // divstyle: {
        //     height: 75,
        //     backgroundColor: '#fff',
        //     position: 'sticky',
        //     top: '97px',
        //     marginBottom: '5px',
        // '& p': {
        //     fontWeight: 'bold',
        //     color: '#000',
        //     margin: '10px 0px 45px 0px',
        //     fontSize: '16px',
        //     paddingTop: '14px',
        // },
        // },
        pques: {
            marginBottom: '3px',
            color: '#3D49B6',
            letterSpacing: '0.03em',
            fontSize: 18,
            fontWeight: theme.typography.fontWeightBold,
        },
        pans: {
            paddingBottom: '25px',
            textAlign: 'justify',
            letterSpacing: '0.03em',
            fontSize: 14,
        },
    }),
);
export default useStyles;
