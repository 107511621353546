/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Drawers, Auto, AutoTags } from './StandupTable';
import useStyles from './StandupNoteStyles';
import {
    Grid,
    Button,
    // TextField,
    Chip,
    Avatar,
    // Checkbox,
    Divider,
    Hidden,
    Card,
    Modal,
    Paper,
    FormLabel,
    Drawer,
    Popover,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getFullName } from 'helpers/getInitials';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import AuthContext from 'context/AuthContext';
import { deleteStandupNote } from 'helpers/hasuraapi3';
import { useSelector } from 'react-redux';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';
import CloseIcon from '@material-ui/icons/Close';
import AddNotes from './AddNote';
import { ToStringFormat } from 'helpers/date';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { useTheme } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import { isoToLocalTime, isoToLocal, getCurrentCountryDateTime } from '../../helpers/timezone';
import { useHistory } from 'react-router-dom';
import { deleteStandupMessage } from './SendChatMessages';
// import { handleStandup } from 'helpers/standupNotif';

interface Sidebar {
    open?: boolean;
}

interface DeleteModal {
    handleDelete?: any;
    standupNoteId?: any;
    open2?: any;
    setOpen2?: any;
}
export function DeleteModal(props: DeleteModal) {
    const classes = useStyles();
    return (
        <>
            <Modal open={props?.open2} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div className={classes.modal1}>
                    <Paper className={classes.paper1}>
                        <Grid container className={classes.deleteModalContainer}>
                            <Grid item xs={12} sm={12} style={{ marginBottom: '20px', marginTop: '15px' }}>
                                <FormLabel className={classes.deltitle}>Delete Standup</FormLabel>
                            </Grid>

                            <Grid item xs={12} sm={12} style={{ marginBottom: '35px' }}>
                                <p style={{ maxWidth: '100%', wordWrap: 'break-word' }} className={classes.delbod}>
                                    Are you sure you want to delete your standup ?
                                </p>
                            </Grid>

                            <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.btnOutlined}
                                    onClick={() => props?.setOpen2(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id="confirm-delete"
                                    variant="contained"
                                    color="primary"
                                    className={classes.btn3}
                                    onClick={() => props?.handleDelete(props?.standupNoteId)}
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                        <CloseIcon
                            data-testid="close"
                            onClick={() => props?.setOpen2(false)}
                            style={{ position: 'absolute', top: 18, right: 18, cursor: 'pointer' }}
                        />
                    </Paper>
                </div>
            </Modal>
        </>
    );
}

interface UserStandupProps {
    profileImage?: any;
    firstName?: string;
    lastName?: string;
    standupNoteTime: string;
    questions?: any;
    answers?: any;
    isAccomplished?: any;
    employeeId?: any;
    standupNoteId?: any;
    handleDelete?: any;
    open2?: any;
    setOpen2?: any;
    tags?: any;
    handleModal?: any;
    drawerOpen?: any;
    mycontext?: any;
}

export function UserStandup(props: UserStandupProps) {
    const classes = useStyles();
    const theme = useTheme();
    // const history = useHistory();
    const handlePopUp = () => {
        props?.setOpen2(true);
    };
    const lastName: any = props.lastName;
    const fullName: any = props?.firstName?.concat(' ', lastName);

    return (
        <>
            {props?.employeeId === props.mycontext.EmployeeId ? (
                <DeleteModal
                    open2={props?.open2}
                    setOpen2={props?.setOpen2}
                    handleDelete={props?.handleDelete}
                    standupNoteId={props?.standupNoteId}
                />
            ) : null}
            <Hidden mdDown>
                <div>
                    <Grid container id={props.employeeId} direction="column">
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }} spacing={2}>
                                <Grid item>
                                    <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid item>
                                            <Avatar
                                                data-tetsid="avatar"
                                                style={{
                                                    width: '30px',
                                                    fontSize: 12,
                                                    height: '30px',
                                                    backgroundColor: '#EDEFFA',
                                                    color: '#444444',
                                                    marginRight: '6px',
                                                }}
                                                src={props?.profileImage}
                                            >
                                                {getFullName(props?.firstName + ' ' + props?.lastName)}
                                            </Avatar>
                                        </Grid>
                                        <Grid item className={classes.name}>
                                            <div className={classes.nameText}>
                                                {props?.firstName} {props?.lastName}
                                            </div>
                                        </Grid>
                                        <Grid item style={{ marginRight: '30px', marginTop: '5px' }}>
                                            <div style={{ color: theme.palette.text.primary }}>
                                                {isoToLocalTime(props?.standupNoteTime, props.mycontext.country)}
                                            </div>
                                        </Grid>
                                        <div style={{ display: 'flex', marginTop: '7px', flexWrap: 'wrap' }}>
                                            {props?.tags?.map((a: any, i: number) => (
                                                <Grid
                                                    item
                                                    key={i}
                                                    style={{
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    <Chip
                                                        style={{
                                                            background: a?.color,
                                                            color: 'white',
                                                            height: '20px',
                                                            marginRight: '4px',
                                                        }}
                                                        label={a?.name}
                                                    />
                                                </Grid>
                                            ))}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        style={{
                                            width: 80,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '11px',
                                        }}
                                    >
                                        {!props.mycontext.isAdmin ? (
                                            <>
                                                <Grid item>
                                                    {props?.employeeId === props.mycontext.EmployeeId ? (
                                                        <FiEdit
                                                            data-testid="fiEdit"
                                                            size="22px"
                                                            onClick={props?.handleModal}
                                                            style={{ marginLeft: '-10px' }}
                                                            // style={{marginLeft:""}}
                                                            className={classes.deleteIcon}
                                                        />
                                                    ) : null}
                                                </Grid>
                                                <Grid item>
                                                    {props?.employeeId === props.mycontext.EmployeeId ? (
                                                        <RiDeleteBinLine
                                                            data-testid="abcd"
                                                            onClick={handlePopUp}
                                                            size="22px"
                                                            style={{ marginRight: '25px' }}
                                                            className={classes.deleteIcon}
                                                        />
                                                    ) : null}
                                                </Grid>
                                            </>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <div className={classes.text1}>Did you accomplish yesterday’s task ?</div>
                            <div className={classes.text4} style={{ marginBottom: 0 }}>
                                {props?.isAccomplished === true ? (
                                    <span data-testid="yes">Yes</span>
                                ) : (
                                    <span data-testid="no">No</span>
                                )}
                            </div>
                            {props?.answers?.map((e: any, j: number) => (
                                <div key={j}>
                                    <div className={classes.text1}>{e.question}</div>
                                    <div
                                        style={{ marginBottom: '2px', maxWidth: '100%', wordBreak: 'break-word' }}
                                        className={classes.text4}
                                    >
                                        {e.answer}
                                    </div>
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                </div>
                <Divider
                    style={{
                        marginBottom: '20px',
                        marginTop: '20px',
                        backgroundColor: theme.palette.background.default,
                    }}
                />
            </Hidden>
            <Hidden lgUp>
                <Grid container id={props.employeeId} direction="column">
                    <Card elevation={0} style={{ marginTop: '8px', padding: '20px 20px 5px 20px' }}>
                        <Grid item>
                            <Grid
                                container
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Grid item>
                                    <Grid container>
                                        <Grid item>
                                            <Avatar
                                                style={{
                                                    width: '30px',
                                                    fontSize: 12,
                                                    height: '30px',
                                                    backgroundColor: '#EDEFFA',
                                                    color: '#444444',
                                                }}
                                                src={props?.profileImage}
                                            >
                                                {getFullName(props?.firstName + ' ' + props?.lastName)}
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <div className={classes.name}>
                                                {fullName?.length >= 14 ? `${fullName?.substring(0, 11)}...` : fullName}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className={classes.time} style={{ marginTop: '-1px' }}>
                                                {isoToLocalTime(props?.standupNoteTime, props.mycontext.country)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        style={{
                                            width: 60,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {!props.mycontext.isAdmin ? (
                                            <>
                                                <Grid item>
                                                    {props?.employeeId === props.mycontext.EmployeeId ? (
                                                        <FiEdit
                                                            data-testid="fiEdit"
                                                            size="22px"
                                                            onClick={props?.handleModal}
                                                            className={classes.deleteIcon}
                                                        />
                                                    ) : null}
                                                </Grid>
                                                <Grid item>
                                                    {props?.employeeId === props.mycontext.EmployeeId ? (
                                                        <RiDeleteBinLine
                                                            onClick={handlePopUp}
                                                            size="22px"
                                                            className={classes.deleteIcon}
                                                        />
                                                    ) : null}
                                                </Grid>
                                            </>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div style={{ display: 'flex', paddingLeft: '40px', flexWrap: 'wrap' }}>
                                {props?.tags?.map((a: any, i: number) => (
                                    <Grid
                                        item
                                        key={i}
                                        style={{
                                            paddingBottom: '10px',
                                        }}
                                    >
                                        <Chip
                                            style={{
                                                background: a?.color,
                                                color: 'white',
                                                height: '15px',
                                                marginRight: '4px',
                                            }}
                                            label={a?.name}
                                        />
                                    </Grid>
                                ))}
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.text3} style={{ marginTop: '10px' }}>
                                Did you accomplish yesterday’s task ?
                            </div>
                            <div className={classes.text3}>{props?.isAccomplished === true ? 'Yes' : 'No'}</div>
                            {props?.answers?.map((e: any, j: number) => (
                                <div key={j}>
                                    <div className={classes.text3}>{e?.question}</div>
                                    <div className={classes.text6}>{e?.answer}</div>
                                </div>
                            ))}
                        </Grid>
                    </Card>
                </Grid>
            </Hidden>
        </>
    );
}

interface StandupProps {
    open?: any;
    setSidebar?: any;
    setIsMobile?: any;
    click?: any;
    setClick?: any;
    date?: string;
    setDate?: any;
    handleClear?: any;
    name?: any;
    setName?: any;
    allEmployee?: any;
    tags?: any;
    setTags?: any;
    allTags?: any;
    data?: any;
    setStep?: any;
    questions?: any;
    handleDelete?: any;
    handleModal?: any;
    open2?: any;
    setOpen2?: any;
    drawerOpen?: any;
    answers?: any;
    mycontext?: any;
    setNameOpen?: any;
    nameOpen?: any;
    teams?: any;
    teamList?: any;
    setTeamList?: any;
}

export function Standup(props: StandupProps) {
    // console.log('datA', props.data);
    const classes = useStyles();
    const [filterIcon, setFilterIcon] = React.useState(false);
    const [anchorEl2, setAnchorEl2] = React.useState<any>(null);
    const theme = useTheme();
    const handleClick = (e: any) => {
        setFilterIcon(!filterIcon);
        setAnchorEl2(e.currentTarget);
    };

    return (
        <>
            <div style={{ width: '100%', height: '100%', paddingTop: '10px' }}>
                <Hidden mdDown>
                    <Popover
                        id="picker-popover"
                        open={filterIcon}
                        anchorEl={anchorEl2}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handleClick}
                        data-testid="filterModal"
                    >
                        <ul className={classes.filterList2}>
                            {(props?.name?.length !== 0 ||
                                props?.tags?.length !== 0 ||
                                props?.teamList?.length !== 0) && (
                                <div
                                    data-testid="clearAll"
                                    style={{ marginLeft: '110px' }}
                                    onClick={props?.handleClear}
                                    // style={{ width: '100%' }}
                                    className={classes.clear}
                                >
                                    Clear all
                                </div>
                            )}
                            <li className={classes.filterName}>
                                <Auto
                                    setSideBar={props.setSidebar}
                                    name={props?.name}
                                    setName={props?.setName}
                                    open={props.nameOpen}
                                    setOpen={props.setNameOpen}
                                    allEmployees={props?.allEmployee}
                                    button={false}
                                />
                            </li>
                            <li className={classes.filterName}>
                                <AutoTags
                                    tags={props?.tags}
                                    allTags={props?.allTags}
                                    open={props.nameOpen}
                                    setOpen={props.setNameOpen}
                                    setTags={props?.setTags}
                                    button={false}
                                    setSideBar={props.setSidebar}
                                />
                            </li>
                        </ul>
                    </Popover>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingBottom: 20,
                        }}
                    >
                        <div>
                            <Button
                                data-testid="abcd"
                                style={
                                    filterIcon === false
                                        ? { color: '#AAAAAA', border: `1px solid #AAAAAA` }
                                        : {
                                              border: `1px solid ${theme.palette.text.secondary}`,
                                              color: theme.palette.text.secondary,
                                          }
                                }
                                className={classes.btn}
                                onClick={handleClick}
                                variant="outlined"
                                disableElevation
                                aria-describedby="filter-popup"
                                startIcon={
                                    <Icon
                                        className={props.open ? classes.filterIcon : undefined}
                                        icon="bx:filter-alt"
                                        data-width="20"
                                        data-height="20"
                                    />
                                }
                                endIcon={<ArrowDropDown style={{ display: 'flex' }} />}
                            >
                                Filter By
                            </Button>
                        </div>
                    </div>
                </Hidden>
                <div className={classes.scrollBar} style={{ scrollBehavior: 'smooth' }}>
                    {props?.data?.map((e: any, i: number) => (
                        <UserStandup
                            key={i}
                            profileImage={e?.Employee?.profileImage}
                            firstName={e?.Employee?.firstName}
                            lastName={e?.Employee?.lastName}
                            standupNoteTime={e?.standupNoteDateTime}
                            tags={e?.tags}
                            isAccomplished={e?.isAccomplished}
                            employeeId={e?.employeeId}
                            answers={e?.answers}
                            standupNoteId={e?.standupNoteId}
                            handleDelete={props?.handleDelete}
                            open2={props?.open2}
                            setOpen2={props?.setOpen2}
                            handleModal={props?.handleModal}
                            mycontext={props.mycontext}
                        />
                    ))}
                </div>
                <Hidden lgUp>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button2}
                                onClick={() => props?.setStep(0)}
                                disableElevation
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={props?.drawerOpen}
                                className={classes.button1}
                            >
                                Filter By
                            </Button>
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
        </>
    );
}

interface MobileDrawerProps {
    openDrawer?: any;
    drawerClose?: any;
    allEmployee?: any;
    name?: any;
    setName?: any;
    setSidebar?: any;
    setIsMobile?: any;
    tags?: any;
    setTags?: any;
    allTags?: any;
    setDrawer?: any;
    isAccomplished?: any;
    nameOpen?: any;
    setNameOpen?: any;
}

export const MobileDrawer = (props: MobileDrawerProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const cancel = () => {
        props?.setTags([]);
        props?.setName([]);
    };

    const handleClose = () => {
        props?.setIsMobile(true);
        props?.drawerClose(false);
    };

    return (
        <Drawer
            PaperProps={{
                className: classes.scroll,
                style: { borderTopLeftRadius: 10, borderTopRightRadius: 10, marginLeft: 3, marginRight: 3 },
            }}
            variant="temporary"
            anchor={theme?.direction === 'rtl' ? 'top' : 'bottom'}
            open={props?.openDrawer}
            onClose={() => props?.drawerClose(false)}
        >
            <div style={{ padding: '20px 20px 0px 20px' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid',
                        paddingBottom: '15px',
                    }}
                >
                    <div>
                        <div className={classes.text}>
                            <span
                                className="iconify"
                                data-icon="bx:bx-filter-alt"
                                data-width="20"
                                data-height="20"
                                style={{ marginRight: '7px', marginBottom: '3px' }}
                            ></span>
                            Filter By
                        </div>
                    </div>
                    {(props?.name?.length !== 0 || props?.tags?.length !== 0) && (
                        <span onClick={cancel} className={classes.text} style={{ textDecoration: 'underline' }}>
                            Clear all
                        </span>
                    )}
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className={classes.boxes}>
                        <div className={classes.text2}>Name</div>
                        <div className={classes.boxes2}>
                            <Auto
                                name={props?.name}
                                setName={props?.setName}
                                open={props.nameOpen}
                                setOpen={props.setNameOpen}
                                allEmployees={props?.allEmployee}
                                button={true}
                            />
                        </div>
                    </div>
                    <div className={classes.boxes}>
                        <div className={classes.text2}>Tags</div>
                        <div className={classes.boxes2}>
                            <AutoTags
                                tags={props?.tags}
                                allTags={props?.allTags}
                                open={props.nameOpen}
                                setOpen={props.setNameOpen}
                                setTags={props?.setTags}
                                button={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Button variant="contained" onClick={handleClose} className={classes.apply}>
                Apply Filter
            </Button>
        </Drawer>
    );
};

interface DrawerContentProps {
    setSidebar?: any;
    setIsMobile?: any;
    isMobile?: boolean;
    setClick?: any;
    setName?: any;
    setTags?: any;
    questions?: any;
    answers?: any;
    profileImage?: any;
    firstName?: string;
    lastName?: string;
    standupNoteTime?: string;
    tags?: any;
    isAccomplished?: any;
    drawerOpen?: any;
}

export const DrawerContent = (props: DrawerContentProps) => {
    const classes = useStyles();

    const theme = useTheme();
    // console.log(props.tags);
    return (
        <div>
            <Hidden mdDown>
                <div>
                    <Grid container>
                        <Grid item style={{ position: 'relative', paddingBottom: '10px' }}>
                            <Grid container spacing={1} style={{ paddingTop: '15px', paddingBottom: '10px' }}>
                                <Grid item style={{ padding: '0px 10px 0px 15px' }}>
                                    <Avatar
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                            backgroundColor: '#EDEFFA',
                                            color: '#444444',
                                            fontSize: 12,
                                        }}
                                        src={props?.profileImage}
                                    >
                                        {getFullName(props?.firstName + ' ' + props?.lastName)}
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <div style={{ padding: '0px 10px 0px 10px' }} className={classes.text2}>
                                        {props?.firstName} {props?.lastName}
                                    </div>
                                </Grid>
                                <Grid item xs={4} style={{ padding: '5px 10px 0px 10px', marginTop: '2px' }}>
                                    <div style={{ color: theme.palette.text.primary }}>{props?.standupNoteTime}</div>
                                </Grid>
                            </Grid>
                            <div style={{ display: 'flex', paddingLeft: '70px', marginTop: '-15px', flexWrap: 'wrap' }}>
                                {props?.tags?.map((a: any, i: number) => (
                                    <Grid
                                        item
                                        key={i}
                                        style={{
                                            paddingBottom: '10px',
                                        }}
                                    >
                                        <Chip
                                            style={{
                                                background: a?.color,
                                                color: 'white',
                                                height: '15px',
                                                marginRight: '4px',
                                            }}
                                            label={a?.name}
                                        />
                                    </Grid>
                                ))}
                            </div>
                            <Grid
                                container
                                style={{
                                    paddingTop: '10px',
                                    paddingLeft: '8px',
                                }}
                            >
                                <Grid item>
                                    <span style={{ paddingLeft: '8px' }} className={classes.text2}>
                                        Did you accomplish yesterday’s task?
                                    </span>
                                </Grid>
                                <div style={{ width: '100%', paddingLeft: '8px', paddingBottom: '9px' }}>
                                    <div style={{ marginBottom: '10px', color: theme.palette.text.primary }}>
                                        {props?.isAccomplished === true ? 'Yes' : 'No'}
                                    </div>
                                    {props?.answers?.map((e: any, j: number) => (
                                        <div key={j}>
                                            <div className={classes.text2}>{e?.question}</div>
                                            <div
                                                className={classes.text4}
                                                style={{ maxWidth: '100%', wordBreak: 'break-word' }}
                                            >
                                                {e?.answer}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Hidden>
            <Hidden lgUp>
                <div>
                    <div className={classes.scroll0}>
                        <Grid container direction="column">
                            <Card elevation={0} style={{ marginTop: '8px', padding: '20px 20px 5px 20px' }}>
                                <Grid item>
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item>
                                                    <Avatar
                                                        style={{
                                                            width: '30px',
                                                            fontSize: 12,
                                                            height: '30px',
                                                            backgroundColor: '#EDEFFA',
                                                            color: '#444444',
                                                        }}
                                                        src={props?.profileImage}
                                                    >
                                                        {getFullName(props?.firstName + ' ' + props?.lastName)}
                                                    </Avatar>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.name}>
                                                        {props?.firstName} {props?.lastName}
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.time} style={{ marginTop: '-1px' }}>
                                                        {props?.standupNoteTime}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <div style={{ display: 'flex', paddingLeft: '40px', flexWrap: 'wrap' }}>
                                        {props?.tags?.map((a: any, i: number) => (
                                            <Grid
                                                item
                                                key={i}
                                                style={{
                                                    paddingBottom: '10px',
                                                }}
                                            >
                                                <Chip
                                                    style={{
                                                        background: a?.color,
                                                        color: 'white',
                                                        height: '15px',
                                                        marginRight: '4px',
                                                    }}
                                                    label={a?.name}
                                                />
                                            </Grid>
                                        ))}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.text3} style={{ marginTop: '10px' }}>
                                        Did you accomplish yesterday’s task ?
                                    </div>
                                    <p style={{ width: '100%' }}>{props?.isAccomplished === true ? 'Yes' : 'No'}</p>
                                    {/* <div className={classes.text1}></div> */}
                                    <Grid item style={{ paddingTop: '2px' }}>
                                        {props?.answers?.map((x: any, j: number) => (
                                            <div key={j}>
                                                <div className={classes.text2}>
                                                    <span>{x?.question}</span>
                                                </div>
                                                <p style={{ maxWidth: '100%', wordBreak: 'break-word' }}>{x?.answer}</p>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </div>
                </div>
            </Hidden>
        </div>
    );
};
interface StandupNoteProps {
    standupd?: any;
    questions?: any;
    open?: any;
    setModal?: any;
    data?: any;
    tags?: any;
    allStandups?: any;
    date?: any;
    setDate?: any;
    ans?: any;
    setAns?: any;
    todos?: any;
    setTodos?: any;
    check?: any;
    modal?: any;
    setCheck?: any;
    modalAns?: any;
    setStep?: any;
    today?: any;
    myData?: any;
    modalDate?: any;
    myteams?: any;
}

export default function StandupNote(props: StandupNoteProps) {
    const classes = useStyles();
    const [sidebar, setSidebar] = React.useState<Sidebar>({
        open: false,
    });
    const history = useHistory();
    const mycontext = useContext(AuthContext);
    const [name, setName] = React.useState<any>([]);
    const [tags, setTags] = React.useState<any>([]);
    const [alltags, setAllTags] = React.useState([]);
    const [click, setClick] = React.useState(false);
    const state = useSelector((state: any) => state);
    const userSubscriptionDetail = state?.subscription.userSubscriptions;
    const organiztionEmployee = state?.orgEmployees;
    const [myData, setMyData] = React.useState<any>([]);
    const [open2, setOpen2] = React.useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState<any>(false);
    const [myStandup, setMyStandup] = React.useState<any>([]);
    const drawerOpen = () => {
        setDrawer(true);
    };
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const teams = useSelector((state: any) => state.teams.teams);
    const [teamList, setTeamList] = React.useState<any>([]);
    const handleClear = () => {
        setName([]);
        setTeamList([]);
        setTags([]);
        setSidebar({ open: false });
    };

    // console.log(props.standupd);
    const handleModal = () => {
        props.setDate(ToStringFormat(props.standupd, 'YYYY-MM-DD'));
        const z: any = props?.allStandups
            ?.filter(
                (e: any) =>
                    isoToLocal(e?.standupNoteDateTime, mycontext.country)
                        .toString()
                        .substring(0, 15) === props?.standupd && e?.employeeId === mycontext?.EmployeeId,
            )
            ?.map((c: any) => c);
        if (z?.length !== 0) {
            const answers: any = z[0].answers?.map((f: any) => f?.answer);
            // console.log(answers);
            for (let i = 0; i < answers?.length; i++) {
                props.setAns((prev: any) => ({ ...prev, [i]: answers[i] }));
            }
            setMyStandup(z);

            if (z[0]?.isAccomplished === true) {
                props?.setCheck('Yes');
            } else {
                props?.setCheck('No');
            }
            props?.setTodos([]);
            props?.setTodos(z[0]?.tags);
        }
        props?.setModal(true);
    };

    React.useMemo(() => {
        const fetchData = async () => {
            // let abcd: any;
            const arr: any = name?.map((a: any) => a?.employeeId);
            const arr2: any = tags?.map((b: any) => b?.name);
            setMyData([]);
            if (arr?.length === 0) {
                const c: any = props.myData?.filter(
                    (s: any) => s?.tags?.filter((t: any) => arr2?.includes(t?.name))?.length !== 0,
                );
                setMyData(c);
            } else if (arr2?.length === 0) {
                // console?.log(arr2?.length);
                const b: any = props.myData?.filter((s: any) => arr?.includes(s?.employeeId));
                setMyData(b);
            } else {
                const a: any = props.myData?.filter(
                    (s: any) =>
                        arr?.includes(s?.employeeId) &&
                        s?.tags?.filter((t: any) => arr2?.includes(t?.name))?.length !== 0,
                );
                setMyData(a);
            }
        };
        fetchData();
    }, [name, tags, props.myData]);

    React.useEffect(() => {
        const z: any = [];
        // if (props.myData?.map((a: any) => a.tags === '' || a.tags === 'null' || a.tags === null)) {
        //     return;
        // } else {
        props.myData?.map((a: any) =>
            a?.tags?.map((b: any) => {
                z.push(b);
            }),
        );
        const arr: any = z?.filter((a: any, i: any) => z?.findIndex((s: any) => a?.name === s?.name) === i);
        setAllTags(arr);
        // }
    }, [props.myData]);

    const handleClick = () => {
        isMobile ? setIsMobile(false) : setSidebar({ open: false });
        setClick(false);
        setTags([]);
        setName([]);
    };

    const handleDelete = async (id: any) => {
        // console?.log('id', id);
        // let notesData = null;
        const standups: any[] = props?.allStandups?.filter(
            (e: any) =>
                isoToLocal(e?.standupNoteDateTime, mycontext.country)
                    .toString()
                    .substring(0, 15) === props?.standupd && e?.employeeId === mycontext?.EmployeeId,
        );
        const todaysStandup: any[] = props?.allStandups?.filter(
            (e: any) =>
                isoToLocal(e?.standupNoteDateTime, mycontext.country)
                    .toString()
                    .substring(0, 15) === props?.standupd,
        );
        // console.log("mystandup", standups, todaysStandup);
        try {
            history.push({
                pathname: `/dashboard`,
            });
            const data: any = await deleteStandupNote(id, mycontext?.token);
            // notesData = data.returning[0];
            if (data?.error) {
                throw new Error('error');
            }
            const channels = props.myteams.filter(
                (t: any) => t.msChannelId !== null && t.isbotAdded['standup'] !== undefined,
            );
            if (channels.length !== 0) {
                await deleteStandupMessage(
                    standups[0]?.msgId,
                    channels[0]?.msChannelId,
                    channels[0]?.channelServiceUrl.standup,
                );
                if (standups.length === todaysStandup.length) {
                    await deleteStandupMessage(
                        standups[0]?.threadId,
                        channels[0]?.msChannelId,
                        channels[0]?.channelServiceUrl.standup,
                    );
                }
            }
            mycontext.togglePopup({
                alertMessage: 'Standup deleted successfully!',
                severity: 'success',
            });
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
        setOpen2(false);
        setTimeout(() => {
            props?.setStep(0);
        }, 1000);

        // handleStandup({
        //     action: 'delete',
        //     date: notesData?.standupNoteDate,
        //     newThreadId: notesData?.threadId,
        //     standupId: id,
        //     ts: notesData?.timestamp,
        //     orgId: mycontext.organizationId,
        //     token: mycontext.token,
        // });
    };

    if (isMobile) {
        return (
            <div>
                {myData?.length === 0 ? (
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                                style={{ display: 'flex', justifyContent: 'center' }}
                                src={upcomingTimeoff}
                                alt="No Teams found"
                                className={classes.notfoundImage}
                            />
                        </div>
                        <div className={classes.notfoundText}>No data found</div>
                    </div>
                ) : (
                    myData?.map((e: any, i: number) => (
                        <DrawerContent
                            key={i}
                            setSidebar={setSidebar}
                            setClick={setClick}
                            setName={setName}
                            setTags={setTags}
                            setIsMobile={setIsMobile}
                            profileImage={e?.Employee?.profileImage}
                            firstName={e?.Employee?.firstName}
                            lastName={e?.Employee?.lastName}
                            standupNoteTime={e?.standupNoteTime}
                            tags={e?.tags}
                            isAccomplished={e?.isAccomplished}
                            answers={e?.answers}
                            drawerOpen={drawerOpen}
                        />
                    ))
                )}
                <Grid container justifyContent="center">
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button2}
                            onClick={handleClick}
                            disableElevation
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => drawerOpen()}
                            className={classes.button1}
                        >
                            Filter By
                        </Button>
                    </Grid>
                </Grid>
                <MobileDrawer
                    openDrawer={drawer}
                    drawerClose={setDrawer}
                    name={name}
                    setName={setName}
                    allEmployee={organiztionEmployee?.employees}
                    setIsMobile={setIsMobile}
                    tags={tags}
                    setTags={setTags}
                    allTags={alltags}
                    setDrawer={setDrawer}
                    nameOpen={open}
                    setNameOpen={setOpen}
                />
            </div>
        );
    }
    const Back = () => {
        props?.setStep(0);
        history.push({
            pathname: `/dashboard`,
        });
    };
    // console?.log('cs', data);
    return (
        <>
            <div className={classes.border}>
                <div className={sidebar?.open ? classes.leftHalf : classes.leftFull}>
                    <Hidden mdDown>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid container>
                                <Grid item>
                                    <ArrowBackIcon className={classes.backArrow} onClick={Back} />
                                </Grid>
                                <Grid item>
                                    <div className={classes.standup}>Standup Notes</div>
                                </Grid>
                            </Grid>
                            <div
                                style={{
                                    width: '180px',
                                    fontWeight: 550,
                                    fontSize: '15px',
                                    color: theme.palette.text.primary,
                                }}
                            >
                                {props.standupd ===
                                getCurrentCountryDateTime(mycontext.country.zoneName)
                                    .dateTime.toString()
                                    .substring(0, 15)
                                    ? 'Today'
                                    : `${ToStringFormat(props.standupd, mycontext.dateFormat)}`}
                            </div>
                        </div>
                    </Hidden>
                    <Hidden lgUp>
                        <Card elevation={0} className={classes.shift3}>
                            <div className={classes.shift2}>
                                {props.standupd ===
                                getCurrentCountryDateTime(mycontext.country.zoneName)
                                    .dateTime.toString()
                                    .substring(0, 15)
                                    ? 'Today'
                                    : `${ToStringFormat(props.standupd, mycontext.dateFormat)}`}
                            </div>
                        </Card>
                    </Hidden>
                    <Standup
                        setSidebar={setSidebar}
                        open={sidebar?.open}
                        click={click}
                        setClick={setClick}
                        handleClear={handleClear}
                        name={name}
                        setName={setName}
                        allEmployee={organiztionEmployee?.employees}
                        tags={tags}
                        setTags={setTags}
                        allTags={alltags}
                        data={props.myData}
                        setDate={props?.setDate}
                        handleDelete={handleDelete}
                        questions={props?.questions}
                        handleModal={handleModal}
                        open2={open2}
                        setOpen2={setOpen2}
                        drawerOpen={drawerOpen}
                        setStep={props?.setStep}
                        mycontext={mycontext}
                        nameOpen={open}
                        setNameOpen={setOpen}
                        teamList={teamList}
                        setTeamList={setTeamList}
                        teams={teams}
                        // answers={answer}
                    />
                </div>
                <Hidden mdDown>
                    <Drawers sidebar={sidebar}>
                        <div>
                            {myData?.length === 0 ? (
                                <div id="noResultFound" className={classes.fontsty} style={{ borderBottom: 'none' }}>
                                    <img
                                        src={upcomingTimeoff}
                                        alt="No standups found"
                                        className={classes.notfoundImage}
                                    />
                                    <div className={classes.notfoundText}>No data found</div>
                                </div>
                            ) : (
                                <div className={classes.scroll0}>
                                    {myData?.map((e: any, i: number) => (
                                        <div key={i}>
                                            <DrawerContent
                                                setSidebar={setSidebar}
                                                setClick={setClick}
                                                setName={setName}
                                                setTags={setTags}
                                                setIsMobile={setIsMobile}
                                                profileImage={e?.Employee?.profileImage}
                                                firstName={e?.Employee?.firstName}
                                                lastName={e?.Employee?.lastName}
                                                standupNoteTime={e?.standupNoteTime}
                                                tags={e?.tags}
                                                isAccomplished={e?.isAccomplished}
                                                answers={e?.answers}
                                            />
                                            <Divider
                                                style={{
                                                    backgroundColor: theme.palette.background.default,
                                                    width: '95%',
                                                    marginLeft: '2.5%',
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            <CloseIcon
                                onClick={handleClick}
                                className={classes.icon}
                                // style={{ position: 'absolute', top: 18, right: 18, cursor: 'pointer' }}
                            />
                        </div>
                    </Drawers>
                </Hidden>
                <Hidden lgUp>
                    <MobileDrawer
                        openDrawer={drawer}
                        drawerClose={setDrawer}
                        // setDate={setDate}
                        name={name}
                        setName={setName}
                        allEmployee={organiztionEmployee?.employees}
                        setIsMobile={setIsMobile}
                        tags={tags}
                        setTags={setTags}
                        allTags={alltags}
                        setDrawer={setDrawer}
                        nameOpen={open}
                        setNameOpen={setOpen}
                    />
                </Hidden>
                {!mycontext.isAdmin && (
                    <AddNotes
                        modal={props?.modal}
                        setModal={props?.setModal}
                        data={props?.data}
                        tags={props?.tags}
                        allStandups={props?.allStandups}
                        todaysStandup={props?.allStandups?.filter(
                            (e: any) =>
                                isoToLocal(e?.standupNoteDateTime, mycontext.country)
                                    .toString()
                                    .substring(0, 15) === props?.standupd && e?.employeeId === mycontext?.EmployeeId,
                        )}
                        date={props?.date}
                        setDate={props?.setDate}
                        ans={props?.ans}
                        setAns={props?.setAns}
                        todos={props?.todos}
                        setTodos={props?.setTodos}
                        check={props?.check}
                        setCheck={props?.setCheck}
                        modalAns={props?.modalAns}
                        myStandup={myStandup}
                        setMyStandup={setMyStandup}
                        setStep={props.setStep}
                        userSubscriptionDetail={userSubscriptionDetail.usermanagementdb_tenant_employee[0]}
                        myteams={state.teams.teams}
                    />
                )}
            </div>
        </>
    );
}
