import React from 'react';
import {
    // Grid,
    Typography,
    // TableContainer,
    // Table,
    // TableHead,
    // TableRow,
    // TableBody,
    // TableCell,
    // MenuItem,

    // useTheme,
    // Button,
    // Popover,
    // Paper,
    // useTheme,
    // Tooltip,
    // Avatar,
} from '@material-ui/core';
import { Donut3 } from '../../donut/donutOuter';
import { Donut2 } from '../../donut/donutOuter';

import { useStyles } from '../pollProfileStyles';
// import { withStyles, Theme } from '@material-ui/core/styles';
// import upcomingTimeOff from '../../Assets/images/upcomingTimeoff.png';

// const StyledTableCell = withStyles(theme => ({
//     root: {
//         padding: '7px',
//         borderBottom: 'none',
//     },
//     head: {
//         fontSize: '14px',
//         backgroundColor: '#DCDFFF',
//         color: '#262F83',
//         fontWeight: 600,
//     },
//     body: {
//         fontSize: '12px',
//     },
// }))(TableCell);

// const LightTooltip = withStyles((theme: Theme) => ({
//     arrow: {
//         color: theme.palette.info.dark,
//     },
//     tooltip: {
//         backgroundColor: theme.palette.info.dark,
//         // color: theme.palette.text.primary,
//         color: theme.palette.info.contrastText,
//         fontWeight: 500,
//         boxShadow: theme.shadows[1],
//         fontSize: 14,
//     },
// }))(Tooltip);

interface ShoutoutInsights {
    shoutoutreceivedcount?: any;
    shoutoutgivencount?: any;
    frontend?: any;
    backend?: any;
    teamwork?: any;
}
export default function ShoutoutInsights(props: ShoutoutInsights) {
    const classes = useStyles();
    // const theme = useTheme();
    const COLORS = ['#587EE0', '#4FB8B2', '#956FE4'];
    const COLORS1 = ['#FFBB28', '#6CD78A', '#BE66E8'];
    const valuespiedata = [
        { name: 'Received', value: props.shoutoutreceivedcount },
        { name: 'Given', value: props.shoutoutgivencount },
    ];
    const tagspiedata = [
        { name: 'Frontend', value: props.frontend },
        { name: 'Teamwork', value: props.teamwork },
        { name: 'Backend', value: props.backend },
    ];

    return (
        <div className={classes.bg}>
            <Typography className={classes.headerTitle1}>Shoutout Insights</Typography>
            <div style={{ display: 'grid', justifyContent: 'center' }}>
                <Typography className={classes.title}>Shoutouts</Typography>
                <Donut3 data={valuespiedata} COLORS={COLORS} />

                <Typography className={classes.title}>Values received</Typography>
                <Donut2 data={tagspiedata} COLORS={COLORS1} />
            </div>
        </div>
    );
}
