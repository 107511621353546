import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        screenSize: {
            display: 'none',
            '@media screen and (max-width: 767px)': {
                display: 'block',
            },
        },
        label1: {
            marginBottom: '22px',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
            zIndex: 0,
        },
        rowData: {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '14px',
        },
        paper: {
            padding: theme.spacing(1),
            paddingBottom: '5px',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        formControl: {
            margin: theme.spacing(1),
        },
        menuItem: {
            backgroundColor: '#fff',
            '&:focus': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        multipleSelect: {
            backgroundColor: theme.palette.primary.light,
            '&:focus': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        btn: {
            height: 35,
            textTransform: 'capitalize',
            fontSize: 14,
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            // marginRight: 10,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '&:disabled': {
                backgroundColor: '#ddd6d6',
                color: '#888888',
                cursor: 'not-allowed',
            },
            '@media screen and (max-width: 650px)': {
                margin: '10px 0px',
                marginRight: 10,
                fontSize: 14,
            },
            '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
                marginLeft: 0,
            },
            '@media only screen and (min-width: 280px) and ( max-width: 280px)': {
                fontSize: 9,
            },
        },
        btnFixed: {
            height: 40,
            width: '50%',
            position: 'fixed',
            bottom: 0,
            right: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            borderRadius: 0,
            zIndex: 99,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        btnOutlined: {
            height: 35,
            textTransform: 'capitalize',
            marginRight: 10,
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                margin: '10px 0px',
                marginRight: 10,
            },
        },
        heading: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
        },
        sidebysideDiv: {
            height: 200,
            overflowY: 'scroll',
            marginTop: '-6px',
            '&::-webkit-scrollbar': {
                width: 3,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        fontsty: {
            textAlign: 'center',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            opacity: 0.7,
        },
        notfoundImage: {
            maxWidth: '75%',
            height: '180px',
        },
    }),
);
