import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import SignUp from './SignUp';
import { useDispatch } from 'react-redux';
import { updateActiveTab } from 'components/Redux/activeTab';
import Questions from './questions';

export default function FAQ() {
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch({ type: 'ACTIVE', payload: '/faq' });
        dispatch(updateActiveTab('/faqs'));
    }, [dispatch]);

    return (
        <div style={{ overflowY: 'hidden' }}>
            <Header />
            <Questions />
            <SignUp title="The new-age assistant to take good care of your team is here. Try now!" />
            <Footer />
        </div>
    );
}
