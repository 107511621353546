import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import {
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Typography,
    Button,
    Grid,
    Avatar,
    Hidden,
    // Container,
    // Paper,
    TextField,
    Card,
    InputAdornment,
    useTheme,
} from '@material-ui/core';
import { useStyles } from './style';
import AuthContext from 'context/AuthContext';
import React, { useContext, useState } from 'react';
import { dateofBirthfunc, workAnniversary } from 'helpers/celebrationApi';
import { getCurrentCountryDateTime } from 'helpers/timezone';
// import { ToStringFormat } from 'helpers/date';
import { getFullName } from 'helpers/getInitials';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const AddManually = (props: { manually?: any; setOpen?: any }) => {
    const classes = useStyles();
    const [search, setSearch] = useState<string>('');
    const mycontext = useContext(AuthContext);
    const [selectedDate, setSelectedDate] = useState<any[]>([]);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const theme = useTheme();
    const handleDateChange = (value: any, employeeId: string, key: string) => {
        if (selectedDate?.filter((e: any) => e.employeeId === employeeId).length > 0) {
            if (key === 'birth') {
                setSelectedDate(
                    selectedDate.map((e: any) => {
                        if (e.employeeId === employeeId) {
                            return { ...e, dateOfBirth: value };
                        } else {
                            return { ...e };
                        }
                    }),
                );
            } else {
                setSelectedDate(
                    selectedDate.map((e: any) => {
                        if (e.employeeId === employeeId) {
                            return { ...e, workAnniversary: value };
                        } else {
                            return { ...e };
                        }
                    }),
                );
            }
        } else {
            if (key === 'birth') {
                setSelectedDate((prev: any) => [...prev, { employeeId: employeeId, dateOfBirth: value }]);
            } else {
                setSelectedDate((prev: any) => [...prev, { employeeId: employeeId, workAnniversary: value }]);
            }
        }
        setIsDisabled(false);
    };

    const handleUpdate = async () => {
        try {
            selectedDate.map(async (a: any) => {
                const obj = {
                    employeeId: a.employeeId,
                    organizationId: mycontext.organizationId,
                    dateOfBirth: a.dateOfBirth,
                    workAnniversary: a.workAnniversary,
                    tenantId: mycontext.tenantTableId,
                    token: mycontext.token,
                };
                //console.log(obj);
                if (obj?.dateOfBirth !== undefined) {
                    const resp = await dateofBirthfunc(obj);
                    if (resp?.error) {
                        throw new Error('Something went wrong try again!');
                    }
                    //console.log(resp);
                    const event = {
                        payload: {
                            employeeId: resp?.employeeId,
                            tenantId: mycontext.tenantTableId,
                            departmentId: mycontext.organizationId,
                            celebrationDate: resp?.dateOfBirth,
                            category: 'birthday',
                            firstName: resp?.firstName,
                            action: 'insert',
                            timeZone: getCurrentCountryDateTime(mycontext.country.zoneName)
                                .dateTime.toString()
                                .slice(25, 33),
                            user: mycontext.EmployeeId,
                        },
                    };
                    const res = await fetch('https://celebration-dev.azurewebsites.net/api/celebration-dev?', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(event),
                    });
                    const jsonData = await res.json();
                    //console.log(jsonData);
                    if (jsonData.statusCode === 500) {
                        throw new Error('Something went wrong, try again!');
                    }
                }
                if (obj.workAnniversary !== undefined) {
                    const resp = await workAnniversary(obj);
                    if (resp?.error) {
                        throw new Error('Something went wrong try again!');
                    }
                    //console.log(resp);
                    const event = {
                        payload: {
                            employeeId: resp?.employeeId,
                            tenantId: mycontext.tenantTableId,
                            departmentId: mycontext.organizationId,
                            celebrationDate: obj.workAnniversary,
                            category: 'work',
                            firstName: resp?.Employee?.firstName,
                            action: 'insert',
                            timeZone: getCurrentCountryDateTime(mycontext.country.zoneName)
                                .dateTime.toString()
                                .slice(25, 33),
                            user: mycontext.EmployeeId,
                        },
                    };
                    //console.log(event);
                    const res = await fetch('https://celebration-dev.azurewebsites.net/api/celebration-dev?', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(event),
                    });
                    const jsonData = await res.json();
                    //console.log(jsonData);
                    if (jsonData.statusCode === 500) {
                        throw new Error('Something went wrong, try again!');
                    }
                }
            });
            mycontext.togglePopup({
                alertMessage: 'Successfully inserted',
                severity: 'success',
            });
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    return (
        <>
            <Hidden mdDown>
                <div>
                    <TextField
                        id="searchname"
                        placeholder="Search"
                        variant="outlined"
                        size="small"
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                        className={classes.searchBorder}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" className={classes.searchIcon}>
                                    <span
                                        style={{
                                            marginRight: 10,
                                            fontSize: 20,
                                            fill: theme.palette.primary.contrastText,
                                        }}
                                        className="iconify"
                                        data-icon="octicon:search-16"
                                    ></span>
                                </InputAdornment>
                            ),
                            classes: {
                                root: classes.searchInput,
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.primary.contrastText,
                            },
                        }}
                    />
                </div>
                <TableContainer component="div" className={classes.tableBorder}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell} align="center">
                                    #
                                </TableCell>
                                <TableCell className={classes.cell} align="left">
                                    Name
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    Birthdate
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    Joining date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {props?.manually?.length === 0 ? (
                            <>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            id="noResultFound"
                                            colSpan={6}
                                            className={classes.fontsty}
                                            style={{ borderBottom: 'none' }}
                                        >
                                            <img
                                                src={upcomingTimeoff}
                                                alt="No Teams found"
                                                className={classes.notfoundImage}
                                            />
                                            <div className={classes.notfoundText}>All data filled</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </>
                        ) : (
                            <>
                                {props?.manually?.filter((a: any) =>
                                    (a?.Employee?.firstName + ' ' + a?.Employee?.lastName)
                                        .toLowerCase()
                                        .includes(search.toLowerCase()),
                                ).length === 0 ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                id="noResultFound"
                                                colSpan={6}
                                                className={classes.fontsty}
                                                style={{ borderBottom: 'none' }}
                                            >
                                                <img
                                                    src={upcomingTimeoff}
                                                    alt="No data found"
                                                    className={classes.notfoundImage}
                                                />
                                                <div className={classes.notfoundText}>No data found</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <>
                                        {props?.manually
                                            ?.filter((v: any) =>
                                                (v?.Employee?.firstName + ' ' + v?.Employee?.lastName)
                                                    .toLowerCase()
                                                    .includes(search.toLowerCase()),
                                            )
                                            .map((a: any, i: number) => (
                                                <TableBody key={`test${i + 1}`}>
                                                    <TableRow>
                                                        <TableCell className={classes.cell1} align="center">
                                                            {i + 1}
                                                        </TableCell>
                                                        <TableCell className={classes.cell1} align="left">
                                                            {a?.Employee?.firstName} {a?.Employee?.lastName}
                                                        </TableCell>
                                                        <TableCell className={classes.cell1} align="center">
                                                            {a?.Employee?.dateOfBirth === null ? (
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    value={a?.Employee?.dateOfBirth}
                                                                    onChange={(e: any) =>
                                                                        handleDateChange(
                                                                            e.target.value,
                                                                            a?.employeeId,
                                                                            'birth',
                                                                        )
                                                                    }
                                                                    className={classes.inputTag}
                                                                    type="date"
                                                                    style={{ width: '135px' }}
                                                                />
                                                            ) : (
                                                                `${a?.Employee?.dateOfBirth}`
                                                            )}
                                                        </TableCell>
                                                        <TableCell className={classes.cell1} align="center">
                                                            {a?.workAnniversary === null ? (
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    value={a?.workAnniversary}
                                                                    className={classes.inputTag}
                                                                    onChange={(e: any) =>
                                                                        handleDateChange(
                                                                            e.target.value,
                                                                            a?.employeeId,
                                                                            'work',
                                                                        )
                                                                    }
                                                                    type="date"
                                                                    style={{ width: '135px' }}
                                                                />
                                                            ) : (
                                                                `${a?.workAnniversary}`
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ))}
                                    </>
                                )}
                            </>
                        )}
                    </Table>
                </TableContainer>
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={isDisabled}
                    className={isDisabled ? classes.resetButton2 : classes.btn}
                    style={{ width: 140, marginLeft: 'auto', marginTop: 15, display: 'flex' }}
                    onClick={handleUpdate}
                >
                    save
                </Button>
            </Hidden>
            <Hidden lgUp>
                {props.manually?.length === 0 ? (
                    <>
                        <div id="noResultFound" className={classes.fontsty} style={{ borderBottom: 'none' }}>
                            <img src={upcomingTimeoff} alt="No data found" className={classes.notfoundImage} />
                            <div className={classes.notfoundText}>All data filled</div>
                        </div>
                    </>
                ) : (
                    <>
                        <Grid container>
                            <Grid item>
                                <ArrowBackIcon className={classes.backArrow} onClick={() => props.setOpen(0)} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.back}>Add Manually</Typography>
                            </Grid>
                        </Grid>
                        <div style={{ paddingTop: 10 }}>
                            <TextField
                                id="searchname"
                                placeholder="Search"
                                variant="outlined"
                                size="small"
                                value={search}
                                onChange={(e: any) => setSearch(e.target.value)}
                                className={classes.searchBorder}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className={classes.searchIcon}>
                                            <span
                                                style={{
                                                    marginRight: 10,
                                                    fontSize: 20,
                                                    fill: theme.palette.primary.contrastText,
                                                }}
                                                className="iconify"
                                                data-icon="octicon:search-16"
                                            ></span>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        root: classes.searchInput,
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: theme.palette.primary.contrastText,
                                    },
                                }}
                            />
                        </div>
                        {props?.manually?.filter((row: any) =>
                            (row?.Employee?.firstName + ' ' + row?.Employee?.lastName)
                                .toLowerCase()
                                .includes(search?.toLowerCase()),
                        ).length === 0 ? (
                            <>
                                <div id="noResultFound" className={classes.fontsty} style={{ borderBottom: 'none' }}>
                                    <img src={upcomingTimeoff} alt="No data found" className={classes.notfoundImage} />
                                    <div className={classes.notfoundText}>No data found</div>
                                </div>
                            </>
                        ) : (
                            <Grid
                                container
                                spacing={2}
                                style={{ marginTop: 5, marginBottom: 40 }}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                {props?.manually
                                    ?.filter((row: any) =>
                                        (row?.Employee?.firstName + ' ' + row?.Employee?.lastName).includes(
                                            search?.toLowerCase(),
                                        ),
                                    )
                                    .map((d: any, i: number) => (
                                        <Grid item key={i}>
                                            <Card elevation={0} className={classes.car}>
                                                <Grid container style={{ position: 'relative' }}>
                                                    <Grid
                                                        item
                                                        style={{
                                                            paddingTop: 8,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        xs={10}
                                                        md={10}
                                                    >
                                                        <Grid container direction="row">
                                                            <div>
                                                                {d?.Employee?.profileImage !== null ? (
                                                                    <Avatar
                                                                        src={d?.Employee?.profileImage}
                                                                        alt={d?.Employee?.firstName}
                                                                        className={classes.avatarimage}
                                                                    />
                                                                ) : (
                                                                    <Avatar className={classes.avatarimage}>
                                                                        {getFullName(
                                                                            d?.Employee?.firstName +
                                                                                ' ' +
                                                                                d?.Employee?.lastName,
                                                                        )}
                                                                    </Avatar>
                                                                )}
                                                            </div>
                                                            <div style={{ padding: 4 }} className={classes.item}>
                                                                {d?.Employee?.firstName} {d?.Employee?.lastName}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    // direction="column"
                                                    style={{ paddingTop: 8 }}
                                                >
                                                    <Grid item xs={12} style={{ paddingBottom: 5 }}>
                                                        <span className={classes.item1}>Birthdate</span>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {d?.Employee?.dateOfBirth === null ? (
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                variant="outlined"
                                                                value={d?.Employee?.dateOfBirth}
                                                                onChange={(e: any) =>
                                                                    handleDateChange(
                                                                        e.target.value,
                                                                        d?.employeeId,
                                                                        'birth',
                                                                    )
                                                                }
                                                                type="date"
                                                                style={{ width: '150px' }}
                                                                className={classes.inputTag}
                                                            />
                                                        ) : (
                                                            `${d?.Employee?.dateOfBirth}`
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={6} sm={8}>
                                                        <span className={classes.item1}>Work Anniversary</span>
                                                        <Grid item xs={12}>
                                                            {d?.workAnniversary === null ? (
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    value={d?.workAnniversary}
                                                                    onChange={(e: any) =>
                                                                        handleDateChange(
                                                                            e.target.value,
                                                                            d?.employeeId,
                                                                            'work',
                                                                        )
                                                                    }
                                                                    className={classes.inputTag}
                                                                    type="date"
                                                                    style={{ width: '150px' }}
                                                                />
                                                            ) : (
                                                                `${d?.workAnniversary}`
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    ))}
                            </Grid>
                        )}
                    </>
                )}
                <Button
                    className={isDisabled ? classes.resetButton2 : classes.btn}
                    // onClick={() => props.setOpen(1)}
                    onClick={handleUpdate}
                    variant="contained"
                    // color="primary"
                >
                    save
                </Button>
            </Hidden>
        </>
    );
};
