import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Grid, useTheme } from '@material-ui/core';
import SubscriptionCardComponent from './subscriptionCard';
import { useEffect } from 'react';
import SubscriptionDetails from './SubscriptionDetails';
import { LoaderPage } from 'components/Loader/loader';
import { PopUp } from '../Modal/Modal';
import { SubscriptionCardStyle } from './SubscriptionCardStyle';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ErrorPage from '../Error/ErrorPage';

export default function Subscription() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const subscription: any = useSelector((state: any) => state.subscription);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [monthly, setMonthly] = useState<boolean>(false);
    const classes = SubscriptionCardStyle();

    const [subsDetails, setSubsDetails] = useState({
        subscription: {
            subscriptionId: '',
            currentTermStart: '',
            currentTermEnd: '',
            nextBillingAt: '',
            isExpired: false,
        },
    });
    useEffect(() => {
        dispatch({ type: 'ACTIVE', payload: '/settings' });
        try {
            if (subscription.status === 'success') {
                if (subscription.subscription.usermanagementdb_Subscription.length !== 0) {
                    setSubsDetails(subsDetails => ({
                        ...subsDetails,
                        subscription: {
                            ...subsDetails.subscription,
                            subscriptionId: subscription.subscription.usermanagementdb_Subscription[0].subscriptionId,
                            currentTermStart:
                                subscription.subscription.usermanagementdb_Subscription[0].currentTermStart,
                            currentTermEnd: subscription.subscription.usermanagementdb_Subscription[0].currentTermEnd,
                            nextBillingAt: subscription.subscription.usermanagementdb_Subscription[0].nextBillingAt,
                            isExpired: subscription.subscription.usermanagementdb_Subscription[0].status,
                        },
                    }));
                }
            }
        } catch (e) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Something went wrong, try again!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, subscription.status]);

    const cardOneContent = {
        id: 'one',
        title: 'Starter',
        price: '10',
        suffix: ' / mo',
        benefit1: '20 users',
        benefit2: '1 Database',
        benefit3: '3 Domains',
        benefit4: '10 GB Storage',
        primary_action: 'Select plan',
        secondary_action: 'Learn more',
        plan_details: 'frontendTestPlan-USD-Weekly',
    };
    const cardTwoContent = {
        id: 'two',
        title: 'Intermediate',
        price: '20',
        suffix: ' / mo',
        benefit1: '50 users',
        benefit2: '2 Database',
        benefit3: '10 Domains',
        benefit4: '25 GB Storage',
        primary_action: 'Select plan',
        secondary_action: 'Learn more',
        plan_details: 'frontendTestPlan-USD-Monthly',
    };
    const cardThreeContent = {
        id: 'three',
        title: 'Enterprise',
        price: '499',
        suffix: ' / mo',
        benefit1: '9 Emails',
        benefit2: '3 Database',
        benefit3: '20 Domains',
        benefit4: '50 GB Storage',
        primary_action: 'Select plan',
        secondary_action: 'Learn more',
        plan_details: 'frontendTestPlan-USD-Yearly',
    };
    if (subscription.status === 'failed') return <ErrorPage msg="Something went wrong!" />;
    if (subscription.status === 'loading') return <LoaderPage />;
    return (
        <div>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            {subscription.status === 'loading' ? (
                <LoaderPage />
            ) : subscription.status === 'success' &&
              subscription.subscription.usermanagementdb_Subscription.length > 0 ? (
                <div className={classes.cardContainer}>
                    <Grid container>
                        <Grid item xs={12}>
                            <SubscriptionDetails subscription={subsDetails.subscription} />
                        </Grid>
                    </Grid>
                </div>
            ) : subscription.status === 'success' &&
              subscription.subscription.usermanagementdb_Subscription.length === 0 ? (
                <div className={classes.cardContainer}>
                    <Grid container>
                        <Grid item xs={12} className={classes.topGrid}>
                            <ButtonGroup
                                variant="outlined"
                                style={{ border: `0.5px solid ${theme.palette.text.secondary}` }}
                            >
                                <Button
                                    style={{ outline: 'none' }}
                                    onClick={() => setMonthly(true)}
                                    className={monthly ? classes.selected : classes.unSelected}
                                >
                                    Monthly
                                </Button>
                                <Button
                                    style={{ outline: 'none' }}
                                    className={monthly ? classes.unSelected : classes.selected}
                                    onClick={() => setMonthly(false)}
                                >
                                    Yearly
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.outerGrid}>
                        <Grid item xs={10} md={3}>
                            <SubscriptionCardComponent content={cardOneContent} monthly={monthly} />
                        </Grid>
                        <Grid item xs={10} md={3}>
                            <SubscriptionCardComponent content={cardTwoContent} monthly={monthly} />
                        </Grid>
                        <Grid item xs={10} md={3}>
                            <SubscriptionCardComponent content={cardThreeContent} monthly={monthly} />
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <ErrorPage msg="Something went wrong!" />
            )}
        </div>
    );
}
