import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App2';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './components/Redux/store';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import config from './config.json';
import * as serviceWorker from './serviceWorker';
import { FlagsmithProvider } from 'flagsmith-react';
import { registerServiceWorker } from './register-sw';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { newurl, newurlSubscription } from './helpers/hasuraapi';
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider, createHttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';

const msalInstance = new PublicClientApplication(msalConfig);
declare let window: any;
// import AppProvider from './context/MyContext';
// import { REGION, USER_POOL_ID } from './aws-exports';

// Amplify.configure({
//     Auth: {
//         mandatorySignId: true,
//         region: config.cognito.REGION,
//         userPoolId: config.cognito.USER_POOL_ID,
//         userPoolWebClientId: config.cognito.APP_CLIENT_ID,
//         oauth: {
//             domain: 'avakasha.auth.us-east-1.amazoncognito.com',
//             scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
//             // redirectSignIn: 'http://localhost:4000/dashboard',
//             // redirectSignOut: 'http://localhost:4000/',
//             responseType: 'code',
//         },
//     },
// });

// Amplify.configure({
//     Auth: {
//         mandatorySignId: true,
//         region: process.env.NODE_ENV === 'production' ? config.cognito.REGION : config.test_cognito.REGION,
//         userPoolId:
//             process.env.NODE_ENV === 'production' ? config.cognito.USER_POOL_ID : config.test_cognito.USER_POOL_ID,
//         userPoolWebClientId:
//             process.env.NODE_ENV === 'production' ? config.cognito.APP_CLIENT_ID : config.test_cognito.APP_CLIENT_ID,
//     },
// });

// Amplify.configure({
//     Auth: {
//         mandatorySignId: true,
//         region: process.env.NODE_ENV === 'production' ? config.cognito.REGION : config.cognito.REGION,
//         userPoolId: process.env.NODE_ENV === 'production' ? config.cognito.USER_POOL_ID : config.cognito.USER_POOL_ID,
//         userPoolWebClientId:
//             process.env.NODE_ENV === 'production' ? config.cognito.APP_CLIENT_ID : config.cognito.APP_CLIENT_ID,
//     },
// });

// Auth.configure({
//     Auth: {
//         mandatorySignId: true,
//         region: config.test_cognito.REGION,
//         userPoolId: config.test_cognito.USER_POOL_ID,
//         userPoolWebClientId: config.test_cognito.APP_CLIENT_ID,
//         oauth: {
//             domain: 'teamplus-staging.auth.us-east-1.amazoncognito.com',
//             scope: ['openid', 'aws.cognito.signin.user.admin'],
//             redirectSignIn: 'http://localhost:3000/googleNewRegistration',
//             redirectSignOut: 'http://localhost:3000/login',
//             responseType: 'token',
//         },
//     },
// });
Auth.configure({
    Auth: {
        mandatorySignId: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        oauth: {
            domain: 'teamplus.auth.eu-north-1.amazoncognito.com',
            scope: ['openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/googleNewRegistration',
            redirectSignOut: 'http://localhost:3000/login',
            responseType: 'token',
        },
    },
});
// AWS.config.update({
//     region: REGION,
//     // credentials: new AWS.CognitoIdentityCredentials({
//     //     IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
//     // }),
// });

export const s3 = new AWS.S3({
    accessKeyId: process.env.NODE_ENV === 'production' ? config.s3.ID : config.test_s3.ID,
    secretAccessKey: process.env.NODE_ENV === 'production' ? config.s3.SECRET : config.test_s3.SECRET,
});
const httpLink = createHttpLink({
    uri: newurl,
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: newurlSubscription,
        connectionParams: () => ({
            headers: {
                'content-type': 'application/json',
                Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
            },
        }),
    }),
);

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(httpLink),
);

const client = new ApolloClient({
    link: ApolloLink.from([splitLink]),
    cache: new InMemoryCache(),
});
// export const DeleteCognitoUser = async (username: string) => {
//     const cognito = new AWS.CognitoIdentityServiceProvider();
//     let params = {
//         UserPoolId: USER_POOL_ID,
//         Username: username,
//     };
//     cognito.adminDeleteUser(params, (err, data) => {
//         if (err) {
//             console.log('Error wile deleting cognito user!', err);
//         }
//         console.log(data);
//     });
//     // const result = cognito.deleteUser({
//     //     AccessToken: accessToken,
//     // });
//     // console.log(result);
//     // console.log('json ', result);

//     // return await new Promise((resolve, reject) => {
//     //     const params = {
//     //         UserPoolId: config.cognito.USER_POOL_ID,
//     //         Username: sub,
//     //     };
//     //     cognito.adminDeleteUser(params, (err, data) => {
//     //         if (err) {
//     //             console.log(err);
//     //             reject(err);
//     //         } else {
//     //             console.log(data);
//     //             resolve(data);
//     //         }
//     //     });
//     // });
//     // .then(data => {
//     //     console.log(data);
//     //     alert(data.json());
//     // })
//     // .catch(err => alert(err));
// };
try {
    window.Chargebee.init({
        site: 'udyamo-test',
        isItemsModel: true,
    });
} catch (err) {}
serviceWorker.unregister();
registerServiceWorker();
ReactDOM.render(
    <Router>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <FlagsmithProvider environmentId="h8yUjPrc2fMDPR4PPGrmJ4">
                    <MsalProvider instance={msalInstance}>
                        <App />
                    </MsalProvider>
                </FlagsmithProvider>
            </Provider>
        </ApolloProvider>
    </Router>,
    document.getElementById('root'),
);
// serviceWorker.unregister();
