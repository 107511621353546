import { createSlice } from '@reduxjs/toolkit';
// import { fetchPollsData } from 'helpers/hasuraapi2';

// export const getPolls: any = createAsyncThunk('polls/getPolls', async (user: any) => {
//     const data: any = await fetchPollsData(user.organizationId, user.token);
//     const jsonData: any = await data.json();
//     return jsonData.data.pollsdb_Polls;
// });

export const pollsSlice = createSlice({
    name: 'polls',
    initialState: { polls: [], status: 'loading' },
    reducers: {
        addPoll: (state: any, action: any) => {
            state.polls.unshift(action.payload);
        },
        editPoll: (state: any, action: any) => {
            state.polls.map((u: any) => {
                if (u.pollId === action.payload.pollId) {
                    u.title = action.payload.title;
                    // console.log('a', u);
                    return u;
                }
                return u;
            });
        },
        removePoll: (state: any, action: any) => {
            state.polls = state.polls.filter((h: any) => h.pollId !== action.payload.pollId);
        },
        fetchAllPolls: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                // console.log(action.payload);
                state.polls = action.payload?.data?.pollsdb_Polls;
            }
        },
    },
    // extraReducers: {
    //     [getPolls.pending]: (state: any) => {
    //         state.status = 'loading';
    //     },
    //     [getPolls.fulfilled]: (state: any, action: any) => {
    //         state.polls = action.payload;
    //         state.status = 'success';
    //     },
    //     [getPolls.rejected]: (state: any) => {
    //         state.status = 'failed';
    //     },
    // },
});

export const { addPoll, editPoll, removePoll, fetchAllPolls } = pollsSlice.actions;
export default pollsSlice.reducer;
