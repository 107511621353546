import React, { useContext, useState } from 'react';
import { SettingStyles } from '../Settings/SettingStyle';
import { Tab, Tabs, Paper, useTheme } from '@material-ui/core';
import AuthContext from 'context/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';

import TimeOffDashboard from '../DashboardPage/Dashboard';
import OtherDashboard from '../DashboardPage/OtherDashboard';
import ShoutoutDashboard from '../Shoutout/Main';
import Polls from '../Polls/tablePoll';
import MainStandup from '../Standups/StandupTable';
import StandupDashboard from 'components/Standups/StandupDashboard';
import { initialDate } from 'helpers/date';
import { getCurrentCountryDateTime, localToISO } from 'helpers/timezone';
import { useFlagsmith } from 'flagsmith-react';
import Celebration from 'components/Celebration/main';

function TabPanel(props: any) {
    const { children, value, index } = props;
    return <>{value === index && <div style={{ paddingTop: 10 }}>{children}</div>}</>;
}

export default function Dashboard(props: { setDate: any; team: any; date: any }) {
    const classes = SettingStyles();
    const location: any = useLocation();
    const [value, setValue] = React.useState(Number(localStorage.getItem('dashboardVal')) || 0); //Number(localStorage.getItem('SettingTabVal'))
    const mycontext = useContext(AuthContext);
    const [step, setStep] = useState(0);
    const [id, setId] = useState(location.state?.id || null);
    const [viewPage, setviewPage] = React.useState(0);
    const [tabValue, settabValue] = React.useState('');
    const theme = useTheme();
    const history = useHistory();
    const { hasFeature } = useFlagsmith();

    React.useEffect(() => {
        if (location?.state) {
            localStorage.setItem('dashboardVal', location?.state.val);
            const tabval = localStorage.getItem('dashboardVal');
            setValue(Number(tabval));
            setId(location?.state?.id || null);
        }
        if (location?.state?.poll) {
            setStep(2);
            mycontext.addCurrentPoll(location?.state?.poll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTabs = (event: any, val: any) => {
        localStorage.setItem('dashboardVal', val);
        // console.log('tabs', val);/
        history.push({
            pathname: `/dashboard`,
        });
        const tabval = localStorage.getItem('dashboardVal');
        setValue(Number(tabval));
        setId(null);
        props.setDate({
            endDate: localToISO(
                getCurrentCountryDateTime(mycontext.country.zoneName).date,
                '23:59:00',
                mycontext.country,
            ),
            startDate: initialDate('week', 0, mycontext.country.zoneName),
            title: 'This Week',
        });
    };
    return (
        <>
            <Paper elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleTabs}
                    indicatorColor="primary"
                    variant="scrollable"
                    className={classes.tabs}
                    style={{
                        minHeight: '30%',
                        lineHeight: '25px',
                        borderRadius: '5px',
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <Tab
                        label="Time-Off"
                        className={classes.tab}
                        id="timeoff"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.paper,
                            textTransform: 'initial',
                            minHeight: '100%',
                            fontSize: '15px',
                            padding: '10px 10px',
                        }}
                    />

                    <Tab
                        label="Standups"
                        className={classes.tab}
                        id="standups"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.paper,
                            textTransform: 'initial',
                            minHeight: '100%',
                            fontSize: '15px',
                            padding: '10px 10px',
                        }}
                    />
                    {hasFeature('polls-dashboard') ? (
                        <Tab
                            label="Polls"
                            className={classes.tab}
                            id="polls"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.background.paper,
                                textTransform: 'initial',
                                minHeight: '100%',
                                fontSize: '15px',
                                padding: '10px 10px',
                            }}
                        />
                    ) : null}
                    {hasFeature('shoutout-dashboard') ? (
                        <Tab
                            label="Shoutout"
                            className={classes.tab}
                            id="shoutout"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.background.paper,
                                textTransform: 'initial',
                                minHeight: '100%',
                                fontSize: '15px',
                                padding: '10px 10px',
                            }}
                        />
                    ) : null}
                    {/* <Tab
                        label="Standup"
                        className={classes.tab}
                        id="standup"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.paper,
                            textTransform: 'initial',
                            minHeight: '100%',
                            fontSize: '15px',
                            padding: '10px 10px',
                        }}
                    /> */}
                    <Tab
                        label="Celebration"
                        className={classes.tab}
                        id="celebrations"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.paper,
                            textTransform: 'initial',
                            minHeight: '100%',
                            fontSize: '15px',
                            padding: '10px 10px',
                        }}
                    />
                </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
                {mycontext.isAdmin ? <TimeOffDashboard teams={props.team} /> : <OtherDashboard />}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {mycontext.isAdmin ? (
                    <StandupDashboard
                        setDate={props.setDate}
                        viewPage={viewPage}
                        setviewPage={setviewPage}
                        tabValue={tabValue}
                        settabValue={settabValue}
                        date={props.date}
                    />
                ) : (
                    <MainStandup
                        setDate={props.setDate}
                        viewPage={viewPage}
                        setviewPage={setviewPage}
                        tabValue={tabValue}
                        settabValue={settabValue}
                        date={props.date}
                    />
                )}
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Polls setStep={setStep} step={step} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ShoutoutDashboard id={id} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Celebration />
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
                <Standup />
            </TabPanel> */}
        </>
    );
}
