import { createSlice } from '@reduxjs/toolkit';
// import { fetchHolidayData } from 'helpers/hasuraapi';

// export const getHolidays: any = createAsyncThunk('holidays/getHolidays', async (user: any) => {
//     const data: any = await fetchHolidayData(user.organizationId, user.token);
//     const jsonData: any = await data.json();
//     return jsonData.data.timeoffdb_Holiday;
// });

export const holidaySlice = createSlice({
    name: 'holidays',
    initialState: { holidays: [], status: 'loading' },
    reducers: {
        addHoliday: (state: any, action: any) => {
            state.holidays.unshift(action.payload);
        },
        editHoliday: (state: any, action: any) => {
            state.holidays.map((u: any) => {
                if (u.holidayId === action.payload.holidayId) {
                    u.startDate = action.payload.startDate;
                    u.holidayDay = action.payload.holidayDay;
                    u.holidayName = action.payload.holidayName;
                    u.endDate = action.payload.endDate;
                    u.isMultidayHoliday = action.payload.isMultidayHoliday;
                    return u;
                }
                return u;
            });
        },
        removeHoliday: (state: any, action: any) => {
            state.holidays = state.holidays.filter((h: any) => h.holidayId !== action.payload.holidayId);
        },
        fetchHolidays: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                state.holidays = action.payload?.data?.timeoffdb_Holiday;
            }
        },
    },
    // extraReducers: {
    //     [getHolidays.pending]: (state: any) => {
    //         state.status = 'loading';
    //     },
    //     [getHolidays.fulfilled]: (state: any, action: any) => {
    //         state.holidays = action.payload;
    //         state.status = 'success';
    //     },
    //     [getHolidays.rejected]: (state: any) => {
    //         state.status = 'failed';
    //     },
    // },
});

export const { addHoliday, editHoliday, removeHoliday, fetchHolidays } = holidaySlice.actions;
export default holidaySlice.reducer;
