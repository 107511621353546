import React, { useEffect } from 'react';
import {
    Typography,
    // TableContainer,
    // Table,
    // TableHead,
    // TableRow,
    // TableBody,
    // TableCell,
    // TextField,
    // InputAdornment,
    // MenuItem,
    // Hidden,
    useTheme,
    // Button,
    // Popover,
    // Paper,
    // useTheme,
    // Tooltip,
    // Avatar,
} from '@material-ui/core';
import { Donut1 } from '../../donut';

import { useStyles } from '../pollProfileStyles';
// import { Theme } from '@material-ui/core/styles';

interface PollsInsights {
    empPolls?: any;
    currentPoll?: any;
}
export default function PollsInsights(props: PollsInsights) {
    const classes = useStyles();
    const theme = useTheme();
    const currentPoll = props.empPolls[props.currentPoll];

    const [currentChoices, setCurrentChoices] = React.useState<any>([]);

    const COLORS = [
        '#587EE0',
        '#4FB8B2',
        '#956FE4',
        '#FFBB28',
        '#6CD78A',
        '#BE66E8',
        '#F2890D',
        '#587EE0',
        '#4FB8B2',
        '#956FE4',
        '#FFBB28',
        '#6CD78A',
        '#BE66E8',
        '#F2890D',
        '#587EE0',
        '#4FB8B2',
        '#956FE4',
        '#FFBB28',
        '#6CD78A',
        '#BE66E8',
        '#F2890D',
    ];
    async function abc() {
        const a: any = [];
        const obj: any = [];
        await currentPoll?.adminChoices?.map((n: string) => {
            let c = 0;
            currentPoll?.poll_results?.map((d: any) => {
                if (d.userChoice.includes(n)) {
                    c = c + 1;
                    return a.push({
                        userChoice: n,
                    });
                }
                return null;
            });
            if (c !== 0) {
                obj.push({
                    name: n,
                    value: c,
                });
            }
            // obj.push({
            //     name: n,
            //     value: c,
            // });
            return null;
        });
        setCurrentChoices(obj);
    }

    useEffect(() => {
        abc();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPoll]);

    return (
        <div className={classes.bg}>
            {props.empPolls?.length > 0 ? (
                <div>
                    <Typography className={classes.headerTitle1}>Polls Insights</Typography>

                    <Typography className={classes.question}>
                        {props.currentPoll + 1} &nbsp; &nbsp; {props.empPolls[props.currentPoll].question}
                    </Typography>

                    <Typography className={classes.choices}>Choices :</Typography>

                    <div
                        style={{
                            // gridTemplateRows: '1.1fr 1fr',
                            gridColumnGap: '1px',
                            display: 'grid',
                        }}
                    >
                        <div className={classes.choicescontainer}>
                            {props.empPolls[props.currentPoll].adminChoices.map((row: any, i: number) => (
                                <div key={i} style={{ paddingLeft: '5%', display: 'flex', alignItems: 'center' }}>
                                    <div
                                        style={{
                                            width: 4,
                                            borderRadius: '0px 8px 8px 0px',
                                            height: 30,
                                            background: COLORS[i],
                                            margin: '2px',
                                        }}
                                    ></div>
                                    <Typography className={classes.choice}>{row}</Typography>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Donut1 data={currentChoices} COLORS={COLORS} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classes.nopollinsights}>
                    <Typography
                        style={{
                            fontSize: '13px',
                            letterSpacing: '0.03em',
                            color: theme.palette.text.primary,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        No Poll(s) insights found.
                    </Typography>
                </div>
            )}
        </div>
    );
}
