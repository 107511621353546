import React from 'react';
import useStyles from './style';
import Background from '../../Assets/dashboard/howitworks_bg.png';
import { Hidden } from '@material-ui/core';

interface HowitWorks {
    img?: any;
    list?: any;
}
export default function Howitworks(props: HowitWorks) {
    const classes = useStyles();
    return (
        <>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <div className={classes.howitwork}>
                    <img src={props.img} alt="" className={classes.howimg} />
                    <div
                        style={{
                            width: '35%',
                            height: '80%',
                            position: 'absolute',
                            right: 50,
                            top: 100,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <h6 className={classes.itworkHeading}>
                            How it <span>Works ?</span>
                            <img src={Background} alt="" />
                        </h6>
                        <ul className={classes.howcontent}>
                            {props.list.map((l: any, key: number) =>
                                l.id === 2 ? (
                                    <li key={key} style={{ position: 'relative', margin: '15px 0' }}>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                width: 40,
                                                height: 40,
                                                left: -5,
                                                backgroundColor: '#3C48B5',
                                                color: '#fff',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 2,
                                                border: '1.5px solid white',
                                                // borderImageSource:
                                                //     'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 92.5%)',
                                            }}
                                        >
                                            {l.id}
                                        </div>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                width: '100%',
                                                height: 30,
                                                left: 12,
                                                fontWeight: 500,
                                                // backgroundColor: '#3C48B5',
                                                background: 'linear-gradient(90deg, #3C48B5 8.06%, #212C93 103.27%)',
                                                color: '#fff',
                                                display: 'flex',
                                                alignItems: 'center',
                                                // justifyContent: 'center',
                                                borderRadius: '0px 50px 50px 0px',
                                                paddingLeft: 40,
                                                zIndex: 1,
                                            }}
                                        >
                                            {l.task}
                                        </div>
                                    </li>
                                ) : (
                                    <li key={key}>
                                        <span>{l.id}</span>
                                        {l.task}
                                    </li>
                                ),
                            )}
                        </ul>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['xl']}>
                <div
                    style={{
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        padding: '70px 20px',
                    }}
                >
                    <h6 className={classes.itworkHeading}>
                        How it <span>Works ?</span>
                    </h6>
                    <ul className={classes.howcontent}>
                        {props.list.map((l: any, key: number) =>
                            l.id === 2 ? (
                                <li key={key} style={{ position: 'relative', margin: '15px 0' }}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: 40,
                                            height: 40,
                                            left: -5,
                                            backgroundColor: '#3C48B5',
                                            color: '#fff',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            zIndex: 2,
                                            border: '1.5px solid white',
                                            // borderImageSource:
                                            //     'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 92.5%)',
                                        }}
                                    >
                                        {l.id}
                                    </div>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            minHeight: 30,
                                            height: 'auto',
                                            left: 12,
                                            fontWeight: 500,
                                            // backgroundColor: '#3C48B5',
                                            background: 'linear-gradient(90deg, #3C48B5 8.06%, #212C93 103.27%)',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            // justifyContent: 'center',
                                            borderRadius: '35px 50px 50px 35px',
                                            paddingLeft: 40,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            zIndex: 1,
                                        }}
                                    >
                                        {l.task}
                                    </div>
                                </li>
                            ) : (
                                <li key={key}>
                                    <span>{l.id}</span>
                                    {l.task}
                                </li>
                            ),
                        )}
                    </ul>
                </div>
            </Hidden>
        </>
    );
}
