interface Props {
    username?: string;
    password?: string;
    confirmpassword?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
    verificationcode?: any;
    request?: string;
    reason?: string;
    year?: string;
    holidays?: any;
    name?: any;
    members?: any;
}

function validateForm(props: Props): any {
    if (props.username === '') {
        return { blankfield: true };
    }
    if (props.firstname === '') {
        return { blankfield: true };
    }
    if (props.lastname === '') {
        return { blankfield: true };
    }
    if (props.email === '') {
        return { blankfield: true };
    }
    if (props.verificationcode === '') {
        return { blankfield: true };
    }
    if (props.password === '') {
        return { blankfield: true };
    }
    if (props.confirmpassword === '') {
        return { blankfield: true };
    }
    if (props.request === '') {
        return { blankfield: true };
    }
    if (props.reason === '') {
        return { blankfield: true };
    }
    if (props.year === '') {
        return { blankfield: true };
    }
    if (props.name === '') {
        return { blankfield: true };
    }
    if (props.holidays === []) {
        return { blank: true };
    }
    if (props.members === ['']) {
        return { blankMember: true };
    }
    if (props.password !== props.confirmpassword) {
        return { passwordmatch: true };
    }
    if ((props.password || 'demo1234').length < 8) {
        return { passwordLength: true };
    }
    if (props.verificationcode?.length < 6) {
        return { otplength: true };
    }
    return;
}

export default validateForm;
