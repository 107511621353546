import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import SignUp from './SignUp';
import First from './First';
import { useDispatch } from 'react-redux';
import useStyles from './style';
// import Collaborate from '../../Assets/dashboard/collaborate.png';
// import Commingup from '../../Assets/dashboard/commingup.png';
// import Availability from '../../Assets/dashboard/availability.png';
import Background from '../../Assets/dashboard/howitworks_bg.png';
import { ArrowRight } from '@material-ui/icons';
import { Hidden } from '@material-ui/core';
import { updateActiveTab } from 'components/Redux/activeTab';
import { s3Bucket, landingpageImages } from '../../helpers/constants';

const TopPart = () => {
    const classes = useStyles();
    return (
        <>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <div className={classes.topDiv}>
                    <div className={classes.topDivContent}>
                        <p className={classes.topDivHeading}>
                            Simple solutions to common road blocks, <span>Easing one crease at a time.</span>
                        </p>
                        <p className={classes.topDivSubtitle}>
                            In a world that identifies complicated as sophisticated, we beg to differ. TeamPlus is your
                            business operations architect, it’s your handyman. It’s here to fix the minor crevices and
                            leaks in your operations that lead to discontentment, frustration and hamper your team’s
                            morale and productivity.
                        </p>
                        <p className={classes.topDivSubtitle}>
                            With its ever-evolving format, TeamPlus takes charge of one business process, offers a
                            simple, straightforward solution for it, and then moves on to find a new problem to solve.
                            The goal is simple too–helping teams collaborate better, and work more efficiently.
                        </p>
                        {/* <button className={classes.topDivBtn}>Launched Features</button> */}
                    </div>
                    <img src={s3Bucket + landingpageImages.simpleSolution} alt="" className={classes.topDivImage} />
                </div>
            </Hidden>
            <Hidden only={['xl']}>
                <div className={classes.topDivSmall}>
                    <p className={classes.topDivHeadingSmall}>
                        Simple solutions to common road blocks, <span>Easing one crease at a time.</span>
                    </p>
                    <img
                        src={s3Bucket + landingpageImages.simpleSolution}
                        alt=""
                        className={classes.topDivImageSmall}
                    />
                    <div className={classes.topDivContentSmall}>
                        <p className={classes.topDivSubtitle}>
                            In a world that identifies complicated as sophisticated, we beg to differ. TeamPlus is your
                            business operations architect, it’s your handyman. It’s here to fix the minor crevices and
                            leaks in your operations that lead to discontentment, frustration and hamper your team’s
                            morale and productivity.
                        </p>
                        <p className={classes.topDivSubtitle}>
                            With its ever-evolving format, TeamPlus takes charge of one business process, offers a
                            simple, straightforward solution for it, and then moves on to find a new problem to solve.
                            The goal is simple too–helping teams collaborate better, and work more efficiently.
                        </p>
                        {/* <button className={classes.topDivBtn}>Launched Features</button> */}
                    </div>
                </div>
            </Hidden>
        </>
    );
};

const MiddlePart = () => {
    const classes = useStyles();
    return (
        <div className={classes.bottomDiv}>
            <div className={classes.bottomDivTopContent}>
                <p className={classes.bottomDivTopHeading}>
                    The first of many <br></br>
                    <span>TeamPlus Availability Assistant</span>
                </p>
                {/* <p className={classes.bottomDivTopSubtitle}>
                    We know there's always room to grow. So we keep updating TeamPlus with new features and
                    functionality. Here's what we've planned for our next update.
                </p> */}
            </div>
            <div className={classes.bottomDivBottom}>
                <img src={s3Bucket + landingpageImages.theFirst} alt="" className={classes.bottomDivBottomImg} />
                <div className={classes.bottomDivBottomContent}>
                    {/* <p className={classes.bottomDivBottomHeading}>TeamPlus Scheduler</p> */}
                    <p className={classes.bottomDivBottomSubtitle}>
                        Gone are the days when you’d enter your office and a quick scan would tell you who’s around and
                        who’s missing-in-action. Today, you schedule a meeting, and only when someone’s declines the
                        invite or doesn’t join at all makes you realize that they’re on a break. That’s not all– the
                        breaks vary. From “at the bank” to “health emergency” to “I just need a break”, the reason and
                        the duration of these breaks can be plenty–from a few hours to a few days, sometimes even weeks.
                    </p>
                    <p className={classes.bottomDivBottomSubtitle}>
                        So, the question persists–who’s available right now? We have the answer.
                    </p>
                    <p className={classes.bottomDivBottomSubtitle}>
                        TeamPlus Availability Assistant not only helps you administer time-offs but also records, and
                        shares everyone’s availability. So, at all times, your answer to who’s available today,
                        tomorrow, or even a week later, is just a dashboard away.
                    </p>
                </div>
            </div>
        </div>
    );
};

const Task = () => {
    const [current, setCurrent] = React.useState<number>(1);
    const classes = useStyles();
    const tasks = [
        {
            id: 1,
            title: 'No More Blind Spots',
            description:
                'TeamPlus is built on transparency. Every feature on the tool is built to provide complete visibility to every team member on availabilities, schedules, notes, etc.',
        },
        {
            id: 2,
            title: 'Complete Slack Integration',
            description:
                'TeamPlus readily integrates with Slack–so requesting time-off, or scheduling a standup is just a Slack message away. No need to switch applications.',
        },
        {
            id: 3,
            title: 'Collaboration On The Go',
            description:
                'TeamPlus likes mobility. Built as Progressive Web Apps, you can access every TeamPlus feature anywhere, anytime.',
        },
    ];
    const handleNext = (id: number) => {
        setCurrent(id);
        if (id === 4) {
            setCurrent(1);
        }
    };
    return (
        <>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <div className={classes.taskContainer}>
                    <img src={s3Bucket + landingpageImages.dell} alt="" />
                    <div className={classes.taskDiv}>
                        <h1>The better way to deal with hiccups!</h1>
                        <ul className={classes.taskContent}>
                            {tasks
                                .filter((o: any) => o.id === current)
                                .map((t: any) => (
                                    <li key={t.id}>
                                        <div>
                                            <h5>{t.id}</h5>
                                            <h6>{t.title}</h6>
                                        </div>
                                        <p>{t.description}</p>
                                    </li>
                                ))}
                        </ul>
                        <div className={classes.btnDiv}>
                            <div className={classes.statusBar}>
                                <div
                                    style={{ width: 20, height: 6, borderRadius: 5 }}
                                    className={current === 1 ? classes.active : classes.inactive}
                                />
                                <div
                                    style={{ width: 6, height: 6, borderRadius: 5 }}
                                    className={current === 2 ? classes.active : classes.inactive}
                                />
                                <div
                                    style={{ width: 6, height: 6, borderRadius: 5 }}
                                    className={current === 3 ? classes.active : classes.inactive}
                                />
                            </div>
                            <p onClick={() => handleNext(current + 1)}>
                                Next <ArrowRight fontSize="small" />
                            </p>
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['xl']}>
                <div className={classes.taskSmall}>
                    <h1>The better way to deal with hiccups!</h1>
                    <ul>
                        {tasks.map((t: any) => (
                            <li key={t.id}>
                                <div>
                                    <h5>{t.id}</h5>
                                    <h6>{t.title}</h6>
                                </div>
                                <p>{t.description}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </Hidden>
        </>
    );
};
const BottomPart = () => {
    const classes = useStyles();
    return (
        <div className={classes.bottomDiv}>
            <div className={classes.bottomDivTopContent}>
                <p className={classes.bottomDivTopHeading}>
                    Many to follow – <span>Upcoming Features On TeamPlus</span>
                </p>
                {/* <p className={classes.bottomDivTopSubtitle}>
                    We know there's always room to grow. So we keep updating TeamPlus with new features and
                    functionality. Here's what we've planned for our next update.
                </p> */}
            </div>
            <div className={classes.bottomDivBottom}>
                <img src={s3Bucket + landingpageImages.manyTo} alt="" className={classes.bottomDivBottomImg2} />
                <div className={classes.bottomDivBottomContent2}>
                    <p className={classes.bottomDivBottomHeading}>Polls</p>
                    <p className={classes.bottomDivBottomSubtitle}>
                        The quickest way to conduct surveys and temp-checks.
                    </p>

                    <p className={classes.bottomDivBottomHeading}>StandUps</p>
                    <p className={classes.bottomDivBottomSubtitle}>
                        Schedule, invite and execute – the easy way to bring everyone on the same page.
                    </p>

                    <p className={classes.bottomDivBottomHeading}>ShoutOuts</p>
                    <p className={classes.bottomDivBottomSubtitle}>
                        “You did a good job today” – time to say it with just a click.
                    </p>
                </div>
            </div>
        </div>
    );
};

const CardComponent = () => {
    const classes = useStyles();
    const obj = [
        {
            id: 1,
            img: s3Bucket + landingpageImages.allIn,
            title: 'All-in-one Dashboard',
            description:
                'A single dashboard to view all pending requests, check your team’s availability, and know upcoming day-offs. ',
        },
        {
            id: 2,
            img: s3Bucket + landingpageImages.twoMinute,
            title: 'Two-Minute Processes',
            description:
                'Apply for and approve time-offs through an efficient single-step process that lets you take care of availability coordination with a click. ',
        },
        {
            id: 3,
            img: s3Bucket + landingpageImages.limitlessCustomization,
            title: 'Limitless Customization',
            description:
                'From time zones to the amount and types of time-offs, customize TeamPlus to suit your organizational needs.',
        },
        {
            id: 4,
            img: s3Bucket + landingpageImages.holidayCalendar,
            title: 'Holiday Calendar',
            description:
                'Import, view, and share holidays with your team with a shared holiday calendar, so that everyone’s on the same page all the time.',
        },
    ];
    return (
        <div className={classes.cardComponent}>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <img src={Background} alt="" className={classes.floatImage} />
            </Hidden>
            {obj.map((d: any) => (
                <div className={d.id % 2 === 0 ? classes.cardReverse : classes.card} key={d.id}>
                    <img src={d.img} alt={d.title} className={classes.cardImg} />
                    <div className={d.id % 2 === 0 ? classes.cardContentReverse : classes.cardContent}>
                        <h6 className={classes.cardHeading}>{d.title}</h6>
                        <p className={classes.cardDescription}>{d.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default function LandingPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateActiveTab('/'));
    }, [dispatch]);
    return (
        <div style={{ overflowY: 'hidden' }}>
            <Header />
            {/* <Notify /> */}
            <First />
            <TopPart />
            <MiddlePart />
            <CardComponent />
            <Task />
            <BottomPart />
            <SignUp title="The new-age, paperless Employee Time-Off Management Tool is here." />
            <Footer />
        </div>
    );
}
