import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import { useDispatch } from 'react-redux';
import { updateActiveTab } from 'components/Redux/activeTab';
import Questions from './questions';
import useStyles from './style';
import { Grid } from '@material-ui/core';

export default function FAQ() {
    const dispatch = useDispatch();
    const classes = useStyles();
    useEffect(() => {
        // dispatch({ type: 'ACTIVE', payload: '/help' });
        dispatch(updateActiveTab('/help'));
    }, [dispatch]);

    const HelpBlock = () => {
        return (
            <Grid container justifyContent="center" alignItems="center" className={classes.helpContent}>
                <Grid item sm={8} xs={12}>
                    <h3 className={classes.helpHeading}>We&apos;re Here to Help You!</h3>
                    <p className={classes.emailBox}>Email us at : &nbsp; support@udyamo.com</p>
                </Grid>
            </Grid>
        );
    };

    return (
        <div style={{ overflowY: 'hidden' }}>
            <Header />
            <Questions />
            <HelpBlock />
            <Footer />
        </div>
    );
}
