import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const otherStyle = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            width: '100%',
            minHeight: 'calc(100vh - 70px)',
            height: 'auto',
            borderRadius: 3,
        },
        top: {
            height: 40,
            marginBottom: 10,
            background: theme.palette.background.paper,
            borderRadius: 3,
            padding: '10px 10px',
            display: 'flex',
            alignItems: 'center',
            '@media screen and (max-width: 768px)': {
                padding: '10px 10px',
            },
        },
        bottom: {
            // minHeight: 'calc(100vh - 190px)',
            // height: 'auto',
            borderRadius: 3,
            display: 'grid',
            gridTemplateColumns: '1fr 0.5fr',
            gridColumnGap: 10,
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                flexDirection: 'column',
                // minHeight: 'auto',
            },
        },
        left: {
            width: '100%',
            // minHeight: 'calc(100vh - 180px)',
            height: 'auto',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 3,
            padding: '10px 20px',
            '@media screen and (max-width: 768px)': {
                marginBottom: 10,
                height: 'auto',
                // minHeight: 'auto',
                padding: '10px 10px',
            },
        },
        formMain: {
            height: '90%',
            width: '100%',
            display: 'flex',
            '@media screen and (max-width: 768px)': {
                flexDirection: 'column',
            },
        },
        form: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '@media screen and (max-width: 768px)': {
                width: '100%',
            },
        },
        modal: {
            width: '30%',
            height: '100%',
            '@media screen and (max-width: 768px)': {
                width: '100%',
            },
        },
        leftBtns: {
            height: '10%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        btn: {
            textTransform: 'capitalize',
            width: 100,
            height: 30,
            '&:hover': {
                outline: 'none',
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
            },
        },
        btnOutlined: {
            textTransform: 'capitalize',
            width: 100,
            height: 30,
            marginRight: 10,
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
            },
        },
        right: {
            width: '100%',
            minHeight: 'calc(100vh - 120px)',
            height: 'auto',
            display: 'flex',
            // justifyContent: 'space-between',
            borderRadius: 3,
            flexDirection: 'column',
            '@media screen and (max-width: 768px)': {
                height: 'auto',
                minHeight: 'auto',
            },
        },
        calender: {
            height: 'auto',
            borderRadius: 3,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            '@media screen and (max-width: 768px)': {
                height: 'auto',
            },
        },
        calenderLabel: {
            width: '100%',
            padding: '10px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginBottom: 10,
        },
        labelDiv: {
            display: 'flex',
            alignItems: 'center',
        },
        holidaySpan: {
            height: 7,
            width: 7,
            borderRadius: '50%',
            backgroundColor: '#7F99F6',
        },
        leaveSpan: {
            height: 7,
            width: 7,
            borderRadius: '50%',
            backgroundColor: '#6DE779',
        },
        label: {
            fontSize: 10,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            color: '#888888',
            marginLeft: 10,
        },
        leaves: {
            height: '50%',
            borderRadius: 3,
            '@media screen and (max-width: 768px)': {
                height: '400px',
            },
        },
        calenderBottomDiv: {
            padding: '0 10px',
            backgroundColor: theme.palette.background.paper,
            paddingBottom: 10,
        },
        leaveHeadingDiv: {
            padding: '10px 20px',
            backgroundColor: theme.palette.background.paper,
            '@media screen and (max-width: 768px)': {
                backgroundColor: 'transparent',
                padding: '10px 10px',
            },
        },
        leaveHeading: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            '@media screen and (max-width: 768px)': {
                fontSize: 14,
            },
        },
        displayLeaves: {
            width: '100%',
            height: 'calc(100vh - 445px)',
            overflow: 'auto',
            backgroundColor: theme.palette.background.paper,
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
            padding: '10px 10px',
            '@media screen and (max-width: 768px)': {
                padding: '10px 0px',
                height: 400,
                maxHeight: 'auto',
            },
        },
        backArrow: {
            width: 25,
            height: 15,
            cursor: 'pointer',
            // color: theme.palette.primary.contrastText,
            '@media screen and (max-width: 768px)': {
                width: 20,
                height: 10,
            },
        },
        arrowBackIcon: {
            cursor: 'pointer',
            color: theme.palette.text.primary,
        },
        heading: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 18,
            marginLeft: 20,
            '@media screen and (max-width: 768px)': {
                fontSize: 16,
            },
        },
        employee: {
            padding: '10px 0',
            display: 'flex',
            alignItems: 'center',
            marginRight: 10,
            '& img': {
                height: 35,
                width: 35,
                borderRadius: '50%',
            },
        },
        employeeData: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 10,
        },
        employeeDates: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        employeeName: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 12,
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
        },
        date: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 10,
            color: '#888888',
            fontWeight: theme.typography.fontWeightRegular,
            flex: 0.9,
        },
        daysCount: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 10,
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
        },
        currentMonthPara: {
            color: theme.palette.text.primary,
        },
        leaveText: {
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontSize: 14,
        },
    }),
);

export default otherStyle;
