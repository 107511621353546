import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllOrganizationTeams } from '../../helpers/hasuraapi';

export const getEmployeesList: any = createAsyncThunk('employeesList/getEmployeesList', async (data: any) => {
    const teamsEmployee = data.data.usermanagementdb_organization_employee;
    let employeeTeams: any = [];
    if (teamsEmployee.length > 0) {
        employeeTeams = teamsEmployee.map((emp: any) => {
            let myTeams: any;
            const approvers: any = [];
            const members: any = [];
            if (emp.Employee.team_employees.length > 0) {
                myTeams = emp.Employee.team_employees.map((tEmp: any) => {
                    const eTeams = {
                        teamId: tEmp.Team.teamId,
                        teamName: tEmp.Team.teamName,
                        isApprover: tEmp.isApprover,
                    };
                    if (tEmp.isApprover) {
                        approvers.push({
                            teamId: tEmp.Team.teamId,
                            teamName: tEmp.Team.teamName,
                            isApprover: tEmp.isApprover,
                        });
                    } else {
                        members.push({
                            teamId: tEmp.Team.teamId,
                            teamName: tEmp.Team.teamName,
                            isApprover: tEmp.isApprover,
                        });
                    }
                    return eTeams;
                });
            } else {
                myTeams = [];
            }
            const empDetail = {
                role: emp.role,
                id: emp.Employee.employeeId,
                email: emp.Employee.email,
                name: emp.Employee.firstName + ' ' + emp.Employee.lastName,
                firstName: emp.Employee.firstName,
                lastName: emp.Employee.lastName,
                image: emp.Employee.profileImage,
                status: emp.Employee.status,
                teams: myTeams,
                approvers: approvers,
                employees: members,
            };
            return empDetail;
        });
    } else {
        employeeTeams = [];
    }
    return employeeTeams;
});

export const getInviteStatus: any = createAsyncThunk('employeesList/getInviteStatus', async (data: any) => {
    // const mydata: any = await fetchInviteStatus(element.organizationId);
    return data.data.timeoffdb_Invites;
});

export const getAllOrgTeams: any = createAsyncThunk('employeesList/getAllOrgTeams', async (element: any) => {
    const data: any = await getAllOrganizationTeams(element.organizationId, element.token);
    const jsonData: any = await data.json();
    return jsonData.data.usermanagementdb_Organization[0].Teams;
});

export const employeesListSlice = createSlice({
    name: 'employeesList',
    initialState: {
        employeesList: {
            initialValues: [],
            initialStatus: 'loading',
            filteredValues: [],
            filteredStatus: 'loading',
            initialEmailInvites: [],
            initialInviteStatus: 'loading',
            filteredEmailInvites: [],
            filteredInviteStatus: 'loading',
            teams: [],
            teamStatus: 'loading',
            defaultTeam: null,
            profilePicture: null,
            profileName: null,
            teams2: null,
        },
    },
    reducers: {
        filterForAll: (state: any, action: any) => {
            state.employeesList.filteredValues = state.employeesList.initialValues;
        },
        filterForAdmin: (state: any, action: any) => {
            state.employeesList.filteredValues = action.payload;
        },
        filterForApprovers: (state: any, action: any) => {
            state.employeesList.filteredValues = action.payload;
        },
        filterForEmployees: (state: any, action: any) => {
            state.employeesList.filteredValues = action.payload;
        },
        promoteToAdmin: (state: any, action: any) => {
            state.employeesList.initialValues.forEach((item: any) => {
                if (item.id === action.payload.empId) {
                    item.role = action.payload.role;
                }
            });
            state.employeesList.filteredValues.forEach((item: any) => {
                if (item.id === action.payload.empId) {
                    item.role = action.payload.role;
                }
            });
        },
        inviteStatusList: (state: any, action: any) => {
            state.employeesList.filteredEmailInvites = action.payload;
        },
        updateProfileImage: (state: any, action: any) => {
            state.employeesList.profilePicture = action.payload.image;
        },
        updateProfileName: (state: any, action: any) => {
            state.employeesList.profileName = action.payload;
        },
    },
    extraReducers: {
        [getEmployeesList.pending]: (state: any) => {
            state.employeesList.initialStatus = 'loading';
            state.employeesList.filteredStatus = 'loading;';
        },
        [getEmployeesList.fulfilled]: (state: any, action: any) => {
            state.employeesList.initialValues = action.payload;
            state.employeesList.filteredValues = action.payload;
            action.payload.forEach((item: any) => {
                if (item.id === localStorage.getItem('uuid')) {
                    // console.log('Item received', item);
                    state.employeesList.teams2 = { approvers: item.approvers, employees: item.employees };
                }
            });
            // console.log('State employees', current(state.employeesList));
            state.employeesList.initialStatus = 'success';
            state.employeesList.filteredStatus = 'success';
        },
        [getEmployeesList.rejected]: (state: any) => {
            state.employeesList.initialStatus = 'failed';
            state.employeesList.filteredStatus = 'failed';
        },
        [getInviteStatus.pending]: (state: any) => {
            state.employeesList.initialInviteStatus = 'loading';
            state.employeesList.filteredInviteStatus = 'loading';
        },
        [getInviteStatus.fulfilled]: (state: any, action: any) => {
            state.employeesList.initialEmailInvites = action.payload;
            state.employeesList.filteredEmailInvites = action.payload;
            state.employeesList.initialInviteStatus = 'success';
            state.employeesList.filteredInviteStatus = 'success';
        },
        [getInviteStatus.rejected]: (state: any, action: any) => {
            state.employeesList.initialInviteStatus = 'failed';
            state.employeesList.filteredInviteStatus = 'failed';
        },
        [getAllOrgTeams.pending]: (state: any) => {
            state.employeesList.teamStatus = 'loading';
        },
        [getAllOrgTeams.fulfilled]: (state: any, action: any) => {
            state.employeesList.teams = action.payload;
            state.employeesList.defaultTeam = action.payload.filter((t: any) => t.teamName === 'general')[0];
            state.employeesList.teamStatus = 'success';
        },
        [getAllOrgTeams.rejected]: (state: any, action: any) => {
            state.employeesList.teamStatus = 'failed';
        },
    },
});

export const {
    filterForAll,
    filterForAdmin,
    filterForApprovers,
    filterForEmployees,
    promoteToAdmin,
    inviteStatusList,
    updateProfileImage,
    updateProfileName,
} = employeesListSlice.actions;
export default employeesListSlice.reducer;
