import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            fontFamily: theme.typography.fontFamily,
        },
        comingSoom: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 48,
            fontWeight: 700,
            color: theme.palette.primary.main,
            '@media screen and (max-width: 650px)': {
                fontSize: 40,
            },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 40,
            },
        },
        paper: {
            height: 'calc(100vh - 100px)',
            padding: 50,
            fontFamily: theme.typography.fontFamily,
            '@media screen and (max-width: 650px)': {
                height: '85vh',
            },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                height: '90vh',
            },
        },
        standupImage: {
            width: '100%',
            height: '100%',
            backgroundAttachment: 'fixed',
            '@media screen and (max-width: 650px)': {
                marginTop: 50,
            },
        },
        subtitle1: {
            fontSize: 24,
            fontWeight: 600,
            fontFamily: theme.typography.fontFamily,
            color: '#222222',
        },
    }),
);

export default useStyles;
