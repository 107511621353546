import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/AuthContext';
import EmployeeDashboard from './EmployeeDashboard';
import ApproverDashboard from './ApproverDashboard';
import { useDispatch } from 'react-redux';

export default function OtherDashboard() {
    const mycontext = useContext(AuthContext);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: 'ACTIVE', payload: '/dashboard' });
    }, [dispatch]);
    // return mycontext.isApprover ? (
    //     <ApproverDashboard
    //         pending={props.pending}
    //         todays={props.todays}
    //         upcoming={props.upcoming}
    //         employee={props.employee}
    //     />
    // ) : (
    //     <EmployeeDashboard employee={props.employee} />
    // );
    return mycontext.isApprover ? <ApproverDashboard /> : <EmployeeDashboard />;
}
