import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchEmployeeTodaysLeaves,
    fetchEmployeeUpcomingLeaves,
    getEmployee2,
    getEmployeeTeams,
    getTeams,
    employeeLeaveHistory,
} from 'helpers/hasuraapi';

export const getUserProfile: any = createAsyncThunk('userProfile/getUserProfile', async (user: any) => {
    const data: any = await getEmployee2(user.employeeId, user.token);
    const jsonData = await data.json();
    return jsonData.data.usermanagementdb_Employee[0];
});

export const getUsersTodaysLeaves: any = createAsyncThunk('userProfile/getUsersTodaysLeaves', async (user: any) => {
    const data: any = await fetchEmployeeTodaysLeaves(user.organizationId, user.token, user.employeeId, user.date);
    const jsonData = await data.json();
    return jsonData.data.timeoffdb_Leaves;
});

export const getUsersUpcomingLeaves: any = createAsyncThunk('userProfile/getUsersUpcomingLeaves', async (user: any) => {
    const data: any = await fetchEmployeeUpcomingLeaves(user.organizationId, user.token, user.employeeId, user.date);
    const jsonData = await data.json();
    return jsonData.data.timeoffdb_Leaves;
});

export const getEmpTeams: any = createAsyncThunk('userProfile/getEmpTeams', async (user: any) => {
    const data: any = await getEmployeeTeams(user.employeeId, user.organizationId, user.token);
    const jsonData = await data.json();
    return jsonData.data;
});

export const getNewEmpTeams: any = createAsyncThunk('userProfile/getNewEmpTeams', async (user: any) => {
    const data: any = await getTeams(user.employeeId, user.organizationId, user.token);
    const jsonData = await data.json();
    return jsonData.data;
});

export const getEmpLeaveHistory: any = createAsyncThunk('userProfile/getEmpLeaveHistory', async (user: any) => {
    const data: any = await employeeLeaveHistory(user.employeeId, user.organizationId, user.date, user.token);
    const jsonData = await data.json();
    return jsonData.data.timeoffdb_Leaves;
});

export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: {
        empTeams: [],
        newEmpTeams: [],
        empDetail: {},
        todaysLeaves: [],
        upcomingLeaves: [],
        empLeaveHistory: [],
        leaveHistoryStatus: 'loading',
        userProfileStatus: 'loading',
        empTeamStatus: 'loading',
        newEmpTeamStatus: 'loading',
        status: 'loading',
    },
    reducers: {},
    extraReducers: {
        [getUserProfile.pending]: state => {
            state.userProfileStatus = 'loading';
        },
        [getUserProfile.fulfilled]: (state, action) => {
            state.empDetail = action.payload;
            state.userProfileStatus = 'success';
        },
        [getUserProfile.rejected]: state => {
            state.userProfileStatus = 'failed';
        },
        [getEmpTeams.pending]: state => {
            state.empTeamStatus = 'loading';
        },
        [getEmpTeams.fulfilled]: (state, action) => {
            state.empTeams = action.payload;
            state.empTeamStatus = 'success';
        },
        [getEmpTeams.rejected]: state => {
            state.empTeamStatus = 'failed';
        },
        [getNewEmpTeams.pending]: state => {
            state.newEmpTeamStatus = 'loading';
        },
        [getNewEmpTeams.fulfilled]: (state, action) => {
            state.newEmpTeams = action.payload;
            state.newEmpTeamStatus = 'success';
        },
        [getNewEmpTeams.rejected]: state => {
            state.newEmpTeamStatus = 'failed';
        },
        [getUsersUpcomingLeaves.pending]: state => {
            state.status = 'loading';
        },
        [getUsersUpcomingLeaves.fulfilled]: (state, action) => {
            state.upcomingLeaves = action.payload;
            state.status = 'success';
        },
        [getUsersUpcomingLeaves.rejected]: state => {
            state.status = 'failed';
        },
        [getUsersTodaysLeaves.pending]: state => {
            state.status = 'loading';
        },
        [getUsersTodaysLeaves.fulfilled]: (state, action) => {
            state.todaysLeaves = action.payload;
            state.status = 'success';
        },
        [getUsersTodaysLeaves.rejected]: state => {
            state.status = 'failed';
        },
        [getEmpLeaveHistory.pending]: state => {
            state.leaveHistoryStatus = 'loading';
        },
        [getEmpLeaveHistory.fulfilled]: (state, action) => {
            state.empLeaveHistory = action.payload;
            state.leaveHistoryStatus = 'success';
        },
        [getEmpLeaveHistory.rejected]: state => {
            state.leaveHistoryStatus = 'failed';
        },
    },
});

export default userProfileSlice.reducer;
