/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useStyles, formStyle } from './style';
import {
    Button,
    Avatar,
    Typography,
    Grid,
    FormLabel,
    FormControl,
    Select,
    MenuItem,
    TextField,
    InputAdornment,
    IconButton,
    Popover,
    useTheme,
    Checkbox,
    RadioGroup,
    Radio,
    FormControlLabel,
    InputBase,
} from '@material-ui/core';
import { Hidden } from '@mui/material';
import Picker from 'emoji-picker-react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
    // toAWSDateFormat,
    ToStringFormat,
    //  toAWSISODateFormat,
    DateDifference,
    convertTime,
    convertTZ,
} from '../../helpers/date';
import {
    convertedDateTime,
    getCurrentCountryDate,
    getCurrentCountryDateTime,
    // isoToLocalTime
    // displayDiff,
    // giveTz,
    isoToLocal,
} from 'helpers/timezone';
import AuthContext from '../../context/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import AddEmojiLight from './addEmojiLight.svg';
import AddEmojiDark from './addEmojiDark.svg';
import CommentLight from './commentLight.svg';
import CommentDark from './commentDark.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import { updateActiveTab } from '../Redux/activeTab';

import confetti from 'canvas-confetti';
import CloseIcon from '@material-ui/icons/Close';
// import Arrow from './arrowSubmit.svg';
import StarMobile from './star_mobile.svg';
import FilterLogo from '../Employees/FilterLogo.svg';
import { LoaderPage } from '../Loader/loader';
import ErrorPage from '../Error/ErrorPage';
import MyModal from '../Modal/Modal';
// import { Howl, Howler } from 'howler';
import Applause from './applause.mp3';
import NotFound from './undraw_empty.svg';
import NotFoundDark from './undraw_empty_dark.svg';
import { Icon } from '@iconify/react';
import getInitials, { getFullName } from '../../helpers/getInitials';

import {
    createShoutout,
    updateShoutout,
    deleteShoutout,
    addShoutoutEmployee,
    addShoutoutTeam,
    deleteShoutoutEmployee,
    updateShoutoutEmployee,
    viewShoutout,
    addShoutoutComment,
    updateShoutoutComment,
    deleteShoutoutComment,
    addShoutoutReaction,
    updateShoutoutReaction,
    deleteShoutoutReaction,
} from '../../helpers/shoutoutApi';
import { useFlagsmith } from 'flagsmith-react';
import {
    // isoToLocal,
    localToISO,
} from 'helpers/timezone';
import { handleShoutout } from 'helpers/sendShoutoutNotif';

// const renderConfetti = () => {
//     const duration = 15 * 1000;
//     const animationEnd = Date.now() + duration;
//     const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 999999 };

//     function randomInRange(min: number, max: number) {
//         return Math.random() * (max - min) + min;
//     }

//     let interval: any = setInterval(function() {
//         const timeLeft = animationEnd - Date.now();

//         if (timeLeft <= 0) {
//             return clearInterval(interval);
//         }

//         const particleCount = 50 * (timeLeft / duration);
//         // since particles fall down, start a bit higher than random
//         confetti(
//             Object.assign({}, defaults, {
//                 particleCount,
//                 origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
//             }),
//         );
//         confetti(
//             Object.assign({}, defaults, {
//                 particleCount,
//                 origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
//             }),
//         );
//     }, 250);
// };

const allTags = ['#teamwork', '#greatwork', '#collaboration', '#culture'];
const allmessages = [
    'Thank you for being you.',
    'Your consistent positive attitude has made a huge difference in our team.',
    'Thank you for all the good work so far and being persistent.',
    'Thanks for meticulously documenting and effective communication with customer.',
    'Write your own custom message.',
];
export const emojis = [
    {
        activeSkinTone: 'neutral',
        emoji: '🎉',
        originalUnified: '1f64c',
        unified: '1f64c',
    },
    {
        activeSkinTone: 'neutral',
        emoji: '👍',
        originalUnified: '1f64c',
        unified: '1f64c',
    },
    {
        activeSkinTone: 'neutral',
        emoji: '👏',
        originalUnified: '1f64c',
        unified: '1f64c',
    },
    {
        activeSkinTone: 'neutral',
        emoji: '🙌',
        originalUnified: '1f64c',
        unified: '1f64c',
    },
];

const renderConfettiRealistic = () => {
    let count = 200;
    let defaults = {
        origin: { y: 0.7 },
        zIndex: 999999,
    };

    function fire(particleRatio: any, opts: any) {
        confetti(
            Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio),
            }),
        );
    }

    fire(0.25, {
        spread: 26,
        startVelocity: 55,
    });
    fire(0.2, {
        spread: 60,
    });
    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 45,
    });
};

interface Sidebar {
    open?: boolean;
}

const colors = [
    'rgba(149, 111, 228, 0.3)',
    'rgba(234, 168, 91, 0.4)',
    'rgba(79, 184, 178, 0.3)',
    'rgba(88, 126, 224, 0.3)',
];

const checkSubscription = (userSubscriptionDetails: any) => {
    const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
    try {
        if (
            userSubscriptionDetail?.engageSubscriptionId === null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('ebtp')).length === 0
        ) {
            throw new Error("Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!");
        }
        if (
            userSubscriptionDetail?.engageSubscriptionId === null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter(
                (s: any) => s.planId.includes('ebtp') && s.status !== 'Subscribed',
            ).length !== 0
        ) {
            throw new Error("Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!");
        }
        if (
            userSubscriptionDetail?.engageSubscriptionId === null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter(
                (s: any) => s.planId.includes('ebtp') && s.status === 'Subscribed',
            ).length !== 0
        ) {
            throw new Error(
                "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
            );
        }
        if (
            userSubscriptionDetail?.engageSubscriptionId !== null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter(
                (s: any) =>
                    s.subscriptionId === userSubscriptionDetail?.engageSubscriptionId && s.status === 'Suspended',
            ).length !== 0
        ) {
            throw new Error(
                'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
            );
        }
        if (
            userSubscriptionDetail?.engageSubscriptionId !== null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter(
                (s: any) =>
                    s.subscriptionId === userSubscriptionDetail?.engageSubscriptionId && s.status === 'Unsubscribed',
            ).length !== 0
        ) {
            throw new Error(
                'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
            );
        }
    } catch (err) {
        throw new Error(err.message);
    }
};

export const AvatarsComponent = (props: { to: any; from: any }) => {
    const classes = useStyles();
    const theme = useTheme();
    if (props.to?.[0]?.Employee === null) {
        return (
            <div style={{ marginLeft: 10, position: 'relative' }}>
                {props.to?.map((p: any, i: number) =>
                    i === 0 ? (
                        <Avatar
                            data-testid="avatar"
                            key={i}
                            alt=""
                            className={classes.logo}
                            style={{
                                float: 'right',
                                border: `2px solid ${theme.palette.background.paper}`,
                                textTransform: 'uppercase',
                                color: '#000',
                            }}
                        >
                            {getFullName(p?.Team?.teamName + ' T')}
                        </Avatar>
                    ) : props.to?.length >= 2 ? (
                        i === 1 ? (
                            <Avatar
                                data-testid="avatar"
                                key={i}
                                alt=""
                                className={classes.logo}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: `${i * 8}px`,
                                    border: `2px solid ${theme.palette.background.paper}`,
                                    textTransform: 'uppercase',
                                    color: '#000',
                                }}
                            >
                                {getFullName(p?.Team?.teamName + ' T')}
                            </Avatar>
                        ) : null
                    ) : // ) : (
                    //     <div
                    //         style={{
                    //             width: 22,
                    //             height: 22,
                    //             position: 'absolute',
                    //             top: -5,
                    //             right: -5,
                    //             border: `1px solid ${theme.palette.text.primary}`,
                    //             display: 'flex',
                    //             alignItems: 'center',
                    //             justifyContent: 'center',
                    //             borderRadius: '50%',
                    //             background: theme.palette.background.paper,
                    //             color: theme.palette.text.primary,
                    //         }}
                    //         key={i}
                    //     >
                    //         <span data-testid="imgcount">{props.to?.length - 2}</span>
                    //     </div>
                    // )
                    null,
                )}
                {props.from?.profile ? (
                    <Avatar
                        data-testid="avatar"
                        src={props.from?.profile}
                        alt=""
                        className={classes.logo}
                        style={{
                            width: 25,
                            height: 25,
                            position: 'absolute',
                            top: 25,
                            left: -5,
                            border: `2px solid ${theme.palette.background.paper}`,
                        }}
                    />
                ) : (
                    <Avatar
                        data-testid="avatar"
                        alt=""
                        className={classes.logo}
                        style={{
                            width: 25,
                            height: 25,
                            position: 'absolute',
                            top: 25,
                            left: -5,
                            border: `2px solid ${theme.palette.background.paper}`,
                            textTransform: 'uppercase',
                            fontSize: 12,
                            color: '#000',
                        }}
                    >
                        {getFullName(props.from?.name + ' ' + props.from?.lastName)}
                    </Avatar>
                )}
            </div>
        );
    }
    return (
        <div style={{ marginLeft: 10, position: 'relative' }}>
            {props.to?.map((p: any, i: number) =>
                i === 0 ? (
                    p?.Employee?.profileImage ? (
                        <Avatar
                            data-testid="avatar"
                            key={i}
                            src={p?.Employee?.profileImage}
                            alt=""
                            className={classes.logo}
                            style={{ float: 'right', border: `2px solid ${theme.palette.background.paper}` }}
                        />
                    ) : (
                        <Avatar
                            data-testid="avatar"
                            key={i}
                            alt=""
                            className={classes.logo}
                            style={{
                                float: 'right',
                                border: `2px solid ${theme.palette.background.paper}`,
                                textTransform: 'uppercase',
                                color: '#000',
                            }}
                        >
                            {getFullName(p?.Employee?.firstName + ' ' + p?.Employee?.lastName)}
                        </Avatar>
                    )
                ) : props.to?.length >= 2 ? (
                    i === 1 ? (
                        p?.Employee?.profileImage ? (
                            <Avatar
                                data-testid="avatar"
                                key={i}
                                src={p?.Employee?.profileImage}
                                alt=""
                                className={classes.logo}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: `${i * 8}px`,
                                    border: `2px solid ${theme.palette.background.paper}`,
                                }}
                                // style={{ float: 'right', border: '1px solid white' }}
                            />
                        ) : (
                            <Avatar
                                data-testid="avatar"
                                key={i}
                                alt=""
                                className={classes.logo}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: `${i * 8}px`,
                                    border: `2px solid ${theme.palette.background.paper}`,
                                    textTransform: 'uppercase',
                                    color: '#000',
                                }}
                            >
                                {getFullName(p?.Employee?.firstName + ' ' + p?.Employee?.lastName)}
                            </Avatar>
                        )
                    ) : null
                ) : // ) : (
                //     <div
                //         style={{
                //             width: 22,
                //             height: 22,
                //             position: 'absolute',
                //             top: -5,
                //             right: -5,
                //             border: `1px solid ${theme.palette.text.primary}`,
                //             display: 'flex',
                //             alignItems: 'center',
                //             justifyContent: 'center',
                //             borderRadius: '50%',
                //             background: theme.palette.background.paper,
                //             color: theme.palette.text.primary,
                //         }}
                //         key={i}
                //     >
                //         <span data-testid="imgcount">{props.to?.length - 2}</span>
                //     </div>
                // )
                null,
            )}
            {props.from?.profile ? (
                <Avatar
                    data-testid="avatar"
                    src={props.from?.profile}
                    alt=""
                    className={classes.logo}
                    style={{
                        width: 25,
                        height: 25,
                        position: 'absolute',
                        top: 25,
                        left: -5,
                        border: `2px solid ${theme.palette.background.paper}`,
                    }}
                />
            ) : (
                <Avatar
                    data-testid="avatar"
                    alt=""
                    className={classes.logo}
                    style={{
                        width: 25,
                        height: 25,
                        position: 'absolute',
                        top: 25,
                        left: -5,
                        border: `2px solid ${theme.palette.background.paper}`,
                        textTransform: 'uppercase',
                        fontSize: 12,
                        color: '#000',
                    }}
                >
                    {getFullName(props.from?.name + ' ' + props.from?.lastName)}
                </Avatar>
            )}
        </div>
    );
};

export const KudosComponent = (props: {
    to: any;
    from: any;
    message: string;
    tags: any;
    emoji: any;
    isDrawer: boolean;
    current: any;
    setCurrent: any;
    setIsMobile?: any;
    setSidebar?: any;
    handleEdit?: any;
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const mycontext = useContext(AuthContext);
    const [open, setOpen] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const state = useSelector((state: any) => state);
    const userSubscriptionDetails = state.subscription.userSubscriptions;

    const handleDelete = async () => {
        try {
            checkSubscription(userSubscriptionDetails);
            const obj = {
                shoutoutId: props.current?.shoutoutId,
                token: mycontext.token,
            };
            await deleteShoutout(obj);
            // const deleteResponse = await deleteShoutout(obj);
            mycontext.togglePopup({
                alertMessage: 'Shoutout deleted successfully!',
                severity: 'success',
            });
            props.setCurrent({});
            props.setIsMobile(false);
            props.setSidebar({ open: false });
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    const handleSubmit = async (value: string) => {
        if (value === 'Edit') {
            props.handleEdit();
        } else {
            handleDelete();
        }
    };

    const handlePicker = (e: any) => {
        setOpen(!open);
        setAnchorEl(e.currentTarget);
    };

    return (
        <>
            <Popover
                id="picker-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setOpen(false);
                    setAnchorEl(null);
                }}
            >
                <ul className={classes.filterList2}>
                    {['Edit', 'Delete'].map((e: string) => (
                        <li
                            key={e}
                            onClick={() => {
                                setOpen(false);
                                setAnchorEl(null);
                                handleSubmit(e);
                            }}
                            className={classes.filterName}
                        >
                            {e}
                        </li>
                    ))}
                </ul>
            </Popover>
            <div style={{ display: 'flex', flex: 1, paddingLeft: 10 }}>
                {props.to?.[0]?.Employee === null ? (
                    <Typography className={props.isDrawer ? classes.text : classes.textShort} data-testid="kudosText">
                        Kudos{' '}
                        {props.isDrawer ? (
                            props.to?.map((p: any, i: number) => (
                                <span
                                    key={i}
                                    className={classes.textLink}
                                    // onClick={() => mycontext.handleProfile(p.Employee?.employeeId)}
                                    data-testid="to"
                                >
                                    {p?.Team?.teamName}
                                    {i === props.to?.length - 1 ? null : ', '}
                                </span>
                            ))
                        ) : (
                            <span className={classes.textLink} data-testid="to">
                                {props.to?.[0]?.Team?.teamName}
                                {' Team! '}
                                <span
                                    style={{
                                        textTransform: 'lowercase',
                                        fontWeight: theme.typography.fontWeightRegular,
                                    }}
                                >
                                    {props.to?.length > 1 ? 'and ' : null}
                                </span>
                                <span
                                    style={{
                                        fontSize: 16,
                                        color: theme.palette.text.secondary,
                                        textTransform: 'lowercase',
                                    }}
                                >
                                    {props.to?.length > 1 ? `${props.to?.length - 1} more` : null}
                                </span>
                            </span>
                        )}
                        &nbsp;🙌 &nbsp;
                        <span
                            data-testid="from"
                            className={classes.textLink}
                            onClick={() => mycontext.handleProfile(props.from?.employeeId)}
                        >
                            {props.from?.name}
                        </span>
                        &nbsp;said, &#x27;<i>{props?.message}</i> {props.emoji?.emoji}&#x27;&nbsp;
                        <span className={classes.tag} data-testid="tags">
                            {props?.tags}
                        </span>
                    </Typography>
                ) : (
                    <Typography className={props.isDrawer ? classes.text : classes.textShort} data-testid="kudosText">
                        Kudos{' '}
                        {props.isDrawer ? (
                            props.to?.map((p: any, i: number) => (
                                <span
                                    key={i}
                                    className={classes.textLink}
                                    onClick={() => mycontext.handleProfile(p.Employee?.employeeId)}
                                    data-testid="to"
                                >
                                    {p?.Employee?.firstName}
                                    {i === props.to?.length - 1 ? null : ', '}
                                </span>
                            ))
                        ) : (
                            <span className={classes.textLink} data-testid="to">
                                {props.to?.[0]?.Employee?.firstName}
                                <span
                                    style={{
                                        textTransform: 'lowercase',
                                        fontWeight: theme.typography.fontWeightRegular,
                                    }}
                                >
                                    {props.to?.length > 1 ? ' and ' : null}
                                </span>
                                <span
                                    style={{
                                        fontSize: 16,
                                        color: theme.palette.text.secondary,
                                        textTransform: 'lowercase',
                                    }}
                                >
                                    {props.to?.length > 1 ? `${props.to?.length - 1} more` : null}
                                </span>
                            </span>
                        )}
                        ! 🙌 &nbsp;
                        <span
                            data-testid="from"
                            className={classes.textLink}
                            onClick={() => mycontext.handleProfile(props.from?.employeeId)}
                        >
                            {props.from?.name}
                        </span>
                        &nbsp;said, &#x27;<i>{props?.message}</i> {props.emoji?.emoji}&#x27;&nbsp;
                        <span className={classes.tag} data-testid="tags">
                            {props?.tags}
                        </span>
                    </Typography>
                )}
                <div style={{ height: '100%', display: 'flex' }} data-testid="morevert">
                    {props.isDrawer && props.from?.employeeId === mycontext.EmployeeId && (
                        <MoreVertIcon
                            style={{
                                width: 24,
                                height: 24,
                                marginRight: '10px',
                                color: theme.palette.text.primary,
                                cursor: 'pointer',
                            }}
                            onClick={e => handlePicker(e)}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export const CardFooterComponent = (props: {
    item: any;
    setOption: any;
    reactions: any;
    comments?: any;
    setCurrent: any;
}) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const [open, setOpen] = React.useState(false);
    const state = useSelector((state: any) => state);
    const userSubscriptionDetails = state.subscription.userSubscriptions;
    const addReaction = async (emoji: any) => {
        const obj = {
            shoutoutId: props.item?.shoutoutId,
            employeeId: mycontext.EmployeeId,
            emoji: emoji,
            token: mycontext.token,
        };
        try {
            checkSubscription(userSubscriptionDetails);
            if (props.reactions?.find((r: any) => r.employeeId === mycontext.EmployeeId) === undefined) {
                await addShoutoutReaction(obj);
                props.setCurrent((prev: any) => {
                    const d: any = {
                        ...prev,
                        reactions: [
                            ...prev.reactions,
                            {
                                employeeId: mycontext.EmployeeId,
                                reaction: obj.emoji,
                                Employee: {
                                    firstName: mycontext.fname,
                                    profileImage: mycontext.profile,
                                },
                            },
                        ],
                    };
                    return d;
                });
            } else {
                if (
                    props.reactions?.find((r: any) => r.employeeId === mycontext.EmployeeId)?.reaction?.emoji ===
                    obj.emoji?.emoji
                ) {
                    await deleteShoutoutReaction(obj);
                    props.setCurrent((prev: any) => {
                        const d: any = {
                            ...prev,
                            reactions: [...prev.reactions.filter((r: any) => r.employeeId !== mycontext.EmployeeId)],
                        };
                        return d;
                    });
                } else {
                    await updateShoutoutReaction(obj);
                    props.setCurrent((prev: any) => {
                        const d: any = {
                            ...prev,
                            reactions: [
                                ...prev.reactions.filter((r: any) => r.employeeId !== mycontext.EmployeeId),
                                {
                                    employeeId: mycontext.EmployeeId,
                                    reaction: obj.emoji,
                                    Employee: {
                                        firstName: mycontext.fname,
                                        profileImage: mycontext.profile,
                                    },
                                },
                            ],
                        };
                        return d;
                    });
                }
            }
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };
    return (
        <>
            <div className={classes.cardFooterLeft} onMouseLeave={() => setOpen(false)}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        position: 'relative',
                    }}
                    onClick={() => props.setOption('smiley')}
                    onMouseEnter={() => setOpen(true)}
                    // onMouseOut={() => setOpen(false)}
                >
                    <div className={open ? classes.emojiPicker : classes.empty} onMouseEnter={() => setOpen(true)}>
                        {emojis.map((e: any, i: number) => (
                            <span key={i} style={{ fontSize: 18 }} onClick={() => addReaction(e)}>
                                {e.emoji}
                            </span>
                        ))}
                    </div>
                    {mycontext.themeToggle ? (
                        <img src={AddEmojiDark} alt="add-emoji" style={{ width: 20, height: 20 }} />
                    ) : (
                        <img src={AddEmojiLight} alt="add-emoji" style={{ width: 20, height: 20 }} />
                    )}
                    <span className={classes.footerText}>{props.item?.reactions?.length} reactions</span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => props.setOption('comment')}
                >
                    {mycontext.themeToggle ? (
                        <img src={CommentDark} alt="add-emoji" style={{ width: 20, height: 20 }} />
                    ) : (
                        <img src={CommentLight} alt="add-emoji" style={{ width: 20, height: 20 }} />
                    )}
                    <span className={classes.footerText}>{props.item?.comments?.length} comments</span>
                </div>
            </div>
            <div
                style={{
                    flex: 0.6,
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                }}
            >
                <Typography className={classes.date}>
                    {props.item?.createdOn ? (
                        DateDifference(
                            convertedDateTime(props.item?.createdOn, mycontext.country)?.date,
                            getCurrentCountryDate(mycontext.country?.zoneName),
                        ) > 2 ? (
                            <span style={{ fontSize: 12 }}>
                                {ToStringFormat(
                                    convertedDateTime(props.item?.createdOn, mycontext.country)?.date,
                                    mycontext.dateFormat,
                                )}
                            </span>
                        ) : (
                            <span style={{ fontSize: 12 }}>
                                {/* {displayDiff(
                                    new Date(
                                        `${convertedDateTime(props.item?.createdOn, mycontext.country)?.date} ${
                                            convertedDateTime(props.item?.createdOn, mycontext.country)?.time
                                        } ${giveTz(mycontext.country.gmtOffset.toString())}`,
                                    ),
                                    mycontext.country,
                                )} */}
                                {/* {isoToLocal(props.item?.createdOn, mycontext.country).toString()}{' '}
                                {convertTZ(new Date().toISOString(), mycontext.country?.zoneName).toString()} */}
                                {moment(isoToLocal(props.item?.createdOn, mycontext.country)).from(
                                    convertTZ(new Date().toISOString(), mycontext.country?.zoneName),
                                )}
                                {/* {moment(
                                    new Date(
                                        `${convertedDateTime(props.item?.createdOn, mycontext.country)?.date} ${
                                            convertedDateTime(props.item?.createdOn, mycontext.country)?.time
                                        }`,
                                    ),
                                )
                                    .startOf('second')
                                    .fromNow()} */}
                                {/* {moment(
                                    new Date(`${convertedDateTime(props.item?.createdOn, mycontext.country)?.date} ${props.item?.createdOn?.split('T')[1]}`),
                                )
                                    .subtract(1, 'days')
                                    .calendar()} */}
                            </span>
                        )
                    ) : (
                        ToStringFormat(props.item?.createdOn, mycontext.dateFormat)
                    )}
                </Typography>
                <Typography className={classes.date}>
                    {props.item?.createdOn ? (
                        DateDifference(
                            convertedDateTime(props.item?.createdOn, mycontext.country)?.date,
                            getCurrentCountryDate(mycontext.country?.zoneName),
                        ) > 2 ? (
                            <span style={{ fontSize: 12, marginLeft: 3 }}>
                                {convertTime(convertedDateTime(props.item?.createdOn, mycontext.country)?.time)}
                            </span>
                        ) : null
                    ) : null}
                </Typography>
            </div>
        </>
    );
};

export const Comments = (props: { comments: any; reactions: any; current?: any; setCurrent?: any }) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const theme = useTheme();
    // const [comment, setComment] = React.useState<string>('');
    const [open, setOpen] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const [current, setCurrent] = React.useState<any>({});
    const [action, setAction] = React.useState<string>('');

    const [editText, setEditText] = React.useState<string>('');
    const state = useSelector((state: any) => state);
    const userSubscriptionDetails = state.subscription.userSubscriptions;

    // const addComment = async (e: any) => {
    //     e.preventDefault();
    //     try {
    //         if (comment === '') {
    //             throw new Error('Please add comment!');
    //         }
    //         const obj = {
    //             employeeId: mycontext.EmployeeId,
    //             shoutoutId: props.current?.shoutoutId,
    //             commentText: comment,
    //             commentDateTime: localToISO(new Date().toDateString(), new Date().toTimeString().split(' ')[0], mycontext.country),
    //             token: mycontext.token,
    //         };
    //         const data: any = await addShoutoutComment(obj);
    //         setComment('');
    //         props.setCurrent((prev: any) => {
    //             const d: any = {
    //                 ...prev,
    //                 comments: [
    //                     ...prev.comments,
    //                     {
    //                         commentDateTime: localToISO(new Date().toDateString(), new Date().toTimeString().split(' ')[0], mycontext.country),
    //                         commentId: data?.commentId,
    //                         employeeId: mycontext.EmployeeId,
    //                         commentText: obj.commentText,
    //                         Employee: {
    //                             firstName: mycontext.fname,
    //                             profileImage: mycontext.profile,
    //                         },
    //                     },
    //                 ],
    //             };
    //             return d;
    //         });
    //     } catch (err) {
    //         return err;
    //     }
    // };
    //add subscription checks
    const deleteComment = async (o: any) => {
        try {
            if (o.commentId === undefined) {
                throw new Error('Failed to delete comment!');
            }
            checkSubscription(userSubscriptionDetails);
            const obj = {
                commentId: o?.commentId,
                token: mycontext.token,
            };
            const data = await deleteShoutoutComment(obj);
            if (data.commentId) {
                props.setCurrent((prev: any) => {
                    const d: any = {
                        ...prev,
                        comments: [...prev.comments.filter((c: any) => c.commentId !== obj.commentId)],
                    };
                    return d;
                });
            }
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    const editComment = async (e: any, o: any) => {
        e.preventDefault();
        try {
            if (o.commentId === undefined) {
                throw new Error('Failed to delete comment!');
            }
            checkSubscription(userSubscriptionDetails);
            const obj = {
                commentId: o?.commentId,
                commentText: editText,
                employeeId: o?.employeeId,
                commentDateTime: localToISO(
                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                    mycontext.country,
                ),
                token: mycontext.token,
            };
            const data = await updateShoutoutComment(obj);
            setCurrent({});
            if (data?.commentId) {
                props.setCurrent((prev: any) => {
                    const d: any = {
                        ...prev,
                        comments: [
                            ...prev.comments.filter((c: any) => c.commentId !== obj.commentId),
                            {
                                commentId: obj.commentId,
                                commentText: obj.commentText,
                                employeeId: obj.employeeId,
                                commentDateTime: localToISO(
                                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                                    mycontext.country,
                                ),
                                Employee: {
                                    firstName: mycontext.fname,
                                    profileImage: mycontext.profile,
                                },
                            },
                        ],
                    };
                    return d;
                });
                setAction('');
                setEditText('');
            }
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    const handlePicker = (e: any, obj: any) => {
        setOpen(!open);
        setAnchorEl(e.currentTarget);
        setCurrent(obj);
        setEditText(obj?.commentText);
    };

    const handleSubmit = (value: string) => {
        if (value === 'Edit') {
            setAction(value);
        } else {
            deleteComment(current);
        }
    };

    return (
        <>
            <Popover
                id="picker-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setOpen(false);
                    setAnchorEl(null);
                }}
            >
                <ul className={classes.filterList2}>
                    {['Edit', 'Delete'].map((e: string) => (
                        <li
                            key={e}
                            onClick={() => {
                                setOpen(false);
                                setAnchorEl(null);
                                handleSubmit(e);
                            }}
                            className={classes.filterName}
                        >
                            {e}
                        </li>
                    ))}
                </ul>
            </Popover>
            <div className={classes.comments}>
                <Typography>Comments</Typography>
                <div className={classes.commentList} data-testid="commentsDiv">
                    {props.comments?.length === 0 ? (
                        <div
                            style={{
                                height: '200px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {mycontext.themeToggle ? (
                                <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                            ) : (
                                <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                            )}
                            <Typography className={classes.notFoundText}>No comments found!</Typography>
                        </div>
                    ) : (
                        props.comments?.map((c: any, i: number) =>
                            c?.commentId === current?.commentId && action === 'Edit' ? (
                                <div
                                    style={{ display: 'flex', height: 'auto', padding: '10px 0', margin: '5px 0' }}
                                    key={i}
                                >
                                    <Avatar
                                        src={c?.Employee.profileImage}
                                        alt=""
                                        style={{ width: 38, height: 38, marginRight: 10 }}
                                    />
                                    <form
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            border: `1px solid ${theme.palette.text.primary}`,
                                            borderRadius: 3,
                                            padding: '5px 10px',
                                        }}
                                        onSubmit={(e: any) => {
                                            editComment(e, current);
                                        }}
                                    >
                                        <input
                                            type="text"
                                            value={editText}
                                            onChange={(e: any) => setEditText(e.target.value)}
                                            style={{ border: 'none', outline: 'none', height: 26, padding: 0 }}
                                            className={classes.commentBox}
                                            maxLength={50}
                                            autoFocus
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                marginTop: 5,
                                            }}
                                        >
                                            <Button
                                                onClick={() => {
                                                    setCurrent({});
                                                    setAction('');
                                                }}
                                                variant="outlined"
                                                style={{
                                                    width: 26,
                                                    height: 22,
                                                    fontSize: 11,
                                                    margin: 0,
                                                    marginRight: 5,
                                                }}
                                                className={classes.filterBtn}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                style={{ width: 26, height: 22, fontSize: 11, margin: 0 }}
                                                className={classes.shoutoutBtn}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            ) : (
                                <div
                                    key={i}
                                    style={{
                                        display: 'flex',
                                        height: 'auto',
                                        padding: '10px 0',
                                        margin: '2px 0',
                                    }}
                                >
                                    <Avatar
                                        src={c?.Employee.profileImage}
                                        alt=""
                                        style={{ width: 38, height: 38, marginRight: 10 }}
                                    />
                                    <div
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography style={{ fontSize: 15, fontWeight: 'bold' }}>
                                                    {c?.Employee?.firstName}
                                                </Typography>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    {/* <Hidden smDown>
                                                        {DateDifference(c?.commentDateTime.split('T')[0], new Date()) >
                                                        2 ? (
                                                            <span style={{ fontSize: 12 }}>
                                                                {ToStringFormat(
                                                                    c?.commentDateTime.split('T')[0],
                                                                    mycontext.dateFormat,
                                                                )}
                                                                <span style={{ marginLeft: 3 }}>
                                                                    {convertTime(
                                                                        c?.commentDateTime.split('T')[1].slice(0, 5),
                                                                    )}
                                                                </span>
                                                            </span>
                                                        ) : (
                                                            <span style={{ fontSize: 12 }}>
                                                                {moment(
                                                                    new Date(
                                                                        `${c?.commentDateTime.split('T')[0]} ${
                                                                            c?.commentDateTime.split('T')[1]
                                                                        }`,
                                                                    ),
                                                                )
                                                                    .startOf('second')
                                                                    .fromNow()}
                                                            </span>
                                                        )}
                                                    </Hidden> */}
                                                    <Hidden smDown>
                                                        {DateDifference(
                                                            convertedDateTime(c?.commentDateTime, mycontext.country)
                                                                ?.date,
                                                            getCurrentCountryDate(mycontext.country?.zoneName),
                                                        ) > 2 ? (
                                                            <span style={{ fontSize: 12 }}>
                                                                {ToStringFormat(
                                                                    convertedDateTime(
                                                                        c?.commentDateTime,
                                                                        mycontext.country,
                                                                    )?.date,
                                                                    mycontext.dateFormat,
                                                                )}
                                                                <span style={{ marginLeft: 3 }}>
                                                                    {convertTime(
                                                                        convertedDateTime(
                                                                            c?.commentDateTime,
                                                                            mycontext.country,
                                                                        )?.time,
                                                                    )}
                                                                </span>
                                                            </span>
                                                        ) : (
                                                            <span style={{ fontSize: 12 }}>
                                                                {/* {moment(
                                                                    new Date(
                                                                        `${
                                                                            convertedDateTime(
                                                                                c?.commentDateTime,
                                                                                mycontext.country,
                                                                            )?.date
                                                                        } ${
                                                                            convertedDateTime(
                                                                                c?.commentDateTime,
                                                                                mycontext.country,
                                                                            )?.time
                                                                        }`,
                                                                    ),
                                                                )
                                                                    .startOf('second')
                                                                    .fromNow()} */}
                                                                {/* {isoToLocal(
                                                                    c?.commentDateTime,
                                                                    mycontext.country,
                                                                ).toString()}{' '}
                                                                {convertTZ(
                                                                    new Date().toISOString(),
                                                                    mycontext.country?.zoneName,
                                                                ).toString()} */}
                                                                {moment(
                                                                    isoToLocal(c?.commentDateTime, mycontext.country),
                                                                ).from(
                                                                    convertTZ(
                                                                        new Date().toISOString(),
                                                                        mycontext.country?.zoneName,
                                                                    ),
                                                                )}
                                                                {/* {displayDiff(
                                                                    new Date(
                                                                        `${
                                                                            convertedDateTime(
                                                                                c?.commentDateTime,
                                                                                mycontext.country,
                                                                            )?.date
                                                                        } ${
                                                                            convertedDateTime(
                                                                                c?.commentDateTime,
                                                                                mycontext.country,
                                                                            )?.time
                                                                        } ${giveTz(
                                                                            mycontext.country.gmtOffset.toString(),
                                                                        )}`,
                                                                    ),
                                                                    mycontext.country,
                                                                )} */}
                                                            </span>
                                                        )}
                                                    </Hidden>
                                                    {c?.employeeId === mycontext.EmployeeId && (
                                                        <MoreVertIcon
                                                            style={{
                                                                width: 22,
                                                                height: 22,
                                                                marginLeft: 2,
                                                                cursor: 'pointer',
                                                                color: theme.palette.text.primary,
                                                            }}
                                                            onClick={e => handlePicker(e, c)}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <Typography style={{ fontSize: 14 }}>{c?.commentText}</Typography>
                                            <Typography>
                                                {/* <Hidden smUp>
                                                    {DateDifference(c?.commentDateTime.split('T')[0], new Date()) >
                                                    2 ? (
                                                        <span style={{ fontSize: 10 }}>
                                                            {ToStringFormat(
                                                                c?.commentDateTime.split('T')[0],
                                                                mycontext.dateFormat,
                                                            )}
                                                            <span style={{ marginLeft: 3 }}>
                                                                {convertTime(
                                                                    c?.commentDateTime.split('T')[1].slice(0, 5),
                                                                )}
                                                            </span>
                                                        </span>
                                                    ) : (
                                                        <span style={{ fontSize: 10 }}>
                                                            {moment(
                                                                new Date(
                                                                    `${c?.commentDateTime.split('T')[0]} ${
                                                                        c?.commentDateTime.split('T')[1]
                                                                    }`,
                                                                ),
                                                            )
                                                                .startOf('second')
                                                                .fromNow()}
                                                        </span>
                                                    )}
                                                </Hidden> */}
                                                <Hidden smUp>
                                                    {DateDifference(
                                                        convertedDateTime(c?.commentDateTime, mycontext.country)?.date,
                                                        getCurrentCountryDate(mycontext.country?.zoneName),
                                                    ) > 2 ? (
                                                        <span style={{ fontSize: 10 }}>
                                                            {ToStringFormat(
                                                                convertedDateTime(c?.commentDateTime, mycontext.country)
                                                                    ?.date,
                                                                mycontext.dateFormat,
                                                            )}
                                                            <span style={{ marginLeft: 3 }}>
                                                                {convertTime(
                                                                    convertedDateTime(
                                                                        c?.commentDateTime,
                                                                        mycontext.country,
                                                                    )?.time,
                                                                )}
                                                            </span>
                                                        </span>
                                                    ) : (
                                                        <span style={{ fontSize: 10 }}>
                                                            {/* {moment(
                                                                new Date(
                                                                    `${
                                                                        convertedDateTime(
                                                                            c?.commentDateTime,
                                                                            mycontext.country,
                                                                        )?.date
                                                                    } ${
                                                                        convertedDateTime(
                                                                            c?.commentDateTime,
                                                                            mycontext.country,
                                                                        )?.time
                                                                    }`,
                                                                ),
                                                            )
                                                                .startOf('second')
                                                                .fromNow()} */}
                                                            {/* {displayDiff(
                                                                new Date(
                                                                    `${
                                                                        convertedDateTime(
                                                                            c?.commentDateTime,
                                                                            mycontext.country,
                                                                        )?.date
                                                                    } ${
                                                                        convertedDateTime(
                                                                            c?.commentDateTime,
                                                                            mycontext.country,
                                                                        )?.time
                                                                    } ${giveTz(
                                                                        mycontext.country.gmtOffset.toString(),
                                                                    )}`,
                                                                ),
                                                                mycontext.country,
                                                            )} */}
                                                            {moment(
                                                                isoToLocal(c?.commentDateTime, mycontext.country),
                                                            ).from(
                                                                convertTZ(
                                                                    new Date().toISOString(),
                                                                    mycontext.country?.zoneName,
                                                                ),
                                                            )}
                                                        </span>
                                                    )}
                                                </Hidden>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            ),
                        )
                    )}
                </div>
                {/* <form onSubmit={addComment} className={classes.commentForm} data-testid="form">
                    <input
                        type="text"
                        placeholder="Type your message..."
                        value={comment}
                        onChange={(e: any) => setComment(e.target.value)}
                        className={classes.commentBox}
                        maxLength={50}
                    />
                    <button type="submit" className={classes.commentBtn} data-testid="submitBtn">
                        <Icon icon="carbon:send-filled" color={theme.palette.text.secondary} width="24" />
                    </button>
                </form> */}
            </div>
        </>
    );
};

export const Reactions = (props: { reactions: any }) => {
    const [currentEmoji, setCurrentEmoji] = React.useState('All');
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    return (
        <div className={classes.reactions}>
            <Typography>Reactions</Typography>
            <ul className={classes.tabs}>
                <li
                    onClick={() => setCurrentEmoji('All')}
                    className={currentEmoji === 'All' ? classes.activeTab : classes.tab}
                >
                    All <span className={classes.emojiCount}>({props.reactions?.length})</span>
                </li>
                {emojis.map((e: any, i: number) => (
                    <li
                        key={i}
                        onClick={() => setCurrentEmoji(e.emoji)}
                        className={currentEmoji === e?.emoji ? classes.activeTab : classes.tab}
                    >
                        {e?.emoji}{' '}
                        <span className={classes.emojiCount}>
                            ({props.reactions?.filter((c: any) => c?.reaction?.emoji === e?.emoji)?.length})
                        </span>
                    </li>
                ))}
            </ul>
            <div className={classes.reactionList} data-testid="reactionsDiv">
                {props.reactions?.length === 0 ? (
                    <div
                        style={{
                            // height: '200px',
                            // flex: '1 1 auto',
                            // flexGrow: 'inherit',
                            // background: 'red',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {mycontext.themeToggle ? (
                            <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                        ) : (
                            <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                        )}
                        <Typography className={classes.notFoundText}>No reactions found!</Typography>
                    </div>
                ) : currentEmoji === 'All' ? (
                    props.reactions?.map((c: any, i: number) => (
                        <div key={i} style={{ display: 'flex', height: 50, padding: 5, margin: '5px 0' }}>
                            <Avatar
                                src={c?.Employee?.profileImage}
                                alt=""
                                style={{ width: 38, height: 38, marginRight: 10 }}
                            />
                            <div style={{ flex: 1 }}>
                                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                                    {c?.Employee.firstName}
                                </Typography>
                                <Typography style={{ fontSize: 14 }}>{c?.reaction?.emoji}</Typography>
                            </div>
                        </div>
                    ))
                ) : props.reactions?.filter((e: any) => e?.reaction?.emoji === currentEmoji)?.length === 0 ? (
                    <div
                        style={{
                            // height: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {mycontext.themeToggle ? (
                            <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                        ) : (
                            <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                        )}
                        <Typography className={classes.notFoundText}>No reactions found!</Typography>
                    </div>
                ) : (
                    props.reactions
                        ?.filter((e: any) => e?.reaction.emoji === currentEmoji)
                        ?.map((c: any, i: number) => (
                            <div key={i} style={{ display: 'flex', height: 50, padding: 5, margin: '5px 0' }}>
                                <Avatar
                                    src={c?.Employee?.profileImage}
                                    alt=""
                                    style={{ width: 38, height: 38, marginRight: 10 }}
                                />
                                <div style={{ flex: 1 }}>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {c?.Employee.firstName}
                                    </Typography>
                                    <Typography style={{ fontSize: 14 }}>{c?.reaction?.emoji}</Typography>
                                </div>
                            </div>
                        ))
                )}
            </div>
        </div>
    );
};

export const CardComponent = (props: {
    data?: any;
    open: boolean;
    handleSidebar?: any;
    setIsMobile?: any;
    setSidebar?: any;
    renderShoutoutConfetti?: any;
    setOption?: any;
    setOpenA?: any;
    setCurrent?: any;
    current?: any;
    employees?: any;
    teams?: any;
    handleEdit?: any;
    playSound?: any;
    mycontext?: any;
}) => {
    const classes = useStyles();
    // const { hasFeature } = useFlagsmith();
    // const classes2 = formStyle();
    const theme = useTheme();
    // const mycontext = useContext(AuthContext);
    const [search, setSearch] = React.useState<string>('');
    const [open, setOpen] = React.useState(false);
    const [currentEmp, setCurrentEmp] = React.useState<string>('');
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const [openSub, setOpenSub] = React.useState(false);
    const [anchorElSub, setAnchorElSub] = React.useState<HTMLElement | null>(null);

    const [active, setActive] = React.useState<string>('employee');

    const handlePicker = (event: any) => {
        setOpen(!open);
        setAnchorEl(event.currentTarget);
        setSearch('');
    };

    const handlePickerSub = (event: any, value: string) => {
        setOpenSub(!openSub);
        setAnchorElSub(event.currentTarget);
        if (value === 'employee') {
            setActive('employee');
        } else if (value === 'team') {
            setActive('team');
        } else {
            setActive('tag');
        }
    };

    const updateIsSeen = async (shoutoutEmployeeTeamId: string, employeeId: string) => {
        try {
            const data = await viewShoutout(shoutoutEmployeeTeamId, employeeId, props?.mycontext.token);
            if (data?.error) {
                throw new Error('Failed to view shoutout!');
            }
            props.playSound();
            props.renderShoutoutConfetti();
        } catch (err) {
            props?.mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    const Personal = (props: { item: any; mycontext: any }) => {
        return props.item?.to?.filter((t: any) => t?.isPersonal && t?.employeeId === props.mycontext.EmployeeId)
            .length > 0 ||
            (props.item?.from?.employeeId === props.mycontext.EmployeeId &&
                props.item?.to.filter((t: any) => t?.isPersonal).length > 0) ? (
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: 70,
                    height: 20,
                    background: theme.palette.primary.main,
                    color: 'white',
                    borderRadius: '0 0 0 10px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 10,
                }}
            >
                <p
                    style={{
                        fontSize: 11,
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: theme.typography.fontWeightBold,
                        fontStyle: 'italic',
                        letterSpacing: 0.2,
                    }}
                >
                    Personal
                </p>
            </div>
        ) : null;
    };

    return (
        <>
            <Popover
                id="picker-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePicker}
                data-testid="filterModal"
            >
                <ul className={classes.filterList}>
                    <li
                        onClick={() => {
                            setOpen(false);
                            setAnchorEl(null);
                            setCurrentEmp('');
                        }}
                        className={classes.filterName}
                    >
                        All
                    </li>
                    <li
                        onClick={(e: any) => handlePickerSub(e, 'employee')}
                        // onMouseLeave={() => {
                        //     setOpenSub(false);
                        //     setAnchorElSub(null);
                        //     setActive(true);
                        // }}
                        className={classes.filterName}
                    >
                        Employee
                    </li>
                    <li
                        onClick={(e: any) => handlePickerSub(e, 'team')}
                        // onMouseLeave={() => {
                        //     setOpenSub(false);
                        //     setAnchorElSub(null);
                        //     setActive(true);
                        // }}
                        className={classes.filterName}
                    >
                        Team
                    </li>
                    <li
                        onClick={(e: any) => handlePickerSub(e, 'tags')}
                        // onMouseLeave={() => {
                        //     setOpenSub(false);
                        //     setAnchorElSub(null);
                        //     setActive(true);
                        // }}
                        className={classes.filterName}
                    >
                        Tags
                    </li>
                </ul>
            </Popover>
            <Popover
                id="picker-popover"
                open={openSub}
                anchorEl={anchorElSub}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePickerSub}
                data-testid="filterSubModal"
            >
                <ul className={classes.filterList}>
                    {active === 'employee'
                        ? props.employees?.map((e: any, i: number) => (
                              <li
                                  key={i}
                                  onClick={() => {
                                      setOpenSub(false);
                                      setAnchorElSub(null);
                                      setCurrentEmp(e?.Employee.employeeId);
                                  }}
                                  className={classes.filterName}
                              >
                                  <Avatar src={e.Employee?.profileImage} alt="" style={{ width: 20, height: 20 }} />{' '}
                                  <span style={{ marginLeft: 10 }}>
                                      {e.Employee?.firstName + ' ' + e.Employee?.lastName}
                                  </span>
                              </li>
                          ))
                        : active === 'team'
                        ? props.teams?.map((e: any, i: number) => (
                              <li
                                  key={i}
                                  onClick={() => {
                                      setOpenSub(false);
                                      setAnchorElSub(null);
                                      setCurrentEmp(e?.teamId);
                                  }}
                                  className={classes.filterName}
                              >
                                  <Avatar alt="" style={{ width: 20, height: 20, fontSize: 12 }}>
                                      {getInitials(e?.teamName + ' T')}
                                  </Avatar>{' '}
                                  <span style={{ marginLeft: 10 }}>{e?.teamName}</span>
                              </li>
                          ))
                        : allTags?.map((e: string, i: number) => (
                              <li
                                  key={i}
                                  onClick={() => {
                                      setOpenSub(false);
                                      setAnchorElSub(null);
                                      setCurrentEmp(e);
                                  }}
                                  className={classes.filterName}
                              >
                                  <span>{e}</span>
                              </li>
                          ))}
                </ul>
            </Popover>
            <div className={classes.filterButtonsDiv}>
                <TextField
                    id="searchname"
                    placeholder="Search by name/team/tags"
                    variant="outlined"
                    size="small"
                    value={search}
                    onChange={(e: any) => setSearch(e.target.value)}
                    onFocus={() => setCurrentEmp('')}
                    className={classes.searchBorder}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" className={classes.searchIcon}>
                                <span
                                    style={{
                                        marginRight: 10,
                                        fontSize: 20,
                                        fill: theme.palette.primary.contrastText,
                                    }}
                                    className="iconify"
                                    data-icon="octicon:search-16"
                                ></span>
                            </InputAdornment>
                        ),
                        classes: {
                            root: classes.searchInput,
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: theme.palette.primary.contrastText,
                        },
                    }}
                />
                <div>
                    <Button
                        variant="outlined"
                        startIcon={
                            <img
                                src={FilterLogo}
                                alt="Filter Logo"
                                style={{ height: 14, width: 14, color: theme.palette.text.primary }}
                            />
                        }
                        onClick={handlePicker}
                        className={classes.filterBtn}
                        data-testid="filter"
                    >
                        Filter by
                    </Button>
                    {/* {hasFeature('give-shoutout') && ( */}
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => props.setOpenA(true)}
                        className={classes.shoutoutBtn}
                        data-testid="giveshoutout"
                    >
                        Give Shoutout
                    </Button>
                    {/* )} */}
                </div>
            </div>
            <div className={classes.filterButtonsDivMobileMain}>
                <div className={classes.searchbarDiv}>
                    <span
                        style={{
                            marginRight: 10,
                            fontSize: 20,
                            fill: theme.palette.text.primary,
                        }}
                        className="iconify"
                        data-icon="octicon:search-16"
                    ></span>
                    <InputBase
                        id="search-input"
                        className={classes.searchInput}
                        defaultValue=""
                        placeholder="Search by name/teams"
                        onChange={(e: any) => setSearch(e.target.value)}
                        inputProps={{ 'aria-label': 'Search by name/team/tags' }}
                    />
                </div>
                <div onClick={handlePicker} className={classes.filterDiv}>
                    <img
                        src={FilterLogo}
                        alt="Filter Logo"
                        style={{ height: 14, width: 14, color: theme.palette.text.primary }}
                    />
                    <ArrowDropDownIcon fontSize="small" style={{ marginLeft: 5 }} />
                </div>
            </div>
            <div className={classes.filterButtonsDivMobile}>
                {/* <Button
                    variant="outlined"
                    startIcon={
                        <img
                            src={FilterLogo}
                            alt="Filter Logo"
                            style={{ height: 14, width: 14, color: theme.palette.text.primary }}
                        />
                    }
                    onClick={handlePicker}
                    className={classes.filterBtnMobile}
                    data-testid="filter"
                >
                    Filter by
                </Button> */}
                {/* {hasFeature('give-shoutout') && ( */}
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => props.setOpenA(true)}
                    className={classes.shoutoutBtnMobile}
                    data-testid="giveshoutout"
                >
                    Give Shoutout
                </Button>
                {/* )} */}
            </div>
            {props.data?.length === 0 ? (
                <div
                    style={{
                        height: 'calc(100vh - 200px)',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    {props?.mycontext.themeToggle ? (
                        <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '60%' }} />
                    ) : (
                        <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '60%' }} />
                    )}
                    <Typography className={classes.notFoundText}>No shoutout found</Typography>
                </div>
            ) : (
                <>
                    <Hidden smDown>
                        <div
                            className={classes.cardsDiv}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: props.open ? '1fr 1fr' : '1fr 1fr 1fr',
                                gap: 10,
                                gridAutoRows: '200px',
                            }}
                            data-testid="cardsDiv"
                        >
                            {currentEmp === '' && search === '' ? (
                                props.data?.map((item: any, index: number) =>
                                    // !item.to?.isSeen && mycontext.EmployeeId === item.to?.employeeId ? (
                                    !item?.to?.filter((e: any) => e?.employeeId === props?.mycontext.EmployeeId)[0]
                                        ?.isSeen &&
                                    props?.mycontext.EmployeeId ===
                                        item?.to?.filter((e: any) => e.employeeId === props?.mycontext.EmployeeId)[0]
                                            ?.employeeId ? (
                                        <div key={index} className={classes.cardHidden}>
                                            {/* <img
                                                src={PartyImg}
                                                alt="party"
                                                style={{ maxWidth: '100%', height: 200, zIndex: 99 }}
                                            /> */}
                                            <Button
                                                onClick={() =>
                                                    updateIsSeen(
                                                        item.to?.filter(
                                                            (e: any) => e.employeeId === props?.mycontext.EmployeeId,
                                                        )[0]?.shoutoutEmployeeTeamId,
                                                        item.to?.filter(
                                                            (e: any) => e.employeeId === props?.mycontext.EmployeeId,
                                                        )[0]?.employeeId,
                                                    )
                                                }
                                                variant="contained"
                                                className={classes.viewbtn}
                                            >
                                                View
                                            </Button>
                                        </div>
                                    ) : (
                                        <div
                                            key={index}
                                            style={{
                                                background: colors[(index + 1) % 4],
                                            }}
                                            className={classes.card}
                                            onClick={() => props.handleSidebar(item)}
                                        >
                                            <Personal item={item} mycontext={props.mycontext} />
                                            <div className={classes.cardHeader}>
                                                <AvatarsComponent to={item?.to} from={item?.from} />
                                                <KudosComponent
                                                    to={item?.to}
                                                    from={item?.from}
                                                    message={item?.message}
                                                    tags={item?.tags}
                                                    emoji={item?.emoji}
                                                    isDrawer={false}
                                                    current={props?.current}
                                                    setCurrent={props.setCurrent}
                                                    setIsMobile={props.setIsMobile}
                                                    setSidebar={props.setSidebar}
                                                    handleEdit={props.handleEdit}
                                                />
                                            </div>
                                            <div className={classes.cardFooter}>
                                                <CardFooterComponent
                                                    item={item}
                                                    setOption={props.setOption}
                                                    reactions={item?.reactions}
                                                    comments={item?.comments}
                                                    setCurrent={props.setCurrent}
                                                />
                                            </div>
                                        </div>
                                    ),
                                )
                            ) : search === '' ? (
                                props.data.filter(
                                    (p: any) =>
                                        p.to?.filter((e: any) => e?.employeeId === currentEmp)[0]?.employeeId ===
                                            currentEmp ||
                                        p.to?.filter((e: any) => e?.teamId === currentEmp)[0]?.teamId === currentEmp ||
                                        p.from?.employeeId === currentEmp ||
                                        currentEmp === p?.tags,
                                )?.length === 0 ? (
                                    <div
                                        style={{
                                            gridColumnStart: 1,
                                            gridColumnEnd: 4,
                                            gridRowStart: 1,
                                            gridRowEnd: 3,
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {props?.mycontext.themeToggle ? (
                                            <img
                                                src={NotFoundDark}
                                                alt=""
                                                style={{ maxWidth: '100%', height: '60%' }}
                                            />
                                        ) : (
                                            <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '60%' }} />
                                        )}
                                        <Typography className={classes.notFoundText}>No shoutout found</Typography>
                                    </div>
                                ) : (
                                    props.data
                                        .filter(
                                            (p: any) =>
                                                p?.to?.filter((e: any) => e.employeeId === currentEmp)[0]
                                                    ?.employeeId === currentEmp ||
                                                p?.to?.filter((e: any) => e?.teamId === currentEmp)[0]?.teamId ===
                                                    currentEmp ||
                                                p?.from?.employeeId === currentEmp ||
                                                currentEmp === p?.tags,
                                        )
                                        .map((item: any, index: number) =>
                                            // !item?.to?.isSeen && mycontext.EmployeeId === item.to?.employeeId ? (
                                            !item?.to?.filter(
                                                (e: any) => e.employeeId === props?.mycontext.EmployeeId,
                                            )[0]?.isSeen &&
                                            props?.mycontext.EmployeeId ===
                                                item?.to?.filter(
                                                    (e: any) => e.employeeId === props?.mycontext.EmployeeId,
                                                )[0]?.employeeId ? (
                                                <div key={index} className={classes.cardHidden}>
                                                    {/* <img
                                                    src={PartyImg}
                                                    alt="party"
                                                    style={{ maxWidth: '100%', height: 200, zIndex: 99 }}
                                                /> */}
                                                    <Button
                                                        onClick={() =>
                                                            updateIsSeen(
                                                                item?.to?.filter(
                                                                    (e: any) =>
                                                                        e.employeeId === props?.mycontext.EmployeeId,
                                                                )[0]?.shoutoutEmployeeTeamId,
                                                                item?.to?.filter(
                                                                    (e: any) =>
                                                                        e.employeeId === props?.mycontext.EmployeeId,
                                                                )[0]?.employeeId,
                                                            )
                                                        }
                                                        variant="contained"
                                                        className={classes.viewbtn}
                                                    >
                                                        View
                                                    </Button>
                                                </div>
                                            ) : (
                                                <div
                                                    key={index}
                                                    style={{
                                                        background: colors[(index + 1) % 4],
                                                    }}
                                                    className={classes.card}
                                                    onClick={() => props.handleSidebar(item)}
                                                >
                                                    <Personal item={item} mycontext={props.mycontext} />
                                                    <div className={classes.cardHeader}>
                                                        <AvatarsComponent to={item?.to} from={item?.from} />
                                                        <KudosComponent
                                                            to={item?.to}
                                                            from={item?.from}
                                                            message={item?.message}
                                                            tags={item?.tags}
                                                            emoji={item?.emoji}
                                                            isDrawer={false}
                                                            current={props?.current}
                                                            setCurrent={props.setCurrent}
                                                            setIsMobile={props.setIsMobile}
                                                            setSidebar={props.setSidebar}
                                                            handleEdit={props.handleEdit}
                                                        />
                                                    </div>
                                                    <div className={classes.cardFooter}>
                                                        <CardFooterComponent
                                                            item={item}
                                                            setOption={props.setOption}
                                                            reactions={item?.reactions}
                                                            comments={item?.comments}
                                                            setCurrent={props.setCurrent}
                                                        />
                                                    </div>
                                                </div>
                                            ),
                                        )
                                )
                            ) : props.data.filter(
                                  (p: any) =>
                                      p?.from?.name?.toLowerCase().includes(search.toLowerCase()) ||
                                      p?.from?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
                                      p?.to
                                          ?.find((e: any) =>
                                              e?.Employee?.firstName.toLowerCase().includes(search.toLowerCase()),
                                          )
                                          ?.Employee?.firstName.toLowerCase()
                                          .includes(search.toLowerCase()) ||
                                      p?.to
                                          ?.find((e: any) =>
                                              e?.Team?.teamName.toLowerCase().includes(search.toLowerCase()),
                                          )
                                          ?.Team?.teamName.toLowerCase()
                                          .includes(search.toLowerCase()) ||
                                      p?.tags.toLowerCase().includes(search.toLowerCase()),
                              )?.length === 0 ? (
                                <div
                                    style={{
                                        gridColumnStart: 1,
                                        gridColumnEnd: 4,
                                        gridRowStart: 1,
                                        gridRowEnd: 3,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {props?.mycontext.themeToggle ? (
                                        <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '60%' }} />
                                    ) : (
                                        <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '60%' }} />
                                    )}
                                    <Typography className={classes.notFoundText}>No shoutout found</Typography>
                                </div>
                            ) : (
                                props.data
                                    .filter(
                                        (p: any) =>
                                            p?.from?.name?.toLowerCase().includes(search.toLowerCase()) ||
                                            p?.from?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
                                            p?.to
                                                ?.find((e: any) =>
                                                    e?.Employee?.firstName.toLowerCase().includes(search.toLowerCase()),
                                                )
                                                ?.Employee?.firstName.toLowerCase()
                                                .includes(search.toLowerCase()) ||
                                            p?.to
                                                ?.find((e: any) =>
                                                    e?.Team?.teamName.toLowerCase().includes(search.toLowerCase()),
                                                )
                                                ?.Team?.teamName.toLowerCase()
                                                .includes(search.toLowerCase()) ||
                                            p?.tags.toLowerCase().includes(search.toLowerCase()),
                                    )
                                    .map((item: any, index: number) =>
                                        // !item.to?.isSeen && mycontext.EmployeeId === item.to?.employeeId ? (
                                        !item?.to?.filter((e: any) => e.employeeId === props?.mycontext.EmployeeId)[0]
                                            ?.isSeen &&
                                        props?.mycontext.EmployeeId ===
                                            item?.to?.filter(
                                                (e: any) => e.employeeId === props?.mycontext.EmployeeId,
                                            )[0]?.employeeId ? (
                                            <div key={index} className={classes.cardHidden}>
                                                {/* <img
                                                    src={PartyImg}
                                                    alt="party"
                                                    style={{ maxWidth: '100%', height: 200, zIndex: 99 }}
                                                /> */}
                                                <Button
                                                    onClick={() =>
                                                        updateIsSeen(
                                                            item.to?.filter(
                                                                (e: any) =>
                                                                    e.employeeId === props?.mycontext.EmployeeId,
                                                            )[0]?.shoutoutEmployeeTeamId,
                                                            item.to?.filter(
                                                                (e: any) =>
                                                                    e.employeeId === props?.mycontext.EmployeeId,
                                                            )[0]?.employeeId,
                                                        )
                                                    }
                                                    variant="contained"
                                                    className={classes.viewbtn}
                                                >
                                                    View
                                                </Button>
                                            </div>
                                        ) : (
                                            <div
                                                key={index}
                                                style={{
                                                    background: colors[(index + 1) % 4],
                                                }}
                                                className={classes.card}
                                                onClick={() => props.handleSidebar(item)}
                                            >
                                                <Personal item={item} mycontext={props.mycontext} />
                                                <div className={classes.cardHeader}>
                                                    <AvatarsComponent to={item?.to} from={item?.from} />
                                                    <KudosComponent
                                                        to={item?.to}
                                                        from={item?.from}
                                                        message={item?.message}
                                                        tags={item?.tags}
                                                        emoji={item?.emoji}
                                                        isDrawer={false}
                                                        current={props?.current}
                                                        setCurrent={props.setCurrent}
                                                        setIsMobile={props.setIsMobile}
                                                        setSidebar={props.setSidebar}
                                                        handleEdit={props.handleEdit}
                                                    />
                                                </div>
                                                <div className={classes.cardFooter}>
                                                    <CardFooterComponent
                                                        item={item}
                                                        setOption={props.setOption}
                                                        reactions={item?.reactions}
                                                        comments={item?.comments}
                                                        setCurrent={props.setCurrent}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    )
                            )}
                        </div>
                    </Hidden>
                    <Hidden smUp>
                        {/* <input
                            type="text"
                            placeholder="Search"
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            className={classes2.searchBar}
                        /> */}
                        <div
                            className={classes.cardsDiv}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: 10,
                                gridAutoRows: '200px',
                            }}
                            data-testid="cardsDiv"
                        >
                            {currentEmp === '' && search === '' ? (
                                props.data.map((item: any, index: number) =>
                                    !item?.to?.filter((e: any) => e.employeeId === props?.mycontext.EmployeeId)[0]
                                        ?.isSeen &&
                                    props?.mycontext.EmployeeId ===
                                        item?.to?.filter((e: any) => e.employeeId === props?.mycontext.EmployeeId)[0]
                                            ?.employeeId ? (
                                        <div key={index} className={classes.cardHidden2}>
                                            <img
                                                src={StarMobile}
                                                alt="party"
                                                style={{ maxWidth: '100%', height: 50 }}
                                            />
                                            <Button
                                                onClick={() =>
                                                    updateIsSeen(
                                                        item.to?.filter(
                                                            (e: any) => e.employeeId === props?.mycontext.EmployeeId,
                                                        )[0]?.shoutoutEmployeeTeamId,
                                                        item.to?.filter(
                                                            (e: any) => e.employeeId === props?.mycontext.EmployeeId,
                                                        )[0]?.employeeId,
                                                    )
                                                }
                                                variant="contained"
                                                className={classes.viewbtn}
                                            >
                                                View
                                            </Button>
                                        </div>
                                    ) : (
                                        <div
                                            key={index}
                                            style={{
                                                background: colors[(index + 1) % 4],
                                            }}
                                            className={classes.card}
                                            onClick={() => {
                                                props.handleSidebar(item);
                                                props.setIsMobile(true);
                                            }}
                                        >
                                            <Personal item={item} mycontext={props.mycontext} />
                                            <div className={classes.cardHeader}>
                                                {/* <KudosComponent
                                                    to={item?.to}
                                                    from={item?.from}
                                                    message={item?.message}
                                                    tags={item?.tags}
                                                    emoji={item?.emoji}
                                                    isDrawer={false}
                                                    current={props?.current}
                                                    setCurrent={props.setCurrent}
                                                    setIsMobile={props.setIsMobile}
                                                    setSidebar={props.setSidebar}
                                                    handleEdit={props.handleEdit}
                                                /> */}
                                                <Typography className={classes.textMobile}>
                                                    {item?.message}&nbsp;
                                                    {item?.emoji?.emoji}
                                                </Typography>
                                            </div>
                                            <div className={classes.cardFooter}>
                                                <AvatarsComponent to={item?.to} from={item?.from} />
                                                <Typography className={classes.tag}>{item?.tags}</Typography>
                                            </div>
                                        </div>
                                    ),
                                )
                            ) : search === '' ? (
                                props.data.filter(
                                    (p: any) =>
                                        p?.to?.filter((e: any) => e.employeeId === currentEmp)[0]?.employeeId ===
                                            currentEmp ||
                                        p?.to?.filter((e: any) => e?.teamId === currentEmp)[0]?.teamId === currentEmp ||
                                        p?.from?.employeeId === currentEmp ||
                                        currentEmp === p?.tags,
                                )?.length === 0 ? (
                                    <div
                                        style={{
                                            gridColumnStart: 1,
                                            gridColumnEnd: 3,
                                            gridRowStart: 1,
                                            gridRowEnd: 3,
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {props?.mycontext.themeToggle ? (
                                            <img
                                                src={NotFoundDark}
                                                alt=""
                                                style={{ maxWidth: '100%', height: '60%' }}
                                            />
                                        ) : (
                                            <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '60%' }} />
                                        )}
                                        <Typography className={classes.notFoundText}>No shoutout found</Typography>
                                    </div>
                                ) : (
                                    props.data
                                        .filter(
                                            (p: any) =>
                                                p.to?.filter((e: any) => e.employeeId === currentEmp)[0]?.employeeId ===
                                                    currentEmp ||
                                                p.to?.filter((e: any) => e?.teamId === currentEmp)[0]?.teamId ===
                                                    currentEmp ||
                                                p.from?.employeeId === currentEmp ||
                                                currentEmp === p?.tags,
                                        )
                                        .map((item: any, index: number) =>
                                            !item.to?.filter(
                                                (e: any) => e.employeeId === props?.mycontext.EmployeeId,
                                            )[0]?.isSeen &&
                                            props?.mycontext.EmployeeId ===
                                                item.to?.filter(
                                                    (e: any) => e.employeeId === props?.mycontext.EmployeeId,
                                                )[0]?.employeeId ? (
                                                <div key={index} className={classes.cardHidden2}>
                                                    {/* <img
                                                    src={PartyImg}
                                                    alt="party"
                                                    style={{ maxWidth: '100%', height: 250, zIndex: 99 }}
                                                /> */}
                                                    <Button
                                                        onClick={() =>
                                                            updateIsSeen(
                                                                item.to?.filter(
                                                                    (e: any) =>
                                                                        e.employeeId === props?.mycontext.EmployeeId,
                                                                )[0]?.shoutoutEmployeeTeamId,
                                                                item.to?.filter(
                                                                    (e: any) =>
                                                                        e.employeeId === props?.mycontext.EmployeeId,
                                                                )[0]?.employeeId,
                                                            )
                                                        }
                                                        variant="contained"
                                                        className={classes.viewbtn}
                                                    >
                                                        View
                                                    </Button>
                                                </div>
                                            ) : (
                                                <div
                                                    key={index}
                                                    style={{
                                                        background: colors[(index + 1) % 4],
                                                    }}
                                                    className={classes.card}
                                                    onClick={() => {
                                                        props.handleSidebar(item);
                                                        props.setIsMobile(true);
                                                    }}
                                                >
                                                    <Personal item={item} mycontext={props.mycontext} />
                                                    <div className={classes.cardHeader}>
                                                        {/* <KudosComponent
                                                        to={item?.to}
                                                        from={item?.from}
                                                        message={item?.message}
                                                        tags={item?.tags}
                                                        emoji={item?.emoji}
                                                        isDrawer={false}
                                                        current={props?.current}
                                                        setCurrent={props.setCurrent}
                                                        setIsMobile={props.setIsMobile}
                                                        setSidebar={props.setSidebar}
                                                        handleEdit={props.handleEdit}
                                                    /> */}
                                                        <Typography className={classes.textMobile}>
                                                            {item?.message}&nbsp;{item?.emoji?.emoji}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.cardFooter}>
                                                        <AvatarsComponent to={item?.to} from={item?.from} />
                                                        <Typography className={classes.tag}>{item?.tags}</Typography>
                                                    </div>
                                                </div>
                                            ),
                                        )
                                )
                            ) : props.data.filter(
                                  (p: any) =>
                                      p?.from?.name?.toLowerCase().includes(search.toLowerCase()) ||
                                      p?.from?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
                                      p.to
                                          ?.find((e: any) =>
                                              e?.Employee?.firstName.toLowerCase().includes(search.toLowerCase()),
                                          )
                                          ?.Employee?.firstName.toLowerCase()
                                          .includes(search.toLowerCase()) ||
                                      p.to
                                          ?.find((e: any) =>
                                              e?.Team?.teamName.toLowerCase().includes(search.toLowerCase()),
                                          )
                                          ?.Team?.teamName.toLowerCase()
                                          .includes(search.toLowerCase()) ||
                                      p?.tags.toLowerCase().includes(search.toLowerCase()),
                              )?.length === 0 ? (
                                <div
                                    style={{
                                        gridColumnStart: 1,
                                        gridColumnEnd: 3,
                                        gridRowStart: 1,
                                        gridRowEnd: 3,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {props?.mycontext.themeToggle ? (
                                        <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '60%' }} />
                                    ) : (
                                        <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '60%' }} />
                                    )}
                                    <Typography className={classes.notFoundText}>No shoutout found</Typography>
                                </div>
                            ) : (
                                props.data
                                    .filter(
                                        (p: any) =>
                                            p?.from?.name?.toLowerCase().includes(search.toLowerCase()) ||
                                            p?.from?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
                                            p.to
                                                ?.find((e: any) =>
                                                    e?.Employee?.firstName.toLowerCase().includes(search.toLowerCase()),
                                                )
                                                ?.Employee?.firstName.toLowerCase()
                                                .includes(search.toLowerCase()) ||
                                            p.to
                                                ?.find((e: any) =>
                                                    e?.Team?.teamName.toLowerCase().includes(search.toLowerCase()),
                                                )
                                                ?.Team?.teamName.toLowerCase()
                                                .includes(search.toLowerCase()) ||
                                            p?.tags.toLowerCase().includes(search.toLowerCase()),
                                    )
                                    .map((item: any, index: number) =>
                                        !item.to?.filter((e: any) => e.employeeId === props?.mycontext.EmployeeId)[0]
                                            ?.isSeen &&
                                        props?.mycontext.EmployeeId ===
                                            item.to?.filter((e: any) => e.employeeId === props?.mycontext.EmployeeId)[0]
                                                ?.employeeId ? (
                                            <div key={index} className={classes.cardHidden2}>
                                                {/* <img
                                                    src={PartyImg}
                                                    alt="party"
                                                    style={{ maxWidth: '100%', height: 250, zIndex: 99 }}
                                                /> */}
                                                <Button
                                                    onClick={() =>
                                                        updateIsSeen(
                                                            item.to?.filter(
                                                                (e: any) =>
                                                                    e.employeeId === props?.mycontext.EmployeeId,
                                                            )[0]?.shoutoutEmployeeTeamId,
                                                            item.to?.filter(
                                                                (e: any) =>
                                                                    e.employeeId === props?.mycontext.EmployeeId,
                                                            )[0]?.employeeId,
                                                        )
                                                    }
                                                    variant="contained"
                                                    className={classes.viewbtn}
                                                >
                                                    View
                                                </Button>
                                            </div>
                                        ) : (
                                            <div
                                                key={index}
                                                style={{
                                                    background: colors[(index + 1) % 4],
                                                }}
                                                className={classes.card}
                                                onClick={() => {
                                                    props.handleSidebar(item);
                                                    props.setIsMobile(true);
                                                }}
                                            >
                                                <Personal item={item} mycontext={props.mycontext} />
                                                <div className={classes.cardHeader}>
                                                    {/* <KudosComponent
                                                        to={item?.to}
                                                        from={item?.from}
                                                        message={item?.message}
                                                        tags={item?.tags}
                                                        emoji={item?.emoji}
                                                        isDrawer={false}
                                                        current={props?.current}
                                                        setCurrent={props.setCurrent}
                                                        setIsMobile={props.setIsMobile}
                                                        setSidebar={props.setSidebar}
                                                        handleEdit={props.handleEdit}
                                                    /> */}
                                                    <Typography className={classes.textMobile}>
                                                        {item?.message}&nbsp;{item?.emoji?.emoji}
                                                    </Typography>
                                                </div>
                                                <div className={classes.cardFooter}>
                                                    <AvatarsComponent to={item?.to} from={item?.from} />
                                                    <Typography className={classes.tag}>{item?.tags}</Typography>
                                                </div>
                                            </div>
                                        ),
                                    )
                            )}
                        </div>
                    </Hidden>
                </>
            )}
        </>
    );
};

export const DrawerContent = (props: {
    setSidebar?: any;
    setIsMobile?: any;
    isMobile?: boolean;
    current: any;
    option?: string;
    setOption?: any;
    setCurrent?: any;
    handleEdit?: any;
}) => {
    const classes = useStyles();
    return (
        <>
            {props.isMobile && (
                <div style={{ height: 40, padding: '10px 0px', display: 'flex' }}>
                    <ArrowBackIcon onClick={() => props.setIsMobile(false)} className={classes.arrowBackStyle} />
                    <Typography className={classes.arrowBackText}>Shoutouts</Typography>
                </div>
            )}
            <div className={props.option === 'comment' ? classes.drawerContent2 : classes.drawerContent} id="scrolldiv">
                <div className={classes.cardHeader2}>
                    <AvatarsComponent to={props.current?.to} from={props.current?.from} />
                    <KudosComponent
                        to={props.current?.to}
                        from={props.current?.from}
                        message={props.current?.message}
                        tags={props.current?.tags}
                        emoji={props.current?.emoji}
                        isDrawer={true}
                        current={props?.current}
                        setCurrent={props?.setCurrent}
                        setIsMobile={props.setIsMobile}
                        setSidebar={props.setSidebar}
                        handleEdit={props.handleEdit}
                    />
                </div>
                <div className={classes.cardFooterComment}>
                    <CardFooterComponent
                        item={props.current}
                        setOption={props.setOption}
                        reactions={props.current?.reactions}
                        comments={props.current?.comments}
                        setCurrent={props.setCurrent}
                    />
                </div>
                {props.option === 'comment' ? (
                    <Comments
                        comments={props.current?.comments}
                        reactions={props.current?.reactions}
                        current={props.current}
                        setCurrent={props.setCurrent}
                    />
                ) : (
                    <Reactions reactions={props.current?.reactions} />
                )}
            </div>
        </>
    );
};

export const Drawer = (props: {
    sidebar: Sidebar;
    setSidebar: any;
    children: React.ReactChild;
    comment?: string;
    setComment?: any;
    action?: string;
    addComment?: any;
}) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <div className={props.sidebar.open ? classes.drawerOpen : classes.drawerClose}>
            <div
                style={{
                    padding: '5px 10px',
                    width: '100%',
                    height: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <CloseIcon className={classes.closeIcon} onClick={() => props.setSidebar({ open: false })} />
            </div>
            <div className={classes.drawerContentScroll}>
                {props.children}
                {props.action === 'comment' ? (
                    <form onSubmit={props.addComment} className={classes.commentForm} data-testid="form">
                        <input
                            type="text"
                            placeholder="Type your message..."
                            value={props.comment}
                            onChange={(e: any) => props.setComment(e.target.value)}
                            className={classes.commentBox}
                            maxLength={50}
                        />
                        <button type="submit" className={classes.commentBtn} data-testid="submitBtn">
                            <Icon icon="carbon:send-filled" color={theme.palette.text.secondary} width="24" />
                        </button>
                    </form>
                ) : null}
            </div>
        </div>
    );
};

export const AddShoutout = (props: {
    current?: any;
    form: any;
    setForm: any;
    setOpen: any;
    allEmployees: any;
    allTeams?: any;
    handleSubmit: any;
    setEmployeeId: any;
    setTeamId?: any;
    handleEdit?: any;
    isEdit?: boolean;
    handleUpdateShoutout?: any;
    handleClose?: any;
    // ref?: any;
}) => {
    const classes2 = formStyle();
    const classes = useStyles();
    const theme = useTheme();
    const [empListModal, setEmpListModal] = React.useState<boolean>(false);
    const [picker, setPicker] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const [employees, setEmployees] = React.useState<any>([]);
    const [employeeId, setEmployeeId] = React.useState<any>([]);
    const [teams, setTeams] = React.useState<any>([]);
    const [teamId, setTeamId] = React.useState<any>([]);
    const [empOrTeam, setEmpOrTeam] = React.useState('employee');
    const [orgOrEmp, setOrgOrEmp] = React.useState('org');

    const handleEmojiClick = (e: any, obj: any) => {
        props.setForm({ ...props.form, emoji: obj });
        setPicker(false);
        setAnchorEl(null);
    };

    const handleEmpOrTeam = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEmpOrTeam(event.target.value as string);
    };

    const handleOrgOrEmp = (event: React.ChangeEvent<{ value: unknown }>) => {
        setOrgOrEmp(event.target.value as string);
    };

    const handlePicker = (event: any) => {
        setPicker(!picker);
        setAnchorEl(event?.currentTarget);
    };

    const handlePicker2 = (event: any) => {
        setEmpListModal(!empListModal);
        setAnchorEl(event?.currentTarget);
    };

    const handleEmployees = (emp: any) => {
        if (employees.includes(emp.firstName) && employeeId.includes(emp.employeeId)) {
            setEmployees((prev: any) => [...prev.filter((e: string) => e !== emp.firstName)]);
            setEmployeeId((prev: any) => [...prev.filter((e: string) => e !== emp.employeeId)]);
            props.setEmployeeId((prev: any) => [...prev.filter((e: string) => e !== emp.employeeId)]);
        } else {
            setEmployees((prev: any) => [...prev, emp.firstName]);
            setEmployeeId((prev: any) => [...prev, emp.employeeId]);
            props.setEmployeeId((prev: any) => [...prev, emp.employeeId]);
        }
    };

    const handleTeams = (t: any) => {
        if (teams.includes(t.teamName) && teamId.includes(t.teamId)) {
            setTeams((prev: any) => [...prev.filter((e: string) => e !== t.teamName)]);
            setTeamId((prev: any) => [...prev.filter((e: string) => e !== t.teamId)]);
            props.setTeamId((prev: any) => [...prev.filter((e: string) => e !== t.teamId)]);
        } else {
            setTeams((prev: any) => [...prev, t.teamName]);
            setTeamId((prev: any) => [...prev, t.teamId]);
            props.setTeamId((prev: any) => [...prev, t.teamId]);
        }
    };

    React.useEffect(() => {
        props.setForm({ ...props.form, employee: props.allEmployees[0]?.Employee?.firstName });
        if (props.isEdit) {
            if (props.current.to?.[0].employeeId === null) {
                setEmpOrTeam('team');
                props.current?.to?.map((e: any) => {
                    setTeamId((prev: any) => [...prev, e?.teamId]);
                    setTeams((prev: any) => [...prev, e?.Team.teamName]);
                    props.setTeamId((prev: any) => [...prev, e?.teamId]);
                });
            } else {
                setEmpOrTeam('employee');
                props.current?.to?.map((e: any) => {
                    setEmployeeId((prev: any) => [...prev, e?.employeeId]);
                    setEmployees((prev: any) => [...prev, e?.Employee?.firstName]);
                    props.setEmployeeId((prev: any) => [...prev, e?.employeeId]);
                });
            }
        }
    }, [props.isEdit]);
    return (
        <>
            <Popover
                id="picker-popover"
                open={picker}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePicker}
            >
                <Picker
                    groupVisibility={{ flags: false, symbols: false, recently_used: false }}
                    onEmojiClick={handleEmojiClick}
                    disableSearchBar={true}
                    preload={false}
                    disableSkinTonePicker={true}
                    pickerStyle={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
                />
            </Popover>
            <Popover
                id="picker-popover"
                open={empListModal}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setAnchorEl(null);
                    setEmpListModal(false);
                }}
            >
                <ul className={classes.filterList}>
                    {empOrTeam === 'employee'
                        ? props.allEmployees?.map((e: any, i: number) => (
                              <li
                                  key={i}
                                  className={classes.filterName2}
                                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                  onClick={() => handleEmployees(e.Employee)}
                              >
                                  <Avatar src={e?.Employee.profileImage} alt="" style={{ width: 26, height: 26 }} />{' '}
                                  <span style={{ flex: 1, marginLeft: 5 }}>
                                      {e.Employee?.firstName + ' ' + e.Employee?.lastName}{' '}
                                  </span>
                                  <Checkbox color="primary" checked={employees.includes(e.Employee.firstName)} />
                              </li>
                          ))
                        : props.allTeams?.map((e: any, i: number) => (
                              <li
                                  key={i}
                                  className={classes.filterName2}
                                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                  onClick={() => handleTeams(e)}
                              >
                                  <Avatar alt="" style={{ width: 26, height: 26 }}>
                                      {getInitials(e?.teamName + 'T')}
                                  </Avatar>{' '}
                                  <span style={{ flex: 1, marginLeft: 5 }}>{e?.teamName} </span>
                                  <Checkbox color="primary" checked={teams.includes(e?.teamName)} />
                              </li>
                          ))}
                </ul>
            </Popover>
            <Grid container className={classes2.formMain}>
                {/* <Grid item xs={12} sm={12} className={classes2.fieldDiv}>
                    <FormLabel className={classes2.formLabelStyle}>Choose someone</FormLabel>
                    <FormControl variant="outlined" className={classes2.formControl}>
                        <Select
                            id="fullCustomSelect"
                            value={props.form.employee || 'Select an employee'}
                            className={classes2.inputSelectStyleOther}
                            onChange={(e: any) => props.setForm({ ...props.form, employee: e.target.value })}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: { marginTop: -10 },
                                },
                            }}
                        >
                            {props.allEmployees.map((e: any, i: number) => (
                                <MenuItem
                                    value={e?.Employee.firstName}
                                    id="fullDay"
                                    key={i}
                                    onClick={() => props.setEmployeeId(e?.Employee.employeeId)}
                                >
                                    {e?.Employee.firstName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={12} className={classes2.fieldDiv}>
                    <FormLabel className={classes2.formLabelStyle}>
                        Choose someone{' '}
                        <RadioGroup row value={empOrTeam} onChange={handleEmpOrTeam}>
                            <FormControlLabel
                                value="employee"
                                control={<Radio color="primary" id="employee" />}
                                label={<FormLabel className={classes.radioNameStyle}>Employee</FormLabel>}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="team"
                                control={<Radio color="primary" id="team" />}
                                label={<FormLabel className={classes.radioNameStyle}>Team</FormLabel>}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormLabel>
                    <FormControl variant="outlined" className={classes2.formControl}>
                        {/* <TextField
                            placeholder="Select employees"
                            id="leave-emoji"
                            variant="outlined"
                            margin="dense"
                            className={classes2.inputSelectStyle2}
                            value={employees.map((e: string, i: number) =>
                                i === employees?.length - 1 ? e : e + ', ',
                            )}
                            onClick={handlePicker2}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ArrowDropDownIcon />
                                    </InputAdornment>
                                ),
                            }}
                        /> */}
                        <div className={classes.textfieldDiv} onClick={handlePicker2}>
                            <div className={classes.inputDiv}>
                                {empOrTeam === 'employee'
                                    ? employees.length === 0
                                        ? 'Select an employee'
                                        : employees.map((e: string, i: number) =>
                                              i === employees?.length - 1 ? e : e + ', ',
                                          )
                                    : teams.length === 0
                                    ? 'Select an team'
                                    : teams.map((e: string, i: number) => (i === teams?.length - 1 ? e : e + ', '))}
                            </div>
                            <ArrowDropDownIcon style={{ marginRight: 5 }} />
                        </div>
                    </FormControl>
                </Grid>
                {empOrTeam === 'employee' && (
                    <Grid item xs={12} sm={12}>
                        <FormLabel className={classes2.formLabelStyle2}>
                            <RadioGroup row value={orgOrEmp} onChange={handleOrgOrEmp}>
                                <FormControlLabel
                                    value="org"
                                    control={<Radio color="primary" id="org" />}
                                    label={<FormLabel className={classes.radioNameStyle}>Show to all</FormLabel>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="emp"
                                    control={<Radio color="primary" id="emp" />}
                                    label={<FormLabel className={classes.radioNameStyle}>Send Personally</FormLabel>}
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormLabel>
                    </Grid>
                )}
                <Grid item xs={8} sm={8} className={classes2.fieldDiv}>
                    <FormLabel className={classes2.formLabelStyle}>Choose value</FormLabel>
                    <FormControl variant="outlined" className={classes2.formControl}>
                        <Select
                            id="fullCustomSelect"
                            value={props.form.tags}
                            className={classes2.inputSelectStyle1}
                            onChange={(e: any) => props.setForm({ ...props.form, tags: e.target.value })}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: { marginTop: -10 },
                                },
                            }}
                        >
                            {allTags.map((e: any, i: number) => (
                                <MenuItem value={e} id="fullDay" key={i}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} sm={4} className={classes2.fieldDiv}>
                    <FormLabel className={classes2.formLabelStyle}>Emoji</FormLabel>
                    <FormControl variant="outlined" className={classes2.formControl}>
                        <TextField
                            placeholder="Select Emoji"
                            id="leave-emoji"
                            variant="outlined"
                            margin="dense"
                            className={classes2.inputSelectStyle2}
                            value={props.form.emoji?.emoji}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple={true}
                                            onClick={handlePicker}
                                            aria-label="emoji"
                                            style={{ outline: 'none' }}
                                        >
                                            <EmojiEmotionsIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} className={classes2.fieldDiv}>
                    <FormLabel className={classes2.formLabelStyle}>
                        {orgOrEmp === 'emp'
                            ? 'Let them know what they did that was awesome!'
                            : 'Let everyone know what they did that was awesome!'}
                    </FormLabel>
                    <FormControl variant="outlined" className={classes2.formControl}>
                        <Select
                            id="fullCustomSelect"
                            value={props.form.message}
                            className={classes2.inputSelectStyleOther}
                            onChange={(e: any) => props.setForm({ ...props.form, message: e.target.value })}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: { marginTop: -10 },
                                },
                            }}
                        >
                            {allmessages.map((e: string, i: number) => (
                                <MenuItem value={e} id="fullDay" key={i}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {props.form.message === allmessages[allmessages.length - 1] ? (
                        <FormControl variant="outlined" className={classes2.formControl}>
                            <TextField
                                id="customReason"
                                type="text"
                                value={props.form.customMessage}
                                variant="outlined"
                                placeholder="Type your message here"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes2.inputReason}
                                onChange={(e: any) => props.setForm({ ...props.form, customMessage: e.target.value })}
                                size="small"
                                autoFocus
                                inputProps={{
                                    maxLength: 90,
                                }}
                                multiline
                                rows={2}
                                style={{ margin: '10px 0' }}
                            />
                        </FormControl>
                    ) : null}
                </Grid>
                <Grid item xs={12} sm={12} className={classes2.btnDiv}>
                    <Button variant="outlined" onClick={() => props.handleClose()} className={classes2.btnOutlined}>
                        Cancel
                    </Button>
                    {props.isEdit ? (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                await props.handleUpdateShoutout(empOrTeam, orgOrEmp);
                                setEmployeeId([]);
                                setEmployees([]);
                            }}
                            className={classes2.btn}
                        >
                            Update
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                await props.handleSubmit(empOrTeam, orgOrEmp);
                                setEmployeeId([]);
                                setEmployees([]);
                            }}
                            className={classes2.btn}
                        >
                            Post
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

interface Shoutout {
    id?: string;
}
const ShoutoutMain = (props: Shoutout) => {
    const classes = useStyles();
    const [sidebar, setSidebar] = React.useState<Sidebar>({
        open: false,
    });
    // Howler.volume(0.7);
    // const refElement = React.useRef(null);
    const theme = useTheme();
    const dispatch = useDispatch();
    const mycontext = useContext(AuthContext);
    const [openA, setOpenA] = React.useState<boolean>(false);
    const state = useSelector((state: any) => state);
    const userSubscriptionDetails = state.subscription.userSubscriptions;
    const data = state.shoutouts;
    const [isMobile, setIsMobile] = React.useState<boolean>(false);
    const [current, setCurrent] = React.useState<any>({});
    const [option, setOption] = React.useState<string>('comment');
    const [comment, setComment] = React.useState<string>('');

    // const [loading, setLoading] = React.useState<boolean>(false);

    const [isEdit, setIsEdit] = React.useState<boolean>(false);

    const [employeeId, setEmployeeId] = React.useState<any>([]);
    const [teamId, setTeamId] = React.useState<any>([]);
    const [form, setForm] = React.useState<any>({
        employee: state.orgEmployees?.employees[0]?.Employee.firstName,
        createdOn: localToISO(
            getCurrentCountryDateTime(mycontext.country?.zoneName).date,
            getCurrentCountryDateTime(mycontext.country?.zoneName).time,
            mycontext.country,
        ),
        createdBy: mycontext.EmployeeId,
        emoji: emojis[0],
        tags: allTags[0],
        organizationId: mycontext.organizationId,
        message: allmessages[0],
        customMessage: '',
    });

    //flagsmith
    const { identify, isIdentified, getTrait, setTrait } = useFlagsmith();
    const identifyUser = async (id: string, email: string) => {
        await identify(id);
        const hasEmail = !!getTrait('email');
        if (!hasEmail) {
            setTrait('email', email);
        }
    };
    if (!isIdentified) {
        identifyUser(mycontext.email, mycontext.email);
    }

    React.useEffect(() => {
        if (props?.id) {
            setSidebar({ open: true });
            setCurrent(data.shoutouts.filter((s: any) => s.shoutoutId === props?.id)[0]);
        }
    }, []);

    // const playSound = () => {
    //     const sound = new Howl({
    //         src: [Applause],
    //     });
    //     sound.play();
    // };

    const giveApplause = () => {
        const audio = new Audio(Applause);
        audio.play();
    };

    const handleSubmit = async (empOrTeam: string, orgOrEmp: string) => {
        setOpenA(false);
        try {
            checkSubscription(userSubscriptionDetails);
            let shoutoutEmpTeam = null;
            const objShoutout = {
                ...form,
                message: form.message === allmessages[4] ? form.customMessage.split('\n').join(' ') : form.message,
                token: mycontext.token,
                createdOn: localToISO(
                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                    mycontext.country,
                ),
            };
            if (empOrTeam === 'employee') {
                if (employeeId.length === 0) {
                    throw new Error('Please select an employee');
                }
            } else {
                if (teamId.length === 0) {
                    throw new Error('Please select an team');
                }
            }
            const sData = await createShoutout(objShoutout);
            if (sData.error) {
                throw new Error('Failed to create shoutout!');
            }

            mycontext.togglePopup({
                alertMessage: 'Shoutout added successfully!',
                severity: 'success',
            });
            let createdFor = [];
            if (empOrTeam === 'employee') {
                let objShoutoutEmployee = null;
                let shoutoutEmployee = [];
                for (let i = 0; i < employeeId.length; i++) {
                    objShoutoutEmployee = {
                        shoutoutId: sData?.shoutoutId,
                        employeeId: employeeId[i],
                        token: mycontext.token,
                        isPersonal: orgOrEmp === 'emp',
                        orgId: mycontext.organizationId,
                        createdBy: mycontext.EmployeeId,
                    };
                    shoutoutEmpTeam = await addShoutoutEmployee(objShoutoutEmployee);
                    shoutoutEmployee.push(shoutoutEmpTeam);
                    createdFor.push({
                        id: employeeId[i],
                        // name: shoutoutEmpTeam.Employee.firstName + ' ' + shoutoutEmpTeam.Employee.lastName,
                    });
                    //old code
                    // handleShoutout(sData, shoutoutEmpTeam, objShoutoutEmployee, empOrTeam, orgOrEmp);
                }
                if (orgOrEmp === 'emp') {
                    handleShoutout(sData, shoutoutEmployee, null, null, empOrTeam, orgOrEmp);
                } else {
                    handleShoutout(sData, shoutoutEmployee, objShoutoutEmployee, employeeId, empOrTeam, orgOrEmp);
                }
            } else {
                let objShoutoutTeam = null;
                let shoutoutEmployee = [];
                for (let i = 0; i < teamId.length; i++) {
                    objShoutoutTeam = {
                        shoutoutId: sData?.shoutoutId,
                        employeeId: teamId[i],
                        token: mycontext.token,
                        orgId: mycontext.organizationId,
                        createdBy: mycontext.EmployeeId,
                    };
                    shoutoutEmpTeam = await addShoutoutTeam(objShoutoutTeam);
                    shoutoutEmployee.push(shoutoutEmpTeam);
                    createdFor.push({
                        name: shoutoutEmpTeam?.Team.teamName,
                        id: teamId[i],
                    });
                }
                handleShoutout(sData, shoutoutEmployee, objShoutoutTeam, teamId, empOrTeam, orgOrEmp);
            }

            // const data = {
            //     recipientType: empOrTeam === 'employee' ? 'Employee' : 'Teams',
            //     shoutoutOperation: 'add_shoutout',
            //     shoutoutId: sData?.shoutoutId,
            //     isPrivate: shoutoutEmpTeam?.isPersonal,
            //     createdBy: {
            //         employeeId: sData?.createdBy,
            //         slackId: sData?.Employee.organization_employees[0].slackId,
            //     },
            //     createdOn: sData?.createdOn,
            //     message: form.message === allmessages[4] ? form.customMessage.split('\n').join(' ') : form.message,
            //     org: sData?.organizationId,
            //     tag: sData?.tags,
            //     createdFor: createdFor,
            //     botToken: null,
            //     // channel: sData?.Organization.engageChannel.channel,
            // };
            // handleSlackShoutoutUI({
            //     orgId: mycontext.organizationId,
            //     empId: mycontext.EmployeeId,
            //     data: data,
            //     shoutoutId: sData?.shoutoutId,
            //     teamMsg: objShoutout.message,
            //     token: mycontext.token,
            //     oldIsPersonal: false,
            //     action: 'add',
            //     requester: 'slack',
            //     teamName: shoutoutEmpTeam?.teamName,
            // });

            setForm({
                employee: state.orgEmployees?.employees[0]?.Employee.firstName,
                createdOn: localToISO(
                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                    mycontext.country,
                ),
                createdBy: mycontext.EmployeeId,
                emoji: emojis[0],
                tags: allTags[0],
                organizationId: mycontext.organizationId,
                message: allmessages[0],
                customMessage: '',
            });
            setTimeout(() => {
                setEmployeeId([]);
                setTeamId([]);
            }, 2000);
        } catch (err) {
            setOpenA(false);
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    const handleUpdateShoutout = async (empOrTeam: string, orgOrEmp: string) => {
        // setLoading(true);
        try {
            setOpenA(false);
            setIsEdit(false);
            checkSubscription(userSubscriptionDetails);
            let shoutoutEmpTeam = null;
            const objShoutout = {
                shoutoutId: current.shoutoutId,
                emoji: form.emoji,
                createdOn: localToISO(
                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                    mycontext.country,
                ),
                tags: form.tags,
                message: form.message === allmessages[4] ? form.customMessage.split('\n').join(' ') : form.message,
                token: mycontext.token,
            };
            if (empOrTeam === 'employee') {
                if (employeeId.length === 0) {
                    throw new Error('Please select an employee');
                }
            } else {
                if (teamId.length === 0) {
                    throw new Error('Please select an team');
                }
            }
            const data = await updateShoutout(objShoutout);
            if (data?.error) {
                throw new Error('Failed to update shoutout!');
            }
            let createdFor1 = [];
            if (empOrTeam === 'employee') {
                let objShoutoutEmployee = null;
                let shoutoutEmployee = [];
                if (current.to?.[0].Employee === null) {
                    for (let i = 0; i < employeeId.length; i++) {
                        const objShoutoutEmployee = {
                            shoutoutId: current?.shoutoutId,
                            employeeId: employeeId[i],
                            token: mycontext.token,
                            isPersonal: orgOrEmp === 'emp',
                            orgId: mycontext.organizationId,
                            createdBy: mycontext.EmployeeId,
                        };
                        // if (current.to?.find((e: any) => e.employeeId === employeeId[i]) === undefined) {
                        shoutoutEmpTeam = await addShoutoutEmployee(objShoutoutEmployee);
                        shoutoutEmployee.push(shoutoutEmpTeam);
                        createdFor1.push({
                            id: employeeId[i],
                            // name: shoutoutEmpTeam.Employee.firstName + ' ' + shoutoutEmpTeam.Employee.lastName,
                        });
                        // }
                        if (orgOrEmp === 'emp') {
                            handleShoutout(data, shoutoutEmployee, null, null, empOrTeam, orgOrEmp);
                        } else {
                            handleShoutout(
                                data,
                                shoutoutEmployee,
                                objShoutoutEmployee,
                                employeeId,
                                empOrTeam,
                                orgOrEmp,
                            );
                        }
                    }
                    current.to?.map((e: any) => {
                        // if (!employeeId.includes(e.employeeId)) {
                        deleteShoutoutEmployee({
                            shoutoutEmployeeTeamId: e.shoutoutEmployeeTeamId,
                            token: mycontext.token,
                        });
                        // }
                    });
                } else {
                    for (let i = 0; i < employeeId.length; i++) {
                        objShoutoutEmployee = {
                            shoutoutEmployeeTeamId: current?.to?.filter(
                                (t: any) => t?.employeeId === employeeId[i],
                            )?.[0]?.shoutoutEmployeeTeamId,
                            shoutoutId: current?.shoutoutId,
                            employeeId: employeeId[i],
                            token: mycontext.token,
                            isPersonal: orgOrEmp === 'emp',
                            orgId: mycontext.organizationId,
                            createdBy: mycontext.EmployeeId,
                        };
                        if (current.to?.find((e: any) => e.employeeId === employeeId[i]) === undefined) {
                            shoutoutEmpTeam = await addShoutoutEmployee(objShoutoutEmployee);
                            shoutoutEmployee.push(shoutoutEmpTeam);
                            createdFor1.push({
                                id: employeeId[i],
                            });
                            // handleShoutout(data, shoutoutEmpTeam, objShoutoutEmployee, empOrTeam, orgOrEmp);
                        } else {
                            shoutoutEmpTeam = await updateShoutoutEmployee(objShoutoutEmployee);
                            // console.log(
                            //     'Shoutout team employeeId: ',
                            //     shoutoutEmpTeam,
                            //     JSON.stringify(shoutoutEmpTeam),
                            //     shoutoutEmpTeam.employeeId,
                            // );
                            shoutoutEmployee.push(shoutoutEmpTeam);
                            createdFor1.push({
                                id: shoutoutEmpTeam.employeeId,
                            });
                            // handleShoutout(data, shoutoutEmpTeam, objShoutoutEmployee, empOrTeam, orgOrEmp);
                        }
                    }
                    handleShoutout(data, shoutoutEmployee, objShoutoutEmployee, employeeId, empOrTeam, orgOrEmp);
                    current.to?.map((e: any) => {
                        if (!employeeId.includes(e.employeeId)) {
                            deleteShoutoutEmployee({
                                shoutoutEmployeeTeamId: e.shoutoutEmployeeTeamId,
                                token: mycontext.token,
                            });
                        }
                    });
                }
            } else {
                let objShoutoutTeam = null;
                let shoutoutEmployee = [];
                if (current.to?.[0].Employee === null) {
                    for (let i = 0; i < teamId.length; i++) {
                        objShoutoutTeam = {
                            shoutoutId: current?.shoutoutId,
                            employeeId: teamId[i],
                            token: mycontext.token,
                            orgId: mycontext.organizationId,
                            createdBy: mycontext.EmployeeId,
                        };
                        state.teams?.teams.map((t: any) => {
                            if (t.teamId === teamId[i]) {
                                createdFor1.push({
                                    id: teamId,
                                    name: t.teamName,
                                });
                            }
                        });
                        if (current.to?.find((e: any) => e.teamId === teamId[i]) === undefined) {
                            shoutoutEmpTeam = await addShoutoutTeam(objShoutoutTeam);
                            shoutoutEmployee.push(shoutoutEmpTeam);
                            // handleShoutout(data, shoutoutEmpTeam, objShoutoutTeam, empOrTeam, orgOrEmp);
                        }
                    }
                    handleShoutout(data, shoutoutEmployee, objShoutoutTeam, teamId, empOrTeam, orgOrEmp);
                    current.to?.map((e: any) => {
                        if (!teamId.includes(e.teamId)) {
                            deleteShoutoutEmployee({
                                shoutoutEmployeeTeamId: e.shoutoutEmployeeTeamId,
                                token: mycontext.token,
                            });
                        }
                    });
                } else {
                    for (let i = 0; i < teamId.length; i++) {
                        objShoutoutTeam = {
                            shoutoutId: current?.shoutoutId,
                            employeeId: teamId[i],
                            token: mycontext.token,
                            orgId: mycontext.organizationId,
                            createdBy: mycontext.EmployeeId,
                        };

                        state.teams?.teams.map((t: any) => {
                            if (t.teamId === teamId[i]) {
                                createdFor1.push({
                                    id: teamId,
                                    name: t.teamName,
                                });
                            }
                        });

                        // if (current.to?.find((e: any) => e.teamId === teamId[i]) === undefined) {
                        shoutoutEmpTeam = await addShoutoutTeam(objShoutoutTeam);
                        shoutoutEmployee.push(shoutoutEmpTeam);
                        // handleShoutout(data, shoutoutEmpTeam, objShoutoutTeam, empOrTeam, orgOrEmp);
                        // }
                    }
                    handleShoutout(data, shoutoutEmployee, objShoutoutTeam, teamId, empOrTeam, orgOrEmp);
                    current.to?.map((e: any) => {
                        // if (!teamId.includes(e.teamId)) {
                        deleteShoutoutEmployee({
                            shoutoutEmployeeTeamId: e.shoutoutEmployeeTeamId,
                            token: mycontext.token,
                        });
                        // }
                    });
                }
            }
            // setCurrent((prev: any) => {
            //     const d = {
            //         ...prev,
            //         message: form.message === allmessages[4] ? form.customMessage : form.message,
            //         tags: form.tags,
            //         emoji: form.emoji,
            //         createdOn: localToISO(new Date().toDateString(), new Date().toTimeString().split(' ')[0], mycontext.country),
            //     };
            //     return d;
            // });
            mycontext.togglePopup({
                alertMessage: 'Shoutout updated successfully!',
                severity: 'success',
            });

            // const data1 = {
            //     recipientType: empOrTeam === 'employee' ? 'Employee' : 'Teams',
            //     shoutoutOperation: 'edit_shoutout',
            //     shoutoutId: data?.shoutoutId,
            //     isPrivate: shoutoutEmpTeam?.isPersonal,
            //     createdBy: {
            //         employeeId: data?.createdBy,
            //         slackId: data?.Employee.organization_employees[0].slackId,
            //     },
            //     createdOn: current?.createdOn,
            //     message: form.message === allmessages[4] ? form.customMessage.split('\n').join(' ') : form.message,
            //     org: data?.organizationId,
            //     tag: data?.tags,
            //     createdFor: createdFor1,
            //     botToken: null,
            //     // channel: data?.Organization.engageChannel.channel,
            //     timeStamp: data?.timestamp,
            // };

            // handleSlackShoutoutUI({
            //     orgId: mycontext.organizationId,
            //     empId: mycontext.EmployeeId,
            //     data: data1,
            //     shoutoutId: current?.shoutoutId,
            //     teamMsg: objShoutout.message,
            //     token: mycontext.token,
            //     oldIsPersonal: false,
            //     action: 'update',
            //     requester: 'slack',
            //     teamName: null,
            // });

            setForm({
                employee: state.orgEmployees?.employees[0]?.Employee.firstName,
                createdOn: localToISO(
                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                    mycontext.country,
                ),
                createdBy: mycontext.EmployeeId,
                emoji: emojis[0],
                tags: allTags[0],
                organizationId: mycontext.organizationId,
                message: allmessages[0],
                customMessage: '',
            });
            setEmployeeId([]);
            setTeamId([]);
            // setLoading(false);
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            // setLoading(false);
            return err;
        }
    };

    const handleClose = () => {
        setIsEdit(false);
        setOpenA(false);
        setForm({
            employee: state.orgEmployees?.employees[0]?.Employee.firstName,
            createdOn: localToISO(
                getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                mycontext.country,
            ),
            createdBy: mycontext.EmployeeId,
            emoji: emojis[0],
            tags: allTags[0],
            organizationId: mycontext.organizationId,
            message: allmessages[0],
            customMessage: '',
        });
        setEmployeeId([]);
    };

    const handleEdit = () => {
        setIsEdit(true);
        setOpenA(true);
        setForm({
            employee: current.employee,
            createdOn: current.createdOn,
            createdBy: current.createdBy,
            emoji: current.emoji,
            tags: current.tags,
            organizationId: mycontext.organizationId,
            message: allmessages.includes(current.message) ? current.message : allmessages[4],
            customMessage: allmessages.includes(current.message) ? '' : current.message,
        });
    };

    const renderShoutoutConfetti = () => {
        renderConfettiRealistic();
    };

    const handleSidebar = (obj: any) => {
        setSidebar({ open: true });
        setCurrent(obj);
    };

    const scrollToBottom = () => {
        let scrollDiv = document.getElementById('scrolldiv');
        if (scrollDiv) {
            scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }
    };

    const addComment = async (e: any) => {
        e.preventDefault();
        try {
            if (comment === '') {
                throw new Error('Please add comment!');
            }
            checkSubscription(userSubscriptionDetails);
            const obj = {
                employeeId: mycontext.EmployeeId,
                shoutoutId: current?.shoutoutId,
                commentText: comment,
                commentDateTime: localToISO(
                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                    mycontext.country,
                ),
                token: mycontext.token,
            };
            const data: any = await addShoutoutComment(obj);
            setComment('');
            setCurrent((prev: any) => {
                const d: any = {
                    ...prev,
                    comments: [
                        ...prev.comments,
                        {
                            commentDateTime: localToISO(
                                getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                                getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                                mycontext.country,
                            ),
                            commentId: data?.commentId,
                            employeeId: mycontext.EmployeeId,
                            commentText: obj.commentText,
                            Employee: {
                                firstName: mycontext.fname,
                                profileImage: mycontext.profile,
                            },
                        },
                    ],
                };
                return d;
            });
            scrollToBottom();
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    React.useMemo(() => {
        if (current?.shoutoutId && data.shoutouts?.length !== 0) {
            data.shoutouts?.map((d: any) => {
                if (d.shoutoutId === current.shoutoutId) {
                    setCurrent(d);
                }
            });
        }
    }, [data.shoutouts]);

    const filterShoutoutsData = (data: any, id: string) => {
        // let a: any = [];
        // let b: any = [];
        // let isAllow = false;
        // data.map((d: any) => {
        //     if (d.to?.filter((t: any) => t?.isPersonal === true).length > 0) {
        //         if (
        //             d.to?.filter((t: any) => t?.employeeId === id && t?.isPersonal).length > 0 ||
        //             d.from?.employeeId === id
        //         ) {
        //             b.push(d);
        //             isAllow = true;
        //         }
        //     } else {
        //         a.push(d);
        //     }
        //     return null;
        // });
        // if (isAllow) {
        //     return [...b, ...a];
        // }
        return data
            .map((d: any) => {
                if (d.to?.filter((t: any) => t?.isPersonal === true).length > 0) {
                    if (
                        d.to?.filter((t: any) => t?.employeeId === id && t?.isPersonal).length > 0 ||
                        d.from?.employeeId === id
                    ) {
                        return d;
                    }
                    return null;
                } else {
                    return d;
                }
            })
            .filter((d: any) => d !== null);
    };

    React.useEffect(() => {
        dispatch(updateActiveTab('/dashboard'));
    }, []);

    if (
        data.status === 'loading' ||
        data.shoutouts === undefined ||
        state.orgEmployees.status === 'loading' ||
        state.teams.status === 'loading'
    ) {
        return <LoaderPage />;
    }
    if (data.status === 'failed' || state.orgEmployees.status === 'failed' || state.teams.status === 'failed') {
        return <ErrorPage title="Something went wrong!" />;
    } else {
        const allEmployees = state.orgEmployees?.employees;
        const teams = state.teams?.teams;
        if (isMobile) {
            return (
                <>
                    <MyModal
                        setOpen={setOpenA}
                        open={openA}
                        onModalClosed={() => {
                            setOpenA(false);
                            setIsEdit(false);
                        }}
                        title="Give Shoutout 🎉"
                    >
                        <AddShoutout
                            current={current}
                            form={form}
                            setForm={setForm}
                            setOpen={setOpenA}
                            // allEmployees={allEmployees}
                            allTeams={teams}
                            allEmployees={allEmployees.filter(
                                (e: any) => e.Employee?.employeeId !== mycontext.EmployeeId,
                            )}
                            handleSubmit={handleSubmit}
                            setEmployeeId={setEmployeeId}
                            setTeamId={setTeamId}
                            handleEdit={handleEdit}
                            isEdit={isEdit}
                            handleUpdateShoutout={handleUpdateShoutout}
                            handleClose={handleClose}
                            // ref={refElement}
                        />
                    </MyModal>
                    <div
                        className={
                            option === 'comment'
                                ? classes.drawerContentScrollMobile
                                : classes.drawerContentScrollMobile2
                        }
                    >
                        <DrawerContent
                            setIsMobile={setIsMobile}
                            setSidebar={setSidebar}
                            isMobile={isMobile}
                            current={current}
                            option={option}
                            setOption={setOption}
                            setCurrent={setCurrent}
                            handleEdit={handleEdit}
                        />
                        {option === 'comment' ? (
                            <div style={{ width: '100%', height: 40, background: theme.palette.background.paper }}>
                                <form onSubmit={addComment} className={classes.commentFormMobile} data-testid="form">
                                    <input
                                        type="text"
                                        placeholder="Type your message..."
                                        value={comment}
                                        onChange={(e: any) => setComment(e.target.value)}
                                        className={classes.commentBox}
                                        maxLength={50}
                                    />
                                    <button type="submit" className={classes.commentBtn} data-testid="submitBtn">
                                        <Icon
                                            icon="carbon:send-filled"
                                            color={theme.palette.text.secondary}
                                            width="24"
                                        />
                                    </button>
                                </form>
                            </div>
                        ) : null}
                    </div>
                </>
            );
        }
        // if (loading) {
        //     return (
        //         <div
        //             style={{
        //                 position: 'fixed',
        //                 left: 0,
        //                 right: 0,
        //                 top: 0,
        //                 bottom: 0,
        //                 display: 'flex',
        //                 alignItems: 'center',
        //                 justifyContent: 'center',
        //                 background: 'rgba(0, 0, 0, 0.1)',
        //             }}
        //         >
        //             <div
        //                 style={{
        //                     width: 200,
        //                     height: 200,
        //                     backgroundColor: theme.palette.background.paper,
        //                     color: theme.palette.text.primary,
        //                     fontSize: 16,
        //                     fontFamily: theme.typography.fontFamily,
        //                     fontWeight: theme.typography.fontWeightBold,
        //                 }}
        //             >
        //                 <Typography>Please wait while updating shoutout!</Typography>
        //             </div>
        //         </div>
        //     );
        // }
        return (
            <>
                <MyModal
                    setOpen={setOpenA}
                    open={openA}
                    onModalClosed={() => {
                        setOpenA(false);
                        setIsEdit(false);
                    }}
                    title="Give Shoutout 🎉"
                >
                    <AddShoutout
                        form={form}
                        current={current}
                        setForm={setForm}
                        setOpen={setOpenA}
                        // allEmployees={allEmployees}
                        allTeams={teams}
                        allEmployees={allEmployees.filter((e: any) => e.Employee?.employeeId !== mycontext.EmployeeId)}
                        handleSubmit={handleSubmit}
                        setEmployeeId={setEmployeeId}
                        setTeamId={setTeamId}
                        handleEdit={handleEdit}
                        isEdit={isEdit}
                        handleUpdateShoutout={handleUpdateShoutout}
                        handleClose={handleClose}
                        // ref={refElement}
                    />
                </MyModal>
                <div className={classes.main}>
                    <div className={sidebar.open ? classes.leftHalf : classes.leftFull}>
                        <CardComponent
                            data={filterShoutoutsData(data.shoutouts, mycontext.EmployeeId) || []}
                            open={sidebar.open || false}
                            handleSidebar={handleSidebar}
                            setIsMobile={setIsMobile}
                            renderShoutoutConfetti={renderShoutoutConfetti}
                            setOption={setOption}
                            setOpenA={setOpenA}
                            setCurrent={setCurrent}
                            current={current}
                            employees={allEmployees}
                            teams={teams}
                            setSidebar={setSidebar}
                            handleEdit={handleEdit}
                            playSound={giveApplause}
                            mycontext={mycontext}
                        />
                    </div>
                    <Drawer
                        sidebar={sidebar}
                        setSidebar={setSidebar}
                        comment={comment}
                        setComment={setComment}
                        action={option}
                        addComment={addComment}
                    >
                        <DrawerContent
                            setSidebar={setSidebar}
                            setIsMobile={setIsMobile}
                            current={current}
                            option={option}
                            setOption={setOption}
                            setCurrent={setCurrent}
                            handleEdit={handleEdit}
                        />
                    </Drawer>
                </div>
            </>
        );
    }
};

export default ShoutoutMain;
