import React, { useState, useContext } from 'react';
import {
    Card,
    Typography,
    Button,
    Chip,
    Hidden,
    Grid,
    FormLabel,
    useTheme,
    FormControl,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    TextField,
} from '@material-ui/core';
import otherStyles from './otherStyles';
import calendarModalStyle from '../calendar/calendarStyle2';
import Tabs from '../Tabbars/Tabs';
import { DisplayDate2, DisplayTime2, DenyBtn, EditBtn } from './DashboardPart';
import AuthContext from '../../context/AuthContext';
import MyModal, { PopUp } from '../Modal/Modal';
import { isHoliday, isTimePresent, isValidTime } from '../../helpers/dateDifference';
import HolidayTable from 'components/HolidayTable/HolidayTable';
import { deleteLeave, fetchTimeoffStatus, updateLeaveEmployee } from 'helpers/hasuraapi';
import todayTimeoff from '../../Assets/images/todaysTimeoff.png';
import Skeleton from '@material-ui/lab/Skeleton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
// import { deleteCalendarLeave, editCalendarLeave } from '../Redux/calendarReducer';
// import { deleteEmployeeLeave, editEmployeeLeave } from '../Redux/employeeLeavesReducer';
import { DateField, TimeField } from '../ImportHoliday/FormInputs';
import {
    DateDifference,
    getTimeDifference,
    toAWSDateFormat,
    getTimeOnlyInMinutes,
    convertTZ,
    checkTimeDifference,
} from '../../helpers/date';
import { isoToLocal, getCurrentCountryDate, getCurrentCountryTime, localToISO, giveTz } from 'helpers/timezone';
import { useFlagsmith } from 'flagsmith-react';
import { handleCancelRequest } from '../../helpers/sendNotif';

interface LeavesProps {
    leaves?: any;
    type?: string;
    handleDeny?: any;
    handleEdit?: any;
    COLORS?: any;
    data?: any;
    approvers?: any;
    orgEmployees?: any;
}

export function Leaves(props: LeavesProps) {
    const classes = otherStyles();
    const mycontext = useContext(AuthContext);
    if (props.leaves.length === 0) {
        return (
            <div className={classes.leaveMainNotFound}>
                <img src={todayTimeoff} alt="No Time-Off Found" className={classes.notfoundImage} />
                <Typography className={classes.notfoundText}>No time-off found</Typography>
            </div>
        );
    }
    return (
        <div className={classes.leaveMain}>
            {props.leaves?.map((l: any, i: number) => (
                <div
                    key={i}
                    className={classes.leave}
                    style={{
                        borderBottom: i === props.leaves.length - 1 ? 'none' : '1px solid #d4d4d4',
                        marginRight: 10,
                    }}
                >
                    <div className={classes.leaveLeft}>
                        <div className={classes.data}>
                            <Chip
                                size="small"
                                label={l.category}
                                style={{
                                    backgroundColor:
                                        props.COLORS[
                                            props.data.findIndex((lo: any) =>
                                                lo.leaveTypeName.toLowerCase().includes(l.category.toLowerCase()),
                                            )
                                        ],
                                    color: '#fff',
                                    fontSize: 10,
                                    marginRight: 10,
                                    height: 15,
                                    padding: '0 2px',
                                }}
                            />
                            <Typography className={classes.reasonStyle}>{l.reason}</Typography>
                        </div>
                        <div>
                            {(l.startTime === 'null' || l.startTime === null) &&
                            (l.endTime === 'null' || l.endTime === null) ? (
                                <DisplayDate2
                                    startDate={isoToLocal(l.isoString?.startTime, mycontext.country)}
                                    endDate={isoToLocal(l.isoString?.endTime, mycontext.country)}
                                    startTime={isoToLocal(l.isoString?.startTime, mycontext.country)
                                        .toTimeString()
                                        .split(' ')[0]
                                        .substring(0, 5)}
                                    endTime={isoToLocal(l.isoString?.endTime, mycontext.country)
                                        .toTimeString()
                                        .split(' ')[0]
                                        .substring(0, 5)}
                                    noOfHours={l.noOfHours}
                                    dateFormat={mycontext.dateFormat}
                                    id={1}
                                />
                            ) : (
                                <DisplayTime2
                                    startTime={isoToLocal(l.isoString?.startTime, mycontext.country)
                                        .toTimeString()
                                        .split(' ')[0]
                                        .substring(0, 5)}
                                    endTime={isoToLocal(l.isoString?.endTime, mycontext.country)
                                        .toTimeString()
                                        .split(' ')[0]
                                        .substring(0, 5)}
                                    startDate={isoToLocal(l.isoString?.startTime, mycontext.country)}
                                    endDate={isoToLocal(l.isoString?.endTime, mycontext.country)}
                                    dateFormat={mycontext.dateFormat}
                                    id={1}
                                />
                            )}
                        </div>
                        {/* <div>
                            {(l.startTime === 'null' || l.startTime === null) &&
                            (l.endTime === 'null' || l.endTime === null) ? (
                                <DisplayDate
                                    startDate={l.startDate}
                                    endDate={l.endDate}
                                    dateFormat={mycontext.dateFormat}
                                    gmt={l.gmt}
                                    id={1}
                                />
                            ) : (
                                <DisplayTime
                                    startTime={l.startTime}
                                    endTime={l.endTime}
                                    startDate={l.startDate}
                                    dateFormat={mycontext.dateFormat}
                                    gmt={l.gmt}
                                    id={1}
                                    timeZone={mycontext.location?.timezone}
                                />
                            )}
                        </div> */}
                        {props.orgEmployees.filter((emp: any) => emp.employeeId === l.leaveAppliedBy)[0]?.employeeId !==
                            l?.Employee?.employeeId && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.reason}>
                                    Leave applied by:{' '}
                                    <span className={classes.date}>
                                        {
                                            props.orgEmployees.filter(
                                                (emp: any) => emp.employeeId === l.leaveAppliedBy,
                                            )[0]?.Employee.firstName
                                        }
                                    </span>
                                </Typography>
                            </div>
                        )}
                        {props.type === 'denied' && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.reason}>
                                    Leave denied Reason:{' '}
                                    <span className={classes.date}>
                                        {l.denialReason === null || l.denialReason === 'null' || l.denialReason === ''
                                            ? 'None'
                                            : l.denialReason}
                                    </span>
                                </Typography>
                            </div>
                        )}
                    </div>
                    <Hidden mdDown>
                        {props.type === 'approved' && (
                            <div className={classes.leaveMiddle}>
                                <Typography className={classes.label}>Approved by</Typography>
                                <Typography className={classes.approverName}>
                                    {l.approverId === null
                                        ? 'Auto Approved'
                                        : props.orgEmployees.filter((e: any) => e?.employeeId === l?.approverId)[0]
                                              ?.Employee.firstName}
                                </Typography>
                            </div>
                        )}
                        {props.type === 'denied' && (
                            <div className={classes.leaveMiddle}>
                                <Typography className={classes.label}>Denied by</Typography>
                                <Typography className={classes.approverName}>
                                    {
                                        props.orgEmployees.filter((e: any) => e?.employeeId === l?.denierId)[0]
                                            ?.Employee?.firstName
                                    }
                                </Typography>
                            </div>
                        )}
                        {props.type === 'denied' ? null : props.type !== 'pending' ? (
                            toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(isoToLocal(l.isoString?.endTime, mycontext.country)) ? (
                                l.startTime === 'null' || l.startTime === null ? (
                                    DateDifference(
                                        isoToLocal(l.isoString?.startTime, mycontext.country),
                                        getCurrentCountryDate(mycontext.country.zoneName),
                                    ) <= 0 ? (
                                        <div className={classes.leaveRight}>
                                            <DenyBtn text="Cancel" handleDeny={() => props.handleDeny(l)} />
                                        </div>
                                    ) : (
                                        <div className={classes.leaveRight}></div>
                                    )
                                ) : toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                                  getCurrentCountryDate(mycontext.country.zoneName) ? (
                                    checkTimeDifference(
                                        isoToLocal(l.isoString?.startTime, mycontext.country)
                                            .toTimeString()
                                            .split(' ')[0]
                                            .slice(0, 5),
                                        getCurrentCountryTime(mycontext.country.zoneName),
                                    ) ? (
                                        <div className={classes.leaveRight}>
                                            <DenyBtn text="Cancel" handleDeny={() => props.handleDeny(l)} />
                                        </div>
                                    ) : (
                                        <div className={classes.leaveRight}></div>
                                    )
                                ) : DateDifference(
                                      isoToLocal(l.isoString?.startTime, mycontext.country),
                                      getCurrentCountryDate(mycontext.country.zoneName),
                                  ) <= 0 ? (
                                    <div className={classes.leaveRight}>
                                        <DenyBtn text="Cancel" handleDeny={() => props.handleDeny(l)} />
                                    </div>
                                ) : (
                                    <div className={classes.leaveRight}></div>
                                )
                            ) : DateDifference(
                                  isoToLocal(l.isoString?.startTime, mycontext.country),
                                  getCurrentCountryDate(mycontext.country.zoneName),
                              ) <= 0 &&
                              DateDifference(
                                  getCurrentCountryDate(mycontext.country.zoneName),
                                  isoToLocal(l.isoString?.endTime, mycontext.country),
                              ) >= 0 ? (
                                <div className={classes.leaveRight}>
                                    <DenyBtn text="Cancel" handleDeny={() => props.handleDeny(l)} />
                                </div>
                            ) : (
                                <div className={classes.leaveRight}></div>
                            )
                        ) : toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                          toAWSDateFormat(isoToLocal(l.isoString?.endTime, mycontext.country)) ? (
                            DateDifference(
                                isoToLocal(l.isoString?.startTime, mycontext.country),
                                getCurrentCountryDate(mycontext.country.zoneName),
                            ) <= 1 ? (
                                <div className={classes.leaveRight}>
                                    <EditBtn text="Edit" handleEdit={() => props.handleEdit(l)} />
                                    <DenyBtn text="Cancel" handleDeny={() => props.handleDeny(l)} />
                                </div>
                            ) : (
                                <div className={classes.leaveRight}></div>
                            )
                        ) : DateDifference(
                              isoToLocal(l.isoString?.startTime, mycontext.country),
                              getCurrentCountryDate(mycontext.country.zoneName),
                          ) <= 1 ||
                          DateDifference(
                              getCurrentCountryDate(mycontext.country.zoneName),
                              isoToLocal(l.isoString?.endTime, mycontext.country),
                          ) >= 0 ? (
                            <div className={classes.leaveRight}>
                                <EditBtn text="Edit" handleEdit={() => props.handleEdit(l)} />
                                <DenyBtn text="Cancel" handleDeny={() => props.handleDeny(l)} />
                            </div>
                        ) : (
                            <div className={classes.leaveRight}></div>
                        )}
                    </Hidden>
                    <Hidden mdUp>
                        {props.type === 'approved' && (
                            <div className={classes.leaveMiddle}>
                                <Typography className={classes.label}>
                                    Approved by:{' '}
                                    <span className={classes.approverName}>
                                        {l.approverId === null
                                            ? 'Auto Approved'
                                            : props.orgEmployees.filter((e: any) => e?.employeeId === l?.approverId)[0]
                                                  ?.Employee.firstName}
                                    </span>
                                </Typography>
                            </div>
                        )}
                        {props.type === 'denied' && (
                            <div className={classes.leaveMiddle}>
                                <Typography className={classes.label}>
                                    Denied by:{' '}
                                    <span className={classes.approverName}>
                                        {
                                            props.orgEmployees.filter((e: any) => e?.employeeId === l?.denierId)[0]
                                                ?.Employee?.firstName
                                        }
                                    </span>
                                </Typography>
                            </div>
                        )}
                        {props.type === 'denied' ? null : props.type !== 'pending' ? (
                            toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(isoToLocal(l.isoString?.endTime, mycontext.country)) ? (
                                l.startTime === 'null' || l.startTime === null ? (
                                    DateDifference(
                                        isoToLocal(l.isoString?.startTime, mycontext.country),
                                        getCurrentCountryDate(mycontext.country.zoneName),
                                    ) <= 0 ? (
                                        <Button
                                            variant="contained"
                                            className={classes.cancelBtn}
                                            onClick={() => props.handleDeny(l)}
                                        >
                                            Cancel
                                        </Button>
                                    ) : null
                                ) : toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                                  getCurrentCountryDate(mycontext.country.zoneName) ? (
                                    checkTimeDifference(
                                        isoToLocal(l.isoString?.startTime, mycontext.country)
                                            .toTimeString()
                                            .split(' ')[0]
                                            .slice(0, 5),
                                        getCurrentCountryTime(mycontext.country.zoneName),
                                    ) ? (
                                        <Button
                                            variant="contained"
                                            className={classes.cancelBtn}
                                            onClick={() => props.handleDeny(l)}
                                        >
                                            Cancel
                                        </Button>
                                    ) : null
                                ) : DateDifference(
                                      isoToLocal(l.isoString?.startTime, mycontext.country),
                                      getCurrentCountryDate(mycontext.country.zoneName),
                                  ) <= 0 ? (
                                    <Button
                                        variant="contained"
                                        className={classes.cancelBtn}
                                        onClick={() => props.handleDeny(l)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null
                            ) : DateDifference(
                                  isoToLocal(l.isoString?.startTime, mycontext.country),
                                  getCurrentCountryDate(mycontext.country.zoneName),
                              ) <= 0 &&
                              DateDifference(
                                  getCurrentCountryDate(mycontext.country.zoneName),
                                  isoToLocal(l.isoString?.endTime, mycontext.country),
                              ) >= 0 ? (
                                <Button
                                    variant="contained"
                                    className={classes.cancelBtn}
                                    onClick={() => props.handleDeny(l)}
                                >
                                    Cancel
                                </Button>
                            ) : null
                        ) : toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                          toAWSDateFormat(isoToLocal(l.isoString?.endTime, mycontext.country)) ? (
                            DateDifference(
                                isoToLocal(l.isoString?.startTime, mycontext.country),
                                getCurrentCountryDate(mycontext.country.zoneName),
                            ) <= 1 ? (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        gridColumnGap: 10,
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        className={classes.editBtn}
                                        onClick={() => props.handleEdit(l)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.cancelBtn}
                                        onClick={() => props.handleDeny(l)}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            ) : null
                        ) : DateDifference(
                              isoToLocal(l.isoString?.startTime, mycontext.country),
                              getCurrentCountryDate(mycontext.country.zoneName),
                          ) <= 1 ||
                          DateDifference(
                              getCurrentCountryDate(mycontext.country.zoneName),
                              isoToLocal(l.isoString?.endTime, mycontext.country),
                          ) >= 0 ? (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gridColumnGap: 10,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    className={classes.editBtn}
                                    onClick={() => props.handleEdit(l)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.cancelBtn}
                                    onClick={() => props.handleDeny(l)}
                                >
                                    Cancel
                                </Button>
                            </div>
                        ) : null}
                    </Hidden>
                </div>
            ))}
        </div>
    );
}

interface DisplayLeavesProps {
    title?: string;
    leaves?: any;
    setStep?: any;
    holidays?: any;
    approvers?: any;
    loading?: boolean;
    orgEmployees?: any;
}

export default function DisplayLeaves(props: DisplayLeavesProps) {
    const classes = otherStyles();
    const classes2 = calendarModalStyle();
    const theme = useTheme();
    const [alert, setAlert] = useState<boolean>(false);
    const [openH, setOpenH] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [currentLeave, setCurrentLeave] = useState<any>({});
    const [severity, setSeverity] = useState<string>('');
    const [alertMessage, setAlertMessage] = useState<string>('');

    const reasons = ['Feeling Sick', 'Doctors Appoinment', 'Family Emergency', 'Custom Reason'];
    const [leaveFormat, setLeaveFormat] = useState('fullday');
    const [formError, setFormError] = useState('');

    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [startTime, setStartTime] = useState<any>(null);
    const [endTime, setEndTime] = useState<any>(null);
    const [reason, setReason] = useState<string>(reasons[0]);
    const [customReason, setCustomReason] = useState<string>('');
    const mycontext = useContext(AuthContext);

    //flagsmith
    // const { identify, isIdentified, getTrait, setTrait, hasFeature } = useFlagsmith();
    const { identify, isIdentified, getTrait, setTrait } = useFlagsmith();
    const identifyUser = async (id: string, email: string) => {
        await identify(id);
        const hasEmail = !!getTrait('email');
        if (!hasEmail) {
            setTrait('email', email);
        }
    };
    if (!isIdentified) {
        identifyUser(mycontext.email, mycontext.email);
    }

    // const [leaveTypes, setLeaveTypes] = useState<any>([]);
    const state: any = useSelector(state => state);
    const userSubscriptionDetails = state?.subscription.userSubscriptions;
    const COLORS = ['#956FE4', '#587EE0', '#4FB8B2', '#F2A142', '#a0d568', '#ed5564', '#ac92eb', '#20639b'];

    const handleDeny = async (leave: any) => {
        setCurrentLeave(leave);
        setOpen(true);
    };

    const handleEdit = (obj: any) => {
        try {
            setCurrentLeave(obj);
            if (reasons.includes(obj?.reason)) {
                setReason(obj?.reason);
            } else {
                setReason(reasons[3]);
                setCustomReason(obj?.reason);
            }
            if (
                (obj.startTime === 'null' || obj.startTime === null) &&
                (obj.endTime === 'null' || obj.endTime === null)
            ) {
                setLeaveFormat('fullday');
                setStartDate(toAWSDateFormat(isoToLocal(obj?.isoString?.startTime, mycontext.country)));
                setStartTime(getCurrentCountryTime(mycontext.country.zoneName));
                setEndTime(getCurrentCountryTime(mycontext.country.zoneName));
                setEndDate(
                    parseInt(obj.noOfHours) === mycontext.workingHours
                        ? toAWSDateFormat(isoToLocal(obj?.isoString?.startTime, mycontext.country))
                        : toAWSDateFormat(isoToLocal(obj?.isoString?.endTime, mycontext.country)),
                );
            } else {
                setLeaveFormat('custom');
                setStartTime(
                    isoToLocal(obj?.isoString?.startTime, mycontext.country)
                        .toTimeString()
                        .split(' ')[0]
                        .substring(0, 5),
                );
                setEndTime(
                    isoToLocal(obj?.isoString?.endTime, mycontext.country)
                        .toTimeString()
                        .split(' ')[0]
                        .substring(0, 5),
                );
                setStartDate(toAWSDateFormat(isoToLocal(obj?.isoString?.startTime, mycontext.country)));
                setEndDate(toAWSDateFormat(isoToLocal(obj?.isoString?.startTime, mycontext.country)));
                // setCustomReason(obj.reason);
            }
            setOpenEdit(true);
        } catch (err) {
            return err;
        }
    };

    const editLeave = async (data: any) => {
        let obj: any;
        try {
            const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            if (leaveFormat === 'fullday') {
                if (startDate === null) {
                    throw new Error('Please select start date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (endDate === null) {
                    throw new Error('Please select end Date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), endDate) < 0) {
                    throw new Error('Please select valid end date!');
                }
                if (DateDifference(startDate, endDate) < 0) {
                    throw new Error('Please select valid end date!');
                }
                if (isHoliday(startDate, endDate, props.holidays)) {
                    throw new Error('You may have holiday on selected date, please select other date!');
                }
                if (customReason === '' && reason === reasons[3]) {
                    throw new Error('Please add a reason!');
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: mycontext.EmployeeId,
                    token: mycontext.token,
                    category: data.category,
                    startDate: toAWSDateFormat(localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country)),
                    endDate: toAWSDateFormat(localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country)),
                    startTime: null,
                    endTime: null,
                    noOfHours:
                        mycontext.workingHours *
                        (DateDifference(toAWSDateFormat(startDate), toAWSDateFormat(endDate)) + 1),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: 'pending',
                    leaveId: data.leaveId,
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country),
                        endTime: localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country),
                    },
                };
            } else {
                if (startDate === null) {
                    throw new Error('Please select start date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (startTime === null || startTime === '') {
                    throw new Error('Please select startTime!');
                }
                if (endTime === null || endTime === '') {
                    throw new Error('Plese select endTime!');
                }
                if (
                    isValidTime(startTime, convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate)
                ) {
                    throw new Error('Please select valid start time!');
                }
                if (isValidTime(endTime, convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate)) {
                    throw new Error('Please select valid end time!');
                }
                if (parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) < 0) {
                    throw new Error('Please select valid times!');
                }
                if (parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) < 0) {
                    throw new Error('Please select valid times!');
                }
                if (parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) < 0.5) {
                    throw new Error('You cant add leave less than half hour!');
                }
                if (customReason === '' && reason === reasons[3]) {
                    throw new Error('Please add a reason!');
                }
                if (isHoliday(startDate, endDate, props.holidays)) {
                    throw new Error('You may have holiday on selected date, please select other date!');
                }
                if (
                    getTimeDifference(startTime, endTime) > mycontext.workingHours ||
                    parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) > mycontext.workingHours
                ) {
                    throw new Error(`You can't add leave more than working hours!`);
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: mycontext.EmployeeId,
                    token: mycontext.token,
                    category: data.category,
                    startDate: toAWSDateFormat(
                        localToISO(toAWSDateFormat(startDate), `${startTime}:00`, mycontext.country),
                    ),
                    endDate: toAWSDateFormat(
                        localToISO(toAWSDateFormat(startDate), `${endTime}:00`, mycontext.country),
                    ),
                    startTime: startTime,
                    endTime: endTime,
                    noOfHours: parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: 'pending',
                    leaveId: data.leaveId,
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), `${startTime}:00`, mycontext.country),
                        endTime: localToISO(toAWSDateFormat(startDate), `${endTime}:00`, mycontext.country),
                    },
                };
            }
            if (
                props.leaves.find(
                    (l: any) =>
                        toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(startDate) &&
                        l.leaveStatus !== 'denied' &&
                        l.startTime === 'null',
                ) !== undefined
            ) {
                throw new Error('Leave with the same date already present!');
            }
            const presentLeave = props.leaves.find(
                (l: any) =>
                    toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                        toAWSDateFormat(startDate) &&
                    l.leaveStatus !== 'denied' &&
                    l.startTime !== 'null' &&
                    isTimePresent(
                        startTime,
                        isoToLocal(l.isoString?.startTime, mycontext.country)
                            .toTimeString()
                            .split(' ')[0]
                            .substring(0, 5),
                        isoToLocal(l.isoString?.endTime, mycontext.country)
                            .toTimeString()
                            .split(' ')[0]
                            .substring(0, 5),
                    ),
            );
            const updatedLeave = obj;
            if (presentLeave) {
                // console.log(
                //     'time check',
                //     presentLeave.startTime,
                //     updatedLeave.startTime,
                //     presentLeave.endTime,
                //     updatedLeave.endTime,
                //     presentLeave.startDate,
                //     updatedLeave.startDate,
                //     presentLeave.endDate,
                //     updatedLeave.endDate,
                // );
                if (
                    presentLeave.startTime === updatedLeave.startTime &&
                    presentLeave.endTime === updatedLeave.endTime &&
                    presentLeave.startDate === updatedLeave.startDate &&
                    presentLeave.endDate === updatedLeave.endDate
                ) {
                    throw new Error('Leave with the same time already present!');
                }
            }
            const resp = await updateLeaveEmployee(obj, mycontext.isApprover);
            if (resp.leaveId) {
                setStartDate(null);
                setEndDate(null);
                setStartTime(null);
                setEndTime(null);
                setReason(reasons[0]);
                setCustomReason('');
                setFormError('');
                setOpenEdit(false);
                setAlert(true);
                setAlertMessage(`leave updated successfully!`);
                setSeverity('success');
                // setTimeout(() => refreshPage(), 1000);
                return;
            }
            setStartDate(null);
            setEndDate(null);
            setStartTime(null);
            setEndTime(null);
            setReason(reasons[0]);
            setCustomReason('');
            setFormError('');
            setOpenEdit(false);
            setAlert(true);
            setAlertMessage(`Failed to update leave`);
            setSeverity('error');
        } catch (err) {
            setFormError(err.message);
            return err;
        }
    };

    // function refreshPage() {
    //     window.location.reload();
    // }

    const handleDelete = async () => {
        try {
            const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const leaveData = await fetchTimeoffStatus(currentLeave.leaveId, mycontext.token);
            let prevStatus = 'pending';

            if (leaveData !== null) {
                prevStatus = leaveData[0].leaveStatus;
            }
            const resp = await deleteLeave(currentLeave.leaveId, mycontext.token);
            if (resp.returning[0]?.leaveId) {
                setOpen(false);
                setAlert(true);
                setAlertMessage(`leave cancelled successfully!`);
                setSeverity('success');
                // setTimeout(() => refreshPage());
                // handleCancelNotification({
                //     empId: mycontext.EmployeeId,
                //     actorId: mycontext.EmployeeId,
                //     orgId: mycontext.organizationId,
                //     leaveId: null,
                //     token: mycontext.token,
                //     startDate: currentLeave.startDate,
                //     endDate: currentLeave.endDate,
                //     startTime: currentLeave.startTime,
                //     endTime: currentLeave.endTime,
                //     category: currentLeave.category,
                //     isoString: currentLeave.isoString,
                //     prevStatus: prevStatus,
                //     notif_type: 'denyRequest',
                //     action: 'cancel',
                // });
                handleCancelRequest({
                    empId: mycontext.EmployeeId,
                    orgId: mycontext.organizationId,
                    leaveId: resp.returning[0]?.leaveId,
                    token: mycontext.token,
                    startDate: currentLeave.startDate,
                    endDate: currentLeave.endDate,
                    startTime: currentLeave.startTime,
                    endTime: currentLeave.endTime,
                    category: currentLeave.category,
                    reason: currentLeave.reason,
                    isoString: currentLeave.isoString,
                    prevStatus: prevStatus,
                    notif_type: 'cancelRequest',
                });
                return;
            }

            setOpen(false);
            setAlert(true);
            setAlertMessage(`Failed to cancel leave`);
            setSeverity('error');
            // setTimeout(() => refreshPage());
        } catch (err) {
            setOpen(false);
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    // useEffect(() => {
    //     async function fetchLeaves() {
    //         const ltypes = await daysPerYearForLeaveTypes(mycontext.organizationId, mycontext.token);
    //         setLeaveTypes(ltypes);
    //     }
    //     fetchLeaves();
    // }, [mycontext]);

    const loadingComponent = [
        {
            label: `Pending (${props.leaves.filter((l: any) => l.leaveStatus === 'pending').length})`,
            component: <Skeleton width="100%" height={35} />,
        },
        {
            label: `Approved (${
                props.leaves
                    .filter((l: any) => l.leaveStatus === 'approved')
                    .filter((lo: any) =>
                        toAWSDateFormat(isoToLocal(lo.isoString?.startTime, mycontext.country)) ===
                        toAWSDateFormat(isoToLocal(lo.isoString?.endTime, mycontext.country))
                            ? DateDifference(
                                  isoToLocal(lo.isoString?.startTime, mycontext.country),
                                  getCurrentCountryDate(mycontext.country.zoneName),
                              ) <= 0
                            : DateDifference(
                                  isoToLocal(lo.isoString?.startTime, mycontext.country),
                                  getCurrentCountryDate(mycontext.country.zoneName),
                              ) <= 0 ||
                              DateDifference(
                                  getCurrentCountryDate(mycontext.country.zoneName),
                                  isoToLocal(lo.isoString?.endTime, mycontext.country),
                              ) >= 0,
                    ).length
            })`,
            component: <Skeleton width="100%" height={35} />,
        },
        {
            label: `Denied (${props.leaves.filter((l: any) => l.leaveStatus === 'denied').length})`,
            component: <Skeleton width="100%" height={35} />,
        },
    ];

    const components = [
        {
            label: `Pending (${props.leaves.filter((l: any) => l.leaveStatus === 'pending').length})`,
            component: (
                <Leaves
                    leaves={props.leaves.filter((l: any) => l.leaveStatus === 'pending')}
                    type="pending"
                    handleDeny={handleDeny}
                    handleEdit={handleEdit}
                    COLORS={COLORS}
                    data={state.leaveTypes?.lTypes}
                    approvers={props.approvers}
                    orgEmployees={props.orgEmployees}
                />
            ),
        },
        {
            label: `Approved (${
                props.leaves
                    .filter((l: any) => l.leaveStatus === 'approved')
                    .filter((lo: any) =>
                        toAWSDateFormat(isoToLocal(lo.isoString?.startTime, mycontext.country)) ===
                        toAWSDateFormat(isoToLocal(lo.isoString?.endTime, mycontext.country))
                            ? DateDifference(
                                  isoToLocal(lo.isoString?.startTime, mycontext.country),
                                  getCurrentCountryDate(mycontext.country.zoneName),
                              ) <= 0
                            : DateDifference(
                                  isoToLocal(lo.isoString?.startTime, mycontext.country),
                                  getCurrentCountryDate(mycontext.country.zoneName),
                              ) <= 0 ||
                              DateDifference(
                                  getCurrentCountryDate(mycontext.country.zoneName),
                                  isoToLocal(lo.isoString?.endTime, mycontext.country),
                              ) >= 0,
                    ).length
            })`,
            component: (
                <Leaves
                    leaves={props.leaves
                        .filter((l: any) => l.leaveStatus === 'approved')
                        .filter((lo: any) =>
                            toAWSDateFormat(isoToLocal(lo.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(isoToLocal(lo.isoString?.endTime, mycontext.country))
                                ? DateDifference(
                                      isoToLocal(lo.isoString?.startTime, mycontext.country),
                                      getCurrentCountryDate(mycontext.country.zoneName),
                                  ) <= 0
                                : DateDifference(
                                      isoToLocal(lo.isoString?.startTime, mycontext.country),
                                      getCurrentCountryDate(mycontext.country.zoneName),
                                  ) <= 0 ||
                                  DateDifference(
                                      getCurrentCountryDate(mycontext.country.zoneName),
                                      isoToLocal(lo.isoString?.endTime, mycontext.country),
                                  ) >= 0,
                        )}
                    type="approved"
                    handleDeny={handleDeny}
                    handleEdit={handleEdit}
                    COLORS={COLORS}
                    data={state.leaveTypes.lTypes}
                    approvers={props.approvers}
                    orgEmployees={props.orgEmployees}
                />
            ),
        },
        {
            label: `Denied (${props.leaves.filter((l: any) => l.leaveStatus === 'denied').length})`,
            component: (
                <Leaves
                    leaves={props.leaves.filter((l: any) => l.leaveStatus === 'denied')}
                    type="denied"
                    handleDeny={handleDeny}
                    handleEdit={handleEdit}
                    COLORS={COLORS}
                    data={state.leaveTypes.lTypes}
                    approvers={props.approvers}
                    orgEmployees={props.orgEmployees}
                />
            ),
        },
    ];
    return (
        <>
            {/* <MyModal open={openH} setOpen={setOpenH} onModalClosed={setOpenH}>
                <div>
                    <Typography className={classes.modalHeading}>Holidays List</Typography>
                    <HolidayTable
                        year={2022}
                        rows={props.holidays}
                        holidayName="Holiday Name"
                        date="Date(s)"
                        day="Day(s)"
                        actions="Actions"
                        isLoading={false}
                        error={false}
                        errorMessage=""
                    />
                </div>
            </MyModal> */}
            <div className={openH ? classes.modal : classes.modalOff} onClick={() => setOpenH(false)}>
                {/* <div className={classes.outerDivHoliday}> */}
                <div className={classes.holiday}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography className={classes.modalHeading}>Holidays List</Typography>
                        <CloseIcon className={classes.icon} fontSize="small" onClick={() => setOpenH(false)} />
                    </div>
                    <div className={classes.holidayList}>
                        <HolidayTable
                            year={2022}
                            rows={props.holidays}
                            holidayName="Holiday Name"
                            date="Date(s)"
                            day="Day(s)"
                            actions="Actions"
                            isLoading={false}
                            error={false}
                            errorMessage=""
                        />
                    </div>
                </div>
                {/* </div> */}
            </div>
            <MyModal open={open} setOpen={setOpen} onModalClosed={setOpen}>
                <Grid container className={classes.deleteModalContainer}>
                    <Grid item xs={12} sm={12} className={classes.titleLabel}>
                        <FormLabel
                            style={{
                                color: theme.palette.text.primary,
                                fontSize: '22px',
                                zIndex: 0,
                                fontWeight: 700,
                                fontFamily: 'Nunito Sans',
                            }}
                        >
                            Are you sure ?
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} className={classes.deleteContent}>
                        <p className={classes.deleteData}>Are you sure you want to cancel the leave ?</p>
                    </Grid>

                    <Grid item xs={12} sm={12} className={classes.deleteModalButtonsDiv}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.deleteBtn}
                            onClick={() => setOpen(false)}
                            style={{ marginRight: '15px' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.createBtn}
                            onClick={() => handleDelete()}
                            id="confirmbtn"
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </MyModal>
            <MyModal open={openEdit} setOpen={setOpenEdit} onModalClosed={setOpenEdit} title="Edit Time-Off Request">
                <div className={classes2.modalContent}>
                    {formError !== '' ? <p className={classes2.errorMessage}>{formError}</p> : null}
                    <div>
                        <FormLabel className={classes2.formLabelStyle2}>Time-Off Category Type</FormLabel>
                        <RadioGroup
                            row
                            value={leaveFormat}
                            onChange={(e: any) => setLeaveFormat(e.target.value)}
                            className={classes2.radioGroupStyles}
                        >
                            <FormControlLabel
                                value="fullday"
                                control={<Radio color="primary" />}
                                label={<FormLabel className={classes2.radioNameStyle}>Full Day</FormLabel>}
                            />
                            <FormControlLabel
                                value="custom"
                                control={<Radio color="primary" />}
                                label={<FormLabel className={classes2.radioNameStyle}>Custom Time-Off</FormLabel>}
                            />
                        </RadioGroup>
                    </div>
                    {leaveFormat === 'fullday' ? (
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gridColumnGap: 10,
                            }}
                        >
                            <div>
                                <FormLabel className={classes2.formLabelStyle}>From</FormLabel>
                                <DateField date={startDate} setDate={setStartDate} id="startdatefield" />
                            </div>
                            <div>
                                <FormLabel className={classes2.formLabelStyle}>To</FormLabel>
                                <DateField date={endDate} setDate={setEndDate} id="enddatefield" />
                            </div>
                        </div>
                    ) : (
                        <div className={classes2.fieldDivDateTimeSecond}>
                            <div>
                                <FormLabel className={classes2.formLabelStyle}>From</FormLabel>
                                <FormControl className={classes2.formControlTime}>
                                    <DateField date={startDate} setDate={setStartDate} id="startdatefield" />
                                    <TimeField time={startTime} setTime={setStartTime} id="timefield" />
                                </FormControl>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <FormLabel className={classes2.formLabelStyle}>To</FormLabel>
                                <FormControl className={classes2.formControlTime}>
                                    <DateField date={endDate} setDate={setEndDate} id="enddatefield" disable={true} />
                                    <TimeField time={endTime} setTime={setEndTime} id="timefield" />
                                </FormControl>
                            </div>
                        </div>
                    )}
                    <div className={classes2.fieldDiv} style={{ marginTop: 10 }}>
                        <FormLabel className={classes2.formLabelStyle}>Reason</FormLabel>
                        <FormControl variant="outlined" className={classes2.formControl}>
                            <Select
                                value={reasons.includes(reason) ? reason : reasons[3]}
                                className={classes2.inputReason}
                                onChange={(e: any) => setReason(e.target.value as string)}
                            >
                                {reasons.map((r: string, i: number) => (
                                    <MenuItem value={r} key={i}>
                                        {r}
                                    </MenuItem>
                                ))}
                            </Select>
                            {!reasons.slice(0, 3).includes(reason) || reason === 'Custom Reason' ? (
                                <TextField
                                    type="text"
                                    value={customReason}
                                    variant="outlined"
                                    placeholder="Type your reason here"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className={classes2.inputReason}
                                    onChange={(e: any) => setCustomReason(e.target.value)}
                                    size="small"
                                    focused
                                    style={{ marginTop: 10 }}
                                />
                            ) : null}
                        </FormControl>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                        }}
                    >
                        <Button
                            className={classes2.btnOutlinedCancel}
                            variant="outlined"
                            color="primary"
                            disableRipple={true}
                            onClick={() => setOpenEdit(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes2.btnFilledEdit}
                            variant="contained"
                            color="primary"
                            disableRipple={true}
                            onClick={() => editLeave(currentLeave)}
                        >
                            Save Changes
                        </Button>
                    </div>
                </div>
            </MyModal>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <Hidden xsDown>
                <Card className={classes.card}>
                    <div className={classes.header}>
                        <Typography className={classes.heading}>{props.title}</Typography>
                        {props.loading ? (
                            <div className={classes.btnDiv}>
                                <Skeleton width={100} height={35} />
                                <Skeleton width={100} height={35} />
                            </div>
                        ) : (
                            <div className={classes.btnDiv}>
                                <Button
                                    className={classes.btnOutlined}
                                    variant="outlined"
                                    color="primary"
                                    disableRipple={true}
                                    onClick={() => setOpenH(true)}
                                    id="holidayList"
                                >
                                    Holiday List
                                </Button>
                                {/* {hasFeature('add-timeoff') && ( */}
                                <Button
                                    className={classes.btn}
                                    id="apply"
                                    variant="contained"
                                    color="primary"
                                    disableRipple={true}
                                    onClick={() => props.setStep(1)}
                                >
                                    Apply Time-Off
                                </Button>
                                {/* )} */}
                            </div>
                        )}
                    </div>
                    <div style={{ height: '100%' }}>
                        {props.loading ? <Tabs components={loadingComponent} /> : <Tabs components={components} />}
                    </div>
                </Card>
            </Hidden>
            <Hidden smUp>
                {props.loading ? (
                    <div className={classes.mobileBtns}>
                        <div className={classes.btnDiv}>
                            <Skeleton width={150} height={35} />
                            <Skeleton width={150} height={35} />
                        </div>
                    </div>
                ) : (
                    <div className={classes.mobileBtns}>
                        <Button
                            className={classes.btnOutlined}
                            variant="outlined"
                            color="primary"
                            disableRipple={true}
                            onClick={() => setOpenH(true)}
                            id="holidayList"
                        >
                            Holiday List
                        </Button>
                        {/* {hasFeature('add-timeoff') && ( */}
                        <Button
                            className={classes.btn}
                            variant="contained"
                            id="apply"
                            color="primary"
                            disableRipple={true}
                            onClick={() => props.setStep(1)}
                        >
                            Apply Time-Off
                        </Button>
                        {/* )} */}
                    </div>
                )}
                <div className={classes.mobileTabsDiv}>
                    <Typography className={classes.heading}>{props.title}</Typography>
                    <div style={{ height: '100%', backgroundColor: theme.palette.background.paper, padding: 10 }}>
                        {props.loading ? <Tabs components={loadingComponent} /> : <Tabs components={components} />}
                    </div>
                </div>
            </Hidden>
        </>
    );
}
