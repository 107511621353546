import { makeStyles, Theme } from '@material-ui/core/styles';

export const SmallTimePolicyScreenStyles = makeStyles((theme: Theme) => ({
    fontsty: {
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '15px',
        opacity: 0.7,
    },
    notfoundImage: {
        maxWidth: '75%',
        height: '180px',
    },
    holidayLeft: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase',
        color: '#AAAAAA',
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    holidayText: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
    },
    paper: {
        height: 'calc(100vh - 100px)',
        padding: 5,
        marginBottom: '5px',
        fontFamily: theme.typography.fontFamily,
        '@media only screen and (max-width: 800px)': {
            height: 'auto',
            padding: '15px 0',
        },
    },
}));
