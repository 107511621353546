import React, { useState, useContext } from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    MenuItem,
    Hidden,
    Button,
    Popover,
    Paper,
    useTheme,
    Tooltip,
    Avatar,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useStyles, leaveTableStyle } from './employeeProfileStyles';
import { withStyles, Theme } from '@material-ui/core/styles';
import upcomingTimeOff from '../../Assets/images/upcomingTimeoff.png';
// import {
// convertTimeInTZ,
// ToStringFormat,
// } from '../../helpers/date';
import { isoToLocal } from '../../helpers/timezone';
import { Icon } from '@iconify/react';
import AuthContext from '../../context/AuthContext';
import { DisplayDate2, DisplayTime2, Point } from 'components/DashboardPage/DashboardPart';

const StyledTableCell = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: 600,
    },
    body: {
        fontSize: '12px',
    },
}))(TableCell);

const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.info.dark,
    },
    tooltip: {
        backgroundColor: theme.palette.info.dark,
        // color: theme.palette.text.primary,
        color: theme.palette.info.contrastText,
        fontWeight: 500,
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

const CustomTooltip = withStyles({
    tooltip: {
        backgroundColor: '#3D49B6',
        color: 'white',
        fontSize: 11,
        fontFamily: 'Nunito Sans',
    },
})(Tooltip);

interface LeaveHistoryTableInt {
    timeOffDetails?: any;
    tableContent?: any;
    orgEmployees?: any;
    basicDetail?: any;
    timeoff?: any;
}

export default function LeaveHistoryTable(props: LeaveHistoryTableInt) {
    const classes = useStyles();
    const theme = useTheme();
    const classes2 = leaveTableStyle();
    const mycontext = useContext(AuthContext);
    const [openPopover, setOpenPopover] = useState<any>(null);
    const [openMenu1, setOpenMenu1] = useState<any>(null);
    const [openMenu2, setOpenMenu2] = useState<any>(null);
    const [tabTimeoff, setTabTimeoff] = useState<string>('Heat Map');
    const [filterClick, setFilterClick] = React?.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React?.useState<any>(null);
    const [fullYearDates, setFullYearDates] = React?.useState<any>([]);
    const currentDate: any = new Date().toISOString().slice(0, 10);
    const localDate: any = new Date(isoToLocal(currentDate, mycontext.country));

    const leavesData = props.tableContent.filter((tc: any) => tc.leaveStatus !== 'pending');
    const [filterdLeaves, setFilteredLeaves] = useState<any>(leavesData);
    const [HeatMapData, setHeatMapData] = React.useState<any>([]);
    // console.log(props.timeoff);

    const color: any = {
        1: '#12A020',
        2: '#FF7518',
        3: '#E8142E',
        4: '#AFAFAF',
    };

    const heatMapTypes: string[] = ['Available', 'Partial Timeoff', 'Sick Leave', 'Fullday Timeoff'];

    const handlePopoverClick = (event: any) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClose = () => {
        setOpenMenu1(null);
        setOpenMenu2(null);
        setOpenPopover(null);
    };

    const handleOffMenu = (event: any) => {
        setOpenMenu1(event.currentTarget);
    };

    const handleStatusMenu = (event: any) => {
        setOpenMenu2(event.currentTarget);
    };

    const handleClick = (e: any) => {
        setFilterClick(!filterClick);
        setAnchorEl(e?.currentTarget);
    };

    const handleHistory = () => {
        setTabTimeoff('Time-Off History');
        setFilteredLeaves(leavesData);
        setFilterClick(!filterClick);
    };
    const handleUpcoming = () => {
        const upcomingTimeoff: any = props.timeoff?.filter(
            (e: any) =>
                new Date(e.endDate).getTime() >= new Date(localDate).getTime() &&
                e?.employeeId === props.basicDetail?.employeeId,
        );
        setTabTimeoff('Upcoming Time-off');
        setFilteredLeaves(upcomingTimeoff);
        setFilterClick(!filterClick);
    };
    const handleHeatMap = () => {
        setTabTimeoff('Heat Map');
        setFilterClick(!filterClick);
    };

    function getDatesInRange(startDate: any, endDate: any) {
        //console.log(startDate, endDate);
        const date = new Date(startDate.slice(0, 10));
        const dates = [];
        while (date.getTime() <= new Date(endDate.slice(0, 10)).getTime()) {
            dates?.push(new Date(date).toString().slice(0, 15));
            date?.setDate(date?.getDate() + 1);
        }
        //console.log(dates);
        return dates;
    }
    //console.log(props.timeoff);

    React.useMemo(() => {
        function CalculatingHeatGraphData() {
            const now = new Date();
            const startDate = new Date(now.getFullYear(), now.getMonth() + 1, 1).toISOString();
            const endDate = new Date(now.getFullYear() - 1, now.getMonth() + 1, 2).toISOString();
            const arr: any = getDatesInRange(endDate, startDate);
            setFullYearDates(arr);
            const graphData: any = {};
            arr.forEach((e: any, i: number) => (graphData[e] = 0));

            props?.timeoff?.map((e: any) => {
                if (
                    props.basicDetail?.employeeId === e?.employeeId &&
                    e.startTime !== 'null' &&
                    e.category !== 'Sick'
                ) {
                    if (e.noOfHours > mycontext.workingHours) {
                        const timeoffDates = getDatesInRange(e.isoString.startTime, e.isoString.endTime);
                        //console.log(timeoffDates);
                        timeoffDates.map(
                            (e: any) =>
                                (graphData[
                                    isoToLocal(e, mycontext.country)
                                        .toString()
                                        .slice(0, 15)
                                ] = 0.1),
                        );
                    } else {
                        graphData[
                            isoToLocal(e?.isoString?.startTime, mycontext.country)
                                ?.toString()
                                .slice(0, 15)
                        ] = 0.1;
                    }
                } else if (
                    props.basicDetail?.employeeId === e?.employeeId &&
                    e.startTime === 'null' &&
                    e.category !== 'Sick'
                ) {
                    if (e.noOfHours > mycontext.workingHours) {
                        const timeoffDates = getDatesInRange(e.isoString.startTime, e.isoString.endTime);
                        //console.log(timeoffDates);
                        timeoffDates.map(
                            (e: any) =>
                                (graphData[
                                    isoToLocal(e, mycontext.country)
                                        ?.toString()
                                        .slice(0, 15)
                                ] = 0.3),
                        );
                    } else {
                        graphData[
                            isoToLocal(e?.isoString?.startTime, mycontext.country)
                                ?.toString()
                                .slice(0, 15)
                        ] = 0.3;
                    }
                } else if (props.basicDetail?.employeeId === e?.employeeId && e.category === 'Sick') {
                    if (e.noOfHours > mycontext.workingHours) {
                        const timeoffDates = getDatesInRange(e?.isoString?.startTime, e?.isoString?.endTime);
                        //console.log(timeoffDates);
                        timeoffDates.map(
                            (e: any) =>
                                (graphData[
                                    isoToLocal(e, mycontext.country)
                                        .toString()
                                        .slice(0, 15)
                                ] = 0.2),
                        );
                    } else {
                        graphData[
                            isoToLocal(e?.isoString?.startTime, mycontext.country)
                                ?.toString()
                                .slice(0, 15)
                        ] = 0.2;
                    }
                }
                return null;
            });

            setHeatMapData(graphData);
        }
        CalculatingHeatGraphData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, setFullYearDates, mycontext.country, mycontext.workingHours]);

    const handleMenuClose = (event: any, filterType: string) => {
        let filteredData: any = [];
        if (event.target.innerText !== 'All') {
            if (filterType === 'category') {
                filteredData = leavesData.filter((data: any) => data.category === event.target.innerText);
            } else {
                filteredData = leavesData.filter(
                    (data: any) => data.leaveStatus === event.target.innerText.toLowerCase(),
                );
            }
        } else {
            filteredData = leavesData;
        }

        setFilteredLeaves(filteredData);
    };

    return (
        <div style={{}}>
            <Popover
                id="Filter-popover"
                anchorEl={openPopover}
                open={Boolean(openPopover)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem value={'Time-off'} onClick={handleOffMenu} className={classes2.menuitemtext}>
                    <Grid container>
                        <Grid
                            item
                            sm={10}
                            xs={10}
                            id="grid"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                        >
                            Time-off type
                        </Grid>
                        <Grid
                            item
                            sm={2}
                            xs={2}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                        >
                            <span
                                className="iconify"
                                data-icon="eva:arrow-right-fill"
                                style={{ color: '#C5C5C5' }}
                            ></span>
                        </Grid>
                    </Grid>
                </MenuItem>

                <MenuItem value={'Status'} onClick={handleStatusMenu} className={classes2.menuitemtext}>
                    <Grid container>
                        <Grid item sm={10} xs={10} id="grid">
                            Status
                        </Grid>
                        <Grid item sm={2} xs={2}>
                            <span
                                className="iconify"
                                data-icon="eva:arrow-right-fill"
                                style={{ color: '#C5C5C5' }}
                            ></span>
                        </Grid>
                    </Grid>
                </MenuItem>
            </Popover>
            <Popover
                id="Time-off-popover"
                anchorEl={openMenu1}
                open={Boolean(openMenu1)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper style={{ maxHeight: '100px' }}>
                    <MenuItem
                        id="all"
                        value={'All'}
                        onClick={(e: any) => handleMenuClose(e, 'category')}
                        className={classes2.menuitemtext}
                    >
                        All
                    </MenuItem>
                    {props.timeOffDetails.length > 0 &&
                        props.timeOffDetails.map((element: any, index: number) => (
                            <MenuItem
                                key={index}
                                id={element.leaveTypeName}
                                data-my-value={element.leaveTypeName}
                                value={element.leaveTypeName}
                                onClick={(e: any) => handleMenuClose(e, 'category')}
                                className={classes2.menuitemtext}
                            >
                                {element.leaveTypeName}
                            </MenuItem>
                        ))}
                </Paper>
            </Popover>
            <Popover
                id="picker-popover"
                open={filterClick}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleClick}
                data-testid="filterModal"
            >
                <ul className={classes.filterList}>
                    <li onClick={handleHistory} className={classes.filterName}>
                        Time-Off History
                    </li>
                    <li onClick={handleUpcoming} className={classes.filterName}>
                        Upcoming Time-Off
                    </li>
                    <li onClick={handleHeatMap} className={classes.filterName}>
                        Heat-Map
                    </li>
                </ul>
            </Popover>

            <Popover
                id="status-popover"
                anchorEl={openMenu2}
                open={Boolean(openMenu2)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem
                    id="all"
                    value={'All'}
                    onClick={(e: any) => handleMenuClose(e, 'leaveStatus')}
                    className={classes2.menuitemtext}
                >
                    All
                </MenuItem>
                <MenuItem
                    id="approved"
                    value={'approved'}
                    onClick={(e: any) => handleMenuClose(e, 'leaveStatus')}
                    className={classes2.menuitemtext}
                >
                    Approved
                </MenuItem>
                <MenuItem
                    id="denied"
                    value={'denied'}
                    onClick={(e: any) => handleMenuClose(e, 'leaveStatus')}
                    className={classes2.menuitemtext}
                >
                    Denied
                </MenuItem>
            </Popover>

            <Grid container>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Grid item xs={12} className={classes.commonStyleHead1}>
                        <Grid item xs={6} className={classes.commonStyle3}>
                            <Button
                                variant="outlined"
                                onClick={handleClick}
                                className={`${classes2.filterButton}`}
                                endIcon={<ArrowDropDownIcon className={classes.IconSty} />}
                            >
                                {tabTimeoff}
                            </Button>
                            {/* <Typography className={classes.headerTitle2}>Time-Off History</Typography> */}
                        </Grid>
                        <Grid item xs={6} className={classes.commonStyle1} style={{ justifyContent: 'flex-end' }}>
                            {tabTimeoff === 'Time-Off History' && (
                                <Button
                                    variant="outlined"
                                    onClick={handlePopoverClick}
                                    startIcon={<Icon icon="iconoir:filter" />}
                                    endIcon={<ArrowDropDownIcon className={classes.IconSty} />}
                                    className={`${classes2.filterButton}`}
                                    style={{ marginBottom: 5 }}
                                    // style={{ backgroundColor: '#ffffff' }}
                                ></Button>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {tabTimeoff === 'Upcoming Time-off' || tabTimeoff === 'Time-Off History' ? (
                            <Grid item xs={12}>
                                {filterdLeaves?.length > 0 ? (
                                    filterdLeaves?.map((row: any, index: number) => (
                                        <Grid
                                            container
                                            className={classes.gridCommonStyle1}
                                            style={{ padding: '5%' }}
                                            key={index}
                                        >
                                            <Grid item xs={6} className={classes.commonStyle2}>
                                                <Typography className={classes.leaveHistoryCardTitle}>
                                                    Date & Time
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item xs={6} className={classes.commonStyle2}>
                                        <Typography className={classes.leaveHistoryCardTitle}>Time</Typography>
                                    </Grid> */}
                                            <Grid item xs={6} className={classes.commonStyle2}>
                                                {/* {row.startDate === row.endDate ? (
                                            <Typography className={classes2.leaveTableCards}>
                                                {ToStringFormat(row.startDate, mycontext.dateFormat)}
                                            </Typography>
                                        ) : (
                                            <Typography className={classes2.leaveTableCards}>
                                                {ToStringFormat(row.startDate, mycontext.dateFormat)} to{' '}
                                                {ToStringFormat(row.endDate, mycontext.dateFormat)}
                                            </Typography>
                                        )} */}
                                                {parseInt(row.noOfHours) < mycontext.workingHours ? (
                                                    <DisplayTime2
                                                        startTime={isoToLocal(
                                                            row?.isoString?.startTime,
                                                            mycontext.country,
                                                        )
                                                            .toTimeString()
                                                            .split(' ')[0]
                                                            .substring(0, 5)}
                                                        endTime={isoToLocal(row.isoString?.endTime, mycontext.country)
                                                            .toTimeString()
                                                            .split(' ')[0]
                                                            .substring(0, 5)}
                                                        startDate={isoToLocal(
                                                            row.isoString?.startTime,
                                                            mycontext.country,
                                                        )}
                                                        endDate={isoToLocal(row.isoString?.endTime, mycontext.country)}
                                                        dateFormat={mycontext.dateFormat}
                                                        gmt={row.gmt}
                                                    />
                                                ) : (
                                                    <DisplayDate2
                                                        startDate={isoToLocal(
                                                            row?.isoString?.startTime,
                                                            mycontext.country,
                                                        )}
                                                        endDate={isoToLocal(row?.isoString?.endTime, mycontext.country)}
                                                        startTime={isoToLocal(
                                                            row?.isoString?.startTime,
                                                            mycontext.country,
                                                        )
                                                            .toTimeString()
                                                            .split(' ')[0]
                                                            .substring(0, 5)}
                                                        endTime={isoToLocal(row?.isoString?.endTime, mycontext.country)
                                                            .toTimeString()
                                                            .split(' ')[0]
                                                            .substring(0, 5)}
                                                        noOfHours={row?.noOfHours}
                                                        dateFormat={mycontext.dateFormat}
                                                        gmt={row?.gmt}
                                                    />
                                                )}
                                            </Grid>
                                            {/* <Grid item xs={6} className={classes.commonStyle2}>
                                        {row.startTime === 'null' ? (
                                            <Icon icon="bi:dash" width="25" height="25" />
                                        ) : (
                                            <Typography className={classes2.leaveTableCards}>
                                                {convertTimeInTZ(row.startTime, row.gmt)} to{' '}
                                                {convertTimeInTZ(row.endTime, row.gmt)}
                                            </Typography>
                                        )}
                                    </Grid> */}
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.commonStyle2}
                                                style={{ marginTop: 10 }}
                                            >
                                                <Typography className={classes.leaveHistoryCardTitle}>
                                                    Reason
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.commonStyle2}>
                                                {row?.reason?.length > 48 ? (
                                                    <LightTooltip arrow title={row.reason}>
                                                        <Typography className={classes2.leaveTableColumnsReason}>
                                                            {row?.reason}{' '}
                                                        </Typography>
                                                    </LightTooltip>
                                                ) : (
                                                    <Typography className={classes2.leaveTableColumnsReason}>
                                                        {row.reason}{' '}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.commonStyle2}
                                                style={{ marginTop: 10 }}
                                            >
                                                <Typography className={classes.leaveHistoryCardTitle}>
                                                    Approved By
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.commonStyle2}>
                                                <Typography className={classes2.leaveTableCards}>
                                                    {row.approverId
                                                        ? props.orgEmployees.filter(
                                                              (e: any) => e.employeeId === row.approverId,
                                                          )[0]?.Employee.firstName +
                                                          ' ' +
                                                          props.orgEmployees.filter(
                                                              (e: any) => e.employeeId === row.approverId,
                                                          )[0]?.Employee.lastName
                                                        : 'Auto Approved'}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.commonStyle1}
                                                style={{ paddingTop: '5%' }}
                                            >
                                                <Grid item xs={6}>
                                                    <Typography className={classes.leaveHistoryCardTitle}>
                                                        Time-off Type
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography className={classes.leaveHistoryCardTitle}>
                                                        Status
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.commonStyle1}>
                                                <Grid item xs={6}>
                                                    <Typography className={classes2.leaveTableCards}>
                                                        {row.category}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        className={classes2.leaveTableCards}
                                                        style={{ textTransform: 'capitalize' }}
                                                    >
                                                        {row.leaveStatus}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid
                                        container
                                        className={classes.mobileUICardTheme}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <img
                                            src={upcomingTimeOff}
                                            alt="Paper"
                                            style={{ height: '100px', width: '100px' }}
                                        />
                                        <Typography
                                            style={{
                                                fontSize: '13px',
                                                letterSpacing: '0.03em',
                                                color: theme.palette.text.primary,
                                                fontWeight: theme.typography.fontWeightBold,
                                            }}
                                        >
                                            No time-off(s) found.
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <div className={classes.heatMapContainer}>
                                <div className={classes.heatMapDiv2} style={{ flexWrap: 'wrap' }}>
                                    <EmployeeHeatGraph
                                        timeoff={props.timeoff}
                                        workingHours={mycontext.workingHours}
                                        basicDetails={props.basicDetail}
                                        fullYearDates={fullYearDates}
                                        HeatMapData={HeatMapData}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        width: 'calc(100vw-40px)',
                                        justifyContent: 'space-evenly',
                                        marginTop: '5%',
                                    }}
                                >
                                    {heatMapTypes.map((element: string, index: number) => (
                                        <div key={index} style={{ marginTop: '2%' }}>
                                            <Point colors={color[index + 1]} title={element} />
                                        </div>
                                    ))}
                                    {/* <div style={{ marginTop: '2%' }}>
                                        <Point colors={color[2]} title={'Partial Timeoff'} />
                                    </div>
                                    <div style={{ marginRight: '2%' }}>
                                        <Point colors={color[3]} title={'Sick Leave'} />
                                    </div>
                                    <div style={{ marginRight: '2%' }}>
                                        <Point colors={color[4]} title={'Full day Timeoff'} />
                                    </div> */}
                                </div>
                            </div>
                        )}
                    </Grid>
                </Hidden>
            </Grid>
            <Grid container>
                <Hidden only={['xs', 'sm']}>
                    <Grid item xs={12} className={classes.commonStyleHead}>
                        <Grid item xs={6} className={classes.commonStyle3}>
                            <Button
                                variant="outlined"
                                onClick={handleClick}
                                className={`${classes2.filterButton}`}
                                endIcon={<ArrowDropDownIcon className={classes.IconSty} />}
                            >
                                {tabTimeoff}
                            </Button>
                            {/* <Typography className={classes.headerTitle}>Time-Off History</Typography> */}
                        </Grid>
                        <Grid item xs={6} className={classes.commonStyle1} style={{ justifyContent: 'flex-end' }}>
                            {tabTimeoff === 'Time-Off History' && (
                                <Button
                                    variant="outlined"
                                    onClick={handlePopoverClick}
                                    startIcon={<Icon icon="iconoir:filter" />}
                                    endIcon={<ArrowDropDownIcon className={classes.IconSty} />}
                                    className={`${classes2.filterButton}`}
                                    style={{ marginBottom: 5 }}
                                    // style={{ backgroundColor: '#ffffff' }}
                                >
                                    {' '}
                                    Filter by
                                </Button>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.background}>
                        {tabTimeoff === 'Upcoming Time-off' || tabTimeoff === 'Time-Off History' ? (
                            <TableContainer className={classes.tableScrollbar}>
                                <Table stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">#</StyledTableCell>
                                            <StyledTableCell align="justify">Date & Time</StyledTableCell>
                                            {/* <StyledTableCell align="justify">Time</StyledTableCell> */}
                                            <StyledTableCell align="justify">Type</StyledTableCell>
                                            <StyledTableCell align="justify">Reason</StyledTableCell>
                                            <StyledTableCell align="justify">Status</StyledTableCell>
                                            <StyledTableCell align="justify">By</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filterdLeaves?.length > 0 ? (
                                            filterdLeaves?.map((row: any, i: number) => (
                                                <TableRow key={i} className={classes.leaveHistoryTableHover}>
                                                    <StyledTableCell
                                                        className={classes.tableCellStyle}
                                                        align="center"
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {i + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                        {/* <Typography
                                                        component={'div'}
                                                        className={classes2.leaveTableColumns}
                                                    > */}
                                                        {/* {row.startDate === row.endDate ? (
                                                            <Typography className={classes2.leaveTableColumns}>
                                                                {ToStringFormat(row.startDate, mycontext.dateFormat)}
                                                            </Typography>
                                                        ) : (
                                                            <Typography className={classes2.leaveTableColumns}>
                                                                {ToStringFormat(row.startDate, mycontext.dateFormat)}
                                                                &nbsp;
                                                                <span
                                                                    style={{
                                                                        color: theme.palette.text.primary,
                                                                        fontWeight: theme.typography.fontWeightBold,
                                                                    }}
                                                                >
                                                                    to
                                                                </span>
                                                                &nbsp;
                                                                {ToStringFormat(row.endDate, mycontext.dateFormat)}
                                                            </Typography>
                                                        )} */}
                                                        {parseInt(row.noOfHours) < mycontext.workingHours ? (
                                                            <DisplayTime2
                                                                startTime={isoToLocal(
                                                                    row?.isoString?.startTime,
                                                                    mycontext.country,
                                                                )
                                                                    .toTimeString()
                                                                    .split(' ')[0]
                                                                    .substring(0, 5)}
                                                                endTime={isoToLocal(
                                                                    row.isoString?.endTime,
                                                                    mycontext.country,
                                                                )
                                                                    .toTimeString()
                                                                    .split(' ')[0]
                                                                    .substring(0, 5)}
                                                                startDate={isoToLocal(
                                                                    row.isoString?.startTime,
                                                                    mycontext.country,
                                                                )}
                                                                endDate={isoToLocal(
                                                                    row.isoString?.endTime,
                                                                    mycontext.country,
                                                                )}
                                                                dateFormat={mycontext.dateFormat}
                                                                gmt={row.gmt}
                                                            />
                                                        ) : (
                                                            <DisplayDate2
                                                                startDate={isoToLocal(
                                                                    row?.isoString?.startTime,
                                                                    mycontext.country,
                                                                )}
                                                                endDate={isoToLocal(
                                                                    row?.isoString?.endTime,
                                                                    mycontext.country,
                                                                )}
                                                                startTime={isoToLocal(
                                                                    row?.isoString?.startTime,
                                                                    mycontext.country,
                                                                )
                                                                    .toTimeString()
                                                                    .split(' ')[0]
                                                                    .substring(0, 5)}
                                                                endTime={isoToLocal(
                                                                    row?.isoString?.endTime,
                                                                    mycontext.country,
                                                                )
                                                                    .toTimeString()
                                                                    .split(' ')[0]
                                                                    .substring(0, 5)}
                                                                noOfHours={row?.noOfHours}
                                                                dateFormat={mycontext.dateFormat}
                                                                gmt={row?.gmt}
                                                            />
                                                        )}
                                                        {/* </Typography> */}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                    <Typography
                                                        component={'div'}
                                                        className={classes2.leaveTableColumns}
                                                    >
                                                        {row.startTime === 'null' ? (
                                                            <Icon icon="bi:dash" width="25" height="25" />
                                                        ) : (
                                                            <Typography className={classes2.leaveTableColumns}>
                                                                {convertTimeInTZ(row.startTime, row.gmt)}&nbsp;
                                                                <span
                                                                    style={{
                                                                        color: theme.palette.text.primary,
                                                                        fontWeight: theme.typography.fontWeightRegular,
                                                                    }}
                                                                >
                                                                    to
                                                                </span>
                                                                &nbsp;
                                                                {convertTimeInTZ(row.endTime, row.gmt)}&nbsp;
                                                            </Typography>
                                                        )}
                                                    </Typography>
                                                </StyledTableCell> */}
                                                    <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                        <Typography className={classes2.leaveTableColumns}>
                                                            {row.category}
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                        {row?.reason?.length > 28 ? (
                                                            <LightTooltip arrow title={row.reason}>
                                                                <Typography
                                                                    className={classes2.leaveTableColumnsReason}
                                                                >
                                                                    {row.reason}{' '}
                                                                </Typography>
                                                            </LightTooltip>
                                                        ) : (
                                                            <Typography className={classes2.leaveTableColumnsReason}>
                                                                {row.reason}{' '}
                                                            </Typography>
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                        <Typography className={classes2.leaveTableColumns}>
                                                            {row.leaveStatus}
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                        <Typography className={classes2.leaveTableColumns}>
                                                            {row.approverId ? (
                                                                <CustomTooltip
                                                                    title={
                                                                        props.orgEmployees.filter(
                                                                            (e: any) => e.employeeId === row.approverId,
                                                                        )[0]?.Employee.firstName
                                                                    }
                                                                    placement="top"
                                                                    arrow
                                                                >
                                                                    <Avatar
                                                                        src={
                                                                            props.orgEmployees.filter(
                                                                                (e: any) =>
                                                                                    e.employeeId === row.approverId,
                                                                            )[0]?.Employee.profileImage
                                                                        }
                                                                        style={{ height: 30, width: 30 }}
                                                                    />
                                                                </CustomTooltip>
                                                            ) : (
                                                                <CustomTooltip
                                                                    title="Auto approved"
                                                                    placement="top"
                                                                    arrow
                                                                >
                                                                    <Avatar
                                                                        src="https://teamplusimagestorage.blob.core.windows.net/teamplusimagestorage/notification/autoapprove.svg"
                                                                        style={{ height: 30, width: 30 }}
                                                                    />
                                                                </CustomTooltip>
                                                            )}
                                                        </Typography>
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    style={{ textAlign: 'center', borderBottom: 'none' }}
                                                >
                                                    <img
                                                        src={upcomingTimeOff}
                                                        alt="Paper"
                                                        style={{ height: '100px', width: '100px' }}
                                                    />
                                                    <Typography
                                                        style={{
                                                            fontSize: '13px',
                                                            letterSpacing: '0.03em',
                                                            color: theme.palette.text.primary,
                                                            fontWeight: theme.typography.fontWeightBold,
                                                        }}
                                                    >
                                                        No time-off(s) found.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div className={classes.heatMapDiv} style={{ flexWrap: 'wrap' }}>
                                <EmployeeHeatGraph
                                    timeoff={props.timeoff}
                                    workingHours={mycontext.workingHours}
                                    basicDetails={props.basicDetail}
                                    fullYearDates={fullYearDates}
                                    HeatMapData={HeatMapData}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <div style={{ marginRight: '2%' }}>
                                        <Point colors={color[1]} title={'Available'} />
                                    </div>
                                    <div style={{ marginRight: '2%' }}>
                                        <Point colors={color[2]} title={'Partial Timeoff'} />
                                    </div>
                                    <div style={{ marginRight: '2%' }}>
                                        <Point colors={color[3]} title={'Sick Leave'} />
                                    </div>
                                    <div style={{ marginRight: '2%' }}>
                                        <Point colors={color[4]} title={'Full day Timeoff'} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
}

export function EmployeeHeatGraph(props: {
    timeoff: any;
    workingHours: any;
    basicDetails: any;
    mycontext?: any;
    fullYearDates?: any;
    HeatMapData: any;
}) {
    //console.log(props);
    const classes = useStyles();

    const color: any = {
        0.0: '#12A020',
        0.1: '#FF7518',
        0.2: '#E8142E',
        0.3: '#AFAFAF',
    };

    const Months: any = [
        'January',
        'Feburary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    function removeDuplicates(arr: any) {
        return arr.filter((item: any, index: number) => arr.indexOf(item) === index);
    }
    const MonthNum: any = removeDuplicates(props.fullYearDates?.map((e: any) => new Date(e).getMonth()));

    const Pixel = (props: { colorPercentage: any; index: any; fullYearDates: any }) => {
        const classes = useStyles();
        return (
            <Tooltip key={props.index} title={props.fullYearDates[props.index]} arrow placement="top">
                <div
                    style={{ backgroundColor: `${color[props.colorPercentage]}`, border: '1px solid white' }}
                    className={classes.pixels}
                ></div>
            </Tooltip>
        );
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className={classes.monthName}>
                {MonthNum.map((e: any, index: any) => (
                    <div className={classes.monthStyle} key={index}>
                        {Months[e].slice(0, 3)}
                    </div>
                ))}
            </div>
            <div className={classes.pixelDiv}>
                {Object.values(props.HeatMapData).map((e: any, index: number) => (
                    <div key={index}>
                        <Pixel colorPercentage={e} index={index} fullYearDates={props.fullYearDates} />
                    </div>
                ))}
            </div>
        </div>
    );
}
