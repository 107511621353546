import React from 'react';
import useStyles from './style';
import { Link } from 'react-router-dom';
import { Hidden } from '@material-ui/core';

interface ExperienceProps {
    img?: any;
    title?: string;
    subtitle?: string;
    btnText?: string;
    spam?: string;
}
export default function Experience(props: ExperienceProps) {
    const classes = useStyles();
    return (
        <>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <div className={classes.experience}>
                    <img src={props.img} alt="" className={classes.experienceImg} />
                    <div className={classes.experienceContent}>
                        <p className={classes.expHeading}>
                            {props.title}
                            <br></br>
                            <span>{props.spam}</span>
                        </p>
                        <p className={classes.expSubtitle}>{props.subtitle}</p>
                        <button className={classes.expBtn}>
                            <Link
                                to={props.btnText === 'TeamPlus Admin Experience' ? '/admin' : 'employee'}
                                style={{ textDecoration: 'none', color: '#3C48B5' }}
                            >
                                {props.btnText}
                            </Link>
                        </button>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['xl']}>
                <div className={classes.experience}>
                    <p className={classes.expHeading}>
                        {props.title}
                        <br></br>
                        <span>{props.spam}</span>
                    </p>
                    <div className={classes.experienceContent}>
                        <img src={props.img} alt="" className={classes.experienceImg} />
                        <p className={classes.expSubtitle}>{props.subtitle}</p>
                        <button className={classes.expBtn}>
                            <Link
                                to={props.btnText === 'TeamPlus Admin Experience' ? '/admin' : 'employee'}
                                style={{ textDecoration: 'none', color: '#3C48B5' }}
                            >
                                {props.btnText}
                            </Link>
                        </button>
                    </div>
                </div>
            </Hidden>
        </>
    );
}
