import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    // TextField,
    // InputAdornment,
    // MenuItem,
    Hidden,
    // useTheme,
    // Button,
    // Popover,
    // Paper,
    useTheme,
    Tooltip,
    // Avatar,
} from '@material-ui/core';
import { useStyles } from '../pollProfileStyles';
import { withStyles, Theme } from '@material-ui/core/styles';
import upcomingTimeOff from '../../../Assets/images/upcomingTimeoff.png';
import { useHistory } from 'react-router-dom';
import AuthContext from 'context/AuthContext';
import { isoToLocal } from '../../../helpers/timezone';
import { ToStringFormat } from '../../../helpers/date';

const StyledTableCell = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: 600,
    },
    body: {
        fontSize: '12px',
    },
}))(TableCell);

const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.info.dark,
    },
    tooltip: {
        backgroundColor: theme.palette.info.dark,
        // color: theme.palette.text.primary,
        color: theme.palette.info.contrastText,
        fontWeight: 500,
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

interface PollHistoryTable1 {
    polls?: any;
    userId?: any;
}
export default function OtherEmpPollsHistory(props: PollHistoryTable1) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const mycontext = useContext(AuthContext);
    const pollsData = useSelector((state: any) => state.polls);
    // const currTime = new Date().toString();
    // const a = currTime.split(' ');

    const handleClick = (d: any) => {
        const e = pollsData.polls.filter((p: any) => p.pollId === d.pollId);
        if (new Date() > new Date(`${d.createdOn} ${d.pollEndTime}`)) {
            mycontext.updatePollResult(true);
        } else {
            mycontext.updatePollResult(false);
        }
        history.push({
            pathname: '/dashboard',
            state: {
                poll: e[0],
                val: '1',
            },
        });
    };

    return (
        <div style={{}}>
            <Hidden only={['xs', 'sm']}>
                <div style={{ height: '55vh', margin: '1%', marginTop: '5%' }}>
                    <TableContainer className={classes.tableScrollbar}>
                        <Table stickyHeader aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">#</StyledTableCell>
                                    <StyledTableCell align="justify">Question</StyledTableCell>
                                    <StyledTableCell align="justify">Created On</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.polls?.length > 0 ? (
                                    props.polls?.map((row: any, i: number) => (
                                        <TableRow
                                            key={i}
                                            className={classes.leaveHistoryTableHover}
                                            onClick={() => handleClick(row)}
                                        >
                                            <StyledTableCell
                                                // className={classes.tableCellStyle}
                                                align="center"
                                                component="th"
                                                scope="row"
                                            >
                                                {i + 1}
                                            </StyledTableCell>
                                            <StyledTableCell align="justify">
                                                {row?.question?.length > 80 ? (
                                                    <LightTooltip arrow title={row.question}>
                                                        <Typography className={classes.leaveTableColumnsReason}>
                                                            {row.question}{' '}
                                                        </Typography>
                                                    </LightTooltip>
                                                ) : (
                                                    <Typography className={classes.leaveTableColumns}>
                                                        {row.question}{' '}
                                                    </Typography>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="justify">
                                                <Typography className={classes.leaveTableColumns}>
                                                    {ToStringFormat(
                                                        isoToLocal(row?.pollEndsDateTime, mycontext.country)
                                                            .toString()
                                                            .substring(3, 15),
                                                        mycontext.dateFormat,
                                                    )}
                                                </Typography>
                                            </StyledTableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={6}
                                            style={{
                                                textAlign: 'center',
                                                borderBottom: 'none',
                                                backgroundColor: theme.palette.background.paper,
                                            }}
                                        >
                                            <img
                                                src={upcomingTimeOff}
                                                alt="Paper"
                                                style={{ height: '100px', width: '100px' }}
                                            />
                                            <Typography
                                                style={{
                                                    fontSize: '13px',
                                                    letterSpacing: '0.03em',
                                                    color: theme.palette.text.primary,
                                                    fontWeight: theme.typography.fontWeightBold,
                                                }}
                                            >
                                                No poll(s) found.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Hidden>

            <Hidden only={['md', 'lg', 'xl']}>
                {props.polls?.length > 0 ? (
                    props.polls?.map((row: any, index: number) => (
                        <Grid container className={classes.gridCommonStyle1} style={{ padding: '5%' }} key={index}>
                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                <Typography className={classes.pollHistoryCardTitle}>Question</Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                // className={classes.commonStyle2}
                            >
                                {row?.question?.length > 48 ? (
                                    <LightTooltip arrow title={row.reason}>
                                        <Typography className={classes.pollTableColumnsReason}>
                                            {row?.question}{' '}
                                        </Typography>
                                    </LightTooltip>
                                ) : (
                                    <Typography className={classes.pollTableColumnsReason}>{row?.question} </Typography>
                                )}
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                <Typography className={classes.pollHistoryCardTitle}>Created On</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                // className={classes.commonStyle1}
                            >
                                <Typography className={classes.pollTableCards}>
                                    {ToStringFormat(
                                        isoToLocal(row?.pollEndsDateTime, mycontext.country)
                                            .toString()
                                            .substring(3, 15),
                                        mycontext.dateFormat,
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <Grid
                        container
                        // className={classes.mobileUICardTheme}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: theme.palette.background.paper,
                        }}
                    >
                        <img src={upcomingTimeOff} alt="Paper" style={{ height: '100px', width: '100px' }} />
                        <Typography
                            style={{
                                fontSize: '13px',
                                letterSpacing: '0.03em',
                                color: theme.palette.text.primary,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            No poll(s) found.
                        </Typography>
                    </Grid>
                )}
            </Hidden>
        </div>
    );
}
