import { makeStyles, Theme } from '@material-ui/core/styles';

export const SubscriptionDetailsStyle = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
    },
    tableContainer: {
        maxHeight: 'calc(100vh - 300px)',
        backgroundColor: theme.palette.background.paper,
        '&::-webkit-scrollbar': {
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.background.paper,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },

    table: {
        maxHeight: 'calc(100vh - 200px)',
        width: '100%',
    },
    row: {
        border: 'none',
        '&:hover': {
            boxShadow: `0 4px 10px 0 ${theme.palette.background.default}`,
            // color: theme.palette.primary.main,fedit
            border: 'none',
            outline: 'none',
            borderRadius: 5,
            cursor: 'pointer',
        },
    },
    manageSubscriptionButton: {
        height: 35,
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        // borderRadius: '5px',
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    cellNameOne: {
        padding: 'none',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        textTransform: 'none',
        borderBottom: 'none',
        textAlign: 'left',
        color: theme.palette.text.primary,
    },
    cellName: {
        padding: 'none',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        textTransform: 'none',
        borderBottom: 'none',
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    columnOne: {
        color: '#262F83',
        backgroundColor: '#FFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '16px',
        textAlign: 'left',
        border: 'none',
    },
    column: {
        color: '#262F83',
        backgroundColor: '#DCDFFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '16px',
        textAlign: 'center',
    },
    detailsContainerOne: {
        backgroundColor: 'white',
        padding: '1%',
        marginTop: '2%',
    },
    detailsContainerTwo: {
        padding: '2%',
    },
    screenSize: {
        display: 'none',
        '@media screen and (max-width: 767px)': {
            display: 'block',
        },
    },
    btnFixed: {
        height: 40,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        textTransform: 'capitalize',
        padding: '0 50px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        marginRight: 10,
        borderRadius: 0,
        zIndex: 99,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    columnname: {
        color: '#262F83',
        backgroundColor: '#DCDFFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '16px',
        textAlign: 'left',
    },
    subsLeft: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase',
        color: '#AAAAAA',
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
    },
    subsText: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
    },
    editIcon: {
        // marginLeft: '10%',
        color: theme.palette.primary.contrastText,
        '&:active, &:focus, &:hover': {
            color: '#3C48B5',
            border: 'none',
            outline: 'none !important',
        },
    },
    manageBtn: {
        backgroundColor: '#fff',
        color: theme.palette.warning.main,
        width: '100%',
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'capitalize',
        '&:hover': {
            color: theme.palette.warning.main,
            backgroundColor: '#fff',
        },
        '&:focus': {
            color: theme.palette.warning.main,
            backgroundColor: '#fff',
            outline: 'none',
        },
        zIndex: 0,
    },
    invoiceBtn: {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        width: '100%',
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'capitalize',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
        },
        '&:focus': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
            outline: 'none',
        },
        zIndex: 0,
    },
}));
