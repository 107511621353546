import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        border: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            '@media only screen and (min-width:900px)': {
                padding: '30px 10px 20px 30px',
                position: 'relative',
                height: 'calc(100vh - 130px)',
                background: theme.palette.background.paper,
            },
            '@media only screen and (max-width:900px)': {
                marginBottom: 40,
            },
        },

        drawerOpen: {
            flex: 0.5,
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.background.paper,
            transition: '0.5s',
            borderLeft: '2px solid #DDDDDD',
            margin: '-20px 0px -20px 0px',
        },
        drawerClose: {
            display: 'none',
            transition: '0.5s',
        },
        leftFull: {
            flex: 1,
            dispaly: 'flex',
            flexDirection: 'column',
            // background: theme.palette.background.paper
        },
        leftHalf: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            // background: theme.palette.background.paper,
        },
        btn: {
            position: 'relative',
            height: 35,
            width: 170,
            textTransform: 'none',
            // padding: '0 50px',
            fontSize: 15,
            marginRight: 20,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.background.paper,
                border: `1px solid #AAAAAA`,
                outline: 'none',
            },
        },
        auto: {
            backgroundColor: theme.palette.background.paper,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            // height: "30px"
        },
        pagination1: {
            marginLeft: '70%',
        },

        pagination2: {
            position: 'absolute',
            right: 0,
        },
        textFieldScroll: {
            overflowY: 'scroll',
            height: '43px',
            outline: 'blue',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#D9DBE5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        scrollbar: {
            maxHeight: '135px',
            overflowY: 'auto',
            // margin: '0px 5px',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#D9DBE5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        filterBtn: {
            position: 'relative',
            marginLeft: 15,
            width: 167,
            '&:focus': {
                outline: 'none',
            },
            zIndex: 9,
            '@media only screen and (max-width:1200px)': {
                // position: 'fixed',
                width: '100%',
                marginLeft: 0,
            },
        },
        clear: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700,
            fontSize: '14px',
            // lineHeight: '24px',
            color: '#3C48B5',
            width: '100%',
            textDecoration: 'underline',
            // paddingTop: '5px',
            marginLeft: 4,
            '@media only screen and (max-width:1200px)': {
                display: 'flex',
                alignSelf: 'flex-end',
                width: 'auto',
                right: '30%',
                marginBottom: 58,
                // marginLeft: 4,
                paddingLeft: '10px',
            },
        },
        notFoundImage: {
            maxWidth: '35px',
            height: '35px',
            marginLeft: '8%',
        },

        notFoundText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 16,
            fontWeight: 600,
        },

        add: {
            display: 'none',
            textAlign: 'center',
            textTransform: 'capitalize',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '@media only screen and (max-width:899px)': {
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: 42,
                right: 0,
                top: 'calc(100vh - 43px)',
                borderRadius: 0,
            },
        },
        minus: {
            display: 'flex',
            textAlign: 'center',
            width: 150,
            height: '35px',
            textTransform: 'capitalize',
            marginRight: 20,
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        main: {
            display: 'none',
            '@media only screen and (max-width: 899px)': {
                display: 'flex',
                justifyContent: 'flex-end',
            },
        },
        filter: {
            '@media only screen and (max-width:899px)': {
                width: '129px',
                hight: '30px',
                fontSize: 15,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightBold,
                backgroundColor: theme.palette.background.paper,
                border: '1px solid #888888',
                '&:focus': {
                    outline: 'none',
                },
                '&:hover': {
                    backgroundColor: theme.palette.background.paper,
                    border: '1px solid #888888',
                    outline: 'none',
                },
            },
        },
        btn3: {
            display: 'none',
            textAlign: 'center',
            textTransform: 'capitalize',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '@media only screen and (max-width:899px)': {
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: 42,
                right: 0,
                top: 'calc(100vh - 43px)',
                borderRadius: 0,
            },
        },
        tableBorder: {
            position: 'relative',
            width: '98%',
            left: '1%',
            top: 27,
            height: 'calc(100vh - 238px)',
            '&::-webkit-scrollbar': {
                width: 4,
                marginTop: 40,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        cell: {
            background: '#DCDFFF',
            fontSize: 14,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            color: '#262F83',
            border: 'none',
        },
        cell2: {
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            border: 'none',
        },
        scroll: {
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 3,
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        cell1: {
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            border: 'none',
        },
        avatarButton: {
            top: -2,
            left: -30,
            fontWeight: 600,
            background: 'transperent',
            fontSize: '13px',
            letterSpacing: '0.02em',
            whiteSpace: 'nowrap',
            fontFamily: theme.typography.fontFamily,
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (min-width: 350px) and (max-width: 820px)': {
                bottom: '2px',
                left: '0px',
            },
            '@media screen and (min-width: 600px)': {
                fontSize: '15px',
            },
        },
        text: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700,
            fontSize: '14px',
            color: '#3C48B5',
            paddingTop: '5px',
            '@media only screen and (min-width:1300px)': {
                paddingLeft: '10px',
            },
        },
        boxes: {
            display: 'flex',
            justifyContent: 'space-around',
            paddingBottom: '30px',
        },
        text2: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            lineHeight: '21.82px',
            marginTop: '2px',
            // color: theme.palette.common.white,
            '@media only screen and (min-width:900px)': {
                paddingLeft: '10px',
            },
        },
        notfoundImage: {
            maxWidth: '75%',
            height: '180px',
            justifyContent: 'center',
        },
        notfoundText: {
            fontFamily: theme.typography.fontFamily,
            display: 'flex',
            justifyContent: 'center',
            // marginLeft: "43%",
            color: theme.palette.text.primary,
            fontSize: 16,
            fontWeight: 600,
        },
        boxes2: {
            width: 207,
            '& input': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 'normal',
                fontWeight: theme.typography.fontWeightBold,
                borderRadius: '2px',
                letterSpacing: '0.03',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '& .MuiAutocomplete-inputRoot': {
                flexWrap: 'nowrap',
                overflowX: 'scroll',
                '&::-webkit-scrollbar': {
                    width: 0,
                    height: 0,
                },
            },
        },

        shadow: {
            '&:hover': {
                boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
                color: theme.palette.primary.main,
                border: 'none',
                outline: 'none',
                borderRadius: 5,
                // cursor: 'pointer',
                backgroundColor: theme.palette.info.main,
            },
        },
        avatar: {
            height: '30px',
            width: '30px',
            fontSize: '12px',
            backgroundColor: '#EDEFFA',
            color: '#444444',
        },
        scroll0: {
            maxHeight: 'calc(100vh - 150px)',
            '@media only screen and (max-width:900px)': {
                maxHeight: 'calc(100vh - 160px)',
            },
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#D9DBE5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        scroll1: {
            maxHeight: '120px',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        button1: {
            height: 35,
            position: 'fixed',
            width: '50%',
            bottom: '0px',
            textTransform: 'capitalize',
            borderRadius: '0px',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        button2: {
            left: '1px',
            height: 35,
            position: 'fixed',
            width: '50%',
            fontSize: '16px',
            fontWeight: 700,
            bottom: '0px',
            borderRadius: '0px',
            textTransform: 'capitalize',
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '&:active,&:hover': {
                outline: 'none',
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        text3: {
            position: 'relative',
            letterSpacing: '0.02em',
            fontSize: '15px',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
        },
        text1: {
            position: 'relative',
            letterSpacing: '0.02em',
            fontSize: '15px',
            fontFamily: theme.typography.fontFamily,
            '@media only screen and (min-width:900px)': {
                padding: '4px 0px 16px 0px',
            },
        },
        name: {
            position: 'relative',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            paddingLeft: '10px',
            paddingRight: '10px',
            top: '10px',
            fontStyle: 'normal',
            fontFamily: theme.typography.fontFamily,
            letterSpacing: '0.02em',
        },
        filterList2: {
            maxHeight: 175,
            width: 168,
        },
        filterList: {
            maxHeight: 160,
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        filterName2: {
            padding: '2px 10px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
        },
        time: {
            position: 'relative',
            paddingLeft: '5px',
            paddingRight: '5px',
            fontWeight: 600,
            top: '12px',
            letterSpacing: '0.02em',
            fontFamily: 'Nunito Sans',
            color: '#00000080',
        },
        tooltip: {
            fontSize: 11,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        inputScroll2: {
            overflowX: 'auto',
            border: '1px solid',
            height: 35,
            borderRadius: 5,
            '&::-webkit-scrollbar': {
                width: 0,
                height: 0,
            },
        },
        fontsty: {
            textAlign: 'center',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            alignItems: 'center',
            opacity: 0.7,
            paddingTop: 50,
        },
        outline: {
            '& .MuiSvgIcon-root': {
                width: 26,
                height: 26,
            },
            '&:hover': {
                outline: 'none',
            },
            '&:active': {
                outline: 'none',
            },
        },
        inputTextField: {
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        icon: {
            position: 'absolute',
            top: 18,
            right: 18,
            cursor: 'pointer',
            color: theme.palette.text.primary,
        },
        apply: {
            '@media only screen and (max-width:899px)': {
                // position: 'fixed',
                display: 'flex',
                width: '100%',
                height: 42,
                right: 0,
                // top: 'calc(100vh - 43px)',
                borderRadius: 0,
                textAlign: 'center',
                textTransform: 'capitalize',
                fontSize: 14,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                '&:focus': {
                    outline: 'none',
                },
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
        disable: {
            opacity: 0.5,
            '& .MuiSvgIcon-root': {
                width: 26,
                height: 26,
            },
        },
        grid: {
            display: 'flex',
            width: '100%',
            // height: 35,
            '@media only screen and (max-width: 1200px)': {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                width: '65%',
                gap: 10,
            },
        },
        grid2: {
            display: 'flex',
            width: '100%',
            // height: 35,
            '@media only screen and (max-width: 1200px)': {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                width: '43%',
                gap: 10,
            },
        },
        filterIcon: {
            '@media only screen and (max-width:1300px) and (min-width: 1200px)': {
                marginLeft: 10,
            },
        },
        test: {
            height: 37,
            '@media only screen and (max-width: 900px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
                width: 'auto',
            },
            display: 'flex',
            width: '150px',
            borderRadius: '4px',
            alignItems: 'center',
        },
        filterName: {
            padding: '2px 10px',
            width: '200px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            // color: theme.palette.text.primary,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.main,
                border: 'none',
                outline: 'none',
                color: '#fff',
                '& a': {
                    color: '#fff',
                },
            },
        },
        standup: {
            position: 'relative',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '17px',
            paddingLeft: 8,
            color: theme.palette.text.primary,
            letterSpacing: '0.9px',
        },
        backArrow: {
            cursor: 'pointer',
            color: theme.palette.text.primary,
        },
        link: {
            color: theme.palette.text.primary,
            '&:hover': {
                textDecoration: 'none',
                color: theme.palette.text.primary,
            },
        },
        filterDate: {
            padding: '2px 10px',
            width: '200px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
        },
    }),
);

export default useStyles;
