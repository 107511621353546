import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    basicSelfProfile,
    newSelfEmployeeTeams,
    newEmpTotalHours,
    newOrgLeaveTypes,
    newTimeOffHistory,
} from '../../helpers/hasuraapi';

export const selfProfileData: any = createAsyncThunk('selfProfile/getSelfProfile', async (obj: any) => {
    const data: any = await basicSelfProfile(obj.organizationId, obj.employeeId, obj.token);
    const jsonData = await data.json();
    return jsonData.data.usermanagementdb_organization_employee[0];
});

export const selfEmployeeTeams: any = createAsyncThunk('selfProfile/getSelfEmpTeams', async (obj: any) => {
    const data: any = await newSelfEmployeeTeams(obj.organizationId, obj.employeeId, obj.token);
    const jsonData = await data.json();
    const teamsEmployee = jsonData.data.usermanagementdb_Team;
    let employeeTeams: any = {
        approvers: [],
        employees: [],
    };
    if (teamsEmployee.length > 0) {
        teamsEmployee.forEach((item: any) => {
            if (item.team_employees[0].isApprover) {
                employeeTeams.approvers.push({
                    teamId: item.teamId,
                    teamName: item.teamName,
                    isApprover: item.team_employees[0].isApprover,
                });
            } else {
                employeeTeams.employees.push({
                    teamId: item.teamId,
                    teamName: item.teamName,
                    isApprover: item.team_employees[0].isApprover,
                });
            }
            return employeeTeams;
        });
    } else {
        employeeTeams = [];
    }
    return employeeTeams;
});

export const selfEmployeeTotalHours: any = createAsyncThunk('selfProfile/getSelfEmpTotHours', async (obj: any) => {
    const data: any = await newEmpTotalHours(obj.organizationId, obj.employeeId, obj.token);
    const jsonData = await data.json();
    return jsonData.data.timeoffdb_get_totalhours;
});

export const selfOrgLeaveTypes: any = createAsyncThunk('selfProfile/getSelfOrgLeaveTypes', async (obj: any) => {
    const data: any = await newOrgLeaveTypes(obj.organizationId, obj.token);
    const jsonData = await data.json();
    return jsonData.data.timeoffdb_LeaveType;
});

export const selfTimeOffHistory: any = createAsyncThunk('selfProfile/getSelfTimeOffHistory', async (obj: any) => {
    const data: any = await newTimeOffHistory(obj.organizationId, obj.employeeId, obj.date, obj.token);
    const jsonData = await data.json();
    return jsonData.data.timeoffdb_Leaves;
});

export const selfProfileSlice = createSlice({
    name: 'selfProfile',
    initialState: {
        basicProfile: {},
        selfEmpTeams: {},
        selfEmpTotHr: [],
        selfOrgLeaves: [],
        selfTimeOffHist: [],
        selectedValue: '',
        selfProfileDataStatus: 'loading',
        selfEmpTeamsStatus: 'loading',
        selfEmpTotHrStatus: 'loading',
        selfOrgLeaveStatus: 'loading',
        selfTimeOffHistoryStatus: 'loading',
    },
    reducers: {
        updateImage: (state: any, action: any) => {
            state.basicProfile.Employee.profileImage = action.payload.image;
        },
        updateName: (state: any, action: any) => {
            state.basicProfile.Employee.firstName = action.payload.firstName;
            state.basicProfile.Employee.lastName = action.payload.lastName;
        },
        setSelectedValue: (state: any, action: any) => {
            state.selectedValue = action.payload.value;
        },
    },
    extraReducers: {
        [selfProfileData.pending]: state => {
            state.selfProfileDataStatus = 'loading';
        },
        [selfProfileData.fulfilled]: (state: any, action: any) => {
            state.basicProfile = action.payload;
            state.selfProfileDataStatus = 'success';
        },
        [selfProfileData.rejected]: state => {
            state.selfProfileDataStatus = 'failed';
        },
        [selfEmployeeTeams.pending]: state => {
            state.selfEmpTeamsStatus = 'loading';
        },
        [selfEmployeeTeams.fulfilled]: (state: any, action: any) => {
            state.selfEmpTeams = action.payload;
            state.selfEmpTeamsStatus = 'success';
        },
        [selfEmployeeTeams.rejected]: state => {
            state.selfEmpTeamsStatus = 'failed';
        },
        [selfEmployeeTotalHours.pending]: state => {
            state.selfEmpTotHrStatus = 'loading';
        },
        [selfEmployeeTotalHours.fulfilled]: (state: any, action: any) => {
            state.selfEmpTotHr = action.payload;
            state.selfEmpTotHrStatus = 'success';
        },
        [selfEmployeeTotalHours.rejected]: state => {
            state.selfEmpTotHrStatus = 'failed';
        },
        [selfOrgLeaveTypes.pending]: state => {
            state.selfOrgLeaveStatus = 'loading';
        },
        [selfOrgLeaveTypes.fulfilled]: (state: any, action: any) => {
            state.selfOrgLeaves = action.payload;
            state.selectedValue = action.payload[0]?.leaveTypeName;
            state.selfOrgLeaveStatus = 'success';
        },
        [selfOrgLeaveTypes.rejected]: state => {
            state.selfOrgLeaveStatus = 'failed';
        },
        [selfTimeOffHistory.pending]: state => {
            state.selfTimeOffHistoryStatus = 'loading';
        },
        [selfTimeOffHistory.fulfilled]: (state: any, action: any) => {
            state.selfTimeOffHist = action.payload;
            state.selfTimeOffHistoryStatus = 'success';
        },
        [selfTimeOffHistory.rejected]: state => {
            state.selfTimeOffHistoryStatus = 'failed';
        },
    },
});

export const { updateImage, updateName, setSelectedValue } = selfProfileSlice.actions;
export default selfProfileSlice.reducer;
