/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Paper,
    Grid,
    Hidden,
    Typography,
    TextField,
    InputBase,
    InputAdornment,
    Popover,
    MenuItem,
    IconButton,
    Tabs,
    Tab,
    useTheme,
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
} from '@material-ui/core';
import EmployeeTable from './EmployeeTable';
import useStyles from './TeammemberStyles';
import ErrorPage from '../Error/ErrorPage';
import AuthContext from '../../context/AuthContext';
// import { useHistory } from 'react-router-dom';
// import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
// import Arrow from '../../Assets/images/leftArrow.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InviteStatus from './InviteStatus';
import EmailInvite from './EmailInvite';
import Addtoslack from '../AddToSlack/AddToSlack';
import BulkInvite from './BulkInvite';
import { PopUp } from '../Modal/Modal';
import Loader from 'components/Loader/loader';
import { updateActiveTab } from '../Redux/activeTab';
import {
    filterForAll,
    filterForAdmin,
    filterForApprovers,
    filterForEmployees,
    // inviteStatusSubscription,
} from '../Redux/employeeListReducer';
import {
    getOrganizationEmployees,
    getOrganizationTeams,
    insertTeamEmployee,
    createOrganizationRole1,
} from '../../helpers/hasuraapi';
import Skeleton from '@material-ui/lab/Skeleton';
// import { withStyles } from '@material-ui/core/styles';
import { useFlagsmith } from 'flagsmith-react';
import FilterLogo from '../Employees/FilterLogo.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Icon } from '@iconify/react';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import jwtDecode from 'jwt-decode';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { membersofTeam, MembersofChannel } from '../Admin/graph';
// import { getEmployeesList, getInviteStatus } from '../Redux/employeeListReducer';
// import { fetchTeams } from '../Redux/teamsReducer';
export default function EmployeesPage(props: any) {
    const [search, setSearch] = React.useState('');
    // const [teamEmployees, setTeamEmployees] = useState<any>([]);
    // const [filteredTeamMembers, setFilteredTeamMembers] = useState<any>([]);
    // const [loading, setLoading] = useState<boolean>(false);
    const mycontext = useContext(AuthContext);
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { hasFeature } = useFlagsmith();
    const teamEmployees = useSelector((state: any) => state.employeesList);

    // const [error, setError] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
    // const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
    // const [sessionExpired, setSessionExpired] = useState<boolean>(false);

    const [filterOn, setFilterOn] = React.useState<string>('All');
    const [inviteType, setInviteType] = React.useState<number>(0);
    // const history = useHistory();
    // const [errorMessage, setErrorMessage] = React.useState<any>([]);

    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertm, setAlertm] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [isInvite, setIsInvite] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const loginRequest = {
        scopes: [
            'User.ReadBasic.All',
            'Group.Read.All',
            'ChannelMember.Read.All',
            'Team.ReadBasic.All',
            'Directory.Read.All',
        ],
    };
    const loginrequest = {
        ...loginRequest,
        account: accounts[0],
    };
    useEffect(() => {
        async function getData() {
            dispatch(updateActiveTab('/employees'));
            // if (props.employee?.data) {
            //     dispatch(getEmployeesList(props.employee?.data));
            //     dispatch(getInviteStatus(props.inviteStatus?.data));
            //     dispatch(fetchTeams(props.teams?.data));
            // }
        }
        getData();
    }, []);

    const filterB = (array1: any, array2: any) => {
        return array1.filter((object1: any) => {
            return !array2.some((object2: any) => {
                return object1.id === object2.employeeId;
            });
        });
    };

    const syncMsEmps = async (accessToken: any) => {
        setLoading(true);
        const orgUsers: any = await getOrganizationEmployees(mycontext.organizationId, mycontext.token);
        const jsonData = await orgUsers.json();
        const users = await membersofTeam(accessToken, mycontext.organizationId);
        if (jsonData?.errors) {
            setLoading(false);
            setSeverity('error');
            setAlertm(true);
            setAlertMessage('Something went wrong!');
        } else {
            const filteredEmps: any = filterB(users, jsonData?.data?.usermanagementdb_organization_employee);
            if (filteredEmps?.length === 0) {
                setLoading(false);
                setSeverity('success');
                setAlertm(true);
                setAlertMessage('Everything is up to Date');
            } else {
                try {
                    await Promise.all(
                        filteredEmps?.map(async (user: any) => {
                            users.map(async (val: any) => {
                                if (val.id === user.id) {
                                    const empRole = {
                                        organizationId: mycontext.organizationId,
                                        role: 'employee',
                                        token: mycontext.token,
                                        location: mycontext.country,
                                        employeeId: user.id,
                                    };
                                    await createOrganizationRole1(empRole);
                                }
                            });
                            const teams = await getOrganizationTeams(mycontext.organizationId, mycontext.token);
                            teams.map(async (val1: any) => {
                                const mem = await MembersofChannel(
                                    accessToken,
                                    mycontext?.organizationId,
                                    val1?.msChannelId,
                                );
                                mem.map(async (val: any) => {
                                    if (val.userId === user.id) {
                                        const teamUser = {
                                            employeeId: user.id,
                                            isApprover: false,
                                            teamId: val1.teamId,
                                            token: mycontext.token,
                                        };
                                        await insertTeamEmployee(teamUser);
                                    }
                                });
                            });
                        }),
                    );

                    setLoading(false);
                    setSeverity('success');
                    setAlertm(true);
                    setAlertMessage('Department Employees Synced Successfully');
                } catch (error) {
                    setLoading(false);
                    setSeverity('error');
                    setAlertm(true);
                    setAlertMessage('An error occurred during the syncing process.');
                }
            }
        }
    };

    const syncEmployees = async () => {
        const decoded: any = jwtDecode(mycontext.token);
        if (decoded?.isMicrosoft) {
            if (isAuthenticated) {
                instance
                    .acquireTokenSilent(loginrequest)
                    .then(async (response: any) => {
                        syncMsEmps(response.accessToken);
                    })
                    .catch((e: any) => {
                        // setIsSynching(false);
                        setSeverity('error');
                        setAlertm(true);
                        setAlertMessage('You dont have necessary permissions to fetch MS Employees');
                    });
            } else {
                instance.acquireTokenPopup(loginrequest).then((res: any) => {
                    syncMsEmps(res.accessToken);
                });
            }
        }
    };

    const handleTabs = (event: any, val: any) => {
        setInviteType(val);
    };

    const handleChange = (e: any) => {
        setSearch(e.target.value);
    };

    const handleFilter = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event: any) => {
        // console.log("Approvers list: ", teamEmployees.employeesList.initialValues);
        const filterFor = event.target.innerText;
        if (filterFor === '') {
            const previousFilter = filterOn;
            setFilterOn(previousFilter);
        } else {
            setFilterOn(filterFor);
        }

        if (filterFor === 'All') {
            // setFilteredTeamMembers(teamEmployees);
            dispatch(filterForAll(teamEmployees.employeesList.initialValues));
        } else if (filterFor === 'Admin') {
            const newTEmp = teamEmployees.employeesList.initialValues.filter((tEmp: any) => tEmp.role === 'admin');
            // setFilteredTeamMembers(newTEmp);
            dispatch(filterForAdmin(newTEmp));
        } else if (filterFor === 'Approvers') {
            // const newTEmp = teamEmployees.employeesList.initialValues.filter((tEmp: any) => tEmp.role === 'approver');
            const newTEmp = teamEmployees.employeesList.initialValues.filter((tEmp: any) => tEmp.approvers.length > 0);
            // setFilteredTeamMembers(newTEmp);
            dispatch(filterForApprovers(newTEmp));
        } else if (filterFor === 'Members') {
            const newTEmp = teamEmployees.employeesList.initialValues.filter((tEmp: any) => tEmp.role === 'employee');
            // setFilteredTeamMembers(newTEmp);
            dispatch(filterForEmployees(newTEmp));
        }
        // else if (filterFor === 'Available') {
        //     const newTEmp = teamEmployees.filter((tEmp: any) => tEmp.status === 'available');
        //     setFilteredTeamMembers(newTEmp);
        // } else if (filterFor === 'Time-off') {
        //     const newTEmp = teamEmployees.filter((tEmp: any) => tEmp.status === 'time-off');
        //     setFilteredTeamMembers(newTEmp);
        // }
        else {
            dispatch(filterForAll(teamEmployees.employeesList.initialValues));
        }
        setAnchorEl(null);
        setAnchorEl1(null);
        // setAnchorEl2(null);
    };

    const handleRoleMenu = (event: any) => {
        setAnchorEl1(event.currentTarget);
    };

    // const handleStatusMenu = (event: any) => {
    //     setAnchorEl2(event.currentTarget);
    // };

    const toggle = () => {
        setIsInvite(!isInvite);
    };

    const inviteEmployees = async (event: any, employeeData: any, defaultTeam: any) => {
        setIsUpdating(true);
        const userList = employeeData.filter((emp: any) => emp.email !== null);

        if (userList.length === 0) {
            setIsUpdating(false);
            setSeverity('error');
            setAlertm(true);
            setAlertMessage('Email invite list is empty.');
        } else {
            const emptyList = userList.filter((item: any) => item.email !== '');
            if (emptyList.length === 0) {
                setIsUpdating(false);
                setSeverity('error');
                setAlertm(true);
                setAlertMessage('Email invite list is empty.');
            } else {
                const isError = emptyList.every((element: any) => element.isError === false);
                if (isError === false) {
                    setIsUpdating(false);
                    setSeverity('error');
                    setAlertm(true);
                    setAlertMessage('Please enter a valid email.');
                } else {
                    const errorList = emptyList.filter((element: any) => element.isError === false);
                    const users = errorList.map((data: any) => {
                        let user;
                        if (data.teams.length > 0) {
                            const userTeams = data.teams.map((team: any) => {
                                return team.teamId;
                            });
                            if (defaultTeam !== null) {
                                if (userTeams.includes(defaultTeam.teamId) === false) {
                                    userTeams.push(defaultTeam.teamId);
                                }
                            }

                            user = {
                                emailId: data.email,
                                orgId: mycontext.organizationId,
                                teams: userTeams,
                            };
                        } else {
                            const userTeams = defaultTeam !== null ? [defaultTeam.teamId] : [];
                            user = {
                                emailId: data.email,
                                orgId: mycontext.organizationId,
                                teams: userTeams,
                            };
                        }
                        return user;
                    });
                    // console.log(users);
                    if (users.length > 0) {
                    } else {
                        setIsUpdating(false);
                        setSeverity('error');
                        setAlertm(true);
                        setAlertMessage('Add  employees to send invites!');
                    }
                }
            }
        }
    };

    // console.log("Team employees: ", teamEmployees, props.employee);

    // if (sessionExpired) {
    //     return (
    //         <div className={classes.expired}>
    //             <div className={classes.card}>
    //                 Your session has expired.<p>Please logout and log in again!</p>
    //             </div>
    //         </div>
    //     );
    // }
    // if (teamEmployees.employeesList.filteredStatus === 'loading') {
    if (props.employee?.loading || props.inviteStatus?.loading || props.teams?.loading) {
        return (
            <>
                <Hidden only={['xs', 'sm']}>
                    <Grid style={{ display: 'grid', gridRowGap: '10px' }}>
                        <Grid
                            container
                            item
                            alignItems="center"
                            sm={mycontext.isAdmin ? 9 : 12}
                            style={{ height: 45, backgroundColor: 'white', minWidth: '100%' }}
                        >
                            <Typography className={classes.title} style={{ paddingLeft: 20 }}>
                                Employees
                            </Typography>
                        </Grid>
                        <TableContainer component="div" className={classes.containerSkeleton}>
                            <Table stickyHeader className={classes.tables} aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.idcolumnSkeleton} id="id1">
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tablecolumnname}>
                                            {/* Employees ({props.teamEmployees.length > 0 ? props.teamEmployees.length : 0}) */}
                                            Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tablecolumnname}>
                                            Approver of
                                        </TableCell>
                                        <TableCell align="left" className={classes.tablecolumnname}>
                                            Employee of
                                        </TableCell>
                                        {mycontext.isAdmin && (
                                            <TableCell align="center" className={classes.tablecolumnname}>
                                                Action
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((n: number) => (
                                        <TableRow key={'row-' + n} className={classes.rows}>
                                            <TableCell align="center" style={{ borderBottom: 'none', minWidth: 50 }}>
                                                <Grid container justifyContent="center">
                                                    <Grid item>
                                                        <Skeleton height={20} width={20} />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="left" style={{ borderBottom: 'none', minWidth: 150 }}>
                                                <Grid container className={classes.changeSize}>
                                                    <Grid item sm={3} style={{ maxWidth: 40 }}>
                                                        <Skeleton variant="circle" height={30} width={30} />
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        item
                                                        sm={9}
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        style={{ paddingLeft: 10 }}
                                                    >
                                                        <Skeleton height={20} width={100} />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                style={{ borderBottom: 'none', minWidth: 150 }}
                                                className={classes.changeSize}
                                            >
                                                <Skeleton height={20} width={150} />
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className={classes.changeSize}
                                                style={{ borderBottom: 'none', minWidth: 150 }}
                                            >
                                                <Skeleton height={20} width={150} />
                                            </TableCell>
                                            {mycontext.isAdmin && (
                                                <TableCell
                                                    align="center"
                                                    style={{ borderBottom: 'none', minWidth: 150 }}
                                                >
                                                    <Grid container justifyContent="center">
                                                        <Grid item>
                                                            <Skeleton height={20} width={150} />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Typography className={classes.title}>Employees</Typography>
                    <div style={{ display: 'grid', gridTemplateRows: '1fr', gap: '10px' }}>
                        {[11, 12, 13, 14, 15, 16].map((n: number) => (
                            <Skeleton key={n} variant="rect" width="100%" height={118} />
                        ))}
                    </div>
                </Hidden>
            </>
        );
    }
    if (props.employee?.data !== null) {
        const filteredMembers = teamEmployees.employeesList.filteredValues.filter((item: any) => {
            return (
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item.teams.some((element: any) => {
                    return element.teamName.toLowerCase().includes(search.toLowerCase());
                })
            );
        });

        if (isInvite) {
            if (mycontext.isAdmin) {
                return (
                    <div>
                        <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
                        <Loader isUpdating={isUpdating} />
                        <Hidden xsDown>
                            <Paper elevation={0} className={classes.paperstyle}>
                                <Grid container alignItems="center" style={{ height: 45, padding: '10px' }}>
                                    <Grid container item alignItems="center" sm={12}>
                                        <Typography id="sendAnInvite" className={classes.title}>
                                            {/* <img
                                                src={Arrow}
                                                alt="backButton"
                                                onClick={toggle}
                                                className={classes.backArrow}
                                            /> */}
                                            <ArrowBackIcon className={classes.backArrowStyle} onClick={toggle} />
                                            Invite Employees
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Hidden>
                        {/* <Hidden smUp>
                            <Typography id="sendAnInvite" className={classes.title} style={{ padding: '10px 0px' }}>
                                Invite Employees
                            </Typography>
                        </Hidden> */}
                        <Paper elevation={0} style={{ borderRadius: '0px' }}>
                            <Tabs
                                value={inviteType}
                                onChange={handleTabs}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                className={classes.tabs}
                                selectionFollowsFocus={false}
                            >
                                <Tab
                                    label="Email Invites"
                                    id="emailInvite"
                                    disableRipple={true}
                                    className={classes.tab}
                                />
                                <Tab
                                    label="Bulk Invites"
                                    id="bulkInvite"
                                    disableRipple={true}
                                    className={classes.tab}
                                />
                                <Tab
                                    label="Invite Status"
                                    id="emailStatus"
                                    disableRipple={true}
                                    className={classes.tab}
                                />
                                {hasFeature('isaddtoslack') && (
                                    <Tab
                                        label="Slack Invites"
                                        id="slackInvite"
                                        disableRipple={true}
                                        className={classes.tab}
                                    />
                                )}
                            </Tabs>
                        </Paper>
                        <Hidden smUp>
                            <TabPanel value={inviteType} index={0}>
                                <EmailInvite toggle={toggle} inviteUsers={inviteEmployees} teams={props.teams} />
                            </TabPanel>
                            <TabPanel value={inviteType} index={1}>
                                <BulkInvite toggle={toggle} inviteUsers={inviteEmployees} />
                            </TabPanel>
                            <TabPanel value={inviteType} index={2}>
                                <InviteStatus toggle={toggle} />
                            </TabPanel>
                            {hasFeature('isaddtoslack') && (
                                <TabPanel value={inviteType} index={3}>
                                    <Addtoslack />
                                </TabPanel>
                            )}
                        </Hidden>
                        <Hidden xsDown>
                            <Paper
                                elevation={0}
                                className={classes.tabContainer}
                                style={inviteType === 2 ? { padding: 0 } : { padding: 10 }}
                            >
                                <TabPanel value={inviteType} index={0}>
                                    <EmailInvite toggle={toggle} inviteUsers={inviteEmployees} teams={props.teams} />
                                </TabPanel>
                                <TabPanel value={inviteType} index={1}>
                                    <BulkInvite toggle={toggle} inviteUsers={inviteEmployees} />
                                </TabPanel>
                                <TabPanel value={inviteType} index={2}>
                                    <InviteStatus toggle={toggle} />
                                </TabPanel>
                                {hasFeature('isaddtoslack') && (
                                    <TabPanel value={inviteType} index={3}>
                                        <Addtoslack />
                                    </TabPanel>
                                )}
                            </Paper>
                        </Hidden>
                    </div>
                );
            } else {
                setIsInvite(false);
            }
        }

        return mycontext.subscriptionExpired === 'cancelled' &&
            Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
            mycontext.isAdmin ? (
                <SubscriptionHardExpired
                    content="Looks like your subscription is expired, Please renew it !"
                    showRenew={true}
                />
            ) : (
                <SubscriptionHardExpired
                    content="Subscription expired, Please contact your admin to renew subscription"
                    showRenew={false}
                />
            )
        ) : (
            <div data-testid="div1">
                <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
                <Loader isUpdating={isUpdating} />
                <Popover
                    data-testid="popover1"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{ className: classes.popoverPropsOne }}
                >
                    <MenuItem value={'Role'} onClick={handleRoleMenu} className={classes.selectedMenus}>
                        <Grid container>
                            <Grid item sm={10} xs={10} id="grid">
                                Role
                            </Grid>
                            <Grid item sm={2} xs={2}>
                                <span
                                    className="iconify"
                                    data-icon="eva:arrow-right-fill"
                                    style={{ color: '#C5C5C5' }}
                                ></span>
                            </Grid>
                        </Grid>
                    </MenuItem>
                    {/* <MenuItem value={'Status'} onClick={handleStatusMenu} className={classes.selectedMenus}>
                        <Grid container>
                            <Grid item sm={10} xs={10} id="status">
                                Status
                            </Grid>
                            <Grid item sm={2} xs={2}>
                                <span
                                    className="iconify"
                                    data-icon="eva:arrow-right-fill"
                                    style={{ color: '#C5C5C5' }}
                                ></span>
                            </Grid>
                        </Grid>
                    </MenuItem> */}
                </Popover>
                <Popover
                    anchorEl={anchorEl1}
                    open={Boolean(anchorEl1)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{ className: classes.popoverPropsTwo }}
                >
                    <Paper elevation={0} className={classes.paper}>
                        <MenuItem id="all" value={'All'} className={classes.selectedMenus} onClick={handleMenuClose}>
                            All
                        </MenuItem>
                        <MenuItem
                            id="admin"
                            value={'Admin'}
                            className={classes.selectedMenus}
                            onClick={handleMenuClose}
                        >
                            Admin
                        </MenuItem>
                        <MenuItem
                            id="approver"
                            value={'Approvers'}
                            className={classes.selectedMenus}
                            onClick={handleMenuClose}
                        >
                            Approvers
                        </MenuItem>
                        <MenuItem
                            id="members"
                            value={'Members'}
                            className={classes.selectedMenus}
                            onClick={handleMenuClose}
                        >
                            Members
                        </MenuItem>
                    </Paper>
                </Popover>

                <Hidden smUp>
                    <Typography className={classes.title}>Employees</Typography>
                </Hidden>
                <Hidden xsDown>
                    <Paper elevation={0} className={classes.breakcrum}>
                        <Grid container alignItems="center" style={{ height: 45, paddingRight: '10px' }}>
                            <Grid
                                container
                                item
                                alignItems="center"
                                sm={mycontext.isAdmin ? 9 : 12}
                                style={{ height: 45 }}
                            >
                                <Typography className={classes.title} style={{ paddingLeft: 20 }}>
                                    Employees
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Hidden>
                <Hidden xsDown>
                    <Paper elevation={0} className={classes.main}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item sm={12}>
                                <Grid container alignItems="center">
                                    <Grid container item justifyContent="flex-start" sm={9} md={10}>
                                        <TextField
                                            id="searchname"
                                            placeholder="Search by name/teams"
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange}
                                            className={classes.searchBorder}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className={classes.searchIcon}>
                                                        <span
                                                            style={{
                                                                marginRight: 10,
                                                                fontSize: 20,
                                                                fill: theme.palette.primary.contrastText,
                                                            }}
                                                            className="iconify"
                                                            data-icon="octicon:search-16"
                                                        ></span>
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    root: classes.searchInput,
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: theme.palette.primary.contrastText,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        sm={3}
                                        md={2}
                                        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px' }}
                                    >
                                        <Grid item>
                                            {mycontext.isAdmin && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.actionBtn}
                                                    // style={{ marginRight: '10px' }}
                                                    onClick={syncEmployees}
                                                    disabled={loading ? true : false}
                                                >
                                                    {loading && (
                                                        <i
                                                            className="fa fa-refresh fa-spin"
                                                            style={{ marginRight: '5px' }}
                                                        />
                                                    )}
                                                    {loading && <span>Syncing Employees</span>}
                                                    {!loading && <span>Sync Employees</span>}
                                                </Button>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                id="filter"
                                                variant="outlined"
                                                onClick={handleFilter}
                                                startIcon={<img src={FilterLogo} alt="Filter Logo" />}
                                                endIcon={<ArrowDropDownIcon className={classes.IconSty} />}
                                                className={classes.filterButton}
                                            >
                                                Filter by
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                {/* <MemberTable teammembers={filteredMembers} loading={loading} /> */}
                                {/* <EmployeeTable teammembers={filteredMembers} teamEmployees={teamEmployees} loading={loading} /> */}
                                <EmployeeTable
                                    teamEmployees={filteredMembers}
                                    setIsUpdating={setIsUpdating}
                                    teammembers={teamEmployees.employeesList.initialValues}
                                    loading={teamEmployees.status === 'loading'}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Hidden>
                <Hidden smUp>
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item xs={10} style={{ marginTop: '10px' }}>
                            <Paper
                                elevation={0}
                                style={{ display: 'flex', alignItems: 'center', width: '95%', height: 40 }}
                            >
                                <IconButton style={{ padding: 10 }} aria-label="menu">
                                    <Icon icon="octicon:search-16" color={theme.palette.primary.contrastText} />
                                </IconButton>
                                <InputBase
                                    id="search-input"
                                    className={classes.searchInput}
                                    defaultValue=""
                                    placeholder="Search by name/teams"
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'Search by name/teams' }}
                                    style={{ padding: 10 }}
                                />
                            </Paper>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={2}
                            justifyContent="flex-end"
                            alignItems="center"
                            style={{ marginTop: '10px' }}
                        >
                            <Paper elevation={0} style={{ height: 40 }}>
                                <Button
                                    id="filter"
                                    variant="outlined"
                                    disableRipple
                                    disableFocusRipple
                                    disableElevation
                                    aria-describedby="filter-popup"
                                    onClick={handleFilter}
                                    style={{
                                        padding: 12,
                                        margin: 0,
                                        color: theme.palette.primary.contrastText,
                                        width: '80%',
                                        border: 'none',
                                    }}
                                    startIcon={
                                        <span
                                            className="iconify"
                                            data-icon="bx:bx-filter-alt"
                                            data-width="20"
                                            data-height="20"
                                            style={{ color: theme.palette.primary.contrastText }}
                                        ></span>
                                    }
                                    endIcon={
                                        <span
                                            className="iconify"
                                            data-icon="bx:bxs-down-arrow"
                                            style={{ color: theme.palette.primary.contrastText }}
                                            data-width="10"
                                            data-height="10"
                                        ></span>
                                    }
                                ></Button>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <EmployeeTable
                                teamEmployees={filteredMembers}
                                setIsUpdating={setIsUpdating}
                                teammembers={teamEmployees.employeesList.initialValues}
                                loading={teamEmployees.status === 'loading'}
                            />
                        </Grid>

                        {mycontext.isAdmin && (
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.btnFixed}
                                // style={{ marginRight: '10px' }}
                                onClick={syncEmployees}
                                disabled={loading ? true : false}
                            >
                                {loading && <i className="fa fa-refresh fa-spin" style={{ marginRight: '5px' }} />}
                                {loading && <span>Syncing Employees</span>}
                                {!loading && <span>Sync Employees</span>}
                            </Button>
                        )}
                    </Grid>
                </Hidden>
            </div>
        );
    } else {
        return <ErrorPage title="Server Down" img="/error.png" msg="Something went wrong, please try again" />;
    }
}

function TabPanel(props: any) {
    const { children, value, index } = props;
    return <div>{value === index && <div>{children}</div>}</div>;
}
