import React, { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ModalStyles } from './ModalStyle';
import { Hidden, Button, Typography } from '@material-ui/core';
import MyModal from '../Modal/Modal';
import { DeleteTimeOff } from '../../helpers/hasuraapi';
import AuthContext from '../../context/AuthContext';
import { deleteTimeOff } from '../Redux/timeOffPolicyReducer';
import { useDispatch } from 'react-redux';

interface Props {
    defaultEnable?: boolean;
    leaveTypeId?: string;
    leaveTypeName?: string;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
    id?: string;
    setIsUpdating?: any;
    userSubscriptionDetails?: any;
    checkSubscription?: any;
}

const DeleteTimeff = (props: Props) => {
    const classes = ModalStyles();
    const [isDelete, setDelete] = useState(false);
    const toggle = () => setDelete(!isDelete);
    const mycontext = useContext(AuthContext);

    const dispatch = useDispatch();

    // const refreshPage = () => {
    //     props.setIsUpdating(false);
    //     window.location.reload();
    // };
    const handleSubmit = async () => {
        try {
            props.checkSubscription(props.userSubscriptionDetails);
            toggle();
            props.setIsUpdating(true);
            const row = {
                leaveTypeId: props.leaveTypeId,
                leaveTypeName: props.leaveTypeName,
                organizationId: mycontext.organizationId,
                token: mycontext.token,
            };
            const data = await DeleteTimeOff(row);

            if (data.error) {
                throw new Error('Something went wrong, try again!');
            } else {
                dispatch(
                    deleteTimeOff({
                        leaveTypeId: props.leaveTypeId,
                    }),
                );
                props.setSeverity('success');
                props.setAlertm(true);
                props.setAlertMessage('Time Off Deleted Successfuly!');
                props.setIsUpdating(false);
            }
        } catch (err) {
            props.setSeverity('error');
            props.setAlertm(true);
            props.setAlertMessage(err.message);
        }

        // refreshPage();
    };
    return (
        <div style={{ width: '100%' }}>
            <Hidden smDown>
                <IconButton
                    id={'DeleteButton' + props.id}
                    aria-label="Delete"
                    component="span"
                    onClick={toggle}
                    disabled={props.defaultEnable}
                >
                    <RiDeleteBinLine
                        className={props.defaultEnable ? classes.disablebtn : classes.activebtn}
                        // height="20px"
                        // width="20px"
                    />
                </IconButton>
            </Hidden>
            <div className={classes.screenSize}>
                <Button
                    id={'DeleteButton' + props.id}
                    variant="contained"
                    className={classes.deleteBtn}
                    onClick={toggle}
                    disabled={props.defaultEnable}
                >
                    Delete
                </Button>
            </div>
            <MyModal setOpen={toggle} open={isDelete} onModalClosed={toggle}>
                <div style={{ marginTop: '4%', padding: '0px' }}>
                    <Typography className={classes.deltitle} style={{ marginBottom: '20px' }}>
                        Delete Time-Off Category
                    </Typography>

                    <p className={classes.delbod} style={{ marginBottom: '35px' }}>
                        Are you sure you want to delete the <strong>{props.leaveTypeName}</strong> category?
                    </p>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '0px',
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.delmobbtnOutlined}
                            onClick={toggle}
                            style={{ marginRight: 10 }}
                            id="cancel"
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            id="delete"
                            className={classes.delbtn}
                            onClick={handleSubmit}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </MyModal>
        </div>
    );
};

export default DeleteTimeff;
