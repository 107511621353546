import React from 'react';
import { Typography, Paper, Tab, Tabs, Hidden } from '@material-ui/core';
import useStyles from './ContactStyle';
import { useLocation } from 'react-router-dom';
import General from './General';
import Reportbug from './ReportBug';

export default function Contact() {
    const classes = useStyles();
    const location: any = useLocation();
    const [value, setValue] = React.useState(
        location.state ? location.state?.value : Number(localStorage.getItem('ContactTabVal')),
    );

    const handleTabs = (event: any, val: any) => {
        localStorage.setItem('ContactTabVal', val);
        const tabval = localStorage.getItem('ContactTabVal');
        setValue(Number(tabval));
    };

    return (
        <>
            <div className={classes.main}>
                <Hidden only={['xs', 'sm']}>
                    <Paper elevation={0} className={classes.paper1style}>
                        <Typography className={classes.title}>Contact Support</Typography>
                    </Paper>
                </Hidden>

                <Paper elevation={0}>
                    <Tabs
                        className={classes.tabs}
                        value={value}
                        onChange={handleTabs}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="off"
                    >
                        <Tab label="General" className={classes.tab} id="general" />
                        <Tab label="Report a bug" className={classes.tab} id="reportbug" />
                    </Tabs>
                </Paper>

                <TabPanel value={value} index={0}>
                    <General />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Reportbug />
                </TabPanel>
            </div>
        </>
    );
}
function TabPanel(props: any) {
    const { children, value, index } = props;
    return <div>{value === index && <h1>{children}</h1>}</div>;
}
