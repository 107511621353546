import { makeStyles, Theme } from '@material-ui/core/styles';

export const HolidaytableStyles = makeStyles((theme: Theme) => ({
    tableContainer: {
        // border: '1px solid red',
        maxHeight: 'calc(100vh - 290px)',
        '&::-webkit-scrollbar': {
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.grey,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
    },
    table: {
        // border: '1px solid green',
        maxHeight: 'calc(100vh - 200px)',
        width: '100%',
    },
    cell: {
        minWidth: 170,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '14px',
        borderBottom: 'none',
        padding: '6px 24px 6px 16px',
        '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
            minWidth: 10,
        },
        '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
            minWidth: 10,
        },
        '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
            minWidth: 10,
        },
    },
    row: {
        border: 'none',
        '&:hover': {
            boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
            color: theme.palette.primary.main,
            border: 'none',
            outline: 'none',
            borderRadius: 5,
            cursor: 'default',
            backgroundColor: theme.palette.info.main,
            '& span': {
                color: theme.palette.primary.contrastText,
            },
        },
    },
    cellName: {
        minWidth: 170,
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        borderBottom: 'none',
        textTransform: 'none',
        padding: '6px 24px 6px 16px',
        '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
            minWidth: 10,
        },
        '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
            minWidth: 10,
        },
        '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
            minWidth: 10,
        },
    },
    fontright: {
        float: 'right',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '15px',
        alignItems: 'center',
        opacity: 0.7,
    },
    rowmessage: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20px',
    },
    tablecolumnname: {
        // minWidth: 170,
        minWidth: 150,
        color: '#262F83',
        backgroundColor: '#DCDFFF',
        zIndex: 999,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
            minWidth: 10,
        },
        '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
            minWidth: 10,
        },
        '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
            minWidth: 10,
        },
    },
    holidaynamecolumn: {
        minWidth: 70,
        color: '#262F83',
        backgroundColor: '#DCDFFF',
        zIndex: 999,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
            minWidth: 10,
        },
        '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
            minWidth: 10,
        },
        '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
            minWidth: 10,
        },
    },
    edit: {
        margin: 2,
        height: 30,
        width: 50,
        fontSize: 12,
    },
    delete: {
        height: 30,
        width: 50,
        padding: 4,
        fontSize: 12,
    },
    heading: {
        padding: theme.spacing(1),
    },
    shiftIcon: {
        padding: '6px',
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '&:active': {
            outline: 'none',
        },
    },
    editIcon: {
        marginLeft: '10%',
        color: theme.palette.primary.contrastText,
        '&:hover': {
            color: '#3C48B5',
        },
    },
    deleteIcon: {
        marginLeft: '10%',
        color: theme.palette.primary.contrastText,
        '&:hover': {
            color: '#3C48B5',
        },
    },
    scrollDiv: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.secondary.main,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },
    holidayLeft: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase',
        color: '#AAAAAA',
        padding: '10px 0',
        display: 'flex',
        alignItems: 'top',
    },
    holidayText: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        padding: '10px 0',
        display: 'flex',
        alignItems: 'top',
        textTransform: 'none',
    },
    paper: {
        height: 'calc(100vh - 100px)',
        padding: 10,
        fontFamily: theme.typography.fontFamily,
        '@media only screen and (max-width: 800px)': {
            height: 'auto',
        },
    },
    editBtn: {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        width: '100%',
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'capitalize',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
        },
        '&:focus': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
            outline: 'none',
        },
        zIndex: 0,
    },
    deleteBtn: {
        backgroundColor: '#fff',
        color: theme.palette.error.main,
        width: '100%',
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'capitalize',
        '&:hover': {
            color: theme.palette.error.main,
            backgroundColor: '#fff',
        },
        '&:focus': {
            color: theme.palette.error.main,
            backgroundColor: '#fff',
            outline: 'none',
        },
        zIndex: 0,
    },
    skeleton: {
        width: '50%',
        height: 30,
        backgroundColor: 'silver',
        borderRadius: 3,
    },
    divider: {
        color: '#666666',
        height: 10,
    },
    notfoundImage: {
        maxWidth: '75%',
        height: '180px',
    },
    screenSize: {
        display: 'none',
        '@media screen and (max-width: 767px)': {
            display: 'block',
        },
    },
}));
