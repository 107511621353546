import React from 'react';

export default React.createContext({
    username: '',
    password: '',
    email: '',
    userId: '',
    fname: '',
    lname: '',
    isAdmin: false,
    isSuperAdmin: false,
    mainAdmin: false,
    isLogedIn: false,
    isApprover: false,
    isGoogle: false,
    token: '',
    refreshToken: '',
    joining: '',
    uuid: '',
    profile: '',
    statustype: '',
    EmployeeId: '',
    organizationId: '',
    settingsId: '',
    organizationName: '',
    tenantName: '',
    teamId: '',
    teamName: '',
    tenantId: '',
    userProfile: '',
    dateFormat: '',
    webhookUrl: '',
    loading: true,
    expires: false,
    themeToggle: false,
    organizationLogo: '',
    settings: false,
    approverCalledAs: '',
    employeeCalledAs: '',
    teamsCalledAs: '',
    subscriptionExpired: '',
    warningDate: '',
    hardExpireDate: '',
    subscriptionId: '',
    workingHours: 5,
    pollResult: false,
    standupTempId: '',
    tenantTableId: '',
    currentPoll: {},
    location: {
        country: '',
        code: '',
        timezone: '',
    },
    country: {
        countryCode: '',
        countryName: '',
        zoneName: '',
        gmtOffset: 0,
        timestamp: 1665135740,
    },
    features: {
        isApproverCalledAs: false,
        isPolls: false,
        isSubscription: false,
        employeeProfilePolls: false,
        employeeProfileStandup: false,
        employeeProfileShoutOut: false,
    },
    teamsPage: false,
    initialSignIn: false,
    firstSignIn: (props: any) => {
        return {
            ...props,
        };
    },
    handleTeamsPage: () => {
        return;
    },
    sessionExpires: () => {
        return;
    },
    login: (props: any) => {
        return {
            ...props,
        };
    },
    logout: () => {
        return;
    },
    updateGoogle: () => {
        return;
    },
    registerUser: (username: any, password: string) => {
        return;
    },
    addUserId: (userId: any) => {
        return;
    },
    updateUsername: (username: any) => {
        return;
    },
    updateSubscriptionExpired: (username: any) => {
        return;
    },
    updateWarningDate: (username: any) => {
        return;
    },
    updateHardExpireDate: (username: any) => {
        return;
    },
    updateSubscriptionId: (username: any) => {
        return;
    },
    updateName: (firstname: string, lastname: string) => {
        return;
    },

    addEmail: (email: any) => {
        return;
    },
    addTenantId: (id: any) => {
        return;
    },
    addOrganizationId: (id: any) => {
        return;
    },
    // addLeaveType: (tdcy: any, udcy: any, adcy: any) => {
    //     return;
    // },
    addOrganizationName: (name: any) => {
        return;
    },
    addTenantName: (name: any) => {
        return;
    },
    addSettingsId: (id: any) => {
        return;
    },
    addTenantTableId: (id: any) => {
        return;
    },
    addTeamId: (id: any) => {
        return;
    },
    updateTeam: (team: any) => {
        return;
    },
    addUserProfile: (id: any) => {
        return;
    },
    updateUserProfile: (url: any) => {
        return;
    },
    updateDateFormat: (dateFormat: any) => {
        return;
    },
    addWebhookUrl: (url: any) => {
        return;
    },
    updateIsAdmin: (isadmin: boolean) => {
        return;
    },
    addLocation: (location: any) => {
        return;
    },
    toggleTheme: () => {
        return;
    },
    addOrganizationLogo: (logo: string) => {
        return;
    },
    addSettings: (settings: any) => {
        return;
    },
    addOtherSettings: (settings: any) => {
        return;
    },
    fetchAllData: () => {
        return;
    },
    addRefreshToken: (refreshToken: string, token: string) => {
        return;
    },
    updatePollResult: (value: boolean) => {
        return;
    },
    togglePopup: (obj: any) => {
        return;
    },
    handleProfile: (userId: string) => {
        return;
    },
    addStandupTempId: (id: string) => {
        return;
    },
    addCurrentPoll: (obj: any) => {
        return;
    },
    addCountry: (obj: any) => {
        return;
    },
});
