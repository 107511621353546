import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootLoader: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
        },
        container: {
            width: '100%',
            // padding: '0px 10px',
            marginTop: 5,
            textAlign: 'center',
            fontFamily: 'Nunito Sans',
            fontSize: 16,
        },
        row: {
            color: 'red',
            padding: '5px 10px',
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            // border: '1px solid red',
            // borderRadius: '5px',
        },
        errorDiv: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.background.paper,
        },
        mainDiv: {
            width: '100%',
            minHeight: 'calc(100vh - 100px)',
            maxHeight: 'calc(100vh - 100px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#FFF',
            padding: '5%',
            '@media only screen and (min-width: 360px) and ( max-width: 540px)': {
                padding: '1%',
            },
        },
        errorDivMobile: {
            padding: 20,
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
        },
        heading: {
            width: '100%',
            color: '#3C48B5',
            fontSize: '54px',
            fontFamily: 'Poppins',
            height: '200px',
            fontWeight: 500,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mainContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        subheading: {
            color: '#444444',
            fontSize: '20px',
            fontFamily: 'Nunito Sans',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media only screen and (min-width: 360px) and ( max-width: 540px)': {
                fontSize: '15px',
            },
        },
        content: {
            color: '#444444',
            fontSize: '16px',
            fontFamily: 'Nunito Sans',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        img: {
            width: '100%',
            height: '100%',
            // padding: '50px',
            // paddingLeft: '0px',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            margin: 0,
        },
        successImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
            '@media only screen and (min-width: 1080px)': {
                marginBottom: '5%',
            },
        },
        imgMobile: {
            maxWidth: '100%',
            height: '300px',
        },
        btn: {
            width: 100,
            marginTop: '30px',
            textTransform: 'capitalize',
            padding: '5px 10px',
            color: theme.palette.common.white,
        },
    }),
);

export default useStyles;
