import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const otherStyle = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            width: '100%',
            minHeight: 'calc(100vh - 200px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: 10,
            '@media screen and (max-width: 770px)': {
                height: 'auto',
                minHeight: 'auto',
            },
        },
        LeftForm: {
            maxHeight: 'calc(100vh - 240px)',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            overflowY: 'auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            '@media screen and (max-width: 770px)': {
                height: 'auto',
                maxHeight: 'auto',
            },
        },
        modalDiv: {
            position: 'relative',
            width: '30%',
            height: '300px',
            boxShadow: '0px 4px 10px rgba(60, 72, 181, 0.2)',
            border: '0.5px solid #DCDFFF',
            display: 'flex',
            flexDirection: 'column',
            '@media screen and (max-width: 770px)': {
                display: 'none',
                // boxShadow: '0px 4px 10px rgba(60, 72, 181, 0.2)',
                // position: 'fixed',
                // width: '90%',
                // backgroundColor: '#fff',
                // boxShadow: '0px 4px 10px rgba(60, 72, 181, 0.2)',
                // margin: 'auto',
                // height: '230px',
                // zIndex: 99999,
            },
        },
        modalDivMobile: {
            margintop: 50,
            width: '100%',
            height: '300px',
            // boxShadow: '0px 4px 10px rgba(60, 72, 181, 0.2)',
            display: 'flex',
            flexDirection: 'column',
        },
        closeBtn: {
            position: 'absolute',
            right: -5,
            top: -5,
            border: '0.5px dotted grey',
            width: 18,
            height: 18,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        hiddenModaldiv: {
            display: 'none',
        },
        modalDivTop: {
            width: '100%',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            backgroundColor: '#DCDFFF',
            '& p': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 12,
                fontWeight: 'bold',
                color: theme.palette.text.hint,
                '& span': {
                    color: theme.palette.primary.main,
                },
            },
        },
        modalDivTopMobile: {
            width: '90%',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            // backgroundColor: '#DCDFFF',
            '& p': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 12,
                fontWeight: 'bold',
                color: theme.palette.text.hint,
                '& span': {
                    color: theme.palette.primary.main,
                },
            },
        },
        modalDivBottom: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '10px 20px',
        },
        modalContentDiv: {
            width: '100%',
            height: '30px',
            display: 'flex',
            flexDirection: 'column',
            margin: '10px 0',
            // justifyContent: 'center',
            fontFamily: theme.typography.fontFamily,
            '& p': {
                fontSize: 10,
                color: theme.palette.primary.contrastText,
                lineHeight: 0,
            },
        },
        modalContentDiv2: {
            width: '100%',
            paddingTop: 20,
            display: 'flex',
            flexDirection: 'column',
            fontFamily: theme.typography.fontFamily,
            '& p': {
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
                fontSize: 14,
                lineHeight: 2,
                color: theme.palette.text.primary,
                '& span': {
                    color: theme.palette.text.hint,
                    fontWeight: 700,
                },
            },
        },
        span1: {
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.success.main,
            lineHeight: 1,
        },
        span2: {
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.error.main,
            lineHeight: 1,
        },
        span3: {
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main,
            lineHeight: 1,
        },
        allfields: {
            height: '100%',
            width: '65%',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-evenly',
            '@media screen and (max-width: 770px)': {
                height: 'auto',
                width: '100%',
            },
        },
        allfields2: {
            maxHeight: 'calc(100vh - 240px)',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            overflowY: 'auto',
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-evenly',
            '@media screen and (max-width: 770px)': {
                height: 'auto',
                maxHeight: 'auto',
                width: '100%',
            },
        },
        formLabelStyle: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '14px',
            textTransform: 'capitalize',
            marginBottom: 10,
            '@media screen and (max-width: 770px)': {
                lineHeight: '22px',
            },
        },
        formLabelStyleOneday: {
            color: theme.palette.primary.contrastText,
            fontWeight: 600,
            fontSize: '14px',
            textTransform: 'capitalize',
            marginBottom: 5,
            marginTop: -5,
            '@media screen and (max-width: 770px)': {
                lineHeight: '15px',
            },
            '.MuiFormControlLabel-root': {
                padding: 0,
            },
            '.MuiFormControlLabel-label': {
                margin: 0,
            },
        },
        formControl: {
            width: '100%',
            // backgroundColor: '#fff',
        },
        inputSelectStyle: {
            width: '60%',
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            // border: `1px solid ${theme.palette.primary.contrastText}`,
            '@media screen and (max-width: 770px)': {
                width: '100%',
            },
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
        },
        inputSelectStyleOther: {
            width: '100%',
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            // border: `1px solid ${theme.palette.primary.contrastText}`,
            '@media screen and (max-width: 770px)': {
                width: '100%',
            },
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
        },
        dateBorder: {
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '& .MuiIcon-colorSecondary': {
                color: 'red',
                background: 'red',
            },
        },
        inputReason: {
            width: '75%',
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            // border: `1px solid ${theme.palette.primary.contrastText}`,
            '@media screen and (max-width: 770px)': {
                width: '100%',
            },
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
        },
        inputReason2: {
            width: '95%',
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            // border: `1px solid ${theme.palette.primary.contrastText}`,
            '@media screen and (max-width: 770px)': {
                width: '100%',
            },
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
        },
        fieldDiv: {
            marginBottom: '15px',
        },
        fieldDiv2: {
            marginBottom: '15px',
        },
        fieldDivDateTime: {
            width: '90%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: 10,
            marginBottom: '15px',
            '@media screen and (max-width: 768px)': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
        },
        fieldDivDateTime2: {
            width: '90%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: 10,
            marginBottom: '15px',
            '@media screen and (max-width: 768px)': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
        },
        fieldDivDateTimeSecond: {
            width: '63%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
            '@media screen and (max-width: 768px)': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
        },
        fieldDivDateTimeSecond2: {
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
            '@media screen and (max-width: 768px)': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
        },
        mobileToPart: {
            marginTop: 0,
            '@media screen and (max-width: 768px)': {
                marginTop: 15,
            },
        },
        mobileToPart2: {
            marginTop: 15,
            '@media screen and (max-width: 768px)': {
                marginTop: 15,
            },
        },
        formControlTime: {
            width: '100%',
            // backgroundColor: '#fff',
            display: 'grid',
            gridTemplateColumns: '1fr 0.8fr',
            gridColumnGap: 10,
        },
        leftBtns: {
            height: '5%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            outline: 'none',
            '@media screen and (max-width: 650px)': {
                justifyContent: 'space-between',
            },
        },
        btn: {
            textTransform: 'capitalize',
            minWidth: 100,
            height: 30,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
            },
        },
        btnOutlined: {
            textTransform: 'capitalize',
            width: 100,
            height: 30,
            // marginRight: 10,
            fontWeight: 700,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
                // marginRight: 10,
            },
        },
        btn2: {
            textTransform: 'capitalize',
            width: 100,
            height: 30,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '50%',
                margin: '5px 0',
            },
        },
        btnOutlined2: {
            textTransform: 'capitalize',
            width: 100,
            height: 30,
            // marginRight: 10,
            fontWeight: 700,
            // color: theme.palette.common.white,
            // backgroundColor: theme.palette.primary.main,
            outline: 'none',
            marginRight: 10,
            '&:focus': {
                textDecoration: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '46%',
                margin: '5px 0',
                color: theme.palette.text.secondary,
                border: `1px solid ${theme.palette.text.secondary}`,
                // marginRight: 10,
            },
        },
        errorText: {
            fontSize: '12px',
            fontFamily: theme.typography.fontFamily,
            color: 'red',
        },
    }),
);

export default otherStyle;
