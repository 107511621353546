/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import useStyles from './smallcalendarStyle';
import moment from 'moment';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@material-ui/icons';
import {
    // DateDifference,
    ToStringFormat,
    convertTZ,
    toAWSDateFormat,
} from '../../helpers/date';
import { isoToLocal } from 'helpers/timezone';
import AuthContext from '../../context/AuthContext';

interface CalendarProps {
    holidays?: any;
    organizationLeaves?: any;
}
export default function Calendar(props: CalendarProps) {
    const mycontext = useContext(AuthContext);
    const [calendar, setCalendar] = useState<any>([]);
    const [value, setValue] = useState(moment(convertTZ(new Date().toISOString(), mycontext.country.zoneName)));
    const [holidays, setHolidays] = useState<any>([]);
    const [leaves, setLeaves] = useState<any>([]);
    const classes = useStyles();

    const listDates = (arr: any) => {
        const h: any = [];
        arr?.filter((a: any, i: number) => {
            // let days = DateDifference(a?.startDate, a?.endDate);
            // console.log('difference ', a?.startDate, a?.endDate, days);
            // while (days !== -1) {
            let theDate = isoToLocal(a?.isoString?.startTime, mycontext.country);
            const eDate = isoToLocal(a?.isoString?.endTime, mycontext.country);
            while (theDate <= eDate) {
                // const nextDate = moment(new Date(a?.startDate)).add(days, 'days').calendar();
                // console.log(
                //     'nexdate and conversion ',
                //     new Date(nextDate),
                //     nextDate,
                //     ToStringFormat(new Date(nextDate), 'DD-MM-YYYY'),
                // );
                h.push(ToStringFormat(new Date(theDate), 'DD-MM-YYYY'));
                if (parseInt(a.noOfHours) > mycontext.workingHours) {
                    if (
                        theDate
                            .toTimeString()
                            .split(' ')[0]
                            .slice(0, 5) === '00:00'
                    ) {
                        theDate.setDate(theDate.getDate() + 1);
                    } else {
                        theDate.setDate(theDate.getDate() + 1);
                        theDate = new Date(theDate.getTime() - 60 * 1000);
                    }
                } else if (
                    parseInt(a.noOfHours) === mycontext.workingHours &&
                    toAWSDateFormat(theDate) !== toAWSDateFormat(eDate)
                ) {
                    theDate.setDate(theDate.getDate() + 1);
                    theDate = new Date(theDate.getTime() - 60 * 1000);
                } else {
                    theDate.setDate(theDate.getDate() + 1);
                    // theDate.setDate(theDate.getMinutes() - 1);
                }
            }
        });
        return h;
    };
    const listDatesHolidays = (arr: any) => {
        const h: any = [];
        arr?.filter((a: any, i: number) => {
            // let days = DateDifference(a?.startDate, a?.endDate);
            // console.log('difference ', a?.startDate, a?.endDate, days);
            // while (days !== -1) {
            const theDate = new Date(a?.startDate);
            const eDate = new Date(a?.endDate);
            while (theDate <= eDate) {
                // const nextDate = moment(new Date(a?.startDate)).add(days, 'days').calendar();
                // console.log(
                //     'nexdate and conversion ',
                //     new Date(nextDate),
                //     nextDate,
                //     ToStringFormat(new Date(nextDate), 'DD-MM-YYYY'),
                // );
                h.push(ToStringFormat(new Date(theDate), 'DD-MM-YYYY'));
                // days = days - 1;
                theDate.setDate(theDate.getDate() + 1);
            }
        });
        return h;
    };

    // const getDatesBetweenDates = (sd: any, ed: any) => {
    //     let dates = [];
    //     //to avoid modifying the original date
    //     const theDate = new Date(startDate);
    //     while (theDate < endDate) {
    //         dates = [...dates, new Date(theDate)];
    //         theDate.setDate(theDate.getDate() + 1);
    //     }
    //     return dates;
    // };
    // const holidays = ['10-10-2021', '11-11-2021', '1-11-2021', '3-11-2021', '2-11-2021'];
    // const leaves = ['11-11-2021', '5-11-2021', '9-11-2021', '20-11-2021', '16-11-2021'];
    function buildCalendar(value: any): any[] {
        const startDay = value
            .clone()
            .startOf('month')
            .startOf('week');
        const endDay = value
            .clone()
            .endOf('month')
            .endOf('week');
        const day = startDay.clone().subtract(1, 'day');
        const a = [];
        while (day.isBefore(endDay, 'day')) {
            a.push(
                Array(7)
                    .fill(0)
                    .map(() => day.add(1, 'day').clone()),
            );
        }
        return a;
    }

    function isSelected(day: any, value: any) {
        return value.isSame(day, 'day');
    }

    // function beforeToday(day: any) {
    //     return day.isBefore(new Date(), 'day');
    // }

    function beforeMonth(day: any) {
        return day.isBefore(convertTZ(new Date().toISOString(), mycontext.country.zoneName), 'month');
    }

    function currentMonth(day: any) {
        return day.isAfter(convertTZ(new Date().toISOString(), mycontext.country.zoneName), 'month');
    }

    function isToday(day: any) {
        return day.isSame(convertTZ(new Date().toISOString(), mycontext.country.zoneName), 'day');
    }

    function dayStyles(day: any, value: any) {
        if (isToday(day)) return 'today';
        if (isSelected(day, value)) return 'select';
        // if (beforeToday(day)) return "before";
        if (currentMonth(day)) return 'before';
        if (beforeMonth(day)) return 'before';
        return '';
    }

    function prevMonth() {
        return value.clone().subtract(1, 'month');
    }

    function nextMonth() {
        return value.clone().add(1, 'month');
    }

    // function thisMonth() {
    //     return value.isSame(new Date(), 'month');
    // }
    // console.log('organization leaves ', props.organizationLeaves);
    useEffect(() => {
        setCalendar(buildCalendar(value));
        setHolidays(listDatesHolidays(props.holidays));
        setLeaves(listDates(props.organizationLeaves));
    }, [value]);
    return (
        <div className={classes.container}>
            <div className={classes.calendar}>
                <div className={classes.month}>
                    <div className={classes.arrowStyle}>
                        <ArrowLeftOutlined data-testid="leftarrow" onClick={() => setValue(prevMonth())} />
                    </div>
                    <div className={classes.date}>
                        <p>
                            {value.format('MMMM')}
                            &nbsp;{value.format('YYYY')}
                        </p>
                    </div>
                    <div className={classes.arrowStyle}>
                        <ArrowRightOutlined data-testid="rightarrow" onClick={() => setValue(nextMonth())} />
                    </div>
                </div>
                <div className={classes.weekDays}>
                    <div>S</div>
                    <div>M</div>
                    <div>T</div>
                    <div>W</div>
                    <div>T</div>
                    <div>F</div>
                    <div>S</div>
                </div>
                {calendar.map((week: any, p: number) => (
                    <div key={p} className={classes.weekDays}>
                        {week.map((d: any, i: number) => (
                            <div key={i} className={classes.day}>
                                <span
                                    className={
                                        dayStyles(d, value) === 'before'
                                            ? holidays.includes(d.format('DD-MM-YYYY').toString())
                                                ? classes.holiday
                                                : leaves.includes(d.format('DD-MM-YYYY').toString())
                                                ? classes.appliedLeave
                                                : classes.prevDate
                                            : dayStyles(d, value) === 'today'
                                            ? holidays.includes(d.format('DD-MM-YYYY').toString())
                                                ? classes.holidayToday
                                                : leaves.includes(d.format('DD-MM-YYYY').toString())
                                                ? classes.appliedLeaveToday
                                                : classes.today
                                            : holidays.includes(d.format('DD-MM-YYYY').toString())
                                            ? classes.holiday
                                            : leaves.includes(d.format('DD-MM-YYYY').toString())
                                            ? classes.appliedLeave
                                            : classes.otherDay
                                    }
                                >
                                    {d.format('DD').toString()}
                                </span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}
