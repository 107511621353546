import React from 'react';
import PropTypes from 'prop-types';

//function to fetch data in nodejs?

function SlackLogin({ slackClientId, onSuccess, onFailure }) {
    function openPopup() {
        const url = `https://slack.com/oauth/v2/authorize?user_scope=users:read,channels:read,groups:read,users:read.email&redirect_uri=https://teamplus.udyamo.com&client_id=${slackClientId}`;
        // const url = `https://slack.com/oauth/v2/authorize?user_scope=users:read,channels:read,groups:read,users:read.email&redirect_uri=https://localhost:3000&client_id=${slackClientId}`;
        return window.open(url);
    }
    function polling(popup) {
        const polling = setInterval(() => {
            if (!popup || popup.closed || popup.closed === undefined) {
                clearInterval(polling);
                onFailure('Popup has been closed by user');
            }

            const closeDialog = () => {
                clearInterval(polling);
                popup.close();
            };

            try {
                if (!popup.location.hostname.includes('slack.com')) {
                    if (popup.location.search) {
                        const query = new URLSearchParams(popup.location.search);
                        const slackCode = query.get('code');

                        closeDialog();
                        if (slackCode) {
                            return onSuccess(slackCode);
                        }

                        if (onFailure) {
                            onFailure(query.get('error'));
                        }
                    }
                }
            } catch (error) {
                return error;
                // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
                // A hack to get around same-origin security policy errors in IE.
            }
        }, 500);
    }
    function handleClick() {
        polling(openPopup());
    }

    return (
        <div style={{ cursor: 'pointer' }}>
            <img
                style={{}}
                className="react-slack-login-img"
                onClick={handleClick}
                alt="Sign in with Slack"
                height="40"
                width="172"
                src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
                srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
            />
        </div>
    );
}

SlackLogin.propTypes = {
    slackClientId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func,
};

export default SlackLogin;
