import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOrganizationEmployees, fetchApproverList } from 'helpers/hasuraapi';

export const getOrgEmployees: any = createAsyncThunk('orgEmployees/getOrgEmployees', async (user: any) => {
    const data: any = await getOrganizationEmployees(user.organizationId, user.token);
    const jsonData: any = await data.json();
    return jsonData.data.usermanagementdb_organization_employee;
});
export const getOrgApprovers: any = createAsyncThunk('orgEmployees/getOrgApprovers', async (user: any) => {
    const data: any = await fetchApproverList(user.organizationId, user.token);
    const jsonData: any = await data.json();
    return jsonData.data.timeoffdb_get_approverdeniername1;
});
export const orgEmployeesSlice = createSlice({
    name: 'orgEmployees',
    initialState: { employees: [], approvers: [], status: 'loading' },
    reducers: {
        addEmployee: (state: any, action: any) => {
            state.employees.push(action.payload);
        },
        editEmployee: (state: any, action: any) => {
            state.employees.map((u: any) => {
                if (u.employeeId === action.payload.employeeId) {
                    u.firstName = action.payload.firstName;
                    u.lastName = action.payload.lastName;
                    return u;
                }
                return u;
            });
        },
        deleteEmployee: (state: any, action: any) => {
            state.employees = state.employees.filter((e: any) => e.employeeId !== action.payload.employeeId);
        },
        fetchEmployees: (state: any, action: any) => {
            state.employees = action.payload;
        },
        fetchApprovers: (state: any, action: any) => {
            state.approvers = action.payload;
        },
    },
    extraReducers: {
        [getOrgEmployees.pending]: (state: any) => {
            state.status = 'loading';
        },
        [getOrgEmployees.fulfilled]: (state: any, action: any) => {
            state.employees = action.payload;
            state.status = 'success';
        },
        [getOrgEmployees.rejected]: (state: any) => {
            state.status = 'failed';
        },
        [getOrgApprovers.pending]: (state: any) => {
            state.status = 'loading';
        },
        [getOrgApprovers.fulfilled]: (state: any, action: any) => {
            state.approvers = action.payload;
            state.status = 'success';
        },
        [getOrgApprovers.rejected]: (state: any) => {
            state.status = 'failed';
        },
    },
});
export const { fetchEmployees, fetchApprovers, addEmployee, editEmployee, deleteEmployee } = orgEmployeesSlice.actions;
export default orgEmployeesSlice.reducer;
