import { makeStyles, Theme } from '@material-ui/core/styles';

const profileStyle = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // borderBottom: '1px solid #fff',
        padding: '0px 20px',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.light,
        // justifyContent: 'space-between',
        // minHeight: 'fit-content',
        // fontFamily: theme.typography.fontFamily,
    },
    profileDiv: {
        width: '100%',
        // borderBottom: '0.5px solid #fff',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0px',
        // textAlign: 'center',
    },
    avatarDiv: {
        width: '55px',
        height: '55px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `0.5px solid ${theme.palette.primary.light}`,
    },
    avatar: {
        margin: 'auto',
        width: '45px',
        height: '45px',
    },
    nameDiv: {
        marginLeft: 5,
        flex: 0.8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    icon: {
        fontSize: '14px',
        color: theme.palette.common.white,
    },
    // heading: {
    //     margin: '5px auto',
    //     fontFamily: theme.typography.fontFamily,
    // },
    name: {
        textDecoration: 'none',
        textTransform: 'capitalize',
        color: theme.palette.common.white,
        margin: 'auto',
        fontSize: '18px',
        fontWeight: theme.typography.fontWeightBold,
        width: 150,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    link: {
        // margin: 'auto',
        color: theme.palette.primary.main,
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
        },
    },
}));

export default profileStyle;
