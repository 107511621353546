import React, { useContext } from 'react';
import {
    Grid,
    FormLabel,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    FormControl,
    Button,
    Paper,
    Typography,
    Hidden,
    useTheme,
} from '@material-ui/core';
import { PopUp } from '../Modal/Modal';
import { useStyles } from './pollFormStyle';
import PollsModal from './modal';
import AuthContext from '../../context/AuthContext';
import { toAWSDateFormat } from '../../helpers/date';
import { createPoll, createPollTeam, pollBot } from 'helpers/hasuraapi2';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';
import { getCurrentCountryDateTime, localToISO } from 'helpers/timezone';

const CreatePoll = (props: { setStep: any }) => {
    const classes = useStyles();
    return (
        <>
            <Hidden lgUp>
                <div style={{ marginBottom: 10 }}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <ArrowBackIcon className={classes.backArrow} onClick={() => props.setStep(0)} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>Create New Poll</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Hidden>
        </>
    );
};

interface TextProps {
    question?: any;
    mycontext?: any;
    pollrespond?: any;
    memberProfile?: any;
    setTeamId?: any;
    teamId?: any;
    choices?: any;
    addmore?: any;
    viewedPoll?: any;
    handleQuestionChange?: any;
    handlepollrespondChange?: any;
    handleInputChange?: any;
    handleviewedpoll?: any;
}

export const TextFieldView = (props: TextProps) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.borderDiv}>
                <Grid container className={classes.firstField}>
                    <Grid item xs={12}>
                        <FormLabel className={classes.formLabelStyle}>Question </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            placeholder="Question"
                            className={classes.inputTag}
                            variant="outlined"
                            id="QuestionId"
                            value={props.question}
                            inputProps={{
                                maxLength: 300,
                            }}
                            onChange={props.handleQuestionChange}
                            multiline
                            rows={3}
                        ></TextField>
                    </Grid>
                </Grid>
                <Grid container className={classes.firstField}>
                    <Grid item xs={12}>
                        <FormLabel className={classes.formLabelStyle}>Who can view and respond to the poll ?</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" size="small" style={{ width: '100%', borderRadius: '2px' }}>
                            <Select
                                className={`${
                                    props.teamId.length === 0 ? classes.inputSelectStyle : classes.inputTag
                                } `}
                                value={props.pollrespond}
                                displayEmpty
                                onChange={props.handlepollrespondChange}
                            >
                                {/* <MenuItem value={'All'}>All</MenuItem> */}
                                {props.memberProfile?.newEmpTeams?.usermanagementdb_Team.map((t: any, i: number) => (
                                    <MenuItem
                                        key={i}
                                        value={t.teamName}
                                        id="2"
                                        onClick={() =>
                                            props.setTeamId({
                                                teamId: t.teamId,
                                                slackTeamId: t.slackTeamId,
                                                msChannelId: t.msChannelId,
                                            })
                                        }
                                    >
                                        <span>{t.teamName}</span>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={classes.firstField}>
                    <Grid item xs={12}>
                        <FormLabel className={classes.formLabelStyle}>Choices </FormLabel>
                    </Grid>
                    <Grid className={classes.inputboxes} item xs={12}>
                        {Object.keys(props.choices).map((key: any, v: any) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                placeholder={`Choice ${v + 1}`}
                                className={classes.inputStyle1}
                                key={v}
                                value={props.choices[key]}
                                onChange={(a: any) => props.handleInputChange(key, a.target.value)}
                            />
                        ))}
                        <Button onClick={props.addmore} className={classes.Addanother}>
                            + Add Another
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.firstFieldThirdGrid}>
                    <Grid item xs={12}>
                        <FormLabel className={classes.formLabelStyle}>
                            How do you want the poll to be viewed after expiration time?
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup row value={props.viewedPoll} onChange={props.handleviewedpoll}>
                            <Grid item xs={12} lg={6}>
                                <FormControlLabel
                                    value="Show Response"
                                    control={<Radio color="primary" className={classes.radioStyle} />}
                                    label={<FormLabel className={classes.radioNameStyle}>Show Response</FormLabel>}
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <FormControlLabel
                                    className={classes.RadioGroupStyle}
                                    value="Anonymous"
                                    control={<Radio color="primary" className={classes.radioStyle} />}
                                    label={<FormLabel className={classes.radioNameStyle}>Anonymous</FormLabel>}
                                    labelPlacement="end"
                                />
                            </Grid>
                        </RadioGroup>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

interface ResponseProps {
    typerespond: any;
    timeend: any;
    handletyperespond: any;
    handlepolltimeendChange: any;
}

export const ResponseTimePart = (props: ResponseProps) => {
    const classes = useStyles();
    // const mycontext = useContext(AuthContext);
    // let a: any = Number(getCurrentCountryDateTime(mycontext.country.zoneName).time.substring(0, 2))
    // console.log(typeof a.toString());
    // console.log(a);
    return (
        <>
            <div>
                <div className={classes.newInnerDiv}>
                    <Grid item xs={12} className={classes.firstFieldThirdGrid}>
                        <Grid item xs={12}>
                            <FormLabel className={classes.formLabelStyle}>Type of response ?</FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                row
                                value={props.typerespond}
                                onChange={props.handletyperespond}
                                defaultValue="Single Vote"
                            >
                                <Grid item xs={12} lg={6}>
                                    <FormControlLabel
                                        value="Single"
                                        control={<Radio color="primary" className={classes.radioStyle} />}
                                        label={<FormLabel className={classes.radioNameStyle}>Single Vote</FormLabel>}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControlLabel
                                        value="Multiple"
                                        control={<Radio color="primary" className={classes.radioStyle} />}
                                        label={<FormLabel className={classes.radioNameStyle}>Multiple Vote</FormLabel>}
                                    />
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.lastField}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <FormLabel className={classes.formLabelStyle}>Poll time ends at</FormLabel>
                            </Grid>
                            <Grid item>
                                <TextField
                                    data-testid="test"
                                    variant="outlined"
                                    type="time"
                                    size="small"
                                    className={classes.TimeStyle}
                                    value={props.timeend}
                                    onChange={props.handlepolltimeendChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export const ButtonPart = (props: { handleReset: any; PreviewandSubmit: any; disable: any }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <>
            <div className={classes.btn}>
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={props.disable}
                    style={
                        props.disable
                            ? { color: theme.palette.primary.contrastText }
                            : { color: theme.palette.text.secondary }
                    }
                    className={classes.btn1}
                    onClick={() => props.handleReset()}
                >
                    Reset
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    className={classes.btn2}
                    onClick={() => props.PreviewandSubmit()}
                >
                    Preview & Submit
                </Button>
            </div>
        </>
    );
};

interface PollProps {
    setStep?: any;
}

export default function PollForm(props: PollProps) {
    const mycontext = useContext(AuthContext);
    const classes = useStyles();
    const [question, setQuestion] = React.useState('');
    // const [pollrespond, setPollrespond] = React.useState('');
    const [teamId, setTeamId] = React.useState({
        teamId: '',
        slackTeamId: '',
        msChannelId: '',
    });
    const [viewedPoll, setViewePoll] = React.useState('Show Response');
    const [typerespond, setTyperespond] = React.useState('Single');
    const time = () => {
        const j = Number(getCurrentCountryDateTime(mycontext.country.zoneName).time.substring(3, 5)) + 30;
        const t = j - 60;
        const x = Number(getCurrentCountryDateTime(mycontext.country.zoneName).time.substring(0, 2));
        const v = `0${x}`;
        if (j < 60) {
            if (x <= 9) {
                return v + ':' + j;
            } else {
                return x + ':' + j;
            }
        } else {
            if (t < 10) {
                const h = `0${t}`;
                if (x < 9) {
                    return v + 1 + ':' + h;
                } else {
                    return x + 1 + ':' + h;
                }
            } else {
                if (x < 9) {
                    return v + 1 + ':' + t;
                } else {
                    return x + 1 + ':' + t;
                }
            }
        }
        // }
    };

    const [timeend, setTimeend] = React.useState<any>(time);
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [modal, setModal] = React.useState(false);
    const memberProfile = useSelector((state: any) => state.memberProfile);
    const subDetails = useSelector((state: any) => state.subscription);
    const [disable, setDisable] = React.useState<boolean>(true);

    const [pollrespond, setPollrespond] = React.useState(memberProfile?.empTeams?.usermanagementdb_Team[0]?.teamName);

    const [pollData, setPollData] = React.useState({
        action: 'create',
        question,
        pollrespond,
        choices: [],
        isAnonymous: viewedPoll === 'Anonymous',
        isSingleVote: typerespond === 'Single',
        createdOn: toAWSDateFormat(new Date()),
        createdBy: mycontext.EmployeeId,
        pollEndTime: timeend,
        Employee: {
            firstName: mycontext.fname,
        },
        organizationId: mycontext.organizationId,
        token: mycontext.token,
    });
    const [choices, setChoices] = React.useState({
        input1: '',
        input2: '',
        input3: '',
    });
    const handleInputChange = (key: any, value: any) => {
        setDisable(false);
        setChoices({
            ...choices,
            [key]: value,
        });
    };
    const addmore = () => {
        const length = Object.keys(choices).length;
        setChoices({
            ...choices,
            [`input${length + 1}`]: '',
        });
    };
    const handleReset = () => {
        setDisable(true);
        setQuestion('');
        // setPollrespond('All');
        setPollrespond('');
        setViewePoll('Show Response');
        setTyperespond('Single');
        setTimeend(time);
        setChoices({
            input1: '',
            input2: '',
            input3: '',
        });
    };
    // console.log('team', memberProfile);
    const handleSubmit = async () => {
        const pollCreate = await createPoll(pollData);
        const pollId = pollCreate.pollId;
        const arr: any = [];
        Object.keys(choices).map((key: any, value: any) => {
            if (choices[key] !== '') {
                arr.push(choices[key]);
            }
            return null;
        });

        const a: any = [];
        a.push({
            pollId: pollId,
            teamId: teamId.teamId,
        });
        const teamObj2 = {
            teams: a,
            token: mycontext.token,
        };
        const resp = await createPollTeam(teamObj2);
        if (resp.error) {
            setOpen(true);
            setSeverity('error');
            setAlertMessage('Failed to create poll!');
        }
        setOpen(true);
        setSeverity('success');
        setAlertMessage('Polls created successfully!');
        const isBotAdded = memberProfile.empTeams.usermanagementdb_Team.some(
            (item: any) => item.isbotAdded && item.isbotAdded.polls,
        );

        if (isBotAdded) {
            await pollBot({
                pollData: pollData,
                pollId: pollId,
            });
        }

        // if ('polls' in memberProfile?.empTeams?.usermanagementdb_Team[0].isbotAdded) {
        //     await pollBot({
        //         pollData: pollData,
        //         pollId: pollId,
        //     });
        // }

        setTimeout(() => props.setStep(0), 1000);
    };

    const PreviewandSubmit = async () => {
        try {
            const pollSubscriptions = subDetails.subscription.usermanagementdb_Subscription.filter((sub: any) =>
                sub.planId.startsWith('pbtp'),
            );

            if (pollSubscriptions.length === 0) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }

            const license = subDetails.userSubscriptions.usermanagementdb_tenant_employee[0].pollSubscriptionId;

            if (!license) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }

            const activeSub = pollSubscriptions.some((sub: any) => {
                if (sub.subscriptionId === license) {
                    if (sub.status === 'Subscribed') {
                        return true;
                    } else if (sub.status === 'Suspended') {
                        throw new Error(
                            'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                        );
                    } else if (sub.status === 'Unsubscribed') {
                        throw new Error(
                            'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                        );
                    }
                }
                return false;
            });

            if (!activeSub) {
                throw new Error('Somthing went wrong, please try again.');
            }
            const arr: any = [];

            Object.keys(choices).map((key: any, value: any) => {
                if (choices[key] !== '') {
                    arr.push(choices[key]);
                }
                return null;
            });

            if (question === '' || pollrespond === '' || viewedPoll === '') {
                throw new Error('All fields are mandatory');
            }

            arr.filter((c: any, index: any) => {
                if (arr.indexOf(c) !== index) {
                    throw new Error('Please add different choices');
                }
                return null;
            });

            if (arr.length < 2) {
                throw new Error('Please select atleast 2 choices!');
            }
            if (teamId.teamId === '' || teamId.slackTeamId === '') {
                throw new Error('Please select a team!');
            }
            const obj = {
                action: 'create',
                question,
                pollrespond,
                isAnonymous: viewedPoll === 'Anonymous',
                isSingleVote: typerespond === 'Single',
                // timeend,
                choices: arr,
                createdOn: toAWSDateFormat(new Date()),
                organizationId: mycontext.organizationId,
                createdBy: mycontext.EmployeeId,
                Employee: {
                    firstName: mycontext.fname,
                    lastName: mycontext.lname,
                },
                conversationId: teamId.msChannelId,
                pollEndTime: timeend,
                token: mycontext.token,
                pollEndsDateTime: localToISO(
                    getCurrentCountryDateTime(mycontext.country.zoneName).date,
                    timeend,
                    mycontext.country,
                ),
            };
            setPollData(obj);
            setModal(true);
        } catch (err) {
            setOpen(true);
            setSeverity('error');
            setAlertMessage(err.message);
            return err;
        }
    };
    const handleQuestionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDisable(false);
        setQuestion(event.target.value as string);
    };
    const handlepollrespondChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDisable(false);
        setPollrespond(event.target.value as string);
    };
    const handleviewedpoll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisable(false);
        setViewePoll((event.target as HTMLInputElement).value);
    };
    const handletyperespond = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisable(false);
        setTyperespond((event.target as HTMLInputElement).value);
    };
    const handlepolltimeendChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDisable(false);
        setTimeend(event.target.value as string);
    };

    // if (modal) {
    //     return (
    //         <PollsModal
    //             data={pollData}
    //             handleSubmit={handleSubmit}
    //             open={modal}
    //             setOpen={setModal}
    //             setStep={() => props.setStep(0)}
    //         />
    //     );
    // console.log(getCurrentCountryDateTime(mycontext.country.zoneName).time.toString());
    // }
    return (
        <>
            <PopUp open={open} setOpen={setOpen} message={alertMessage} severity={severity} />
            <CreatePoll setStep={props.setStep} />
            <div>
                <Paper style={{ padding: '0px 20px 32px 20px', minHeight: 'calc(100vh - 130px)' }}>
                    <Hidden mdDown>
                        <Paper elevation={0} className={classes.paperstyle}>
                            <Grid container>
                                <Grid item>
                                    <ArrowBackIcon className={classes.backArrow} onClick={() => props.setStep(0)} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title}>Create New Poll</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Hidden>
                    <div className={classes.newDivStyle}>
                        <TextFieldView
                            question={question}
                            handleQuestionChange={handleQuestionChange}
                            mycontext={mycontext}
                            pollrespond={pollrespond}
                            handlepollrespondChange={handlepollrespondChange}
                            memberProfile={memberProfile}
                            setTeamId={setTeamId}
                            teamId={teamId.teamId}
                            choices={choices}
                            handleInputChange={handleInputChange}
                            addmore={addmore}
                            viewedPoll={viewedPoll}
                            handleviewedpoll={handleviewedpoll}
                        />
                        <ResponseTimePart
                            typerespond={typerespond}
                            handletyperespond={handletyperespond}
                            timeend={timeend}
                            handlepolltimeendChange={handlepolltimeendChange}
                        />
                    </div>
                    <ButtonPart disable={disable} handleReset={handleReset} PreviewandSubmit={PreviewandSubmit} />
                </Paper>
            </div>
            <PollsModal
                data={pollData}
                handleSubmit={handleSubmit}
                open={modal}
                setOpen={setModal}
                timeend={timeend}
                typerespond={typerespond}

                // setStep={() => props.setStep(0)}
            />
        </>
    );
}
