import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 200;
const drawerWidth2 = 20;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        first: {
            width: '100%',
            height: '100vh',
            // fontFamily: 'Rye',
            backgroundColor: theme.palette.primary.light,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '@media screen and (max-width: 650px)': {
                width: '0%',
            },
        },
        mobileView: {
            width: 300,
            // paddingTop: 10,
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: theme.palette.action.active,
            background: 'url("sidebar_bg.png")',
            backgroundPosition: 'bottom',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 99,
            transition: '0.5s',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
                height: 20,
            },
        },
        hideView: {
            width: 300,
            paddingTop: 10,
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: -300,
            backgroundColor: theme.palette.primary.light,
            background: 'url("sidebar_bg.png")',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 99,
            transition: '0.5s',
        },
        hr: {
            height: 1,
            width: '80%',
            background: theme.palette.text.secondary,
        },
        toggleDiv: {
            width: '100%',
            height: '70px',
            position: 'relative',
            marginTop: '50px',
            // backgroundColor: 'red',
        },
        toggleButton: {
            position: 'absolute',
            backgroundColor: theme.palette.background.default,
            // backgroundColor: 'green',
            // top: '20px',
            margin: 'auto 0',
            right: 0,
            width: '20px',
            height: '70px',
        },
        toggleTop: {
            width: '100%',
            height: '40%',
            borderRadius: '0 0 15px 0',
            backgroundColor: theme.palette.primary.light,
            // backgroundColor: 'black',
        },
        toggleBottom: {
            width: '100%',
            height: '40%',
            borderRadius: '0 15px 0 0',
            backgroundColor: theme.palette.primary.light,
            // backgroundColor: 'grey',
        },
        toggleBtn: {
            width: '100%',
            height: '30%',
            zIndex: 999,
            backgroundColor: theme.palette.primary.light,
            position: 'relative',
        },
        toggleBtnIcon: {
            position: 'absolute',
            width: '25px',
            height: '25px',
            borderRadius: '35px 0 0 35px',
            left: '2px',
            top: '-2px',
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            fontSize: 12,
            fontWeight: 600,
        },
        second: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '200px',
            height: 'auto',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            paddingTop: 5,
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            // justifyContent: 'center',
            '@media screen and (max-width: 650px)': {
                width: '0%',
            },
        },
        hideSecond: {
            width: 200,
            paddingTop: 10,
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: -200,
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'row',
            zIndex: 99,
        },
        hideSecond1: {
            position: 'fixed',
            top: 0,
            left: -50,
            width: '50px',
            height: 'auto',
            background: theme.palette.secondary.main,
            '@media screen and (max-width: 650px)': {
                width: '0%',
            },
        },
        second1: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '50px',
            height: 'auto',
            paddingTop: 5,
            background: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '@media screen and (max-width: 650px)': {
                width: '0%',
            },
        },
        root: {
            width: '250px',
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
        },
        title: {
            flexGrow: 5,
            color: theme.palette.primary.main,
            textAlign: 'center',
        },
        drawer: {
            position: 'relative',
            top: 0,
            left: '50px',
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawer2: {
            width: drawerWidth2,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
                zIndex: -1,
                '@media screen and (max-width: 1020px) and (min-width: 651px)': {
                    width: 200,
                },
            },
            '@media screen and (max-width: 1020px) and (min-width: 651px)': {
                width: 200,
            },
        },
        drawerOpen: {
            width: drawerWidth,
            backgroundColor: theme.palette.secondary.main,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerOpen2: {
            width: drawerWidth2,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            '@media screen and (max-width: 1020px) and (min-width: 651px)': {
                width: 200,
            },
        },
        drawerClose: {
            backgroundColor: theme.palette.secondary.main,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        side: {
            marginTop: '100px',
        },
        link: {
            color: 'white',
            '&:hover': {
                color: 'white',
                textDecoration: 'none',
            },
        },
        shiftIcon: {
            justifyContent: 'center',
            '&:hover': {
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
            '&:active': {
                outline: 'none',
            },
        },
        menuButton: {
            marginRight: 36,
        },
        menuButtonHidden: {
            display: 'none',
        },

        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer is closed
        },
        iconDiv: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50px',
            height: '100px',
            cursor: 'pointer',
            position: 'relative',
            right: 0,
            top: '50%',
        },
        icondiv: {
            position: 'relative',
            width: '1%',
            height: '100vh',
            background: 'transparent',
        },
        innerIcon: {
            width: '30px',
            height: '50px',
            borderRadius: 5,
            background: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            position: 'absolute',
            top: '45%',
            right: -25,
            zIndex: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        topDiv: {
            height: 60,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.light,
            padding: '25px',
        },
        topImg: {
            maxWidth: '100%',
            height: 34,
        },
        fieldDivDateTime: {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: 10,
            marginBottom: '15px',
            '@media screen and (max-width: 820px)': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
        },
        reporttitle: {
            color: theme.palette.text.primary,
            fontWeight: 600,
            fontSize: '14px',
            textTransform: 'capitalize',
            paddingBottom: '5px',
        },
        inputSelectStyleOther: {
            width: 215,
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: 'theme.palette.text.primary',
            // border: '1px solid black',
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
            '@media screen and (max-width: 1024px)': {
                width: 165,
            },
        },
        formControl: {
            width: '100%',

            // alignItems: 'center'
        },
        mobileToPart: {
            marginTop: 0,
            '@media screen and (max-width: 768px)': {
                marginTop: 15,
            },
        },
        newButtonGroup: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        buttonStyle: {
            whiteSpace: 'nowrap',
            width: 100,
            height: 35,
            marginTop: 10,
            marginRight: 10,
            // border: `1px solid ${theme.palette.text.secondary}`,
            '& span': {
                fontWeight: 'bold',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                letterSpacing: '0.05em',
                color: theme.palette.text.secondary,
            },
        },
        resetButton: {
            '&:focus': {
                outline: 'none',
            },
        },
        btn2: {
            float: 'right',
            marginTop: 10,
            textTransform: 'capitalize',
            width: 100,
            height: 35,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            fontWeight: 'bold',
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            // '@media screen and (min-width: 768px) and (max-width: 1180px)': {
            //     width: 150,
            //     height: 35,
            //     marginTop: 5,
            //     marginRight: 10
            // },
        },
        outerdiv: {
            border: '1.5px solid white',
            width: '40px',
            height: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',

            '&:focus': {
                border: '1.5px solid red',
            },
        },
        btn1: {
            border: 'none',
            '&:focus': {
                outline: 'none',
                border: 'none',
            },

            // border: '1.5px solid white',
            width: '40px',
            height: '35px',
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            // borderRadius: '5px',
        },
        btn: {
            color: 'white',
            border: '1.5px solid #FFFFFF',
            fontWeight: 600,
            width: '112px',
            height: '35px',
            '&:focus': {
                // border: 'none',
                outline: 'none!important',
                // backgroundColor: 'white',
                // color: theme.palette.primary.main
            },
        },
    }),
);

export default useStyles;
