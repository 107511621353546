// import { fetchSlackChannel } from './hasuraapi2';

export const handleShoutout = (
    shoutoutData: any,
    shoutoutEmployee: any,
    objShoutoutEmpTeam: any,
    allEmpsTeam: any,
    empOrTeam: any,
    isPersonal: any,
) => {
    try {
        if (empOrTeam === 'employee' && isPersonal === 'emp') {
            // console.log('sending shoutout notif to employee');
            const data = {
                shoutoutData: shoutoutData,
                shoutoutEmployee: shoutoutEmployee,
            };
            fetch(
                'https://sls-swec-dev-engagebyteamplusprod.azurewebsites.net/api/sendMsgToEmp?code=2_1OEI0DbX5MyU3GMQ-ytFokAmioN4dGMvmMptvUkDMSAzFuSUxVfw==',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                },
            );
        } else {
            // console.log('sending shoutout to team for emp or team');
            const ob = {
                createdBy: objShoutoutEmpTeam.createdBy,
                createdFor: allEmpsTeam.toString(),
                isPersonal: false,
                tags: shoutoutData.tags,
                emoji: {
                    activeSkinTone: 'neutral',
                    emoji: shoutoutData.emoji.emoji,
                    originalUnified: '1f64c',
                    unified: '1f64c',
                },
                message: shoutoutData.message,
                shoutoutId: shoutoutData.shoutoutId,
                action: 'post',
            };
            const data = {
                shoutoutData: shoutoutData,
                shoutoutEmployee: shoutoutEmployee,
                ob: ob,
                shoutoutFor: empOrTeam === 'employee' ? 'employee' : 'team',
            };
            fetch(
                'https://sls-swec-dev-engagebyteamplusprod.azurewebsites.net/api/sendMsgToTeam?code=VzRGP8lcp2o4SbH1jyzZHroyUpVRoH-869kFCtHSYjf-AzFulOldmw==',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                },
            );
        }
        return;
    } catch (err) {
        return { error: err };
    }
};

// export const handleSlackShoutoutUI = async (obj: any) => {
//     const orgInfo = await fetchSlackChannel(obj.orgId, obj.empId, obj.token);
//     if (
//         orgInfo?.usermanagementdb_Organization[0].engageChannel.isIntegrated === true &&
//         orgInfo?.usermanagementdb_SlackBot.length !== 0 &&
//         orgInfo?.usermanagementdb_Organization[0].organization_employees[0].slackId !== null
//     ) {
//         if (obj.action === 'add' || obj.action === 'update') {
//             obj.data.botToken = orgInfo?.usermanagementdb_SlackBot[0].engageToken;
//             try {
//                 fetch('https://j0cm77ko20.execute-api.eu-north-1.amazonaws.com/production/displayShoutout', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({
//                         data: obj.data,
//                         shoutoutId: obj.shoutoutId,
//                         teamMsg: obj.teamMsg,
//                         token: obj.token,
//                         oldIsPersonal: obj.oldIsPersonal,
//                         action: obj.action,
//                         requester: obj.requester,
//                         teamName: obj.teamName,
//                     }),
//                 });
//                 return true;
//             } catch (err) {
//                 return err;
//             }
//         } else {
//             fetch('https://j0cm77ko20.execute-api.eu-north-1.amazonaws.com/production/displayShoutout', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     data: {
//                         channel: orgInfo?.usermanagementdb_Organization[0].engageChannel.channel,
//                         ts: obj.ts,
//                         requester: obj.requester,
//                         botToken: orgInfo?.usermanagementdb_SlackBot[0].engageToken,
//                     },
//                 }),
//             });
//             return true;
//         }
//     }
// };
