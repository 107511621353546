/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import {
    Avatar,
    Paper,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup,
    Checkbox,
    Button,
    Hidden,
} from '@material-ui/core';
import useStyles from './employeePollStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Countdown from 'react-countdown';
import AuthContext from '../../context/AuthContext';
import Comment from './comments';
import comment from './comment.png';
import { createChoices, updateChoices, fetchPollResult } from '../../helpers/hasuraapi2';
import icon from './Vector.png';
import { getFullName } from 'helpers/getInitials';
import { PopUp } from '../Modal/Modal';
import { isoToLocal } from '../../helpers/timezone';
import { ToStringFormat } from '../../helpers/date';

interface HeaderProps {
    handleClick: any;
    setStep: any;
}

export const HeaderPart = (props: HeaderProps) => {
    const classes = useStyles();

    return (
        <>
            <Hidden smDown>
                <Paper elevation={0}>
                    <div>
                        <Grid container className={classes.paper1}>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <ArrowBackIcon
                                            className={classes.backArrow}
                                            data-testid="Arrow"
                                            onClick={() => props.setStep(0)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.poll}>Respond to Poll</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button className={classes.btn} onClick={props.handleClick}>
                                    Comment
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </Hidden>
        </>
    );
};

export const AvtarComponents = (props?: { pollsData: any }) => {
    const classes = useStyles();
    return (
        <>
            <Grid container className={classes.main}>
                <Grid item>
                    <Grid container direction="row" style={{ padding: 8 }}>
                        <div>
                            {props?.pollsData.Employee.profileImage !== null ? (
                                <Avatar
                                    src={props?.pollsData?.Employee?.profileImage}
                                    alt="profile"
                                    className={classes.avatarimage}
                                />
                            ) : (
                                <Avatar className={classes.avatarimage}>
                                    {getFullName(
                                        props?.pollsData?.Employee?.firstName +
                                            ' ' +
                                            props?.pollsData?.Employee?.lastName,
                                    )}
                                </Avatar>
                            )}
                        </div>
                        <div className={classes.item}>
                            {props?.pollsData?.Employee?.firstName} {props?.pollsData?.Employee?.lastName}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

interface ChoiceProps {
    pollsData?: any;
    renderer?: any;
}

export const ChoicePart2 = (props?: ChoiceProps) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    return (
        <>
            <div>
                <Grid container direction="row" data-testid="poll">
                    <Grid item xs={12} sm={12} md={9}>
                        <span className={classes.heading}>Teams</span>
                        {props?.pollsData.poll_teams.length !== 1 ? (
                            <p className={classes.center}>All</p>
                        ) : (
                            <p className={classes.center}>{props?.pollsData?.poll_teams[0]?.Team?.teamName}</p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <span className={classes.heading}>Remaining Time</span>
                        {props?.pollsData.pollEndTime === null ? (
                            <div className={classes.subheading}>
                                <span className="iconify" data-icon="bi:dash" style={{ width: 25, height: 25 }}></span>
                            </div>
                        ) : (
                            <p className={classes.subheading}>
                                <Countdown
                                    daysInHours={true}
                                    // date={new Date(`${props?.pollsData.createdOn}T${props?.pollsData.pollEndTime}`)}
                                    date={props?.pollsData?.pollEndsDateTime}
                                    renderer={t => props?.renderer(t)}
                                />
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={9} data-testid="questions">
                        <span className={classes.heading}>Question</span>
                        <p style={{ maxWidth: '95%', wordWrap: 'break-word' }} className={classes.subheading}>
                            {props?.pollsData?.question}
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <span className={classes.heading}>Created On</span>
                        <p className={classes.center}>
                            {ToStringFormat(
                                isoToLocal(props?.pollsData?.pollEndsDateTime, mycontext.country)
                                    .toString()
                                    .substring(3, 15),
                                mycontext.dateFormat,
                            )}
                        </p>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export const ButtonPart = (props?: { data: any; handleSubmit2: any; handleSubmit: any }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.btnMain}>
                {props?.data.length !== 0 ? (
                    <Button
                        onClick={() => props?.handleSubmit2()}
                        variant="contained"
                        color="primary"
                        className={classes.btn1}
                    >
                        Update
                    </Button>
                ) : (
                    <Button
                        onClick={() => props?.handleSubmit()}
                        variant="contained"
                        color="primary"
                        className={classes.btn1}
                    >
                        Submit
                    </Button>
                )}
            </div>
        </>
    );
};

interface RadioProps {
    pollsData?: any;
    value?: any;
    handleChange?: any;
    handleMultiChange?: any;
}

export const RadioButtonComp = (props: RadioProps) => {
    const classes = useStyles();

    return (
        <>
            <div>
                <span className={classes.choices}>Choices</span>
                {props?.pollsData?.isSingleVote === true ? (
                    <RadioGroup value={props?.value[0]} onChange={props.handleChange}>
                        {props.pollsData?.adminChoices.map((v: any, i: number) => (
                            <FormControlLabel
                                key={i}
                                value={v}
                                control={<Radio className={classes.btn2} checked={props.value[0] === v} />}
                                label={<span className={classes.subheading}>{v}</span>}
                            />
                        ))}
                    </RadioGroup>
                ) : (
                    <FormGroup>
                        {props?.pollsData?.adminChoices.map((v: string, i: number) => (
                            <FormControlLabel
                                key={i}
                                value={v}
                                control={
                                    <Checkbox
                                        className={classes.btn2}
                                        onChange={props?.handleMultiChange}
                                        checked={props?.value.includes(v)}
                                    />
                                }
                                label={<span className={classes.subheading}>{v}</span>}
                            />
                        ))}
                    </FormGroup>
                )}
            </div>
        </>
    );
};

export default function ReplyPoll(props: { setStep: any }) {
    const classes = useStyles();
    // const history = useHistory();
    // const location: any = useLocation();
    // const pollsData: any = location.state?.poll;
    const mycontext: any = useContext(AuthContext);
    const pollsData = mycontext.currentPoll;
    // const filteredChoice = pollsData.);
    // console.log(filteredChoice);
    const [value, setValue] = React.useState<string[]>([]);
    const abcd = useSelector((state: any) => state.polls);
    // console.log('pollsData', pollsData);
    // const mycontext = useContext(AuthContext);
    const [data, setData] = React.useState<any>([]);
    const [step, setStep] = React.useState(0);
    const [alert, setAlert] = React.useState(false);
    const [popup, setPopup] = React.useState({
        alertMessage: '',
        severity: '',
    });

    const subDetails = useSelector((state: any) => state.subscription);

    React.useEffect(() => {
        async function fetchAll() {
            const data = await fetchPollResult(pollsData.pollId, mycontext.token);
            // console.log(data);
            setData(data.filter((d: any) => d.Employee.employeeId === mycontext.EmployeeId));
            setValue(
                data.filter((d: any) => d.Employee.employeeId === mycontext.EmployeeId).length > 0
                    ? data.filter((d: any) => d.Employee.employeeId === mycontext.EmployeeId)[0]?.userChoice
                    : [],
            );
        }
        fetchAll();
    }, [pollsData, mycontext]);
    const handleChange = (event: any) => {
        setValue([event.target.value]);
    };

    const handleMultiChange = (event: any) => {
        const data = event.target.value;
        const index = value.indexOf(data);
        if (index > -1) {
            setValue([...value.slice(0, index), ...value.slice(index + 1)]);
        } else {
            setValue([...value, ...[data]]);
        }
    };
    const handleSubmit = async () => {
        try {
            const pollSubscriptions = subDetails.subscription.usermanagementdb_Subscription.filter((sub: any) =>
                sub.planId.startsWith('pbtp'),
            );

            if (pollSubscriptions.length === 0) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }

            const license = subDetails.userSubscriptions.usermanagementdb_tenant_employee[0].pollSubscriptionId;

            if (!license) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }

            const activeSub = pollSubscriptions.some((sub: any) => {
                if (sub.subscriptionId === license) {
                    if (sub.status === 'Subscribed') {
                        return true;
                    } else if (sub.status === 'Suspended') {
                        throw new Error(
                            'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                        );
                    } else if (sub.status === 'Unsubscribed') {
                        throw new Error(
                            'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                        );
                    }
                }
                return false;
            });

            if (!activeSub) {
                throw new Error('Somthing went wrong, please try again.');
            }
            const a: any = abcd?.polls.map((b: any) => b?.pollId);

            if (a.indexOf(pollsData.pollId) === -1) {
                throw new Error('Poll does not exist it was deleted by creator');
            }
            if (value.length === 0) {
                throw new Error('Please Select Choices');
            }
            const obj = {
                pollId: pollsData.pollId,
                employeeId: mycontext.EmployeeId,
                userChoices: value,
                token: mycontext.token,
            };
            const resp = await createChoices(obj);
            if (resp.error) {
                throw new Error('failed to create Choices!');
            }
            setAlert(true);
            setPopup({
                alertMessage: 'Choices created successfully!',
                severity: 'success',
            });
            setTimeout(() => props.setStep(0), 2000);
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };
    const handleSubmit2 = async () => {
        try {
            const pollSubscriptions = subDetails.subscription.usermanagementdb_Subscription.filter((sub: any) =>
                sub.planId.startsWith('pbtp'),
            );

            if (pollSubscriptions.length === 0) {
                throw new Error('Sorry, this feature is for subscribers only. Upgrade now for full app access');
            }

            const license = subDetails.userSubscriptions.usermanagementdb_tenant_employee[0].pollSubscriptionId;

            if (!license) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use Polls by TeamPlus, kindly request your team admin to assign you a license",
                );
            }

            const activeSub = pollSubscriptions.some((sub: any) => {
                if (sub.subscriptionId === license) {
                    if (sub.status === 'Subscribed') {
                        return true;
                    } else if (sub.status === 'Suspended') {
                        throw new Error(
                            'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                        );
                    } else if (sub.status === 'Unsubscribed') {
                        throw new Error(
                            'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                        );
                    }
                }
                return false;
            });

            if (!activeSub) {
                throw new Error('Somthing went wrong, please try again.');
            }
            const obj = {
                pollId: pollsData.pollId,
                employeeId: mycontext.EmployeeId,
                userChoices: value,
                token: mycontext.token,
            };
            // console.log(obj);
            const resp = await updateChoices(obj);
            if (resp.error) {
                throw new Error('failed to update choices!');
            }
            // history.push('/polls');
            setAlert(true);
            setPopup({
                alertMessage: 'Choices updated successfully!',
                severity: 'success',
            });
            setTimeout(() => props.setStep(0), 2000);
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };

    const handleClick = () => {
        const a: any = abcd?.polls?.map((b: any) => b?.pollId);
        try {
            if (a?.indexOf(pollsData.pollId) === -1) {
                throw new Error('Poll does not exist it was deleted by creator');
            }
            setStep(1);
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };

    const renderer = (props: any) => {
        if (props.completed) return <div>Expired</div>;
        if (props.hours === 0 && props.minutes === 0) return <div>{props.seconds} sec</div>;
        if (props.hours === 0)
            return (
                <div>
                    {props.minutes} mins {props.seconds} sec
                </div>
            );
        return (
            <div>
                {props.hours} hrs {props.minutes} mins
            </div>
        );
    };
    if (step === 1) {
        return <Comment setStep={setStep} pollId={pollsData.pollId} />;
    }
    return (
        <>
            <HeaderPart setStep={props.setStep} handleClick={handleClick} />
            <PopUp open={alert} message={popup.alertMessage} severity={popup.severity} setOpen={setAlert} />
            <Hidden mdUp>
                <Grid container className={classes.paper1}>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <ArrowBackIcon className={classes.backArrow} onClick={() => props.setStep(0)} />
                            </Grid>
                            <Grid item>
                                <div className={classes.poll}>Respond to Poll</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <img
                            // style={{ position: 'relative', right: 30 }}
                            src={comment}
                            alt="backArrow"
                            onClick={handleClick}
                        />
                    </Grid>
                </Grid>
            </Hidden>
            <div className={classes.border}>
                <Paper className={classes.paper}>
                    <div>
                        <AvtarComponents pollsData={pollsData} />
                        <div className={classes.maindiv}>
                            {pollsData?.isAnonymous === true && (
                                <Grid container>
                                    <Grid item className={classes.hidden}>
                                        &nbsp;&nbsp;
                                        <img src={icon} alt="icon" />
                                        &nbsp;&nbsp;
                                        <div>
                                            This poll is anonymous. The identity of all responeses will be hidden.&nbsp;
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                            <ChoicePart2 pollsData={pollsData} renderer={renderer} />
                            <RadioButtonComp
                                pollsData={pollsData}
                                handleChange={handleChange}
                                value={value}
                                handleMultiChange={handleMultiChange}
                            />
                            <ButtonPart data={data} handleSubmit2={handleSubmit2} handleSubmit={handleSubmit} />
                        </div>
                    </div>
                </Paper>
            </div>
        </>
    );
}
