/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import DisplayLeaves from './DisplayLeaves';
import { fetchTimeoffStatus, updateLeaveAdmin, updateLeaveAdminMultiple } from '../../helpers/hasuraapi';
import { Typography, Button, TextField, useTheme } from '@material-ui/core';
import AuthContext from '../../context/AuthContext';
import AddLeave from './AddLeave';
import useStyles from './otherStyles';
import useStyles2 from './DashboardStyle';
import {
    // DateDifference,
    toAWSDateFormat,
} from '../../helpers/date';
import { Accordians } from './DashboardPart';
import Modal, { PopUp } from '../Modal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { updateActiveTab } from '../Redux/activeTab';
import { LoaderPage } from '../Loader/loader';
import ErrorPage from '../Error/ErrorPage';
import { getTotalLeaveCount, getLeaveCountToday } from 'components/Redux/employeeStatusReducer';
// import {
//     sendNotificationToMembers,
//     sendNotificationToSelf,
//     sendDenyNotificationToSelf,
//     getTokenFromLeave,
// } from '../AppNotification/AppNotification';
import {
    sendNotificationToEmps,
    sendApproveNotifToSelf,
    sendDeniedNotifToSelf,
    getDenyTokenFromLeave,
    getMultiDenyTokenFromLeave,
    storeApproveNotif,
    storeDenyNotif,
} from '../WebNotification/Notification';

import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import { handleNotif, handleMsNotification } from 'helpers/sendNotif';
// import { getCurrentCountryDate, isoToLocal } from 'helpers/timezone';

export default function ApproverDashboard() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const classes2 = useStyles2();
    const state: any = useSelector(state => state);
    const userSubscriptionDetails = state?.subscription.userSubscriptions;
    const mycontext = useContext(AuthContext);
    const [step, setStep] = useState<number>(0);

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [open, setOpen] = useState<boolean>(false);
    const [openA, setOpenA] = useState<boolean>(false);

    const [currentLeave, setCurrentLeave] = useState<any>({});
    const [multipleLeave, setMultipleLeave] = useState<any>({});

    const orgEmployees = state.orgEmployees.employees;
    const approvers = state.orgEmployees.approvers;
    const holidays = state.holidays.holidays;
    const todaysLeaves = state.dashboardLeaves?.todaysLeaves;
    const pendingLeaves = state.approverDashboardLeaves?.leaves;
    const upcomingLeaves = state.dashboardLeaves?.upcomingLeaves;
    const leaveTypes = state.leaveTypes.lTypes;
    const formDetails = state.formDetails.details;
    const orgLeave = state.orgLeaves.orgleaves;
    const empLeave = state.empOnLeaves.empOnleaves;
    const leaves = state.employeeLeaves?.leaves;

    const data = [
        { name: 'Paid Leaves', value: 40 },
        { name: 'Casual Leaves', value: 30 },
        { name: 'Sick Leaves', value: 20 },
    ];
    const COLORS = ['#956FE4', '#587EE0', '#4FB8B2', '#F2A142', '#a0d568', '#ed5564', '#ac92eb', '#20639b'];

    // function refreshPage() {
    //     window.location.reload();
    // }

    const handleApprove = async (leave: any) => {
        try {
            const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const obj = {
                leaveId: leave.leaveId,
                approverId: mycontext.EmployeeId,
                denialReason: null,
                denierId: mycontext.EmployeeId,
                leaveStatus: 'approved',
                token: mycontext.token,
            };
            const resp = await updateLeaveAdmin(obj, mycontext.isAdmin);
            if (resp?.errors) {
                setAlert(true);
                setAlertMessage('Failed to approved leave!');
                setSeverity('error');
                return;
            }
            setAlert(true);
            setAlertMessage('Request approved successfully!');
            setSeverity('success');

            //web notification
            const dateString = new Date().toString();
            const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
            const time = new Date().toString().slice(16, 24);
            const dateTime = date + time;

            const notifObj = {
                startDate: leave.startDate,
                endDate: leave.endDate,
                startTime: leave.startTime,
                endTime: leave.endTime,
                fname: leave.Employee.firstName,
                lname: leave.Employee.lastName,
                afname: mycontext.fname,
                alname: mycontext.lname,
                empId: leave.employeeId,
                leaveId: leave.leaveId,
                orgId: mycontext.organizationId,
                notifDate: dateTime,
                token: mycontext.token,
                approverId: mycontext.EmployeeId,
            };
            await storeApproveNotif(notifObj);
            // handleNotification({
            //     empId: leave.employeeId,
            //     actorId: mycontext.EmployeeId,
            //     orgId: mycontext.organizationId,
            //     leaveId: leave.leaveId,
            //     token: mycontext.token,
            //     startDate: leave.startDate,
            //     endDate: leave.endDate,
            //     startTime: leave.startTime,
            //     endTime: leave.endTime,
            //     isoString: leave.isoString,
            //     category: resp.category,
            //     notif_type: 'approveRequest',
            // });
            sendNotificationToEmps(
                leave.employeeId,
                leave.Employee.firstName,
                leave.Employee.lastName,
                leave.startDate,
                leave.endDate,
                leave.startTime,
                leave.endTime,
                mycontext.organizationId,
                mycontext.token,
            );
            sendApproveNotifToSelf(
                leave.employeeId,
                mycontext.organizationId,
                mycontext.token,
                leave.startDate,
                leave.endDate,
                leave.startTime,
                leave.endTime,
                mycontext.fname,
                mycontext.lname,
            );

            // await sendNotificationToMembers(
            //     leave.employeeId,
            //     leave.Employee.firstName,
            //     leave.startDate,
            //     leave.endDate,
            //     leave.startTime,
            //     leave.endTime,
            //     mycontext.organizationId,
            //     mycontext.token,
            // );
            // await sendNotificationToSelf(
            //     leave.employeeId,
            //     leave.startDate,
            //     leave.endDate,
            //     leave.startTime,
            //     leave.endTime,
            //     mycontext.token,
            // );
            handleMsNotification({
                empId: leave.employeeId,
                actorId: mycontext.EmployeeId,
                orgId: mycontext.organizationId,
                leaveId: leave.leaveId,
                token: mycontext.token,
                startDate: leave.startDate,
                endDate: leave.endDate,
                startTime: leave.startTime,
                isoString: leave.isoString,
                endTime: leave.endTime,
                category: leave.category,
                // isoString: leave.isoString,
                notif_type: 'approveRequest',
            });
            setTimeout(() => {
                if (leave.startDate <= toAWSDateFormat(new Date()) && leave.endDate >= toAWSDateFormat(new Date())) {
                    dispatch(
                        getTotalLeaveCount({
                            organizationId: mycontext.organizationId,
                            token: mycontext.token,
                        }),
                    );
                    dispatch(
                        getLeaveCountToday({
                            organizationId: mycontext.organizationId,
                            token: mycontext.token,
                            date: toAWSDateFormat(new Date()),
                        }),
                    );
                }
            }, 1000);
        } catch (err) {
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    const handleDeny = (leave: any) => {
        setOpen(true);
        setCurrentLeave(leave);
    };

    const handleDenyAll = (leave: any) => {
        setMultipleLeave(leave);
        setOpenA(true);
    };

    const handleModal = async (reason: string) => {
        try {
            const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const leaveData = await fetchTimeoffStatus(currentLeave.leaveId, mycontext.token);
            let prevStatus = 'pending';

            if (leaveData !== null) {
                prevStatus = leaveData[0].leaveStatus;
            }

            const obj = {
                leaveId: currentLeave.leaveId,
                approverId: mycontext.EmployeeId,
                denialReason: reason.split('\n').join(' '),
                denierId: mycontext.EmployeeId,
                leaveStatus: 'denied',
                token: mycontext.token,
            };
            const resp = await updateLeaveAdmin(obj, mycontext.isAdmin);
            if (resp?.errors) {
                setOpen(false);
                setAlert(true);
                setAlertMessage('Failed to deny leave!');
                setSeverity('error');
                return;
            }
            setOpen(false);
            setAlert(true);
            setAlertMessage(`${currentLeave.category} Request denied successfully!`);

            // await sendDenyNotificationToSelf(
            //     currentLeave.employeeId,
            //     currentLeave.startDate,
            //     currentLeave.endDate,
            //     currentLeave.startTime,
            //     currentLeave.endTime,
            //     mycontext.token,
            // );
            setSeverity('success');

            //web notification
            const dateString = new Date().toString();
            const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
            const time = new Date().toString().slice(16, 24);
            const dateTime = date + time;

            const notifObj = {
                startDate: currentLeave.startDate,
                endDate: currentLeave.endDate,
                startTime: currentLeave.startTime,
                endTime: currentLeave.endTime,
                fname: mycontext.fname,
                lname: mycontext.lname,
                empId: currentLeave.employeeId,
                leaveId: currentLeave.leaveId,
                orgId: mycontext.organizationId,
                notifDate: dateTime,
                token: mycontext.token,
                approverId: mycontext.EmployeeId,
            };
            await storeDenyNotif(notifObj);
            // handleNotification({
            //     empId: currentLeave.employeeId,
            //     actorId: mycontext.EmployeeId,
            //     orgId: mycontext.organizationId,
            //     leaveId: currentLeave.leaveId,
            //     token: mycontext.token,
            //     startDate: currentLeave.startDate,
            //     endDate: currentLeave.endDate,
            //     startTime: currentLeave.startTime,
            //     endTime: currentLeave.endTime,
            //     category: currentLeave.category,
            //     isoString: currentLeave.isoString,
            //     prevStatus: prevStatus,
            //     notif_type: 'denyRequest',
            //     action: 'deny',
            // });
            sendDeniedNotifToSelf(
                currentLeave.employeeId,
                mycontext.organizationId,
                mycontext.token,
                currentLeave.startDate,
                currentLeave.endDate,
                currentLeave.startTime,
                currentLeave.endTime,
                mycontext.fname,
                mycontext.lname,
            );
            handleMsNotification({
                empId: currentLeave.employeeId,
                actorId: mycontext.EmployeeId,
                orgId: mycontext.organizationId,
                leaveId: currentLeave.leaveId,
                token: mycontext.token,
                startDate: currentLeave.startDate,
                endDate: currentLeave.endDate,
                startTime: currentLeave.startTime,
                endTime: currentLeave.endTime,
                category: currentLeave.category,
                isoString: currentLeave.isoString,
                prevStatus: prevStatus,
                notif_type: 'denyRequest',
            });
            setTimeout(() => {
                if (
                    currentLeave.startDate <= toAWSDateFormat(new Date()) &&
                    currentLeave.endDate >= toAWSDateFormat(new Date())
                ) {
                    dispatch(
                        getTotalLeaveCount({
                            organizationId: mycontext.organizationId,
                            token: mycontext.token,
                        }),
                    );
                    dispatch(
                        getLeaveCountToday({
                            organizationId: mycontext.organizationId,
                            token: mycontext.token,
                            date: toAWSDateFormat(new Date()),
                        }),
                    );
                }
            }, 1000);
        } catch (err) {
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    const DenyTimeOff = () => {
        const [reason, setReason] = useState<string>('');
        const theme = useTheme();
        return (
            <div style={{ paddingTop: 20 }}>
                <p style={{ color: theme.palette.text.primary, fontFamily: 'Nunito Sans', fontSize: 14 }}>
                    Are you sure you want to Deny the Time-off request ?
                </p>
                <div className={classes2.denyModalContent}>
                    <Typography
                        style={{
                            marginRight: 10,
                            marginTop: 15,
                            color: theme.palette.text.primary,
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightBold,
                            fontSize: 14,
                        }}
                    >
                        Reason (optional):
                    </Typography>
                    <TextField
                        multiline
                        type="text"
                        rows={3}
                        value={reason}
                        onChange={(e: any) => setReason(e.target.value)}
                        placeholder="Type your reason here"
                        variant="outlined"
                        className={classes2.textDeco}
                        style={{ flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                        className={classes2.deleteButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes2.createBtn}
                        onClick={() => handleModal(reason)}
                    >
                        Deny
                    </Button>
                </div>
            </div>
        );
    };

    useEffect(() => {
        dispatch(updateActiveTab('/dashboard'));
    }, []);

    const handlePopup = (severity: string, message: string): void => {
        setAlert(true);
        setSeverity(severity);
        setAlertMessage(message);
    };

    const handleDenyMultiple = async (obj: any) => {
        try {
            const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const resp = await updateLeaveAdminMultiple(obj, mycontext.isAdmin);
            if (resp.errors) {
                setOpenA(false);
                handlePopup('error', 'Failed to denied leaves!');
            } else {
                setOpenA(false);
                handlePopup('success', 'All Leaves are denied successfuly!');
                // handleNotif({
                //     response: resp,
                //     requestFor: 'slack',
                //     user: {
                //         name: mycontext.fname + ' ' + mycontext.lname,
                //         type: 'name',
                //     },
                //     orgId: mycontext.organizationId,
                //     requestType: 'deny',
                // });
                handleNotif({
                    response: resp,
                    requestFor: 'msteams',
                    prevStatus: obj.prevStatus,
                    user: mycontext.EmployeeId,
                    token: mycontext.token,
                    orgId: mycontext.organizationId,
                    requestType: 'deny',
                });
                // eslint-disable-next-line
                obj.leaveIds.map(async (leave: any) => {
                    // console.log(leave);
                    // await getTokenFromLeave(leave, mycontext.token);
                    await getMultiDenyTokenFromLeave(
                        leave,
                        mycontext.token,
                        mycontext.fname,
                        mycontext.lname,
                        mycontext.organizationId,
                        mycontext.EmployeeId,
                    );
                    await getDenyTokenFromLeave(leave, mycontext.token, mycontext.fname, mycontext.lname);
                    return null;
                });
            }
        } catch (err) {
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    const DenyTimeOffMultiple = (obj: any) => {
        const [reason, setReason] = useState<string>('');
        const theme = useTheme();
        return (
            <div style={{ paddingTop: 20 }}>
                <p style={{ color: theme.palette.text.primary, fontFamily: 'Nunito Sans', fontSize: 14 }}>
                    Are you sure you want to Deny the Time-off request ?
                </p>
                <div className={classes2.denyModalContent}>
                    <Typography
                        style={{
                            marginRight: 10,
                            marginTop: 15,
                            color: theme.palette.text.primary,
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightBold,
                            fontSize: 14,
                        }}
                    >
                        Reason (optional):
                    </Typography>
                    <TextField
                        multiline
                        type="text"
                        rows={3}
                        value={reason}
                        onChange={(e: any) => setReason(e.target.value)}
                        placeholder="Type your reason here"
                        variant="outlined"
                        className={classes2.textDeco}
                        style={{ flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenA(false)}
                        className={classes2.deleteButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes2.createBtn}
                        onClick={() =>
                            handleDenyMultiple({ ...multipleLeave, denialReason: reason.split('\n').join(' ') })
                        }
                    >
                        Deny
                    </Button>
                </div>
            </div>
        );
    };

    if (
        state.orgEmployees.status === 'loading' ||
        state.holidays.status === 'loading' ||
        // props.employee.loading ||
        // props.pending.loading ||
        // props.todays.loading ||
        // props.upcoming.loading ||
        state.approverDashboardLeaves.status === 'loading' ||
        state.employeeLeaves.status === 'loading' ||
        state.leaveTypes.status === 'loading' ||
        state.formDetails.status === 'loading' ||
        state.orgLeaves.status === 'loading' ||
        state.empOnLeaves.status === 'loading' ||
        state.dashboardLeaves.tstatus === 'loading' ||
        state.dashboardLeaves.ustatus === 'loading' ||
        state.settings.status === 'loading'
    ) {
        return <LoaderPage />;
    }
    if (
        state.orgEmployees.status === 'failed' ||
        state.holidays.status === 'failed' ||
        // props.employee.error ||
        // props.pending.error ||
        // props.todays.error ||
        // props.upcoming.error ||
        state.approverDashboardLeaves.status === 'failed' ||
        state.employeeLeaves.status === 'failed' ||
        state.leaveTypes.status === 'failed' ||
        state.formDetails.status === 'failed' ||
        state.orgLeaves.status === 'failed' ||
        state.empOnLeaves.status === 'failed' ||
        state.dashboardLeaves.tstatus === 'failed' ||
        state.dashboardLeaves.ustatus === 'failed' ||
        state.settings.status === 'failed'
    ) {
        return <ErrorPage title="Server Down" />;
    }
    if (step === 1) {
        return (
            <AddLeave
                setStep={setStep}
                holidays={holidays || []}
                employeeLeaves={empLeave || []}
                organizationLeaves={orgLeave || []}
                data={formDetails}
                leaves={state.employeeLeaves?.leaves || []}
                // leaves={props.employee?.data?.timeoffdb_Leaves}
                workWeek={state.settings?.settings.workWeek}
                userSubscriptionDetails={userSubscriptionDetails.usermanagementdb_tenant_employee[0]}
            />
        );
    }
    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        <SubscriptionHardExpired
            content="Subscription expired, Please contact your admin to renew subscription"
            showRenew={false}
        />
    ) : (
        <>
            <Modal open={open} setOpen={setOpen} onModalClosed={setOpen} title="Deny Time-Off Request">
                <DenyTimeOff />
            </Modal>
            <Modal open={openA} setOpen={setOpenA} onModalClosed={setOpenA} title="Deny Multiple Time-Off Request">
                <DenyTimeOffMultiple />
            </Modal>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <div className={classes.approverContainer}>
                <div className={classes.myleaves}>
                    <DisplayLeaves
                        title="Personal Time-Offs Status"
                        leaves={leaves || []}
                        // leaves={props.employee?.data?.timeoffdb_Leaves || []}
                        setStep={setStep}
                        holidays={holidays}
                        approvers={approvers}
                        loading={false}
                        orgEmployees={orgEmployees}
                    />
                </div>
                <div className={classes.leavesContainer}>
                    <Accordians
                        // pendingLeaves={
                        //     props.pending?.data?.timeoffdb_get_pendingdeniedapproverleaves?.filter(
                        //         (l: any) => l.Employee.employeeId !== mycontext.EmployeeId,
                        //     ) || []
                        // }
                        // todaysLeaves={
                        //     props.todays?.data?.timeoffdb_Leaves?.filter(
                        //         (l: any) => l.Employee.employeeId !== mycontext.EmployeeId,
                        //     ) || []
                        // }
                        // upcomingLeaves={
                        //     props.upcoming?.data?.timeoffdb_Leaves?.filter(
                        //         (l: any) => l.Employee.employeeId !== mycontext.EmployeeId,
                        //     ) || []
                        // }
                        pendingLeaves={pendingLeaves.filter((l: any) => l.Employee.employeeId !== mycontext.EmployeeId)}
                        todaysLeaves={
                            todaysLeaves
                            //     .filter(
                            //     (l: any) =>
                            //         l.Employee.employeeId !== mycontext.EmployeeId &&
                            //         DateDifference(
                            //             isoToLocal(l.isoString.startTime, mycontext.country),
                            //             getCurrentCountryDate(mycontext.country.zoneName),
                            //         ) >= 0 &&
                            //         DateDifference(
                            //             getCurrentCountryDate(mycontext.country.zoneName),
                            //             isoToLocal(l.isoString.endTime, mycontext.country),
                            //         ) > 0,
                            // )
                        }
                        upcomingLeaves={upcomingLeaves.filter(
                            (l: any) => l.Employee.employeeId !== mycontext.EmployeeId,
                            // &&
                            // DateDifference(
                            //     getCurrentCountryDate(mycontext.country.zoneName),
                            //     isoToLocal(l.isoString?.endTime, mycontext.country),
                            // ) > 1,
                        )}
                        orgEmployees={orgEmployees}
                        COLORS={COLORS}
                        data={leaveTypes || data}
                        handleApprove={handleApprove}
                        handleDeny={handleDeny}
                        handleModal={handleModal}
                        isApprover={true}
                        isProfile={false}
                        isDashboard={true}
                        handlePopup={handlePopup}
                        handleDenyAll={handleDenyAll}
                        userSubscriptionDetail={userSubscriptionDetails?.usermanagementdb_tenant_employee[0]}
                    />
                </div>
            </div>
        </>
    );
}
