import { createSlice } from '@reduxjs/toolkit';

export const celebrationSlice = createSlice({
    name: 'celebrations',
    initialState: {
        todayCelebrations: [],
        pastCelebrations: [],
        todaycelebrationsStatus: 'loading',
        pastCelebrationsStatus: 'loading',
    },
    reducers: {
        fetchAllTodayCelebration: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.todaycelebrationsStatus = 'loading';
            }
            if (action.payload?.error) {
                state.todaycelebrationsStatus = 'failed';
            } else {
                state.todaycelebrationsStatus = 'success';
                // console.log(action.payload);
                state.todayCelebrations = action.payload?.data?.celebrationdb_Celebration;
            }
        },
        fetchAllPastCelebration: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.pastCelebrationsStatus = 'loading';
            }
            if (action.payload?.error) {
                state.pastCelebrationsStatus = 'failed';
            } else {
                state.pastCelebrationsStatus = 'success';
                // console.log(action.payload);
                state.pastCelebrations = action.payload?.data?.celebrationdb_Celebration;
            }
        },
    },
});

export const { fetchAllTodayCelebration, fetchAllPastCelebration } = celebrationSlice.actions;
export default celebrationSlice.reducer;
