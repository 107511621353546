import React from 'react';
import useStyles from './firstStyle';
// import bgImg from './bgImg.png';
import {
    // Box,
    Grid,
    // Hidden,
    TextField,
    Button,
    // FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import Img from '../../Assets/images/register.png';
import Validate from '../../Validator/validateRegister';
import FormError from '../Error/RegisterFormError';
// import TeamPlusLogo from '../../Assets/images/teamPlus_white.png';

interface Props {
    prevStep?: any;
    nextStep?: any;
    values?: any;
    handleRegister?: any;
    isGoogle?: boolean;
    stepCount?: any;
    // totalCount?: any;
}

export default function First(props: Props): JSX.Element {
    const classes = useStyles();
    const [error, setError] = React.useState<any>();
    const handleSubmit = async () => {
        const data = {
            email: props.values.oemail,
            orgname: props.values.orgname,
            days: props.values.daysLeave,
        };
        const err = await Validate(data);
        if (err === null) {
            setError(null);
            props.nextStep();
        } else {
            setError(err);
        }
    };
    return (
        <Grid item xs={12} lg={12} xl={5} className={classes.main}>
            <Grid container direction="row">
                {/* <Grid item sm={12} xs={12} className={classes.counterDiv}>
                    <h2 className={classes.counter}>
                        {props.stepCount}/{props.totalCount}
                    </h2>
                </Grid> */}
                <Grid item xs={12} className={classes.SignUpheading}>
                    <h2 className={classes.font}>Register Your Organization</h2>
                </Grid>
                <Grid item xs={12}>
                    <FormError formerrors={{ error }} />
                </Grid>
                <form
                    id="organizationForm"
                    onSubmit={(e: any) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    style={{ width: '100%' }}
                >
                    <Grid item xs={12} className={classes.firstNameCSS}>
                        <TextField
                            id="organizationName"
                            type="text"
                            label="Organisation Name"
                            autoFocus
                            fullWidth
                            value={props.values.orgname}
                            onChange={e => props.values.setOrgname(e.target.value)}
                            margin="dense"
                        />
                        <Tooltip
                            title="The organisation name will reflect in your application and to the respective users"
                            placement="top"
                        >
                            <InfoOutlinedIcon />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} className={classes.emailCSS}>
                        <TextField
                            type="email"
                            fullWidth
                            label="Organisation Email ID"
                            value={props.values.oemail}
                            onChange={e => props.values.setOemail(e.target.value)}
                            margin="dense"
                        />
                        <Tooltip
                            title="The email we are asking for will be used for further communication with your organisation"
                            placement="top"
                        >
                            <InfoOutlinedIcon />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} className={classes.selectionFieldCSS}>
                        <Grid item xs={6}>
                            {/* <FormControl component="fieldset"> */}
                            <FormLabel>Unlimited Vacation Days?</FormLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <RadioGroup
                                aria-label="vacation"
                                name="position"
                                row
                                value={props.values.vacation}
                                style={{ marginTop: '1vh' }}
                                onChange={(e: any) => props.values.setVacation(e.target.value)}
                            >
                                <FormControlLabel
                                    value="Yes"
                                    control={<Radio id="yes" color="primary" />}
                                    label="Yes"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="No"
                                    control={<Radio id="no" color="primary" />}
                                    label="No"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                            {/* </FormControl> */}
                        </Grid>
                    </Grid>
                    {props.values.vacation === 'No' && (
                        <Grid item xs={12} className={classes.selectionFieldCSS}>
                            {/* <FormControl> */}
                            <Grid item xs={6}>
                                <FormLabel>
                                    Default leave days per year? &nbsp;
                                    {/* <Tooltip
                                title="You can change this later through the Settings page, and you can customize the number of leave days for each individual employee"
                                placement="top"
                            >
                                <InfoOutlinedIcon />
                            </Tooltip> */}
                                </FormLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-number"
                                    type="number"
                                    value={props.values.daysLeave}
                                    onChange={e => props.values.setDaysLeave(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: 0, max: 50 }}
                                    variant="outlined"
                                    margin="dense"
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            {/* </FormControl> */}
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.submitButtonCSS}>
                        <Button
                            id="next"
                            color="primary"
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.hoverStyle}
                            // onClick={() => handleSubmit()}
                        >
                            Register
                        </Button>
                    </Grid>
                    {/* <Grid item xs={12} className={classes.submitButtonCSS}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="outlined"
                            className={classes.hoverStyleOutline}
                            onClick={() => props.prevStep()}
                        >
                            Back
                        </Button>
                    </Grid> */}
                </form>
            </Grid>
        </Grid>
    );
}
