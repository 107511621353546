import { gql } from '@apollo/client';
export const getOrganizationSubscription_Subscription = (tenantTableId: string) => {
    return gql`
        subscription subscription_subscription {
            usermanagementdb_Subscription(where: { tenantId: { _eq: "${tenantTableId}" } }) {
                tenantId
                purchasedBy
                subscriptionId
                currentTermStart
                currentTermEnd
                nextBillingAt
                status
                showWarning
                hardExpireDate
                planId
                quantity
                autoRenew
                Employee {
                    employeeId
                    firstName
                    lastName
                }
                timeoffplan: Tenant {
                    tenant_employees_aggregate {
                        aggregate {
                            count(columns: timeoffSubscriptionId)
                        }
                    }
                }
                standupplan: Tenant {
                    tenant_employees_aggregate {
                        aggregate {
                            count(columns: standupSubscriptionId)
                        }
                    }
                }
                pollplan: Tenant {
                    tenant_employees_aggregate {
                        aggregate {
                            count(columns: pollSubscriptionId)
                        }
                    }
                }
                engageplan: Tenant {
                    tenant_employees_aggregate {
                        aggregate {
                            count(columns: engageSubscriptionId)
                        }
                    }
                }
            }
        }
    `;
};

export const getUsersSubscription = (employeeId: string, tenantId: string, organizationId: string) => {
    return gql`
        subscription UserSubscription {
            usermanagementdb_tenant_employee(where: {employeeId: {_eq: "${employeeId}"},     Tenant: {workspaceId: {_eq: "${tenantId}"}}}) {
                tenantId
                standupSubscriptionId
                timeoffSubscriptionId
                pollSubscriptionId
                engageSubscriptionId
                employeeId
                role
                Employee {
                    email
                    firstName
                    lastName
                    employeeId
                    profileImage
                }
                Tenant {
                    isBotAdded
                    Subscriptions {
                        subscriptionId
                        tenantId
                        status
                        hardExpireDate
                        planId
                    }
                    workspaceId
                    name
                    Organizations(where: {organizationId: {_eq: "${organizationId}"}}) {
                        organizationId
                        organizationName
                        StandupTemplate {
                            standupTempId
                            startMessage
                            questions
                            organizationId
                        }
                        Teams(where: {isbotAdded: {_contains: {standup: true}}}) {
                            isbotAdded
                            msChannelId
                            organizationId
                        }
                    }
                }
            }
        }
    `;
};
