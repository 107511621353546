import axios from 'axios';
// import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { DisplayCenter } from "components/Celebration/DetailedAccordian";
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './style';
import AuthContext from 'context/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { Button, FormLabel, Grid, Hidden, TextField, useTheme } from '@material-ui/core';
import { UpdateCelebration, otherCelebration } from 'helpers/celebrationApi';
import { ToStringFormat } from 'helpers/date';
import { getCurrentCountryDateTime } from 'helpers/timezone';
import { DisplayCenter } from './upcomingCelebration';

const RenderGifs = (props: {
    isloading: boolean;
    logo: any;
    handleGifclose: any;
    handleGIFSubmit: any;
    data1: any;
}) => {
    const classes = useStyles();
    if (props.isloading === true) {
        return (
            <div>
                <DisplayCenter>
                    <CircularProgress />
                </DisplayCenter>
            </div>
        );
    }

    return (
        <>
            <Grid>
                {props.logo !== '' && (
                    <Grid style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <img src={props.logo} alt="Gif" style={{ width: 200, height: 200, borderRadius: 10 }} />
                        <div>
                            <CloseIcon onClick={props.handleGifclose} style={{ cursor: 'pointer' }} />
                        </div>
                    </Grid>
                )}
                {props.logo === '' &&
                    props.data1.map((e: any, i: number) => (
                        <React.Fragment key={i}>
                            <img
                                src={e.images.fixed_height.url}
                                alt="Gif"
                                className={classes.GifiImage}
                                onClick={() => props.handleGIFSubmit(e.images.fixed_height.url)}
                            />
                        </React.Fragment>
                    ))}
            </Grid>
        </>
    );
};

export const Giphy = (props: { logo: any; setLogo: any }) => {
    const classes = useStyles();
    const [data1, setData1] = React.useState<any>([]);
    const [search, setSearch] = React.useState<any>('');
    const [isloading, setIsloading] = React.useState<boolean>(false);
    const theme = useTheme();

    useEffect(() => {
        const fetchData = async () => {
            setIsloading(true);
            try {
                const results = await axios('https://api.giphy.com/v1/gifs/search', {
                    params: {
                        api_key: 'mZuf8JfSpxqYc7sOarhcNrRflVS1SNRG',
                        limit: 10,
                        q: 'office-celebration',
                    },
                });
                setData1(results?.data?.data);
            } catch (error) {
                // console.log('error', error);
                return error;
            }
            setIsloading(false);
            setIsloading(false);
        };
        fetchData();
    }, []);

    const handleSubmit = async (event: any) => {
        setIsloading(true);
        props.setLogo('');
        setSearch(event?.target.value);
        const results = await axios('https://api.giphy.com/v1/gifs/search', {
            params: {
                api_key: 'MjOrvnCKzGyjmY36uGHqXx4v4QKDdfCg',
                q: search,
                limit: 10,
            },
        });
        setData1(results.data.data);
        setIsloading(false);
    };

    return (
        <>
            <div className={classes.searchGifRounded}>
                <span
                    style={{
                        marginRight: 10,
                        fontSize: 20,
                        fill: theme.palette.primary.contrastText,
                    }}
                    className="iconify"
                    data-icon="octicon:search-16"
                ></span>
                <input
                    type="text"
                    placeholder="Search"
                    value={search}
                    className={classes.searchBarInput}
                    onChange={handleSubmit}
                />
            </div>
            <RenderGifs
                isloading={isloading}
                logo={props.logo}
                handleGifclose={() => props.setLogo('')}
                handleGIFSubmit={(v: any) => props.setLogo(v)}
                data1={data1}
            />
        </>
    );
};

export function Addothercele(props: { setModal?: any }) {
    const mycontext = useContext(AuthContext);
    const classes = useStyles();
    const [logo, setLogo] = useState<string>('');
    const [date, setDate] = useState<string>(
        ToStringFormat(getCurrentCountryDateTime(mycontext.country.zoneName).dateTime, 'YYYY-MM-DD'),
    );
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    // console.log(ToStringFormat(getCurrentCountryDateTime(mycontext.country.zoneName).dateTime, 'YYYY-MM-DD'));
    const handleClick = async () => {
        try {
            if (title === '') {
                throw new Error('please fill title');
            }
            if (logo === '') {
                throw new Error('please select a image');
            }
            if (date === '') {
                throw new Error('please add date');
            }
            if (date < new Date().toISOString().substring(0, 10)) {
                throw new Error('please fill date to upcoming date');
            }
            const obj = {
                title: title,
                description: description,
                celebrationDate: date,
                logo: logo,
                tenantId: mycontext.tenantTableId,
                departmentId: mycontext.organizationId,
                employeeId: mycontext.EmployeeId,
                token: mycontext.token,
            };
            const data = await otherCelebration(obj);
            if (data.error) {
                throw new Error('celebration was not created');
            }
            mycontext.togglePopup({
                alertMessage: 'celebration was created successfully',
                severity: 'success',
            });
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err.message,
                severity: 'error',
            });
        }
    };
    return (
        <div className={classes.maindivFirst}>
            <div className={classes.second}>
                <div className={classes.enter}>
                    <FormLabel className={classes.modalTitle}>Title</FormLabel>
                    <TextField
                        placeholder="Enter the title"
                        variant="outlined"
                        data-testid="title"
                        margin="dense"
                        fullWidth
                        inputProps={{
                            maxLength: 80,
                        }}
                        value={title}
                        className={classes.inputTag}
                        onChange={(e: any) => setTitle(e.target.value)}
                    />
                </div>
                <div className={classes.cele}>
                    <FormLabel className={classes.modalTitle}>Celebrate on</FormLabel>
                    <div className={classes.celeDate}>
                        <TextField
                            variant="outlined"
                            data-testid="date"
                            fullWidth
                            size="small"
                            type="date"
                            value={date}
                            className={classes.inputTag}
                            onChange={(e: any) => setDate(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
                <FormLabel className={classes.modalTitle}>Description (optional)</FormLabel>
                <TextField
                    fullWidth
                    placeholder="Enter the Description"
                    variant="outlined"
                    data-testid="desc"
                    id="DescriptionId"
                    inputProps={{
                        maxLength: 200,
                    }}
                    multiline
                    value={description}
                    className={classes.inputTag}
                    onChange={(e: any) => setDescription(e.target.value)}
                    minRows={2}
                />
            </div>
            <div>
                <FormLabel className={classes.heading}>Add Gif</FormLabel>
                <Giphy logo={logo} setLogo={setLogo} />
            </div>
            <Hidden mdDown>
                <div style={{ display: 'flex', marginTop: 10, justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        style={{ height: 40, width: 120 }}
                        onClick={handleClick}
                    >
                        Done
                    </Button>
                </div>
            </Hidden>
            <Hidden lgUp>
                <div className={classes.btnDiv}>
                    <Button onClick={() => props.setModal(false)} variant="outlined" className={classes.btn1}>
                        cancel
                    </Button>
                    <Button variant="outlined" onClick={handleClick} className={classes.btn2}>
                        Done
                    </Button>
                </div>
            </Hidden>
        </div>
    );
}

export const UpdateOtherCelebration = (props: {
    title: string;
    setTitle: any;
    date: any;
    setDate: any;
    description: any;
    setDescription: any;
    logo: any;
    setLogo: any;
    celebrationId: any;
    setModal: any;
}) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);

    const handleUpdate = async () => {
        try {
            if (props.title === '') {
                throw new Error('please fill title');
            }
            if (props.logo === '') {
                throw new Error('please select a image');
            }
            if (props.date === '') {
                throw new Error('please add date');
            }
            if (props.date < new Date().toISOString().substring(0, 10)) {
                throw new Error('please fill date to upcoming date');
            }
            const obj = {
                title: props.title,
                description: props.description,
                date: props.date,
                logo: props.logo,
                celebrationId: props.celebrationId,
                token: mycontext.token,
            };
            const data = await UpdateCelebration(obj);
            if (data.error) {
                throw new Error('celebration was not update');
            }
            props.setModal(false);
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err.message,
                severity: 'error',
            });
        }
    };

    return (
        <div className={classes.update} style={{ height: 380 }}>
            <div className={classes.second}>
                <div className={classes.enter}>
                    <FormLabel className={classes.modalTitle}>Title</FormLabel>
                    <TextField
                        placeholder="Enter the title"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        inputProps={{
                            maxLength: 80,
                        }}
                        value={props.title}
                        className={classes.inputTag}
                        onChange={(e: any) => props.setTitle(e.target.value)}
                    />
                </div>
                <div className={classes.cele}>
                    <FormLabel className={classes.modalTitle}>Celebrate on</FormLabel>
                    <div className={classes.celeDate}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="date"
                            value={props.date}
                            className={classes.inputTag}
                            onChange={(e: any) => props.setDate(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
                <FormLabel className={classes.modalTitle}>Description (optional)</FormLabel>
                <TextField
                    fullWidth
                    placeholder="Enter the Description"
                    variant="outlined"
                    id="DescriptionId"
                    inputProps={{
                        maxLength: 200,
                    }}
                    multiline
                    value={props.description}
                    className={classes.inputTag}
                    onChange={(e: any) => props.setDescription(e.target.value)}
                    minRows={2}
                />
            </div>
            <div>
                <FormLabel className={classes.heading}>Add Gif</FormLabel>
                <Giphy logo={props.logo} setLogo={props.setLogo} />
            </div>
            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    // className={classes.btn}
                    style={{ height: 40, width: 120, color: 'white' }}
                    onClick={handleUpdate}
                >
                    Update
                </Button>
            </div>
        </div>
    );
};
