import React from 'react';
import { Grid, Paper, FormLabel, Button, Hidden } from '@material-ui/core';
// import Page3Img from '../../Assets/images/Page3Img.png';
// import udyamo from '../../Assets/images/udyamo.png';
import useStyles from './newRegisterStyles';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

interface NewRegister {
    setPageNumber?: any;
    values?: any;
}

export default function RegisterPartThree(props: NewRegister) {
    const classes = useStyles();

    return (
        <div style={{ display: 'flex' }}>
            <Grid container style={{ display: 'flex' }}>
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.userOrgnizationPageImage}
                                    alt="Page3Img"
                                    style={{ height: '75vh' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                    alt="udyamoLogo"
                                    style={{ height: '2.5vh' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} xl={7} className={classes.secondGrid}>
                    <Paper style={{ boxShadow: 'none' }} className={classes.orgPaperStyle}>
                        <Grid container>
                            <Grid container direction="row">
                                <Grid item xs={12} style={{ justifyContent: 'flex-end' }} className={classes.formGrids}>
                                    <FormLabel style={{ color: '#888888' }}>3/4</FormLabel>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <FormLabel className={classes.title}>My Organisations</FormLabel>
                                </Grid>
                                <Grid container style={{ padding: '10px' }} className={classes.contentGrid}>
                                    <Grid item xs={12} className={classes.paraGridStyle}>
                                        <FormLabel className={classes.paraContent}>
                                            Looks like you are new to Teamplus. Register your organisation and add your
                                            employees to use time-off management feature and get started with the
                                            efficient way of working together.
                                        </FormLabel>
                                    </Grid>
                                    {/* <Grid item xs={12} className={classes.paraGridStyle} style={{ marginTop: '5%' }}>
                                        <FormLabel className={classes.paraContent}>
                                            TeamPlus is a platform to help teams collaborate, communicate and create
                                            efficiently. Add your organisation to TeamPlus to use features like Time-Off
                                            Management, Shoutouts, Standups and more.
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} className={classes.paraGridStyle}>
                                        <FormLabel className={classes.paraContent}>
                                            Register your organisation and add your team to get started with the
                                            efficient way of working together.
                                        </FormLabel>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{ marginTop: '10%', padding: '24px 24px 0px 24px' }}
                                className={classes.registerButtonGrid}
                            >
                                <Button
                                    id="regNewOrg"
                                    className={classes.registerButton}
                                    onClick={/* istanbul ignore next */ () => props.setPageNumber(3)}
                                >
                                    Register a new organisation
                                </Button>
                            </Grid>
                            {/* <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button className={classes.takeMeBackButton} onClick={props.values.backButton}>
                                    Take me back
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
