import React, { useState, useContext } from 'react';
import MyModal from '../Modal/Modal';
import { FormLabel, Button, TextField, Typography } from '@material-ui/core';
import useStyles from './FeedbackStyle';
import { sendFeedback } from '../../helpers/hasuraapi';
import AuthContext from '../../context/AuthContext';
import { PopUp } from '../Modal/Modal';

interface Props {
    open: boolean;
    toggle: any;
    handleCancel: any;
}

export default function Feedback(props: Props): JSX.Element {
    const classes = useStyles();
    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState<number>(0);
    const [value, setValue] = useState<string>('');
    const mycontext = useContext(AuthContext);

    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    // const [modal, setModal] = useState<boolean>(false);

    let resp: any;
    const handleSubmit = async () => {
        if (rating === 0 && value === '') {
            setSeverity('error');
            setError(true);
            setErrorMsg('Cannot send an empty feedback.');
        } else {
            resp = await sendFeedback({
                tenantId: mycontext.tenantId,
                // employeeId: mycontext.EmployeeId,
                rating: rating,
                experienceOfApp: value,
                token: mycontext.token,
            });

            if (resp.error) {
                setSeverity('error');
                setAlert(true);
                setAlertMessage('Something went wrong.');
            } else {
                setSeverity('success');
                setAlert(true);
                setAlertMessage('Feedback sent successfully!');
                setValue('');
                setRating(0);
                setHover(0);
                setError(false);
                // setModal(false);
                props.handleCancel();
            }
        }
    };

    const handleCancel = async () => {
        props.handleCancel();
        setError(false);
        setRating(0);
        setHover(0);
        setValue('');
    };

    return (
        <>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <MyModal open={props.open} setOpen={props.toggle} onModalClosed={handleCancel}>
                <div style={{ padding: '10px' }}>
                    {error === true ? (
                        <FormLabel component="legend" error id="feedbackError">
                            {errorMsg}
                        </FormLabel>
                    ) : (
                        ''
                    )}
                    <Typography className={classes.formLabel1}>Rating</Typography>

                    {[...Array(5)].map((star, index) => (
                        <button
                            type="button"
                            key={index}
                            className={index + 1 <= (hover || rating) ? classes.on : classes.off}
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                outline: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => setRating(index + 1)}
                            onMouseEnter={() => setHover(index + 1)}
                            onMouseLeave={() => setHover(index + 1)}
                        >
                            <span style={{ fontSize: 32, paddingRight: 10 }}>&#9733;</span>
                        </button>
                    ))}
                    <br />
                    <br />
                    <FormLabel className={classes.formLabel}>How can we make your experience better?</FormLabel>
                    <TextField
                        multiline
                        fullWidth
                        type="text"
                        rows={3}
                        placeholder="Leave a message, if you want"
                        variant="outlined"
                        className={classes.inputStyle}
                        value={value}
                        onChange={(e: any) => setValue(e.target.value)}
                    />

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleCancel()}
                            className={classes.btnOutlined}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmit()}
                            type="submit"
                            className={classes.btn}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            </MyModal>
        </>
    );
}
