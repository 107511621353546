import React, { useState, useContext } from 'react';
import { FormLabel, TextField, Grid, FormControl, Select, MenuItem, Button, useTheme, Hidden } from '@material-ui/core';
import useStyles from './ContactStyle';
import AuthContext from '../../context/AuthContext';
import { PopUp } from 'components/Modal/Modal';
import { sendGeneral } from '../../helpers/hasuraapi';

interface Contact {
    title?: string;
    desc?: string;
    severity?: string;
    tenantId?: any;
    token?: any;
    bug?: boolean;
}

export default function General() {
    const classes = useStyles();
    const theme = useTheme();
    const mycontext = useContext(AuthContext);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');

    const [contact, setContact] = useState<Contact>({
        title: '',
        desc: '',
        severity: 'Low',
        tenantId: mycontext.tenantId,
        token: mycontext.token,
        bug: false,
    });

    const handleCancel = () => {
        setContact({
            title: '',
            desc: '',
            severity: 'Low',
        });
    };

    const handleSend = async () => {
        if (contact.title === '' && contact.desc === '') {
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Please enter the details.');
        } else if (contact.desc === '') {
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Please enter the details.');
        } else {
            const resp = await sendGeneral(contact);
            if (resp.error) {
                setSeverity('error');
                setAlert(true);
                setAlertMessage('Something went wrong.');
            } else {
                setSeverity('success');
                setAlert(true);
                setAlertMessage('Form submitted successfully!');
                setContact({ ...contact, title: '', desc: '', severity: 'Low' });
            }
        }
    };

    const handleSeverity = (event: React.ChangeEvent<{ value: unknown }>) => {
        setContact({
            ...contact,
            severity: event.target.value as string,
        });
    };

    return (
        <>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <div className={classes.outerdiv}>
                <Grid item xs={12}>
                    <FormLabel className={classes.formlabelstyle}>Title</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        size="small"
                        type="text"
                        variant="outlined"
                        placeholder="Enter title"
                        className={classes.inputselectstyle}
                        value={contact.title}
                        onChange={(e: any) =>
                            setContact({
                                ...contact,
                                title: e.target.value as string,
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormLabel className={classes.formlabelstyle1} required>
                        Tell us more
                    </FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        multiline
                        placeholder="Hi, how can we help you?"
                        type="text"
                        rows={2}
                        variant="outlined"
                        className={classes.inputselectstyle}
                        value={contact.desc}
                        onChange={(e: any) => setContact({ ...contact, desc: e.target.value as string })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormLabel className={classes.formlabelstyle1}>Severity</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" className={classes.selectstyle} size="small">
                        <Select
                            value={contact.severity}
                            className={classes.inputselectstyle}
                            onChange={handleSeverity}
                            id="dateFormat"
                        >
                            <MenuItem value={'Low'} id="one">
                                Low
                            </MenuItem>
                            <MenuItem value={'High'} id="two">
                                High
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel className={classes.formlabelstyle1}>
                        In case of any other complaints, you can also write to us at{' '}
                        <span style={{ color: '#3D49B6' }}>support@udyamo.com</span>
                    </FormLabel>
                </Grid>

                <Hidden only={['xs', 'sm']}>
                    <div className={classes.resetButtonGrid}>
                        <Grid>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ border: `1px solid ${theme.palette.text.secondary}` }}
                                className={classes.buttonStyle}
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.updateButtonStyle}
                                onClick={() => handleSend()}
                            >
                                Send
                            </Button>
                        </Grid>
                    </div>
                </Hidden>

                <Hidden only={['md', 'lg', 'xl']}>
                    <div className={classes.resetButtonGrid}>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ border: `1px solid ${theme.palette.text.secondary}` }}
                                className={classes.buttonStyle}
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.updateButtonStyle}
                                onClick={() => handleSend()}
                            >
                                Send
                            </Button>
                        </Grid>
                    </div>
                </Hidden>
            </div>
        </>
    );
}
