import React from 'react';
import myStyle from './TeamStyle';
import { Button, Typography } from '@material-ui/core';
import MyModal from '../Modal/Modal';
import AuthContext from '../../context/AuthContext';
import { getMyTeams, EmployeeRole, updateUserToEmployee } from '../../helpers/hasuraapi';
import Loader from 'components/Loader/loader';

interface Props {
    isEmployeeRole?: boolean;
    setEmployeeRole?: any;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
    teamID?: any;
    employeeDetails?: any;
    action?: string;
}

const RoleModal = (props: Props) => {
    const classes = myStyle();
    const toggle = () => props.setEmployeeRole(!props.isEmployeeRole);
    const mycontext = React.useContext(AuthContext);

    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

    const handleSuccess = () => {
        props.setSeverity('success');
        props.setAlertm(true);
        mycontext.handleTeamsPage();
        props.setAlertMessage('Employee role changed successfully!');
        setIsUpdating(false);
    };

    // const handleSubmit = async () => {
    //     toggle();

    //     setIsUpdating(true);
    //     const myTeams = await getMyTeams(
    //         props.employeeDetails.Employee.employeeId,
    //         mycontext.organizationId,
    //         mycontext.token,
    //     );
    //     const otherTeams = myTeams.usermanagementdb_Team.filter((team: any) => team.teamId !== props.teamID);
    //     const empRole = myTeams.usermanagementdb_organization_employee[0].role;
    //     const row = {
    //         teamId: props.teamID,
    //         employeeId: props.employeeDetails.Employee.employeeId,
    //         isApprover: props.action === 'makeApprover' ? true : false,
    //         organizationId: mycontext.organizationId,
    //         token: mycontext.token,
    //     };
    //     const EmployeeRoleData = await EmployeeRole(row);
    //     if (EmployeeRoleData.error) {
    //         throw new Error('Something went wrong, try again!');
    //     } else {
    //         if (otherTeams.length > 0) {
    //             if (empRole !== 'admin' || empRole !== 'approver') {
    //                 const updateResponse = await updateUserToEmployee(
    //                     {
    //                         empId: props.employeeDetails.Employee.employeeId,
    //                         role: empRole === 'admin' ? 'admin' : 'approver',
    //                     },
    //                     mycontext.token,
    //                 );
    //                 if (updateResponse.error) {
    //                     throw new Error('Something went wrong, try again!');
    //                 } else {
    //                     handleSuccess();
    //                 }
    //             }
    //         } else {
    //             let updateResponse: any;

    //             if (props.action === 'makeApprover') {
    //                 updateResponse = await updateUserToEmployee(
    //                     {
    //                         empId: props.employeeDetails.Employee.employeeId,
    //                         role: empRole === 'admin' ? 'admin' : 'approver',
    //                     },
    //                     mycontext.token,
    //                 );
    //             } else {
    //                 updateResponse = await updateUserToEmployee(
    //                     {
    //                         empId: props.employeeDetails.Employee.employeeId,
    //                         role: empRole === 'admin' ? 'admin' : 'employee',
    //                     },
    //                     mycontext.token,
    //                 );
    //             }
    //             // const updateResponse = await updateUserToEmployee({
    //             //     empId: props.employeeDetails.Employee.employeeId,
    //             //     role: empRole === "admin" ? "admin" : props.action === "makeApprover" ? "approver" : "employee"
    //             // }, mycontext.token);
    //             if (updateResponse.error) {
    //                 setIsUpdating(false);
    //                 throw new Error('Something went wrong, try again!');
    //             } else {
    //                 handleSuccess();
    //             }
    //         }
    //     }
    //     // window.location.reload();
    // };

    const handleSubmit = async () => {
        toggle();

        setIsUpdating(true);
        const myTeams = await getMyTeams(
            props.employeeDetails.Employee.employeeId,
            mycontext.organizationId,
            mycontext.token,
        );
        const otherTeams = myTeams.usermanagementdb_Team.filter((team: any) => team.teamId !== props.teamID);
        const empRole = myTeams.usermanagementdb_organization_employee[0].role;

        const row = {
            teamId: props.teamID,
            employeeId: props.employeeDetails.Employee.employeeId,
            isApprover: props.action === 'makeApprover' ? true : false,
            organizationId: mycontext.organizationId,
            token: mycontext.token,
        };
        const EmployeeRoleData = await EmployeeRole(row);
        if (EmployeeRoleData.error) {
            throw new Error('Something went wrong, try again!');
        } else {
            if (otherTeams.length > 0) {
                const updateResponse = await updateUserToEmployee(
                    {
                        empId: props.employeeDetails.Employee.employeeId,
                        orgId: mycontext.organizationId,
                        role: empRole === 'admin' ? 'admin' : 'approver',
                    },
                    mycontext.token,
                );
                if (updateResponse.error) {
                    throw new Error('Something went wrong, try again!');
                } else {
                    handleSuccess();
                }
            } else {
                if (props.action === 'makeApprover') {
                    const updateResponse = await updateUserToEmployee(
                        {
                            empId: props.employeeDetails.Employee.employeeId,
                            orgId: mycontext.organizationId,
                            role: empRole === 'admin' ? 'admin' : 'approver',
                        },
                        mycontext.token,
                    );

                    if (updateResponse.error) {
                        throw new Error('Something went wrong, try again!');
                    } else {
                        handleSuccess();
                    }
                } else {
                    const updateResponse = await updateUserToEmployee(
                        {
                            empId: props.employeeDetails.Employee.employeeId,
                            orgId: mycontext.organizationId,
                            role: empRole === 'admin' ? 'admin' : 'employee',
                        },
                        mycontext.token,
                    );

                    if (updateResponse.error) {
                        setIsUpdating(false);
                        throw new Error('Something went wrong, try again!');
                    } else {
                        handleSuccess();
                    }
                }
            }
        }
        // window.location.reload();
    };

    return (
        <div>
            <Loader isUpdating={isUpdating} />
            <MyModal
                setOpen={toggle}
                open={props.isEmployeeRole === undefined ? false : props.isEmployeeRole}
                onModalClosed={toggle}
            >
                <div
                    style={{
                        marginTop: '4%',
                        padding: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography className={classes.deltitle}>
                        {props.action === 'makeApprover' ? 'Make Approver' : 'Delete Approver'}
                    </Typography>

                    {props.action === 'makeApprover' ? (
                        <p className={classes.delbod}>
                            Are you sure you want to make
                            <strong>
                                {' ' +
                                    props.employeeDetails?.Employee?.firstName +
                                    ' ' +
                                    props.employeeDetails?.Employee?.lastName}
                            </strong>{' '}
                            an Approver?
                        </p>
                    ) : (
                        <p className={classes.delbod}>
                            Are you sure you want to delete
                            <strong>
                                {' ' +
                                    props.employeeDetails?.Employee?.firstName +
                                    ' ' +
                                    props.employeeDetails?.Employee?.lastName}
                            </strong>{' '}
                            as an Approver?
                        </p>
                    )}
                    {/* <Typography className={classes.delbodOption} style={{ marginBottom: '18px' }}>
                        {props.action === 'makeApprover'
                            ? `${
                                  props.employeeDetails === undefined
                                      ? 'Demo Demo'
                                      : props.employeeDetails?.Employee?.firstName +
                                        ' ' +
                                        props.employeeDetails?.Employee?.lastName
                              } will be able to approve/deny leaves of employees.`
                            : `${
                                  props.employeeDetails === undefined
                                      ? 'Demo Demo'
                                      : props.employeeDetails?.Employee?.firstName +
                                        ' ' +
                                        props.employeeDetails?.Employee?.lastName
                              } won't be able to approve/deny leaves of employees.`}
                    </Typography> */}

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '10px 0',
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.delmobbtnOutlined}
                            onClick={toggle}
                            style={{ marginRight: 10 }}
                        >
                            No
                        </Button>

                        <Button variant="contained" color="primary" className={classes.delbtn} onClick={handleSubmit}>
                            Yes
                        </Button>
                    </div>
                </div>
            </MyModal>
        </div>
    );
};

export default RoleModal;
