import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
    },
    bgImage: {
        height: '100vh',
        width: '100%',
        backgroundAttachment: 'fixed',
    },
    overlay: {
        display: 'flex',
        position: 'relative',
        bottom: '60vh',
        left: '8vw',
        alignItems: 'center',
        color: 'white',
    },
    overlay2: {
        display: 'flex',
        position: 'relative',
        bottom: '58vh',
        left: '8vw',
        fontSize: '1.2em',
        alignItems: 'center',
        color: 'white',
        fontStyle: 'italic',
    },
    teamLogoSmall: {
        width: 120,
        height: 26,
        marginBottom: 5,
    },
    addEmployeeLabel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#3C48B5',
        '@media screen and (max-width: 650px)': {
            fontSize: '18px',
        },
    },
    font: {
        '@media screen and (max-width: 650px and min-width: 400px)': {
            fontSize: 20,
        },
        '@media screen and (max-width: 399px)': {
            fontSize: 18,
        },
    },
    textfield: {
        width: '80%',
        height: '40px',
        background: '#FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '30px',
        outline: 'none',
        [`& fieldset`]: {
            borderRadius: 25,
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    addBtn: {
        outline: 'none',
        backgroundColor: 'transparent',
        color: '#fff',
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        fontWeight: 700,
        width: '20%',
        height: 40,
        borderRadius: '30px',
        boxShadow: 'none',
        '&:hover': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
        },
    },
    addEmailNew: {
        width: '80%',
        boxSizing: 'border-box',
        borderRadius: 25,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        height: 40,
        margin: '30px auto',
        '@media screen and (max-width: 650px)': {
            margin: '20px auto',
        },
    },
    counterDiv: {
        // width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        marginTop: '5vh',
    },
    counter: {
        width: 50,
        height: 50,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formErrorDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media only screen and (min-width: 540px) and (max-width: 730px)': {
            marginTop: '25vh',
            marginLeft: '30vw',
        },
    },
    chipsEmailDisplay: {
        width: '70%',
        display: 'flex',
        flexWrap: 'wrap',
        textDecoration: 'none',
        listStyle: 'none',
        padding: '20px 60px',
        '@media screen and (max-width: 650px)': {
            padding: '20px 20px',
        },
        // '@media only screen and (min-width: 400px) and (max-width: 735px)': {
        //     marginLeft: '6em',
        //     marginRight: '1em',
        //     marginTop: '1em',
        // },
        // '@media only screen and (min-width: 850px)': {
        //     marginLeft: '6em',
        //     marginRight: '6em',
        //     marginTop: '2em',
        // },
    },
    submitButtonCSS: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginTop: '2vh',
        '@media screen and (max-width: 1090px) and (min-width: 790px)': {
            width: '70%',
        },
    },
    hoverStyleOutline: {
        width: '70%',
        borderRadius: 20,
        fontSize: 18,
        textTransform: 'capitalize',
        color: '@3d49b6',
        fontWeight: theme.typography.fontWeightBold,
        outline: 'none',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            color: '#3D49B6',
            backgroundColor: '#fff',
        },
    },
    hoverStyle: {
        width: '70%',
        borderRadius: 20,
        fontSize: 18,
        textTransform: 'capitalize',
        color: '#fff',
        fontWeight: theme.typography.fontWeightBold,
        outline: 'none',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: '#3D49B6',
        },
    },
    backAndSubmitBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2em',
        marginLeft: '25vw',
        '@media only screen and (min-width: 400px) and (max-width: 735px)': {
            marginLeft: '3em',
            marginRight: '3em',
        },
        '@media only screen and (min-width: 300px) and (max-width: 570px)': {
            marginLeft: '2em',
            marginRight: '2em',
        },
        '@media only screen and (min-width: 375px) and (max-width: 670px)': {
            marginLeft: '2.5em',
            marginRight: '2.5em',
            marginTop: '2em',
        },
        '@media only screen and (min-width: 760px) and (max-width: 1030px)': {
            marginLeft: '10em',
            marginRight: '10em',
        },
        '@media only screen and (min-width: 540px) and (max-width: 730px)': {
            marginLeft: '25vw',
            marginTop: '2em',
        },
        '@media only screen and (min-width: 850px)': {
            marginLeft: '6em',
            marginRight: '6em',
            marginTop: '2em',
        },
    },
    btnPrimary: {
        height: 35,
        width: 100,
        margin: '0px 5px',
        textTransform: 'capitalize',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            outline: 'none',
        },
    },
    btnOutlined: {
        height: 35,
        width: 100,
        margin: '0px 5px',
        textTransform: 'capitalize',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        '&:focus': {
            outline: 'none',
        },
    },
    skip: {
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: 600,
        marginTop: 30,
        textDecoration: 'underline',
        color: theme.palette.primary.light,
        '&:hover, &:focus': {
            cursor: 'pointer',
        },
    },
}));

export default useStyles;
