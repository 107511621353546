import React, { useContext } from 'react';
import AuthContext from 'context/AuthContext';
// import { LoaderPage } from '../components/Loader/loader';
import AuthRoutes from './AuthRoutes';
import Routes from './Routes';

export default function MainRoutes() {
    const mycontext = useContext(AuthContext);
    if (
        mycontext.isLogedIn &&
        mycontext.token &&
        mycontext.organizationId &&
        mycontext.EmployeeId &&
        mycontext.standupTempId
    ) {
        return <AuthRoutes />;
    } else {
        return <Routes />;
    }
}
