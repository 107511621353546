import React from 'react';
import {
    Paper,
    Typography,
    Button,
    Grid,
    Avatar,
    Tooltip,
    Hidden,
    RadioGroup,
    Radio,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { useStyles } from './pollresultsStyles';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Donut } from '../donut/donutOuter';
import { Popover } from '@material-ui/core';
// import { useLocation } from 'react-router-dom';
import { createChoices, fetchPollResult, updateChoices } from 'helpers/hasuraapi2';
// import Countdown from 'react-countdown';
import AuthContext from '../../context/AuthContext';
import Comment from './comments';
import { useSelector } from 'react-redux';
// import comment from './comment.png';
import { getFullName } from 'helpers/getInitials';
import { useSubscription } from '@apollo/client';
import { getPollResult } from 'helpers/subscriptions/pollSubscriptions';
import { PopUp } from '../Modal/Modal';
import { AvtarComponents } from './pollresults';
import { Header } from '../Polls/pollresults';

interface ChoicesProps {
    pollsData: any;
    value: any;
    users: any;
    open: any;
    arr: any;
    arr3: any;
    COLORS: any;
    anchorEl: any;
    handleClose: any;
    currentChoice: any;
    handleClick: any;
    handleChange: any;
    handleMultiChange: any;
}

const ChoicesMobilePart = (props: ChoicesProps) => {
    const classes = useStyles();
    return (
        <>
            <Hidden lgUp>
                <Grid container className={classes.grid}>
                    {props.pollsData.adminChoices.map((n: any, i: any) => (
                        <Grid item key={i} style={{ paddingLeft: '14px' }}>
                            {props.pollsData.isSingleVote === true ? (
                                <RadioGroup value={props.value[0]} onChange={props.handleChange}>
                                    <FormControlLabel
                                        value={n}
                                        control={<Radio checked={props.value[0] === n} />}
                                        label={
                                            <Grid item className={classes.rectangle1}>
                                                <div
                                                    style={{ background: `${props.COLORS[i]}` }}
                                                    className={classes.rectangle2}
                                                >
                                                    <Typography className={classes.pollText}>{n}</Typography>
                                                </div>
                                            </Grid>
                                        }
                                    />
                                </RadioGroup>
                            ) : (
                                <FormControlLabel
                                    // key={i}
                                    value={n}
                                    control={
                                        <Checkbox
                                            // className={classes.btn2}
                                            onChange={props.handleMultiChange}
                                            checked={props.value.includes(n)}
                                        />
                                    }
                                    label={
                                        <Grid item className={classes.rectangle1}>
                                            <div
                                                style={{ background: `${props.COLORS[i]}` }}
                                                className={classes.rectangle2}
                                            >
                                                <Typography className={classes.pollText}>{n}</Typography>
                                            </div>
                                        </Grid>
                                    }
                                />
                            )}
                            {props.pollsData.isAnonymous === true ? (
                                <Typography className={classes.response}>
                                    {props.users.filter((u: any) => u.userChoice === n)?.length < 2 ? (
                                        <>{props.users.filter((u: any) => u.userChoice === n)?.length} Response</>
                                    ) : (
                                        <>{props.users.filter((u: any) => u.userChoice === n)?.length} Responses</>
                                    )}
                                </Typography>
                            ) : (
                                <>
                                    <Grid className={classes.avatar1}>
                                        {props.users
                                            .filter((u: any) => u.userChoice === n)
                                            .slice(0, 3)
                                            .map((user: any, index: number) => (
                                                <div key={index}>
                                                    <Tooltip
                                                        key={index}
                                                        title={
                                                            <>
                                                                {user?.firstName} {user?.lastName}
                                                            </>
                                                        }
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                        arrow
                                                    >
                                                        <Avatar src={user?.image} className={classes.avatar2}>
                                                            {getFullName(user.firstName + ' ' + user.lastName)}
                                                        </Avatar>
                                                    </Tooltip>
                                                </div>
                                            ))}
                                        {props.users.filter((u: any) => u.userChoice === n).length > 3 && (
                                            <Button
                                                className={classes.avatarButton}
                                                onClick={(e: any) => props.handleClick(e, n)}
                                            >
                                                + {props.users.filter((u: any) => u.userChoice === n)?.length - 3} More
                                            </Button>
                                        )}
                                    </Grid>
                                    <div>
                                        <Popover
                                            className={classes.popover1}
                                            open={props.open && props.currentChoice === n}
                                            anchorEl={props.anchorEl}
                                            onClose={props.handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            classes={{ paper: classes.triangle }}
                                        >
                                            <div className={classes.popover2}>
                                                {props.users
                                                    .filter((u: any) => u.userChoice === n)
                                                    .map((user: any, index: number) => (
                                                        <div key={index}>
                                                            <Typography>
                                                                <div className={classes.menuItem}>
                                                                    <Avatar
                                                                        src={user?.image}
                                                                        className={classes.popoverAvatar}
                                                                    >
                                                                        {getFullName(
                                                                            user?.firstName + ' ' + user?.lastName,
                                                                        )}
                                                                    </Avatar>
                                                                    <Typography className={classes.title}>
                                                                        {' '}
                                                                        {user?.firstName} {user?.lastName}
                                                                    </Typography>
                                                                </div>
                                                            </Typography>
                                                        </div>
                                                    ))}
                                            </div>
                                        </Popover>
                                    </div>
                                </>
                            )}
                        </Grid>
                    ))}
                </Grid>
                <Grid container>
                    <Grid item>
                        <Typography className={classes.donut}>
                            {props.arr.length === 0 ? <div></div> : <Donut data={props.arr} COLORS={props.arr3} />}
                        </Typography>
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
};

interface ChoicesProps {
    pollsData: any;
    value: any;
    users: any;
    open: any;
    arr: any;
    arr3: any;
    COLORS: any;
    anchorEl: any;
    handleClose: any;
    currentChoice: any;
    handleClick: any;
    handleChange: any;
    handleMultiChange: any;
}

export const ChoicePart = (props: ChoicesProps) => {
    const classes = useStyles();
    return (
        <>
            <Hidden mdDown>
                <Grid container className={classes.container4}>
                    <Grid item lg={9} md={12} style={{ paddingLeft: '14px' }}>
                        {props.pollsData.adminChoices.map((n: any, i: any) => (
                            // <Grid key={i} style={{ border: '1px solid' }}>
                            <Grid container className={classes.result} key={i}>
                                <Grid item lg={6} md={7}>
                                    {props.pollsData.isSingleVote === true ? (
                                        <RadioGroup value={props.value[0]} onChange={props.handleChange}>
                                            <FormControlLabel
                                                value={n}
                                                control={<Radio checked={props.value[0] === n} />}
                                                label={
                                                    <Grid item className={classes.rectangle1}>
                                                        <div
                                                            style={{ background: `${props.COLORS[i]}` }}
                                                            className={classes.rectangle2}
                                                        >
                                                            <Typography className={classes.pollText}>{n}</Typography>
                                                        </div>
                                                    </Grid>
                                                }
                                            />
                                        </RadioGroup>
                                    ) : (
                                        <FormControlLabel
                                            // key={i}
                                            value={n}
                                            control={
                                                <Checkbox
                                                    // className={classes.btn2}
                                                    onChange={props.handleMultiChange}
                                                    checked={props.value.includes(n)}
                                                />
                                            }
                                            label={
                                                <Grid item className={classes.rectangle1}>
                                                    <div
                                                        style={{ background: `${props.COLORS[i]}` }}
                                                        className={classes.rectangle2}
                                                    >
                                                        <Typography className={classes.pollText}>{n}</Typography>
                                                    </div>
                                                </Grid>
                                            }
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    {props.pollsData.isAnonymous === true ? (
                                        // <Grid className={classes.avatar1}>
                                        <Typography className={classes.text}>
                                            {props.users.filter((u: any) => u.userChoice === n)?.length < 2 ? (
                                                <>
                                                    {props.users.filter((u: any) => u.userChoice === n)?.length}{' '}
                                                    Response
                                                </>
                                            ) : (
                                                <>
                                                    {props.users.filter((u: any) => u.userChoice === n)?.length}{' '}
                                                    Responses
                                                </>
                                            )}{' '}
                                        </Typography>
                                    ) : (
                                        // </Grid>
                                        <>
                                            <Grid className={classes.text1}>
                                                {props.users
                                                    .filter((u: any) => u.userChoice === n)
                                                    .slice(0, 3)
                                                    .map((user: any, index: number) => (
                                                        <div key={index}>
                                                            <Tooltip
                                                                title={
                                                                    <>
                                                                        {user?.firstName} {user?.lastName}
                                                                    </>
                                                                }
                                                                placement="top"
                                                                classes={{
                                                                    tooltip: classes.tooltip,
                                                                }}
                                                                arrow
                                                            >
                                                                <Avatar src={user?.image} className={classes.avatar2}>
                                                                    {getFullName(user.firstName + ' ' + user.lastName)}
                                                                </Avatar>
                                                            </Tooltip>
                                                        </div>
                                                    ))}
                                                {props.users.filter((u: any) => u.userChoice === n).length > 3 && (
                                                    <Button
                                                        className={classes.avatarButton}
                                                        onClick={(e: any) => props.handleClick(e, n)}
                                                    >
                                                        +{' '}
                                                        {props.users.filter((u: any) => u.userChoice === n)?.length - 3}{' '}
                                                        More
                                                    </Button>
                                                )}
                                            </Grid>
                                            <div>
                                                <Popover
                                                    className={classes.popover1}
                                                    classes={{ paper: classes.triangle }}
                                                    open={props.open && props.currentChoice === n}
                                                    anchorEl={props.anchorEl}
                                                    onClose={props.handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <div className={classes.popover2}>
                                                        {props.users
                                                            .filter((u: any) => u.userChoice === n)
                                                            .map((user: any, index: number) => (
                                                                <div key={index}>
                                                                    <Typography>
                                                                        <div className={classes.menuItem}>
                                                                            <Avatar
                                                                                src={user?.image}
                                                                                className={classes.popoverAvatar}
                                                                            >
                                                                                {getFullName(
                                                                                    user.firstName +
                                                                                        ' ' +
                                                                                        user.lastName,
                                                                                )}
                                                                            </Avatar>
                                                                            <Typography className={classes.title}>
                                                                                {user?.firstName} {user?.lastName}
                                                                            </Typography>
                                                                        </div>
                                                                    </Typography>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </Popover>
                                            </div>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            // </Grid>
                        ))}
                    </Grid>
                    <Grid item lg={3}>
                        <div className={classes.donut}>
                            {props.arr.length === 0 ? <div></div> : <Donut data={props.arr} COLORS={props.arr3} />}
                        </div>
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
};

export default function PollCreator(props?: { setStep: any }) {
    const classes = useStyles();
    const mycontext: any = React.useContext(AuthContext);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [currentChoice, setCurrentChoice] = React.useState('');
    const [users, setUsers] = React.useState<any>([]);
    const [choices, setChoices] = React.useState<any>([]);
    // const location: any = useLocation();
    // const pollsData: any = location.state?.poll;
    const COLORS = ['#587EE0', '#4FB8B2', '#956FE4', '#FFBB28', '#6CD78A', '#BE66E8', '#F2890D'];
    const abcd = useSelector((state: any) => state.polls);
    const open = Boolean(anchorEl);
    const [value, setValue] = React.useState<any>([]);
    const [data, setData] = React.useState<any>([]);
    const [step, setStep] = React.useState(0);
    // const history = useHistory();
    const subDetails = useSelector((state: any) => state.subscription);

    const pollsData = mycontext.currentPoll;
    const [alert, setAlert] = React.useState(false);
    const [popup, setPopup] = React.useState({
        alertMessage: '',
        severity: '',
    });
    let total: any = 0;
    choices.forEach((a: any) => (total += a.value));
    const renderer = (props: any) => {
        if (props.completed) return <div>Expired</div>;
        if (props.hours === 0 && props.minutes === 0) return <div>{props.seconds} sec</div>;
        if (props.hours === 0)
            return (
                <div>
                    {props.minutes} mins {props.seconds} sec
                </div>
            );
        return (
            <div>
                {props.hours} hrs {props.minutes} mins
            </div>
        );
    };

    const pollResult = useSubscription(getPollResult(pollsData.pollId));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, choice: string) => {
        setAnchorEl(event.currentTarget);
        setCurrentChoice(choice);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const arr = choices.filter((v: any) => (v.value === 0 ? null : v.name));

    const color: any = choices.filter((a: any, i: number) => Object.assign(a, { color: COLORS[i++] }));

    const arr2: any = color.filter((v: any) => (v.value !== 0 ? v : null));

    const arr3: any = arr2.map((v: any) => v.color);

    const handleChange = (event: any) => {
        setValue([event.target.value]);
    };

    const handleMultiChange = (event: any) => {
        const data = event.target.value;
        const index = value.indexOf(data);
        if (index > -1) {
            setValue([...value.slice(0, index), ...value.slice(index + 1)]);
        } else {
            setValue([...value, ...[data]]);
        }
    };

    React.useEffect(() => {
        async function fetchAll() {
            const data = await fetchPollResult(pollsData.pollId, mycontext.token);
            setData(data.filter((d: any) => d.Employee.employeeId === mycontext.EmployeeId));
            setValue(
                data.filter((d: any) => d.Employee.employeeId === mycontext.EmployeeId).length > 0
                    ? data.filter((d: any) => d.Employee.employeeId === mycontext.EmployeeId)[0]?.userChoice
                    : [],
            );
        }
        fetchAll();
    }, [pollsData, mycontext]);
    const handleSubmit = async () => {
        try {
            const pollSubscriptions = subDetails.subscription.usermanagementdb_Subscription.filter((sub: any) =>
                sub.planId.startsWith('pbtp'),
            );
            if (pollSubscriptions.length === 0) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }

            const license = subDetails.userSubscriptions.usermanagementdb_tenant_employee[0].pollSubscriptionId;

            if (!license) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }

            const activeSub = pollSubscriptions.some((sub: any) => {
                if (sub.subscriptionId === license) {
                    if (sub.status === 'Subscribed') {
                        return true;
                    } else if (sub.status === 'Suspended') {
                        throw new Error(
                            'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                        );
                    } else if (sub.status === 'Unsubscribed') {
                        throw new Error(
                            'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                        );
                    }
                }
                return false;
            });

            if (!activeSub) {
                throw new Error('Somthing went wrong, please try again.');
            }
            const a: any = abcd?.polls.map((b: any) => b?.pollId);

            if (a.indexOf(pollsData.pollId) === -1) {
                throw new Error('Poll does not exist it was deleted by creator!');
            }
            if (value.length === 0) {
                throw new Error('Please Select Choices');
            }
            const obj: any = {
                pollId: pollsData.pollId,
                employeeId: mycontext.EmployeeId,
                userChoices: value,
                token: mycontext.token,
            };
            // setTimeout(() => console.log('b', obj), 3000)

            const resp = await createChoices(obj);
            // console.log('abcd', resp);
            if (resp.error) {
                throw new Error('failed to create Choices!');
            }
            setAlert(true);
            setPopup({
                alertMessage: 'Choices created successfully!',
                severity: 'success',
            });
            setTimeout(() => props?.setStep(0), 2000);
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };

    const handleSubmit2 = async () => {
        try {
            const pollSubscriptions = subDetails.subscription.usermanagementdb_Subscription.filter((sub: any) =>
                sub.planId.startsWith('pbtp'),
            );
            if (pollSubscriptions.length === 0) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }

            const license = subDetails.userSubscriptions.usermanagementdb_tenant_employee[0].pollSubscriptionId;

            if (!license) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }

            const activeSub = pollSubscriptions.some((sub: any) => {
                if (sub.subscriptionId === license) {
                    if (sub.status === 'Subscribed') {
                        return true;
                    } else if (sub.status === 'Suspended') {
                        throw new Error(
                            'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                        );
                    } else if (sub.status === 'Unsubscribed') {
                        throw new Error(
                            'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                        );
                    }
                }
                return false;
            });

            if (!activeSub) {
                throw new Error('Somthing went wrong, please try again.');
            }
            const obj = {
                pollId: pollsData.pollId,
                employeeId: mycontext.EmployeeId,
                userChoices: value,
                token: mycontext.token,
            };
            // console.log(obj);
            const resp = await updateChoices(obj);
            if (resp.error) {
                throw new Error('failed to update choices!');
            }
            // history.push('/polls');
            setAlert(true);
            setPopup({
                alertMessage: 'Choices updated successfully!',
                severity: 'success',
            });
            setTimeout(() => props?.setStep(0), 2000);
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };
    // useEffect(() => {
    //     async function fetchAll() {
    //         const data = await fetchPollResult(pollsData.pollId, mycontext.token);
    //         // console.log(data);
    //         const a: any = [];
    //         const obj: any = [];
    //         pollsData.adminChoices.map((n: string) => {
    //             let c = 0;
    //             data.map((d: any) => {
    //                 if (d.userChoice.includes(n)) {
    //                     c = c + 1;
    //                     return a.push({
    //                         firstName: d.Employee.firstName,
    //                         lastName: d.Employee.lastName,
    //                         image: d.Employee.profileImage,
    //                         userChoice: n,
    //                     });
    //                 }
    //                 return null;
    //             });
    //             obj.push({
    //                 name: n,
    //                 value: c,
    //             });
    //             return null;
    //         });
    //         // console.log('filtered data ', a, obj);
    //         setUsers(a);
    //         setChoices(obj);
    //     }
    //     fetchAll();
    // }, [pollsData, mycontext]);

    React.useMemo(() => {
        if (!pollResult.loading) {
            const a: any = [];
            const obj: any = [];
            pollsData.adminChoices.map((n: string) => {
                let c = 0;
                pollResult?.data?.pollsdb_poll_result?.map((d: any) => {
                    if (d.userChoice.includes(n)) {
                        c = c + 1;
                        return a.push({
                            firstName: d.Employee.firstName,
                            lastName: d.Employee.lastName,
                            image: d.Employee.profileImage,
                            userChoice: n,
                        });
                    }
                    return null;
                });
                obj.push({
                    name: n,
                    value: c,
                });
                return null;
            });
            setUsers(a);
            setChoices(obj);
        }
    }, [pollResult, pollsData]);
    // console.log('poll', pollResult?.data?.pollsdb_poll_result);

    const handleClick1 = () => {
        try {
            const pollSubscriptions = subDetails.subscription.usermanagementdb_Subscription.filter((sub: any) =>
                sub.planId.startsWith('pbtp'),
            );

            if (pollSubscriptions.length === 0) {
                throw new Error('Sorry, this feature is for subscribers only. Upgrade now for full app access');
            }

            const license = subDetails.userSubscriptions.usermanagementdb_tenant_employee[0].pollSubscriptionId;

            if (!license) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use Polls by TeamPlus, kindly request your team admin to assign you a license",
                );
            }

            const activeSub = pollSubscriptions.some((sub: any) => {
                if (sub.subscriptionId === license) {
                    if (sub.status === 'Subscribed') {
                        return true;
                    } else if (sub.status === 'Suspended') {
                        throw new Error(
                            'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                        );
                    } else if (sub.status === 'Unsubscribed') {
                        throw new Error(
                            'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                        );
                    }
                }
                return false;
            });

            if (!activeSub) {
                throw new Error('Somthing went wrong, please try again.');
            }
            const a: any = abcd?.polls.map((b: any) => b?.pollId);

            if (a?.indexOf(pollsData?.pollId) === -1) {
                throw new Error('Poll does not exist it was deleted by creator!');
            }
            setStep(1);
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };

    if (step === 1) {
        return <Comment setStep={setStep} pollId={pollsData.pollId} />;
    }

    return (
        <>
            {/* <Headers
                setStep={props.setStep}
                handleClick1={handleClick1}
            /> */}
            <Header
                setStep={props?.setStep}
                handleClick1={handleClick1}
                button={1}
                title="Poll Results"
                buttonText="Comment"
            />
            <PopUp open={alert} message={popup.alertMessage} severity={popup.severity} setOpen={setAlert} />
            <div>
                <Paper elevation={0} className={classes.paper2}>
                    <div className={classes.scroll}>
                        <div className={classes.maindiv}>
                            <AvtarComponents pollsData={pollsData} renderer={renderer} />
                            <Grid container>
                                <Grid container className={classes.container3} style={{ paddingLeft: '14px' }}>
                                    {total === 1 ? (
                                        <Typography className={classes.heading1} data-testid="choices">
                                            Choices (Total Response: {total}){' '}
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.heading1}>
                                            Choices (Total Responses: {total}){' '}
                                        </Typography>
                                    )}
                                </Grid>
                                <ChoicePart
                                    pollsData={pollsData}
                                    value={value}
                                    handleChange={handleChange}
                                    handleMultiChange={handleMultiChange}
                                    users={users}
                                    handleClick={handleClick}
                                    open={open}
                                    currentChoice={currentChoice}
                                    anchorEl={anchorEl}
                                    handleClose={handleClose}
                                    COLORS={COLORS}
                                    arr={arr}
                                    arr3={arr3}
                                />
                                <ChoicesMobilePart
                                    pollsData={pollsData}
                                    value={value}
                                    handleChange={handleChange}
                                    handleMultiChange={handleMultiChange}
                                    users={users}
                                    handleClick={handleClick}
                                    open={open}
                                    currentChoice={currentChoice}
                                    anchorEl={anchorEl}
                                    handleClose={handleClose}
                                    COLORS={COLORS}
                                    arr={arr}
                                    arr3={arr3}
                                />
                            </Grid>
                        </div>
                        <div className={classes.btnMain} data-testid="submitButton">
                            {data.length !== 0 ? (
                                <Button
                                    onClick={() => handleSubmit2()}
                                    variant="contained"
                                    color="primary"
                                    className={classes.btn1}
                                >
                                    Update
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => handleSubmit()}
                                    variant="contained"
                                    color="primary"
                                    className={classes.btn1}
                                >
                                    Submit
                                </Button>
                            )}
                        </div>
                    </div>
                </Paper>
            </div>
        </>
    );
}
