import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formLabel: {
            width: '100%',
            paddingRight: 25,
            textAlign: 'right',
            paddingTop: 10,
            paddingBottom: 10,
            color: 'black',
            fontWeight: 700,
            fontSize: '15px',
            opacity: 0.6,
            '@media (max-width: 650px)': {
                textAlign: 'left',
                paddingBottom: 0,
            },
        },
        formControl: {
            width: '100%',
            // backgroundColor: theme.palette.background.paper,
            color: 'white',
            textColor: 'white',
            calendarTextColor: 'grey',
            selectColor: 'red',
            selectTextColor: 'white',
            calendarYearBackgroundColor: 'red',
            margin: 0,
            '@media only screen and (max-width: 650px)': {
                width: '100%',
            },
            '& div': {
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
        },
        dateField: {
            // border: `1px solid ${theme.palette.primary.contrastText}`,
            outline: 'none !important',
            color: 'white',
            textColor: 'white',
            calendarTextColor: 'grey',
            selectColor: 'red',
            selectTextColor: 'white',
            calendarYearBackgroundColor: 'red',
            '&:focus': {
                outline: 'none !important',
                // border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                outline: 'none !important',
            },
        },
        menuItem: {
            // backgroundColor: '#fff',
            // '&:focus': {
            //     backgroundColor: theme.palette.primary.light,
            // },
        },
        selectStyle: {
            outline: `1px solid ${theme.palette.primary.contrastText}`,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            '&:active': {
                // border: `1px solid ${theme.palette.primary.contrastText}`,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '&:focus': {
                // border: `1px solid ${theme.palette.primary.contrastText}`,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '& .MuiSelect-icon': {
                color: theme.palette.primary.contrastText,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
                // outline: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: '0px 3px',
            height: '17px',
        },
    }),
);

export default useStyles;
