import React, { useContext } from 'react';
import { Typography, Avatar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import profileStyle from './profileStyle';
import AuthContext from '../../../context/AuthContext';
// import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { closeSidebar } from '../../Redux/sidebarReducer';

interface Props {
    name?: string;
    linkToMain?: boolean;
    img?: string;
    open?: boolean;
}
function Profile(props: Props): JSX.Element {
    const classes = profileStyle();
    const mycontext = useContext(AuthContext);
    const dispatch = useDispatch();
    const history = useHistory();

    const profileLink = () => {
        // mycontext.addUserProfile(user.id);
        history.push({
            pathname: '/user/profile',
            state: {
                userLink: false,
                backTo: 'employees',
            },
        });
        dispatch(closeSidebar());
    };

    return (
        <div className={classes.root}>
            <div className={classes.profileDiv}>
                <div style={{ display: 'flex' }} onClick={profileLink}>
                    <div className={classes.avatarDiv}>
                        <Avatar
                            data-testid="avatar"
                            alt="Person"
                            className={classes.avatar}
                            src={mycontext.profile || '/avatars/avatar_11.png'}
                        />
                    </div>
                    {/* <Typography className={classes.heading}>Welcome</Typography> */}
                    <Typography className={classes.nameDiv} data-testid="typo">
                        {props.linkToMain ? (
                            <span
                                data-testid="link"
                                className={classes.name}
                                // to={{ pathname: '/user/profile' }}
                            >
                                {mycontext.fname + ' ' + mycontext.lname}
                            </span>
                        ) : (
                            <span data-testid="span" className={classes.name}>
                                {props.name}
                            </span>
                        )}
                    </Typography>
                </div>
                <ArrowBackIosOutlinedIcon
                    data-testid="arrowbackicon"
                    className={classes.icon}
                    onClick={() => dispatch(closeSidebar())}
                />
            </div>
        </div>
    );
}

export default Profile;
