import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Donut } from '../donut';
import { useStyles } from './employeeProfileStyles';

interface PieChartDataProps {
    orgWorkingHours?: any;
    totalUsedDays?: any;
    totalAvailableDays?: any;
}

export default function EmployeePieChart(props: PieChartDataProps) {
    const classes = useStyles();
    const COLORS2 = ['#3F9F6B', '#EAA85B'];
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    const data2 = [
        { name: 'Leaves used', value: Math.floor(props.totalUsedDays / props.orgWorkingHours) },
        {
            name: 'Leaves available',
            value: props.totalAvailableDays - Math.floor(props.totalUsedDays / props.orgWorkingHours),
        },
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12} className={classes.commonStyle1}>
                <Typography className={classes.pieChartTitle}>Time-off status for {getCurrentYear()}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.commonStyle1}>
                <Donut data={props.totalAvailableDays === 0 ? [] : data2} COLORS={COLORS2} />
            </Grid>
        </div>
    );
}
