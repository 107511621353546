/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Hidden } from '@material-ui/core';
import RegisterPartOne from './registerPartOne';
import RegisterPartTwo from './registerPartTwo';
import MultipleUserRegistration from './registerPartThree';
import UserAlreadyRegistered from './userAlreadRegistered';
import { Auth } from 'aws-amplify';
import useStyles from '../Login/LoginStyle';
import AuthContext from '../../context/AuthContext';
import { toAWSDateFormat } from '../../helpers/date';
import {
    createOrganizationRole,
    insertTeamEmployee,
    registerAdmin,
    isEmployeeInOrganization,
    getOrganization,
} from '../../helpers/hasuraapi';
import { useHistory, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
// import { LoaderPage } from '../Loader/loader';
import { NameLoader, ThreeDotsLoader } from '../Spinner/Spinner';
// import TeamPlusLogoMobile from '../../Assets/images/udyamo-splash-screen.png';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

export const TeamplusLoader = () => {
    const classes = useStyles();
    return (
        <>
            <Hidden smDown>
                <div className={classes.rootLoader}>
                    <NameLoader />
                    <ThreeDotsLoader />
                </div>
            </Hidden>
            <Hidden smUp>
                <div className={classes.loginMain}>
                    <h3 className={classes.welcome}>Welcome</h3>
                    <h3 className={classes.to}>To</h3>
                    <img
                        src={s3Bucket + registerLoginPageImages.udyamoSplashScreen}
                        alt="teamplus"
                        className={classes.teamLogoSmallMobile}
                    />
                </div>
            </Hidden>
        </>
    );
};
export default function RegisterAdminMain() {
    const mycontext = useContext(AuthContext);
    // const location: any = useLocation();
    // const classes = useStyles();
    const [pageNumber, setPageNumber] = React.useState(0);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [emailId, setEmailId] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [orgDetails, setOrgDetails] = React.useState({
        orgId: '',
        orgName: '',
        orgLogo: null,
        location: null,
    });
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [code, setCode] = React.useState<string>('');
    const location = useLocation();
    const search: any = location.search;
    const orgId: any = new URLSearchParams(search).get('orgId');
    const email: any = new URLSearchParams(search).get('email');
    const teams: any = new URLSearchParams(search).get('teams');
    const [isLoading, setIsLoading] = React.useState(false);
    // const [isLoading, setIsLoading] = React.useState(false);

    // const [task, setTask] = React.useState({
    //     task1: false,
    //     task2: false,
    //     task3: false,
    //     task4: false,
    // });
    const history = useHistory();
    const myteams: any = teams.split(',');

    const backButton = () => {
        setPageNumber(pageNumber - 1);
    };

    const handleNewOrganization = async () => {
        try {
            setIsLoading(true);
            const loginResponse = await Auth.signIn(emailId, password);
            const decoded: any = jwt_decode(loginResponse.signInUserSession.idToken.jwtToken);
            const empRole = {
                organizationId: orgId,
                role: 'employee',
                token: loginResponse.signInUserSession.idToken.jwtToken,
                location: orgDetails.location,
            };
            await createOrganizationRole(empRole);
            let l = myteams.length;
            while (l !== 0) {
                const teamUser = {
                    employeeId: decoded.sub,
                    isApprover: false,
                    teamId: myteams[l - 1],
                    token: loginResponse.signInUserSession.idToken.jwtToken,
                };
                await insertTeamEmployee(teamUser);
                l--;
            }
            setIsLoading(false);
            history.push({
                pathname: '/myorganizations',
                state: {
                    id: decoded.sub,
                    token: loginResponse.signInUserSession.idToken.jwtToken,
                    refreshToken: loginResponse.signInUserSession.refreshToken.token,
                    isSignup: true,
                },
            });
        } catch (err) {
            return err;
        }
    };

    const handleSubmit = async () => {
        try {
            const loginResponse = await Auth.signIn(emailId, password);
            setIsLoading(true);
            const decoded: any = jwt_decode(loginResponse.signInUserSession.idToken.jwtToken);
            const employee: any = {
                email: emailId,
                firstName: firstName,
                lastName: lastName,
                joining: toAWSDateFormat(new Date()),
                token: loginResponse.signInUserSession.idToken.jwtToken,
            };
            await registerAdmin(employee);
            const empRole = {
                organizationId: orgId,
                role: 'employee',
                token: loginResponse.signInUserSession.idToken.jwtToken,
                location: orgDetails.location,
            };
            await createOrganizationRole(empRole);
            let l = myteams.length;
            while (l !== 0) {
                const teamUser = {
                    employeeId: decoded.sub,
                    isApprover: false,
                    teamId: myteams[l - 1],
                    token: loginResponse.signInUserSession.idToken.jwtToken,
                };
                await insertTeamEmployee(teamUser);
                l--;
            }
            setIsLoading(false);
            history.push({
                pathname: '/myorganizations',
                state: {
                    id: decoded.sub,
                    token: loginResponse.signInUserSession.idToken.jwtToken,
                    refreshToken: loginResponse.signInUserSession.refreshToken.token,
                    isSignup: true,
                },
            });
        } catch (err) {
            return err;
        }
    };

    const values = {
        firstName: firstName,
        setFirstName: setFirstName,
        lastName: lastName,
        setLastName: setLastName,
        emailId: emailId,
        setEmailId: setEmailId,
        password: password,
        setPassword: setPassword,
        confirmPassword: confirmPassword,
        setConfirmPassword: setConfirmPassword,
        code: code,
        setCode: setCode,
        backButton: backButton,
        pageNumber: pageNumber,
        handleSubmit: handleSubmit,
        handleNewOrganization: handleNewOrganization,
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        orgDetails: orgDetails,
        orgId: orgId,
        teams: myteams,
        // isLoading: isLoading,
        // task: task,
    };
    useEffect(() => {
        // handleSubmit();
        if (mycontext.token) {
            mycontext.logout();
            history.push(location.pathname + location.search);
        }
        setEmailId(email);
        setIsLoading(true);
        async function checkUser() {
            const orgData = await getOrganization(orgId);
            setOrgDetails({
                orgId: orgData.organizationId,
                orgName: orgData.organizationName,
                // orgLogo: orgData.organizationLogo,
                orgLogo: orgData.organizationName,
                location: orgData.country,
            });
            const data = await isEmployeeInOrganization(orgId, email);
            if (
                data.usermanagementdb_organization_employee_aggregate.aggregate.count === 1 &&
                data.usermanagementdb_Employee_aggregate.aggregate.count === 1
            ) {
                setPageNumber(3);
            } else if (
                data.usermanagementdb_organization_employee_aggregate.aggregate.count === 0 &&
                data.usermanagementdb_Employee_aggregate.aggregate.count === 1
            ) {
                setPageNumber(2);
            } else {
                setPageNumber(0);
            }
            setIsLoading(false);
        }
        checkUser();
    }, []);
    // if (isLoading)
    //     return (
    //         <div
    //             style={{
    //                 width: '100vw',
    //                 height: '100vh',
    //                 display: 'flex',
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //             }}
    //         >
    //             <LoaderPage />
    //         </div>
    //     );
    if (isLoading) {
        return <TeamplusLoader />;
    }
    switch (pageNumber) {
        case 0:
            return <RegisterPartOne values={values} setPageNumber={setPageNumber} />;
        case 1:
            return <RegisterPartTwo values={values} setPageNumber={setPageNumber} />;
        case 2:
            return <MultipleUserRegistration values={values} />;
        case 3:
            return <UserAlreadyRegistered values={values} />;
        default:
            return <div></div>;
    }
}
