import React, { useState } from 'react';
import { Grid, Paper, FormLabel, Button, Hidden, Box, TextField, InputAdornment, IconButton } from '@material-ui/core';
// import Page1Img from '../../Assets/images/Page1Img.png';
// import udyamo from '../../Assets/images/udyamo.png';
import useStyles from './newRegisterStyles';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import google from '../Login/google.svg';
import { createOrganizationRole, insertTeamEmployee } from '../../helpers/hasuraapi';
import { PopUp } from '../Modal/Modal';
import { useHistory } from 'react-router-dom';
import { TeamplusLoader } from './registerMain';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import Icon from '@material-ui/core/Icon';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

interface NewRegister {
    values?: any;
}
const signIn = async () => {
    await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
    });
};
interface NewRegister {
    values?: any;
}

export default function MultipleUserRegistration(props: NewRegister) {
    const classes = useStyles();
    const history = useHistory();
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [severity, setSeverity] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [isGoogle, setIsGoogle] = React.useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            if (!isGoogle) {
                if (props.values.password === '') {
                    throw new Error('Please enter your password!');
                }
                const loginResponse = await Auth.signIn(props.values.emailId, props.values.password);
                const decoded: any = jwt_decode(loginResponse.signInUserSession.idToken.jwtToken);
                setIsLoading(true);
                const empRole = {
                    organizationId: props.values.orgId,
                    role: 'employee',
                    token: loginResponse.signInUserSession.idToken.jwtToken,
                    location: props.values.orgDetails.location,
                };
                await createOrganizationRole(empRole);
                let l = props.values.teams.length;
                while (l !== 0) {
                    const teamUser = {
                        employeeId: decoded.sub,
                        isApprover: false,
                        teamId: props.values.teams[l - 1],
                        token: loginResponse.signInUserSession.idToken.jwtToken,
                    };
                    await insertTeamEmployee(teamUser);
                    l--;
                }
                setIsLoading(false);
                history.push({
                    pathname: '/myorganizations',
                    state: {
                        id: decoded.sub,
                        token: loginResponse.signInUserSession.idToken.jwtToken,
                        isSignup: true,
                    },
                });
            } else {
                signIn();
            }
        } catch (err) {
            setIsLoading(false);
            setAlert(true);
            setAlertMessage(err.message);
            setSeverity('error');
            return err;
        }
    };
    const customSvgGoogle = (
        <Icon fontSize="small" style={{ marginRight: 5 }}>
            <img src={google} className={classes.googleImg} alt="GoogleIcon"></img>
        </Icon>
    );

    if (isLoading) return <TeamplusLoader />;
    return (
        <Box className={classes.root}>
            <Grid container direction="row">
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.registerPageImage}
                                    alt="Page1Img"
                                    style={{ height: '75vh' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                    alt="udyamoLogo"
                                    style={{ height: '2.5vh' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} lg={12} xl={7} className={classes.secondGrid}>
                    <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                    <Paper style={{ boxShadow: 'none' }} className={classes.paperStyleOTP}>
                        <form className={classes.secondGridSmall} onSubmit={handleSubmit}>
                            <Grid container direction="row">
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <FormLabel className={classes.title}>Register</FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} className={classes.label2}>
                                        <p className={classes.para1}>
                                            Looks like <strong>{props.values.emailId}</strong> is already registered.
                                            Click on Continue to join <strong>{props.values.orgDetails.orgName}</strong>
                                        </p>
                                        <p className={classes.para1}>Please enter your Password.</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} className={classes.label2}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            size="small"
                                            name="password"
                                            id="password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={props.values.password}
                                            onChange={
                                                /* istanbul ignore next */ (event: any) =>
                                                    props.values.setPassword((event.target as HTMLInputElement).value)
                                            }
                                            className={classes.passwordInput}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            className={classes.passswordButtonStyle}
                                                        >
                                                            {/* istanbul ignore next */ showPassword ? (
                                                                <VisibilityOffIcon />
                                                            ) : (
                                                                <VisibilityIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.forgotPasswordStyle}>
                                        <Link
                                            to={{ pathname: '/forgotpassword' }}
                                            style={{ color: '#0F0F0F', textDecoration: 'none' }}
                                            id="forgot-password"
                                        >
                                            Forgot Password?
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} sm={12} className={classes.verifybutton}>
                                        <Button
                                            id="verify"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            // onClick={() => props.values.verifyOTP()}
                                            className={classes.hoverStyle}
                                        >
                                            Continue
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.label2}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <Button
                                            id="registerBtn"
                                            className={classes.authBtn}
                                            style={{ width: '100%' }}
                                            type="submit"
                                            onClick={() => setIsGoogle(true)}
                                        >
                                            {customSvgGoogle}
                                            Continue with google
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} justifyContent="center">
                                            <p className={classes.para2}>
                                                Login to existing organizations? <Link to="/login">Click here</Link>{' '}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
