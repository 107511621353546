import { createSlice } from '@reduxjs/toolkit';
// import { fetchtimeoff } from 'helpers/hasuraapi';

// export const getTimeOffPolicy: any = createAsyncThunk('timeOffPolicy/getTimeOffPolicy', async (org: any) => {
//     const data: any = await fetchtimeoff(org.organizationId, org.token);
//     const jsonData: any = await data.json();
//     return jsonData.data.timeoffdb_LeaveType;
// });

// export const getUnlimitedDaySwitch: any = createAsyncThunk('timeOffPolicy/getUnlimitedDaySwitch', async (org: any) => {
//     const data: any = await UnlimitedDayStatus(org.organizationId, org.token);
//     const jsonData: any = await data.json();
//     return jsonData.data.update_timeoffdb_LeaveType;
// });

export const timeOffPolicySlice = createSlice({
    name: 'timeOffPolicy',
    initialState: { timeOffPolicy: [], status: 'loading' },
    reducers: {
        editTimeOff: (state: any, action: any) => {
            state.timeOffPolicy.forEach((item: any) => {
                if (item.leaveTypeId === action.payload.leaveTypeId) {
                    item.leaveTypeName = action.payload.leaveTypeName;
                    item.emoji = action.payload.emoji;
                    item.isPaid = action.payload.isPaid;
                    item.daysPerYear = action.payload.daysPerYear;
                }
            });
        },
        editUnlimitedDays: (state: any, action: any) => {
            state.timeOffPolicy.forEach((element: any) => {
                element.unlimitedDays = action.payload.unlimitedDays;
            });
        },
        activeInactiveTimeOff: (state: any, action: any) => {
            state.timeOffPolicy.forEach((item: any) => {
                if (item.leaveTypeId === action.payload.leaveTypeId) {
                    item.active = action.payload.active;
                    item.approvalRequired = action.payload.approvalRequired;
                }
            });
        },
        addTimeOff: (state: any, action: any) => {
            state.timeOffPolicy.push(action.payload);
        },
        deleteTimeOff: (state: any, action: any) => {
            state.timeOffPolicy = state.timeOffPolicy.filter(
                (item: any) => item.leaveTypeId !== action.payload.leaveTypeId,
            );
        },
        fetchTimeOff: (state: any, action: any) => {
            state.timeOffPolicy = action.payload;
        },
        getTimeOff: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                state.timeOffPolicy = action.payload?.data?.timeoffdb_LeaveType;
            }
        },
    },
    // extraReducers: {
    //     [getTimeOffPolicy.pending]: (state: any) => {
    //         state.status = 'loading';
    //     },
    //     [getTimeOffPolicy.fulfilled]: (state: any, action: any) => {
    //         state.timeOffPolicy = action.payload;
    //         state.status = 'success';
    //     },
    //     [getTimeOffPolicy.rejected]: (state: any) => {
    //         state.status = 'failed';
    //     },
    // },
});

export const {
    editTimeOff,
    editUnlimitedDays,
    activeInactiveTimeOff,
    addTimeOff,
    deleteTimeOff,
    fetchTimeOff,
    getTimeOff,
} = timeOffPolicySlice.actions;
export default timeOffPolicySlice.reducer;
