/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import useStyles from '../Login/LoginStyle';
import { NameLoader, ThreeDotsLoader } from '../Spinner/Spinner';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

export default function RegisterGoogleEmployee() {
    const classes = useStyles();
    const [error1, setError] = useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const error_desc = params.get('error_description') || null;
    const code = params.get('code') || null;

    useEffect(() => {
        const signIn = async () => {
            await Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
            });
        };
        if (code) {
            signIn();
        }
        if (error_desc) {
            setError(true);
            setTimeout(() => signIn(), 5000);
        }
    }, []);
    return (
        <div className={classes.rootLoader}>
            <h2 style={{ color: 'white' }}>
                {error1
                    ? 'You have successfully registered your Google account with teamplus. Please Login again to continue.'
                    : ''}
            </h2>
            <NameLoader />
            <ThreeDotsLoader />
        </div>
    );
}
