import React, { useContext } from 'react';
// import { Donut } from '../donut';
import { Line } from 'react-chartjs-2';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Accordion from '@material-ui/core/Accordion';
import RemoveIcon from '@material-ui/icons/Remove';
import {
    Typography,
    Avatar,
    Hidden,
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
    // Popover,
    Checkbox,
    Divider,
    Tooltip as Tooltip2,
    useTheme,
    Menu,
    Paper,
    // Input,
    // InputAdornment,
} from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// import { withStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import logo from '../../Assets/images/profile1.png';
import { getFullName } from 'helpers/getInitials';
// import { ResponsiveLine } from '@nivo/line';
// import { graphdata } from './const';
import useStyles from './DashboardStyle';
import {
    ToStringFormat,
    DateDifference,
    getTimeDifferenceMinute,
    convertTime,
    // toBrowserDateFormat,
    convertTimeInTZ,
    toAWSDateFormat,
    // changeTimeZone,
    // convertTZ,
} from '../../helpers/date';
import { isoToLocal, isoToLocalTime } from 'helpers/timezone';
import { LineChart, XAxis, YAxis, Legend, Line as LineDraw, Tooltip } from 'recharts';
import AuthContext from '../../context/AuthContext';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import SearchIcon from '@material-ui/icons/Search';
// import todayTimeoff from './todaysTimeoff.png';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import { Link } from 'react-router-dom';
import { AgChartsReact } from 'ag-charts-react';
import Skeleton from '@material-ui/lab/Skeleton';
import { updateLeaveAdminMultiple } from '../../helpers/hasuraapi';
import EditIcon from '@material-ui/icons/Edit';
// import { sendNotificationToMembers1 } from '../AppNotification/AppNotification';
import DisplayEmoji from '../TeamMembers/DisplayEmoji';
import { sendAprrovedNotifToMembers, getMultiApproveTokenFromLeave } from '../WebNotification/Notification';
import { handleNotif } from 'helpers/sendNotif';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Donut4 } from 'components/donut/donutOuter';

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

// Preparing the chart data
const chartData = [
    {
        label: 'Venezuela',
        value: '290',
    },
    {
        label: 'Saudi',
        value: '260',
    },
    {
        label: 'Canada',
        value: '180',
    },
    {
        label: 'Iran',
        value: '140',
    },
    {
        label: 'Russia',
        value: '115',
    },
    {
        label: 'UAE',
        value: '100',
    },
    {
        label: 'US',
        value: '30',
    },
    {
        label: 'China',
        value: '30',
    },
];

// Create a JSON object to store the chart configurations
const chartConfigs = {
    type: 'line', // The chart type
    width: '300', // Width of the chart
    height: '250', // Height of the chart
    dataFormat: 'json', // Data type
    dataSource: {
        // Chart Configuration
        chart: {
            // caption: 'Countries With Most Oil Reserves [2017-18]', //Set the chart caption
            // subCaption: 'In MMbbl = One Million barrels', //Set the chart subcaption
            xAxisName: 'Country', //Set the x-axis name
            yAxisName: 'Reserves (MMbbl)', //Set the y-axis name
            // numberSuffix: 'K',
            theme: 'fusion', //Set the theme for your chart
        },
        // Chart Data - from step 2
        data: chartData,
    },
};
interface StatisticsInterface {
    data?: any;
    data2?: any;
    COLORS?: any;
    COLORS2?: any;
    month?: string;
    setMonth?: any;
    updatePieChart?: any;
    lTypes?: any;
    todayTimeoff?: any;
}
export const EmployeeStatistics = (props: StatisticsInterface) => {
    const classes = useStyles();
    //console.log(props.data2)
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // /* istanbul ignore next */
    // const handleClick = (event: any) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // /* istanbul ignore next */
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const MONTHS = [
    //     {
    //         label: 'Jan',
    //         value: 'January',
    //     },
    //     {
    //         label: 'Feb',
    //         value: 'February',
    //     },
    //     {
    //         label: 'Mar',
    //         value: 'March',
    //     },
    //     {
    //         label: 'Apr',
    //         value: 'April',
    //     },
    //     {
    //         label: 'May',
    //         value: 'May',
    //     },
    //     {
    //         label: 'Jun',
    //         value: 'June',
    //     },
    //     {
    //         label: 'Jul',
    //         value: 'July',
    //     },
    //     {
    //         label: 'Aug',
    //         value: 'August',
    //     },
    //     {
    //         label: 'Sep',
    //         value: 'September',
    //     },
    //     {
    //         label: 'Oct',
    //         value: 'October',
    //     },
    //     {
    //         label: 'Nov',
    //         value: 'November',
    //     },
    //     {
    //         label: 'Dec',
    //         value: 'December',
    //     },
    // ];
    // const open = Boolean(anchorEl);
    // /* istanbul ignore next */
    // const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <div style={{ padding: '0px 5px' }}>
                <Grid data-testid="empStats" className={classes.employeeStatistics} container spacing={1}>
                    <Grid item xs={12} style={{ marginTop: '10px', marginLeft: 10 }}>
                        {' '}
                        <p className={classes.accordionTitle}>Employee status today</p>{' '}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/*
                    <div className={classes.piechartDiv}>
                    <div className={classes.piechartColumn}>
                    <Donut data={props.data} COLORS={props.COLORS} lTypes={props.lTypes} text="donut" />
                    <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                            >
                                <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                                > */}
                        {/* <p className={classes.pieChartDropDown}>
                                        <span className={classes.textTheme}>Time-off in</span>{' '}
                                        <span className={classes.pieChartDropDownTitle}>{props.month}</span>{' '}
                                        <ArrowDropDownIcon
                                        fontSize="default"
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleClick}
                                        />
                                    </p> */}
                        {/* <Input
                                    id="standard-adornment-weight"
                                    value={props.month}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            onClick={handleClick}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            >
                                            <ArrowDropDownIcon fontSize="default" style={{ cursor: 'pointer' }} />
                                            </InputAdornment>
                                        }
                                        aria-describedby="standard-weight-helper-text"
                                        className={classes.inputFirst}
                                        margin="dense"
                                    /> */}
                        {/* <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        background: 'red',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleClick}
                                    >
                                    <p style={{ fontWeight: 500, color: '#3C48B5', margin: 'auto' }}>{props.month}</p>
                                    <ArrowDropDownIcon
                                    fontSize="default"
                                    style={{ cursor: 'pointer', marginLeft: 10 }}
                                    />
                                </div> */}
                        {/* <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        classes={{
                                            paper: classes.popover,
                                        }}
                                        >
                                        <div
                                            style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 60px)' }}
                                            data-testid="mapMonth"
                                            >
                                            {MONTHS.map((m: any, i: number) => (
                                                <div
                                                key={i}
                                                className={classes.textDiv}
                                                onClick={
                                                    () => {
                                                        props.setMonth(m.value);
                                                        handleClose();
                                                    }
                                                    }
                                                    >
                                                    <Typography className={classes.popoverText}>{m.label}</Typography>
                                                    </div>
                                                    ))}
                                                    </div>
                                                </Popover> */}
                        {/* </div>
                            </div>
                        </div> */}
                        {/* <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                        >
                        {props.data.map((d: any, i: number) => (
                            <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '5px 0',
                            }}
                            key={i}
                            >
                            <div
                            style={{
                                height: 5,
                                width: 5,
                                borderRadius: 2,
                                backgroundColor: `${props.COLORS[i]}`,
                                marginRight: 5,
                            }}
                            ></div>
                            <p
                            style={{
                                fontSize: 14,
                                fontFamily: 'Nunito Sans',
                                fontWeight: 'bold',
                                color: `${props.COLORS[i]}`,
                            }}
                            >
                            {d.name}
                            </p>
                            </div>
                            ))}
                        </div> */}
                        <div className={classes.piechartContainer}>
                            <div className={classes.piechartDiv}>
                                <Donut4 data={props.data2} COLORS={props.COLORS2} />
                            </div>
                            <Divider
                                style={{
                                    backgroundColor: '#4FB8B2',
                                    height: '2px',
                                    width: '80%',
                                    marginLeft: '10%',
                                    marginTop: '6%',
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    width: '80%',
                                    justifyContent: 'space-between',
                                    marginLeft: '10%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '9px',
                                    }}
                                >
                                    <Brightness1Icon
                                        style={{
                                            height: '12px',
                                            width: '12px',
                                            color: `${props.COLORS2[1]}`,
                                            marginTop: '5px',
                                            marginRight: '10px',
                                        }}
                                    />
                                    <div
                                        style={{
                                            marginRight: '8px',
                                            color: `grey`,
                                        }}
                                    >
                                        {props.data2[1].name}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            marginTop: -2,
                                            color: 'grey',
                                        }}
                                    >
                                        {props.data2[1].value}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '9px',
                                    }}
                                >
                                    <Brightness1Icon
                                        style={{
                                            height: '12px',
                                            width: '12px',
                                            color: `${props.COLORS2[0]}`,
                                            marginTop: '5px',
                                            marginRight: '10px',
                                        }}
                                    />
                                    <div
                                        style={{
                                            marginRight: '8px',
                                            color: `grey`,
                                        }}
                                    >
                                        {props.data2[0].name}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            marginTop: -2,
                                            color: 'grey',
                                        }}
                                    >
                                        {props.data2[0].value}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid className={classes.timeoffStatusContainer} item xs={12} md={6}>
                        <div className={classes.piechartContainer}>
                            <TimeOffStatus data2={props.todayTimeoff} />
                        </div>
                    </Grid>
                    {/* <Donut data={props.data2} COLORS={props.COLORS2} /> */}
                    {/* <div className={classes.piechartDiv}> */}
                    {/* </div> */}
                    {/* <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                        >
                        {props.data2.map((d: any, i: number) => (
                            <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '5px 0',
                            }}
                            key={i}
                            >
                            <div
                            style={{
                                height: 5,
                                width: 5,
                                borderRadius: 2,
                                backgroundColor: `${props.COLORS2[i]}`,
                                marginRight: 5,
                            }}
                            ></div>
                            <p
                            style={{
                                fontSize: 14,
                                fontFamily: 'Nunito Sans',
                                fontWeight: 'bold',
                                color: `${props.COLORS2[i]}`,
                            }}
                            >
                            {d.name}
                                </p>
                                </div>
                                ))}
                            </div> */}
                    {/* </div> */}
                    {/* </div> */}
                </Grid>
            </div>
        </>
    );
};

export const TimeOffStatus = (props: { data2: any }) => {
    const classes = useStyles();
    const theme = useTheme();
    const currentDate = new Date();
    const [profileMenu, setProfileMenu] = React.useState<any>(null);
    const [teamMember, setTeamMember] = React.useState<any>([]);
    const currentTime = currentDate.toISOString();
    const mycontext = useContext(AuthContext);
    const currentTimeoff = props?.data2?.filter(
        (e: any) =>
            new Date(isoToLocal(e.isoString.endTime, mycontext.country)).getTime() >= new Date(currentTime).getTime() &&
            new Date(isoToLocal(e.isoString.startTime, mycontext.country)).getTime() <= new Date(currentTime).getTime(),
    );

    const upcomingTimeoff = props?.data2?.filter((e: any) => e.noOfHours < mycontext.workingHours);

    const handleProfileMenuClose = () => {
        setProfileMenu(null);
        setTeamMember([]);
    };

    // }, [props.allStandups])
    const handleProfileMenuClick = (event: any, timeoff: any) => {
        setProfileMenu(event?.currentTarget);
        setTeamMember(Object?.values(timeoff)?.map((l: any) => l));
    };
    // //console.log(currentTimeoff);
    return (
        <div>
            <Menu
                anchorEl={profileMenu}
                open={Boolean(profileMenu)}
                onClose={handleProfileMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={classes.scroll1}>
                    {teamMember?.map((e: any, i: number) => (
                        <div
                            key={i}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                margin: '3px 0px',
                            }}
                            onClick={handleProfileMenuClose}
                        >
                            <Tooltip2
                                key={i}
                                title={
                                    <>
                                        <div>Name- {`${e?.Employee?.firstName} ${e?.Employee?.lastName}`}</div>
                                        <div>
                                            Time-{' '}
                                            {e.noOfHours >= mycontext.workingHours
                                                ? `${isoToLocal(e.isoString.startTime, mycontext.country)
                                                      .toString()
                                                      .slice(0, 15)} - ${isoToLocal(
                                                      e.isoString.endTime,
                                                      mycontext.country,
                                                  )
                                                      .toString()
                                                      .slice(0, 15)}`
                                                : `${isoToLocalTime(
                                                      e.isoString.startTime,
                                                      mycontext.country,
                                                  )} - ${isoToLocalTime(e.isoString.endTime, mycontext.country)}`}
                                        </div>
                                        <div>Category- {`${e?.category}`}</div>
                                        <div>Reason- {`${e?.reason}`}</div>
                                    </>
                                }
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                                interactive
                            >
                                <Paper
                                    className={classes.paperAvtar}
                                    style={
                                        // e?.startTime <= currentTime && e?.endTime >= currentTime
                                        //     ? { border: '2px solid #25D366' }
                                        //     : e?.startTime > currentTime && e?.endTime > currentTime
                                        //         ? { border: '2px solid orange' }
                                        //         : { border: '2px solid #CDCDCD' }
                                        e?.category === 'Sick'
                                            ? { border: '2px solid red' }
                                            : new Date(isoToLocal(e.isoString.endTime, mycontext.country)).getTime() >=
                                                  new Date(isoToLocal(currentTime, mycontext.country)).getTime() ||
                                              (e.isoString.startTime.slice(11, 16) === '18:30' &&
                                                  e.isoString.endTime.slice(11, 16) === '18:29')
                                            ? { border: '2px solid #3D49B6' }
                                            : { border: '2px solid #BDB8B8' }
                                    }
                                >
                                    <Avatar src={e?.Employee?.profileImage} className={classes.avatar2}>
                                        {getFullName(e?.Employee?.firstName + ' ' + e?.Employee?.lastName)}
                                    </Avatar>
                                </Paper>
                            </Tooltip2>
                            <Typography style={{ marginLeft: '5px' }}>
                                {e?.Employee?.firstName + ' ' + e?.Employee?.lastName}
                            </Typography>
                        </div>
                    ))}
                </div>
            </Menu>
            <Grid container>
                <Grid item xs={6}>
                    <div>
                        <div className={classes.pointDiv}>
                            {/* <Point colors={'#666666'} title={'Current Timeoff'} /> */}
                            <div style={{ display: 'flex' }}>
                                {/* <FiberManualRecordIcon className={classes.titlePoint} style={{ color: 'red' }} /> */}
                                <div style={{ color: theme.palette.text.primary, fontFamily: 'Nunito Sans' }}>
                                    {' '}
                                    Currently unavailable
                                </div>
                            </div>
                        </div>
                        <div className={classes.avatarDiv}>
                            {currentTimeoff?.length > 0 ? (
                                currentTimeoff?.slice(0, 2)?.map((e: any, key: number) => (
                                    <Tooltip2
                                        key={key}
                                        classes={{ tooltip: classes.tooltip }}
                                        title={
                                            <>
                                                <div>Name- {`${e?.Employee?.firstName} ${e?.Employee?.lastName}`}</div>
                                                <div>
                                                    Time-{' '}
                                                    {e.noOfHours >= mycontext.workingHours
                                                        ? `${isoToLocal(e.isoString.startTime, mycontext.country)
                                                              .toString()
                                                              .slice(0, 15)} - ${isoToLocal(
                                                              e.isoString.endTime,
                                                              mycontext.country,
                                                          )
                                                              .toString()
                                                              .slice(0, 15)}`
                                                        : `${isoToLocalTime(
                                                              e.isoString.startTime,
                                                              mycontext.country,
                                                          )} - ${isoToLocalTime(
                                                              e.isoString.endTime,
                                                              mycontext.country,
                                                          )}`}
                                                </div>
                                                <div>Category- {`${e?.category}`}</div>
                                                <div>Reason- {`${e?.reason}`}</div>
                                            </>
                                        }
                                        placement="top"
                                        interactive
                                    >
                                        <Paper
                                            className={classes.paperAvtar}
                                            style={
                                                e?.category === 'Sick'
                                                    ? { border: '2px solid red' }
                                                    : new Date(
                                                          isoToLocal(e.isoString.endTime, mycontext.country),
                                                      ).getTime() >=
                                                          new Date(
                                                              isoToLocal(currentTime, mycontext.country),
                                                          ).getTime() ||
                                                      (e.isoString.startTime.slice(11, 16) === '18:30' &&
                                                          e.isoString.endTime.slice(11, 16) === '18:29')
                                                    ? { border: '2px solid #3D49B6' }
                                                    : { border: '2px solid #BDB8B8' }
                                            }
                                        >
                                            <Avatar src={e?.Employee?.profileImage} className={classes.avatar2}>
                                                {getFullName(e?.Employee?.firstName + ' ' + e?.Employee?.lastName)}
                                            </Avatar>
                                        </Paper>
                                    </Tooltip2>
                                ))
                            ) : (
                                <div className={classes.noTimeofFound}>
                                    {' '}
                                    <RemoveIcon />{' '}
                                </div>
                            )}
                            {currentTimeoff?.length > 2 && (
                                <Tooltip2
                                    title={`Total ${currentTimeoff?.length}`}
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                    arrow
                                >
                                    <Avatar
                                        style={{
                                            left: -4,
                                            width: 20,
                                            height: 15,
                                            top: 9,
                                            fontSize: 12,
                                            background: '#fff',
                                            color: '#444444',
                                        }}
                                        onClick={(e: any) => handleProfileMenuClick(e, currentTimeoff)}
                                    >
                                        +{currentTimeoff?.length - 2}
                                    </Avatar>
                                </Tooltip2>
                            )}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <div className={classes.pointDiv}>
                            <div style={{ display: 'flex' }}>
                                {/* <FiberManualRecordIcon className={classes.titlePoint} style={{ color: 'orange' }} /> */}
                                <div style={{ color: theme.palette.text.primary, fontFamily: 'Nunito Sans' }}>
                                    {' '}
                                    Partial timeoff
                                </div>
                            </div>
                        </div>
                        <div className={classes.avatarDiv}>
                            {upcomingTimeoff?.length > 0 ? (
                                upcomingTimeoff?.slice(0, 2)?.map((e: any, key: number) => (
                                    <Tooltip2
                                        key={key}
                                        title={
                                            <>
                                                <div>Name- {`${e?.Employee?.firstName} ${e?.Employee?.lastName}`}</div>
                                                <div>
                                                    Time-{' '}
                                                    {e.noOfHours >= mycontext.workingHours
                                                        ? `${isoToLocal(e.isoString.startTime, mycontext.country)
                                                              .toString()
                                                              .slice(0, 15)} - ${isoToLocal(
                                                              e.isoString.endTime,
                                                              mycontext.country,
                                                          )
                                                              .toString()
                                                              .slice(0, 15)}`
                                                        : `${isoToLocalTime(
                                                              e.isoString.startTime,
                                                              mycontext.country,
                                                          )} - ${isoToLocalTime(
                                                              e.isoString.endTime,
                                                              mycontext.country,
                                                          )}`}
                                                </div>
                                                <div>Category- {`${e?.category}`}</div>
                                                <div>Reason- {`${e?.reason}`}</div>
                                            </>
                                        }
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                        interactive
                                    >
                                        {/* <Avatar
                                            src={e?.Employee?.profileImage}
                                            className={classes.avatar2}
                                            // style={key !== 0 ? { left: `-${key * 8}px` } : { left: '0px' }}
                                            style={e?.startTime <= currentTime && e?.endTime >= currentTime ? { border: '3px solid #25D366' } : e?.startTime > currentTime && e?.endTime > currentTime ? { border: '3px solid orange' } : { border: '3px solid grey' }}
                                        >
                                            {getFullName(e?.Employee?.firstName + ' ' + e?.Employee?.lastName)}
                                        </Avatar> */}
                                        <Paper
                                            className={classes.paperAvtar}
                                            style={
                                                e?.category === 'Sick'
                                                    ? { border: '2px solid red' }
                                                    : new Date(
                                                          isoToLocal(e.isoString.endTime, mycontext.country),
                                                      ).getTime() >=
                                                          new Date(
                                                              isoToLocal(currentTime, mycontext.country),
                                                          ).getTime() ||
                                                      (e.isoString.startTime.slice(11, 16) === '18:30' &&
                                                          e.isoString.endTime.slice(11, 16) === '18:29')
                                                    ? { border: '2px solid #3D49B6' }
                                                    : { border: '2px solid #BDB8B8' }
                                            }
                                        >
                                            <Avatar
                                                src={e?.Employee?.profileImage}
                                                className={classes.avatar2}
                                                // style={{  }}
                                                // style={key !== 0 ? { left: `-${key * 8}px` } : { left: '0px' }}
                                            >
                                                {getFullName(e?.Employee?.firstName + ' ' + e?.Employee?.lastName)}
                                            </Avatar>
                                        </Paper>
                                    </Tooltip2>
                                ))
                            ) : (
                                <div className={classes.noTimeofFound}>
                                    {' '}
                                    <RemoveIcon />{' '}
                                </div>
                            )}
                            {upcomingTimeoff?.length > 2 && (
                                <Tooltip2
                                    title={`Total ${upcomingTimeoff?.length}`}
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                    arrow
                                >
                                    <Avatar
                                        style={{
                                            left: -4,
                                            width: 20,
                                            height: 15,
                                            top: 9,
                                            fontSize: 12,
                                            background: '#fff',
                                            color: '#444444',
                                        }}
                                        onClick={(e: any) => handleProfileMenuClick(e, upcomingTimeoff)}
                                    >
                                        +{upcomingTimeoff?.length - 2}
                                    </Avatar>
                                </Tooltip2>
                            )}
                        </div>
                    </div>
                </Grid>

                <Grid
                    style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', marginTop: '60px' }}
                    item
                    xs={12}
                >
                    {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}
                    <div style={{ marginLeft: '13px' }}>
                        <Point title={'Sick Leave'} colors={'red'} />
                    </div>
                    <div style={{ marginLeft: '13px' }}>
                        <Point title={'Casual Leave'} colors={'#3D49B6'} />
                    </div>
                    <div style={{ marginLeft: '13px' }}>
                        <Point title={'Timeoff completed'} colors={'#BDB8B8'} />
                    </div>
                    {/* </div> */}
                </Grid>
            </Grid>
        </div>
    );
};

export const Point = (props: { colors: string; title: string }) => {
    const classes = useStyles();
    return (
        <span style={{ display: 'flex', width: 'fit-content' }}>
            <div style={{ backgroundColor: `${props.colors}` }} className={classes.point}></div>
            <div className={classes.pointTitle}>{props.title}</div>
        </span>
    );
};

export const SlackBtn = () => {
    const classes = useStyles();
    return (
        <Link
            to={{
                pathname: '/settings',
                state: { value: 4 },
            }}
            style={{ color: 'black', textDecoration: 'none' }}
        >
            <div className={classes.slackBtn} data-testid="SlackBtn">
                <img src={'/dashboard/dc739c.svg'} alt="slack" className={classes.slackImg} />
                <h6 className={classes.slackText}>Integrate with Slack</h6>
            </div>
        </Link>
    );
};

interface Progress {
    count?: number;
    percent?: number;
    onClick?: any;
}
export const ProgressDiv = (props: Progress) => {
    const classes = useStyles();
    return (
        <>
            <Hidden lgDown>
                <div className={classes.progressDiv} onClick={() => props.onClick(true)} data-testid="ProgressDiv">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <h6 className={classes.progressCount}>{props.count}</h6>
                        <div className={classes.textContent}>
                            <h6 className={classes.progressText1}>Pending Actions</h6>
                            <div
                                style={{
                                    height: 5,
                                    width: '100%',
                                    backgroundColor: '#fff',
                                    borderRadius: 5,
                                }}
                            >
                                <div
                                    style={{
                                        width: `${props.percent}%`,
                                        borderRadius: 5,
                                        height: 5,
                                        backgroundColor: '#56E195',
                                    }}
                                ></div>
                            </div>
                            {/* <h6
                        className={classes.progressText2}
                    >
                       
                    </h6> */}
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden lgUp>
                <div className={classes.progressDiv} onClick={() => props.onClick(true)} data-testid="ProgressDiv">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <div className={classes.textContent}>
                            <h6 className={classes.progressCount}>{props.count}</h6>
                            <h6 className={classes.progressText1}>Pending Actions</h6>
                        </div>
                        <div
                            style={{
                                height: 5,
                                width: '100%',
                                backgroundColor: '#fff',
                                borderRadius: 5,
                            }}
                        >
                            <div
                                style={{
                                    width: `${props.percent}%`,
                                    borderRadius: 5,
                                    height: 5,
                                    backgroundColor: '#56E195',
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </Hidden>
        </>
    );
};

/* istanbul ignore next */
export const Graph = () => {
    return (
        <div
            style={{
                height: '100%',
                background: 'white',
                borderRadius: 5,
                padding: 20,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h5
                    style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: '#333333',
                        fontFamily: 'Nunito Sans',
                    }}
                >
                    Team Performance
                </h5>
                <p>Select box</p>
            </div>
            <ReactFC {...chartConfigs} />
        </div>
    );
};

const state = {
    // labels: ['January', 'February', 'March', 'April', 'May'],
    // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat'],
    datasets: [
        {
            label: 'Desgin Team',
            fill: false,
            lineTension: 0,
            backgroundColor: '#29BD99',
            borderColor: '#29BD99',
            borderWidth: 1,
            data: [30, 34, 30, 11, 6, 7, 19],
        },
        {
            label: 'Developers',
            fill: false,
            lineTension: 0,
            backgroundColor: '#587FE6',
            borderColor: '#587FE6',
            borderWidth: 1,
            data: [20, 29, 8, 19, 5, 2, 5],
        },
        {
            label: 'UX Team',
            fill: false,
            lineTension: 0,
            backgroundColor: '#BE66E8',
            borderColor: '#BE66E8',
            borderWidth: 1,
            data: [25, 19, 18, 8, 16, 0, 29],
        },
        {
            label: 'Engineering Team',
            fill: false,
            lineTension: 0,
            backgroundColor: '#F2A142',
            borderColor: '#F2A142',
            borderWidth: 1,
            data: [15, 19, 8, 11, 5, 12, 5],
        },
    ],
};
/* istanbul ignore next */
export const Graph2 = () => {
    return (
        <div
            style={{
                height: '100%',
                background: 'white',
                borderRadius: 5,
                padding: 20,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h5
                    style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: '#333333',
                        fontFamily: 'Nunito Sans',
                    }}
                >
                    Team Performance
                </h5>
                <p>Select box</p>
            </div>
            <Line
                data={state}
                options={{
                    responsive: true,
                    plugins: {
                        title: {
                            display: false,
                            text: 'Average Rainfall per month',
                        },
                        legend: {
                            display: true,
                            position: 'bottom',
                        },
                    },
                }}
            />
        </div>
    );
};

// const options = [
//     {
//         value: 'By Week',
//         label: 'By Week',
//     },
//     {
//         value: 'By Month',
//         label: 'By Month',
//     },
//     {
//         value: 'By Year',
//         label: 'By year',
//     },
// ];

interface GraphData3 {
    graphData: any;
    isLoading: boolean;
    graphY: any;
    timeOffOrgData: any;
}
export const Graph3 = (props: GraphData3) => {
    const classes = useStyles();
    // const [value, setValue] = React.useState('By Week');
    const COLORS = ['#956FE4', '#587EE0', '#4FB8B2', '#F2A142', '#a0d568', '#ed5564', '#ac92eb', '#20639b'];
    const mycontext = useContext(AuthContext);
    const myTheme = {
        baseTheme: mycontext.themeToggle ? 'ag-default-dark' : 'ag-default',
        palette: {
            fills: COLORS,
            strokes: mycontext.themeToggle ? ['#2C2E38'] : ['#FFFFFF'],
        },
        overrides: {
            common: {
                background: {
                    fill: mycontext.themeToggle ? '#2C2E38' : '#FFFFFF',
                },
            },
        },
    };
    const datagraph = {
        theme: myTheme,
        height: 200, // Chart height increase or decrease in pixel
        width: 400,
        // title: { text: 'TimeOff OverView' },
        // subtitle: { text: 'Based Upon Leaves' },
        data: props.graphData,
        axes: [
            {
                type: 'category',
                position: 'bottom',
                gridStyle: [
                    {
                        stroke: undefined,
                    },
                ],
                label: {
                    formatter: function(params: { value: string }) {
                        if (params.value.length > 8) {
                            return params.value.substr(0, 6) + '...';
                        }
                        return params.value;
                    },
                },
            },
            {
                type: 'number',
                position: 'left',
                min: 0,
                // max: 100,
                gridStyle: [
                    {
                        stroke: undefined,
                    },
                ],
            },
        ],
        series: [
            {
                type: 'column',
                xKey: 'leaveTypeName',
                yKeys: props.graphY,
                yNames: props.graphY,
                grouped: true,
                highlightStyle: {
                    item: {
                        fill: '#888888',
                    },
                },
            },
        ],
        legend: {
            position: 'bottom',
        },
    };
    return (
        <div className={classes.graphDiv} data-testid="Graph3">
            {props.isLoading ? (
                <div>
                    <Skeleton width="100%" height={200} />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '5px',
                        }}
                    >
                        <h5 className={classes.graphHeader}>Time-Off Overview</h5>
                        {/* <SelectBox value={value} setValue={setValue} options={options} /> */}
                    </div>
                    <Hidden smDown>
                        {props.timeOffOrgData.length === 0 ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={upcomingTimeoff}
                                    alt="Time-off Data Not Found"
                                    className={classes.notfoundImage2}
                                />
                                <Typography className={classes.notfoundText}>
                                    Time-off Overview Data Not Found
                                </Typography>
                            </div>
                        ) : (
                            <AgChartsReact options={datagraph} />
                        )}
                    </Hidden>
                    <Hidden smUp>
                        {props.timeOffOrgData.length === 0 ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={upcomingTimeoff}
                                    alt="Time-off Data Not Found"
                                    className={classes.notfoundImage}
                                />
                                <Typography className={classes.notfoundText}>
                                    Time-off OverView Data Not Found
                                </Typography>
                            </div>
                        ) : (
                            <AgChartsReact options={datagraph} />
                        )}
                    </Hidden>
                </>
            )}
        </div>
    );
};

const lineData = [
    {
        name: 'Mon',
        Design: 20,
        DevOps: 24,
        UX: 15,
        DB: 10,
        amt: 24,
    },
    {
        name: 'Tue',
        Design: 15,
        DevOps: 13,
        UX: 10,
        DB: 29,
        amt: 22,
    },
    {
        name: 'Wed',
        Design: 24,
        DevOps: 9,
        UX: 11,
        DB: 14,
        amt: 26,
    },
    {
        name: 'Thu',
        Design: 10,
        DevOps: 13,
        UX: 8,
        DB: 12,
        amt: 20,
    },
    {
        name: 'Fri',
        Design: 18,
        DevOps: 4,
        UX: 5,
        DB: 7,
        amt: 21,
    },
    {
        name: 'Sat',
        Design: 22,
        DevOps: 25,
        UX: 22,
        DB: 21,
        amt: 25,
    },
];

const options = [
    {
        value: 'By Week',
        label: 'By Week',
    },
    {
        value: 'By Month',
        label: 'By Month',
    },
    {
        value: 'By Year',
        label: 'By year',
    },
];
export const Graph4 = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState('By Week');
    return (
        <div className={classes.graphDiv} data-testid="Graph3">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h5 className={classes.graphHeader}>Time-off Overview</h5>
                <SelectBox value={value} setValue={setValue} options={options} />
            </div>
            <Hidden smDown>
                <LineChart
                    width={480}
                    height={220}
                    data={lineData}
                    margin={{ top: 15, right: 10, left: -20, bottom: 5 }}
                >
                    {/* <CartesianGrid strokeDasharray="0" /> */}
                    <XAxis dataKey="name" padding={{ left: 20, right: 20 }} tickLine={false} />
                    <YAxis tickLine={false} />
                    <Tooltip />
                    <Legend align="left" iconType="rect" iconSize={10} wrapperStyle={{ left: 40 }} />
                    <LineDraw type="linear" dataKey="Design" stroke="#29BD99" dot={false} strokeWidth={2} />
                    <LineDraw type="linear" dataKey="DevOps" stroke="#587FE6" dot={false} strokeWidth={2} />
                    <LineDraw type="linear" dataKey="UX" stroke="#BE66E8" dot={false} strokeWidth={2} />
                    <LineDraw type="linear" dataKey="DB" stroke="#F2A142" dot={false} strokeWidth={2} />
                </LineChart>
            </Hidden>
            <Hidden smUp>
                <LineChart
                    width={240}
                    height={200}
                    data={lineData}
                    margin={{ top: 15, right: 10, left: -20, bottom: 5 }}
                >
                    {/* <CartesianGrid strokeDasharray="0" /> */}
                    <XAxis dataKey="name" padding={{ left: 10, right: 10 }} tickLine={false} />
                    <YAxis tickLine={false} />
                    {/* <Tooltip /> */}
                    <Legend align="left" iconSize={12} iconType="rect" wrapperStyle={{ left: 40 }} />
                    <LineDraw type="linear" dataKey="Design" stroke="#29BD99" dot={false} strokeWidth={2} />
                    <LineDraw type="linear" dataKey="DevOps" stroke="#587FE6" dot={false} strokeWidth={2} />
                    <LineDraw type="linear" dataKey="UX" stroke="#BE66E8" dot={false} strokeWidth={2} />
                    <LineDraw type="linear" dataKey="DB" stroke="#F2A142" dot={false} strokeWidth={2} />
                </LineChart>
            </Hidden>
        </div>
    );
};
interface GraphData6 {
    graphData: any;
    isLoading: boolean;
    graphY: any;
    timeOffOrgData: any;
}
export const Graph6 = (props: GraphData6) => {
    const classes = useStyles();
    const color = ['#956FE4', '#587EE0', '#4FB8B2', '#F2A142', '#a0d568', '#ed5564', '#ac92eb', '#20639b'];
    const getLineChart = () => {
        const keysArr = Object.keys(props.graphData[0]).slice(1);
        const lineArr: any = [];
        keysArr.forEach((item, index) => {
            lineArr.push(<LineDraw type="linear" dataKey={item} stroke={color[index]} dot={false} strokeWidth={2} />);
        });
        return lineArr;
    };

    return (
        <div className={classes.graphDiv} data-testid="Graph6">
            {props.graphData.length === 0 ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <img src={upcomingTimeoff} alt="Time-off Data Not Found" className={classes.notfoundImage2} />
                    <Typography className={classes.notfoundText}>Time-Off Overview Data Not Found</Typography>
                </div>
            ) : (
                <>
                    <Hidden smDown>
                        <LineChart
                            width={480}
                            height={220}
                            data={props.graphData}
                            margin={{ top: 15, right: 10, left: -20, bottom: 5 }}
                        >
                            {/* <CartesianGrid strokeDasharray="0" /> */}
                            <XAxis dataKey="leaveTypeName" padding={{ left: 20, right: 20 }} tickLine={false} />
                            <YAxis tickLine={false} />
                            <Tooltip />
                            <Legend align="center" iconType="rect" iconSize={10} /> {/* wrapperStyle={{ left: 40 }} */}
                            {getLineChart()}
                        </LineChart>
                    </Hidden>
                    <Hidden smUp>
                        <LineChart
                            width={240}
                            height={200}
                            data={props.graphData}
                            margin={{ top: 15, right: 10, left: -20, bottom: 5 }}
                        >
                            {/* <CartesianGrid strokeDasharray="0" /> */}
                            <XAxis dataKey="leaveTypeName" padding={{ left: 10, right: 10 }} tickLine={false} />
                            <YAxis tickLine={false} />
                            {/* <Tooltip /> */}
                            <Legend align="left" iconSize={12} iconType="rect" wrapperStyle={{ left: 40 }} />
                            {getLineChart()}
                        </LineChart>
                    </Hidden>
                </>
            )}
        </div>
    );
};

interface AccordianProps {
    pendingLeaves?: any;
    todaysLeaves?: any;
    upcomingLeaves?: any;
    orgEmployees?: any;
    COLORS?: any;
    data?: any;
    handleApprove?: any;
    handleDeny?: any;
    handleModal?: any;
    isApprover?: boolean;
    isProfile?: boolean;
    isDashboard?: boolean;
    handlePopup?: any;
    handleDenyAll?: any;
    userSubscriptionDetail?: any;
}

export const Accordians = (props: AccordianProps) => {
    // //console.log(props.pendingLeaves, props.todaysLeaves, props.upcomingLeaves);
    /* istanbul ignore next */
    const mycontext = useContext(AuthContext);
    const [leavesPending, setLeavesPending] = React.useState<string[]>([]);
    const [leavesUpcoming, setLeavesUpcoming] = React.useState<string[]>([]);
    const [allLeavePending, setAllLeavePending] = React.useState<boolean>(false);
    const [allLeaveUpcoming, setAllLeaveUpcoming] = React.useState<boolean>(false);

    const accordianData1 = [
        {
            id: 1,
            title: props.isApprover ? `Team Member's Pending Requests` : 'Pending Requests',
            leaves: props.pendingLeaves || [],
        },
        {
            id: 2,
            title: props.isApprover ? `Team Member's on Time-Off Today` : `Today's Time-Offs`,
            leaves: props.todaysLeaves || [],
        },
        {
            id: 3,
            title: props.isApprover ? `Team Member's Upcoming Time-Offs` : `Upcoming Time-Offs`,
            leaves: props.upcomingLeaves || [],
        },
    ];
    const accordianData2 = [
        {
            id: 2,
            title: props.isApprover ? `Team Member's on Time-Off Today` : `Today's Time-Offs`,
            leaves: props.todaysLeaves || [],
        },
        {
            id: 3,
            title: props.isApprover ? `Team Member's Upcoming Time-Offs` : `Upcoming Time-Offs`,
            leaves: props.upcomingLeaves || [],
        },
    ];
    const accordianData = props.isProfile ? accordianData2 : accordianData1;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(accordianData[0].title);
    const [search1, setSearch1] = React.useState('');
    const [search2, setSearch2] = React.useState('');
    const [search3, setSearch3] = React.useState('');

    const handleAllLeavesPending = (e: any, l: Record<string, any>[]) => {
        if (e) {
            setAllLeavePending(!allLeavePending);
            // l.filter((l: any) => l.category.toLowerCase().includes(search1.toLowerCase())).map((o: any) =>
            //     setLeavesPending((prev: any) => {
            //         if (prev.find((l: string) => l === o.leaveId) === undefined) {
            //             return prev.concat(o.leaveId);
            //         }
            //         return prev;
            //     }),
            // );
            l.map((o: any) =>
                setLeavesPending((prev: any) => {
                    if (prev.find((l: string) => l === o.leaveId) === undefined) {
                        return prev.concat(o.leaveId);
                    }
                    return prev;
                }),
            );
        } else {
            setAllLeavePending(!allLeavePending);
            setLeavesPending([]);
        }
    };

    const handleAllLeavesUpcoming = (e: any, l: Record<string, any>[]) => {
        if (e) {
            setAllLeaveUpcoming(!allLeaveUpcoming);
            // l.filter((l: any) => l.category.toLowerCase().includes(search3.toLowerCase())).map((o: any) =>
            //     setLeavesUpcoming((prev: any) => {
            //         if (prev.find((l: string) => l === o.leaveId) === undefined) {
            //             return prev.concat(o.leaveId);
            //         }
            //         return prev;
            //     }),
            // );
            l.map((o: any) =>
                setLeavesUpcoming((prev: any) => {
                    if (prev.find((l: string) => l === o.leaveId) === undefined) {
                        return prev.concat(o.leaveId);
                    }
                    return prev;
                }),
            );
        } else {
            setAllLeaveUpcoming(!allLeaveUpcoming);
            setLeavesUpcoming([]);
        }
    };

    const handleSingleLeavePending = (isChecked: boolean, leaveId: string) => {
        if (isChecked) {
            if (
                props.pendingLeaves.filter(
                    (lv: any) =>
                        lv.Employee?.firstName?.toLowerCase().includes(search1) ||
                        lv.Employee?.lastName?.toLowerCase().includes(search1) ||
                        lv.category?.toLowerCase().includes(search1),
                ).length ===
                leavesPending.length + 1
            ) {
                setAllLeavePending(true);
            }
            setLeavesPending((prev: any) => prev.concat(leaveId));
        } else {
            if (leavesPending.length === 1) {
                setAllLeavePending(false);
            }
            setLeavesPending((prev: any) => prev.filter((l: string) => l !== leaveId));
        }
    };

    const handleSingleLeaveUpcoming = (isChecked: boolean, leaveId: string) => {
        if (isChecked) {
            if (
                props.upcomingLeaves.filter(
                    (lv: any) =>
                        lv.Employee?.firstName?.toLowerCase().includes(search3) ||
                        lv.Employee?.lastName?.toLowerCase().includes(search3) ||
                        lv.category?.toLowerCase().includes(search3),
                ).length ===
                leavesUpcoming.length + 1
            ) {
                setAllLeaveUpcoming(true);
            }
            setLeavesUpcoming((prev: any) => prev.concat(leaveId));
        } else {
            if (leavesUpcoming.length === 1) {
                setAllLeaveUpcoming(false);
            }
            setLeavesUpcoming((prev: any) => prev.filter((l: string) => l !== leaveId));
        }
    };

    const handleApprove = async (id: number) => {
        try {
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp'))
                    .length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const obj = {
                leaveIds: leavesPending,
                approverId: mycontext.EmployeeId,
                denialReason: null,
                denierId: mycontext.EmployeeId,
                leaveStatus: 'approved',
                token: mycontext.token,
            };
            const resp = await updateLeaveAdminMultiple(obj, mycontext.isAdmin);
            if (resp.errors) {
                props.handlePopup('error', 'Failed to approve leaves!');
            } else {
                // handleNotif({
                //     response: resp,
                //     requestFor: 'slack',
                //     user: {
                //         name: mycontext.fname + ' ' + mycontext.lname,
                //         type: 'name',
                //     },
                //     orgId: mycontext.organizationId,
                //     requestType: 'approve',
                // });
                handleNotif({
                    response: resp,
                    requestFor: 'msteams',
                    user: mycontext.EmployeeId,
                    token: mycontext.token,
                    orgId: mycontext.organizationId,
                    requestType: 'approve',
                });
                props.handlePopup('success', 'All Leaves are approved!');
                setSearch1('');
                setSearch3('');
                setLeavesPending([]);
                setAllLeavePending(false);
                // eslint-disable-next-line
                obj.leaveIds.map(async (leave: any) => {
                    // await sendNotificationToMembers1(leave, mycontext.token, mycontext.organizationId);
                    //web notification
                    await getMultiApproveTokenFromLeave(
                        leave,
                        mycontext.token,
                        mycontext.fname,
                        mycontext.lname,
                        mycontext.organizationId,
                        mycontext.EmployeeId,
                    );
                    await sendAprrovedNotifToMembers(
                        leave,
                        mycontext.token,
                        mycontext.organizationId,
                        mycontext.fname,
                        mycontext.lname,
                    );
                    return null;
                });
            }
        } catch (err) {
            props.handlePopup('error', err?.message);
            return err;
        }
    };

    const handleDeny = async (id: number) => {
        try {
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp'))
                    .length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                props.userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const obj = {
                leaveIds: id === 1 ? leavesPending : leavesUpcoming,
                approverId: mycontext.EmployeeId,
                denialReason: null,
                denierId: mycontext.EmployeeId,
                prevStatus: id === 1 ? 'pending' : 'approved',
                leaveStatus: 'denied',
                token: mycontext.token,
            };
            props.handleDenyAll(obj);
            setSearch1('');
            setSearch3('');
            setLeavesPending([]);
            setLeavesUpcoming([]);
            setAllLeavePending(false);
            setAllLeaveUpcoming(false);
            // const resp = await updateLeaveAdminMultiple(obj, mycontext.isAdmin);
            // if (resp.errors) {
            //     props.handlePopup('error', 'Failed to denied leaves!');
            // } else {
            //     props.handlePopup('success', 'All Leaves are denied successfuly!');
            //     dispatch(
            //         getCalendar({
            //             employeeId: mycontext.EmployeeId,
            //             organizationId: mycontext.organizationId,
            //             token: mycontext.token,
            //             isApprover: mycontext.isApprover,
            //         }),
            //     );
            //     dispatch(
            //         getEmployeeLeaves({
            //             organizationId: mycontext.organizationId,
            //             employeeId: mycontext.EmployeeId,
            //             token: mycontext.token,
            //         }),
            //     );
            // }
        } catch (err) {
            props.handlePopup('error', err?.message);
            return err;
        }
    };
    /* istanbul ignore next */
    const handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
        if (props.isProfile) {
            setExpanded(
                isExpanded ? panel : panel === accordianData[0].title ? accordianData[1].title : accordianData[0].title,
            );
        } else {
            setExpanded(
                isExpanded
                    ? panel
                    : panel === accordianData[0].title
                    ? accordianData[1].title
                    : panel === accordianData[2].title
                    ? accordianData[0].title
                    : accordianData[2].title,
            );
        }
    };
    if (props.isProfile) {
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'grid',
                    // display: 'grid',
                    gridRowGap: '10px',
                    gridTemplateRows:
                        /* istanbul ignore next */ expanded === accordianData[0].title ? '1fr auto' : 'auto 1fr',
                }}
                className={classes.accordianMain}
                data-testid="Accordians"
            >
                {accordianData.map((d: any, index: number) => (
                    <Accordion
                        expanded={expanded === d.title}
                        onChange={handleChange(d.title)}
                        key={index}
                        style={{ borderRadius: 5, margin: 0, border: 'none' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: '#333333' }} />}
                            aria-controls="panel1bh-content"
                            className={classes.accordionHeaderStyle}
                            id={'panel1bh-header' + d.id}
                        >
                            <Typography className={classes.accordionTitle}>
                                {d.title} ({' ' + d.leaves?.length + ' '})
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordianDetails}>
                            {/* <div style={{ width: '100%', background: '#D9DBE5', height: 1, alignSelf: 'center', marginBottom: 10}}></div> */}
                            <>
                                {/* <SearchBar
                                    value={index === 0 ? search1 : index === 1 ? search2 : search3}
                                    setValue={index === 0 ? setSearch1 : index === 1 ? setSearch2 : setSearch3}
                                    mode="rect"
                                    icon={true}
                                /> */}
                                <div style={{ width: '100%', flex: 1, overflow: 'hidden' }}>
                                    <div className={classes.scrollDivAccordianProfile}>
                                        {/* istanbul ignore next */ d.leaves?.length === 0 ? (
                                            d.id === 1 ? (
                                                <DisplayCenter>
                                                    <img
                                                        src={upcomingTimeoff}
                                                        alt="No pending time-off found"
                                                        className={classes.notfoundImage}
                                                    />
                                                    <Typography className={classes.notfoundText}>
                                                        No pending time-off found
                                                    </Typography>
                                                </DisplayCenter>
                                            ) : d.id === 2 ? (
                                                <DisplayCenter>
                                                    <img
                                                        src={upcomingTimeoff}
                                                        alt="No one is on time-off today"
                                                        className={classes.notfoundImage}
                                                    />
                                                    <Typography className={classes.notfoundText}>
                                                        No time-off for today
                                                    </Typography>
                                                </DisplayCenter>
                                            ) : (
                                                <DisplayCenter>
                                                    <img
                                                        src={upcomingTimeoff}
                                                        alt="No upcoming time-off found"
                                                        className={classes.notfoundImage}
                                                    />
                                                    <Typography className={classes.notfoundText}>
                                                        No upcoming time-off found
                                                    </Typography>
                                                </DisplayCenter>
                                            )
                                        ) : d.leaves.filter(
                                              (lv: any) =>
                                                  lv.Employee?.firstName
                                                      ?.toLowerCase()
                                                      .includes(
                                                          index === 0 ? search1 : index === 1 ? search2 : search3,
                                                      ) ||
                                                  lv.category
                                                      ?.toLowerCase()
                                                      .includes(
                                                          index === 0 ? search1 : index === 1 ? search2 : search3,
                                                      ),
                                          ).length === 0 ? (
                                            <DisplayCenter>
                                                <img
                                                    src={upcomingTimeoff}
                                                    alt="No results found"
                                                    className={classes.notfoundImage}
                                                />
                                                <Typography className={classes.notfoundText}>
                                                    No results found
                                                </Typography>
                                            </DisplayCenter>
                                        ) : (
                                            d.leaves
                                                .filter(
                                                    (lv: any) =>
                                                        lv.Employee?.firstName
                                                            ?.toLowerCase()
                                                            .includes(
                                                                index === 0 ? search1 : index === 1 ? search2 : search3,
                                                            ) ||
                                                        lv.category
                                                            ?.toLowerCase()
                                                            .includes(
                                                                index === 0 ? search1 : index === 1 ? search2 : search3,
                                                            ),
                                                )
                                                .map((l: any, i: number) => (
                                                    <Leave
                                                        leave={l}
                                                        key={i}
                                                        color={
                                                            props.COLORS[
                                                                props.data.findIndex((lo: any) =>
                                                                    lo.leaveTypeName
                                                                        .toLowerCase()
                                                                        .includes(l.category.toLowerCase()),
                                                                )
                                                            ]
                                                        }
                                                        id={d.id}
                                                        handleApprove={props.handleApprove}
                                                        handleDeny={props.handleDeny}
                                                        handleModal={props.handleModal}
                                                        isProfile={props.isProfile}
                                                        orgEmployees={props.orgEmployees}
                                                    />
                                                ))
                                        )}
                                    </div>
                                </div>
                            </>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        );
    }
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'grid',
                // display: 'grid',
                gridRowGap: '10px',
                gridTemplateRows:
                    /* istanbul ignore next */ expanded === accordianData[0].title
                        ? '1fr auto auto'
                        : /* istanbul ignore next */ expanded === accordianData[1].title
                        ? 'auto 1fr auto'
                        : 'auto auto 1fr',
            }}
            className={classes.accordianMain}
            data-testid="Accordians"
        >
            {accordianData.map((d: any, index: number) => (
                <Accordion
                    expanded={expanded === d.title}
                    onChange={handleChange(d.title)}
                    key={index}
                    style={{ borderRadius: 5, margin: 0, border: 'none' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#333333' }} />}
                        aria-controls="panel1bh-content"
                        className={classes.accordionHeaderStyle}
                        id={'panel1bh-header' + d.id}
                    >
                        <Typography className={classes.accordionTitle}>
                            {d.title} ({' ' + d.leaves?.length + ' '})
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordianDetails}>
                        {/* <div style={{ width: '100%', background: '#D9DBE5', height: 1, alignSelf: 'center', marginBottom: 10}}></div> */}
                        <>
                            {props.isProfile || (
                                <SearchBar
                                    value={index === 0 ? search1 : index === 1 ? search2 : search3}
                                    setValue={index === 0 ? setSearch1 : index === 1 ? setSearch2 : setSearch3}
                                    mode="rect"
                                    icon={true}
                                />
                            )}
                            {d.id === 2 ||
                                d.leaves.length === 0 ||
                                (d.id === 1 ? (
                                    <div className={classes.allCheckbox}>
                                        {/* {leavesPending.length > 0 && ( */}
                                        <Checkbox
                                            checked={allLeavePending}
                                            onChange={(e: any) =>
                                                handleAllLeavesPending(
                                                    e.target.checked,
                                                    props.pendingLeaves.filter(
                                                        (lv: any) =>
                                                            lv.Employee?.firstName
                                                                ?.toLowerCase()
                                                                .includes(index === 0 ? search1 : search3) ||
                                                            lv.Employee?.lastName
                                                                ?.toLowerCase()
                                                                .includes(index === 0 ? search1 : search3) ||
                                                            lv.category
                                                                ?.toLowerCase()
                                                                .includes(index === 0 ? search1 : search3),
                                                    ),
                                                )
                                            }
                                        />
                                        {/* )} */}
                                        {/* {leavesPending.length > 0 && ( */}
                                        <Typography>
                                            Selected{' '}
                                            {props.pendingLeaves.filter(
                                                (lv: any) =>
                                                    lv.Employee?.firstName
                                                        ?.toLowerCase()
                                                        .includes(index === 0 ? search1 : search3) ||
                                                    lv.Employee?.lastName
                                                        ?.toLowerCase()
                                                        .includes(index === 0 ? search1 : search3) ||
                                                    lv.category
                                                        ?.toLowerCase()
                                                        .includes(index === 0 ? search1 : search3),
                                            ).length === leavesPending.length
                                                ? '( All )'
                                                : `( ${leavesPending.length} )`}
                                        </Typography>
                                        {/* )} */}
                                        {leavesPending.length !== 0 && (
                                            <ApproveBtn handleApprove={() => handleApprove(d.id)} />
                                        )}
                                        {leavesPending.length !== 0 && (
                                            <DenyBtn text="Deny" handleDeny={() => handleDeny(d.id)} />
                                        )}
                                    </div>
                                ) : (
                                    <div className={classes.allCheckbox}>
                                        {/* {leavesUpcoming.length > 0 && ( */}
                                        <Checkbox
                                            checked={allLeaveUpcoming}
                                            onChange={(e: any) =>
                                                handleAllLeavesUpcoming(
                                                    e.target.checked,
                                                    props.upcomingLeaves.filter(
                                                        (lv: any) =>
                                                            lv.Employee?.firstName
                                                                ?.toLowerCase()
                                                                .includes(index === 0 ? search1 : search3) ||
                                                            lv.Employee?.lastName
                                                                ?.toLowerCase()
                                                                .includes(index === 0 ? search1 : search3) ||
                                                            lv.category
                                                                ?.toLowerCase()
                                                                .includes(index === 0 ? search1 : search3),
                                                    ),
                                                )
                                            }
                                        />
                                        {/* )} */}
                                        {/* {leavesUpcoming.length > 0 && ( */}
                                        <Typography>
                                            Selected{' '}
                                            {props.upcomingLeaves.filter(
                                                (lv: any) =>
                                                    lv.Employee?.firstName
                                                        ?.toLowerCase()
                                                        .includes(index === 0 ? search1 : search3) ||
                                                    lv.Employee?.lastName
                                                        ?.toLowerCase()
                                                        .includes(index === 0 ? search1 : search3) ||
                                                    lv.category
                                                        ?.toLowerCase()
                                                        .includes(index === 0 ? search1 : search3),
                                            ).length === leavesUpcoming.length
                                                ? '( All )'
                                                : `( ${leavesUpcoming.length} )`}
                                        </Typography>
                                        {/* )} */}
                                        {leavesUpcoming.length !== 0 && (
                                            <DenyBtn text="Deny" handleDeny={() => handleDeny(d.id)} />
                                        )}
                                    </div>
                                ))}
                            <div style={{ width: '100%', flex: 1, overflow: 'hidden' }}>
                                <div
                                    className={
                                        d.id === 2
                                            ? classes.scrollDivAccordian2
                                            : d.leaves.length === 0
                                            ? classes.scrollDivAccordian2
                                            : classes.scrollDivAccordian
                                    }
                                >
                                    {/* istanbul ignore next */ d.leaves?.length === 0 ? (
                                        d.id === 1 ? (
                                            <DisplayCenter>
                                                <img
                                                    src={upcomingTimeoff}
                                                    alt="No pending time-off found"
                                                    className={classes.notfoundImage}
                                                />
                                                <Typography className={classes.notfoundText}>
                                                    No pending time-off found
                                                </Typography>
                                            </DisplayCenter>
                                        ) : d.id === 2 ? (
                                            <DisplayCenter>
                                                <img
                                                    src={upcomingTimeoff}
                                                    alt="No one is on time-off today"
                                                    className={classes.notfoundImage}
                                                />
                                                <Typography className={classes.notfoundText}>
                                                    No time-off for today
                                                </Typography>
                                            </DisplayCenter>
                                        ) : (
                                            <DisplayCenter>
                                                <img
                                                    src={upcomingTimeoff}
                                                    alt="No upcoming time-off found"
                                                    className={classes.notfoundImage}
                                                />
                                                <Typography className={classes.notfoundText}>
                                                    No upcoming time-off found
                                                </Typography>
                                            </DisplayCenter>
                                        )
                                    ) : d.leaves.filter(
                                          (lv: any) =>
                                              lv.Employee?.firstName
                                                  ?.toLowerCase()
                                                  .includes(index === 0 ? search1 : index === 1 ? search2 : search3) ||
                                              lv.Employee?.lastName
                                                  ?.toLowerCase()
                                                  .includes(index === 0 ? search1 : index === 1 ? search2 : search3) ||
                                              lv.category
                                                  ?.toLowerCase()
                                                  .includes(index === 0 ? search1 : index === 1 ? search2 : search3),
                                      ).length === 0 ? (
                                        <DisplayCenter>
                                            <img
                                                src={upcomingTimeoff}
                                                alt="No Results Found"
                                                className={classes.notfoundImage}
                                            />
                                            <Typography className={classes.notfoundText}>No results found</Typography>
                                        </DisplayCenter>
                                    ) : (
                                        d.leaves
                                            .filter(
                                                (lv: any) =>
                                                    lv.Employee?.firstName
                                                        ?.toLowerCase()
                                                        .includes(
                                                            index === 0 ? search1 : index === 1 ? search2 : search3,
                                                        ) ||
                                                    lv.Employee?.lastName
                                                        ?.toLowerCase()
                                                        .includes(
                                                            index === 0 ? search1 : index === 1 ? search2 : search3,
                                                        ) ||
                                                    lv.category
                                                        ?.toLowerCase()
                                                        .includes(
                                                            index === 0 ? search1 : index === 1 ? search2 : search3,
                                                        ),
                                            )
                                            .map((l: any, i: number) => (
                                                <Leave
                                                    leave={l}
                                                    key={i}
                                                    color={
                                                        props.COLORS[
                                                            props.data.findIndex((lo: any) =>
                                                                lo.leaveTypeName
                                                                    .toLowerCase()
                                                                    .includes(l.category.toLowerCase()),
                                                            )
                                                        ]
                                                    }
                                                    id={d.id}
                                                    handleApprove={props.handleApprove}
                                                    handleDeny={props.handleDeny}
                                                    handleModal={props.handleModal}
                                                    isProfile={props.isProfile}
                                                    orgEmployees={props.orgEmployees}
                                                    addLeave={
                                                        d.id === 1
                                                            ? handleSingleLeavePending
                                                            : handleSingleLeaveUpcoming
                                                    }
                                                    selectedLeaves={d.id === 1 ? leavesPending : leavesUpcoming}
                                                />
                                            ))
                                    )}
                                </div>
                            </div>
                        </>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

interface SearchBarProps {
    id?: string;
    value?: string;
    setValue?: any;
    mode?: string;
    icon?: boolean;
    style?: any;
}
interface LeaveProps {
    leave: any;
    color?: any;
    id?: number;
    handleApprove?: any;
    handleDeny?: any;
    handleModal?: any;
    isProfile?: any;
    orgEmployees?: any;
    addLeave?: any;
    selectedLeaves?: any;
}

interface Date {
    startDate?: any;
    endDate?: any;
    startTime?: any;
    endTime?: any;
    noOfHours?: any;
    dateFormat?: any;
    id?: number;
    gmt?: string;
}

interface Time {
    startTime?: any;
    endTime?: any;
    startDate?: any;
    endDate?: any;
    dateFormat?: any;
    id?: number;
    timeZone?: any;
    gmt?: string;
}

interface DisplayCenterProps {
    children?: any;
}

/* istanbul ignore next */
const DisplayCenter = (props: DisplayCenterProps) => {
    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {props.children}
        </div>
    );
};

export const DisplayDate = (props: Date) => {
    const classes = useStyles();
    return (
        <>
            {props.id === 2 ? (
                <Typography className={classes.date}>Full Day Time-Off</Typography>
            ) : (
                <Typography className={classes.date}>
                    {DateDifference(props.startDate, props.endDate) >= 1 ? (
                        <>
                            {ToStringFormat(props.startDate, props.dateFormat)}
                            <span style={{ color: '#666666', fontSize: '12px' }}>&nbsp; To &nbsp;</span>
                            {ToStringFormat(props.endDate, props.dateFormat)}
                        </>
                    ) : (
                        <>{ToStringFormat(props.startDate, props.dateFormat)}</>
                    )}
                    <span className={classes.reason}>
                        {' '}
                        {'('}{' '}
                        {DateDifference(props.startDate, props.endDate) >= 1
                            ? DateDifference(props.startDate, props.endDate) + 1 + ' Days '
                            : DateDifference(props.startDate, props.endDate) === 0
                            ? '1 Day '
                            : '1 Day '}
                        {')'}
                    </span>
                </Typography>
            )}
        </>
    );
};

export const DisplayDate2 = (props: Date) => {
    const mycontext = useContext(AuthContext);
    const classes = useStyles();
    if (parseInt(props.noOfHours) === mycontext.workingHours) {
        if (toAWSDateFormat(props.startDate) === toAWSDateFormat(props.endDate)) {
            return (
                <>
                    <Typography className={classes.date}>
                        {ToStringFormat(props.startDate, props.dateFormat)}{' '}
                        <span className={classes.reason}>{'( 1 Day )'}</span>
                    </Typography>
                </>
            );
        } else {
            return (
                <>
                    <Typography className={classes.date}>
                        {ToStringFormat(props.startDate, props.dateFormat)} ({convertTime(props.startTime)}){' '}
                        <span style={{ color: '#666666', fontSize: '12px' }}>To</span>{' '}
                        {ToStringFormat(props.endDate, props.dateFormat)} ({convertTime(props.endTime)}){' '}
                        <span className={classes.reason}>{'( 1 Day )'}</span>
                    </Typography>
                </>
            );
        }
    }
    if (convertTime(props.startTime) === '12:00 AM') {
        return (
            <>
                <Typography className={classes.date}>
                    {ToStringFormat(props.startDate, props.dateFormat)}{' '}
                    <span style={{ color: '#666666', fontSize: '12px' }}>To</span>{' '}
                    {ToStringFormat(props.endDate, props.dateFormat)}{' '}
                    <span className={classes.reason}>
                        {`( ${parseInt(props.noOfHours) / mycontext.workingHours} Days )`}
                    </span>
                </Typography>
            </>
        );
    }
    return (
        <>
            <Typography className={classes.date}>
                {ToStringFormat(props.startDate, props.dateFormat)} ({convertTime(props.startTime)}){' '}
                <span style={{ color: '#666666', fontSize: '12px' }}>To</span>{' '}
                {ToStringFormat(props.endDate, props.dateFormat)} ({convertTime(props.endTime)}){' '}
                <span className={classes.reason}>
                    {`( ${parseInt(props.noOfHours) / mycontext.workingHours} Days )`}
                </span>
            </Typography>
        </>
    );
};

export const DisplayTime = (props: Time) => {
    const classes = useStyles();
    // //console.log('time', props.startTime, props.timeZone);
    // //console.log(changeTimeZone(`01/01/2021 ${props.startTime}:00`, props.timeZone));
    // const d = 'Thu Sep 15 2022 03:20:18 GMT-0300';
    // const arr = d.split(' ');
    // //console.log('arr', arr);
    // arr[4] = '10:00:00';
    // //console.log('updated date ', arr.toString().replaceAll(',', ' '));
    // const startTime = `${toBrowserDateFormat(new Date())} ${props.startTime}:00 ${props?.gmt}`;
    // //console.log('startT', startTime, props);
    // const endTime = `${new Date().toLocaleDateString()} ${props.endTime}:00 ${props?.gmt}`;
    return (
        <Typography className={classes.date}>
            {/* {convertTime(
                changeTimeZone('Thu Sep 15 2022 03:20:18 GMT-0300', props.timeZone)
                    .toTimeString()
                    .split(' ')[0],
            )} */}
            {/* {convertTime(
                convertTZ(new Date('Thu Sep 15 2022 07:49:00 GMT+0100'), props.timeZone)
                    .toTimeString()
                    .split(' ')[0]
                    .substring(0, 5),
            )} */}
            {/* {convertTime(
                changeTimeZone(new Date(), props.timeZone)
                    .toTimeString()
                    .split(' ')[0],
            )} */}
            {/* {convertTime(
                new Date(startTime)
                    .toTimeString()
                    .split(' ')[0]
                    .substring(0, 5),
            )} */}
            {convertTimeInTZ(props.startTime, props?.gmt)}
            <span style={{ color: '#666666', fontSize: '12px' }}>&nbsp; To &nbsp;</span>
            {/* {convertTime(
                convertTZ(new Date(`2022/01/01 ${props.endTime}:00`), props.timeZone)
                    .toTimeString()
                    .split(' ')[0]
                    .substring(0, 5),
            )} */}
            {/* {convertTime(
                new Date(toBrowserDateFormat(new Date()) + ' ' + props.endTime + ':00 ' + props?.gmt)
                    .toTimeString()
                    .split(' ')[0]
                    .substring(0, 5),
            )} */}
            {convertTimeInTZ(props.endTime, props?.gmt)}
            {/* {convertTime(props.endTime)} */}
            <span style={{ color: '#666666', fontSize: '12px' }}>&nbsp; On &nbsp;</span>
            {ToStringFormat(props.startDate, props.dateFormat)}
            <span className={classes.reason}>
                &nbsp;(&nbsp;
                {getTimeDifferenceMinute(
                    props.startTime ? props.startTime : '12:00',
                    props.endTime ? props.endTime : '12:00',
                )}
                &nbsp;)
            </span>
        </Typography>
    );
};

export const DisplayTime2 = (props: Time) => {
    const classes = useStyles();
    if (toAWSDateFormat(props.startDate) === toAWSDateFormat(props.endDate)) {
        return (
            <Typography className={classes.date}>
                {convertTime(props.startTime)} <span style={{ color: '#666666', fontSize: '12px' }}>To</span>{' '}
                {convertTime(props.endTime)} <span style={{ color: '#666666', fontSize: '12px' }}>On</span>{' '}
                {ToStringFormat(props.startDate, props.dateFormat)}
                <span className={classes.reason}>
                    &nbsp;(&nbsp;
                    {getTimeDifferenceMinute(
                        props.startTime ? props.startTime : '12:00',
                        props.endTime ? props.endTime : '12:00',
                    )}
                    &nbsp;)
                </span>
            </Typography>
        );
    }
    return (
        <Typography className={classes.date}>
            {ToStringFormat(props.startDate, props.dateFormat)} ({convertTime(props.startTime)}){' '}
            <span style={{ color: '#666666', fontSize: '12px' }}>To</span>{' '}
            {ToStringFormat(props.endDate, props.dateFormat)} ({convertTime(props.endTime)})
            <span className={classes.reason}>
                &nbsp;(&nbsp;
                {getTimeDifferenceMinute(
                    props.startTime ? props.startTime : '12:00',
                    props.endTime ? props.endTime : '12:00',
                )}
                &nbsp;)
            </span>
        </Typography>
    );
};

/* istanbul ignore next */
export const Leave = (props: LeaveProps) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const currentEmp: any = props.orgEmployees.filter((emp: any) => emp.employeeId === props.leave.leaveAppliedBy)[0];
    // //console.log('leave', props);
    return (
        <>
            <div className={classes.leaveMain}>
                {props.isProfile ||
                    props.id === 2 ||
                    (props.id === 1 ? (
                        <Checkbox
                            checked={props.selectedLeaves.find((l: string) => l === props.leave.leaveId) !== undefined}
                            onChange={(e: any) => props.addLeave(e.target.checked, props.leave.leaveId)}
                            style={{ marginTop: 5 }}
                        />
                    ) : (
                        <Checkbox
                            checked={props.selectedLeaves.find((l: string) => l === props.leave.leaveId) !== undefined}
                            onChange={(e: any) => props.addLeave(e.target.checked, props.leave.leaveId)}
                            style={{ marginTop: 5 }}
                        />
                    ))}
                <Avatar
                    src={props.leave.Employee?.profileImage === null ? logo : props.leave.Employee?.profileImage}
                    alt=""
                    variant="circular"
                    style={{ width: 35, height: 35, marginRight: 10, marginTop: 5, cursor: 'pointer' }}
                    onClick={() => mycontext.handleProfile(props.leave?.Employee.employeeId)}
                />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div className={classes.leaveTop}>
                        <Typography
                            className={classes.name}
                            onClick={() => mycontext.handleProfile(props.leave?.Employee.employeeId)}
                        >
                            {props.leave?.Employee?.firstName + ' ' + props.leave?.Employee?.lastName}&nbsp;{' '}
                            <DisplayEmoji employeeId={props.leave?.Employee.employeeId} />
                        </Typography>
                        {/* {(props.id === 2 || props.id === 3) && (
                            <Typography className={classes.reason}>
                                {props.leave.teams?.map(
                                    (t: any, i: number) => t + (i === props.leave.teams.length - 1 ? '' : ' | '),
                                )}
                            </Typography>
                        )} */}
                        {props.id === 1 && (
                            <div className={classes.actionbtn}>
                                <ApproveBtn handleApprove={() => props.handleApprove(props.leave)} />
                                <DenyBtn handleDeny={() => props.handleDeny(props.leave)} text="Deny" />
                            </div>
                        )}
                        {props.id === 3 && !props.isProfile ? (
                            (mycontext.isApprover && mycontext.EmployeeId === props.leave.approverId) ||
                            mycontext.isAdmin ? (
                                <div className={classes.actionbtn}>
                                    <DenyBtn handleDeny={() => props.handleDeny(props.leave)} text="Deny" />
                                </div>
                            ) : null
                        ) : null}
                    </div>
                    <div className={classes.dateTimeDiv}>
                        <Chip
                            size="small"
                            label={props.leave.category}
                            style={{
                                backgroundColor: `${props.color}`,
                                color: '#fff',
                                fontSize: 10,
                                marginRight: 10,
                                height: 15,
                                padding: '0 2px',
                            }}
                        />

                        {(props.leave?.startTime === 'null' && props.leave?.endTime === 'null') ||
                        (props.leave?.startTime === null && props.leave?.endTime === null) ? (
                            <DisplayDate2
                                startDate={isoToLocal(props.leave?.isoString?.startTime, mycontext.country)}
                                endDate={isoToLocal(props.leave?.isoString?.endTime, mycontext.country)}
                                startTime={isoToLocal(props.leave?.isoString?.startTime, mycontext.country)
                                    .toTimeString()
                                    .split(' ')[0]
                                    .substring(0, 5)}
                                endTime={isoToLocal(props.leave?.isoString?.endTime, mycontext.country)
                                    .toTimeString()
                                    .split(' ')[0]
                                    .substring(0, 5)}
                                noOfHours={props.leave?.noOfHours}
                                dateFormat={mycontext.dateFormat}
                                id={props.id}
                                gmt={props.leave?.gmt}
                            />
                        ) : (
                            <DisplayTime2
                                startTime={isoToLocal(props.leave?.isoString?.startTime, mycontext.country)
                                    .toTimeString()
                                    .split(' ')[0]
                                    .substring(0, 5)}
                                endTime={isoToLocal(props.leave?.isoString?.endTime, mycontext.country)
                                    .toTimeString()
                                    .split(' ')[0]
                                    .substring(0, 5)}
                                startDate={isoToLocal(props.leave?.isoString?.startTime, mycontext.country)}
                                endDate={isoToLocal(props.leave?.isoString?.endTime, mycontext.country)}
                                dateFormat={mycontext.dateFormat}
                                id={props.id}
                                gmt={props.leave?.gmt}
                            />
                        )}
                        {/* {props.leave.startTime === 'null' && props.leave.startTime === null
                            ? null
                            : new Date(props.leave.startDate + '' + props.leave.startTime).toISOString()} */}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                        {mycontext.isAdmin || mycontext.isApprover ? (
                            <Typography className={classes.reason}>{props.leave.reason}</Typography>
                        ) : null}
                    </div>
                    {currentEmp?.employeeId !== props.leave?.Employee.employeeId && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography className={classes.reason}>
                                Leave applied by: <span className={classes.date}>{currentEmp?.Employee.firstName}</span>
                            </Typography>
                        </div>
                    )}
                    {props.id === 2 || props.id === 3 ? (
                        props.orgEmployees.filter((e: any) => e.employeeId === props.leave.approverId).length > 0 ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.reason}>
                                    Approved by:{' '}
                                    <span className={classes.date}>
                                        {
                                            props.orgEmployees.filter(
                                                (e: any) => e.employeeId === props.leave.approverId,
                                            )[0].Employee.firstName
                                        }
                                    </span>
                                </Typography>
                            </div>
                        ) : props.leave.approverId === null ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.reason}>
                                    Approved by: <span className={classes.date}>Auto Approved</span>
                                </Typography>
                            </div>
                        ) : null
                    ) : null}
                </div>
            </div>
            {props.id === 1 && (
                <Grid container className={classes.btncontainer}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            className={classes.deleteBtn}
                            onClick={() => props.handleDeny(props.leave)}
                        >
                            Deny
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            className={classes.editBtn}
                            onClick={() => props.handleApprove(props.leave)}
                        >
                            Approve
                        </Button>
                    </Grid>
                </Grid>
            )}
            {props.id === 3 && !props.isProfile ? (
                (mycontext.isApprover && mycontext.EmployeeId === props.leave.approverId) || mycontext.isAdmin ? (
                    <Grid container className={classes.btncontainer}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                className={classes.deleteBtn}
                                onClick={() => props.handleDeny(props.leave)}
                            >
                                Deny
                            </Button>
                        </Grid>
                    </Grid>
                ) : null
            ) : null}
        </>
    );
};

interface ApproveProps {
    handleApprove?: any;
}

export const ApproveBtn = (props: ApproveProps) => {
    const classes = useStyles();
    return (
        <div className={classes.approveBtn} onClick={() => props.handleApprove()} id="approveBtn">
            <CheckIcon className={classes.icon} />
            <span className={classes.btntext}>Approve</span>
        </div>
    );
};

interface DenyProps {
    handleDeny?: any;
    text?: string;
}

interface EditProps {
    handleEdit?: any;
    text?: string;
}
export const DenyBtn = (props: DenyProps) => {
    const classes = useStyles();
    return (
        <div className={classes.denyBtn} onClick={/* istanbul ignore next */ () => props.handleDeny()} id="denyBtn">
            <CloseIcon className={classes.icon} />
            <span className={classes.btntext}>{props.text}</span>
        </div>
    );
};

export const EditBtn = (props: EditProps) => {
    const classes = useStyles();
    return (
        <div className={classes.denyEditBtn} onClick={/* istanbul ignore next */ () => props.handleEdit()} id="denyBtn">
            <EditIcon className={classes.icon} />
            <span className={classes.btntext}>{props.text}</span>
        </div>
    );
};

/* istanbul ignore next */
export const SearchBar = (props: SearchBarProps) => {
    const classes = useStyles();
    // const theme = useTheme();
    return (
        // <div className={classes.searchbar}>
        //     <input type="text" placeholder="Search" value={props.value} onChange={(e: any) => props.setValue(e.target.value)} />
        // </div>
        <div className={props.mode === 'rounded' ? classes.searchbarRounded : classes.searchbarRect} {...props}>
            {/* {props.icon ? <SearchIcon style={{ marginRight: 10 }} fontSize="small" /> : null} */}
            {props.icon ? (
                <span
                    style={{ marginRight: 10, fontSize: 15, color: '#888888' }}
                    className="iconify"
                    data-icon="octicon:search-16"
                ></span>
            ) : null}
            <input
                type="text"
                placeholder="Search"
                value={props.value}
                onChange={(e: any) => props.setValue(e.target.value)}
                className={classes.searchBarInput}
            />
        </div>
    );
};

interface SelectboxProps {
    value?: string;
    setValue?: any;
    options?: any;
}
/* istanbul ignore next */
export const SelectBox = (props: SelectboxProps) => {
    const classes = useStyles();
    return (
        <FormControl>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select
                id="demo-simple-select"
                value={props.value}
                onChange={(e: any) => props.setValue(e.target.value)}
                // style={{ fontFamily: 'Nunito Sans', fontSize: 12 }}
                className={classes.graphDropDown}
                margin="dense"
                variant="outlined"
                classes={{
                    outlined: classes.input,
                    root: classes.input,
                }}
            >
                {props.options.map((v: any, i: number) => (
                    <MenuItem value={v.value} key={i} style={{ fontFamily: 'Nunito Sans', fontSize: 12 }}>
                        {v.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

interface ProgressBarProps {
    percentage?: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
    const classes = useStyles();
    return (
        <div className={classes.progressBar}>
            <div
                style={{
                    height: 5,
                    width: '30%',
                    backgroundColor: '#d6d6d6',
                    borderRadius: 5,
                }}
            >
                <div
                    style={{
                        width: `${props.percentage}%`,
                        borderRadius: 5,
                        height: 5,
                        backgroundColor: '#56E195',
                    }}
                ></div>
            </div>
            <span style={{ color: '#56E195', marginLeft: 10, fontSize: 14, fontWeight: 'bold' }}>
                {Math.floor(props.percentage || 0)} %{' '}
                <span style={{ fontSize: 12, color: 'silver', fontFamily: 'Nunito Sans' }}>Complete</span>
            </span>
        </div>
    );
};
