import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    Avatar,
    Typography,
    Hidden,
    Paper,
    Menu,
    MenuItem,
    Tooltip,
    Grid,
    useTheme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles, Theme } from '@material-ui/core/styles';
import myStyle from './TeamStyle';
import getInitials from '../../helpers/getInitials';
import AuthContext from '../../context/AuthContext';
import { Box } from '@mui/material';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RoleModal from './RoleModal';
import DisplayEmoji from '../TeamMembers/DisplayEmoji';

interface Props {
    EmployeeData?: any;
    searchvalue?: any;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
    filterFor?: any;
    teamEmployeeList?: any;
    teamID?: any;
    selectedTeam?: any;
    setSelectedTeam?: any;
    current?: any;
}
function TeamsTable(props: Props) {
    const classes = myStyle();
    const theme = useTheme();
    const [isEmployeeRole, setEmployeeRole] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<any>([]);
    const [action, setAction] = React.useState<string>('');
    const [employeeDetails, setEmployeeDetails] = React.useState([]);
    const mycontext = useContext(AuthContext);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleProfile = (user: any) => {
        const userData = {
            id: user.Employee.employeeId,
            role: user.isApprover ? 'Approver' : 'Member',
            name: user.Employee.firstName + user.Employee.lastName,
            image: user.Employee.profileImage,
            status: user.Employee.status,
        };
        dispatch({ type: 'PROFILE_ID', payload: userData });
        mycontext.addUserProfile(user.Employee.employeeId);
        if (userData.id === mycontext.EmployeeId) {
            history.push({
                pathname: '/user/profile',
                state: {
                    id: userData.id,
                    userLink: false,
                    backTo: 'teams',
                },
            });
        } else {
            history.push({
                pathname: `/employees/${userData.id}`,
                state: {
                    id: userData.id,
                    userLink: false,
                    backTo: 'teams',
                },
            });
        }
    };

    const handleApproverRole = () => {
        setAction('removeApprover');
        setEmployeeRole(!isEmployeeRole);
    };

    const handleMemberRole = () => {
        setAction('makeApprover');
        setEmployeeRole(!isEmployeeRole);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>, emp: any) => {
        setAnchorEl((anchorEl: any) => ({
            ...anchorEl,
            [emp.Employee.employeeId]: event.target,
        }));
        setEmployeeDetails(emp);
    };

    const handleClose = (empId: any) => {
        setAnchorEl((anchorEl: any) => ({
            ...anchorEl,
            [empId]: null,
        }));
    };
    return (
        <div>
            <RoleModal
                isEmployeeRole={isEmployeeRole}
                setEmployeeRole={setEmployeeRole}
                action={action}
                teamID={props.teamID}
                employeeDetails={employeeDetails}
                setAlertMessage={props.setAlertMessage}
                setAlertm={props.setAlertm}
                setSeverity={props.setSeverity}
                // current={props.current}
            />
            <Hidden mdDown>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader size="small" aria-label="small table" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.idColumn}>
                                    #
                                </TableCell>
                                <TableCell align="left" className={classes.columnnam}>
                                    Employees({props.teamEmployeeList.length})
                                </TableCell>
                                <TableCell align="left" className={classes.columnnam}>
                                    Role
                                </TableCell>
                                {mycontext.isAdmin && props.current === 'details' && (
                                    <TableCell align="center" className={classes.columnnam}>
                                        Action&nbsp;
                                        <LightTooltip
                                            title="You cannot make admin as an approver of a team"
                                            placement="top-start"
                                        >
                                            <InfoIcon id="infoicon" fontSize="inherit" style={{ color: '#515ECE' }} />
                                        </LightTooltip>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.teamEmployeeList.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} className={classes.fontsty} style={{ borderBottom: 'none' }}>
                                        <div
                                            style={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                position: 'relative',
                                            }}
                                        >
                                            <img
                                                src={upcomingTimeoff}
                                                alt="No Employee Found"
                                                className={classes.notfoundImage}
                                            />
                                            {props.current === 'create' ? (
                                                <Typography className={classes.notfoundText}>
                                                    No employee added
                                                </Typography>
                                            ) : (
                                                <Typography className={classes.notfoundText}>
                                                    No records found
                                                </Typography>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                props.teamEmployeeList.map((employee: any, index: number) => (
                                    <TableRow key={index} className={classes.row}>
                                        <TableCell align="center" className={classes.hashColumn}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="left" className={classes.cellName}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {employee.Employee.profileImage ? (
                                                    <Avatar
                                                        alt={employee.Employee.firstName}
                                                        src={employee.Employee.profileImage}
                                                        className={classes.image}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        alt={employee.Employee.firstName}
                                                        className={classes.image}
                                                        src={getInitials(employee.Employee.firstName)}
                                                    />
                                                )}
                                                &nbsp;&nbsp;&nbsp;
                                                <Box
                                                    className={classes.boxtext}
                                                    onClick={() => handleProfile(employee)}
                                                >
                                                    {employee.Employee.firstName}
                                                    &nbsp;
                                                    {employee.Employee.lastName}{' '}
                                                    <DisplayEmoji employeeId={employee.Employee.employeeId} />
                                                </Box>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left" className={classes.cellName}>
                                            {employee.isApprover ? 'Approver' : 'Member'}
                                        </TableCell>

                                        {mycontext.isAdmin && props.current === 'details' && (
                                            <TableCell align="center" className={classes.cellName}>
                                                <>
                                                    <IconButton
                                                        id="iconButton"
                                                        aria-controls="iconMenu"
                                                        onClick={(e: any) => handleMenu(e, employee)}
                                                        style={{ padding: 0, outline: 'none' }}
                                                    >
                                                        <MoreHorizIcon className={classes.IconSty} />
                                                    </IconButton>
                                                    <Menu
                                                        id="iconMenu"
                                                        anchorEl={anchorEl[employee.Employee.employeeId]}
                                                        open={Boolean(anchorEl[employee.Employee.employeeId])}
                                                        onClick={e => handleClose(employee.Employee.employeeId)}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'iconButton',
                                                        }}
                                                        keepMounted
                                                        getContentAnchorEl={null}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        className={classes.menusty}
                                                    >
                                                        <MenuItem
                                                            className={classes.menuitem}
                                                            onClick={() => handleProfile(employee)}
                                                        >
                                                            View Profile
                                                        </MenuItem>
                                                        {employee.isApprover ? (
                                                            <MenuItem
                                                                className={classes.menuitem}
                                                                onClick={handleApproverRole}
                                                                // disabled={
                                                                //     employee.Employee.organization_employees.length !==
                                                                //     0
                                                                // }
                                                            >
                                                                Delete Approver
                                                            </MenuItem>
                                                        ) : (
                                                            <MenuItem
                                                                className={classes.menuitem}
                                                                onClick={handleMemberRole}
                                                                disabled={
                                                                    employee.Employee.organization_employees.length !==
                                                                    0
                                                                }
                                                            >
                                                                Make Approver&nbsp;
                                                            </MenuItem>
                                                        )}
                                                    </Menu>
                                                </>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Hidden>
            <Hidden lgUp>
                <div>
                    {props.teamEmployeeList.length === 0 ? (
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid container item justify="center" xs={8}>
                                <img src={upcomingTimeoff} alt="No Employee Found" className={classes.notfoundImage} />
                            </Grid>
                            <Grid container item justify="center" xs={8}>
                                <Typography className={classes.notfoundText}>No employee found</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        props.teamEmployeeList.map((employee: any, index: any) => (
                            <Paper
                                elevation={0}
                                key={index}
                                style={{ padding: '10px', margin: '5px 0px', borderRadius: '0px' }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className={classes.rowteam} style={{ marginTop: '6px' }}>
                                        {employee.Employee.profileImage ? (
                                            <Avatar
                                                alt={employee.Employee.firstName}
                                                src={employee.Employee.profileImage}
                                                className={classes.image}
                                            />
                                        ) : (
                                            <Avatar
                                                alt={employee.Employee.firstName}
                                                className={classes.image}
                                                src={getInitials(employee.Employee.firstName)}
                                            />
                                        )}
                                        &nbsp;
                                        <Box className={classes.boxtext} onClick={() => handleProfile(employee)}>
                                            {employee.Employee.firstName}
                                            &nbsp;
                                            {employee.Employee.lastName}
                                            &nbsp;
                                            <DisplayEmoji employeeId={employee.Employee.employeeId} />
                                            &nbsp;
                                            {employee.Employee.organization_employees.length !== 0 ? (
                                                <LightTooltip
                                                    title="You cannot make admin as an approver of a team"
                                                    placement="top-start"
                                                >
                                                    <InfoIcon
                                                        id="infoicon"
                                                        fontSize="inherit"
                                                        style={{ color: '#515ECE' }}
                                                    />
                                                </LightTooltip>
                                            ) : null}{' '}
                                        </Box>
                                        &nbsp;
                                    </div>
                                    <div>
                                        {mycontext.isAdmin && props.current === 'details' && (
                                            <>
                                                <IconButton
                                                    id="iconButton"
                                                    aria-controls="iconMenu"
                                                    onClick={(e: any) => handleMenu(e, employee)}
                                                    style={{ padding: 0, outline: 'none' }}
                                                >
                                                    <MoreHorizIcon />
                                                </IconButton>
                                                <Menu
                                                    id="iconMenu"
                                                    anchorEl={anchorEl[employee.Employee.employeeId]}
                                                    open={Boolean(anchorEl[employee.Employee.employeeId])}
                                                    onClick={e => handleClose(employee.Employee.employeeId)}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'iconButton',
                                                    }}
                                                    keepMounted
                                                    getContentAnchorEl={null}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    className={classes.menusty}
                                                >
                                                    <MenuItem
                                                        className={classes.menuitem}
                                                        onClick={() => handleProfile(employee)}
                                                    >
                                                        View Profile
                                                    </MenuItem>
                                                    {employee.isApprover ? (
                                                        <MenuItem
                                                            className={classes.menuitem}
                                                            onClick={handleApproverRole}
                                                        >
                                                            Delete Approver
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem
                                                            className={classes.menuitem}
                                                            onClick={handleMemberRole}
                                                            disabled={
                                                                employee.Employee.organization_employees.length !== 0
                                                            }
                                                        >
                                                            Make Approver
                                                        </MenuItem>
                                                    )}
                                                </Menu>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className={classes.rowteam} style={{ marginTop: '6px' }}>
                                    <div style={{ fontSize: '16px', color: theme.palette.secondary.contrastText }}>
                                        Role:&nbsp;
                                    </div>
                                    <div style={{ fontSize: '16px', color: theme.palette.text.primary }}>
                                        {employee.isApprover ? 'Approver' : 'Member'}
                                    </div>
                                </div>
                            </Paper>
                        ))
                    )}
                </div>
            </Hidden>
        </div>
    );
}
const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        color: '#444444',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        width: 180,
    },
}))(Tooltip);
export default TeamsTable;
