import React from 'react';
// import TeamPlusLogo from 'teamplusLogoWhite.png';
// import LinearProgress from '@material-ui/core/LinearProgress';
import './SpinnerStyle.css';

export function NameLoader(): JSX.Element {
    // const [progress, setProgress] = React.useState<number>(0);
    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress(oldProgress => {
    //             if (oldProgress === 100) {
    //                 return 0;
    //             }
    //             const diff = Math.random() * 10;
    //             return Math.min(oldProgress + diff, 100);
    //         });
    //     }, 1000);

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);
    return (
        <div className="main">
            <div className="innerDiv">
                <img src="teamplusLogoWhite.png" alt="teamplus" className="teamLogo" />
                {/* <div className="line"></div> */}
                {/* <div className="progressDiv">
                    <LinearProgress color="primary" variant="buffer" value={progress} />
                </div> */}
            </div>
        </div>
    );
}

export function ThreeDotsLoader(): JSX.Element {
    return (
        <div className="loader_container">
            <div className="loader_dot"></div>
            <div className="loader_dot"></div>
            <div className="loader_dot"></div>
        </div>
    );
}
export default function Spinner(): JSX.Element {
    return (
        <div className="main">
            <div className="lds-dual-ring"></div>
        </div>
    );
}
