import React, { useEffect } from 'react';
import myStyles from './StandupStyles';
import { useDispatch } from 'react-redux';
import { Grid, Paper, Typography } from '@material-ui/core';
import ComingSoonImage from '../../Assets/images/comingSoon.png';
import { updateActiveTab } from '../Redux/activeTab';

export default function Standups() {
    const dispatch = useDispatch();
    const classes = myStyles();
    useEffect(() => {
        dispatch(updateActiveTab('/standups'));
        // dispatch({ type: 'ACTIVE', payload: '/standups' });
    }, [dispatch]);

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={4} className={classes.comingSoom}>
                    <Typography variant="h3" className={classes.comingSoom} data-testid="coming">
                        Coming Soon
                    </Typography>
                </Grid>
                <Grid container item xs={12} sm={8} justify="center" alignItems="center">
                    <img
                        src={ComingSoonImage}
                        data-testid="standup-img"
                        className={classes.standupImage}
                        alt="BackGroundImg"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}
