import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
const otherStyle = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            width: '100%',
            height: '100%',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            // background: '#fff',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 20,
        },
        heading: {
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 18,
            '@media screen and (max-width: 650px)': {
                fontSize: 14,
                marginBottom: 10,
            },
        },
        btn: {
            textTransform: 'capitalize',
            width: 150,
            height: 30,
            color: theme.palette.common.white,
            '&:hover': {
                outline: 'none',
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
            },
        },
        btnDiv: {
            display: 'flex',
            alignItems: 'center',
        },
        btnOutlined: {
            textTransform: 'capitalize',
            width: 150,
            height: 30,
            marginRight: 10,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
            },
        },
        leaveMain: {
            width: '100%',
            maxHeight: 'calc(100vh - 210px)',
            height: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
            '@media screen and (max-width: 768px)': {
                maxHeight: '400px',
            },
        },
        leaveMainNotFound: {
            width: '100%',
            // maxHeight: 'calc(100vh - 210px)',
            height: 'calc(100vh - 210px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
            '@media screen and (max-width: 768px)': {
                maxHeight: '400px',
            },
        },
        notfoundImage: {
            maxWidth: '80%',
            height: '80%',
        },
        notfoundText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.contrastText,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
        },
        leave: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 5px',
            paddingRight: 10,
            '@media screen and (max-width: 768px)': {
                paddingRight: 0,
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
            },
        },
        leaveLeft: {
            flex: 0.8,
            display: 'flex',
            flexDirection: 'column',
        },
        leaveMiddle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginRight: 50,
            '@media screen and (max-width: 768px)': {
                marginRight: 0,
            },
        },
        label: {
            fontSize: 10,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            marginBottom: 5,
        },
        approverName: {
            fontSize: 12,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
        },
        reasonStyle: {
            color: theme.palette.text.primary,
        },
        leaveRight: {
            display: 'flex',
            flex: 0.2,
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (max-width: 768px)': {
                alignItems: 'flex-start',
                justifyContent: 'center',
            },
        },
        data: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 5,
            '@media screen and (max-width: 768px)': {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
        cancelBtn: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.error.main,
            width: '100%',
            height: 30,
            cursor: 'pointer',
            fontSize: 14,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            textTransform: 'capitalize',
            '&:hover': {
                color: theme.palette.error.main,
                backgroundColor: theme.palette.background.paper,
            },
            '&:focus': {
                color: theme.palette.error.main,
                backgroundColor: theme.palette.background.paper,
                outline: 'none',
            },
            zIndex: 0,
            margin: '5px 0',
        },
        editBtn: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.main,
            width: '100%',
            height: 30,
            cursor: 'pointer',
            fontSize: 14,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            textTransform: 'capitalize',
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
            },
            '&:focus': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
                outline: 'none',
            },
            zIndex: 0,
            margin: '5px 0',
        },
        mobileBtns: {
            padding: '10px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: 10,
            backgroundColor: theme.palette.background.paper,
            marginBottom: 10,
            // marginTop: -10,
            // marginLeft: -10,
            // marginRight: -10,
        },
        mobileTabsDiv: {
            display: 'flex',
            flexDirection: 'column',
        },
        mobileBgDarkMode: {
            height: '100%',
            backgroundColor: theme.palette.background.paper,
            padding: 10,
        },
        modalHeading: {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.contrastText,
            marginBottom: 10,
            '@media screen and (max-width: 768px)': {
                fontSize: 14,
            },
        },
        approverContainer: {
            width: '100%',
            height: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr 0.8fr',
            gridColumnGap: 10,
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        myleaves: {
            width: '100%',
            height: '100%',
        },
        leavesContainer: {
            width: '100%',
            height: '100%',
            borderRadius: 5,
        },
        deleteModalContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        titleLabel: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '25px',
            marginTop: '15px',
        },
        deleteContent: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
            textAlign: 'center',
            color: theme.palette.primary.contrastText,
        },
        deleteModalButtonsDiv: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            marginBottom: '20px',
        },
        titleDelete: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: theme.typography.fontWeightBold,
        },
        deleteData: {
            fontFamily: theme.typography.fontFamily,
            color: '#666666',
            fontSize: 16,
            fontWeight: theme.typography.fontWeightRegular,
        },
        deleteBtn: {
            height: 30,
            width: 100,
            outline: 'none',
            position: 'relative',
            float: 'left',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            background: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            marginRight: 5,
            '@media (max-width: 650px)': {
                fontSize: '14px',
                outline: 'none',
            },
            '&:hover': {
                outline: 'none',
                color: theme.palette.text.secondary,
                background: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
            },
            '&:focus': {
                outline: 'none',
                color: theme.palette.text.secondary,
                background: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
            },
        },
        createBtn: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            outline: 'none',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'capitalize',
            height: 30,
            width: 100,
            '@media (max-width: 650px)': {
                fontSize: '14px',
            },
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        modal: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 99999,
            backgroundColor: 'rgba(0, 0, 0,.5)',
        },
        modalOff: {
            display: 'none',
        },
        outerDivHoliday: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0,.5)',
            zIndex: 0,
        },
        holiday: {
            zIndex: 1,
            width: '60%',
            height: '70%',
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            position: 'relative',
            // boxShadow: '0px 0.3px 0px 0px #888888',
            border: `0.5px solid ${theme.palette.grey}`,
            borderRadius: 5,
            '@media (max-width: 650px)': {
                width: '96%',
            },
        },
        holidayList: {
            height: '500px',
            width: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        icon: {
            position: 'absolute',
            right: 10,
            top: 10,
            cursor: 'pointer',
            outline: 'none',
            color: '#888888',
            fontSize: 16,
        },
        date: {
            fontSize: 12,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            letterSpacing: '0.02em',
            '&span': {
                fontSize: 12,
                color: '#666666',
                fontWeight: 'normal',
                letterSpacing: '0.02em',
                fontFamily: theme.typography.fontFamily,
            },
        },
        reason: {
            fontSize: 12,
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightRegular,
            letterSpacing: '0.02em',
            fontFamily: theme.typography.fontFamily,
        },
    }),
);
export default otherStyle;
