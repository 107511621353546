import React, { useContext, useEffect } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import Logodesk from './Group 75.png';
import Logotrue from './emojione-monotone_heavy-check-mark.png';
import { AddToSlackStyles } from './AddToSlackStyle';
import AuthContext from '../../context/AuthContext';
import config from '../../config.json';
import {
    getSlackWorkspace,
    // updateIntegrateWithSlackPendingAction
} from 'helpers/hasuraapi';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
// import Teams from './teams.png';

export default function Addtoslack() {
    const mycontext = useContext(AuthContext);
    const classes = AddToSlackStyles();
    const [isIntegrated, setIsIntegrated] = React.useState<boolean>(false);
    // const [isMsTeamsIntegrated, setIsMsTeamsIntegrated] = React.useState<boolean>(false);

    const handleClick = async () => {
        // await updateIntegrateWithSlackPendingAction(mycontext.organizationId, mycontext.token);
    };

    // const handleClick1 = async () => {
    //     await updateIntegrateWithMsTeamsPendingAction(mycontext.organizationId, mycontext.token);
    // }

    useEffect(() => {
        let isMounted = true;
        async function getData() {
            const slackData = await getSlackWorkspace(mycontext.organizationId, mycontext.token);
            if (slackData.length > 0) {
                setIsIntegrated(true);
            }

            //for ms teams
            // const msTeamsData = await getMsData(mycontext.organizationId, mycontext.token);
            // if (msTeamsData) {
            //     setIsMsTeamsIntegrated(true);
            // }
        }

        if (isMounted) {
            getData();
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        <SubscriptionHardExpired
            content="Looks like your subscription is expired, Please renew it !"
            showRenew={true}
            fromSettings={true}
        />
    ) : (
        <Paper id="AddToSlackPage" elevation={0} className={classes.slackpaper}>
            <Grid container style={{ padding: 20 }}>
                <Grid item sm={12} xs={12} style={{ padding: '0px 0px 15px 0px' }}>
                    <Typography className={classes.notification}>Notification</Typography>
                    <Typography className={classes.notification1}>
                        For receiving time-off notification on Slack, click below and integrate your Slack workspace
                        with TeamPlus.
                    </Typography>
                </Grid>

                <Grid item sm={4} xs={12}>
                    <img src={Logodesk} alt="DesktopLogo" className={classes.image}></img>
                </Grid>

                <Grid item sm={8} xs={12} className={classes.contentDiv}>
                    <Typography className={classes.line2}>
                        Integrate TeamPlus with your Slack workspace to make time-off management quick and easy for your
                        team.
                    </Typography>

                    <Typography className={classes.text}>
                        TeamPlus Slack integration allows to do the following from Slack itself :
                    </Typography>

                    <Typography className={classes.text}>
                        <img src={Logotrue} alt="RightLogo"></img>&nbsp;Admins to approve and deny leaves.
                    </Typography>

                    <Typography className={classes.text}>
                        <img src={Logotrue} alt="RightLogo"></img>&nbsp;Team members and admins to view each other’s
                        availabilities.
                    </Typography>

                    <Typography className={classes.text}>
                        <img src={Logotrue} alt="RightLogo"></img>&nbsp;Team members to apply for leave.
                    </Typography>

                    <a
                        href={`https://slack.com/oauth?client_id=${config.slack.CLIENT_ID}&scope=incoming-webhook%2Ccommands%2Cbot%2Cusers.profile:write&user_scope=users.profile:write&redirect_uri=https://4fxm4bwhy0.execute-api.eu-north-1.amazonaws.com/production/auth/slack/callback&state=${mycontext.organizationId}&granular_bot_scope=0&single_channel=0&install_redirect=&tracked=1&team=`}
                        className={isIntegrated ? classes.disabledLink : classes.slackLink}
                        onClick={() => handleClick()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ height: 20, width: 20, marginRight: 12 }}
                            viewBox="0 0 122.8 122.8"
                        >
                            <path
                                d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                                fill="#e01e5a"
                            ></path>
                            <path
                                d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                                fill="#36c5f0"
                            ></path>
                            <path
                                d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                                fill="#2eb67d"
                            ></path>
                            <path
                                d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                                fill="#ecb22e"
                            ></path>
                        </svg>{' '}
                        Add to Slack
                    </a>

                    {/* for ms teams */}
                    {/* <a
                        href={`https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${
                            config.ms_teams.MICROSOFTAPPID
                        }&response_type=code&redirect_uri=https://kcnbvmvek7.execute-api.us-east-1.amazonaws.com/test/auth/addToTeams&response_mode=query&scope=ChatMessage.Send Chat.ReadWrite&state=${[
                            mycontext.organizationId,
                            mycontext.EmployeeId,
                        ]}`}
                        className={isMsTeamsIntegrated ? classes.disabledLink1 : classes.slackLink1}
                    >
                        <img src={Teams} alt="" style={{ height: 25, width: 25, marginRight: 12 }} />
                        Connect To Teams
                    </a> */}
                </Grid>
            </Grid>
            <a
                href={`https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?
                client_id=f6d4ccca-0599-40f1-bcf6-f8bbe8c23deb
                &response_type=code
                &redirect_uri=https://v43gplaffk.execute-api.us-east-1.amazonaws.com/dev/addToTeams1
                &response_mode=query
                &scope=offline_access user.read mail.read
                &state=${[mycontext.organizationId, mycontext.EmployeeId]}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                Add to Teams
            </a>
            {/* <a href='https://login.microsoftonline.com/77521edf-526a-479e-8e90-bd7785d79ad9/oauth2/v2.0/authorize?
                client_id=f6d4ccca-0599-40f1-bcf6-f8bbe8c23deb
                &response_type=token
                &redirect_uri=https://www.microsoft.com/en-ww/microsoft-teams/log-in
                &response_mode=fragment
                &scope=https%3A%2F%2Fgraph.microsoft.com%2Fuser.read
                &state=12345
                &nonce=678910' target='_blank'>Add to Teams</a> */}
        </Paper>
    );
}
