import React from 'react';
import { Grid, Paper, FormLabel, Hidden, Box } from '@material-ui/core';
// import Page1Img from '../../Assets/images/Page1Img.png';
// import udyamo from '../../Assets/images/udyamo.png';
import useStyles from './newRegisterStyles';
import { Link } from 'react-router-dom';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

interface NewRegister {
    values?: any;
}
export default function UserAlreadyRegistered(props: NewRegister) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container direction="row">
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.registerPageImage}
                                    alt="Page1Img"
                                    style={{ height: '75vh' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                    alt="udyamoLogo"
                                    style={{ height: '2.5vh' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} lg={12} xl={7} className={classes.secondGrid}>
                    <Paper style={{ boxShadow: 'none' }} className={classes.paperStyleOTP}>
                        <form className={classes.secondGridSmall}>
                            <Grid container direction="row">
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <FormLabel className={classes.title}>Already Registered</FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} className={classes.label2}>
                                        <p className={classes.para1}>
                                            Looks like you are already registered with Teamplus. with the{' '}
                                            <strong>{props.values.orgDetails.orgName}</strong> Organization.
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} className={classes.label2}>
                                        <p className={classes.para2}>
                                            Login to your TeamPlus account <Link to="/login">Click here</Link>{' '}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
