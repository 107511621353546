import { createSlice } from '@reduxjs/toolkit';
// import { DateDifference } from 'helpers/date';
// import { isoToLocal, getCurrentCountryDate } from 'helpers/timezone';
// import { convertTZ } from 'helpers/date';
// import { fetchPendingLeaves, fetchTodaysLeaves, fetchUpcomingLeaves } from 'helpers/hasuraapi';

// export const getPendingLeaves: any = createAsyncThunk('dashboardLeaves/getPendingLeaves', async (user: any) => {
//     const data: any = await fetchPendingLeaves(user.organizationId, user.token);
//     const jsonData: any = await data.json();
//     return jsonData.data.timeoffdb_Leaves;
// });

// export const getTodaysLeaves: any = createAsyncThunk('dashboardLeaves/getTodaysLeaves', async (user: any) => {
//     const data: any = await fetchTodaysLeaves(user.organizationId, user.token, user.date);
//     const jsonData: any = await data.json();
//     return jsonData.data.timeoffdb_Leaves;
// });

// export const getUpcomingLeaves: any = createAsyncThunk('dashboardLeaves/getUpcomingLeaves', async (user: any) => {
//     const data: any = await fetchUpcomingLeaves(user.organizationId, user.token, user.date);
//     const jsonData: any = await data.json();
//     return jsonData.data.timeoffdb_Leaves;
// });

export const dashboardLeavesSlice = createSlice({
    name: 'dashboardLeaves',
    initialState: {
        pendingLeaves: [],
        todaysLeaves: [],
        upcomingLeaves: [],
        pstatus: 'loading',
        tstatus: 'loading',
        ustatus: 'loading',
    },
    reducers: {
        addPendingLeave: (state: any, action: any) => {
            state.pendingLeaves.push(action.payload);
        },
        // fetchPendingLeave: (state: any, action: any) => {
        //     state.pendingLeaves = action.payload;
        // },
        // fetchTodaysLeave: (state: any, action: any) => {
        //     state.todaysLeaves = action.payload;
        // },
        // fetchUpcomingLeave: (state: any, action: any) => {
        //     state.upcomingLeaves = action.payload;
        // },
        addTodaysLeave: (state: any, action: any) => {
            state.todaysLeaves.push(action.payload);
        },
        addUpcomingLeave: (state: any, action: any) => {
            state.upcomingLeaves.push(action.payload);
        },
        editPendingLeave: (state, action) => {
            state.pendingLeaves.map((u: any) => {
                if (u.leaveId === action.payload.leaveId) {
                    u.approverId = action.payload.approverId;
                    u.denialReason = action.payload.denialReason;
                    u.denierId = action.payload.denierId;
                    u.leaveStatus = action.payload.leaveStatus;
                    u.startDate = action.payload.startDate;
                    u.endDate = action.payload.endDate;
                    u.startTime = action.payload.startTime;
                    u.endTime = action.payload.endTime;
                    u.noOfHours = action.payload.noOfHours;
                    u.reason = action.payload.reason;
                    return u;
                }
                return u;
            });
        },
        editTodaysLeave: (state, action) => {
            state.todaysLeaves.map((u: any) => {
                if (u.leaveId === action.payload.leaveId) {
                    u.approverId = action.payload.approverId;
                    u.denialReason = action.payload.denialReason;
                    u.denierId = action.payload.denierId;
                    u.leaveStatus = action.payload.leaveStatus;
                    u.startDate = action.payload.startDate;
                    u.endDate = action.payload.endDate;
                    u.startTime = action.payload.startTime;
                    u.endTime = action.payload.endTime;
                    u.noOfHours = action.payload.noOfHours;
                    u.reason = action.payload.reason;
                    return u;
                }
                return u;
            });
        },
        editUpcomingLeave: (state, action) => {
            state.upcomingLeaves.map((u: any) => {
                if (u.leaveId === action.payload.leaveId) {
                    u.approverId = action.payload.approverId;
                    u.denialReason = action.payload.denialReason;
                    u.denierId = action.payload.denierId;
                    u.leaveStatus = action.payload.leaveStatus;
                    u.startDate = action.payload.startDate;
                    u.endDate = action.payload.endDate;
                    u.startTime = action.payload.startTime;
                    u.endTime = action.payload.endTime;
                    u.noOfHours = action.payload.noOfHours;
                    u.reason = action.payload.reason;
                    return u;
                }
                return u;
            });
        },
        fetchTodaysLeaves: (state, action) => {
            if (action.payload?.loading) {
                state.tstatus = 'loading';
            }
            if (action.payload?.error) {
                state.tstatus = 'failed';
            } else {
                state.tstatus = 'success';
                // console.log(action.payload);
                // action.payload?.data?.timeoffdb_Leaves.filter((l: any) => DateDifference(l.isoString?.startTime, convertTZ(new Date().toISOString(), action.payload.country?.zoneName)?.toISOString())
                state.todaysLeaves = action.payload?.data?.timeoffdb_Leaves;
                // state.todaysLeaves = action.payload?.data?.timeoffdb_Leaves.filter(
                //     (l: any) =>
                //         DateDifference(
                //             isoToLocal(l.isoString?.startTime, JSON.parse(localStorage.getItem('country') || '')),
                //             getCurrentCountryDate(JSON.parse(localStorage.getItem('country') || '').zoneName),
                //         ) >= 0 &&
                //         DateDifference(
                //             getCurrentCountryDate(JSON.parse(localStorage.getItem('country') || '').zoneName),
                //             isoToLocal(l.isoString?.endTime, JSON.parse(localStorage.getItem('country') || '')),
                //         ) > 0,
                // );
            }
        },
        fetchPendingLeaves: (state, action) => {
            if (action.payload?.loading) {
                state.pstatus = 'loading';
            }
            if (action.payload?.error) {
                state.pstatus = 'failed';
            } else {
                state.pstatus = 'success';
                state.pendingLeaves = action.payload?.data?.timeoffdb_Leaves;
            }
        },
        fetchUpcomingLeaves: (state, action) => {
            if (action.payload?.loading) {
                state.ustatus = 'loading';
            }
            if (action.payload?.error) {
                state.ustatus = 'failed';
            } else {
                state.ustatus = 'success';
                state.upcomingLeaves = action.payload?.data?.timeoffdb_Leaves;
            }
        },
    },
    // extraReducers: {
    //     [getPendingLeaves.pending]: state => {
    //         state.status = 'loading';
    //     },
    //     [getPendingLeaves.fulfilled]: (state, action) => {
    //         state.pendingLeaves = action.payload;
    //         state.status = 'success';
    //     },
    //     [getPendingLeaves.rejected]: state => {
    //         state.status = 'failed';
    //     },
    //     [getTodaysLeaves.pending]: state => {
    //         state.status = 'loading';
    //     },
    //     [getTodaysLeaves.fulfilled]: (state, action) => {
    //         state.todaysLeaves = action.payload;
    //         state.status = 'success';
    //     },
    //     [getTodaysLeaves.rejected]: state => {
    //         state.status = 'failed';
    //     },
    //     [getUpcomingLeaves.pending]: state => {
    //         state.status = 'loading';
    //     },
    //     [getUpcomingLeaves.fulfilled]: (state, action) => {
    //         state.upcomingLeaves = action.payload;
    //         state.status = 'success';
    //     },
    //     [getUpcomingLeaves.rejected]: state => {
    //         state.status = 'failed';
    //     },
    // },
});

export const {
    addPendingLeave,
    fetchPendingLeaves,
    fetchTodaysLeaves,
    fetchUpcomingLeaves,
} = dashboardLeavesSlice.actions;
export default dashboardLeavesSlice.reducer;
