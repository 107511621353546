import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import { useDispatch } from 'react-redux';
// import { Hidden } from '@material-ui/core';
import useStyles from './style';
// import Questions from './questions';

export default function TeamplusPolicy() {
    const dispatch = useDispatch();
    const classes = useStyles();
    useEffect(() => {
        dispatch({ type: 'ACTIVE', payload: '/privacypolicy' });
    }, [dispatch]);

    const PolicyBlock = () => {
        return (
            <div className={classes.policyDiv}>
                <h3 className={classes.policyHeading} style={{ marginBottom: 0 }}>
                    Privacy Policy
                </h3>

                <div className={classes.policySubtitle}>
                    <h5 className={classes.policySubtitleheading}>Welcome To TeamPlus!</h5>
                    <br />
                    <p>
                        Udyamo is a g-suite for all the applications under ownership of Udgama Softwares & Systems
                        Private Limited. Teamplus is one such application under this g-suite and is accessible through
                        teamplus.udyamo.com
                    </p>
                    <p>
                        Teamplus is committed to protecting your policy. This Privacy Policy explains how your personal
                        information is collected, used and disclosed by Teamplus.
                    </p>
                    <p>
                        By accessing or using our services, you signify that you have read, understood, and agree to our
                        collection, storage, use and disclosure of your personal information as described in our Privacy
                        Policy.
                    </p>
                    <p>
                        If you have additional questions or require more information about our Privacy Policy, do not
                        hesitate to contact us.
                    </p>
                </div>

                <div>
                    <dl>
                        <dt className={classes.titles}>1. Applicability</dt>
                        <dd className={classes.description}>
                            <p>
                                This Privacy Policy applies to all the websites/applications linked with Teamplus. It
                                also applies to all the services provided by Teamplus applications and applications
                                posted by Udyamo and in other third-party online marketplaces.
                            </p>
                            <p>
                                This Privacy Policy applies only to our online activities and is valid for visitors to
                                our website and application with regards to the information that they shared and/or
                                collected in Teamplus. This policy is not applicable to any information collected
                                offline or via channels other than this.
                            </p>
                        </dd>
                        <dt className={classes.titles}>2. Consent</dt>
                        <dd className={classes.description}>
                            <p>
                                By using our application, you hereby consent to our Privacy Policy and agree to its
                                terms.
                            </p>
                        </dd>

                        <dt className={classes.titles}>3. Information we collect</dt>
                        <dd className={classes.description}>
                            <p>
                                The personal information that you are asked to provide, and the reasons why you are
                                asked to provide it, will be made clear to you at the point we ask you to provide your
                                personal information.
                            </p>
                            <p>
                                If you contact us directly, we may receive additional information about you such as your
                                name, email address, phone number, the contents of the message and/or attachments you
                                may send us, and any other information you may choose to provide.
                            </p>
                            <p>
                                When you register for an account, we may ask for your contact information, including
                                items such as name, company name, address, email address, and telephone number.
                            </p>
                            <p>
                                We collect information about you only if we need the information for some legitimate
                                purpose. We will have information about you only if (a) you have provided the
                                information yourself, (b) We have automatically collected the information, or (c) We
                                have obtained the information from a third party.
                            </p>
                            <p>The type of data collected are as mentioned aforesaid:</p>
                            <ol type="a" className={classes.alist}>
                                <li>
                                    <span className={classes.listTitle}>Customer Data: </span>
                                    <span>
                                        Customers or Individuals granted access to a workspace by a customer
                                        (“Authorized Users”) routinely submit customer data to Teamplus when using the
                                        services.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Personal Data: </span>
                                    <span>
                                        The personal information that you are asked to provide, and the reasons why you
                                        are asked to provide it, will be made clear to you at the point we ask you to
                                        provide your personal information. While using our service, we may ask you to
                                        provide us with certain personally identifiable information that can be used to
                                        contact or identify you. Personally identifiable information may include, but is
                                        not limited to the email address, first name and last name, usage data.
                                        Authorized Users may provide us with their personal data directly at instances,
                                        e.g., when they provide feedback or request client support or in any way
                                        communicate with us. We do not collect or use any sensitive personal data unless
                                        the decider (customer) has received an expressed consent regarding the specific
                                        data.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Usage Data: </span>
                                    <span>
                                        Usage Data is collected automatically when using the service. The brief types of
                                        usage data collected are:
                                    </span>
                                    <ul style={{ marginLeft: 40, marginBottom: 10 }}>
                                        <li>Service Metadata</li>
                                        <li>Account signup</li>
                                        <li>Log Data</li>
                                        <li>Device Information</li>
                                        <li>Location Information</li>
                                    </ul>

                                    <p>
                                        When you sign up for an account to access one or more of our services, we ask
                                        for information like your name, contact number, email address, company name and
                                        country to complete the account signup process.
                                    </p>
                                    <p>
                                        You’ll also be required to choose a unique username and a password for accessing
                                        the created account. You may also provide us with more information such as your
                                        photo, time zone and language, but we don’t require that information to sign up
                                        for an account.
                                    </p>
                                    <p>
                                        When an Authorized User interacts with the services, metadata is generated that
                                        provides additional context about the way that Authorized Users work.
                                    </p>
                                    <p>
                                        Usage Data may include information such as your device’s Internet Protocol
                                        address (e.g. IP address), browser type, browser version, the pages of our
                                        service that you visit, the time and date of your visit, the time spent on those
                                        pages, unique device identifiers and other diagnostic data.
                                    </p>
                                    <p>
                                        When you access the service by or through a mobile device, we may collect
                                        certain information automatically, including, but not limited to, the type of
                                        mobile device you use, your mobile device unique ID, the IP address of your
                                        mobile device, your mobile operating system, the type of mobile Internet browser
                                        you use, unique device identifiers and other diagnostic data.
                                    </p>
                                    <p>
                                        We may also collect information that your browser sends whenever you visit our
                                        service or when you access the service by or through a mobile device.
                                    </p>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Third-Party Services: </span>
                                    <span>
                                        A Customer can choose to permit or restrict Third-Party Services for its
                                        workspace. Typically, Third-Party Services are software that integrate with our
                                        services, and a customer can permit its authorized users to enable and disable
                                        these integrations for its workspace. Once enabled, the provider of a
                                        Third-Party Service may share certain information with us.
                                    </span>
                                    <p style={{ marginTop: 10 }}>
                                        Authorized Users should check the privacy settings and notices in these
                                        Third-Party Services to understand what data may be disclosed to us. When a
                                        Third-Party Service is enabled, Teamplus is authorized to connect and access
                                        Other Information made available to Teamplus in accordance with our agreement
                                        with the Third-Party Provider and any permission(s) granted by the Customer
                                        (including by its Authorized User(s). We do not, however, receive or store
                                        passwords for any of these Third-Party Services when connecting them to the
                                        services.
                                    </p>
                                    <p>
                                        Teamplus’s Privacy Policy does not apply to other advertisers or
                                        websites/applications. Thus, we are advising you to consult the respective
                                        Privacy Policies of these third-party ad servers for more detailed information.
                                        It may include their practices and instructions about how to opt out of certain
                                        options.
                                    </p>
                                    <p>
                                        You can choose to disable cookies through your individual browser options. To
                                        know more detailed information about cookie management with specific web
                                        browsers, it can be found at the browsers’ respective websites.
                                    </p>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Contact Information: </span>
                                    <span>
                                        In accordance with the consent process provided by your device or other
                                        third-party API, any contact information that an authorized user chooses to
                                        import (such as an address book from a device or API) is collected when using
                                        the services.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Third-Party Data: </span>
                                    <span>
                                        We may receive data about organizations, industries, lists of companies that are
                                        customers, website visitors, marketing campaigns and other matters related to
                                        our business from parent corporation(s), affiliates and subsidiaries, our
                                        partners or others that we use to make our own information better or more
                                        useful. This data may be combined with other information that we collect and
                                        might include aggregate-level data, such as which IP addresses correspond to
                                        postcodes or countries or it might be more specific: for example, how well an
                                        online marketing or email campaign performed.
                                    </span>
                                </li>
                            </ol>
                        </dd>

                        <dt className={classes.titles}>4. How we use your information</dt>
                        <dd className={classes.description}>
                            <p>We use the information we collect in various ways, including to:</p>
                            <ol type="a" className={classes.alist}>
                                <li>To provide, operate and maintain our website</li>
                                <li>To improve, personalize and expand our website</li>
                                <li>To understand and analyze your interaction with our website</li>
                                <li>
                                    To communicate with you, either directly or through one of our partners, including
                                    for customer service, to provide you with updates and other information relating to
                                    the website, and for marketing and promotional purposes
                                </li>
                                <li>To send you emails and other communications</li>
                                <li>To find and prevent fraud and other security issues and abuse</li>
                                <li>For performance of contract with you and business transfers, if any.</li>
                                <li>
                                    To communicate with you by responding to your requests, comments and redress the
                                    grievances
                                </li>
                                <li>To ask you to participate in the surveys and provide with the product feedbacks</li>
                                <li>As may be required by applicable law, legal process and regulations.</li>
                            </ol>
                        </dd>

                        <dt className={classes.titles}>5. Log Files</dt>
                        <dd className={classes.description}>
                            <p>
                                Teamplus follows a standard procedure of using log files. These files log visitors when
                                they visit websites. All hosting companies do this as part of hosting services’
                                analytics. The information collected by log files include internet protocol (IP)
                                addresses, browser type, Internet Service Provider (ISP), date and time stamp,
                                referring/exit pages, and possibly the number of clicks. These are not linked to any
                                information that is personally identifiable. The purpose of the information is for
                                analyzing trends, administering the site, tracking users’ movement on the website, and
                                gathering demographic information.
                            </p>
                        </dd>

                        <dt className={classes.titles}>6. CCPA Privacy Rights (Do Not Sell My Personal Information)</dt>
                        <dd className={classes.description}>
                            <p>Under the CCPA, among other rights, California consumers have the right to: </p>

                            <ol type="a" className={classes.alist}>
                                <li>
                                    Request that a business that collects a consumer’s personal data disclose the
                                    categories and specific pieces of personal data that a business has collected about
                                    consumers.
                                </li>
                                <li>
                                    Request that a business delete any personal data about the consumer that a business
                                    has collected.
                                </li>
                                <li>
                                    Request that a business that sells a consumer’s personal data, not sell the
                                    consumer’s personal data
                                </li>
                                <li>
                                    Right to notice (it is right to be notified which type of personal data is being
                                    collected)
                                </li>
                                <li>
                                    Right to not being discriminated against for exercising any of your common rights.
                                </li>
                            </ol>

                            <p>Under CCPA, personal information does not include: </p>
                            <ol type="a" className={classes.alist}>
                                <li>Publicly available information from government records</li>
                                <li>Deidentified or aggregated consumer information </li>
                                <li>
                                    Request that a business that sells a consumer&apos;s personal data, not sell the
                                    consumer&apos;s personal data
                                </li>
                                <li>Information excluded from the CCPA’s scope, such as: </li>
                                <ul style={{ marginLeft: 40 }}>
                                    <li>
                                        Health or medical information covered by the Health Insurance Portability and
                                        Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                                        Information Act (CMIA) or clinical trial data
                                    </li>
                                    <li>
                                        Personal Information covered by certain sector-specific privacy laws, including
                                        the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or
                                        California Financial Information Privacy Act (FIPA), and the Driver’s Privacy
                                        Protection Act of 1994
                                    </li>
                                </ul>
                            </ol>
                        </dd>

                        <dt className={classes.titles}>7. General Data Protection Rights</dt>
                        <dd className={classes.description}>
                            <p className={classes.listTitle}>Legal Basis for Processing Personal Data under GDPR: </p>
                            <p>We may process Personal Data under the following conditions: </p>
                            <ol type="a" className={classes.alist}>
                                <li>
                                    <span className={classes.listTitle}>Consent: </span>
                                    <span>
                                        You have given your consent for processing personal data for one or more
                                        specific purposes.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Performance of a contract: </span>
                                    <span>
                                        Provision of personal data is necessary for the performance of an agreement with
                                        you and/or for any pre-contractual obligations thereof.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Legal obligations: </span>
                                    <span>
                                        Processing personal data is necessary for compliance with a legal obligation to
                                        which the company is subject.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Vital interests: </span>
                                    <span>
                                        Processing personal data is necessary in order to protect your vital interests
                                        or of another natural person.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Public interests: </span>
                                    <span>
                                        Processing personal data is related to a task that is carried out in the public
                                        interest or in the exercise of official authority vested in the company.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Legitimate interests: </span>
                                    <span>
                                        Processing personal data is necessary for the purposes of the legitimate
                                        interests pursued by the company.
                                    </span>
                                </li>
                            </ol>

                            <p>
                                In any case, the Company will gladly help to clarify the specific legal basis that
                                applies to the processing, and in particular whether the provision of Personal Data is a
                                statutory or contractual requirement, or a requirement necessary to enter into a
                                contract.
                            </p>
                            <p>
                                We would like to make sure you are fully aware of all of your data protection rights.
                                Every user is entitled to the following:
                            </p>

                            <ol type="a" className={classes.alist}>
                                <li>
                                    <span className={classes.listTitle}>The right to access : </span>
                                    <span>
                                        You have the right to request copies of your personal data. We may charge you a
                                        small fee for this service.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to rectification: </span>
                                    <span>
                                        You have the right to request that we correct any information you believe is
                                        inaccurate. You also have the right to request that we complete the information
                                        you believe is incomplete.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to erasure: </span>
                                    <span>
                                        You have the right to request that we erase your personal data, under certain
                                        conditions.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to restrict processing: </span>
                                    <span>
                                        You have the right to request that we restrict the processing of your personal
                                        data, under certain conditions.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to object to processing: </span>
                                    <span>
                                        You have the right to object to our processing of your personal data, under
                                        certain conditions.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>The right to data portability: </span>
                                    <span>
                                        You have the right to request that we transfer the data that we have collected
                                        to another organization, or directly to you, under certain conditions.
                                    </span>
                                </li>
                            </ol>

                            <p>If you would like to exercise any of these rights, please contact us.</p>
                        </dd>

                        <dt className={classes.titles}>8. Who We Share Information with</dt>
                        <dd className={classes.description}>
                            <ol type="a" className={classes.alist}>
                                <li>
                                    <span className={classes.listTitle}>Employee and Independent Contractor: </span>
                                    <span>
                                        Employees and independent contractors of Teamplus have access to the information
                                        on legitimate requirements on a need-to-know basis. We require all employees and
                                        independent contractors of Teamplus to follow this Privacy Policy for personal
                                        information that we share with them.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>
                                        Information from social media sites and other publicly available sources:{' '}
                                    </span>
                                    <span>
                                        When you interact or engage with us on social media sites such as Linkedin,
                                        Facebook, Twitter, Google+ and Instagram through posts, comments, questions and
                                        other interactions, we may collect such publicly available information,
                                        including profile information, to allow us to connect with you, improve our
                                        products, or better understand user reactions and issues. We must tell you that
                                        once collected, this information may remain with us even if you delete it from
                                        the social media sites. Teamplus may also add and update information about you,
                                        from other publicly available sources.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>
                                        Signups using federated authentication service providers:{' '}
                                    </span>
                                    <span>
                                        You can log in to your account using supported federated authentication service
                                        providers such as Google, LinkedIn. These services will authenticate your
                                        identity and give you the option to share certain personal information with us,
                                        such as your name and email address.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>
                                        Information from application log and mobile analytics:{' '}
                                    </span>
                                    <span>
                                        We collect information about your use of our products, services and mobile
                                        applications from application logs and in-house usage analytics tools, and use
                                        it to understand how your business use and needs can improve our products. This
                                        information includes clicks, scrolls, features accessed, access time and
                                        frequency, errors generated, performance data, storage utilized, user settings
                                        and configurations, and devices used to access and their locations.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Payment Processing: </span>
                                    <span>
                                        When you buy something from us, we ask you to provide your name, contact
                                        information, and credit card information or other payment account information.
                                        When you submit your card information, we store the name and address of the
                                        cardholder, the expiry date and the last four digits of the credit card number.
                                        We do not store the actual credit card number. For quick processing of future
                                        payments, if you have given us your approval, we may store your credit card
                                        information or other payment information in an encrypted format in the secured
                                        servers of our Payment Gateway Service Providers.
                                    </span>
                                </li>
                                <li>
                                    <span className={classes.listTitle}>Legal Obligations and requirements: </span>
                                    <span>
                                        We may disclose information for compliance of the legal obligations and
                                        requirements applicable upon us for the following:
                                    </span>
                                    <ul style={{ marginLeft: 40, marginBottom: 10 }}>
                                        <li>
                                            <span className={classes.listTitle}>
                                                Disclosure in compliance with legal obligations or statutory
                                                requirements:{' '}
                                            </span>
                                            <span>
                                                We may as be required by law preserve or disclose your personal
                                                information and service data to comply with any applicable law,
                                                regulation, legal process or governmental request, including to meet
                                                national security requirements
                                            </span>
                                        </li>
                                        <li>
                                            <span className={classes.listTitle}>Enforcement of our rights: </span>
                                            <span>
                                                We may disclose personal information and service data to a third party
                                                if we believe that such disclosure is necessary for preventing fraud,
                                                investigating any suspected illegal activity, enforcing our agreements
                                                or policies, or protecting the safety of our users
                                            </span>
                                        </li>
                                        <li>
                                            <span className={classes.listTitle}>Business Transfer: </span>
                                            <span>
                                                We do not intend to sell our business. However, in the unlikely event
                                                that we sell our business or get acquired or merged, we will ensure that
                                                the acquiring entity is legally bound to honor our commitments to you.
                                                We will notify you via email or through a prominent notice on our
                                                website of any change in ownership or in the uses of your personal
                                                information and service data. We will also notify you about any choices
                                                you may have regarding your personal information and service data
                                            </span>
                                        </li>
                                        <li>
                                            <span className={classes.listTitle}>Compliance with privacy Policy: </span>
                                            <span>
                                                We make every effort, including periodic reviews, to ensure that
                                                personal information you provide is used in conformity with this Privacy
                                                Policy
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </dd>

                        <dt className={classes.titles}>9. Data Retention</dt>
                        <dd className={classes.description}>
                            <p>
                                We shall retain your personal data only for as long as is necessary for the purposes set
                                out in this PrivacyPolicy. We will retain and use your personal data to the extent
                                necessary to comply with our legal obligations (for example, if we are required to
                                retain your data to comply with applicable laws), resolve disputes, and enforce our
                                legal agreements and policies. The Company will also retain usage data for internal
                                analysis purposes. Usage Data is generally retained for a shorter period of time, except
                                when this data is used to strengthen the security or to improve the functionality of our
                                service, or we are legally obligated to retain this data for longer time periods.
                            </p>
                        </dd>

                        <dt className={classes.titles}>10. Children’s Information</dt>
                        <dd className={classes.description}>
                            <p>
                                Another part of our priority is adding protection for children while using the internet.
                                We encourage parents and guardians to observe, participate in, and/or monitor and guide
                                their online activity.
                            </p>
                            <p>
                                Teamplus does not knowingly collect any Personal Identifiable Information from children
                                under the age of 13. If you think that your child provided this kind of information on
                                our website, we strongly encourage you to contact us immediately and we will do our best
                                efforts to promptly remove such information from our records.
                            </p>
                        </dd>

                        <dt className={classes.titles}>11. Data Security</dt>
                        <dd className={classes.description}>
                            <p>
                                We take security of data very seriously. We practise utmost care and diligence to
                                protect all and other information provided by you from any loss, misuse and unauthorized
                                access or disclosure. Given the nature of communications and information processing
                                technology, we cannot guarantee that information, during transmission through the
                                Internet or while stored on our systems or otherwise in our care, will be absolutely
                                safe from intrusion from others.
                            </p>
                        </dd>

                        <dt className={classes.titles}>12. Change in this Policy</dt>
                        <dd className={classes.description}>
                            <p>
                                We may change this Privacy Policy from time to time. Laws, regulations and industry
                                standards evolve, which may make those changes necessary, or we may make changes to our
                                business. We shall keep you informed about the changes to this policy and encourage us
                                to review Privacy Policy for the same.
                            </p>
                            <p>
                                If we make changes that materially after your privacy rights, we will provide additional
                                notice, such as via email. If you disagree with the changes to the new policy, you may
                                deactivate your service account.
                            </p>
                        </dd>

                        <dt className={classes.titles}>13. Contact us</dt>
                        <dd className={classes.description}>
                            <p>Need assistance?</p>
                            <p>Feel free to contact us at email: support@udyamo.com</p>
                        </dd>
                    </dl>
                </div>
            </div>
        );
    };

    return (
        <div style={{ overflowY: 'hidden' }}>
            <Header />
            <PolicyBlock />
            <Footer />
        </div>
    );
}
