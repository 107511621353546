import { getMSData } from 'helpers/graphapi';

/* eslint-disable no-console */
export async function getTenantDetails(accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch('https://graph.microsoft.com/v1.0/organization', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getTenantUsers(accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch('https://graph.microsoft.com/v1.0/users', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getAllTenantUsers(accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    const url = 'https://graph.microsoft.com/v1.0/users';
    const allUsers = await getMSData(url, options);
    return allUsers;
}

export async function employeeDetails(accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch('https://graph.microsoft.com/v1.0/me', options)
        .then(response => {
            return response.json();
        })
        .catch(error => console.log(error));
}

// export async function employeePicture(accessToken: any) {
//     const headers = new Headers();
//     const bearer = `Bearer ${accessToken}`;

//     headers.append('Authorization', bearer);

//     const options = {
//         method: 'GET',
//         headers: headers,
//     };

//     return fetch('https://graph.microsoft.com/v1.0/me/photo/$value', options)
//         .then(response =>
//             {console.log(response);}
//             )
//         .catch(error => console.log(error));
// }

export async function joinedTeams(accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    const url = 'https://graph.microsoft.com/v1.0/me/joinedTeams';
    const allTeams = await getMSData(url, options);
    return allTeams;

    // return fetch('https://graph.microsoft.com/v1.0/me/joinedTeams', options)
    //     .then(response => response.json())
    //     .catch(error => console.log(error));
}

export async function membersofTeam(accessToken: any, id: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    const url = 'https://graph.microsoft.com/v1.0/groups/' + id + '/members';
    const allMembers = await getMSData(url, options);
    return allMembers;
    // return fetch('https://graph.microsoft.com/v1.0/groups/' + id + '/members', options)
    //     .then(response => response.json())
    //     .catch(error => console.log(error));
}

export async function channelsofTeam(accessToken: any, id: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    const url = 'https://graph.microsoft.com/v1.0/teams/' + id + '/channels';
    const allChannels = await getMSData(url, options);
    return allChannels;
    // return fetch('https://graph.microsoft.com/v1.0/teams/' + id + '/channels', options)
    //     .then(response => response.json())
    //     .catch(error => console.log(error));
}

export async function MembersofChannel(accessToken: any, id: any, id1: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    const url = 'https://graph.microsoft.com/v1.0/teams/' + id + '/channels/' + id1 + '/members';
    const allMembers = await getMSData(url, options);
    return allMembers;
    // return fetch('https://graph.microsoft.com/v1.0/teams/' + id + '/channels/' + id1 + '/members', options)
    //     .then(response => response.json())
    //     .catch(error => console.log(error));
}

export async function ChannelInfo(accessToken: any, id: any, id1: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch('https://graph.microsoft.com/v1.0/teams/' + id + '/channels/' + id1, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
