/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
// import Box from '@material-ui/core/Box';
import Logo from './Layer 1.png';
import LogoWhite from './teamplusLogoWithoutName.png';
// import Admin from './admin.png';
// import Employee from './employee.png';
// import Approver from './approver.png';
// import Notification from './Notification Icon.png';
import Teamslack from './Group 43.png';
import TeamsWhite from './teamplusLogoWhite.png';
import {
    Typography,
    Divider,
    useTheme,
    Menu,
    MenuItem,
    Hidden,
    Avatar,
    FormLabel,
    TextField,
    Popover,
    // Badge,
    Box,
    Collapse,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useSelector, useDispatch } from 'react-redux';
import { ProgressBar } from '../DashboardPage/DashboardPart';
// import IconButton from '@material-ui/core/IconButton';
//import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
// import ErrorPage from '../Error/ErrorPage';
// import { LoaderPage } from '../Loader/loader';
// import { useSelector } from 'react-redux';
// import SettingsIcon from '@material-ui/icons/Settings';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuImage from '../../Assets/images/menu.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useStyles from './HeaderStyles';
import useStyles2 from '../DashboardPage/DashboardStyle';
// import useStyles3 from '../Navbar/NavbarStyle2';
import AuthContext from '../../context/AuthContext';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
// import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
//import AddIcon from '@material-ui/icons/Add';
// import GroupAddIcon from '@material-ui/icons/GroupAdd';
// import PlusOneIcon from '@material-ui/icons/PlusOne';
import { Grid, Button } from '@material-ui/core';
//import TeamPlusLogo from '../../Assets/images/udyamo-teamplus-mobile.png'; //udyamo-teamplus-mobile.png TeamPlus_logo.png
import Modal from '../Modal/Modal';
import Validate from '../../Validator/Validation';
import FormError from '../Error/FormError';
import { PopUp } from '../Modal/Modal';
// import SpeedDial from '@material-ui/lab/SpeedDial';
// import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
// import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
// import AddBoxIcon from '@material-ui/icons/AddBox';
import Switch from 'react-switch';
import { BsFillBrightnessHighFill } from 'react-icons/bs';
import { RiMoonClearFill } from 'react-icons/ri';
import { AiFillCaretRight } from 'react-icons/ai';
import {
    // getSubscriptionDetails,
    // getEmployeeRole,
    getEmployee,
    fetchOrganizationSettings,
    // getSubscriptionExpired,
    fetchAdminNotif,
    // getSlackWorkspace,
    fetchEmpNotif,
    fetchApproverNotif,
    fetchEmpIdFromNotifSeen,
    updateNotifSeen,
    refreshSlackLoginToken,
    refreshMSLoginToken,
    deleteMSOrganization,
    // fetchOrg,
    // deleteOrg,
    // deleteEmployee,
    // deleteTenant,
    // deleteEmployee,
} from '../../helpers/hasuraapi';
import { fetchQuestion } from 'helpers/hasuraapi3';
// import { styled } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import Feedback from './FeedbackModal';
import { Icon } from '@iconify/react';
import { openSidebar, closeSidebar } from '../Redux/sidebarReducer';
import { clearUserStatus } from '../Redux/userStatusReducer';
import Actionmodal from '../TeamMembers/ActionModal';
import { DateDifference } from '../../helpers/date';
// import { sendInvite } from '../../helpers/hasuraapi';
import moment from 'moment';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import { s3Bucket, notificationImage } from '../../helpers/constants';
import TourData from '../QuickTour/quickTour';
// import Tour from 'reactour';
import ReactJoyride, { EVENTS } from 'react-joyride';
import { useSubscription } from '@apollo/client';
import { getNotification } from '../../helpers/subscriptions/notificationSubscription';
// import { useFlagsmith } from 'flagsmith-react';
import { clearReducers } from 'components/Redux/StandupRedux';
import { useMsal } from '@azure/msal-react';

interface HeaderProps {
    mainLink?: string;
    currentLink?: string;
    label?: string;
    title?: string;
    children?: JSX.Element;
    isSmall?: boolean;
    showSnackBar?: boolean;
}

// const StyledBadge = styled(Badge)((theme: any) => ({
//     '& .MuiBadge-badge': {
//         right: 3,
//         top: 3,
//     },
//     '& .MuiBadge-dot': {
//         width: 10,
//         height: 10,
//     },
// }));

interface BadgeProps {
    width: number;
    height: number;
    top: number;
    right: number;
    children: React.ReactNode;
    background: string;
    handleClick: any;
    invisible?: boolean;
}

const StyleBadge = (props: BadgeProps) => {
    return (
        <div
            style={{
                width: 28,
                height: 28,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            }}
            onClick={(e: any) => props.handleClick(e)}
        >
            <div
                style={{
                    backgroundColor: props.background,
                    width: `${props.width}px`,
                    height: `${props.height}px`,
                    borderRadius: '50%',
                    position: 'absolute',
                    top: props.top,
                    right: props.right,
                    display: props.invisible ? 'none' : 'block',
                }}
            ></div>
            {props.children}
        </div>
    );
};
// const VerticalLine = () => {
//     const classes = useStyles();
//     return <div className={classes.vr}></div>;
// };

export default function Header(props: HeaderProps): JSX.Element {
    const classes = useStyles();
    const classes2 = useStyles2();
    const theme: any = useTheme();
    const dispatch = useDispatch();
    const state: any = useSelector((state: any) => state);
    const history = useHistory();
    const adminData: any = TourData('admin');
    const employeeData: any = TourData('employee');
    const [stepIndex, setStepIndex] = useState(0);
    const [stepIndexAdmin, setStepIndexAdmin] = useState(0);
    const [check1, setCheck1] = useState(localStorage.getItem('themeToggle') === 'true' ? true : false);
    // const activeLink = useSelector((state: any) => state.activeTab);
    const mycontext = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
    const [listAnchor, setListAnchor] = useState<null | HTMLElement>(null);
    const [actionAnchor, setActionAnchor] = useState<null | HTMLElement>(null);
    // const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const openMenu2 = Boolean(anchorEl2);

    const [expanded, setExpanded] = React.useState(0);
    const [actionNotifAnchor, setActionNotifAnchor] = useState<null | HTMLElement>(null);
    // const openMenu3 = Boolean(anchorEl3);

    // const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
    // const openMenu1 = Boolean(anchorEl1);
    const [open, setOpen] = useState<boolean>(false);
    const [alert1, setAlert] = useState<boolean>(false);
    const [alert2, setAlert2] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertSeverity, setAlertSeverity] = useState<string>('success');
    const [password, setPassword] = useState<string>('');
    const [newpassword, setNewpassword] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<any>();
    const [emailDone, setEmailDone] = useState<boolean>(false);
    const [cognitoError, setCognitoError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<any>('');
    const [organizations, setOrganizations] = useState([]);
    const [notif, setNotif] = useState([]);
    const [notifSeen, setShowNotifSeen] = useState<boolean>(true);
    const [currentAction, setCurrentAction] = useState({
        isObject: false,
        id: 0,
        action: '',
        time: '',
        content: '',
    });
    const [isTourOpenAdmin, setIsTourOpenAdmin] = useState(false);
    // const [adminTourIndex, setAdminTourIndex] = useState(0);
    // const [employeeTourIndex, setEmployeeTourIndex] = useState(0);
    const [isTourOpenEmployee, setIsTourOpenEmployee] = useState(false);
    const quickTour = () => {
        setIsTourOpenAdmin(true);
    };
    const [isClose, setIsClose] = useState<boolean>(true);
    const [isEmployeeClose, setIsEmployeeClose] = useState<boolean>(true);
    const notifData = useSubscription(
        getNotification(mycontext.organizationId, mycontext.EmployeeId, mycontext.isAdmin, mycontext.isApprover),
    );
    // flagsmith
    // const { hasFeature, logout: flagsmithLogout } = useFlagsmith();
    const { instance } = useMsal();
    React.useMemo(async () => {
        if (!notifData?.loading) {
            const a: any = [];

            if (mycontext.isApprover) {
                setNotif(notifData?.data?.timeoffdb_get_notificationApproverData);
                await notifData?.data?.timeoffdb_get_notificationApproverData.forEach(async (i: any) => {
                    // let notifId = i.notificationId;
                    if (i.Notification.NotificationSeens.length === 0) {
                        //   setNotificationSeens([...notificationSeens, false]);
                        a.push(false);
                    } else {
                        const empId = await fetchEmpIdFromNotifSeen(i.notificationId, mycontext.token);
                        const empIdObjToArr = empId.map((j: any) => {
                            return j.employeeId;
                        });
                        if (empIdObjToArr.includes(mycontext.EmployeeId)) {
                            a.push(true);
                        } else {
                            a.push(false);
                        }
                    }
                    if (a.includes(false)) {
                        setShowNotifSeen(false);
                    }
                });
            } else {
                setNotif(notifData?.data?.timeoffdb_Notifications);
                await notifData?.data?.timeoffdb_Notifications.forEach(async (i: any) => {
                    if (i.NotificationSeens.length === 0) {
                        a.push(false);
                    } else {
                        const empId = await fetchEmpIdFromNotifSeen(i.notificationId, mycontext.token);
                        const empIdObjToArr = empId.map((j: any) => {
                            return j.employeeId;
                        });
                        if (empIdObjToArr.includes(mycontext.EmployeeId)) {
                            a.push(true);
                        } else {
                            a.push(false);
                        }
                    }
                    if (a.includes(false)) {
                        setShowNotifSeen(false);
                    }
                });
            }
        }
    }, [notifData]);

    async function delay(delayInms: any) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(2);
            }, delayInms);
        });
    }
    const handleJoyrideAdminCallback = async (data: any) => {
        const { action, type } = data;
        if (
            action === 'close' ||
            action === 'skip' ||
            action === 'reset' ||
            action === 'stop' ||
            action === 'paused' ||
            type === 'tour:end'
        ) {
            setIsTourOpenAdmin(false);
            setIsClose(true);
            setStepIndexAdmin(0);
            mycontext.firstSignIn({
                initialSignIn: false,
            });
            return;
        }
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            if (action === 'prev' && stepIndexAdmin - 1 === 3) {
                history.push('/teams');
                dispatch(closeSidebar());
                await delay(500);
                setStepIndexAdmin(stepIndexAdmin - 1);
                return;
            }
            if (action === 'prev' && stepIndexAdmin - 1 === 2) {
                history.push('/teams');
                dispatch(openSidebar());
                await delay(500);
                setStepIndexAdmin(stepIndexAdmin - 1);
                return;
            }
            if (action === 'prev' && stepIndexAdmin - 1 === 5) {
                history.push('/employees');
                dispatch(closeSidebar());
                await delay(500);
                setStepIndexAdmin(stepIndexAdmin - 1);
                return;
            }
            if (action === 'prev' && stepIndexAdmin - 1 === 4) {
                history.push('/employees');
                dispatch(openSidebar());
                await delay(500);
                setStepIndexAdmin(stepIndexAdmin - 1);
                return;
            }
            if (action === 'prev' && stepIndexAdmin - 1 === 7) {
                history.push('/settings');
                dispatch(openSidebar());
                await delay(500);
                setStepIndexAdmin(stepIndexAdmin - 1);
                return;
            }
            // if (action === 'prev' && stepIndexAdmin - 1 === 10  ) {
            //     history.push('/settings');
            //     dispatch(closeSidebar());
            //     await delay(500);
            //     setStepIndexAdmin(stepIndexAdmin - 1);
            //     return;
            // }
            if (action === 'prev' && stepIndexAdmin - 1 === 10) {
                history.push('/settings');
                dispatch(closeSidebar());
                await delay(500);
                setStepIndexAdmin(stepIndexAdmin - 1);
                return;
            }
            if (action === 'prev' && stepIndexAdmin - 1 === 0) {
                history.push('/Dashboard');
                setIsTourOpenAdmin(false);
                setIsClose(true);
                await delay(500);
                setIsTourOpenAdmin(true);
                setIsClose(false);
                setStepIndexAdmin(0);
                return;
            }
            if (action === 'prev') {
                setStepIndexAdmin(stepIndexAdmin - 1);
                return;
            }
            if (stepIndexAdmin + 1 === 1) {
                history.push('/dashboard');
                dispatch(openSidebar());
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 2) {
                history.push('/teams');
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 3) {
                dispatch(closeSidebar());
                history.push('/teams');
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 4) {
                dispatch(openSidebar());
                history.push('/employees');
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 5) {
                dispatch(closeSidebar());
                history.push('/employees');
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 6) {
                dispatch(openSidebar());
                history.push('/calender');
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 7) {
                history.push('/settings');
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 8) {
                dispatch(closeSidebar());
                document.getElementById('holidays')?.click();
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 9) {
                document.getElementById('timeOffPolicies')?.click();
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 10) {
                document.getElementById('standups')?.click();
                await delay(500);
            }
            if (stepIndexAdmin + 1 === 11) {
                dispatch(openSidebar());
                history.push('/settings');
                await delay(500);
            }
            // if (stepIndexAdmin + 1 === 11) {
            //     dispatch(openSidebar());
            //     history.push('/plugins');
            //     await delay(500);
            // }
            // if (stepIndexAdmin + 1 === 12) {
            //     history.push('/plugins');
            //     await delay(500);
            // }
            if (stepIndexAdmin + 1 === 13) {
                dispatch(closeSidebar());
                await delay(500);
                setIsTourOpenAdmin(false);
                setIsClose(true);
                setStepIndexAdmin(0);
            } else {
                setStepIndexAdmin(stepIndexAdmin + 1);
            }
        }
    };

    const handleJoyrideEmployeeCallback = async (data: any) => {
        const { action, type } = data;
        if (
            action === 'close' ||
            action === 'skip' ||
            action === 'reset' ||
            action === 'stop' ||
            action === 'paused' ||
            type === 'tour:end'
        ) {
            setIsTourOpenEmployee(false);
            setIsEmployeeClose(true);
            setStepIndex(0);
            mycontext.firstSignIn({
                initialSignIn: false,
            });
            return;
        }
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // const a = stepIndex + (action === 'prev' ? -1 : 1);
            // setStepIndex(a);
            if (action === 'prev' && stepIndex - 1 === 3) {
                history.push('/Dashboard');
                dispatch(closeSidebar());
                await delay(500);
                setStepIndex(stepIndex - 1);
                return;
            }
            if (action === 'prev' && stepIndex - 1 === 1) {
                history.push('/Dashboard');
                dispatch(openSidebar());
                await delay(500);
                setStepIndex(stepIndex - 1);
                return;
            }
            if (action === 'prev' && stepIndex - 1 === 0) {
                history.push('/Dashboard');
                setIsTourOpenEmployee(false);
                setIsEmployeeClose(true);
                await delay(500);
                setIsTourOpenEmployee(true);
                setIsEmployeeClose(false);
                setStepIndex(0);
                return;
            }
            if (action === 'prev') {
                setStepIndex(stepIndex - 1);
                return;
            }
            if (stepIndex === 0) {
                history.push('/dashboard');
            }
            if (stepIndex + 1 === 1) {
                history.push('/dashboard');
                dispatch(openSidebar());
                await delay(500);
            }
            if (stepIndex + 1 === 2) {
                history.push('/dashboard');
                dispatch(closeSidebar());
                await delay(500);
            }
            if (stepIndex + 1 === 4) {
                history.push('/teams');
                dispatch(openSidebar());
                await delay(500);
            }
            if (stepIndex + 1 === 5) {
                history.push('/employees');
                await delay(500);
            }
            if (stepIndex + 1 === 6) {
                history.push('/calender');
                await delay(500);
            }
            if (stepIndex + 1 === 7) {
                dispatch(closeSidebar());
                await delay(500);
                setIsTourOpenEmployee(false);
                setIsEmployeeClose(true);
                setStepIndex(0);
            } else {
                setStepIndex(stepIndex + 1);
            }
        }
    };

    const quickTourEmployee = () => {
        setIsTourOpenEmployee(true);
    };
    let notification: any;
    const notificationSeen: any[] = [];

    useEffect(() => {
        async function fetchAll() {
            if (mycontext.isAdmin === false && mycontext.initialSignIn === true) {
                setIsTourOpenEmployee(mycontext.initialSignIn);
                setIsEmployeeClose(false);
            }
            setIsTourOpenAdmin(mycontext.initialSignIn);
            setIsClose(false);
            mycontext.isAdmin
                ? (notification = await fetchAdminNotif(mycontext.organizationId, mycontext.token))
                : mycontext.isApprover
                ? (notification = await fetchApproverNotif(
                      mycontext.organizationId,
                      mycontext.EmployeeId,
                      mycontext.token,
                  ))
                : (notification = await fetchEmpNotif(mycontext.organizationId, mycontext.EmployeeId, mycontext.token));

            setNotif(notification);

            //console.log('notification: ', notification.length);
            if (notification.length === undefined) {
                return;
            } else {
                mycontext.isApprover
                    ? await notification.forEach(async (i: any) => {
                          // let notifId = i.notificationId;
                          if (i.Notification.NotificationSeens.length === 0) {
                              notificationSeen.push(false);
                          } else {
                              const empId = await fetchEmpIdFromNotifSeen(i.notificationId, mycontext.token);
                              const empIdObjToArr = empId.map((j: any) => {
                                  return j.employeeId;
                              });
                              if (empIdObjToArr.includes(mycontext.EmployeeId)) {
                                  notificationSeen.push(true);
                              } else {
                                  notificationSeen.push(false);
                              }
                          }
                          if (notificationSeen.includes(false)) {
                              setShowNotifSeen(false);
                          }
                      })
                    : await notification.forEach(async (i: any) => {
                          if (i.NotificationSeens.length === 0) {
                              notificationSeen.push(false);
                          } else {
                              const empId = await fetchEmpIdFromNotifSeen(i.notificationId, mycontext.token);
                              const empIdObjToArr = empId.map((j: any) => {
                                  return j.employeeId;
                              });
                              if (empIdObjToArr.includes(mycontext.EmployeeId)) {
                                  notificationSeen.push(true);
                              } else {
                                  notificationSeen.push(false);
                              }
                          }
                          if (notificationSeen.includes(false)) {
                              setShowNotifSeen(false);
                          }
                      });
            }
        }
        fetchAll();
    }, []);

    const handleClickNotif = async (e: any) => {
        setActionNotifAnchor(e.currentTarget);

        //console.log('notif: ', notif?.length);
        if (notif?.length === undefined) {
            return;
        } else {
            mycontext.isApprover
                ? notif.map(async (d: any, i: number) => {
                      if (d.Notification.NotificationSeens.length === 0) {
                          await updateNotifSeen(d.notificationId, mycontext.EmployeeId, mycontext.token);
                      } else {
                          const empId = await fetchEmpIdFromNotifSeen(d.notificationId, mycontext.token);
                          const empIdObjToArr = empId.map((j: any) => {
                              return j.employeeId;
                          });
                          if (empIdObjToArr.includes(mycontext.EmployeeId)) {
                              return;
                          } else {
                              await updateNotifSeen(d.notificationId, mycontext.EmployeeId, mycontext.token);
                          }
                      }
                  })
                : notif.map(async (d: any, i: number) => {
                      if (d.NotificationSeens.length === 0) {
                          await updateNotifSeen(d.notificationId, mycontext.EmployeeId, mycontext.token);
                      } else {
                          const empId = await fetchEmpIdFromNotifSeen(d.notificationId, mycontext.token);
                          const empIdObjToArr = empId.map((j: any) => {
                              return j.employeeId;
                          });
                          if (empIdObjToArr.includes(mycontext.EmployeeId)) {
                              return;
                          } else {
                              await updateNotifSeen(d.notificationId, mycontext.EmployeeId, mycontext.token);
                          }
                      }
                  });
        }

        setShowNotifSeen(true);
    };

    const handleExpandClick = (id: number) => {
        setExpanded(id);
    };

    const handlePendingActions = (e: any) => {
        setAnchorEl2(null);
        setAnchorEl(null);
        setActionAnchor(e.currentTarget);
    };

    const pendingActions = state.pendingActions.actions;
    /* istanbul ignore next */
    const handleSubmit = async () => {
        const state = {
            password: password,
            confirmpassword: newpassword,
            verificationcode: code,
        };
        const error1 = await Validate(state);
        if (error1) {
            setError(error1);
            // setCode('');
            // setPassword('');
            // setNewpassword('');
            // setCognitoError(false);
        } else {
            try {
                await Auth.forgotPasswordSubmit(mycontext.email, code, password);
                setCode('');
                setPassword('');
                setNewpassword('');
                setOpen(!open);
                setEmailDone(!emailDone);
                setCognitoError(false);

                setAlert(true);
            } catch (error) {
                setCognitoError(true);
                setErrorMessage(error?.message);
                setError(false);
            }
        }
    };

    const switchStatus = async () => {
        setCheck1(!check1);
        mycontext.toggleTheme();
    };

    const handleProfile = () => {
        setAnchorEl(null);
        // dispatch({ type: 'PROFILE_ID', payload: mycontext.EmployeeId });
        // mycontext.addUserProfile({id:mycontext.EmployeeId,name:mycontext.fname+' '+mycontext.lname});
        dispatch({ type: 'PROFILE_ID', payload: mycontext.EmployeeId });
        mycontext.addUserProfile(mycontext.EmployeeId);
        // history.push({
        //     pathname: '/user/profile',
        //     state: {
        //         id: mycontext.EmployeeId,
        //         userLink: true
        //     },
        // });
    };
    const handleEmail = async () => {
        const state = {
            email: mycontext.email,
        };
        const error1 = await Validate(state);
        /* istanbul ignore next */
        if (error1) {
            setError(error1);
        } else {
            try {
                await Auth.forgotPassword(mycontext.email);
                setEmailDone(!emailDone);
            } catch (error) {
                alert(error?.message);
            }
        }
    };
    /* istanbul ignore next */
    const handleCancel = () => {
        setEmailDone(!emailDone);
        setOpen(!open);
        setCode('');
        setPassword('');
        setNewpassword('');
        setAlert(false);
        setCognitoError(false);
    };
    // const handlePassword = () => {
    //     setAnchorEl(null);
    //     setOpen(!open);
    // };
    const customSvg = mycontext.themeToggle ? (
        <img src={LogoWhite} alt="teamslacklogo" style={{ width: 20, height: 20 }}></img>
    ) : (
        <img src={Logo} alt="teamslacklogo"></img>
    );
    /* istanbul ignore next */
    const handleOpen = () => {
        setOpen(!open);
        setEmailDone(!emailDone);
        setCode('');
        setPassword('');
        setNewpassword('');
        setCognitoError(false);
    };

    const switchOrganization = async (organization: any) => {
        try {
            dispatch(clearUserStatus());
            dispatch(clearReducers());
            const data: any = await getEmployee(mycontext.EmployeeId, mycontext.token);
            // const obj = {
            //     organizationId: organization.Organization.organizationId,
            //     token: mycontext.token,
            // };
            // const isExpired: any = await getSubscriptionExpired(obj);
            // localStorage.removeItem('invoice');
            // localStorage.removeItem('startDate');
            // localStorage.removeItem('endDate');
            // localStorage.removeItem('category');
            // localStorage.removeItem('leaveType');
            // localStorage.removeItem('startTime');
            // localStorage.removeItem('endTime');
            // localStorage.removeItem('oneday');
            // localStorage.removeItem('reason');
            // localStorage.removeItem('customReason');
            // const status =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].status
            //         : '';
            // const warningDate =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].showWarning
            //         : '';
            // const hardExpireDate =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].hardExpireDate
            //         : '';
            // const subscriptionId =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].subscriptionId
            //         : '';
            const orgSettings: any = await fetchOrganizationSettings(
                organization.Organization.organizationId,
                mycontext.token,
            );
            // mycontext.addSettings(orgSettings?.displayLogo);
            mycontext.addOtherSettings({
                approverCalledAs: orgSettings?.approverCalledAs,
                employeeCalledAs: orgSettings?.employeeCalledAs,
                teamsCalledAs: orgSettings?.teamsCalledAs,
                workingHours: orgSettings?.workingHours,
            });
            mycontext.updateDateFormat(orgSettings.dateFormat);
            const standup: any = await fetchQuestion(organization.Organization.organizationId, mycontext.token);
            mycontext.addStandupTempId(standup?.standupTempId);
            // mycontext.updateSubscriptionExpired(status);
            // mycontext.updateWarningDate(warningDate);
            // mycontext.updateHardExpireDate(hardExpireDate);
            // mycontext.updateSubscriptionId(subscriptionId);
            mycontext.login({
                username: data.email,
                EmployeeId: data.employeeId,
                fname: data.firstName,
                lname: data.lastName,
                isAdmin: organization.role.toLowerCase() === 'admin'.toLowerCase() ? true : false,
                isApprover: organization.role.toLowerCase() === 'approver'.toLowerCase() ? true : false,
                isLogedIn: true,
                joining: data.joining,
                profile: data.profileImage,
                // teamname: data.teamname,
                statustype: data.status,
                email: data.email,
                isGoogle: false,
                token: mycontext.token,
                refreshToken: mycontext.refreshToken,
                uuid: mycontext.EmployeeId,
                // subscriptionExpired: status,
                // warningDate: warningDate,
                // hardExpireDate: hardExpireDate,
                // subscriptionId: subscriptionId,
                standupTempId: standup?.standupTempId,
                tenantTableId: mycontext.tenantTableId,
                isSuperAdmin: data?.tenant_employees[0]?.role === 'superadmin' ? true : false,
            });
            mycontext.addOrganizationId(organization.Organization.organizationId);
            mycontext.addOrganizationName(organization.Organization.organizationName);
            // mycontext.addTenantName(organization.Organization?.Tenant?.name);
            // mycontext.addTenantId(organization.Organization?.Tenant?.id);
            mycontext.fetchAllData();
            if (history.location?.pathname === '/dashboard') {
                history.push('/settings');
            } else {
                history.push('/dashboard');
            }
        } catch (e) {
            return e;
        }
    };

    const updateRefreshToken = async () => {
        const t: any = jwt_decode(mycontext.token);
        if (t.isMicrosoft) {
            try {
                const res = await refreshMSLoginToken(localStorage.getItem('refreshToken'));
                if (res.refresh_token === undefined || res.token === undefined) {
                    mycontext.logout();

                    setTimeout(() => {
                        history.push('/login');
                    }, 2000);
                    instance.logout();
                } else {
                    mycontext.addRefreshToken(res.refresh_token, res.token);
                }
            } catch (e) {
                mycontext.logout();
                setTimeout(() => {
                    history.push('/login');
                }, 2000);
                instance.logout();
            }
        } else {
            try {
                const res = await refreshSlackLoginToken(localStorage.getItem('refreshToken'));
                if (res.refresh_token === undefined || res.token === undefined) {
                    Auth.signOut();
                    mycontext.logout();
                    setTimeout(() => {
                        history.push('/login');
                    }, 2000);
                } else {
                    mycontext.addRefreshToken(res.refresh_token, res.token);
                }
            } catch (e) {
                Auth.signOut();
                mycontext.logout();
                setTimeout(() => {
                    history.push('/login');
                }, 2000);
            }
        }
    };
    useEffect(() => {
        const t: any = jwt_decode(mycontext.token);
        // //console.log(t.exp, t.iat, new Date(t.iat * 1000), new Date(t.exp * 1000));
        // //console.log('Is expired ', new Date(t.exp * 1000).getTime() < new Date().getTime());
        // //console.log('time difference ', new Date(new Date(t.exp * 1000)).getTime() - new Date().getTime() - 60 * 1000);
        const interval = setInterval(
            () => {
                updateRefreshToken();
            },
            new Date(new Date(t.exp * 1000)).getTime() - new Date().getTime() - 60 * 60 * 1000 > 0
                ? new Date(new Date(t.exp * 1000)).getTime() - new Date().getTime() - 60 * 60 * 1000
                : 60 * 60 * 1000,
        );
        // const interval = setInterval(() => {
        //     updateRefreshToken();
        // }, 15000);
        // const interval = setInterval(
        //     () => {
        //         updateRefreshToken();
        //     },
        //     new Date(new Date(t.exp * 1000)).getTime() - new Date().getTime() - 1 * 60 * 1000 > 0
        //         ? new Date(new Date(t.exp * 1000)).getTime() - new Date().getTime() - 1 * 60 * 1000
        //         : 4 * 60 * 1000,
        // );
        const getOrganizations = async () => {
            if (state.myorganizations.status === 'success') {
                setOrganizations(state.myorganizations.organizations);
            }
        };
        getOrganizations();
        return () => clearInterval(interval);
    }, [state.myorganizations.status]);

    const PendingActionsData = [
        {
            id: 1,
            action: 'Add organisation details',
            time: '',
            isComplete: pendingActions?.organizationDetails,
            content:
                'To complete your organisations profile please add relevant details and get started with TeamPlus.',
        },
        {
            id: 2,
            action: 'Update Time-off Policy',
            time: '',
            isComplete: pendingActions?.timeOffPolicy,
            content:
                'Set standards for time-off management for your organization by adding details about types of leaves, total number of leaves and more.',
        },
        // {
        //     id: 3,
        //     action: 'Invite Employees',
        //     time: '',
        //     isComplete: pendingActions?.inviteEmployees,
        //     content:
        //         'Add your team to TeamPlus by manually adding their Email Ids, uploading a list of your team members or integrating with Slack.',
        // },
        {
            id: 3,
            action: 'Create new team',
            time: '',
            isComplete: pendingActions?.createNewTeam,
            content:
                'Divide your employees into their respective teams and assign approvers for each team to make time-off management easier for your organization.',
        },
        // {
        //     id: 4,
        //     action: 'Integrate with slack',
        //     time: '',
        //     isComplete: pendingActions?.integrateWithSlack,
        //     content:
        //         'Integrate TeamPlus with Slack to allow apply for, approve and manage time-offs directly from Slack.',
        // },
        // {
        //     id: 5,
        //     action: 'Integrate with ms teams',
        //     time: '',
        //     isComplete: pendingActions?.integrateWithTeams,
        //     content:
        //         'Integrate TeamPlus with ms teams to allow apply for, approve and manage time-offs directly from Ms Teams.',
        // },
    ];

    const resetCurrentAction = () => {
        setCurrentAction({
            isObject: false,
            id: 0,
            action: '',
            time: '',
            content: '',
        });
    };

    const data = PendingActionsData.filter((d: any) => d.isComplete === false);
    const percentage: number = ((6 - data.length) / 6) * 100;

    /* istanbul ignore next */
    const PendingActions = () => {
        if (state.pendingActions.status === 'loading') {
            return (
                <div className={classes2.pendingActionMain}>
                    <Skeleton variant="text" height={40}></Skeleton>
                </div>
            );
        }
        if (state.pendingActions.status === 'failed') {
            return <h6>Server is down! Please wait for some time!</h6>;
        }
        return (
            <>
                <div className={classes2.pendingActionMain}>
                    <ProgressBar percentage={percentage} />
                    {/* <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.remindMeBtn}
                        onClick={() => setOpenB(false)}
                    >
                        Remind me Later
                    </Button> */}
                </div>
                <div className={classes2.scrollModal} style={{ height: data.length === 0 ? 'auto' : 200 }}>
                    {data.length === 0 ? (
                        <div style={{ width: '100%', padding: '20px 0', textAlign: 'center' }}>
                            <p className={classes.noFoundText}>No Pending Actions Found</p>
                        </div>
                    ) : (
                        PendingActionsData.map((d: any, i: number) => (
                            <div key={i} className={classes2.pendingAction}>
                                {!d.isComplete && (
                                    <>
                                        <div
                                            style={{
                                                padding: '10px 3px',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                handleExpandClick(d.id);
                                                setCurrentAction({
                                                    isObject: true,
                                                    id: d.id,
                                                    action: d.action,
                                                    time: d.time,
                                                    content: d.content,
                                                });
                                            }}
                                            // onClick={() =>
                                            //     setCurrentAction({
                                            //         isObject: true,
                                            //         id: d.id,
                                            //         action: d.action,
                                            //         time: d.time,
                                            //         content: d.content,
                                            //     })
                                            // }
                                        >
                                            <div>
                                                <h6
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 700,
                                                        color:
                                                            expanded === d.id
                                                                ? theme.palette.text.secondary
                                                                : theme.palette.text.primary,
                                                        fontFamily: theme.typography.fontFamily,
                                                    }}
                                                >
                                                    {d.action}
                                                </h6>
                                                {/* <span style={{ fontSize: 14, color: '#AAAAAA' }}>{d.time}</span> */}
                                            </div>
                                            {expanded !== 0 && d.id === currentAction.id ? null : (
                                                <ExpandMoreIcon
                                                    onClick={() => {
                                                        handleExpandClick(d.id);
                                                        setCurrentAction({
                                                            isObject: true,
                                                            id: d.id,
                                                            action: d.action,
                                                            time: d.time,
                                                            content: d.content,
                                                        });
                                                    }}
                                                />
                                            )}
                                        </div>

                                        {d.id === expanded
                                            ? null
                                            : i === PendingActionsData.length - 1 || (
                                                  <div
                                                      style={{
                                                          background: '#d9dbe5',
                                                          height: 1,
                                                          width: '100%',
                                                          alignSelf: 'center',
                                                      }}
                                                  ></div>
                                              )}
                                        <Collapse in={expanded === d.id ? true : false} timeout="auto" unmountOnExit>
                                            <PendingActionDetails />
                                        </Collapse>
                                    </>
                                )}
                            </div>
                        ))
                    )}
                </div>
            </>
        );
    };
    const handleLogout = async () => {
        const decoded: any = jwt_decode(mycontext.token);

        if (decoded.isMicrosoft) {
            try {
                mycontext.logout();
                // await flagsmithLogout();
                setTimeout(() => {
                    history.push('/login');
                }, 2000);
                await instance.logoutRedirect().catch(e => {
                    // //console.error(e);
                });
            } catch (e) {
                mycontext.logout();
                setTimeout(() => {
                    history.push('/login');
                }, 2000);
            }
        } else {
            try {
                await Auth.signOut();
                mycontext.logout();
                // await flagsmithLogout();
                setTimeout(() => {
                    history.push('/login');
                }, 2000);
            } catch (e) {
                mycontext.logout();
                setTimeout(() => {
                    history.push('/login');
                }, 2000);
            }
        }
    };

    const PendingActionDetails = () => {
        return (
            <div>
                <p style={{ fontFamily: theme.typography.fontFamily }}>{currentAction.content}</p>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 20,
                        marginBottom: 10,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            resetCurrentAction();
                            setExpanded(0);
                        }}
                        className={classes.deleteButton}
                    >
                        Dismiss
                    </Button>
                    {currentAction.id.toString() === '1' && (
                        <Link
                            to={{
                                pathname: '/settings',
                                state: { value: 0 },
                            }}
                            style={{ color: 'black', textDecoration: 'none' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.createBtn}
                                onClick={() => {
                                    resetCurrentAction();
                                    setExpanded(0);
                                    setActionAnchor(null);
                                }}
                            >
                                Continue
                            </Button>
                        </Link>
                    )}
                    {currentAction.id.toString() === '2' && (
                        <Link
                            to={{
                                pathname: '/settings',
                                state: { value: 2 },
                            }}
                            style={{ color: 'black', textDecoration: 'none' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.createBtn}
                                onClick={() => {
                                    resetCurrentAction();
                                    setExpanded(0);
                                    setActionAnchor(null);
                                }}
                            >
                                Continue
                            </Button>
                        </Link>
                    )}
                    {/* {currentAction.id.toString() === '3' && (
                        <Link
                            to={{
                                pathname: '/employees',
                                state: { value: 3 },
                            }}
                            style={{ color: 'black', textDecoration: 'none' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.createBtn}
                                onClick={() => {
                                    resetCurrentAction();
                                    setExpanded(0);
                                    setActionAnchor(null);
                                }}
                            >
                                Continue
                            </Button>
                        </Link>
                    )} */}
                    {currentAction.id.toString() === '3' && (
                        <Link
                            to={{
                                pathname: '/teams',
                                state: { value: 1 },
                            }}
                            style={{ color: 'black', textDecoration: 'none' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.createBtn}
                                onClick={() => {
                                    resetCurrentAction();
                                    setExpanded(0);
                                    setActionAnchor(null);
                                }}
                            >
                                Continue
                            </Button>
                        </Link>
                    )}
                    {currentAction.id.toString() === '4' && (
                        <Link
                            to={{
                                pathname: '/plugins',
                                state: { value: 3 },
                            }}
                            style={{ color: 'black', textDecoration: 'none' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.createBtn}
                                onClick={() => {
                                    resetCurrentAction();
                                    setExpanded(0);
                                    setActionAnchor(null);
                                }}
                            >
                                Continue
                            </Button>
                        </Link>
                    )}
                    {currentAction.id.toString() === '5' && (
                        <Link
                            to={{
                                pathname: '/plugins',
                                state: { value: 3 },
                            }}
                            style={{ color: 'black', textDecoration: 'none' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.createBtn}
                                onClick={() => {
                                    resetCurrentAction();
                                    setExpanded(0);
                                    setActionAnchor(null);
                                }}
                            >
                                Continue
                            </Button>
                        </Link>
                    )}
                </div>
                <div
                    style={{
                        background: '#d9dbe5',
                        height: 1,
                        width: '100%',
                        alignSelf: 'center',
                    }}
                ></div>
            </div>
        );
    };

    const NotificationModal = () => {
        return (
            <>
                {/* /* {notif.length === undefined ?
                    <div className={classes.notfoundDiv}>
                        <img src={upcomingTimeoff} alt="No notifications found." className={classes.notfoundImage} />
                        <Typography className={classes.notFoundText}>No Notifications Found.</Typography>
                    </div>
                    : */}
                {notif?.length === undefined || notif?.length === 0 ? (
                    <div className={classes.notfoundDiv}>
                        <img src={upcomingTimeoff} alt="No notifications found." className={classes.notfoundImage} />
                        <Typography className={classes.notFoundText}>No notifications found.</Typography>
                    </div>
                ) : (
                    notif.map((d: any, i: number) => {
                        // //console.log('approverId: ', d.approverId)
                        if (d.approverId === null) {
                            return (
                                <List key={i} style={{ padding: 0, margin: 0 }}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar src={s3Bucket + notificationImage.autoApprove} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={d.notification}
                                            secondary={
                                                DateDifference(d.leaveAppliedOn.split('T')[0], new Date()) > 4
                                                    ? d.leaveAppliedOn.split('T')[0]
                                                    : moment(
                                                          new Date(
                                                              `${d.leaveAppliedOn.split('T')[0]} ${
                                                                  d.leaveAppliedOn.split('T')[1]
                                                              }`,
                                                          ),
                                                      )
                                                          .startOf('second')
                                                          .fromNow()
                                            }
                                        />
                                    </ListItem>
                                    <Divider
                                        style={{
                                            borderBottom: i === notif.length - 1 ? 'none' : '1px solid #d4d4d4',
                                        }}
                                    />
                                </List>
                            );
                        } else {
                            return (
                                <List key={i} style={{ padding: 0, margin: 0 }}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar src={d.Employee.profileImage} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={d.notification}
                                            secondary={
                                                DateDifference(d.leaveAppliedOn.split('T')[0], new Date()) > 4
                                                    ? d.leaveAppliedOn.split('T')[0]
                                                    : moment(
                                                          new Date(
                                                              `${d.leaveAppliedOn.split('T')[0]} ${
                                                                  d.leaveAppliedOn.split('T')[1]
                                                              }`,
                                                          ),
                                                      )
                                                          .startOf('second')
                                                          .fromNow()
                                            }
                                        />
                                    </ListItem>
                                    <Divider
                                        style={{
                                            borderBottom: i === notif.length - 1 ? 'none' : '1px solid #d4d4d4',
                                        }}
                                    />
                                </List>
                            );
                        }
                    })
                )}
            </>
        );
    };

    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const toggle = () => {
        setModal(!modal);
    };

    const handleSendFeedback = async () => {
        setModal(false);
    };

    const toggle1 = () => {
        setModal1(!modal1);
    };

    const handleDeleteOrg = async () => {
        const deletedData: any = await deleteMSOrganization(mycontext.organizationId, mycontext.token);

        if (!deletedData.errors) {
            toggle1();
            setAlert2(true);
            setAlertSeverity('success');
            setAlertMessage('Department deleted successfully!');
            mycontext.logout();
            setTimeout(() => {
                history.push('/login');
            }, 2000);
            instance.logout();
        } else {
            toggle1();
            setAlert2(true);
            setAlertSeverity('error');
            setAlertMessage('Something went wrong while deleting department!');
        }
    };

    // const delTenant = async () => {
    //     try {
    //         const org = await fetchOrg(mycontext.tenantTableId, mycontext.token);
    //         //console.log(org);
    //         await org.map(async (val: any) => {
    //             //console.log('is', val.organizationId);
    //             await deleteMSOrganization(val.organizationId, mycontext.token);
    //         });
    //         const del = await deleteTenant(mycontext.tenantTableId, mycontext.token);
    //         //console.log(del);
    //         await del.delete_usermanagementdb_tenant_employee.returning.map(async (val: any, i: number) => {
    //             if (mycontext.EmployeeId !== val.employeeId) {
    //                 //console.log(i++);
    //                 await deleteEmployee(val.employeeId, mycontext.token);
    //             }
    //         });
    //         await deleteOrg(mycontext.tenantTableId, mycontext.token);
    //         await deleteEmployee(mycontext.EmployeeId, mycontext.token);
    //         // if (!deletedData.errors) {
    //         // toggle1();
    //         // setAlert2(true);
    //         // setAlertSeverity('success');
    //         // setAlertMessage('Department deleted successfully!');
    //         // mycontext.logout();
    //         // setTimeout(() => {
    //         //     history.push('/login');
    //         // }, 20000);
    //         // instance.logout();
    //         // } else {
    //         //     toggle1();
    //         //     setAlert2(true);
    //         //     setAlertSeverity('error');
    //         //     setAlertMessage('Something went wrong while deleting department!');
    //         // }
    //     } catch (err) {
    //         return err;
    //     }
    // };

    return (
        <>
            <PopUp open={alert1} setOpen={setAlert} message="Password updated successfuly!" severity="success" />
            <PopUp open={alert2} setOpen={setAlert2} message={alertMessage} severity={alertSeverity} />
            <Modal open={open} setOpen={handleOpen} onModalClosed={() => setOpen(!open)}>
                {emailDone ? (
                    <>
                        <Grid container data-testid="grid">
                            {cognitoError ? (
                                <Typography className={classes.error}>{errorMessage}</Typography>
                            ) : (
                                <FormError formerrors={error} />
                            )}
                            <Grid container style={{ margin: '10px 0px' }}>
                                <Grid item xs={12} sm={12}>
                                    <FormLabel id="otp" className={classes.label}>
                                        Verify OTP
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="outlined-size-small"
                                        placeholder="Please Enter the verification code"
                                        value={code}
                                        variant="outlined"
                                        onChange={/* istanbul ignore next */ e => setCode(e.target.value)}
                                        size="small"
                                        className={classes.textfield}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={{ margin: '10px 0px' }}>
                                <Grid item xs={12} sm={12}>
                                    <FormLabel className={classes.label}>New Password</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="password"
                                        type="password"
                                        placeholder="New Password"
                                        value={password}
                                        variant="outlined"
                                        onChange={/* istanbul ignore next */ e => setPassword(e.target.value)}
                                        size="small"
                                        className={classes.textfield}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={{ margin: '10px 0px' }}>
                                <Grid item xs={12} sm={12}>
                                    <FormLabel className={classes.label}>Verify New Password</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="confirmPassword"
                                        type="password"
                                        placeholder="Verify New Password"
                                        value={newpassword}
                                        variant="outlined"
                                        onChange={/* istanbul ignore next */ e => setNewpassword(e.target.value)}
                                        size="small"
                                        className={classes.textfield}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={/* istanbul ignore next */ () => handleSubmit()}
                                    className={classes.btn}
                                    id="updatepassword"
                                >
                                    Update
                                </Button>
                                <Button
                                    id="cancelbtn"
                                    variant="outlined"
                                    color="primary"
                                    style={{ textTransform: 'capitalize', fontSize: 14, letterSpacing: 1 }}
                                    onClick={/* istanbul ignore next */ () => handleCancel()}
                                    className={classes.btnOutlined}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Typography className={classes.text}>
                            Please click on send button to get OTP to change password.
                        </Typography>
                        <Grid container style={{ margin: '20px 0px' }}>
                            <Grid item xs={12} sm={12}>
                                <FormLabel className={classes.label}>Email</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="outlined-size-small"
                                    type="Email"
                                    placeholder="Verify New Password"
                                    value={mycontext.email}
                                    variant="outlined"
                                    size="small"
                                    className={classes.disabledTextfield}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEmail()}
                                className={classes.btn}
                                id="sendemail"
                            >
                                Send
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ textTransform: 'capitalize', fontSize: 14, letterSpacing: 1 }}
                                onClick={() => setOpen(!open)}
                                className={classes.btnOutlined}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </>
                )}
            </Modal>
            <Feedback open={modal} toggle={toggle} handleCancel={handleSendFeedback} />
            <Actionmodal
                id="deleteOrgAction"
                title="Delete Department"
                open={modal1}
                toggle={toggle1}
                bodycontent={[`Are you sure you want to delete`, mycontext.organizationName, `department?`]}
                primarybutton="Yes"
                handleSubmit={handleDeleteOrg}
                secondarybutton="No"
            />
            <div className={classes.header}>
                <div className={classes.headerLeft}>
                    {/* <h4 className={classes.title}>TeamPlus</h4> */}
                    <Hidden mdDown>
                        {mycontext.themeToggle ? (
                            <img
                                data-testid="teamPlusImg"
                                src={TeamsWhite}
                                alt="Teamslack"
                                className={classes.teamslack2}
                                onClick={() => history.push('/dashboard')}
                                style={{ cursor: 'pointer' }}
                            />
                        ) : (
                            <img
                                data-testid="teamPlusImg"
                                src={Teamslack}
                                alt="Teamslack"
                                className={classes.teamslack}
                                onClick={() => history.push('/dashboard')}
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                        <Divider orientation="vertical" color="blue" className={classes.divider} />
                        {mycontext.settings === true ? (
                            <h6 className={classes.companyname}>
                                <img src={mycontext.organizationLogo} alt="" className={classes.orgLogo} /> :{' '}
                                {mycontext.organizationName}
                            </h6>
                        ) : (
                            <h6 className={classes.companyname}>
                                {mycontext.tenantName} : {mycontext.organizationName}
                            </h6>
                        )}
                    </Hidden>
                    <Hidden lgUp>
                        <img
                            src={MenuImage}
                            alt=""
                            style={{ height: 26, maxWidth: '100%' }}
                            onClick={() => dispatch(openSidebar())}
                            id="hamburger"
                        />
                        <div style={{ flex: 0.7, padding: '0px 10px', display: 'flex' }}>
                            {/* {mycontext.settings === true ? (<img src={mycontext.organizationLogo} alt="" className={classes.orgLogo} />) : (<h6 className={classes.companyname}>{mycontext.tenantName} : {mycontext.organizationName}</h6>)} */}
                            {mycontext.settings === true ? (
                                <h6 className={classes.companyname}>
                                    <img src={mycontext.organizationLogo} alt="" className={classes.orgLogo} /> :{' '}
                                    {mycontext.organizationName}
                                </h6>
                            ) : (
                                <h6 className={classes.companyname}>
                                    {mycontext.tenantName} : {mycontext.organizationName}
                                </h6>
                            )}
                        </div>
                    </Hidden>
                </div>
                <div className={classes.headerRight}>
                    <Hidden mdDown>
                        {mycontext.isAdmin && (
                            <Button
                                variant="outlined"
                                className={classes.addmore}
                                startIcon={customSvg}
                                onClick={(e: any) => {
                                    setActionAnchor(null);
                                    setAnchorEl(null);
                                    setAnchorEl2(e.currentTarget);
                                }}
                                disableRipple={true}
                            >
                                {' '}
                                Add More{' '}
                            </Button>
                        )}
                    </Hidden>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl2}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={openMenu2}
                        onClose={() => {
                            setAnchorEl2(null);
                        }}
                        classes={{ paper: classes.menuPaperAddmore }}
                        style={{
                            zIndex: 999999,
                            padding: 20,
                            fontFamily: 'Nunito Sans',
                            fontStyle: 'normal',
                            fontSize: '16px',
                        }}
                    >
                        <Link to="/employees" style={{ textDecoration: 'none' }} onClick={() => setAnchorEl2(null)}>
                            <MenuItem className={classes.addMoreMenu} id="admin">
                                {/* <img src={Admin} alt="" className={classes.addMoreImage} /> */}
                                {mycontext.themeToggle ? (
                                    <Icon
                                        icon="clarity:administrator-line"
                                        color="white"
                                        width="24"
                                        height="24"
                                        style={{ marginRight: 10 }}
                                    />
                                ) : (
                                    <Icon
                                        icon="clarity:administrator-line"
                                        color="#888888"
                                        width="24"
                                        height="24"
                                        style={{ marginRight: 10 }}
                                    />
                                )}
                                Admin
                            </MenuItem>
                        </Link>
                        <Link to="/teams" style={{ textDecoration: 'none' }} onClick={() => setAnchorEl2(null)}>
                            <MenuItem className={classes.addMoreMenu} id="approver">
                                {mycontext.themeToggle ? (
                                    <Icon
                                        icon="carbon:user-admin"
                                        color="white"
                                        width="24"
                                        height="24"
                                        style={{ marginRight: 10 }}
                                    />
                                ) : (
                                    <Icon
                                        icon="carbon:user-admin"
                                        color="#888888"
                                        width="24"
                                        height="24"
                                        style={{ marginRight: 10 }}
                                    />
                                )}
                                Approver
                            </MenuItem>
                        </Link>
                        <Link to="/employees" style={{ textDecoration: 'none' }} onClick={() => setAnchorEl2(null)}>
                            <MenuItem className={classes.addMoreMenu} id="employee">
                                {mycontext.themeToggle ? (
                                    <Icon
                                        icon="clarity:group-line"
                                        color="white"
                                        width="24"
                                        height="24"
                                        style={{ marginRight: 10 }}
                                    />
                                ) : (
                                    <Icon
                                        icon="clarity:group-line"
                                        color="#888888"
                                        width="24"
                                        height="24"
                                        style={{ marginRight: 10 }}
                                    />
                                )}
                                Employee
                            </MenuItem>
                        </Link>
                        {/* <Link
                            to={{
                                pathname: '/plugins',
                                state: { value: 3 },
                            }}
                            style={{ textDecoration: 'none' }}
                            onClick={() => setAnchorEl2(null)}
                        >
                            <MenuItem className={classes.addMoreMenu} id="addtoslack">
                                <span
                                        className="iconify"
                                        data-icon="ph:slack-logo"
                                        data-width="22"
                                        data-height="22"
                                        style={{
                                            marginRight: 10,
                                            color: `${theme.palette.primary.contrastText}`,
                                        }}
                                    ></span>
                                {mycontext.themeToggle ? (
                                    <Icon
                                        icon="ph:slack-logo-light"
                                        color="white"
                                        width="24"
                                        height="24"
                                        style={{ marginRight: 10 }}
                                    />
                                ) : (
                                    <Icon
                                        icon="ph:slack-logo-light"
                                        color="#888888"
                                        width="24"
                                        height="24"
                                        style={{ marginRight: 10 }}
                                    />
                                )}
                                Add To Slack
                            </MenuItem>
                        </Link> */}
                    </Menu>
                    {/* <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl3}
                            getContentAnchorEl={null}
                            keepMounted
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={openMenu3}
                            onClose={() => setAnchorEl3(null)}
                            classes={{ paper: classes.menuPaper3 }}
                            style={{
                                zIndex: 999999,
                                padding: 20,
                                fontFamily: 'Nunito Sans',
                                fontStyle: 'normal',
                                fontSize: '16px',
                            }}
                        >
                            <MenuItem>demo</MenuItem>
                        </Menu> */}
                    <Hidden mdDown>
                        {mycontext.isAdmin && <Divider orientation="vertical" className={classes.divider} />}
                    </Hidden>
                    {mycontext.isAdmin && (
                        <StyleBadge
                            // badgeContent={2}
                            // color="error"
                            // variant="dot"
                            // invisible={data.length === 0 ? true : false}
                            invisible={data.length === 0 ? true : false}
                            width={10}
                            height={10}
                            top={-3}
                            right={-2}
                            background="red"
                            handleClick={(e: any) => handlePendingActions(e)}
                        >
                            {/* <span
                                    className="iconify"
                                    style={{
                                        color: theme.palette.text.secondary,
                                        cursor: 'pointer',
                                    }}
                                    data-icon="ic:baseline-pending-actions"
                                    data-width="30"
                                    data-height="30"
                                ></span> */}
                            {mycontext.themeToggle ? (
                                <Icon
                                    icon="ic:outline-pending-actions"
                                    color="white"
                                    width={28}
                                    height={28}
                                    style={{ cursor: 'pointer' }}
                                />
                            ) : (
                                <Icon
                                    icon="ic:outline-pending-actions"
                                    color="#3C48B5"
                                    width={28}
                                    height={28}
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                            {/* {mycontext.themeToggle ? (
                                    <span
                                        className="iconify"
                                        style={{
                                            color: 'white',
                                            cursor: 'pointer',
                                        }}
                                        data-icon="ic:baseline-pending-actions"
                                        data-width="30"
                                        data-height="30"
                                    ></span>
                                ) : (
                                    <span
                                        className="iconify"
                                        style={{
                                            color: '#3C48B5',
                                            cursor: 'pointer',
                                        }}
                                        data-icon="ic:baseline-pending-actions"
                                        data-width="30"
                                        data-height="30"
                                    ></span>
                                )} */}
                        </StyleBadge>
                    )}
                    <Hidden mdDown>
                        {mycontext.isAdmin && <Divider orientation="vertical" className={classes.divider} />}
                    </Hidden>
                    <h6>{mycontext.location?.country}</h6>
                    {notifSeen === true ? (
                        <div className={classes.notifIcon}>
                            <StyleBadge
                                width={10}
                                height={10}
                                top={1}
                                right={1}
                                background="transparent"
                                handleClick={(e: any) => handleClickNotif(e)}
                            >
                                {mycontext.themeToggle ? (
                                    <Icon
                                        icon="clarity:notification-line"
                                        color="white"
                                        width={28}
                                        height={28}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : (
                                    <Icon
                                        icon="clarity:notification-line"
                                        color="#3C48B5"
                                        width={28}
                                        height={28}
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                            </StyleBadge>
                        </div>
                    ) : (
                        <div className={classes.notifIcon}>
                            <StyleBadge
                                width={10}
                                height={10}
                                top={1}
                                right={1}
                                background="red"
                                handleClick={(e: any) => handleClickNotif(e)}
                            >
                                {mycontext.themeToggle ? (
                                    <Icon
                                        icon="clarity:notification-line"
                                        color="white"
                                        width={28}
                                        height={28}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : (
                                    <Icon
                                        icon="clarity:notification-line"
                                        color="#3C48B5"
                                        width={28}
                                        height={28}
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                            </StyleBadge>
                        </div>
                    )}

                    <Hidden mdDown>{<Divider orientation="vertical" className={classes.divider} />}</Hidden>
                    <Hidden mdDown>
                        <div
                            className={classes.userDetail}
                            onClick={(e: any) => {
                                setActionAnchor(null);
                                setAnchorEl2(null);
                                setAnchorEl(e.currentTarget);
                            }}
                        >
                            <Avatar src={mycontext.profile} className={classes.avatar} data-testid="avatar" />
                            {/* <ExpandMoreIcon className={classes.icon} aria-controls="menu-appbar" aria-haspopup="true" /> */}
                            {/* <Typography className={classes.email} id="email" data-testid="email">
                                {mycontext.email}{' '}
                            </Typography> */}
                        </div>
                    </Hidden>
                    <Hidden lgUp>
                        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                            {mycontext.isAdmin && (
                                <StyledBadge
                                    badgeContent={2}
                                    color="error"
                                    variant="dot"
                                    invisible={data.length === 0 ? true : false}
                                    onClick={(e: any) => {
                                        setAnchorEl2(null);
                                        setAnchorEl(null);
                                        setActionAnchor(e.currentTarget);
                                    }}
                                >
                                    {mycontext.themeToggle ? (
                                        <Icon
                                            icon="ic:outline-pending-actions"
                                            color="white"
                                            width={28}
                                            height={28}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    ) : (
                                        <Icon
                                            icon="ic:outline-pending-actions"
                                            color="#3C48B5"
                                            width={28}
                                            height={28}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                    {/* {mycontext.themeToggle ? (
                                        <span
                                            className="iconify"
                                            style={{
                                                color: 'white',
                                                cursor: 'pointer',
                                            }}
                                            data-icon="ic:baseline-pending-actions"
                                            data-width="30"
                                            data-height="30"
                                        ></span>
                                    ) : (
                                        <span
                                            className="iconify"
                                            style={{
                                                color: '#3C48B5',
                                                cursor: 'pointer',
                                            }}
                                            data-icon="ic:baseline-pending-actions"
                                            data-width="30"
                                            data-height="30"
                                        ></span>
                                    )} */}
                        {/* </StyledBadge>
                            )} */}
                        {mycontext.themeToggle ? (
                            <MoreVertIcon
                                fontSize="large"
                                onClick={(e: any) => {
                                    setActionAnchor(null);
                                    setAnchorEl2(null);
                                    setAnchorEl(e.currentTarget);
                                }}
                                style={{
                                    color: 'white',
                                    cursor: 'pointer',
                                }}
                            />
                        ) : (
                            <MoreVertIcon
                                fontSize="large"
                                onClick={(e: any) => {
                                    setActionAnchor(null);
                                    setAnchorEl2(null);
                                    setAnchorEl(e.currentTarget);
                                }}
                                style={{
                                    color: '#3C48B5',
                                    cursor: 'pointer',
                                }}
                            />
                        )}
                        {/* </div> */}
                    </Hidden>
                    <Popover
                        id="teamsList"
                        open={Boolean(actionAnchor)}
                        anchorEl={actionAnchor}
                        onClose={() => setActionAnchor(null)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        classes={{ paper: classes.menuPaperPAction }}
                        style={{ zIndex: 999999 }}
                    >
                        <Box
                            sx={{
                                width: 300,
                                height: data.length === 0 ? 'auto' : 280,
                                my: 2,
                                px: 2,
                                bgcolor: 'transparent',
                                borderRadius: 10,
                            }}
                        >
                            <h6 className={classes.modalText}>Pending Actions</h6>
                            {<PendingActions />}
                        </Box>
                    </Popover>

                    <Popover
                        id="teamsList"
                        open={Boolean(actionNotifAnchor)}
                        anchorEl={actionNotifAnchor}
                        onClose={() => setActionNotifAnchor(null)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        classes={{ paper: classes.menuPaperNAction }}
                        style={{ zIndex: 999999 }}
                    >
                        <Box
                            className={classes.notifBox}
                            sx={{
                                width: 330,
                                maxHeight: 270,
                                // maxHeight: 300,
                                // my: 2,
                                // px: 2,
                                bgcolor: 'transparent',
                                borderRadius: 10,
                            }}
                        >
                            {<NotificationModal />}
                        </Box>
                    </Popover>

                    <Popover
                        id="teamsList"
                        open={Boolean(listAnchor)}
                        anchorEl={listAnchor}
                        onClose={() => {
                            setListAnchor(null);
                            setAnchorEl(null);
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        style={{ zIndex: 999999 }}
                    >
                        {organizations.length === 0 ? (
                            <MenuItem>No Organizations found</MenuItem>
                        ) : (
                            organizations
                                ?.filter((o: any) => o.Organization.organizationId !== mycontext.organizationId)
                                .map((org: any, i: number) => (
                                    <MenuItem
                                        key={i}
                                        onClick={() => {
                                            switchOrganization(org);
                                            setAnchorEl(null);
                                            setListAnchor(null);
                                        }}
                                    >
                                        {org.Organization.organizationName}
                                    </MenuItem>
                                ))
                        )}
                    </Popover>
                    <Popover
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={openMenu}
                        onClose={() => setAnchorEl(null)}
                        classes={{ paper: classes.menuPaper2 }}
                        style={{ zIndex: 999999 }}
                    >
                        <MenuItem className={classes.emailText}>
                            {/* <Typography id="email" data-testid="email"> */}
                            {mycontext.email} {/* </Typography> */}
                        </MenuItem>
                        {mycontext.isAdmin && (
                            <MenuItem
                                id="switchEmpProfile"
                                onClick={() => {
                                    mycontext.updateIsAdmin(false);
                                    setAnchorEl(null);
                                }}
                                className={classes.menuitemtext}
                            >
                                Switch to Employee Profile
                            </MenuItem>
                        )}
                        {mycontext.mainAdmin && !mycontext.isAdmin && (
                            <MenuItem
                                id="switchAdminProfile"
                                onClick={() => {
                                    mycontext.updateIsAdmin(true);
                                    setAnchorEl(null);
                                }}
                                className={classes.menuitemtext}
                            >
                                Switch to Admin Profile
                            </MenuItem>
                        )}
                        {organizations.length === 1 || (
                            <MenuItem
                                id="switchOrg"
                                onClick={(e: any) => setListAnchor(e.currentTarget)}
                                className={classes.menuitemtext}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                            >
                                Switch Department
                                <AiFillCaretRight
                                    height="5px"
                                    width="8px"
                                    style={{ color: '#868686' }}
                                    onClick={(e: any) => {
                                        setListAnchor(e.currentTarget);
                                    }}
                                />
                            </MenuItem>
                        )}
                        {/* {!mycontext.isGoogle && (
                            <MenuItem
                                onClick={() => handlePassword()}
                                id="changepassword"
                                data-testid="changepassword"
                                className={classes.menuitemtext}
                            >
                                Change Password
                            </MenuItem>
                        )} */}
                        {mycontext.isAdmin && (
                            // <Tour
                            //     steps={AdminData}
                            //     isOpen={isTourOpen}
                            //     onRequestClose={() => setIsTourOpen(false)}
                            // />
                            <ReactJoyride
                                // steps={adminData}
                                steps={isClose ? [] : adminData}
                                run={isTourOpenAdmin}
                                stepIndex={stepIndexAdmin}
                                callback={handleJoyrideAdminCallback}
                                // debug={true}
                                // callback={() => setIsTourOpen(false)}
                                continuous
                                showProgress
                                disableScrollParentFix={true}
                                showSkipButton
                                locale={{
                                    last: 'End tour',
                                    // skip: "Close tour"
                                }}
                                styles={{
                                    options: {
                                        arrowColor: theme.palette.background.paper,
                                        backgroundColor: theme.palette.background.paper,
                                        // overlayColor: 'rgba(79, 26, 0, 0.4)',
                                        primaryColor: theme.palette.primary.main,
                                        textColor: theme.palette.text.primary,
                                        // width: 900,
                                        zIndex: 1000,
                                        // top: 0,
                                        // 99999
                                    },
                                    buttonSkip: {
                                        outline: 'none',
                                    },
                                    buttonClose: {
                                        outline: 'none',
                                    },
                                    buttonNext: {
                                        border: 'none',
                                        outline: 'none',
                                        fontFamily: theme.typography.fontFamily,
                                        color: theme.palette.common.white,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontSize: 16,
                                        padding: 10,
                                    },
                                    buttonBack: {
                                        border: 'none',
                                        outline: 'none',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontSize: 16,
                                        padding: 10,
                                        // display: 'none',
                                    },
                                }}
                            />
                        )}
                        {!mycontext.isAdmin && (
                            // <Tour
                            //     steps={EmployeeData}
                            //     isOpen={isTourOpenEmployee}
                            //     onRequestClose={() => setIsTourOpenEmployee(false)}
                            // />
                            <ReactJoyride
                                steps={isEmployeeClose ? [] : employeeData}
                                run={isTourOpenEmployee}
                                stepIndex={stepIndex}
                                callback={handleJoyrideEmployeeCallback}
                                continuous
                                showProgress
                                showSkipButton
                                locale={{
                                    last: 'End tour',
                                }}
                                styles={{
                                    options: {
                                        arrowColor: theme.palette.background.paper,
                                        backgroundColor: theme.palette.background.paper,
                                        primaryColor: theme.palette.primary.main,
                                        textColor: theme.palette.text.primary,
                                        zIndex: 1000,
                                    },
                                    buttonSkip: {
                                        outline: 'none',
                                    },
                                    buttonClose: {
                                        outline: 'none',
                                    },
                                    buttonNext: {
                                        border: 'none',
                                        outline: 'none',
                                        fontFamily: theme.typography.fontFamily,
                                        color: theme.palette.common.white,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontSize: 16,
                                        padding: 10,
                                    },
                                    buttonBack: {
                                        border: 'none',
                                        outline: 'none',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontSize: 16,
                                        padding: 10,
                                        // display: 'none',
                                    },
                                }}
                            />
                        )}
                        {mycontext.isAdmin && (
                            <MenuItem
                                style={{
                                    width: '100%',
                                    color: theme.palette.text.primary,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: theme.typography.fontFamily,
                                }}
                                onClick={() => {
                                    quickTour();
                                    setAnchorEl(null);
                                    setIsClose(false);
                                }}
                                id="quickTour"
                            >
                                Quick Tour
                            </MenuItem>
                        )}
                        {!mycontext.isAdmin && (
                            <MenuItem
                                style={{
                                    width: '100%',
                                    color: theme.palette.text.primary,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: theme.typography.fontFamily,
                                }}
                                onClick={() => {
                                    quickTourEmployee();
                                    setAnchorEl(null);
                                    setIsEmployeeClose(false);
                                }}
                                id="quickTour"
                            >
                                Quick Tour
                            </MenuItem>
                        )}
                        {/* <MenuItem>Contact Support</MenuItem>
                            <MenuItem>User Guide</MenuItem>
                            <MenuItem>Feedback</MenuItem> */}
                        {mycontext.isAdmin && (
                            <MenuItem>
                                <Link
                                    to="/settings"
                                    className={classes.link}
                                    onClick={() => setAnchorEl(null)}
                                    style={{
                                        width: '100%',
                                        color: theme.palette.text.primary,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                    id="setting"
                                >
                                    Settings
                                </Link>
                            </MenuItem>
                        )}
                        {/* {mycontext.isSuperAdmin && ( */}
                        {mycontext.isSuperAdmin && mycontext.isAdmin && (
                            <MenuItem>
                                {' '}
                                <Link
                                    to="/globalsettings"
                                    className={classes.link}
                                    onClick={() => setAnchorEl(null)}
                                    style={{
                                        width: '100%',
                                        color: theme.palette.text.primary,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                    id="globalsetting"
                                >
                                    {' '}
                                    Global Settings
                                </Link>{' '}
                            </MenuItem>
                        )}
                        {mycontext.isSuperAdmin && mycontext.isAdmin && (
                            <MenuItem>
                                {' '}
                                <Link
                                    to="/userLicense"
                                    className={classes.link}
                                    onClick={() => setAnchorEl(null)}
                                    style={{
                                        width: '100%',
                                        color: theme.palette.text.primary,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                    id="userlicense"
                                >
                                    {' '}
                                    User License
                                </Link>{' '}
                            </MenuItem>
                        )}
                        {!mycontext.isAdmin && (
                            <MenuItem>
                                <Link
                                    to={{
                                        pathname: '/user/profile',
                                        state: {
                                            id: mycontext.EmployeeId,
                                            userLink: true,
                                        },
                                    }}
                                    className={classes.link}
                                    onClick={() => handleProfile()}
                                    style={{
                                        width: '100%',
                                        color: theme.palette.text.primary,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                    id="profile"
                                >
                                    Profile
                                </Link>
                            </MenuItem>
                        )}

                        <MenuItem
                            style={{
                                width: '100%',
                                color: theme.palette.text.primary,
                                fontWeight: theme.typography.fontWeightBold,
                                fontFamily: theme.typography.fontFamily,
                            }}
                            onClick={() => {
                                toggle();
                                setAnchorEl(null);
                            }}
                            id="feedback"
                        >
                            Feedback
                        </MenuItem>
                        <MenuItem id="guide">
                            <Link
                                target="_blank"
                                to={
                                    mycontext.isAdmin
                                        ? '/adminguide'
                                        : mycontext.isApprover
                                        ? '/approverguide'
                                        : '/employeeguide'
                                }
                                style={{
                                    width: '100%',
                                    color: theme.palette.text.primary,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: theme.typography.fontFamily,
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                User Guide
                            </Link>
                        </MenuItem>

                        <MenuItem
                            style={{
                                width: '100%',
                                color: theme.palette.text.primary,
                                fontWeight: theme.typography.fontWeightBold,
                                fontFamily: theme.typography.fontFamily,
                            }}
                        >
                            <Link
                                to="/contact"
                                className={classes.link}
                                style={{
                                    width: '100%',
                                    color: theme.palette.text.primary,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: theme.typography.fontFamily,
                                }}
                                id="contactsupport"
                                onClick={() => setAnchorEl(null)}
                            >
                                Contact Support
                            </Link>
                        </MenuItem>
                        {/* {hasFeature('disable_delete_department') && ( */}
                        <>
                            {(mycontext.isAdmin || mycontext.isSuperAdmin) && (
                                <MenuItem
                                    style={{
                                        width: '100%',
                                        color: theme.palette.text.primary,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                    onClick={() => {
                                        toggle1();
                                        setAnchorEl(null);
                                    }}
                                    id="deleteOrg"
                                >
                                    Delete Department
                                </MenuItem>
                            )}
                        </>
                        {/* )} */}

                        {/* {mycontext.isSuperAdmin && (
                            <MenuItem
                                style={{
                                    width: '100%',
                                    color: theme.palette.text.primary,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: theme.typography.fontFamily,
                                }}
                                // onClick={() => {
                                //     toggle1();
                                //     setAnchorEl(null);
                                // }}
                                onClick={delTenant}
                                id="deleteOrg"
                            >
                                Delete Tenant
                            </MenuItem>
                        )} */}

                        <MenuItem
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                background: theme.palette.background.paper,
                            }}
                            className={classes.menuitemtext}
                            id="theme"
                        >
                            Theme
                            <div
                                style={{
                                    // paddingLeft: '40%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'nowrap',
                                    alignItems: 'center',
                                }}
                            >
                                {check1 === false ? (
                                    <BsFillBrightnessHighFill height="20px" width="20px" style={{ color: '#3C48B5' }} />
                                ) : (
                                    <BsFillBrightnessHighFill height="20px" width="20px" style={{ color: '#888888' }} />
                                )}
                                &nbsp;&nbsp;
                                <Switch
                                    onChange={switchStatus}
                                    checked={check1}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={16}
                                    width={34}
                                    onColor="#3C48B5" //#CCCCCC
                                    offColor="#888888"
                                />
                                &nbsp;&nbsp;
                                {check1 === false ? (
                                    <RiMoonClearFill height="20px" width="20px" style={{ color: '#888888' }} />
                                ) : (
                                    <RiMoonClearFill height="20px" width="20px" style={{ color: '#3C48B5' }} />
                                )}
                            </div>
                        </MenuItem>
                        <Divider
                            orientation="horizontal"
                            variant="middle"
                            style={{ marginTop: '5px', background: `${theme.palette.primary.contrastText}` }}
                        />
                        <MenuItem
                            onClick={() => handleLogout()}
                            id="logout"
                            className={classes.menuitemtext}
                            data-testid="logout"
                        >
                            Logout
                        </MenuItem>
                    </Popover>
                    {/* <Divider orientation="vertical" className={classes.divider1} />
                        <IconButton color="primary" aria-label="Notification" style={{ outline: 'none' }}>
                            <img src={Notification} alt="Notification" style={{ height: '18px', width: '17px' }} />
                        </IconButton> */}
                </div>
            </div>
        </>
    );
}
