import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatBtn: {
            position: 'fixed',
            right: 30,
            bottom: 20,
            width: 40,
            height: 40,
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            cursor: 'pointer',
            '@media screen and (max-width: 768px)': {
                display: 'none',
            },
        },
        chatIcon: {
            width: '20px',
            height: 20,
        },
        mobileAddMore: {
            position: 'fixed',
            right: 20,
            bottom: 50,
            width: 40,
            height: 40,
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            cursor: 'pointer',
            '@media screen and (min-width: 768px)': {
                display: 'none',
            },
        },
        addMoreIcon: {
            width: '20px',
            height: 20,
        },
        AddMoreLabels: {
            display: 'flex',
            flexDirection: 'column',
        },
        AddMoreHidden: {
            display: 'none',
        },
        popover: {
            overflow: 'visible',
            background: 'transparent',
            height: 170,
            width: 'auto',
            paddingBottom: 10,
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            boxShadow: 'none',
            marginTop: 15,
            '& .MuiAvatar-root': {
                width: 'auto',
                height: 32,
                ml: -0.5,
                mr: 1,
                zIndex: 9999,
            },
        },
        menuItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: 5,
        },
        label: {
            height: 28,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 14,
            backgroundColor: '#fff',
            fontFamily: 'Nunito Sans',
            marginRight: 10,
            padding: '2px 10px',
            color: '#353535',
        },
        icon: {
            width: 34,
            height: 34,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fff',
        },
        backgroundDiv: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: 400,
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.51) 100%)',
        },
        hiddenDiv: {
            display: 'none',
        },
        text: {
            color: '#353535',
            fontFamily: 'Nunito Sans',
            fontWeight: 600,
        },
        iconImg: {
            color: theme.palette.primary.main,
        },
    }),
);
