/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import useStyles from './calendarStyle2';
import moment from 'moment';
import {
    Typography,
    Hidden,
    Avatar,
    Button,
    TextField,
    Select,
    FormControl,
    MenuItem,
    FormLabel,
    InputAdornment,
    useTheme,
    RadioGroup,
    Radio,
    FormControlLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
// import { ArrowLeftOutlined, ArrowRightOutlined } from '@material-ui/icons';
import {
    ToStringFormat,
    // convertTime,
    DateDifference,
    getTimeDifference,
    getTimeDifferenceMinute,
    toDayFormat,
    toAWSDateFormat,
    getTimeOnlyInMinutes,
    // convertTimeInTZ,
} from '../../helpers/date';
import {
    getCurrentCountryDate,
    getCurrentCountryTime,
    giveTz,
    isoToLocal,
    isoToLocalTime,
    localToISO,
} from '../../helpers/timezone';
import Modal, { PopUp } from '../Modal/Modal';
import AuthContext from '../../context/AuthContext';
import { s3Bucket, images } from '../../helpers/constants';
import {
    updateLeaveAdmin,
    deleteLeave,
    updateLeaveEmployee,
    fetchTimeoffStatus,
    // getApproverInfo,
    // fetchCalendarData,
    // getLeavesForApproverCalendar,
    // getOrganizationEmployees,
} from 'helpers/hasuraapi';
import ErrorPage from '../Error/ErrorPage';
import FilterIcon from './filterIcon.png';
import logo from '../../Assets/images/profile1.png';
import { updateActiveTab } from '../Redux/activeTab';
import { deleteCalendarLeave, editCalendarLeave } from '../Redux/calendarReducer';
import { editEmployeeLeave, deleteEmployeeLeave } from '../Redux/employeeLeavesReducer';
// import { getApproverLeaves } from '../Redux/approverDashboardLeavesReducer';
// import { getPendingLeaves, getTodaysLeaves, getUpcomingLeaves } from '../Redux/dashboardLeavesReducer';
import { getTotalLeaveCount, getLeaveCountToday } from 'components/Redux/employeeStatusReducer';
import { DateField, TimeField } from '../ImportHoliday/FormInputs';
// import {
//     sendDenyNotificationToSelf,
//     sendNotificationToMembers,
//     sendNotificationToSelf,
// } from '../AppNotification/AppNotification';
import {
    sendDeniedNotifToSelf,
    sendApproveNotifToSelf,
    sendNotificationToEmps,
    storeApproveNotif,
    storeDenyNotif,
} from '../WebNotification/Notification';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import {
    handleNotification,
    handleMsNotification,
    handleCancelRequest,
    //     handleCancelNotification,
} from 'helpers/sendNotif';
import { isHoliday, isValidTime, isTimePresent } from '../../helpers/dateDifference';
import { convertTZ } from '../../helpers/date';

export default function Calendar(props: any) {
    const mycontext = useContext(AuthContext);
    const [calendar, setCalendar] = useState<any>([]);
    const [value, setValue] = useState(moment(convertTZ(new Date().toISOString(), mycontext.country.zoneName)));
    const [type, setType] = useState<string>('');
    const classes = useStyles();
    const theme = useTheme();
    // const leaves = useSelector((state: any) => state.leaves);
    // const [leaves, setLeaves] = useState<any>({});
    const [open, setOpen] = useState<boolean>(false);
    const [openA, setOpenA] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [openC, setOpenC] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('');
    const [leaveType, setLeaveType] = useState<string>('');
    const [currentLeave, setCurrentLeave] = useState<any>({});
    const [currentDate, setCurrentDate] = useState<any>('');
    // const [orgEmployees, setOrgEmployees] = useState<any>([]);

    const [leaveFormat, setLeaveFormat] = useState('fullday');
    const [formError, setFormError] = useState('');

    // const [startDate, setStartDate] = useState<any>(null);
    // const [endDate, setEndDate] = useState<any>(null);
    // const [startTime, setStartTime] = useState<any>(null);
    // const [endTime, setEndTime] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(
        toAWSDateFormat(convertTZ(new Date().toISOString(), mycontext.country.zoneName)),
    );
    const [endDate, setEndDate] = useState<any>(
        toAWSDateFormat(convertTZ(new Date().toISOString(), mycontext.country.zoneName)),
    );
    const [startTime, setStartTime] = useState<any>(getCurrentCountryTime(mycontext.country.zoneName));
    const [endTime, setEndTime] = useState<any>(getCurrentCountryTime(mycontext.country.zoneName));
    const dispatch = useDispatch();

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('');

    const reasons = ['Feeling Sick', 'Doctors Appoinment', 'Family Emergency', 'Custom Reason'];
    const [reason, setReason] = useState<string>(reasons[0]);
    const [customReason, setCustomReason] = useState<string>('');
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [error, setError] = useState<boolean>(false);
    const allTypes = ['view all', 'pending', 'denied', 'approved'];

    const useData: any = useSelector((state: any) => state);
    const userSubscriptionDetails: any = useSelector((state: any) => state?.subscription.userSubscriptions);
    // const leaves: any = useData.calendarData;
    const orgEmployees: any = useData.orgEmployees;
    const holidayData: any = useData.holidays;

    function buildCalendar(value: any): any[] {
        const startDay = value
            .clone()
            .startOf('month')
            .startOf('week');
        const endDay = value
            .clone()
            .endOf('month')
            .endOf('week');
        const day = startDay.clone().subtract(1, 'day');
        const a = [];
        while (day.isBefore(endDay, 'day')) {
            a.push(
                Array(7)
                    .fill(0)
                    .map(() => day.add(1, 'day').clone()),
            );
        }
        return a;
    }

    function isSelected(day: any, value: any) {
        return value.isSame(day, 'day');
    }

    function beforeToday(day: any) {
        return day.isBefore(convertTZ(new Date().toISOString(), mycontext.country.zoneName), 'day');
    }

    function beforeMonth(day: any) {
        return day.isBefore(convertTZ(new Date().toISOString(), mycontext.country.zoneName), 'month');
    }

    function currentMonth(day: any) {
        return day.isAfter(convertTZ(new Date().toISOString(), mycontext.country.zoneName), 'month');
    }

    function isToday(day: any) {
        return day.isSame(convertTZ(new Date().toISOString(), mycontext.country.zoneName), 'day');
    }

    function dayStyles(day: any, value: any) {
        if (isToday(day)) return 'today';
        if (isSelected(day, value)) return 'select';
        // if (beforeToday(day)) return "before";
        if (currentMonth(day)) return 'before';
        if (beforeMonth(day)) return 'before';
        return '';
    }

    function prevMonth() {
        return value.clone().subtract(1, 'month');
    }

    function nextMonth() {
        return value.clone().add(1, 'month');
    }

    function thisMonth() {
        return value.isSame(convertTZ(new Date().toISOString(), mycontext.country.zoneName), 'month');
    }

    function ColorChip(props: any) {
        const classes = useStyles();
        return (
            <div
                onClick={() => props.onClick()}
                className={classes.chip}
                style={{ backgroundColor: `${props.colorLight}`, border: `1px solid ${props.colorDark}` }}
            >
                <p className={classes.chipText} style={{ color: `${props.colorDark}` }}>
                    {props.children}
                </p>
            </div>
        );
    }

    const listLeavesHolidays = (arr: any) => {
        const h: any = [];
        arr?.filter((a: any, i: number) => {
            const theDate = new Date(a?.startDate);
            const eDate = new Date(a?.endDate);
            while (theDate <= eDate) {
                h.push({ ...a, date: ToStringFormat(new Date(theDate), 'DD-MM-YYYY') });
                theDate.setDate(theDate.getDate() + 1);
            }
        });
        return h;
    };

    const listLeaves = (arr: any) => {
        const h: any = [];
        arr?.filter((a: any, i: number) => {
            let theDate = isoToLocal(a?.isoString?.startTime, mycontext.country);
            const eDate = isoToLocal(a?.isoString?.endTime, mycontext.country);
            while (theDate <= eDate) {
                h.push({ ...a, date: ToStringFormat(new Date(theDate), 'DD-MM-YYYY') });
                if (parseInt(a.noOfHours) > mycontext.workingHours) {
                    if (
                        theDate
                            .toTimeString()
                            .split(' ')[0]
                            .slice(0, 5) === '00:00'
                    ) {
                        theDate.setDate(theDate.getDate() + 1);
                    } else {
                        theDate.setDate(theDate.getDate() + 1);
                        theDate = new Date(theDate.getTime() - 60 * 1000);
                    }
                } else if (
                    parseInt(a.noOfHours) === mycontext.workingHours &&
                    toAWSDateFormat(theDate) !== toAWSDateFormat(eDate)
                ) {
                    theDate.setDate(theDate.getDate() + 1);
                    theDate = new Date(theDate.getTime() - 60 * 1000);
                } else {
                    theDate.setDate(theDate.getDate() + 1);
                    // theDate.setDate(theDate.getMinutes() - 1);
                }
            }
        });
        return h;
    };

    const handleLeave = (obj: any) => {
        setModalTitle(
            obj?.leaveStatus === 'pending'
                ? 'Pending Time-Off Request'
                : obj?.leaveStatus === 'denied'
                ? 'Time-Off Details'
                : 'Time-Off Details',
        );
        setCurrentLeave(obj);
        if (reasons.includes(obj?.reason)) {
            setReason(obj?.reason);
        } else {
            setReason(reasons[3]);
            setCustomReason(obj?.reason);
        }
        setLeaveType(obj?.leaveStatus);
        if ((obj.startTime === 'null' || obj.startTime === null) && (obj.endTime === 'null' || obj.endTime === null)) {
            setLeaveFormat('fullday');
            setStartDate(toAWSDateFormat(isoToLocal(obj?.isoString?.startTime, mycontext.country)));
            setStartTime(getCurrentCountryTime(mycontext.country.zoneName));
            setEndTime(getCurrentCountryTime(mycontext.country.zoneName));
            setEndDate(
                parseInt(obj.noOfHours) === mycontext.workingHours
                    ? toAWSDateFormat(isoToLocal(obj?.isoString?.startTime, mycontext.country))
                    : toAWSDateFormat(isoToLocal(obj?.isoString?.endTime, mycontext.country)),
            );
        } else {
            setLeaveFormat('custom');
            setStartTime(
                isoToLocal(obj?.isoString?.startTime, mycontext.country)
                    .toTimeString()
                    .split(' ')[0]
                    .substring(0, 5),
            );
            setEndTime(
                isoToLocal(obj?.isoString?.endTime, mycontext.country)
                    .toTimeString()
                    .split(' ')[0]
                    .substring(0, 5),
            );
            setStartDate(toAWSDateFormat(isoToLocal(obj?.isoString?.startTime, mycontext.country)));
            setEndDate(toAWSDateFormat(isoToLocal(obj?.isoString?.startTime, mycontext.country)));
            // setCustomReason(obj.reason);
        }
        // setStartDate(obj.startDate);
        // setEndDate(obj.endDate);
        // if (obj.startTime !== null) {
        //     setStartTime(obj.startTime);
        //     setEndTime(obj.endTime);
        // }
        setOpen(true);
    };

    const handleApprove = async () => {
        try {
            const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }

            const obj = {
                ...currentLeave,
                leaveId: currentLeave.leaveId,
                approverId: mycontext.EmployeeId,
                denialReason: null,
                denierId: mycontext.EmployeeId,
                leaveStatus: 'approved',
                token: mycontext.token,
            };
            const resp = await updateLeaveAdmin(obj, mycontext.isAdmin);
            if (resp?.errors) {
                setAlert(true);
                setAlertMessage('Failed to approved leave!');
                setSeverity('error');
                return;
            }

            setOpen(false);
            setAlert(true);
            setAlertMessage('Request approved successfully!');
            setSeverity('success');
            // await sendNotificationToMembers(
            //     obj.employeeId,
            //     obj.Employee.firstName,
            //     obj.startDate,
            //     obj.endDate,
            //     obj.startTime,
            //     obj.endTime,
            //     mycontext.organizationId,
            //     mycontext.token,
            // );
            // await sendNotificationToSelf(
            //     obj.employeeId,
            //     obj.startDate,
            //     obj.endDate,
            //     obj.startTime,
            //     obj.endTime,
            //     mycontext.token,
            // );

            //web notification
            const dateString = new Date().toString();
            const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
            const time = new Date().toString().slice(16, 24);
            const dateTime = date + time;

            const notifObj = {
                startDate: obj.startDate,
                endDate: obj.endDate,
                startTime: obj.startTime,
                endTime: obj.endTime,
                fname: obj.Employee.firstName,
                lname: obj.Employee.lastName,
                afname: mycontext.fname,
                alname: mycontext.lname,
                empId: obj.employeeId,
                leaveId: obj.leaveId,
                orgId: mycontext.organizationId,
                notifDate: dateTime,
                token: mycontext.token,
                approverId: mycontext.EmployeeId,
            };
            await storeApproveNotif(notifObj);
            handleNotification({
                empId: currentLeave.employeeId,
                actorId: mycontext.EmployeeId,
                orgId: mycontext.organizationId,
                leaveId: currentLeave.leaveId,
                token: mycontext.token,
                startDate: currentLeave.startDate,
                endDate: currentLeave.endDate,
                startTime: currentLeave.startTime,
                endTime: currentLeave.endTime,
                isoString: currentLeave.isoString,
                category: resp.category,
                notif_type: 'approveRequest',
            });
            sendApproveNotifToSelf(
                obj.employeeId,
                mycontext.organizationId,
                mycontext.token,
                obj.startDate,
                obj.endDate,
                obj.startTime,
                obj.endTime,
                mycontext.fname,
                mycontext.lname,
            );
            sendNotificationToEmps(
                obj.employeeId,
                obj.Employee.firstName,
                obj.Employee.lastName,
                obj.startDate,
                obj.endDate,
                obj.startTime,
                obj.endTime,
                mycontext.organizationId,
                mycontext.token,
            );
            // handleNotification({
            //     empId: currentLeave.employeeId,
            //     actorId: mycontext.EmployeeId,
            //     orgId: mycontext.organizationId,
            //     leaveId: currentLeave.leaveId,
            //     token: mycontext.token,
            //     startDate: currentLeave.startDate,
            //     endDate: currentLeave.endDate,
            //     startTime: currentLeave.startTime,
            //     endTime: currentLeave.endTime,
            //     isoString: currentLeave.isoString,
            //     category: resp.category,
            //     notif_type: 'approveRequest',
            // });
            handleMsNotification({
                empId: currentLeave.employeeId,
                actorId: mycontext.EmployeeId,
                orgId: mycontext.organizationId,
                leaveId: currentLeave.leaveId,
                token: mycontext.token,
                startDate: currentLeave.startDate,
                endDate: currentLeave.endDate,
                startTime: currentLeave.startTime,
                endTime: currentLeave.endTime,
                category: resp.category,
                isoString: currentLeave.isoString,
                notif_type: 'approveRequest',
            });
            if (
                currentLeave.startDate <= toAWSDateFormat(new Date()) &&
                currentLeave.endDate >= toAWSDateFormat(new Date())
            ) {
                dispatch(
                    getTotalLeaveCount({
                        organizationId: mycontext.organizationId,
                        token: mycontext.token,
                    }),
                );
                dispatch(
                    getLeaveCountToday({
                        organizationId: mycontext.organizationId,
                        token: mycontext.token,
                        date: toAWSDateFormat(new Date()),
                    }),
                );
            }
            // setTimeout(() => refreshPage(), 1000);
        } catch (err) {
            setOpen(false);
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    const handleModal = async (reason: string) => {
        try {
            const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const leaveData = await fetchTimeoffStatus(currentLeave.leaveId, mycontext.token);
            let prevStatus = 'pending';

            if (leaveData !== null) {
                prevStatus = leaveData[0].leaveStatus;
            }

            const obj = {
                ...currentLeave,
                leaveId: currentLeave.leaveId,
                approverId: mycontext.EmployeeId,
                denialReason: reason.replace('\n', ' '),
                denierId: mycontext.EmployeeId,
                leaveStatus: 'denied',
                token: mycontext.token,
            };
            const resp = await updateLeaveAdmin(obj, mycontext.isAdmin);
            if (resp?.errors) {
                setOpen(false);
                setOpenA(false);
                setAlert(true);
                setAlertMessage('Failed to deny leave!');
                setSeverity('error');
                return;
            }

            setOpen(false);
            setOpenA(false);
            setAlert(true);
            setAlertMessage(`${currentLeave.category} Request denied successfully!`);
            setSeverity('success');
            // await sendDenyNotificationToSelf(
            //     currentLeave.employeeId,
            //     currentLeave.startDate,
            //     currentLeave.endDate,
            //     currentLeave.startTime,
            //     currentLeave.endTime,
            //     mycontext.token,
            // );

            //web notification
            const dateString = new Date().toString();
            const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
            const time = new Date().toString().slice(16, 24);
            const dateTime = date + time;
            const notifObj = {
                startDate: obj.startDate,
                endDate: obj.endDate,
                startTime: obj.startTime,
                endTime: obj.endTime,
                fname: mycontext.fname,
                lname: mycontext.lname,
                empId: obj.employeeId,
                leaveId: obj.leaveId,
                orgId: mycontext.organizationId,
                notifDate: dateTime,
                token: mycontext.token,
                approverId: mycontext.EmployeeId,
            };
            await storeDenyNotif(notifObj);
            handleNotification({
                empId: currentLeave.employeeId,
                actorId: mycontext.EmployeeId,
                orgId: mycontext.organizationId,
                leaveId: currentLeave.leaveId,
                token: mycontext.token,
                startDate: currentLeave.startDate,
                endDate: currentLeave.endDate,
                startTime: currentLeave.startTime,
                endTime: currentLeave.endTime,
                category: currentLeave.category,
                isoString: currentLeave.isoString,
                prevStatus: prevStatus,
                notif_type: 'denyRequest',
                action: 'deny',
            });
            handleMsNotification({
                empId: currentLeave.employeeId,
                actorId: mycontext.EmployeeId,
                orgId: mycontext.organizationId,
                leaveId: currentLeave.leaveId,
                token: mycontext.token,
                startDate: currentLeave.startDate,
                endDate: currentLeave.endDate,
                startTime: currentLeave.startTime,
                endTime: currentLeave.endTime,
                category: resp.category,
                isoString: currentLeave.isoString,
                prevStatus: prevStatus,
                notif_type: 'denyRequest',
            });
            sendDeniedNotifToSelf(
                currentLeave.employeeId,
                mycontext.organizationId,
                mycontext.token,
                currentLeave.startDate,
                currentLeave.endDate,
                currentLeave.startTime,
                currentLeave.endTime,
                mycontext.fname,
                mycontext.lname,
            );
            dispatch(editCalendarLeave(obj));
            dispatch(editEmployeeLeave(obj));
            if (
                currentLeave.startDate <= toAWSDateFormat(new Date()) &&
                currentLeave.endDate >= toAWSDateFormat(new Date())
            ) {
                dispatch(
                    getTotalLeaveCount({
                        organizationId: mycontext.organizationId,
                        token: mycontext.token,
                    }),
                );
                dispatch(
                    getLeaveCountToday({
                        organizationId: mycontext.organizationId,
                        token: mycontext.token,
                        date: toAWSDateFormat(new Date()),
                    }),
                );
            }
            // setTimeout(() => refreshPage(), 1000);
        } catch (err) {
            setOpen(false);
            setOpenA(false);
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    const handleMobileModal = (value: any) => {
        setOpenC(true);
        setCurrentDate(value);
    };

    const DenyTimeOff = () => {
        const [reason, setReason] = useState<string>('');
        return (
            <div style={{ paddingTop: 20 }}>
                <p style={{ color: theme.palette.text.primary, fontFamily: 'Nunito Sans', fontSize: 14 }}>
                    Are you sure you want to Deny the Time-off request ?
                </p>
                <div className={classes.denyModalContent}>
                    <Typography
                        style={{
                            marginRight: 10,
                            marginTop: 15,
                            color: theme.palette.text.primary,
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightBold,
                            fontSize: 14,
                        }}
                    >
                        Reason (optional):
                    </Typography>
                    <TextField
                        multiline
                        type="text"
                        rows={3}
                        value={reason}
                        onChange={(e: any) => setReason(e.target.value)}
                        placeholder="Type your reason here"
                        variant="outlined"
                        style={{ flex: 1 }}
                        className={classes.inputtextStyle}
                    />
                </div>
                <br />
                <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenA(false)}
                        className={classes.btnOutlined}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.btnFilled}
                        onClick={() => handleModal(reason)}
                    >
                        Deny
                    </Button>
                </div>
            </div>
        );
    };

    const handleDeny = () => {
        setOpen(false);
        setLeaveType('');
        setModalTitle('Deny Time-off Request');
        setOpenA(true);
    };

    const cancelRequest = async () => {
        try {
            const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            const leaveData = await fetchTimeoffStatus(currentLeave.leaveId, mycontext.token);
            let prevStatus = 'pending';

            if (leaveData !== null) {
                prevStatus = leaveData[0].leaveStatus;
            }
            const resp = await deleteLeave(currentLeave.leaveId, mycontext.token);
            if (resp.returning[0]?.leaveId) {
                dispatch(deleteCalendarLeave({ leaveId: currentLeave.leaveId }));
                dispatch(deleteEmployeeLeave({ leaveId: currentLeave.leaveId }));
                setOpen(false);
                setAlert(true);
                setAlertMessage(`leave cancelled successfully!`);
                setSeverity('success');
                // setTimeout(() => refreshPage(), 1000);
                // handleCancelNotification({
                //     empId: mycontext.EmployeeId,
                //     actorId: mycontext.EmployeeId,
                //     orgId: mycontext.organizationId,
                //     leaveId: null,
                //     token: mycontext.token,
                //     startDate: currentLeave.startDate,
                //     endDate: currentLeave.endDate,
                //     startTime: currentLeave.startTime,
                //     endTime: currentLeave.endTime,
                //     category: currentLeave.category,
                //     isoString: currentLeave.isoString,
                //     prevStatus: prevStatus,
                //     notif_type: 'denyRequest',
                //     action: 'cancel',
                // });
                handleCancelRequest({
                    empId: mycontext.EmployeeId,
                    orgId: mycontext.organizationId,
                    leaveId: resp.returning[0]?.leaveId,
                    token: mycontext.token,
                    startDate: currentLeave.startDate,
                    endDate: currentLeave.endDate,
                    startTime: currentLeave.startTime,
                    endTime: currentLeave.endTime,
                    category: currentLeave.category,
                    reason: currentLeave.reason,
                    isoString: currentLeave.isoString,
                    prevStatus: prevStatus,
                    notif_type: 'cancelRequest',
                });
                return;
            }
            setOpen(false);
            setAlert(true);
            setAlertMessage(`Failed to cancel leave`);
            setSeverity('error');
        } catch (err) {
            setAlert(true);
            setAlertMessage(err?.message);
            setSeverity('error');
            return err;
        }
    };

    const editRequest = () => {
        setIsUpdate(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setIsUpdate(false);
        setCustomReason('');
        setReason(reasons[0]);
    };

    const DisplayCalendarLeaveModal = ({ currentLeave, mycontext }: { currentLeave: any; mycontext: any }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
                <Avatar
                    src={currentLeave.Employee?.profileImage === null ? logo : currentLeave.Employee?.profileImage}
                    alt={currentLeave.Employee.firstName}
                    style={{
                        height: 24,
                        width: 24,
                        margin: '0 5px',
                        marginBottom: -6,
                        cursor: 'pointer',
                    }}
                    onClick={() => mycontext.handleProfile(currentLeave?.Employee.employeeId)}
                />
                <p className={classes.modalText}>
                    {/* <Avatar style={{ height: 15, width: 15, marginRight: 5 }} />{' '} */}
                    <span
                        style={{
                            color: theme.palette.primary.main,
                            fontWeight: theme.typography.fontWeightBold,
                            cursor: 'pointer',
                        }}
                        onClick={() => mycontext.handleProfile(currentLeave?.Employee.employeeId)}
                    >
                        {currentLeave?.Employee.firstName} {currentLeave?.Employee.lastName}
                    </span>
                    &nbsp;
                    {currentLeave.startTime === 'null' || currentLeave.startTime === null ? (
                        DateDifference(currentLeave.startDate, currentLeave.endDate) !== 0 &&
                        parseInt(currentLeave.noOfHours) === mycontext.workingHours ? (
                            <span
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontSize: 14,
                                }}
                            >
                                {currentLeave.text}
                                {' from '}
                                {ToStringFormat(
                                    isoToLocal(currentLeave.isoString?.startTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}{' '}
                                {isoToLocalTime(currentLeave.isoString?.startTime, mycontext.country)}
                                {' to '}
                                {ToStringFormat(
                                    isoToLocal(currentLeave.isoString?.endTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}{' '}
                                {isoToLocalTime(currentLeave.isoString?.endTime, mycontext.country)}
                                <span
                                    style={{
                                        color: theme.palette.primary.contrastText,
                                        fontWeight: theme.typography.fontWeightRegular,
                                        fontSize: 12,
                                    }}
                                >
                                    (&nbsp;
                                    {' 1 Day'}&nbsp;)
                                </span>
                            </span>
                        ) : (
                            <span
                                style={{
                                    color: theme.palette.text.primary,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontSize: 14,
                                }}
                            >
                                {currentLeave.text} {' from '}
                                {ToStringFormat(
                                    isoToLocal(currentLeave.isoString?.startTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}{' '}
                                {isoToLocalTime(currentLeave.isoString?.startTime, mycontext.country)}
                                {' to '}
                                {ToStringFormat(
                                    isoToLocal(currentLeave.isoString?.endTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}{' '}
                                {isoToLocalTime(currentLeave.isoString?.endTime, mycontext.country)}
                                <span
                                    style={{
                                        color: theme.palette.primary.contrastText,
                                        fontWeight: theme.typography.fontWeightRegular,
                                        fontSize: 12,
                                    }}
                                >
                                    {' '}
                                    (
                                    {parseInt(currentLeave.noOfHours) === mycontext.workingHours
                                        ? ` ${parseInt(currentLeave.noOfHours) / mycontext.workingHours} Day `
                                        : ` ${parseInt(currentLeave.noOfHours) / mycontext.workingHours} Days `}
                                    )
                                </span>
                            </span>
                        )
                    ) : (
                        <span
                            style={{
                                color: theme.palette.text.primary,
                                fontWeight: theme.typography.fontWeightBold,
                                fontSize: 14,
                            }}
                        >
                            {currentLeave.text} on{' '}
                            {ToStringFormat(
                                isoToLocal(currentLeave.isoString?.startTime, mycontext.country),
                                'MMMM Do YYYY',
                            )}
                            {' from '}
                            {isoToLocalTime(currentLeave.isoString?.startTime, mycontext.country)} {' to '}
                            {isoToLocalTime(currentLeave.isoString?.endTime, mycontext.country)}
                            &nbsp;{' '}
                            <span
                                style={{
                                    color: theme.palette.primary.contrastText,
                                    fontWeight: theme.typography.fontWeightRegular,
                                    fontSize: 12,
                                }}
                            >
                                (&nbsp;
                                {getTimeDifferenceMinute(currentLeave.startTime, currentLeave.endTime)}
                                &nbsp;)
                            </span>
                        </span>
                    )}
                </p>
            </div>
        );
    };

    const saveEditRequest = async (data: any) => {
        let obj: any;
        const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
        if (
            userSubscriptionDetail?.timeoffSubscriptionId === null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
        ) {
            throw new Error("Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!");
        }
        if (
            userSubscriptionDetail?.timeoffSubscriptionId === null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter(
                (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
            ).length !== 0
        ) {
            throw new Error("Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!");
        }
        if (
            userSubscriptionDetail?.timeoffSubscriptionId === null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter(
                (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
            ).length !== 0
        ) {
            throw new Error(
                "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
            );
        }
        if (
            userSubscriptionDetail?.timeoffSubscriptionId !== null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter(
                (s: any) =>
                    s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
            ).length !== 0
        ) {
            throw new Error(
                'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
            );
        }
        if (
            userSubscriptionDetail?.timeoffSubscriptionId !== null &&
            userSubscriptionDetail?.Tenant.Subscriptions.filter(
                (s: any) =>
                    s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Unsubscribed',
            ).length !== 0
        ) {
            throw new Error(
                'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
            );
        }
        try {
            if (leaveFormat === 'fullday') {
                if (startDate === null) {
                    throw new Error('Please select start date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (endDate === null) {
                    throw new Error('Please select end Date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), endDate) < 0) {
                    throw new Error('Please select valid end date!');
                }
                if (DateDifference(startDate, endDate) < 0) {
                    throw new Error('Please select valid end date!');
                }
                if (isHoliday(startDate, endDate, holidayData?.holidays)) {
                    throw new Error('You may have holiday on selected date, please select other date!');
                }
                if (reason === reason[3] && customReason === '') {
                    throw new Error('Please add a reason!');
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: mycontext.EmployeeId,
                    token: mycontext.token,
                    category: data.category,
                    startDate: toAWSDateFormat(localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country)),
                    endDate: toAWSDateFormat(localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country)),
                    // endDate:
                    //     DateDifference(toAWSDateFormat(startDate), toAWSDateFormat(endDate)) === 0
                    //         ? toAWSDateFormat(
                    //               localToISO(
                    //                   toAWSDateFormat(new Date().setDate(new Date(startDate).getDate() + 1)),
                    //                   '00:00:00',
                    //                   mycontext.country,
                    //               ),
                    //           )
                    //         : toAWSDateFormat(localToISO(toAWSDateFormat(endDate), '00:00:00', mycontext.country)),
                    startTime: null,
                    endTime: null,
                    noOfHours:
                        mycontext.workingHours *
                        (DateDifference(toAWSDateFormat(startDate), toAWSDateFormat(endDate)) + 1),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: 'pending',
                    leaveId: data.leaveId,
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), '00:00:00', mycontext.country),
                        endTime: localToISO(toAWSDateFormat(endDate), '23:59:00', mycontext.country),
                        // endTime:
                        //     DateDifference(toAWSDateFormat(startDate), toAWSDateFormat(endDate)) === 0
                        //         ? localToISO(
                        //               toAWSDateFormat(new Date().setDate(new Date(startDate).getDate() + 1)),
                        //               '00:00:00',
                        //               mycontext.country,
                        //           )
                        //         : localToISO(toAWSDateFormat(endDate), '00:00:00', mycontext.country),
                    },
                };
            } else {
                if (startDate === null) {
                    throw new Error('Please select start date!');
                }
                if (DateDifference(convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate) < 0) {
                    throw new Error('Please select valid start date!');
                }
                if (startTime === null || startTime === '') {
                    throw new Error('Please select startTime!');
                }
                if (endTime === null || endTime === '') {
                    throw new Error('Plese select endTime!');
                }
                if (
                    isValidTime(startTime, convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate)
                ) {
                    throw new Error('Please select valid start time!');
                }
                if (isValidTime(endTime, convertTZ(new Date().toISOString(), mycontext.country.zoneName), startDate)) {
                    throw new Error('Please select valid end time!');
                }
                if (parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) < 0) {
                    throw new Error('Please select valid times!');
                }
                if (parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) < 0.5) {
                    throw new Error('You cant add leave less than half hour!');
                }
                if (
                    getTimeDifference(startTime, endTime) > mycontext.workingHours ||
                    parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours) > mycontext.workingHours
                ) {
                    throw new Error(`You can't add leave more than working hours!`);
                }
                if (customReason === '' && reason === reasons[3]) {
                    throw new Error('Please add a reason!');
                }
                if (isHoliday(startDate, endDate, holidayData.holidays)) {
                    throw new Error('You may have holiday on selected date, please select other date!');
                }
                if (startTime === 'Inval' || endTime === 'Inval') {
                    throw new Error('Please select valid time!');
                }
                obj = {
                    gmt: giveTz(`${mycontext.country.gmtOffset}`),
                    organizationId: mycontext.organizationId,
                    employeeId: mycontext.EmployeeId,
                    token: mycontext.token,
                    category: data.category,
                    startDate: toAWSDateFormat(
                        localToISO(toAWSDateFormat(startDate), `${startTime}:00`, mycontext.country),
                    ),
                    endDate: toAWSDateFormat(
                        localToISO(toAWSDateFormat(startDate), `${endTime}:00`, mycontext.country),
                    ),
                    startTime: startTime,
                    endTime: endTime,
                    noOfHours: parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours),
                    reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                    leaveStatus: 'pending',
                    leaveId: data.leaveId,
                    isoString: {
                        startTime: localToISO(toAWSDateFormat(startDate), `${startTime}:00`, mycontext.country),
                        endTime: localToISO(toAWSDateFormat(startDate), `${endTime}:00`, mycontext.country),
                    },
                };
                if (
                    props.leaves?.data.timeoffdb_Leaves?.find(
                        (l: any) =>
                            toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                                toAWSDateFormat(startDate) &&
                            l.leaveStatus !== 'denied' &&
                            l.startTime === 'null',
                    ) !== undefined
                ) {
                    throw new Error('Leave with the same date already present!');
                }
                const presentLeave = props.leaves?.data.timeoffdb_Leaves?.find(
                    (l: any) =>
                        toAWSDateFormat(isoToLocal(l.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(startDate) &&
                        l.leaveStatus !== 'denied' &&
                        l.startTime !== 'null' &&
                        isTimePresent(
                            startTime,
                            isoToLocal(l.isoString?.startTime, mycontext.country)
                                .toTimeString()
                                .split(' ')[0]
                                .substring(0, 5),
                            isoToLocal(l.isoString?.endTime, mycontext.country)
                                .toTimeString()
                                .split(' ')[0]
                                .substring(0, 5),
                        ),
                );
                const updatedLeave = obj;
                if (presentLeave) {
                    // console.log(
                    //     'time check',
                    //     presentLeave.startTime,
                    //     updatedLeave.startTime,
                    //     presentLeave.endTime,
                    //     updatedLeave.endTime,
                    //     presentLeave.startDate,
                    //     updatedLeave.startDate,
                    //     presentLeave.endDate,
                    //     updatedLeave.endDate,
                    // );
                    if (
                        presentLeave.startTime === updatedLeave.startTime &&
                        presentLeave.endTime === updatedLeave.endTime &&
                        presentLeave.startDate === updatedLeave.startDate &&
                        presentLeave.endDate === updatedLeave.endDate
                    ) {
                        throw new Error('Leave with the same time already present!');
                    }
                }
            }

            setOpen(false);
            setOpenC(false);
            const resp = await updateLeaveEmployee(obj, mycontext.isApprover);
            if (resp.leaveId) {
                if (leaveFormat === 'fullday') {
                    dispatch(
                        editCalendarLeave({
                            ...currentLeave,
                            category: data.category,
                            startDate: startDate,
                            endDate: endDate,
                            startTime: 'null',
                            endTime: 'null',
                            noOfHours: mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                            reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                            leaveStatus: 'pending',
                            leaveId: data.leaveId,
                        }),
                    );
                    dispatch(
                        editEmployeeLeave({
                            ...currentLeave,
                            category: data.category,
                            startDate: startDate,
                            endDate: endDate,
                            startTime: 'null',
                            endTime: 'null',
                            noOfHours: mycontext.workingHours * (DateDifference(startDate, endDate) + 1),
                            reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                            leaveStatus: 'pending',
                            leaveId: data.leaveId,
                        }),
                    );
                } else {
                    dispatch(
                        editCalendarLeave({
                            ...currentLeave,
                            category: data.category,
                            startDate: startDate,
                            endDate: startDate,
                            startTime: startTime,
                            endTime: endTime,
                            noOfHours: parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours),
                            reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                            leaveStatus: 'pending',
                            leaveId: data.leaveId,
                        }),
                    );
                    dispatch(
                        editEmployeeLeave({
                            ...currentLeave,
                            category: data.category,
                            startDate: startDate,
                            endDate: startDate,
                            startTime: startTime,
                            endTime: endTime,
                            noOfHours: parseFloat(getTimeOnlyInMinutes(startTime, endTime).hours),
                            reason: reason === reasons[3] ? customReason.split('\n').join(' ') : reason,
                            leaveStatus: 'pending',
                            leaveId: data.leaveId,
                        }),
                    );
                }
                setStartDate(null);
                setEndDate(null);
                setStartTime(null);
                setEndTime(null);
                setReason(reasons[0]);
                setCustomReason('');
                setIsUpdate(false);
                setFormError('');
                setOpen(false);
                setAlert(true);
                setAlertMessage(`leave updated successfully!`);
                setSeverity('success');
                // setTimeout(() => refreshPage(), 1000);
                return;
            }
            setStartDate(null);
            setEndDate(null);
            setStartTime(null);
            setEndTime(null);
            setReason(reasons[0]);
            setCustomReason('');
            setIsUpdate(false);
            setFormError('');
            setOpen(false);
            setAlert(true);
            setAlertMessage(`Failed to update leave`);
            setSeverity('error');
        } catch (err) {
            setFormError(err.message);
            return err;
        }
    };

    useEffect(() => {
        // dispatch({ type: 'ACTIVE', payload: '/calender' });
        dispatch(updateActiveTab('/calender'));
        setCalendar(buildCalendar(value));
        //setIsLoading(false);
        // setError(false);
        // console.log(document?.querySelector('#dateDiv').childElementCount);
    }, [value]);

    // useEffect(() => {
    //     async function fetchData() {
    //         const data: any = await fetchCalendarData(mycontext.organizationId, mycontext.token);
    //         if (data.errors) {
    //             setError(true);
    //             return;
    //         }
    //         const employees = await getOrganizationEmployees(mycontext.organizationId, mycontext.token);
    //         setLeaves(data);
    //         setOrgEmployees(employees);
    //         setIsLoading(false);
    //     }
    //     async function fetchApproverData() {
    //         const data: any = await getLeavesForApproverCalendar(
    //             mycontext.EmployeeId,
    //             mycontext.organizationId,
    //             mycontext.token,
    //         );
    //         if (data.errors) {
    //             setError(true);
    //             return;
    //         }
    //         const employees = await getOrganizationEmployees(mycontext.organizationId, mycontext.token);
    //         setOrgEmployees(employees);
    //         const a: any = data.timeoffdb_get_pendingdeniedapproverleaves.concat(data.timeoffdb_Leaves);
    //         const arr: any = [].concat(a);
    //         const mydata = {
    //             data: {
    //                 timeoffdb_Leaves: arr,
    //                 timeoffdb_Holiday: data.timeoffdb_Holiday,
    //             },
    //         };
    //         // setLeaves([...data.timeoffdb_get_pendingdeniedapproverleaves, ...data.timeoffdb_Leaves]);
    //         setLeaves(mydata);
    //         setIsLoading(false);
    //     }
    //     setIsLoading(true);
    //     if (mycontext.isApprover) {
    //         fetchApproverData();
    //         return;
    //     }
    //     fetchData();
    // }, []);

    if (
        orgEmployees.status === 'loading' ||
        props.leaves?.loading ||
        props.approverLeaves?.loading ||
        holidayData.status === 'loading'
    ) {
        return (
            <>
                <Hidden smDown>
                    <div className={classes.main}>
                        <div className={classes.top}>
                            <Typography className={classes.heading} data-testid="cal">
                                Calender
                            </Typography>
                        </div>
                        <div className={classes.bottom}>
                            <div className={classes.calendar}>
                                <div className={classes.calendarHeader}>
                                    <div className={classes.month}>
                                        <img
                                            src={s3Bucket + images.LeftArrow}
                                            alt="left_arrow"
                                            onClick={() => setValue(prevMonth())}
                                            id="leftarrow"
                                            className={classes.iconCol1}
                                        />
                                        <div className={classes.date}>
                                            <p>
                                                {value.format('MMMM')}&nbsp;
                                                {value.format('YYYY')}
                                            </p>
                                        </div>
                                        <img
                                            src={s3Bucket + images.RightArrow}
                                            alt="right_arrow"
                                            onClick={() => setValue(nextMonth())}
                                            id="rightarrow"
                                            className={classes.iconCol2}
                                        />
                                    </div>
                                    <div className={classes.labels} data-testid="approved">
                                        {type === '' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#3D49B6"
                                                onClick={() => setType('')}
                                            >
                                                View All
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#3D49B6"
                                                colorLight="#E2E5FF"
                                                onClick={() => setType('')}
                                            >
                                                View All
                                            </ColorChip>
                                        )}
                                        {type === 'approved' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#088843"
                                                onClick={() => setType('approved')}
                                            >
                                                Approved Time-Off
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#088843"
                                                colorLight="#DAF6E7"
                                                onClick={() => setType('approved')}
                                            >
                                                Approved Time-Off
                                            </ColorChip>
                                        )}
                                        {type === 'denied' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#CE3B3B"
                                                onClick={() => setType('denied')}
                                            >
                                                Denied Time-Off
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#CE3B3B"
                                                colorLight="#FAD9D9"
                                                onClick={() => setType('denied')}
                                            >
                                                Denied Time-Off
                                            </ColorChip>
                                        )}
                                        {type === 'pending' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#F2890D"
                                                onClick={() => setType('pending')}
                                            >
                                                {mycontext.isAdmin || mycontext.isApprover
                                                    ? 'Pending Request'
                                                    : 'My Pending Request'}
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#F2890D"
                                                colorLight="#FEF2DA"
                                                onClick={() => setType('pending')}
                                            >
                                                {mycontext.isAdmin || mycontext.isApprover
                                                    ? 'Pending Request'
                                                    : 'My Pending Request'}
                                            </ColorChip>
                                        )}
                                        {type === 'holidays' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#695FDE"
                                                onClick={() => setType('holidays')}
                                            >
                                                Organization Holidays
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#695FDE"
                                                colorLight="#E0DEFF"
                                                onClick={() => setType('holidays')}
                                            >
                                                Organization Holidays
                                            </ColorChip>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.weekDays}>
                                    <div>Sunday</div>
                                    <div>Monday</div>
                                    <div>Tuesday</div>
                                    <div>Wednesday</div>
                                    <div>Thursday</div>
                                    <div>Friday</div>
                                    <div>Saturday</div>
                                </div>
                                {calendar.map((week: any, i: number) => (
                                    <div key={i} className={classes.days}>
                                        {week.map((d: any, j: number) => (
                                            <div
                                                key={j}
                                                className={
                                                    dayStyles(d, value) === 'before'
                                                        ? classes.prevDate
                                                        : dayStyles(d, value) === 'today'
                                                        ? classes.today
                                                        : classes.otherDay
                                                }
                                                onClick={() => !beforeToday(d) && setValue(d)}
                                            >
                                                <div
                                                    className={
                                                        thisMonth() && dayStyles(d, value) === 'today'
                                                            ? classes.currentDay
                                                            : classes.laterDay
                                                    }
                                                >
                                                    {d.format('DD').toString()}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Hidden>
                <Hidden smUp>
                    <FormControl
                        variant="outlined"
                        style={{ width: '100%', background: theme.palette.background.paper, marginBottom: 10 }} //height: 40,
                        margin="dense"
                    >
                        <Select
                            value={type === '' ? allTypes[0] : type}
                            className={classes.inputSelectStyle}
                            onChange={(e: any) =>
                                setType((e.target.value as string) === 'view all' ? '' : e.target.value)
                            }
                        >
                            {allTypes.map((t: any, i: number) => (
                                <MenuItem key={i} value={t}>
                                    {t}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div
                        style={{
                            width: '100%',
                            background: theme.palette.background.paper,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div className={classes.monthMobile}>
                            <img
                                src={s3Bucket + images.LeftArrow}
                                alt="left_arrow"
                                onClick={() => setValue(prevMonth())}
                                id="leftarrow"
                                className={classes.iconCol1}
                            />
                            <div className={classes.date}>
                                <p>
                                    {value.format('MMMM')}&nbsp;
                                    {value.format('YYYY')}
                                </p>
                            </div>
                            <img
                                src={s3Bucket + images.RightArrow}
                                alt="right_arrow"
                                onClick={() => setValue(nextMonth())}
                                id="rightarrow"
                                className={classes.iconCol2}
                            />
                        </div>
                        <div className={classes.weekDaysMobile}>
                            <div>Sun</div>
                            <div>Mon</div>
                            <div>Tue</div>
                            <div>Wed</div>
                            <div>Thu</div>
                            <div>Fri</div>
                            <div>Sat</div>
                        </div>
                        {calendar.map((week: any, i: number) => (
                            <div key={i} className={classes.daysMobile}>
                                {week.map((d: any, j: number) => (
                                    <div
                                        key={j}
                                        className={
                                            dayStyles(d, value) === 'before'
                                                ? classes.prevDateM
                                                : dayStyles(d, value) === 'today'
                                                ? classes.todayM
                                                : classes.otherDayM
                                        }
                                        onClick={() => !beforeToday(d) && setValue(d)}
                                    >
                                        <div
                                            className={
                                                thisMonth() && dayStyles(d, value) === 'today'
                                                    ? classes.currentDay
                                                    : classes.laterDay
                                            }
                                        >
                                            {d.format('DD').toString()}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            backgroundColor: '#fff',
                            padding: 10,
                            marginTop: 10,
                            fontFamily: 'Nunito Sans',
                            fontSize: 12,
                            color: '#202020',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                            <span
                                style={{
                                    height: 8,
                                    width: 8,
                                    borderRadius: '50%',
                                    backgroundColor: '#088843',
                                    marginRight: 5,
                                }}
                            ></span>{' '}
                            Approved Time-Off
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                            <span
                                style={{
                                    height: 8,
                                    width: 8,
                                    borderRadius: '50%',
                                    backgroundColor: '#CE3B3B',
                                    marginRight: 5,
                                }}
                            ></span>{' '}
                            Denied Time-Off
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                            <span
                                style={{
                                    height: 8,
                                    width: 8,
                                    borderRadius: '50%',
                                    backgroundColor: '#F2890D',
                                    marginRight: 5,
                                }}
                            ></span>{' '}
                            {mycontext.isAdmin || mycontext.isApprover ? 'Pending Request' : 'My Pending Reques'}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                            <span
                                style={{
                                    height: 8,
                                    width: 8,
                                    borderRadius: '50%',
                                    backgroundColor: '#695FDE',
                                    marginRight: 5,
                                }}
                            ></span>{' '}
                            Organization Holidays
                        </div>
                    </div>
                </Hidden>
            </>
        );
    } else if (
        orgEmployees.status === 'failed' ||
        props.leaves?.error ||
        props.approverLeaves?.error ||
        holidayData.status === 'failed'
    ) {
        return <ErrorPage title="Server Down" />;
    } else {
        // const myleaves: any = listLeaves(
        //     mycontext.isAdmin || mycontext.isApprover
        //         ? leaves.leaves
        //         : leaves.leaves.filter(
        //               (l: any) =>
        //                   l.Employee.employeeId === mycontext.EmployeeId ||
        //                   (l.Employee.employeeId !== mycontext.EmployeeId && l.leaveStatus === 'approved'),
        //           ),
        // );
        const myleaves: any = mycontext.isApprover
            ? listLeaves(
                  props.approverLeaves?.data?.timeoffdb_get_pendingdeniedapproverleaves.length
                      ? props.approverLeaves?.data?.timeoffdb_get_pendingdeniedapproverleaves.concat(
                            props.leaves?.data?.timeoffdb_Leaves.filter((l: any) => l.leaveStatus === 'approved'),
                        )
                      : props.leaves?.data?.timeoffdb_Leaves.filter((l: any) => l.leaveStatus === 'approved'),
              )
            : listLeaves(
                  mycontext.isAdmin
                      ? props.leaves.data?.timeoffdb_Leaves
                      : props.leaves.data?.timeoffdb_Leaves.filter(
                            (l: any) =>
                                l.Employee.employeeId === mycontext.EmployeeId ||
                                (l.Employee.employeeId !== mycontext.EmployeeId && l.leaveStatus === 'approved'),
                        ),
              );
        const holidays: any = listLeavesHolidays(
            holidayData.holidays.filter((l: any) => l.startDate !== null || l.endDate !== null),
        );
        return mycontext.subscriptionExpired === 'cancelled' &&
            Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
            mycontext.isAdmin ? (
                <SubscriptionHardExpired
                    content="Looks like your subscription is expired, Please renew it !"
                    showRenew={true}
                />
            ) : (
                <SubscriptionHardExpired
                    content="Subscription expired, Please contact your admin to renew subscription"
                    showRenew={false}
                />
            )
        ) : (
            <>
                <Modal open={open} setOpen={setOpen} onModalClosed={handleCloseModal} title={modalTitle}>
                    {isUpdate ? (
                        <div className={classes.modalContent}>
                            {formError !== '' ? <p className={classes.errorMessage}>{formError}</p> : null}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '5px 0',
                                }}
                            >
                                {/* <Avatar
                                    src={
                                        currentLeave.Employee?.profileImage === null
                                            ? logo
                                            : currentLeave.Employee?.profileImage
                                    }
                                    alt={currentLeave.Employee.firstName}
                                    style={{ height: 24, width: 24, margin: '0 5px', marginBottom: -6 }}
                                /> */}
                                {/* {leaveType === 'pending' && (
                                    <p className={classes.modalText}>
                                        <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                            {currentLeave?.Employee?.firstName} {currentLeave?.Employee?.lastName}
                                        </span>
                                        <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                            You{' '}
                                        </span>
                                        are on Time-Off on{' '}
                                    </p>
                                )} */}
                            </div>
                            <div>
                                <FormLabel className={classes.formLabelStyle2}>Time-Off Category Type</FormLabel>
                                <RadioGroup
                                    row
                                    value={leaveFormat}
                                    onChange={(e: any) => setLeaveFormat(e.target.value)}
                                    className={classes.radioGroupStyles}
                                >
                                    <FormControlLabel
                                        value="fullday"
                                        control={<Radio color="primary" />}
                                        label={<FormLabel className={classes.radioNameStyle}>Full Day</FormLabel>}
                                    />
                                    <FormControlLabel
                                        value="custom"
                                        control={<Radio color="primary" />}
                                        label={
                                            <FormLabel className={classes.radioNameStyle}>Custom Time-Off</FormLabel>
                                        }
                                    />
                                </RadioGroup>
                            </div>
                            {leaveFormat === 'fullday' ? (
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        gridColumnGap: 10,
                                    }}
                                >
                                    <div>
                                        <FormLabel className={classes.formLabelStyle}>From</FormLabel>
                                        <DateField date={startDate} setDate={setStartDate} id="startdatefield" />
                                    </div>
                                    <div>
                                        <FormLabel className={classes.formLabelStyle}>To</FormLabel>
                                        <DateField date={endDate} setDate={setEndDate} id="enddatefield" />
                                    </div>
                                </div>
                            ) : (
                                // <div
                                //     style={{
                                //         display: 'grid',
                                //         gridTemplateColumns: '1fr 1fr',
                                //         gridColumnGap: 10,
                                //         background: 'green',
                                //     }}
                                // >
                                //     <div style={{ display: 'flex', flexDirection: 'column', background: 'red' }}>
                                //         {/* <FormControl className={classes.formControlTime}> */}
                                //         <FormLabel className={classes.formLabelStyle}>From</FormLabel>
                                //         <TextField
                                //             id="date"
                                //             type="text"
                                //             value={startDate}
                                //             defaultValue={new Date().toISOString()}
                                //             variant="outlined"
                                //             InputLabelProps={{
                                //                 shrink: true,
                                //             }}
                                //             onChange={(e: any) => setStartDate(e.target.value)}
                                //             size="small"
                                //             className={classes.editinputtextStyle}
                                //         />
                                //         {/* </FormControl> */}
                                //     </div>
                                //     <div style={{ display: 'flex', flexDirection: 'column', background: 'yellow' }}>
                                //         <FormLabel className={classes.formLabelStyle}>To</FormLabel>
                                //         <TextField
                                //             id="date"
                                //             type="text"
                                //             value={endDate}
                                //             defaultValue={new Date().toISOString()}
                                //             variant="outlined"
                                //             InputLabelProps={{
                                //                 shrink: true,
                                //             }}
                                //             onChange={(e: any) => setEndDate(e.target.value)}
                                //             size="small"
                                //             className={classes.editinputtextStyle}
                                //         />
                                //     </div>
                                // </div>
                                <div className={classes.fieldDivDateTimeSecond}>
                                    <div>
                                        <FormLabel className={classes.formLabelStyle}>From</FormLabel>
                                        <FormControl className={classes.formControlTime}>
                                            <DateField date={startDate} setDate={setStartDate} id="startdatefield" />
                                            <TimeField time={startTime} setTime={setStartTime} id="timefield" />
                                        </FormControl>
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <FormLabel className={classes.formLabelStyle}>To</FormLabel>
                                        <FormControl className={classes.formControlTime}>
                                            <DateField
                                                date={endDate}
                                                setDate={setEndDate}
                                                id="enddatefield"
                                                disable={true}
                                            />
                                            <TimeField time={endTime} setTime={setEndTime} id="timefield" />
                                        </FormControl>
                                    </div>
                                </div>
                            )}
                            <div className={classes.fieldDiv} style={{ marginTop: 10 }}>
                                <FormLabel className={classes.formLabelStyle}>Reason</FormLabel>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        value={reasons.includes(reason) ? reason : reasons[3]}
                                        className={classes.inputReason}
                                        onChange={(e: any) => setReason(e.target.value as string)}
                                    >
                                        {reasons.map((r: string, i: number) => (
                                            <MenuItem value={r} key={i}>
                                                {r}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {!reasons.slice(0, 3).includes(reason) || reason === 'Custom Reason' ? (
                                        <TextField
                                            type="text"
                                            value={customReason}
                                            variant="outlined"
                                            placeholder="Type your reason here"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className={classes.inputReason}
                                            onChange={(e: any) => setCustomReason(e.target.value)}
                                            size="small"
                                            focused
                                            style={{ marginTop: 10 }}
                                        />
                                    ) : null}
                                </FormControl>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    marginTop: 10,
                                }}
                            >
                                <Button
                                    className={classes.btnOutlinedCancel}
                                    variant="outlined"
                                    color="primary"
                                    disableRipple={true}
                                    onClick={() => handleCloseModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={classes.btnFilledEdit}
                                    variant="contained"
                                    color="primary"
                                    disableRipple={true}
                                    onClick={() => saveEditRequest(currentLeave)}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.modalContent}>
                            {leaveType === 'pending' && (
                                <DisplayCalendarLeaveModal
                                    currentLeave={{ ...currentLeave, text: 'has request for Time-Off' }}
                                    mycontext={mycontext}
                                />
                            )}
                            {leaveType === 'approved' && (
                                <DisplayCalendarLeaveModal
                                    currentLeave={{ ...currentLeave, text: 'is on Time-Off' }}
                                    mycontext={mycontext}
                                />
                            )}
                            {leaveType === 'denied' && (
                                <DisplayCalendarLeaveModal
                                    currentLeave={{ ...currentLeave, text: 'is on Time-Off' }}
                                    mycontext={mycontext}
                                />
                            )}
                            <p className={classes.modalText} style={{ color: theme.palette.text.primary }}>
                                <span style={{ fontWeight: theme.typography.fontWeightBold }}>Category:</span>{' '}
                                <span
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: theme.typography.fontWeightRegular,
                                        fontSize: 14,
                                    }}
                                >
                                    {currentLeave.category}
                                </span>
                            </p>
                            {leaveType === 'approved' &&
                            (mycontext.isAdmin ||
                                mycontext.EmployeeId === currentLeave.Employee.employeeId ||
                                (mycontext.isApprover && mycontext.EmployeeId === currentLeave.denierId)) ? (
                                <>
                                    <p className={classes.modalText} style={{ color: theme.palette.text.primary }}>
                                        <span style={{ fontWeight: theme.typography.fontWeightBold }}>Reason:</span>{' '}
                                        <span
                                            style={{
                                                color: theme.palette.text.primary,
                                                fontWeight: theme.typography.fontWeightRegular,
                                                fontSize: 14,
                                            }}
                                        >
                                            {currentLeave.reason === '' ? 'None' : currentLeave.reason}
                                        </span>
                                    </p>
                                    <p className={classes.modalText} style={{ color: theme.palette.text.primary }}>
                                        <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                                            Leave approved by:
                                        </span>{' '}
                                        <span
                                            style={{
                                                color: theme.palette.text.primary,
                                                fontWeight: theme.typography.fontWeightRegular,
                                                fontSize: 14,
                                            }}
                                        >
                                            {currentLeave.approverId === null
                                                ? 'Auto Approved'
                                                : orgEmployees.employees.filter(
                                                      (emp: any) => emp.employeeId === currentLeave?.approverId,
                                                  )[0]?.Employee?.firstName}
                                        </span>
                                    </p>
                                </>
                            ) : null}
                            {leaveType === 'pending' &&
                            (mycontext.isAdmin ||
                                mycontext.EmployeeId === currentLeave.Employee.employeeId ||
                                mycontext.isApprover) ? (
                                <p className={classes.modalText} style={{ color: theme.palette.text.primary }}>
                                    <span style={{ fontWeight: theme.typography.fontWeightBold }}>Reason:</span>{' '}
                                    <span
                                        style={{
                                            color: theme.palette.text.primary,
                                            fontWeight: theme.typography.fontWeightRegular,
                                            fontSize: 14,
                                        }}
                                    >
                                        {currentLeave.reason === '' || currentLeave.reason === 'null'
                                            ? 'None'
                                            : currentLeave.reason}
                                    </span>
                                </p>
                            ) : null}
                            {leaveType === 'denied' &&
                            (mycontext.isAdmin ||
                                mycontext.EmployeeId === currentLeave.Employee.employeeId ||
                                (mycontext.isApprover && mycontext.EmployeeId === currentLeave.denierId)) ? (
                                <p className={classes.modalText} style={{ color: theme.palette.text.primary }}>
                                    <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                                        Reason for Time-Off:
                                    </span>{' '}
                                    <span
                                        style={{
                                            color: theme.palette.text.primary,
                                            fontWeight: theme.typography.fontWeightRegular,
                                            fontSize: 14,
                                        }}
                                    >
                                        {currentLeave.reason === '' || currentLeave.reason === 'null'
                                            ? 'None'
                                            : currentLeave.reason}
                                    </span>
                                </p>
                            ) : null}
                            {leaveType === 'denied' &&
                            (mycontext.isAdmin ||
                                mycontext.EmployeeId === currentLeave.Employee.employeeId ||
                                (mycontext.isApprover && mycontext.EmployeeId === currentLeave.denierId)) ? (
                                <>
                                    <p className={classes.modalText} style={{ color: theme.palette.text.primary }}>
                                        <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                                            Reason for denial:
                                        </span>{' '}
                                        <span
                                            style={{
                                                color: theme.palette.text.primary,
                                                fontWeight: theme.typography.fontWeightRegular,
                                                fontSize: 14,
                                            }}
                                        >
                                            {currentLeave.denialReason === '' || currentLeave.denialReason === 'null'
                                                ? 'None'
                                                : currentLeave.denialReason}
                                        </span>
                                    </p>
                                    <p className={classes.modalText} style={{ color: theme.palette.text.primary }}>
                                        <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                                            Leave denied by:
                                        </span>{' '}
                                        <span
                                            style={{
                                                color: theme.palette.text.primary,
                                                fontWeight: theme.typography.fontWeightRegular,
                                                fontSize: 14,
                                            }}
                                        >
                                            {
                                                orgEmployees.employees.filter(
                                                    (emp: any) => emp.employeeId === currentLeave.denierId,
                                                )[0]?.Employee?.firstName
                                            }
                                        </span>
                                    </p>
                                </>
                            ) : null}
                            {orgEmployees.employees.filter(
                                (emp: any) => emp.employeeId === currentLeave?.leaveAppliedBy,
                            )[0]?.employeeId !== currentLeave?.Employee?.employeeId && (
                                <p className={classes.modalText} style={{ color: theme.palette.text.primary }}>
                                    <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                                        Leave applied by:
                                    </span>{' '}
                                    <span
                                        style={{
                                            color: theme.palette.text.primary,
                                            fontWeight: theme.typography.fontWeightRegular,
                                            fontSize: 14,
                                        }}
                                    >
                                        {
                                            orgEmployees.employees.filter(
                                                (emp: any) => emp.employeeId === currentLeave?.leaveAppliedBy,
                                            )[0]?.Employee.firstName
                                        }
                                    </span>
                                </p>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    marginTop: 10,
                                }}
                            >
                                {((mycontext.isApprover && mycontext.EmployeeId === currentLeave.denierId) ||
                                    mycontext.isAdmin) &&
                                    (DateDifference(
                                        toAWSDateFormat(
                                            isoToLocal(currentLeave.isoString?.startTime, mycontext.country),
                                        ),
                                        getCurrentCountryDate(mycontext.country?.zoneName),
                                    ) <= 0 ||
                                        DateDifference(
                                            getCurrentCountryDate(mycontext.country?.zoneName),
                                            toAWSDateFormat(
                                                isoToLocal(currentLeave.isoString?.endTime, mycontext.country),
                                            ),
                                        ) >= 0) &&
                                    leaveType === 'denied' && (
                                        <Button
                                            id="approve-time-off"
                                            className={classes.btn}
                                            variant="contained"
                                            color="primary"
                                            disableRipple={true}
                                            onClick={() => handleApprove()}
                                        >
                                            Approve Time-Off{' '}
                                        </Button>
                                    )}
                                {((mycontext.isApprover && mycontext.EmployeeId === currentLeave.approverId) ||
                                    mycontext.isAdmin) &&
                                    (DateDifference(
                                        toAWSDateFormat(
                                            isoToLocal(currentLeave.isoString?.startTime, mycontext.country),
                                        ),
                                        getCurrentCountryDate(mycontext.country?.zoneName),
                                    ) <= -1 ||
                                        DateDifference(
                                            getCurrentCountryDate(mycontext.country?.zoneName),
                                            toAWSDateFormat(
                                                isoToLocal(currentLeave.isoString?.endTime, mycontext.country),
                                            ),
                                        ) >= 1) &&
                                    leaveType === 'approved' && (
                                        <Button
                                            id="deny-time-off"
                                            className={classes.btn}
                                            variant="contained"
                                            color="primary"
                                            disableRipple={true}
                                            onClick={() => handleDeny()}
                                        >
                                            Deny Time-Off
                                        </Button>
                                    )}
                                {((mycontext.isApprover &&
                                    mycontext.EmployeeId !== currentLeave.Employee?.employeeId) ||
                                    mycontext.isAdmin) &&
                                    (DateDifference(
                                        toAWSDateFormat(
                                            isoToLocal(currentLeave.isoString?.startTime, mycontext.country),
                                        ),
                                        getCurrentCountryDate(mycontext.country?.zoneName),
                                    ) <= 0 ||
                                        DateDifference(
                                            getCurrentCountryDate(mycontext.country?.zoneName),
                                            toAWSDateFormat(
                                                isoToLocal(currentLeave.isoString?.endTime, mycontext.country),
                                            ),
                                        ) >= 0) &&
                                    leaveType === 'pending' && (
                                        <Button
                                            id="deny-time-off"
                                            className={classes.btnOutlined}
                                            variant="outlined"
                                            color="primary"
                                            disableRipple={true}
                                            onClick={() => handleDeny()}
                                        >
                                            Deny
                                        </Button>
                                    )}
                                {((mycontext.isApprover &&
                                    mycontext.EmployeeId !== currentLeave.Employee?.employeeId) ||
                                    mycontext.isAdmin) &&
                                    (DateDifference(
                                        toAWSDateFormat(
                                            isoToLocal(currentLeave.isoString?.startTime, mycontext.country),
                                        ),
                                        getCurrentCountryDate(mycontext.country?.zoneName),
                                    ) <= 0 ||
                                        DateDifference(
                                            getCurrentCountryDate(mycontext.country?.zoneName),
                                            toAWSDateFormat(
                                                isoToLocal(currentLeave.isoString?.endTime, mycontext.country),
                                            ),
                                        ) >= 0) &&
                                    leaveType === 'pending' && (
                                        <Button
                                            id="approve-time-off"
                                            className={classes.btnFilled}
                                            variant="contained"
                                            color="primary"
                                            disableRipple={true}
                                            onClick={() => handleApprove()}
                                        >
                                            Approve
                                        </Button>
                                    )}

                                {mycontext.EmployeeId === currentLeave.Employee?.employeeId &&
                                    !mycontext.isAdmin &&
                                    (DateDifference(
                                        toAWSDateFormat(
                                            isoToLocal(currentLeave.isoString?.startTime, mycontext.country),
                                        ),
                                        getCurrentCountryDate(mycontext.country?.zoneName),
                                    ) <= 0 ||
                                        DateDifference(
                                            getCurrentCountryDate(mycontext.country?.zoneName),
                                            toAWSDateFormat(
                                                isoToLocal(currentLeave.isoString?.endTime, mycontext.country),
                                            ),
                                        ) >= 0) &&
                                    leaveType === 'pending' && (
                                        <Button
                                            className={classes.btnOutlinedCancel}
                                            variant="outlined"
                                            color="primary"
                                            disableRipple={true}
                                            onClick={() => cancelRequest()}
                                        >
                                            Cancel Request
                                        </Button>
                                    )}
                                {mycontext.EmployeeId === currentLeave.Employee?.employeeId &&
                                    !mycontext.isAdmin &&
                                    (DateDifference(
                                        toAWSDateFormat(
                                            isoToLocal(currentLeave.isoString?.startTime, mycontext.country),
                                        ),
                                        getCurrentCountryDate(mycontext.country?.zoneName),
                                    ) <= 0 ||
                                        DateDifference(
                                            getCurrentCountryDate(mycontext.country?.zoneName),
                                            toAWSDateFormat(
                                                isoToLocal(currentLeave.isoString?.endTime, mycontext.country),
                                            ),
                                        ) >= 0) &&
                                    leaveType === 'pending' && (
                                        <Button
                                            className={classes.btnFilledEdit}
                                            variant="contained"
                                            color="primary"
                                            disableRipple={true}
                                            onClick={() => editRequest()}
                                        >
                                            Edit Request
                                        </Button>
                                    )}
                            </div>
                        </div>
                    )}
                </Modal>
                <Modal open={openA} setOpen={setOpenA} onModalClosed={setOpenA} title={modalTitle}>
                    <DenyTimeOff />
                </Modal>
                <Modal
                    open={openC}
                    setOpen={setOpenC}
                    onModalClosed={setOpenC}
                    title={ToStringFormat(currentDate, 'MMM Do YYYY') + ' ( ' + toDayFormat(currentDate) + ' )'}
                >
                    <div className={classes.eventDivModal} id="dateDiv">
                        {type === ''
                            ? myleaves.map((obj: any, i: number) =>
                                  obj.date ===
                                  moment(currentDate)
                                      .format('DD-MM-YYYY')
                                      .toString() ? (
                                      <div
                                          key={i}
                                          className={
                                              obj.leaveStatus === 'denied'
                                                  ? classes.denied
                                                  : obj.leaveStatus === 'approved'
                                                  ? classes.approved
                                                  : classes.pending
                                          }
                                          onClick={() => handleLeave(obj)}
                                      >
                                          <Avatar
                                              style={{ height: 12, width: 12, margin: '0 5px' }}
                                              src={
                                                  obj.Employee?.profileImage === null
                                                      ? logo
                                                      : obj.Employee?.profileImage
                                              }
                                              alt={obj.Employee?.firstName}
                                          />
                                          {obj.Employee?.firstName}&nbsp;
                                          {(obj.startTime === 'null' || obj.startTime === null) &&
                                          (obj.endTime === 'null' || obj.endTime === null) ? (
                                              <span
                                                  className={
                                                      obj.leaveStatus === 'denied'
                                                          ? classes.deniedText
                                                          : obj.leaveStatus === 'approved'
                                                          ? classes.approvedText
                                                          : classes.pendingText
                                                  }
                                              >
                                                  Full Day
                                              </span>
                                          ) : (
                                              <span
                                                  className={
                                                      obj.leaveStatus === 'denied'
                                                          ? classes.deniedText
                                                          : obj.leaveStatus === 'approved'
                                                          ? classes.approvedText
                                                          : classes.pendingText
                                                  }
                                              >
                                                  {isoToLocalTime(obj?.isoString?.startTime, mycontext.country)}
                                                  &nbsp;-&nbsp;
                                                  {isoToLocalTime(obj?.isoString?.endTime, mycontext.country)}
                                              </span>
                                          )}
                                      </div>
                                  ) : null,
                              )
                            : myleaves
                                  .filter((l: any) => l.leaveStatus === type)
                                  .map((obj: any, i: number) =>
                                      obj.date ===
                                      moment(currentDate)
                                          .format('DD-MM-YYYY')
                                          .toString() ? (
                                          <div
                                              key={i}
                                              className={
                                                  obj.leaveStatus === 'denied'
                                                      ? classes.denied
                                                      : obj.leaveStatus === 'approved'
                                                      ? classes.approved
                                                      : classes.pending
                                              }
                                              onClick={() => handleLeave(obj)}
                                          >
                                              <Avatar
                                                  style={{ height: 12, width: 12, margin: '0 5px' }}
                                                  src={
                                                      obj.Employee?.profileImage === null
                                                          ? logo
                                                          : obj.Employee?.profileImage
                                                  }
                                                  alt={obj.Employee?.firstName}
                                              />
                                              {obj.Employee?.firstName}&nbsp;
                                              {(obj.startTime === 'null' || obj.startTime === null) &&
                                              (obj.endTime === 'null' || obj.endTime === null) ? (
                                                  <span
                                                      className={
                                                          obj.leaveStatus === 'denied'
                                                              ? classes.deniedText
                                                              : obj.leaveStatus === 'approved'
                                                              ? classes.approvedText
                                                              : classes.pendingText
                                                      }
                                                  >
                                                      Full Day
                                                  </span>
                                              ) : (
                                                  <span
                                                      className={
                                                          obj.leaveStatus === 'denied'
                                                              ? classes.deniedText
                                                              : obj.leaveStatus === 'approved'
                                                              ? classes.approvedText
                                                              : classes.pendingText
                                                      }
                                                  >
                                                      {isoToLocalTime(obj?.isoString?.startTime, mycontext.country)}
                                                      &nbsp;-&nbsp;
                                                      {isoToLocalTime(obj?.isoString?.endTime, mycontext.country)}
                                                  </span>
                                              )}
                                          </div>
                                      ) : null,
                                  )}
                        {type === '' || type === 'holidays'
                            ? holidays.map((h: any, j: number) =>
                                  h.date ===
                                  moment(currentDate)
                                      .format('DD-MM-YYYY')
                                      .toString() ? (
                                      <div key={j} className={classes.holidayDiv}>
                                          {h.holidayName}&nbsp;
                                      </div>
                                  ) : null,
                              )
                            : null}
                    </div>
                </Modal>
                <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                <Hidden smDown>
                    <div className={classes.main}>
                        <div className={classes.top}>
                            <Typography className={classes.heading} data-testid="cal">
                                Calendar
                            </Typography>
                        </div>
                        <div className={classes.bottom}>
                            <div className={classes.calendar}>
                                <div className={classes.calendarHeader}>
                                    <div className={classes.month}>
                                        {/* <ArrowLeftOutlined
                                            data-testid="leftarrow"
                                            className={classes.iconCol}
                                            onClick={() => setValue(prevMonth())}
                                            id="leftarrow
                                       "
                                        /> */}
                                        <img
                                            src={s3Bucket + images.LeftArrow}
                                            alt="left_arrow"
                                            onClick={() => setValue(prevMonth())}
                                            id="leftarrow"
                                            className={classes.iconCol1}
                                        />
                                        <div className={classes.date}>
                                            <p>
                                                {value.format('MMMM')}&nbsp;
                                                {value.format('YYYY')}
                                            </p>
                                        </div>
                                        <img
                                            src={s3Bucket + images.RightArrow}
                                            alt="right_arrow"
                                            onClick={() => setValue(nextMonth())}
                                            id="rightarrow"
                                            className={classes.iconCol2}
                                        />
                                        {/* <ArrowRightOutlined
                                            data-testid="rightarrow"
                                            className={classes.iconCol}
                                            onClick={() => setValue(nextMonth())}
                                            id="rightarrow"
                                        /> */}
                                    </div>
                                    <div className={classes.labels} data-testid="approved">
                                        {type === '' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#3D49B6"
                                                onClick={() => setType('')}
                                            >
                                                View All
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#3D49B6"
                                                colorLight="#E2E5FF"
                                                onClick={() => setType('')}
                                            >
                                                View All
                                            </ColorChip>
                                        )}
                                        {type === 'approved' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#088843"
                                                onClick={() => setType('approved')}
                                            >
                                                Approved Time-Off
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#088843"
                                                colorLight="#DAF6E7"
                                                onClick={() => setType('approved')}
                                            >
                                                Approved Time-Off
                                            </ColorChip>
                                        )}
                                        {type === 'denied' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#CE3B3B"
                                                onClick={() => setType('denied')}
                                            >
                                                Denied Time-Off
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#CE3B3B"
                                                colorLight="#FAD9D9"
                                                onClick={() => setType('denied')}
                                            >
                                                Denied Time-Off
                                            </ColorChip>
                                        )}
                                        {type === 'pending' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#F2890D"
                                                onClick={() => setType('pending')}
                                            >
                                                {mycontext.isAdmin || mycontext.isApprover
                                                    ? 'Pending Request'
                                                    : 'My Pending Request'}
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#F2890D"
                                                colorLight="#FEF2DA"
                                                onClick={() => setType('pending')}
                                            >
                                                {mycontext.isAdmin || mycontext.isApprover
                                                    ? 'Pending Request'
                                                    : 'My Pending Request'}
                                            </ColorChip>
                                        )}
                                        {type === 'holidays' ? (
                                            <ColorChip
                                                colorDark="#fff"
                                                colorLight="#695FDE"
                                                onClick={() => setType('holidays')}
                                            >
                                                Organization Holidays
                                            </ColorChip>
                                        ) : (
                                            <ColorChip
                                                colorDark="#695FDE"
                                                colorLight="#E0DEFF"
                                                onClick={() => setType('holidays')}
                                            >
                                                Organization Holidays
                                            </ColorChip>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.weekDays}>
                                    <div>Sunday</div>
                                    <div>Monday</div>
                                    <div>Tuesday</div>
                                    <div>Wednesday</div>
                                    <div>Thursday</div>
                                    <div>Friday</div>
                                    <div>Saturday</div>
                                </div>
                                {calendar.map((week: any, i: number) => (
                                    <div key={i} className={classes.days}>
                                        {week.map((d: any, j: number) => (
                                            <div
                                                key={j}
                                                className={
                                                    dayStyles(d, value) === 'before'
                                                        ? classes.prevDate
                                                        : dayStyles(d, value) === 'today'
                                                        ? classes.today
                                                        : classes.otherDay
                                                }
                                                onClick={() => !beforeToday(d) && setValue(d)}
                                            >
                                                <div
                                                    className={
                                                        thisMonth() && dayStyles(d, value) === 'today'
                                                            ? classes.currentDay
                                                            : classes.laterDay
                                                    }
                                                >
                                                    {d.format('DD').toString()}
                                                </div>
                                                <div className={classes.eventDiv} id="dateDiv">
                                                    {type === ''
                                                        ? myleaves.map((obj: any, i: number) =>
                                                              obj.date === d.format('DD-MM-YYYY').toString() ? (
                                                                  <div
                                                                      key={obj.leaveId + i}
                                                                      className={
                                                                          obj.leaveStatus === 'denied'
                                                                              ? classes.denied
                                                                              : obj.leaveStatus === 'approved'
                                                                              ? classes.approved
                                                                              : classes.pending
                                                                      }
                                                                      onClick={() => handleLeave(obj)}
                                                                  >
                                                                      <Avatar
                                                                          style={{
                                                                              height: 12,
                                                                              width: 12,
                                                                              margin: '0 5px',
                                                                          }}
                                                                          src={
                                                                              obj.Employee?.profileImage === null
                                                                                  ? logo
                                                                                  : obj.Employee?.profileImage
                                                                          }
                                                                          alt={obj.Employee?.firstName}
                                                                      />
                                                                      {obj.Employee?.firstName}&nbsp;
                                                                      {(obj.startTime === 'null' ||
                                                                          obj.endTime === null) &&
                                                                      (obj.endTime === 'null' ||
                                                                          obj.endTime === null) ? (
                                                                          <span
                                                                              className={
                                                                                  obj.leaveStatus === 'denied'
                                                                                      ? classes.deniedText
                                                                                      : obj.leaveStatus === 'approved'
                                                                                      ? classes.approvedText
                                                                                      : classes.pendingText
                                                                              }
                                                                          >
                                                                              Full Day
                                                                          </span>
                                                                      ) : (
                                                                          <span
                                                                              className={
                                                                                  obj.leaveStatus === 'denied'
                                                                                      ? classes.deniedText
                                                                                      : obj.leaveStatus === 'approved'
                                                                                      ? classes.approvedText
                                                                                      : classes.pendingText
                                                                              }
                                                                          >
                                                                              {isoToLocalTime(
                                                                                  obj.isoString?.startTime,
                                                                                  mycontext.country,
                                                                              )}
                                                                              &nbsp;-&nbsp;
                                                                              {isoToLocalTime(
                                                                                  obj.isoString?.endTime,
                                                                                  mycontext.country,
                                                                              )}
                                                                          </span>
                                                                      )}
                                                                  </div>
                                                              ) : null,
                                                          )
                                                        : myleaves
                                                              .filter((l: any) => l.leaveStatus === type)
                                                              .map((obj: any, i: number) =>
                                                                  obj.date === d.format('DD-MM-YYYY').toString() ? (
                                                                      <div
                                                                          key={obj.leaveId + i}
                                                                          className={
                                                                              obj.leaveStatus === 'denied'
                                                                                  ? classes.denied
                                                                                  : obj.leaveStatus === 'approved'
                                                                                  ? classes.approved
                                                                                  : classes.pending
                                                                          }
                                                                          onClick={() => handleLeave(obj)}
                                                                      >
                                                                          <Avatar
                                                                              style={{
                                                                                  height: 12,
                                                                                  width: 12,
                                                                                  margin: '0 5px',
                                                                              }}
                                                                              src={
                                                                                  obj.Employee?.profileImage === null
                                                                                      ? logo
                                                                                      : obj.Employee?.profileImage
                                                                              }
                                                                              alt={obj.Employee?.firstName}
                                                                          />
                                                                          {obj.Employee?.firstName}&nbsp;
                                                                          {(obj.startTime === 'null' ||
                                                                              obj.startTime === null) &&
                                                                          (obj.endTime === 'null' ||
                                                                              obj.endTime === null) ? (
                                                                              <span
                                                                                  className={
                                                                                      obj.leaveStatus === 'denied'
                                                                                          ? classes.deniedText
                                                                                          : obj.leaveStatus ===
                                                                                            'approved'
                                                                                          ? classes.approvedText
                                                                                          : classes.pendingText
                                                                                  }
                                                                              >
                                                                                  Full Day
                                                                              </span>
                                                                          ) : (
                                                                              <span
                                                                                  className={
                                                                                      obj.leaveStatus === 'denied'
                                                                                          ? classes.deniedText
                                                                                          : obj.leaveStatus ===
                                                                                            'approved'
                                                                                          ? classes.approvedText
                                                                                          : classes.pendingText
                                                                                  }
                                                                              >
                                                                                  {isoToLocalTime(
                                                                                      obj.isoString?.startTime,
                                                                                      mycontext.country,
                                                                                  )}
                                                                                  &nbsp;-&nbsp;
                                                                                  {isoToLocalTime(
                                                                                      obj.isoString?.endTime,
                                                                                      mycontext.country,
                                                                                  )}
                                                                              </span>
                                                                          )}
                                                                      </div>
                                                                  ) : null,
                                                              )}
                                                    {type === '' || type === 'holidays'
                                                        ? holidays.map((h: any, j: number) =>
                                                              h.date === d.format('DD-MM-YYYY').toString() ? (
                                                                  <div
                                                                      key={h.holidayId + j}
                                                                      className={classes.holidayDiv}
                                                                  >
                                                                      {h.holidayName}&nbsp;
                                                                  </div>
                                                              ) : null,
                                                          )
                                                        : null}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Hidden>
                <Hidden smUp>
                    <FormControl
                        variant="outlined"
                        style={{ width: '100%', background: theme.palette.background.paper, marginBottom: 10 }} //height: 40,
                        margin="dense"
                    >
                        <Select
                            value={type === '' ? allTypes[0] : type}
                            className={classes.inputSelectStyle}
                            onChange={(e: any) =>
                                setType((e.target.value as string) === 'view all' ? '' : e.target.value)
                            }
                            startAdornment={
                                <InputAdornment position="start">
                                    <img
                                        src={FilterIcon}
                                        alt="filter"
                                        style={{
                                            width: 15,
                                            height: 15,
                                            background: theme.palette.background.paper,
                                        }}
                                    />
                                </InputAdornment>
                            }
                            style={{ background: theme.palette.background.paper }}
                        >
                            {allTypes.map((t: any, i: number) => (
                                <MenuItem key={i} value={t}>
                                    {t}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            backgroundColor: theme.palette.background.paper,
                            padding: 10,
                            marginBottom: 10,
                            fontFamily: 'Nunito Sans',
                            fontSize: 12,
                            color: '#202020',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                            <span
                                style={{
                                    height: 8,
                                    width: 8,
                                    borderRadius: '50%',
                                    backgroundColor: '#088843',
                                    marginRight: 5,
                                }}
                            ></span>{' '}
                            <span
                                style={{ color: theme.palette.text.primary, fontFamily: theme.typography.fontFamily }}
                            >
                                Approved Time-Off
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                            <span
                                style={{
                                    height: 8,
                                    width: 8,
                                    borderRadius: '50%',
                                    backgroundColor: '#CE3B3B',
                                    marginRight: 5,
                                }}
                            ></span>{' '}
                            <span
                                style={{ color: theme.palette.text.primary, fontFamily: theme.typography.fontFamily }}
                            >
                                Denied Time-Off
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                            <span
                                style={{
                                    height: 8,
                                    width: 8,
                                    borderRadius: '50%',
                                    backgroundColor: '#F2890D',
                                    marginRight: 5,
                                }}
                            ></span>{' '}
                            <span
                                style={{ color: theme.palette.text.primary, fontFamily: theme.typography.fontFamily }}
                            >
                                {mycontext.isAdmin || mycontext.isApprover ? 'Pending Request' : 'My Pending Request'}
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                            <span
                                style={{
                                    height: 8,
                                    width: 8,
                                    borderRadius: '50%',
                                    backgroundColor: '#695FDE',
                                    marginRight: 5,
                                }}
                            ></span>{' '}
                            <span
                                style={{ color: theme.palette.text.primary, fontFamily: theme.typography.fontFamily }}
                            >
                                Organization Holidays
                            </span>
                        </div>
                    </div>
                    <div style={{ width: '100%', background: '#fff', display: 'flex', flexDirection: 'column' }}>
                        <div className={classes.monthMobile}>
                            <img
                                src={s3Bucket + images.LeftArrow}
                                alt="left_arrow"
                                onClick={() => setValue(prevMonth())}
                                id="leftarrow"
                                className={classes.iconCol1}
                            />
                            <div className={classes.date}>
                                <p>
                                    {value.format('MMMM')}&nbsp;
                                    {value.format('YYYY')}
                                </p>
                            </div>
                            <img
                                src={s3Bucket + images.RightArrow}
                                alt="right_arrow"
                                onClick={() => setValue(nextMonth())}
                                id="rightarrow"
                                className={classes.iconCol2}
                            />
                        </div>
                        <div className={classes.weekDaysMobile}>
                            <div>Sun</div>
                            <div>Mon</div>
                            <div>Tue</div>
                            <div>Wed</div>
                            <div>Thu</div>
                            <div>Fri</div>
                            <div>Sat</div>
                        </div>
                        {calendar.map((week: any, i: number) => (
                            <div key={i} className={classes.daysMobile}>
                                {week.map((d: any, j: number) => (
                                    <div
                                        key={j}
                                        className={
                                            dayStyles(d, value) === 'before'
                                                ? classes.prevDateM
                                                : dayStyles(d, value) === 'today'
                                                ? classes.todayM
                                                : classes.otherDayM
                                        }
                                        onClick={() => !beforeToday(d) && setValue(d)}
                                    >
                                        <div
                                            className={
                                                thisMonth() && dayStyles(d, value) === 'today'
                                                    ? classes.currentDay
                                                    : classes.laterDay
                                            }
                                        >
                                            {d.format('DD').toString()}
                                        </div>
                                        <div
                                            className={classes.eventDivMobile}
                                            id="dateDiv"
                                            onClick={() => handleMobileModal(d)}
                                        >
                                            {type === ''
                                                ? myleaves.map((obj: any, i: number) =>
                                                      obj.date === d.format('DD-MM-YYYY').toString() ? (
                                                          <div
                                                              key={obj.leaveId + i}
                                                              className={
                                                                  obj.leaveStatus === 'denied'
                                                                      ? classes.deniedMobile
                                                                      : obj.leaveStatus === 'approved'
                                                                      ? classes.approvedMobile
                                                                      : classes.pendingMobile
                                                              }
                                                              //   onClick={() => handleLeave(obj)}
                                                          ></div>
                                                      ) : null,
                                                  )
                                                : myleaves
                                                      .filter((l: any) => l.leaveStatus === type)
                                                      .map((obj: any, i: number) =>
                                                          obj.date === d.format('DD-MM-YYYY').toString() ? (
                                                              <div
                                                                  key={obj.leaveId + i}
                                                                  className={
                                                                      obj.leaveStatus === 'denied'
                                                                          ? classes.deniedMobile
                                                                          : obj.leaveStatus === 'approved'
                                                                          ? classes.approvedMobile
                                                                          : classes.pendingMobile
                                                                  }
                                                                  //   onClick={() => handleLeave(obj)}
                                                              ></div>
                                                          ) : null,
                                                      )}
                                            {type === '' || type === 'holidays'
                                                ? holidays.map((h: any, j: number) =>
                                                      h.date === d.format('DD-MM-YYYY').toString() ? (
                                                          <div
                                                              key={h.holidayId + j}
                                                              className={classes.holidayDivMobile}
                                                              //   onClick={() => handleLeave(obj)}
                                                          ></div>
                                                      ) : null,
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </Hidden>
            </>
        );
    }
}
