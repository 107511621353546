/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import DisplayLeaves from './DisplayLeaves';
import AddLeave from './AddLeave';
import { useSelector, useDispatch } from 'react-redux';
import { updateActiveTab } from '../Redux/activeTab';
import { LoaderPage } from '../Loader/loader';
import ErrorPage from '../Error/ErrorPage';

import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import AuthContext from '../../context/AuthContext';

export default function EmployeeDashboard() {
    const dispatch = useDispatch();
    const state: any = useSelector(state => state);
    const userSubscriptionDetail = state?.subscription.userSubscriptions;
    const [step, setStep] = useState<number>(0);
    const mycontext = useContext(AuthContext);
    useEffect(() => {
        dispatch(updateActiveTab('/dashboard'));
    }, []);
    /* istanbul ignore next */
    if (
        state.orgEmployees.status === 'loading' ||
        state.holidays.status === 'loading' ||
        state.formDetails.status === 'loading' ||
        state.orgLeaves.status === 'loading' ||
        // props.employee.loading ||
        state.employeeLeaves.loading ||
        state.empOnLeaves.status === 'loading' ||
        state.settings.status === 'loading'
    ) {
        return <LoaderPage />;
    }
    if (
        state.orgEmployees.status === 'failed' ||
        state.holidays.status === 'failed' ||
        state.formDetails.status === 'failed' ||
        state.orgLeaves.status === 'failed' ||
        // props.employee.error ||
        state.employeeLeaves.error ||
        state.empOnLeaves.status === 'failed' ||
        state.settings.status === 'failed'
    ) {
        return <ErrorPage title="Server Down" />;
    }
    if (step === 1) {
        return mycontext.subscriptionExpired === 'cancelled' &&
            Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
            <SubscriptionHardExpired
                content="Subscription expired, Please contact your admin to renew subscription"
                showRenew={false}
            />
        ) : (
            <AddLeave
                setStep={setStep}
                holidays={state?.holidays?.holidays || []}
                employeeLeaves={state?.empOnLeaves?.empOnleaves || []}
                organizationLeaves={state?.orgLeaves?.orgleaves || []}
                data={state?.formDetails?.details}
                leaves={state?.employeeLeaves?.leaves || []}
                // leaves={state.employeeLeaves?.data?.timeoffdb_Leaves || []}
                workWeek={state.settings?.settings?.workWeek}
                userSubscriptionDetails={userSubscriptionDetail.usermanagementdb_tenant_employee[0]}
            />
        );
    }
    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        <SubscriptionHardExpired
            content="Subscription expired, Please contact your admin to renew subscription"
            showRenew={false}
        />
    ) : (
        <DisplayLeaves
            title="Personal Time-Offs Status"
            leaves={state?.employeeLeaves?.leaves || []}
            // leaves={props.employee?.data?.timeoffdb_Leaves || []}
            setStep={setStep}
            holidays={state?.holidays?.holidays}
            approvers={state?.orgEmployees?.approvers}
            loading={false}
            orgEmployees={state?.orgEmployees?.employees}
        />
    );
}
