import { gql } from '@apollo/client';

export function getFormDetailsLeaveType(organizationId: string) {
    return gql`
        subscription LeaveType {
            timeoffdb_LeaveType(where: {organizationId: {_eq: "${organizationId}"}, forYear: { _eq: "${new Date().getFullYear()}" }, active: {_eq: true}}) {
                leaveTypeName
                daysPerYear
                unlimitedDays
                approvalRequired
            }
        }
    `;
}

export function getFormDetailsOrgEmployees(organizationId: string) {
    return gql`
        subscription LeaveType {
            usermanagementdb_organization_employee(where: {organizationId: {_eq: "${organizationId}"}}) {
                Employee {
                    employeeId
                    firstName
                    lastName
                }
            }
        }
    `;
}

export function getFormDetailsTotalHours(organizationId: string, employeeId: string) {
    return gql`
        subscription LeaveType {
            timeoffdb_get_totalhours(args: {employeeid: "${employeeId}", organizationid: "${organizationId}"}) {
                employeeId
                leaveType
                totalHoursLeave
                workingHours
            }
        }
    `;
}

export function getFormDetailsTotalHoursOrg(organizationId: string) {
    return gql`
        subscription LeaveType {
            timeoffdb_get_totalhours_org(args: {organizationid: "${organizationId}"}) {
                employeeId
                leaveType
                totalHoursLeave
                workingHours
            }
        }
    `;
}
