import {
    createSlice,
    // createAsyncThunk
} from '@reduxjs/toolkit';
// import { fetchAddLeaveFormDetails2 } from 'helpers/hasuraapi';

// export const getFormDetails: any = createAsyncThunk('getFormDetails/getFormDetailsSlice', async (user: any) => {
//     const data: any = await fetchAddLeaveFormDetails2(user.organizationId, user.employeeId, user.token);
//     const jsonData: any = await data.json();
//     return jsonData.data;
// });

export const getFormDetailsSlice = createSlice({
    name: 'getFormDetails',
    initialState: { details: {}, status: 'loading' },
    reducers: {
        fetchFormDetails: (state, action) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                state.details = action.payload;
            }
        },
    },
    // extraReducers: {
    //     [getFormDetails.pending]: state => {
    //         state.status = 'loading';
    //     },
    //     [getFormDetails.fulfilled]: (state, action) => {
    //         state.details = action.payload;
    //         state.status = 'success';
    //     },
    //     [getFormDetails.rejected]: state => {
    //         state.status = 'failed';
    //     },
    // },
});

export const { fetchFormDetails } = getFormDetailsSlice.actions;
export default getFormDetailsSlice.reducer;
