import React, { useState, useEffect, useContext } from 'react';
import useStyles from './RegisterStyle';
import useStylesNew from '../Admin/newRegisterStyles';
// import welcomeRegister from './welcome_register.png';
// import udyamo from './udyamo.png';
import jwt_decode from 'jwt-decode';
import { Grid, Box, Hidden, Avatar, FormLabel, Paper, useTheme } from '@material-ui/core';
import AuthContext from '../../context/AuthContext';
import {
    getEmployeeRole,
    getEmployee,
    fetchOrganizationSettings,
    deleteTeamplusUser,
    // getSubscriptionExpired,
    insertSubscription,
    getTenantId,
    deleteSubscription,
    removeAllTenantLicenses,
    UpdateUserLicenses,
    getTenantEmployees,
    getTenantIdForClient,
    getTenantSubscription,
} from '../../helpers/hasuraapi';
import { getOrgInitial } from '../../helpers/getInitials';
import { useHistory, useLocation } from 'react-router-dom';
import { clearUserStatus } from '../Redux/userStatusReducer';
import { useDispatch } from 'react-redux';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';
import { Auth } from 'aws-amplify';
// import { messaging } from '../../init-fcm';
// import { saveDeviceToken } from '../WebNotification/Notification';
import { PopUp } from 'components/Modal/Modal';
import { newurl } from '../../helpers/hasuraapi';
import { fetchQuestion } from 'helpers/hasuraapi3';
import { clearReducers } from 'components/Redux/StandupRedux';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { TeamplusLoader } from 'components/Loader/loader';
import { lambdaMsSubscription } from 'helpers/msSubscriptionApi';
import { joinedTeams } from 'components/Admin/graph';
/* istanbul ignore next */
export const TakeMeBack = (decoded: any) => {
    const history = useHistory();
    const { instance } = useMsal();
    const mycontext = useContext(AuthContext);

    const handleBack = () => {
        if (decoded.decoded.isMicrosoft) {
            mycontext.logout();
            instance.logout();
        } else {
            Auth.signOut();
            history.goBack();
        }
    };
    return (
        <p
            onClick={() => handleBack()}
            style={{
                fontSize: 12,
                color: '#7A7A7A',
                fontFamily: 'Nunito Sans',
                fontWeight: 500,
                cursor: 'pointer',
                marginBottom: 30,
            }}
        >
            Take me back
        </p>
    );
};

export default function UserOrganizations() {
    const classes = useStyles();
    const classesNew = useStylesNew();
    const [organizations, setOrganizations] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [id, setId] = useState('');
    const [token, setToken] = useState('');
    const [newUser, setNewUser] = useState(false);
    const [ogUrl, setOgUrl] = useState('');
    const [error, setError] = useState<boolean>(false);
    const [alert2, setAlert2] = useState<boolean>(false);
    const [msteams, setmsteams] = useState(0);
    const [teamplusT, setteamplusT] = useState(0);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const mycontext = useContext(AuthContext);
    const history = useHistory();
    const location: any = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const decoded = jwt_decode(mycontext.token);

    const [subscriptionTokenError, setSubscriptionTokenError] = useState<boolean>(
        location.state.invalidSubscriptionToken || false,
    );
    const [alreadyPurchasedSubscriptionError, setAlreadyPurchasedSubscriptionError] = useState<boolean>(
        location.state.alreadyPurchasedSubscriptionError || false,
    );
    const [noSuperAdmin, setNoSuperAdmin] = useState<boolean>(false);
    // const [show, setShow] = useState<boolean>(false);
    const { instance, accounts } = useMsal();
    const loginRequest = {
        scopes: ['User.ReadBasic.All', 'Group.Read.All', 'ChannelMember.Read.All'],
    };
    const request = {
        ...loginRequest,
        account: accounts[0],
    };
    const isAuthenticated = useIsAuthenticated();
    // const { id: string, token: string } = location.state;
    /* istanbul ignore next */
    useEffect(() => {
        const getOrganizations = async () => {
            // console.log('mycontext', mycontext);
            try {
                if (location.state) {
                    const role = await getEmployeeRole(location?.state.id, location?.state.token);
                    setteamplusT(role.length);
                    setId(location.state.id);
                    setToken(location.state.token);
                    setError(false);
                    // if (role.error) {
                    //     setError(true);
                    //     mycontext.logout();
                    // }
                    setOrganizations(role);
                    if (role.length < 1) {
                        setIsDelete(true);
                    }
                } else {
                    try {
                        setOgUrl(window.location.href.split('#')[1]);
                        const currentUser = await Auth.currentAuthenticatedUser();
                        const token: any = currentUser.signInUserSession.idToken.jwtToken;
                        if (token) {
                            const decoded: any = jwt_decode(token);
                            const Tid = await getTenantId(decoded.tid, token);
                            // console.log('THIS IS DECODED', decoded);
                            setId(decoded.sub);
                            setToken(token);
                            const role = await getEmployeeRole(decoded.sub, token);
                            if (role.error) {
                                mycontext.logout();
                            }
                            setOrganizations(role);
                            if (role.length < 1) {
                                setIsDelete(true);
                                setNewUser(true);
                            }
                            const data = await getEmployee(decoded.oid, token);
                            // const standup: any = await fetchQuestion(organization.Organization.organizationId, token);
                            // mycontext.addStandupTempId(standup?.standupTempId);
                            mycontext.login({
                                username: decoded.email,
                                EmployeeId: decoded.sub,
                                fname: '',
                                lname: '',
                                // isAdmin: data.isAdmin === 'admin' ? true : false,
                                // isApprover: data.isapprover.toLowerCase() === 'Yes'.toLowerCase() ? true : false,
                                isLogedIn: false,
                                joining: '',
                                profile: '',
                                // teamname: data.teamname,
                                statustype: '',
                                email: decoded.email,
                                isGoogle: false,
                                token: token,
                                uuid: decoded.sub,
                                refreshToken: currentUser.signInUserSession.refreshToken.token,
                                standupTempId: '',
                                tenantId: mycontext.tenantId,
                                tenantTableId: Tid[0]?.id,
                                // isSuperAdmin: mycontext.isSuperAdmin,
                                isSuperAdmin: data?.tenant_employees[0]?.role === 'superadmin' ? true : false,
                            });
                        }
                    } catch (e) {
                        mycontext.logout();
                    }
                }
            } catch (e) {
                mycontext.logout();
            }
        };
        getOrganizations();
        if (isAuthenticated) {
            instance.acquireTokenSilent(request).then(async (response: any) => {
                const data = await joinedTeams(response.accessToken);

                // setmsteams(data.value.length);
                setmsteams(data.length);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    /* istanbul ignore next */
    // const Notify = async () => {
    //     if (Notification.permission === 'default' || Notification.permission === 'denied') {
    //         Notification.requestPermission().then(async function() {
    //             const tokenNotify: any = null;
    //             if (Notification.permission === 'granted') {
    //                 const tokenNotify = await messaging?.getToken();
    //                 // setToken(token);
    //                 // console.log('line 81', token);
    //                 const obj = {
    //                     empId: location.state.id,
    //                     deviceToken: tokenNotify,
    //                     token: location.state.token,
    //                 };
    //                 await saveDeviceToken(obj);
    //             } else {
    //                 const obj = {
    //                     empId: location.state.id,
    //                     deviceToken: tokenNotify,
    //                     token: location.state.token,
    //                 };
    //                 await saveDeviceToken(obj);
    //             }
    //         });
    //     }
    // };

    /* istanbul ignore next */
    const selectOrganization = async (organization: any) => {
        try {
            const tenantIdDatabase = await getTenantId(mycontext.tenantId, location.state.token);
            const clientTenantId = await getTenantIdForClient(mycontext.tenantId, mycontext.token);
            const tenantSubscription = await getTenantSubscription(tenantIdDatabase[0].id, mycontext.token);
            // console.log("clientid:",clientTenantId?.tenantId);
            // console.log("tenant id from local:", location.state.tenantId)
            setIsLoading(true);
            dispatch(clearUserStatus());
            dispatch(clearReducers());
            mycontext.addOrganizationId(organization.Organization.organizationId);
            mycontext.addOrganizationName(organization.Organization.organizationName);
            mycontext.addTenantName(organization?.Organization?.Tenant?.name);
            // mycontext.addOrganizationLogo(organization.Organization.organizationLogo);
            const data: any = await getEmployee(location.state.id, location.state.token);
            let subscriptionStoreObject;
            let subscriptionDetails;
            let storeSubscription;
            let employeeIdArrayList: any;
            const employeeIdArray: any = [];
            // console.log('THIS IS DATA', data);
            // console.log(clientTenantId)
            if (
                localStorage.getItem('subscriptionId') ||
                (location?.state.isSignup === true &&
                    location?.state.tenantId === clientTenantId?.tenantId &&
                    tenantSubscription.length === 0)
                // organization?.Organization?.Tenant?.workspaceId === clientTenantId?.tenantId
            ) {
                //store in subscription table
                const role = await getEmployeeRole(location?.state.id, location?.state.token);
                const superAdminRole = role[0]?.Organization.Tenant?.tenant_employees[0]?.role;
                if (superAdminRole !== 'superadmin' && localStorage.getItem('subscriptionId')) {
                    setNoSuperAdmin(true);
                    setIsLoading(false);
                    localStorage.removeItem('subscriptionToken');
                    localStorage.removeItem('subscriptionId');
                    localStorage.removeItem('botType');
                    return;
                }
                if (localStorage.getItem('subscriptionId')) {
                    employeeIdArrayList = await getTenantEmployees(tenantIdDatabase[0].id, mycontext.token);
                    employeeIdArrayList.forEach((employee: any) => {
                        employeeIdArray.push(employee.employeeId);
                    });
                    const bodyContentsFetch = {
                        action: 'FETCH',
                        subscriptionId: localStorage.getItem('subscriptionId'),
                        tenantId: mycontext.tenantId,
                        botType: localStorage.getItem('botType'),
                    };
                    // console.log('body content fetch', bodyContentsFetch);
                    const subscriptionDetailsResponse = await lambdaMsSubscription(bodyContentsFetch);
                    // console.log('response from lambda', subscriptionDetailsResponse);
                    if (!subscriptionDetailsResponse?.response.id) {
                        throw new Error('Unable to fetch subscription');
                    }
                    subscriptionDetails = subscriptionDetailsResponse.response;

                    subscriptionStoreObject = {
                        subscriptionId: subscriptionDetails.id,
                        tenantId: tenantIdDatabase[0].id,
                        purchasedBy: location.state.id,
                        status: 'Subscribed',
                        showWarning: '',
                        quantity: subscriptionDetails.quantity,
                        planId: subscriptionDetails.planId,
                        nextBillingAt: '',
                        hardExpireDate: '',
                        currentTermStart: subscriptionDetails.term.startDate,
                        currentTermEnd: subscriptionDetails.term.endDate,
                        autoRenew: subscriptionDetails.autoRenew,
                        token: location.state.token,
                    };
                    storeSubscription = await insertSubscription(subscriptionStoreObject, 'user');
                    if (storeSubscription[0]?.message) {
                        throw new Error('Unable to insert subscription in database');
                    }
                } else {
                    employeeIdArrayList = await getTenantEmployees(tenantIdDatabase[0].id, mycontext.token);
                    employeeIdArrayList.forEach((employee: any) => {
                        employeeIdArray.push(employee.employeeId);
                    });
                    // console.log("length:",employeeIdArrayList.length)
                    const planIds = ['tbtpprivateplan', 'sbtpprivateplan', 'pbtpprivateplan', 'ebtpprivateplan'];
                    for (let i = 0; i < 4; i++) {
                        subscriptionStoreObject = {
                            tenantId: tenantIdDatabase[0].id,
                            purchasedBy: location.state.id,
                            status: 'Subscribed',
                            showWarning: '',
                            quantity: employeeIdArrayList.length,
                            planId: planIds[i],
                            nextBillingAt: '',
                            hardExpireDate: '',
                            currentTermStart: '',
                            currentTermEnd: '',
                            autoRenew: false,
                            token: location.state.token,
                        };
                        if (mycontext.tenantId === clientTenantId.tenantId) {
                            storeSubscription = await insertSubscription(subscriptionStoreObject, 'client');
                            // console.log("subscription data:", storeSubscription);
                            // console.log("subId:",storeSubscription.insert_usermanagementdb_Subscription.returning[0].subscriptionId);
                            if (storeSubscription[0]?.message) {
                                throw new Error('Unable to insert subscription in database');
                            }
                        }
                        const updateObject = {
                            token: mycontext.token,
                            empIdArray: employeeIdArray,
                            subscriptionType: subscriptionStoreObject?.planId?.includes('tbtp')
                                ? 'timeoffSubscriptionId'
                                : subscriptionStoreObject?.planId?.includes('sbtp')
                                ? 'standupSubscriptionId'
                                : subscriptionStoreObject?.planId?.includes('pbtp')
                                ? 'pollSubscriptionId'
                                : 'engageSubscriptionId',
                            subscriptionId:
                                storeSubscription.insert_usermanagementdb_Subscription.returning[0].subscriptionId,
                        };
                        if (employeeIdArray.length <= subscriptionStoreObject?.quantity) {
                            const updateResponse = await UpdateUserLicenses(updateObject);
                            // console.log(updateResponse)
                            if (updateResponse.error) {
                                throw new Error('Unable to assign license');
                            }
                        }
                    }
                }
                if (localStorage.getItem('subscriptionId')) {
                    const bodyContentsActivate = {
                        action: 'ACTIVATE',
                        subscriptionId: subscriptionDetails.id,
                        planId: subscriptionDetails.planId,
                        tenantId: mycontext.tenantId,
                        botType: localStorage.getItem('botType'),
                    };
                    const activateResponse = await lambdaMsSubscription(bodyContentsActivate);
                    // console.log('act', activateResponse);
                    // console.log('activate response', activateResponse);
                    // const activateResponse = {
                    //     message: 'Subscription Activated Successfully',
                    // };
                    if (activateResponse.message !== 'Subscription Activated Successfully') {
                        await deleteSubscription(subscriptionDetails.id, location?.state?.token);
                        throw new Error('Unable to activate subscription');
                    }
                }
                if (localStorage.getItem('oldSubscriptionId') && localStorage.getItem('oldSubscriptionType')) {
                    await deleteSubscription(localStorage.getItem('oldSubscriptionId'), mycontext.token);
                    await removeAllTenantLicenses(
                        tenantIdDatabase[0].id,
                        localStorage.getItem('oldSubscriptionType'),
                        mycontext.token,
                    );
                    localStorage.removeItem('oldSubscriptionId');
                    localStorage.removeItem('oldSubscriptionType');
                }
                if (localStorage.getItem('subscriptionId')) {
                    const updateObject = {
                        token: mycontext.token,
                        empIdArray: employeeIdArray,
                        subscriptionType: subscriptionStoreObject?.planId?.includes('tbtp')
                            ? 'timeoffSubscriptionId'
                            : subscriptionStoreObject?.planId?.includes('sbtp')
                            ? 'standupSubscriptionId'
                            : subscriptionStoreObject?.planId?.includes('pbtp')
                            ? 'pollSubscriptionId'
                            : 'engageSubscriptionId',
                        subscriptionId: subscriptionStoreObject?.subscriptionId,
                    };
                    // tenantEmployees?.tenantEmployees?.forEach((selectedUser: any) => {
                    //     employeeIdArray.push(selectedUser.empId);
                    // });
                    if (employeeIdArray.length <= subscriptionStoreObject?.quantity) {
                        const updateResponse = await UpdateUserLicenses(updateObject);
                        // console.log(updateResponse)
                        if (updateResponse.error) {
                            throw new Error('Unable to assign license');
                        }
                    }
                }
            }
            // const obj = {
            //     organizationId: organization.Organization.organizationId,
            //     token: location.state.token,
            // };
            // const isExpired: any = await getSubscriptionExpired(obj);
            // const status =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].status
            //         : '';
            // const warningDate =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].showWarning
            //         : '';
            // const hardExpireDate =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].hardExpireDate
            //         : '';
            // const subscriptionId =
            //     isExpired.data.usermanagementdb_Subscription.length !== 0
            //         ? isExpired.data.usermanagementdb_Subscription[0].subscriptionId
            //         : '';
            // mycontext.addRole(organization.role);
            // const data: any = await getEmployee(id, token);
            const orgSettings: any = await fetchOrganizationSettings(organization.Organization.organizationId, token);
            // mycontext.updateSubscriptionExpired(subscriptionId);
            // mycontext.updateWarningDate(warningDate);
            // mycontext.updateHardExpireDate(hardExpireDate);
            // mycontext.addSettings(orgSettings?.displayLogo);
            mycontext.addOtherSettings({
                approverCalledAs: orgSettings?.approverCalledAs,
                employeeCalledAs: orgSettings?.employeeCalledAs,
                teamsCalledAs: orgSettings?.teamsCalledAs,
                workingHours: orgSettings?.workingHours,
            });
            mycontext.updateDateFormat(orgSettings?.dateFormat);
            const standup: any = await fetchQuestion(organization.Organization.organizationId, token);
            mycontext.addStandupTempId(standup?.standupTempId);
            mycontext.login({
                username: data.email,
                EmployeeId: data.employeeId,
                fname: data.firstName,
                lname: data.lastName,
                isAdmin: organization.role.toLowerCase() === 'admin'.toLowerCase() ? true : false,
                isApprover: organization.role.toLowerCase() === 'approver'.toLowerCase() ? true : false,
                isLogedIn: true,
                joining: data.joining,
                profile: data.profileImage,
                // teamname: data.teamname,
                statustype: data.status,
                email: data.email,
                isGoogle: false,
                token: location.state.token,
                refreshToken: location.state.refreshToken,
                uuid: location.state.id,
                // subscriptionExpired: status,
                // hardExpireDate: hardExpireDate,
                // warningDate: warningDate,
                // subscriptionId: subscriptionId,
                standupTempId: standup?.standupTempId,
                tenantId: mycontext.tenantId,
                tenantTableId: tenantIdDatabase[0].id,
                // isSuperAdmin: mycontext.isSuperAdmin,
                isSuperAdmin: data?.tenant_employees[0]?.role === 'superadmin' ? true : false,
                // sickdaysused: data.sickdaysused,
                // sickdaystotal: data.sickdaystotal,
                // gender: data.gender,
            });
            setTimeout(() => {
                mycontext.fetchAllData();
            }, 1000);
            if (location.state) {
                if (location.state.isSignup) {
                    mycontext.firstSignIn({
                        initialSignIn: true,
                    });
                    setTimeout(() => {
                        history.push('/userLicense');
                        // Notify();
                    }, 1500);
                } else {
                    if (localStorage.getItem('subscriptionToken')) {
                        localStorage.removeItem('subscriptionToken');
                        localStorage.removeItem('subscriptionId');
                        localStorage.removeItem('botType');
                        setTimeout(() => {
                            history.push('/userLicense');
                        }, 1500);
                    } else {
                        setTimeout(() => {
                            history.push('/userLicense');
                        }, 1500);
                    }
                }
            } else {
                setTimeout(() => {
                    history.push('/userLicense');
                }, 1500);
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
            // mycontext.addOrganizationId(organization.Organization.organizationId);
            // mycontext.addOrganizationName(organization.Organization.organizationName);

            // slackbot-auth code starts here
            if (
                localStorage.getItem('slackChannelId') !== '' &&
                localStorage.getItem('slackWorkspace') !== '' &&
                localStorage.getItem('slackUserId') !== '' &&
                localStorage.getItem('slackUrl') !== ''
            ) {
                const slackChannel = localStorage.getItem('slackChannelId');
                const slackUser = localStorage.getItem('slackUserId');
                const slackUrl = localStorage.getItem('slackUrl');
                const slackWorkspace = localStorage.getItem('slackWorkspace');
                const serviceUrl = localStorage.getItem('serviceUrl');
                const conversationId = localStorage.getItem('conversationId');
                const appId = localStorage.getItem('appId');
                const recipientId = localStorage.getItem('recipientId');
                try {
                    const query = {
                        query:
                            slackChannel === 'msteams'
                                ? `mutation MyMutation {
                            update_usermanagementdb_organization_employee(where: {employeeId: {_eq: "${location.state.id}"}, organizationId: {_eq: "${organization.Organization.organizationId}"}}, _set: {msUserId: "${slackUser}", msTenantId: "${slackWorkspace}", msConversationId: "${conversationId}", msRecipientId: "${recipientId}", isMsAccountLinked: true}) {
                                affected_rows
                            }
                        }`
                                : `mutation MyMutation {
                            update_usermanagementdb_organization_employee(where: {organizationId: {_eq: "${organization.Organization.organizationId}"}, employeeId: {_eq: "${location.state.id}"}}, _set: {slackId: "${slackUser}", isAccountLinked: true, workspaceId: "${slackWorkspace}"}) {
                                affected_rows
                            }
                        }`,
                    };
                    const resData = await fetch(newurl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${location.state.token}`,
                            'x-hasura-user-role': 'user',
                            'x-hasura-user-id': data.employeeId,
                        },
                        body: JSON.stringify(query),
                    });
                    const jsonData = await resData.json();
                    const info = {
                        url: slackUrl,
                        channel: slackChannel,
                        team: slackWorkspace,
                        orgId: organization.Organization.organizationId,
                        serviceUrl: serviceUrl,
                        conversationId: conversationId,
                        appId: appId,
                        recipientId: recipientId,
                    };
                    if (jsonData.data.update_usermanagementdb_organization_employee.affected_rows > 0) {
                        try {
                            const redirectUrl =
                                slackChannel === 'msteams'
                                    ? 'https://kcnbvmvek7.execute-api.us-east-1.amazonaws.com/test/success'
                                    : 'https://4fxm4bwhy0.execute-api.eu-north-1.amazonaws.com/production/success';
                            fetch(redirectUrl, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(info),
                            });
                            localStorage.removeItem('slackChannelId');
                            localStorage.removeItem('slackWorkspace');
                            localStorage.removeItem('slackUserId');
                            localStorage.removeItem('slackUrl');
                            localStorage.removeItem('serviceUrl');
                            localStorage.removeItem('conversationId');
                            localStorage.removeItem('appId');
                            localStorage.removeItem('recipientId');
                        } catch (err) {
                            return err;
                            // console.log('Error while sending message to user: ', err);
                        }
                        if (slackChannel === 'msteams') {
                            window.open('https://teams.microsoft.com/');
                        } else {
                            window.open(`https://slack.com/app_redirect?channel=${slackChannel}`);
                        }
                    }
                } catch (err) {
                    return err;
                    // console.log('Error in sending message to user: ', err);
                }
                // slackbot-auth code ends here
            }
        } catch (e) {
            // console.log('Insert Subscription error', e);
            localStorage.removeItem('subscriptionToken');
            localStorage.removeItem('subscriptionId');
            localStorage.removeItem('botType');
            setError(true);
            setSubscriptionTokenError(false);
            setAlreadyPurchasedSubscriptionError(false);
            // setNoAdminError(false);
            setIsLoading(false);
            return e;
            // mycontext.logout();
        }
    };

    const handleSelectOrganization = async (organization: any) => {
        // console.log("tenantId:", organization);
        await selectOrganization(organization);
    };
    const disableAlreadyPurchasedError = () => {
        localStorage.removeItem('subscriptionToken');
        localStorage.removeItem('subscriptionId');
        localStorage.removeItem('botType');
        setAlreadyPurchasedSubscriptionError(false);
        setNoSuperAdmin(false);
    };
    const handleRegisterOrganization = async () => {
        const decoded: any = jwt_decode(mycontext.token);

        if (decoded.isMicrosoft) {
            if (isAuthenticated) {
                instance
                    .acquireTokenSilent(request)
                    .then(async (response: any) => {
                        // console.log('THIS IS RESPONSE', response);
                        // await setIdToken(response.idToken);
                        // await setAccessToken(response.accessToken);
                        history.push({
                            pathname: '/mymsteams',
                            state: {
                                accessToken: response.accessToken,
                                idToken: response.idToken,
                            },
                        });
                    })
                    .catch(e => {
                        instance.acquireTokenPopup(request).then(async (response: any) => {
                            // console.log('THIS IS RESPONSE', response);
                            // await setIdToken(response.idToken);
                            // await setAccessToken(response.accessToken);
                            history.push({
                                pathname: '/mymsteams',
                                state: {
                                    accessToken: response.accessToken,
                                    idToken: response.idToken,
                                },
                            });
                        });
                    });
            }
        } else {
            if (newUser) {
                history.push({
                    pathname: '/googleNewRegistration',
                    search: '#' + ogUrl,
                });
            } else {
                mycontext.login({
                    uuid: id,
                    token: token,
                });
                history.push({
                    pathname: '/main',
                    state: {
                        pageNumber: 3,
                        takeMeBack: true,
                        token: token,
                    },
                });
            }
        }
    };

    const handleUserAccount = async () => {
        let employeeId = '';
        let token = '';
        if (mycontext.EmployeeId) {
            employeeId = mycontext.EmployeeId;
            token = mycontext.token;
        } else {
            employeeId = location.state.id;
            token = location.state.token;
        }
        const resp = await deleteTeamplusUser(employeeId, token);
        if (resp.error) {
            setMessage('Unable to delete the account!');
            setSeverity('error');
            setAlert2(true);
        } else {
            if (resp.affected_rows > 0) {
                setMessage('Account deleted successfully!');
                setSeverity('success');
                setAlert2(true);
                setTimeout(() => {
                    // Auth.signOut();
                    mycontext.logout();
                    history.push({
                        pathname: '/',
                    });
                    instance.logout();
                }, 3000);
            }
        }
    };

    if (isLoading) {
        return <TeamplusLoader />;
    }
    if (error || subscriptionTokenError || alreadyPurchasedSubscriptionError || noSuperAdmin) {
        return (
            <Box className={classes.root}>
                <Grid container>
                    <Hidden lgDown>
                        <Grid item xs={5} sm={5} className={classes.registerLeft}>
                            <img
                                src={s3Bucket + registerLoginPageImages.userOrgnizationPageImage}
                                alt="login"
                                style={{ width: '70%' }}
                            />
                            <img
                                src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                alt="udyamo"
                                style={{ height: 15, maxWidth: '100%' }}
                            />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} xl={7} className={classesNew.secondGrid}>
                        <Paper style={{ boxShadow: 'none' }} className={classesNew.orgPaperStyle}>
                            <Grid container>
                                <Grid container direction="column">
                                    <Grid container className={classesNew.contentGrid}>
                                        <Grid item xs={12} className={classesNew.paraGridStyle}>
                                            {subscriptionTokenError ? (
                                                <FormLabel className={classesNew.paraContent}>
                                                    <p>
                                                        We couldn&apos;t identify this purchase. Reopen this SaaS
                                                        subscription in the Azure portal or in Microsoft 365 Admin
                                                        Center and select &quot;Configure Account&quot; or &quot;Manage
                                                        Account&quot; again.
                                                    </p>
                                                </FormLabel>
                                            ) : alreadyPurchasedSubscriptionError ? (
                                                <FormLabel className={classesNew.paraContent}>
                                                    <p>
                                                        You have already purchased subscription for this plan, please
                                                        update the quantity if you want more licenses. You will not be
                                                        charged for this transaction.
                                                    </p>
                                                </FormLabel>
                                            ) : noSuperAdmin ? (
                                                <FormLabel className={classesNew.paraContent}>
                                                    <p>
                                                        You are not the superadmin of this tenant so you cannot puchase
                                                        a subscription, you will not be charged for this
                                                    </p>
                                                </FormLabel>
                                            ) : (
                                                <FormLabel className={classesNew.paraContent}>
                                                    <p>Something went wrong, please try again</p>
                                                </FormLabel>
                                            )}
                                        </Grid>
                                        {alreadyPurchasedSubscriptionError || noSuperAdmin ? (
                                            <Grid
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 10,
                                                }}
                                            >
                                                <p
                                                    onClick={() => disableAlreadyPurchasedError()}
                                                    style={{
                                                        fontSize: 12,
                                                        color: '#7A7A7A',
                                                        fontFamily: 'Nunito Sans',
                                                        fontWeight: 500,
                                                        cursor: 'pointer',
                                                        marginBottom: 30,
                                                    }}
                                                >
                                                    Click here to continue
                                                </p>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 10,
                                                }}
                                            >
                                                <TakeMeBack decoded={decoded} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return (
        // <PopUp open={alert1} setOpen={setAlert} message="Password updated successfuly!" severity="success" />
        <>
            <PopUp open={alert2} setOpen={setAlert2} message={message} severity={severity} />
            <Box className={classes.root}>
                <Grid container>
                    <Hidden lgDown>
                        <Grid item xs={5} sm={5} className={classes.userOrganizationLeft}>
                            <img
                                src={s3Bucket + registerLoginPageImages.userOrgnizationPageImage}
                                alt="login"
                                style={{ width: '70%' }}
                            />
                            <img
                                src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                alt="udyamo"
                                style={{ height: 15, maxWidth: '100%' }}
                            />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={12} xl={7} className={classes.userOrganizationRight}>
                        <div className={classes.contentContainer}>
                            <h1 style={{ fontWeight: 600, color: '#3c48b5', fontFamily: 'Nunito Sans', marginTop: 50 }}>
                                My Departments
                            </h1>
                            <p
                                style={{
                                    lineHeight: 2,
                                    width: '90%',
                                    color: '#444444',
                                    textAlign: 'center',
                                    fontSize: 14,
                                    fontFamily: 'Nunito Sans',
                                    marginTop: 30,
                                }}
                            >
                                {organizations.length > 0
                                    ? 'You are a part of the following organizations. Select the one you wish to join.'
                                    : 'You are not a part of any organization. Create one if you wish to.'}
                            </p>
                            {localStorage.getItem('subscriptionId') && (
                                <p
                                    style={{
                                        lineHeight: 2,
                                        width: '90%',
                                        color: 'green',
                                        textAlign: 'center',
                                        fontSize: 14,
                                        fontFamily: 'Nunito Sans',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Select any organization to activate your subscription
                                </p>
                            )}
                            {/* istanbul ignore next */ organizations.length > 0 && (
                                <div className={classes.organizations}>
                                    {organizations.map((o: any, i: number) => (
                                        <div
                                            className={i === 0 ? classes.org : classes.orgOutline}
                                            key={i}
                                            onClick={() => handleSelectOrganization(o)}
                                            id={'organization' + i}
                                        >
                                            {o.Organization?.organizationLogo ? (
                                                <Avatar
                                                    src={o.Organization?.organizationLogo}
                                                    alt={o.Organization?.organizationName}
                                                    className={i === 0 ? classes.avatar : classes.avatarOutline}
                                                />
                                            ) : (
                                                <Avatar
                                                    src={getOrgInitial(o.Organization?.organizationName)}
                                                    alt={o.Organization?.organizationName}
                                                    className={i === 0 ? classes.avatar : classes.avatarOutline}
                                                />
                                            )}
                                            <div className={i === 0 ? classes.innerOrg : classes.innerOrgOutline}>
                                                <span className={i === 0 ? classes.orgName : classes.orgNameOutline}>
                                                    {o.Organization.organizationName}
                                                </span>
                                                <p className={i === 0 ? classes.role : classes.roleOrgOutline}>
                                                    {o.role}
                                                </p>
                                            </div>
                                            {i === 0 ? (
                                                <p
                                                    style={{
                                                        color: '#cccefe',
                                                        fontSize: 12,
                                                        fontFamily: 'Nunito Sans',
                                                    }}
                                                >
                                                    Default
                                                </p>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {msteams === teamplusT ? (
                                ''
                            ) : (
                                <div
                                    style={{
                                        width: '260px',
                                        border: '1px solid #3C48B5',
                                        color: '#3c48b5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 5,
                                        padding: 5,
                                        fontSize: 16,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.typography.fontFamily,
                                        cursor: 'pointer',
                                        marginBottom: 20,
                                    }}
                                    onClick={() => handleRegisterOrganization()}
                                >
                                    Register a new Organisation
                                </div>
                            )}
                            {isDelete && (
                                <div
                                    id="deleteAccountBtn"
                                    style={{
                                        width: '260px',
                                        border: '1px solid #FF0000',
                                        color: '#FFFFFF',
                                        backgroundColor: '#FF0000',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 5,
                                        padding: 5,
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        fontFamily: 'Nunito Sans',
                                        cursor: 'pointer',
                                        marginBottom: 20,
                                    }}
                                    onClick={() => handleUserAccount()}
                                >
                                    Delete Account
                                </div>
                            )}
                            <TakeMeBack decoded={decoded} />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
