import React from 'react';
import { tabStyles } from './TabbarStyle';
import { Tab, Tabs, Paper, useTheme } from '@material-ui/core';

interface TabProps {
    components: any;
}

export default function TabComponent(props: TabProps) {
    const [value, setValue] = React.useState(0);
    const classes = tabStyles();
    const theme: any = useTheme();
    const handleTabs = (event: any, val: any) => {
        setValue(val);
    };

    const TabPanel = (props: any) => {
        const { children, value, index } = props;
        return <div className={classes.component}>{value === index && children}</div>;
    };

    return (
        <div>
            <Paper elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    className={classes.tabs}
                    style={{
                        minHeight: '30%',
                        height: '100%',
                        width: 'auto',
                        lineHeight: 25,
                        // paddingLeft: 10,
                        borderRadius: 0,
                        marginBottom: 20,
                    }}
                >
                    {props.components.map((c: any, i: number) => (
                        <Tab
                            label={c.label}
                            className={classes.tab}
                            style={{
                                letterSpacing: '0.05rem',
                                textAlign: 'left',
                                fontWeight: theme.typography.fontWeightBold,
                                // borderRight: '1px solid #AAAAAA',
                                textTransform: 'capitalize',
                                minHeight: '10%',
                                marginBottom: 8,
                                lineHeight: '30%',
                                fontSize: 14,
                                outline: 'none',
                                fontFamily: 'Nunito Sans',
                            }}
                            key={i}
                            // fullWidth={true}
                            classes={{
                                root: classes.tabButton,
                            }}
                        />
                    ))}
                </Tabs>
            </Paper>
            {props.components.map((c: any, i: number) => (
                <TabPanel value={value} index={i} key={i}>
                    {c.component}
                </TabPanel>
            ))}
        </div>
    );
}
