import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        subscriptionCardBackground: string;
    }

    interface PaletteOptions {
        subscriptionCardBackground?: string;
    }
}

export const lightTheme = createMuiTheme({
    palette: {
        subscriptionCardBackground: '#EDE7F6',
        common: {
            black: '#333333',
            white: '#FFFFFF',
        },
        primary: {
            main: '#3C48B5', // Button
            light: '#3C48B5',
            dark: '#3C48B5',
            contrastText: '#888888', // Textfield,Select
        },
        secondary: {
            main: '#3D49B6',
            light: '#3C48B5',
            dark: '#3C48B5',
            contrastText: '#262F83',
        },
        success: {
            main: '#088843',
            light: '#64CBA0',
            dark: '#28A15F',
            contrastText: '#3C48B5',
        },
        warning: {
            main: '#FF9519',
            light: '#DD9B6B',
            dark: '#FF9519',
            contrastText: '#FFFFFF',
        },
        info: {
            main: '#f2f3ff',
            light: '#3C48B5',
            dark: '#909497',
            contrastText: '#fff',
        },
        error: {
            main: '#C82930',
            light: '#C82930',
            dark: '#CE3B41',
            contrastText: '#FFFFFF',
        },
        grey: {
            50: '#ddd6d6',
            // 100: '',
            // 200: '',
            // 300: '',
            // 400: '',
            // 500: '',
            // 600: '',
            // 700: '',
            // 800: '',
            // 900: '',
            // A100: '',
            // A200: '',
            // A400: '',
            // A700: '',
        },
        text: {
            primary: '#444444', // black   Text
            secondary: '#3C48B5', // Header Text
            hint: '#666666',
            disabled: '#888888', // grey   Disabled text
        },
        background: {
            paper: '#FFFFFF', //  white  All Paper
            default: '#EEEEF4', //Components background color (off-white)
        },
        action: {
            active: '#3C48B5',
            hover: '',
            selected: '',
            disabled: '',
            focus: '',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 600,
            lg: 900,
            xl: 1200,
        },
    },
    typography: {
        fontFamily: 'Nunito Sans',
        fontWeightBold: 600,
        fontWeightRegular: 400,
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontSize: 14,
        h1: {
            fontFamily: 'Nunito Sans',
            fontSize: 16,
        },
        subtitle1: {
            fontFamily: 'Nunito Sans',
            fontSize: 14,
        },
        subtitle2: {
            fontFamily: 'Nunito Sans',
            fontSize: 12,
        },
        button: {
            fontFamily: 'Nunito Sans',
            fontSize: 14,
            textTransform: 'capitalize',
        },
    },
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontWeight: 400,
                fontSize: 14,
            },
        },
    },
});
export const darkTheme = createMuiTheme({
    palette: {
        subscriptionCardBackground: '#5471ab',
        common: {
            black: '#333333',
            white: '#FFFFFF',
        },
        primary: {
            main: '#4E509E', // Button
            light: '#2C2E38',
            dark: '#19191E',
            contrastText: '#FFFFFF', // Textfield,Select
        },
        secondary: {
            main: '#6675FD',
            light: '#626ED9',
            dark: '#4E509E',
            contrastText: '#7298FB',
        },
        success: {
            main: '#088843',
            light: '#64CBA0',
            dark: '#28A15F',
            contrastText: '#BFC9CA',
        },
        warning: {
            main: '#FF9519',
            light: '#DD9B6B',
            dark: '#FF9519',
            contrastText: '#FFFFFF',
        },
        info: {
            main: '#19191E',
            light: '#3E8EED',
            dark: '#909497',
            contrastText: '#000',
        },
        error: {
            main: '#C82930',
            light: '#C82930',
            dark: '#CE3B41',
            contrastText: '#FFFFFF',
        },
        grey: {
            50: '#696969',
            // 100: '',
            // 200: '',
            // 300: '',
            // 400: '',
            // 500: '',
            // 600: '',
            // 700: '',
            // 800: '',
            // 900: '',
            // A100: '',
            // A200: '',
            // A400: '',
            // A700: '',
        },
        text: {
            primary: '#FFFFFF', // white   All Text
            secondary: '#FFFFFF', // white
            disabled: '#888888', // grey   Diabled Text
            hint: '#FFFFFF',
            // hint: '',
        },
        background: {
            paper: '#2C2E38', // All Paper
            default: '#19191E', //Components background color (off-white)
        },
        action: {
            active: '#2C2E38',
            hover: '',
            selected: '',
            disabled: '',
            focus: '',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 600,
            lg: 900,
            xl: 1200,
        },
    },
    typography: {
        fontFamily: 'Nunito Sans',
        fontWeightBold: 600,
        fontWeightRegular: 400,
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontSize: 14,
        h1: {
            fontFamily: 'Nunito Sans',
            fontSize: 16,
        },
        subtitle1: {
            fontFamily: 'Nunito Sans',
            fontSize: 14,
        },
        subtitle2: {
            fontFamily: 'Nunito Sans',
            fontSize: 12,
        },
        button: {
            fontFamily: 'Nunito Sans',
            fontSize: 14,
            textTransform: 'capitalize',
        },
    },
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontWeight: 400,
                fontSize: 14,
            },
        },
    },
});
