import { createSlice } from '@reduxjs/toolkit';

export const teamSlice = createSlice({
    name: 'teams',
    initialState: { teams: [], status: 'loading' },
    reducers: {
        addTeam: (state: any, action: any) => {
            state.teams.push(action.payload);
        },
        editTeam: (state: any, action: any) => {
            state.teams.map((u: any) => {
                if (u.teamId === action.payload.teamId) {
                    u.teamName = action.payload.teamName;
                    u.team_employees = action.payload.team_employees;
                    return u;
                }
                return u;
            });
        },
        deleteTeam: (state: any, action: any) => {
            state.teams = state.teams.filter((t: any) => t.teamId !== action.payload.teamId);
        },
        fetchTeams: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                state.teams = action.payload?.data?.usermanagementdb_Team;
            }
        },
    },
});

export const { fetchTeams, addTeam, editTeam, deleteTeam } = teamSlice.actions;
export default teamSlice.reducer;
