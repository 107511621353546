import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { SubscriptionDetailsStyle } from './SubscriptionDetailsStyle';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import { fetchInvoice } from '../../helpers/hasuraapi';
import { IconButton, Divider } from '@material-ui/core';
import { RiFileDownloadLine } from 'react-icons/ri';
import Skeleton from '@material-ui/lab/Skeleton';
import { PopUp } from '../Modal/Modal';
// import { getSubscription } from 'components/Redux/subscriptionReducer';
import { getInvoices } from 'components/Redux/invoicesReducer';
import { fetchSubscriptionDetailsChargebee } from '../../helpers/hasuraapi';
import { updateSubscription } from 'helpers/hasuraapi';
import AuthContext from '../../context/AuthContext';
import Loader from '../Loader/loader';

declare let window: any;
interface SubscriptionDetailsProps {
    subscription: {
        subscriptionId?: string;
        currentTermStart?: string;
        currentTermEnd?: string;
        nextBillingAt?: string;
        isExpired?: any;
    };
}

export default function Subscription(props: SubscriptionDetailsProps): JSX.Element {
    const dispatch = useDispatch();
    const subscription: any = useSelector((state: any) => state.subscription);
    const invoicesRedux: any = useSelector((state: any) => state.invoices);
    const mycontext = useContext(AuthContext);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [invoices, setInvoices] = useState<any>([]);
    const [updating, setUpdating] = useState(false);
    async function fetchSubscriptionDetails(subscriptionId: any) {
        try {
            const result = await fetchSubscriptionDetailsChargebee(subscriptionId);
            if (result.http_status_code !== 404) {
                const obj = {
                    token: mycontext.token,
                    EmployeeId: mycontext.EmployeeId,
                    organizationId: mycontext.organizationId,
                    subscriptionId: result.subscription.id,
                    currentTermStart: result.subscription.current_term_start,
                    currentTermEnd: result.subscription.current_term_end,
                    nextBillingAt: result.subscription.next_billing_at || 0,
                };
                if (result.subscription.status === 'non_renewing' || result.subscription.status === 'cancelled') {
                    obj['status'] = 'cancelled';
                    obj['showWarning'] = result.subscription.current_term_end;
                    obj['hardExpireDate'] = result.subscription.current_term_end + 2592000;
                    mycontext.updateSubscriptionExpired('cancelled');
                    mycontext.updateWarningDate(result.subscription.current_term_end);
                    mycontext.updateHardExpireDate(result.subscription.current_term_end + 2592000);
                }
                if (result.subscription.status === 'active') {
                    obj['status'] = 'active';
                    obj['showWarning'] = 0;
                    obj['hardExpireDate'] = 0;
                    mycontext.updateSubscriptionExpired('active');
                    mycontext.updateWarningDate('0');
                    mycontext.updateHardExpireDate('0');
                }
                await updateSubscription(obj);
                dispatch(getInvoices(subscriptionId));
            } else {
                setSeverity('error');
                setAlert(true);
                setAlertMessage('Something went wrong, try again!');
            }
        } catch (e) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Something went wrong, try again!');
        }
    }
    useEffect(() => {
        async function fetchData() {
            try {
                fetchSubscriptionDetails(subscription.subscription?.usermanagementdb_Subscription[0].subscriptionId);
            } catch (e) {
                setSeverity('error');
                setAlert(true);
                setAlertMessage('Something went wrong, try again!');
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription.subscription]);
    useEffect(() => {
        async function fetchData() {
            try {
                setInvoices(invoicesRedux.invoices?.list || []);
            } catch (e) {
                setSeverity('error');
                setAlert(true);
                setAlertMessage('Something went wrong, try again!');
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoicesRedux.status]);
    const manageSubscription = () => {
        try {
            const cbInstance = window.Chargebee.getInstance();
            const chargebeePortalInstance = cbInstance.createChargebeePortal();
            chargebeePortalInstance.open({
                close() {
                    fetchSubscriptionDetails(
                        subscription.subscription?.usermanagementdb_Subscription[0].subscriptionId,
                    );
                },
            });
        } catch (e) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Something went wrong, try again!');
        }
    };
    const classes = SubscriptionDetailsStyle();

    const downloadInvoice = async (invoiceId: any) => {
        try {
            setUpdating(true);
            const Invoice = await fetchInvoice(invoiceId);
            const link = document.createElement('a');
            link.href = Invoice.download.download_url;
            link.setAttribute('download', 'invoice' + invoiceId + '.pdf');
            link.click();
            setUpdating(false);
        } catch (e) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Something went wrong, try again!');
            setUpdating(false);
        }
    };

    return (
        <>
            <Loader isUpdating={updating} />
            <Hidden smDown>
                <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            paddingTop: '2%',
                            paddingRight: '2%',
                        }}
                    >
                        <Button className={classes.manageSubscriptionButton} onClick={manageSubscription}>
                            Manage Subscription
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.detailsContainerTwo}>
                        {invoices?.length !== 0 ? (
                            <>
                                <TableContainer className={classes.tableContainer}>
                                    <Table stickyHeader size="small" aria-label="small table" className={classes.table}>
                                        <TableHead>
                                            <TableRow key="heading">
                                                <TableCell className={classes.column}>#</TableCell>
                                                <TableCell className={classes.column}>Paid On</TableCell>
                                                <TableCell className={classes.column}>
                                                    Amount&nbsp;&#40;{invoices[0].invoice.currency_code}&#41;
                                                </TableCell>
                                                <TableCell className={classes.column}>Invoice Id</TableCell>
                                                <TableCell className={classes.column} style={{ textAlign: 'center' }}>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody key="heading">
                                            {invoices.map((inv: any, index: any) => (
                                                <TableRow key={inv.invoice.id}>
                                                    <TableCell className={classes.cellName}>{index + 1}</TableCell>
                                                    <TableCell className={classes.cellName}>
                                                        {
                                                            new Date(parseInt(inv.invoice.paid_at || '') * 1000)
                                                                .toLocaleString('en-GB')
                                                                .split(',')[0]
                                                        }
                                                    </TableCell>
                                                    <TableCell className={classes.cellName}>
                                                        {inv.invoice.total}
                                                    </TableCell>
                                                    <TableCell className={classes.cellName}>{inv.invoice.id}</TableCell>
                                                    <TableCell className={classes.cellName}>
                                                        <IconButton
                                                            id={inv.invoice.id}
                                                            style={{ padding: 5 }}
                                                            onClick={(e: any) => downloadInvoice(inv.invoice.id)}
                                                            className={classes.editIcon}
                                                        >
                                                            <RiFileDownloadLine />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader size="small" aria-label="small table" className={classes.table}>
                                    <TableHead>
                                        <TableRow key="heading">
                                            <TableCell className={classes.column}>#</TableCell>
                                            <TableCell className={classes.column}>Paid On</TableCell>
                                            <TableCell className={classes.column}>Amount</TableCell>
                                            <TableCell className={classes.column}>Invoice Id</TableCell>
                                            <TableCell className={classes.column} style={{ textAlign: 'center' }}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody key="heading">
                                        <>
                                            <TableRow key="heading">
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton />
                                                </TableCell>
                                                <TableCell className={classes.cellName}>
                                                    <Skeleton />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden smUp>
                <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                <div className={classes.screenSize}>
                    <Button
                        variant="contained"
                        className={classes.btnFixed}
                        onClick={manageSubscription}
                        id="manageSubscription"
                    >
                        Manage Subscription
                    </Button>
                </div>

                {invoices?.length !== 0 ? (
                    <>
                        {invoices.map((inv: any, index: any) => (
                            <Grid
                                container
                                style={{ margin: 0, padding: 10 }}
                                className={classes.container}
                                key={inv.invoice.id}
                            >
                                <Grid item xs={5} className={classes.subsLeft}>
                                    Date:
                                </Grid>
                                <Grid item xs={5} className={classes.subsText}>
                                    {
                                        new Date(parseInt(inv.invoice.paid_at || '') * 1000)
                                            .toLocaleString('en-GB')
                                            .split(',')[0]
                                    }{' '}
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <IconButton
                                        id={inv.invoice.id}
                                        style={{ padding: '0' }}
                                        onClick={(e: any) => downloadInvoice(inv.invoice.id)}
                                        className={classes.editIcon}
                                        size="medium"
                                    >
                                        <RiFileDownloadLine />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={5} className={classes.subsLeft}>
                                    Amount&nbsp;&#40;{inv.invoice.currency_code}&#41;:
                                </Grid>
                                <Grid item xs={7} className={classes.subsText}>
                                    {inv.invoice.total}
                                </Grid>
                                <Grid item xs={5} className={classes.subsLeft}>
                                    Invoice Id:
                                </Grid>
                                <Grid item xs={7} className={classes.subsText}>
                                    {inv.invoice.id}
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider
                                        data-testid="divider"
                                        orientation="horizontal"
                                        variant="fullWidth"
                                        style={{ marginTop: '20px' }}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </>
                ) : (
                    <>
                        {[1, 2, 3, 4].map((d: number) => (
                            <Grid container style={{ margin: 0, padding: 10, backgroundColor: 'white' }} key={d}>
                                <Grid item xs={5} className={classes.subsLeft}>
                                    Date:
                                </Grid>
                                <Grid item xs={7} className={classes.subsText}>
                                    <Skeleton width={50} />
                                </Grid>
                                <Grid item xs={5} className={classes.subsLeft}>
                                    Amount:
                                </Grid>
                                <Grid item xs={7} className={classes.subsText}>
                                    <Skeleton width={50} />
                                </Grid>
                                <Grid item xs={5} className={classes.subsLeft}>
                                    Invoice Id:
                                </Grid>
                                <Grid item xs={7} className={classes.subsText}>
                                    <Skeleton width={50} />
                                </Grid>
                                <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                    <Skeleton width={100} />
                                </Grid>
                                <Divider
                                    data-testid="divider"
                                    orientation="horizontal"
                                    variant="fullWidth"
                                    style={{ marginTop: '20px' }}
                                />
                            </Grid>
                        ))}
                    </>
                )}
            </Hidden>
        </>
    );
}
