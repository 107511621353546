import { gql } from '@apollo/client';

export function getStandupsAnswer(standupTempId: string, standupDate: any) {
    return gql`
        subscription MyQuery2 {
            standupdb_StandupNotes(where: {standupTempId: {_eq: "${standupTempId}"}, standupNoteDate: {_eq: "${standupDate}"}}, order_by: {standupNoteTime: asc}) {
                answers
                employeeId
                isAccomplished
                standupNoteDate
                standupNoteId
                standupTempId
                standupNoteTime
                standupNoteDateTime
                msgId
                threadId
                Employee {
                    firstName
                    lastName
                    profileImage
                }
                tags
            }
        }
    `;
}

export function getStandupByDate(standupTempId: string, endDate: string, startDate: string) {
    return gql`
        subscription standups {
            standupdb_StandupNotes(where: {standupTempId: {_eq: "${standupTempId}"}, standupNoteDateTime: {_gte: "${startDate}", _lt: "${endDate}"}}, order_by: {standupNoteDate: desc}) {
                standupNoteDate
                employeeId
                answers
                isAccomplished
                standupNoteId
                standupNoteTime
                standupTempId
                standupNoteDateTime
                msgId
                threadId
                Employee {
                    firstName
                    lastName
                    profileImage
                }
                tags
            }
        }
    `;
}

export function getStandupsByTeams(orgId: string, teamId: string) {
    return gql`
    subscription MyQuery {
  standupdb_StandupTemplate(where: {organizationId: {_eq: "${orgId}"}}) {
    Organization {
      Teams(where: {teamId: {_eq: "${teamId}"}}) {
        team_employees {
          teamId
          Employee {
            StandupNotes(where: {StandupTemplate: {organizationId: {_eq: "${orgId}"}}}) {
              employeeId
              answers
              standupNoteDate
              standupNoteDateTime
              isAccomplished
              msgId
              threadId
              Employee {
                firstName
                lastName
                profileImage
              }
            }
          }
        }
      }
    }
  }
}
    `;
}
