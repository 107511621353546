import { newurl } from '../../helpers/hasuraapi';

const getDeviceTokens = async (empId: any, orgId: any, token: any) => {
    try {
        const query = {
            query: `query {
                    usermanagementdb_Organization(where: {organizationId: {_eq: "${orgId}"}}) {
                        organization_employees(where: {employeeId: {_neq: "${empId}"}}) {
                        Employee {
                            deviceToken
                        }
                        }
                    }
                    }
            `,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        const orgEmployees = jsonData.data.usermanagementdb_Organization[0].organization_employees;
        const tokenArr: any[] = [];
        if (orgEmployees.length > 0) {
            orgEmployees.map((tE: any) => {
                if (tE.Employee.deviceToken !== null) {
                    tokenArr.push(tE.Employee.deviceToken);
                }
                return null;
            });
        }

        const uniqueChars = tokenArr.filter((c, index) => {
            return tokenArr.indexOf(c) === index;
        });
        return uniqueChars;
    } catch (err) {
        return err;
    }
};

export const sendNotificationToMembers = async (
    empId: any,
    firstname: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    orgId: any,
    token: any,
) => {
    const tokens = await getDeviceTokens(empId, orgId, token);
    let body;
    if ((startDate === endDate && startTime === 'null') || (startDate === endDate && startTime === null)) {
        body = 'Hello there, ' + firstname + ' is on time-off on ' + startDate + '.';
    } else if ((startTime !== 'null' && startDate === endDate) || (startTime !== null && startDate === endDate)) {
        body =
            'Hello there, ' +
            firstname +
            ' is on time-off on ' +
            startDate +
            ' from ' +
            startTime +
            ' to ' +
            endTime +
            '.';
    } else {
        body = 'Hello there, ' + firstname + ' is on time-off from ' + startDate + ' to ' + endDate + '.';
    }
    const notification = {
        title: 'Teamplus',
        body: body,
    };
    const notification_body = {
        notification: notification,
        registration_ids: tokens,
    };
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

const getSelfDeviceTokens = async (empId: any, Token: any) => {
    try {
        const query = {
            query: `query {
                        usermanagementdb_Employee(where: {employeeId: {_eq: "${empId}"}}) {
                                deviceToken
                            }
                        }
                    `,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.usermanagementdb_Employee[0].deviceToken;
    } catch (err) {
        return err;
    }
};

export const sendNotificationToSelf = async (
    empId: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    Token: any,
) => {
    const token = await getSelfDeviceTokens(empId, Token);
    let body;
    if ((startDate === endDate && startTime === 'null') || (startDate === endDate && startTime === null)) {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off has been approved on " + startDate + "."
    } else if ((startTime !== 'null' && startDate === endDate) || (startTime !== null && startDate === endDate)) {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off has been approved on " + startDate + " from " + startTime + " to " + endTime + "."
    } else {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off has been approved on " + startDate + " to " + endDate + "."
    }
    const notification = {
        title: 'Teamplus',
        body: body,
    };
    const notification_body = {
        notification: notification,
        to: token,
    };

    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

export const sendDenyNotificationToSelf = async (
    empId: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    Token: any,
) => {
    const token = await getSelfDeviceTokens(empId, Token);
    let body;
    if (startDate === endDate && startTime === 'null') {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off has been denied of date " + startDate + "."
    } else if (startTime !== 'null' && startDate === endDate) {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off has been denied of date " + startDate + " from " + startTime + " to " + endTime + "."
    } else {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off has been denied of date " + startDate + " to " + endDate + "."
    }
    const notification = {
        title: 'Teamplus',
        body: body,
    };
    const notification_body = {
        notification: notification,
        to: token,
    };

    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

const sendDenyNotificationToMulti = (token: any, startDate: any, endDate: any, startTime: any, endTime: any) => {
    let body;
    if (startDate === endDate && startTime === 'null') {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off has been denied of date " + startDate + "."
    } else if (startTime !== 'null' && startDate === endDate) {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off has been denied of date " + startDate + " from " + startTime + " to " + endTime + "."
    } else {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off has been denied of date " + startDate + " to " + endDate + "."
    }
    const notification = {
        title: 'Teamplus',
        body: body,
    };
    const notification_body = {
        notification: notification,
        to: token,
    };

    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

export const getTokenFromLeave = async (leaveId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
            timeoffdb_Leaves(where: {leaveId: {_eq: "${leaveId}"}}) {
                    Employee {
                        deviceToken
                    }
                    endDate
                    endTime
                    startDate
                    startTime
                }
            }
            `,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        sendDenyNotificationToMulti(
            jsonData.data.timeoffdb_Leaves[0].Employee.deviceToken,
            jsonData.data.timeoffdb_Leaves[0].startDate,
            jsonData.data.timeoffdb_Leaves[0].endDate,
            jsonData.data.timeoffdb_Leaves[0].startTime,
            jsonData.data.timeoffdb_Leaves[0].endDate,
        );
    } catch (err) {
        return err;
    }
};

const getTokenFromLeave1 = async (leaveId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
            timeoffdb_Leaves(where: {leaveId: {_eq: "${leaveId}"}}) {
                    Employee {
                        employeeId
                        firstName
                    }
                    startDate
                    startTime
                    endTime
                    endDate
                }
            }
            `,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // eslint-disable-next-line
        return [jsonData.data.timeoffdb_Leaves[0].Employee.employeeId, jsonData.data.timeoffdb_Leaves[0].startTime, jsonData.data.timeoffdb_Leaves[0].endTime, jsonData.data.timeoffdb_Leaves[0].startDate, jsonData.data.timeoffdb_Leaves[0].endDate, jsonData.data.timeoffdb_Leaves[0].Employee.firstName];
    } catch (err) {
        return err;
    }
};

const getTokenFromLeave2 = async (empId: any, token: any, orgId: any) => {
    try {
        const query = {
            query: `query {
            usermanagementdb_team_employee(where: {employeeId: {_eq: "${empId}"}, Team: {organizationId: {_eq: "${orgId}"}}}) {
                Team {
                team_employees(where: {isApprover: {_eq: true}}) {
                    Employee {
                        deviceToken
                    }
                }
                }
            }
            usermanagementdb_organization_employee(where: {_and: {employeeId: {_eq: "${empId}"}, organizationId: {_eq: "${orgId}"}}}) {
                Organization {
                organization_employees(where: {role: {_eq: "admin"}}) {
                    Employee {
                        deviceToken
                    }
                }
                }
            }
        }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        const teamEmployees = jsonData.data.usermanagementdb_team_employee;
        const admins = jsonData.data.usermanagementdb_organization_employee[0].Organization.organization_employees;
        const tokenArr: any[] = [];
        if (teamEmployees.length > 0) {
            teamEmployees.map((tE: any) => {
                if (tE.Team.team_employees.length > 0) {
                    tE.Team.team_employees.map((emp: any) => {
                        if (emp.Employee.deviceToken !== null) {
                            tokenArr.push(emp.Employee.deviceToken);
                        }

                        return null;
                    });
                }
                return null;
            });
        }

        if (admins.length > 0) {
            admins.map((tE: any) => {
                if (tE.Employee.deviceToken !== null) {
                    tokenArr.push(tE.Employee.deviceToken);
                }
                return null;
            });
        }

        const uniqueChars = tokenArr.filter((c, index) => {
            return tokenArr.indexOf(c) === index;
        });
        return uniqueChars;
    } catch (err) {
        return err;
    }
};

export const sendNotificationToMembers1 = async (leave: any, token: any, orgId: any) => {
    const org = await getTokenFromLeave1(leave, token);
    const empId = org[0];
    const startTime = org[1];
    const endTime = org[2];
    const startDate = org[3];
    const endDate = org[4];
    const firstname = org[5];
    const tokens = await getTokenFromLeave2(empId, token, orgId);
    await sendNotificationToSelf(empId, startDate, endDate, startTime, endTime, token);
    let body;
    if (startDate === endDate && startTime === 'null') {
        body = 'Hello there, ' + firstname + ' is on time-off on ' + startDate + '.';
    } else if (startTime !== 'null' && startDate === endDate) {
        body =
            'Hello there, ' +
            firstname +
            ' is on time-off on ' +
            startDate +
            ' from ' +
            startTime +
            ' to ' +
            endTime +
            '.';
    } else {
        body = 'Hello there, ' + firstname + ' is on time-off from ' + startDate + ' to ' + endDate + '.';
    }
    const notification = {
        title: 'Teamplus',
        body: body,
    };
    const notification_body = {
        notification: notification,
        registration_ids: tokens,
    };
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

const getDeviceTokens1 = async (empId: any, orgId: any, token: any) => {
    try {
        const query = {
            query: `query {
            usermanagementdb_team_employee(where: {employeeId: {_eq: "${empId}"}, Team: {organizationId: {_eq: "${orgId}"}}}) {
                Team {
                team_employees(where: {isApprover: {_eq: true}}) {
                    Employee {
                        deviceToken
                    }
                }
                }
            }
            usermanagementdb_organization_employee(where: {_and: {employeeId: {_eq: "${empId}"}, organizationId: {_eq: "${orgId}"}}}) {
                Organization {
                organization_employees(where: {role: {_eq: "admin"}}) {
                    Employee {
                        deviceToken
                    }
                }
                }
            }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        const teamEmployees = jsonData.data.usermanagementdb_team_employee;
        const orgAdmin = jsonData.data.usermanagementdb_organization_employee[0].Organization.organization_employees;
        const tokenArr: any[] = [];
        if (teamEmployees.length > 0) {
            teamEmployees.map((tE: any) => {
                if (tE.Team.team_employees.length > 0) {
                    tE.Team.team_employees.map((emp: any) => {
                        if (emp.Employee.deviceToken !== null) {
                            tokenArr.push(emp.Employee.deviceToken);
                        }

                        return null;
                    });
                }
                return null;
            });
        }
        // eslint-disable-next-line

        orgAdmin.map((item: any, key: any) => {
            if (item.Employee.deviceToken != null) {
                tokenArr.push(item.Employee.deviceToken);
            }
            return null;
        });

        const uniqueChars = tokenArr.filter((c, index) => {
            return tokenArr.indexOf(c) === index;
        });

        return uniqueChars;
    } catch (err) {
        return err;
    }
};

export const sendNotification = async (
    empId: any,
    orgId: any,
    token: any,
    fname: any,
    approvalRequired: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
) => {
    if (approvalRequired === false) {
        await sendNotificationToMembers(empId, fname, startDate, endDate, startTime, endTime, orgId, token);
        await sendNotificationToSelf(empId, startDate, endDate, startTime, endTime, token);
    } else {
        const tokens = await getDeviceTokens1(empId, orgId, token);

        const notification = {
            title: 'Teamplus',
            body: 'Hello there, ' + fname + ' has added time-off.',
        };
        const notification_body = {
            notification: notification,
            registration_ids: tokens,
        };
        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                // eslint-disable-next-line
                "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
                'Content-Type': 'application/json',
            },

            body: JSON.stringify(notification_body),
        })
            .then(function(response) {
                return response;
            })
            .catch(function(error) {
                return error;
            });
    }
};

export const sendNotificationOtherEmp = async (
    empId: any,
    orgId: any,
    token: any,
    fname: any,
    empName: any,
    approvalRequired: boolean,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
) => {
    // console.log(approvalRequired, startDate, endDate, startTime, endTime);

    if (approvalRequired === false) {
        await sendNotificationToMembers(empId, empName, startDate, endDate, startTime, endTime, orgId, token);
        await sendNotificationToSelf(empId, startDate, endDate, startTime, endTime, token);
    } else {
        const tokens = await getDeviceTokens1(empId, orgId, token);
        const notification = {
            title: 'Teamplus',
            body: 'Hello there, ' + fname + ' has added time-off on behalf of ' + empName + '.',
        };
        const notification_body = {
            notification: notification,
            registration_ids: tokens,
        };
        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                // eslint-disable-next-line
                "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
                'Content-Type': 'application/json',
            },

            body: JSON.stringify(notification_body),
        })
            .then(function(response) {
                return response;
            })
            .catch(function(error) {
                return error;
            });
    }
};
