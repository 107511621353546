import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
import {
    Button,
    // Paper,
    Grid,
    Hidden,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Typography,
    TableCell,
    InputLabel,
    Select,
    Avatar,
    // TextField,
    // InputBase,
    // InputAdornment,
    Popover,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    // IconButton,
    // Tabs,
    // Tab,
} from '@material-ui/core';
// import EmployeeTable from './EmployeeTable';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { CSVLink } from 'react-csv';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './TeammemberStyles';
import Papa from 'papaparse';
import { PopUp } from '../Modal/Modal';
// import { getAllOrganizationTeams } from '../../helpers/hasuraapi';
// import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
// import ErrorPage from '../Error/ErrorPage';
// import { getOrganizationEmployeesTeams } from '../../helpers/hasuraapi';
// import AuthContext from '../../context/AuthContext';
// import { useHistory } from 'react-router-dom';
// import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import Addtoslack from '../AddToSlack/AddToSlack';
import { useSelector } from 'react-redux';

interface InviteProps {
    toggle(): void;
    inviteUsers(e: any, data: any, team?: any, emailError?: boolean): void;
}

export default function BulkInvite(props: InviteProps) {
    const [isFile, setIsFile] = useState<boolean>(false);
    // const [teams, setTeams] = useState<any[]>([]);
    const [csvArray, setCsvArray] = React.useState<any[]>([]);
    const [fileName, setFileName] = React.useState<string>('');
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertm, setAlertm] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    // const mycontext = useContext(AuthContext);
    const [listAnchor, setListAnchor] = useState<any>(null);
    const [currentList, setCurrentList] = useState<any>([]);
    const classes = useStyles();

    const teams = useSelector((state: any) => state.employeesList);

    // useEffect(() => {
    //     let isMounted = true;
    //     async function getData() {
    //         const teamsData = await getAllOrganizationTeams(mycontext.organizationId, mycontext.token);
    //         setTeams(teamsData);
    //     }

    //     if (isMounted) {
    //         getData();
    //     }
    //     return () => {
    //         isMounted = false;
    //     };
    // }, [mycontext]);

    const handleBack = () => {
        setIsFile(false);
    };

    const handleFile = (e: any) => {
        if (e.target.files[0]) {
            try {
                const file = e.target.files[0];
                setFileName(file.name);
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    Papa.parse(file, {
                        header: true,
                        skipEmptyLines: 'greedy',
                        complete: results => {
                            if (results.errors.length > 0) {
                                setSeverity('error');
                                setAlertm(true);
                                setAlertMessage('Error while processing csv');
                            } else {
                                if (results.data.length > 0) {
                                    const myData = results.data.map((row: any) => {
                                        const newTeams = row.Teams.split(',');
                                        const teamArr: any[] = [];

                                        newTeams.map((t: any) => {
                                            const val = t.trim();
                                            const data = teams.employeesList.teams.filter(
                                                (team: any) => val.toLowerCase() === team.teamName.toLowerCase(),
                                            );
                                            if (data.length > 0) {
                                                teamArr.push(data[0]);
                                            }
                                            return null;
                                        });

                                        return {
                                            email: row.Email,
                                            teams: teamArr,
                                        };
                                    });
                                    // console.log("File data: ", myData);
                                    setCsvArray(myData);
                                    setIsFile(true);
                                }
                            }
                            // if (results.errors.length > 0) {
                            //     // console.log("Error while processing csv: ", results.errors);
                            //     setSeverity('error');
                            //     setAlertm(true);
                            //     setAlertMessage('Error while processing csv');
                            // }
                        },
                    });
                };
                reader.readAsText(file);
            } catch (error) {
                // console.log("Erro in handle file: ", error);
                setSeverity('error');
                setAlertm(true);
                setAlertMessage(error);
            }
        }
    };

    const removeInput = (e: any, data: any) => {
        const newArray = csvArray.filter((arr: any) => arr.email !== data.email);
        setCsvArray(newArray);
    };

    const headers = [
        { label: 'Email', key: 'Email' },
        { label: 'Teams', key: 'Teams' },
    ];

    const data = [
        { Email: 'john@gmail.com', Teams: 'General, Development, Engineering' },
        { Email: 'alex@gmail.com', Teams: 'General, Engineering' },
        { Email: 'david12@gmail.com', Teams: 'Development' },
        { Email: 'ketty@gmail.com', Teams: 'Development, Engineering, Product Management' },
    ];

    const csvReport = {
        data: data,
        header: headers,
        filename: 'sampledata.csv',
    };

    const handleListOpen = (event: any, arrData: any[]) => {
        setListAnchor(event.currentTarget);
        setCurrentList(arrData);
    };

    const handleListClose = () => {
        setListAnchor(null);
        setCurrentList([]);
    };

    return (
        <div>
            <Popover
                id="teamList"
                open={Boolean(listAnchor)}
                anchorEl={listAnchor}
                onClose={handleListClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List component="nav" aria-labelledby="team-list-subheader" style={{ width: '100%', padding: 0 }}>
                    <ListItem
                        key="listTitle"
                        style={{ padding: '0px 10px', textTransform: 'capitalize', fontSize: 12 }}
                    >
                        <ListItemText primary="Team List" />
                    </ListItem>
                    <div className={classes.teamList}>
                        {currentList.map((l: any) => (
                            <ListItem
                                key={l.teamId}
                                style={{ padding: '0px 10px', textTransform: 'capitalize', fontSize: 12 }}
                            >
                                <ListItemText primary={l.teamName} />
                            </ListItem>
                        ))}
                    </div>
                </List>
            </Popover>
            <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
            <Hidden smUp>
                <Button
                    id="bulkBack"
                    variant="contained"
                    color="primary"
                    disableRipple={true}
                    onClick={isFile ? handleBack : props.toggle}
                    className={classes.backBtn}
                >
                    Back
                </Button>
                <Button
                    id="bulkSend"
                    variant="contained"
                    color="primary"
                    disableRipple={true}
                    onClick={(e: any) => props.inviteUsers(e, csvArray, null)}
                    className={classes.sendBtn}
                >
                    Send Invite
                </Button>
            </Hidden>
            {isFile && csvArray.length > 0 ? (
                <Grid container justify="center" spacing={2}>
                    <Hidden xsDown>
                        <Grid item sm={12}>
                            <Grid container alignItems="center">
                                <Grid container item justify="flex-start" sm={6}>
                                    <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#222222' }}>
                                        {fileName}
                                    </Typography>
                                </Grid>
                                <Grid container justify="flex-end" item sm={6}>
                                    <input
                                        onChange={handleFile}
                                        accept=".csv"
                                        style={{ display: 'none' }}
                                        id="new-csv-file"
                                        type="file"
                                    />
                                    <label htmlFor="new-csv-file">
                                        <Button
                                            variant="outlined"
                                            id="newDoc"
                                            disableRipple
                                            component="span"
                                            color="primary"
                                            className={classes.downloadBtn}
                                        >
                                            Upload New Document
                                        </Button>
                                    </label>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>
                            <TableContainer component="div" className={classes.innerContainer}>
                                <Table
                                    stickyHeader
                                    className={classes.emailList}
                                    aria-label="simple table"
                                    size="small"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" className={classes.idcolumn}>
                                                #
                                            </TableCell>
                                            <TableCell align="left" className={classes.tablecolumn}>
                                                Email
                                            </TableCell>
                                            <TableCell align="left" className={classes.tablecolumn}>
                                                Teams
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {csvArray.map((row: any, i: number) => (
                                            <TableRow key={i} className={classes.typographyH6heading}>
                                                <TableCell align="center">{i + 1}</TableCell>
                                                <TableCell align="left">
                                                    <Typography variant="subtitle2">{row.email}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        textTransform: 'capitalize',
                                                    }}
                                                >
                                                    <Select
                                                        className={classes.textField}
                                                        id={'select--' + i}
                                                        multiple
                                                        value={row.teams}
                                                        fullWidth
                                                        displayEmpty
                                                        disableUnderline
                                                        renderValue={() => {
                                                            return row.teams.length > 0 ? (
                                                                <span>{row.teams[0].teamName}</span>
                                                            ) : (
                                                                <span>No teams</span>
                                                            );
                                                        }}
                                                        style={{
                                                            border: '1px solid #C4C4C4',
                                                            padding: '5px',
                                                            borderRadius: 4,
                                                            height: 40,
                                                        }}
                                                        inputProps={{
                                                            'aria-label': 'Without label',
                                                            style: {
                                                                padding: 5,
                                                            },
                                                        }}
                                                    >
                                                        {row.teams.length > 0 ? (
                                                            <div>
                                                                {row.teams.map((team: any) => (
                                                                    <MenuItem key={team.teamId} value={team.teamName}>
                                                                        {team.teamName}
                                                                    </MenuItem>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <MenuItem value="none">None</MenuItem>
                                                        )}
                                                    </Select>
                                                    <ClearIcon
                                                        id={'clearicon-' + i}
                                                        fontSize="small"
                                                        onClick={(e: any) => removeInput(e, row)}
                                                        style={{ color: '#BCBCBC' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Hidden>
                    <Hidden smUp>
                        <Grid container justify="center" alignItems="center" style={{ padding: 10, marginBottom: 45 }}>
                            {csvArray.map((row: any, i: number) => (
                                <Grid
                                    item
                                    key={i}
                                    xs={12}
                                    sm={12}
                                    style={{
                                        marginTop: 10,
                                        background: '#FFFFFF',
                                        padding: 10,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} style={{ padding: 0 }}>
                                            <Grid container item justify="flex-end" alignItems="flex-start" xs={12}>
                                                <ClearIcon
                                                    id={'clearicon-' + i}
                                                    fontSize="small"
                                                    onClick={(e: any) => removeInput(e, row)}
                                                    className={classes.cancelIcon}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor={'textField-' + i} className={classes.bulkLabels}>
                                                Email
                                            </InputLabel>
                                            <Typography variant="subtitle2">{row.email}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor={'teams-' + i} className={classes.bulkLabels}>
                                                Teams
                                            </InputLabel>
                                            {row.teams.length === 0 ? (
                                                <Typography variant="subtitle2">-</Typography>
                                            ) : (
                                                <>
                                                    {row.teams.slice(0, 3).map((team: any) => (
                                                        <span key={team.teamId} className={classes.teamNames}>
                                                            {team.teamName}, &nbsp;
                                                        </span>
                                                    ))}
                                                    {row.teams.length > 3 && (
                                                        <Avatar
                                                            className={classes.fab}
                                                            onClick={(e: any) => handleListOpen(e, row.teams)}
                                                        >
                                                            +{row.teams.length - 3}
                                                        </Avatar>
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Hidden>
                </Grid>
            ) : (
                <Grid container justify="center" alignItems="center" className={classes.bulkContainer}>
                    <Grid item sm={12} xs={12} className={classes.dashedContainer}>
                        <Grid container spacing={2}>
                            <Hidden xsDown>
                                <Grid item sm={12}>
                                    <Typography className={classes.dragTitle} id="infoText">
                                        Drag and drop a CSV file or a document to send invites in a bulk
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Grid container item justify="center" sm={12} xs={12}>
                                <input
                                    onChange={handleFile}
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    id="csv-file"
                                    type="file"
                                />
                                {/* <Button variant="outlined" id="templateBtn" disableRipple color="primary" component="span" className={classes.downloadBtn}>
                                        <CSVLink {...csvReport} className={classes.csvLink}>Download Template</CSVLink>
                                    </Button> */}
                                <CSVLink {...csvReport} className={classes.csvLink}>
                                    <Button
                                        variant="outlined"
                                        id="templateBtn"
                                        disableRipple
                                        color="primary"
                                        component="span"
                                        className={classes.downloadBtn}
                                    >
                                        Download Template
                                    </Button>
                                </CSVLink>
                                <label htmlFor="csv-file">
                                    <Button
                                        variant="contained"
                                        id="uploadBtn"
                                        disableRipple
                                        color="primary"
                                        component="span"
                                        className={classes.uploadBtn}
                                    >
                                        Upload Document
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Hidden xsDown>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                    spacing={2}
                    style={{ marginTop: 40 }}
                >
                    {/* <Grid item>
                        <Button
                            id="back"
                            variant="outlined"
                            color="primary"
                            disableRipple
                            onClick={isFile ? handleBack : props.toggle}
                            className={classes.back}
                        >
                            Back
                        </Button>
                    </Grid> */}
                    <Grid item>
                        <Button
                            id="sendBtn"
                            variant="contained"
                            color="primary"
                            disableRipple
                            className={classes.send}
                            onClick={(e: any) => props.inviteUsers(e, csvArray, null)}
                        >
                            Send Invite
                        </Button>
                    </Grid>
                </Grid>
            </Hidden>
        </div>
    );
}
