import React, { useState, useEffect, useContext } from 'react';
import { SmallTimePolicyScreenStyles } from './SmallTimePolicyStyle';
import { Paper, Grid, Divider, Typography } from '@material-ui/core';
import Switch from 'react-switch';
import DeleteTimeff from './DeleteTimeOff';
import EditTimeff from './EditTimeOff';
import AuthContext from '../../context/AuthContext';
import Skeleton from '@material-ui/lab/Skeleton';
import { timeoffLeaveStatus } from '../../helpers/hasuraapi';
import { activeInactiveTimeOff } from '../Redux/timeOffPolicyReducer';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import { useDispatch } from 'react-redux';

interface Props {
    rows: any[];
    isLoading?: boolean;
    error?: boolean;
    errorMessage?: string;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
    setIsUpdating?: any;
    userSubscriptionDetails?: any;
    checkSubscription?: any;
}
function SmallTimePolicyScreen(props: Props) {
    const classes = SmallTimePolicyScreenStyles();
    const mycontext = useContext(AuthContext);
    const [timeoffData, setTimeoffData] = useState<any>([]);
    const dispatch = useDispatch();
    /* istanbul ignore next */
    const refreshPage = () => {
        window.location.reload();
    };
    /* istanbul ignore next */
    const handleleavestatus = async (leaveId: string, active: boolean, approvalRequired: boolean) => {
        try {
            props.checkSubscription(props.userSubscriptionDetails);
            const row = {
                leaveTypeId: leaveId,
                organizationId: mycontext.organizationId,
                active: active,
                approvalRequired: approvalRequired,
                token: mycontext.token,
            };
            const data = await timeoffLeaveStatus(row);
            refreshPage();
            if (data.error) {
                throw new Error('Something went wrong, try again!');
            } else {
                dispatch(
                    activeInactiveTimeOff({
                        leaveTypeId: leaveId,
                        active: data.returning[0].active,
                        approvalRequired: data.returning[0].approvalRequired,
                    }),
                );
            }
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };
    useEffect(() => {
        setTimeoffData(props.rows);
    }, [props.rows, mycontext.organizationId]);

    return (
        <div>
            {timeoffData.length === 0 ? (
                <Paper className={classes.paper} elevation={0}>
                    {props.isLoading && (
                        <>
                            {[1, 2, 3, 4].map((d: number) => (
                                <Paper className={classes.paper} key={d}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Skeleton height={20} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                        </>
                    )}

                    {props.isLoading || (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={upcomingTimeoff} alt="No TimeOFF Data Found" className={classes.notfoundImage} />
                            <Typography className={classes.fontsty}>No TimeOFF Data Found</Typography>
                        </div>
                    )}
                </Paper>
            ) : (
                <>
                    {timeoffData.map((timeoff: any, index: any) => (
                        <div key={index}>
                            <Paper className={classes.paper} elevation={0}>
                                <Grid container key={'grid' + index}>
                                    <Grid item xs={6} className={classes.holidayLeft}>
                                        Time-Off Category
                                    </Grid>
                                    <Grid item xs={6} className={classes.holidayText}>
                                        {timeoff.leaveTypeName}{' '}
                                        {/* {timeoff.leaveTypeEmoticon === 'palm_tree' ? '🌴' : timeoff.leaveTypeEmoticon} */}
                                        {timeoff.leaveTypeEmoticon === null ? '🌴' : timeoff.leaveTypeEmoticon.emoji}
                                    </Grid>
                                    <Grid item xs={6} className={classes.holidayLeft} data-testid="head2">
                                        Days per year
                                    </Grid>
                                    <Grid item xs={6} className={classes.holidayText}>
                                        {timeoff.unlimitedDays
                                            ? 'Unlimited'
                                            : timeoff.daysPerYear === 0
                                            ? 'Unlimited'
                                            : timeoff.daysPerYear}
                                    </Grid>
                                    <Grid item xs={6} className={classes.holidayLeft} data-testid="head3">
                                        Paid / Unpaid
                                    </Grid>
                                    <Grid item xs={6} className={classes.holidayText}>
                                        {/* istanbul ignore next */ timeoff.isPaid ? 'Paid' : 'Unpaid'}
                                    </Grid>
                                    <Grid item xs={6} className={classes.holidayLeft} data-testid="head4">
                                        Status
                                    </Grid>
                                    <Grid item xs={6} className={classes.holidayText}>
                                        <Switch
                                            data-testid="switch"
                                            onChange={() =>
                                                handleleavestatus(
                                                    timeoff.leaveTypeId,
                                                    !timeoff.active,
                                                    timeoff.approvalRequired,
                                                )
                                            }
                                            checked={timeoff.active}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={18}
                                            width={34}
                                            onColor="#3C48B5"
                                            offColor="#CCCCCC"
                                            activeBoxShadow="0 0 0px 0px #CCCCCC"
                                        />
                                    </Grid>

                                    <Grid item xs={6} className={classes.holidayLeft} data-testid="head4">
                                        Auto Approve
                                    </Grid>
                                    <Grid item xs={6} className={classes.holidayText}>
                                        <Switch
                                            data-testid="switch"
                                            onChange={() =>
                                                handleleavestatus(
                                                    timeoff.leaveTypeId,
                                                    timeoff.active,
                                                    !timeoff.approvalRequired,
                                                )
                                            }
                                            checked={!timeoff.approvalRequired}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={18}
                                            width={34}
                                            onColor="#3C48B5"
                                            offColor="#CCCCCC"
                                            activeBoxShadow="0 0 0px 0px #CCCCCC"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <DeleteTimeff
                                            leaveTypeId={timeoff.leaveTypeId}
                                            leaveTypeName={timeoff.leaveTypeName}
                                            defaultEnable={timeoff.defaultEnable}
                                            setAlertMessage={props.setAlertMessage}
                                            setAlertm={props.setAlertm}
                                            setSeverity={props.setSeverity}
                                            id={index + 1}
                                            setIsUpdating={props.setIsUpdating}
                                            userSubscriptionDetails={props.userSubscriptionDetails}
                                            checkSubscription={props.checkSubscription}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <EditTimeff
                                            leaveTypeId={timeoff.leaveTypeId}
                                            leaveTypeName={timeoff.leaveTypeName}
                                            leaveTypeEmoticon={timeoff.leaveTypeEmoticon}
                                            daysPerYear={timeoff.daysPerYear}
                                            defaultEnable={timeoff.defaultEnable}
                                            isPaid={timeoff.isPaid}
                                            setAlertMessage={props.setAlertMessage}
                                            setAlertm={props.setAlertm}
                                            setSeverity={props.setSeverity}
                                            id={index + 1}
                                            setIsUpdating={props.setIsUpdating}
                                            userSubscriptionDetails={props.userSubscriptionDetails}
                                            checkSubscription={props.checkSubscription}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Divider
                                data-testid="divider"
                                orientation="horizontal"
                                variant="fullWidth"
                                style={{ marginTop: '10px' }}
                            />
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default SmallTimePolicyScreen;
