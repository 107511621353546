import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { newurl } from '../../helpers/hasuraapi';

// export const getCountries: any = createAsyncThunk('calendar/getCountries', async () => {
//     const timezone_api = 'https://api.timezonedb.com/v2.1/list-time-zone?key=COQCAXVEC0RV&format=json';
//     const h = await fetch(timezone_api);
//     const jsonh = await h.json();
//     return jsonh?.zones;
// });

export const getCountries: any = createAsyncThunk('calendar/getCountries', async (obj: any) => {
    const query = {
        query: `query MyQuery {
            usermanagementdb_Country {
                countryData
                countryId
            }
        }`,
    };
    const data = await fetch(newurl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + obj.token,
        },
        body: JSON.stringify(query),
    });
    const jsonData = await data.json();
    return jsonData.data.usermanagementdb_Country;
});

export const countrySlice = createSlice({
    name: 'countries',
    initialState: { countries: [], status: 'loading' },
    reducers: {},
    extraReducers: {
        [getCountries.pending]: state => {
            state.status = 'loading';
        },
        [getCountries.fulfilled]: (state, action) => {
            // const arr: any = [];
            // action.payload.map((d: any) => {
            //     arr.push(d.countryData);
            // });
            state.countries = action.payload.map((d: any) => d.countryData);
            state.status = 'success';
        },
        [getCountries.rejected]: state => {
            state.status = 'failed';
        },
    },
});

export default countrySlice.reducer;
