import { makeStyles, Theme } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) => ({
    newDivStyle: {
        '@media screen and (max-width: 770px)': {
            display: 'grid',
            gridTemplateColumns: '1fr ',
        },
        '@media screen and (min-width: 1024px)': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            backgroundColor: theme.palette.background.paper,
        },
    },
    borderDiv: {
        '@media screen and (min-width: 1024px)': {
            borderRight: '1px solid #DDDDDD',
        },
    },
    firstField: {
        '@media screen and (min-width: 1024px)': {
            width: '85%',
        },
        '@media screen and (max-width: 770px)': {
            width: '100%',
        },
    },
    formLabelStyle: {
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '14px',
        marginTop: '5%',
        '@media screen and (max-width: 770px)': {
            lineHeight: '22px',
        },
    },
    inputStyle: {
        '& div': {
            fontSize: '14px',
            '& input': {
                fontFamily: theme.typography.fontFamily,
                fontStyle: 'normal',
                fontWeight: theme.typography.fontWeightBold,
                borderRadius: '2px',
                letterSpacing: '0.03em',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
    },
    inputTag: {
        '& div': {
            fontSize: '14px',
            fontWeight: 'bolder',
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
    },
    inputStyle1: {
        '& div': {
            fontSize: '14px',
            '& input': {
                fontFamily: 'Nunito Sans',
                fontStyle: 'normal',
                fontWeight: 900,
                borderRadius: 'px',
                letterSpacing: '0.03em',
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
    },
    QuestionStyle: {
        '@media screen and (min-width: 1024px)': {
            marginTop: '-3%',
        },
        '@media screen and (max-width: 770px)': {
            marginTop: '-3%',
        },
    },
    organizationName: {
        '@media screen and (min-width: 1024px)': {
            marginTop: '-3%',
        },
    },
    Choices: {
        '@media screen and (min-width: 1024px)': {
            marginTop: '-3%',
        },
        '@media screen and (max-width: 770px)': {
            marginTop: '-1%',
        },
    },
    inputboxes: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 10,
        '@media screen and (max-width: 600px)': {
            display: 'grid',
            gridTemplateColumns: '1fr',
        },
    },
    Addanother: {
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'left',
        paddingRight: '45%',
        '&:focus': {
            outline: 'none',
        },
    },
    firstFieldThirdGrid: {
        '@media screen and (max-width: 1024)': {
            marginTop: '-20%',
        },
        '@media screen and (max-width: 770px)': {
            marginTop: '-2%',
        },
    },
    radioNameStyle: {
        letterSpacing: '0.03em',
        fontWeight: 600,
        fontSize: '14px',
        color: theme.palette.text.primary,
        marginTop: '10px',
        '@media screen and (max-width: 1024)': {
            marginTop: '5px',
        },
        '@media screen and (max-width: 770px)': {
            marginTop: '0%',
        },
    },
    RadioGroupStyle: {
        fontSize: 30,
    },
    radioStyle: {
        // marginTop: -4,
    },
    newInnerDiv: {
        position: 'relative',
        '@media screen and (min-width: 1024px)': {
            paddingLeft: '8%',
        },
    },
    lastField: {
        '@media screen and (min-width: 1024px)': {
            marginTop: '-3%',
        },
        '@media screen and (max-width: 770px)': {
            marginTop: '-3%',
        },
    },
    TimeStyle: {
        width: 120,
        borderRadius: '4px',
        marginTop: 10,
        paddingRight: 5,
        paddingLeft: 5,
        color: '#888888',
        background: theme.palette.background.paper,
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
    },
    radioGroupStyles: {
        '@media screen and (max-width: 899.9px)': {
            height: 100,
            padding: 40,
        },
    },
    inputButton: {
        display: 'none',
    },
    inputTagStyle: {
        position: 'relative',
        border: '1px solid',
        height: '35px',
        width: '35%',
        borderRadius: 5,
        paddingLeft: '3%',
    },
    paperstyle: {
        position: 'relative',
        height: 45,
        top: 8,
    },
    title: {
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        // paddingTop: 8,
        color: theme.palette.text.primary,
        letterSpacing: 0.9,
        '@media screen and (max-width: 900px)': {
            padding: 0,
        },
    },
    backArrow: {
        cursor: 'pointer',
        color: theme.palette.text.primary,
        marginRight: 20,
        padding: 0,
        '@media screen and (max-width: 900px)': {
            margin: '10px 8px 10px -4px',
        },
    },

    inputSelectStyle: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '0.03em',
        color: '#AAAAAA',
        '& .MuiSelect-icon': {
            color: theme.palette.primary.contrastText,
        },
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '@media screen and (max-width: 770px)': {
            height: '40px',
            '& fieldset': {
                height: '40px',
                borderRadius: '2px',
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '& div': {
                marginBottom: '3px',
                letterSpacing: '0.05em',
            },
        },
        '@media screen and (min-width: 1024px)': {
            height: '40px',
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
    },
    inputSelectStyle2: {
        color: 'black',
        fontWeight: 900,
    },

    inputSelectAll: {
        color: 'red',
        '& .MuiSelect-select': {
            background: '#ffffff',
        },
        '& .MuiSelect-selectMenu': {
            // fontWeight: 00,
            color: 'black',
            fontSize: 14,
        },
    },
    btn: {
        display: 'flex',
        justifyContent: 'flex-end',
        '@media screen and (max-width: 700px)': {
            marginTop: '5%',
        },
    },
    btn1: {
        position: 'relative',
        height: 35,
        width: 155,
        textTransform: 'capitalize',
        padding: '0 50px',
        // marginLeft: 30,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        // color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
        '&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
        },
        '&:focus': {
            // textDecoration: 'none',d
            // border: 'none',
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
        },
        '@media only screen and ( max-width: 360px)': {
            width: 140,
            fontSize: 13,
        },
    },
    btn2: {
        height: 35,
        textTransform: 'capitalize',
        // padding: '0 50px',
        fontSize: 14,
        width: 155,
        marginLeft: '10px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            // textDecoration: 'none',d
            border: 'none',
            outline: 'none',
        },
        '@media only screen and ( max-width: 360px)': {
            width: 170,
            fontSize: 13,
        },
    },
}));
