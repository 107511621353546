import { gql } from '@apollo/client';

export function query(organizationId: string, employeeId: string, created: any) {
    return gql`query MyQuery {
        pollsdb_Polls(where: {orgId: {_eq: "${organizationId}"}, createdBy: {_eq: "${employeeId}"}, _and: { pollEndsDateTime: {_lt: "${created}"}}}) {
            adminChoices
            createdBy
            createdOn
            isAnonymous
            isSingleVote
            pollEndTime
            pollEndsDateTime
            pollId
            orgId
            question
            poll_results {
                userChoice
            }
        }
    }`;
}

export function query8(organizationId: string, userId: string, currenttime: any) {
    return gql`query MyQuery {
            pollsdb_Polls(where: {orgId: {_eq: "${organizationId}"}, createdBy: {_eq: "${userId}"}, _and: {pollEndsDateTime: {_lt: "${currenttime}"}}}) {
                adminChoices
                createdBy
                createdOn
                isAnonymous
                isSingleVote
                pollEndsDateTime
                pollEndTime
                pollId
                orgId
                question
                poll_results {
                    userChoice
                }
            }
        }`;
}

// export function query9(organizationId: string, userId: string, createdOn: any) {
//     return gql`query myquery {
//         pollsdb_Polls(where: {orgId: {_eq: "${organizationId}"}, createdBy: {_eq: "${userId}"}, createdOn: {_lt: "${createdOn}"}}) {
//             adminChoices
//             createdBy
//             createdOn
//             isAnonymous
//             isSingleVote
//             pollEndTime
//             pollEndsDateTime
//             pollId
//             orgId
//             question
//             poll_results {
//                 userChoice
//             }
//         }
//     }`
// }

export function query10(organizationId: string, userId: string, currenttime: any, employeeId: string) {
    return gql`query MyQuery {
        pollsdb_Polls(where: {orgId: {_eq: "${organizationId}"}, createdBy: {_eq: "${userId}"}, _and: {pollEndsDateTime: {_lt: "${currenttime}"}, poll_teams: {Team: {team_employees: {employeeId: {_eq: "${employeeId}"}}}}}}) {
            adminChoices
            createdBy
            createdOn
            isAnonymous
            isSingleVote
            pollEndsDateTime
            pollEndTime
            pollId
            orgId
            question
            poll_results {
                userChoice
            }
        }
    }`;
}

// export function query11(organizationId: string, userId: string, createdOn: any, employeeId: string) {
//     return gql`query myquery {
//         pollsdb_Polls(where: {orgId: {_eq: "${organizationId}"}, createdBy: {_eq: "${userId}"}, createdOn: {_lt: "${createdOn}"}, poll_teams: {Team: {team_employees: {employeeId: {_eq: "${employeeId}"}}}}}) {
//             adminChoices
//             createdBy
//             createdOn
//             isAnonymous
//             isSingleVote
//             pollEndTime
//             pollEndsDateTime
//             pollId
//             orgId
//             question
//             poll_results {
//             userChoice
//             }
//         }
//     }`
// }
