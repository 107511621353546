import React, { useState } from 'react';
import useStyles from './style';
import Logo from '../../Assets/images/teamplusLogoWhite.png';
import MobileLogo from '../../Assets/icons/mobile_icon.png';
import Menu from '../../Assets/icons/landing_page_menu.png';
import Close from '../../Assets/icons/landing_page_close.png';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Header() {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [showSubmenu, setShowSubmenu] = useState<boolean>(false);
    const activeLink = useSelector((state: any) => state.activeTab.activeTab);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <div className={classes.header}>
                    <Link to="/" onClick={() => scrollToTop()}>
                        <div className={classes.headerLeft}>
                            <img src={Logo} alt="TeamPlus" className={classes.img} />
                            {/* <p className={classes.label}>TeamPlus</p> */}
                        </div>
                    </Link>
                    <ul className={classes.headerRight}>
                        <Link
                            to="/admin"
                            style={{ textDecoration: 'none', color: '#fff' }}
                            onClick={() => scrollToTop()}
                        >
                            <li className={activeLink === '/admin' ? classes.activelink : classes.link}>Admin</li>
                        </Link>
                        <Link
                            to="/employee"
                            style={{ textDecoration: 'none', color: '#fff' }}
                            onClick={() => scrollToTop()}
                        >
                            <li className={activeLink === '/employee' ? classes.activelink : classes.link}>Employee</li>
                        </Link>

                        <li
                            className={`${classes.submenu} ${
                                activeLink === '/faqs' ||
                                activeLink === '/userguide' ||
                                activeLink === '/employeeguide' ||
                                activeLink === '/adminguide' ||
                                activeLink === '/approverguide'
                                    ? classes.activelink
                                    : classes.link
                            }`}
                        >
                            <div>
                                <ul>
                                    <li>
                                        <Link
                                            to="/faqs"
                                            style={{ textDecoration: 'none', color: '#fff' }}
                                            onClick={() => scrollToTop()}
                                        >
                                            <li>FAQs</li>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/userguide"
                                            style={{ textDecoration: 'none', color: '#fff' }}
                                            onClick={() => scrollToTop()}
                                        >
                                            <li>User Guide</li>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            Resources <ExpandMoreIcon fontSize="large" style={{ height: 28, paddingLeft: '3px' }} />
                        </li>

                        <Link
                            to="/help"
                            style={{ textDecoration: 'none', color: '#fff' }}
                            onClick={() => scrollToTop()}
                        >
                            <li className={activeLink === '/help' ? classes.activelink : classes.link}>Help</li>
                        </Link>
                        <Link to="/login" style={{ textDecoration: 'none', color: '#fff' }}>
                            <li className={activeLink === '/login' ? classes.activelink : classes.link}>Log In</li>
                        </Link>
                        <Link to="/main">
                            <li style={{ textDecoration: 'none', padding: '10px 20px', marginRight: -20 }}>
                                <button className={classes.button}>Get Started</button>
                            </li>
                        </Link>
                    </ul>
                </div>
            </Hidden>
            <Hidden only={['xl']}>
                <div className={classes.headerSmall}>
                    <Link to="/" onClick={() => scrollToTop()}>
                        <img src={MobileLogo} alt="TeamPlus" className={classes.imgSmall} />
                    </Link>

                    <ul className={open ? classes.toggleDiv : classes.toggleDivHidden}>
                        <Link
                            to="/admin"
                            style={{ textDecoration: 'none', color: '#fff' }}
                            onClick={() => scrollToTop()}
                        >
                            <li className={activeLink === '/admin' ? classes.activelinkMobile : classes.link}>Admin</li>
                        </Link>
                        <Link
                            to="/employee"
                            style={{ textDecoration: 'none', color: '#fff' }}
                            onClick={() => scrollToTop()}
                        >
                            <li className={activeLink === '/employee' ? classes.activelinkMobile : classes.link}>
                                Employee
                            </li>
                        </Link>

                        <li
                            className={`${classes.submenuSmall} ${classes.link}`}
                            onClick={() => setShowSubmenu(!showSubmenu)}
                        >
                            Resources{' '}
                            {showSubmenu ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
                            {showSubmenu ? (
                                <ul>
                                    <li>
                                        <Link
                                            to="/faqs"
                                            style={{ textDecoration: 'none', color: '#fff' }}
                                            onClick={() => scrollToTop()}
                                        >
                                            <li
                                                className={
                                                    activeLink === '/faqs' ? classes.activelinkMobile : classes.link
                                                }
                                            >
                                                FAQs
                                            </li>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to="/userguide"
                                            style={{ textDecoration: 'none', color: '#fff' }}
                                            onClick={() => scrollToTop()}
                                        >
                                            <li
                                                className={
                                                    activeLink === '/userguide'
                                                        ? classes.activelinkMobile
                                                        : classes.link
                                                }
                                            >
                                                User Guide
                                            </li>
                                        </Link>
                                    </li>
                                </ul>
                            ) : null}
                        </li>

                        <Link
                            to="/help"
                            style={{ textDecoration: 'none', color: '#fff' }}
                            onClick={() => scrollToTop()}
                        >
                            <li className={activeLink === '/help' ? classes.activelinkMobile : classes.link}>Help</li>
                        </Link>
                        <Link to="/login" style={{ textDecoration: 'none', color: '#fff' }}>
                            <li className={activeLink === '/login' ? classes.activelinkMobile : classes.link}>
                                Log In
                            </li>
                        </Link>
                        <Link to="/main">
                            <li style={{ textDecoration: 'none', padding: '10px 20px', marginRight: -20 }}>
                                <button className={classes.button}>Get Started</button>
                            </li>
                        </Link>
                    </ul>
                    <div>
                        {open ? (
                            <img src={Close} alt="close" className={classes.toggleBtn} onClick={() => setOpen(false)} />
                        ) : (
                            <img src={Menu} alt="open" className={classes.toggleBtn} onClick={() => setOpen(true)} />
                        )}
                    </div>
                </div>
            </Hidden>
        </>
    );
}
