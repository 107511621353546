export const getMSData = async (url: string, options: any) => {
    const allData: any = [];

    let data = await fetch(url, options);
    let response = await data.json();

    let respVal = response.value;
    allData.push(...respVal);

    while (response['@odata.nextLink']) {
        data = await fetch(response['@odata.nextLink'], options);
        response = await data.json();
        respVal = response.value;
        allData.push(...respVal);
    }

    return allData;
};
