import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        border: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'relative',
            width: '100%',
            height: '77vh',
            background: theme.palette.background.paper,
            padding: 10,
            '@media only screen and (min-width: 1340px)': {
                height: '85vh',
            },
            '@media only screen and (max-width: 1020px)': {
                height: '90vh',
            },
        },
        src: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            width: '98%',
            left: '1%',
            top: 15,
        },
        tooltip: {
            fontSize: 11,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        tableBorder: {
            position: 'relative',
            width: '98%',
            left: '1%',
            top: 27,
            height: 'calc(100vh - 238px)',
            '&::-webkit-scrollbar': {
                width: 4,
                marginTop: 40,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                // backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        row: {
            '&:hover': {
                boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
                color: theme.palette.primary.main,
                border: 'none',
                outline: 'none',
                borderRadius: 5,
                cursor: 'pointer',
                backgroundColor: theme.palette.info.main,
            },
        },
        cell: {
            background: '#DCDFFF',
            fontSize: 14,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            color: '#262F83',
            border: 'none',
        },
        cell1: {
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            border: 'none',
        },
        cell2: {
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            border: 'none',
            width: 210,
        },
        fontsty: {
            textAlign: 'center',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            alignItems: 'center',
            opacity: 0.7,
        },
        notfoundImage: {
            maxWidth: '75%',
            height: '180px',
        },
        notfoundText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 16,
            fontWeight: 600,
        },
        btn: {
            position: 'relative',
            display: 'flex-end',
            textAlign: 'center',
            width: 150,
            height: '35px',
            textTransform: 'capitalize',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '@media only screen and (max-width:899px)': {
                position: 'fixed',
                bottom: 0,
                width: '100%',
                height: 42,
                borderRadius: 0,
            },
        },
        car: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: 'calc(98vw - 15px)',
            borderRadius: 5,
            height: 269,
            padding: 20,
        },
        item: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            padding: 7,
        },
        item1: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
            marginLeft: 8,
        },
        snack: {
            marginTop: 35,
            '@media only screen and (max-width:900px)': {
                marginTop: 50,
            },
        },
        avatarimage: {
            height: 30,
            width: 30,
            fontSize: 12,
            border: 'none',
            backgroundColor: '#EDEFFA',
            color: '#444444',
            textTransform: 'uppercase',
        },
        icon: {
            height: 21,
            width: 21,
            cursor: 'pointer',
            color: theme.palette.primary.contrastText,
            // padding: 20
        },
        createPollMobileBtn: {
            height: 40,
            width: '100%',
            position: 'fixed',
            bottom: 0,
            right: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            borderRadius: 0,
            zIndex: 99,
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '&:focus': {
                textDecoration: 'none',
                outline: 'none',
                border: '1px solid #3C48B5',
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
            },
        },
        searchbarRounded: {
            // marginTop: -10,
            width: '280px',
            // background: 'red',
            // background: '#D9DBE5',
            height: 35,
            borderRadius: '3px',
            alignSelf: 'center',
            marginBottom: 5,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 10px',
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            outline: 'none',
            backgroundColor: theme.palette.background.paper,
            '@media only screen and (max-width:900px)': {
                width: '100%',
            },
        },
        searchBarInput: {
            flex: 1,
            outline: 'none',
            border: 'none',
            fontSize: 14,
            fontFamily: 'Nunito Sans',
            width: '100%',
            color: theme.palette.text.primary,
            // border: `1px solid ${theme.palette.primary.contrastText}`,
            backgroundColor: theme.palette.background.paper,
        },
        searchBorder: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&:hover fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
        },
        searchIcon: {
            '@media screen and (min-width:912px) and (max-width: 1024px)': {
                marginLeft: 10,
            },
        },
        searchInput: {
            height: 35,
            width: '100%',
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '&:hover, &:focus': {
                outline: 'none',
                color: theme.palette.text.primary,
            },
            // '@media screen and (min-width: 768px)': {
            //     marginLeft: '10px',
            // },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: '0px 14px',
                '&:hover, &:focus': {
                    outline: 'none',
                    color: theme.palette.text.primary,
                },
            },
        },
        searchbarDiv: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
            padding: 10,
            height: 40,
        },
    }),
);
export default useStyles;
