const previewList = (questions, answers) => {
    // console.log("preview list ", questions, answers);
    // let ans = Object.values(answers);
    const arr = [
        {
            type: 'Container',
            items: [
                {
                    type: 'TextBlock',
                    size: 'Medium',
                    weight: 'Bolder',
                    text: 'Did you accomplished yesterdays task?',
                    spacing: 'Small',
                },
                {
                    type: 'TextBlock',
                    size: 'Medium',
                    weight: 'Default',
                    text: answers['question1'],
                    spacing: 'Small',
                },
            ],
            spacing: 'Default',
            horizontalAlignment: 'Left',
            style: 'Default',
            bleed: true,
            rtl: false,
        },
    ];
    questions.forEach((q, i) => {
        arr.push({
            type: 'Container',
            items: [
                {
                    type: 'TextBlock',
                    size: 'Medium',
                    weight: 'Bolder',
                    text: q,
                    spacing: 'Small',
                    wrap: true,
                },
                {
                    type: 'TextBlock',
                    size: 'Medium',
                    weight: 'Default',
                    text: answers['question' + (i + 2)],
                    spacing: 'Small',
                    wrap: true,
                },
            ],
            spacing: 'Default',
            horizontalAlignment: 'Left',
            style: 'Default',
            bleed: true,
            rtl: false,
        });
    });
    // console.log("json data", arr);
    return arr;
};

export const myStandup = (templateQuestions, answers, id, name, localTimestamp, timestamp) => {
    // console.log("line 90 ", templateQuestions, answers);
    return {
        type: 'AdaptiveCard',
        body: [
            {
                type: 'TextBlock',
                size: 'Medium',
                weight: 'Bolder',
                text: name,
                color: 'Accent',
            },
            {
                type: 'Container',
                items: previewList(templateQuestions, answers),
                spacing: 'Small',
                horizontalAlignment: 'Left',
                style: 'Default',
                bleed: true,
                rtl: false,
            },
        ],
        actions: [
            {
                type: 'Action.Submit',
                title: '✏️ Edit standup',
                id: 'edit',
                tooltip: 'Edit standup',
                mode: 'secondary',
                data: {
                    msteams: {
                        type: 'task/fetch',
                    },
                    title: 'edit',
                    date: timestamp.split('T')[0],
                    inputType: 'edit',
                    value: id,
                },
            },
            {
                type: 'Action.Submit',
                title: '🗑️ Delete standup',
                id: 'delete',
                tooltip: 'Delete standup?',
                mode: 'secondary',
                data: {
                    msteams: {
                        type: 'task/fetch',
                    },
                    title: 'delete',
                    date: timestamp.split('T')[0],
                    inputType: 'delete',
                    value: id,
                },
            },
        ],
        height: 'medium',
        width: 'medium',
        version: '1.5',
        msTeams: {
            width: 'Full',
        },
        $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
    };
};
