/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import clsx from 'clsx';
import { Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import myStyle from './menuStyle';
import useStyles from './mobileMenuStyle';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import PeopleIcon from '@material-ui/icons/People';
// import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
// import ApartmentIcon from '@material-ui/icons/Apartment';
// import FlightIcon from '@material-ui/icons/Flight';
import AuthContext from '../../../context/AuthContext';
// import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
// import SettingsIcon from '@material-ui/icons/Settings';
import New from './New.png';
import udyamo from '../../../Assets/images/udyamo-white.png';
import Logout from '../../../Assets/icons/logout.png';
import { closeSidebar } from '../../Redux/sidebarReducer';
import { useFlagsmith } from 'flagsmith-react';
import { Icon } from '@iconify/react';
import { Button } from '@material-ui/core';
import { DateField2 } from '../../ImportHoliday/FormInputs';
import { CSVLink } from 'react-csv';
import { getAllLeavesReports, getLeavesBalanceReport } from '../../../helpers/hasuraapi';
import { PopUp } from '../../Modal/Modal';
import Modal from '../../Modal/Modal';
import { FormControl, FormLabel, Select, MenuItem, useTheme } from '@material-ui/core';
import { toAWSDateFormat, DateDifference } from '../../../helpers/date';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { updateActiveTab } from 'components/Redux/activeTab';

interface MenuProps {
    pgs: any[];
    handleToggle?: any;
    isSmall?: boolean;
    isMobile?: boolean;
}

function Menu(props: MenuProps): JSX.Element {
    const classes = myStyle();
    const activeLink = useSelector((state: any) => state.activeTab.activeTab);
    const dispatch = useDispatch();
    // const mycontext = useContext(AuthContext);
    const { hasFeature } = useFlagsmith();
    // const isAdmin = mycontext.isAdmin;
    // const isApprover = mycontext.isApprover;

    // if (props.isSmall) {
    //     return (
    //         <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
    //             {/* <div style={{ width: '100%', background: '#374785', color: '#fff', display: 'flex' }}>
    //                 <Link to={{ pathname: '/leave' }} className={classes.sideLink}>
    //                     <DepartureBoardIcon
    //                         className={activeLink === '/leave' ? classes.activeMenu : classes.sidMenu}
    //                         style={{ color: '#fff' }}
    //                     />
    //                 </Link>
    //             </div> */}
    //             <Link to={{ pathname: '/dashboard' }} className={classes.sideLink}>
    //                 <DashboardIcon className={activeLink === '/dashboard' ? classes.activeMenu : classes.sidMenu} />
    //             </Link>

    //             {isAdmin && (
    //                 <Link to={{ pathname: '/teams' }} className={classes.sideLink}>
    //                     <ApartmentIcon className={activeLink === '/teams' ? classes.activeMenu : classes.sidMenu} />
    //                 </Link>
    //             )}

    //             {isAdmin && (
    //                 <Link to={{ pathname: '/people' }} className={classes.sideLink}>
    //                     <PeopleIcon className={activeLink === '/people' ? classes.activeMenu : classes.sidMenu} />
    //                 </Link>
    //             )}

    //             <Link to={{ pathname: '/holiday' }} style={{ textDecoration: 'none', margin: '0px auto' }}>
    //                 <FlightIcon className={activeLink === '/holiday' ? classes.activeMenu : classes.sidMenu} />
    //             </Link>
    //             {isAdmin && (
    //                 <Link to={{ pathname: '/settings' }} className={classes.sideLink}>
    //                     <SettingsIcon className={activeLink === '/settings' ? classes.activeMenu : classes.sidMenu} />
    //                 </Link>
    //             )}
    //         </div>
    //     );
    // } else {
    return (
        <Hidden>
            {/* <List className={classes.list}> */}
            {/* <Link to={{ pathname: '/leave' }} style={{ textDecoration: 'none', width: '100%' }}>
                        <Button
                            id="leaveButton"
                            className={classes.leaveBtn}
                            onClick={() => dispatch({ type: 'TOGGLE' })}
                        >
                            <div className={classes.leaveIcon}>
                                <DepartureBoardIcon />
                            </div>
                            &nbsp; &nbsp; &nbsp;
                            {isAdmin ? (
                                <div className={classes.title}>Request Leave</div>
                            ) : isApprover ? (
                                <div className={classes.title}>Request Leave</div>
                            ) : (
                                <div className={classes.title}>Request Leave</div>
                            )}
                        </Button>
                    </Link> */}
            <div className={classes.list}>
                {props.pgs.map(
                    (page: any, i: number) =>
                        // <ListItem className={classes.item} disableGutters key={page.title}>
                        // (!mycontext.features.isPolls && page.href === '/polls') ||
                        !hasFeature('isstandup') && page.href === '/standups' ? null : (
                            <div className={classes.main} key={i}>
                                <div
                                    className={activeLink === page.href ? classes.upLinkActive : classes.upLinkDiv}
                                ></div>
                                <Link
                                    to={{ pathname: page.href }}
                                    className={activeLink === page.href ? classes.activeLink : classes.link}
                                    style={{ textDecoration: 'none' }}
                                    // id={page.title}
                                    id={page.title.replace(' ', '')}
                                    data-testid="link"
                                    onClick={() => dispatch(updateActiveTab(page.href))}
                                >
                                    {/* <Button className={activeLink === page.href ? classes.button1 : classes.button}> */}
                                    <div className={activeLink === page.href ? classes.iconActive : classes.icon}>
                                        {activeLink === page.href ? (
                                            <img src={page.image} alt={page.title} />
                                        ) : (
                                            <img src={page.imageOutline} alt={page.title} />
                                        )}
                                    </div>
                                    <div className={activeLink === page.href ? classes.titleActive : classes.title}>
                                        {page.title === 'Standups' ? (
                                            <div style={{ position: 'relative' }}>
                                                {page.title}
                                                <sup>
                                                    <img
                                                        src={New}
                                                        alt="Udyamo"
                                                        style={{ position: 'absolute', left: '-8px', top: '-6px' }}
                                                    />
                                                </sup>
                                            </div>
                                        ) : (
                                            <div>{page.title}</div>
                                        )}
                                    </div>
                                    {/* </Button> */}
                                </Link>
                                <div
                                    className={activeLink === page.href ? classes.downLinkActive : classes.downLinkDiv}
                                ></div>
                            </div>
                        ),
                    // </ListItem>
                )}
            </div>
            {/* <ListItem className={classes.item} disableGutters>
                    {props.isMobile ? (
                        <Button className={classes.button} onClick={() => mycontext.logout()}>
                            <div className={classes.icon}>
                                <PowerSettingsNewOutlinedIcon />
                            </div>
                            &nbsp;
                            <div className={classes.title}>Logout</div>
                        </Button>
                    ) : null}
                </ListItem> */}
            {/* </List> */}
        </Hidden>
    );
}
// }

export const MobileMenu = (props: MenuProps) => {
    const classes = useStyles();
    const theme = useTheme();
    // const activeLink = useSelector((state: any) => state.activeTab);
    const dispatch = useDispatch();
    const mycontext = useContext(AuthContext);
    const { hasFeature } = useFlagsmith();

    const [open, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState([]);
    const store: any = useSelector((store: any) => store.orgEmployees);
    const [startDate, setStartDate] = useState<any>(toAWSDateFormat(new Date()));
    const [endDate, setEndDate] = useState<any>(toAWSDateFormat(new Date()));
    const [currentEmployees, setCurrentEmployees]: any = React.useState<any>([]);
    const [data, setdata] = useState<any>([]);
    const [headers, setHeaders] = useState<any>([]);
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [leavesCountData, setLeavesCountData] = useState<any>([]);
    const [filename, setFilename] = useState<string>('all_timeoff_report.csv');
    const [isRun, setIsRun] = useState<any>(null);
    const [type, setType]: any = useState('one');
    const csvLink: any = useRef();
    const subscriptionExpired =
        mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate);

    useEffect(() => {
        (async function() {
            try {
                await leavesCountData.forEach(function(val: any) {
                    val.Employee.organization_employees[0].Organization.LeaveTypes.map((value: any) => {
                        if (value.leaveTypeName === val.leaveType) {
                            if (value.daysPerYear === 0 && value.unlimitedDays === true) {
                                val.leaveAllowed = 'unlimited';
                            } else {
                                val.leaveAllowed = value.daysPerYear;
                            }
                        }
                        return null;
                    });
                    const quotient = Math.floor(val.totalHoursLeave / val.workingHours);
                    const remainder = val.totalHoursLeave % val.workingHours;
                    const str = remainder.toString();
                    const numarray = str.split('.');
                    let a = [];
                    a = numarray;
                    const first2Str = String(parseInt(a[1])).slice(0, 2);
                    const first2Num = Number(first2Str);
                    const int_length = ('' + first2Num).length;
                    let first = first2Num;
                    if (int_length < 2) {
                        first = first2Num * 10;
                    }
                    if (isNaN(Math.trunc((first / 100) * 60))) {
                        val.Minutes = 0;
                    } else {
                        val.Minutes = Math.trunc((first / 100) * 60);
                    }
                    val.Days = quotient;
                    val.Hours = Math.trunc(remainder);
                    val.startDate = startDate;
                    val.endDate = endDate;
                });
                await setdata(leavesCountData);
                if (isRun === null) {
                    setIsRun(false);
                } else if (isRun === false) {
                    await csvLink.current.link.click();
                }
            } catch (e) {
                return e;
            }
        })();
    }, [leavesCountData]); // eslint-disable-line react-hooks/exhaustive-deps

    const onReportClick1 = async () => {
        const arr: any = [];
        // console.log("THIS IS CURRENT EMPLOYEES", currentEmployees)
        await currentEmployees.map((val: any) => {
            arr.push(val.employeeId);
            return null;
        });
        if (currentEmployees.length === 0) {
            setAlertMessage('Please select atleast 1 employee');
            setAlert(true);
        } else if (DateDifference(startDate, endDate) < 0) {
            setAlertMessage('Please select a valid date');
            setAlert(true);
        } else {
            if (type === 'one') {
                setFilename('all_timeoff_report.csv');
                const result = await getAllLeavesReports(
                    {
                        employees: arr,
                        orgId: mycontext.organizationId,
                        startDate: startDate,
                        endDate: endDate,
                    },
                    mycontext.token,
                );

                await result.timeoffdb_Leaves.map((val: any, key: any) => {
                    if (val.leaveStatus === 'pending') {
                        return (val.approver = '');
                    } else if (val.leaveStatus === 'denied') {
                        return (val.approver = '');
                    } else {
                        return (val.approver = val.approverId
                            ? store.employees.filter((e: any) => e.employeeId === val.approverId)[0]?.Employee
                                  ?.firstName
                            : 'Auto Approved');
                    }
                });

                setHeaders([
                    { label: 'Firstname', key: 'Employee.firstName' },
                    { label: 'Lastname', key: 'Employee.lastName' },
                    { label: 'Start Date', key: 'startDate' },
                    { label: 'End Date', key: 'endDate' },
                    { label: 'Start Time', key: 'startTime' },
                    { label: 'End Time', key: 'endTime' },
                    { label: 'Category', key: 'category' },
                    { label: 'Leave Count', key: 'leaveCount' },
                    { label: 'Leave Status', key: 'leaveStatus' },
                    { label: 'Denied Reason', key: 'denialReason' },
                    { label: 'No. of hours', key: 'noOfHours' },
                    { label: 'Reason', key: 'reason' },
                    { label: 'Approved By', key: 'approver' },
                ]);
                setdata(result.timeoffdb_Leaves);
                csvLink.current.link.click();
            } else {
                setFilename('timeoff_balance_report.csv');
                const response = await getLeavesBalanceReport(
                    {
                        employees: arr,
                        orgId: mycontext.organizationId,
                        startDate: startDate,
                        endDate: endDate,
                    },
                    mycontext.token,
                );
                setHeaders([
                    { label: 'Firstname', key: 'Employee.firstName' },
                    { label: 'Lastname', key: 'Employee.lastName' },
                    { label: 'From', key: 'startDate' },
                    { label: 'To', key: 'endDate' },
                    { label: 'Leave Type', key: 'leaveType' },
                    { label: 'No. of Days Leave Taken', key: 'Days' },
                    { label: 'No. of Hours Leave Taken', key: 'Hours' },
                    { label: 'No. of Minutes Leave Taken', key: 'Minutes' },
                    { label: 'Number of days allowed to take leaves as per leave type', key: 'leaveAllowed' },
                ]);
                setLeavesCountData(response.timeoffdb_get_totalhoursbydate_org);
            }
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const onReportClick = (v: any) => {
        setOpen(true);
        dispatch(closeSidebar());
    };

    const handleChange1 = (event: any) => {
        const value = event.target.value;
        // console.log("THIS IS VAL", value)
        // if (value[value.length - 1] === "all") {
        //     setSelected(selected.length === store.employees.length ? [] : store.employees);
        //     return;
        // }
        setSelected(value);
        setCurrentEmployees(value);
    };

    const resetSettings = () => {
        setType('one');
        setCurrentEmployees([]);
        setStartDate(toAWSDateFormat(new Date()));
        setEndDate(toAWSDateFormat(new Date()));
        setSelected([]);
    };

    const handleDates = (v: any) => {
        setStartDate(v);
        setEndDate(v);
    };

    // const isAdmin = mycontext.isAdmin;
    // const isApprover = mycontext.isApprover;
    return (
        <Hidden>
            <div className={classes.list}>
                <Modal open={open} setOpen={setOpen} onModalClosed={handleCloseModal} title="Reports">
                    <div>
                        <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity="error" />
                        {subscriptionExpired && (
                            <FormLabel className={classes.reporttitle} style={{ color: 'red', marginTop: 20 }}>
                                Subscription expired, Please renew it !
                            </FormLabel>
                        )}
                        <div className={classes.fielddiv}>
                            <FormLabel className={classes.reporttitle}>Report Type</FormLabel>
                            <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
                                <Select
                                    id="categorySelect"
                                    value={type}
                                    className={classes.inputSelectStyleOther}
                                    onChange={(e: any) => setType(e.target.value)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'center',
                                        },
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                            style: {
                                                marginTop: -10,
                                                maxWidth: 50,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="one">All Time-offs report</MenuItem>
                                    <MenuItem value="two">Time-off balance report</MenuItem>
                                </Select>
                            </FormControl>

                            <FormLabel className={classes.reporttitle}>Employees</FormLabel>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                {/* <InputLabel id="mutiple-select-label">Multiple Select</InputLabel> */}
                                <Select
                                    id="categorySelect"
                                    multiple
                                    value={selected}
                                    onChange={handleChange1}
                                    displayEmpty={true}
                                    className={classes.inputSelectStyleOther}
                                    renderValue={(selected: any) =>
                                        selected.length > 0 ? (
                                            <div>
                                                {selected.map((s: any, index: number) => (
                                                    <span key={index}>
                                                        {s.Employee?.firstName}
                                                        {index !== selected.length - 1 && <>, &nbsp;</>}
                                                    </span>
                                                ))}
                                            </div>
                                        ) : (
                                            <span>Select Employees</span>
                                        )
                                    }
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'center',
                                        },
                                        getContentAnchorEl: null,

                                        PaperProps: {
                                            style: {
                                                marginTop: -10,
                                                maxWidth: 50,
                                            },
                                        },
                                    }}
                                >
                                    {/* <MenuItem
                                                    value="all"
                                                    // classes={{
                                                    //     root: isAllSelected ? classes.selectedAll : ""
                                                    // }}
                                                > */}
                                    {/* <ListItemIcon> */}
                                    {/* <Checkbox
                                                            classes={{ indeterminate: classes.indeterminateColor }}
                                                            checked={isAllSelected}
                                                            indeterminate={
                                                                selected.length > 0 && selected.length < store.employees.length
                                                            }
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        classes={{ primary: classes.selectAllText }}
                                                        primary="Select All"
                                                    />
                                                </MenuItem> */}

                                    {store.employees.map((emp: any, index: number) => (
                                        <MenuItem key={emp.employeeId} value={emp}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    // defaultChecked={true}
                                                    // checked={true}
                                                    checked={(selected as any).includes(emp) ? true : false}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={emp.Employee?.firstName} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.fieldDivDateTime}>
                            <div>
                                <FormLabel className={classes.reporttitle}>From</FormLabel>
                                <FormControl className={classes.formControl}>
                                    <DateField2
                                        date={startDate}
                                        setDate={setStartDate}
                                        id="startdatefield"
                                        handleDates={handleDates}
                                    />
                                </FormControl>
                            </div>
                            <div className={classes.mobileToPart}>
                                <FormLabel className={classes.reporttitle}>To</FormLabel>
                                <FormControl className={classes.formControl}>
                                    <DateField2 date={endDate} setDate={setEndDate} id="enddatefield" />
                                </FormControl>
                            </div>
                        </div>

                        <div className={classes.newButtonGroup}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={`${classes.buttonStyle} ${classes.resetButton}`}
                                onClick={() => resetSettings()}
                                // disabled={isDisabled}
                                id="settingsReset"
                                style={{ border: `1px solid ${theme.palette.text.secondary}` }}
                                disabled={subscriptionExpired}
                            >
                                Reset
                            </Button>
                            <Button
                                id="submitBtn"
                                className={classes.btn2}
                                variant="contained"
                                color="primary"
                                disableRipple={true}
                                onClick={onReportClick1}
                                disabled={subscriptionExpired}
                            >
                                Export
                            </Button>
                        </div>

                        <CSVLink
                            headers={headers}
                            data={data}
                            filename={filename}
                            target="_blank"
                            ref={csvLink}
                        ></CSVLink>
                    </div>
                </Modal>
                {/* <Link to={{ pathname: '/leave' }} style={{ textDecoration: 'none', width: '100%' }}>
                    <Button id="leaveButton" className={classes.leaveBtn} onClick={() => dispatch({ type: 'TOGGLE' })}>
                        <div className={classes.leaveIcon}>
                            <DepartureBoardIcon />
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        {isAdmin ? (
                            <div className={classes.title}>Request Leave</div>
                        ) : isApprover ? (
                            <div className={classes.title}>Request Leave</div>
                        ) : (
                            <div className={classes.title}>Request Leave</div>
                        )}
                    </Button>
                </Link> */}
                <div>
                    {props.pgs.map(
                        (page: any, i: number) =>
                            // // <ListItem className={classes.item} disableGutters key={page.title}>
                            // (!mycontext.features.isPolls && page.href === '/polls') ||
                            !hasFeature('isstandup') && page.href === '/standups' ? null : (
                                <Link
                                    key={i}
                                    // id={page.title}
                                    id={page.title?.replace(' ', '')}
                                    to={{ pathname: page.href }}
                                    className={classes.link}
                                    onClick={() => dispatch(closeSidebar())}
                                >
                                    {/* <Button
                            className={activeLink === page.href ? classes.button1 : classes.button}
                            onClick={() => dispatch({ type: 'TOGGLE' })}
                        > */}
                                    <div className={classes.icon}>
                                        {page.image ? (
                                            <img src={page.imageOutline} alt={page.title} />
                                        ) : (
                                            page.iconOutline
                                        )}
                                    </div>
                                    {page.title === 'Standups' ? (
                                        <div style={{ position: 'relative' }} className={classes.title}>
                                            {page.title}
                                            <sup>
                                                <img
                                                    src={New}
                                                    alt="Udyamo"
                                                    style={{ position: 'absolute', left: '-8px', top: '-6px' }}
                                                />
                                            </sup>
                                        </div>
                                    ) : (
                                        <div className={classes.title}>{page.title}</div>
                                    )}
                                    {/* </Button> */}
                                </Link>
                            ),
                        // </ListItem>
                    )}
                    <Link to={{ pathname: '/login' }} className={classes.link} onClick={() => mycontext.logout()}>
                        <div className={classes.icon}>
                            <img src={Logout} alt="Logout" />
                        </div>
                        <div className={classes.title}>Logout</div>
                    </Link>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
                        {mycontext.isAdmin && (
                            <>
                                <Button variant="outlined" className={classes.btn} onClick={onReportClick} id="Reports">
                                    <Icon icon="heroicons-outline:document-report" width="20" />
                                    &nbsp;Reports
                                </Button>
                            </>
                        )}
                    </div>
                    <hr className={classes.hr} />
                    <div className={classes.imgDiv}>
                        <img src={udyamo} alt="udyamo" className={classes.udyamoImg} />
                    </div>
                </div>
            </div>
        </Hidden>
    );
};
export default Menu;
