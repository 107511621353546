import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            width: '100%',
            height: 'calc(100vh - 70px)',
            display: 'grid',
            gridTemplateRows: '46px 1fr',
            gap: 10,
        },
        outerDiv: {
            height: 'calc(100vh - 150px)',
            background: theme.palette.background.paper,
            borderRadius: 3,
            '@media screen and (max-width: 768px)': {
                height: 'auto',
            },
        },
        header: {
            background: theme.palette.background.paper,
            padding: '0 20px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 3,
        },
        title: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontStyle: 'normal',
            color: theme.palette.text.primary,
            margin: 'auto 0',
        },
        description: {
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontStyle: 'normal',
            color: theme.palette.primary.contrastText,
            maxWidth: '80%',
        },
        body: {
            background: theme.palette.background.paper,
            borderRadius: 3,
            display: 'flex',
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        bodyContent: {
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            width: '50%',
            '@media screen and (max-width: 768px)': {
                padding: 10,
                width: '100%',
            },
        },
        formLabelStyle: {
            color: theme.palette.primary.contrastText,
            fontWeight: 600,
            fontSize: '14px',
            '@media screen and (max-width: 770px)': {
                lineHeight: '22px',
            },
        },
        inputDiv: {
            flexBasis: '0%',
            marginBottom: '30px',
        },
        inputStyle: {
            width: '85%',
            '& div': {
                fontSize: '14px',
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.paper,
                },
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
            '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #AAAAAA',
            },
            '@media screen and (max-width: 768px)': {
                width: '100%',
            },
        },
        imagePreviewStyle: {
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                '& img': {
                    width: 100,
                    height: 30,
                },
            },
            '@media only screen and (min-width: 820px) and (max-width: 820px)': {
                '& img': {
                    width: 100,
                    height: 30,
                },
            },
            '@media screen and (min-width: 1024px)': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '13px',
                height: '60px',
                '& img': {
                    maxWidth: '100%',
                    maxHeight: '100%',
                    padding: '10px 0px 10px 0px',
                },
            },
            '@media screen and (max-width: 770px)': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '13px',
                height: '50px',
                '& img': {
                    maxWidth: '100%',
                    maxHeight: '100%',
                    padding: '10px 0px 10px 0px',
                },
            },
        },
        orgLogoBoxStyle: {
            borderRadius: 5,
            display: 'flex',
            padding: 5,
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media only screen and (min-width: 820px) and (max-width: 820px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media screen and (min-width: 1024px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
                boxSizing: 'border-box',
                marginRight: '5%',
                height: '60px',
                width: '85%',
            },
            '@media screen and (max-width: 770px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
                boxSizing: 'border-box',
                height: '50px',
            },
        },
        editIconStyle: {
            display: 'flex',
            height: '50px',
            justifyContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
            outline: 'none',
            borderRadius: 5,
        },
        radioNameStyle: {
            letterSpacing: '0.03em',
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.text.primary,
            marginTop: '5px',
        },
        vr: {
            height: '90%',
            width: 1,
            backgroundColor: theme.palette.primary.contrastText,
            margin: 'auto',
            '@media screen and (max-width: 768px)': {
                height: 1,
                width: '90%',
                margin: '2px auto',
                display: 'none',
            },
        },
        userDiv: {
            display: 'grid',
            gridTemplateColumns: '50px 1fr auto',
            borderBottom: `1px solid #E0E0E0`,
            alignItems: 'center',
            padding: '10px 5px',
        },
        avatar: {
            width: 30,
            height: 30,
        },
        userDivName: {
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontStyle: 'normal',
            color: theme.palette.text.primary,
            textTransform: 'capitalize',
        },
        userDivRemove: {
            fontSize: 12,
            color: theme.palette.primary.light,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontStyle: 'normal',
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },
        newButtonGroup: {
            border: '1px solid red',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            '@media screen and (min-width: 1024px)': {
                position: 'absolute',
                bottom: 10,
                right: 0,
            },
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                marginTop: '20px',
            },
            '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
                marginTop: '20px',
            },
        },
        resetButtonGrid: {
            display: 'flex',
            alignSelf: 'flex-end',
            '@media screen and (min-width: 1024px)': {
                paddingRight: '10px',
            },
            '@media screen and (max-width: 770px)': {
                marginTop: '8%',
            },
        },
        resetButton: {
            height: 35,
            width: '150px',
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (min-width: 1024px)': {
                width: '140px',
            },
            '@media screen and (max-width: 770px)': {
                width: '40%',
                marginRight: '12px',
            },
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                width: '150px',
                height: '35px',
            },
            // '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
            //     width: '80%',
            //     height: '38px',
            // },
            '@media only screen and (min-width: 820px)': {
                width: '150px',
            },
        },
        buttonStyle: {
            width: '150px',
            height: 35,
            whiteSpace: 'nowrap',
            // border: `1px solid ${theme.palette.text.secondary}`,
            '& span': {
                fontWeight: 'bold',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                letterSpacing: '0.05em',
                color: theme.palette.text.secondary,
            },
        },
        updateButtonStyle: {
            whiteSpace: 'nowrap',
            height: 35,
            // width: '100%',
            width: '150px',
            outline: 'none',
            fontSize: '13px',
            border: `1px solid ${theme.palette.primary.main}`,
            '& span': {
                fontWeight: 'bold',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                letterSpacing: '0.05em',
                color: theme.palette.common.white,
            },
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.main,
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (min-width: 1024px)': {
                width: '140px',
            },
            '@media screen and (max-width: 770px)': {
                width: '40%',
            },
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                width: '150px',
                height: '35px',
            },
            // '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
            //     width: '80%',
            // },
            '@media only screen and (min-width: 820px)': {
                width: '150px',
            },
        },
        resetButton2: {
            whiteSpace: 'nowrap',
            height: 35,
            width: '150px',
            outline: 'none',
            fontSize: '13px',
            border: `1px solid ${theme.palette.text.secondary}`,
            '& span': {
                fontWeight: 'bold',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                letterSpacing: '0.05em',
                color: theme.palette.text.secondary,
            },
            '&:hover, &:focus': {
                cursor: 'not-allowed',
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (min-width: 1024px)': {
                width: '140px',
            },
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                width: '150px',
                height: '35px',
            },
            '@media screen and (max-width: 770px)': {
                width: '40%',
            },
            // '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
            //     width: '80%',
            // },
            '@media only screen and (min-width: 820px)': {
                width: '150px',
            },
        },
        footer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
        empList: {
            height: 150,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 0.5,
                height: 3,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.light,
                height: 3,
            },
            '&::-webkit-scrollbar-thumb:hover': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 3,
            },
        },
        autocompletelist: {
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.light,
                height: 3,
            },
            '&::-webkit-scrollbar-thumb:hover': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 3,
            },
            backgroundColor: theme.palette.background.paper,
        },
        autotext: {
            '& div': {
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    background: '#FFFFFF',
                    fontSize: '15px',
                    color: '#222222',
                },
                '& div': {
                    '& button': {
                        color: theme.palette.primary.contrastText,
                    },
                },
            },
        },
        superAdminList: {
            maxHeight: 170,
            overflow: 'auto',
            '@media screen and (max-width: 850px)': {
                maxHeight: 270,
            },
            '&::-webkit-scrollbar': {
                width: '0.2em',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 3px rgba(0,0,0,0)',
                webkitBoxShadow: 'inset 0 0 3px #3C48B5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#3C48B5',
                outline: 'none',
            },
        },
        autobox: {
            '& .MuiAutocomplete-input': {
                padding: '0px',
                paddingLeft: '10px',
                height: '2px',
                width: '100%',
                // width: '200%'
            },
            '& .MuiAutocomplete-inputRoot': {
                // width: '280px',
                minWidth: 280,
                width: '100%',
            },
            '& div': {
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '15px',
                    color: theme.palette.text.primary,
                },
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media screen and (min-width: 900px) and (max-width: 1055px)': {
                '& .MuiAutocomplete-input': {
                    padding: '0px',
                    paddingLeft: '10px',
                    height: '2px',
                    width: '100%',
                },
                '& .MuiAutocomplete-inputRoot': {
                    // width: '280px',
                    minWidth: 200,
                    width: '100%',
                },
            },
        },
        addBtn: {
            whiteSpace: 'nowrap',
            height: 35,
            width: '100px',
            outline: 'none',
            fontSize: '13px',
            border: `1px solid ${theme.palette.primary.main}`,
            '& span': {
                fontWeight: 'bold',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                letterSpacing: '0.05em',
                color: theme.palette.common.white,
            },
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.main,
                textDecoration: 'none',
                outline: 'none',
                border: 'none',
            },
            '@media screen and (min-width: 1024px)': {
                width: '140px',
            },
            '@media screen and (max-width: 770px)': {
                width: '40%',
            },
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                width: '150px',
                height: '35px',
            },
            '@media only screen and (min-width: 820px)': {
                width: '150px',
            },
        },
        paperstyle: {
            height: 45,
            marginBottom: 10,
            padding: '10px 20px',
            backgroundColor: theme.palette.background.paper,
        },
        firstDiv: {
            padding: 20,
            borderRight: '1px solid #CCCCCC',
            '@media screen and (max-width: 768px)': {
                border: 'none',
                borderBottom: '1px solid #CCCCCC',
            },
        },
        removeBtn: {
            '&:hover, &:focus': {
                textDecoration: 'none',
                outline: 'none',
                border: 'none',
            },
        },
        cancelBtn: {
            height: 35,
            width: 100,
            textTransform: 'capitalize',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        confirmBtn: {
            height: 35,
            width: 120,
            textTransform: 'capitalize',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
    }),
);
