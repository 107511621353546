import React, { useState, useContext, useEffect } from 'react';

import { Grid, Paper, Hidden, Typography, Icon } from '@material-ui/core';
import useStyles from '../Admin/newRegisterStyles';
import { PopUp } from '../Modal/Modal';
import { Link, useHistory } from 'react-router-dom';
import UILink from '@mui/material/Link';
import {
    fetchSlackLoginToken,
    isEmployeeInDB,
    fetchMSLoginToken,
    addClients,
    getTenantId,
    getTenantSubscriptionForPlan,
    getEmployee,
} from '../../helpers/hasuraapi';
import { lambdaMsSubscription } from 'helpers/msSubscriptionApi';
import SlackLogin from '../Login/slackPopUp';
// import { NameLoader, ThreeDotsLoader } from '../Spinner/Spinner';
import jwt_decode from 'jwt-decode';
import axios, { AxiosRequestConfig } from 'axios';
import AuthContext from '../../context/AuthContext';

import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import config from '../../config.json';
import { TeamplusLoader } from 'components/Loader/loader';
import { useFlagsmith } from 'flagsmith-react';
import { useLocation } from 'react-router-dom';
interface LoginProps {
    heading: string;
    btn?: string;
    dispatch?: any;
    token?: any;
    link?: string;
    avatar?: any;
    botType?: any;
}

export default function Login(props: LoginProps) {
    const classes = useStyles();
    const history = useHistory();
    const { hasFeature } = useFlagsmith();
    const mycontext = useContext(AuthContext);
    localStorage.setItem('inviteUrl', window.location.href);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isLogin, setIsLogin] = useState<boolean>(true);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const loginRequest = {
        scopes: [
            'User.ReadBasic.All',
            'Group.Read.All',
            'ChannelMember.Read.All',
            'Team.ReadBasic.All',
            'Directory.Read.All',
        ],
    };
    const registerRequest = {
        scopes: [
            'User.ReadBasic.All',
            'Group.Read.All',
            'ChannelMember.Read.All',
            'Team.ReadBasic.All',
            'Directory.Read.All',
        ],
    };

    // const subscriptionObject = {
    //     response: {
    //         id: 'b3e6ac1c-703c-4aaf-ddf3-a3dd37893b08',
    //         publisherId: 'udyamo1675760123066',
    //         offerId: 'demo-preview',
    //         name: 'TimeOffs by TeamPlus SaaS offer - basic plan',
    //         saasSubscriptionStatus: 'Unsubscribed',
    //         beneficiary: {
    //             emailId: 'swaraj.chavan@udgama.com',
    //             objectId: '839df3d6-05d7-4671-9e01-f2a165b7988a',
    //             tenantId: 'd32ed307-0508-4f9d-a4d0-cb2f519dabfc',
    //             puid: '100320021F2901E9',
    //         },
    //         purchaser: {
    //             emailId: 'swaraj.chavan@udgama.com',
    //             objectId: '839df3d6-05d7-4671-9e01-f2a165b7988a',
    //             tenantId: 'd32ed307-0508-4f9d-a4d0-cb2f519dabfc',
    //             puid: '100320021F2901E9',
    //         },
    //         planId: 'timeoffplan',
    //         term: {
    //             startDate: '2023-02-27T00:00:00Z',
    //             endDate: '2023-03-26T00:00:00Z',
    //             termUnit: 'P1M',
    //         },
    //         autoRenew: false,
    //         isTest: false,
    //         isFreeTrial: true,
    //         allowedCustomerOperations: ['Delete', 'Read', 'Update'],
    //         sandboxType: 'None',
    //         created: '2023-02-27T06:58:31.5425517Z',
    //         lastModified: '0001-01-01T00:00:00',
    //         quantity: 8,
    //         sessionMode: 'None',
    //     },
    // };
    const loginrequest = {
        ...loginRequest,
        account: accounts[0],
    };
    const registerrequest = {
        ...registerRequest,
        account: accounts[0],
    };

    const customSvgMicrosoft = (
        <Icon fontSize="small" style={{ marginRight: 5 }}>
            <img
                src={'https://teamplusimagestorage.blob.core.windows.net/teamplusimagestorage/avatars/ms-pictogram.svg'}
                className={classes.googleImg}
                alt="MSIcon"
            ></img>{' '}
        </Icon>
    );
    // const styleclass = MBScreenStyle();

    useEffect(() => {
        const subscriptionToken = searchParams.get('token');
        if (subscriptionToken) {
            localStorage.setItem('subscriptionToken', subscriptionToken);
            localStorage.setItem('botType', props.botType);
            mycontext.logout();
        }
        if (mycontext.isLogedIn) {
            history.push('/dashboard');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mycontext.isLogedIn]);

    const loginFunction = async (response: any) => {
        const tokenResponse = await fetchMSLoginToken(response.idToken);
        const isUser: any = await isEmployeeInDB(response?.idTokenClaims?.oid, tokenResponse.token);
        const decoded: any = jwt_decode(response.idToken);
        const stringArray = decoded.name.split(' ');
        if (isUser === 0) {
            const obj: any = {
                email: response?.idTokenClaims?.preferred_username,

                firstName: stringArray[0],
                lastName: stringArray[1] !== undefined ? stringArray[1] : '',

                token: tokenResponse.token,
            };
            await addClients(obj);
            let subscriptionTokenError = false;
            if (localStorage.getItem('subscriptionToken')) {
                const bodyContentsResolve = {
                    action: 'RESOLVE',
                    subscriptionToken: localStorage.getItem('subscriptionToken'),
                    tenantId: response.tenantId,
                    botType: localStorage.getItem('botType'),
                };
                const resolveSubscription = await lambdaMsSubscription(bodyContentsResolve);
                // console.log('resolved', resolveSubscription);
                // const resolveSubscription = subscriptionObject;
                // console.log('FsfsG', resolveSubscription);
                if (!resolveSubscription?.response?.id) {
                    localStorage.removeItem('subscriptionToken');
                    localStorage.removeItem('botType');
                    subscriptionTokenError = true;
                } else {
                    localStorage.setItem('subscriptionId', resolveSubscription?.response?.id);
                }
            }

            localStorage.setItem('loginMethod', 'teams');
            history.push({
                pathname: '/mymsteams',
                state: {
                    accessToken: response.accessToken,
                    idToken: response.idToken,
                    invalidSubscriptionToken: subscriptionTokenError,
                },
            });
        } else {
            try {
                setLoading(true);
                const tokenResponse = await fetchMSLoginToken(response.idToken);
                const decoded: any = jwt_decode(response.idToken);
                const Tid = await getTenantId(decoded.tid, tokenResponse.token);
                const data = await getEmployee(decoded.oid, tokenResponse.token);
                mycontext.login({
                    username: decoded.preferred_username,
                    EmployeeId: decoded.oid,
                    fname: '',
                    lname: '',
                    isLogedIn: false,
                    joining: '',
                    profile: '',
                    statustype: '',
                    email: decoded.preferred_username,
                    isGoogle: false,
                    token: tokenResponse.token,
                    uuid: decoded.oid,
                    standupTempId: '',
                    tenantId: response.tenantId,
                    tenantTableId: Tid[0]?.id,
                    isSuperAdmin: data?.tenant_employees[0]?.role === 'superadmin' ? true : false,
                });
                let subscriptionTokenError = false;
                let alreadyPurchasedError = false;
                if (localStorage.getItem('subscriptionToken')) {
                    const bodyContentsResolve = {
                        action: 'RESOLVE',
                        subscriptionToken: localStorage.getItem('subscriptionToken'),
                        tenantId: response.tenantId,
                        botType: localStorage.getItem('botType'),
                    };
                    const resolveSubscription = await lambdaMsSubscription(bodyContentsResolve);
                    // console.log('resolved', resolveSubscription);
                    // const resolveSubscription = subscriptionObject;
                    if (!resolveSubscription?.response?.id) {
                        localStorage.removeItem('subscriptionToken');
                        localStorage.removeItem('botType');
                        subscriptionTokenError = true;
                    } else {
                        let planName = '';
                        switch (true) {
                            case resolveSubscription?.response.planId.includes('tbtp'):
                                planName = 'tbtp';
                                break;
                            case resolveSubscription?.response.planId.includes('sbtp'):
                                planName = 'sbtp';
                                break;
                            case resolveSubscription?.response.planId.includes('pbtp'):
                                planName = 'pbtp';
                                break;
                            case resolveSubscription?.response.planId.includes('ebtp'):
                                planName = 'ebtp';
                                break;
                            default:
                                break;
                        }
                        const alreadySubscribed = await getTenantSubscriptionForPlan(
                            Tid[0]?.id,
                            planName,
                            tokenResponse.token,
                        );
                        if (
                            alreadySubscribed.length !== 0 &&
                            !alreadySubscribed[0]?.extensions?.code &&
                            alreadySubscribed[0]?.status !== 'Unsubscribed'
                        ) {
                            localStorage.removeItem('subscriptionToken');
                            localStorage.removeItem('botType');
                            alreadyPurchasedError = true;
                        } else {
                            if (alreadySubscribed[0]?.status === 'Unsubscribed') {
                                const subscriptionType = alreadySubscribed[0]?.planId.includes('tbtp')
                                    ? 'timeoffSubscriptionId'
                                    : alreadySubscribed[0]?.planId.includes('sbtp')
                                    ? 'standupSubscriptionId'
                                    : alreadySubscribed[0]?.planId.includes('pbtp')
                                    ? 'pollSubscriptionId'
                                    : 'engageSubscriptionId';
                                localStorage.setItem('oldSubscriptionId', alreadySubscribed[0]?.subscriptionId);
                                localStorage.setItem('oldSubscriptionType', subscriptionType);
                            }
                            localStorage.setItem('subscriptionId', resolveSubscription?.response?.id);
                        }
                    }
                }
                localStorage.setItem('loginMethod', 'teams');
                setLoading(false);
                history.push({
                    pathname: '/myorganizations',
                    state: {
                        id: decoded.oid,
                        token: tokenResponse.token,
                        refreshToken: tokenResponse.refresh_token,
                        invalidSubscriptionToken: subscriptionTokenError,
                        alreadyPurchasedSubscriptionError: alreadyPurchasedError,
                    },
                });
            } catch (err) {
                localStorage.removeItem('subscriptionToken');
                localStorage.removeItem('botType');
                setLoading(false);
                setAlert(true);
                setSeverity('error');
                setAlertMessage('Something went wrong');
                return err;
            }
        }
    };

    React.useEffect(() => {
        if (isAuthenticated) {
            instance
                .acquireTokenSilent(loginrequest)
                .then(async (response: any) => {
                    await loginFunction(response);
                })
                .catch((e: any) => {
                    instance.acquireTokenPopup(loginrequest).then(async (response: any) => {
                        await loginFunction(response);
                    });
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const onFailed = (message: any) => {
        setLoading(false);
        setAlert(true);
        setSeverity('error');
        setAlertMessage('Click allow to give access!');
    };

    const onSuccess = async (message: any) => {
        try {
            setLoading(true);
            const slackUrl = 'https://slack.com/api/openid.connect.token';
            const client_id = config.client_id_dev;
            const client_secret = config.client_secret_dev;
            const code = message;
            const details = {
                code,
                client_id,
                client_secret,
            };
            const formBody = [];
            for (const property in details) {
                const encodedKey = encodeURIComponent(property);
                const encodedValue = encodeURIComponent(details[property]);
                formBody.push(encodedKey + '=' + encodedValue);
            }
            const formBodyTwo = formBody.join('&');

            const _headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
            };

            const configAxios: AxiosRequestConfig = {
                method: 'POST',
                url: slackUrl,
                data: formBodyTwo,
                headers: _headers,
            };

            const response = await axios(configAxios);
            const authToken = response.data.access_token;
            const decodedSlackToken: any = jwt_decode(response.data.id_token);
            const slackUserId = decodedSlackToken.sub;
            const bodyFormData = new FormData();
            bodyFormData.append('token', authToken);
            const tokenResponse = await fetchSlackLoginToken(slackUserId, response.data.access_token);
            const token: any = tokenResponse;
            localStorage.setItem('loginMethod', 'Slack');
            history.push({
                pathname: '/slackRegistration',
                state: {
                    token: token.token,
                    refreshToken: token.refresh_token,
                },
            });
        } catch (e) {
            setLoading(false);
            setAlert(true);
            setSeverity('error');
            setAlertMessage('Please try again in sometime');
        }
    };

    const handleMicrosoftSignIn = async () => {
        if (isLogin) {
            instance
                .loginPopup(loginrequest)
                .then(async (res: any) => {
                    return res;
                })
                .catch((e: any) => {
                    return e;
                });
        } else {
            instance
                .loginPopup(registerrequest)
                .then(async (res: any) => {
                    return res;
                })
                .catch((e: any) => {
                    // console.log('Err', e);
                });
        }
    };

    /* istanbul ignore next */
    // if (loading) {
    //     return (
    //         <>
    //             <Hidden smDown>
    //                 <div className={classes.rootLoader}>
    //                     <NameLoader />
    //                     <ThreeDotsLoader />
    //                 </div>
    //             </Hidden>
    //             <Hidden smUp>
    //                 <div className={classes.loginMain}>
    //                     <h3 className={classes.welcome}>Welcome</h3>
    //                     <h3 className={classes.to}>To</h3>
    //                     <img
    //                         src={s3Bucket + registerLoginPageImages.udyamoTeamplusWhite}
    //                         alt="teamplus"
    //                         className={classes.teamLogoSmallMobile}
    //                     />
    //                 </div>
    //             </Hidden>
    //         </>
    //     );
    // }
    if (loading) {
        return <TeamplusLoader />;
    }
    return (
        <div style={{ display: 'flex' }}>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.registerPageImage}
                                    alt="Page1Img"
                                    style={{
                                        height: '75vh',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                    alt="udyamoLogo"
                                    style={{
                                        height: '2.5vh',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} xl={7} className={classes.secondGrid}>
                    <Paper style={{ boxShadow: 'none' }} className={classes.paperStyle}>
                        <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                        <Grid container direction="row">
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                {isLogin ? (
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.SignInheading}
                                        id="signin-heading"
                                    >
                                        Login
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.SignInheading}
                                        id="signin-heading"
                                    >
                                        Register
                                    </Typography>
                                )}
                            </Grid>
                            {hasFeature('disableslacklogin') && (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingTop: 25,
                                    }}
                                >
                                    <div>
                                        <SlackLogin
                                            onFailure={onFailed}
                                            onSuccess={onSuccess}
                                            slackClientId={config.client_id_prod}
                                        />
                                    </div>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: 25,
                                }}
                            >
                                <div>
                                    <div onClick={() => handleMicrosoftSignIn()} className={classes.authBtn1}>
                                        {customSvgMicrosoft} Sign in with Microsoft
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                style={{
                                    marginBottom: 30,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {isLogin ? (
                                    <Typography variant="caption" className={classes.createLink}>
                                        New to teamplus?{' '}
                                        <Link
                                            to={{
                                                pathname: '/login',
                                            }}
                                            style={{
                                                textDecoration: 'none',
                                            }}
                                            onClick={() => setIsLogin(!isLogin)}
                                        >
                                            Register
                                        </Link>
                                    </Typography>
                                ) : (
                                    <Typography variant="caption" className={classes.createLink}>
                                        Already a user?{' '}
                                        <Link
                                            to={{
                                                pathname: '/login',
                                            }}
                                            style={{
                                                textDecoration: 'none',
                                            }}
                                            onClick={() => setIsLogin(!isLogin)}
                                        >
                                            Login
                                        </Link>
                                    </Typography>
                                )}
                                <Typography variant="caption" className={classes.createLink}>
                                    Visit to home page?{' '}
                                    <UILink
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.udyamo.com/teamplus/"
                                        style={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Click here
                                    </UILink>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {/* </Hidden> */}
        </div>
    );
}
