import React, { useContext } from 'react';
import { Button, Hidden, Paper, Typography } from '@material-ui/core';
import myStyle from './TeamStyle';
import AuthContext from '../../context/AuthContext';
// import Arrow from '../../Assets/images/leftArrow.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface Props {
    current?: string;
    setCurrent?: any;
    handleCreateTeam?: any;
    handleEditTeam?: any;
    handleDeleteTeam?: any;
    teamDetails?: any;
}

const TeamsToolbar = (props: Props) => {
    const classes = myStyle();
    const mycontext = useContext(AuthContext);

    if (props.current === 'create') {
        return (
            <>
                <Hidden mdDown>
                    <Paper elevation={0} className={classes.teampaper}>
                        <div className={classes.top}>
                            <ArrowBackIcon className={classes.backArrow} onClick={() => props.setCurrent('teams')} />
                            <Typography className={classes.heading}>Create New Team</Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button
                                className={classes.btnOutlined}
                                id="cancel"
                                variant="outlined"
                                color="primary"
                                onClick={() => props.setCurrent('teams')}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={classes.btn}
                                id="createteam"
                                variant="contained"
                                onClick={props.handleCreateTeam}
                            >
                                Create Team
                            </Button>
                        </div>
                    </Paper>
                </Hidden>
                <Hidden lgUp>
                    <div className={classes.toolbarRow}>
                        <div className={classes.tab}>Create New Team</div>
                    </div>
                    <Button
                        className={classes.btnOutlined2}
                        id="cancel"
                        variant="outlined"
                        color="primary"
                        onClick={() => props.setCurrent('teams')}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.btnHalfFixed}
                        id="createteam"
                        variant="contained"
                        onClick={props.handleCreateTeam}
                    >
                        Create Team
                    </Button>
                </Hidden>
            </>
        );
    } else if (props.current === 'edit') {
        return (
            <>
                <Hidden mdDown>
                    <Paper elevation={0} className={classes.teampaper}>
                        <div className={classes.top}>
                            <ArrowBackIcon className={classes.backArrow} onClick={() => props.setCurrent('teams')} />
                            <Typography className={classes.heading}>Team Details</Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button
                                className={classes.btnOutlined}
                                id="cancel"
                                variant="outlined"
                                color="primary"
                                onClick={() => props.setCurrent('teams')}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={classes.btn}
                                id="createteam"
                                variant="contained"
                                onClick={props.handleEditTeam}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </Paper>
                </Hidden>
                <Hidden lgUp>
                    <>
                        <Button
                            className={classes.btnOutlined2}
                            id="cancel"
                            variant="outlined"
                            color="primary"
                            onClick={() => props.setCurrent('teams')}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.btnHalfFixed}
                            id="createteam"
                            variant="contained"
                            onClick={props.handleEditTeam}
                        >
                            Save Changes
                        </Button>
                    </>
                </Hidden>
            </>
        );
    } else if (props.current === 'details') {
        return (
            <>
                <Hidden mdDown>
                    <Paper elevation={0} className={classes.teampaper}>
                        <div className={classes.top}>
                            <ArrowBackIcon className={classes.backArrow} onClick={() => props.setCurrent('teams')} />
                            <Typography className={classes.heading}>Team Details</Typography>
                        </div>
                        {mycontext.isAdmin && props.teamDetails.teamName !== 'general' && (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Button
                                    className={classes.btnOutlined}
                                    id="cancel"
                                    style={{ outline: 'none' }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={props.handleDeleteTeam}
                                >
                                    Delete Team
                                </Button>
                                <Button
                                    className={classes.btn}
                                    id="createteam"
                                    variant="contained"
                                    onClick={() => props.setCurrent('edit')}
                                >
                                    Edit Team
                                </Button>
                            </div>
                        )}
                    </Paper>
                </Hidden>

                <Hidden lgUp>
                    <>
                        <div className={classes.toolbarRow}>
                            <div className={classes.tab}>
                                <ArrowBackIcon
                                    className={classes.backArrow}
                                    onClick={() => props.setCurrent('teams')}
                                />
                                &nbsp; Team Details
                            </div>
                        </div>
                        {mycontext.isAdmin && props.teamDetails.teamName !== 'general' && (
                            <>
                                <Button
                                    className={classes.btnOutlined2}
                                    id="cancel"
                                    variant="outlined"
                                    color="primary"
                                    onClick={props.handleDeleteTeam}
                                >
                                    Delete Team
                                </Button>
                                <Button
                                    className={classes.btnHalfFixed}
                                    id="createteam"
                                    variant="contained"
                                    onClick={() => props.setCurrent('edit')}
                                >
                                    Edit Team
                                </Button>
                            </>
                        )}
                    </>
                </Hidden>
            </>
        );
    }

    return (
        <>
            <Hidden mdDown>
                <div className={classes.toolbarRow}>
                    <div className={classes.tab}>Teams</div>
                    {mycontext.isAdmin && (
                        <div style={{ marginRight: '10px' }}>
                            <Button
                                className={classes.button}
                                id="create_new_team"
                                variant="contained"
                                color="primary"
                                onClick={() => props.setCurrent('create')}
                            >
                                Create New Team
                            </Button>
                        </div>
                    )}
                </div>
            </Hidden>
            <Hidden lgUp>
                <div className={classes.toolbarRow}>
                    <div className={classes.tab}>Teams</div>
                </div>
            </Hidden>
        </>
    );
};

export default TeamsToolbar;
