import React, { useState, useContext } from 'react';
import RegisterPartOne from './registerPartOne';
import RegisterPartTwo from './registerPartTwo';
import RegisterPartThree from './registerPartThree';
import RegisterPartFour from './registerPartFour';
import AuthContext from '../../context/AuthContext';
import { toAWSDateFormat } from '../../helpers/date';
import { Auth } from 'aws-amplify';
import {
    registerAdmin,
    registerOrganization,
    createOrganizationRole,
    createOrganizationSettings,
    createDefaultOrganizationTeam,
    insertTeamEmployee,
    insertPendingActions,
    createDefaultLeaveType,
    // sendInvite,
} from '../../helpers/hasuraapi';
import { adminQuestion } from '../../helpers/hasuraapi3';
import { useHistory, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { PopUp } from '../Modal/Modal';

export default function RegisterAdminMain() {
    const mycontext = useContext(AuthContext);
    const location: any = useLocation();
    const [pageNumber, setPageNumber] = useState(location.state?.pageNumber || 0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [code, setCode] = useState<string>('');
    const [orgName, setOrgName] = useState('');
    const [employeeEmail, setEmployeeEmail] = useState('');
    const [allEmailsArray, setAllEmailsArray] = useState<string[]>([]);
    const [checkedTC, setCheckedTC] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const invitees: any = [];

    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [severity, setSeverity] = useState<string>('');

    const [task, setTask] = useState({
        task1: false,
        task2: false,
        task3: false,
        task4: false,
        task5: false,
    });

    const [country, setCountry] = useState({
        countryCode: 'IN',
        countryName: 'India',
        gmtOffset: 19800,
        timestamp: 1665487798,
        zoneName: 'Asia/Kolkata',
    });
    const history = useHistory();

    const createNewOrganization = async () => {
        try {
            if (orgName === '') {
                throw new Error('Please enter organisation name');
            } else if (orgName.length < 2) {
                throw new Error('Organisation name should consist of atleast 2 characters');
            }
            setIsLoading(true);
            const organization = {
                employeeId: mycontext.uuid,
                organizationName: orgName,
                token: mycontext.token,
                location: country,
            };
            const org = await registerOrganization(organization);
            setTask({ ...task, task1: true });
            const empRole = {
                organizationId: org.organizationId,
                role: 'admin',
                token: mycontext.token,
                location: country,
            };
            await createOrganizationRole(empRole);
            setTask({ ...task, task1: true, task2: true });
            const defaultSettings = {
                organizationId: org.organizationId,
                dateFormat: 'DD-MM-YYYY',
                displayLogo: false,
                approverCalledAs: 'approver',
                employeeCalledAs: 'employee',
                subscriptionPlan: '',
                teamsCalledAs: 'teams',
                workWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
                workingHours: 5,
                token: mycontext.token,
            };

            await createOrganizationSettings(defaultSettings);
            setTask({ ...task, task1: true, task2: true, task3: true });
            const defaultTeam = {
                teamName: 'general',
                organizationId: org.organizationId,
                createdAt: toAWSDateFormat(new Date()),
                token: mycontext.token,
            };

            const team = await createDefaultOrganizationTeam(defaultTeam);
            const teamUser = {
                employeeId: mycontext.uuid,
                isApprover: true,
                teamId: team.teamId,
                token: mycontext.token,
            };
            allEmailsArray.map((email: string) =>
                invitees.push({
                    emailId: email,
                    orgId: org.organizationId,
                    teams: [team.teamId],
                }),
            );
            await insertPendingActions({
                organizationId: org.organizationId,
                token: mycontext.token,
            });
            await insertTeamEmployee(teamUser);
            const leaveType1 = {
                organizationId: org.organizationId,
                token: mycontext.token,
                leaveTypeName: 'Sick',
                isPaid: true,
                daysPerYear: 0,
                approvalRequired: true,
                active: true,
                unlimitedDays: true,
                defaultEnable: true,
                unified: '1f334',
                emoji: '🌴',
                activeSkinTone: 'neutral',
                originalUnified: '1f334',
            };
            const leaveType2 = {
                organizationId: org.organizationId,
                token: mycontext.token,
                leaveTypeName: 'Casual',
                isPaid: false,
                daysPerYear: 25,
                approvalRequired: true,
                active: true,
                unlimitedDays: false,
                defaultEnable: true,
                unified: '1f334',
                emoji: '🌴',
                activeSkinTone: 'neutral',
                originalUnified: '1f334',
            };
            const standupTemplate = {
                questions: ['What did you accomplish yesterday?', 'What is your goal for today?', 'Blockers'],
                employeeId: mycontext.uuid,
                organizationId: org.organizationId,
                token: mycontext.token,
            };
            const data = await adminQuestion(standupTemplate);
            mycontext.addStandupTempId(data?.standupTempId);
            setTask({ ...task, task1: true, task2: true, task3: true, task4: true, task5: true });
            await createDefaultLeaveType(leaveType1);
            await createDefaultLeaveType(leaveType2);
            // await sendInvite(invitees, mycontext.token);
            setIsLoading(false);
            history.push({
                pathname: '/myorganizations',
                state: {
                    id: mycontext.uuid,
                    token: mycontext.token,
                    refreshToken: mycontext.refreshToken,
                    isSignup: true,
                },
            });
        } catch (err) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage(err.message);
            return err;
        }
    };

    const confirmSubmit = async () => {
        try {
            if (orgName === '') {
                throw new Error('Please enter organisation name');
            } else if (orgName.length < 2) {
                throw new Error('Organisation name should consist of atleast two characters');
            }
            setIsLoading(true);
            const loginResponse = await Auth.signIn(emailId, password);
            const decoded: any = jwt_decode(loginResponse.signInUserSession.idToken.jwtToken);
            const employee: any = {
                email: emailId,
                firstName: firstName,
                lastName: lastName,
                joining: toAWSDateFormat(new Date()),
                token: loginResponse.signInUserSession.idToken.jwtToken,
            };
            await registerAdmin(employee);
            setTask({ ...task, task1: true });
            const organization = {
                employeeId: decoded.sub,
                organizationName: orgName,
                token: loginResponse.signInUserSession.idToken.jwtToken,
                location: country,
            };
            const org = await registerOrganization(organization);
            const empRole = {
                organizationId: org.organizationId,
                role: 'admin',
                token: loginResponse.signInUserSession.idToken.jwtToken,
                location: country,
            };
            await createOrganizationRole(empRole);
            setTask({ ...task, task1: true, task2: true });
            const defaultSettings = {
                organizationId: org.organizationId,
                dateFormat: 'DD-MM-YYYY',
                displayLogo: false,
                approverCalledAs: 'approver',
                employeeCalledAs: 'employee',
                subscriptionPlan: '',
                teamsCalledAs: 'teams',
                workWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
                workingHours: 5,
                token: loginResponse.signInUserSession.idToken.jwtToken,
            };

            await createOrganizationSettings(defaultSettings);
            const defaultTeam = {
                teamName: 'general',
                organizationId: org.organizationId,
                createdAt: toAWSDateFormat(new Date()),
                token: loginResponse.signInUserSession.idToken.jwtToken,
            };

            const team = await createDefaultOrganizationTeam(defaultTeam);
            const teamUser = {
                employeeId: decoded.sub,
                isApprover: true,
                teamId: team.teamId,
                token: loginResponse.signInUserSession.idToken.jwtToken,
            };
            allEmailsArray.map((email: string) =>
                invitees.push({
                    emailId: email,
                    orgId: org.organizationId,
                    teams: [team.teamId],
                }),
            );
            await insertPendingActions({
                organizationId: org.organizationId,
                token: loginResponse.signInUserSession.idToken.jwtToken,
            });
            setTask({ ...task, task1: true, task2: true, task3: true });
            await insertTeamEmployee(teamUser);
            const leaveType1 = {
                organizationId: org.organizationId,
                token: loginResponse.signInUserSession.idToken.jwtToken,
                leaveTypeName: 'Sick',
                isPaid: true,
                daysPerYear: 0,
                approvalRequired: true,
                active: true,
                unlimitedDays: true,
                defaultEnable: true,
                unified: '1f334',
                emoji: '🌴',
                activeSkinTone: 'neutral',
                originalUnified: '1f334',
            };
            const leaveType2 = {
                organizationId: org.organizationId,
                token: loginResponse.signInUserSession.idToken.jwtToken,
                leaveTypeName: 'Casual',
                isPaid: false,
                daysPerYear: 25,
                approvalRequired: true,
                active: true,
                unlimitedDays: false,
                defaultEnable: true,
                unified: '1f334',
                emoji: '🌴',
                activeSkinTone: 'neutral',
                originalUnified: '1f334',
            };
            const standupTemplate = {
                questions: ['What did you accomplish yesterday?', 'What is your goal for today?', 'Blockers'],
                employeeId: decoded.sub,
                organizationId: org.organizationId,
                token: loginResponse.signInUserSession.idToken.jwtToken,
            };
            const data = await adminQuestion(standupTemplate);
            // console.log('ab', data);
            mycontext.addStandupTempId(data?.standupTempId);
            setTask({ ...task, task1: true, task2: true, task3: true, task4: true, task5: true });
            await createDefaultLeaveType(leaveType1);
            await createDefaultLeaveType(leaveType2);
            // await sendInvite(invitees, loginResponse.signInUserSession.idToken.jwtToken);
            setIsLoading(false);
            history.push({
                pathname: '/myorganizations',
                state: {
                    id: decoded.sub,
                    token: loginResponse.signInUserSession.idToken.jwtToken,
                    refreshToken: loginResponse.signInUserSession.refreshToken.token,
                    isSignup: true,
                },
            });
        } catch (err) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage(err.message);
            return err;
        }
    };

    const backButton = () => {
        setPageNumber(pageNumber - 1);
    };

    const values = {
        firstName: firstName,
        setFirstName: setFirstName,
        lastName: lastName,
        setLastName: setLastName,
        emailId: emailId,
        setEmailId: setEmailId,
        password: password,
        setPassword: setPassword,
        confirmPassword: confirmPassword,
        setConfirmPassword: setConfirmPassword,
        code: code,
        setCode: setCode,
        orgName: orgName,
        setOrgName: setOrgName,
        employeeEmail: employeeEmail,
        setEmployeeEmail: setEmployeeEmail,
        allEmailsArray: allEmailsArray,
        setAllEmailsArray: setAllEmailsArray,
        checkedTC: checkedTC,
        setCheckedTC: setCheckedTC,
        confirmSubmit: confirmSubmit,
        backButton: backButton,
        pageNumber: pageNumber,
        isLoading: isLoading,
        task: task,
        createNewOrganization: createNewOrganization,
        country,
        setCountry,
    };
    switch (pageNumber) {
        case 0:
            return <RegisterPartOne values={values} setPageNumber={setPageNumber} />;
        case 1:
            return <RegisterPartTwo values={values} setPageNumber={setPageNumber} />;
        case 2:
            return <RegisterPartThree values={values} setPageNumber={setPageNumber} />;
        case 3:
            return (
                <>
                    <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                    <RegisterPartFour
                        values={values}
                        setPageNumber={setPageNumber}
                        takeMeBack={location.state?.takeMeBack || false}
                    />
                </>
            );
        default:
            return <div></div>;
    }
}
