import React, { useState, useContext } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    Avatar,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Grid,
    // Menu,
    MenuItem,
    IconButton,
    Paper,
    Container,
    Hidden,
    Popover,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    useTheme,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './TeammemberStyles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useDispatch } from 'react-redux';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import ActionModal from './ActionModal';
import { useHistory } from 'react-router-dom';
import {
    updateOrganizationEmployee,
    // deleteEmployeeFromTeams,
    // deleteOrganizationEmployee,
    // deleteEmployeeLeaves,
    getUserApproverTeamCount,
    // deleteEmployeeInvites,
    // deleteNotifSeenOfEmp,
    // deleteNotifOfEmp,
    // fetchShoutouts,
    // deleteEmpShoutouts,
    // deleteEmpShoutoutData,
    // deleteComReac,
    // deleteEmployeeFromOrg,
} from '../../helpers/hasuraapi';
import AuthContext from '../../context/AuthContext';
import { PopUp } from '../Modal/Modal';
import { promoteToAdmin } from '../Redux/employeeListReducer';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import { useApolloClient, gql } from '@apollo/client';
import DisplayEmoji from './DisplayEmoji';

const StyledTableCell0 = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: theme.typography.fontWeightBold,
        width: '50px',
    },
    body: {
        fontSize: '14px',
        minWidth: '100px',
        maxWidth: '90px',
        borderBottom: 'none',
        whiteSpace: 'break-spaces',
    },
}))(TableCell);

const StyledTableCell1 = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: theme.typography.fontWeightBold,
        width: '50px',
    },
    body: {
        fontSize: '14px',
        minWidth: '40px',
        maxWidth: '50px',
        borderBottom: 'none',
        whiteSpace: 'break-spaces',
    },
}))(TableCell);

const StyledTableCell2 = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: theme.typography.fontWeightBold,
        minWidth: '110px',
        maxWidth: '120px',
    },
    body: {
        fontSize: '14px',
        minWidth: '120px',
        maxWidth: '130px',
        borderBottom: 'none',
    },
}))(TableCell);

const StyledTableCell3 = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: theme.typography.fontWeightBold,
        minWidth: '50px',
    },
    body: {
        fontSize: '14px',
        minWidth: '90px',
        maxWidth: '100px',
        borderBottom: 'none',
        whiteSpace: 'break-spaces',
    },
}))(TableCell);

const StyledTableCell4 = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: theme.typography.fontWeightBold,
        minWidth: '50px',
    },
    body: {
        fontSize: '14px',
        minWidth: '40px',
        maxWidth: '50px',
        borderBottom: 'none',
        whiteSpace: 'break-spaces',
    },
}))(TableCell);

const StyledTableCell5 = withStyles(theme => ({
    root: {
        // padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: theme.typography.fontWeightBold,
        width: '50px',
    },
    body: {
        fontSize: '14px',
        minWidth: '150px',
        maxWidth: '160px',
        borderBottom: 'none',
        whiteSpace: 'break-spaces',
        padding: '0px 24px 0px 16px',
    },
}))(TableCell);

// const StyledTableRow = withStyles(theme =>
//     createStyles({
//         root: {
//             '&:nth-of-type(odd)': {
//                 backgroundColor: 'white',
//             },
//         },
//     }),
// )(TableRow);

interface Props {
    teammembers?: any;
    setIsUpdating?: any;
    teamEmployees?: any;
    loading?: boolean;
}

export default function EmployeeTable(props: Props): JSX.Element {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentEmployee, setCurrentemployee] = useState<any>({});

    const [anchorEls, setAnchorEls] = useState<any>([]);
    const [listAnchor, setListAnchor] = useState<any>(null);
    const [currentList, setCurrentList] = useState<any>([]);

    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');

    const client = useApolloClient();

    const handleMenu = (employee: any, event: React.MouseEvent<HTMLElement>) => {
        setAnchorEls((anchorEls: any) => ({
            ...anchorEls,
            [employee.id]: event.target,
        }));
        setCurrentemployee(employee);
    };

    const [modalA, setModalA] = useState(false);
    const toggleA = () => setModalA(!modalA);

    const [modalB, setModalB] = useState(false);
    const toggleB = () => {
        setModalB(!modalB);
    };

    // const [modalC, setModalC] = useState(false);
    // const toggleC = () => setModalC(!modalC);

    const handleClose = (empId: any) => {
        setAnchorEls((anchorEls: any) => ({
            ...anchorEls,
            [empId]: null,
        }));
    };

    // const refreshPage = () => {
    //     window.location.reload();
    // };

    const handleSubmitAdmin = async () => {
        setModalA(false);
        props.setIsUpdating(true);
        const resp = await updateOrganizationEmployee(
            {
                empId: currentEmployee.id,
                orgId: mycontext.organizationId,
                role: 'admin',
            },
            mycontext.token,
        );
        if (resp.error) {
            props.setIsUpdating(false);
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Something went wrong.');
        } else {
            props.setIsUpdating(false);
            dispatch(
                promoteToAdmin({
                    empId: resp.returning[0].employeeId,
                    role: resp.returning[0].role,
                }),
            );
            const employeeDetail = props.teamEmployees.filter((item: any) => {
                return item.id === resp.returning[0].employeeId;
            });
            // console.log('Details', employeeDetail);
            const query = gql`
                query MyQuery {
                    usermanagementdb_organization_employee(where: {employeeId: {_eq: "${resp.returning[0].employeeId}"}, organizationId: {_eq: "${mycontext.organizationId}"}}) {
                        employeeId
                        role
                        Employee {
                            firstName
                            lastName
                            profileImage
                        }
                    }
                }
            `;
            const updateQueryObject = [
                {
                    __typename: 'usermanagementdb_organization_employee',
                    employeeId: resp.returning[0].employeeId,
                    role: resp.returning[0].role,
                    Employee: {
                        __typename: 'usermanagementdb_Employee',
                        firstName: employeeDetail[0].firstName,
                        lastName: employeeDetail[0].lastName,
                        profileImage: employeeDetail[0].image,
                    },
                },
            ];
            client.writeQuery({
                query,
                data: {
                    usermanagementdb_organization_employee: updateQueryObject,
                },
            });
            setSeverity('success');
            setAlert(true);
            setAlertMessage('Admin added successfully!');
        }
    };

    const handleRemoveAdmin = async () => {
        setModalB(false);
        props.setIsUpdating(true);
        const teamCount = await getUserApproverTeamCount(
            {
                empId: currentEmployee.id,
                orgId: mycontext.organizationId,
            },
            mycontext.token,
        );

        const response = await updateOrganizationEmployee(
            {
                empId: currentEmployee.id,
                orgId: mycontext.organizationId,
                role: teamCount > 0 ? 'approver' : 'employee',
            },
            mycontext.token,
        );

        if (response.error) {
            props.setIsUpdating(false);
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Unable to remove admin.');
        } else {
            props.setIsUpdating(false);
            dispatch(
                promoteToAdmin({
                    empId: response.returning[0].employeeId,
                    role: teamCount > 0 ? 'approver' : 'employee',
                }),
            );
            const employeeDetail = props.teamEmployees.filter((item: any) => {
                return item.id === response.returning[0].employeeId;
            });
            // console.log('Details', employeeDetail);
            const query = gql`
                query MyQuery {
                    usermanagementdb_organization_employee(where: {employeeId: {_eq: "${response.returning[0].employeeId}"}, organizationId: {_eq: "${mycontext.organizationId}"}}) {
                        employeeId
                        role
                        Employee {
                            firstName
                            lastName
                            profileImage
                        }
                    }
                }
            `;
            const updateQueryObject = [
                {
                    __typename: 'usermanagementdb_organization_employee',
                    employeeId: response.returning[0].employeeId,
                    role: teamCount > 0 ? 'approver' : 'employee',
                    Employee: {
                        __typename: 'usermanagementdb_Employee',
                        firstName: employeeDetail[0].firstName,
                        lastName: employeeDetail[0].lastName,
                        profileImage: employeeDetail[0].image,
                    },
                },
            ];
            client.writeQuery({
                query,
                data: {
                    usermanagementdb_organization_employee: updateQueryObject,
                },
            });
            setSeverity('success');
            setAlert(true);
            setAlertMessage('Admin removed successfully!');
            // refreshPage();
        }
    };

    // const handleRemoveEmployee = async () => {
    //     setModalC(false);
    //     props.setIsUpdating(true);
    //     const response = await fetchShoutouts(mycontext.organizationId, mycontext.token);
    //     if (response.error) {
    //         props.setIsUpdating(false);
    //         setSeverity('error');
    //         setAlert(true);
    //         setAlertMessage('Unable to delete employee. Please try again!');
    //     } else {
    //         const givenShoutouts: any[] = [];
    //         await response.data.shoutoutdb_Shoutout.map(async (row: any) => {
    //             if (row.createdBy === currentEmployee.id) {
    //                 givenShoutouts.push(row.shoutoutId);
    //             } else {
    //                 if (row.shoutout_employee_teams.length === 1) {
    //                     if (row.shoutout_employee_teams[0].Employee === null) {
    //                         await deleteComReac(row.shoutoutId, currentEmployee.id, mycontext.token);
    //                     } else {
    //                         if (row.shoutout_employee_teams[0].Employee.employeeId === currentEmployee.id) {
    //                             await deleteEmpShoutouts([row.shoutoutId], mycontext.token);
    //                         } else {
    //                             await deleteComReac(row.shoutoutId, currentEmployee.id, mycontext.token);
    //                         }
    //                     }
    //                 } else {
    //                     if (row.shoutout_employee_teams[0].Team === null) {
    //                         await deleteEmpShoutoutData(row.shoutoutId, currentEmployee.id, mycontext.token);
    //                     } else {
    //                         await deleteComReac(row.shoutoutId, currentEmployee.id, mycontext.token);
    //                     }
    //                 }
    //             }
    //         });
    //         await deleteEmpShoutouts(givenShoutouts, mycontext.token);

    //         const delEmployeeFromOrg = await deleteEmployeeFromOrg(
    //             currentEmployee.id,
    //             mycontext.token,
    //             mycontext.organizationId,
    //             currentEmployee.email,
    //         );
    //         if (delEmployeeFromOrg.error) {
    //             props.setIsUpdating(false);
    //             setSeverity('error');
    //             setAlert(true);
    //             setAlertMessage('Unable to delete employee. Please try again!');
    //         } else {
    //             props.setIsUpdating(false);
    //             setSeverity('success');
    //             setAlert(true);
    //             setAlertMessage('Employee deleted successfully!');
    //             // refreshPage();
    //         }
    //     }
    // };

    const handleUser = (user: any) => {
        if (user.id === mycontext.EmployeeId) {
            mycontext.addUserProfile(user.id);
            history.push({
                pathname: '/user/profile',
                state: {
                    id: user.id,
                    userLink: false,
                    backTo: 'employees',
                },
            });
        } else {
            mycontext.addUserProfile(user.id);
            history.push({
                pathname: `/employees/${user.id}`,
                state: {
                    id: user.id,
                    data: user,
                    userLink: false,
                    backTo: 'employees',
                },
            });
        }
    };

    // const deleteUser = (user: any) => {
    //     setCurrentemployee(user);
    //     setModalC(true);
    // };

    const makeAdmin = (user: any) => {
        setCurrentemployee(user);
        setModalA(true);
    };

    const removeAdmin = (user: any) => {
        setCurrentemployee(user);
        setModalB(true);
    };

    const handleListOpen = (event: any, arrData: any[]) => {
        setListAnchor(event.currentTarget);
        setCurrentList(arrData);
    };

    const handleListClose = () => {
        setListAnchor(null);
        setCurrentList([]);
    };

    return (
        <div className={classes.MainMemberTable}>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <Popover
                id="teamsList"
                open={Boolean(listAnchor)}
                anchorEl={listAnchor}
                onClose={handleListClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List
                    component="nav"
                    aria-labelledby="team-list-subheader"
                    style={{ minWidth: 100, width: '100%', padding: 0 }}
                >
                    <ListItem key="listTitle">
                        <ListItemText primary="Team List" className={classes.teamMenuTitle} />
                    </ListItem>
                    <div className={classes.teamList}>
                        {currentList.map((l: any, index: number) => (
                            <ListItem
                                key={'team-' + index}
                                style={{ padding: '0px 10px', textTransform: 'capitalize', fontSize: 12 }}
                            >
                                <ListItemText primary={l.teamName} />
                            </ListItem>
                        ))}
                    </div>
                </List>
            </Popover>
            <ActionModal
                id="confirmMakeAdmin"
                title="Make Admin"
                open={modalA}
                toggle={toggleA}
                bodycontent={[`Are you sure you want to make`, currentEmployee.name, `an admin?`]}
                primarybutton="Yes"
                handleSubmit={handleSubmitAdmin}
                secondarybutton="No"
            />
            <ActionModal
                id="confirmRemoveAdmin"
                title="Remove Admin"
                open={modalB}
                toggle={toggleB}
                bodycontent={[`Are you sure you want to remove`, currentEmployee.name + `'s`, `admin privileges?`]}
                primarybutton="Yes"
                handleSubmit={handleRemoveAdmin}
                secondarybutton="No"
            />
            {/* <ActionModal
                id="removeEmployee"
                title="Delete Employee"
                open={modalC}
                toggle={toggleC}
                bodycontent={[`Are you sure you want to delete`, currentEmployee.name + `?`]}
                primarybutton="Yes"
                handleSubmit={handleRemoveEmployee}
                secondarybutton="No"
            /> */}
            <Hidden only={['xs', 'sm']}>
                <TableContainer component="div" className={classes.container}>
                    <Table stickyHeader className={classes.tables} aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell1 align="center" className={classes.idcolumn} id="id1">
                                    #
                                </StyledTableCell1>
                                <StyledTableCell2 align="left" className={classes.tablecolumnname}>
                                    {/* Employees ({props.teamEmployees.length > 0 ? props.teamEmployees.length : 0}) */}
                                    Name
                                </StyledTableCell2>
                                <StyledTableCell3 align="left" className={classes.tableColumnApprover}>
                                    Approver of
                                </StyledTableCell3>
                                <StyledTableCell4 align="left" className={classes.tableColumnEmployee}>
                                    Employee of
                                </StyledTableCell4>
                                {mycontext.isAdmin && (
                                    <StyledTableCell5 align="center" className={classes.tableColumnAction}>
                                        Action
                                    </StyledTableCell5>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ textTransform: 'capitalize' }}>
                            {props.teamEmployees.length === 0 ? (
                                props.loading ? (
                                    [11, 12, 13, 14, 15].map((n: number) => (
                                        <TableRow key={'row-' + n} className={classes.rows}>
                                            <StyledTableCell1
                                                align="center"
                                                style={{ borderBottom: 'none', minWidth: 50 }}
                                            >
                                                <Grid container justifyContent="center">
                                                    <Grid item>
                                                        <Skeleton height={20} width={20} />
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell1>
                                            <StyledTableCell2
                                                align="left"
                                                style={{ borderBottom: 'none', minWidth: 150 }}
                                            >
                                                <Grid container className={classes.changeSize}>
                                                    <Grid item sm={3} style={{ maxWidth: 40 }}>
                                                        <Skeleton variant="circle" height={30} width={30} />
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        item
                                                        sm={9}
                                                        justify="flex-start"
                                                        alignItems="center"
                                                        style={{ paddingLeft: 10 }}
                                                    >
                                                        <Skeleton height={20} width={100} />
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell2>
                                            <StyledTableCell3
                                                align="left"
                                                style={{ borderBottom: 'none', minWidth: 150 }}
                                                className={classes.changeSize}
                                            >
                                                <Skeleton height={20} width={150} />
                                            </StyledTableCell3>
                                            <StyledTableCell4
                                                align="left"
                                                className={classes.changeSize}
                                                style={{ borderBottom: 'none', minWidth: 150 }}
                                            >
                                                <Skeleton height={20} width={150} />
                                            </StyledTableCell4>
                                            {mycontext.isAdmin && (
                                                <StyledTableCell5
                                                    align="center"
                                                    style={{ borderBottom: 'none', minWidth: 150 }}
                                                >
                                                    <Grid container justify="center">
                                                        <Grid item>
                                                            <Skeleton height={20} width={150} />
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell5>
                                            )}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <StyledTableCell0
                                            align="center"
                                            colSpan={mycontext.isAdmin ? 5 : 4}
                                            // style={{ borderBottom: 'none' }}
                                        >
                                            <img
                                                src={upcomingTimeoff}
                                                alt="No Employee Found"
                                                className={classes.notfoundImage}
                                            />
                                            <Typography className={classes.notfoundText}>No record found</Typography>
                                        </StyledTableCell0>
                                    </TableRow>
                                )
                            ) : (
                                props.teamEmployees.map((row: any, i: number) => (
                                    <TableRow
                                        key={'employee-' + i}
                                        className={classes.rows}
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        <StyledTableCell1 align="center" style={{ padding: 0 }}>
                                            {i + 1}
                                        </StyledTableCell1>
                                        <StyledTableCell2 align="left">
                                            <Grid container className={classes.changeSize}>
                                                <Grid item sm={3} style={{ maxWidth: 40 }}>
                                                    <Avatar
                                                        src={row.image}
                                                        onClick={() => handleUser(row)}
                                                        style={{ height: 30, width: 30 }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    sm={9}
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    style={{ paddingLeft: 10 }}
                                                >
                                                    <Typography
                                                        id={'employeeName' + i}
                                                        variant="subtitle2"
                                                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                        className={classes.typographyH6heading}
                                                        onClick={() => handleUser(row)}
                                                    >
                                                        {row.name} <DisplayEmoji employeeId={row.id} />
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid container item justify="flex-start" alignItems="center" sm={2} style={{ maxWidth: 20 }}>
                                                    {row.user.status}
                                                </Grid> */}
                                            </Grid>
                                        </StyledTableCell2>
                                        <StyledTableCell3
                                            id={'emptype' + i}
                                            align="left"
                                            // style={{ borderBottom: 'none', minWidth: 150 }}
                                            className={classes.changeSize}
                                        >
                                            {row.approvers.length === 0 ? (
                                                <Icon icon="bi:dash" width="25" height="25" />
                                            ) : (
                                                <div>
                                                    {row.approvers.slice(0, 2).map((ap: any, index: number) =>
                                                        ap.teamName.length > 9 ? (
                                                            <Tooltip key={'approver-' + ap.teamId} title={ap.teamName}>
                                                                <span
                                                                    key={'approver-' + ap.teamId}
                                                                    className={classes.teamNameStyle}
                                                                >
                                                                    {ap.teamName.slice(0, 5) + '...'}
                                                                    {row.approvers.length > 1
                                                                        ? index === 0
                                                                            ? ', '
                                                                            : ''
                                                                        : ''}
                                                                </span>
                                                            </Tooltip>
                                                        ) : (
                                                            <span
                                                                key={'approver-' + ap.teamId}
                                                                className={classes.teamNameStyle}
                                                            >
                                                                {ap.teamName}
                                                                {row.approvers.length > 1
                                                                    ? index === 0
                                                                        ? ', '
                                                                        : ''
                                                                    : ''}
                                                            </span>
                                                        ),
                                                    )}
                                                    {row.approvers.length > 2 && (
                                                        <Avatar
                                                            className={classes.fab}
                                                            onClick={(e: any) => handleListOpen(e, row.approvers)}
                                                        >
                                                            +{row.approvers.length - 2}
                                                        </Avatar>
                                                    )}
                                                </div>
                                            )}
                                        </StyledTableCell3>
                                        <StyledTableCell4
                                            align="left"
                                            className={classes.changeSize}
                                            // style={{ borderBottom: 'none', minWidth: 150 }}
                                        >
                                            {row.employees.length === 0 ? (
                                                <Icon icon="bi:dash" width="25" height="25" />
                                            ) : (
                                                <span>
                                                    {row.employees.slice(0, 2).map((emp: any, index: number) =>
                                                        emp.teamName.length > 9 ? (
                                                            <Tooltip key={'member-' + emp.teamId} title={emp.teamName}>
                                                                <span
                                                                    key={'member-' + emp.teamId}
                                                                    className={classes.teamNameStyle}
                                                                >
                                                                    {emp.teamName.slice(0, 5) + '...'}
                                                                    {row.employees.length > 1
                                                                        ? index === 0
                                                                            ? ', '
                                                                            : ' '
                                                                        : ''}
                                                                </span>
                                                            </Tooltip>
                                                        ) : (
                                                            <span
                                                                key={'member-' + emp.teamId}
                                                                className={classes.teamNameStyle}
                                                            >
                                                                {emp.teamName}
                                                                {row.employees.length > 1
                                                                    ? index === 0
                                                                        ? ', '
                                                                        : ' '
                                                                    : ''}
                                                            </span>
                                                        ),
                                                    )}
                                                    {row.employees.length > 2 && (
                                                        <Avatar
                                                            className={classes.fab}
                                                            onClick={(e: any) => handleListOpen(e, row.employees)}
                                                        >
                                                            +{row.employees.length - 2}
                                                        </Avatar>
                                                    )}
                                                </span>
                                            )}
                                        </StyledTableCell4>
                                        {mycontext.isAdmin && (
                                            <StyledTableCell5
                                                align="center"
                                                // style={{ borderBottom: 'none', minWidth: 150 }}
                                            >
                                                <IconButton
                                                    id={'actiontab' + i}
                                                    aria-label="Actions Icon"
                                                    aria-controls="menu-appbar"
                                                    aria-haspopup="true"
                                                    onClick={(e: any) => handleMenu(row, e)}
                                                    color="inherit"
                                                    className={classes.moreActions}
                                                >
                                                    <MoreHorizIcon color="primary" />
                                                </IconButton>
                                                <Popover
                                                    id={row.id}
                                                    anchorEl={anchorEls[row.id]}
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    open={Boolean(anchorEls[row.id])}
                                                    onClose={e => handleClose(row.id)}
                                                >
                                                    <MenuItem
                                                        onClick={e => handleClose(row.id)}
                                                        className={classes.selectedMenus}
                                                    >
                                                        <Typography
                                                            id="viewprofile"
                                                            className={classes.link}
                                                            onClick={() => handleUser(row)}
                                                        >
                                                            View Profile
                                                        </Typography>
                                                    </MenuItem>
                                                    {mycontext.EmployeeId === currentEmployee?.id &&
                                                    row.id === currentEmployee.id ? (
                                                        <MenuItem
                                                            disabled
                                                            onClick={e => handleClose(row.id)}
                                                            className={classes.selectedMenus}
                                                        >
                                                            <Typography
                                                                id="removeAdmin"
                                                                className={classes.link}
                                                                onClick={() => removeAdmin(row)}
                                                            >
                                                                Remove Admin
                                                            </Typography>
                                                        </MenuItem>
                                                    ) : currentEmployee?.role === 'admin' ? (
                                                        <MenuItem
                                                            onClick={e => handleClose(row.id)}
                                                            className={classes.selectedMenus}
                                                        >
                                                            <Typography
                                                                id="removeAdmin"
                                                                className={classes.link}
                                                                onClick={() => removeAdmin(row)}
                                                            >
                                                                Remove Admin
                                                            </Typography>
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem
                                                            onClick={e => handleClose(row.id)}
                                                            className={classes.selectedMenus}
                                                        >
                                                            <Typography
                                                                id="makeAdmin"
                                                                className={classes.link}
                                                                onClick={() => makeAdmin(row)}
                                                            >
                                                                Make Admin
                                                            </Typography>
                                                        </MenuItem>
                                                    )}
                                                    {/* {mycontext.EmployeeId === currentEmployee?.id &&
                                                    row.id === currentEmployee.id ? (
                                                        <MenuItem
                                                            disabled
                                                            onClick={e => handleClose(row.id)}
                                                            className={classes.selectedMenus}
                                                        >
                                                            <Typography
                                                                id="deleteEmployee"
                                                                className={classes.link}
                                                                onClick={() => deleteUser(row)}
                                                            >
                                                                Delete Employee
                                                            </Typography>
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem
                                                            onClick={e => handleClose(row.id)}
                                                            className={classes.selectedMenus}
                                                        >
                                                            <Typography
                                                                id="deleteEmployee"
                                                                className={classes.link}
                                                                onClick={() => deleteUser(row)}
                                                            >
                                                                Delete Employee
                                                            </Typography>
                                                        </MenuItem>
                                                    )} */}
                                                </Popover>
                                            </StyledTableCell5>
                                        )}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Hidden>

            <Hidden only={['md', 'lg', 'xl']}>
                {props.teamEmployees.length === 0 ? (
                    props.loading ? (
                        <div>
                            {[21, 22, 23, 24, 25].map((d: number) => (
                                <Paper className={classes.paper} key={'row-' + d}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Skeleton height={20} />
                                        </Grid>

                                        <Grid item xs={5}>
                                            <Skeleton height={20} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Skeleton height={20} />
                                        </Grid>

                                        {mycontext.isAdmin && (
                                            <div>
                                                <Grid item xs={6}>
                                                    <Skeleton height={20} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Skeleton height={20} />
                                                </Grid>
                                            </div>
                                        )}
                                    </Grid>
                                </Paper>
                            ))}
                        </div>
                    ) : (
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid container item justify="center" xs={8}>
                                <img src={upcomingTimeoff} alt="No Employee Found" className={classes.notfoundImage} />
                            </Grid>
                            <Grid container item justify="center" xs={8}>
                                <Typography className={classes.notfoundText}>No record found</Typography>
                            </Grid>
                        </Grid>
                    )
                ) : (
                    <Container style={{ padding: 0, marginBottom: 40 }}>
                        {props.teamEmployees.map((row: any, i: number) => (
                            <Paper
                                className={classes.paper}
                                style={{ marginBottom: 10, borderRadius: '8px' }}
                                key={row.id + i}
                            >
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={2}>
                                        <Avatar alt={row.name} onClick={() => handleUser(row)} src={row.image} />
                                    </Grid>
                                    <Grid item xs={mycontext.isAdmin ? 7 : 10}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.username}
                                            id={'employeeName' + i}
                                            onClick={() => handleUser(row)}
                                        >
                                            {row.name} <DisplayEmoji employeeId={row.id} />
                                        </Typography>
                                        {row.role === 'admin' && (
                                            <Typography
                                                variant="caption"
                                                // style={{ color: '#262F83', fontSize: 13, fontWeight: 600 }}
                                                className={classes.captionStyle}
                                            >
                                                Admin
                                            </Typography>
                                        )}
                                    </Grid>
                                    {mycontext.isAdmin && (
                                        <Grid container item xs={3} justify="flex-end" alignItems="flex-start">
                                            <IconButton
                                                id={'moreBtn' + i}
                                                aria-label="more"
                                                onClick={(e: any) => handleMenu(row, e)}
                                                className={classes.moreActions}
                                            >
                                                <MoreHorizIcon color="primary" />
                                            </IconButton>
                                            <Popover
                                                id={row.id}
                                                anchorEl={anchorEls[row.id]}
                                                anchorOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={Boolean(anchorEls[row.id])}
                                                onClose={e => handleClose(row.id)}
                                            >
                                                <MenuItem
                                                    onClick={e => handleClose(row.id)}
                                                    className={classes.selectedMenus}
                                                >
                                                    <Typography
                                                        id="viewprofile"
                                                        className={classes.link}
                                                        onClick={() => handleUser(row)}
                                                    >
                                                        View Profile
                                                    </Typography>
                                                </MenuItem>
                                                {mycontext.EmployeeId === currentEmployee?.id &&
                                                row.id === currentEmployee.id ? (
                                                    <MenuItem
                                                        disabled
                                                        onClick={e => handleClose(row.id)}
                                                        className={classes.selectedMenus}
                                                    >
                                                        <Typography
                                                            id="removeAdmin"
                                                            className={classes.link}
                                                            onClick={() => removeAdmin(row)}
                                                        >
                                                            Remove Admin
                                                        </Typography>
                                                    </MenuItem>
                                                ) : currentEmployee?.role === 'admin' ? (
                                                    <MenuItem
                                                        onClick={e => handleClose(row.id)}
                                                        className={classes.selectedMenus}
                                                    >
                                                        <Typography
                                                            id="removeAdmin"
                                                            className={classes.link}
                                                            onClick={() => removeAdmin(row)}
                                                        >
                                                            Remove Admin
                                                        </Typography>
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        onClick={e => handleClose(row.id)}
                                                        className={classes.selectedMenus}
                                                    >
                                                        <Typography
                                                            id="makeAdmin"
                                                            className={classes.link}
                                                            onClick={() => makeAdmin(row)}
                                                        >
                                                            Make Admin
                                                        </Typography>
                                                    </MenuItem>
                                                )}
                                                {/* {mycontext.EmployeeId === currentEmployee?.id &&
                                                row.id === currentEmployee.id ? (
                                                    <MenuItem
                                                        disabled
                                                        onClick={e => handleClose(row.id)}
                                                        className={classes.selectedMenus}
                                                    >
                                                        <Typography
                                                            id="deleteEmployee"
                                                            className={classes.link}
                                                            onClick={() => deleteUser(row)}
                                                        >
                                                            Delete Employee
                                                        </Typography>
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        onClick={e => handleClose(row.id)}
                                                        className={classes.selectedMenus}
                                                    >
                                                        <Typography
                                                            id="deleteEmployee"
                                                            className={classes.link}
                                                            onClick={() => deleteUser(row)}
                                                        >
                                                            Delete Employee
                                                        </Typography>
                                                    </MenuItem>
                                                )} */}
                                            </Popover>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" className={classes.titles}>
                                            Approver of :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {row.approvers.length === 0 ? (
                                            <span
                                                className="iconify"
                                                data-icon="bi:dash"
                                                data-width="28"
                                                style={{ fill: theme.palette.text.primary }}
                                            ></span>
                                        ) : (
                                            <div>
                                                {row.approvers.slice(0, 3).map((ap: any, index: number) =>
                                                    ap.teamName.length > 14 ? (
                                                        <span key={ap.teamId + index} className={classes.teamNameStyle}>
                                                            {ap.teamName.slice(0, 8) + '...'}
                                                            {row.approvers.length === 2
                                                                ? index === 0
                                                                    ? ', '
                                                                    : ''
                                                                : ''}
                                                            {row.approvers.length > 2 ? (index < 2 ? ', ' : '') : ''}
                                                        </span>
                                                    ) : (
                                                        <span key={ap.teamId + index} className={classes.teamNameStyle}>
                                                            {ap.teamName}
                                                            {row.approvers.length === 2
                                                                ? index === 0
                                                                    ? ', '
                                                                    : ''
                                                                : ''}
                                                            {row.approvers.length > 2 ? (index < 2 ? ', ' : '') : ''}
                                                        </span>
                                                    ),
                                                )}
                                                {row.approvers.length > 3 && (
                                                    <Avatar
                                                        className={classes.fab}
                                                        onClick={(e: any) => handleListOpen(e, row.approvers)}
                                                    >
                                                        +{row.approvers.length - 3}
                                                    </Avatar>
                                                )}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" className={classes.titles}>
                                            Employee of :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {row.employees.length === 0 ? (
                                            <span
                                                className="iconify"
                                                data-icon="bi:dash"
                                                data-width="28"
                                                style={{ fill: theme.palette.text.primary }}
                                            ></span>
                                        ) : (
                                            <span>
                                                {row.employees.slice(0, 3).map((emp: any, index: number) =>
                                                    emp.teamName.length > 14 ? (
                                                        <span
                                                            key={emp.teamId + index}
                                                            className={classes.teamNameStyle}
                                                        >
                                                            {emp.teamName.slice(0, 8) + '...'}
                                                            {/* {index === row.employees.length - 1 ? '' : ', '} */}
                                                            {row.employees.length === 2
                                                                ? index === 0
                                                                    ? ', '
                                                                    : ''
                                                                : ''}
                                                            {row.employees.length > 2 ? (index < 2 ? ', ' : '') : ''}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            key={emp.teamId + index}
                                                            className={classes.teamNameStyle}
                                                        >
                                                            {emp.teamName}
                                                            {/* {index === row.employees.length - 1 ? '' : ', '} */}
                                                            {row.employees.length === 2
                                                                ? index === 0
                                                                    ? ', '
                                                                    : ''
                                                                : ''}
                                                            {row.employees.length > 2 ? (index < 2 ? ', ' : '') : ''}
                                                        </span>
                                                    ),
                                                )}
                                                {row.employees.length > 3 && (
                                                    <Avatar
                                                        className={classes.fab}
                                                        onClick={(e: any) => handleListOpen(e, row.employees)}
                                                    >
                                                        +{row.employees.length - 3}
                                                    </Avatar>
                                                )}
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </Container>
                )}
            </Hidden>
        </div>
    );
}
