/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import useStyles from '../Login/LoginStyle';
import { NameLoader, ThreeDotsLoader } from '../Spinner/Spinner';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
export default function RegisterGetUserAdmin() {
    const classes = useStyles();
    const [error, setError] = useState(false);
    useEffect(() => {
        async function getDetails() {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const myUrl = new URL(window.location.href.replace(/#/g, '?'));
            const error_desc = myUrl.searchParams.get('error_description') || null;
            const code = params.get('code') || '';
            const provider = localStorage.getItem('provider') || null;
            const signInGoogle = async () => {
                await Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                });
            };
            const signInSlack = async () => {
                Auth.federatedSignIn({ customProvider: 'Slack' });
                // Auth.federatedSignIn();
                // Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
            };
            if (code && provider === 'google') {
                signInGoogle();
            }
            if (code && provider === 'slack') {
                signInSlack();
            }
            if (error_desc && provider === 'google') {
                setError(true);
                setTimeout(() => signInGoogle(), 3000);
            }
            if (error_desc && provider === 'slack') {
                setError(true);
                setTimeout(() => signInSlack(), 3000);
            }
        }
        getDetails();
    }, []);
    return (
        <div className={classes.rootLoader}>
            <h2 style={{ color: 'white' }}>
                {error
                    ? localStorage.getItem('provider') === 'slack'
                        ? 'Successfully linked / registered the account. Please Login to continue.'
                        : 'Successfully registered the slack account. Please Login to continue.'
                    : ''}
            </h2>
            <NameLoader />
            <ThreeDotsLoader />
        </div>
    );
}
