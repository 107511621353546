import React from 'react';
import useStyles from './SubscriptionHardExpiredStyle';
import { Button, Grid, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import end from './new_end.png';

interface Props {
    content?: string;
    showRenew?: boolean;
    fromSettings?: boolean;
}
export default function SubscriptionHardExpired(props: Props): JSX.Element {
    const classes = useStyles();
    const heightStyleDesktop = {
        height: props.fromSettings ? 'calc(100vh - 175px)' : 'calc(100vh - 70px)',
    };
    const heightStyleMobile = {
        height: props.fromSettings ? 'calc(100vh - 130px)' : 'calc(100vh - 70px)',
    };
    return (
        <>
            <Hidden smUp>
                <div className={classes.errorDivMobile} style={heightStyleMobile}>
                    <img src={end} alt="end" className={classes.imgMobile} />
                    <p className={classes.content}>{props.content} </p>
                    {props.showRenew && (
                        <Link
                            to={{
                                pathname: '/settings',
                                state: { value: 4 },
                            }}
                            style={{
                                textDecoration: 'none',
                                color: 'white',
                            }}
                        >
                            <Button variant="contained" color="primary" size="small" className={classes.btn}>
                                Renew Subscription
                            </Button>
                        </Link>
                    )}
                </div>
            </Hidden>
            <Hidden smDown>
                <Grid container className={classes.mainDiv} style={heightStyleDesktop}>
                    <Grid item xs style={{ padding: 30 }}>
                        <h4 className={classes.heading}>{props.content}</h4>
                        {/* <h2 className={classes.content}>{props.msg ? props.msg : 'Page Not Found'}</h2> */}
                        {props.showRenew && (
                            <Link
                                to={{
                                    pathname: '/settings',
                                    state: { value: 4 },
                                }}
                                style={{
                                    textDecoration: 'none',
                                    color: 'white',
                                }}
                            >
                                <Button variant="contained" color="primary" size="small" className={classes.btn}>
                                    Renew Subscription
                                </Button>
                            </Link>
                        )}
                    </Grid>
                    <Grid item xs style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-start' }}>
                        <img src={end} alt="end" className={classes.img} />
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
}
