import { makeStyles } from '@material-ui/core/styles';

const myStyle = makeStyles(theme => ({
    list: {
        width: '100%',
        height: 'auto',
        position: 'absolute',
        top: 120,
        overflowX: 'hidden',
        backgroundColor: theme.palette.primary.main,
    },
    main: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.common.white,
    },
    link: {
        width: '100%',
        height: '40px',
        padding: '5px 20px',
        display: 'grid',
        gridTemplateColumns: '25px max-content',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.light,
        columnGap: '25px',
        textDecoration: 'none',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        '&: hover': {
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
        },
        '&: active': {
            textDecoration: 'none',
        },
    },
    activeLink: {
        width: '100%',
        height: '40px',
        padding: '5px 20px',
        display: 'grid',
        gridTemplateColumns: '25px max-content',
        alignItems: 'center',
        columnGap: '25px',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: 'none',
        '&: hover': {
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
        },
        '&: active': {
            textDecoration: 'none',
        },
    },

    upLinkDiv: {
        width: '100%',
        height: '10px',
        backgroundColor: theme.palette.primary.light,
    },
    upLinkActive: {
        width: '100%',
        height: '10px',
        borderRadius: '0 0 10px 0',
        backgroundColor: theme.palette.primary.light,
    },
    downLinkDiv: {
        width: '100%',
        height: '10px',
        backgroundColor: theme.palette.primary.light,
    },
    downLinkActive: {
        width: '100%',
        height: '10px',
        borderRadius: '0 10px 0 0',
        backgroundColor: theme.palette.primary.light,
    },
    icon: {
        color: theme.palette.text.secondary,
        fontSize: 22,
        display: 'flex',
        justifyContent: 'center',
        // margin: '0px 5px',
    },
    iconActive: {
        color: theme.palette.text.secondary,
        fontSize: 18,
    },
    title: {
        color: theme.palette.common.white,
        fontSize: 15,
        fontWeight: 600,
        textTransform: 'capitalize',
        letterSpacing: 1,
    },
    titleActive: {
        color: theme.palette.text.secondary,
        fontSize: 16,
        fontWeight: 500,
        textTransform: 'capitalize',
        letterSpacing: 1,
    },
    hr: {
        width: '80%',
        backgroundColor: theme.palette.text.secondary,
    },
}));

export default myStyle;
