import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { Grid, useTheme } from '@material-ui/core';
import { PopUp } from '../Modal/Modal';
import { SubscriptionCardStyle } from './SubscriptionCardStyle';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
declare let window: any;

interface SubscriptionCardComponentProps {
    monthly: boolean;
    content: {
        id?: string;
        title?: string;
        price?: string;
        suffix?: string;
        benefit1?: string;
        benefit2?: string;
        benefit3?: string;
        benefit4?: string;
        primary_action?: string;
        secondary_action?: string;
        plan_details: string;
    };
}

export default function SubscriptionCardComponent(props: SubscriptionCardComponentProps): JSX.Element {
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const classes = SubscriptionCardStyle();
    const theme = useTheme();

    const checkoutForm = (planDetails: string) => {
        try {
            const cbInstance = window.Chargebee.getInstance();
            const cart = cbInstance.getCart();
            const planPriceQuantity = 1;
            const product = cbInstance.initializeProduct(planDetails, planPriceQuantity);
            product.addAddon({ id: 'add-on-test-USD-Weekly', quantity: 9 });
            cart.replaceProduct(product);
            cart.proceedToCheckout();
        } catch (e) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Something went wrong, try again!');
        }
    };

    return (
        <>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <Card
                variant="outlined"
                id={props.content.id}
                className={props.content.title === 'Intermediate' ? classes.intermediateCard : classes.mainCard}
            >
                <CardHeader
                    title={props.content.title}
                    className={
                        props.content.title === 'Intermediate' ? classes.intermediateCardHeader : classes.cardHeader
                    }
                ></CardHeader>
                <CardContent>
                    <Box px={1}>
                        <Typography variant="h3" component="h2" gutterBottom={true} className={classes.price}>
                            {'$'}
                            {props.content.price && props.monthly
                                ? Math.round(parseInt(props.content.price) / 12)
                                : props.content.price}
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} className={classes.price}>
                                <Typography color="textSecondary" variant="subtitle1" component="p">
                                    <CheckIcon style={{ color: '#18de43' }} fontSize="medium" />
                                    &nbsp;&nbsp;{props.content.benefit1}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.price}>
                                <Typography color="textSecondary" variant="subtitle1" component="p">
                                    <CheckIcon style={{ color: '#18de43' }} fontSize="medium" />
                                    &nbsp;&nbsp;{props.content.benefit2}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.price}>
                                <Typography color="textSecondary" variant="subtitle1" component="p">
                                    <CheckIcon style={{ color: '#18de43' }} fontSize="medium" />
                                    &nbsp;&nbsp;{props.content.benefit3}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.price}>
                                <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>
                                    <CloseIcon style={{ color: 'red' }} fontSize="medium" />
                                    &nbsp;&nbsp;{props.content.benefit4}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} className={classes.outerGrid}>
                            <Button
                                variant={props.content.title === 'Intermediate' ? 'contained' : 'outlined'}
                                className={
                                    props.content.title === 'Intermediate'
                                        ? classes.intermediateButton
                                        : classes.normalButton
                                }
                                onClick={() => checkoutForm(props.content.plan_details)}
                                style={{ border: `1px solid ${theme.palette.text.secondary}` }}
                            >
                                {props.content.primary_action}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
