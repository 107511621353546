import {
    createSlice,
    //  createAsyncThunk
} from '@reduxjs/toolkit';
// import {
//     fetchEmployeeLeaves,
//     // fetchEmployeeLeavesSubscription,
// } from 'helpers/hasuraapi';

// export const getEmployeeLeaves: any = createAsyncThunk('employeeLeaves/getEmployeeLeaves', async (user: any) => {
//     const data: any = await fetchEmployeeLeaves(user.organizationId, user.token, user.employeeId);
//     const jsonData: any = await data.json();
//     return jsonData.data.timeoffdb_Leaves;
// });

export const employeeLeavesSlice = createSlice({
    name: 'employeeLeaves',
    initialState: { leaves: [], status: 'loading' },
    reducers: {
        addEmployeeLeave: (state: any, action: any) => {
            state.leaves.push(action.payload);
        },
        setEmployeeLeave: (state: any, action: any) => {
            state.leaves = action.payload;
        },
        editEmployeeLeave: (state, action) => {
            state.leaves.map((u: any) => {
                if (u.leaveId === action.payload.leaveId) {
                    u.approverId = action.payload.approverId;
                    u.denialReason = action.payload.denialReason;
                    u.denierId = action.payload.denierId;
                    u.leaveStatus = action.payload.leaveStatus;
                    u.startDate = action.payload.startDate;
                    u.endDate = action.payload.endDate;
                    u.startTime = action.payload.startTime;
                    u.endTime = action.payload.endTime;
                    u.noOfHours = action.payload.noOfHours;
                    u.reason = action.payload.reason;
                    return u;
                }
                return u;
            });
        },
        deleteEmployeeLeave: (state, action) => {
            state.leaves = state.leaves.filter((u: any) => u.leaveId !== action.payload.leaveId);
        },
        removeEmployeeLeave: state => {
            state.leaves = [];
            state.status = 'loading';
        },
        fetchEmployeeLeaves: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                state.leaves = action.payload?.data?.timeoffdb_Leaves;
            }
        },
    },
    // extraReducers: {
    //     [getEmployeeLeaves.pending]: state => {
    //         state.status = 'loading';
    //     },
    //     [getEmployeeLeaves.fulfilled]: (state, action) => {
    //         state.leaves = action.payload;
    //         state.status = 'success';
    //     },
    //     [getEmployeeLeaves.rejected]: state => {
    //         state.status = 'failed';
    //     },
    // },
});

export const {
    addEmployeeLeave,
    editEmployeeLeave,
    deleteEmployeeLeave,
    removeEmployeeLeave,
    setEmployeeLeave,
    fetchEmployeeLeaves,
} = employeeLeavesSlice.actions;
export default employeeLeavesSlice.reducer;
