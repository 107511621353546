import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: any) => ({
    border: {
        width: '100%',
        // minHeight: 'calc(100vh - 190px)',
        display: 'flex',
        // border: 'thin solid red',
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    paper2: {
        position: 'relative',
        width: '100%',
        maxHeight: 'calc(100vh - 190px)',
        minHeight: 'calc(100vh - 190px)',
        // marginTop: 10,
        padding: 20,
    },
    name: {
        color: theme.palette.text.primary,
        padding: '6px 15px',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: theme.typography.fontFamily,
        letterSpacing: '0.02em',
    },
    tooltip: {
        fontSize: 11,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    date: {
        color: theme.palette.text.primary,
        paddingTop: '6px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightSemiBold,
        fontSize: '14px',
        fontStyle: 'normal',
        // color: '#424242',
        letterSpacing: '0.02em',
    },
    text: {
        color: theme.palette.text.primary,
        // position: 'relative',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
        fontSize: '16px',
        fontStyle: 'normal',
        // color: '#424242',
        letterSpacing: '0.02em',
        paddingTop: 10,
        paddingBottom: 30,
        // paddingLeft: '6px',
    },
    field: {
        position: 'absolute',
        bottom: 10,
        width: '100%',
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '&:hover': {
            borderColor: 'none',
        },
    },
    scroll: {
        position: 'relative',
        // top: 15,
        height: '83%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        // border: 'thin solid black',
        // paddingBottom: 70,
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    paper1: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 10,
        padding: '0px 18px 0px 5px',
        height: 50,
        '@media only screen and (max-width: 600px)': {
            marginBottom: 0,
            padding: 0,
        },
    },
    fontsty: {
        textAlign: 'center',
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '15px',
        alignItems: 'center',
        opacity: 0.7,
        paddingTop: 50,
    },
    notfoundImage: {
        maxWidth: '75%',
        height: '180px',
    },
    notfoundText: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        fontSize: 16,
        fontWeight: 600,
    },
    backArrow: {
        cursor: 'pointer',
        color: theme.palette.text.primary,
        margin: '10px 8px 10px 8px',
        '@media only screen and (max-width: 600px)': {
            margin: '10px 8px 10px -4px',
        },
    },
    poll: {
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        paddingTop: 8,
        color: theme.palette.text.primary,
        letterSpacing: 0.9,
    },
    main: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    icon: {
        color: theme.palette.primary.contrastText,
    },
}));
export default useStyles;
