export const months = [
    {
        value: 'May',
        label: 'May',
    },
    {
        value: 'January',
        label: 'Jan',
    },
    {
        value: 'December',
        label: 'Dec',
    },
];

export const days = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '15',
    },
];

export const dateFormat = [
    {
        label: 'YYYY-MM-DD',
        value: 'YYYY-MM-DD',
    },
    {
        label: 'DD-MM-YYYY',
        value: 'DD-MM-YYYY',
    },
    {
        label: 'MMMM Do YYYY',
        value: 'MMMM Do YYYY',
    },
    {
        label: 'MMM Do YY',
        value: 'MMM Do YY',
    },
];

export const reportOptions = [
    {
        value: 'Leave history report',
        label: 'Leave history report',
    },
    {
        value: 'Employee leave statuses',
        label: 'Employee leave statuses',
    },
];
