import { gql } from '@apollo/client';

export function getShoutout(organizationId: string) {
    return gql`
        subscription Shoutouts {
            shoutoutdb_Shoutout(
                where: { organizationId: { _eq: "${organizationId}" }},
                order_by: { createdOn: desc }
            ) {
                createdBy
                Employee {
                    firstName
                    lastName
                    profileImage
                }
                createdOn
                message
                emoji
                tags
                shoutoutId
                shoutout_employee_teams {
                    employeeId
                    shoutoutId
                    isSeen
                    isPersonal
                    teamId
                    Team {
                        teamName
                    }
                    shoutoutEmployeeTeamId
                    Employee {
                        firstName
                        lastName
                        employeeId
                        profileImage
                    }
                }
                shoutout_reactions {
                    employeeId
                    reaction
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
                shoutout_comments(order_by: {commentDateTime: asc}) {
                    employeeId
                    commentId
                    commentText
                    commentDateTime
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }
        }
    `;
}
