import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DateDifference, ToStringFormat, convertTZ, convertTime } from 'helpers/date';
import {
    convertedDateTime,
    getCurrentCountryDate,
    getCurrentCountryDateTime,
    isoToLocal,
    localToISO,
} from 'helpers/timezone';
import moment from 'moment';
import { Avatar, Button, Popover, Typography, useTheme } from '@material-ui/core';
import { deleteComment, editCelebrationComment } from 'helpers/celebrationApi';
import { useContext, useState } from 'react';
import { useStyles } from './style';
import { emojis } from '../Shoutout/Main';
import AuthContext from 'context/AuthContext';
import React from 'react';
import NotFound from '../Shoutout/undraw_empty.svg';
import NotFoundDark from '../Shoutout/undraw_empty_dark.svg';

export const Comment = (props: { comment: any }) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [action, setAction] = useState<string>('');
    const [input, setInput] = useState<string>('');
    const [current, setCurrent] = useState<any>({});
    const theme = useTheme();

    // console.log(action);
    // console.log(props.comment);

    const handlePicker = (e: any) => {
        setOpen(!open);
        setAnchorEl(e.currentTarget);
    };

    const editComment = async (e: any, comment: any) => {
        try {
            e.preventDefault();
            const obj = {
                commentId: comment.commentId,
                commentText: input,
                commentDateTime: localToISO(
                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                    mycontext.country,
                ),
                token: mycontext.token,
            };
            const resp = await editCelebrationComment(obj);
            if (resp.error) {
                throw new Error('not update comment!');
            }
            setAction('');
        } catch (err) {
            return err;
        }
        // console.log(resp);
    };

    const handleDelete = async (id: any) => {
        // console.log(id);
        try {
            const data: any = await deleteComment(id, mycontext.token);
            if (data.error) {
                throw new Error('error');
            }
        } catch (err) {
            return err;
        }
    };

    const handleSubmit = (value: string, data: any) => {
        if (value === 'Edit') {
            setAction(value);
            setCurrent(data);
            setInput(data?.commentText);
        } else {
            handleDelete(data?.commentId);
        }
    };

    return (
        <>
            <div>
                {action === 'Edit' ? (
                    <div style={{ display: 'flex', height: 'auto', padding: '10px 0', margin: '5px 0' }}>
                        <Avatar
                            src={props.comment?.Employee?.profileImage}
                            alt=""
                            style={{ width: 38, height: 38, marginRight: 10 }}
                        />
                        <form
                            className={classes.form}
                            onSubmit={(e: any) => {
                                editComment(e, current);
                            }}
                        >
                            <input
                                type="text"
                                value={input}
                                onChange={(e: any) => setInput(e.target.value)}
                                style={{ border: 'none', outline: 'none', height: 26, padding: 0 }}
                                className={classes.commentBox}
                                maxLength={50}
                                autoFocus
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    marginTop: 5,
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        setCurrent({});
                                        setAction('');
                                    }}
                                    variant="outlined"
                                    style={{
                                        width: 26,
                                        height: 22,
                                        fontSize: 11,
                                        margin: 0,
                                        marginRight: 5,
                                    }}
                                    className={classes.filterBtn}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ width: 26, height: 22, fontSize: 11, margin: 0 }}
                                    className={classes.savebtn}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            height: 50,
                            paddingTop: 10,
                            margin: '5px 0',
                            // border: '1px solid'
                        }}
                    >
                        <Avatar
                            src={props.comment?.Employee?.profileImage}
                            alt=""
                            style={{ width: 38, height: 38, marginRight: 10 }}
                        />
                        <div style={{ flex: 1 }}>
                            <Typography data-testid="name" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                {props.comment?.Employee?.firstName}
                            </Typography>
                            <Typography data-testid="text" style={{ fontSize: 14 }}>
                                {props.comment?.commentText}
                            </Typography>
                        </div>
                        <Typography className={classes.date}>
                            {DateDifference(
                                convertedDateTime(props.comment.commentDateTime, mycontext.country)?.date,
                                getCurrentCountryDate(mycontext.country?.zoneName),
                            ) > 2 ? (
                                <span style={{ fontSize: 12 }}>
                                    {ToStringFormat(
                                        convertedDateTime(props.comment.commentDateTime, mycontext.country)?.date,
                                        mycontext.dateFormat,
                                    )}
                                    <span style={{ marginLeft: 3 }}>
                                        {convertTime(
                                            convertedDateTime(props.comment.commentDateTime, mycontext.country)?.time,
                                        )}
                                    </span>
                                </span>
                            ) : (
                                <span style={{ fontSize: 12 }}>
                                    {moment(isoToLocal(props.comment.commentDateTime, mycontext.country)).from(
                                        convertTZ(new Date().toISOString(), mycontext.country?.zoneName),
                                    )}
                                </span>
                            )}
                        </Typography>
                        {props.comment.employeeId === mycontext.EmployeeId && (
                            <MoreVertIcon
                                style={{
                                    width: 22,
                                    height: 22,
                                    marginLeft: 2,
                                    cursor: 'pointer',
                                    color: theme.palette.text.primary,
                                }}
                                onClick={e => handlePicker(e)}
                            />
                        )}
                    </div>
                )}
            </div>
            <Popover
                id="picker-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setOpen(false);
                    setAnchorEl(null);
                }}
                data-testid="filterModal"
            >
                <ul className={classes.filterList2}>
                    {['Edit', 'Delete'].map((e: string) => (
                        <li
                            key={e}
                            onClick={() => {
                                setOpen(false);
                                setAnchorEl(null);
                                handleSubmit(e, props.comment);
                            }}
                            className={classes.filterName}
                        >
                            {e}
                        </li>
                    ))}
                </ul>
            </Popover>
        </>
    );
};

export const Reactions = (props: { reactions: any }) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const [currentEmoji, setCurrentEmoji] = useState<string>('All');
    return (
        <div style={{ height: '380px' }}>
            <ul className={classes.tabs}>
                <li
                    onClick={() => setCurrentEmoji('All')}
                    className={currentEmoji === 'All' ? classes.activeTab : classes.reactionTab}
                >
                    All
                    <span className={classes.emojiCount}>({props.reactions?.length})</span>
                </li>
                {emojis.map((e: any, i: number) => (
                    <li
                        key={`reaction${i}`}
                        onClick={() => setCurrentEmoji(e.emoji)}
                        className={currentEmoji === e?.emoji ? classes.activeTab : classes.reactionTab}
                    >
                        {e?.emoji}{' '}
                        <span className={classes.emojiCount}>
                            ({props.reactions?.filter((c: any) => c?.reaction?.emoji === e?.emoji)?.length})
                        </span>
                    </li>
                ))}
            </ul>
            <div className={classes.reactionList} style={{ overflowY: 'auto', height: 330 }} data-testid="reactionsDiv">
                <div className={classes.scroll}>
                    {props.reactions?.length === 0 ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {mycontext.themeToggle ? (
                                <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                            ) : (
                                <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                            )}
                            <Typography className={classes.heading}>No reactions found!</Typography>
                        </div>
                    ) : currentEmoji === 'All' ? (
                        props.reactions?.map((c: any, i: number) => (
                            <div
                                key={`reaction${i}`}
                                style={{
                                    display: 'flex',
                                    height: 50,
                                    padding: 5,
                                    margin: '5px 0',
                                }}
                            >
                                <Avatar
                                    src={c?.Employee?.profileImage}
                                    alt=""
                                    style={{ width: 38, height: 38, marginRight: 10 }}
                                />
                                <div style={{ flex: 1 }}>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {c?.Employee?.firstName}
                                    </Typography>
                                    <Typography style={{ fontSize: 14 }}>{c?.reaction?.emoji}</Typography>
                                </div>
                            </div>
                        ))
                    ) : props.reactions?.filter((e: any) => e?.reaction?.emoji === currentEmoji)?.length === 0 ? (
                        <div
                            id="noResultFound"
                            style={{
                                borderBottom: 'none',
                                height: '90%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {mycontext.themeToggle ? (
                                <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                            ) : (
                                <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                            )}
                            <div>No Reactions found</div>
                        </div>
                    ) : (
                        props.reactions
                            ?.filter((e: any) => e?.reaction?.emoji === currentEmoji)
                            ?.map((c: any, i: number) => (
                                <div
                                    key={`reaction${i}`}
                                    style={{
                                        display: 'flex',
                                        height: 50,
                                        padding: 5,
                                        margin: '5px 0',
                                    }}
                                >
                                    <Avatar
                                        src={c?.Employee?.profileImage}
                                        alt=""
                                        style={{ width: 38, height: 38, marginRight: 10 }}
                                    />
                                    <div style={{ flex: 1 }}>
                                        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                                            {c?.Employee?.firstName}
                                        </Typography>
                                        <Typography style={{ fontSize: 14 }}>{c?.reaction?.emoji}</Typography>
                                    </div>
                                </div>
                            ))
                    )}
                </div>
            </div>
        </div>
    );
};
