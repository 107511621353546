import React from 'react';
import useStyles from './style';
import Floater from '../../Assets/dashboard/floater.png';
import { Hidden } from '@material-ui/core';

interface Features {
    heading?: string;
    spam?: string;
    isEmployee?: boolean;
    features?: any;
}
export default function Features(props: Features) {
    const classes = useStyles();
    return (
        <div className={classes.features}>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <img src={Floater} alt="" className={props.isEmployee ? classes.floaterEmp : classes.floater} />
            </Hidden>
            <div className={classes.featuresText}>
                <p className={classes.featuresHeading}>
                    {props.heading} <br></br>
                    <span>{props.spam}</span>
                </p>
            </div>
            <div className={classes.featuresList}>
                {props.features.map((feature: any, i: number) => (
                    <div className={classes.featureContainer} key={i}>
                        <img src={feature.img} alt="" className={classes.featureimage} />
                        <p className={classes.featureTitle}>{feature.title}</p>
                        <p className={classes.featureDescription}>{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
