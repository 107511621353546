import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchOrganizationLeaves2 } from 'helpers/hasuraapi';

export const getOrgLeave: any = createAsyncThunk('orgLeave/getOrgLeave', async (user: any) => {
    const data: any = await fetchOrganizationLeaves2(user.organizationId, user.token, 'approved');
    const jsonData: any = await data.json();
    return jsonData.data.timeoffdb_Leaves;
});

export const orgLeaveSlice = createSlice({
    name: 'orgLeave',
    initialState: { orgleaves: [], status: 'loading' },
    reducers: {
        deleteOrgLeave: (state, action) => {
            state.orgleaves = state.orgleaves.filter((u: any) => u.leaveId !== action.payload.leaveId);
        },
    },
    extraReducers: {
        [getOrgLeave.pending]: state => {
            state.status = 'loading';
        },
        [getOrgLeave.fulfilled]: (state, action) => {
            state.orgleaves = action.payload;
            state.status = 'success';
        },
        [getOrgLeave.rejected]: state => {
            state.status = 'failed';
        },
    },
});

export const { deleteOrgLeave } = orgLeaveSlice.actions;
export default orgLeaveSlice.reducer;
