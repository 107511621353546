import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        border: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
            height: 'calc(100vh - 100px)',
            // border: "1px solid",
            '@media only screen and (min-width:900px)': {
                padding: '20px 0px 20px 30px',
                position: 'relative',
                height: 'calc(100vh - 140px)',
                background: theme.palette.background.paper,
            },
            '@media only screen and (max-width:900px)': {
                marginBottom: '48px',
            },
        },
        textFieldScroll: {
            overflowY: 'scroll',
            height: '43px',
            outline: 'blue',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#D9DBE5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        deleteModalContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        notfoundImage: {
            maxWidth: '75%',
            height: '180px',
            justifyContent: 'center',
        },
        notfoundText: {
            fontFamily: theme.typography.fontFamily,
            display: 'flex',
            justifyContent: 'center',
            // marginLeft: "43%",
            color: theme.palette.text.primary,
            fontSize: 16,
            fontWeight: 600,
        },
        btnOutlined: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 50px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
        },
        deltitle: {
            textAlign: 'center',
            fontSize: 20,
            fontStyle: 'normal',
            color: theme.palette.info.light,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
        },
        delbod: {
            fontSize: 18,
            textAlign: 'center',
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
            fontStyle: 'normal',
            fontFamily: theme.typography.fontFamily,
        },
        paper1: {
            width: '60%',
            backgroundColor: theme.palette.background.paper,
            padding: '20px',
            // left: 100,
            // top: 25,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
            '@media screen and (max-width: 800px)': {
                width: '100%',
                padding: 0,
            },
            '@media screen and (min-width: 1100px)': {
                width: '45%',
            },
        },

        modal1: {
            width: '100%',
            // height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 100,
            '@media screen and (max-width: 800px)': {
                padding: '60px 10px',
            },
        },
        name: {
            fontSize: '15px',
            fontStyle: 'normal',
            // fontWeight: 450,
            '@media only screen and (max-width:900px)': {
                paddingLeft: '12px',
                // paddingTop: '9px',
            },
            padding: '3px 10px 0px 10px',
            letterSpacing: 0.9,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
        },

        text1: {
            // marginBottom: '19px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            letterSpacing: '0.02em',
            fontStyle: 'normal',
            fontSize: '15px',
            paddingTop: '10px',
        },

        nameText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            letterSpacing: '0.02em',
            fontStyle: 'normal',
            fontSize: '15px',
        },

        text3: {
            fontSize: '15px',
            marginTop: '8px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            letterSpacing: '0.02em',
            fontStyle: 'normal',
        },
        text4: {
            fontSize: '15px',
            marginBottom: '10px',
            // marginTop: '8px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: 500,
            letterSpacing: '0.02em',
            fontStyle: 'normal',
        },

        clear: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700,
            fontSize: '14px',
            // lineHeight: '24px',
            color: '#3C48B5',
            width: '100%',
            textDecoration: 'underline',
            // paddingTop: '5px',
            marginLeft: 4,
            marginTop: 7,
            '@media only screen and (max-width:1200px)': {
                display: 'flex',
                alignSelf: 'flex-end',
                width: 'auto',
                right: '30%',
                marginBottom: 58,
                // marginLeft: 4,
                paddingLeft: '10px',
            },
        },

        scrollBar: {
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '84%',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#D9DBE5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
            '@media only screen and (max-width: 1300px)': {
                height: '76%',
                // border: 'thin solid red'
            },
            '@media only screen and (max-width: 900px)': {
                height: '94%',
            },
        },
        deleteIcon: {
            color: theme.palette.primary.contrastText,
            // marginRight: "10px",
            cursor: 'pointer',
            '&:hover': {
                color: '#3C48B5',
            },
        },
        leftFull: {
            flex: 1,
            dispaly: 'flex',
            flexDirection: 'column',
        },
        leftHalf: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        backArrow: {
            cursor: 'pointer',
            color: theme.palette.text.primary,
            margin: '10px 8px 10px -4px',
        },
        text: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700,
            fontSize: '14px',
            // lineHeight: '24px',
            color: '#3C48B5',
            // textDecoration: 'underline',
            paddingTop: '5px',
            '@media only screen and (min-width:1300px)': {
                paddingLeft: '10px',
            },
        },
        auto: {
            backgroundColor: theme.palette.background.paper,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        btn: {
            position: 'relative',
            height: 35,
            width: 170,
            textTransform: 'none',
            // padding: '0 50px',
            fontSize: 15,
            marginRight: 20,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            border: `1px solid #AAAAAA`,
            color: '#AAAAAA',
            backgroundColor: theme.palette.background.paper,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                // color: theme.palette.text.secondary,/
                backgroundColor: theme.palette.background.paper,
                border: `1px solid #AAAAAA`,
                outline: 'none',
            },
        },

        btn3: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 50px',
            fontSize: 16,
            marginLeft: '10px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },

        btn2: {
            position: 'relative',
            height: 35,
            width: 150,
            textTransform: 'none',
            // padding: '0 50px',
            fontSize: 15,
            marginRight: 20,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
            '@media only screen and (max-width:1300px) and (min-width: 1200px)': {
                height: 35,
                width: 10,
                // padding: '0 50px',
                fontSize: 0,
            },
            '@media only screen and (max-width:1200px)': {
                height: 35,
                width: '100%',
                // padding: '0 50px',
                fontSize: 15,
            },
        },
        scrollbar: {
            maxHeight: '135px',
            overflowY: 'auto',
            // margin: '0px 5px',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#D9DBE5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        filterBtn: {
            position: 'relative',
            marginLeft: 15,
            width: 167,
            '&:focus': {
                outline: 'none',
            },
            zIndex: 9,
            '@media only screen and (max-width:1200px)': {
                width: '100%',
                marginLeft: 0,
            },
        },
        scroll0: {
            maxHeight: 'calc(100vh - 150px)',
            '@media only screen and (max-width:900px)': {
                maxHeight: 'calc(100vh - 100px)',
            },
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#D9DBE5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
        },

        text2: {
            fontFamily: 'Nunito Sans',
            color: theme.palette.text.primary,
            // paddingLeft: "8px",
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '21.82px',
            marginTop: '5px',
            // paddingLeft: 'px',
        },
        button1: {
            height: 35,
            position: 'fixed',
            width: '50%',
            bottom: '0px',
            textTransform: 'capitalize',
            borderRadius: '0px',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        button2: {
            left: '1px',
            height: 35,
            position: 'fixed',
            width: '50%',
            fontSize: '16px',
            fontWeight: 700,
            bottom: '0px',
            borderRadius: '0px',
            textTransform: 'capitalize',
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '&:active,&:hover': {
                outline: 'none',
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        text6: {
            position: 'relative',
            padding: '4px 0px 16px 0px',
            letterSpacing: '0.02em',
            maxWidth: '100%',
            wordBreak: 'break-word',
            fontSize: '15px',
            fontFamily: theme.typography.fontFamily,
        },
        time: {
            position: 'relative',
            paddingLeft: '5px',
            paddingRight: '5px',
            fontWeight: theme.typography.fontWeightBold,
            top: '5px',
            letterSpacing: '0.02em',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
        },
        shift2: {
            display: 'flex',
            justifyContent: 'center',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            color: theme.palette.text.primary,
            letterSpacing: '0.02em',
        },
        shift3: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '45px',
        },
        notFoundImage: {
            maxWidth: '35px',
            height: '35px',
            marginLeft: '8%',
        },
        notFoundText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 16,
            fontWeight: 600,
        },
        boxes: {
            display: 'flex',
            justifyContent: 'space-around',
            paddingBottom: '30px',
        },
        boxes2: {
            width: 207,
            // color: 'transparent !important',
        },
        scroll: {
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 3,
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        standup: {
            position: 'relative',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 17,
            paddingTop: 8,
            color: theme.palette.text.primary,
            letterSpacing: 0.9,
        },
        inputScroll2: {
            overflowX: 'auto',
            border: '1px solid',
            height: 35,
            borderRadius: 5,
            '&::-webkit-scrollbar': {
                width: 0,
                height: 0,
            },
        },
        fontsty: {
            textAlign: 'center',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            alignItems: 'center',
            opacity: 0.7,
            paddingTop: 50,
        },
        icon: {
            position: 'absolute',
            top: 18,
            right: 18,
            cursor: 'pointer',
            color: theme.palette.text.primary,
        },
        apply: {
            '@media only screen and (max-width:899px)': {
                // position: 'fixed',
                display: 'flex',
                width: '100%',
                height: 42,
                right: 0,
                // top: 'calc(100vh - 43px)',
                borderRadius: 0,
                textAlign: 'center',
                textTransform: 'capitalize',
                fontSize: 14,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                '&:focus': {
                    outline: 'none',
                },
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
        grid: {
            display: 'flex',
            width: '100%',
            // height: 35,
            '@media only screen and (max-width: 1200px)': {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                width: '65%',
                gap: 10,
            },
        },
        grid2: {
            display: 'flex',
            width: '100%',
            // height: 35,
            '@media only screen and (max-width: 1200px)': {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                width: '43%',
                gap: 10,
            },
        },
        filterIcon: {
            '@media only screen and (max-width:1300px) and (min-width: 1200px)': {
                marginLeft: 10,
            },
        },
        test: {
            width: 'auto',
            height: 37,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
        },
        filterList: {
            maxHeight: 160,
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        filterName2: {
            padding: '2px 10px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
        },
        filterName: {
            padding: '2px 10px',
            width: '200px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
            },
        },
        filterList2: {
            maxHeight: 160,
            width: 168,
        },
    }),
);

export default useStyles;
