import { makeStyles, Theme } from '@material-ui/core/styles';

export const tabStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 850,
        margin: 'auto',
        marginTop: 100,
        backgroundColor: theme.palette.background.paper,
        '@media (max-width:360px)': {
            marginLeft: 20,
        },
        '@media (max-width:375px)': {
            marginLeft: 12,
        },
    },
    tabs: {
        minHeight: '30%',
        height: '100%',
        width: 'auto',
        lineHeight: 25,
        // paddingLeft: 10,
        borderRadius: 0,
        marginBottom: 20,
    },
    tab: {
        letterSpacing: '0.05rem',
        fontWeight: theme.typography.fontWeightBold,
        // borderRight: '1px solid #AAAAAA',
        textTransform: 'capitalize',
        minHeight: '10%',
        marginBottom: 8,
        lineHeight: '30%',
        fontSize: 14,
        outline: 'none',
        fontFamily: theme.typography.fontFamily,
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            outline: 'none',
            cursor: 'pointer',
        },
        '@media screen and (max-width: 650px)': {
            fontSize: 12,
            textAlign: 'left',
            padding: '5px 10px',
        },
    },
    tabButton: {
        minWidth: 120,
        height: 20,
        '&:focus': {
            color: theme.palette.secondary.contrastText,
        },
    },
    component: {
        height: '100%',
    },
}));
