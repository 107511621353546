import { gql } from '@apollo/client';

export function getUserStatus(organizationId: string, employeeId: string) {
    return gql`
        subscription UserStatus {
            usermanagementdb_organization_employee(where: {Employee: {employeeId: {_eq: "${employeeId}"}}, Organization: {organizationId: {_eq: "${organizationId}"}}}) {
                employeeId
                role
                organizationId
            }
        }
    `;
}
