import { gql } from '@apollo/client';
import { newurl } from './hasuraapi';

export const otherCelebration = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_celebrationdb_Celebration(objects: {celebrationDate: "${obj.celebrationDate}", attachmentFile: "${obj.logo}", description: "${obj.description}", employeeId: "${obj.employeeId}",tenantId: "${obj.tenantId}", departmentId: "${obj.departmentId}", title: "${obj.title}", category: "other"}) {
                    returning {
                        attachmentFile
                        category
                        celebrationDate
                        celebrationId
                        description
                        employeeId
                        tenantId
                        departmentId
                        title
                    }
                }
            }`,
        };
        // console.log('query', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log(jsonData);
        if (jsonData.errors) {
            throw new Error('Celebration was not added.');
        }
        return jsonData.data.insert_celebrationdb_Celebration.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export function SubscriptionUpcomingCelebration(tenantId: string, date: string) {
    return gql`subscription MySubscription {
                celebrationdb_Celebration(where: {tenantId: {_eq: "${tenantId}"}, celebrationDate: {_gt: "${date}"}}) {
                    category
                    celebrationDate
                    celebrationId
                    description
                    employeeId
                    tenantId
                    departmentId
                    attachmentFile
                    title
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }
    `;
}

// export function fetchUpcomingCelebration(tenantId: string, date: string) {
//     return gql`query MyQuery  {
//                 celebrationdb_Celebration(where: {tenantId: {_eq: "${tenantId}"}, celebrationDate: {_gt: "${date}"}}) {
//                     category
//                     celebrationDate
//                     celebrationId
//                     description
//                     attachmentFile
//                     employeeId
//                     tenantId
//                     departmentId
//                     title
//                     Employee {
//                         firstName
//                         lastName
//                         profileImage
//                     }
//                 }
//             }
//         `;
// }

export const fetchUpcomingCelebration = async (tenantId: any, date: string, ldate: any, token: any) => {
    // console.log(ldate);
    try {
        const query = {
            query: `query MyQuery  {
                celebrationdb_Celebration(where: {tenantId: {_eq: "${tenantId}"}, celebrationDate: {_gt: "${date}", _lt: "${ldate}"}}, order_by: {celebrationDate: asc}) {
                    category
                    celebrationDate
                    celebrationId
                    description
                    attachmentFile
                    employeeId
                    tenantId
                    departmentId
                    title
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log(jsonData, 'test');
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        return jsonData.data.celebrationdb_Celebration;
    } catch (err) {
        return { error: err };
    }
};

export const deleteOtherCelebration = async (celebrationId: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation2 {
                    delete_celebrationdb_Celebration(where: {celebrationId: {_eq: "${celebrationId}"}}) {
                        affected_rows
                        returning {
                            celebrationId
                        }
                    }
                }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log(jsonData, 'test')
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        return jsonData.data.delete_celebrationdb_Celebration;
    } catch (err) {
        return { error: err };
    }
};

export const addCelebrationReaction = async (obj: any) => {
    // console.log("dkd", obj);
    try {
        const query = {
            query: `mutation MyMutation {
                    insert_celebrationdb_celebration_reaction(objects: {celebrationId: "${obj.celebrationId}", employeeId: "${obj.employeeId}", reaction: { emoji: "${obj.emoji.emoji}", activeSkinTone: "${obj.emoji.activeSkinTone}", unified: "${obj.emoji.unified}", originalUnified: "${obj.emoji.originalUnified}" }}) {
                        returning {
                            celebrationId
                            employeeId
                            reaction
                        }
                    }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('my data', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to add reactions!');
        }
        return jsonData.data.insert_celebrationdb_celebration_reaction.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateCelebrationReaction = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                    update_celebrationdb_celebration_reaction(where: {celebrationId: {_eq: "${obj.celebrationId}"}, employeeId: {_eq: "${obj.employeeId}"}}, _set: {reaction: { emoji: "${obj.emoji.emoji}", activeSkinTone: "${obj.emoji.activeSkinTone}", unified: "${obj.emoji.unified}", originalUnified: "${obj.emoji.originalUnified}" }}) {
                        affected_rows
                        returning {
                            celebrationId
                            employeeId
                            reaction
                        }
                    }
                }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_celebrationdb_celebration_reaction === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_celebrationdb_celebration_reaction.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const deleteCelebrationReaction = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                    delete_celebrationdb_celebration_reaction(where: {celebrationId: {_eq: "${obj.celebrationId}"}, employeeId: {_eq: "${obj.employeeId}"}}) {
                        affected_rows
                    }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        return jsonData.data.delete_celebrationdb_celebration_reaction;
    } catch (err) {
        return { error: err };
    }
};

export const addCelebrationComment = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                    insert_celebrationdb_CelebrationComment(objects: {celebrationId: "${obj.celebrationId}", commentDateTime: "${obj.commentDateTime}", commentText: "${obj.commentText}", employeeId: "${obj.employeeId}"}) {
                            returning {
                                celebrationId
                                commentDateTime
                                commentId
                                commentText
                                employeeId
                            }
                        }
                    }
                `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log(jsonData, 'comments');
        if (jsonData.errors) {
            throw new Error('Failed to create comment!');
        }
        return jsonData.data.insert_celebrationdb_CelebrationComment;
    } catch (err) {
        return { error: err };
    }
};

export function getComment(celebrationId: string) {
    return gql`subscription MyQuery {
        celebrationdb_CelebrationComment(where: {celebrationId: {_eq: "${celebrationId}"}}) {
                celebrationId
                commentDateTime
                commentId
                commentText
                employeeId
                Employee {
                    firstName
                    lastName
                    profileImage
                }
            
        }
        
    }
    `;
}

export function getReaction(celebrationId: string) {
    return gql`subscription MyQuery {
        celebrationdb_celebration_reaction(where: {celebrationId: {_eq: "${celebrationId}"}}) {
                    celebrationId
                    employeeId
                    reaction
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
        }
    `;
}

export function SubscriptionPast(tenantId: string, startDate: any, date: any) {
    // console.log(startDate);
    return gql`subscription MySubscription {
            celebrationdb_Celebration(where: {tenantId: {_eq: "${tenantId}"}, celebrationDate: {_lt: "${date}", _gt: "${startDate}"}}, order_by: {celebrationDate: desc}) {
                attachmentFile
                category
                celebrationDate
                celebrationId
                description
                employeeId
                Employee {
                    firstName
                    lastName
                    profileImage
                }
                tenantId
                title
            }
        }

    `;
}

export function Past(tenantId: string, date: any) {
    return gql`
        query MyQuery {
                    celebrationdb_Celebration(where: {tenantId: {_eq: "${tenantId}"}, celebrationDate: {_lt: "${date}"}}) {
                        attachmentFile
                        category
                        celebrationDate
                        celebrationId
                        description
                        employeeId
                        Employee {
                            firstName
                            lastName
                            profileImage
                        }
                        CelebrationComments {
                            commentId
                        }
                        tenantId
                        title
                        celebration_reactions {
                            celebrationId
                        }
                    }
                }
            `;
}

export function SubscriptionToday(tenantId: string, date: string) {
    return gql`subscription MySubscription {
            celebrationdb_Celebration(where: {tenantId: {_eq: "${tenantId}"}, celebrationDate: {_eq: "${date}"}}) {
                attachmentFile
                category
                celebrationDate
                celebrationId
                description
                employeeId
                Employee {
                    firstName
                    lastName
                    profileImage
                }
                tenantId
                title
            }
        }
    `;
}

export function Today(tenantId: string, date: string) {
    return gql`query MyQuery {
                celebrationdb_Celebration(where: {tenantId: {_eq: "${tenantId}"}, celebrationDate: {_eq: "${date}"}}) {
                    attachmentFile
                    category
                    celebrationDate
                    celebrationId
                    description
                    employeeId
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                    CelebrationComments {
                        commentId
                    }
                    tenantId
                    title
                    celebration_reactions {
                        celebrationId
                    }
                }
            }
        `;
}

export const deleteComment = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                            delete_celebrationdb_CelebrationComment(where: {commentId: {_eq: "${id}"}}) {
                                affected_rows 
                                returning {
                                    commentId
                                }
                            }
                         }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log(jsonData);
        if (jsonData.errors) {
            throw new Error('Comment not deleted!');
        }
        return jsonData.data.delete_celebrationdb_CelebrationComment;
    } catch (err) {
        return { error: err };
    }
};

export const editCelebrationComment = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                    update_celebrationdb_CelebrationComment(where: {commentId: {_eq: "${obj.commentId}"}}, _set: {commentDateTime: "${obj.commentDateTime}", commentText: "${obj.commentText}"}) {
                        affected_rows
                        returning {
                            celebrationId
                            commentDateTime
                            commentId
                            commentText
                            employeeId
                        }
                    }
                }`,
        };
        // // console.log('query', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Comment not deleted!');
        }
        return jsonData.data.update_celebrationdb_CelebrationComment.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const getEmployeeData = async (tenantId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_tenant_employee(where: {tenantId: {_eq: "${tenantId}"}, _or: [{workAnniversary: {_is_null: true}}, {Employee: {dateOfBirth: {_is_null: true}}}]}) {
                        workAnniversary
                        employeeId
                        Employee {
                            firstName
                            email
                            dateOfBirth
                            lastName
                            profileImage
                        }
                    }
                }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log(jsonData);
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.usermanagementdb_tenant_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.usermanagementdb_tenant_employee;
        // return data;
    } catch (err) {
        return { error: err };
    }
};

export function getEmployees(tenantId: string) {
    return gql`subscription MyQuery {
                usermanagementdb_tenant_employee(where: {tenantId: {_eq: "${tenantId}"}, _or: [{workAnniversary: {_is_null: true}}, {Employee: {dateOfBirth: {_is_null: true}}}]}) {
                        workAnniversary
                        employeeId
                        Employee {
                            firstName
                            email
                            dateOfBirth
                            lastName
                            profileImage
                        }
                    }
                }
    `;
}

export const dateofBirthfunc = async (obj: any) => {
    // console.log(obj);
    let def: any;
    if (obj.dateOfBirth === undefined) {
        def = null;
    } else {
        def = JSON.stringify(obj.dateOfBirth);
    }
    try {
        const query = {
            query: `mutation MyMutation {
                update_usermanagementdb_Employee(where: {employeeId: {_eq: "${obj.employeeId}"}}, _set: {dateOfBirth: ${def}}) {
                    affected_rows
                    returning {
                        dateOfBirth
                        employeeId
                        firstName
                    }
                }
            }
        `,
        };
        // console.log(query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
                'x-hasura-role': 'teamplusadmin',
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('mutationdata', jsonData);
        if (jsonData.errors) {
            throw new Error('not found');
        }
        return jsonData.data.update_usermanagementdb_Employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const workAnniversary = async (obj: any) => {
    let abc: any;
    if (obj.workAnniversary === undefined) {
        abc = null;
    } else {
        abc = JSON.stringify(obj.workAnniversary);
    }
    try {
        const query = {
            query: `mutation MyMutation {
                update_usermanagementdb_tenant_employee(where: {tenantId: {_eq: "${obj.tenantId}"}, employeeId: {_eq: "${obj.employeeId}"}}, _set: {workAnniversary: ${abc}}) {
                    affected_rows
                    returning {
                      workAnniversary
                      employeeId
                      Employee {
                        firstName
                      }
                    }
                }
            }
        `,
        };
        // console.log(query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('mutationdata', jsonData);
        if (jsonData.errors) {
            throw new Error('not found');
        }
        return jsonData.data.update_usermanagementdb_tenant_employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const deleteCelebration = async (employeeId: string, token: string, category: string) => {
    try {
        const query = {
            query: `mutation MyMutation {
                delete_celebrationdb_CelebrationComment(where: {Celebration: {employeeId: {_eq: "${employeeId}"}, category: {_eq: "${category}"}}}) {
                    affected_rows
                }
                delete_celebrationdb_celebration_reaction(where: {Celebration: {employeeId: {_eq: "${employeeId}"}, category: {_eq: "${category}"}}}) {
                    affected_rows
                }
                delete_celebrationdb_Celebration(where: {employeeId: {_eq: "${employeeId}"}, category: {_eq: "${category}"}}) {
                    affected_rows
                    returning {
                        employeeId
                    }
                }
            }
        `,
        };
        // console.log(query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('mutationdata', jsonData);
        if (jsonData.errors) {
            throw new Error('not found');
        }
        return jsonData.data.delete_celebrationdb_Celebration;
    } catch (err) {
        return { error: err };
    }
};

export const UpdateCelebration = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_celebrationdb_Celebration(where: {celebrationId: {_eq: "${obj.celebrationId}"}}, _set: {attachmentFile: "${obj.logo}", celebrationDate: "${obj.date}", description: "${obj.description}", title: "${obj.title}"}) {
                    affected_rows
                    returning {
                        attachmentFile
                        category
                        celebrationDate
                        celebrationId
                        description
                        employeeId
                        title
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('my data', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to add reactions!');
        }
        return jsonData.data.update_celebrationdb_Celebration.returning[0];
    } catch (err) {
        return { error: err };
    }
};
