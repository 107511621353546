/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
// import { FetchingDetails } from '../components/Loader/loader';

import { TeamplusLoader } from '../components/Loader/loader';

export default function FetchData() {
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);
    useMemo(() => {
        if (!loading) {
            history.push('/dashboard');
        }
    }, [loading]);
    return <TeamplusLoader />;
}
