import { createSlice } from '@reduxjs/toolkit';
// import { fetchPendingActions2 } from 'helpers/hasuraapi';
// export const getPendingActions: any = createAsyncThunk('pendingActions/getPendingActions', async (user: any) => {
//     const data: any = await fetchPendingActions2(user.organizationId, user.token);
//     const jsonData: any = await data.json();
//     return jsonData.data.timeoffdb_PendingActions[0];
// });
export const pendingActionsSlice = createSlice({
    name: 'pendingActions',
    initialState: { actions: {}, status: 'loading' },
    reducers: {
        updatePendingAction: (state, action) => {
            state.actions = action.payload;
        },
        fetchPendingAction: (state, action) => {
            state.actions = action.payload;
        },
        fetchAllPendingActions: (state, action) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                state.actions = action.payload?.data?.timeoffdb_PendingActions[0];
            }
        },
    },
    // extraReducers: {
    //     [getPendingActions.pending]: state => {
    //         state.status = 'loading';
    //     },
    //     [getPendingActions.fulfilled]: (state, action) => {
    //         state.actions = action.payload;
    //         state.status = 'success';
    //     },
    //     [getPendingActions.rejected]: state => {
    //         state.status = 'failed';
    //     },
    // },
});
export const { updatePendingAction, fetchPendingAction, fetchAllPendingActions } = pendingActionsSlice.actions;
export default pendingActionsSlice.reducer;
