import { newurl } from './hasuraapi';

// const newurl: any = 'https://udyamo-teamplus.hasura.app/v1/graphql';
export const createShoutout = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_shoutoutdb_Shoutout(objects: {createdBy: "${obj.createdBy}", createdOn: "${obj.createdOn}", emoji: { emoji: "${obj.emoji.emoji}", activeSkinTone: "${obj.emoji.activeSkinTone}", unified: "${obj.emoji.unified}", originalUnified: "${obj.emoji.originalUnified}" }, message: "${obj.message}", organizationId: "${obj.organizationId}", tags: "${obj.tags}"}) {
                    returning {
                        createdBy
                        createdOn
                        shoutoutId
                        message
                        tags
                        emoji
                        organizationId
                        Employee {
                            firstName
                            lastName
                            employeeId
                            engageMsBot
                            organization_employees {
                                slackId
                            }
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_shoutoutdb_Shoutout === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_shoutoutdb_Shoutout.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const addShoutoutEmployee = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutEmployee {
                insert_shoutoutdb_shoutout_employee_team(objects: {shoutoutId: "${obj.shoutoutId}", employeeId: "${obj.employeeId}", isPersonal: ${obj.isPersonal}}) {
                    returning {
                        employeeId
                        shoutoutEmployeeTeamId
                        shoutoutId
                        isSeen
                        isPersonal
                        Employee {
                            organization_employees(where: {employeeId: {_eq: "${obj.employeeId}"}, organizationId: {_eq: "${obj.orgId}"}}) {
                                slackId
                            }
                            firstName
                            lastName
                            employeeId
                            engageMsBot
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_shoutoutdb_shoutout_employee_team === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_shoutoutdb_shoutout_employee_team.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const addShoutoutTeam = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutEmployee {
                insert_shoutoutdb_shoutout_employee_team(objects: {shoutoutId: "${obj.shoutoutId}", teamId: "${obj.employeeId}", isSeen: true, isPersonal: false}) {
                    returning {
                        employeeId
                        shoutoutEmployeeTeamId
                        shoutoutId
                        isSeen
                        Team {
                            teamName
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_shoutoutdb_shoutout_employee_team === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_shoutoutdb_shoutout_employee_team.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const deleteShoutoutEmployee = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutEmployee {
                delete_shoutoutdb_shoutout_employee_team(where: {shoutoutEmployeeTeamId: {_eq: "${obj.shoutoutEmployeeTeamId}"}}) {
                    affected_rows
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        return jsonData.data.delete_shoutoutdb_shoutout_employee_team;
    } catch (err) {
        return { error: err };
    }
};

export const deleteShoutout = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation2 {
                delete_shoutoutdb_shoutout_comment(where: {shoutoutId: {_eq: "${obj.shoutoutId}"}}) {
                    affected_rows
                }
                delete_shoutoutdb_shoutout_reaction(where: {shoutoutId: {_eq: "${obj.shoutoutId}"}}) {
                    affected_rows
                }
                delete_shoutoutdb_shoutout_employee_team(where: {shoutoutId: {_eq: "${obj.shoutoutId}"}}) {
                    affected_rows
                }
                delete_shoutoutdb_Shoutout(where: {shoutoutId: {_eq: "${obj.shoutoutId}"}}) {
                    affected_rows
                    returning {
                        timestamp
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};

export const updateShoutout = async (obj: any) => {
    try {
        const query = {
            query: `mutation UpdateShoutout {
                update_shoutoutdb_Shoutout(where: {shoutoutId: {_eq: "${obj.shoutoutId}"}}, _set: {createdOn: "${obj.createdOn}", emoji: { emoji: "${obj.emoji.emoji}", activeSkinTone: "${obj.emoji.activeSkinTone}", unified: "${obj.emoji.unified}", originalUnified: "${obj.emoji.originalUnified}" }, message: "${obj.message}", tags: "${obj.tags}"}) {
                    returning {
                        createdBy
                        createdOn
                        shoutoutId
                        message
                        tags
                        emoji
                        timestamp
                        organizationId
                        Employee {
                            firstName
                            lastName
                            employeeId
                            engageMsBot
                            organization_employees {
                                slackId
                            }
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        return jsonData.data.update_shoutoutdb_Shoutout.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateShoutoutEmployee = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutEmployee {
                update_shoutoutdb_shoutout_employee_team(where: {shoutoutEmployeeTeamId: {_eq: "${obj.shoutoutEmployeeTeamId}"}}, _set: {employeeId: "${obj.employeeId}", isPersonal: ${obj.isPersonal}}) {
                    returning {
                        employeeId
                        shoutoutEmployeeTeamId
                        shoutoutId
                        isSeen
                        isPersonal
                        Employee {
                            firstName
                            lastName
                            employeeId
                            engageMsBot
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_shoutoutdb_shoutout_employee_team === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_shoutoutdb_shoutout_employee_team.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateShoutoutTeam = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutEmployee {
                update_shoutoutdb_shoutout_employee_team(where: {shoutoutEmployeeTeamId: {_eq: "${obj.shoutoutEmployeeTeamId}"}}, _set: {teamId: "${obj.employeeId}", isSeen: true}) {
                    returning {
                        employeeId
                        shoutoutEmployeeTeamId
                        shoutoutId
                        isSeen
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_shoutoutdb_shoutout_employee_team === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_shoutoutdb_shoutout_employee_team.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const addShoutoutComment = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutComment {
                insert_shoutoutdb_shoutout_comment(objects: {shoutoutId: "${obj.shoutoutId}", employeeId: "${obj.employeeId}", commentText: "${obj.commentText}", commentDateTime: "${obj.commentDateTime}"}) {
                    returning {
                        commentId
                        commentDateTime
                        commentText
                        employeeId
                        shoutoutId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_shoutoutdb_shoutout_reaction_comment === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_shoutoutdb_shoutout_comment.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateShoutoutComment = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutComment {
                update_shoutoutdb_shoutout_comment(where: {commentId: {_eq: "${obj.commentId}"}, employeeId: {_eq: "${obj.employeeId}"}}, _set: {commentText: "${obj.commentText}", commentDateTime: "${obj.commentDateTime}"}) {
                    returning {
                        commentId
                        commentDateTime
                        commentText
                        employeeId
                        shoutoutId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_shoutoutdb_shoutout_comment === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_shoutoutdb_shoutout_comment.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const deleteShoutoutComment = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutComment {
                delete_shoutoutdb_shoutout_comment(where: {commentId: {_eq: "${obj.commentId}"}}) {
                    affected_rows
                    returning {
                        commentId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.delete_shoutoutdb_shoutout_comment === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.delete_shoutoutdb_shoutout_comment.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const addShoutoutReaction = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutComment {
                insert_shoutoutdb_shoutout_reaction(objects: {shoutoutId: "${obj.shoutoutId}", employeeId: "${obj.employeeId}", reaction: { emoji: "${obj.emoji.emoji}", activeSkinTone: "${obj.emoji.activeSkinTone}", unified: "${obj.emoji.unified}", originalUnified: "${obj.emoji.originalUnified}" }}) {
                    returning {
                        employeeId
                        reaction
                        shoutoutId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_shoutoutdb_shoutout_reaction === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_shoutoutdb_shoutout_reaction.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateShoutoutReaction = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutReaction {
                update_shoutoutdb_shoutout_reaction(where: {shoutoutId: {_eq: "${obj.shoutoutId}"}, employeeId: {_eq: "${obj.employeeId}"}}, _set: {reaction: { emoji: "${obj.emoji.emoji}", activeSkinTone: "${obj.emoji.activeSkinTone}", unified: "${obj.emoji.unified}", originalUnified: "${obj.emoji.originalUnified}" }}) {
                    returning {
                        employeeId
                        reaction
                        shoutoutId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_shoutoutdb_shoutout_reaction === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_shoutoutdb_shoutout_reaction.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const deleteShoutoutReaction = async (obj: any) => {
    try {
        const query = {
            query: `mutation ShoutoutReaction {
                delete_shoutoutdb_shoutout_reaction(where: {shoutoutId: {_eq: "${obj.shoutoutId}"}, employeeId: {_eq: "${obj.employeeId}"}}) {
                    affected_rows
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        return jsonData.data.delete_shoutoutdb_shoutout_reaction;
    } catch (err) {
        return { error: err };
    }
};

export const viewShoutout = async (shoutoutEmployeeTeamId: string, employeeId: string, token: string) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_shoutoutdb_shoutout_employee_team(where: {shoutoutEmployeeTeamId: {_eq: "${shoutoutEmployeeTeamId}"}}, _set: {isSeen: true}) {
                    returning {
                        employeeId
                        isSeen
                        shoutoutEmployeeTeamId
                        shoutoutId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                'x-hasura-role': 'employee',
                // 'x-hasura-admin-secret': '5EP7zKFwg1vLTjy8Du3rg28TbGG9WpJGSPB58P33YmVoZChR1KTapy8pfN1laCbI',
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        return jsonData.data.update_shoutoutdb_shoutout_employee_team.returning[0];
    } catch (err) {
        return { error: err };
    }
};
