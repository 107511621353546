// const newurl: any = 'https://udyamo-teamplus.hasura.app/v1/graphql';
import { newurl } from './hasuraapi';

export const adminQuestion = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_standupdb_StandupTemplate(objects: {employeeId: "${obj.employeeId}", questions: ${JSON.stringify(
                obj.questions,
            )}, organizationId: "${obj.organizationId}"}) {
                    returning {
                        employeeId
                        organizationId
                        questions
                        standupTempId
                    }
                }
            }`,
        };
        // console.log('query', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        // console.log('data: ', data);
        const jsonData = await data.json();
        // console.log('questionsjsonData', jsonData);
        if (jsonData.errors) {
            throw new Error('Data not found');
        }
        return jsonData.data.insert_standupdb_StandupTemplate.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateAdminQuestion = async (obj: any) => {
    let questions: any;
    let tag = JSON.stringify(obj.tag);
    let length = tag.length;
    while (length !== 0) {
        tag = tag.replace('"name"', 'name');
        tag = tag.replace('"color"', 'color');
        length = length - 1;
    }
    if (obj.questions === null) {
        questions = null;
    } else {
        questions = JSON.stringify(obj.questions);
    }
    try {
        const query = {
            query: `mutation MyMutation {
                update_standupdb_StandupTemplate(where: {organizationId: {_eq: "${obj.organizationId}"}}, _set: {questions: ${questions}, employeeId: "${obj.employeeId}", tags: ${tag}}) {
                    affected_rows
                        returning {
                            questions
                            employeeId
                            tags
                            organizationId
                        }
                    }
            }`,
        };
        // console.log('fatcheadminQuestion', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data', jsonData);
        if (jsonData.errors) {
            throw new Error('failed to update Choices!');
        }
        return jsonData.data.update_standupdb_StandupTemplate.returning[0];
    } catch (err) {
        return err;
    }
};

export const employeeTags = async (obj: any) => {
    let tag = JSON.stringify(obj.tag);
    let length = tag.length;
    while (length !== 0) {
        tag = tag.replace('"name"', 'name');
        tag = tag.replace('"color"', 'color');
        length = length - 1;
    }
    try {
        const query = {
            query: `mutation MyMutation {
                update_standupdb_StandupTemplate(where: {organizationId: {_eq: "${obj.organizationId}"}}, _set: {employeeId: "${obj.employeeId}", tags: ${tag}}) {
                    affected_rows
                        returning {
                            employeeId
                            tags
                            organizationId
                        }
                    }
            }`,
        };
        // console.log('fatc..headminQuestion', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data', jsonData);
        if (jsonData.errors) {
            throw new Error('failed to update Choices!');
        }
        return jsonData.data.update_standupdb_StandupTemplate.returning[0];
    } catch (err) {
        return err;
    }
};

export const fetchQuestion = async (organizationId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                standupdb_StandupTemplate(where: {organizationId: {_eq: "${organizationId}"}}) {
                    employeeId
                    organizationId
                    questions
                    standupTempId
                    tags
                    startMessage
                }
            }`,
        };
        // console.log('query666', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // console.log('data: ', data);
        const jsonData = await data.json();
        // console.log('data22', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to create poll!');
        }
        return jsonData.data.standupdb_StandupTemplate[0];
    } catch (err) {
        return { error: err };
    }
};

export const standupNote = async (obj: any) => {
    // let data2: any = JSON.stringify(obj.answers);
    // // console.log('my data', data2);

    // let length = data2.length;
    // const questions = obj.questions;
    // while (length !== 0) {
    //     data2 = data2.replace(`${questions[length - 1]}`, `${questions[length - 1]}`);
    //     length = length - 1;
    // }
    // console.log('abcd', data2);

    let tag = JSON.stringify(obj.tag);
    let tagLength = tag.length;
    while (tagLength !== 0) {
        tag = tag.replace('"name"', 'name');
        tag = tag.replace('"color"', 'color');
        tagLength = tagLength - 1;
    }
    let ans = JSON.stringify(obj.answers);
    let length = ans.length;
    while (length !== 0) {
        ans = ans.replace('"question"', 'question');
        ans = ans.replace('"answer"', 'answer');
        length = length - 1;
    }
    // console.log(ans);
    try {
        const query = {
            query: `mutation MyMutation2 {
                        insert_standupdb_StandupNotes(objects: {answers: ${ans}, standupNoteDateTime: "${obj.standupNoteDateTime}", employeeId: "${obj.employeeId}", standupNoteDate: "${obj.standupNoteDate}", standupTempId: "${obj.standupTempId}", standupNoteTime: "${obj.standupNoteTime}", isAccomplished: ${obj.isAccomplished}, tags: ${tag}}) {
                                affected_rows
                                    returning {
                                        answers
                                        employeeId
                                        threadId
                                        StandupTemplate {
                                            startMessage
                                        }
                                        Employee {
                                            firstName
                                            lastName
                                        }
                                        standupNoteId
                                        standupNoteDate
                                        standupNoteDateTime
                                        standupTempId
                                        isAccomplished
                                        tags
                                    }
                                }
                        }`,
        };
        // console.log('queryTags', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('my data', jsonData);
        if (jsonData.errors) {
            throw new Error('Data not found');
        }
        return jsonData.data.insert_standupdb_StandupNotes.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateStandup = async (obj: any) => {
    let tag = JSON.stringify(obj.tags);
    let tagLength = tag.length;
    while (tagLength !== 0) {
        tag = tag.replace('"name"', 'name');
        tag = tag.replace('"color"', 'color');
        tagLength = tagLength - 1;
    }

    let ans = JSON.stringify(obj.answers);
    let length = ans.length;
    while (length !== 0) {
        ans = ans.replace('"question"', 'question');
        ans = ans.replace('"answer"', 'answer');
        length = length - 1;
    }
    try {
        const query = {
            query: `mutation MyMutation {
                    update_standupdb_StandupNotes(where: {standupNoteId: {_eq: "${obj.standupNoteId}"}, employeeId: {_eq: "${obj.employeeId}"}}, _set: {answers: ${ans}, standupNoteDate: "${obj.standupNoteDate}", standupNoteTime: "${obj.standupNoteTime}",standupNoteDateTime: "${obj.standupNoteDateTime}", isAccomplished: ${obj.isAccomplished}, tags: ${tag}}) {
                        returning {
                            answers
                            employeeId
                            standupNoteDate
                            standupNoteId
                            standupNoteTime
                            isAccomplished
                            tags
                            timestamp
                            threadId
                            StandupTemplate {
                                questions
                            }
                            Employee {
                                firstName
                                lastName
                            }
                            standupNoteDateTime
                            standupTempId
                    }
                }
            }`,
        };
        // console.log(query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('my data', jsonData);
        if (jsonData.errors) {
            throw new Error('Data not found');
        }
        return jsonData.data.update_standupdb_StandupNotes.returning[0];
    } catch (err) {
        // console.log('error');
        return { error: err };
    }
};

export const standupAnswers = async (standupTempId: any, standupDate: any, token: any) => {
    // console.log('yes it is ', standupTempId, standupDate);
    try {
        const query = {
            query: `query MyQuery2 {
                    standupdb_StandupNotes(where: {standupTempId: {_eq: "${standupTempId}"}, standupNoteDate: {_eq: "${standupDate}"}}, order_by: {standupNoteTime: asc}) {
                        answers
                        employeeId
                        isAccomplished
                        standupNoteDate
                        standupNoteId
                        standupTempId
                        standupNoteTime
                        Employee {
                            firstName
                            lastName
                            profileImage
                        }
                        tags
                    }
                }`,
        };
        // console.log('queryAnswers', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // console.log('Answer DAta', data);
        const jsonData = await data.json();
        // console.log('jsonData', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to create Standups!');
        }
        return jsonData.data.standupdb_StandupNotes;
    } catch (err) {
        return { error: err };
    }
};

export const tableNotes = async (standupTempId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery2 {
                    standupdb_StandupNotes(where: {standupTempId: {_eq: "${standupTempId}"}}, order_by: {standupNoteDate: desc}) {
                        standupNoteDate
                        employeeId
                        answers
                        isAccomplished
                        standupNoteId
                        standupNoteTime
                        standupTempId
                        standupNoteDateTime
                        Employee {
                            firstName
                            lastName
                            profileImage
                        }
                        tags
                    }
                }`,
        };
        // console.log('queryTags', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // // console.log(data);
        // const jsonData = await data.json();
        // // console.log('my data', jsonData);
        // if (jsonData.errors) {
        //     throw new Error('Failed to create poll!');
        // }
        // return jsonData.data.standupdb_StandupNotes;
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const deleteStandupNote = async (standupNoteId: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                        delete_standupdb_StandupNotes(where: {standupNoteId: {_eq: "${standupNoteId}"}}) {
                            affected_rows
                            returning {
                                timestamp
                                threadId
                                standupNoteDate
                            }
                        }
                    }`,
        };
        // console.log('queryTags', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // // console.log(data);
        const jsonData = await data.json();
        // console.log('my data', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to create poll!');
        }
        return jsonData.data.delete_standupdb_StandupNotes;
    } catch (err) {
        return { error: err };
    }
};

// new API to check the subscription for tenant employee
export const getEmployeeOrganizationChannel = async (employeeId: string, organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}}) {
                    Organization {
                        organizationId
                        organizationName
                        StandupTemplate {
                            standupTempId
                            questions
                            startMessage
                            tags
                        }
                        Teams(where: {isbotAdded: {_contains: {standup: true}}}) {
                            isbotAdded
                            msChannelId
                            organizationId
                        }
                        organization_employees {
                            employeeId
                            role
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log("jsonData", jsonData);
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.usermanagementdb_organization_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.usermanagementdb_organization_employee;
    } catch (error) {
        return error;
    }
};

export const getSubscriptionInfoOfUser = async (
    employeeId: string,
    tenantId: string,
    organizationId: string,
    token: string,
) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_tenant_employee(where: {employeeId: {_eq: "${employeeId}"},     Tenant: {workspaceId: {_eq: "${tenantId}"}}}) 
                {
                    tenantId
                    standupSubscriptionId
                    employeeId
                    role
                    Employee {
                        email
                        firstName
                        lastName
                        employeeId
                        profileImage
                    }
                    Tenant {
                        isBotAdded
                        Subscriptions(where: {planId: {_eq: "timeoffplan"}}) {
                            subscriptionId
                            tenantId
                            status
                            hardExpireDate
                            planId
                        }
                        workspaceId
                        name
                        Organizations(where: {organizationId: {_eq: "${organizationId}"}}) {
                            organizationId
                            organizationName
                            StandupTemplate {
                                standupTempId
                                startMessage
                                questions
                                organizationId
                            }
                            Teams(where: {isbotAdded: {_contains: {standup: true}}}) {
                                isbotAdded
                                msChannelId
                                organizationId
                            }
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log("jsonData", jsonData);
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.usermanagementdb_tenant_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.usermanagementdb_tenant_employee[0];
    } catch (error) {
        return error;
    }
};

export const editStandupNoteMessageId = async (obj: any, token: string) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_standupdb_StandupNotes_by_pk(pk_columns: {standupNoteId: "${obj.standupNoteId}"}, _set: {msgId: "${obj.messageId}", threadId: "${obj.threadId}"}) {
                    isAccomplished
                    msgId
                    standupNoteDate
                    standupNoteDateTime
                    standupNoteId
                    standupTempId
                    threadId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log("jsonData", jsonData);
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        if (jsonData.update_standupdb_StandupNotes_by_pk === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.update_standupdb_StandupNotes_by_pk;
    } catch (err) {
        return { error: err };
    }
};
