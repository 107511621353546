interface Props {
    fname?: string;
    lname?: string;
    pass?: string;
    cpass?: string;
    email?: string;
    orgname?: string;
    members?: any;
    days?: any;
    leaveName?: any;
}

function validateRegisterForm(props: Props): any {
    const email: any = props.email === undefined ? 'abcd@gmail.com' : props.email;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (
        props.cpass === '' ||
        props.pass === '' ||
        props.fname === '' ||
        props.lname === '' ||
        props.email === '' ||
        props.orgname === '' ||
        props.members === '' ||
        props.days === '' ||
        props.leaveName === ''
    ) {
        return { blankfield: true };
    }
    if (props.pass !== props.cpass) {
        return { passwordmatch: true };
    }
    if ((props.pass || 'demo1234').length < 8) {
        return { passwordLength: true };
    }
    if (!emailPattern.test(email)) {
        return { email: true };
    }
    if (props.days < 1 || props.days > 50) {
        return { isNumber: true };
    }
    return null;
}

export default validateRegisterForm;
