import React, { useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { Button, Grid, Hidden } from '@material-ui/core';
import AuthContext from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import error from './ErrorImage.png';
import { Auth } from 'aws-amplify';
interface Props {
    title?: string;
    img?: string;
    msg?: string;
    internet?: string;
}
declare let location: any;
export default function ErrorPage(props: Props): JSX.Element {
    const classes = useStyles();
    const history = useHistory();
    const mycontext = useContext(AuthContext);
    const [internet, setInternet] = useState<boolean>(false);
    useEffect(() => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            fetch('https://teamplus.udyamo.com', {
                // Check for internet connectivity
                mode: 'no-cors',
            })
                .then(() => {
                    setInternet(true);
                })
                .catch(() => {
                    setInternet(false);
                });
        } else {
            setInternet(false);
        }
    }, [mycontext]);
    const handleLogout = () => {
        Auth.signOut();
        mycontext.logout();
        setTimeout(() => {
            history.push('/login');
        }, 2000);
    };
    const reloadPage = () => {
        location.reload();
    };
    return (
        <>
            <Hidden mdUp>
                <div className={classes.errorDivMobile}>
                    {!internet ? (
                        <>
                            <p className={classes.heading}>Not connected to the internet</p>
                            <img src={error} alt="Error" className={classes.imgMobile} />
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.btn}
                                onClick={reloadPage}
                            >
                                Reload
                            </Button>
                        </>
                    ) : (
                        <>
                            <h2 className={classes.heading}>{props.title}</h2>
                            <img src={error} alt="Error" className={classes.imgMobile} />
                            {!props.title && (
                                <p className={classes.content}>
                                    Looks like you&#39;re looking for a page that doesn&#39;t exist. We recommend that
                                    you take a deep breath and go back and try again.{' '}
                                </p>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.btn}
                                onClick={handleLogout}
                            >
                                Go Back
                            </Button>
                        </>
                    )}
                </div>
            </Hidden>
            <Hidden mdDown>
                <Grid container className={classes.mainDiv}>
                    <Grid item xs style={{ padding: 30 }}>
                        {!internet ? (
                            <>
                                <p className={classes.heading}>Not connected to the internet</p>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.btn}
                                    onClick={reloadPage}
                                >
                                    Reload
                                </Button>
                            </>
                        ) : (
                            <>
                                <h2 className={classes.heading}>{props.title}</h2>
                                {!props.title && (
                                    <p className={classes.content}>
                                        Looks like you&#39;re looking for a page that doesn&#39;t exist. We recommend
                                        that you take a deep breath and go back and try again.{' '}
                                    </p>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.btn}
                                    onClick={handleLogout}
                                >
                                    Go Back
                                </Button>
                            </>
                        )}
                    </Grid>
                    <Grid item xs style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-start' }}>
                        <img src={error} alt="Error" className={classes.img} />
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
}
