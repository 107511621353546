import React, { useState } from 'react';
import myStyle from './TeamStyle';
import { withStyles } from '@material-ui/core/styles';
import {
    Hidden,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Typography,
    Avatar,
    TableRow,
    Tooltip,
    Button,
    TableContainer,
    Paper,
    Menu,
    MenuItem,
    useTheme,
} from '@material-ui/core';
import AuthContext from '../../context/AuthContext';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import Skeleton from '@material-ui/lab/Skeleton';
import { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteModal from './DeleteModal';
import { getFullName } from '../../helpers/getInitials';
import { Icon } from '@iconify/react';

interface Props {
    setRenderTeampg?: any;
    setCurrent?: any;
    users: any[];
    isLoading?: boolean;
    error?: boolean;
    errorMessage?: string;
    searchvalue?: string;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
    setTeamDetails?: any;
    teamID?: any;
    setteamID?: any;
    teamname?: any;
    setteamname?: any;
    selectedTeam?: any;
    setSelectedTeam?: any;
    teamDetails?: any;
}

const CustomTooltip = withStyles({
    tooltip: {
        backgroundColor: '#3D49B6',
        color: 'white',
        fontSize: 11,
        fontFamily: 'Nunito Sans',
    },
})(Tooltip);

function DetailsTable(props: Props) {
    const classes = myStyle();
    const theme = useTheme();
    const mycontext = useContext(AuthContext);
    const [isDelete, setDelete] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<any>([]);
    const [profileMenu, setProfileMenu] = React.useState<any>(null);
    const [approverMenu, setApproverMenu] = React.useState<any>(null);
    const [teamApprover, setTeamApprover] = React.useState<any>([]);
    const [teamMember, setTeamMember] = React.useState<any>([]);
    const handleProfileMenuClick = (event: any, user: any) => {
        setProfileMenu(event.currentTarget);
        setTeamMember(user.team_employees.filter((m: any) => !m.isApprover));
    };

    const handleProfileMenuClose = () => {
        setProfileMenu(null);
        setTeamMember([]);
    };

    const handleApproverMenuClick = (event: any, user: any) => {
        setApproverMenu(event.currentTarget);
        setTeamApprover(user.team_employees.filter((m: any) => m.isApprover));
    };

    const handleApproverMenuClose = () => {
        setApproverMenu(null);
        setTeamApprover([]);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>, team: any) => {
        setAnchorEl((anchorEl: any) => ({
            ...anchorEl,
            [team.teamId]: event.target,
        }));
        props.setteamID(team.teamId);
        props.setteamname(team.teamName);
        props.setTeamDetails(team);
    };

    const handleClose = (teamId: any) => {
        setAnchorEl((anchorEl: any) => ({
            ...anchorEl,
            [teamId]: null,
        }));
    };

    const handleViewMenu = () => {
        props.setCurrent('details');
    };

    const handleEditMenu = () => {
        props.setCurrent('edit');
    };

    const handleMenuElementClose = (e: any, team: any) => {
        handleClose(team);
        setDelete(!isDelete);
    };
    return (
        <>
            <DeleteModal
                isDelete={isDelete}
                setDelete={setDelete}
                teamId={props.teamID}
                teamname={props.teamname}
                setAlertMessage={props.setAlertMessage}
                setAlertm={props.setAlertm}
                setSeverity={props.setSeverity}
            />
            <Menu
                id="ApproverMenu"
                anchorEl={approverMenu}
                open={Boolean(approverMenu)}
                onClose={handleApproverMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'ApproverButton',
                }}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Typography
                    className={classes.teamMenuTitle}
                    style={{
                        margin: '3px 10px',
                        outline: 'none',
                    }}
                    onClick={handleApproverMenuClose}
                >
                    Team Approver List
                </Typography>
                <div className={classes.teamList}>
                    {teamApprover.map((a: any) =>
                        a.Employee.profileImage ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: '3px 0px',
                                }}
                                key={'approver-' + a.Employee.employeeId}
                                onClick={handleApproverMenuClose}
                            >
                                <Avatar
                                    alt={a.Employee.firstName}
                                    src={a.Employee.profileImage}
                                    className={classes.avatarimage}
                                />
                                &nbsp;
                                <Typography className={classes.textName}>
                                    {a.Employee.firstName} {a.Employee.lastName}
                                </Typography>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: '3px 0px',
                                }}
                                key={'approver-' + a.Employee.employeeId}
                                onClick={handleApproverMenuClose}
                            >
                                <Avatar className={classes.avatarimage}>
                                    {getFullName(a.Employee.firstName + ' ' + a.Employee.lastName)}
                                </Avatar>
                                &nbsp;
                                <Typography className={classes.textName}>
                                    {a.Employee.firstName} {a.Employee.lastName}
                                </Typography>
                            </div>
                        ),
                    )}
                </div>
            </Menu>
            <Menu
                id="ProfileMenu"
                anchorEl={profileMenu}
                open={Boolean(profileMenu)}
                onClose={handleProfileMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'ProfileButton',
                }}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Typography
                    className={classes.teamMenuTitle}
                    style={{
                        margin: '3px 10px',
                        outline: 'none',
                    }}
                    onClick={handleProfileMenuClose}
                >
                    Team Member List
                </Typography>
                <div className={classes.teamList}>
                    {teamMember.map((a: any, index1: number) =>
                        a.Employee.profileImage ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: '3px 0px',
                                }}
                                key={'member-' + index1}
                                onClick={handleProfileMenuClose}
                            >
                                <Avatar
                                    alt={a.Employee.firstName}
                                    src={a.Employee.profileImage}
                                    className={classes.avatarimage}
                                />
                                &nbsp;
                                <Typography className={classes.textName}>
                                    {a.Employee.firstName} {a.Employee.lastName}
                                </Typography>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: '3px 0px',
                                }}
                                key={'member-' + index1}
                                onClick={handleProfileMenuClose}
                            >
                                <Avatar className={classes.avatarimage}>
                                    {getFullName(a.Employee.firstName + ' ' + a.Employee.lastName)}
                                </Avatar>
                                &nbsp;
                                <Typography className={classes.textName}>
                                    {a.Employee.firstName} {a.Employee.lastName}
                                </Typography>
                            </div>
                        ),
                    )}
                </div>
            </Menu>
            <div className={classes.mainRoot} style={{ marginBottom: 40 }}>
                {props.users.length === 0 ? (
                    <>
                        {props.isLoading && (
                            <>
                                <Hidden lgUp>
                                    <Paper elevation={0} className={classes.mobpaperstyle}>
                                        <div className={classes.Team}>
                                            <Typography className={classes.userteam}>
                                                <Skeleton height={20} width={200} />
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography className={classes.teamtitle1}>Members</Typography>
                                            <div className={classes.Members}>
                                                <Skeleton height={20} width={300} />
                                            </div>
                                        </div>
                                        <div>
                                            <Typography className={classes.teamtitle1}>Approvers</Typography>
                                            <div className={classes.Members}>
                                                <Skeleton height={20} width={300} />
                                            </div>
                                        </div>
                                    </Paper>
                                </Hidden>
                                <Hidden mdDown>
                                    <Table stickyHeader size="small" aria-label="small table" className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" className={classes.headerId}>
                                                    #
                                                </TableCell>
                                                <TableCell align="left" className={classes.headerName}>
                                                    Team
                                                </TableCell>
                                                <TableCell align="left" className={classes.headerAppEmp}>
                                                    Members
                                                </TableCell>
                                                <TableCell align="left" className={classes.headerAppEmp}>
                                                    Approvers
                                                </TableCell>
                                                {mycontext.isAdmin && (
                                                    <TableCell align="center" className={classes.headerAction}>
                                                        Action
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {[11, 21, 31, 41].map((i3: number) => (
                                                <TableRow key={'row-' + i3} className={classes.row}>
                                                    <TableCell className={classes.hashColumn}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    <TableCell className={classes.cellName}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    <TableCell className={classes.cellName}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    <TableCell className={classes.cellName}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    {mycontext.isAdmin && (
                                                        <TableCell className={classes.cellName}>
                                                            <Skeleton height={20} />
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Hidden>
                            </>
                        )}

                        {props.isLoading || (
                            <div>
                                <Hidden mdDown>
                                    <div className={classes.rowmessage}>
                                        <Typography className={classes.fontright}>
                                            {mycontext.isAdmin
                                                ? 'No teams found. Please create new team.'
                                                : 'No teams found.'}
                                        </Typography>
                                    </div>
                                </Hidden>
                                <Hidden lgUp>
                                    <div className={classes.rowmessage}>
                                        <Typography className={classes.fontright}>
                                            {mycontext.isAdmin
                                                ? 'No teams found. Please create new team.'
                                                : 'No teams found.'}
                                        </Typography>
                                    </div>
                                </Hidden>
                            </div>
                        )}
                    </>
                ) : (
                    <div>
                        <Hidden lgUp>
                            <div>
                                {props.users.filter(searchval =>
                                    searchval.teamName.toLowerCase().includes(props.searchvalue?.toLowerCase()),
                                ).length === 0 ? (
                                    <>
                                        <img
                                            src={upcomingTimeoff}
                                            alt="No teams found"
                                            className={classes.notfoundImage}
                                        />
                                        <Typography className={classes.notfoundText}>No teams found</Typography>
                                    </>
                                ) : (
                                    <div>
                                        {props.users
                                            .filter(searchval =>
                                                searchval.teamName
                                                    .toLowerCase()
                                                    .includes(props.searchvalue?.toLowerCase()),
                                            )
                                            .map((user: any, i8: any) => (
                                                <Paper
                                                    key={'user-' + user.teamId + i8}
                                                    elevation={0}
                                                    className={classes.mobpaperstyle}
                                                >
                                                    <div className={classes.Team}>
                                                        <div>
                                                            <Typography
                                                                className={classes.userteam}
                                                                onClick={(e: any) => {
                                                                    handleMenu(e, user);
                                                                    handleViewMenu();
                                                                }}
                                                            >
                                                                {user.teamName}
                                                            </Typography>
                                                        </div>
                                                        {mycontext.isAdmin && (
                                                            <>
                                                                <IconButton
                                                                    id={'iconButton' + i8}
                                                                    aria-label="Actions Icon"
                                                                    aria-haspopup="true"
                                                                    aria-controls="iconGeneralMenu"
                                                                    onClick={(e: any) => handleMenu(e, user)}
                                                                    style={{ padding: 0, outline: 'none' }}
                                                                >
                                                                    <MoreHorizIcon className={classes.IconSty} />
                                                                </IconButton>
                                                                <Menu
                                                                    id="iconmenu"
                                                                    anchorEl={anchorEl[user.teamId]}
                                                                    getContentAnchorEl={null}
                                                                    open={Boolean(anchorEl[user.teamId])}
                                                                    onClose={e => handleClose(user.teamId)}
                                                                    MenuListProps={{
                                                                        'aria-labelledby': 'iconButton',
                                                                    }}
                                                                    keepMounted
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    className={classes.menusty}
                                                                >
                                                                    <MenuItem
                                                                        id="iconMenu1"
                                                                        className={classes.menuitem}
                                                                        onClick={handleViewMenu}
                                                                    >
                                                                        View Team
                                                                    </MenuItem>
                                                                    {user.teamName?.toLowerCase() !== 'general' && (
                                                                        <div>
                                                                            <MenuItem
                                                                                id="iconMenu2"
                                                                                className={classes.menuitem}
                                                                                onClick={handleEditMenu}
                                                                            >
                                                                                Edit Team
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                id={`iconMenu3${i8}`}
                                                                                className={classes.menuitem}
                                                                                onClick={e =>
                                                                                    handleMenuElementClose(
                                                                                        e,
                                                                                        user.teamId,
                                                                                    )
                                                                                }
                                                                            >
                                                                                Delete Team
                                                                            </MenuItem>
                                                                        </div>
                                                                    )}
                                                                </Menu>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {user.team_employees.filter((m: any) => !m.isApprover).length >
                                                        0 ? (
                                                            <>
                                                                <Typography className={classes.teamtitle1}>
                                                                    Members (
                                                                    {
                                                                        user.team_employees.filter(
                                                                            (a: any) => !a.isApprover,
                                                                        ).length
                                                                    }
                                                                    )
                                                                </Typography>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    {user.team_employees
                                                                        .filter((m: any) => !m.isApprover)
                                                                        .map(
                                                                            (a: any, i7: number) =>
                                                                                i7 < 4 &&
                                                                                (a.Employee.profileImage ? (
                                                                                    <CustomTooltip
                                                                                        title={a.Employee.firstName}
                                                                                        placement="top"
                                                                                        key={'avatar-' + i7}
                                                                                        arrow
                                                                                    >
                                                                                        <Avatar
                                                                                            className={
                                                                                                classes.avatarimage
                                                                                            }
                                                                                            alt={a.Employee.firstName}
                                                                                            src={
                                                                                                a.Employee.profileImage
                                                                                            }
                                                                                            style={
                                                                                                i7 !== 0
                                                                                                    ? {
                                                                                                          left: `-${i7 *
                                                                                                              8}px`,
                                                                                                      }
                                                                                                    : {
                                                                                                          left: '0px',
                                                                                                      }
                                                                                            }
                                                                                        />
                                                                                    </CustomTooltip>
                                                                                ) : (
                                                                                    <CustomTooltip
                                                                                        title={a.Employee.firstName}
                                                                                        placement="top"
                                                                                        key={'avatar-' + i7}
                                                                                        arrow
                                                                                    >
                                                                                        <Avatar
                                                                                            className={
                                                                                                classes.avatarimage
                                                                                            }
                                                                                            style={
                                                                                                i7 !== 0
                                                                                                    ? {
                                                                                                          left: `-${i7 *
                                                                                                              8}px`,
                                                                                                      }
                                                                                                    : {
                                                                                                          left: '0px',
                                                                                                      }
                                                                                            }
                                                                                        >
                                                                                            {getFullName(
                                                                                                a.Employee.firstName +
                                                                                                    ' ' +
                                                                                                    a.Employee.lastName,
                                                                                            )}
                                                                                        </Avatar>
                                                                                    </CustomTooltip>
                                                                                )),
                                                                        )}
                                                                    {user.team_employees.filter(
                                                                        (m: any) => !m.isApprover,
                                                                    ).length > 4 && (
                                                                        <Avatar
                                                                            className={classes.fab}
                                                                            style={{ left: '-24px' }}
                                                                            onClick={(e: any) =>
                                                                                handleProfileMenuClick(e, user)
                                                                            }
                                                                        >
                                                                            +
                                                                            {user.team_employees.filter(
                                                                                (m: any) => !m.isApprover,
                                                                            ).length - 4}
                                                                        </Avatar>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography className={classes.teamtitle1}>
                                                                    Members
                                                                </Typography>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <span
                                                                        className="iconify"
                                                                        data-icon="bi:dash"
                                                                        data-width="28"
                                                                        style={{ fill: theme.palette.text.primary }}
                                                                    ></span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {user.team_employees.filter((m: any) => m.isApprover).length >
                                                        0 ? (
                                                            <>
                                                                <Typography className={classes.teamtitle1}>
                                                                    Approvers (
                                                                    {
                                                                        user.team_employees.filter(
                                                                            (a: any) => a.isApprover,
                                                                        ).length
                                                                    }
                                                                    )
                                                                </Typography>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    {user.team_employees
                                                                        .filter((m: any) => m.isApprover)
                                                                        .map(
                                                                            (a: any, i7: number) =>
                                                                                i7 < 4 &&
                                                                                (a.Employee.profileImage ? (
                                                                                    <CustomTooltip
                                                                                        title={a.Employee.firstName}
                                                                                        placement="top"
                                                                                        key={'emp-' + i7}
                                                                                        arrow
                                                                                    >
                                                                                        <Avatar
                                                                                            className={
                                                                                                classes.avatarimage
                                                                                            }
                                                                                            alt={a.Employee.firstName}
                                                                                            src={
                                                                                                a.Employee.profileImage
                                                                                            }
                                                                                            style={
                                                                                                i7 !== 0
                                                                                                    ? {
                                                                                                          left: `-${i7 *
                                                                                                              8}px`,
                                                                                                      }
                                                                                                    : {
                                                                                                          left: '0px',
                                                                                                      }
                                                                                            }
                                                                                        />
                                                                                    </CustomTooltip>
                                                                                ) : (
                                                                                    <CustomTooltip
                                                                                        title={a.Employee.firstName}
                                                                                        placement="top"
                                                                                        key={'emp-' + i7}
                                                                                        arrow
                                                                                    >
                                                                                        <Avatar
                                                                                            className={
                                                                                                classes.avatarimage
                                                                                            }
                                                                                            style={
                                                                                                i7 !== 0
                                                                                                    ? {
                                                                                                          left: `-${i7 *
                                                                                                              8}px`,
                                                                                                      }
                                                                                                    : {
                                                                                                          left: '0px',
                                                                                                      }
                                                                                            }
                                                                                        >
                                                                                            {getFullName(
                                                                                                a.Employee.firstName +
                                                                                                    ' ' +
                                                                                                    a.Employee.lastName,
                                                                                            )}
                                                                                        </Avatar>
                                                                                    </CustomTooltip>
                                                                                )),
                                                                        )}
                                                                    {user.team_employees.filter(
                                                                        (m: any) => m.isApprover,
                                                                    ).length > 4 && (
                                                                        <Avatar
                                                                            className={classes.fab}
                                                                            style={{ left: '-24px' }}
                                                                            onClick={(e: any) =>
                                                                                handleApproverMenuClick(e, user)
                                                                            }
                                                                        >
                                                                            +
                                                                            {user.team_employees.filter(
                                                                                (m: any) => m.isApprover,
                                                                            ).length - 4}
                                                                        </Avatar>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography className={classes.teamtitle1}>
                                                                    Members
                                                                </Typography>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <span
                                                                        className="iconify"
                                                                        data-icon="bi:dash"
                                                                        data-width="28"
                                                                        style={{ fill: theme.palette.text.primary }}
                                                                    ></span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </Paper>
                                            ))}
                                    </div>
                                )}

                                <div>
                                    {mycontext.isAdmin && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.btnFixed}
                                            id="create_new_team"
                                            onClick={() => props.setCurrent('create')}
                                        >
                                            Create New Team
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdDown>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader size="small" className={classes.table}>
                                    <TableHead style={{ zIndex: 999, backgroundColor: '#fff' }}>
                                        <TableRow>
                                            <TableCell align="center" className={classes.headerId}>
                                                #
                                            </TableCell>
                                            <TableCell align="left" className={classes.headerName}>
                                                Team
                                            </TableCell>
                                            <TableCell align="left" className={classes.headerAppEmp}>
                                                Members
                                            </TableCell>
                                            <TableCell align="left" className={classes.headerAppEmp}>
                                                Approvers
                                            </TableCell>
                                            {mycontext.isAdmin && (
                                                <TableCell align="center" className={classes.headerAction}>
                                                    Action
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    {props.users.filter(searchval =>
                                        searchval.teamName.toLowerCase().includes(props.searchvalue?.toLowerCase()),
                                    ).length === 0 ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    id="noResultFound"
                                                    colSpan={6}
                                                    className={classes.fontsty}
                                                    style={{ borderBottom: 'none' }}
                                                >
                                                    <img
                                                        src={upcomingTimeoff}
                                                        alt="No Teams found"
                                                        className={classes.notfoundImage}
                                                    />
                                                    <Typography className={classes.notfoundText}>
                                                        No teams found
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {props.users
                                                .filter(searchval =>
                                                    searchval.teamName
                                                        .toLowerCase()
                                                        .includes(props.searchvalue?.toLowerCase()),
                                                )
                                                .map((user, i5) => (
                                                    <TableRow className={classes.row} key={'users-' + i5}>
                                                        <TableCell align="center" className={classes.hashColumn}>
                                                            {i5 + 1}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={classes.cellName}
                                                            id={'teamName' + i5}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                className={classes.username}
                                                                onClick={(e: any) => {
                                                                    handleMenu(e, user);
                                                                    handleViewMenu();
                                                                }}
                                                            >
                                                                {user.teamName}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" className={classes.cellName}>
                                                            {user.team_employees.filter((m: any) => !m.isApprover)
                                                                .length !== 0 ? (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        {user.team_employees
                                                                            .filter((m: any) => !m.isApprover)
                                                                            .map(
                                                                                (a: any, i7: number) =>
                                                                                    i7 < 5 &&
                                                                                    (a.Employee.profileImage ? (
                                                                                        <CustomTooltip
                                                                                            title={a.Employee.firstName}
                                                                                            placement="top"
                                                                                            key={'av-' + i7}
                                                                                            arrow
                                                                                        >
                                                                                            <Avatar
                                                                                                className={
                                                                                                    classes.avatarimage
                                                                                                }
                                                                                                alt={
                                                                                                    a.Employee.firstName
                                                                                                }
                                                                                                src={
                                                                                                    a.Employee
                                                                                                        .profileImage
                                                                                                }
                                                                                                style={
                                                                                                    i7 !== 0
                                                                                                        ? {
                                                                                                              left: `-${i7 *
                                                                                                                  8}px`,
                                                                                                          }
                                                                                                        : {
                                                                                                              left:
                                                                                                                  '0px',
                                                                                                          }
                                                                                                }
                                                                                            />
                                                                                        </CustomTooltip>
                                                                                    ) : (
                                                                                        <CustomTooltip
                                                                                            title={a.Employee.firstName}
                                                                                            placement="top"
                                                                                            key={'av-' + i7}
                                                                                            arrow
                                                                                        >
                                                                                            <Avatar
                                                                                                className={
                                                                                                    classes.avatarimage
                                                                                                }
                                                                                                style={
                                                                                                    i7 !== 0
                                                                                                        ? {
                                                                                                              left: `-${i7 *
                                                                                                                  8}px`,
                                                                                                          }
                                                                                                        : {
                                                                                                              left:
                                                                                                                  '0px',
                                                                                                          }
                                                                                                }
                                                                                            >
                                                                                                {getFullName(
                                                                                                    a.Employee
                                                                                                        .firstName +
                                                                                                        ' ' +
                                                                                                        a.Employee
                                                                                                            .lastName,
                                                                                                )}
                                                                                            </Avatar>
                                                                                        </CustomTooltip>
                                                                                    )),
                                                                            )}
                                                                        {user.team_employees.filter(
                                                                            (m: any) => !m.isApprover,
                                                                        ).length > 5 && (
                                                                            <Avatar
                                                                                className={classes.fab}
                                                                                style={{ left: '-24px' }}
                                                                                onClick={(e: any) =>
                                                                                    handleProfileMenuClick(e, user)
                                                                                }
                                                                            >
                                                                                +
                                                                                {user.team_employees.filter(
                                                                                    (m: any) => !m.isApprover,
                                                                                ).length - 5}
                                                                            </Avatar>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <Icon icon="bi:dash" width="25" height="25" />
                                                            )}
                                                        </TableCell>
                                                        <TableCell className={classes.cellName} align="left">
                                                            {user.team_employees.filter((m: any) => m.isApprover)
                                                                .length !== 0 ? (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        {user.team_employees
                                                                            .filter((m: any) => m.isApprover)
                                                                            .map(
                                                                                (a: any, i7: number) =>
                                                                                    i7 < 5 &&
                                                                                    (a.Employee.profileImage ? (
                                                                                        <CustomTooltip
                                                                                            title={a.Employee.firstName}
                                                                                            placement="top"
                                                                                            key={'avatars:' + i7}
                                                                                            arrow
                                                                                        >
                                                                                            <Avatar
                                                                                                className={
                                                                                                    classes.avatarimage
                                                                                                }
                                                                                                alt={
                                                                                                    a.Employee.firstName
                                                                                                }
                                                                                                src={
                                                                                                    a.Employee
                                                                                                        .profileImage
                                                                                                }
                                                                                                style={
                                                                                                    i7 !== 0
                                                                                                        ? {
                                                                                                              left: `-${i7 *
                                                                                                                  8}px`,
                                                                                                          }
                                                                                                        : {
                                                                                                              left:
                                                                                                                  '0px',
                                                                                                          }
                                                                                                }
                                                                                            />
                                                                                        </CustomTooltip>
                                                                                    ) : (
                                                                                        <CustomTooltip
                                                                                            title={a.Employee.firstName}
                                                                                            placement="top"
                                                                                            key={'avatars:' + i7}
                                                                                            arrow
                                                                                        >
                                                                                            <Avatar
                                                                                                className={
                                                                                                    classes.avatarimage
                                                                                                }
                                                                                                style={
                                                                                                    i7 !== 0
                                                                                                        ? {
                                                                                                              left: `-${i7 *
                                                                                                                  8}px`,
                                                                                                          }
                                                                                                        : {
                                                                                                              left:
                                                                                                                  '0px',
                                                                                                          }
                                                                                                }
                                                                                            >
                                                                                                {getFullName(
                                                                                                    a.Employee
                                                                                                        .firstName +
                                                                                                        ' ' +
                                                                                                        a.Employee
                                                                                                            .lastName,
                                                                                                )}
                                                                                            </Avatar>
                                                                                        </CustomTooltip>
                                                                                    )),
                                                                            )}
                                                                        {user.team_employees.filter(
                                                                            (m: any) => m.isApprover,
                                                                        ).length > 5 && (
                                                                            <Avatar
                                                                                className={classes.fab}
                                                                                style={{ left: '-24px' }}
                                                                                onClick={(e: any) =>
                                                                                    handleApproverMenuClick(e, user)
                                                                                }
                                                                            >
                                                                                +
                                                                                {user.team_employees.filter(
                                                                                    (m: any) => m.isApprover,
                                                                                ).length - 5}
                                                                            </Avatar>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <Icon icon="bi:dash" width="25" height="25" />
                                                            )}
                                                        </TableCell>
                                                        {mycontext.isAdmin && (
                                                            <TableCell
                                                                align="center"
                                                                className={classes.cellName}
                                                                style={{ padding: 0 }}
                                                            >
                                                                <IconButton
                                                                    id={'iconButton' + i5}
                                                                    aria-controls="iconGeneralMenu"
                                                                    onClick={(e: any) => handleMenu(e, user)}
                                                                    style={{ padding: 0, outline: 'none' }}
                                                                >
                                                                    <MoreHorizIcon className={classes.IconSty} />
                                                                </IconButton>
                                                                <Menu
                                                                    id="iconGeneralMenu"
                                                                    anchorEl={anchorEl[user.teamId]}
                                                                    getContentAnchorEl={null}
                                                                    open={Boolean(anchorEl[user.teamId])}
                                                                    onClose={() => handleClose(user.teamId)}
                                                                    MenuListProps={{
                                                                        'aria-labelledby': 'iconGeneralButton',
                                                                    }}
                                                                    keepMounted
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    className={classes.menusty}
                                                                >
                                                                    <MenuItem
                                                                        id="iconMenu1"
                                                                        className={classes.menuitem}
                                                                        onClick={handleViewMenu}
                                                                    >
                                                                        View Team
                                                                    </MenuItem>
                                                                    {user.teamName?.toLowerCase() !== 'general' && (
                                                                        <div>
                                                                            <MenuItem
                                                                                id="iconMenu2"
                                                                                className={classes.menuitem}
                                                                                onClick={handleEditMenu}
                                                                            >
                                                                                Edit Team
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                id="iconMenu3"
                                                                                className={classes.menuitem}
                                                                                onClick={e =>
                                                                                    handleMenuElementClose(
                                                                                        e,
                                                                                        user.teamId,
                                                                                    )
                                                                                }
                                                                            >
                                                                                Delete Team
                                                                            </MenuItem>
                                                                        </div>
                                                                    )}
                                                                </Menu>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Hidden>
                    </div>
                )}
            </div>
        </>
    );
}
export default DetailsTable;
