import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            // padding: '0px 10px',
            marginTop: 5,
            textAlign: 'center',
            fontFamily: 'Nunito Sans',
            fontSize: 16,
        },
        row: {
            color: 'red',
            padding: '5px 10px',
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            // border: '1px solid red',
            // borderRadius: '5px',
        },
        errorDiv: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.background.paper,
        },
        mainDiv: {
            width: '100%',
            minHeight: 'calc(100vh - 80px)',
            maxHeight: 'calc(100vh - 70px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.background.paper,
        },
        errorDivMobile: {
            padding: 20,
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
        },
        heading: {
            color: '#3C48B5',
            fontSize: '54px',
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        headingGoogle: {
            color: '#3C48B5',
            fontSize: '30px',
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        subheading: {
            color: '#444444',
            fontSize: '26px',
            fontFamily: 'Nunito Sans',
            fontWeight: 'bold',
        },
        content: {
            color: '#444444',
            fontSize: '16px',
            fontFamily: 'Nunito Sans',
            marginTop: 30,
        },
        img: {
            width: '100%',
            height: '100%',
            padding: '50px',
            paddingLeft: '0px',
        },
        imgMobile: {
            maxWidth: '100%',
            height: '300px',
        },
        btn: {
            width: 100,
            marginTop: '30px',
            textTransform: 'capitalize',
            padding: '5px 10px',
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                outline: 'none',
                border: 'none',
            },
        },
    }),
);

export default useStyles;
