/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useMemo } from 'react';
import {
    Grid,
    FormLabel,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    FormControl,
    FormGroup,
    Tooltip,
    Checkbox,
    Button,
    Modal,
    Paper,
    useTheme,
} from '@material-ui/core';
import { SettingStyles } from './newSettingStyle';
import { useSelector, useDispatch } from 'react-redux';
// import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import {
    updateOrganizationSettings,
    updateOrganizationPendingAction,
    updateOrganization,
} from '../../helpers/hasuraapi';
import AuthContext from '../../context/AuthContext';
import { PopUp } from '../Modal/Modal';
import Loader, { LoaderPage } from '../Loader/loader';
import ErrorPage from '../Error/ErrorPage';
import { editSettings } from '../Redux/settingsReducer';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import { ToStringFormat } from 'helpers/date';

export default function NewSettingComponent() {
    const classes = SettingStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const mycontext = useContext(AuthContext);
    const [orgName, setOrgName] = React.useState('');
    // const [image, setImage] = React.useState<any>(null);
    const [dateFormat, setDateFormat] = React.useState('DD-MM-YYYY');
    const dateFormats: string[] = [
        'DD-MM-YYYY',
        'YYYY-MM-DD',
        'MM-DD-YYYY',
        'Do MMMM YYYY',
        'D MMMM YYYY',
        'MMMM Do YYYY',
    ];
    const [workingHours, setWorkingHours] = React.useState('5 Hours');
    const [logoOrName, setLogoOrName] = React.useState('orgname');
    const [empOrMem, setEmpOrMem] = React.useState('employee');
    // const [isImage, setIsImage] = React.useState<boolean>(false);
    const [workWeek, setWorkWeek] = React.useState({
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: false,
        saturday: false,
        sunday: false,
    });
    const [approvers, setApprovers] = React.useState('approvers');
    const [othersField, setOthersField] = React.useState(true);
    const [otherApprover, setOtherApprover] = React.useState('');
    const [otherTeam, setOtherTeam] = React.useState('');
    const [teams, setTeams] = React.useState('teams');
    const [otherNameField, setOtherNameField] = React.useState(true);
    const [approverInfoModal, setApproverInfoModal] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [alert, setAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const settings: any = useSelector((state: any) => state.settings);
    const [isUpdating, setIsUpdating] = React.useState(false);
    // const editFocus = useRef<any>();
    // const focusInput = () => {
    //     editFocus.current.click();
    // };

    const convertToHour = (value: string): number => {
        const num: any = value.split(' ');
        const ans: number = parseInt(num[0]);
        return ans;
    };

    useEffect(() => {
        const getOrganizationSettings = async () => {
            if (settings.status === 'success') {
                const setting = settings.settings;
                setOrgName(setting.Organization?.organizationName);
                // setImage(setting.Organization?.organizationLogo);
                setDateFormat(setting.dateFormat);
                setWorkingHours(setting.workingHours?.toString() + ' Hours');
                // setLogoOrName(setting.displayLogo ? 'orglogo' : 'orgname');
                setLogoOrName('orgName');
                setEmpOrMem(setting.employeeCalledAs === 'employee' ? 'employee' : 'teamMembers');
                setApprovers(setting.approverCalledAs === 'approver' ? 'approvers' : 'others');
                setOtherApprover(setting.approverCalledAs === 'approver' ? '' : setting.approverCalledAs);
                setOtherTeam(setting.teamsCalledAs === 'teams' ? '' : setting.teamsCalledAs);
                setTeams(setting.teamsCalledAs === 'teams' ? 'teams' : 'others');
                setOtherNameField(setting.teamsCalledAs === 'teams' ? true : false);
                setWorkWeek({
                    monday: setting?.workWeek?.includes('monday') || false,
                    tuesday: setting?.workWeek?.includes('tuesday') || false,
                    wednesday: setting?.workWeek?.includes('wednesday') || false,
                    thursday: setting?.workWeek?.includes('thursday') || false,
                    friday: setting?.workWeek?.includes('friday') || false,
                    saturday: setting?.workWeek?.includes('saturday') || false,
                    sunday: setting?.workWeek?.includes('sunday') || false,
                });
                mycontext.addOrganizationName(setting.Organization?.organizationName);
                mycontext.addTenantName(setting.Organization?.Tenant?.name);
                mycontext.addSettings(setting.Organization?.Tenant?.displayLogo);
                mycontext.updateDateFormat(setting.dateFormat);
            }
        };
        getOrganizationSettings();
    }, [settings.status]);

    useMemo(() => {
        if (settings.status === 'success') {
            const setting = settings.settings;
            setOrgName(setting.Organization?.organizationName);
            // setImage(setting.Organization?.organizationLogo);
            // if (setting.Organization?.organizationLogo) {
            //     setIsImage(true);
            // }
            // mycontext.addOrganizationLogo(setting.Organization?.organizationLogo);
            setDateFormat(setting.dateFormat);
            setWorkingHours(setting.workingHours?.toString() + ' Hours');
            // setLogoOrName(setting.displayLogo ? 'orglogo' : 'orgname');
            setLogoOrName('orgname');
            setEmpOrMem(setting.employeeCalledAs === 'employee' ? 'employee' : 'teamMembers');
            setApprovers(setting.approverCalledAs === 'approver' ? 'approvers' : 'others');
            setOtherApprover(setting.approverCalledAs === 'approver' ? '' : setting.approverCalledAs);
            setOtherTeam(setting.teamsCalledAs === 'teams' ? '' : setting.teamsCalledAs);
            setTeams(setting.teamsCalledAs === 'teams' ? 'teams' : 'others');
            setOtherNameField(setting.teamsCalledAs === 'teams' ? true : false);
            setWorkWeek({
                monday: setting?.workWeek?.includes('monday') || false,
                tuesday: setting?.workWeek?.includes('tuesday') || false,
                wednesday: setting?.workWeek?.includes('wednesday') || false,
                thursday: setting?.workWeek?.includes('thursday') || false,
                friday: setting?.workWeek?.includes('friday') || false,
                saturday: setting?.workWeek?.includes('saturday') || false,
                sunday: setting?.workWeek?.includes('sunday') || false,
            });
            mycontext.addOrganizationName(setting.Organization?.organizationName);
            mycontext.addTenantName(setting.Organization?.Tenant?.name);
            mycontext.addSettings(setting.Organization?.organizationName);
            mycontext.updateDateFormat(setting.dateFormat);
        }
    }, [settings?.settings]);

    const resetSettings = async () => {
        if (settings.status === 'success') {
            const setting = settings.settings;
            // setIsImage(false);
            setOrgName(setting.Organization?.organizationName);
            // setImage(setting.Organization?.organizationLogo);
            setDateFormat(setting.dateFormat);
            setWorkingHours(setting.workingHours?.toString() + ' Hours');
            setLogoOrName(setting.displayLogo ? 'orglogo' : 'orgname');
            setEmpOrMem(setting.employeeCalledAs === 'employee' ? 'employee' : 'teamMembers');
            setApprovers(setting.approverCalledAs === 'approver' ? 'approvers' : 'others');
            setOtherApprover(setting.approverCalledAs === 'approver' ? '' : setting.approverCalledAs);
            setOtherTeam(setting.teamsCalledAs === 'teams' ? '' : setting.teamsCalledAs);
            setTeams(setting.teamsCalledAs === 'teams' ? 'teams' : 'others');
            setOthersField(!othersField);
            setOtherNameField(setting.teamsCalledAs === 'teams' ? true : false);
            setWorkWeek({
                monday: setting?.workWeek.includes('monday') || false,
                tuesday: setting?.workWeek.includes('tuesday') || false,
                wednesday: setting?.workWeek.includes('wednesday') || false,
                thursday: setting?.workWeek.includes('thursday') || false,
                friday: setting?.workWeek.includes('friday') || false,
                saturday: setting?.workWeek.includes('saturday') || false,
                sunday: setting?.workWeek.includes('sunday') || false,
            });
        }
    };

    const updateSettings = async () => {
        // let url: any;
        const setting = settings.settings;
        try {
            // if (image === null && logoOrName === 'orglogo') {
            //     throw new Error('Please select image');
            // }
            if (!orgName) {
                throw new Error('Organisation name is empty, please enter your organisation name');
            }
            setIsUpdating(true);
            const arr: any = [];
            if (workWeek.monday) {
                arr.push('monday');
            }
            if (workWeek.tuesday) {
                arr.push('tuesday');
            }
            if (workWeek.wednesday) {
                arr.push('wednesday');
            }
            if (workWeek.thursday) {
                arr.push('thursday');
            }
            if (workWeek.friday) {
                arr.push('friday');
            }
            if (workWeek.saturday) {
                arr.push('saturday');
            }
            if (workWeek.sunday) {
                arr.push('sunday');
            }
            const data = {
                organizationId: mycontext.organizationId,
                token: mycontext.token,
                organizationName: orgName,
                dateFormat: dateFormat,
                workingHours: convertToHour(workingHours),
                displayLogo: logoOrName === 'orglogo' ? true : false,
                employeeCalledAs: empOrMem,
                approverCalledAs: approvers === 'approvers' ? 'approver' : otherApprover,
                teamsCalledAs: teams === 'teams' ? 'teams' : otherTeam === '' ? 'Groups' : otherTeam,
                workWeek: arr,
                subscriptionPlan: '',
            };

            const orgData = {
                organizationId: mycontext.organizationId,
                token: mycontext.token,
                organizationName: orgName,
            };
            await updateOrganizationSettings(data);
            await updateOrganization(orgData);
            await updateOrganizationPendingAction(mycontext.organizationId, mycontext.token);
            setIsUpdating(false);
            mycontext.addOrganizationName(orgName);
            mycontext.addSettings(logoOrName === 'orglogo' ? true : false);
            mycontext.addOtherSettings({
                approverCalledAs: approvers === 'approvers' ? 'approver' : otherApprover,
                employeeCalledAs: empOrMem,
                teamsCalledAs: teams === 'teams' ? 'teams' : otherTeam === '' ? 'Groups' : otherTeam,
                workingHours: convertToHour(workingHours),
            });
            mycontext.updateDateFormat(dateFormat);
            if (teams === 'teams') {
                setOtherTeam('');
            }
            dispatch(
                editSettings({
                    approverCalledAs: approvers === 'approvers' ? 'approver' : otherApprover,
                    dateFormat: dateFormat,
                    displayLogo: logoOrName === 'orglogo' ? true : false,
                    employeeCalledAs: empOrMem,
                    subscriptionPlan: '',
                    teamsCalledAs: teams === 'teams' ? 'teams' : otherTeam === '' ? 'Groups' : otherTeam,
                    workWeek: arr,
                    workingHours: convertToHour(workingHours),
                    settingId: setting.settingId,
                    organizationId: mycontext.organizationId,
                    Organization: {
                        organizationName: orgName,
                        // organizationLogo: isImage ? url : setting.Organization.organizationLogo,
                    },
                }),
            );
            // setIsImage(false);
            setAlert(true);
            setAlertMessage('Settings updated successfully!');
            setSeverity('success');
        } catch (err) {
            setAlert(true);
            setSeverity('error');
            if (err.message) {
                setAlertMessage(err.message);
            } else {
                setAlertMessage('Failed to update settings!');
            }
        }
    };

    const handleChangeApprover = (event: React.ChangeEvent<{ value: unknown }>) => {
        setIsDisabled(false);
        setOtherApprover(event.target.value as string);
    };

    const handleChangeTeam = (event: any) => {
        setIsDisabled(false);
        setOtherTeam(event.target.value as string);
    };

    const handleOrgNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setIsDisabled(false);
        setOrgName(event.target.value as string);
    };
    // console.log('Image1=', image);
    // const handleFile = (image: any) => {
    //     if (
    //         image.type === 'image/jpg' ||
    //         image.type === 'image/png' ||
    //         image.type === 'image/gif' ||
    //         image.type === 'image/jpeg'
    //     ) {
    //         setIsDisabled(false);
    //         // setIsImage(true);
    //         // setImage(URL.createObjectURL(image));
    //         const reader = new FileReader();
    //         reader.readAsDataURL(image);
    //         reader.onload = () => {
    //             setImage(reader.result);
    //         };
    //     } else {
    //         setAlert(true);
    //         setAlertMessage('Images must be of type GIFs, JPGs/JPEGs or PNGs.');
    //         setSeverity('error');
    //     }
    // };

    const handleDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setIsDisabled(false);
        setDateFormat(event.target.value as string);
    };

    const handleHoursChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setIsDisabled(false);
        setWorkingHours(event.target.value as string);
    };

    const handleWorkWeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDisabled(false);
        setWorkWeek({ ...workWeek, [event.target.name]: event.target.checked });
    };

    // const handleLogoOrNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setIsDisabled(false);
    //     setLogoOrName((event.target as HTMLInputElement).value);
    // };

    const handleEmpOrMemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDisabled(false);
        setEmpOrMem((event.target as HTMLInputElement).value);
    };

    const handleApproversChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDisabled(false);
        setApprovers(event.target.value);
        setOthersField(!othersField);
    };

    const handleTeamsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDisabled(false);
        setTeams(event.target.value);
        setOtherNameField(event.target.value === 'teams' ? true : false);
    };

    const approverInfo = () => {
        setIsDisabled(false);
        setApproverInfoModal(true);
    };

    if (settings.status === 'loading') return <LoaderPage />;
    if (settings.status === 'failed') return <ErrorPage msg="Something went wrong!" />;
    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        <SubscriptionHardExpired
            content="Looks like your subscription is expired, Please renew it !"
            showRenew={true}
            fromSettings={true}
        />
    ) : (
        <div>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <Loader isUpdating={isUpdating} />
            <Modal open={approverInfoModal}>
                <Paper elevation={0} className={classes.approverInfoModal}>
                    <Grid container>
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <Grid
                                item
                                xs={6}
                                style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '10px' }}
                            >
                                <FormLabel className={classes.approverInfoTitle}>Approvers</FormLabel>
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CloseIcon onClick={() => setApproverInfoModal(false)} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginLeft: '10px', marginTop: '12px' }}>
                            <FormLabel
                                style={{ color: theme.palette.text.primary, lineHeight: '22px', fontWeight: 600 }}
                            >
                                An approver for a team has the authority to:{' '}
                            </FormLabel>
                        </Grid>
                        <Grid style={{ marginTop: '18px', marginLeft: '20px' }}>
                            <Grid item xs={12} className={classes.approverInfoGrid}>
                                <FormLabel>
                                    <ul>
                                        <li style={{ lineHeight: '22px', color: theme.palette.text.primary }}>
                                            Approve/Deny time-off requests for the team(s) assigned to them.
                                        </li>
                                    </ul>
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} className={classes.approverInfoGrid}>
                                <FormLabel>
                                    <ul>
                                        <li style={{ lineHeight: '22px', color: theme.palette.text.primary }}>
                                            Create a new team with the existing team members within the assigned
                                            team(s).
                                        </li>
                                    </ul>
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12} className={classes.approverInfoGrid}>
                                <FormLabel>
                                    <ul>
                                        <li style={{ lineHeight: '22px', color: theme.palette.text.primary }}>
                                            Add/Remove/Edit teams members for the assigned team(s).
                                        </li>
                                    </ul>
                                </FormLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
            <div className={classes.newDivStyle}>
                <div className={classes.borderDiv}>
                    <Grid item xs={12} className={classes.firstField}>
                        <Grid item xs={12}>
                            <FormLabel className={classes.formLabelStyle}>Name of the department</FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                disabled={true}
                                placeholder="e.g: Apple.Inc"
                                size="small"
                                value={orgName}
                                className={classes.inputStyle}
                                onChange={handleOrgNameChange}
                                id="organisationName"
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} className={classes.orgLogo}>
                        <Grid item xs={12}>
                            <FormLabel className={classes.formLabelStyle}>Organization logo</FormLabel>
                        </Grid>
                        <div className={classes.orgLogoBoxStyle}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Grid item xs={10} className={classes.imagePreviewStyle}>
                                    {image === null ? (
                                        <div>
                                            <h5
                                                style={{
                                                    color: '#AAAAAA',
                                                    letterSpacing: '0.03em',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Add your organization&apos;s logo
                                            </h5>
                                        </div>
                                    ) : (
                                        <img src={image} alt="Uploaded logo" />
                                    )}
                                </Grid>
                                <Grid item xs={2} className={classes.editIconStyle}>
                                    <label onClick={focusInput}>
                                        <EditIcon fontSize="small" style={{ color: '#666666', cursor: 'pointer' }} />
                                    </label>
                                    <input
                                        type="file"
                                        ref={editFocus}
                                        id="myInput"
                                        accept="image/*"
                                        placeholder="choose a file"
                                        color="primary"
                                        className={classes.inputButton}
                                        hidden
                                        onChange={(e: any) => handleFile(e.target.files[0])}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid> */}
                    <Grid item xs={12} className={classes.dateFormatGrid}>
                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Grid item xs={12}>
                                    <FormLabel className={classes.formLabelStyle}>Date format</FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" style={{ width: '68%' }}>
                                        <Select
                                            value={dateFormat}
                                            className={classes.inputSelectStyle}
                                            onChange={handleDateChange}
                                            id="dateFormat"
                                        >
                                            {dateFormats.map((format: string, i: number) => (
                                                <MenuItem value={format} id={format} key={i}>
                                                    {ToStringFormat(new Date(), format)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6} className={classes.workingHoursGrid}>
                                <Grid item xs={12}>
                                    <FormLabel className={classes.formLabelStyle}>Working hours</FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" style={{ width: '68%' }}>
                                        <Select
                                            value={workingHours}
                                            className={classes.inputSelectStyle}
                                            onChange={handleHoursChange}
                                            id="workingHours"
                                        >
                                            <MenuItem value={'3 Hours'} id="H3">
                                                3 Hours
                                            </MenuItem>
                                            <MenuItem value={'4 Hours'} id="H4">
                                                4 Hours
                                            </MenuItem>
                                            <MenuItem value={'5 Hours'} id="H5">
                                                5 Hours
                                            </MenuItem>
                                            <MenuItem value={'6 Hours'} id="H6">
                                                6 Hours
                                            </MenuItem>
                                            <MenuItem value={'7 Hours'} id="H7">
                                                7 Hours
                                            </MenuItem>
                                            <MenuItem value={'8 Hours'} id="H8">
                                                8 Hours
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.workWeekGrid}>
                        <Grid item xs={12}>
                            <FormLabel className={classes.formLabelStyle}>Workweek</FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup row>
                                <Grid item xs={6} lg={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="monday"
                                                id="monday"
                                                color="primary"
                                                checked={workWeek.monday}
                                                onChange={handleWorkWeekChange}
                                            />
                                        }
                                        label="Monday"
                                        className={classes.checkBoxLabelStyle}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="tuesday"
                                                id="tuesday"
                                                color="primary"
                                                checked={workWeek.tuesday}
                                                onChange={handleWorkWeekChange}
                                            />
                                        }
                                        label="Tuesday"
                                        className={classes.checkBoxLabelStyle}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="wednesday"
                                                id="wednesday"
                                                color="primary"
                                                checked={workWeek.wednesday}
                                                onChange={handleWorkWeekChange}
                                            />
                                        }
                                        label="Wednesday"
                                        className={classes.checkBoxLabelStyle}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="thursday"
                                                id="thursday"
                                                color="primary"
                                                checked={workWeek.thursday}
                                                onChange={handleWorkWeekChange}
                                            />
                                        }
                                        label="Thursday"
                                        className={classes.checkBoxLabelStyle}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="friday"
                                                id="friday"
                                                color="primary"
                                                checked={workWeek.friday}
                                                onChange={handleWorkWeekChange}
                                            />
                                        }
                                        label="Friday"
                                        className={classes.checkBoxLabelStyle}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="saturday"
                                                id="saturday"
                                                color="primary"
                                                checked={workWeek.saturday}
                                                onChange={handleWorkWeekChange}
                                            />
                                        }
                                        label="Saturday"
                                        className={classes.checkBoxLabelStyle}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="sunday"
                                                id="sunday"
                                                color="primary"
                                                checked={workWeek.sunday}
                                                onChange={handleWorkWeekChange}
                                            />
                                        }
                                        label="Sunday"
                                        className={classes.checkBoxLabelStyle}
                                    />
                                </Grid>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>
                {/* <Hidden mdDown>
                        <Grid item xs={1} className={classes.middleGrid}>
                            <Divider
                                orientation="vertical"
                                style={{ height: '95%', backgroundColor: theme.palette.primary.contrastText }}
                            />
                        </Grid>
                    </Hidden> */}
                <div className={classes.newInnerDiv}>
                    {/* <Grid item xs={12} className={classes.firstFieldThirdGrid}>
                        <Grid item xs={12}>
                            <FormLabel className={classes.formLabelStyle}>
                                What should be displayed on the navigation bar?
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup row value={logoOrName} onChange={handleLogoOrNameChange}>
                                <Grid item xs={12} lg={6}>
                                    <FormControlLabel
                                        value="orgname"
                                        control={<Radio color="primary" id="orgName" />}
                                        label={
                                            <FormLabel className={classes.radioNameStyle}>Organization Name</FormLabel>
                                        }
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControlLabel
                                        value="orglogo"
                                        control={<Radio color="primary" id="orgLogo" />}
                                        label={
                                            <FormLabel className={classes.radioNameStyle}>Organization Logo</FormLabel>
                                        }
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    </Grid> */}
                    <Grid item className={classes.otherFields}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <FormLabel className={classes.formLabelStyle}>
                                    How would you like to address your employees?
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup row value={empOrMem} onChange={handleEmpOrMemChange}>
                                    <Grid item xs={12} lg={6}>
                                        <FormControlLabel
                                            value="employee"
                                            control={<Radio color="primary" id="employees" />}
                                            label={<FormLabel className={classes.radioNameStyle}>Employees</FormLabel>}
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <FormControlLabel
                                            value="teamMembers"
                                            control={<Radio color="primary" id="teamMembers" />}
                                            label={
                                                <FormLabel className={classes.radioNameStyle}>Team Members</FormLabel>
                                            }
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    {mycontext.features?.isApproverCalledAs ? (
                        <Grid item className={classes.otherFields}>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <FormLabel className={classes.formLabelStyle}>
                                        How would you like to address your approvers ?
                                        <Tooltip title="Click to know more" placement="top" onClick={approverInfo}>
                                            <div style={{ display: 'inline' }}>
                                                <InfoIcon
                                                    style={{
                                                        transform: 'scale(0.7)',
                                                        color: '#3C48B5',
                                                        cursor: 'pointer',
                                                        marginBottom: '2px',
                                                    }}
                                                />
                                                {/* <img
                                                src={InfoIcon}
                                                alt="infoIcon"
                                                style={{ paddingLeft: '10px', marginTop: '-5px' }}
                                            /> */}
                                            </div>
                                        </Tooltip>
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup
                                        row
                                        value={approvers}
                                        className={classes.radioGroupStyles}
                                        onChange={handleApproversChange}
                                    >
                                        <Grid item xs={12} lg={6}>
                                            <FormControlLabel
                                                value="approvers"
                                                control={<Radio name="approvers" color="primary" id="approvers" />}
                                                label={
                                                    <FormLabel className={classes.radioNameStyle}>Approvers</FormLabel>
                                                }
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <FormControlLabel
                                                value="others"
                                                control={<Radio name="others" color="primary" id="otherApprover" />}
                                                label={
                                                    <TextField
                                                        variant="outlined"
                                                        disabled={othersField}
                                                        // inputRef={input => {
                                                        //     if (input != null) {
                                                        //         input.focus();
                                                        //     }
                                                        // }}
                                                        value={otherApprover}
                                                        className={
                                                            othersField === false
                                                                ? classes.activeinput
                                                                : classes.inputStyle
                                                        }
                                                        size="small"
                                                        placeholder="e.g: Managers"
                                                        onChange={handleChangeApprover}
                                                        id="otherApproverInput"
                                                    />
                                                }
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid item className={classes.lastField}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <FormLabel className={classes.formLabelStyle}>
                                    How would you like to address your teams?
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    row
                                    value={teams}
                                    className={classes.radioGroupStyles}
                                    onChange={handleTeamsChange}
                                >
                                    <Grid item xs={12} lg={6}>
                                        <FormControlLabel
                                            value="teams"
                                            control={<Radio name="teams" color="primary" id="teams" />}
                                            label={<FormLabel className={classes.radioNameStyle}>Teams</FormLabel>}
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <FormControlLabel
                                            value="others"
                                            control={<Radio name="others" color="primary" id="others" />}
                                            label={
                                                <TextField
                                                    variant="outlined"
                                                    disabled={otherNameField}
                                                    inputProps={{ maxLength: 12 }}
                                                    // inputRef={input => {
                                                    //     if (input != null) {
                                                    //         input.focus();
                                                    //     }
                                                    // }}
                                                    value={otherTeam}
                                                    size="small"
                                                    className={
                                                        otherNameField === false
                                                            ? classes.activeinput
                                                            : classes.inputStyle
                                                    }
                                                    placeholder="e.g: Groups"
                                                    onChange={handleChangeTeam}
                                                    id="othersInput"
                                                />
                                            }
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.newButtonGroup}>
                        {/* <Grid item xs={12} lg={6} className={classes.passwordChangeButtonGrid}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={`${classes.buttonStyle} ${classes.passwordChangeButton}`}
                                    onClick={passwordChange}
                                >
                                    Password Change
                                </Button>
                            </Grid> */}
                        <Grid item xs={6} lg={3} className={classes.resetButtonGrid}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={`${classes.buttonStyle} ${classes.resetButton}`}
                                onClick={() => resetSettings()}
                                disabled={isDisabled}
                                id="settingsReset"
                                style={{ border: `1px solid ${theme.palette.text.secondary}` }}
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            lg={3}
                            style={{ display: 'flex', justifyContent: 'flex-end', alignSelf: 'flex-end' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={isDisabled ? classes.resetButton2 : classes.updateButtonStyle}
                                style={{ width: '100%', outline: 'none', fontSize: '13px' }}
                                onClick={() => updateSettings()}
                                id="settingsUpdate"
                                disabled={isDisabled}
                            >
                                Update
                            </Button>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
}
