import AuthContext from 'context/AuthContext';
import React, { useContext } from 'react';
import { QuickTourStyles } from './quickTourStyle';

export default function TourData(data: string) {
    const classes = QuickTourStyles();
    const mycontext = useContext(AuthContext);
    const AdminData = [
        {
            target: 'body',
            placement: 'center',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Learn about Teamplus</h6>
                    <p className={classes.content}>
                        To help you get started, we&apos;ve added a few steps to that will help you set up your
                        organization quickly. In case you need help, we&apos;re just one email away at
                        support@udyamo.com
                    </p>
                </div>
            ),
        },
        {
            target: '#Dashboard',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Teamplus Dashboard</h6>
                    <p className={classes.content}>
                        Dashboard in Teamplus is designed to keep you up to date with all the latest activities in your
                        organization.
                    </p>
                </div>
            ),
            // style: {
            //   backgroundColor: '#bada55',
            // },
        },
        {
            target: '#' + mycontext.teamsCalledAs?.replace(' ', ''),
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>View and Update teams</h6>
                    <p className={classes.content}>
                        You can view teams in your organization or you can create/update teams.
                    </p>
                </div>
            ),
        },
        {
            target: '#create_new_team',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Create Teams and Assign Approvers</h6>
                    <p className={classes.content}>
                        You can create teams and add members to it. You can also add approvers, who along with admin has
                        permissions to approve/deny time-off requests.
                    </p>
                </div>
            ),
        },
        {
            target: mycontext.employeeCalledAs === 'teamMembers' ? '#TeamMembers' : '#Employees',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>View Employees</h6>
                    <p className={classes.content}>
                        You can view employees of your organization and view their profile.
                    </p>
                </div>
            ),
        },
        {
            target: '#sendAnInvite',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Sync Employees</h6>
                    <p className={classes.content}>
                        You can sync your employees from Slack/MS Teams to your TeamPlus account.
                    </p>
                </div>
            ),
        },
        {
            target: '#Calendar',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>View Calendar</h6>
                    <p className={classes.content}>You can view all time-offs and holidays of your organization.</p>
                </div>
            ),
        },
        {
            target: '#Settings',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>All Settings</h6>
                    <p className={classes.content}>
                        We always recommend you to start your Teamplus journey by configuring your organization
                        settings.
                    </p>
                </div>
            ),
        },
        {
            target: '#holidays',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Organization Holidays</h6>
                    <p className={classes.content}>
                        You&apos;ll can import holidays from a list of preset holidays as well as add your own holidays
                        manually.
                    </p>
                </div>
            ),
        },
        {
            target: '#timeOffPolicies',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Create Time-off Policies</h6>
                    <p className={classes.content}>
                        In addition to the default time-off categories, you can create your own categories and configure
                        it as per your ease.
                    </p>
                </div>
            ),
        },
        {
            target: '#standups',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Standup setting</h6>
                    <p className={classes.content}>
                        You can configure your standup details for your organization here.
                    </p>
                </div>
            ),
        },
        // {
        //     target: '#Plugins',
        //     disableBeacon: true,
        //     content: (
        //         <div>
        //             <h6 className={classes.heading}>Integrate Plugins</h6>
        //             <p className={classes.content}>
        //                 Integration of TeamPlus organization with your Slack/MS Teams is just a single click away. This
        //                 integration can help you use all the Teamplus features.
        //             </p>
        //         </div>
        //     ),
        // },
        {
            target: '#Reports',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Teamplus Reports</h6>
                    <p className={classes.content}>
                        Reports in Teamplus helps you download the time-offs related in csv format.
                    </p>
                </div>
            ),
        },
    ];

    const EmployeeData = [
        {
            target: 'body',
            placement: 'center',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Learn about Teamplus</h6>
                    <p className={classes.content}>
                        To help you get started, we&apos;ve added a few steps to that will help you set up your
                        organization quickly. In case you need help, we&apos;re just one email away at
                        support@udyamo.com
                    </p>
                </div>
            ),
        },
        {
            target: '#Dashboard',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Teamplus Dashboard</h6>
                    <p className={classes.content}>
                        Dashboard in Teamplus is designed to keep you up to date with all of your latest activities.
                    </p>
                </div>
            ),
        },
        {
            target: '#holidayList',
            disableBeacon: true,

            content: (
                <div>
                    <h6 className={classes.heading}>Holiday List</h6>
                    <p className={classes.content}>You can view holidays of your organization.</p>
                </div>
            ),
        },
        {
            target: '#apply',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>Apply Time-Off</h6>
                    <p className={classes.content}>
                        You can apply timeoff for yourself as well as for other employees.
                    </p>
                </div>
            ),
        },
        {
            target: '#' + mycontext.teamsCalledAs?.replace(' ', ''),
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>View teams</h6>
                    <p className={classes.content}>You can view teams of your organization</p>
                </div>
            ),
        },
        {
            target: mycontext.employeeCalledAs === 'teamMembers' ? '#TeamMembers' : '#Employees',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>View Employees</h6>
                    <p className={classes.content}>
                        You can view employees of your organization and view their profile.
                    </p>
                </div>
            ),
        },
        {
            target: '#Calendar',
            disableBeacon: true,
            content: (
                <div>
                    <h6 className={classes.heading}>View Calendar</h6>
                    <p className={classes.content}>You can view all time-offs and holidays of your organization.</p>
                </div>
            ),
        },
    ];

    if (data === 'admin') {
        return AdminData;
    } else {
        return EmployeeData;
    }
}
