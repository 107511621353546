import React, { useState } from 'react';
import { useStyles } from './chatboxStyle';
import ChatImg from './chat.png';
import Addmore from './addmore.png';
import { Popover, MenuItem } from '@material-ui/core';
import Admin from './adminM.png';
import Employee from './employeeM.png';
import Approver from './approverM.png';
import { Link } from 'react-router-dom';

export type ChatText = {
    text?: string;
};
export function Chatbox({ text }: ChatText) {
    const classes = useStyles();
    return (
        <div className={classes.chatBtn}>
            <img src={ChatImg} alt="chaticon" className={classes.chatIcon} />
            {text}
        </div>
    );
}

export function AddMoreMobile() {
    const classes = useStyles();
    // const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const openMenu = Boolean(anchorEl);
    return (
        <>
            <Popover
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                classes={{ paper: classes.popover }}
            >
                <Link to="/employees" style={{ textDecoration: 'none' }} onClick={() => setAnchorEl(null)}>
                    <MenuItem className={classes.menuItem}>
                        <div className={classes.label}>
                            <p className={classes.text}>Employee</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={Employee} alt="Employee" className={classes.iconImg} />
                        </div>
                    </MenuItem>
                </Link>
                <Link to="/teams" style={{ textDecoration: 'none' }} onClick={() => setAnchorEl(null)}>
                    <MenuItem className={classes.menuItem}>
                        <div className={classes.label}>
                            <p className={classes.text}>Approver</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={Approver} alt="Approver" className={classes.iconImg} />
                        </div>
                    </MenuItem>
                </Link>
                <Link to="/employees" style={{ textDecoration: 'none' }} onClick={() => setAnchorEl(null)}>
                    <MenuItem className={classes.menuItem}>
                        <div className={classes.label}>
                            <p className={classes.text}>Admin</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={Admin} alt="Admin" className={classes.iconImg} />
                        </div>
                    </MenuItem>
                </Link>
                {/* <Link
                    to={{
                        pathname: '/plugins',
                        state: { value: 3 },
                    }}
                    style={{ textDecoration: 'none' }}
                    onClick={() => setAnchorEl(null)}
                >
                    <MenuItem className={classes.menuItem}>
                        <div className={classes.label}>
                            <p className={classes.text}>Add To Slack</p>
                        </div>
                        <div className={classes.icon}>
                            <span
                                className="iconify"
                                data-icon="ph:slack-logo"
                                data-width="24"
                                data-height="24"
                                style={{
                                    color: `${theme.palette.primary.main}`,
                                }}
                            ></span>
                        </div>
                    </MenuItem>
                </Link> */}
            </Popover>
            <div className={classes.mobileAddMore} onClick={(e: any) => setAnchorEl(e.currentTarget)}>
                <img src={Addmore} alt="chaticon" className={classes.addMoreIcon} />
            </div>
            <div className={openMenu ? classes.backgroundDiv : classes.hiddenDiv}></div>
        </>
    );
}
