// import React from 'react';
import React, { useContext } from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    // MenuItem,
    Hidden,
    // Button,
    // Popover,
    // Paper,
    useTheme,
    // Tooltip,
    // Avatar,
} from '@material-ui/core';
import { useStyles, leaveTableStyle } from './employeeProfileStyles';
import { withStyles } from '@material-ui/core/styles';
// import { withStyles, Theme } from '@material-ui/core/styles';
import upcomingTimeOff from '../../Assets/images/upcomingTimeoff.png';
// import {
// convertTimeInTZ,
//     ToStringFormat,
// } from '../../helpers/date';
// import { Icon } from '@iconify/react';
import { isoToLocal } from '../../helpers/timezone';
import AuthContext from '../../context/AuthContext';
import { DisplayDate2, DisplayTime2 } from 'components/DashboardPage/DashboardPart';

const StyledTableCell = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: 600,
    },
    body: {
        fontSize: '12px',
    },
}))(TableCell);

interface LeaveHistory {
    upcomingLeaves: any;
}

export default function LeaveHistoryOther(props: LeaveHistory) {
    const classes = useStyles();
    const theme = useTheme();
    const classes2 = leaveTableStyle();
    const mycontext = useContext(AuthContext);

    return (
        <div style={{}}>
            <Grid container>
                <Hidden only={['md', 'lg', 'xl']}>
                    <div style={{}}>
                        {props.upcomingLeaves.timeoffdb_Leaves.length > 0 ? (
                            props.upcomingLeaves.timeoffdb_Leaves.map((row: any, index: number) => (
                                <Grid
                                    container
                                    className={classes.gridCommonStyle1}
                                    style={{ padding: '5%', marginBottom: '1%' }}
                                    key={index}
                                >
                                    <Grid item xs={12} className={classes.commonStyle2}>
                                        <Typography className={classes.leaveHistoryCardTitle}>Date & Time</Typography>
                                    </Grid>
                                    {/* <Grid item xs={6} className={classes.commonStyle2}>
                                                    <Typography className={classes.leaveHistoryCardTitle}>Time</Typography>
                                                </Grid> */}
                                    <Grid item xs={12} className={classes.commonStyle2}>
                                        {/* {row.startDate === row.endDate ? (
                                            <Typography className={classes2.leaveTableCards}>
                                                {ToStringFormat(row.startDate, mycontext.dateFormat)}
                                            </Typography>
                                        ) : (
                                            <Typography className={classes2.leaveTableCards}>
                                                {ToStringFormat(row.startDate, mycontext.dateFormat)} to{' '}
                                                {ToStringFormat(row.endDate, mycontext.dateFormat)}
                                            </Typography>
                                        )} */}
                                        {parseInt(row.noOfHours) < mycontext.workingHours ? (
                                            <DisplayTime2
                                                startTime={isoToLocal(row?.isoString?.startTime, mycontext.country)
                                                    .toTimeString()
                                                    .split(' ')[0]
                                                    .substring(0, 5)}
                                                endTime={isoToLocal(row.isoString?.endTime, mycontext.country)
                                                    .toTimeString()
                                                    .split(' ')[0]
                                                    .substring(0, 5)}
                                                startDate={isoToLocal(row.isoString?.startTime, mycontext.country)}
                                                endDate={isoToLocal(row.isoString?.endTime, mycontext.country)}
                                                dateFormat={mycontext.dateFormat}
                                                gmt={row.gmt}
                                            />
                                        ) : (
                                            <DisplayDate2
                                                startDate={isoToLocal(row?.isoString?.startTime, mycontext.country)}
                                                endDate={isoToLocal(row?.isoString?.endTime, mycontext.country)}
                                                startTime={isoToLocal(row?.isoString?.startTime, mycontext.country)
                                                    .toTimeString()
                                                    .split(' ')[0]
                                                    .substring(0, 5)}
                                                endTime={isoToLocal(row?.isoString?.endTime, mycontext.country)
                                                    .toTimeString()
                                                    .split(' ')[0]
                                                    .substring(0, 5)}
                                                noOfHours={row?.noOfHours}
                                                dateFormat={mycontext.dateFormat}
                                                gmt={row?.gmt}
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={12} className={classes.commonStyle1} style={{ paddingTop: '5%' }}>
                                        {/* <Grid item xs={6}>
                                            <Typography className={classes.leaveHistoryCardTitle}>Time</Typography>
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <Typography className={classes.leaveHistoryCardTitle}>
                                                Time-off Type
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.commonStyle1}>
                                        {/* <Grid item xs={6}>
                                            <Typography className={classes2.leaveTableCards}>
                                                {row.startTime === 'null' ? (
                                                    <Icon icon="bi:dash" width="25" height="25" />
                                                ) : (
                                                    <Typography className={classes2.leaveTableCards}>
                                                        {convertTimeInTZ(row.startTime, row?.gmt)} to{' '}
                                                        {convertTimeInTZ(row.endTime, row.gmt)}
                                                    </Typography>
                                                )}
                                            </Typography>
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classes2.leaveTableCards}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                {row.category}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <Grid
                                container
                                // className={classes.mobileUICardTheme}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: theme.palette.background.paper,

                                    minWidth: '96vw',
                                }}
                            >
                                <img src={upcomingTimeOff} alt="Paper" style={{ height: '100px', width: '100px' }} />
                                <Typography
                                    style={{
                                        fontSize: '13px',
                                        letterSpacing: '0.03em',
                                        color: theme.palette.text.primary,
                                        fontWeight: theme.typography.fontWeightBold,
                                    }}
                                >
                                    No time-off(s) found
                                </Typography>
                            </Grid>
                        )}
                    </div>
                </Hidden>
            </Grid>

            <Hidden only={['xs', 'sm']}>
                <div style={{ height: '55vh', margin: '1%', marginTop: '5%' }}>
                    <TableContainer className={classes.tableScrollbar}>
                        <Table stickyHeader aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">#</StyledTableCell>
                                    <StyledTableCell align="justify">Date & Time</StyledTableCell>
                                    {/* <StyledTableCell align="justify">Time</StyledTableCell> */}
                                    <StyledTableCell align="justify">Time-Off Type</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.upcomingLeaves.timeoffdb_Leaves.length > 0 ? (
                                    props.upcomingLeaves.timeoffdb_Leaves.map((row: any, i: number) => (
                                        <TableRow key={i} className={classes.leaveHistoryTableHover}>
                                            <StyledTableCell
                                                className={classes.tableCellStyle}
                                                align="center"
                                                component="th"
                                                scope="row"
                                            >
                                                {i + 1}
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                {/* <Typography component={'div'} className={classes2.leaveTableColumns}> */}
                                                {/* {row.startDate === row.endDate ? (
                                                        <Typography className={classes2.leaveTableColumns}>
                                                            {ToStringFormat(row.startDate, mycontext.dateFormat)}
                                                        </Typography>
                                                    ) : (
                                                        <Typography className={classes2.leaveTableColumns}>
                                                            {ToStringFormat(row.startDate, mycontext.dateFormat)}
                                                            &nbsp;
                                                            <span
                                                                style={{
                                                                    color: theme.palette.text.primary,
                                                                    fontWeight: theme.typography.fontWeightBold,
                                                                }}
                                                            >
                                                                to
                                                            </span>
                                                            &nbsp;
                                                            {ToStringFormat(row.endDate, mycontext.dateFormat)}
                                                        </Typography>
                                                    )} */}
                                                {parseInt(row.noOfHours) < mycontext.workingHours ? (
                                                    <DisplayTime2
                                                        startTime={isoToLocal(
                                                            row?.isoString?.startTime,
                                                            mycontext.country,
                                                        )
                                                            .toTimeString()
                                                            .split(' ')[0]
                                                            .substring(0, 5)}
                                                        endTime={isoToLocal(row.isoString?.endTime, mycontext.country)
                                                            .toTimeString()
                                                            .split(' ')[0]
                                                            .substring(0, 5)}
                                                        startDate={isoToLocal(
                                                            row.isoString?.startTime,
                                                            mycontext.country,
                                                        )}
                                                        endDate={isoToLocal(row.isoString?.endTime, mycontext.country)}
                                                        dateFormat={mycontext.dateFormat}
                                                        gmt={row.gmt}
                                                    />
                                                ) : (
                                                    <DisplayDate2
                                                        startDate={isoToLocal(
                                                            row?.isoString?.startTime,
                                                            mycontext.country,
                                                        )}
                                                        endDate={isoToLocal(row?.isoString?.endTime, mycontext.country)}
                                                        startTime={isoToLocal(
                                                            row?.isoString?.startTime,
                                                            mycontext.country,
                                                        )
                                                            .toTimeString()
                                                            .split(' ')[0]
                                                            .substring(0, 5)}
                                                        endTime={isoToLocal(row?.isoString?.endTime, mycontext.country)
                                                            .toTimeString()
                                                            .split(' ')[0]
                                                            .substring(0, 5)}
                                                        noOfHours={row?.noOfHours}
                                                        dateFormat={mycontext.dateFormat}
                                                        gmt={row?.gmt}
                                                    />
                                                )}
                                                {/* </Typography> */}
                                            </StyledTableCell>
                                            {/* <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                <Typography component={'div'} className={classes2.leaveTableColumns}>
                                                    {row.startTime === 'null' ? (
                                                        <Icon icon="bi:dash" width="25" height="25" />
                                                    ) : (
                                                        <Typography className={classes2.leaveTableColumns}>
                                                            {convertTimeInTZ(row.startTime, row.gmt)}&nbsp;
                                                            <span
                                                                style={{
                                                                    color: theme.palette.text.primary,
                                                                    fontWeight: theme.typography.fontWeightRegular,
                                                                }}
                                                            >
                                                                to
                                                            </span>
                                                            &nbsp;
                                                            {convertTimeInTZ(row.endTime, row.gmt)}&nbsp;
                                                        </Typography>
                                                    )}
                                                </Typography>
                                            </StyledTableCell> */}
                                            <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                <Typography className={classes2.leaveTableColumns}>
                                                    {row.category}
                                                </Typography>
                                            </StyledTableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} style={{ textAlign: 'center', borderBottom: 'none' }}>
                                            <img
                                                src={upcomingTimeOff}
                                                alt="Paper"
                                                style={{ height: '100px', width: '100px' }}
                                            />
                                            <Typography
                                                style={{
                                                    fontSize: '13px',
                                                    letterSpacing: '0.03em',
                                                    color: theme.palette.text.primary,
                                                    fontWeight: theme.typography.fontWeightBold,
                                                }}
                                            >
                                                No time-off(s) found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Hidden>
        </div>
    );
}
