import { gql } from '@apollo/client';
export function getPendingActions(organizationId: string) {
    return gql`
        subscription PendingActions {
            timeoffdb_PendingActions(where: {organizationId: {_eq: "${organizationId}"}}) {
                createNewTeam
                organizationDetails
                pendingActionId
                timeOffPolicy
            }
        }
    `;
}
