import React, { useState, useContext, useEffect } from 'react';
import { TimeOffPolicyStyles } from './TimeOffPolicyStyle';
import { Typography, Paper, Hidden } from '@material-ui/core';
import PolicyTable from './PolicyTable';
import Switch from 'react-switch';
import SmallTimePolicyScreen from './SmallTimePolicyScreen';
// import AutoTimeff from './AutoTimeOff';
import AuthContext from '../../context/AuthContext';
import { UnlimitedDayStatus } from '../../helpers/hasuraapi';
import AddTimeOff from './AddTimeOff';
import { PopUp } from '../Modal/Modal';
// import Skeleton from '@material-ui/lab/Skeleton';
import Loader from '../Loader/loader';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { editUnlimitedDays } from '../Redux/timeOffPolicyReducer';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
// import { LoaderPage } from '../Loader/loader';
// import ErrorPage from '../Error/ErrorPage';

export default function TimeOffPolicy() {
    const classes = TimeOffPolicyStyles();
    const mycontext = useContext(AuthContext);

    const dispatch = useDispatch();

    // const [timeoff, setTimeoff] = useState<any>([]);
    // const [error, setError] = useState<boolean>(false);
    // const [errorMessage, setErrorMessage] = useState<any>('');
    // const [isLoading, setLoading] = useState<boolean>(true);
    const [unlimitedDays, setunlimitedDays] = useState(true);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertm, setAlertm] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [isUpdating, setIsUpdating] = React.useState(false);
    const state = useSelector((state: any) => state);
    const timeOffPolicy: any = state.timeOffPolicy;
    const userSubscriptionDetails = state?.subscription.userSubscriptions;
    // console.log(timeOffPolicy);
    // useEffect(() => {
    //     async function getAll() {
    //         const data = await fetchtimeoff(mycontext.organizationId, mycontext.token);
    //         if (data.Error) {
    //             setError(true);
    //             setErrorMessage(data.Error);
    //             setLoading(false);
    //         } else {
    //             setTimeoff(data);
    //             setunlimitedDays(data[0].unlimitedDays);
    //             console.log(data);
    //             setLoading(false);
    //         }
    //     }
    //     getAll();
    // }, [mycontext]);

    // const refreshPage = () => {
    //     setIsUpdating(false);
    //     window.location.reload(false);
    // };

    const checkSubscription = (userSubscriptionDetails: any) => {
        const userSubscriptionDetail = userSubscriptionDetails?.usermanagementdb_tenant_employee[0];
        try {
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('tbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('tbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.timeoffSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.timeoffSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
        } catch (err) {
            throw new Error(err.message);
        }
    };
    // console.log(unlimitedDays);
    // console.log(timeOffPolicy?.timeOffPolicy);
    useEffect(() => {
        setunlimitedDays(true);
        timeOffPolicy.timeOffPolicy.forEach((item: any) => {
            if (!item.unlimitedDays) {
                setunlimitedDays(false);
            }
        });
    }, [timeOffPolicy]);

    const handleUnlimitedDays = async () => {
        try {
            setIsUpdating(true);
            checkSubscription(userSubscriptionDetails);
            const row = {
                organizationId: mycontext.organizationId,
                unlimitedDays: !unlimitedDays,
                // daysPerYear: unlimitedDays ? 25 : 0,
                token: mycontext.token,
            };
            const data = await UnlimitedDayStatus(row);
            if (data.error) {
                throw new Error('Something went wrong, try again!');
            } else {
                dispatch(
                    editUnlimitedDays({
                        unlimitedDays: data.returning[0].unlimitedDays,
                    }),
                );
                setSeverity('success');
                setAlertm(true);
                setAlertMessage('TimeOff Changed Successfuly!');
                setIsUpdating(false);
            }
            setunlimitedDays(!unlimitedDays);
        } catch (err) {
            setSeverity('error');
            setAlertm(true);
            setAlertMessage(err.message);
        } finally {
            setIsUpdating(false);
        }
        // refreshPage();
    };

    // if (timeOffPolicy.status === 'loading') return <LoaderPage />;
    // if (timeOffPolicy.status === 'failed') return <ErrorPage msg="Something went wrong!" />;
    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        <SubscriptionHardExpired
            content="Looks like your subscription is expired, Please renew it !"
            showRenew={true}
            fromSettings={true}
        />
    ) : (
        <div>
            <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
            <Loader isUpdating={isUpdating} />
            <Paper className={classes.main} id="TimeOffPolicyPage" elevation={0}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}
                >
                    {/* <Typography className={classes.title}>Manage the Time-offs for your Organization</Typography> */}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography className={classes.para}>
                            Do you want to keep unlimited days for all time-offs ?
                        </Typography>

                        <Switch
                            checked={unlimitedDays}
                            onChange={handleUnlimitedDays}
                            // onChange={() => handleleavestatus(!timeoff.unlimitedDays)}
                            // checked={timeoff.unlimitedDays}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={18}
                            width={34}
                            onColor="#3C48B5"
                            offColor="#CCCCCC"
                            activeBoxShadow="0 0 0px 0px #CCCCCC"
                            id="unlimitedTimeOffSwitch"
                        />
                    </div>
                    {/* )} */}
                    {/* <Grid item xs={10} sm={4} style={{ marginTop: '10px' }}>
                            <Typography className={classes.para}>
                                Do you want to Auto-approve the time-offs ?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sm={1}
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignContent: 'space-around',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <AutoTimeff rows={timeoff || []} />
                        </Grid> */}
                    <AddTimeOff
                        setAlertMessage={setAlertMessage}
                        setAlertm={setAlertm}
                        setSeverity={setSeverity}
                        setIsUpdating={setIsUpdating}
                        userSubscriptionDetails={userSubscriptionDetails}
                        checkSubscription={checkSubscription}
                    />
                </div>
                {/* <div>
                    <Hidden smDown>
                        <div style={{ float: 'left', marginBottom: '14px', marginTop: '4px' }}>
                            <div className={classes.Deskpara}>
                                <p>Do you want to keep unlimited days for all time-offs ? &nbsp;&nbsp; </p>
                                <Switch
                                    // onChange={setunlimitedDays}
                                    checked={unlimitedDays}
                                    onChange={handleUnlimitedDays}
                                    // checked={timeoff.unlimitedDays}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={18}
                                    width={34}
                                    onColor="#3C48B5"
                                    offColor="#CCCCCC"
                                    id="unlimitedTimeOffSwitch"
                                />
                            </div>
                        </div>

                        {/* <Grid item xs={10} sm={6} style={{ float: 'right' }}>
                            <p className={classes.Deskpara} style={{ float: 'right', clear: 'both' }}>
                                Do you want to Auto-approve the time-offs ? &nbsp;&nbsp;
                                <div
                                    style={{
                                        float: 'right',
                                        clear: 'both',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignContent: 'space-around',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <AutoTimeff rows={timeoff || []} />
                                </div>
                            </p>
                        </Grid>
                    </Hidden>
                </div> */}

                <Hidden smDown>
                    <PolicyTable
                        rows={timeOffPolicy.timeOffPolicy || []}
                        isLoading={timeOffPolicy.status === 'loading'}
                        error={timeOffPolicy.status === 'failed'}
                        errorMessage="Something went wrong, please try again"
                        setAlertMessage={setAlertMessage}
                        setAlertm={setAlertm}
                        setSeverity={setSeverity}
                        isUpdating={isUpdating}
                        setIsUpdating={setIsUpdating}
                        userSubscriptionDetails={userSubscriptionDetails}
                        checkSubscription={checkSubscription}
                    />
                </Hidden>
                <div className={classes.screenSize}>
                    <SmallTimePolicyScreen
                        rows={timeOffPolicy.timeOffPolicy || []}
                        isLoading={timeOffPolicy.status === 'loading'}
                        error={timeOffPolicy.status === 'failed'}
                        errorMessage="Something went wrong, please try again"
                        setAlertMessage={setAlertMessage}
                        setAlertm={setAlertm}
                        setSeverity={setSeverity}
                        setIsUpdating={setIsUpdating}
                        userSubscriptionDetails={userSubscriptionDetails}
                        checkSubscription={checkSubscription}
                    />
                    <AddTimeOff
                        setAlertMessage={setAlertMessage}
                        setAlertm={setAlertm}
                        setSeverity={setSeverity}
                        setIsUpdating={setIsUpdating}
                        userSubscriptionDetails={userSubscriptionDetails}
                        checkSubscription={checkSubscription}
                    />
                </div>
            </Paper>
        </div>
    );
}
