import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
const myStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            height: 'auto',
            padding: 10,
            backgroundColor: theme.palette.background.paper,
        },
        calendar: {
            display: 'flex',
            flexDirection: 'column',
        },
        month: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: '10px 0',
        },
        days: {
            display: 'grid',
            gridTemplateColumns: 'repeat(7, auto)',
            // '& div': {
            //     height: '70px',
            //     backgroundColor: '#fff',
            //     border: '1px solid rgba(0, 0, 0, 0.8)',
            //     display: 'flex',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            // },
        },
        otherDay: {
            // backgroundColor: '#fff',
            height: 25,
            color: theme.palette.primary.contrastText,
            padding: 5,
            fontWeight: theme.typography.fontWeightBold,
            // border: '1px solid rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            margin: '1px 0',
        },
        holiday: {
            height: 25,
            backgroundColor: '#B1C2FF',
            color: '#444444',
            padding: 5,
            borderRadius: 2,
            // border: '1px solid rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
            margin: '1px 0',
        },
        holidayToday: {
            height: 25,
            backgroundColor: '#B1C2FF',
            color: 'red',
            padding: 5,
            borderRadius: 2,
            // border: '1px solid rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
            margin: '1px 0',
        },
        appliedLeave: {
            height: 25,
            backgroundColor: '#B1FFB9',
            color: '#444444',
            padding: 5,
            borderRadius: 2,
            // border: '1px solid rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
            margin: '1px 0',
        },
        appliedLeaveToday: {
            height: 25,
            backgroundColor: '#B1FFB9',
            color: 'red',
            padding: 5,
            borderRadius: 2,
            // border: '1px solid rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
            margin: '1px 0',
        },
        date: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
        },
        arrowStyle: {
            width: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            color: theme.palette.text.primary,
        },
        weekDays: {
            display: 'grid',
            gridTemplateColumns: 'repeat(7, auto)',
            '& div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 12,
                color: theme.palette.primary.contrastText,
                padding: 5,
                fontWeight: theme.typography.fontWeightBold,
                margin: '1px 0',
            },
        },
        day: {
            height: 25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '1px 0',
            borderRadius: 2,
        },
        prevDate: {
            color: '#AAAAAA',
            height: 25,
            // height: '70px',
            // backgroundColor: '#fff',
            // border: '1px solid rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
            padding: 5,
            margin: '1px 0',
        },
        nextDate: {
            color: '#AAAAAA',
            height: 25,
            // height: '70px',
            // backgroundColor: '#fff',
            // border: '1px solid rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
            padding: 5,
            margin: '1px 0',
        },
        today: {
            height: 25,
            color: 'red',
            padding: 5,
            // height: '70px',
            // backgroundColor: '#fff',
            // border: '1px solid rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            margin: '1px 0',
            fontWeight: theme.typography.fontWeightBold,
            borderRadius: 2,
        },
        todaysDate: {
            color: 'red',
            fontWeight: theme.typography.fontWeightBold,
        },
    }),
);
export default myStyle;
