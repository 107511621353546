import React from 'react';
import { useStyles, skeletonStyles } from './employeeProfileStyles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography } from '@material-ui/core';

export default function AccordionSkeleton() {
    const classes = useStyles();
    const classes2 = skeletonStyles();

    return (
        <div style={{ display: 'grid', gap: '10px' }}>
            <div className={classes2.div1Grid} style={{ gap: '10px' }}>
                <div className={classes2.div1Components}>
                    <Skeleton variant="text" width="30%" height={20}>
                        <Typography>.</Typography>
                    </Skeleton>
                </div>
            </div>
            <div className={classes.accordionGridStyle}>
                <div className={classes2.employeeProfile}>
                    <div style={{ display: 'grid', padding: '20px', gridTemplateColumns: '1fr 4fr' }}>
                        <div style={{ display: 'grid' }}>
                            <Skeleton variant="circle" width={80} height={80} />
                        </div>
                        <div style={{ width: '100%', padding: '10px' }}>
                            <Skeleton width="100%" height={20} />
                            <Skeleton width="60%" height={20} />
                        </div>
                        <div style={{ width: '100%', padding: '10px' }}>
                            <Skeleton width="100%" height={20} />
                            <Skeleton width="60%" height={20} />
                        </div>
                        <div style={{ width: '100%', padding: '10px' }}>
                            <Skeleton width="100%" height={20} />
                            <Skeleton width="60%" height={20} />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'grid', gap: '20px' }}>
                    <div className={classes2.accordionOne}>
                        <Skeleton width="60%" height={20} />
                    </div>
                    <div className={classes2.accordionTwo}>
                        <Skeleton width="100%" height={80} />
                        <Skeleton width="100%" height={80} />
                        <Skeleton width="100%" height={80} />
                    </div>
                </div>
            </div>
        </div>
    );
}
