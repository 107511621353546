import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    Typography,
    Hidden,
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
} from '@material-ui/core';
import { useStyles, skeletonStyles } from './employeeProfileStyles';
import { withStyles } from '@material-ui/core/styles';

export default function EmployeeSkeleton() {
    const classes = useStyles();
    const classes2 = skeletonStyles();

    const StyledTableCell = withStyles(theme => ({
        root: {
            padding: '7px',
            borderBottom: 'none',
        },
        head: {
            fontSize: '14px',
            backgroundColor: '#DCDFFF',
            color: '#262F83',
            fontWeight: 600,
        },
        body: {
            fontSize: '12px',
        },
    }))(TableCell);

    return (
        <div style={{ display: 'grid', gap: '10px' }}>
            <div className={classes2.div1Grid}>
                <div className={classes2.div1Components}>
                    <Skeleton variant="text" width="30%" height={20}>
                        <Typography>.</Typography>
                    </Skeleton>
                </div>
            </div>
            <Hidden only={['xs', 'sm']}>
                <div
                    style={{
                        marginLeft: 0,
                        marginRight: 0,
                        maxWidth: '100%',
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: '2.5fr 1fr',

                        height: '100%',
                        minHeight: 'calc(100vh - 140px)',
                        maxHeight: 'calc(100vh - 130px)',
                    }}
                >
                    <div style={{ marginRight: 10, padding: 0 }}>
                        <div className={classes2.profileGrid1}>
                            <div style={{ display: 'grid', padding: '20px', gridTemplateColumns: '1fr 4fr' }}>
                                <div style={{ display: 'grid' }}>
                                    <Skeleton variant="circle" width={80} height={80} />
                                </div>
                                <div style={{ width: '100%', padding: '10px' }}>
                                    <Skeleton width="100%" height={20} />
                                    <Skeleton width="60%" height={20} />
                                </div>
                                <div style={{ width: '100%', padding: '10px' }}>
                                    <Skeleton width="100%" height={20} />
                                    <Skeleton width="60%" height={20} />
                                </div>
                                <div style={{ width: '100%', padding: '10px' }}>
                                    <Skeleton width="100%" height={20} />
                                    <Skeleton width="60%" height={20} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <TableContainer className={classes.skeletonStyle}>
                                <Table aria-label="customized table" style={{ height: 'calc(100vh - 300px)' }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">#</StyledTableCell>
                                            <StyledTableCell align="justify">Date</StyledTableCell>
                                            <StyledTableCell align="justify">Time</StyledTableCell>
                                            <StyledTableCell align="justify">Type</StyledTableCell>
                                            <StyledTableCell align="justify">Reason</StyledTableCell>
                                            <StyledTableCell align="justify">Status</StyledTableCell>
                                            <StyledTableCell align="justify">By</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {[1, 2, 3, 4, 5, 6, 7, 8].map((row: any, i: any) => (
                                            <TableRow key={i}>
                                                <StyledTableCell
                                                    className={classes.tableCellStyle}
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <Skeleton variant="text" width="100%" height={20} />
                                                </StyledTableCell>
                                                <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                    <Skeleton variant="text" width="100%" height={20} />
                                                </StyledTableCell>
                                                <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                    <Skeleton variant="text" width="100%" height={20} />
                                                </StyledTableCell>
                                                <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                    <Skeleton variant="text" width="100%" height={20} />
                                                </StyledTableCell>
                                                <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                    <Skeleton variant="text" width="100%" height={20} />
                                                </StyledTableCell>
                                                <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                    <Skeleton variant="text" width="100%" height={20} />
                                                </StyledTableCell>
                                                <StyledTableCell className={classes.tableCellStyle} align="justify">
                                                    <Skeleton variant="text" width="100%" height={20} />
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div>
                        <div className={classes2.skeleton}>
                            <Skeleton variant="circle" width={80} height={80} style={{ marginTop: '30px' }} />
                            <Skeleton variant="text" width="60%" height={20} />
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr',
                                    justifyItems: 'center',
                                    gap: '20px',
                                    width: '100%',
                                }}
                            >
                                <Skeleton variant="text" width="60%" height={20} />
                                <Skeleton variant="text" width="60%" height={20} />
                                <Skeleton variant="text" width="60%" height={20} />
                            </div>
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
                <div className={classes2.mobileUILeaveCards}>
                    <div style={{ display: 'grid', width: '100%', justifyItems: 'left', gap: '40px' }}>
                        <Skeleton variant="circle" width={80} height={80} />
                        <Skeleton variant="text" width="60%" height={20} />
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                justifyItems: 'left',
                                gap: '20px',
                                width: '100%',
                            }}
                        >
                            <Skeleton variant="text" width="60%" height={20} />
                            <Skeleton variant="text" width="60%" height={20} />
                        </div>
                    </div>
                </div>
                <div className={classes2.mobileUILeaveCards}>
                    <div style={{ display: 'grid', width: '100%', justifyItems: 'left', gap: '40px' }}>
                        <Skeleton variant="text" width="60%" height={20} />
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                justifyItems: 'left',
                                gap: '20px',
                                width: '100%',
                            }}
                        >
                            <Skeleton variant="text" width="60%" height={20} />
                            <Skeleton variant="text" width="60%" height={20} />
                        </div>
                    </div>
                </div>
                <div className={classes2.mobileUILeaveCards}>
                    <div style={{ display: 'grid', width: '100%', justifyItems: 'left', gap: '40px' }}>
                        <Skeleton variant="text" width="60%" height={20} />
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                justifyItems: 'left',
                                gap: '20px',
                                width: '100%',
                            }}
                        >
                            <Skeleton variant="text" width="60%" height={20} />
                            <Skeleton variant="text" width="60%" height={20} />
                        </div>
                    </div>
                </div>
            </Hidden>
        </div>
    );
}
