import React from 'react';
import { useContext } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { HolidaytabStyles } from './HolidayStyle';
import HolidayTable from '../HolidayTable/HolidayTable';
import AddHoliday from '../AddHoliday/AddHoliday';
import ImportHoliday from '../ImportHoliday/ImportHoliday';
import AuthContext from '../../context/AuthContext';
// import { fetchHoliday } from '../../helpers/hasuraapi';
import ErrorPage from '../Error/ErrorPage';
import Loader, { LoaderPage } from '../Loader/loader';
import { useSelector } from 'react-redux';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';

export default function HolidayPage() {
    const classes = HolidaytabStyles();
    const mycontext = useContext(AuthContext);
    // const [holidays, setHolidays] = useState<any>([]);
    // const [error, setError] = useState<boolean>(false);
    // const [errorMessage, setErrorMessage] = useState<any>('');
    // const [isLoading, setLoading] = useState<boolean>(true);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const holidays: any = useSelector((state: any) => state.holidays);

    const isAdmin = mycontext.isAdmin;
    /* istanbul ignore next */
    // useEffect(() => {
    //     async function getAll() {
    //         const data = await fetchHoliday(mycontext.organizationId, mycontext.token);
    //         if (data.Error) {
    //             setError(true);
    //             setErrorMessage(data.Error);
    //             setLoading(false);
    //         } else {
    //             setHolidays(data);
    //             setLoading(false);
    //         }
    //     }
    //     getAll();
    // }, [mycontext]);

    // if (holidays.status === 'loading') {
    //     return <h2>Loading ...</h2>;
    // }
    // if (holidays.status === 'failed') {
    //     return <ErrorPage title="Server Down" img="/error.png" msg="Something went wrong!" />;
    // }
    if (holidays.status === 'loading') return <LoaderPage />;
    if (holidays.status === 'failed') return <ErrorPage msg="Something went wrong!" />;
    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        <SubscriptionHardExpired
            content="Looks like your subscription is expired, Please renew it !"
            showRenew={true}
            fromSettings={true}
        />
    ) : (
        <Paper className={classes.main} id="HolidayPage" style={{ borderRadius: 0 }} elevation={0}>
            <Loader isUpdating={isUpdating} />
            <Grid container className={classes.grid}>
                {/* <Grid item xs={12} sm={5}>
                    <Hidden only="xs">
                        <Typography className={classes.title}>Holidays</Typography>
                    </Hidden>
                </Grid> */}
                <Grid item xs={12} sm={7} className={classes.holiday}>
                    {/* istanbul ignore next */ isAdmin && (
                        <div className={classes.holidaybtn}>
                            <AddHoliday
                                Addholidaybtn="Add Holiday"
                                holidays={holidays.holidays}
                                setIsUpdating={setIsUpdating}
                            />
                        </div>
                    )}
                    {/* istanbul ignore next */ isAdmin && (
                        <div className={classes.holidaybtn}>
                            <ImportHoliday
                                Addholidaybtn="Import Holiday"
                                importList={holidays.holidays}
                                setIsUpdating={setIsUpdating}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
            <div style={{ margin: 'auto', width: '100%', marginTop: 20 }}>
                <Grid container>
                    <Grid item xs={12} sm={12} className={classes.scrollDiv}>
                        <HolidayTable
                            year={2022}
                            rows={holidays.holidays}
                            holidayName="Holiday Name"
                            date="Date(s)"
                            day="Day(s)"
                            actions="Action"
                            isLoading={holidays.status === 'loading'}
                            error={holidays.status === 'failed'}
                            // errorMessage=""
                            setIsUpdating={setIsUpdating}
                        />
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}
