import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { totalPeopleCount, peopleOnLeaveCount } from 'helpers/hasuraapi';

export const getTotalLeaveCount: any = createAsyncThunk('employeeStatus/getTotalLeaveCount', async (user: any) => {
    const data: any = await totalPeopleCount(user.organizationId, user.token);
    const jsonData: any = await data.json();
    return jsonData.data.usermanagementdb_organization_employee_aggregate.aggregate.count;
});

export const getLeaveCountToday: any = createAsyncThunk('employeeStatus/getLeaveCountToday', async (user: any) => {
    const data: any = await peopleOnLeaveCount(user.organizationId, user.token, user.date);
    const jsonData: any = await data.json();
    return jsonData.data.timeoffdb_Leaves_aggregate.aggregate.count;
});

export const employeeStatusSlice = createSlice({
    name: 'employeeStatus',
    initialState: { totalPeopleCount: 0, peopleOnLeaveToday: 0, status: 'loading' },
    reducers: {
        updateLeaveCountToday: (state: any, action: any) => {
            state.peopleOnLeaveToday = action.payload;
        },
    },
    extraReducers: {
        [getTotalLeaveCount.pending]: state => {
            state.status = 'loading';
        },
        [getTotalLeaveCount.fulfilled]: (state, action) => {
            state.totalPeopleCount = action.payload;
            state.status = 'success';
        },
        [getTotalLeaveCount.rejected]: state => {
            state.status = 'failed';
        },
        [getLeaveCountToday.pending]: state => {
            state.status = 'loading';
        },
        [getLeaveCountToday.fulfilled]: (state, action) => {
            state.peopleOnLeaveToday = action.payload;
            state.status = 'success';
        },
        [getLeaveCountToday.rejected]: state => {
            state.status = 'failed';
        },
    },
});

export const { updateLeaveCountToday } = employeeStatusSlice.actions;
export default employeeStatusSlice.reducer;
