import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import SignUp from './SignUp';
import Experience from './Experience';
import Features from './Features';
import Howitworks from './Howitworks';
import Main from './Main';
import { useDispatch } from 'react-redux';
import { updateActiveTab } from 'components/Redux/activeTab';
// import AdminExp from '../../Assets/dashboard/adminExp.png';
// import Desktop from '../../Assets/dashboard/main_admin.png';
// import DesktopSmall from '../../Assets/dashboard/admin_mobile.png';
import { s3Bucket, landingpageImages } from '../../helpers/constants';

const features = [
    {
        id: 1,
        title: 'Mobile-Friendly',
        description: 'Add members, approve leaves, assign roles, wherever you are access TeamPlus through your phone.',
        img: s3Bucket + landingpageImages.mobileFriendly,
    },
    {
        id: 2,
        title: 'Real-Time Updates',
        description: `Always be abreast with everyone's time-offs and availability with an all-in-one dashboard that allows real time monitoring.`,
        img: s3Bucket + landingpageImages.realTime,
    },
    {
        id: 3,
        title: 'Easy Designation',
        description: 'Designate time zones, leave approvers for each time at your company in a jiffy',
        img: s3Bucket + landingpageImages.easyDesignation,
    },
    {
        id: 4,
        title: 'Instant Notification',
        description: `Get notified instantly on Slack for every request that's made so that you never miss a time-off application from your team members.`,
        img: s3Bucket + landingpageImages.instantNotifications,
    },
];

const list = [
    {
        id: 1,
        task: 'Register on TeamPlus.',
    },
    {
        id: 2,
        task: 'Add all your team members.',
    },
    {
        id: 3,
        task: 'Assign approvers.',
    },
    {
        id: 4,
        task: 'Customize time-off requirements.',
    },
    {
        id: 5,
        task: 'Oversee availabilities with a click.',
    },
];

export default function Admin() {
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch({ type: 'ACTIVE', payload: '/admin' });
        dispatch(updateActiveTab('/admin'));
    }, [dispatch]);
    return (
        <div style={{ overflowY: 'hidden' }}>
            <Main
                img={s3Bucket + landingpageImages.main_admin}
                imgSmall={s3Bucket + landingpageImages.admin_mbl}
                title="Hassle-free and easy-to-use availability coordination tool "
                subtitle="As an admin, you’ll always have too much on your plate. It’s time to strike off approving time-offs from your to-do list."
                btnText="Try TeamPlus"
                isAdmin={true}
            />
            <Header />
            <Howitworks list={list} img={s3Bucket + landingpageImages.admin_bg} />
            <Features heading="Managing time-offs" spam="made easy" isEmployee={false} features={features} />
            <Experience
                img={s3Bucket + landingpageImages.wonderingWhat}
                title="Wondering what's in store "
                spam="for your team members?"
                subtitle="TeamPlus is as easy to use and user-friendly for your team members as it is for you. Check out all the features we’ve instilled in TeamPlus for your team members."
                btnText="TeamPlus Employee Experience"
            />
            <SignUp title="The new-age assistant to take good care of your team is here. Try now!" />
            <Footer />
        </div>
    );
}
