import React, { useMemo, useContext, useState } from 'react';
import {
    Paper,
    Hidden,
    Typography,
    Grid,
    LinearProgress,
    Box,
    Button,
    ButtonGroup,
    Chip,
    TextField,
    InputAdornment,
    useTheme,
    TableContainer,
    Table,
    TableBody,
    Checkbox,
    TableHead,
    TableRow,
    TableCell,
    // Select,
    // Input,
    // MenuItem,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
    Avatar,
    // FormControl,
    IconButton,
    MobileStepper,
} from '@material-ui/core';
import { SettingStyles } from './SettingStyle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HoldIcon from '../../Assets/icons/hold.svg';
import ActiveIcon from '../../Assets/icons/activeLicense.svg';
import TickFilled from '../../Assets/icons/licensedFilled.svg';
import CloseFilled from '../../Assets/icons/unlicensedFilled.svg';
import MyModal from '../Modal/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import AuthContext from '../../context/AuthContext';
import { getTenantUsers, membersofTeam, MembersofChannel } from '../Admin/graph';
import {
    UpdateUserLicenses,
    insertTeamEmployee,
    listTenantEmployees,
    registerTenantEmployee,
    GetOrganizations,
    createOrganizationRole1,
    registerEmployee,
    // updateIsBotAddedDatabase,
    updateSubscriptionMs,
} from '../../helpers/hasuraapi';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { PopUp } from '../Modal/Modal';
import { toAWSDateFormat } from '../../helpers/date';
import jwtDecode from 'jwt-decode';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import { lambdaMsSubscription } from 'helpers/msSubscriptionApi';
import { useFlagsmith } from 'flagsmith-react';
// import config from '../../config.json';
// const planDetails = [
//     {
//         plaId: 'timeoffPlan',
//         name: 'Basic plan',
//         licenseCount: 60,
//         endDate: '15th March, 2023',
//     },
//     {
//         plaId: 'timeoffPlan2',
//         name: 'Gold plan',
//         licenseCount: 40,
//         endDate: '10th March, 2023',
//     },
//     {
//         plaId: 'timeoffPlan3',
//         name: 'Advanced plan',
//         licenseCount: 30,
//         endDate: '04th April, 2023',
//     },
// ];

export default function UserLicense() {
    const classes = SettingStyles();
    const theme = useTheme();
    const mycontext = useContext(AuthContext);
    const [activeStep, setActiveStep] = useState(0);
    const [search, setSearch] = useState<string>('');
    const [showLicensed, setShowLicensed] = useState<boolean>(true);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    const [selected, setSelected] = useState<string[]>([]);
    const [modal, setModal] = useState<boolean>(false);
    // const [currentPlan, setCurrentPlan] = useState([]);
    const [currentSelectedPlan, setCurrentSelectedPlan] = useState(0);
    const [licenses, setLicenses] = useState<any>([]);
    const [currentLicenses, setCurrentLicenses] = useState<any>([]);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { identify, getTrait, setTrait, hasFeature } = useFlagsmith();
    const subscriptions: any = useSelector(
        (state: any) => state.subscription?.subscription.usermanagementdb_Subscription,
    );
    const tenantEmployees: any = useSelector((state: any) => state?.tenantEmployees);
    // console.log('tenant  employees', tenantEmployees);
    // const isBotAdded: any = useSelector((state: any) => state.tenantEmployees.isBotAdded);
    const tableData = tenantEmployees?.tenantEmployees;
    const purchasedLicenses: any = [];
    const subscribedPlans = {
        timeoffplan: false,
        pollplan: false,
        standupplan: false,
        engageplan: false,
    };
    // const addedBots = {
    //     timeoff: isBotAdded.timeoff ? true : false,
    //     poll: isBotAdded.engage ? true : false,
    //     standup: isBotAdded.standup ? true : false,
    //     engage: isBotAdded.engage ? true : false,
    // };
    const redirectUrl = {
        timeoff: `https://teams.microsoft.com/l/app/da74cb73-efd0-4666-8457-fc1fe8ba59a0?source=app-details-dialog`,
        poll: ``,
        standup: `https://teams.microsoft.com/l/app/0a9d9818-ee3c-4825-9ced-a0c1952f5a0e?source=app-details-dialog`,
        engage: ``,
    };
    const appSourceUrl = {
        timeoff: `https://appsource.microsoft.com/en-us/product/web-apps/udyamo1675760123066.tbtp31032023?exp=ubp8&tab=Overview`,
        poll: `https://appsource.microsoft.com/en-US/product/web-apps/udyamo1675760123066.pbtp03042023?exp=ubp8`,
        standup: `https://appsource.microsoft.com/en-us/product/web-apps/udyamo1675760123066.sbtp31032023?exp=ubp8&tab=Overview`,
        engage: `https://appsource.microsoft.com/en-us/product/web-apps/udyamo1675760123066.ebtp03042023?exp=ubp8`,
    };
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    const loginRequest = {
        scopes: [
            'User.ReadBasic.All',
            'Group.Read.All',
            'ChannelMember.Read.All',
            'Team.ReadBasic.All',
            'Directory.Read.All',
        ],
    };
    const loginrequest = {
        ...loginRequest,
        account: accounts[0],
    };
    const fetchDateForSubscription = async (subscriptionId: any) => {
        const bodyContentsFetch = {
            action: 'FETCH',
            subscriptionId: subscriptionId,
            tenantId: mycontext.tenantId,
            botType: 'timeoff',
        };
        const subscriptionDetailsResponse = await lambdaMsSubscription(bodyContentsFetch);
        const subscriptionObject = subscriptionDetailsResponse?.response;
        if (subscriptionObject?.term?.startDate && subscriptionObject?.term?.endDate) {
            const subscriptionStoreObject = {
                subscriptionId: subscriptionId,
                nextBillingAt: subscriptionObject.term.endDate,
                currentTermStart: subscriptionObject.term.startDate,
                currentTermEnd: subscriptionObject.term.endDate,
                token: mycontext.token,
            };
            await updateSubscriptionMs(subscriptionStoreObject);
        }
    };
    const handleSelectedPlan = (index: any, initialLicenses?: any) => {
        setSelected([]);
        setSelectedUsers([]);
        setCurrentSelectedPlan(index);
        const licensesFilter = initialLicenses || licenses;
        const filterCondition = licensesFilter[index]?.planDetails?.plan?.includes('tbtp')
            ? 'timeoffLicense'
            : licenses[index]?.planDetails?.plan?.includes('sbtp')
            ? 'standupLicense'
            : licenses[index]?.planDetails?.plan?.includes('pbtp')
            ? 'pollLicense'
            : licenses[index]?.planDetails?.plan?.includes('ebtp')
            ? 'engageLicense'
            : '';
        const data: any = tableData.filter((d: any) => d[filterCondition]);
        const filteredData = data.filter((item: any) => {
            return (
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item.email.toLowerCase().includes(search.toLowerCase())
            );
        });
        setCurrentLicenses(filteredData);
        setShowLicensed(true);
    };
    const handleFilter = (val: string) => {
        const filterCondition = licenses[currentSelectedPlan]?.planDetails?.plan?.includes('tbtp')
            ? 'timeoffLicense'
            : licenses[currentSelectedPlan]?.planDetails?.plan?.includes('sbtp')
            ? 'standupLicense'
            : licenses[currentSelectedPlan]?.planDetails?.plan?.includes('pbtp')
            ? 'pollLicense'
            : 'engageLicense';
        if (val === 'licensed') {
            const data: any = tableData.filter((d: any) => d[filterCondition]);
            const filteredData = data.filter((item: any) => {
                return (
                    item.name.toLowerCase().includes(search.toLowerCase()) ||
                    item.email.toLowerCase().includes(search.toLowerCase())
                );
            });
            setCurrentLicenses(filteredData);
            setShowLicensed(true);
        } else {
            const data: any = tableData.filter((d: any) => !d[filterCondition]);
            const filteredData = data.filter((item: any) => {
                return (
                    item.name.toLowerCase().includes(search.toLowerCase()) ||
                    item.email.toLowerCase().includes(search.toLowerCase())
                );
            });
            setCurrentLicenses(filteredData);
            setShowLicensed(false);
        }
        setCheckedAll(false);
        setSelected([]);
        setSelectedUsers([]);
    };
    useMemo(() => {
        try {
            const identifyUser = async (id: string, email: string) => {
                await identify(id);
                const hasEmail = !!getTrait('email');
                if (!hasEmail) {
                    setTrait('email', email);
                }
            };
            identifyUser(mycontext.email, mycontext.email);
            subscriptions.forEach((subscription: any) => {
                if (subscription.currentTermStart === 'undefined' || subscription.currentTermEnd === 'undefined') {
                    fetchDateForSubscription(subscription.subscriptionId);
                }
                let planName = '';
                switch (true) {
                    case subscription.planId.includes('tbtp'):
                        subscribedPlans['timeoffplan'] = true;
                        planName = 'timeoffplan';
                        break;
                    case subscription.planId.includes('sbtp'):
                        subscribedPlans['standupplan'] = true;
                        planName = 'standupplan';
                        break;
                    case subscription.planId.includes('pbtp'):
                        subscribedPlans['pollplan'] = true;
                        planName = 'pollplan';
                        break;
                    case subscription.planId.includes('ebtp'):
                        subscribedPlans['engageplan'] = true;
                        planName = 'engageplan';
                        break;
                    default:
                        break;
                }
                const obj = {
                    id: subscription.planId.includes('tbtp')
                        ? 'timeoff'
                        : subscription.planId.includes('sbtp')
                        ? 'standup'
                        : subscription.planId.includes('pbtp')
                        ? 'poll'
                        : subscription.planId.includes('ebtp')
                        ? 'engage'
                        : '',
                    feature: subscription.planId.includes('tbtp')
                        ? 'TimeOffs by TeamPlus'
                        : subscription.planId.includes('sbtp')
                        ? 'StandUps by TeamPlus'
                        : subscription.planId.includes('pbtp')
                        ? 'Polls by TeamPlus'
                        : subscription.planId.includes('ebtp')
                        ? 'Engage by TeamPlus'
                        : '',
                    status: subscription.status,
                    planDetails: {
                        subscriptionId: subscription.subscriptionId,
                        plan: subscription.planId,
                        monthly: true,
                        yearly: false,
                        licenseCount: subscription.quantity,
                        billing: subscription.autoRenew ? 'Auto Renew' : 'Non recurring',
                        nextDate:
                            subscription.currentTermEnd !== 'undefined'
                                ? subscription.autoRenew
                                    ? subscription.currentTermEnd.split('T')[0]
                                    : 'Non-recurring'
                                : '---',
                        purchasedBy: subscription.Employee.firstName + ' ' + subscription.Employee.lastName,
                        usage: subscription[planName]?.tenant_employees_aggregate.aggregate.count,
                    },
                };
                purchasedLicenses.push(obj);
            });
            const unPurchasedLicenses = [];
            for (const key in subscribedPlans) {
                if (subscribedPlans.hasOwnProperty(key)) {
                    const val = subscribedPlans[key];
                    if (!val) {
                        const obj = {
                            id:
                                key === 'timeoffplan'
                                    ? 'timeoff'
                                    : key === 'standupplan'
                                    ? 'standup'
                                    : key === 'pollplan'
                                    ? 'poll'
                                    : key === 'engageplan'
                                    ? 'engage'
                                    : '',
                            feature:
                                key === 'timeoffplan'
                                    ? 'TimeOffs by TeamPlus'
                                    : key === 'standupplan'
                                    ? 'StandUps by TeamPlus'
                                    : key === 'pollplan'
                                    ? 'Polls by TeamPlus'
                                    : key === 'engageplan'
                                    ? 'Engage by Teamplus'
                                    : '',
                            status: 'Not Subscribed',
                            planDetails: {
                                plan: null,
                                monthly: false,
                                yearly: false,
                                licenseCount: 0,
                                billing: '',
                                nextDate: '',
                                purchasedBy: '',
                                usage: 0,
                            },
                        };
                        unPurchasedLicenses.push(obj);
                    }
                }
            }
            const licenseArray: any = [...purchasedLicenses, ...unPurchasedLicenses];
            setLicenses(licenseArray);
            const filterCondition = licenseArray[currentSelectedPlan]?.planDetails?.plan?.includes('tbtp')
                ? 'timeoffLicense'
                : licenseArray[currentSelectedPlan]?.planDetails?.plan?.includes('sbtp')
                ? 'standupLicense'
                : licenseArray[currentSelectedPlan]?.planDetails?.plan?.includes('pbtp')
                ? 'pollLicense'
                : licenseArray[currentSelectedPlan]?.planDetails?.plan?.includes('ebtp')
                ? 'engageLicense'
                : '';
            const data: any = tableData.filter((d: any) => d[filterCondition]);
            setCurrentLicenses(data);
            setShowLicensed(true);
            handleSelectedPlan(0, licenseArray);
        } catch (e) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage(`Something went wrong`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptions, tenantEmployees]);

    const maxSteps = licenses.length;

    // const ITEM_HEIGHT = 48;
    // const ITEM_PADDING_TOP = 8;

    // const updateIsBotAdded = async (id: any) => {
    //     try {
    //         const updateResponse = await updateIsBotAddedDatabase(mycontext.tenantTableId, id, mycontext.token);
    //         if (updateResponse?.returning?.length === 0) {
    //             throw new Error('Unable to update is bot added');
    //         }
    //         window.open(redirectUrl[id], '_blank');
    //         setSeverity('success');
    //         setAlert(true);
    //         setAlertMessage('Click on Allow to add to microsoft teams');
    //     } catch (e) {
    //         setSeverity('error');
    //         setAlert(true);
    //         setAlertMessage('Something went wrong');
    //     }
    // };
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        handleSelectedPlan(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        handleSelectedPlan(activeStep - 1);
    };

    const handleChange = (e: any) => {
        // console.log(e.target.value);
        setSearch(e.target.value);
        const filterCondition = licenses[currentSelectedPlan]?.planDetails?.plan?.includes('tbtp')
            ? 'timeoffLicense'
            : licenses[currentSelectedPlan]?.planDetails?.plan?.includes('sbtp')
            ? 'standupLicense'
            : licenses[currentSelectedPlan]?.planDetails?.plan?.includes('pbtp')
            ? 'pollLicense'
            : 'engageLicense';
        let data = [];
        if (showLicensed === true) {
            data = tableData.filter((d: any) => d[filterCondition]);
        } else {
            data = tableData.filter((d: any) => !d[filterCondition]);
        }

        const filteredData = data.filter((item: any) => {
            return (
                item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                item.email.toLowerCase().includes(e.target.value.toLowerCase())
            );
        });
        setCurrentLicenses(filteredData);
    };

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setCheckedAll(true);
            const newSelectedIds = currentLicenses.map((c: any) => c.empId);
            setSelected(newSelectedIds);
            setSelectedUsers(currentLicenses);
        } else {
            setCheckedAll(false);
            setSelected([]);
            setSelectedUsers([]);
        }
    };

    // const handleSelect1 = (event: React.MouseEvent<unknown>, id: string, obj: any) => {
    //     const selectedIndex = selected.indexOf(id);
    //     console.log("SelectedIndex: ", selectedIndex);
    //     let newSelected: string[] = [];
    //     // let newUsers: any[] = [];

    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, id);
    //         // newUsers = [...newUsers, obj];
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //         // newUsers = [...newUsers, selectedUsers.slice(1)];
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //         // newUsers = [...newUsers, selectedUsers.slice(0, -1)];
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    //         // newUsers = [...newUsers, selectedUsers.slice(0, selectedIndex), selectedUsers.slice(selectedIndex + 1)];
    //     }

    //     setSelected(newSelected);
    //     setSelectedUsers([...selectedUsers, obj]);
    // };

    const handleSelect = (event: React.MouseEvent<unknown>, id: string, obj: any) => {
        const index = selected.indexOf(id);
        if (index > -1) {
            setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
            setSelectedUsers([...selectedUsers.slice(0, index), ...selectedUsers.slice(index + 1)]);
        } else {
            setSelected([...selected, id]);
            setSelectedUsers([...selectedUsers, obj]);
        }
    };
    const normalise = (value: any, count: any) => ((value - 0) * 100) / (count - 0);

    // const handlePlan = (event: any) => {
    // console.log('Current plan: ', event.target.value);
    //     setCurrentPlan(event.target.value);
    // };

    const toggleModal = () => {
        setSelectedUsers(selectedUsers);
        setSelected(selected);
        setModal(!modal);
    };

    const handleCancel = (id: any) => {
        // console.log('Event: ', id);
        const newUsers = selectedUsers.filter((user: any) => user.empId !== id);
        const newSelectedIds = newUsers.map((c: any) => c.empId);

        // console.log('New users: ', newUsers);
        setSelectedUsers(newUsers);
        setSelected(newSelectedIds);
    };

    const isSelected = (id: any) => selected.indexOf(id) !== -1;
    // console.log('Selected users: ', selectedUsers, currentPlan);
    const validateLicenseCount = async () => {
        try {
            // console.log('selected length', selectedUsers.length);
            // console.log(
            //     'left',
            //     licenses[currentSelectedPlan]?.planDetails.licenseCount -
            //         licenses[currentSelectedPlan]?.planDetails.usage -
            //         1,
            // );
            if (licenses[currentSelectedPlan]?.status === 'Unsubscribed') {
                throw new Error('You have cancelled subscription for this bot');
            }
            if (licenses[currentSelectedPlan]?.status === 'Suspended') {
                throw new Error('Your license for this bot is suspended');
            }
            if (selectedUsers.length === 0) {
                throw new Error('Please select atleast one employee');
            }
            if (!showLicensed) {
                if (
                    selectedUsers.length >
                    licenses[currentSelectedPlan]?.planDetails.licenseCount -
                        licenses[currentSelectedPlan]?.planDetails.usage
                ) {
                    throw new Error(`The number of people selected can't exceed the number of licenses available`);
                } else {
                    setModal(!modal);
                }
            } else {
                setModal(!modal);
            }
        } catch (e) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage(e.message);
        }
    };
    const updateLicenses = async () => {
        try {
            await validateLicenseCount();
            const employeeIdArray: any = [];
            const openMsTeams = licenses[currentSelectedPlan]?.planDetails?.usage === 0 ? true : false;
            selectedUsers.forEach((selectedUser: any) => {
                employeeIdArray.push(selectedUser.empId);
            });
            const updateObject = {
                token: mycontext.token,
                empIdArray: employeeIdArray,
                subscriptionType: licenses[currentSelectedPlan]?.planDetails?.plan?.includes('tbtp')
                    ? 'timeoffSubscriptionId'
                    : licenses[currentSelectedPlan]?.planDetails?.plan?.includes('sbtp')
                    ? 'standupSubscriptionId'
                    : licenses[currentSelectedPlan]?.planDetails?.plan?.includes('pbtp')
                    ? 'pollSubscriptionId'
                    : 'engageSubscriptionId',
                subscriptionId: showLicensed ? null : licenses[currentSelectedPlan]?.planDetails.subscriptionId,
            };
            const updateResponse = await UpdateUserLicenses(updateObject);
            if (updateResponse?.returning?.length === 0) {
                throw new Error('Unable to assign license');
            }
            setSelectedUsers([]);
            setSelected([]);
            setModal(!modal);
            setShowLicensed(true);
            handleFilter('licensed');
            setSeverity('success');
            setAlert(true);
            setAlertMessage(
                `License ${showLicensed ? 'removed' : 'assigned'} successfully ${
                    openMsTeams ? ', redirecting you to ms teams' : ''
                }`,
            );
            if (openMsTeams) {
                setTimeout(() => {
                    window.open(redirectUrl[licenses[currentSelectedPlan]?.id], '_blank');
                }, 3000);
            }
        } catch (e) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage(`Something went wrong, Please try to ${showLicensed ? 'remove' : 'assign'} license again`);
        }
    };

    const filterB = (array1: any, array2: any) => {
        return array1.filter((object1: any) => {
            return !array2.some((object2: any) => {
                return object1.id === object2.employeeId;
            });
        });
    };

    const syncMsEmps = async (accessToken: any) => {
        setLoading(true);
        const data = await getTenantUsers(accessToken);
        const listEmp = await listTenantEmployees(mycontext.tenantId, mycontext.token);

        if (listEmp[0]?.message) {
            setLoading(false);
            // setIsSynching(false);
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Something went wrong!');
        } else {
            const filteredEmps = filterB(data?.value, listEmp?.usermanagementdb_tenant_employee);
            if (filteredEmps.length === 0) {
                setLoading(false);
                // setIsSynching(false);
                setSeverity('success');
                setAlert(true);
                setAlertMessage('Everything is up to Date');
            } else {
                const teamplusDept: any = await GetOrganizations(mycontext.token);
                // const MSdept = await allTeams(accessToken);

                // console.log("MS Departments:", teamplusDept);
                await filteredEmps.map(async (user: any) => {
                    const stringArray = user.displayName.split(' ');
                    const employee: any = {
                        email: user.userPrincipalName,
                        firstName: user.givenName ? user.givenName : stringArray[0],
                        lastName: user.surname ? user.surname : user.surname ? stringArray[1] : '',
                        joining: toAWSDateFormat(new Date()),
                        id: user.id,
                        token: mycontext.token,
                    };
                    await registerEmployee(employee);

                    const obj = {
                        employeeId: user.id,
                        role: 'employee',
                        tenantId: listEmp?.usermanagementdb_tenant_employee[0]?.tenantId,
                        token: mycontext.token,
                    };
                    await registerTenantEmployee(obj);

                    teamplusDept?.data?.usermanagementdb_Organization.map(async (data: any) => {
                        const members = await membersofTeam(accessToken, data.organizationId);
                        // members.value.map(async (val: any) => {
                        members.map(async (val: any) => {
                            if (val.id === user.id) {
                                // console.log('User exists in this dept',data);
                                const empRole = {
                                    organizationId: data.organizationId,
                                    role: 'employee',
                                    token: mycontext.token,
                                    location: data.country,
                                    employeeId: user.id,
                                };
                                // console.log("THIS IS EMP role",empRole)
                                // const role =
                                await createOrganizationRole1(empRole);
                                // console.log('Step 5:ROLE CREATED:', empRole);
                            }
                        });
                        data?.Teams.map(async (val1: any) => {
                            const mem = await MembersofChannel(accessToken, data?.organizationId, val1?.msChannelId);
                            // mem.value.map(async (val: any) => {
                            mem.map(async (val: any) => {
                                // console.log(val)
                                if (val.userId === user.id) {
                                    const teamUser = {
                                        employeeId: user.id,
                                        isApprover: false,
                                        teamId: val1.teamId,
                                        token: mycontext.token,
                                    };
                                    await insertTeamEmployee(teamUser);
                                }
                            });
                        });
                    });
                });
            }
        }
    };

    const syncEmployees = async () => {
        // setIsSynching(true);
        const decoded: any = jwtDecode(mycontext.token);
        if (decoded?.isMicrosoft) {
            if (isAuthenticated) {
                instance
                    .acquireTokenSilent(loginrequest)
                    .then(async (response: any) => {
                        syncMsEmps(response.accessToken);
                    })
                    .catch((e: any) => {
                        // setIsSynching(false);
                        setSeverity('error');
                        setAlert(true);
                        setAlertMessage('You dont have necessary permissions to fetch MS Employees');
                    });
            } else {
                instance.acquireTokenPopup(loginrequest).then((res: any) => {
                    syncMsEmps(res.accessToken);
                });
            }
        }
        // else {
        //     try {
        //         const slackUsers = await fetchSlackUsers(decoded['accId']);
        //         if (Object.keys(slackUsers.employees).length === 0) {
        //             setAlertm(true);
        //             setSeverity('error');
        //             setAlertMessage('Please try again in sometime');
        //         } else {
        //             const empIdList = await listOrganizationEmployees(mycontext.organizationId, mycontext.token);
        //             const empIdArray: any = [];
        //             empIdList.forEach((element: any) => {
        //                 empIdArray.push(element.employeeId.toString());
        //             });
        //             if (Object.keys(slackUsers.employees_formatted).length === empIdArray.length) {
        //                 setSeverity('success');
        //                 setAlertm(true);
        //                 setAlertMessage('Everything is up to Date');
        //             } else {
        //                 const teamsList = props.teams.data.usermanagementdb_Team;
        //                 const teamsObj = {};
        //                 teamsList.forEach((item: any, index: any) => {
        //                     teamsObj[item.teamName] = item.teamId;
        //                 });
        //                 Object.keys(slackUsers.employees_formatted).forEach(function(key, index) {
        //                     if (!empIdArray.includes(getUuidByString(key))) {
        //                         storeNewEmployees(
        //                             key,
        //                             slackUsers.employees_formatted[key],
        //                             teamsObj,
        //                             slackUsers.team_employees_formatted,
        //                         );
        //                     }
        //                 });
        //             }
        //         }
        //     } catch (e) {
        //         setIsSynching(false);
        //         setSeverity('error');
        //         setAlertm(true);
        //         setAlertMessage('Something went wrong');
        //     }
        // }
        // setIsSynching(false);
    };

    return (
        <div style={{ width: '100%' }}>
            <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
            <div className={classes.screenSize}>
                <MyModal open={modal} setOpen={toggleModal} onModalClosed={toggleModal}>
                    <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        justifyContent="flex-end"
                        style={{ color: '#111111', width: '100%', padding: '10px 0px', margin: 0 }}
                    >
                        <Grid item sm={1} xs={1} style={{ padding: 0 }}>
                            <DateRangeIcon />
                        </Grid>
                        <Grid item sm={11} xs={11} style={{ padding: 0 }}>
                            <Typography variant="h6" style={{ fontSize: 16, fontWeight: 'bold' }}>
                                {showLicensed ? 'Remove licenses' : 'Assign licenses'}
                            </Typography>
                        </Grid>
                        <Grid item sm={11} xs={11} style={{ padding: 0 }}>
                            <Typography variant="body2" style={{ fontSize: 12, fontWeight: 'bold' }}>
                                {licenses[currentSelectedPlan]?.feature}
                            </Typography>
                        </Grid>
                        {showLicensed ? (
                            <Grid item sm={12} xs={12} style={{ padding: 0, paddingTop: '20px' }}>
                                <Typography variant="subtitle2" style={{ fontSize: 14 }}>
                                    Are you sure you want to remove license/s for the following user/s?
                                </Typography>
                            </Grid>
                        ) : (
                            <></>
                            // <Grid item sm={12} xs={12} style={{ padding: 0, paddingTop: 20 }}>
                            //     <Typography variant="subtitle2" style={{ fontSize: 14 }}>
                            //         Found {planDetails.length} subscriptions. Select a plan to assign licenses:
                            //     </Typography>
                            //     <FormControl
                            //         variant="outlined"
                            //         style={{
                            //             width: '100%',
                            //             background: theme.palette.background.paper,
                            //             marginBottom: 10,
                            //         }}
                            //         margin="dense"
                            //     >
                            //         <Select
                            //             displayEmpty
                            //             value={currentPlan}
                            //             onChange={handlePlan}
                            //             fullWidth
                            //             disableUnderline
                            //             input={<Input />}
                            //             MenuProps={{
                            //                 anchorOrigin: {
                            //                     vertical: 'bottom',
                            //                     horizontal: 'center',
                            //                 },
                            //                 transformOrigin: {
                            //                     vertical: 'top',
                            //                     horizontal: 'center',
                            //                 },
                            //                 getContentAnchorEl: null,
                            //                 PaperProps: {
                            //                     style: {
                            //                         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            //                         width: 250,
                            //                     },
                            //                 },
                            //             }}
                            //             style={{
                            //                 border: '1px solid #C4C4C4',
                            //                 padding: '5px',
                            //                 borderRadius: '4px',
                            //                 height: 40,
                            //                 fontSize: 14,
                            //             }}
                            //             inputProps={{
                            //                 'aria-label': 'Select plan',
                            //                 style: {
                            //                     padding: 5,
                            //                 },
                            //             }}
                            //             className={classes.selectInput}
                            //             renderValue={(selected: any) => {
                            //                 // console.log('selected: ', selected);
                            //                 if ((selected as string[]).length === 0) {
                            //                     return <span>Select Plan</span>;
                            //                 }

                            //                 return <span style={{ textTransform: 'none' }}>{selected.name}</span>;
                            //             }}
                            //         >
                            //             {planDetails.map((plan: any, index: number) => (
                            //                 <MenuItem key={index} value={plan}>
                            //                     {plan.name} ({plan.licenseCount} licenses) | Ends on {plan.endDate}
                            //                 </MenuItem>
                            //             ))}
                            //         </Select>
                            //     </FormControl>
                            // </Grid>
                        )}
                        <Grid item sm={12} xs={12} style={{ padding: '20px 0px' }}>
                            <div className={classes.listHeader}>
                                <Typography>{selectedUsers.length} user/s selected</Typography>
                            </div>
                            <List component="nav" className={classes.listStyle}>
                                {selectedUsers.map((cl: any, i: number) => (
                                    <ListItem key={`users-${i}`} style={{ fontSize: 14, padding: 0 }}>
                                        <ListItemAvatar className={classes.listItemAvatar}>
                                            <Avatar alt={cl.name} style={{ height: 20, width: 20 }}>
                                                {cl.name[0]}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            style={{ display: 'flex', flexDirection: 'column', color: '#444444' }}
                                        >
                                            {cl.name}
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="cancel"
                                                size="small"
                                                disableRipple
                                                disableFocusRipple
                                                className={classes.closeIcon}
                                                onClick={() => handleCancel(cl.empId)}
                                            >
                                                <CloseIcon fontSize="small" style={{ height: 15, width: 15 }} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid container item sm={8} xs={7} justifyContent="flex-end" style={{ padding: 0 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={toggleModal}
                                className={classes.cancelBtn}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid container item sm={4} xs={5} justifyContent="flex-end" style={{ padding: 0 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={updateLicenses}
                                className={classes.confirmBtn}
                                disabled={selectedUsers.length === 0}
                            >
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </MyModal>
                <Paper elevation={0} className={classes.paperstyle}>
                    <Typography className={classes.title}>License Assignment</Typography>
                </Paper>
                {/* <Hidden smDown> */}
                <Hidden only={['xs', 'sm']}>
                    <Paper elevation={0} className={classes.outerDiv}>
                        <div>
                            <div className={classes.divContainer}>
                                {licenses.map(
                                    (license: any, index: number) =>
                                        !hasFeature(`hide_${license.id}_subscription_card`) &&
                                        license?.feature && (
                                            <div
                                                key={index}
                                                style={{
                                                    padding: '5px 0px 5px 5px',
                                                }}
                                                onClick={() => handleSelectedPlan(index)}
                                            >
                                                <Paper
                                                    style={{
                                                        cursor: 'pointer',
                                                        backgroundColor:
                                                            index === currentSelectedPlan
                                                                ? theme.palette.subscriptionCardBackground
                                                                : '',
                                                    }}
                                                    className={classes.dpaper}
                                                >
                                                    {(license.status === 'Subscribed' ||
                                                        license.status === 'Suspended' ||
                                                        license.status === 'Unsubscribed' ||
                                                        license.status === 'Not Subscribed') && (
                                                        <Chip
                                                            size="medium"
                                                            avatar={
                                                                license.status === 'Subscribed' ? (
                                                                    <img
                                                                        src={ActiveIcon}
                                                                        alt="Subscribed"
                                                                        style={{ height: 12, width: 12 }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={HoldIcon}
                                                                        alt="onHold"
                                                                        style={{ height: 12, width: 12 }}
                                                                    />
                                                                )
                                                            }
                                                            label={license.status}
                                                            className={
                                                                license.status === 'Subscribed'
                                                                    ? classes.activeChip
                                                                    : classes.holdChip
                                                            }
                                                        />
                                                    )}
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        alignItems="center"
                                                        className={classes.gridContainer}
                                                    >
                                                        <Grid
                                                            container
                                                            item
                                                            sm={12}
                                                            alignItems="flex-start"
                                                            justifyContent="center"
                                                        >
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="flex-end"
                                                            >
                                                                <Grid
                                                                    container
                                                                    item
                                                                    sm={1}
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                >
                                                                    <DateRangeIcon style={{ height: 20, width: 20 }} />
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    item
                                                                    sm={11}
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                >
                                                                    <Typography
                                                                        variant="h4"
                                                                        className={classes.innerTitle}
                                                                    >
                                                                        {license?.feature}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid container item sm={11}>
                                                                    {license.status === 'Suspended' && (
                                                                        <Typography
                                                                            variant="body2"
                                                                            style={{ paddingLeft: 5 }}
                                                                        >
                                                                            Account on hold
                                                                        </Typography>
                                                                    )}
                                                                    {license.status === 'Subscribed' && (
                                                                        <Typography
                                                                            variant="body2"
                                                                            style={{ paddingLeft: 5 }}
                                                                        >
                                                                            {license.planDetails.plan.includes(
                                                                                'freeplan',
                                                                            )
                                                                                ? 'Free'
                                                                                : license.planDetails.plan.includes(
                                                                                      'paidplan',
                                                                                  )
                                                                                ? 'Business'
                                                                                : 'Private'}{' '}
                                                                            | {license.planDetails.licenseCount}{' '}
                                                                            licenses
                                                                        </Typography>
                                                                    )}
                                                                    {license.status === 'Not Subscribed' && (
                                                                        <Typography
                                                                            variant="body2"
                                                                            style={{ paddingLeft: 5 }}
                                                                        >
                                                                            Subscribe now
                                                                        </Typography>
                                                                    )}
                                                                    {license.status === 'Unsubscribed' && (
                                                                        <Typography
                                                                            variant="body2"
                                                                            style={{ paddingLeft: 5 }}
                                                                        >
                                                                            Subscription cancelled
                                                                        </Typography>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            sm={12}
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            style={{ height: '100%' }}
                                                        >
                                                            {license.status === 'Subscribed' && (
                                                                // addedBots[license.id] ? (
                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12}>
                                                                        <Typography variant="body2">
                                                                            <b>Billing:</b>{' '}
                                                                            {license.planDetails.billing}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={12}>
                                                                        <Typography variant="body2">
                                                                            <b>Next payment date:</b>{' '}
                                                                            {license.planDetails.nextDate}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={12}>
                                                                        <Typography variant="body2">
                                                                            <b>Purchased by:</b>{' '}
                                                                            {license.planDetails.purchasedBy}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {/* {index === currentSelectedPlan && ( */}
                                                                    <Grid item sm={12}>
                                                                        <Typography variant="body2">
                                                                            <b>License Usage:</b>
                                                                        </Typography>
                                                                        <Box display="flex" alignItems="center">
                                                                            <Box width="100%" mr={1}>
                                                                                <LinearProgress
                                                                                    className={
                                                                                        license.planDetails.usage >=
                                                                                        license.planDetails.licenseCount
                                                                                            ? classes.progressBarRed
                                                                                            : classes.progressBar
                                                                                    }
                                                                                    variant="determinate"
                                                                                    value={
                                                                                        license.planDetails.usage >=
                                                                                        license.planDetails.licenseCount
                                                                                            ? 100
                                                                                            : normalise(
                                                                                                  license.planDetails
                                                                                                      .usage,
                                                                                                  license.planDetails
                                                                                                      .licenseCount,
                                                                                              )
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                            <Box minWidth={35}>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="textSecondary"
                                                                                >
                                                                                    {license.planDetails.usage}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {license.status === 'Suspended' && (
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    style={{ textAlign: 'justify' }}
                                                                >
                                                                    <Grid item sm={12}>
                                                                        <Typography variant="body2">
                                                                            We&apos;re having some trouble with your
                                                                            current billing information.
                                                                        </Typography>
                                                                        <Typography variant="body2">
                                                                            {' '}
                                                                            We&apos;ll try again, but in the meantime
                                                                            you may want to update your payment details.
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        sm={12}
                                                                        justifyContent="flex-end"
                                                                        alignItems="flex-end"
                                                                    >
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className={classes.updateBtn}
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    'https://admin.microsoft.com/Adminportal/Home?#/subscriptions',
                                                                                );
                                                                            }}
                                                                        >
                                                                            Update Account
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {license.status === 'Unsubscribed' && (
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    style={{ textAlign: 'justify' }}
                                                                >
                                                                    <Grid item sm={12}>
                                                                        <Typography variant="body2">
                                                                            You have cancelled your subscription.
                                                                        </Typography>
                                                                        <Typography variant="body2">
                                                                            Due to restrictions from microsoft&apos;s
                                                                            side we cannot reactivate it. You need to
                                                                            buy a new subscription and re-assign
                                                                            licenses
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        sm={12}
                                                                        justifyContent="flex-end"
                                                                        alignItems="flex-end"
                                                                    >
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className={classes.updateBtn}
                                                                            onClick={() => {
                                                                                window.open(appSourceUrl[license?.id]);
                                                                            }}
                                                                        >
                                                                            Buy A Subscription
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {license.status === 'Not Subscribed' && (
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    style={{ textAlign: 'justify' }}
                                                                >
                                                                    <Grid item sm={12}>
                                                                        <Typography variant="body2">
                                                                            You currently haven&apos;t purchased the
                                                                            licenses for this bot yet, please visit
                                                                            microsoft teams appsource to buy a
                                                                            subscription and continue
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        sm={12}
                                                                        justifyContent="flex-end"
                                                                        alignItems="flex-end"
                                                                    >
                                                                        <Button
                                                                            onClick={() => {
                                                                                window.open(appSourceUrl[license?.id]);
                                                                            }}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className={classes.updateBtn}
                                                                        >
                                                                            Get Subscription
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        ),
                                )}
                            </div>
                            <div style={{ marginTop: 10, paddingLeft: 5 }}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item sm={12}>
                                        <Typography className={classes.outerTitle}>
                                            {/* Manage licenses for Time-Offs by TeamPlus */}
                                            Manage licenses for {licenses[currentSelectedPlan]?.feature}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <TextField
                                            id="searchname"
                                            placeholder="Employees/Email"
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange}
                                            className={classes.searchBorder}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className={classes.searchIcon}>
                                                        <span
                                                            style={{
                                                                // marginRight: 10,
                                                                fontSize: 16,
                                                                fill: theme.palette.primary.contrastText,
                                                            }}
                                                            className="iconify"
                                                            data-icon="octicon:search-16"
                                                        ></span>
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    root: classes.searchInput,
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: theme.palette.primary.contrastText,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <ButtonGroup disableElevation variant="outlined" color="primary">
                                            <Button
                                                disableFocusRipple
                                                className={
                                                    showLicensed === true ? classes.selectedBtn : classes.btnGroup
                                                }
                                                onClick={() => handleFilter('licensed')}
                                            >
                                                <img src={TickFilled} alt="licensed" style={{ height: 12 }} />{' '}
                                                &nbsp;Licensed
                                            </Button>
                                            <Button
                                                disableFocusRipple
                                                className={
                                                    showLicensed === false ? classes.selectedBtn : classes.btnGroup
                                                }
                                                onClick={() => handleFilter('unlicensed')}
                                            >
                                                <img src={CloseFilled} alt="unlicensed" style={{ height: 12 }} />{' '}
                                                &nbsp;Unlicensed
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid container item justifyContent="flex-end" sm={5}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.actionBtn}
                                            style={{ marginRight: '10px' }}
                                            onClick={syncEmployees}
                                            disabled={loading ? true : false}
                                        >
                                            {loading && (
                                                <i className="fa fa-refresh fa-spin" style={{ marginRight: '5px' }} />
                                            )}
                                            {loading && <span>Syncing Employees</span>}
                                            {!loading && <span>Sync Employees</span>}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.actionBtn}
                                            onClick={validateLicenseCount}
                                            disabled={
                                                (licenses[currentSelectedPlan]?.status === 'Suspended' ||
                                                    licenses[currentSelectedPlan]?.status === 'Unsubscribed') &&
                                                true
                                            }
                                        >
                                            {showLicensed ? 'Remove Licenses' : 'Assign Licenses'}
                                        </Button>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <TableContainer component="div" className={classes.container}>
                                            <Table
                                                stickyHeader
                                                className={classes.tables}
                                                aria-label="simple table"
                                                size="small"
                                            >
                                                <TableHead style={{ zIndex: 999, backgroundColor: '#fff' }}>
                                                    <TableRow style={{ height: 20 }}>
                                                        <TableCell
                                                            align="center"
                                                            className={classes.idcolumn}
                                                            id="selectAll"
                                                        >
                                                            <Checkbox
                                                                checked={checkedAll}
                                                                onChange={handleSelectAll}
                                                                value="All"
                                                                inputProps={{ 'aria-label': 'Select all employees' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={classes.tablecolumnname}
                                                            id="empName"
                                                        >
                                                            Name
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={classes.tablecolumnname}
                                                            id="email"
                                                        >
                                                            Email
                                                        </TableCell>
                                                        {/* <TableCell
                                                            align="left"
                                                            className={classes.tablecolumnname}
                                                            id="empName"
                                                        >
                                                            Departments
                                                        </TableCell> */}
                                                        {/* <TableCell
                                                            align="left"
                                                            className={classes.tablecolumnname}
                                                            id="empName"
                                                        >
                                                            Assigned by
                                                        </TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody style={{ textTransform: 'capitalize' }}>
                                                    {currentLicenses.length > 0 ? (
                                                        <>
                                                            {currentLicenses.map((cell: any, i: number) => (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    onClick={(event: any) =>
                                                                        handleSelect(event, cell.empId, cell)
                                                                    }
                                                                    selected={isSelected(cell.empId)}
                                                                    key={`row-${i}`}
                                                                    className={classes.rows}
                                                                    style={{ height: '20px' }}
                                                                >
                                                                    <TableCell align="center" style={{ padding: 0 }}>
                                                                        <Checkbox
                                                                            checked={isSelected(cell.empId)}
                                                                            value={cell}
                                                                            inputProps={{
                                                                                'aria-label': 'Select employee',
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="left"
                                                                        className={classes.tableCell}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            alignItems="center"
                                                                            justifyContent="flex-start"
                                                                            spacing={1}
                                                                            style={{
                                                                                color: theme.palette.text.primary,
                                                                            }}
                                                                        >
                                                                            <Grid item xs={2} sm={2}>
                                                                                <Avatar
                                                                                    src={cell.profile}
                                                                                    style={{ height: 30, width: 30 }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={10} sm={10}>
                                                                                <Typography>{cell.name}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="left"
                                                                        className={classes.tableCell}
                                                                        style={{
                                                                            color: theme.palette.text.primary,
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            textTransform: 'none',
                                                                        }}
                                                                    >
                                                                        <Typography>{cell.email}</Typography>
                                                                    </TableCell>
                                                                    {/* <TableCell
                                                                        align="left"
                                                                        className={classes.tableCell}
                                                                        style={{ color: theme.palette.text.primary }}
                                                                    >
                                                                        {cell.departments.length === 0 ? (
                                                                            '-'
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    {cell.departments.map(
                                                                                        (dept: any) => (
                                                                                            <div key={dept.id}>
                                                                                                {dept.organizationName}
                                                                                            </div>
                                                                                        ),
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </TableCell> */}
                                                                    {/* <TableCell
                                                                        align="left"
                                                                        className={classes.tableCell}
                                                                    >
                                                                        {cell.assignedBy !== null ? (
                                                                            <Grid
                                                                                container
                                                                                alignItems="center"
                                                                                justifyContent="flex-start"
                                                                                spacing={1}
                                                                                style={{
                                                                                    color: theme.palette.text.primary,
                                                                                }}
                                                                            >
                                                                                <Grid item xs={2} sm={2}>
                                                                                    <Avatar
                                                                                        src={cell.assignedBy.profile}
                                                                                        style={{
                                                                                            height: 30,
                                                                                            width: 30,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={10} sm={10}>
                                                                                    <Typography>
                                                                                        {cell.assignedBy.name}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ) : (
                                                                            '-'
                                                                        )}
                                                                    </TableCell> */}
                                                                </TableRow>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <TableRow className={classes.rows} style={{ height: '20px' }}>
                                                            <TableCell
                                                                align="center"
                                                                colSpan={5}
                                                                style={{
                                                                    padding: 20,
                                                                    fontSize: 14,
                                                                    textTransform: 'none',
                                                                    color: theme.palette.text.primary,
                                                                }}
                                                            >
                                                                <div className={classes.rowmessage}>
                                                                    <img
                                                                        src={upcomingTimeoff}
                                                                        alt="No data Found"
                                                                        className={classes.notfoundImage}
                                                                    />
                                                                    <Typography
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            fontSize: 14,
                                                                            color: theme.palette.text.primary,
                                                                        }}
                                                                    >
                                                                        No data found
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Paper>
                </Hidden>
                {/* <Hidden smUp> */}
                <Hidden only={['md', 'lg', 'xl']}>
                    <div className={classes.divcss}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.btnFixed}
                            onClick={validateLicenseCount}
                            disabled={
                                (licenses[activeStep]?.status === 'Suspended' ||
                                    licenses[activeStep]?.status === 'Unsubscribed') &&
                                true
                            }
                        >
                            {showLicensed ? 'Remove Licenses' : 'Assign Licenses'}
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.btnFixed1}
                            onClick={syncEmployees}
                            disabled={loading ? true : false}
                        >
                            {loading && <i className="fa fa-refresh fa-spin" style={{ marginRight: '5px' }} />}
                            {loading && <span>Syncing Employees</span>}
                            {!loading && <span>Sync Employees</span>}
                        </Button>
                    </div>
                    {!hasFeature(`hide_${licenses[activeStep]?.id}_subscription_card`) &&
                        licenses[activeStep]?.feature && (
                            <Paper elevation={0} className={classes.outerDiv}>
                                <div style={{ justifyContent: 'center' }}>
                                    <div className={classes.divContainer}>
                                        <Paper elevation={3} className={classes.dpaper}>
                                            {(licenses[activeStep]?.status === 'Subscribed' ||
                                                licenses[activeStep]?.status === 'Suspended' ||
                                                licenses[activeStep]?.status === 'Not Subscribed' ||
                                                licenses[activeStep]?.status === 'Unsubscribed') && (
                                                <Chip
                                                    size="small"
                                                    avatar={
                                                        licenses[activeStep]?.status === 'Subscribed' ? (
                                                            <img
                                                                src={ActiveIcon}
                                                                alt="active"
                                                                style={{ height: 12, width: 12 }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={HoldIcon}
                                                                alt="onHold"
                                                                style={{ height: 12, width: 12 }}
                                                            />
                                                        )
                                                    }
                                                    label={licenses[activeStep]?.status}
                                                    className={
                                                        licenses[activeStep]?.status === 'Subscribed'
                                                            ? classes.activeChip
                                                            : classes.holdChip
                                                    }
                                                />
                                            )}
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                                className={classes.gridContainer}
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    sm={12}
                                                    alignItems="flex-start"
                                                    justifyContent="center"
                                                >
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent="flex-end"
                                                    >
                                                        <Grid
                                                            container
                                                            item
                                                            sm={1}
                                                            xs={1}
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                        >
                                                            <DateRangeIcon />
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            sm={11}
                                                            xs={11}
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                        >
                                                            <Typography variant="h4" className={classes.innerTitle}>
                                                                {licenses[activeStep]?.feature}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid container item sm={11} xs={11}>
                                                            {licenses[activeStep]?.status === 'Suspended' && (
                                                                <Typography variant="body2" style={{ paddingLeft: 5 }}>
                                                                    Account on hold
                                                                </Typography>
                                                            )}
                                                            {licenses[activeStep]?.status === 'Subscribed' && (
                                                                <Typography variant="body2" style={{ paddingLeft: 5 }}>
                                                                    {licenses[activeStep]?.planDetails.plan.includes(
                                                                        'freeplan',
                                                                    )
                                                                        ? 'Free'
                                                                        : licenses[
                                                                              activeStep
                                                                          ]?.planDetails.plan.includes('paidplan')
                                                                        ? 'Business'
                                                                        : 'Private'}{' '}
                                                                    | {licenses[activeStep]?.planDetails.licenseCount}{' '}
                                                                    licenses
                                                                </Typography>
                                                            )}
                                                            {licenses[activeStep]?.status === 'Not Subscribed' && (
                                                                <Typography variant="body2" style={{ paddingLeft: 5 }}>
                                                                    Subscribe now
                                                                </Typography>
                                                            )}
                                                            {licenses[activeStep]?.status === 'Unsubscribed' && (
                                                                <Typography variant="body2" style={{ paddingLeft: 5 }}>
                                                                    Subscription cancelled
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    sm={12}
                                                    xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{ height: '100%' }}
                                                >
                                                    {licenses[activeStep]?.status === 'Subscribed' && (
                                                        <Grid container spacing={1}>
                                                            <Grid item sm={12} xs={12}>
                                                                <Typography variant="body2">
                                                                    <b>Billing:</b>{' '}
                                                                    {licenses[activeStep]?.planDetails.billing}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sm={12} xs={12}>
                                                                <Typography variant="body2">
                                                                    <b>Next payment date:</b>{' '}
                                                                    {licenses[activeStep]?.planDetails.nextDate}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sm={12} xs={12}>
                                                                <Typography variant="body2">
                                                                    <b>Purchased by:</b>{' '}
                                                                    {licenses[activeStep]?.planDetails.purchasedBy}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sm={12} xs={12}>
                                                                <Typography variant="body2">
                                                                    <b>License Usage:</b>
                                                                </Typography>
                                                                <Box display="flex" alignItems="center">
                                                                    <Box width="100%" mr={1}>
                                                                        <LinearProgress
                                                                            variant="determinate"
                                                                            value={
                                                                                licenses[activeStep]?.planDetails
                                                                                    .usage >=
                                                                                licenses[activeStep]?.planDetails
                                                                                    .licenseCount
                                                                                    ? 100
                                                                                    : normalise(
                                                                                          licenses[activeStep]
                                                                                              ?.planDetails.usage,
                                                                                          licenses[activeStep]
                                                                                              ?.planDetails
                                                                                              .licenseCount,
                                                                                      )
                                                                            }
                                                                            className={
                                                                                licenses[activeStep]?.planDetails
                                                                                    .usage >=
                                                                                licenses[activeStep]?.planDetails
                                                                                    .licenseCount
                                                                                    ? classes.progressBarRed
                                                                                    : classes.progressBar
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    <Box minWidth={35}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                        >
                                                                            {licenses[activeStep]?.planDetails.usage}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {licenses[activeStep]?.status === 'Suspended' && (
                                                        <Grid container spacing={2} style={{ textAlign: 'justify' }}>
                                                            <Grid item sm={12} xs={12}>
                                                                <Typography variant="body2">
                                                                    We&apos;re having some trouble with your current
                                                                    billing information.
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    {' '}
                                                                    We&apos;ll try again, but in the meantime you may
                                                                    want to update your payment details.
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                sm={12}
                                                                xs={12}
                                                                justifyContent="flex-end"
                                                                alignItems="flex-end"
                                                            >
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.updateBtn}
                                                                    onClick={() => {
                                                                        window.open(
                                                                            'https://admin.microsoft.com/Adminportal/Home?#/subscriptions',
                                                                        );
                                                                    }}
                                                                >
                                                                    Update Account
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    {licenses[activeStep]?.status === 'Unsubscribed' && (
                                                        <Grid container spacing={2} style={{ textAlign: 'justify' }}>
                                                            <Grid item sm={12} xs={12}>
                                                                <Typography variant="body2">
                                                                    You have cancelled your subscription.
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    Due to restrictions from microsoft&apos;s side , we
                                                                    cannot reactivate it. You need to buy a new
                                                                    subscription and re-assign licenses
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                sm={12}
                                                                xs={12}
                                                                justifyContent="flex-end"
                                                                alignItems="flex-end"
                                                            >
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.updateBtn}
                                                                    onClick={() => {
                                                                        window.open(
                                                                            appSourceUrl[licenses[activeStep]?.id],
                                                                        );
                                                                    }}
                                                                >
                                                                    Buy A Subscription
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    {licenses[activeStep]?.status === 'Not Subscribed' && (
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            direction="column"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            style={{ height: '100%' }}
                                                        >
                                                            <Grid item sm={12}>
                                                                <Typography variant="body2">
                                                                    You currently haven&apos;t purchased the licenses
                                                                    for this bot yet, please visit microsoft teams
                                                                    appsource to buy a subscription and continue
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                sm={12}
                                                                justifyContent="flex-end"
                                                                alignItems="flex-end"
                                                            >
                                                                <Button
                                                                    onClick={() => {
                                                                        window.open(
                                                                            appSourceUrl[licenses[activeStep]?.id],
                                                                        );
                                                                    }}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.updateBtn}
                                                                >
                                                                    Get Subscription
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </div>
                                    <div className={classes.stepperDiv}>
                                        <MobileStepper
                                            variant="dots"
                                            steps={maxSteps}
                                            position="static"
                                            activeStep={activeStep}
                                            backButton={
                                                <Button
                                                    size="small"
                                                    onClick={handleBack}
                                                    disabled={activeStep === 0}
                                                    className={classes.controls}
                                                    style={{ justifyContent: 'flex-start' }}
                                                >
                                                    {theme.direction === 'rtl' ? (
                                                        <KeyboardArrowRight style={{ padding: 0 }} />
                                                    ) : (
                                                        <KeyboardArrowLeft style={{ padding: 0 }} />
                                                    )}
                                                    Back
                                                </Button>
                                            }
                                            nextButton={
                                                <Button
                                                    size="small"
                                                    onClick={handleNext}
                                                    disabled={activeStep === maxSteps - 1}
                                                    className={classes.controls}
                                                    style={{ justifyContent: 'flex-end' }}
                                                >
                                                    Next
                                                    {theme.direction === 'rtl' ? (
                                                        <KeyboardArrowLeft style={{ padding: 0 }} />
                                                    ) : (
                                                        <KeyboardArrowRight style={{ padding: 0 }} />
                                                    )}
                                                </Button>
                                            }
                                            className={classes.stepper}
                                        />
                                    </div>
                                    <div style={{ marginTop: 10, paddingLeft: 5, marginBottom: 40 }}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            direction="row"
                                            spacing={2}
                                        >
                                            <Grid item sm={12} xs={12}>
                                                <Typography className={classes.outerTitle}>
                                                    Manage licenses for {licenses[currentSelectedPlan]?.feature}
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={12} xs={12} style={{ padding: 0 }}>
                                                <Button
                                                    disableFocusRipple
                                                    className={
                                                        showLicensed === true
                                                            ? classes.selectedBtnMobile
                                                            : classes.btnGroupMobile
                                                    }
                                                    onClick={() => handleFilter('licensed')}
                                                >
                                                    <img src={TickFilled} alt="licensed" style={{ height: 12 }} />{' '}
                                                    &nbsp;Licensed
                                                </Button>
                                                <Button
                                                    disableFocusRipple
                                                    className={
                                                        showLicensed === false
                                                            ? classes.selectedBtnMobile
                                                            : classes.btnGroupMobile
                                                    }
                                                    onClick={() => handleFilter('unlicensed')}
                                                >
                                                    <img src={CloseFilled} alt="unlicensed" style={{ height: 12 }} />{' '}
                                                    &nbsp;Unlicensed
                                                </Button>
                                            </Grid>
                                            <Grid item sm={12} xs={12} style={{ padding: '10px 0px' }}>
                                                <TextField
                                                    id="searchname"
                                                    placeholder="Employees/Email"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    className={classes.searchBorder}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                className={classes.searchIcon}
                                                            >
                                                                <span
                                                                    style={{
                                                                        // marginRight: 10,
                                                                        fontSize: 16,
                                                                        fill: theme.palette.primary.contrastText,
                                                                    }}
                                                                    className="iconify"
                                                                    data-icon="octicon:search-16"
                                                                ></span>
                                                            </InputAdornment>
                                                        ),
                                                        classes: {
                                                            root: classes.searchInput,
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: theme.palette.primary.contrastText,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            {currentLicenses.length !== 0 && (
                                                <Grid
                                                    container
                                                    item
                                                    sm={12}
                                                    xs={12}
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    style={{ padding: 0 }}
                                                >
                                                    <Checkbox
                                                        checked={checkedAll}
                                                        onChange={handleSelectAll}
                                                        value="All"
                                                        inputProps={{ 'aria-label': 'Select all' }}
                                                    />
                                                    <Typography style={{ fontSize: 14 }}>Select all</Typography>
                                                </Grid>
                                            )}
                                            <Grid
                                                container
                                                item
                                                sm={12}
                                                xs={12}
                                                style={{ padding: '5px 0px' }}
                                                spacing={2}
                                            >
                                                {currentLicenses.length === 0 ? (
                                                    <div className={classes.rowmessage}>
                                                        <img
                                                            src={upcomingTimeoff}
                                                            alt="No data Found"
                                                            className={classes.notfoundImage}
                                                        />
                                                        <Typography
                                                            style={{
                                                                textAlign: 'center',
                                                                fontSize: 14,
                                                                color: theme.palette.text.primary,
                                                            }}
                                                        >
                                                            No data found
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {currentLicenses.map((cell: any, i: number) => (
                                                            <Grid
                                                                container
                                                                key={`cl-${i}`}
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                style={{
                                                                    borderBottom: '1px solid #E0E0E0',
                                                                    padding: '5px 0px',
                                                                }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    item
                                                                    xs={1}
                                                                    sm={1}
                                                                    justifyContent="flex-start"
                                                                >
                                                                    <Checkbox
                                                                        checked={isSelected(cell.empId)}
                                                                        onChange={(event: any) =>
                                                                            handleSelect(event, cell.empId, cell)
                                                                        }
                                                                        value="All"
                                                                        inputProps={{ 'aria-label': 'Select all' }}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    item
                                                                    xs={2}
                                                                    sm={2}
                                                                    justifyContent="center"
                                                                >
                                                                    <Avatar alt={cell.name}>{cell.name[0]}</Avatar>
                                                                </Grid>
                                                                <Grid item xs={9} sm={9}>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        style={{ fontSize: 16, fontWeight: 'bold' }}
                                                                    >
                                                                        {cell.name}
                                                                    </Typography>
                                                                    {cell.renewOn !== null && (
                                                                        <Typography
                                                                            variant="body2"
                                                                            style={{
                                                                                fontSize: 14,
                                                                                whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                textTransform: 'none',
                                                                            }}
                                                                        >
                                                                            {cell.email}
                                                                        </Typography>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Paper>
                        )}
                </Hidden>
            </div>
        </div>
    );
}
