import React from 'react';
import useStyles from './secondStyle';
import { Grid, TextField, Button, Chip, Typography } from '@material-ui/core';
import Validate from '../../Validator/validateRegister';
import CloseIcon from '@material-ui/icons/Close';
// import bgImg from './bgImg.png';
import FormError from '../Error/RegisterFormError';
import MyModal from '../Modal/Modal';
// import TeamPlusLogo from '../../Assets/images/teamPlus_white.png';

interface Props {
    prevStep?: any;
    nextStep?: any;
    isOpen?: boolean;
    setOpen?: any;
    values?: any;
    handleRegister?: any;
    handleSubmit?: any;
    stepCount?: any;
    // totalCount?: any;
}

export default function Second(props: Props): JSX.Element {
    const classes = useStyles();
    const [error, setError] = React.useState<any>();

    const [alert, setAlert] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>('');

    const handleDelete = (id: any) => {
        props.values.setAllEmails(props.values.allEmails.filter((e: any, i: number) => i !== id));
    };
    const handleEmails = async (value: any) => {
        const data = {
            email: value,
        };
        const err = await Validate(data);
        if (err === null) {
            props.values.setAllEmails(props.values.allEmails.concat(value));
            props.values.setMEmail('');
            setError(null);
        } else {
            setError(err);
        }
    };

    const handleChange = async () => {
        if (props.values.allEmails.length === 0) {
            setAlert(true);
            setAlertMessage(
                "You haven't selected any email to send Invitation. You can add the emails to send the invitation or you can skip it for now or do it later from Employee tab.",
            );
        } else {
            await props.handleRegister();
        }
    };

    const handleSkip = async () => {
        setAlert(true);
        setAlertMessage(
            "You haven't selected any email to send Invitation. You can add the emails to send the invitation or you can skip it for now or do it later from Employee tab.",
        );
    };

    const handleAlert = async () => {
        setAlert(false);
        await props.handleSubmit();
    };

    return (
        <>
            <MyModal setOpen={setAlert} open={alert}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <h4
                        style={{
                            padding: 5,
                            color: '#666666',
                            fontSize: 18,
                            fontWeight: 400,
                            fontFamily: 'Nunito Sans',
                        }}
                    >
                        {alertMessage}
                    </h4>
                    <p
                        style={{
                            padding: 5,
                            color: '#666666',
                            fontSize: 18,
                            fontWeight: 400,
                            fontFamily: 'Nunito Sans',
                        }}
                    >
                        Are you sure you want to proceed ahead and add your team to TeamPlus?
                    </p>
                    <div style={{ padding: '5px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            id="cancel"
                            variant="outlined"
                            color="primary"
                            onClick={() => setAlert(false)}
                            className={classes.btnOutlined}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="OK"
                            variant="contained"
                            color="primary"
                            onClick={() => handleAlert()}
                            className={classes.btnPrimary}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </MyModal>
            <Grid
                item
                xs={12}
                lg={12}
                xl={5}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                {/* <Grid item sm={12} xs={12} className={classes.counterDiv}>
                    <h2 className={classes.counter}>
                        {props.stepCount}/{props.totalCount}
                    </h2>
                </Grid> */}
                <Grid container item xs={12} sm={12}>
                    <Grid item xs={12} className={classes.addEmployeeLabel}>
                        <h2 className={classes.font}>Add Employees to your Organization</h2>
                    </Grid>
                    <Grid item xs={12} className={classes.addEmployeeLabel}>
                        <FormError formerrors={{ error }} />
                    </Grid>
                    <Grid item xs={12}>
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                handleEmails(props.values.memail);
                            }}
                            className={classes.addEmailNew}
                        >
                            <TextField
                                id="addEmployee"
                                value={props.values.memail}
                                variant="outlined"
                                fullWidth
                                placeholder="Add Employee Email IDs"
                                className={classes.textfield}
                                onChange={e => props.values.setMEmail(e.target.value)}
                                size="small"
                            />
                            <Button
                                id="add"
                                color="primary"
                                variant="contained"
                                className={classes.addBtn}
                                type="submit"
                                // onClick={() => handleEmails(props.values.memail)}
                            >
                                Add
                            </Button>
                        </form>
                    </Grid>
                    <Grid item xs={12} className={classes.chipsEmailDisplay}>
                        {props.values.allEmails.map((e: any, i: number) => (
                            <Chip
                                key={i}
                                label={e}
                                style={{ backgroundColor: '#3C48B5', color: 'white', margin: '2px 2px' }}
                                onDelete={() => handleDelete(i)}
                                deleteIcon={<CloseIcon fontSize="small" style={{ color: 'white', fontSize: 10 }} />}
                            />
                        ))}
                    </Grid>
                    {/* <Grid item xs={12} className={classes.backAndSubmitBtn}>
                    <Grid item xs={6}>
                        <Button color="primary" variant="contained" onClick={() => props.prevStep()}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="primary" variant="contained" onClick={() => handleChange()}>
                            Submit
                        </Button>
                    </Grid>
                </Grid> */}
                    <Grid item xs={12} className={classes.submitButtonCSS}>
                        <Button
                            id="submit"
                            color="primary"
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.hoverStyle}
                            onClick={() => handleChange()}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.submitButtonCSS}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="outlined"
                            className={classes.hoverStyleOutline}
                            onClick={() => props.prevStep()}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.submitButtonCSS}>
                        <Typography className={classes.skip} onClick={() => handleSkip()}>
                            Skip
                        </Typography>
                    </Grid>
                </Grid>
                {/* </div> */}
            </Grid>
        </>
    );
}
