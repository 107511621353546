import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 100,
            zIndex: 999,
            '@media (max-width: 650px)': {
                padding: '60px 10px',
            },
        },
        paper: {
            width: '40%',
            maxHeight: 600,
            // overflowY: 'scroll',
            padding: theme.spacing(2),
            paddingBottom: '5px',
            borderRadius: '0px',
            backgroundColor: theme.palette.background.paper,
            '@media screen and (max-width: 650px)': {
                width: '100%',
                height: 'auto',
                padding: theme.spacing(2),
            },
            '@media screen and (width: 1024px) and (height: 1366px)': {
                width: '50%',
                height: '100%',
                padding: theme.spacing(1),
            },
            '@media screen and (width: 768px) and (height: 1024px)': {
                width: '60%',
                height: '100%',
                padding: theme.spacing(2),
            },
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            fontSize: '22px',
            color: theme.palette.text.primary,
            fontWeight: 500,
            '@media (max-width: 650px)': {
                fontSize: '20px',
            },
        },
        headerTwo: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'relative',
            alignItems: 'center',
            fontSize: '20px',
            paddingLeft: 20,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            '@media (max-width: 650px)': {
                fontSize: '16px',
                paddingLeft: 0,
            },
        },
        stepHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-between',
            fontSize: '22px',
            color: '#444444',
            fontWeight: 500,
            '@media (max-width: 650px)': {
                fontSize: '20px',
            },
        },
        content: {
            padding: '0 20px',
            color: theme.palette.text.primary,
            '@media screen and (max-width: 760px)': {
                fontSize: '10px',
                padding: '0 5px',
                overflow: 'auto',
                height: '60vh',
                '&::-webkit-scrollbar': {
                    width: 4,
                },
                '&::-webkit-scrollbar-track': {
                    borderRadius: 10,
                    backgroundColor: theme.palette.secondary.main,
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: 10,
                    backgroundColor: theme.palette.primary.main,
                    height: 5,
                },
            },
            '@media only screen and (min-width: 1023.5px)': {
                paddingBottom: 20,
            },
        },
        modfontsty: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '14px',
        },
        snackbar: {
            marginTop: '35px',
            '@media (max-width: 650px)': {
                marginTop: '60px',
            },
        },
        subscriptionSnackbar: {
            width: '90%',
            '@media (max-width: 650px)': {
                height: '10%',
                marginBottom: '40%',
                width: '95%',
            },
        },

        icon: {
            position: 'absolute',
            right: 0,
            top: 0,
            cursor: 'pointer',
            // marginLeft: 'auto',
            // marginRight: '20px',
            // marginTop: '10px',
            outline: 'none',
            color: '#888888',
            fontSize: 16,
        },
        subscriptionSnackbarAlert: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            '&:focus': {
                outline: 'none',
            },
        },
    }),
);
export default useStyles;
