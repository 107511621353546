import { makeStyles, Theme } from '@material-ui/core/styles';

export const HolidaytabStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        fontFamily: theme.typography.fontFamily,
    },
    main: {
        flexGrow: 1,
        width: '100%',
        padding: '15px 20px',
        fontFamily: theme.typography.fontFamily,
        height: 'calc(100vh - 175px)',
        // '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
        //     minHeight: 'calc(100vh + 150vh)',
        //     padding: '20px 50px',
        // },
        // '@media only screen and (min-width: 820px) and (max-width: 820px)': {
        //     minHeight: 'calc(100vh - 130vh)',
        //     padding: '20px 50px',
        // },
        // '@media screen and (min-width: 821px)': {
        //     minHeight: 'calc(100vh - 163px)',
        //     maxHeight: 'calc(100vh - 170px)',
        // },
        '@media screen and (max-width: 767px)': {
            padding: 10,
            height: 'auto',
        },
    },
    grid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    holidaybtn: {
        // margin: 10,
        outline: 'none',
        fontFamily: theme.typography.fontFamily,
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        fontStyle: 'normal',
        fontFamily: theme.typography.fontFamily,
    },
    holiday: {
        padding: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        '@media screen and (max-width: 767px)': {
            display: 'block',
            height: 0,
        },
    },
    scrollDiv: {
        height: 'auto',
        // border: '1px solid yellow',
        paddingBottom: '10px',
        overflow: 'none',
        '@media screen and (max-width: 800px)': {
            height: 'auto',
            paddingBottom: 20,
        },
        '@media screen and (min-width: 768px) and (max-width: 1024px)': {
            minHeight: 'calc(100vh - 300px)',
            maxHeight: 'calc(100vh - 250px)',
        },
        '@media screen and (min-width: 1025px)': {
            minHeight: 'calc(100vh - 300px)',
            maxHeight: 'calc(100vh - 250px)',
        },
    },
}));
