import { storeNotif } from 'helpers/hasuraapi';
import Logo from './Asset 26.png';
// const newurl: any = 'https://udyamo-teamplus.hasura.app/v1/graphql';
import { newurl } from '../../helpers/hasuraapi';

export const saveDeviceToken = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_usermanagementdb_Employee(where: {employeeId: {_eq: "${obj.empId}"}}, _set: {webDeviceToken: "${obj.deviceToken}"}) {
                    affected_rows
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Update Query Wrong');
        }
        return jsonData.data.update_usermanagementdb_Employee;
    } catch (err) {
        return { error: err };
    }
};

export const getDeviceToken = async (empId: any, orgId: any, token: any) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_team_employee(where: {employeeId: {_eq: "${empId}"}, Team: {organizationId: {_eq: "${orgId}"}}}) {
                    Team {
                    team_employees(where: {isApprover: {_eq: true}}) {
                        Employee {
                            firstName
                            webDeviceToken
                        }
                    }
                    }
                }
                usermanagementdb_organization_employee(where: {_and: {employeeId: {_eq: "${empId}"}, organizationId: {_eq: "${orgId}"}}}) {
                    Organization {
                    organization_employees(where: {role: {_eq: "admin"}}) {
                        Employee {
                            firstName
                            webDeviceToken
                        }
                    }
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        const teamEmployees = jsonData.data.usermanagementdb_team_employee;
        const admins = jsonData.data.usermanagementdb_organization_employee[0].Organization.organization_employees;

        const tokenArr: any[] = [];
        if (teamEmployees.length > 0) {
            teamEmployees.map((tE: any) => {
                if (tE.Team.team_employees.length > 0) {
                    tE.Team.team_employees.map((emp: any) => {
                        if (emp.Employee.webDeviceToken !== null) {
                            tokenArr.push(emp.Employee.webDeviceToken);
                        }
                        return null;
                    });
                }
                return null;
            });
        }

        if (admins.length > 0) {
            admins.map((tE: any) => {
                if (tE.Employee.deviceToken !== null) {
                    tokenArr.push(tE.Employee.webDeviceToken);
                }
                return null;
            });
        }

        const uniqueChars = tokenArr.filter((c, index) => {
            return tokenArr.indexOf(c) === index;
        });
        return uniqueChars;
    } catch (err) {
        return err;
    }
};

//send notif to admin and approver for self applied leave
export const sendNotificationForSelfLeave = async (
    empId: any,
    orgId: any,
    fname: string,
    lname: string,
    token: any,
) => {
    const tokens = await getDeviceToken(empId, orgId, token);
    const notification = {
        title: 'Teamplus',
        body: 'Hello there, ' + fname + ' ' + lname + ' has added time-off.',
        click_action: 'https://teamplus.udyamo.com',
        icon: Logo,
    };
    const notification_body = {
        notification: notification,
        registration_ids: tokens,
    };
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            Authorization:
                'key=' +
                'AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

//send notif to admin and approver for others leave
export const sendNotificationForOthersLeave = async (
    empId: any,
    orgId: any,
    token: any,
    fname: string,
    lname: string,
    empName: string,
) => {
    const tokens = await getDeviceToken(empId, orgId, token);

    const notification = {
        title: 'Teamplus',
        body: 'Hello there, ' + fname + ' ' + lname + ' has added time-off on behalf of ' + empName + '.',
        click_action: 'https://teamplus.udyamo.com',
        icon: Logo,
    };
    const notification_body = {
        notification: notification,
        registration_ids: tokens,
    };
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            Authorization:
                'key=' +
                'AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7',
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

export const getDeviceTokensOfAllEmp = async (empId: any, orgId: any, token: any) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_organization_employee(where: {_and: {employeeId: {_eq: "${empId}"}, organizationId: {_eq: "${orgId}"}}}) {
                    Organization {
                        organization_employees(where: {employeeId: {_neq: "${empId}"}}) {
                            Employee {
                                firstName
                                webDeviceToken
                            }
                        }
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        const emps = jsonData.data.usermanagementdb_organization_employee[0].Organization.organization_employees;

        const tokenArr: any[] = [];
        if (emps.length > 0) {
            emps.map((tE: any) => {
                if (tE.Employee.webDeviceToken !== null) {
                    tokenArr.push(tE.Employee.webDeviceToken);
                }
                return null;
            });
        }

        const uniqueChars = tokenArr.filter((c, index) => {
            return tokenArr.indexOf(c) === index;
        });
        return uniqueChars;
    } catch (err) {
        return err;
    }
};

//sending notif to all emps for approved leave
export const sendNotificationToEmps = async (
    empId: any,
    firstname: any,
    lastName: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    orgId: any,
    token: any,
    empName?: string,
) => {
    const tokens = await getDeviceTokensOfAllEmp(empId, orgId, token);
    let body;

    if (empName === '' || empName === undefined) {
        if (startDate === endDate && startTime === 'null') {
            body = 'Hello there, ' + firstname + ' ' + lastName + ' is on time-off on ' + startDate + '.';
        } else if (startTime !== 'null' && startDate === endDate) {
            body =
                'Hello there, ' +
                firstname +
                ' ' +
                lastName +
                ' is on time-off on ' +
                startDate +
                ' from ' +
                startTime +
                ' to ' +
                endTime +
                '.';
        } else {
            body =
                'Hello there, ' +
                firstname +
                ' ' +
                lastName +
                ' is on time-off from ' +
                startDate +
                ' to ' +
                endDate +
                '.';
        }
    } else {
        if (startDate === endDate && startTime === 'null') {
            body = 'Hello there, ' + empName + ' is on time-off on ' + startDate + '.';
        } else if (startTime !== 'null' && startDate === endDate) {
            body =
                'Hello there, ' +
                empName +
                ' is on time-off on ' +
                startDate +
                ' from ' +
                startTime +
                ' to ' +
                endTime +
                '.';
        } else {
            body = 'Hello there, ' + empName + ' is on time-off from ' + startDate + ' to ' + endDate + '.';
        }
    }

    const notification = {
        title: 'Teamplus',
        body: body,
        click_action: 'https://teamplus.udyamo.com',
        icon: Logo,
    };
    const notification_body = {
        notification: notification,
        registration_ids: tokens,
    };
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

export const getSelfDeviceToken = async (empId: any, orgId: any, token: any) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_Employee(where: {employeeId: {_eq: "${empId}"}}) {
                        webDeviceToken
                    }
                }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.usermanagementdb_Employee[0].webDeviceToken;
    } catch (err) {
        return err;
    }
};

//sending notif to self for auto approved leave
export const sendAutoApproveNotifToSelf = async (
    empId: any,
    orgId: any,
    token: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
) => {
    const tokens = await getSelfDeviceToken(empId, orgId, token);
    let body;

    if (startDate === endDate && startTime === 'null') {
        body = 'Hello there, Your time-off request of ' + startDate + ' has been auto-approved.';
    } else if (startTime !== 'null' && startDate === endDate) {
        body =
            'Hello there, Your time-off request of ' +
            startDate +
            ' from ' +
            startTime +
            ' to ' +
            endTime +
            ' has been auto-approved.';
    } else {
        body = 'Hello there, Your time-off request of ' + startDate + ' to ' + endDate + ' has been auto-approved.';
    }
    const notification = {
        title: 'Teamplus',
        body: body,
        click_action: 'https://teamplus.udyamo.com',
        icon: Logo,
    };
    const notification_body = {
        notification: notification,
        to: tokens,
    };
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

//sending notif to self for single approved leave
export const sendApproveNotifToSelf = async (
    empId: any,
    orgId: any,
    token: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    fname: string,
    lname: string,
) => {
    const tokens = await getSelfDeviceToken(empId, orgId, token);
    let body;
    if (startDate === endDate && startTime === 'null') {
        body =
            'Hello there, Your time-off request of ' + startDate + ' has been approved by ' + fname + ' ' + lname + '.';
    } else if (startTime !== 'null' && startDate === endDate) {
        body =
            'Hello there, Your time-off request of ' +
            startDate +
            ' from ' +
            startTime +
            ' to ' +
            endTime +
            ' has been approved by ' +
            fname +
            ' ' +
            lname +
            '.';
    } else {
        body =
            'Hello there, Your time-off request of ' +
            startDate +
            ' to ' +
            endDate +
            ' has been approved by ' +
            fname +
            ' ' +
            lname +
            '.';
    }
    const notification = {
        title: 'Teamplus',
        body: body,
        click_action: 'https://teamplus.udyamo.com',
        icon: Logo,
    };
    const notification_body = {
        notification: notification,
        to: tokens,
    };
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

//sending notif to self for single denied leave
export const sendDeniedNotifToSelf = async (
    empId: any,
    orgId: any,
    token: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    fname: string,
    lname: string,
) => {
    const tokens = await getSelfDeviceToken(empId, orgId, token);
    let body;
    if (startDate === endDate && startTime === 'null') {
        body =
            'Hello there, Your time-off request of ' + startDate + ' has been denied by ' + fname + ' ' + lname + '.';
    } else if (startTime !== 'null' && startDate === endDate) {
        body =
            'Hello there, Your time-off request of ' +
            startDate +
            ' from ' +
            startTime +
            ' to ' +
            endTime +
            ' has been denied by ' +
            fname +
            ' ' +
            lname +
            '.';
    } else {
        body =
            'Hello there, Your time-off request of ' +
            startDate +
            ' to ' +
            endDate +
            ' has been denied by ' +
            fname +
            ' ' +
            lname +
            '.';
    }
    const notification = {
        title: 'Teamplus',
        body: body,
        click_action: 'https://teamplus.udyamo.com',
        icon: Logo,
    };
    const notification_body = {
        notification: notification,
        to: tokens,
    };
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

//sending notif to self for multi denied leaves
const sendDenyNotificationToMulti = (
    token: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    dfname: any,
    dlname: any,
) => {
    let body;
    if (startDate === endDate && startTime === 'null') {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off request of " + startDate + " has been denied by " + dfname + " " + dlname + ".";
    } else if (startTime !== 'null' && startDate === endDate) {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off request of " + startDate + " from " + startTime + " to " + endTime + "has been denied by " + dfname + " " + dlname + ".";
    } else {
        // eslint-disable-next-line
        body = "Hello there, " + "Your time-off request of " + startDate + " to " + endDate + "has been denied by " + dfname + " " + dlname + ".";
    }
    const notification = {
        title: 'Teamplus',
        body: body,
        click_action: 'https://teamplus.udyamo.com',
        icon: Logo,
    };
    const notification_body = {
        notification: notification,
        to: token,
    };

    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

export const getDenyTokenFromLeave = async (leaveId: any, token: any, dfname: any, dlname: any) => {
    try {
        const query = {
            query: `query MyQuery {
            timeoffdb_Leaves(where: {leaveId: {_eq: "${leaveId}"}}) {
                    Employee {
                        firstName
                        webDeviceToken
                    }
                    endDate
                    endTime
                    startDate
                    startTime
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        sendDenyNotificationToMulti(
            jsonData.data.timeoffdb_Leaves[0].Employee.webDeviceToken,
            jsonData.data.timeoffdb_Leaves[0].startDate,
            jsonData.data.timeoffdb_Leaves[0].endDate,
            jsonData.data.timeoffdb_Leaves[0].startTime,
            jsonData.data.timeoffdb_Leaves[0].endTime,
            dfname,
            dlname,
        );
    } catch (err) {
        return err;
    }
};

const getApproveTokenFromLeave = async (leaveId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
            timeoffdb_Leaves(where: {leaveId: {_eq: "${leaveId}"}}) {
                    Employee {
                        employeeId
                        firstName
                        lastName
                    }
                    startDate
                    startTime
                    endTime
                    endDate
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return [
            jsonData.data.timeoffdb_Leaves[0].Employee.employeeId,
            jsonData.data.timeoffdb_Leaves[0].startTime,
            jsonData.data.timeoffdb_Leaves[0].endTime,
            jsonData.data.timeoffdb_Leaves[0].startDate,
            jsonData.data.timeoffdb_Leaves[0].endDate,
            jsonData.data.timeoffdb_Leaves[0].Employee.firstName,
            jsonData.data.timeoffdb_Leaves[0].Employee.lastName,
        ];
    } catch (err) {
        return err;
    }
};

export const getAllEmpTokens = async (empId: any, orgId: any, token: any) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_Organization(where: {organizationId: {_eq: "${orgId}"}}) {
                    organization_employees(where: {employeeId: {_neq: "${empId}"}}) {
                        Employee {
                            webDeviceToken
                        }
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        const emps = jsonData.data.usermanagementdb_Organization[0].organization_employees;
        // //console.log('admins', emps);

        const tokenArr: any[] = [];
        if (emps.length > 0) {
            emps.map((tE: any) => {
                if (tE.Employee.webDeviceToken !== null) {
                    tokenArr.push(tE.Employee.webDeviceToken);
                }
                return null;
            });
        }

        const uniqueChars = tokenArr.filter((c, index) => {
            return tokenArr.indexOf(c) === index;
        });
        return uniqueChars;
    } catch (err) {
        return err;
    }
};

//sending notif to all emps for multi approved leaves
export const sendAprrovedNotifToMembers = async (leave: any, token: any, orgId: any, fname: any, lname: any) => {
    const org = await getApproveTokenFromLeave(leave, token);

    const empId = org[0];
    const startTime = org[1];
    const endTime = org[2];
    const startDate = org[3];
    const endDate = org[4];
    const firstName = org[5];
    const lastName = org[6];

    const tokens = await getAllEmpTokens(empId, orgId, token);
    await sendApproveNotifToSelf(empId, orgId, token, startDate, endDate, startTime, endTime, fname, lname);
    let body;
    if (startDate === endDate && startTime === 'null') {
        body = 'Hello there, ' + firstName + ' ' + lastName + ' is on time-off on ' + startDate + '.';
    } else if (startTime !== 'null' && startDate === endDate) {
        body =
            'Hello there, ' +
            firstName +
            ' ' +
            lastName +
            ' is on time-off on ' +
            startDate +
            ' from ' +
            startTime +
            ' to ' +
            endTime +
            '.';
    } else {
        body =
            'Hello there, ' + firstName + ' ' + lastName + ' is on time-off from ' + startDate + ' to ' + endDate + '.';
    }

    const notification = {
        title: 'Teamplus',
        body: body,
        click_action: 'https://teamplus.udyamo.com',
        icon: Logo,
    };
    const notification_body = {
        notification: notification,
        registration_ids: tokens,
    };
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            // eslint-disable-next-line
            "Authorization": "key=" + "AAAAQWfIYzs:APA91bHzmjtx8IZbMvqIFnqNZki72G8H9DbYeKs9PF89G4zIDtFnNuROqJ1bFn8iMTCQAS02wHgAlQIlS9Tr2JY68bo6pv7IrmoEQ4h_2tyMtpM5aiIWs6p3S_nguFrwEm_oqgZR4Yu7",
            'Content-Type': 'application/json',
        },

        body: JSON.stringify(notification_body),
    })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            return error;
        });
};

export const storeDenyNotif = async (obj: any) => {
    let body: any;
    let objData: any;

    try {
        if (obj.startDate === obj.endDate && obj.startTime === 'null') {
            body =
                'Your time-off request of ' +
                obj.startDate +
                ' has been denied by ' +
                obj.fname +
                ' ' +
                obj.lname +
                '.';
        } else if (obj.startTime !== 'null' && obj.startDate === obj.endDate) {
            body =
                'Your time-off request of ' +
                obj.startDate +
                ' from ' +
                obj.startTime +
                ' to ' +
                obj.endTime +
                ' has been denied by ' +
                obj.fname +
                ' ' +
                obj.lname +
                '.';
        } else {
            body =
                'Your time-off request of ' +
                obj.startDate +
                ' to ' +
                obj.endDate +
                ' has been denied by ' +
                obj.fname +
                ' ' +
                obj.lname +
                '.';
        }
        // //console.log('leaveId: ', currentLeave.leaveId);
        objData = {
            empId: obj.approverId,
            leaveId: obj.leaveId,
            notif: body,
            orgId: obj.orgId,
            notifDate: obj.notifDate,
            token: obj.token,
            isAdmin: 'employee',
            approverId: obj.empId,
        };
        const notifResp = await storeNotif(objData);
        if (notifResp.error) {
            throw new Error('Failed to store notification!');
        }
    } catch (err) {
        return err;
    }
};

export const getMultiDenyTokenFromLeave = async (
    leaveId: any,
    token: any,
    fname: any,
    lname: any,
    orgId: any,
    approverId: any,
) => {
    try {
        const dateString = new Date().toString();
        const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
        const time = new Date().toString().slice(16, 24);
        const dateTime = date + time;

        const query = {
            query: `query MyQuery {
            timeoffdb_Leaves(where: {leaveId: {_eq: "${leaveId}"}}) {
                Employee {
                    firstName
                    lastName
                    employeeId
                }
                endDate
                endTime
                startDate
                startTime
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        await storeDenyNotif({
            startDate: jsonData.data.timeoffdb_Leaves[0].startDate,
            endDate: jsonData.data.timeoffdb_Leaves[0].endDate,
            startTime: jsonData.data.timeoffdb_Leaves[0].startTime,
            endTime: jsonData.data.timeoffdb_Leaves[0].endTime,
            fname: fname,
            lname: lname,
            empId: jsonData.data.timeoffdb_Leaves[0].Employee.employeeId,
            approverId: approverId,
            orgId: orgId,
            leaveId: leaveId,
            token: token,
            notifDate: dateTime,
        });
        return jsonData;
    } catch (err) {
        return { error: err };
    }
};

export const storeApproveNotifForSelf = async (
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    dfname: any,
    dlname: any,
    employeeId: any,
    leaveId: any,
    organizationId: any,
    token: any,
    approverId: any,
) => {
    try {
        let body: any;
        const dateString = new Date().toString();
        const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
        const time = new Date().toString().slice(16, 24);
        const dateTime = date + time;

        if (startDate === endDate && startTime === 'null') {
            body = 'Your time-off request of ' + startDate + ' has been approved by ' + dfname + ' ' + dlname + '.';
        } else if (startTime !== 'null' && startDate === endDate) {
            body =
                'Your time-off request of ' +
                startDate +
                ' from ' +
                startTime +
                ' to ' +
                endTime +
                ' has been approved by ' +
                dfname +
                ' ' +
                dlname +
                '.';
        } else {
            body =
                'Your time-off request of ' +
                startDate +
                ' to ' +
                endDate +
                ' has been approved by ' +
                dfname +
                ' ' +
                dlname +
                '.';
        }
        const objData = {
            empId: approverId,
            leaveId: leaveId,
            notif: body,
            orgId: organizationId,
            notifDate: dateTime,
            token: token,
            isAdmin: 'employee',
            approverId: employeeId,
        };
        const notifResp = await storeNotif(objData);
        if (notifResp.error) {
            throw new Error('Failed to store notification!');
        }
        //console.log('notifResp: ', notifResp);
        return notifResp;
    } catch (err) {
        return { error: err };
    }
};

export const storeApproveNotifForAll = async (
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    fname: any,
    lname: any,
    employeeId: any,
    leaveId: any,
    organizationId: any,
    token: any,
    approverId: any,
) => {
    try {
        let body: any;
        const dateString = new Date().toString();
        const date = new Date(dateString.replace(/GMT.*$/, '')).toISOString().slice(0, 11);
        const time = new Date().toString().slice(16, 24);
        const dateTime = date + time;

        if (startDate === endDate && startTime === 'null') {
            body = fname + ' ' + lname + ' is on time-off on ' + startDate + '.';
        } else if (startTime !== 'null' && startDate === endDate) {
            body =
                fname + ' ' + lname + ' is on time-off on ' + startDate + ' from ' + startTime + ' to ' + endTime + '.';
        } else {
            body = fname + lname + ' is on time-off on ' + startDate + ' to ' + endDate + '.';
        }
        const objData = {
            empId: employeeId,
            leaveId: leaveId,
            notif: body,
            orgId: organizationId,
            notifDate: dateTime,
            token: token,
            isAdmin: 'allemps',
            approverId: approverId,
        };
        const notifResp = await storeNotif(objData);
        if (notifResp.error) {
            throw new Error('Failed to store notification!');
        }
        //console.log('notifResp: ', notifResp);
        return notifResp;
    } catch (err) {
        return { error: err };
    }
};

export const getMultiApproveTokenFromLeave = async (
    leaveId: any,
    token: any,
    fname: any,
    lname: any,
    orgId: any,
    approverId: any,
) => {
    try {
        const org = await getApproveTokenFromLeave(leaveId, token);

        const employeeId = org[0];
        const startTime = org[1];
        const endTime = org[2];
        const startDate = org[3];
        const endDate = org[4];
        const firstName = org[5];
        const lastName = org[6];

        await storeApproveNotifForSelf(
            startDate,
            endDate,
            startTime,
            endTime,
            fname,
            lname,
            employeeId,
            leaveId,
            orgId,
            token,
            approverId,
        );
        await storeApproveNotifForAll(
            startDate,
            endDate,
            startTime,
            endTime,
            firstName,
            lastName,
            employeeId,
            leaveId,
            orgId,
            token,
            approverId,
        );
    } catch (err) {
        return err;
    }
};

export const storeNotifForAutoAproveSelfLeave = async (obj: any) => {
    try {
        //for auto-approved full day leave
        let body: any;
        let objData: any;
        if (obj.startTime === obj.endTime) {
            if (obj.startDate === obj.endDate) {
                body = obj.fname + ' ' + obj.lname + ' is on time-off on ' + obj.startDate + '.';
            } else {
                body =
                    obj.fname + ' ' + obj.lname + ' is on time-off from ' + obj.startDate + ' to ' + obj.endDate + '.';
            }
            objData = {
                empId: obj.empId,
                leaveId: obj.leaveId,
                notif: body,
                orgId: obj.orgId,
                notifDate: obj.notifDate,
                token: obj.token,
                isAdmin: 'allemps',
                approverId: obj.empId,
            };
            const notifResp = await storeNotif(objData);
            // //console.log('notifResp: ', notifResp)
            if (notifResp.error) {
                throw new Error('Failed to store notification!');
            }
            if (obj.startDate === obj.endDate) {
                body = 'Your time-off request of ' + obj.startDate + ' has been auto-approved.';
            } else {
                body = 'Your time-off request of ' + obj.startDate + ' to ' + obj.endDate + ' has been auto-approved.';
            }
            objData = {
                empId: obj.empId,
                leaveId: obj.leaveId,
                notif: body,
                orgId: obj.orgId,
                notifDate: obj.notifDate,
                token: obj.token,
                isAdmin: 'employee',
                approverId: obj.approverId,
            };
            const notifResp1 = await storeNotif(objData);
            //console.log('notifResp1: ', notifResp1);
            if (notifResp1.error) {
                throw new Error('Failed to store notification!');
            }
        } else {
            //for custom auto-approved leave
            objData = {
                empId: obj.empId,
                leaveId: obj.leaveId,
                notif:
                    obj.fname +
                    ' ' +
                    obj.lname +
                    ' is on time-off on ' +
                    obj.startDate +
                    ' from ' +
                    obj.startTime +
                    ' to ' +
                    obj.endTime +
                    '.',
                orgId: obj.orgId,
                notifDate: obj.notifDate,
                token: obj.token,
                isAdmin: 'allemps',
                approverId: obj.empId,
            };
            const notifResp2 = await storeNotif(objData);
            if (notifResp2.error) {
                throw new Error('Failed to store notification!');
            }
            objData = {
                empId: obj.empId,
                leaveId: obj.leaveId,
                notif:
                    'Your time-off request of ' +
                    obj.startDate +
                    ' from ' +
                    obj.startTime +
                    ' to ' +
                    obj.endTime +
                    ' has been auto-approved.',
                orgId: obj.orgId,
                notifDate: obj.notifDate,
                token: obj.token,
                isAdmin: 'employee',
                approverId: obj.approverId,
            };
            const notifResp3 = await storeNotif(objData);
            if (notifResp3.error) {
                throw new Error('Failed to store notification!');
            }
        }
    } catch (err) {
        return err;
    }
};

export const storeNotifForAutoApproveOtherLeave = async (obj: any) => {
    try {
        let body: any;
        let objData: any;
        //for full day auto-approved leave
        if (obj.startTime === obj.endTime) {
            if (obj.startDate === obj.endDate) {
                body = obj.empName + ' is on time-off on ' + obj.startDate + '.';
            } else {
                body = obj.empName + ' is on time-off on ' + obj.startDate + ' to ' + obj.endDate + '.';
            }
            objData = {
                empId: obj.empId,
                leaveId: obj.leaveId,
                notif: body,
                orgId: obj.orgId,
                notifDate: obj.notifDate,
                token: obj.token,
                isAdmin: 'allemps',
                approverId: obj.empId,
            };
            const notifResp = await storeNotif(objData);
            if (notifResp.error) {
                throw new Error('Failed to store notification!');
            }

            if (obj.startDate === obj.endDate) {
                body = 'Your time-off request of ' + obj.startDate + ' has been auto-approved.';
            } else {
                body = 'Your time-off request of ' + obj.startDate + ' to ' + obj.endDate + ' has been auto-approved.';
            }
            objData = {
                empId: obj.empId,
                leaveId: obj.leaveId,
                notif: body,
                orgId: obj.orgId,
                notifDate: obj.notifDate,
                token: obj.token,
                isAdmin: 'employee',
                approverId: obj.approverId,
            };
            // const notifResp1 = await storeNotif(objData);
            // if (notifResp1.error) {
            //     throw new Error('Failed to store notification!');
            // }
        } else {
            //for custom auto-approved leave
            objData = {
                empId: obj.empId,
                leaveId: obj.leaveId,
                notif:
                    obj.empName +
                    ' is on time-off on ' +
                    obj.startDate +
                    ' from ' +
                    obj.startTime +
                    ' to ' +
                    obj.endTime +
                    '.',
                orgId: obj.orgId,
                notifDate: obj.notifDate,
                token: obj.token,
                isAdmin: 'allemps',
                approverId: obj.empId,
            };
            // const notifResp2 = await storeNotif(objData);
            // if (notifResp2.error) {
            //     throw new Error('Failed to store notification!');
            // }

            objData = {
                empId: obj.empId,
                leaveId: obj.leaveId,
                notif:
                    'Your time-off request of ' +
                    obj.startDate +
                    ' from ' +
                    obj.startTime +
                    ' to ' +
                    obj.endTime +
                    ' has been auto-approved.',
                orgId: obj.orgId,
                notifDate: obj.notifDate,
                token: obj.token,
                isAdmin: 'employee',
                approverId: obj.approverId,
            };
            // const notifResp3 = await storeNotif(objData);
            // if (notifResp3.error) {
            //     throw new Error('Failed to store notification!');
            // }
        }
    } catch (err) {
        return err;
    }
};

export const storeApproveNotif = async (obj: any) => {
    let body: any;
    let objData: any;
    try {
        if (obj.startDate === obj.endDate && obj.startTime === 'null') {
            body = obj.fname + ' ' + obj.lname + ' is on time-off on ' + obj.startDate + '.';
        } else if (obj.startTime !== 'null' && obj.startDate === obj.endDate) {
            body =
                obj.fname +
                ' ' +
                obj.lname +
                ' is on time-off on ' +
                obj.startDate +
                ' from ' +
                obj.startTime +
                ' to ' +
                obj.endTime +
                '.';
        } else {
            body = obj.fname + ' ' + obj.lname + ' is on time-off on ' + obj.startDate + ' to ' + obj.endDate + '.';
        }
        // //console.log('leaveId: ', leave.leaveId);
        objData = {
            empId: obj.empId,
            leaveId: obj.leaveId,
            notif: body,
            orgId: obj.orgId,
            notifDate: obj.notifDate,
            token: obj.token,
            isAdmin: 'allemps',
            approverId: obj.approverId,
        };
        const notifResp = await storeNotif(objData);
        if (notifResp.error) {
            throw new Error('Failed to store notification!');
        }

        if (obj.startDate === obj.endDate && obj.startTime === 'null') {
            body = body =
                'Your time-off request of ' +
                obj.startDate +
                ' has been approved by ' +
                obj.afname +
                ' ' +
                obj.alname +
                '.';
        } else if (obj.startTime !== 'null' && obj.startDate === obj.endDate) {
            body =
                'Your time-off request of ' +
                obj.startDate +
                ' from ' +
                obj.startTime +
                ' to ' +
                obj.endTime +
                ' has been approved by ' +
                obj.afname +
                ' ' +
                obj.alname +
                '.';
        } else {
            body =
                'Your time-off request of ' +
                obj.startDate +
                ' to ' +
                obj.endDate +
                ' has been approved by ' +
                obj.afname +
                ' ' +
                obj.alname +
                '.';
        }
        objData = {
            empId: obj.approverId,
            leaveId: obj.leaveId,
            notif: body,
            orgId: obj.orgId,
            notifDate: obj.notifDate,
            token: obj.token,
            isAdmin: 'employee',
            approverId: obj.empId,
        };
        const notifResp2 = await storeNotif(objData);
        if (notifResp2.error) {
            throw new Error('Failed to store notification!');
        }
    } catch (err) {
        return err;
    }
};
