import React from 'react';
import './App.css';
import Routes from './Routes/MainRoute';
import { AuthContext } from 'context/Context';
function App() {
    return (
        <AuthContext>
            <Routes />
        </AuthContext>
    );
}
export default App;
