import { makeStyles } from '@material-ui/core/styles';
import bgImg from './bgImg.png';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
    },
    main: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media screen and (max-width: 300px)': {
            height: '653px',
            overflow: 'none',
        },
    },
    bgImage: {
        height: '100vh',
        width: '100%',
        padding: '0px 100px',
        background: `url("${bgImg}")`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
        color: '#fff',
    },
    heading: {
        fontFamily: theme.typography.fontFamily,
        color: '#fff',
        fontSize: 60,
        fontWeight: theme.typography.fontWeightBold,
    },
    subtitle: {
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'italic',
        fontSize: 30,
        fontWeight: theme.typography.fontWeightBold,
    },
    SignUpheading: {
        textAlign: 'center',
        // marginTop: '12vh',
        marginBottom: '2vh',
        color: '#3C48B5',
        '@media screen and (max-width: 650px)': {
            fontSize: 18,
            marginTop: '5vh',
            marginBottom: '5vh',
        },
    },
    font: {
        '@media screen and (max-width: 650px and min-width: 400px)': {
            fontSize: 24,
        },
        '@media screen and (max-width: 399px)': {
            fontSize: 20,
        },
    },
    overlay: {
        display: 'flex',
        position: 'relative',
        bottom: '60vh',
        left: '8vw',
        alignItems: 'center',
        color: 'white',
    },
    overlay2: {
        display: 'flex',
        position: 'relative',
        bottom: '58vh',
        left: '8vw',
        fontSize: '1.2em',
        alignItems: 'center',
        color: 'white',
        fontStyle: 'italic',
    },
    firstNameCSS: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '2vh',
        marginLeft: '6vw',
        marginRight: '4vw',
        justifyContent: 'center',
    },
    teamLogoSmall: {
        maxWidth: '100%',
        height: 40,
        marginBottom: 5,
    },
    emailCSS: {
        display: 'flex',
        marginTop: '3vh',
        marginLeft: '6vw',
        marginRight: '4vw',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formLabelCSS: {
        display: 'flex',
        marginLeft: '6vw',
        marginTop: '2em',
    },
    dateTimePicker: {
        display: 'flex',
        width: '50%',
        marginLeft: '6vw',
        marginRight: '6vw',
        marginTop: '1em',
    },
    selectionFieldCSS: {
        display: 'flex',
        marginLeft: '6vw',
        marginTop: '2vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    submitButtonCSS: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // marginLeft: '6vw',
        // marginRight: '6vw',
        marginTop: '2vh',
    },
    hoverStyleOutline: {
        width: '70%',
        borderRadius: 20,
        fontSize: 18,
        textTransform: 'capitalize',
        color: '@3d49b6',
        fontWeight: theme.typography.fontWeightBold,
        outline: 'none',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            color: '#3D49B6',
            backgroundColor: '#fff',
        },
    },
    counterDiv: {
        // width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        marginTop: '5vh',
    },
    counter: {
        width: 50,
        height: 50,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    hoverStyle: {
        width: '70%',
        borderRadius: 20,
        fontSize: 18,
        textTransform: 'capitalize',
        color: '#fff',
        fontWeight: theme.typography.fontWeightBold,
        outline: 'none',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: '#3D49B6',
        },
    },
}));

export default useStyles;
