import React, { useState } from 'react';
import { Box, Grid, Hidden, Button, Typography, Paper, FormLabel } from '@material-ui/core';
// import OtpInput from 'react-otp-input';
import useStyles from './newRegisterStyles';
// import Page1Img from '../../Assets/images/Page1Img.png';
// import udyamo from '../../Assets/images/udyamo.png';
import { PopUp } from '../Modal/Modal';
import { Auth } from 'aws-amplify';
import { OTPInput } from 'chakra-otp-input';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

interface Props {
    setPageNumber?: any;
    values?: any;
}

export default function RegisterPartTwo(props: Props) {
    const classes = useStyles();
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [severity, setSeverity] = useState<string>('');

    /* istanbul ignore next */
    const verifyOTP = async (e: any) => {
        e.preventDefault();
        try {
            if (props.values.code === '') {
                throw new Error('Please Enter OTP!');
            }
            if (props.values.code.length !== 6) {
                throw new Error('Please check your entered OTP!');
            }
            await Auth.confirmSignUp(props.values.emailId, props.values.code);
            try {
                await Auth.signIn(props.values.emailId, props.values.password);
            } catch (err) {
                const obj2: any = err;
                if (obj2?.message === 'Incorrect username or password.') {
                    setAlertMessage('Please enter the same password you used while registering the first time!');
                    setSeverity('error');
                    setAlert(true);
                    setTimeout(() => props.setPageNumber(props.values.pageNumber - 1), 2000);
                    return obj2;
                }
            }
            setSeverity('success');
            setAlert(true);
            setAlertMessage('Verified successfully!');
            setTimeout(() => props.setPageNumber(props.values.pageNumber + 1), 2000);
        } catch (error) {
            // setSeverity('error');
            // setAlert(true);
            // if (typeof error === 'object') {
            //     const obj: any = error;
            //     setAlertMessage(obj?.message);
            // }
            const obj: any = error;
            setAlertMessage(obj?.message);
            setSeverity('error');
            setAlert(true);
            return error;
        }
    };

    /* istanbul ignore next */
    const resendOTP = async () => {
        try {
            await Auth.resendSignUp(props.values.emailId || 'demo@gmail.com');
            setSeverity('success');
            setAlert(true);
            setAlertMessage('OTP has been sent to your email');
        } catch (error) {
            setSeverity('error');
            setAlert(true);
            if (typeof error === 'object') {
                const obj: any = error;
                setAlertMessage(obj?.message);
            }
        }
    };

    return (
        <Box className={classes.root}>
            <Grid container direction="row">
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.registerPageImage}
                                    alt="Page1Img"
                                    style={{ height: '75vh' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                    alt="udyamoLogo"
                                    style={{ height: '2.5vh' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} lg={12} xl={7} className={classes.secondGrid}>
                    <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                    <Paper style={{ boxShadow: 'none' }} className={classes.paperStyleOTP}>
                        <form id="otpForm" className={classes.secondGridSmall} onSubmit={verifyOTP}>
                            <Grid container style={{ height: '100%' }} direction="row">
                                <Grid
                                    item
                                    xs={12}
                                    style={{ justifyContent: 'flex-end' }}
                                    className={classes.partTwoLabel}
                                >
                                    <FormLabel id="otp">2/4</FormLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <FormLabel className={classes.title}>Email Verification</FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} className={classes.label}>
                                        <h6 className={classes.font}>
                                            {' '}
                                            Enter the verification code sent to your email
                                        </h6>
                                    </Grid>
                                    <Grid item xs={12} sm={12} className={classes.OtpInput} id="otpinput">
                                        <OTPInput
                                            id="otp"
                                            className={classes.otpdesign}
                                            onChange={props.values.setCode}
                                            noInputs={6}
                                            isNumeric={true}
                                        />

                                        {/* <OtpInput
                                            className="otp"
                                            value={props.values.code}
                                            onChange={props.values.setCode}
                                            numInputs={6}
                                            // shouldAutoFocus
                                            inputStyle={{
                                                width: '2.7rem',
                                                height: '2.7rem',
                                                margin: '0px 5px',
                                                fontSize: '1rem',
                                                borderRadius: 4,
                                                border: '2px solid #CCCCCC',
                                            }}
                                            focusStyle={{ border: '2px solid #3C48B5', borderRadius: 4 }}
                                        /> */}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} sm={12} className={classes.verifybutton}>
                                        <Button
                                            id="verify"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            // onClick={() => props.values.verifyOTP()}
                                            className={classes.hoverStyle}
                                        >
                                            Verify
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: '#3C48B5',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                fontWeight: 600,
                                            }}
                                            onClick={() => resendOTP()}
                                        >
                                            Resend OTP
                                        </Typography>
                                    </Grid>
                                    {/* <Grid
                                        item
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Button
                                            className={classes.takeMeBackButton}
                                            onClick={props.values.backButton}
                                            style={{ marginBottom: 20 }}
                                        >
                                            Take me back
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
