import { createSlice } from '@reduxjs/toolkit';
export const tenantEmployeeSlice = createSlice({
    name: 'tenantEmployees',
    initialState: { tenantEmployees: {}, isBotAdded: {}, status: 'loading' },
    reducers: {
        fetchTenantEmployees: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                const employeeArray: any = [];
                action.payload.data.usermanagementdb_tenant_employee.forEach((employee: any) => {
                    const employeeObj = {
                        name: employee.Employee?.firstName + ' ' + employee.Employee?.lastName,
                        email: employee.Employee?.email,
                        empId: employee.Employee?.employeeId,
                        profile: employee.Employee?.profileImage,
                        timeoffLicense: employee.timeoffSubscriptionId ? employee.timeoffSubscriptionId : null,
                        standupLicense: employee.standupSubscriptionId ? employee.standupSubscriptionId : null,
                        engageLicense: employee.engageSubscriptionId ? employee.engageSubscriptionId : null,
                        pollLicense: employee.pollSubscriptionId ? employee.pollSubscriptionId : null,
                        departments: employee.Tenant.Organizations,
                        assignedBy: {
                            empId: '1111',
                            name: 'Sailee Panse',
                            profile: '',
                        },
                        workAnniversary: employee?.workAnniversary,
                        isDOB: employee?.isDOB,
                        isYear: employee?.isYear,
                    };
                    employeeArray.push(employeeObj);
                });
                state.tenantEmployees = employeeArray;
                state.isBotAdded = action.payload?.data?.usermanagementdb_tenant_employee[0]?.Tenant?.isBotAdded;
            }
        },
    },
});
export const { fetchTenantEmployees } = tenantEmployeeSlice.actions;

export default tenantEmployeeSlice.reducer;
