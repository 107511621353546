import { gql } from '@apollo/client';

export function getEmployeeList(organizationId: string) {
    return gql`
        subscription {
            usermanagementdb_organization_employee(order_by: {Employee: {firstName: asc}}, where: {organizationId: {_eq: "${organizationId}"}}) {
                role
                Employee {
                    firstName
                    lastName
                    employeeId
                    email
                    status
                    profileImage
                    team_employees(where: {Team: {organizationId: {_eq: "${organizationId}"}}}) {
                        employeeId
                        isApprover
                        teamId
                        Team {
                            teamId
                            teamName
                        }
                    }
                }
            }
        }`;
}

export function inviteStatus(organizationId: string) {
    return gql`
        subscription MyQuery {
            timeoffdb_Invites(where: {orgId: {_eq: "${organizationId}"}}, distinct_on: emailId) {
                emailId
                status
                orgId
                inviteId
                teams
            }
        }
    `;
}
export function getTenantEmployee(tenantTableId: string) {
    return gql`
        subscription MySubscription {
        usermanagementdb_tenant_employee(where: {tenantId: {_eq: "${tenantTableId}"}}, order_by: {Employee: {firstName: asc}}) {
            employeeId
            standupSubscriptionId
            timeoffSubscriptionId
            engageSubscriptionId
            pollSubscriptionId
            workAnniversary
            isDOB
            isYear
            role
            Employee {
            employeeId
            firstName
            lastName
            email
            profileImage
            }
            Tenant {
            isBotAdded
            Organizations {
                organizationId
                organizationName
            }
            }
        }
        }
    `;
}

export function getTenantMembers(tenantWorkspace: string) {
    return gql`
        subscription MySubscription {
            usermanagementdb_tenant_employee(where: { Tenant: { workspaceId: { _eq: "${tenantWorkspace}" } } }) {
                employeeId
                role
                Employee {
                    employeeId
                    firstName
                    lastName
                    profileImage
                }
            }
        }
    `;
}
