import React from 'react';
import useStyles from './style';
import Question from '../../Assets/dashboard/question.png';
import { Hidden } from '@material-ui/core';

export default function Questions() {
    const classes = useStyles();
    const [active, setActive] = React.useState<number>(0);

    const questions = [
        {
            id: 1,
            question: 'Is TeamPlus only for managing time-offs?',
            answer:
                'TeamPlus is not a tool for managing time-offs in fact, it is anything but that. It is a complete team where time-off coordination is one of many features that we’ve launched first. The tool is there to share availabilities and request shorter (or longer!) time-offs, irrespective of whether they’re hour-long or standard day-long time-offs. So, TeamPlus is definitely much more than a time-ff management tool.',
        },
        {
            id: 2,
            question: 'Do I need a time-off management system for my team?',
            answer:
                'Any small, medium or large enterprise needs to have a system in place for handling time-offs. While you could go with the traditional emails and forms to deal with time-offs, it is recommended to use a platform instead. It makes it easier for yourself and your team, especially in terms of recording and approving time-off.',
        },
        {
            id: 3,
            question:
                'Why do you call the feature as TeamPlus Availability Assistant and not a time-off management system?',
            answer:
                'In the present times, many teams are working remotely. So, your team members may need a few hours of time off instead of completely day time-offs. Taking that into consideration, TeamPlus lets your team take half-day or shorter time-offs through the platform instead of just restricting them to full-day time-offs. More importantly, it records and makes this information available to every team member. So, everyone’s availability is known to all. Hence, the name.',
        },
        {
            id: 4,
            question: 'How do I add a new member who joins the team?',
            answer:
                'You need to simply go to the employee section of the platform and invite people you wish to add using their email id. An email id is a must to add people to your team’s account.',
        },
        {
            id: 5,
            question: 'How do I remove someone from the team once they’ve moved on from the organization?',
            answer:
                'You can remove a team member by choosing the delete employee option next to their name in the employee tab.',
        },
        {
            id: 6,
            question: 'Can I assign a certain amount and types of time-offs per year?',
            answer:
                'Yes, you can choose the types of time-offs and their number per year through the Settings tab on the platform. ',
        },
        {
            id: 7,
            question: 'How does the TeamPlus Slack integration help?',
            answer:
                'Using the integration, your team can directly apply for time-off through their Slack using a designated channel. For admins, the Slack integration helps in getting timely notifications of any requests pending on the portal.',
        },
    ];

    const questionsSmall = [
        {
            id: 1,
            question: 'Is TeamPlus only for managing time-offs?',
            answer:
                'TeamPlus is not a tool for managing time-offs in fact, it is anything but that. It is a complete team where time-off coordination is one of many features that we’ve launched first. The tool is there to share availabilities and request shorter (or longer!) time-offs, irrespective of whether they’re hour-long or standard day-long time-offs. So, TeamPlus is definitely much more than a time-ff management tool.',
        },
        {
            id: 2,
            question: 'Do I need a time-off management system for my team?',
            answer:
                'Any small, medium or large enterprise needs to have a system in place for handling time-offs. While you could go with the traditional emails and forms to deal with time-offs, it is recommended to use a platform instead. It makes it easier for yourself and your team, especially in terms of recording and approving time-off.',
        },
        {
            id: 3,
            question: 'Why do you call the feature as TeamPlus Availability Assistant?',
            answer:
                'In the present times, many teams are working remotely. So, your team members may need a few hours of time off instead of completely day time-offs. Taking that into consideration, TeamPlus lets your team take half-day or shorter time-offs through the platform instead of just restricting them to full-day time-offs. More importantly, it records and makes this information available to every team member. So, everyone’s availability is known to all. Hence, the name.',
        },
        {
            id: 4,
            question: 'How do I add a new member who joins the team?',
            answer:
                'You need to simply go to the employee section of the platform and invite people you wish to add using their email id. An email id is a must to add people to your team’s account.',
        },
        {
            id: 5,
            question: 'How do I remove someone from the team ?',
            answer:
                'You can remove a team member by choosing the delete employee option next to their name in the employee tab.',
        },
        {
            id: 6,
            question: 'Can I assign a certain amount and types of time-offs per year?',
            answer:
                'Yes, you can choose the types of time-offs and their number per year through the Settings tab on the platform. ',
        },
        {
            id: 7,
            question: 'How does the TeamPlus Slack integration help?',
            answer:
                'Using the integration, your team can directly apply for time-off through their Slack using a designated channel. For admins, the Slack integration helps in getting timely notifications of any requests pending on the portal.',
        },
    ];

    return (
        <>
            <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <div className={classes.questionContainer}>
                    <h2>
                        Frequently Asked Questions <img src={Question} alt="" className={classes.questionImg} />
                    </h2>
                    {questions.map((q: any) => (
                        <div key={q.id} className={classes.questionCard}>
                            <div className={active === q.id ? classes.activeQuestion : classes.question}>
                                <div>{q.id}</div>
                                <h6>{q.question}</h6>
                                {active === q.id ? (
                                    <span onClick={() => setActive(0)}>-</span>
                                ) : (
                                    <span onClick={() => setActive(q.id)}>+</span>
                                )}
                            </div>
                            <div className={active === q.id ? classes.answer : classes.answerHidden}>
                                <h6>{q.answer}</h6>
                            </div>
                        </div>
                    ))}
                </div>
            </Hidden>
            <Hidden only={['xl']}>
                <div className={classes.questionContainerSmall}>
                    <h2>
                        Frequently Asked <br></br>
                        <span>Questions </span>
                    </h2>
                    {questionsSmall.map((q: any) => (
                        <div key={q.id} className={classes.questionCard}>
                            <div
                                className={active === q.id ? classes.activeQuestionSmall : classes.questionSmall}
                                onClick={() => {
                                    q.id === active ? setActive(0) : setActive(q.id);
                                }}
                            >
                                <div>{q.id}</div>
                                <h6>{q.question}</h6>
                                {/* {active === q.id ? (
                                        <span onClick={() => setActive(0)}>-</span>
                                    ) : (
                                        <span onClick={() => setActive(q.id)}>+</span>
                                    )} */}
                            </div>
                            <div className={active === q.id ? classes.answer : classes.answerHidden}>
                                <h6>{q.answer}</h6>
                            </div>
                        </div>
                    ))}
                </div>
            </Hidden>
        </>
    );
}
