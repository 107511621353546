export default (name = '') =>
    name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map(v => v && v[0].toUpperCase())
        .join('');

export const getNameFromEmail = (name: string) => {
    const value = name.split('@');
    return value[0];
};

export const getFullName = (name: any) => {
    const fullName = name.split(' ');
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials;
};

export const getOrgInitial = (name: any) => {
    const fullName = name.split(' ');
    const initials = fullName.shift().charAt(0);
    return initials;
};
