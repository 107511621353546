import { createSlice } from '@reduxjs/toolkit';

export const shoutoutSlice = createSlice({
    name: 'shoutout',
    initialState: { shoutouts: [], status: 'loading' },
    reducers: {
        fetchAllShoutouts: (state, action) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                if (action.payload?.data !== undefined) {
                    const myshoutouts: any = [];
                    action.payload?.data?.shoutoutdb_Shoutout?.map((s: any) => {
                        myshoutouts.push({
                            shoutoutId: s?.shoutoutId,
                            tags: s?.tags,
                            from: {
                                name: s?.Employee.firstName,
                                lastName: s?.Employee.lastName,
                                profile: s?.Employee?.profileImage,
                                employeeId: s?.createdBy,
                            },
                            to: s?.shoutout_employee_teams,
                            message: s?.message,
                            createdOn: s?.createdOn,
                            emoji: s?.emoji,
                            reactions: s?.shoutout_reactions,
                            comments: s?.shoutout_comments,
                            createdBy: s?.createdBy,
                            // reactions: s?.shoutout_reaction_comments?.filter((c: any) => c.reaction !== null),
                            // comments: s?.shoutout_reaction_comments?.filter((c: any) => c.commentText !== null),
                        });
                    });
                    state.shoutouts = myshoutouts;
                    state.status = 'success';
                }
            }
        },
    },
});

export const { fetchAllShoutouts } = shoutoutSlice.actions;
export default shoutoutSlice.reducer;

// to:
//                                 s?.shoutout_employee_teams.length === 1
//                                     ? {
//                                           name: s?.shoutout_employee_teams[0]?.Employee.firstName,
//                                           profile: s?.shoutout_employee_teams[0]?.Employee.profileImage,
//                                           employeeId: s?.shoutout_employee_teams[0]?.Employee.employeeId,
//                                           isSeen: s?.shoutout_employee_teams[0]?.isSeen,
//                                           shoutoutEmployeeTeamId: s?.shoutout_employee_teams[0]?.shoutoutEmployeeTeamId,
//                                       }
//                                     : [
//                                           {
//                                               name: s?.shoutout_employee_teams[0]?.Employee.firstName,
//                                               profile: s?.shoutout_employee_teams[0]?.Employee.profileImage,
//                                               employeeId: s?.shoutout_employee_teams[0]?.Employee.employeeId,
//                                               isSeen: s?.shoutout_employee_teams[0]?.isSeen,
//                                           },
//                                       ],
