import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    Button,
    FormControlLabel,
    Hidden,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    useTheme,
    Grid,
    Typography,
} from '@material-ui/core';
import { useStyles } from './style';
import AuthContext from 'context/AuthContext';
import { getCurrentCountryDateTime, localToISO } from 'helpers/timezone';
import { dateofBirthfunc, fetchUpcomingCelebration, getEmployees, workAnniversary } from 'helpers/celebrationApi';
import { CelebrationCard, MobileCard } from './celebrationCard';
import { Addothercele } from './otherCelebration';
import { UpcomingCelebration } from './upcomingCelebration';
import { AddManually } from './addDob';
import BulkData from './BulkData';
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveTab } from 'components/Redux/activeTab';
import MyModal from 'components/Modal/Modal';
import ErrorPage from 'components/Error/ErrorPage';
import { useSubscription } from '@apollo/client';

export function TabBar(props: {
    todayData: any;
    pastData: any;
    setCardData: any;
    setOpen: any;
    setCommentData: any;
    open: any;
    option: any;
    setOption: any;
    emp: any;
    sendUsersData: any;
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [tab, setTab] = React.useState<number>(0);
    const mycontext = useContext(AuthContext);

    const handleTab = (event: React.ChangeEvent<{}>, value: number) => {
        localStorage.removeItem('page');
        setTab(value);
    };
    const TabPanel = (props: any) => {
        const { children, value, index } = props;
        return <>{value === index && <div>{children}</div>}</>;
    };

    return (
        <div>
            <Hidden mdDown>
                <Tabs
                    value={tab}
                    onChange={handleTab}
                    indicatorColor="primary"
                    style={{
                        lineHeight: '50px',
                        borderRadius: '5px',
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <Tab label="Today's Celebrations" className={classes.tab} />
                    <Tab label="Past Celebrations" className={classes.tab} />
                    <Tab label="Add Celebration" className={classes.tab} />
                </Tabs>
            </Hidden>
            <Hidden lgUp>
                <Tabs
                    value={tab}
                    onChange={handleTab}
                    indicatorColor="primary"
                    style={{
                        lineHeight: '50px',
                        borderRadius: '5px',
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <Tab label="Today's Celebrations" className={classes.tab} />
                    <Tab label="Past Celebrations" className={classes.tab} />
                </Tabs>
            </Hidden>
            <TabPanel index={0} value={tab}>
                <CelebrationCard
                    data={props?.todayData}
                    setCardData={props.setCardData}
                    setOpen={props.setOpen}
                    setCommentData={props.setCommentData}
                    open={props.open}
                    option={props.option}
                    setOption={props.setOption}
                />
            </TabPanel>
            <TabPanel index={1} value={tab}>
                <CelebrationCard
                    data={props?.pastData}
                    setCardData={props.setCardData}
                    setOpen={props.setOpen}
                    setCommentData={props.setCommentData}
                    open={props.open}
                    option={props.option}
                    setOption={props.setOption}
                />
            </TabPanel>
            <TabPanel index={2} value={tab}>
                {mycontext.isAdmin ? (
                    <AdminPage emp={props.emp} sendUsersData={props.sendUsersData} />
                ) : (
                    <Addothercele />
                )}
            </TabPanel>
        </div>
    );
}

export function AdminPage(props: { emp: any; sendUsersData: any }) {
    const classes = useStyles();
    const [value, setValue] = useState<string>(localStorage.getItem('page') || 'add');

    const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
        localStorage.setItem('page', value);
        setValue(value);
    };

    return (
        <>
            <div>
                <RadioGroup row value={value} onChange={handleChange}>
                    <FormControlLabel
                        value="add"
                        control={<Radio size="small" />}
                        label={<span className={classes.tab}>Add manually</span>}
                    />
                    <FormControlLabel
                        value="import"
                        control={<Radio size="small" />}
                        label={<span className={classes.tab}>Import from file</span>}
                    />
                    <FormControlLabel
                        value="other"
                        control={<Radio size="small" />}
                        label={<span className={classes.tab}>Add other celebrations</span>}
                    />
                </RadioGroup>
            </div>
            {value === 'add' ? (
                <div>
                    <AddManually manually={props.emp} />
                </div>
            ) : value === 'other' ? (
                <Addothercele />
            ) : (
                <BulkData sendUsersData={props.sendUsersData} teams={props.emp} />
            )}
        </>
    );
}

export const DobCelebration = (props: { open?: number; setOpen?: any }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.dobPaper} data-testid="dobpaper">
                <Grid container style={{ paddingLeft: '10px' }}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid container>
                                <Typography className={classes.heading}>
                                    Add birthdays and work anniversaries
                                </Typography>
                            </Grid>

                            <Grid container style={{ display: 'flex' }}>
                                <Button
                                    className={classes.dobBtn}
                                    variant="outlined"
                                    color="primary"
                                    disableRipple={true}
                                    onClick={() => props.setOpen(1)}
                                >
                                    Add Manually
                                </Button>
                                <Button
                                    className={classes.dobBtn2}
                                    onClick={() => props.setOpen(2)}
                                    variant="contained"
                                    id="uploadBtn"
                                    disableRipple
                                    color="primary"
                                    component="span"
                                >
                                    Import File
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default function Celebration() {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const [open, setOpen] = useState<number>(0);
    const [cardData, setCardData] = useState<any>([]);
    const [commentData, setCommentData] = useState<any>([]);
    const [option, setOption] = useState<string>('');
    const dispatch = useDispatch();
    const [emp, setEmp] = useState<any>([]);
    const [modal, setModal] = useState<boolean>(false);
    const state = useSelector((state: any) => state);
    const getEmp = useSubscription(getEmployees(mycontext.tenantTableId));
    const [isError, setIsError] = useState<boolean>(false);
    // console.log(mycontext.country);
    const [otherData, setOtherData] = useState<any>([]);
    const [birthday, setBirthday] = useState<any>([]);
    const [work, setWork] = useState<any>([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchUpcomingCelebration(
                mycontext.tenantTableId,
                localToISO(
                    getCurrentCountryDateTime(mycontext.country.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country.zoneName).time,
                    mycontext.country,
                ),
                new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString(),
                mycontext.token,
            );
            const first = data?.filter((a: any) => a.category === 'other');
            const second = data?.filter((a: any) => a.category === 'work');
            const third = data?.filter((a: any) => a.category === 'birthday');
            setOtherData(first);
            setWork(second);
            setBirthday(third);
        };
        fetchData();
    }, [mycontext]);
    //console.log('test');
    useMemo(() => {
        if (!getEmp?.loading) {
            const data: any = getEmp?.data?.usermanagementdb_tenant_employee;
            if (data.error) {
                setIsError(true);
            } else {
                setEmp(data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getEmp]);

    useEffect(() => {
        dispatch(updateActiveTab('/dashboard'));
    }, [dispatch]);

    const sendUsersData = async (e: any, userData: any) => {
        e.preventDefault();
        try {
            // setIsUpdating(true);
            if (userData.length > 0) {
                const userFilter = userData.filter((item: any) => item.error === undefined);
                if (userFilter.length > 0) {
                    userFilter.map(async (a: any) => {
                        const obj = {
                            employeeId: a.employeeId,
                            organizationId: mycontext.organizationId,
                            dateOfBirth: a.Birthdate,
                            workAnniversary: a.workAnniversary,
                            tenantId: mycontext.tenantTableId,
                            token: mycontext.token,
                        };
                        // //console.log(obj);
                        if (obj.dateOfBirth !== undefined) {
                            const resp = await dateofBirthfunc(obj);
                            if (resp.error) {
                                throw new Error('Something went wrong try again!');
                            }
                            // //console.log(resp);
                            const event = {
                                payload: {
                                    employeeId: resp.employeeId,
                                    tenantId: mycontext.tenantTableId,
                                    departmentId: mycontext.organizationId,
                                    celebrationDate: resp.dateOfBirth,
                                    category: 'birthday',
                                    firstName: resp.firstName,
                                    action: 'insert',
                                    timeZone: getCurrentCountryDateTime(mycontext.country.zoneName)
                                        .dateTime.toString()
                                        .slice(25, 33),
                                    user: mycontext.EmployeeId,
                                },
                            };
                            const res = await fetch('https://celebration-dev.azurewebsites.net/api/celebration-dev?', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(event),
                            });
                            const jsonData = await res.json();
                            //console.log(jsonData);
                            if (jsonData.ṣtatusCode === 500) {
                                throw new Error('Something went wrong, try again!');
                            }
                        }
                        if (obj.workAnniversary !== undefined) {
                            const resp = await workAnniversary(obj);
                            if (resp.error) {
                                throw new Error('Something went wrong try again!');
                            }
                            //console.log(resp);
                            const event = {
                                payload: {
                                    employeeId: resp.employeeId,
                                    tenantId: mycontext.tenantTableId,
                                    departmentId: mycontext.organizationId,
                                    celebrationDate: obj.workAnniversary,
                                    category: 'work',
                                    firstName: resp?.Employee?.firstName,
                                    action: 'insert',
                                    timeZone: getCurrentCountryDateTime(mycontext.country.zoneName)
                                        .dateTime.toString()
                                        .slice(25, 33),
                                    user: mycontext.EmployeeId,
                                },
                            };
                            //console.log(event);
                            const res = await fetch('https://celebration-dev.azurewebsites.net/api/celebration-dev?', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(event),
                            });
                            const jsonData = await res.json();
                            //console.log(jsonData);
                            if (jsonData.statusCode === 500) {
                                throw new Error('Something went wrong, try again!');
                            }
                        }
                    });
                } else {
                    // setIsUpdating(false);
                    throw new Error('Bulk data list is empty');
                }
            } else {
                // setIsUpdating(false);
                throw new Error('Add employees to send data');
            }
        } catch (err) {
            // setIsUpdating(false);
            mycontext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    if (
        state?.celebrations?.todaycelebrationsStatus === 'failed' ||
        state?.celebrations?.pastCelebrationsStatus === 'failed' ||
        state?.celebrations?.upcomingCelebrationsStatus === 'failed' ||
        isError
    ) {
        return <ErrorPage />;
    } else {
        if (open === 1) {
            return <AddManually manually={emp} setOpen={setOpen} />;
        }
        if (open === 2) {
            return <BulkData sendUsersData={sendUsersData} setOpen={setOpen} teams={emp} />;
        }
        if (open === 3) {
            return (
                <MobileCard
                    cardData={cardData}
                    setOpen={setOpen}
                    commentData={commentData}
                    option={option}
                    setOption={setOption}
                    setCommentData={setCommentData}
                />
            );
        }

        return (
            <>
                <MyModal open={modal} setOpen={setModal} title="Other Celebration">
                    <Addothercele setModal={setModal} />
                </MyModal>
                <div className={classes.main}>
                    <Hidden lgUp>
                        <div style={{ marginBottom: 10 }}>
                            <DobCelebration open={open} setOpen={setOpen} />
                        </div>
                    </Hidden>
                    <div className={classes.tabbar}>
                        <TabBar
                            todayData={state?.celebrations?.todayCelebrations}
                            pastData={state?.celebrations?.pastCelebrations}
                            setCardData={setCardData}
                            setOpen={setOpen}
                            setCommentData={setCommentData}
                            open={open}
                            option={option}
                            setOption={setOption}
                            emp={emp}
                            sendUsersData={sendUsersData}
                        />
                    </div>
                    <UpcomingCelebration birthday={birthday} work={work} otherData={otherData} myContext={mycontext} />
                </div>
                <Hidden lgUp>
                    <Button className={classes.btn} onClick={() => setModal(true)}>
                        Add Celebration
                    </Button>
                </Hidden>
            </>
        );
    }
}
// }
