import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
const myStyle = makeStyles((theme: Theme) =>
    createStyles({
        paper1: {
            display: 'flex',
            padding: theme.spacing(2),
            flexGrow: 1,
            alignItems: 'center',
        },
        listMain: {
            paddingBottom: '0px',
            textAlign: 'center',
        },
        mobileList: {
            padding: '0 0 8px 0',
        },
        mobilePaper: {
            display: 'flex',
            padding: theme.spacing(2),
            flexGrow: 1,
            alignItems: 'center',
            marginBottom: '20px',
        },
        header: {
            padding: theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,
        },
        mobileDays: {
            textAlign: 'center',
            margin: 10,
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.fontSize,
        },
        days: {
            textAlign: 'center',
            // margin: 10,
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '1.5rem',
            height: '30px',
        },
        mobileLeavesList: {
            display: 'flex',
            flexGrow: 1,
        },
        buttonDiv: {
            display: 'flex',
            alignItems: 'center',
        },

        imageBorder: {
            borderRadius: '50%',
            border: '10px solid',
            borderColor: '#E5E5E5 #E5E5E5 #3D49B6 #3D49B6',
            borderWidth: '5px',
            padding: theme.spacing(0.5),
        },
        profile: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            '@media (max-width: 800px)': {
                marginBottom: '0px',
                padding: '0px',
            },
            '@media screen and (width: 768px) and (height: 1024px)': {
                paddingRight: '15px',
            },
            '@media screen and (width: 1024px) and (height: 1366px)': {
                paddingRight: '15px',
                marginBottom: theme.spacing(0),
            },
        },
        leaves: {
            padding: theme.spacing(1),
        },
        leave: {
            // padding: theme.spacing(2),
            flexGrow: 1,
            justifyContent: 'center',
            '@media (max-width: 800px)': {
                marginBottom: '16px',
            },
        },
        leaveType: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            // margin: 10,
            height: '50px',
        },
        chart: {
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(1),
            objectFit: 'scale-down',
            margin: '0 auto',
            maxWidth: '100%',
            position: 'relative',
            alignSelf: 'center',
        },
        doughnut: {
            width: '200px',
            height: '120px',
        },
        user: {
            alignSelf: 'center',
        },
        avatar: {
            width: '70px',
            height: '70px',
            '@media (max-width: 650px)': {
                width: '60px',
                height: '60px',
            },
            '&:hover': {
                cursor: 'pointer',
            },
        },
        font: {
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'capitalize',
            color: '#072074',
        },
        btnPrimary: {
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            height: 35,
            fontSize: 16,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
            },
        },
        button2: {
            textTransform: 'capitalize',
            textDecoration: 'none',
            outline: 'none',
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
            },
            '&:hover': {
                textDecoration: 'none',
                border: 'none',
            },
        },
        mobileButton: {
            position: 'fixed',
            bottom: '0px',
            borderRadius: '5px',
            width: '100%',
            left: '0px',
            zIndex: 1,
            '&:hover': {
                textDecoration: 'none',
            },
        },
        editicon: {
            marginLeft: 10,
            outline: 'none',
            justifyContent: 'flex-end',
            color: '#7A7A7A',
            size: '15px',
            '&:hover': {
                color: '#000',
                outline: 'none',
                transform: 'scale(1.2)',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        fontleft: {
            width: '100%',
            fontSize: '16px',
            color: '#222222',
            // fontWeight: theme.typography.fontWeightRegular,
            float: 'left',
            border: 'none',
            marginTop: '5px',
        },
        teams: {
            color: '#000',
            textTransform: 'capitalize',
            fontSize: theme.typography.fontSize,
            width: '100%',
            '@media (max-width: 650px)': {
                float: 'left',
                wordWrap: 'break-word',
            },
        },
        card: {
            '@media (max-width:320px)': {
                width: '100%',
            },
            marginLeft: '5px',
            justifyContent: 'center',
            // padding: '0px 0px 10px 0px',
            // '&:last-child': {
            //     paddingBottom: '0px',
            // },
        },
        uploadfileDiv: {
            padding: '30px 0px',
        },
        formLabel: {
            color: '#444444',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
            zIndex: 0,
        },
        image: {
            '@media only screen and (min-width: 660px)': {
                maxWidth: '100%',
                height: '250px',
                marginBottom: '10%',
                marginTop: '10px',
                '& img': {
                    maxWidth: '100%',
                    height: '250px',
                },
            },
            '@media only screen and (max-width: 650px)': {
                maxWidth: '100%',
                height: '210px',
                marginBottom: '10%',
                marginTop: '10px',
                '& img': {
                    maxWidth: '100%',
                    height: '210px',
                },
            },
        },
        inputbox: {
            margin: '30px 0px',
        },
        cancelBtn: {
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            marginRight: '20px',
            '&:focus, &:hover': {
                outline: 'none',
            },
        },
        submitBtn: {
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            '&:focus, &:hover': {
                outline: 'none',
                backgroundColor: '#3C48B5',
            },
        },
        formControlLeft: {
            color: '#A6ACAF',
            width: '100%',
            margin: 0,
            fontFamily: theme.typography.fontFamily,
            '@media only screen and (max-width: 650px)': {
                width: '100%',
            },
        },
        formControlRight: {
            color: '#A6ACAF',
            width: '100%',
            margin: 0,
            fontFamily: theme.typography.fontFamily,
            '@media only screen and (max-width: 650px)': {
                width: '100%',
            },
        },
        field: {
            padding: '10px 0px',
            '@media screen and (max-width: 650px)': {
                padding: '10px 0px',
            },
        },
        smallField: {
            padding: 0,
            '@media screen and (max-width: 650px)': {
                padding: '10px 0px',
            },
        },
        formLabelForm: {
            width: '100%',
            textAlign: 'left',
            color: 'black',
            fontWeight: 700,
            fontSize: '15px',
            fontFamily: theme.typography.fontFamily,
            opacity: 0.6,
            '@media (max-width: 650px)': {
                textAlign: 'left',
                paddingBottom: 0,
            },
        },
        // modal scroll
        scrollModal: {
            marginTop: 15,
            paddingRight: 15,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
        },
        // new dashboard styles
        mainDiv: {
            // height: '80vh',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: '10px',
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                width: '100%',
                flexDirection: 'column-reverse',
            },
        },
        leftDiv: {
            // height: 'calc(100vh - 70px)',
            height: 'auto',
            display: 'grid',
            // gridTemplateColumn: '',
            gridTemplateRows: 'auto auto',
            gridRowGap: '10px',
            // flexDirection: 'column',
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
            },
        },
        graphLeft: {
            // display: 'grid',
            // gridTemplateColumns: '1fr 2fr',
            // gridColumnGap: '10px',
            height: 'calc(100vh - 300px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: theme.palette.background.paper,
            borderRadius: 5,
            padding: 10,
            // margin: '10px 0',
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
            },
        },
        groupDiv: {
            // height: '100%',
            // display: 'flex',
            // flexDirection: 'row',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: '10px',
            marginBottom: '10px',
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                width: '100%',
                flexDirection: 'column-reverse',
                marginBottom: '0px',
            },
        },
        piechartContainer: {
            // display: 'flex',
            borderRadius: 5,
            padding: '5px',
            // border: '1px solid',
            height: '100%',
            width: '99.7%',
            // background: theme.palette.background.paper,
            '@media screen and (max-width: 768px)': {
                flexDirection: 'column',
                justifyContent: 'center',
            },
        },
        piechartDiv: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // background: theme.palette.background.paper,

            '@media screen and (max-width: 768px)': {
                width: '100%',
                justifyContent: 'space-around',
            },
        },
        piechartColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            '@media screen and (max-width: 768px)': {
                flexDirection: 'column-reverse',
                width: '100%',
                alignItems: 'center',
            },
        },
        // slack btn
        slackBtn: {
            width: '100%',
            height: 60,
            background: theme.palette.background.paper,
            borderRadius: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (max-width: 768px)': {
                height: 50,
                border: `2px solid ${theme.palette.background.paper}`,
                marginBottom: '10px',
            },
        },
        slackImg: {
            width: 26,
            height: 26,
            marginRight: 15,
            '@media screen and (max-width: 768px)': {
                width: 26,
                height: 26,
            },
        },
        slackText: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700,
            fontSize: 18,
            letterSpacing: '0.01em',
            color: theme.palette.text.primary,
            marginTop: 5,
            '@media screen and (max-width: 768px)': {
                fontSize: 18,
            },
        },
        // Progress bar
        progressDiv: {
            width: '100%',
            height: 60,
            background: '#5559C7',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '@media screen and (max-width: 768px)': {
                height: 75,
                marginBottom: 10,
            },
        },
        textContent: {
            height: '70%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            '@media screen and (max-width: 768px)': {
                flexDirection: 'row',
                alignItems: 'center',
            },
        },
        progressText1: {
            fontSize: 18,
            fontWeight: 600,
            color: '#fff',
            // marginBottom: -2,
            letterSpacing: '0.01em',
            fontFamily: 'Nunito Sans',
            '@media screen and (max-width: 768px)': {
                marginBottom: 'none',
                marginRight: 5,
            },
            '@media screen and (min-width: 768px)': {
                marginBottom: -2,
                marginRight: 5,
            },
        },
        progressText2: {
            fontSize: 18,
            fontWeight: 600,
            color: '#fff',
            // marginTop: -2,
            letterSpacing: '0.01em',
            fontFamily: 'Nunito Sans',
            '@media screen and (max-width: 768px)': {
                marginTop: 'none',
            },
            '@media screen and (min-width: 768px)': {
                marginBottom: -2,
            },
        },
        progressCount: {
            fontSize: 45,
            color: '#fff',
            fontWeight: 700,
            marginRight: 15,
            '@media screen and (max-width: 768px)': {
                fontSize: 28,
                marginTop: -2,
            },
        },
        employeeStatistics: {
            height: '240px',
            display: 'flex',
            // width: '99%',
            // marginLeft: '0.5%',
            flexDirection: 'row',
            justifyContent: 'center',
            background: theme.palette.background.paper,
            // padding: '10px',
            borderRadius: 5,
            '@media screen and (max-width: 1050px)': {
                height: 'auto',
            },
        },
        mainTitle: {
            fontSize: 16,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
        },
        employeeStatisticsTitle: {
            fontSize: 16,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
        },
        pieChartOneTitle: {
            fontFamily: 'Nunito Sans',
            fontSize: 14,
            color: theme.palette.text.primary,
            fontWeight: 600,
            marginRight: 10,
            margin: '0 10px',
            marginBottom: 5,
        },
        empStatusTodayTitle: {
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
            letterSpacing: '0.02em',
            paddingLeft: 10,
            color: theme.palette.text.primary,
        },
        // graph
        graphDiv: {
            height: '100%',
            background: theme.palette.background.paper,
            borderRadius: 5,
            padding: 15,
            paddingTop: 5,
            display: 'flex',
            flexDirection: 'column',
            '@media screen and (max-width: 768px)': {
                // margin: '10px 0',
                marginBottom: '10px',
            },
        },
        // accordian
        accordianMain: {
            '@media screen and (max-width: 768px)': {
                marginBottom: 10,
                marginTop: 10,
            },
        },
        accordianDetails: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            '@media screen and (max-width: 768px)': {
                height: 'auto',
            },
        },
        graphHeader: {
            fontSize: 16,
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            fontFamily: 'Nunito Sans',
        },
        accordionTitle: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            '@media screen and (max-width: 768px)': {
                fontSize: 14,
            },
        },
        accordianDetails2: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            '@media screen and (max-width: 768px)': {
                height: 'auto',
            },
        },
        scrollDivAccordian: {
            height: 'calc(100vh - 345px)',
            // height: '100%',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
            '@media screen and (max-width: 768px)': {
                maxHeight: '300px',
                height: 'auto',
            },
        },
        scrollDivAccordian2: {
            height: 'calc(100vh - 310px)',
            // height: '100%',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
            '@media screen and (max-width: 768px)': {
                maxHeight: '300px',
                height: 'auto',
            },
        },
        scrollDivAccordianProfile: {
            height: 'calc(100vh - 250px)',
            // height: '100%',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
            '@media screen and  (min-width: 1500px)': {
                height: 'calc(100vh - 390px)',
            },

            '@media screen and (max-width: 900px)': {
                maxHeight: '300px',
                height: 'auto',
            },
            // '@media screen and (max-width: 768px)': {
            //     maxHeight: '300px',
            //     height: 'auto',
            // },
        },
        notfoundImage: {
            maxWidth: '75%',
            height: '60%',
        },
        notfoundImage2: {
            height: 150,
            width: 200,
        },
        notfoundText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.disabled,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
        },
        name: {
            fontSize: 16,
            color: theme.palette.secondary.contrastText,
            letterSpacing: '0.02em',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            marginRight: 10,
            cursor: 'pointer',
        },
        date: {
            fontSize: 12,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            letterSpacing: '0.02em',
            '&span': {
                fontSize: 12,
                color: '#666666',
                fontWeight: 'normal',
                letterSpacing: '0.02em',
                fontFamily: theme.typography.fontFamily,
            },
        },
        reason: {
            fontSize: 12,
            color: theme.palette.primary.contrastText,
            fontWeight: 400,
            letterSpacing: '0.02em',
            fontFamily: theme.typography.fontFamily,
        },
        approveBtn: {
            backgroundColor: theme.palette.success.main,
            color: '#fff',
            height: 18,
            width: 'auto',
            // borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 3px',
            borderRadius: 9,
            padding: 5,
            cursor: 'pointer',
            '& span': {
                display: 'block',
                marginLeft: 5,
            },
            '& icon': {
                margin: '0px 5px',
            },
            // '&:hover': {
            //     width: 'auto',
            //     padding: '5px',
            //     borderRadius: 9,
            //     justifyContent: 'space-around',
            //     '& span': {
            //         display: 'block',
            //         marginLeft: 5,
            //     },
            //     '& icon': {
            //         margin: '0px 5px',
            //     },
            // },
        },
        denyBtn: {
            color: '#fff',
            backgroundColor: theme.palette.error.main,
            height: 18,
            width: 'auto',
            // borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 3px',
            padding: '5px 7px',
            borderRadius: 9,
            cursor: 'pointer',
            '& span': {
                display: 'block',
                marginLeft: 5,
            },
            '& icon': {
                margin: '0px 5px',
            },
            // '&:hover': {
            //     width: 'auto',
            //     padding: '5px',
            //     justifyContent: 'center',
            //     borderRadius: 9,
            //     '& span': {
            //         display: 'block',
            //         marginLeft: 5,
            //     },
            //     '& icon': {
            //         margin: '0px 5px',
            //     },
            // },
        },
        denyEditBtn: {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            height: 18,
            width: 'auto',
            // borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 3px',
            padding: '5px 7px',
            borderRadius: 9,
            cursor: 'pointer',
            '& span': {
                display: 'block',
                marginLeft: 5,
            },
            '& icon': {
                margin: '0px 5px',
            },
        },
        icon: {
            color: '#fff',
            fontSize: 12,
            fontWeight: 'bold',
        },
        btntext: {
            color: '#fff',
            fontSize: 10,
            fontWeight: 'bold',
            fontFamily: 'Nunito Sans',
        },
        // leave
        leaveMain: {
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            paddingBottom: 10,
        },
        leaveTop: {
            display: 'flex',
            paddingRight: 10,
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        dateTimeDiv: {
            display: 'flex',
            flexWrap: 'wrap',
            '@media screen and (max-width: 768px)': {
                display: 'block',
            },
        },
        actionbtn: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
            '@media screen and (max-width: 768px)': {
                display: 'none',
            },
        },
        btncontainer: {
            display: 'none',
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                margin: '10px 0',
            },
        },
        editBtn: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.success.main,
            width: '98%',
            cursor: 'pointer',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'capitalize',
            '&:hover': {
                color: theme.palette.success.main,
                backgroundColor: theme.palette.background.paper,
            },
            '&:focus': {
                color: theme.palette.success.main,
                backgroundColor: theme.palette.background.paper,
                outline: 'none',
            },
            zIndex: 0,
        },
        deleteBtn: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.error.main,
            width: '98%',
            cursor: 'pointer',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            textTransform: 'capitalize',
            '&:hover': {
                color: theme.palette.error.main,
                backgroundColor: theme.palette.background.paper,
            },
            '&:focus': {
                color: theme.palette.error.main,
                backgroundColor: theme.palette.background.paper,
                outline: 'none',
            },
            zIndex: 0,
        },
        searchbarRounded: {
            // marginTop: -10,
            width: '90%',
            // background: 'red',
            height: 35,
            borderRadius: '35px',
            alignSelf: 'center',
            marginBottom: 5,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 10px',
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            outline: 'none',
            // zIndex: 99,
            backgroundColor: theme.palette.background.paper,
        },
        searchbarRect: {
            width: '90%',
            // background: '#D9DBE5',
            height: 35,
            borderRadius: '35px',
            alignSelf: 'center',
            marginBottom: 5,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 10px',
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            outline: 'none',
            backgroundColor: theme.palette.background.paper,
        },
        searchBarInput: {
            flex: 1,
            outline: 'none',
            border: 'none',
            fontSize: 14,
            fontFamily: 'Nunito Sans',
            width: '100%',
            color: theme.palette.text.primary,
            // border: `1px solid ${theme.palette.primary.contrastText}`,
            backgroundColor: theme.palette.background.paper,
        },
        accordionHeaderStyle: {
            height: 30,
            backgroundColor: theme.palette.background.paper,
            padding: '0 20px',
            borderRadius: 5,
        },
        createBtn: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            outline: 'none',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'capitalize',
            height: 30,
            width: 100,
            '@media (max-width: 650px)': {
                fontSize: '14px',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        deleteButton: {
            height: 30,
            width: 100,
            outline: 'none',
            position: 'relative',
            float: 'left',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            background: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            marginRight: 5,
            '@media (max-width: 650px)': {
                fontSize: '14px',
                outline: 'none',
                color: theme.palette.text.secondary,
                background: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
            },
            '&:hover': {
                outline: 'none',
                color: theme.palette.text.secondary,
                background: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
            },
            '&:focus': {
                outline: 'none',
                color: theme.palette.text.secondary,
                background: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
            },
        },
        progressBar: {
            display: 'flex',
            alignItems: 'center',
            width: '240px',
            '@media screen and (max-width: 768px)': {
                width: '260px',
                margin: '5px 0',
            },
        },
        pendingAction: {
            '&:hover': {
                // backgroundColor: theme.palette.secondary.light,
                // color: '#FFFFFF',
            },
        },
        pendingActionMain: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: 20,
            '@media screen and (max-width: 768px)': {
                flexDirection: 'column',
                paddingRight: 0,
                alignItems: 'flex-start',
            },
        },
        remindMeBtn: {
            width: 120,
            borderRadius: 35,
            fontSize: 12,
            fontWeight: 'bold',
            textTransform: 'capitalize',
            fontFamily: 'Nunito Sans',
            outline: 'none',
            '@media screen and (max-width: 768px)': {
                width: 120,
                margin: '10px 0',
            },
        },
        denyModalContent: {
            display: 'flex',
            '@media screen and (max-width: 768px)': {
                flexDirection: 'column',
            },
        },
        input: {
            height: 8,
            width: 80,
            border: `1px solid ${theme.palette.text.secondary}`,
        },
        inputFirst: {
            width: 100,
            height: 10,
            fontFamily: 'Nunito Sans',
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.secondary.contrastText,
            padding: '3px 1px',
            ' & div': {
                '& svg': {
                    color: theme.palette.text.secondary,
                },
            },
        },
        graphDropDown: {
            fontFamily: 'Nunito Sans',
            fontSize: 12,
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
        },
        popover: {
            borderRadius: 2,
            padding: 5,
            backgroundColor: '#fff',
            boxShadow: '0px 5px 10px rgba(26, 32, 85, 0.25)',
        },
        textDiv: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 3,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#E5E8E8',
            },
        },
        popoverText: {
            fontFamily: 'Nunito Sans',
            fontSize: 14,
            color: '#666666',
        },
        textTheme: {
            color: theme.palette.text.primary,
        },
        pieChartDropDownTitle: {
            fontWeight: 600,
            color: theme.palette.secondary.contrastText,
        },
        pieChartDropDown: {
            fontFamily: 'Nunito Sans',
            fontSize: 14,
            color: '#333333',
            fontWeight: 600,
            marginRight: 10,
            margin: '0 10px',
            marginBottom: 5,
            '& svg': {
                color: theme.palette.text.primary,
            },
        },
        allCheckbox: {
            display: 'flex',
            height: 35,
            alignItems: 'center',
            // justifyContent: 'space-between',
        },
        textDeco: {
            '& div': {
                '& textarea': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    color: theme.palette.text.primary,
                },
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
        },
        filterName: {
            padding: '2px 10px',
            width: '120px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
            },
        },

        pointDiv: {
            padding: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },

        avatarDiv: {
            padding: '3px',
            // paddingLeft: '25px',
            display: 'flex',
            justifyContent: 'center',
        },

        point: {
            width: '9px',
            height: '9px',
            borderRadius: '2px',
            marginTop: '6px',
            marginRight: '8px',
        },

        pointTitle: {
            fontSize: '14px',
            color: theme.palette.text.primary,
            fontFamily: 'Nunito Sans',
        },
        teamTitle: {
            // fontSize: 17,
            // marginBottom: '10px',
            // // fontWeight: 500,
            // color: theme.palette.text.primary,
            // '@media (min-width: 800px)': {
            //     marginBottom: '30px',
            //     fontSize: 19,
            // },
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            // marginBottom: 10,
            paddingLeft: '15px',
            color: theme.palette.text.primary,
            '@media screen and (max-width: 900px)': {
                marginBottom: 10,
            },
            // border: '1px solid'
        },
        noTimeofFound: {
            color: theme.palette.primary.contrastText,
            marginTop: '6px',
        },

        filterButtons: {
            // height: '30px',
            // border: `1px solid ${theme.palette.text.primary}`,
            // '&:focus': {
            //     outline: 'none',
            // },
            textTransform: 'initial',
            height: '24px',
            width: '110px',
            borderRadius: 5,
            fontSize: '11px',
            marginRight: 8,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width: 400px)': {
                width: 95,
            },
        },

        filterButtons2: {
            height: '30px',
            marginLeft: '10px',
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.text.primary}`,
            '&:focus': {
                outline: 'none',
            },
        },
        scroll1: {
            maxHeight: '120px',
            paddingRight: '4px',
            paddingLeft: '4px',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },

        test: {
            height: 37,
            '@media only screen and (max-width: 900px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
                width: 'auto',
            },
            display: 'flex',
            width: '150px',
            borderRadius: '4px',
            alignItems: 'center',
        },
        test2: {
            height: 37,
            '@media only screen and (max-width: 1200px)': {
                // border: `1px solid ${theme.palette.primary.contrastText}`,
                width: 'auto',
            },
            display: 'flex',
            borderRadius: '4px',
            alignItems: 'center',
        },
        filterName2: {
            padding: '2px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
        },
        graphXAxis2: {
            transform: 'rotate(-90deg)',
            position: 'absolute',
            marginTop: '10%',
            marginLeft: '-30px',
            fontFamily: 'Nunito Sans',
            color: theme.palette.text.primary,
            '@media only screen and (max-width: 1310px)': {
                marginTop: '7%',
            },
            '@media only screen and (min-height: 721px) and (max-height: 1067px) and (min-width: 300px) and (max-width: 768px)': {
                marginTop: '30%',
            },
            '@media only screen and (min-width: 800px) and (max-width: 920px)': {
                marginTop: '40%',
            },
            '@media only screen and (max-height: 720px) and (min-width: 300px) and (max-width: 768px)': {
                marginTop: '15%',
            },
        },
        graphXAxis: {
            transform: 'rotate(270deg)',
            position: 'absolute',
            marginTop: '7%',
            marginLeft: '-35px',
            color: theme.palette.text.primary,
            // '@media screen and (max-width: 768px)': {
            //     // display: 'none',
            //     marginTop: '25%',
            // },
            '@media only screen and (max-width: 1310px)': {
                marginTop: '7%',
            },
            '@media only screen and (min-height: 721px) and (max-height: 1067px) and (min-width: 300px) and (max-width: 768px)': {
                marginTop: '31%',
            },
            '@media only screen and (min-width: 800px) and (max-width: 920px)': {
                marginTop: '41%',
            },
            '@media only screen and (max-height: 720px) and (min-width: 300px) and (max-width: 768px)': {
                marginTop: '16%',
            },
        },
        filterList: {
            maxHeight: 160,
            width: '110px',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        teamPerformanceDiv: {
            justifyContent: 'space-between',
            '@media screen and (min-width: 750px)': {
                display: 'flex',
            },
        },
        tooltip: {
            fontSize: 11,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        timeoffStatusContainer: {
            display: 'flex',
            borderRadius: 5,
            paddingTop: '5%',
            height: '100%',
            '@media screen and (max-width: 768px)': {
                flexDirection: 'column',
                justifyContent: 'center',
            },
        },
        paperAvtar: {
            backgroundColor: 'white',
            height: '33px',
            width: '33px',
            borderRadius: '100%',
        },
        avatar2: {
            height: '27px',
            width: '27px',
            fontSize: '12px',
            dispaly: 'flex',
            justifyContent: 'center',
            marginTop: '1px',
            marginLeft: '1px',
            backgroundColor: '#EDEFFA',
            color: '#444444',
        },
        graphDiv1: {
            width: 600,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '@media screen and (max-width: 768px)': {
                width: 'auto',
            },
            '@media screen and (min-width: 769px) and (max-width: 1250px)': {
                width: '350px',
            },
            '@media screen and (min-width:1600px)': {
                minWidth: 950,
            },
        },
        graph: {
            minWidth: 480,
            width: 'auto',
            height: 'calc(100vh - 420px)',
            background: theme.palette.background.paper,
            display: 'flex',
            // justifyContent: 'flex-start',
            '@media screen and (max-width: 1250px)': {
                height: 'calc(100vh - 480px)',
                minWidth: 250,
                width: 'auto',
            },
        },
    }),
);
export default myStyle;
