import React, { useContext, useState, useEffect, useRef } from 'react';
import {
    useSelector,
    // useDispatch
} from 'react-redux';
import useStyles from './DrawerStyle';

import Udyamo from './Group 91.png'; //Udyamo Logo-grey.png  Group 104.png
import UdyIco from './Group 92.png';

import {
    Hidden,
    useTheme,
    //  Avatar
} from '@material-ui/core';
import Menu, { MobileMenu } from '../Sidebar/menu/menu';
import Profile from '../Sidebar/profile/profile';

import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
// import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
// import AssessmentIcon from '@mui/icons-material/Assessment';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import PeopleIcon from '@material-ui/icons/People';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
// import PeopleIcon from '@material-ui/icons/People';
import ApartmentIcon from '@material-ui/icons/Apartment';
//import FlightIcon from '@material-ui/icons/Flight';
// import SettingsIcon from '@material-ui/icons/Settings';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import AuthContext from '../../context/AuthContext';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
// import PersonIcon from '@material-ui/icons/Person';
// import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import teamplus from '../../Assets/images/teamplus-white.png';
import { s3Bucket, sideBarImages } from '../../helpers/constants';
import Modal from '../Modal/Modal';
import { FormControl, FormLabel, Select, MenuItem } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { toAWSDateFormat, DateDifference } from '../../helpers/date';
import { DateField2 } from '../ImportHoliday/FormInputs';
import { CSVLink } from 'react-csv';
import { getAllLeavesReports, getLeavesBalanceReport } from '../../helpers/hasuraapi';
import { PopUp } from '../Modal/Modal';
import { Button } from '@material-ui/core';
import { Icon } from '@iconify/react';
// import select_plugins from './select_plugins.svg';
// import plugins from './plugins.svg';

interface ResponsiveDrawerProps {
    container?: Element;
    className?: any;
    isSmall?: boolean;
    setSmall?: any;
}

export default function SideDrawer(props: ResponsiveDrawerProps): JSX.Element {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const theme = useTheme();
    const isAdmin = mycontext.isAdmin;
    const isApprover = mycontext.isApprover;

    const [open, setOpen] = useState<boolean>(false);
    const [type, setType]: any = useState('one');
    // const [selectedValue, setSelectedValue] = React.useState('a');
    const [selected, setSelected] = useState([]);
    const store: any = useSelector((store: any) => store.orgEmployees);
    const [startDate, setStartDate] = useState<any>(toAWSDateFormat(new Date()));
    const [endDate, setEndDate] = useState<any>(toAWSDateFormat(new Date()));
    const [currentEmployees, setCurrentEmployees]: any = React.useState<any>([]);
    const [data, setdata] = useState<any>([]);
    const [headers, setHeaders] = useState<any>([]);
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [leavesCountData, setLeavesCountData] = useState<any>([]);
    const [filename, setFilename] = useState<string>('all_timeoff_report.csv');
    const [isRun, setIsRun] = useState<any>(null);
    const csvLink: any = useRef();
    const subscriptionExpired =
        mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate);

    useEffect(() => {
        (async function() {
            try {
                await leavesCountData.forEach(function(val: any) {
                    val.Employee.organization_employees[0].Organization.LeaveTypes.map((value: any) => {
                        if (value.leaveTypeName === val.leaveType) {
                            if (value.daysPerYear === 0 && value.unlimitedDays === true) {
                                val.leaveAllowed = 'unlimited';
                            } else {
                                val.leaveAllowed = value.daysPerYear;
                            }
                        }
                        return null;
                    });
                    const quotient = Math.floor(val.totalHoursLeave / val.workingHours);
                    const remainder = val.totalHoursLeave % val.workingHours;
                    const str = remainder.toString();
                    const numarray = str.split('.');
                    let a = [];
                    a = numarray;
                    const first2Str = String(parseInt(a[1])).slice(0, 2);
                    const first2Num = Number(first2Str);
                    const int_length = ('' + first2Num).length;
                    let first = first2Num;
                    if (int_length < 2) {
                        first = first2Num * 10;
                    }
                    if (isNaN(Math.trunc((first / 100) * 60))) {
                        val.Minutes = 0;
                    } else {
                        val.Minutes = Math.trunc((first / 100) * 60);
                    }
                    val.Days = quotient;
                    val.Hours = Math.trunc(remainder);
                    val.startDate = startDate;
                    val.endDate = endDate;
                });
                await setdata(leavesCountData);
                if (isRun === null) {
                    setIsRun(false);
                } else if (isRun === false) {
                    await csvLink.current.link.click();
                }
            } catch (e) {
                return e;
            }
        })();
    }, [leavesCountData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCloseModal = () => {
        setOpen(false);
    };

    const onReportClick = (v: any) => {
        setOpen(true);
    };

    const onReportClick1 = async () => {
        const arr: any = [];
        await currentEmployees.map((val: any) => {
            arr.push(val.employeeId);
            return null;
        });
        if (currentEmployees.length === 0) {
            setAlertMessage('Please select atleast 1 employee');
            setAlert(true);
        } else if (DateDifference(startDate, endDate) < 0) {
            setAlertMessage('Please select a valid date');
            setAlert(true);
        } else {
            if (type === 'one') {
                setFilename('all_timeoff_report.csv');
                const result = await getAllLeavesReports(
                    {
                        employees: arr,
                        orgId: mycontext.organizationId,
                        startDate: startDate,
                        endDate: endDate,
                    },
                    mycontext.token,
                );

                await result.timeoffdb_Leaves.map((val: any, key: any) => {
                    if (val.leaveStatus === 'pending') {
                        return (val.approver = '');
                    } else if (val.leaveStatus === 'denied') {
                        return (val.approver = '');
                    } else {
                        return (val.approver = val.approverId
                            ? store.employees.filter((e: any) => e.employeeId === val.approverId)[0]?.Employee
                                  ?.firstName
                            : 'Auto Approved');
                    }
                });

                setHeaders([
                    { label: 'Firstname', key: 'Employee.firstName' },
                    { label: 'Lastname', key: 'Employee.lastName' },
                    { label: 'Start Date', key: 'startDate' },
                    { label: 'End Date', key: 'endDate' },
                    { label: 'Start Time', key: 'startTime' },
                    { label: 'End Time', key: 'endTime' },
                    { label: 'Category', key: 'category' },
                    { label: 'Leave Count', key: 'leaveCount' },
                    { label: 'Leave Status', key: 'leaveStatus' },
                    { label: 'Denied Reason', key: 'denialReason' },
                    { label: 'No. of hours', key: 'noOfHours' },
                    { label: 'Reason', key: 'reason' },
                    { label: 'Approved By', key: 'approver' },
                ]);
                setdata(result.timeoffdb_Leaves);
                csvLink.current.link.click();
            } else {
                setFilename('timeoff_balance_report.csv');
                const response = await getLeavesBalanceReport(
                    {
                        employees: arr,
                        orgId: mycontext.organizationId,
                        startDate: startDate,
                        endDate: endDate,
                    },
                    mycontext.token,
                );
                setHeaders([
                    { label: 'Firstname', key: 'Employee.firstName' },
                    { label: 'Lastname', key: 'Employee.lastName' },
                    { label: 'From', key: 'startDate' },
                    { label: 'To', key: 'endDate' },
                    { label: 'Leave Type', key: 'leaveType' },
                    { label: 'No. of Days Leave Taken', key: 'Days' },
                    { label: 'No. of Hours Leave Taken', key: 'Hours' },
                    { label: 'No. of Minutes Leave Taken', key: 'Minutes' },
                    { label: 'Number of days allowed to take leaves as per leave type', key: 'leaveAllowed' },
                ]);
                setLeavesCountData(response.timeoffdb_get_totalhoursbydate_org);
            }
        }
    };

    const handleDates = (v: any) => {
        setStartDate(v);
        setEndDate(v);
    };

    const resetSettings = () => {
        setType('one');
        setCurrentEmployees([]);
        setStartDate(toAWSDateFormat(new Date()));
        setEndDate(toAWSDateFormat(new Date()));
        setSelected([]);
    };

    const handleChange1 = (event: any) => {
        const value = event.target.value;
        setSelected(value);
        setCurrentEmployees(value);
    };

    const AdminPages = [
        {
            id: 1,
            title: 'Dashboard',
            href: '/dashboard',
            icon: <DashboardIcon />,
            image: s3Bucket + sideBarImages.dashboardActive,
            imageOutline: s3Bucket + sideBarImages.dashboardInactive,
            iconOutline: <DashboardOutlinedIcon />,
        },
        {
            id: 2,
            title: mycontext.teamsCalledAs,
            href: '/teams',
            icon: <ApartmentIcon />,
            image: s3Bucket + sideBarImages.teamsActive,
            imageOutline: s3Bucket + sideBarImages.teamsInactive,
            iconOutline: <ApartmentIcon />,
        },
        {
            id: 3,
            title: mycontext.employeeCalledAs === 'teamMembers' ? 'Team Members' : 'Employees',
            href: '/employees',
            icon: <PeopleIcon />,
            image: s3Bucket + sideBarImages.employeesActive,
            imageOutline: s3Bucket + sideBarImages.employeesInactive,
            iconOutline: <PeopleOutlinedIcon />,
        },
        // {
        //     id: 4,
        //     title: 'Holidays',
        //     href: '/holiday',
        //     icon: <FlightIcon />,
        //     image: '/dashboard/holiday.png',
        //     imageOutline: '/dashboard/holiday_outline.png',
        //     iconOutline: <FlightIcon />,
        // },
        {
            id: 4,
            title: 'Calendar',
            href: '/calender',
            icon: <DateRangeIcon />,
            image: s3Bucket + sideBarImages.calenderActive,
            imageOutline: s3Bucket + sideBarImages.calenderInactive,
            iconOutline: <DateRangeOutlinedIcon />,
        },
        // {
        //     id: 5,
        //     title: `Polls`,
        //     href: '/polls',
        //     icon: <SpeakerNotesIcon />,
        //     image: s3Bucket + sideBarImages.pollActive,
        //     imageOutline: s3Bucket + sideBarImages.pollInActive,
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        // {
        //     id: 5,
        //     title: `Polls`,
        //     href: '/polls',
        //     icon: <SpeakerNotesIcon />,
        //     image: s3Bucket + sideBarImages.pollActive,
        //     imageOutline: s3Bucket + sideBarImages.pollInActive,
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        {
            id: 6,
            title: 'Settings',
            href: '/settings',
            icon: <SettingsIcon />,
            image: s3Bucket + sideBarImages.settingsActive,
            imageOutline: s3Bucket + sideBarImages.settingsInactive,
            iconOutline: <SettingsOutlinedIcon />,
        },
        // {
        //     id: 7,
        //     title: 'Plugins',
        //     href: '/plugins',
        //     icon: plugins,
        //     image: select_plugins,
        //     imageOutline: plugins,
        //     iconOutline: select_plugins,
        // },
        // {
        //     id: 7,
        //     title: `Standups`,
        //     href: '/standups',
        //     icon: <SpeakerNotesIcon />,
        //     image: '/dashboard/standups.png',
        //     imageOutline: '/dashboard/standups_outlined.svg',
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        // {
        //     id: 8,
        //     title: 'Reports',
        //     href: '/reports',
        //     icon: <AssessmentIcon />,
        //     image: s3Bucket + sideBarImages.reportActive,
        //     imageOutline: s3Bucket + sideBarImages.reportInActive,
        //     iconOutline: <AssessmentOutlinedIcon />,
        // },
    ];

    const ApproverPages = [
        {
            id: 1,
            title: 'Dashboard',
            href: '/dashboard',
            icon: <DashboardIcon />,
            image: s3Bucket + sideBarImages.dashboardActive,
            imageOutline: s3Bucket + sideBarImages.dashboardInactive,
            iconOutline: <DashboardOutlinedIcon />,
        },
        {
            id: 2,
            title: mycontext.teamsCalledAs,
            href: '/teams',
            icon: <ApartmentIcon />,
            image: s3Bucket + sideBarImages.teamsActive,
            imageOutline: s3Bucket + sideBarImages.teamsInactive,
            iconOutline: <ApartmentIcon />,
        },
        {
            id: 3,
            title: mycontext.employeeCalledAs === 'teamMembers' ? 'Team Members' : 'Employees',
            href: '/employees',
            icon: <PeopleIcon />,
            image: s3Bucket + sideBarImages.employeesActive,
            imageOutline: s3Bucket + sideBarImages.employeesInactive,
            iconOutline: <PeopleOutlinedIcon />,
        },
        // {
        //     id: 4,
        //     title: 'Holidays',
        //     href: '/holiday',
        //     icon: <FlightIcon />,
        //     image: '/dashboard/holiday.png',
        //     imageOutline: '/dashboard/holiday_outline.png',
        //     iconOutline: <FlightIcon />,
        // },
        {
            id: 4,
            title: 'Calendar',
            href: '/calender',
            icon: <DateRangeIcon />,
            image: s3Bucket + sideBarImages.calenderActive,
            imageOutline: s3Bucket + sideBarImages.calenderInactive,
            iconOutline: <DateRangeOutlinedIcon />,
        },
        // {
        //     id: 5,
        //     title: 'Standups',
        //     href: '/standups',
        //     icon: <SpeakerNotesIcon />,
        //     image: '/dashboard/standups.png',
        //     imageOutline: '/dashboard/standups_outlined.svg',
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        // {
        //     id: 5,
        //     title: `Polls`,
        //     href: '/polls',
        //     icon: <SpeakerNotesIcon />,
        //     image: s3Bucket + sideBarImages.pollActive,
        //     imageOutline: s3Bucket + sideBarImages.pollInActive,
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
    ];

    const EmployeePages = [
        {
            id: 1,
            title: 'Dashboard',
            href: '/dashboard',
            icon: <DashboardIcon />,
            image: s3Bucket + sideBarImages.dashboardActive,
            imageOutline: s3Bucket + sideBarImages.dashboardInactive,
            iconOutline: <DashboardOutlinedIcon />,
        },
        {
            id: 2,
            title: mycontext.teamsCalledAs,
            href: '/teams',
            icon: <ApartmentIcon />,
            image: s3Bucket + sideBarImages.teamsActive,
            imageOutline: s3Bucket + sideBarImages.teamsInactive,
            iconOutline: <ApartmentIcon />,
        },
        {
            id: 3,
            title: mycontext.employeeCalledAs === 'teamMembers' ? 'Team Members' : 'Employees',
            href: '/employees',
            icon: <PeopleIcon />,
            image: s3Bucket + sideBarImages.employeesActive,
            imageOutline: s3Bucket + sideBarImages.employeesInactive,
            iconOutline: <PeopleOutlinedIcon />,
        },
        // {
        //     id: 4,
        //     title: 'Holidays',
        //     href: '/holiday',
        //     icon: <FlightIcon />,
        //     image: '/dashboard/holiday.png',
        //     imageOutline: '/dashboard/holiday_outline.png',
        //     iconOutline: <FlightIcon />,
        // },
        {
            id: 4,
            title: 'Calendar',
            href: '/calender',
            icon: <DateRangeIcon />,
            image: s3Bucket + sideBarImages.calenderActive,
            imageOutline: s3Bucket + sideBarImages.calenderInactive,
            iconOutline: <DateRangeOutlinedIcon />,
        },
        // {
        //     id: 5,
        //     title: 'Standups',
        //     href: '/standups',
        //     icon: <SpeakerNotesIcon />,
        //     image: '/dashboard/standups.png',
        //     imageOutline: '/dashboard/standups_outlined.svg',
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        // {
        //     id: 5,
        //     title: `Polls`,
        //     href: '/polls',
        //     icon: <SpeakerNotesIcon />,
        //     image: s3Bucket + sideBarImages.pollActive,
        //     imageOutline: s3Bucket + sideBarImages.pollInActive,
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
    ];
    return (
        <Hidden mdDown implementation="css">
            <div className={classes.first}>
                <Modal open={open} setOpen={setOpen} onModalClosed={handleCloseModal} title="Reports">
                    <div style={{ paddingTop: 10 }}>
                        {subscriptionExpired && (
                            <FormLabel className={classes.reporttitle} style={{ color: 'red' }}>
                                Subscription expired, Please renew it !
                            </FormLabel>
                        )}
                        <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity="error" />
                        <div className={classes.fieldDivDateTime}>
                            <div>
                                <FormLabel className={classes.reporttitle}>Report Type</FormLabel>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <Select
                                        id="categorySelect"
                                        value={type}
                                        className={classes.inputSelectStyleOther}
                                        onChange={(e: any) => setType(e.target.value)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                style: {
                                                    marginTop: -10,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="one">All Time-offs report</MenuItem>
                                        <MenuItem value="two">Time-off balance report</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.mobileToPart}>
                                <FormLabel className={classes.reporttitle}>Employees</FormLabel>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    {/* <InputLabel id="mutiple-select-label">Multiple Select</InputLabel> */}
                                    <Select
                                        id="categorySelect"
                                        multiple
                                        value={selected}
                                        onChange={handleChange1}
                                        displayEmpty={true}
                                        className={classes.inputSelectStyleOther}
                                        renderValue={(selected: any) =>
                                            selected.length > 0 ? (
                                                <div>
                                                    {selected.map((s: any, index: number) => (
                                                        <span key={index}>
                                                            {s.Employee?.firstName}
                                                            {index !== selected.length - 1 && <>, &nbsp;</>}
                                                        </span>
                                                    ))}
                                                </div>
                                            ) : (
                                                <span>Select Employees</span>
                                            )
                                        }
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                style: { marginTop: -10 },
                                            },
                                        }}
                                        // style={{
                                        //     maxWidth: 250,
                                        // }}
                                        inputProps={{
                                            'aria-label': 'Without label',
                                            style: {
                                                padding: 5,
                                            },
                                        }}
                                    >
                                        {/* <MenuItem
                                                    value="all"
                                                    // classes={{
                                                    //     root: isAllSelected ? classes.selectedAll : ""
                                                    // }}
                                                > */}
                                        {/* <ListItemIcon> */}
                                        {/* <Checkbox
                                                            classes={{ indeterminate: classes.indeterminateColor }}
                                                            checked={isAllSelected}
                                                            indeterminate={
                                                                selected.length > 0 && selected.length < store.employees.length
                                                            }
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        classes={{ primary: classes.selectAllText }}
                                                        primary="Select All"
                                                    />
                                                </MenuItem> */}

                                        {store.employees.map((emp: any, index: number) => (
                                            <MenuItem key={emp.employeeId} value={emp}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        // defaultChecked={true}
                                                        // checked={true}
                                                        checked={(selected as any).includes(emp) ? true : false}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={emp.Employee?.firstName} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className={classes.fieldDivDateTime}>
                            <div>
                                <FormLabel className={classes.reporttitle}>From</FormLabel>
                                <FormControl className={classes.formControl}>
                                    <DateField2
                                        date={startDate}
                                        setDate={setStartDate}
                                        id="startdatefield"
                                        handleDates={handleDates}
                                    />
                                </FormControl>
                            </div>
                            <div className={classes.mobileToPart}>
                                <FormLabel className={classes.reporttitle}>To</FormLabel>
                                <FormControl className={classes.formControl}>
                                    <DateField2 date={endDate} setDate={setEndDate} id="enddatefield" />
                                </FormControl>
                            </div>
                        </div>

                        <div className={classes.newButtonGroup}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={`${classes.buttonStyle} ${classes.resetButton}`}
                                onClick={() => resetSettings()}
                                // disabled={isDisabled}
                                id="settingsReset"
                                style={{ border: `1px solid ${theme.palette.text.secondary}` }}
                                disabled={subscriptionExpired}
                            >
                                Reset
                            </Button>
                            <Button
                                id="submitBtn"
                                className={classes.btn2}
                                variant="contained"
                                color="primary"
                                disableRipple={true}
                                onClick={onReportClick1}
                                disabled={subscriptionExpired}
                            >
                                Export
                            </Button>
                        </div>

                        <CSVLink
                            headers={headers}
                            data={data}
                            filename={filename}
                            target="_blank"
                            ref={csvLink}
                        ></CSVLink>
                    </div>
                </Modal>
                {/* {props.isSmall ? (
                    <div className={props.isSmall ? classes.second1 : classes.hideSecond1}>
                        {/* <h2 style={{ fontFamily: 'Rye', margin: '0px auto', color: 'purple' }}>A</h2> */}
                {/* <Avatar src={mycontext.profile} style={{ margin: '20px 0px' }} /> */}
                {/*<div style={{ width: '100%', marginTop: 20 }}>
                            <Menu
                                pgs={isAdmin ? AdminPages : isApprover ? ApproverPages : EmployeePages}
                                isSmall={props.isSmall}
                            />
                        </div>
                    </div>
                ) : ( */}
                {/* <div className={props.isSmall ? classes.hideSecond : classes.second}> */}
                {/* <h2
                            style={{
                                fontFamily: 'Rye',
                                margin: '0px auto',
                                marginBottom: 10,
                                color: 'purple',
                            }}
                        >
                            Avakasha
                        </h2> */}
                {/* <Profile linkToMain={true} /> */}
                <div className={classes.toggleDiv}>
                    <div className={classes.toggleButton} onClick={() => props.setSmall(!props.isSmall)}>
                        <div className={classes.toggleTop}></div>
                        <div className={classes.toggleBtn}>
                            <div className={classes.toggleBtnIcon}>
                                <span
                                    style={{
                                        marginLeft: 3,
                                        fontSize: 12,
                                        fontWeight: 600,
                                    }}
                                >
                                    {props.isSmall ? (
                                        <ArrowForwardIosOutlinedIcon className={classes.icon} />
                                    ) : (
                                        <ArrowBackIosOutlinedIcon className={classes.icon} />
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className={classes.toggleBottom}></div>
                    </div>
                </div>

                <Menu pgs={isAdmin ? AdminPages : isApprover ? ApproverPages : EmployeePages} />
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                        {mycontext.isAdmin && (
                            <>
                                {props.isSmall ? (
                                    <div className={classes.outerdiv}>
                                        <Button
                                            variant="outlined"
                                            className={classes.btn1}
                                            onClick={onReportClick}
                                            id="Reports"
                                        >
                                            <Icon icon="heroicons-outline:document-report" width="20" color="white" />
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        className={classes.btn}
                                        onClick={onReportClick}
                                        id="Reports"
                                    >
                                        <Icon icon="heroicons-outline:document-report" width="20" />
                                        &nbsp;Reports
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            padding: '10px 0',
                        }}
                    >
                        {props.isSmall ? (
                            <img src={UdyIco} alt="SmallUdyamo" style={{ maxWidth: '80%', height: 15 }} />
                        ) : (
                            <img src={Udyamo} alt="Udyamo" style={{ maxWidth: '80%', height: 15 }} />
                        )}
                    </div>
                </div>
            </div>
        </Hidden>
    );
}

export function MobileDrawer(): JSX.Element {
    const classes = useStyles();
    const open = useSelector((state: any) => state.sideBar.isOpen);
    // const dispatch = useDispatch();
    const mycontext = useContext(AuthContext);
    const isAdmin = mycontext.isAdmin;
    const isApprover = mycontext.isApprover;

    const AdminPages = [
        {
            id: 1,
            title: 'Dashboard',
            href: '/dashboard',
            icon: <DashboardIcon />,
            image: s3Bucket + sideBarImages.dashboardActive,
            imageOutline: s3Bucket + sideBarImages.dashboardInactive,
            iconOutline: <DashboardOutlinedIcon />,
        },
        {
            id: 2,
            title: mycontext.teamsCalledAs,
            href: '/teams',
            icon: <ApartmentIcon />,
            image: s3Bucket + sideBarImages.teamsActive,
            imageOutline: s3Bucket + sideBarImages.teamsInactive,
            iconOutline: <ApartmentIcon />,
        },
        {
            id: 3,
            title: mycontext.employeeCalledAs === 'teamMembers' ? 'Team Members' : 'Employees',
            href: '/employees',
            icon: <PeopleIcon />,
            image: s3Bucket + sideBarImages.employeesActive,
            imageOutline: s3Bucket + sideBarImages.employeesInactive,
            iconOutline: <PeopleOutlinedIcon />,
        },
        // {
        //     id: 4,
        //     title: 'Holidays',
        //     href: '/holiday',
        //     icon: <FlightIcon />,
        //     image: '/dashboard/holiday.png',
        //     imageOutline: '/dashboard/holiday_outline.png',
        //     iconOutline: <FlightIcon />,
        // },
        {
            id: 4,
            title: 'Calendar',
            href: '/calender',
            icon: <DateRangeIcon />,
            image: s3Bucket + sideBarImages.calenderActive,
            imageOutline: s3Bucket + sideBarImages.calenderInactive,
            iconOutline: <DateRangeOutlinedIcon />,
        },
        // {
        //     id: 5,
        //     title: `Polls`,
        //     href: '/polls',
        //     icon: <SpeakerNotesIcon />,
        //     image: s3Bucket + sideBarImages.pollActive,
        //     imageOutline: s3Bucket + sideBarImages.pollInActive,
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        // {
        //     id: 5,
        //     title: `Polls`,
        //     href: '/polls',
        //     icon: <SpeakerNotesIcon />,
        //     image: s3Bucket + sideBarImages.pollActive,
        //     imageOutline: s3Bucket + sideBarImages.pollInActive,
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        {
            id: 6,
            title: 'Settings',
            href: '/settings',
            icon: <SettingsIcon />,
            image: s3Bucket + sideBarImages.settingsActive,
            imageOutline: s3Bucket + sideBarImages.settingsInactive,
            iconOutline: <SettingsOutlinedIcon />,
        },
        // {
        //     id: 7,
        //     title: 'Plugins',
        //     href: '/plugins',
        //     icon: <SettingsIcon />,
        //     image: s3Bucket + sideBarImages.settingsActive,
        //     imageOutline: s3Bucket + sideBarImages.settingsInactive,
        //     iconOutline: <SettingsOutlinedIcon />,
        // },
        // {
        //     id: 7,
        //     title: `Standups`,
        //     href: '/standups',
        //     icon: <SpeakerNotesIcon />,
        //     image: '/dashboard/standups.png',
        //     imageOutline: '/dashboard/standups_outlined.svg',
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        // {
        //     id: 8,
        //     title: 'Reports',
        //     href: '/reports',
        //     icon: <AssessmentIcon />,
        //     image: s3Bucket + sideBarImages.dashboardActive,
        //     imageOutline: s3Bucket + sideBarImages.dashboardInactive,
        //     iconOutline: <AssessmentOutlinedIcon />,
        // },
    ];

    const ApproverPages = [
        {
            id: 1,
            title: 'Dashboard',
            href: '/dashboard',
            icon: <DashboardIcon />,
            image: s3Bucket + sideBarImages.dashboardActive,
            imageOutline: s3Bucket + sideBarImages.dashboardInactive,
            iconOutline: <DashboardOutlinedIcon />,
        },
        {
            id: 2,
            title: mycontext.teamsCalledAs,
            href: '/teams',
            icon: <ApartmentIcon />,
            image: s3Bucket + sideBarImages.teamsActive,
            imageOutline: s3Bucket + sideBarImages.teamsInactive,
            iconOutline: <ApartmentIcon />,
        },
        {
            id: 3,
            title: mycontext.employeeCalledAs === 'teamMembers' ? 'Team Members' : 'Employees',
            href: '/employees',
            icon: <PeopleIcon />,
            image: s3Bucket + sideBarImages.employeesActive,
            imageOutline: s3Bucket + sideBarImages.employeesInactive,
            iconOutline: <PeopleOutlinedIcon />,
        },
        // {
        //     id: 4,
        //     title: 'Holidays',
        //     href: '/holiday',
        //     icon: <FlightIcon />,
        //     image: '/dashboard/holiday.png',
        //     imageOutline: '/dashboard/holiday_outline.png',
        //     iconOutline: <FlightIcon />,
        // },
        {
            id: 4,
            title: 'Calendar',
            href: '/calender',
            icon: <DateRangeIcon />,
            image: s3Bucket + sideBarImages.calenderActive,
            imageOutline: s3Bucket + sideBarImages.calenderInactive,
            iconOutline: <DateRangeOutlinedIcon />,
        },
        // {
        //     id: 6,
        //     title: `Polls`,
        //     href: '/polls',
        //     icon: <SpeakerNotesIcon />,
        //     image: s3Bucket + sideBarImages.pollActive,
        //     imageOutline: s3Bucket + sideBarImages.pollInActive,
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        {
            id: 5,
            title: `Standups`,
            href: '/standups',
            icon: <SpeakerNotesIcon />,
            image: '/dashboard/standups.png',
            imageOutline: '/dashboard/standups_outlined.svg',
            iconOutline: <SpeakerNotesOutlinedIcon />,
        },
    ];

    const EmployeePages = [
        {
            id: 1,
            title: 'Dashboard',
            href: '/dashboard',
            icon: <DashboardIcon />,
            image: s3Bucket + sideBarImages.dashboardActive,
            imageOutline: s3Bucket + sideBarImages.dashboardInactive,
            iconOutline: <DashboardOutlinedIcon />,
        },
        {
            id: 2,
            title: mycontext.teamsCalledAs,
            href: '/teams',
            icon: <ApartmentIcon />,
            image: s3Bucket + sideBarImages.teamsActive,
            imageOutline: s3Bucket + sideBarImages.teamsInactive,
            iconOutline: <ApartmentIcon />,
        },
        {
            id: 3,
            title: mycontext.employeeCalledAs === 'teamMembers' ? 'Team Members' : 'Employees',
            href: '/employees',
            icon: <PeopleIcon />,
            image: s3Bucket + sideBarImages.employeesActive,
            imageOutline: s3Bucket + sideBarImages.employeesInactive,
            iconOutline: <PeopleOutlinedIcon />,
        },
        // {
        //     id: 4,
        //     title: 'Holidays',
        //     href: '/holiday',
        //     icon: <FlightIcon />,
        //     image: '/dashboard/holiday.png',
        //     imageOutline: '/dashboard/holiday_outline.png',
        //     iconOutline: <FlightIcon />,
        // },
        {
            id: 4,
            title: 'Calendar',
            href: '/calender',
            icon: <DateRangeIcon />,
            image: s3Bucket + sideBarImages.calenderActive,
            imageOutline: s3Bucket + sideBarImages.calenderInactive,
            iconOutline: <DateRangeOutlinedIcon />,
        },
        // {
        //     id: 6,
        //     title: `Polls`,
        //     href: '/polls',
        //     icon: <SpeakerNotesIcon />,
        //     image: s3Bucket + sideBarImages.pollActive,
        //     imageOutline: s3Bucket + sideBarImages.pollInActive,
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
        // {
        //     id: 5,
        //     title: `Standups`,
        //     href: '/standups',
        //     icon: <SpeakerNotesIcon />,
        //     image: '/dashboard/standups.png',
        //     imageOutline: '/dashboard/standups_outlined.svg',
        //     iconOutline: <SpeakerNotesOutlinedIcon />,
        // },
    ];

    return (
        <Hidden lgUp implementation="css">
            {/* <div className={classes.first}> */}
            <div className={open ? classes.mobileView : classes.hideView}>
                {/* <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: 10 }}> */}
                <div className={classes.topDiv}>
                    <img src={teamplus} alt="teamplus" className={classes.topImg} />
                </div>
                <Profile open={open} linkToMain={true} />
                <hr className={classes.hr} />
                <MobileMenu pgs={isAdmin ? AdminPages : isApprover ? ApproverPages : EmployeePages} isMobile={true} />
                {/* </div> */}
                {/* <div className={classes.icondiv} onClick={() => dispatch({ type: 'TOGGLE', payload: !open })}>
                        <div className={classes.innerIcon}>
                            {open ? <ArrowBackIosOutlinedIcon /> : <ArrowForwardIosOutlinedIcon />}
                        </div>
                    </div> */}
            </div>
            {/* </div> */}
        </Hidden>
    );
}
