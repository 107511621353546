import { gql } from '@apollo/client';

export function getTimeoffPolicies(organizationId: string) {
    return gql`
        subscription TimeoffPolicy {
                timeoffdb_LeaveType(where: {organizationId: {_eq: "${organizationId}"}, forYear: {_eq: "${new Date().getFullYear()}"}}, order_by: {leaveTypeName: asc}) {
                    approvalRequired
                    daysPerYear
                    isPaid
                    leaveTypeId
                    leaveTypeName
                    organizationId
                    unlimitedDays
                    active
                    defaultEnable
                    leaveTypeEmoticon
                    
                }
            }
    `;
}
