import React, { useContext } from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    // MenuItem,
    Hidden,
    useTheme,
    // Button,
    // Popover,
    // Paper,
    // useTheme,
    Tooltip,
    // Avatar,
} from '@material-ui/core';
import { useStyles } from '../pollProfileStyles';
import { withStyles, Theme } from '@material-ui/core/styles';
import upcomingTimeOff from '../../../Assets/images/upcomingTimeoff.png';
import { SearchBar } from '../../DashboardPage/DashboardPart';
import { isoToLocal } from '../../../helpers/timezone';
import { ToStringFormat } from '../../../helpers/date';
import AuthContext from 'context/AuthContext';

const StyledTableCell = withStyles(theme => ({
    root: {
        padding: '7px',
        borderBottom: 'none',
    },
    head: {
        fontSize: '14px',
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        fontWeight: 600,
    },
    body: {
        fontSize: '12px',
    },
}))(TableCell);

const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.info.dark,
    },
    tooltip: {
        backgroundColor: theme.palette.info.dark,
        // color: theme.palette.text.primary,
        color: theme.palette.info.contrastText,
        fontWeight: 500,
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

interface PollHistoryTable {
    empPolls?: any;
    setcurrentPoll?: any;
}
export default function PollHistoryTable(props: PollHistoryTable) {
    const classes = useStyles();
    const theme = useTheme();
    const [search, setSearch] = React.useState('');
    const mycontext = useContext(AuthContext);
    // const currTime = new Date().toString();
    // const a = currTime.split(' ');

    return (
        <div style={{}}>
            <Grid container>
                <Hidden only={['xs', 'sm']}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                            display: 'flex',
                            padding: '0px 10px 5px 10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid item xs={6} sm={6}>
                            <Typography className={classes.headerTitle}>Poll History</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            style={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                        >
                            <SearchBar
                                value={search}
                                setValue={setSearch}
                                icon={true}
                                style={{
                                    border: '1px solid #C4C4C4',
                                    width: '250px',
                                    height: '35px',
                                    margin: '0px',
                                    paddingLeft: '1%',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TableContainer className={classes.tableScrollbar}>
                            <Table stickyHeader aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">#</StyledTableCell>
                                        <StyledTableCell align="justify">Question</StyledTableCell>
                                        <StyledTableCell align="justify">Created On</StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                {props.empPolls?.length > 0 ? (
                                    props.empPolls?.filter((row: any) =>
                                        row?.question?.toLowerCase().includes(search?.toLowerCase()),
                                    ).length === 0 ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    style={{ textAlign: 'center', borderBottom: 'none' }}
                                                >
                                                    <img
                                                        src={upcomingTimeOff}
                                                        alt="Paper"
                                                        style={{ height: '100px', width: '100px' }}
                                                    />
                                                    <Typography
                                                        style={{
                                                            fontSize: '13px',
                                                            letterSpacing: '0.03em',
                                                            color: theme.palette.text.primary,
                                                            fontWeight: theme.typography.fontWeightBold,
                                                        }}
                                                    >
                                                        No poll(s) found.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        props.empPolls
                                            ?.filter((row: any) =>
                                                row?.question?.toLowerCase().includes(search?.toLowerCase()),
                                            )
                                            .map((row: any, i: number) => (
                                                <TableBody key={i}>
                                                    <TableRow
                                                        // key={i}
                                                        className={classes.leaveHistoryTableHover}
                                                        onClick={() => props.setcurrentPoll(i)}
                                                    >
                                                        <StyledTableCell
                                                            // className={classes.tableCellStyle}
                                                            align="center"
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {i + 1}
                                                        </StyledTableCell>

                                                        <StyledTableCell align="justify">
                                                            {row?.question?.length > 80 ? (
                                                                <LightTooltip arrow title={row.question}>
                                                                    <Typography
                                                                        className={classes.leaveTableColumnsReason}
                                                                    >
                                                                        {row.question}{' '}
                                                                    </Typography>
                                                                </LightTooltip>
                                                            ) : (
                                                                <Typography className={classes.leaveTableColumns}>
                                                                    {row.question}{' '}
                                                                </Typography>
                                                            )}
                                                        </StyledTableCell>

                                                        <StyledTableCell align="justify">
                                                            <Typography className={classes.leaveTableColumns}>
                                                                {ToStringFormat(
                                                                    isoToLocal(row?.pollEndsDateTime, mycontext.country)
                                                                        .toString()
                                                                        .substring(3, 15),
                                                                    mycontext.dateFormat,
                                                                )}
                                                            </Typography>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ))
                                    )
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                style={{ textAlign: 'center', borderBottom: 'none' }}
                                            >
                                                <img
                                                    src={upcomingTimeOff}
                                                    alt="Paper"
                                                    style={{ height: '100px', width: '100px' }}
                                                />
                                                <Typography
                                                    style={{
                                                        fontSize: '13px',
                                                        letterSpacing: '0.03em',
                                                        color: theme.palette.text.primary,
                                                        fontWeight: theme.typography.fontWeightBold,
                                                    }}
                                                >
                                                    No poll(s) found.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Grid>
                </Hidden>
            </Grid>
            <Grid container>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', padding: '5px', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Grid item xs={6}>
                            <Typography className={classes.headerTitle}>Poll History</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                        >
                            <SearchBar
                                value={search}
                                setValue={setSearch}
                                icon={true}
                                style={{
                                    border: '1px solid #C4C4C4',
                                    width: '220px',
                                    height: '35px',
                                    margin: '0px',
                                    paddingLeft: '1%',
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        {props.empPolls?.length > 0 ? (
                            props.empPolls?.filter((row: any) =>
                                row?.question?.toLowerCase().includes(search?.toLowerCase()),
                            ).length === 0 ? (
                                <Grid
                                    container
                                    // className={classes.mobileUICardTheme}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: theme.palette.background.paper,
                                    }}
                                >
                                    <img
                                        src={upcomingTimeOff}
                                        alt="Paper"
                                        style={{ height: '100px', width: '100px' }}
                                    />
                                    <Typography
                                        style={{
                                            fontSize: '13px',
                                            letterSpacing: '0.03em',
                                            color: theme.palette.text.primary,
                                            fontWeight: theme.typography.fontWeightBold,
                                        }}
                                    >
                                        No poll(s) found.
                                    </Typography>
                                </Grid>
                            ) : (
                                props.empPolls
                                    ?.filter((row: any) => row?.question?.toLowerCase().includes(search?.toLowerCase()))
                                    .map((row: any, index: number) => (
                                        <Grid
                                            container
                                            className={classes.gridCommonStyle1}
                                            style={{ padding: '5%' }}
                                            key={index}
                                            onClick={() => {
                                                props.setcurrentPoll(index);
                                            }}
                                        >
                                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                                <Typography className={classes.pollHistoryCardTitle}>
                                                    Question
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}

                                                // className={classes.commonStyle2}
                                            >
                                                {row?.question?.length > 48 ? (
                                                    <LightTooltip arrow title={row.reason}>
                                                        <Typography className={classes.pollTableColumnsReason}>
                                                            {row?.question}{' '}
                                                        </Typography>
                                                    </LightTooltip>
                                                ) : (
                                                    <Typography className={classes.pollTableColumnsReason}>
                                                        {row?.question}{' '}
                                                    </Typography>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                                <Typography className={classes.pollHistoryCardTitle}>
                                                    Created On
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                // className={classes.commonStyle1}
                                            >
                                                <Typography className={classes.pollTableCards}>
                                                    {ToStringFormat(
                                                        isoToLocal(row?.pollEndsDateTime, mycontext.country)
                                                            .toString()
                                                            .substring(3, 15),
                                                        mycontext.dateFormat,
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))
                            )
                        ) : (
                            <Grid
                                container
                                // className={classes.mobileUICardTheme}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: theme.palette.background.paper,
                                }}
                            >
                                <img src={upcomingTimeOff} alt="Paper" style={{ height: '100px', width: '100px' }} />
                                <Typography
                                    style={{
                                        fontSize: '13px',
                                        letterSpacing: '0.03em',
                                        color: theme.palette.text.primary,
                                        fontWeight: theme.typography.fontWeightBold,
                                    }}
                                >
                                    No poll(s) found.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
}
