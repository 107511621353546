import { gql } from '@apollo/client';

export const getCalendarLeaves = (organizationId: string) => {
    return gql`subscription MySubscription {
            timeoffdb_Leaves(where: {organizationId: {_eq: "${organizationId}"}}) {
                leaveId
                category
                employeeId
                leaveAppliedBy
                reason
                leaveStatus
                noOfHours
                startDate
                endDate
                startTime
                endTime
                gmt
                isoString
                denierId
                denialReason
                approverId
                Employee {
                    firstName
                    lastName
                    employeeId
                    profileImage
                }
            }
        }`;
};

export const getCalendarLeavesForApprover = (organizationId: string, employeeId: string) => {
    return gql`
        subscription MySubscription {
            timeoffdb_get_pendingdeniedapproverleaves(args: {employeeid: "${employeeId}", organizationid: "${organizationId}"}) {
                leaveId
                category
                employeeId
                leaveAppliedBy
                reason
                leaveStatus
                noOfHours
                startDate
                endDate
                startTime
                endTime
                denierId
                denialReason
                approverId
                gmt 
                isoString
                Employee {
                    employeeId
                    firstName
                    lastName
                    profileImage
                }
            }
        }
    `;
};
