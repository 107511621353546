import React from 'react';
import './loader.css';
import { SettingStyles } from '../Settings/newSettingStyle';
import { Grid, Box, Hidden } from '@material-ui/core';
import useStyles from '../Register/RegisterStyle';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';
import { useTheme } from '@material-ui/core';
import teamplusImage from './teamplusLogo.png';
import udyamoImage from './udyamoLogo.png';

interface Props {
    isUpdating?: boolean;
}

export const LoaderPage = () => {
    const classes = SettingStyles();
    const theme = useTheme();
    return (
        <div className={classes.modal}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    className="loader"
                    style={{
                        border: `6px solid ${theme.palette.background.paper}`,
                        borderTop: `6px solid ${theme.palette.primary.main}`,
                    }}
                ></div>
            </div>
        </div>
    );
};
export default function Loader(props: Props) {
    const classes = SettingStyles();
    const theme = useTheme();
    return (
        <div className={props.isUpdating ? classes.modal : classes.modalOff}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    className="loader"
                    style={{
                        border: `6px solid ${theme.palette.background.paper}`,
                        borderTop: `6px solid ${theme.palette.primary.main}`,
                    }}
                ></div>
            </div>
        </div>
    );
}
export const FetchingDetails = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Grid container>
                <Hidden lgDown>
                    <Grid item xs={5} sm={5} className={classes.registerLeft}>
                        <img
                            src={s3Bucket + registerLoginPageImages.userOrgnizationPageImage}
                            alt="login"
                            style={{ width: '70%' }}
                        />
                        <img
                            src={s3Bucket + registerLoginPageImages.udyamoBlack}
                            alt="udyamo"
                            style={{ height: 15, maxWidth: '100%' }}
                        />
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={7} className={classes.registerRight}>
                    <div
                        style={{
                            backgroundColor: '#fff',
                            opacity: 0.5,
                        }}
                    >
                        <p
                            style={{
                                margin: '10px 10px',
                                color: '#222222',
                            }}
                        >
                            Please wait while fetching your details...
                        </p>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};
export const TeamplusLoader = () => {
    const classes = SettingStyles();
    return (
        <div className={classes.modal}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <img src={teamplusImage} alt="teamplus logo" style={{ maxWidth: '100%', height: '100px' }} />
                <img
                    src={udyamoImage}
                    alt="udyamo logo"
                    style={{ maxWidth: '100%', height: '18px', marginTop: '20px' }}
                />
            </div>
        </div>
    );
};
