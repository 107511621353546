import { makeStyles } from '@material-ui/core/styles';
// import BackgroundImg from './bgImg.png';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        fontFamily: theme.typography.fontFamily,
    },
    loginLeft: {
        width: '100%',
        height: '100vh',
        // backgroundColor: theme.palette.primary.main,
        backgroundImage: `url("${s3Bucket + registerLoginPageImages.bgImage}")`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    loginRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    formContainer: {
        width: '55%',
        // height: '85%',
        borderRadius: 10,
        backgroundColor: '#fff',
        display: 'flex',
        // alignItems: 'center',
        flexDirection: 'column',
        // justifyContent: 'space-around',
        padding: '0 50px',
        '@media screen and (max-width: 1200px) and (min-width: 771px)': {
            width: '70%',
            height: '60%',
            padding: '0 50px',
            borderRadius: 10,
            transform: 'scale(1.1)',
        },
        '@media screen and (max-width: 770px) and (min-width: 561px)': {
            width: '70%',
            height: '70%',
            padding: '0 50px',
            borderRadius: 10,
            transform: 'scale(1.1)',
        },
        '@media screen and (max-width: 560px)': {
            width: '100%',
            height: '100vh',
            padding: '0 20px',
            borderRadius: 0,
        },
    },
    rootLoader: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    bgImage: {
        height: '100vh',
        width: '100%',
        backgroundAttachment: 'fixed',
    },
    welcome: {
        fontFamily: theme.typography.fontFamily,
        color: '#fff',
        fontSize: 34,
        fontWeight: theme.typography.fontWeightBold,
    },
    to: {
        fontFamily: theme.typography.fontFamily,
        color: '#fff',
        fontSize: 34,
        fontWeight: theme.typography.fontWeightRegular,
        margin: '20px 0',
    },
    loginMain: {
        width: '100%',
        height: '100vh',
        background: `url("${s3Bucket + registerLoginPageImages.bgImage}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
    },
    password: {
        color: '#444444',
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
    },
    label: {
        color: '#444444 !important',
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        // '&:focus': {
        //     color: '#444444',
        //     fontWeight: 600,
        //     fontFamily: theme.typography.fontFamily,
        // }
    },
    SignInheading: {
        textAlign: 'center',
        marginTop: 30,
        fontSize: 36,
        fontWeight: 600,
        color: '#3C48B5',
        fontFamily: theme.typography.fontFamily,
    },
    or: {
        display: 'flex',
        alignItems: 'center',
        margin: '15px 0',
        // marginTop: -30,
    },
    authBtn: {
        width: '100%',
        height: 40,
        border: '1px solid #AAAAAA',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#333333',
        '@media screen and (max-width: 768px)': {
            fontSize: 12,
        },
    },
    overlay: {
        display: 'flex',
        position: 'relative',
        bottom: '60vh',
        left: '8vw',
        alignItems: 'center',
        color: 'white',
    },
    overlay2: {
        display: 'flex',
        position: 'relative',
        bottom: '58vh',
        left: '8vw',
        fontSize: '1.2em',
        alignItems: 'center',
        color: 'white',
        fontStyle: 'italic',
    },
    heading: {
        fontFamily: theme.typography.fontFamily,
        color: '#fff',
        fontSize: 40,
        fontWeight: theme.typography.fontWeightBold,
    },
    subtitle: {
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'italic',
        fontSize: 20,
        fontWeight: theme.typography.fontWeightBold,
    },
    emailCSS: {
        marginTop: 10,
        // display: 'flex',
        // marginTop: '5vh',
        // marginLeft: '8vw',
        // marginRight: '8vw',
        // alignItems: 'center',
        // justifyContent: 'center',
        // margin: '0 auto',
        // background: 'red',
    },
    passwordCSS: {
        marginTop: 15,
        // display: 'flex',
        // marginTop: '8vh',
        // marginLeft: '8vw',
        // marginRight: '8vw',
        // alignItems: 'center',
        // justifyContent: 'center',
        // margin: '0 auto',
    },
    teamLogoSmall: {
        maxWidth: '70%',
        height: 80,
        marginBottom: 5,
    },
    teamLogoSmallMobile: {
        maxWidth: '70%',
        height: 70,
        marginBottom: 5,
    },
    createLink: {
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: 600,
        marginTop: 15,
        textDecoration: 'none',
        '&:hover, &:focus': {
            textDecoration: 'none',
        },
    },
    forgotPasswordStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 30,
        // margin: '0 auto',
        // '@media only screen and (min-width: 375px) and (max-width: 815px)': {
        //     marginLeft: '17em',
        //     marginTop: '2em',
        // },
        // '@media only screen and (min-width: 414px) and (max-width: 736px)': {
        //     marginLeft: '2em',
        // },
        // '@media only screen and (min-width: 400px) and (max-width: 825px)': {
        //     marginTop: '1em',
        //     marginLeft: '10em',
        // },
        // '@media only screen and (min-width: 320px) and (max-width: 570px)': {
        //     marginLeft: '13.5em',
        //     marginTop: '1em',
        // },
        // '@media only screen and (min-width: 360px) and (max-width: 640px)': {
        //     marginLeft: '16em',
        //     marginTop: '2em',
        // },
        // '@media only screen and (min-width: 375px) and (max-width: 670px)': {
        //     marginLeft: '17em',
        //     marginTop: '2em',
        // },
        // '@media only screen and (min-width: 410px) and (max-width: 740px)': {
        //     marginTop: '2em',
        //     marginLeft: '19.5em',
        // },
        // '@media only screen and (min-width: 760px) and (max-width: 1030px)': {
        //     marginLeft: '43em',
        //     marginTop: '1em',
        // },
        // '@media only screen and (min-width: 540px) and (max-width: 730px)': {
        //     marginLeft: '28em',
        //     marginTop: '2em',
        // },
        // '@media only screen and (min-width: 850px)': {
        //     marginLeft: '23em',
        //     marginTop: '1em',
        // },
        // '@media only screen and (min-width: 1024px) and (max-width: 1366px)': {
        //     marginLeft: '59.5em',
        //     marginTop: '2em',
        // },
        fontStyle: 'italic',
    },
    hoverStyle: {
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: '#3D49B6',
        },
    },
    signInButtonStyle: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        // justifyContent: 'center',
        // alignItems: 'center',
        // margin: '0 auto',
        // '@media only screen and (min-width: 358px) and (max-width: 650px)': {
        //     marginLeft: '2em',
        //     marginRight: '2em',
        //     marginTop: '2vh',
        // },
        // '@media only screen and (min-width: 400px) and (max-width: 735px)': {
        //     marginLeft: '2em',
        //     marginRight: '2em',
        //     marginTop: '2vh',
        // },
        // '@media only screen and (min-width: 400px) and (max-width: 825px)': {
        //     marginLeft: '4em',
        //     marginRight: '4em',
        // },
        // '@media only screen and (min-width: 300px) and (max-width: 570px)': {
        //     marginLeft: '2em',
        //     marginRight: '2em',
        //     marginTop: '3em',
        // },
        // '@media only screen and (min-width: 375px) and (max-width: 670px)': {
        //     marginLeft: '2.5em',
        //     marginRight: '2.5em',
        //     marginTop: '2em',
        // },
        // '@media only screen and (min-width: 410px) and (max-width: 740px)': {
        //     marginLeft: '4em',
        //     marginRight: '4em',
        //     marginTop: '6em',
        // },
        // '@media only screen and (min-width: 370px) and (max-width: 816px)': {
        //     marginLeft: '3em',
        //     marginRight: '3em',
        //     marginTop: '2em',
        // },
        // '@media only screen and (min-width: 760px) and (max-width: 1030px)': {
        //     marginLeft: '12em',
        //     marginRight: '12em',
        //     marginTop: '4em',
        // },
        // '@media only screen and (min-width: 540px) and (max-width: 730px)': {
        //     marginLeft: '8em',
        //     marginRight: '8em',
        //     marginTop: '3em',
        // },
        // '@media only screen and (min-width: 850px)': {
        //     marginLeft: '8em',
        //     marginRight: '8em',
        //     marginTop: '6vh',
        // },
    },
    msButtonStyle: {
        width: '100%',
        height: 40,
        border: '1px solid #AAAAAA',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#333333',
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (max-width: 768px)': {
            fontSize: 12,
        },
    },
    signInButtonGoogle: {
        justifyContent: 'center',
        alignItems: 'center',
        // '@media only screen and (min-width: 358px) and (max-width: 650px)': {
        //     marginLeft: '2em',
        //     marginRight: '2em',
        //     marginTop: '2vh',
        // },
        // '@media only screen and (min-width: 400px) and (max-width: 735px)': {
        //     marginLeft: '3em',
        //     marginRight: '3em',
        // },
        // '@media only screen and (min-width: 400px) and (max-width: 825px)': {
        //     marginLeft: '4em',
        //     marginRight: '4em',
        // },
        // '@media only screen and (min-width: 300px) and (max-width: 570px)': {
        //     marginLeft: '2em',
        //     marginRight: '2em',
        //     marginTop: '2em',
        // },
        // '@media only screen and (min-width: 375px) and (max-width: 670px)': {
        //     marginLeft: '2.5em',
        //     marginRight: '2.5em',
        //     marginTop: '5em',
        // },
        // '@media only screen and (min-width: 410px) and (max-width: 740px)': {
        //     marginLeft: '4em',
        //     marginRight: '4em',
        //     marginTop: '6em',
        // },
        // '@media only screen and (min-width: 370px) and (max-width: 816px)': {
        //     marginLeft: '3em',
        //     marginRight: '3em',
        //     marginTop: '2em',
        // },
        // '@media only screen and (min-width: 760px) and (max-width: 1030px)': {
        //     marginLeft: '12em',
        //     marginRight: '12em',
        //     marginTop: '3em',
        // },
        // '@media only screen and (min-width: 540px) and (max-width: 730px)': {
        //     marginLeft: '8em',
        //     marginRight: '8em',
        //     marginTop: '2em',
        // },
        // '@media only screen and (min-width: 850px)': {
        //     marginLeft: '8em',
        //     marginRight: '8em',
        //     marginTop: '2em',
        // },
    },
    googleImg: {
        height: '100%',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: '10vh',
        marginRight: '20vh',
    },
    googleSignIn: {
        '& .MuiButton-sizeSmall': {
            paddingLeft: 48,
            paddingRight: 32,
            position: 'absolute',
            left: 16,
        },
        '& .MuiButton-startIcon': {
            position: 'absolute',
            left: 16,
        },
    },
}));

export default useStyles;
