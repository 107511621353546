import React, { useContext } from 'react';
import useStyles from './styles';
import { Button, Grid, Hidden } from '@material-ui/core';
import AuthContext from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import error from './ErrorImage.png';
import { Auth } from 'aws-amplify';
interface Props {
    title?: string;
    img?: string;
    msg?: string;
}
export default function ErrorPage(props: Props): JSX.Element {
    const classes = useStyles();
    const history = useHistory();
    const mycontext = useContext(AuthContext);
    const handleLogout = () => {
        Auth.signOut();
        mycontext.logout();
        setTimeout(() => {
            history.push('/login');
        }, 2000);
    };
    return (
        <>
            <Hidden mdUp>
                <div className={classes.errorDivMobile}>
                    <h2 className={classes.headingGoogle}>{props.title}</h2>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.btn}
                        onClick={handleLogout}
                    >
                        Go Back
                    </Button>
                </div>
            </Hidden>
            <Hidden mdDown>
                <Grid container className={classes.mainDiv}>
                    <Grid item xs style={{ padding: 30 }}>
                        {props.title && <h2 className={classes.headingGoogle}>{props.title}</h2>}
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.btn}
                            onClick={handleLogout}
                        >
                            Go Back
                        </Button>
                    </Grid>
                    <Grid item xs style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-start' }}>
                        <img src={error} alt="Error" className={classes.img} />
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
}
