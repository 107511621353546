import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            width: '100%',
            height: 'auto',
        },
        headerTitle: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '25px',
            letterSpacing: '0.05em',
        },
        paper1style: {
            height: 45,
            marginBottom: 10,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: theme.palette.background.paper,
        },
        title: {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            fontStyle: 'normal',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            marginLeft: 20,
        },
        tabs: {
            minHeight: '30%',
            lineheight: '25px',
            borderradius: '5px',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            '& div': {
                opacity: '4',
            },
        },
        tab: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            textTransform: 'initial',
            minHeight: '100%',
            fontSize: '15px',
            alignItems: 'flex-start',
            '&.Mui-selected': {
                outline: 'none',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '&.MuiTab-root': {
                padding: '6px 20px',
                minWidth: 'auto',
                '@media screen and (max-width:760px)': {
                    padding: '6px 10px',
                    minWidth: 'auto',
                },
            },
            '& span': {
                display: 'inline-flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'center',
                '@media screen and (max-width:760px)': {
                    width: '100%',
                    display: 'inline-flex',
                    alignItems: 'flex-start',
                    marginLeft: 0,
                },
            },
        },
        outerdiv: {
            backgroundColor: theme.palette.background.paper,
            height: 'auto',
            width: '100%',
            padding: '10px 20px',
            minHeight: 'calc(100vh - 165px)',
            '@media screen and (max-width: 760px)': {
                padding: '0px 10px 35px 10px',
                minHeight: 'calc(100vh - 110px)',
            },
        },
        formlabelstyle: {
            color: theme.palette.primary.contrastText,
            fontWeight: 600,
            fontSize: '14px',
            '@media screen and (max-width: 760px)': {
                lineHeight: '22px',
            },
        },
        formlabelstyle1: {
            color: theme.palette.primary.contrastText,
            fontWeight: 600,
            fontSize: '14px',
            paddingTop: 20,
            '@media screen and (max-width: 760px)': {
                lineHeight: '22px',
                // paddinTop: 20,
            },
        },
        // emailformlabel: {
        //     color: theme.palette.primary.contrastText,
        //     fontWeight: 600,
        //     fontSize: '16px',
        // },
        inputselectstyle: {
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            width: '70%',
            color: '#AAAAAA',
            '& .MuiSelect-icon': {
                color: theme.palette.primary.contrastText,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media screen and (max-width: 760px)': {
                width: '100%',
            },
        },
        selectstyle: {
            width: '30%',
            '@media screen and (max-width: 760px)': {
                width: '60%',
            },
        },
        resetButtonGrid: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
            '@media screen and (max-width: 760px)': {
                justifyContent: 'center',
                width: '100%',
            },
        },
        buttonStyle: {
            fontWeight: 'bold',
            fontStyle: 'normal',
            textTransform: 'capitalize',
            letterSpacing: '0.05em',
            color: theme.palette.text.secondary,
            marginRight: 10,
            width: '120px',
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width:760px)': {
                width: '90%',
            },
        },
        resetButton: {
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (min-width: 1024px)': {
                width: '140px',
            },
            '@media screen and (max-width: 760px)': {
                width: '100%',
                marginRight: '12px',
            },
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                width: '150px',
                height: '35px',
            },
            '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
                width: '80%',
                height: '38px',
            },
        },
        updateButtonStyle: {
            backgroundColor: theme.palette.primary.main,
            border: 'none',
            fontWeight: 'bold',
            fontStyle: 'normal',
            textTransform: 'capitalize',
            letterSpacing: '0.05em',
            color: theme.palette.common.white,
            width: '120px',
            '@media screen and (max-width:760px)': {
                width: '100%',
            },
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                border: 'none',
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
    }),
);

export default useStyles;
