import React from 'react';
import useStyles from './styles';
import { Button, Grid, TextField } from '@material-ui/core';
import Validate from '../../Validator/validateRegister';
import FormError from '../Error/RegisterFormError';
import { Auth } from 'aws-amplify';
import { getNameFromEmail } from '../../helpers/getInitials';
import Spinner from '../../Spinner/Spinner';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

interface SignInProps {
    nextStep?: any;
    values?: any;
}

export default function SignInUser(props: SignInProps) {
    const classes = useStyles();
    const [error, setError] = React.useState<any>();
    // const [user, setUser] = React.useState<any>(null);
    const [username, setUsername] = React.useState<any>();
    const [email, setEmail] = React.useState<any>();
    const [loading, setLoading] = React.useState<boolean>(true);

    const handleSubmit = async () => {
        const data = {
            fname: username,
            email: email,
        };
        const err = await Validate(data);
        if (err === null) {
            await setError(null);
            props.nextStep();
        } else {
            setError(err);
        }
    };

    const getUser = async () => {
        const signInUser = await Auth.currentAuthenticatedUser();
        setEmail(signInUser.attributes.email);
        props.values.setOemail(signInUser.attributes.email);
        props.values.setEmail(signInUser.attributes.email);
        setUsername(getNameFromEmail(signInUser.attributes.email));
        setLoading(false);
    };

    React.useEffect(() => {
        const fetchUser = async () => {
            await getUser();
        };
        fetchUser();
    });

    // if (user === null) {
    //     return (
    //         <div className={classes.mainPaper}>
    //             <Button onClick={() => getUser()}>Get User Details</Button>
    //         </div>
    //     );
    // }
    if (loading) {
        return (
            <div className={classes.mainPaper}>
                <Spinner />
            </div>
        );
    }
    return (
        <Grid item xs={12} lg={12} xl={5} className={classes.googleSignInDiv}>
            <Grid item xs={12} className={classes.verifyMessage}>
                <CheckCircleOutlineIcon fontSize="small" className={classes.verifyIcon} />{' '}
                <span>User has been verified!</span>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FormError formerrors={{ error }} />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.textfield}>
                <TextField
                    type="text"
                    label="Username"
                    margin="dense"
                    className={classes.formControlLeft}
                    id="outlined-size-small"
                    placeholder="First Name"
                    value={username}
                    size="small"
                    disabled={true}
                />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.textfield}>
                <TextField
                    type="text"
                    label="Email"
                    margin="dense"
                    className={classes.formControlLeft}
                    id="outlined-size-small"
                    placeholder="Email"
                    value={email}
                    size="small"
                    disabled={true}
                />
            </Grid>
            <Button variant="contained" color="primary" onClick={() => handleSubmit()} className={classes.btn}>
                Submit & Next
            </Button>
        </Grid>
    );
}
