// import { makeStyles } from '@material-ui/core/styles';
// const useStyles = makeStyles((theme: any) => ({
//     modal: {
//         width: '100%',
//         height: '100vh',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         '@media screen and (max-width: 900px)': {
//             padding: '25px 0px 10px 0px',
//         },
//     },
//     modal1: {
//         width: '100%',
//         // height: '100vh',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         paddingTop: 100,
//         '@media screen and (max-width: 800px)': {
//             padding: '60px 10px',
//         },
//     },
//     paper: {
//         width: '60%',
//         backgroundColor: theme.palette.background.paper,
//         padding: '50px',
//         left: 100,
//         top: 25,
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'space-between',
//         position: 'relative',
//         '@media screen and (max-width: 899.99px)': {
//             position: 'realtive',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             left: 0,
//             padding: 30,
//             // top: 7,
//         },
//         '@media screen and (min-width: 1300px)': {
//             height: '60%',
//         },
//     },
//     paper1: {
//         width: '60%',
//         backgroundColor: theme.palette.background.paper,
//         padding: '20px',
//         // left: 100,
//         // top: 25,
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'space-between',
//         position: 'relative',
//         '@media screen and (max-width: 800px)': {
//             width: '100%',
//             padding: 0,
//         },
//         '@media screen and (min-width: 1100px)': {
//             width: '45%',
//         },
//     },
//     text_item: {
//         color: theme.palette.primary.contrastText,
//         fontWeight: 600,
//         fontSize: '14px',
//         marginTop: '8%',
//         top: 16,
//     },
//     item1: {
//         // paddingTop: 3,
//         paddingTop: 5,
//         fontSize: 16,
//         fontFamily: 'Nunito Sans',
//         fontWeight: 600,
//         wordWrap: 'break-word',
//         // color: '#222222',
//         width: 700,
//     },
//     item2: {
//         paddingTop: 5,
//         fontSize: 16,
//         fontFamily: 'Nunito Sans',
//         fontWeight: 600,
//         // color: '#444444',
//         width: 180,
//     },
//     btnDiv: {
//         display: 'flex',
//         justifyContent: 'flex-end',
//         position: 'fixed',
//         '@media screen and (max-width: 899.9px)': {
//             bottom: 10,
//             left: 60,
//         },
//     },
//     btn1: {
//         height: 40,
//         textTransform: 'capitalize',
//         padding: '0 45px',
//         right: 20,
//         fontFamily: theme.typography.fontFamily,
//         fontWeight: theme.typography.fontWeightBold,
//         color: theme.palette.text.secondary,
//         backgroundColor: theme.palette.background.paper,
//         border: `1px solid ${theme.palette.text.secondary}`,
//         '&:active,&:hover': {
//             color: theme.palette.text.secondary,
//             backgroundColor: theme.palette.background.paper,
//             border: `1px solid ${theme.palette.text.secondary}`,
//             outline: 'none',
//         },
//     },
//     btn2: {
//         height: 40,
//         textTransform: 'capitalize',
//         padding: '0 45px',
//         fontSize: 16,
//         right: 6,
//         fontFamily: theme.typography.fontFamily,
//         fontWeight: theme.typography.fontWeightBold,
//         color: theme.palette.common.white,
//         backgroundColor: theme.palette.primary.main,
//         '&:hover': {
//             color: theme.palette.common.white,
//             backgroundColor: theme.palette.primary.main,
//         },
//         '&:focus': {
//             textDecoration: 'none',
//             border: 'none',
//             outline: 'none',
//         },
//         '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
//             marginLeft: 0,
//         },
//     },
//     choices: {
//         color: theme.palette.primary.contrastText,
//         fontWeight: 600,
//         fontSize: '14px',
//         marginTop: '8%',
//     },
//     label: {
//         // marginTop: 1000,
//         // display: 'flex',
//         // display: 'grid',
//         // gridTemplateColumns: '1fr 1fr 1fr 1fr',
//         fontSize: 16,
//         fontFamily: 'Nunito Sans',
//         fontWeight: 600,
//         // width: 170,
//         // paddingLeft: 50,
//         // paddingRight: 50,
//     },
//     scroll: {
//         position: 'relative',
//         top: 15,
//         height: '93.5%',
//         overflowY: 'scroll',
//         overflowX: 'hidden',
//         width: '100%',
//         // paddingBottom: -10,
//         '&::-webkit-scrollbar': {
//             width: 0,
//         },
//     },
//     inputStyle: {
//         color: theme.palette.primary.contrastText,
//         '& div': {
//             '& input': {
//                 backgroundColor: theme.palette.background.paper,
//                 color: theme.palette.text.primary,
//                 border: `1px solid ${theme.palette.primary.contrastText}`,
//                 borderRadius: '4px',
//             },
//         },
//         '& fieldset': {
//             border: `1px solid ${theme.palette.primary.contrastText}`,
//         },
//     },
//     label1: {
//         color: theme.palette.text.primary,
//         fontWeight: theme.typography.fontWeightBold,
//         fontSize: '16px',
//         fontFamily: theme.typography.fontFamily,
//     },
//     btnOutlined: {
//         height: 35,
//         textTransform: 'capitalize',
//         padding: '0 50px',
//         fontFamily: theme.typography.fontFamily,
//         fontWeight: theme.typography.fontWeightBold,
//         color: theme.palette.text.secondary,
//         backgroundColor: theme.palette.background.paper,
//         border: `1px solid ${theme.palette.text.secondary}`,
//         '&:active,&:hover': {
//             color: theme.palette.text.secondary,
//             backgroundColor: theme.palette.background.paper,
//             border: `1px solid ${theme.palette.text.secondary}`,
//             outline: 'none',
//         },
//     },
//     btn: {
//         height: 35,
//         textTransform: 'capitalize',
//         padding: '0 50px',
//         fontSize: 16,
//         marginLeft: '10px',
//         fontFamily: theme.typography.fontFamily,
//         fontWeight: theme.typography.fontWeightBold,
//         color: theme.palette.common.white,
//         backgroundColor: theme.palette.primary.main,
//         '&:hover': {
//             color: theme.palette.common.white,
//             backgroundColor: theme.palette.primary.main,
//         },
//         '&:focus': {
//             textDecoration: 'none',
//             border: 'none',
//             outline: 'none',
//         },
//     },
//     deleteModalContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         textAlign: 'center',
//     },
//     deltitle: {
//         textAlign: 'center',
//         fontSize: 20,
//         fontStyle: 'normal',
//         color: theme.palette.info.light,
//         fontFamily: theme.typography.fontFamily,
//         fontWeight: theme.typography.fontWeightBold,
//     },
//     delbod: {
//         fontSize: 18,
//         textAlign: 'center',
//         fontWeight: theme.typography.fontWeightMedium,
//         color: theme.palette.text.primary,
//         fontStyle: 'normal',
//         fontFamily: theme.typography.fontFamily,
//     },
// }));
// export default useStyles;

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: any) => ({
    scroll: {
        position: 'relative',
        top: 15,
        height: '93.5%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        width: '100%',
        // paddingBottom: -10,
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    label1: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '16px',
        fontFamily: theme.typography.fontFamily,
    },
    inputStyle: {
        color: theme.palette.primary.contrastText,
        '& div': {
            '& input': {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                border: `1px solid ${theme.palette.primary.contrastText}`,
                borderRadius: '4px',
            },
        },
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
    },

    modal1: {
        width: '100%',
        // height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 100,
        '@media screen and (max-width: 800px)': {
            padding: '60px 10px',
        },
    },
    paper1: {
        width: '60%',
        backgroundColor: theme.palette.background.paper,
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        '@media screen and (max-width: 800px)': {
            width: '100%',
            padding: 0,
        },
        '@media screen and (min-width: 1100px)': {
            width: '45%',
        },
    },
    deleteModalContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    deltitle: {
        textAlign: 'center',
        fontSize: 20,
        fontStyle: 'normal',
        color: theme.palette.info.light,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    delbod: {
        fontSize: 18,
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        fontStyle: 'normal',
        fontFamily: theme.typography.fontFamily,
    },
    btnOutlined: {
        height: 35,
        textTransform: 'capitalize',
        padding: '0 50px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
        '&:active,&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
        },
    },
    btn: {
        height: 35,
        textTransform: 'capitalize',
        padding: '0 50px',
        fontSize: 16,
        marginLeft: '10px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    modal: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // '@media screen and (max-width: 900px)': {
        //     padding: '25px 0px 10px 0px',
        // },
    },
    paper: {
        position: 'relative',
        flexDirection: 'column',
        display: 'flex',
        // height: '80%',
        // width: '40%',
        top: 25,
        backgroundColor: theme.palette.background.paper,
        '@media screen and (min-width: 300px) and (max-width: 550px)': {
            height: '60%',
            width: '80%',
            padding: '30px 20px 20px 20px',
        },
        '@media screen and (min-width: 550px) and (max-width: 899.99px)': {
            height: '70%',
            width: '60%',
            padding: '30px 20px 40px 20px',
        },
        '@media screen and (min-width: 900px) and (max-width: 1299.99px)': {
            height: '80%',
            width: '40%',
            padding: '30px',
        },
        '@media screen and (min-width: 1300px)': {
            height: '60%',
            width: '30%',
            padding: '30px',
        },
    },
    btnDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        '@media screen and (min-width: 300px) and (max-width: 899.99px)': {
            marginLeft: '20px',
            // marginTop: ''
        },
    },
    btn1: {
        height: 40,
        textTransform: 'capitalize',
        padding: '0 45px',
        right: 20,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
        '&:active,&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
        },
    },
    btn2: {
        height: 40,
        textTransform: 'capitalize',
        padding: '0 45px',
        fontSize: 16,
        // right: 6,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
        '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
            marginLeft: 0,
        },
    },
    text_item: {
        // fontWeight: 500,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        // color: theme.palette.primary.black,
    },
    item2: {
        fontSize: 16,
        fontWeight: 450,
        color: theme.palette.primary.black,
    },

    item3: {
        fontSize: 16,
        fontFamily: 'Nunito Sans',
        fontWeight: 600,
        marginTop: '2%',
        width: '100%',
    },

    label: {
        width: '100%',
        marginTop: '8px',
        fontSize: 16,
        fontFamily: 'Nunito Sans',
        fontWeight: 450,
        color: theme.palette.primary.black,
    },

    scrollDiv: {
        height: '82%',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 4,
            marginTop: 40,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },
    mobileDiv: {
        height: '100%',
        '@media screen and (min-width: 900px) and (max-width: 1299.99px)': {
            marginTop: '15px',
        },
        '@media screen and (min-width: 1300px)': {
            marginTop: '15px',
        },
    },
    closeIcon: {
        position: 'absolute',
        '@media screen and (min-width: 300px) and (max-width: 899.99px)': {
            top: 5,
            right: 5,
        },
        '@media screen and (min-width: 900px) and (max-width: 1299.99px)': {
            top: 15,
            right: 15,
        },
        '@media screen and (min-width: 1300px)': {
            top: 15,
            right: 15,
        },
    },
}));
export default useStyles;
