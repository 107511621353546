import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100vh',
            // display: 'flex',
            // flexDirection: 'row',
            // alignItems: 'center',
            // justifyContent: 'space-around',
            // backgroundColor: '#D7DBDD',
            // backgroundImage: 'url("loginBackground.png")',
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            '@media (max-width: 650px)': {
                flexDirection: 'column',
                width: '100%',
                height: 'auto',
                minHeight: '100vh',
            },
        },
        imageDiv: {
            width: '60%',
            height: '100vh',
            display: 'flex',
            '@media (max-width: 650px)': {
                height: '300px',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        image: {
            maxWidth: '100%',
            height: '100%',
            '@media (max-width: 650px)': {
                height: '200px',
                width: '60%',
            },
        },
        main: {
            width: '40%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (max-width: 650px)': {
                width: '100%',
                justifyContent: 'center',
            },
        },
    }),
);
export default useStyles;
