import React, { useState, useContext } from 'react';
import { Avatar, Typography, Badge, IconButton, Popover, List, ListItem, ListItemText } from '@material-ui/core';
import { useStyles, profileStyle } from './employeeProfileStyles';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import EditIcon from '@material-ui/icons/Edit';
import { EmployeeProfileModal, EditProfile } from './employeeProfileModal';
import { useSelector } from 'react-redux';
import AuthContext from '../../context/AuthContext';
import getFullName from '../../helpers/getInitials';
import Loader from '../Loader/loader';
import DisplayEmoji from '../TeamMembers/DisplayEmoji';
import { ToStringFormat, ToStringFormatWithoutYear } from '../../helpers/date';

interface Weeks {
    label: string;
    value: string;
}
const weeks: Weeks[] = [
    {
        label: 'Sun',
        value: 'sunday',
    },
    {
        label: 'Mon',
        value: 'monday',
    },
    {
        label: 'Tue',
        value: 'tuesday',
    },
    {
        label: 'Wed',
        value: 'wednesday',
    },
    {
        label: 'Thu',
        value: 'thursday',
    },
    {
        label: 'Fri',
        value: 'friday',
    },
    {
        label: 'Sat',
        value: 'saturday',
    },
];

interface ProfileData {
    firstName?: any;
    lastName?: any;
    employeeId?: any;
    role?: any;
    approverTeams?: any;
    employeeTeams?: any;
    image?: any;
    totalDaysPerYear?: any;
    demoData?: any;
    orgWorkingHours?: any;
    dateOfBirth?: any;
    workAnniversary?: any;
    isYear?: boolean;
    isDOB?: boolean;
}

export default function EmployeeProfile(props: ProfileData) {
    const classes = useStyles();

    const mycontext = useContext(AuthContext);
    const classes2 = profileStyle();
    const [open, setOpen] = useState<boolean>(false);
    const [openname, setOpenname] = React.useState<boolean>(false);
    const [listAnchor, setListAnchor] = useState<any>(null);
    const [currentList, setCurrentList] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<any>(false);
    const settings = useSelector((state: any) => state.settings);
    const [workAnniversary, setworkAnniversary] = React.useState<any>(props.workAnniversary);
    const [dateOfBirth, setdateOfBirth] = React.useState<any>(props.dateOfBirth);
    const profilecheck = localStorage.getItem('employeeid') === mycontext.userProfile ? 'SELF' : 'OTHERS';
    // const [isYear,setisYear] = useState<any>(props.isYear);

    // const handleListOpen = (event: any, arrData: any[]) => {
    //     setListAnchor(event.currentTarget);
    //     setCurrentList(arrData);
    // };

    const handleListClose = () => {
        setListAnchor(null);
        setCurrentList([]);
    };

    let render: any;

    if (props.isDOB === false && props.isYear === false) {
        render = <></>;
    } else if (props.isDOB === true && props.isYear === false) {
        render = (
            <div style={{ display: 'flex', marginTop: '12px' }}>
                <Typography className={classes2.rolesStyle}>Date of birth :&nbsp;</Typography>
                <div style={{ flex: 1, paddingLeft: 2, display: 'flex', flexWrap: 'wrap' }}>
                    {dateOfBirth === null ? (
                        <Typography className={classes2.rolesValuesStyle}>--</Typography>
                    ) : (
                        <Typography className={classes2.rolesValuesStyle}>
                            {ToStringFormat(dateOfBirth, mycontext.dateFormat)}
                        </Typography>
                    )}
                </div>
            </div>
        );
    } else if (props.isDOB === false && props.isYear === true) {
        render = <></>;
    } else {
        render = (
            <div style={{ display: 'flex', marginTop: '12px' }}>
                <Typography className={classes2.rolesStyle}>Date of birth :&nbsp;</Typography>
                <div style={{ flex: 1, paddingLeft: 2, display: 'flex', flexWrap: 'wrap' }}>
                    {dateOfBirth === null ? (
                        <Typography className={classes2.rolesValuesStyle}>--</Typography>
                    ) : (
                        <Typography className={classes2.rolesValuesStyle}>
                            {ToStringFormatWithoutYear(dateOfBirth, mycontext.dateFormat)}
                        </Typography>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={classes.empmain}>
            <Popover
                id="teamsList2"
                open={Boolean(listAnchor)}
                anchorEl={listAnchor}
                onClose={handleListClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List
                    component="nav"
                    aria-labelledby="team-list-subheader"
                    style={{ minWidth: 100, width: '100%', padding: 0 }}
                >
                    <ListItem key="listTitle" className={classes.teamMenuTitle}>
                        <ListItemText primary="Team List" />
                    </ListItem>
                    <div className={classes.teamList}>
                        {currentList.map((l: any, index: number) => (
                            <ListItem
                                key={index}
                                style={{ padding: '0px 10px', textTransform: 'capitalize', fontSize: 12 }}
                            >
                                <ListItemText primary={l.teamName} />
                            </ListItem>
                        ))}
                    </div>
                </List>
            </Popover>
            <>
                <EmployeeProfileModal open={open} setOpen={setOpen} setIsLoading={setIsLoading} />
                <Loader isUpdating={isLoading} />
                <EditProfile
                    openname={openname}
                    setOpenname={setOpenname}
                    setIsLoading={setIsLoading}
                    workAnniversary={workAnniversary}
                    setworkAnniversary={setworkAnniversary}
                    setdateOfBirth={setdateOfBirth}
                    dateOfBirth={dateOfBirth}
                    isYear={props.isYear}
                    isDOB={props.isDOB}
                />
            </>

            <div className={`container ${classes.profilecontainer}`}>
                {localStorage.getItem('employeeid') === mycontext.userProfile && (
                    <EditIcon
                        id="edit-icon"
                        fontSize="medium"
                        className={classes.editicon}
                        onClick={() => setOpenname(true)}
                    />
                )}

                <div className={`col-xs-12 col-md-3 pull-left ${classes.profileimage}`}>
                    {localStorage.getItem('employeeid') === mycontext.userProfile ? (
                        <div
                            onClick={() => setOpen(true)}
                            style={{
                                outline: 'none',
                                display: 'flex',
                                alignContent: 'center',
                                justifyContent: 'center',
                                maxHeight: '130px',
                            }}
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={
                                    <IconButton
                                        disableRipple
                                        aria-label="edit"
                                        style={{ left: '-6px', top: '-6px', padding: '14px' }}
                                        className={classes.editPen}
                                    >
                                        <CameraAltIcon fontSize="medium" />
                                    </IconButton>
                                }
                            >
                                {props.image ? (
                                    <Avatar src={props.image} alt="User Profile" className={classes.userImg} />
                                ) : (
                                    <Avatar alt="User Profile" className={classes.userImg}>
                                        {getFullName(props.firstName + ' ' + props.lastName)}
                                    </Avatar>
                                )}
                            </Badge>
                        </div>
                    ) : props.image ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Avatar src={props.image} alt="User Profile" className={classes.userImg} />
                        </div>
                    ) : (
                        <div style={{}}>
                            <Avatar alt="User Profile" className={classes.userImg}>
                                {getFullName(props.firstName + ' ' + props.lastName)}{' '}
                            </Avatar>
                        </div>
                    )}
                </div>
                <div className={`container col-xs-12 col-md-9 pull-right ${classes.cont}`}>
                    <div className={`container col-xs-12 col-md-12 pull-left2 ${classes.info}`}>
                        {/* <div style={{ border: '1px solid red' }}> */}
                        <div className={classes.block}>
                            <div>
                                <Typography className={classes2.nameStyle}>
                                    {props.firstName + ' ' + props.lastName}{' '}
                                    <DisplayEmoji employeeId={props.employeeId} />
                                </Typography>
                                {/* <div style={{ display: 'flex' }}> */}
                            </div>
                            {/* </div> */}
                            <div>
                                <Typography className={classes2.designationTwoStyle}>{props.role}</Typography>
                            </div>
                        </div>
                        {/* </div> */}
                        <div>
                            <div style={{ display: 'flex', marginTop: '12px' }}>
                                <Typography className={classes2.rolesStyle}>Joining Date :&nbsp;</Typography>
                                <div style={{ flex: 1, paddingLeft: 2, display: 'flex', flexWrap: 'wrap' }}>
                                    {workAnniversary === null ? (
                                        <Typography className={classes2.rolesValuesStyle}>--</Typography>
                                    ) : (
                                        <Typography className={classes2.rolesValuesStyle}>
                                            {ToStringFormat(workAnniversary, mycontext.dateFormat)}
                                        </Typography>
                                    )}
                                </div>
                            </div>

                            {profilecheck === 'OTHERS' ? (
                                render
                            ) : (
                                <div style={{ display: 'flex', marginTop: '12px' }}>
                                    <Typography className={classes2.rolesStyle}>Date of birth :&nbsp;</Typography>
                                    <div style={{ flex: 1, paddingLeft: 2, display: 'flex', flexWrap: 'wrap' }}>
                                        {dateOfBirth === null ? (
                                            <Typography className={classes2.rolesValuesStyle}>--</Typography>
                                        ) : (
                                            <Typography className={classes2.rolesValuesStyle}>
                                                {ToStringFormat(dateOfBirth, mycontext.dateFormat)}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`container col-xs-12 col-md-12 ${classes.week}`}>
                        <div className={classes.workweekfont}>
                            <Typography className={classes.weekstyles}>Workweek :&nbsp;&nbsp;</Typography>
                        </div>
                        <div className={classes.workweeks}>
                            {settings.status === 'loading'
                                ? null
                                : weeks.map((item: Weeks, i: number) => (
                                      <div
                                          key={item.label}
                                          className={
                                              settings.settings?.workWeek.includes(item.value)
                                                  ? i === 0
                                                      ? classes.activeDayFirst
                                                      : i === 6
                                                      ? classes.activeDayLast
                                                      : classes.activeDay
                                                  : i === 0
                                                  ? classes.dayFirst
                                                  : i === 6
                                                  ? classes.dayLast
                                                  : classes.day
                                          }
                                      >
                                          {item.label}
                                      </div>
                                  ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
