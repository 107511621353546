import { createSlice } from '@reduxjs/toolkit';
// import { tableNotes } from 'helpers/hasuraapi3';

// export const getStandups: any = createAsyncThunk('standups/getStandups', async (user: any) => {
//     const data: any = await tableNotes(user.standupTempId, user.token);
//     const jsonData: any = await data.json();
//     return jsonData.data.standupdb_StandupNotes;
// });

export const standupSlice = createSlice({
    name: 'standups',
    initialState: { standups: [], status: 'loading' },
    reducers: {
        addStandups: (state: any, action: any) => {
            state.standups.unshift(action.payload);
        },
        editStandups: (state: any, action: any) => {
            state.standups.map((u: any) => {
                if (u.standupNoteId === action.payload.standupNoteId) {
                    u.title = action.payload.title;
                    return u;
                }
                return u;
            });
        },
        removeStandups: (state: any, action: any) => {
            state.standups = state.standups.filter((h: any) => h.standupNoteId !== action.payload.standupNoteId);
        },

        fetchAllStandups: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                // console.log(action.payload);
                state.standups = action.payload?.data?.standupdb_StandupNotes;
            }
        },
        // fetchStandupQuestions: (state: any, action: any) => {
        //     state.questions = action.payload?.questions;
        //     state.tags = action.payload?.tags;
        // },

        clearReducers: (state: any) => {
            state.status = 'loading';
            state.standups = [];
            state.questions = [];
            state.tags = [];
        },
    },
});

export const { addStandups, editStandups, removeStandups, fetchAllStandups, clearReducers } = standupSlice.actions;
export default standupSlice.reducer;
