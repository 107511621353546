/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Hidden } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import useStyles from '../Login/LoginStyle';
import { toAWSDateFormat } from '../../helpers/date';
import {
    createOrganizationRole,
    insertTeamEmployee,
    isEmployeeInDatabase,
    registerAdmin,
} from '../../helpers/hasuraapi';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { NameLoader, ThreeDotsLoader } from '../Spinner/Spinner';
import TeamPlusLogoMobile from '../../Assets/images/udyamo-splash-screen.png';
import { getNameFromEmail } from 'helpers/getInitials';
import ErrorGoogle from 'components/Error/ErrorGoogle';

export const TeamplusLoader = () => {
    const classes = useStyles();
    return (
        <>
            <Hidden smDown>
                <div className={classes.rootLoader}>
                    <NameLoader />
                    <ThreeDotsLoader />
                </div>
            </Hidden>
            <Hidden smUp>
                <div className={classes.loginMain}>
                    <h3 className={classes.welcome}>Welcome</h3>
                    <h3 className={classes.to}>To</h3>
                    <img src={TeamPlusLogoMobile} alt="teamplus" className={classes.teamLogoSmallMobile} />
                </div>
            </Hidden>
        </>
    );
};
export default function GoogleSignupEmployee() {
    const history = useHistory();
    const [error, setError] = useState('');

    const handleSubmit = async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const token: any = currentUser.signInUserSession.idToken.jwtToken;
            const search: any = new URL(localStorage.getItem('inviteUrl') || ' ');
            const orgId: any = search.searchParams.get('orgId');
            const email: any = search.searchParams.get('email');
            const teams: any = search.searchParams.get('teams');
            const myteams: any = teams.split(',');
            if (token) {
                const decoded: any = jwt_decode(token);
                if (email === decoded.email) {
                    const isUser: any = await isEmployeeInDatabase(decoded.email);
                    if (isUser !== 0) {
                        const empRole = {
                            organizationId: orgId,
                            role: 'employee',
                            token: token,
                        };
                        await createOrganizationRole(empRole);
                        let l = myteams.length;
                        while (l !== 0) {
                            const teamUser = {
                                employeeId: decoded.sub,
                                isApprover: false,
                                teamId: myteams[l - 1],
                                token: token,
                            };
                            await insertTeamEmployee(teamUser);
                            l--;
                        }
                        localStorage.removeItem('inviteUrl');
                    } else {
                        const employee: any = {
                            email: decoded.email,
                            firstName: getNameFromEmail(decoded.email),
                            lastName: '',
                            joining: toAWSDateFormat(new Date()),
                            token: token,
                        };
                        await registerAdmin(employee);
                        const empRole = {
                            organizationId: orgId,
                            role: 'employee',
                            token: token,
                        };
                        await createOrganizationRole(empRole);
                        let l = myteams.length;
                        while (l !== 0) {
                            const teamUser = {
                                employeeId: decoded.sub,
                                isApprover: false,
                                teamId: myteams[l - 1],
                                token: token,
                            };
                            await insertTeamEmployee(teamUser);
                            l--;
                        }
                        localStorage.removeItem('inviteUrl');
                    }
                    history.push({
                        pathname: '/myorganizations',
                        state: {
                            id: decoded.sub,
                            token: token,
                            isSignup: true,
                        },
                    });
                } else {
                    // const isUser: any = await isEmployeeInDatabase(decoded.email);
                    // if (isUser !== 0) {
                    //     history.push({
                    //         pathname: '/myorganizations',
                    //         state: {
                    //             id: decoded.sub,
                    //             token: token,
                    //         },
                    //     });
                    // } else {
                    //     setError(true);
                    // }
                    setError('different email');
                }
            }
        } catch (err) {
            setError('other');
        }
    };

    useEffect(() => {
        handleSubmit();
    }, []);

    if (error === 'different email') {
        return (
            <ErrorGoogle title="Please login with same email that you were invited to, click on invite link again to retry." />
        );
    }
    if (error === 'other') {
        return <ErrorGoogle title="Something went wrong, Please try again!" />;
    }
    return <TeamplusLoader />;
}
