import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import BgImage from './party.png';
import BgMobile from './background_mobile.svg';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            width: '100%',
            minHeight: 'calc(100vh - 130px)',
            height: 'auto',
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            '@media screen and (max-width: 768px)': {
                height: 'auto',
            },
        },
        leftFull: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
        },
        leftHalf: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
        },
        drawerOpen: {
            flex: 0.5,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            transition: '0.5s',
            position: 'relative',
            borderLeft: `0.5px solid ${theme.palette.text.primary}`,
            '@media screen and (max-width: 768px)': {
                display: 'none',
            },
        },
        drawerContentScroll: {
            width: '100%',
            height: 'calc(100vh - 160px)',
            paddingRight: 10,
        },
        drawerContentScrollMobile: {
            width: '100%',
            height: 'calc(100vh - 160px)',
            // paddingRight: 10,
            // backgroundColor: theme.palette.background.paper,
        },
        drawerContentScrollMobile2: {
            width: '100%',
            height: 'calc(100vh - 130px)',
            // paddingRight: 10,
            // backgroundColor: theme.palette.background.paper,
        },
        drawerContent: {
            width: '100%',
            height: 'calc(100vh - 160px)',
            // backgroundColor: 'yellow',
            // paddingTop: 10,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
            '@media screen and (max-width: 768px)': {
                height: 'calc(100vh - 200px)',
            },
        },
        drawerContent2: {
            width: '100%',
            height: 'calc(100vh - 215px)',
            // backgroundColor: 'yellow',
            // paddingTop: 10,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
            '@media screen and (max-width: 768px)': {
                paddingTop: 10,
            },
        },
        drawerClose: {
            display: 'none',
            transition: '0.5s',
        },
        filterButtonsDiv: {
            padding: '15px 20px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.paper,
            '@media screen and (max-width: 768px)': {
                display: 'none',
            },
        },
        filterButtonsDivMobileMain: {
            paddingBottom: 10,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.default,
            '@media screen and (min-width: 768px)': {
                display: 'none',
            },
        },
        searchbarDiv: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
            padding: 10,
            height: 40,
        },
        filterDiv: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
            padding: 10,
            height: 40,
            marginLeft: 10,
        },
        searchbarMobile: {
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            outline: 'none',
            '&:hover': {
                border: 'none',
                outline: 'none',
            },
            '&:focus': {
                border: 'none',
                outline: 'none',
            },
        },
        filterButtonsDivMobile: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: 40,
            display: 'grid',
            gridTemplateColumns: '1fr',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            fontSize: 18,
            '@media screen and (min-width: 769px)': {
                display: 'none',
            },
            zIndex: 99999,
        },
        // css for card
        cardsDiv: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
            height: 'calc(100vh - 200px)',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: 0,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 10,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                },
            },
            '@media screen and (max-width: 1050px)': {
                height: 'auto',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 10,
                padding: 10,
                paddingBottom: 50,
                gridAutoRows: '200px',
            },
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
            height: 200,
            borderRadius: '20px',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
            '@media screen and (max-width: 1050px)': {
                height: '200px',
            },
        },
        cardHidden: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 200,
            borderRadius: '20px',
            backgroundColor: '#3D49B6',
            backgroundImage: `url("${BgImage}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 200px',
            // backgroundOrigin: 'content-box',
            '@media screen and (max-width: 1050px)': {
                height: '200px',
            },
            // position: 'relative',
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: 200,
            // borderRadius: '20px',
            // backgroundColor: '#3D49B6',
            // // backgroundImage: `url("${BgImage}")`,
            // // backgroundSize: 'cover',
            // '@media screen and (max-width: 1050px)': {
            //     height: '250px',
            // },
        },
        cardHidden2: {
            // position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: 200,
            borderRadius: '20px',
            backgroundColor: '#3D49B6',
            backgroundImage: `url("${BgMobile}")`,
            backgroundRepeat: 'no-repeat',
            // objectFit: 'cover',
            // backgroundPosition: 'center',
            backgroundSize: '100% 200px',
            // backgroundOrigin: 'content-box',
            '@media screen and (max-width: 1050px)': {
                height: '200px',
            },
        },
        cardHeader: {
            display: 'flex',
            padding: 10,
            paddingTop: 20,
            height: 110,
            '@media screen and (max-width: 1050px)': {
                height: 'auto',
            },
        },
        cardHeader2: {
            height: 'auto',
            display: 'flex',
            padding: 10,
            paddingTop: 20,
        },
        logo: {
            flex: 0.3,
        },
        text: {
            flex: 1,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
            fontSize: 16,
            wordWrap: 'break-word',
            hyphens: 'auto',
        },
        textShort: {
            display: 'inline-block',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
            fontSize: 16,
            wordWrap: 'break-word',
            hyphens: 'auto',
        },
        textMobile: {
            flex: 1,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
            fontSize: 14,
            wordWrap: 'break-word',
            // whiteSpace: 'nowrap',
            // textOverflow: 'ellipsis',
        },
        textLink: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: 16,
            cursor: 'pointer',
            textTransform: 'capitalize',
        },
        cardFooter: {
            padding: 20,
            paddingBottom: 15,
            width: '100%',
            height: 90,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            '@media screen and (max-width: 1050px)': {
                height: 'auto',
                padding: 10,
                paddingBottom: 15,
            },
        },
        cardFooterComment: {
            padding: 20,
            paddingBottom: 5,
            width: '100%',
            height: 90,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '@media screen and (max-width: 1050px)': {
                height: 'auto',
            },
        },
        cardFooterLeft: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flex: 1,
        },
        // comments style
        comments: {
            position: 'relative',
            padding: '10px 25px',
            width: '100%',
            height: 'auto',
            // height: 'calc(100vh - 350px)',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            // '@media screen and (max-width: 1050px)': {
            //     height: 'calc(100vh - 350px)',
            // },
        },
        commentList: {
            width: '100%',
            height: 'auto',
            // height: 'calc(100vh - 440px)',
            display: 'flex',
            flexDirection: 'column',
            // overflow: 'auto',
            // '&::-webkit-scrollbar': {
            //     width: 0,
            // },
            // '@media screen and (max-width: 1050px)': {
            //     height: 'calc(100vh - 440px)',
            // },
        },
        commentForm: {
            position: 'absolute',
            bottom: 10,
            right: 20,
            left: 20,
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: 5,
        },
        commentFormMobile: {
            // position: 'absolute',
            // bottom: 10,
            // right: 20,
            // left: 20,
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: 5,
        },
        commentBox: {
            width: '90%',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
            padding: 10,
            height: 40,
            outline: 'none',
            border: 'none',
            background: 'transparent',
        },
        commentBtn: {
            width: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'transparent',
        },
        commentImage: {
            width: 26,
            height: 26,
        },
        // reactions styles
        reactions: {
            padding: '10px 25px',
            width: '100%',
            // height: 'calc(100vh - 350px)',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            // '@media screen and (max-width: 1050px)': {
            //     height: 'calc(100vh - 340px)',
            // },
        },
        reactionList: {
            width: '100%',
            // height: 'calc(100vh - 430px)',
            display: 'flex',
            // alignItems: 'stretch',
            flexDirection: 'column',
            // flexFlow: 'column',
            // overflow: 'auto',
            // '&::-webkit-scrollbar': {
            //     width: 0,
            // },
            // '@media screen and (max-width: 1050px)': {
            //     height: 'calc(100vh - 440px)',
            // },
        },
        footerText: {
            fontSize: 12,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            marginTop: 5,
        },
        date: {
            fontSize: 12,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
        },
        // ul style in reactions
        tabs: {
            width: '100%',
            height: 30,
            marginTop: 5,
            display: 'flex',
            overflow: 'hidden',
        },
        tab: {
            height: 30,
            display: 'flex',
            alignItems: 'center',
            fontSize: 15,
            padding: '5px 8px',
            textDecoration: 'none',
            listStyle: 'none',
            cursor: 'pointer',
        },
        activeTab: {
            height: 30,
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
            padding: '5px 8px',
            textDecoration: 'none',
            listStyle: 'none',
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            cursor: 'pointer',
        },
        emojiCount: {
            fontSize: 13,
            marginLeft: 2,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
        },
        btn: {
            height: 30,
            width: 40,
            position: 'absolute',
            top: '60%',
            left: '43%',
            border: '1px solid white',
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
                color: 'white',
            },
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
                color: 'white',
            },
        },
        viewbtn: {
            marginLeft: '20px',
            // marginRight: 'auto',
            marginTop: 80,
            height: 30,
            width: 40,
            // position: 'absolute',
            // textAlign: 'center',
            // top: '60%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            border: '1px solid white',
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
                color: 'white',
            },
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
                color: 'white',
            },
            '@media screen and (max-width: 768px)': {
                marginLeft: 0,
                marginTop: 20,
            },
        },
        btnHidden: {
            height: 30,
            width: 40,
            position: 'absolute',
            top: '60%',
            border: '1px solid white',
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
                color: 'white',
            },
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
                color: 'white',
            },
        },
        closeIcon: {
            // position: 'absolute',
            // top: 5,
            // right: 20,
            // zIndex: 9999,
            color: theme.palette.text.primary,
            cursor: 'pointer',
        },
        // buttons style
        shoutoutBtn: {
            color: '#fff',
            height: 36,
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            marginLeft: 10,
            '&:hover': {
                color: '#fff',
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
            },
            '&:focus': {
                color: '#fff',
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
            },
        },
        filterBtn: {
            height: 35,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            // color: theme.palette.text.primary,
            // backgroundColor: theme.palette.background.paper,
            // border: `1px solid ${theme.palette.text.primary}`,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.background.paper,
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.primary.contrastText}`,
            marginLeft: 10,
            outline: 'none',
            '&:hover': {
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        shoutoutBtnMobile: {
            width: '100%',
            color: '#fff',
            height: '100%',
            borderRadius: 0,
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
                color: '#fff',
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
            },
            '&:focus': {
                color: '#fff',
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
            },
            zIndex: 999999,
        },
        filterBtnMobile: {
            width: '100%',
            height: '100%',
            borderRadius: 0,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.primary}`,
            outline: 'none',
            '&:hover': {
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
            zIndex: 999999,
        },
        tag: {
            color: theme.palette.text.secondary,
        },
        emojiPicker: {
            width: 140,
            height: 36,
            position: 'absolute',
            top: -30,
            left: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.background.paper,
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderRadius: '15px',
        },
        empty: {
            display: 'none',
        },
        filterList: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            maxHeight: 210,
            overFlowY: 'auto',
            margin: 0,
            '&::-webkit-scrollbar': {
                width: 1,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
        },
        filterList2: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            margin: 0,
        },
        filterName: {
            padding: '2px 10px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
            },
        },
        filterName2: {
            padding: '2px 10px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
        },
        arrowBackStyle: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
        },
        arrowBackText: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            margin: '0px 10px',
        },
        textfieldDiv: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            borderRadius: 3,
            '& svg': {
                color: theme.palette.primary.contrastText,
                outline: 'none',
            },
        },
        inputDiv: {
            height: '100%',
            padding: '0px 10px',
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            overflowX: 'auto',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
                width: 0,
                height: 0,
            },
        },
        notFoundText: {
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: 16,
            marginTop: 20,
        },
        // style for searchbar
        searchInput: {
            height: 35,
            width: '100%',
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '&:hover, &:focus': {
                outline: 'none',
                color: theme.palette.text.primary,
            },
            // '@media screen and (min-width: 768px)': {
            //     marginLeft: '10px',
            // },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: '0px 14px',
                '&:hover, &:focus': {
                    outline: 'none',
                    color: theme.palette.text.primary,
                },
            },
        },
        searchBorder: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&:hover fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
        },
        searchIcon: {
            '@media screen and (min-width:912px) and (max-width: 1024px)': {
                marginLeft: 10,
            },
        },
        radioNameStyle: {
            letterSpacing: '0.03em',
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.text.primary,
            marginTop: '5px',
        },
    }),
);

export const formStyle = makeStyles((theme: Theme) =>
    createStyles({
        formMain: {
            paddingTop: 20,
        },
        formLabelStyle: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '14px',
            marginBottom: 10,
            '@media screen and (max-width: 770px)': {
                lineHeight: '22px',
            },
        },
        formLabelStyle2: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '14px',
            lineHeight: 0,
            '@media screen and (max-width: 770px)': {
                lineHeight: '22px',
            },
        },
        formControl: {
            width: '100%',
            // backgroundColor: '#fff',
        },
        inputSelectStyle1: {
            width: '90%',
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
        },
        inputSelectStyle2: {
            width: '100%',
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            marginTop: -2,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '& svg': {
                color: theme.palette.primary.contrastText,
                outline: 'none',
            },
        },
        inputSelectStyleOther: {
            width: '100%',
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
        },
        btnDiv: {
            height: 40,
            paddingTop: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        btn: {
            textTransform: 'capitalize',
            minWidth: 100,
            height: 35,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            outline: 'none',
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        btnOutlined: {
            textTransform: 'capitalize',
            width: 100,
            height: 35,
            marginRight: 10,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
        },
        fieldDiv: {
            marginBottom: '15px',
        },
        inputReason: {
            width: '100%',
            height: 35,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
        },
        emojiPickerStyles: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            '& div': {
                backgroundColor: theme.palette.background.paper,
            },
            '& p': {
                color: theme.palette.text.primary,
            },
        },
        searchBar: {
            width: '95%',
            height: 40,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            padding: '0px 10px',
            margin: '10px',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 3,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '& svg': {
                color: theme.palette.primary.contrastText,
            },
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                outline: 'none',
            },
        },
    }),
);
