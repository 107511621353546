import { convertTZ, toAWSDateFormat, convertTime } from './date';

export const giveTz = (offset: string) => {
    const v: number = parseInt(offset);
    if (v > 0) {
        const off = v / (60 * 60);
        const data = off.toString().split('.');
        const mystring = `GMT+${data[0].toString().padStart(2, '0')}${
            data[1] === '5' ? '30' : data[1] === '25' ? '15' : data[1] === '75' ? '45' : '00'
        }`;
        return mystring;
    } else {
        const off = v / (60 * 60);
        const data = Math.abs(off)
            .toString()
            .split('.');
        const mystring = `GMT-${data[0].toString().padStart(2, '0')}${
            data[1] === '5' ? '30' : data[1] === '25' ? '15' : data[1] === '75' ? '45' : '00'
        }`;
        return mystring;
    }
};

export const countryToFlag = (isoCode: string) => {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
};

export const localToISO = (date: string, time: string, obj: any) => {
    const d =
        obj?.gmtOffset === 'null' && obj?.zoneName === 'null'
            ? new Date().toISOString()
            : new Date(`${date} ${time} ${giveTz(obj?.gmtOffset)}`).toISOString();
    return d;
};

export const isoToLocal = (dateTime: string, obj: any) =>
    obj?.gmtOffset === 'null' && obj?.zoneName === 'null'
        ? new Date()
        : convertTZ(new Date(`${dateTime}`), obj?.zoneName);

export const isoToLocalTime = (dateTime: string, obj: any) =>
    obj?.gmtOffset === 'null' && obj?.zoneName === 'null'
        ? new Date()
        : convertTime(
              convertTZ(new Date(`${dateTime}`), obj?.zoneName)
                  .toTimeString()
                  .split(' ')[0]
                  .substring(0, 5),
          );

export const getCurrentCountryDate = (zoneName: string) =>
    toAWSDateFormat(convertTZ(new Date().toISOString(), zoneName));

export const getCurrentCountryDateTime = (zoneName: string) => {
    const date = convertTZ(new Date().toISOString(), zoneName);
    return {
        dateTime: date,
        date: date.toDateString(),
        time: date.toTimeString().split(' ')[0],
    };
};

export const getCurrentCountryTime = (zoneName: string) =>
    convertTZ(new Date().toISOString(), zoneName)
        .toTimeString()
        .split(' ')[0]
        .slice(0, 5);

export const convertedDateTime = (dateTime: string, obj: any) => {
    const date = toAWSDateFormat(isoToLocal(dateTime, obj));
    const time = isoToLocal(dateTime, obj)
        .toTimeString()
        .split(' ')[0]
        .slice(0, 5);
    return { date, time };
};
