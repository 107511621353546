import React, { useState, useContext } from 'react';
import useStyles from './styles';
import First from './First';
import Second from './Second';
import SignInUser from './SignInUser';
import { MyModalDemo } from '../Modal/Modal';
import { Grid, Button, Hidden } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import AuthContext from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
// import Spinner from '../Spinner/Spinner';
import ProgressPage from './ProgressPage';
import { PopUp } from '../Modal/Modal';
import {
    createEmployee,
    createOrganization,
    createOrganizationEmployee,
    createGeneralSettings,
    createOrganizationWorkweek,
    createLeaveType,
    createWorkweek,
    sendEmail,
    getEmployeeOrganization,
    getEmployee,
    addDefaultTeam,
    addDefaultTeamMembers,
    addDefaultTeamApprover,
    deleteEmployee,
    deleteCognitoUser,
    getOrganization,
    getOrganizationSettingId,
} from '../../helpers/apifunctions';
import { toDate, toAWSDateFormat } from '../../helpers/date';
import { months, days } from '../../helpers/helperVariables';
import { getNameFromEmail } from '../../helpers/getInitials';
import TeamPlusLogo from '../../Assets/images/udyamo-teamplus-logo-white.png';

export default function GoogleSignIn(): JSX.Element {
    const classes = useStyles();
    const history = useHistory();

    const mycontext = useContext(AuthContext);
    const [step, setStep] = useState<number>(1);
    const [open, setOpen] = useState<boolean>(false);

    // user details for first page
    // const [fname, setFname] = useState<string>('');

    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    // organization details for second page
    const [orgname, setOrgname] = useState<string>('');
    const [oemail, setOemail] = useState<string>('');
    const [ulmtvacation, setUlmtVacation] = useState<string>('No');
    const [month, setMonth] = useState<string>('Jan');
    const [day, setDay] = useState<any>(1);
    const [daysLeave, setDaysLeave] = useState<any>(25);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [checkbox, setcheckbox] = React.useState({
        checkedA: false,
        checkedB: true,
        checkedC: true,
        checkedD: true,
        checkedE: true,
        checkedF: true,
        checkedG: true,
    });
    const [task, setTask] = React.useState({
        task1: false,
        task2: false,
        task3: false,
        task4: false,
    });
    const [isLogedIn, setIsLogedIn] = React.useState<boolean>(false);
    const [memail, setMEmail] = React.useState<string>('');
    const [allEmails, setAllEmails] = React.useState<any>([]);

    const [username, setUsername] = React.useState<any>(null);
    const [userId, setUserId] = React.useState<any>(null);

    const handleChangeCheckbox = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setcheckbox({ ...checkbox, [name]: event.target.checked });
    };

    const nextStep = (): any => {
        setStep(step + 1);
    };

    const prevStep = (): any => {
        setStep(step - 1);
    };

    const cancelModal = (): any => {
        prevStep();
        setOpen(!open);
    };

    const handleLogin = async (loginResponse: any) => {
        try {
            const data = await getEmployee(
                loginResponse.attributes.sub,
                loginResponse.signInUserSession.accessToken.jwtToken,
            );
            if (data.error) {
                throw new Error('Login failed');
            } else {
                mycontext.login({
                    username: data.username,
                    password: data.password,
                    EmployeeId: data.EmployeeId,
                    fname: data.firstname,
                    lname: data.lastname,
                    isAdmin: data.isAdmin === 'admin' ? true : false,
                    isApprover: data.isapprover.toLowerCase() === 'Yes'.toLowerCase() ? true : false,
                    isLogedIn: true,
                    joining: data.joining,
                    profile: data.image,
                    teamname: data.teamname,
                    email: data.email,
                    isGoogle: true,
                    token: loginResponse.signInUserSession.accessToken.jwtToken,
                    uuid: loginResponse.attributes.sub,
                    availabledaysoffcurrentyear: data.availabledaysoffcurrentyear,
                    useddaysoffcurrentyear: data.useddaysoffcurrentyear,
                    totaldaysoffcurrentyear: data.totaldaysoffcurrentyear,
                    availabledaysoffnextyear: data.availabledaysoffnextyear,
                    useddaysoffnextyear: data.useddaysoffnextyear,
                    totaldaysoffnextyear: data.totaldaysoffnextyear,
                    sickdaysused: data.sickdaysused,
                    sickdaystotal: data.sickdaystotal,
                    subscriptionExpired: '',
                });
                const organization = await getEmployeeOrganization(
                    loginResponse.attributes.sub,
                    loginResponse.signInUserSession.accessToken.jwtToken,
                );
                mycontext.addOrganizationId(organization[0].organizationId);
                const organizationDetails = await getOrganization(organization[0].organizationId);
                // console.log(organizationDetails);
                mycontext.addWebhookUrl(organizationDetails.webhookURL);
                const { settingId, dateFormat } = await getOrganizationSettingId(
                    organization[0].organizationId,
                    loginResponse.signInUserSession.accessToken.jwtToken,
                );
                mycontext.addSettingsId(settingId);
                mycontext.updateDateFormat(dateFormat);
                // alert('Login successful!');
                setTimeout(() => history.push('/dashboard'), 2000);
                setSeverity('success');
                setAlert(true);
                setAlertMessage('Login Successful!');
                // setLoading(false);
            }
        } catch (err) {
            // setLoading(false);
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Something went wrong, try again!');
            return err;
        }
    };

    const register = async () => {
        try {
            const loginResponse = await Auth.currentAuthenticatedUser();
            setUsername(loginResponse.username);
            setUserId(loginResponse.attributes.sub);
            // mycontext.registerUser(loginResponse.user, pass);
            if (loginResponse) {
                const obj = {
                    id: loginResponse.attributes.sub,
                    firstname: getNameFromEmail(loginResponse.attributes.email),
                    lastname: '',
                    email: loginResponse.attributes.email,
                    username: loginResponse.username,
                    password: '',
                    gender: '',
                    joining: toDate(new Date()),
                    isAdmin: 'admin',
                    teamname: orgname,
                    statusType: 'Active',
                    availabledaysoffcurrentyear: 25,
                    useddaysoffcurrentyear: 0,
                    totaldaysoffcurrentyear: 25,
                    availabledaysoffnextyear: 25,
                    useddaysoffnextyear: 0,
                    totaldaysoffnextyear: 25,
                    isapprover: 'yes',
                    unlimitedvacationpolicy: 'yes',
                    allowdefaultteamleaves: 'yes',
                    activecustomdays: 'yes',
                    deactivate: 'no',
                    sickdaysused: 0,
                    sickdaystotal: 10,
                    image:
                        'https://teamplusimagestorage.blob.core.windows.net/teamplusimagestorage/avatars/profile.png',
                };

                const data = {
                    organizationName: orgname,
                    creator: loginResponse.attributes.sub,
                };
                await createEmployee(obj);
                const organization = await createOrganization(data);
                setTask({ ...task, task1: true });
                mycontext.addOrganizationId(organization.OrganizationId);

                const organizationEmployee = {
                    organizationId: organization.OrganizationId,
                    employeeId: loginResponse.attributes.sub,
                };
                await createOrganizationEmployee(organizationEmployee);

                const workweek = {
                    sunday: checkbox.checkedA ? 'true' : 'false',
                    monday: checkbox.checkedB ? 'true' : 'false',
                    tuesday: checkbox.checkedC ? 'true' : 'false',
                    wednesday: checkbox.checkedD ? 'true' : 'false',
                    thursday: checkbox.checkedE ? 'true' : 'false',
                    friday: checkbox.checkedF ? 'true' : 'false',
                    saturday: checkbox.checkedG ? 'true' : 'false',
                };

                const orgWorkweek = await createWorkweek(workweek);
                const organizationWorkweek = {
                    organizationId: organization.OrganizationId,
                    workweekId: orgWorkweek.workweekId,
                };
                await createOrganizationWorkweek(organizationWorkweek);

                const generalSettings = {
                    OrganizationId: organization.OrganizationId,
                    dateFormat: 'DD/MM/YYYY',
                    transfer: 'no',
                    daysExpire: 'no',
                    month: 'January',
                    date: '1',
                };
                const settings = await createGeneralSettings(generalSettings);
                setTask({ ...task, task1: true, task2: true });
                mycontext.addSettingsId(settings.settingId);

                const PaidleaveType = {
                    leaveTypeName: 'Paid Time Off',
                    type: 'paid',
                    organizationId: organization.OrganizationId,
                    active: 'yes',
                    daysPerYear: '25',
                    approvalRequired: 'false',
                    reasonRequired: 'false',
                    allowHalfDay: 'true',
                };

                const SickleaveType = {
                    leaveTypeName: 'Sick Leave',
                    type: 'casual',
                    organizationId: organization.OrganizationId,
                    active: 'yes',
                    daysPerYear: '10',
                    approvalRequired: 'false',
                    reasonRequired: 'false',
                    allowHalfDay: 'true',
                };

                const CasualleaveType = {
                    leaveTypeName: 'Casual Leave',
                    type: 'casual',
                    organizationId: organization.OrganizationId,
                    active: 'yes',
                    daysPerYear: '5',
                    approvalRequired: 'true',
                    reasonRequired: 'true',
                    allowHalfDay: 'true',
                };

                const team = {
                    name: 'General',
                    createdAt: toAWSDateFormat(new Date()),
                    orgId: organization.OrganizationId,
                };

                await createLeaveType(PaidleaveType);
                await createLeaveType(SickleaveType);
                await createLeaveType(CasualleaveType);
                setTask({ ...task, task1: true, task2: true, task3: true });
                const defaultTeam = await addDefaultTeam(team);
                if (defaultTeam.teamId !== null) {
                    const teamMember = {
                        teamId: defaultTeam.teamId,
                        employeeId: loginResponse.attributes.sub,
                    };
                    await addDefaultTeamMembers(teamMember);
                    await addDefaultTeamApprover(teamMember);
                }
                setTask({ ...task, task1: true, task2: true, task3: true, task4: true });
                sendEmail({
                    from: 'teamplus.udyamo@gmail.com',
                    emails: allEmails,
                    message:
                        'Welcome to Udyamo TeamPlus. Register on this link: https://teamplus.udyamo.net/organization/' +
                        organization.OrganizationId +
                        '/register',
                });
                setEmail('');
                setIsLogedIn(true);
                await handleLogin(loginResponse);
                // alert('User added, Please verify your email.');
            }
        } catch (err) {
            // alert(err);
            await deleteCognitoUser(username);
            await deleteEmployee(userId);
            history.push('/auth');
            setSeverity('error');
            setAlert(true);
            setAlertMessage('Something went wrong, try again!');
        }
    };

    const handleStepModal = () => {
        setOpen(!open);
        prevStep();
    };

    const handleRegister = async () => {
        setOpen(!open);
        nextStep();
    };

    const handleSubmit = async () => {
        setOpen(!open);
        setLoading(true);
        await register();
        setLoading(false);
    };

    // const userDetails = {
    //     fname: fname,
    //     setFname: setFname,
    //     lname: lname,
    //     setLname: setLname,
    //     email: email,
    //     setEmail: setEmail,
    //     pass: pass,
    //     setPass: setPass,
    //     cpass: cpass,
    //     setCpass: setCpass,
    //     error: error,
    //     setError: setError,
    //     oemail: oemail,
    //     setOemail: setOemail,
    //     gender: gender,
    //     setGender: setGender,
    // };

    const Values = {
        email: email,
        setEmail: setEmail,
        oemail: oemail,
        setOemail: setOemail,
        vacation: ulmtvacation,
        setVacation: setUlmtVacation,
        checkbox: checkbox,
        handleCheckbox: handleChangeCheckbox,
        months: months,
        month: month,
        setMonth: setMonth,
        days: days,
        day: day,
        setDay: setDay,
        daysLeave: daysLeave,
        setDaysLeave: setDaysLeave,
        memail: memail,
        setMEmail: setMEmail,
        allEmails: allEmails,
        setAllEmails: setAllEmails,
        orgname: orgname,
        setOrgname: setOrgname,
    };
    if (loading) {
        return <ProgressPage task={task} isLogedIn={isLogedIn} />;
    }
    switch (step) {
        case 1:
            return (
                // <div>
                //     <div className={classes.header}>
                //         <h2 className={classes.title}>TeamPlus</h2>
                //         <h2 className={classes.counter}>
                //             {step}/{mycontext.isGoogle ? 2 : 3}
                //         </h2>
                //     </div>
                //     <div className={classes.body}>
                //
                //     </div>
                // </div>
                <Grid container className={classes.body}>
                    <Hidden lgDown>
                        <Grid item xl={7}>
                            <div className={classes.bgImage}>
                                <h1 className={classes.heading}>Welcome to </h1>
                                <img src={TeamPlusLogo} alt="TeamPlus" className={classes.teamLogoSmall} />
                                <p className={classes.subtitle}>Register Your Organization</p>
                            </div>
                        </Grid>
                    </Hidden>
                    {/* <Grid item sm={12} xs={12} xl={5}> */}
                    <SignInUser nextStep={nextStep} values={Values} />
                </Grid>
            );
        case 2:
            // return (
            //     <div>
            //         <div className={classes.header}>
            //             <h2 className={classes.title}>TeamPlus</h2>
            //             <h2 className={classes.counter}>
            //                 {mycontext.isGoogle ? step - 1 : step}/{mycontext.isGoogle ? 2 : 3}
            //             </h2>
            //         </div>
            //         <div className={classes.body}>
            //             <First prevStep={prevStep} nextStep={nextStep} values={Values} isGoogle={mycontext.isGoogle} />
            //         </div>
            //     </div>
            // );
            return (
                <Grid container className={classes.body}>
                    <Hidden lgDown>
                        <Grid item xl={7}>
                            <div className={classes.bgImage}>
                                <h1 className={classes.heading}>Welcome to </h1>
                                <img src={TeamPlusLogo} alt="TeamPlus" className={classes.teamLogoSmall} />
                                <p className={classes.subtitle}>Register Your Organization</p>
                            </div>
                        </Grid>
                    </Hidden>
                    {/* <Grid item sm={12} xs={12} xl={5}> */}
                    <First
                        prevStep={prevStep}
                        nextStep={nextStep}
                        values={Values}
                        isGoogle={mycontext.isGoogle}
                        stepCount={step}
                        // totalCount={mycontext.isGoogle ? 2 : 3}
                    />
                    {/* </Grid> */}
                    {/* <Register prevStep={prevStep} nextStep={nextStep} values={userDetails} /> */}
                    {/* </div> */}
                </Grid>
            );
        case 3:
            // return (
            //     <div>
            //         <div className={classes.header}>
            //             <h2 className={classes.title}>TeamPlus</h2>
            //             <h2 className={classes.counter}>
            //                 {mycontext.isGoogle ? step - 1 : step}/{mycontext.isGoogle ? 2 : 3}
            //             </h2>
            //         </div>
            //         <div className={classes.body}>
            //             <Second
            //                 prevStep={prevStep}
            //                 nextStep={nextStep}
            //                 isOpen={open}
            //                 setOpen={setOpen}
            //                 values={Values}
            //                 handleRegister={handleRegister}
            //             />
            //         </div>
            //     </div>
            // );
            return (
                <Grid container className={classes.body}>
                    <MyModalDemo open={open} setOpen={setOpen} prevStep={prevStep} onclick={handleStepModal}>
                        <Grid
                            container
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{
                                    marginBottom: '20px',
                                    fontFamily: 'Nunito Sans',
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: '#666666',
                                }}
                            >
                                Are you sure you want to proceed ahead and add your team to TeamPlus?
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => cancelModal()}
                                    className={classes.btnOutlined}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleSubmit()}
                                    className={classes.btnPrimary}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </MyModalDemo>
                    <Hidden lgDown>
                        <Grid item xl={7}>
                            <Grid container direction="row" className={classes.bgImage}>
                                {/* <Grid item xs={12}>
                                <img src={bgImg}  alt="BackGroundImg" />
                            </Grid> */}
                                {/* <Grid item xs={12} className={classes.overlay}> */}
                                <h1 className={classes.heading}>Welcome to </h1>
                                <img src={TeamPlusLogo} alt="TeamPlus" className={classes.teamLogoSmall} />
                                {/* </Grid> */}
                                {/* <Grid item xs={12} className={classes.overlay2}> */}
                                <p className={classes.subtitle}>Register Your Organization</p>
                                {/* </Grid> */}
                            </Grid>
                        </Grid>
                    </Hidden>

                    <Second
                        prevStep={prevStep}
                        nextStep={nextStep}
                        isOpen={open}
                        setOpen={setOpen}
                        values={Values}
                        handleRegister={handleRegister}
                        handleSubmit={handleSubmit}
                        stepCount={step}
                        // totalCount={mycontext.isGoogle ? 2 : 3}
                    />
                </Grid>
            );
        default:
            // return (
            //     <>
            //         <Alert setOpen={setAlert} open={alert}>
            //             <div style={{ padding: 5 }}>
            //                 <h4 style={{ padding: 5, opacity: 0.6 }}>{alertMessage}</h4>
            //                 <div style={{ padding: '5px 0', display: 'flex', justifyContent: 'flex-end' }}>
            //                     {/* <Button
            //                     variant="contained"
            //                     color="primary"
            //                     onClick={() => setAlert(false)}
            //                     style={{ textTransform: 'capitalize' }}
            //                 >
            //                     Ok
            //                 </Button> */}
            //                 </div>
            //             </div>
            //         </Alert>
            //         <div>
            //             <div className={classes.header}>
            //                 <h2 className={classes.title}>TeamPlus</h2>
            //             </div>
            //             <div className={classes.body}>
            //                 <StepModal
            //                     title="Set Up TeamPlus for your Team"
            //                     open={open}
            //                     setOpen={setOpen}
            //                     prevStep={prevStep}
            //                 >
            //                     <Grid container style={{ padding: '20px 0px' }}>
            //                         <Grid item xs={12} sm={12} style={{ paddingBottom: '20px' }}>
            //                             Are you sure you want to proceed ahead and add your team to TeamPlus?
            //                         </Grid>
            //                         <Grid item xs={6} sm={6}>
            //                             <Button
            //                                 color="primary"
            //                                 variant="contained"
            //                                 onClick={() => cancelModal()}
            //                                 style={{ textTransform: 'capitalize' }}
            //                             >
            //                                 Cancel
            //                             </Button>
            //                         </Grid>
            //                         <Grid item xs={6} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            //                             <Button
            //                                 color="primary"
            //                                 variant="contained"
            //                                 onClick={() => handleSubmit()}
            //                                 style={{ textTransform: 'capitalize' }}
            //                             >
            //                                 Confirm
            //                             </Button>
            //                         </Grid>
            //                     </Grid>
            //                 </StepModal>
            //             </div>
            //         </div>
            //     </>
            // );
            return (
                <Grid container className={classes.body}>
                    <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                    <MyModalDemo open={open} setOpen={setOpen} prevStep={prevStep} onclick={handleStepModal}>
                        <Grid
                            container
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{
                                    marginBottom: '20px',
                                    fontFamily: 'Nunito Sans',
                                    fontWeight: 400,
                                    fontSize: 18,
                                    color: '#666666',
                                }}
                            >
                                Are you sure you want to proceed ahead and add your team to TeamPlus?
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => cancelModal()}
                                    className={classes.btnOutlined}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleSubmit()}
                                    className={classes.btnPrimary}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </MyModalDemo>
                    <Hidden lgDown>
                        <Grid item xl={7}>
                            <Grid container direction="row" className={classes.bgImage}>
                                {/* <Grid item xs={12}>
                                <img src={bgImg}  alt="BackGroundImg" />
                            </Grid> */}
                                {/* <Grid item xs={12} className={classes.overlay}> */}
                                <h1 className={classes.heading}>Welcome to </h1>
                                <img src={TeamPlusLogo} alt="TeamPlus" className={classes.teamLogoSmall} />
                                {/* </Grid> */}
                                {/* <Grid item xs={12} className={classes.overlay2}> */}
                                <p className={classes.subtitle}>Register Your Organization</p>
                                {/* </Grid> */}
                            </Grid>
                        </Grid>
                    </Hidden>

                    <Second
                        prevStep={prevStep}
                        nextStep={nextStep}
                        isOpen={open}
                        setOpen={setOpen}
                        values={Values}
                        handleRegister={handleRegister}
                        handleSubmit={handleSubmit}
                        stepCount={step}
                        // totalCount={mycontext.isGoogle ? 2 : 3}
                    />
                </Grid>
            );
        // return (
        //     <div>
        //         <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
        //         <div className={classes.header}>
        //             <h2 className={classes.title}>
        //                 <img src={TeamPlusLogo} alt="teamplus" className={classes.teamLogoSmall} />
        //             </h2>
        //         </div>
        //         <div className={classes.body}>
        //             <MyModalDemo open={open} setOpen={setOpen} prevStep={prevStep} onclick={handleStepModal}>
        //                 <Grid
        //                     container
        //                     style={{
        //                         display: 'flex',
        //                         flexDirection: 'column',
        //                         alignItems: 'center',
        //                         justifyContent: 'center',
        //                         textAlign: 'center',
        //                     }}
        //                 >
        //                     <Grid
        //                         item
        //                         xs={12}
        //                         sm={12}
        //                         style={{
        //                             marginBottom: '20px',
        //                             fontFamily: 'Nunito Sans',
        //                             fontWeight: 400,
        //                             fontSize: 18,
        //                             color: '#666666',
        //                         }}
        //                     >
        //                         Are you sure you want to proceed ahead and add your team to TeamPlus?
        //                     </Grid>
        //                     <Grid item xs={12} sm={12}>
        //                         <Button
        //                             color="primary"
        //                             variant="outlined"
        //                             onClick={() => cancelModal()}
        //                             className={classes.btnOutlined}
        //                         >
        //                             Cancel
        //                         </Button>
        //                         <Button
        //                             color="primary"
        //                             variant="contained"
        //                             onClick={() => handleSubmit()}
        //                             className={classes.btnPrimary}
        //                         >
        //                             Confirm
        //                         </Button>
        //                     </Grid>
        //                 </Grid>
        //             </MyModalDemo>
        //         </div>
        //     </div>
        // );
    }
}
