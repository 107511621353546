import {
    getAdminApprovers,
    getApproverInfo,
    getApproverCancelInfo,
    getSlackChannel,
    getMsApproverInfo,
    getMsAdminApprovers,
    getApproverAcc,
    getUserInfoForCancelRequest,
} from './hasuraapi';
import config from '../config.json';

const notifUrl = 'https://4fxm4bwhy0.execute-api.eu-north-1.amazonaws.com/production/notification';

export const handleCancelNotification = async (obj: any) => {
    const info = {
        empId: obj.empId,
        actorId: obj.actorId,
        orgId: obj.orgId,
        workspace: localStorage.getItem('slackWorkspaceId'),
    };
    const userData = await getApproverCancelInfo(info, obj.token);
    let botToken = null;
    let empSlackChannel = null;
    let denierSlackId = null;
    const slackChannel =
        userData?.data?.usermanagementdb_Organization?.length > 0
            ? userData.data.usermanagementdb_Organization[0].timeoffChannel.channel
            : null;
    empSlackChannel = userData.data.usermanagementdb_organization_employee[0]?.slackId;
    botToken = userData.data.usermanagementdb_SlackBot[0]?.timeoffToken;
    denierSlackId = userData.data.usermanagementdb_organization_employee[0]?.slackId;
    let notifObj = null;
    notifObj = {
        channelId: empSlackChannel,
        approvername: denierSlackId,
        notif_type: obj.notif_type,
        workspaceBotToken: botToken,
        publicChannel: slackChannel,
        prevStatus: obj.prevStatus,
        action: obj.action,
        isoString: obj.isoString,
        leaveInfo: {
            leaveId: null,
            startDate: obj.startDate,
            endDate: obj.endDate,
            startTime: obj.startTime,
            endTime: obj.endTime,
        },
    };

    if (notifObj !== null) {
        await fetch(notifUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(notifObj),
        });
    }
    return true;
};

export const handleNotification = async (obj: any) => {
    const info = {
        empId: obj.empId,
        actorId: obj.actorId,
        orgId: obj.orgId,
        leaveId: obj.leaveId,
        workspace: localStorage.getItem('slackWorkspaceId'),
    };
    const userData = await getApproverInfo(info, obj.token);
    let botToken = null;
    let empSlackId = null;
    let empSlackChannel = null;
    let approverSlackId = null;
    let denierSlackId = null;
    const slackChannel =
        userData?.data?.usermanagementdb_Organization?.length > 0
            ? userData.data.usermanagementdb_Organization[0].timeoffChannel.channel
            : null;
    if (userData?.data?.usermanagementdb_organization_employee?.length > 0) {
        const employeeInfo = userData?.data?.usermanagementdb_organization_employee[0];
        botToken = userData?.data?.usermanagementdb_SlackBot[0]?.timeoffToken;
        if (employeeInfo.isAccountLinked && employeeInfo.slackId !== null) {
            empSlackChannel = employeeInfo.slackId;
            empSlackId = {
                name: employeeInfo.slackId,
                type: 'id',
            };
        } else {
            empSlackId = {
                name: employeeInfo.Employee.firstName + ' ' + employeeInfo.Employee.lastName,
                type: 'name',
            };
        }
    }
    if (userData.data?.timeoffdb_Leaves?.length > 0) {
        if (obj.notif_type === 'approveRequest') {
            const approverData = userData.data.timeoffdb_Leaves[0].Organization.organization_employees[0];
            approverSlackId =
                approverData.slackId === 'null' || approverData.slackId === null
                    ? {
                          name: approverData?.Employee?.firstName + ' ' + approverData?.Employee?.lastName,
                          type: 'name',
                      }
                    : {
                          name: approverData.slackId,
                          type: 'id',
                      };
        } else {
            const denierData = userData.data.timeoffdb_Leaves[0].Organization.organization_employees[0];
            denierSlackId =
                denierData.slackId === 'null' || denierData.slackId === null
                    ? {
                          name: denierData?.Employee?.firstName + ' ' + denierData?.Employee?.lastName,
                          type: 'name',
                      }
                    : {
                          name: denierData.slackId,
                          type: 'id',
                      };
        }
    }

    let notifObj = null;

    if (obj.notif_type === 'approveRequest') {
        if (empSlackId === null || approverSlackId === null) {
            return;
        } else {
            notifObj = {
                channelId: empSlackChannel,
                leaveFor: empSlackId,
                approvername: approverSlackId,
                notif_type: obj.notif_type,
                workspaceBotToken: botToken,
                publicChannel: slackChannel,
                isoString: obj.isoString,
                leaveInfo: {
                    leaveId: obj.leaveId,
                    startDate: obj.startDate,
                    endDate: obj.endDate,
                    startTime: obj.startTime,
                    endTime: obj.endTime,
                },
            };
        }
    } else {
        if (empSlackId === null || denierSlackId === null) {
            return;
        } else {
            notifObj = {
                channelId: empSlackChannel,
                approvername: denierSlackId,
                notif_type: obj.notif_type,
                workspaceBotToken: botToken,
                publicChannel: slackChannel,
                prevStatus: obj.prevStatus,
                action: obj.action,
                isoString: obj.isoString,
                leaveInfo: {
                    leaveId: obj.leaveId,
                    startDate: obj.startDate,
                    endDate: obj.endDate,
                    startTime: obj.startTime,
                    endTime: obj.endTime,
                },
            };
        }
    }

    if (notifObj !== null) {
        await fetch(notifUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(notifObj),
        });
    }

    if (obj.notif_type === 'approveRequest') {
        const channelInfo = await getSlackChannel(
            {
                orgId: obj.orgId,
                empId: obj.actorId,
                workspace: localStorage.getItem('slackWorkspaceId'),
            },
            obj.token,
        );

        const leaveTypes = channelInfo.timeoffdb_LeaveType;
        const categoryType =
            leaveTypes.length > 0
                ? leaveTypes.filter((lt: { leaveTypeName: any }) => lt.leaveTypeName === obj.category)
                : [{ leaveTypeEmoticon: '🌴' }];
        let leaveIcon = '🌴';

        if (categoryType.length > 0) {
            leaveIcon = categoryType[0].leaveTypeEmoticon === 'palm_tree' ? '🌴' : categoryType[0].leaveTypeEmoticon;
        }

        const statusObj = {
            startDate: obj.startDate,
            endDate: obj.endDate,
            startTime: obj.startTime,
            endTime: obj.endTime,
            employeeId: obj.actorId,
            organizationId: obj.orgId,
            category: obj.category,
            categoryIcon: leaveIcon,
        };

        await fetch('https://4fxm4bwhy0.execute-api.eu-north-1.amazonaws.com/production/updateStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(statusObj),
        });
    }

    return true;
};

export const handleTimeoffs = async (obj: any) => {
    try {
        if (obj.approvalRequired) {
            const allApprovers = await getAdminApprovers(
                {
                    empId: obj.empId,
                    orgId: obj.orgId,
                    otherEmp: obj.otherEmp,
                    workspace: localStorage.getItem('slackWorkspaceId'),
                },
                obj.token,
            );
            let botToken = '';

            const otherEmp = allApprovers.usermanagementdb_organization_employee[0].Organization.organization_employees;
            const otherApprovers = allApprovers.usermanagementdb_Team;
            const botData = allApprovers.usermanagementdb_SlackBot;

            if (allApprovers.usermanagementdb_organization_employee.length > 0) {
                const approversData = allApprovers.usermanagementdb_organization_employee[0];
                const slackUser =
                    approversData.slackId === null || approversData.slackId === 'null'
                        ? {
                              name: approversData.Employee.firstName + ' ' + approversData.Employee.lastName,
                              type: 'name',
                          }
                        : {
                              name: approversData.slackId,
                              type: 'id',
                          };

                const otherUserData = allApprovers.usermanagementdb_Organization[0].organization_employees;
                let otherUser: any = null;

                if (otherUserData.length > 0) {
                    if (otherUserData[0].isAccountLinked === false) {
                        otherUser = {
                            name: otherUserData[0].Employee.firstName + ' ' + otherUserData[0].Employee.lastName,
                            type: 'name',
                        };
                    } else {
                        otherUser =
                            otherUserData[0].slackId === 'null' || otherUserData[0].slackId === null
                                ? {
                                      name:
                                          otherUserData[0].Employee.firstName +
                                          ' ' +
                                          otherUserData[0].Employee.lastName,
                                      type: 'name',
                                  }
                                : {
                                      name: otherUserData[0].slackId,
                                      type: 'id',
                                  };
                    }
                }

                if (botData.length > 0) {
                    botToken = botData[0].timeoffToken;
                    let adminList: any = [];
                    let approverList: any = [];
                    const newBlock = {
                        leaveId: obj.leaveId,
                        notif_type: 'selfRequest',
                        channelId: approversData.slackId,
                        leaveType: obj.leaveType,
                        reason: obj.reason,
                        from: obj.startDate,
                        to: obj.endDate,
                        fromTime: obj.startTime,
                        toTime: obj.endTime,
                        isoString: obj.isoString,
                        workspaceBotToken: botToken,
                    };
                    await fetch(notifUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(newBlock),
                    });
                    if (otherEmp.length > 0) {
                        adminList = otherEmp.filter((ad: any) => ad.role === 'admin');

                        for (let i = 0; i < otherApprovers.length; i++) {
                            if (otherApprovers[i].team_employees.length > 0) {
                                for (let j = 0; j < otherApprovers[i].team_employees.length; j++) {
                                    if (
                                        otherApprovers[i].team_employees[j].Employee.organization_employees.length > 0
                                    ) {
                                        approverList.push({
                                            slackId:
                                                otherApprovers[i].team_employees[j].Employee.organization_employees[0]
                                                    .slackId,
                                        });
                                    }
                                }
                            }
                        }
                        approverList = approverList.filter((ap: any) => ap.slackId !== approversData.slackId);
                        approverList = approverList.filter(
                            (elm: any) => !adminList.map((elm: any) => elm.slackId).includes(elm.slackId),
                        );
                    }
                    if (adminList.length > 0) {
                        for (let i = 0; i < adminList.length; i++) {
                            const objData = {
                                channelId: adminList[i].slackId,
                                reqType: obj.leaveType,
                                user: slackUser,
                                reason: obj.reason,
                                from: obj.startDate,
                                to: obj.endDate,
                                fromTime: obj.startTime,
                                toTime: obj.endTime,
                                leaveId: obj.leaveId,
                                workspaceBotToken: botToken,
                                notif_type: 'timeoffRequest',
                                isoString: obj.isoString,
                                otherUser: obj.requestFor === 'self' ? null : otherUser,
                            };

                            await fetch(notifUrl, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(objData),
                            });
                        }
                    }
                    if (approverList.length > 0) {
                        for (let i = 0; i < approverList.length; i++) {
                            const objData = {
                                channelId: approverList[i].slackId,
                                reqType: obj.leaveType,
                                user: slackUser,
                                reason: obj.reason,
                                from: obj.startDate,
                                to: obj.endDate,
                                fromTime: obj.startTime,
                                toTime: obj.endTime,
                                leaveId: obj.leaveId,
                                workspaceBotToken: botToken,
                                isoString: obj.isoString,
                                notif_type: 'timeoffRequest',
                                otherUser: obj.requestFor === 'self' ? null : otherUser,
                            };

                            await fetch(notifUrl, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(objData),
                            });
                        }
                    }
                }
            }
        } else {
            const channelInfo = await getSlackChannel(
                {
                    orgId: obj.orgId,
                    empId: obj.empId,
                    workspace: localStorage.getItem('slackWorkspaceId'),
                },
                obj.token,
            );

            const leaveTypes = channelInfo.timeoffdb_LeaveType;

            if (channelInfo.usermanagementdb_SlackBot.length > 0) {
                const botToken = channelInfo.usermanagementdb_SlackBot[0].timeoffToken;
                let empChannel = null;
                let leaveFor = null;
                let publicChannel = null;

                if (channelInfo.usermanagementdb_organization_employee.length > 0) {
                    publicChannel =
                        channelInfo.usermanagementdb_organization_employee[0].Organization.timeoffChannel.channel;
                    empChannel =
                        channelInfo.usermanagementdb_organization_employee[0].slackId === null ||
                        channelInfo.usermanagementdb_organization_employee[0].slackId === 'null'
                            ? null
                            : channelInfo.usermanagementdb_organization_employee[0].slackId;

                    leaveFor =
                        channelInfo.usermanagementdb_organization_employee[0].slackId === null ||
                        channelInfo.usermanagementdb_organization_employee[0].slackId === 'null'
                            ? {
                                  name:
                                      channelInfo.usermanagementdb_organization_employee[0].Employee.firstName +
                                      ' ' +
                                      channelInfo.usermanagementdb_organization_employee[0].Employee.lastName,
                                  type: 'name',
                              }
                            : {
                                  name: channelInfo.usermanagementdb_organization_employee[0].slackId,
                                  type: 'id',
                              };
                }

                const objData = {
                    channelId: empChannel,
                    leaveFor: leaveFor,
                    approvername: null,
                    notif_type: 'approveRequest',
                    workspaceBotToken: botToken,
                    publicChannel: publicChannel,
                    isoString: obj.isoString,
                    leaveInfo: {
                        leaveId: obj.leaveId,
                        startDate: obj.startDate,
                        endDate: obj.endDate,
                        startTime: obj.startTime,
                        endTime: obj.endTime,
                    },
                };

                const messageResponse = await fetch(notifUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(objData),
                });

                if (!messageResponse.ok) {
                    return;
                } else {
                    const categoryType =
                        leaveTypes.length > 0
                            ? leaveTypes.filter((lt: { leaveTypeName: any }) => lt.leaveTypeName === obj.leaveType)
                            : [{ leaveTypeEmoticon: '🌴' }];
                    let leaveIcon = '🌴';

                    if (categoryType.length > 0) {
                        leaveIcon =
                            categoryType[0].leaveTypeEmoticon === 'palm_tree'
                                ? '🌴'
                                : categoryType[0].leaveTypeEmoticon;
                    }

                    const statusObj = {
                        startDate: obj.startDate,
                        endDate: obj.endDate,
                        startTime: obj.startTime,
                        endTime: obj.endTime,
                        employeeId: obj.empId,
                        organizationId: obj.orgId,
                        category: obj.leaveType,
                        categoryIcon: leaveIcon,
                    };

                    await fetch('https://4fxm4bwhy0.execute-api.eu-north-1.amazonaws.com/production/updateStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(statusObj),
                    });
                }
            }
        }
        return true;
    } catch (err) {
        return err;
    }
};

export const handleNotif = async (obj: any) => {
    let leaveIds: any[] = [];
    let data = null,
        approverObj = null,
        url = null;
    if (obj.response.length > 0) {
        leaveIds = obj.response.map((r: any) => r.leaveId);
    }

    url =
        'https://sls-cind-dev-teamplustestudgama.azurewebsites.net/api/sendMessageToAll?code=PuzXPMnyQGC-cyJgASP9GkUv4-fib5LZmOSIzDd5uIRhAzFu9Yhm-Q==';

    // url = 'https://w1xa2qdt8d.execute-api.eu-north-1.amazonaws.com/dev/sendApproveDenyMessageToAll';
    if (obj.requestFor === 'msteams') {
        url =
            'https://sls-swec-dev-timeoffsbyteamplusprod.azurewebsites.net/api/sendMessageToAll?code=sFJJXD1_lZ-KMzelSMBSFDxQUpZIFdoFqhlFQglBABFPAzFu3B3R1A==';
        const approver = await getApproverAcc(obj.user, obj.orgId, obj.token);
        if (approver.Employee.timeoffMsBot != null) {
            approverObj = {
                name:
                    approver.Employee.lastName === ''
                        ? approver.Employee.firstName
                        : approver.Employee.firstName + ' ' + approver.Employee.lastName,
                type: 'id',
            };
        } else {
            approverObj = {
                name:
                    approver.Employee.lastName === ''
                        ? approver.Employee.firstName
                        : approver.Employee.firstName + ' ' + approver.Employee.lastName,
                type: 'name',
            };
        }
        data = {
            leaveIds: leaveIds,
            approver: approverObj,
            approverId: obj.user,
            orgId: obj.orgId,
            requestType: obj.requestType,
            prevStatus: obj.prevStatus,
        };
        // } else {
        //     url = 'https://4fxm4bwhy0.execute-api.eu-north-1.amazonaws.com/production/sendMessage';
        //     data = {
        //         leaveIds: leaveIds,
        //         approver: obj.user,
        //         orgId: obj.orgId,
        //         requestType: obj.requestType,
        //         user: localStorage.getItem('slackEmployeeId'),
        //         workspace: localStorage.getItem('slackWorkspaceId'),
        //     };
    }
    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return true;
};

//ms Notification
export const handleMsNotification = async (obj: any) => {
    //approve deny leave
    const info = { empId: obj.empId, actorId: obj.actorId, orgId: obj.orgId, leaveId: obj.leaveId };
    const userData = await getMsApproverInfo(info, obj.token);
    let approverData = null,
        empMsUserIdObj = null,
        approverMsUserId = null,
        msRecipientId = null,
        empMsConversationId = null,
        msChannel = null,
        serviceUrl = null,
        channelServiceUrl = null;
    const empMsUserId = obj.empId;
    if (userData.data.usermanagementdb_organization_employee.length > 0) {
        const employeeInfo = userData.data.usermanagementdb_organization_employee[0]; //info of user who applied leave
        if (employeeInfo.Organization.Teams.length > 0) {
            msChannel = employeeInfo.Organization.Teams[0].msChannelId;
            channelServiceUrl = employeeInfo.Organization.Teams[0].channelServiceUrl.timeoff;
        }
        if (employeeInfo.Employee.timeoffMsBot != null) {
            serviceUrl = employeeInfo.Employee.timeoffMsBot.serviceUrl;
            msRecipientId = employeeInfo.Employee.timeoffMsBot.msRecipientId;
            empMsConversationId = employeeInfo.Employee.timeoffMsBot.msConversationId;
            empMsUserIdObj = {
                name:
                    employeeInfo.Employee.lastName === ''
                        ? employeeInfo.Employee.firstName
                        : employeeInfo.Employee.firstName + ' ' + employeeInfo.Employee.lastName,
                type: 'id',
            };
        } else {
            empMsUserIdObj = {
                name:
                    employeeInfo.Employee.lastName === ''
                        ? employeeInfo.Employee.firstName
                        : employeeInfo.Employee.firstName + ' ' + employeeInfo.Employee.lastName,
                type: 'name',
            };
        }
        //approver's data
        if (userData.data.timeoffdb_Leaves[0].Organization.organization_employees.length > 0) {
            approverData = userData.data.timeoffdb_Leaves[0].Organization.organization_employees[0];
            if (approverData.Employee.timeoffMsBot != null) {
                approverMsUserId = {
                    name:
                        approverData.Employee.lastName === ''
                            ? approverData.Employee.firstName
                            : approverData.Employee.firstName + ' ' + approverData.Employee.lastName,
                    type: 'id',
                };
            } else {
                approverMsUserId = {
                    name:
                        approverData.Employee.lastName === ''
                            ? approverData.Employee.firstName
                            : approverData.Employee.firstName + ' ' + approverData.Employee.lastName,
                    type: 'name',
                };
            }
        }
    }
    let notifObj = null;
    if (empMsUserIdObj === null || approverMsUserId === null) {
        return;
    } else {
        notifObj = {
            channelId: empMsUserId,
            leaveFor: empMsUserIdObj,
            approvername: approverMsUserId,
            approverId: obj.actorId,
            notif_type: obj.notif_type,
            personalConversationId: empMsConversationId,
            conversationId: msChannel,
            serviceUrl: serviceUrl,
            channelServiceUrl: channelServiceUrl,
            recipientId: msRecipientId,
            requestFromWeb: true,
            leaveInfo: {
                leaveId: obj.leaveId,
                startDate: obj.isoString.startTime,
                endDate: obj.isoString.endTime,
            },
        };
        if (obj.notif_type === 'denyRequest') {
            notifObj['prevStatus'] = obj.prevStatus;
        }
    }
    if (notifObj !== null) {
        await fetch(
            'https://sls-swec-dev-timeoffsbyteamplusprod.azurewebsites.net/api/msTeamsNotification?code=BJP5dEMgPDwG7oUZmgiF9mL1Tne6YlmCzuL8lhLYXKmKAzFua1KxfA==',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(notifObj),
            },
        );
    }

    return true;
};

export const handleTimeoffsForMs = async (obj: any) => {
    //add leave notification
    try {
        const allApprovers = await getMsAdminApprovers(
            {
                empId: obj.empId,
                orgId: obj.orgId,
                otherEmp: obj.otherEmp,
            },
            obj.token,
        );
        if (obj.approvalRequired) {
            if (allApprovers.usermanagementdb_organization_employee.length > 0) {
                const approversData = allApprovers.usermanagementdb_organization_employee[0]; //user's info along with all admin and approvers' info
                if (approversData.Organization.organization_employees.length > 0) {
                    //if approver's length > 0
                    const msUser =
                        approversData.Employee.timeoffMsBot != null //employee for whom the leave is applied
                            ? {
                                  name:
                                      approversData.Employee.lastName === ''
                                          ? approversData.Employee.firstName
                                          : approversData.Employee.firstName + ' ' + approversData.Employee.lastName,
                                  type: 'id',
                              }
                            : {
                                  name:
                                      approversData.Employee.lastName === ''
                                          ? approversData.Employee.firstName
                                          : approversData.Employee.firstName + ' ' + approversData.Employee.lastName,
                                  type: 'name',
                              };
                    const otherUserData = allApprovers.usermanagementdb_Organization[0].organization_employees; //info of the user who applied leave on belhalf of othe emp
                    let otherUser: any = null;
                    if (otherUserData.length > 0) {
                        otherUser =
                            otherUserData[0].Employee.timeoffMsBot != null //employee who applied the leave
                                ? {
                                      name:
                                          otherUserData[0].Employee.lastName === ''
                                              ? otherUserData[0].Employee.firstName
                                              : otherUserData[0].Employee.firstName +
                                                ' ' +
                                                otherUserData[0].Employee.lastName,
                                      type: 'id',
                                  }
                                : {
                                      name:
                                          otherUserData[0].Employee.lastName === ''
                                              ? otherUserData[0].Employee.firstName
                                              : otherUserData[0].Employee.firstName +
                                                ' ' +
                                                otherUserData[0].Employee.lastName,
                                      type: 'name',
                                  };
                    }
                    const myApprovers = approversData.Organization.organization_employees;
                    const approverList = myApprovers.map((ap: any) => {
                        return ap;
                    });
                    approverList.map(async (msId: any) => {
                        const objData = {
                            orgId: obj.orgId,
                            channelId: obj.empId,
                            reqType: obj.leaveType,
                            user: msUser,
                            reason: obj.reason,
                            from: obj.isoString.startTime,
                            to: obj.isoString.endTime,
                            leaveId: obj.leaveId,
                            personalConversationId: msId.Employee.timeoffMsBot.msConversationId,
                            recipientId: msId.Employee.timeoffMsBot.msRecipientId,
                            notif_type: 'timeoffRequest',
                            orgName: approversData.Organization.organizationName,
                            otherUser: obj.requestFor === 'self' ? null : otherUser,
                            otherUserId: obj.requestFor === 'self' ? null : obj.otherEmp,
                            serviceUrl: msId.Employee.timeoffMsBot.serviceUrl,
                            requestFromWeb: true,
                        };
                        await fetch(
                            'https://sls-swec-dev-timeoffsbyteamplusprod.azurewebsites.net/api/msTeamsNotification?code=BJP5dEMgPDwG7oUZmgiF9mL1Tne6YlmCzuL8lhLYXKmKAzFua1KxfA==',
                            {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(objData),
                            },
                        );
                    });
                }
            }
        } else {
            if (allApprovers.usermanagementdb_organization_employee.length > 0) {
                const empData = allApprovers.usermanagementdb_organization_employee[0];
                if (empData.Organization.Teams.length > 0) {
                    let msChannel = null,
                        leaveFor = null,
                        msRecipientId = null,
                        serviceUrl = null,
                        msConversationId = null,
                        channelServiceUrl = null;
                    const empMsUserId = obj.empId;
                    msChannel = empData.Organization.Teams[0].msChannelId;
                    channelServiceUrl = empData.Organization.Teams[0].channelServiceUrl.timeoff;
                    if (empData.Employee.timeoffMsBot != null) {
                        serviceUrl = empData.Employee.timeoffMsBot.serviceUrl;
                        msRecipientId = empData.Employee.timeoffMsBot.msRecipientId;
                        msConversationId = empData.Employee.timeoffMsBot.msConversationId;
                        leaveFor = {
                            name:
                                empData.Employee.lastName === ''
                                    ? empData.Employee.firstName
                                    : empData.Employee.firstName + ' ' + empData.Employee.lastName,
                            type: 'id',
                        };
                    } else {
                        leaveFor = {
                            name:
                                empData.Employee.lastName === ''
                                    ? empData.Employee.firstName
                                    : empData.Employee.firstName + ' ' + empData.Employee.lastName,
                            type: 'name',
                        };
                    }
                    const objData = {
                        channelId: empMsUserId,
                        leaveFor: leaveFor,
                        approvername: null,
                        notif_type: 'approveRequest',
                        personalConversationId: msConversationId,
                        recipientId: msRecipientId,
                        requestFromWeb: true,
                        conversationId: msChannel,
                        serviceUrl: serviceUrl,
                        channelServiceUrl: channelServiceUrl,
                        leaveInfo: {
                            leaveId: obj.leaveId,
                            startDate: obj.isoString.startTime,
                            endDate: obj.isoString.endTime,
                        },
                    };
                    await fetch(
                        'https://sls-swec-dev-timeoffsbyteamplusprod.azurewebsites.net/api/msTeamsNotification?code=BJP5dEMgPDwG7oUZmgiF9mL1Tne6YlmCzuL8lhLYXKmKAzFua1KxfA==',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(objData),
                        },
                    );
                }
            }
        }
        return;
    } catch (err) {
        return err;
    }
};

export const handleCancelRequest = async (obj: any) => {
    try {
        const info = { empId: obj.empId, orgId: obj.orgId, leaveId: obj.leaveId };
        const userData = await getUserInfoForCancelRequest(info, obj.token);
        let empMsUserIdObj = null,
            msRecipientId = null,
            empMsConversationId = null,
            msChannel = null,
            serviceUrl = null,
            channelServiceUrl = null;
        const empMsUserId = obj.empId;
        if (userData.data.usermanagementdb_organization_employee.length > 0) {
            if (userData.data.usermanagementdb_organization_employee[0].Organization.Teams.length > 0) {
                const teamsData = userData.data.usermanagementdb_organization_employee[0].Organization.Teams[0];
                msChannel = teamsData.msChannelId;
                channelServiceUrl = teamsData.channelServiceUrl.timeoff;
            }
            const employeeInfo = userData.data.usermanagementdb_organization_employee[0]; //info of user who applied leave
            if (employeeInfo.Employee.timeoffMsBot != null) {
                serviceUrl = employeeInfo.Employee.timeoffMsBot.serviceUrl;
                msRecipientId = employeeInfo.Employee.timeoffMsBot.msRecipientId;
                empMsConversationId = employeeInfo.Employee.timeoffMsBot.msConversationId;
                empMsUserIdObj = {
                    name:
                        employeeInfo.Employee.lastName === ''
                            ? employeeInfo.Employee.firstName
                            : employeeInfo.Employee.firstName + ' ' + employeeInfo.Employee.lastName,
                    type: 'id',
                };
            } else {
                empMsUserIdObj = {
                    name:
                        employeeInfo.Employee.lastName === ''
                            ? employeeInfo.Employee.firstName
                            : employeeInfo.Employee.firstName + ' ' + employeeInfo.Employee.lastName,
                    type: 'name',
                };
            }
        }
        let notifObj = null;
        if (empMsUserIdObj === null) {
            return;
        } else {
            notifObj = {
                channelId: empMsUserId,
                notif_type: obj.notif_type,
                personalConversationId: empMsConversationId,
                conversationId: msChannel,
                appId: config.ms_teams.MICROSOFTTIMEOFFAPPID,
                serviceUrl: serviceUrl,
                channelServiceUrl: channelServiceUrl,
                recipientId: msRecipientId,
                requestFromWeb: true,
                prevStatus: obj.prevStatus,
                leaveInfo: {
                    leaveId: obj.leaveId,
                    startDate: obj.isoString.startTime,
                    endDate: obj.isoString.endTime,
                    category: obj.category,
                    reason: obj.reason,
                },
                leaveFor: empMsUserIdObj,
            };
        }
        if (notifObj != null) {
            await fetch(
                'https://sls-swec-dev-timeoffsbyteamplusprod.azurewebsites.net/api/msTeamsNotification?code=BJP5dEMgPDwG7oUZmgiF9mL1Tne6YlmCzuL8lhLYXKmKAzFua1KxfA==',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(notifObj),
                },
            );
        }
        return;
    } catch (error) {
        return { error: error };
    }
};
