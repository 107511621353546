import React from 'react';
import { Button, Grid, FormLabel } from '@material-ui/core';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import { MuiSelectComponent } from '../ImportHoliday/FormInputs';
import useStyles from './TeammemberStyles';
import MyModal from '../Modal/Modal';

interface Props {
    id?: string;
    title: string;
    bodycontent: any;
    primarybutton: string;
    secondarybutton: string;
    open: boolean;
    toggle: any;
    handleSubmit: any;
}

// export default function ActionModal(props: Props): JSX.Element {
//     return (
//         <div>
//             <Modal isOpen={props.open} toggle={props.toggle}>
//                 <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>

//                 <ModalBody>{props.bodycontent}</ModalBody>
//                 <ModalFooter>
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={props.handleSubmit}
//                         style={{ textTransform: 'capitalize' }}
//                     >
//                         {props.primarybutton}
//                     </Button>
//                     <Button
//                         variant="contained"
//                         color="secondary"
//                         onClick={props.toggle}
//                         style={{ textTransform: 'capitalize' }}
//                     >
//                         {props.secondarybutton}
//                     </Button>
//                 </ModalFooter>
//             </Modal>
//         </div>
//     );
// }

export default function ActionModal(props: Props): JSX.Element {
    const classes = useStyles();
    return (
        <div>
            <MyModal open={props.open} setOpen={props.toggle} onModalClosed={props.toggle}>
                <>
                    <Grid container style={{ padding: '10px 0px' }}>
                        <Grid item xs={12} className={classes.headingModal}>
                            <FormLabel className={classes.modalMainTitle}>{props.title}</FormLabel>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.newModalContent}
                            style={{ display: 'flex', marginBottom: '5px', marginTop: '10px', flexWrap: 'wrap' }}
                        >
                            <p className={classes.modalContent}>
                                {props.bodycontent[0]} <strong>{props.bodycontent[1]}</strong>
                                {props.bodycontent.length > 2 && <span> {props.bodycontent[2]}</span>}
                            </p>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                                marginBottom: '5px',
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.btnOutlined}
                                onClick={props.toggle}
                                style={{ marginRight: '10px' }}
                            >
                                {props.secondarybutton}
                            </Button>
                            <Button
                                id="yesbtn"
                                variant="contained"
                                color="primary"
                                className={classes.modalbtn}
                                onClick={props.handleSubmit}
                            >
                                {props.primarybutton}
                            </Button>
                        </Grid>
                    </Grid>
                </>
            </MyModal>
        </div>
    );
}

// export function MultipleSelectModal(props: Props): JSX.Element {
//     const Channels = ['Engineering', 'Udgama', 'Z-off topic'];
//     const [team, setTeam] = React.useState([]);
//     const classes = useStyles();

//     return (
//         <div>
//             <Modal isOpen={props.open} toggle={props.toggle}>
//                 <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>

//                 <ModalBody>
//                     <Typography className={classes.label1}>{props.bodycontent}</Typography>

//                     <MuiSelectComponent label="Team" value={team} setValue={setTeam} options={Channels} />
//                 </ModalBody>

//                 <ModalFooter>
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={props.handleSubmit}
//                         style={{ textTransform: 'capitalize' }}
//                     >
//                         {props.primarybutton}
//                     </Button>
//                     <Button
//                         variant="contained"
//                         color="secondary"
//                         onClick={props.toggle}
//                         style={{ textTransform: 'capitalize' }}
//                     >
//                         {props.secondarybutton}
//                     </Button>
//                 </ModalFooter>
//             </Modal>
//         </div>
//     );
// }
