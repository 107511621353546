import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button, Hidden, useTheme } from '@material-ui/core';
import { useStyles } from './employeeProfileStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { PopUp } from '../Modal/Modal';
import AuthContext from '../../context/AuthContext';
import { getAllLeavesReports, getLeavesBalanceReport } from '../../helpers/hasuraapi';
import Modal from '../Modal/Modal';
import { CSVLink } from 'react-csv';
import { toAWSDateFormat, DateDifference } from '../../helpers/date';
import { DateField2 } from '../ImportHoliday/FormInputs';
import { FormControl, FormLabel, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';

interface Props {
    backTo?: any;
}

export default function EmployeeHeader(props: Props): JSX.Element {
    const history = useHistory<any>();
    const classes = useStyles();
    const theme = useTheme();
    const store: any = useSelector((store: any) => store.orgEmployees);
    const location = useLocation<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);
    const [type, setType]: any = useState('one');
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [filename, setFilename] = useState<string>('all_timeoff_report.csv');
    const [data, setdata] = useState<any>([]);
    const [headers, setHeaders] = useState<any>([]);
    const [isRun, setIsRun] = useState<any>(null);
    const [leavesCountData, setLeavesCountData] = useState<any>([]);
    const [startDate, setStartDate] = useState<any>(toAWSDateFormat(new Date()));
    const [endDate, setEndDate] = useState<any>(toAWSDateFormat(new Date()));
    const csvLink: any = useRef();
    const mycontext = useContext(AuthContext);
    const subscriptionExpired =
        mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate);

    // const subscriptionExpired = mycontext.subscriptionExpired === 'cancelled' ? true : false;

    const resetSettings = () => {
        setType('one');
        setStartDate(toAWSDateFormat(new Date()));
        setEndDate(toAWSDateFormat(new Date()));
    };

    useEffect(() => {
        (async function() {
            try {
                await leavesCountData.forEach(function(val: any) {
                    val.Employee.organization_employees[0].Organization.LeaveTypes.map((value: any) => {
                        if (value.leaveTypeName === val.leaveType) {
                            if (value.daysPerYear === 0 && value.unlimitedDays === true) {
                                val.leaveAllowed = 'unlimited';
                            } else {
                                val.leaveAllowed = value.daysPerYear;
                            }
                        }
                        return null;
                    });
                    const quotient = Math.floor(val.totalHoursLeave / val.workingHours);
                    const remainder = val.totalHoursLeave % val.workingHours;
                    const str = remainder.toString();
                    const numarray = str.split('.');
                    let a = [];
                    a = numarray;
                    const first2Str = String(parseInt(a[1])).slice(0, 2);
                    const first2Num = Number(first2Str);
                    const int_length = ('' + first2Num).length;
                    let first = first2Num;
                    if (int_length < 2) {
                        first = first2Num * 10;
                    }
                    if (isNaN(Math.trunc((first / 100) * 60))) {
                        val.Minutes = 0;
                    } else {
                        val.Minutes = Math.trunc((first / 100) * 60);
                    }
                    val.Days = quotient;
                    val.Hours = Math.trunc(remainder);
                    val.startDate = startDate;
                    val.endDate = endDate;
                });
                await setdata(leavesCountData);
                if (isRun === null) {
                    setIsRun(false);
                } else if (isRun === false) {
                    await csvLink.current.link.click();
                }
            } catch (e) {
                return e;
            }
        })();
    }, [leavesCountData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDates = (v: any) => {
        setStartDate(v);
        setEndDate(v);
    };

    const onReportClick = (v: any) => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    const exportbtnclicked = async () => {
        const arr: any = [];
        arr.push(mycontext.EmployeeId);
        if (DateDifference(startDate, endDate) < 0) {
            setAlertMessage('Please select a valid date');
            setAlert(true);
        } else {
            if (type === 'one') {
                setFilename('all_timeoff_report.csv');
                const result = await getAllLeavesReports(
                    {
                        employees: arr,
                        orgId: mycontext.organizationId,
                        startDate: startDate,
                        endDate: endDate,
                    },
                    mycontext.token,
                );

                await result.timeoffdb_Leaves.map((val: any, key: any) => {
                    if (val.leaveStatus === 'pending') {
                        return (val.approver = '');
                    } else if (val.leaveStatus === 'denied') {
                        return (val.approver = '');
                    } else {
                        return (val.approver = val.approverId
                            ? store.employees.filter((e: any) => e.employeeId === val.approverId)[0]?.Employee.firstName
                            : 'Auto Approved');
                    }
                });

                setHeaders([
                    { label: 'Firstname', key: 'Employee.firstName' },
                    { label: 'Lastname', key: 'Employee.lastName' },
                    { label: 'Start Date', key: 'startDate' },
                    { label: 'End Date', key: 'endDate' },
                    { label: 'Start Time', key: 'startTime' },
                    { label: 'End Time', key: 'endTime' },
                    { label: 'Category', key: 'category' },
                    { label: 'Leave Count', key: 'leaveCount' },
                    { label: 'Leave Status', key: 'leaveStatus' },
                    { label: 'Denied Reason', key: 'denialReason' },
                    { label: 'No. of hours', key: 'noOfHours' },
                    { label: 'Reason', key: 'reason' },
                    { label: 'Approved By', key: 'approver' },
                ]);
                setdata(result.timeoffdb_Leaves);
                csvLink.current.link.click();
            } else {
                setFilename('timeoff_balance_report.csv');
                const response = await getLeavesBalanceReport(
                    {
                        employees: arr,
                        orgId: mycontext.organizationId,
                        startDate: startDate,
                        endDate: endDate,
                    },
                    mycontext.token,
                );
                setHeaders([
                    { label: 'Firstname', key: 'Employee.firstName' },
                    { label: 'Lastname', key: 'Employee.lastName' },
                    { label: 'From', key: 'startDate' },
                    { label: 'To', key: 'endDate' },
                    { label: 'Leave Type', key: 'leaveType' },
                    { label: 'No. of Days Leave Taken', key: 'Days' },
                    { label: 'No. of Hours Leave Taken', key: 'Hours' },
                    { label: 'No. of Minutes Leave Taken', key: 'Minutes' },
                    { label: 'Number of days allowed to take leaves as per leave type', key: 'leaveAllowed' },
                ]);
                setLeavesCountData(response.timeoffdb_get_totalhoursbydate_org);
            }
        }
    };

    return (
        <div className={classes.headerStyle} style={{ padding: '10px', height: '45px' }}>
            <Modal open={open} setOpen={setOpen} onModalClosed={handleCloseModal} title="Reports">
                <div>
                    <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity="error" />
                    {subscriptionExpired && (
                        <FormLabel className={classes.formLabelStyle} style={{ color: 'red' }}>
                            Subscription expired, Please contact your admin !
                        </FormLabel>
                    )}
                    <FormLabel className={classes.formLabelStyle}>Report Type</FormLabel>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                            id="categorySelect"
                            value={type}
                            className={classes.inputSelectStyleOther}
                            onChange={(e: any) => setType(e.target.value)}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: { marginTop: -10 },
                                },
                            }}
                        >
                            <MenuItem value="one">All Time-offs report</MenuItem>

                            <MenuItem value="two">Time-off balance report</MenuItem>
                        </Select>
                    </FormControl>
                    <div className={classes.fieldDivDateTime}>
                        <div>
                            <FormLabel className={classes.formLabelStyle}>From</FormLabel>
                            <FormControl className={classes.formControl}>
                                <DateField2
                                    date={startDate}
                                    setDate={setStartDate}
                                    id="startdatefield"
                                    handleDates={handleDates}
                                />
                            </FormControl>
                        </div>
                        <div className={classes.mobileToPart}>
                            <FormLabel className={classes.formLabelStyle}>To</FormLabel>
                            <FormControl className={classes.formControl}>
                                <DateField2 date={endDate} setDate={setEndDate} id="enddatefield" />
                            </FormControl>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            className={`${classes.buttonStyle} ${classes.resetButton}`}
                            onClick={() => resetSettings()}
                            // disabled={isDisabled}
                            id="settingsReset"
                            style={{ border: `1px solid ${theme.palette.text.secondary}` }}
                            disabled={subscriptionExpired}
                        >
                            Reset
                        </Button>
                        <Button
                            id="submitBtn"
                            className={classes.btn2}
                            variant="contained"
                            color="primary"
                            disableRipple={true}
                            onClick={exportbtnclicked}
                            disabled={subscriptionExpired}
                        >
                            Export
                        </Button>
                    </div>

                    <CSVLink headers={headers} data={data} filename={filename} target="_blank" ref={csvLink}></CSVLink>
                </div>
            </Modal>
            <Grid item xs={12} className={classes.commonStyle2}>
                {/* <Hidden mdDown> */}
                {location.state?.userLink ? null : (
                    <Grid item xs={1} className={classes.commonStyle3} style={{ maxWidth: 'fit-content' }}>
                        <ArrowBackIcon
                            className={classes.backArrowStyle}
                            onClick={() => history.push(`/${props.backTo}`)}
                        />
                    </Grid>
                )}

                {/* </Hidden> */}
                <Grid item xs={12} lg={11} className={classes.commonStyle3}>
                    <Typography className={classes.headerTitle}>Employee Profile</Typography>
                </Grid>
                {localStorage.getItem('employeeid') === mycontext.userProfile && !mycontext.isAdmin && (
                    <div>
                        <Hidden mdDown>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.btnFixed}
                                // endIcon={<Icon icon="heroicons-solid:folder-download" />}
                                onClick={onReportClick}
                            >
                                <Icon icon="heroicons-outline:document-report" width="20" />
                                &nbsp; Reports
                            </Button>
                        </Hidden>
                        <Hidden lgUp>
                            <Icon
                                icon="heroicons-outline:document-report"
                                className={classes.icon}
                                onClick={onReportClick}
                            />
                        </Hidden>
                    </div>
                )}
            </Grid>
        </div>
    );
}
