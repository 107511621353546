// export const newurl = 'https://udyamo-teamplus.hasura.app/v1/graphql';

export const newurl = 'https://tsgkobrggbcrjl2ffuell27v24.appsync-api.us-east-1.amazonaws.com/graphql';
export const apikey = 'da2-nefcoprzeffqff5rrkecjgvpn4';

export const getEmployee = async (id: any, token: any) => {
    try {
        const query = {
            query: `query {
                getEmployee(EmployeeId: "${id}") {
                    EmployeeId,
                    firstname,
                    lastname,
                    email,
                    joining,
                    teamname,
                    statustype,
                    isapprover,
                    image,
                    isAdmin,
                    sickdaysused,
                    sickdaystotal,
                    gender,
                    username
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/graphql',
                'Cache-Control': 'no-cache',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.getEmployee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.getEmployee;
    } catch (err) {
        return { error: err };
    }
};

export const createEmployee = async (obj: any) => {
    try {
        const query = {
            query: `mutation {
                createEmployee(createEmployeeInput:{
                    EmployeeId: "${obj.id}"
                    firstname: "${obj.firstname}"
                    lastname: "${obj.lastname}"
                    email: "${obj.email}"
                    password: "${obj.password}"
                    gender: "${obj.gender}"
                    joining: "${obj.joining}"
                    isAdmin: "${obj.isAdmin}"
                    teamname: "${obj.teamname}"
                    username: "${obj.username}",
                    statustype: "available"
                    isapprover: "${obj.isapprover}"
                    unlimitedvacationpolicy: "no"
                    allowdefaultteamleaves:"yes"
                    activecustomdays:"no"
                    deactivate:"no"
                    sickdaysused:0
                    sickdaystotal: 10
                    image: "${obj.image}"
                  }){
                    EmployeeId
                  }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/graphql',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createEmployee;
    } catch (err) {
        return { error: err };
    }
};

export const getTeamMembers = async (token: any) => {
    try {
        const query = {
            query: `{
                listEmployees {
                        EmployeeId
                        image
                        firstname
                        lastname
                        teamname
                        statustype
                        isapprover
                        isAdmin
                    }
                }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.listEmployees;
    } catch (err) {
        return { error: err };
    }
};

export const getEmployeeDetail = async (id: any, token: any) => {
    try {
        const query = {
            query: `query {
                getEmployee(EmployeeId: "${id}") {
                    EmployeeId
                    firstname
                    lastname
                    email
                    image
                    joining
                    statustype
                    sickdaystotal
                    teamname
                    username
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (!jsonData.errors) {
            return jsonData.data.getEmployee;
        } else {
            const error = new Error(`Error: ${jsonData.errors[0].message}`);
            throw error;
        }
    } catch (err) {
        return err;
    }
};

export const updateEmployeeName = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateEmployee(updateEmployeeInput: {
                    EmployeeId: "${obj.EmployeeId}"
                    firstname: "${obj.firstname}"
                    lastname: "${obj.lastname}"
                }) {
                    EmployeeId
                    firstname
                    lastname
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateEmployee;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationEmployees = async (organizationId: any, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganization_employee(organizationId: "${organizationId}"){
                    EmployeeId
                    firstname
                    lastname
                    image
                    statustype
                    teamname
                    isAdmin
                    isapprover
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        if (data.status !== 200) {
            return { error: 'Session Expired!' };
        }
        const jsonData = await data.json();
        return jsonData.data.getOrganization_employee;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationEmployeesTeams = async (organizationId: any, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganizationEmployeeTeam(organizationId: "${organizationId}"){
                    employee {
                        EmployeeId
                        firstname
                        lastname
                        image
                        statustype
                        isAdmin
                        isapprover
                        username
                        joining
                    }
                    team {
                        teamId
                        name
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error(jsonData.errors[0].message);
        }
        return jsonData.data.getOrganizationEmployeeTeam;
    } catch (err) {
        return { Error: err.message };
    }
};

export const getEmployeeOrganization = async (employeeId: any, token: any) => {
    try {
        const query = {
            query: `query {
                getEmployee_organization(employeeId: "${employeeId}"){
                    organizationId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getEmployee_organization;
    } catch (err) {
        return { error: err };
    }
};

// Add leave page
export const createLeaves = async (row: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                createLeaves(createLeavesInput: {
                    category: "${row.category}",
                    reason: "${row.reason}",
                    start_date: "${row.startdate}",
                    end_date: "${row.enddate}",
                    Time_of_Leave: "${row.TimeofLeave}",
                    approver: "${row.approver}",
                    status: "${row.status}"
                    employeeId: "${row.employeeId}",
                }){
                    employeeId
                    LeavesId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createLeaves;
    } catch (err) {
        return { error: err };
    }
};

export const updateLeaves = async (row: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateLeaves(updateLeavesInput: {
                    LeavesId: "${row.LeavesId}",
                    category: "${row.category}",
                    reason: "${row.reason}",
                    start_date: "${row.start_date}",
                    end_date: "${row.end_date}",
                    Time_of_Leave: "${row.TimeofLeave}",
                    approver: "${row.approver}",
                    status: "${row.status}"
                    employeeId: "${row.employeeId}",
                }){
                    employeeId
                    LeavesId
                    start_date
                    end_date
                    status
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateLeaves;
    } catch (err) {
        return { error: err };
    }
};

export const deleteLeaves = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                deleteLeaves(LeavesId: "${id}"){
                    employeeId
                    LeavesId
                    start_date
                    end_date
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.deleteLeaves;
    } catch (err) {
        return { error: err };
    }
};

// Add Team
export const addTeam = async (team: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                    createTeam(createTeamInput: {
                      name: "${team.name}",
                      createdAt: "${team.createdAt}",
                      orgId: "${team.organizationId}"
                    }){
                      teamId
                      name
                      createdAt
                      orgId
                    }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createTeam;
    } catch (err) {
        return err;
    }
};

export const addDefaultTeam = async (team: any) => {
    try {
        const query = {
            query: `mutation {
                    createTeam(createTeamInput: {
                      name: "${team.name}",
                      createdAt: "${team.createdAt}",
                      orgId: "${team.orgId}"
                    }){
                      teamId
                      name
                      createdAt
                      orgId
                    }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createTeam;
    } catch (err) {
        return err;
    }
};

export const addDefaultTeamMembers = async (myteam: any) => {
    try {
        const query = {
            query: `mutation {
                createTeamMembers(createTeamMemberInput: {
                    employeeId: "${myteam.employeeId}",
                    teamId: "${myteam.teamId}"
                })
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createTeamMembers;
    } catch (err) {
        return err;
    }
};

export const addDefaultTeamApprover = async (obj: any) => {
    try {
        const query = {
            query: `mutation {
                createTeamApprovers(createTeamApproverInput: {
                    employeeId: "${obj.employeeId}",
                    teamId: "${obj.teamId}"
                })
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createTeamApprovers;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationAllTeams = async (organizationId: any) => {
    try {
        const query = {
            query: `query {
                getOrganization_Teams(organizationId: "${organizationId}") {
                    teamId
                    name
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getOrganization_Teams;
    } catch (err) {
        return { error: err };
    }
};

// Add Team Members to the Team
// export const addTeamMembers = async (team: any, token: any) => {
//     team = JSON.stringify(team);
//     team = team.replace('[', '');
//     team = team.replace(']', '');
//     while (length !== 0) {
//         team = team.replace('"teamId"', 'teamId');
//         // team = team.replace('[', '');
//         team = team.replace('"employeeId"', 'employeeId');
//         length = length - 1;
//     }

//     try {
//         const query = {
//             query: `mutation {
//                 createTeamMembers(createTeamMemberInput: [${team}])
//             }`,
//         };
//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await data.json();
//         return jsonData.data.createTeamMembers;
//     } catch (err) {
//         return err;
//     }
// };
//Add Approvers to the Team
// export const addTeamApprovers = async (teamdata: any, token: any) => {
//     teamdata = JSON.stringify(teamdata);
//     teamdata = teamdata.replace('[', '');
//     teamdata = teamdata.replace(']', '');
//     while (length !== 0) {
//         teamdata = teamdata.replace('"teamId"', 'teamId');
//         // teamdata = teamdata.replace('[', '');
//         teamdata = teamdata.replace('"employeeId"', 'employeeId');
//         length = length - 1;
//     }
//     try {
//         const query = {
//             query: `mutation {
//                 createTeamApprovers(createTeamApproverInput: [${teamdata}])
//             }`,
//         };

//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await data.json();
//         return jsonData.data.createTeamApprovers;
//     } catch (err) {
//         return { error: err };
//     }
// };

export const updateTeam = async (team: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateTeam(updateTeamInput: {
                    teamId: "${team.teamId}",
                    name: "${team.name}",
                    orgId: "${team.organizationId}"
                }) {
                    name
                    orgId
                    teamId
                  }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateTeam;
    } catch (err) {
        return { error: err };
    }
};

export const updateTeamMembers = async (team: any, token: any) => {
    team = JSON.stringify(team);
    team = team.replace('[', '');
    team = team.replace(']', '');
    team = team.replaceAll('"teamId"', 'teamId');
    team = team.replace('[', '');
    team = team.replaceAll('"employeeId"', 'employeeId');
    try {
        const query = {
            query: `mutation {
                updateTeamMembers(updateTeamMemberInput: [${team}])
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateTeamMembers;
    } catch (err) {
        return err;
    }
};

export const updateTeamApprovers = async (teamdata: any, token: any) => {
    teamdata = JSON.stringify(teamdata);
    teamdata = teamdata.replace('[', '');
    teamdata = teamdata.replace(']', '');
    teamdata = teamdata.replaceAll('"teamId"', 'teamId');
    teamdata = teamdata.replace('[', '');
    teamdata = teamdata.replaceAll('"employeeId"', 'employeeId');
    try {
        const query = {
            query: `mutation {
                updateTeamApprovers(updateTeamApproverInput: [${teamdata}])
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateTeamApprovers;
    } catch (err) {
        return err;
    }
};

export const deleteTeam = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation{
                deleteTeam(teamId:"${id}")
              }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.deleteTeam;
    } catch (err) {
        return { error: err };
    }
};

//view employees of team
export const getTeamEmployeeMembers = async (teamId: any, token: any) => {
    try {
        const query = {
            query: `query {
                    getTeam_employee(teamId: "${teamId}"){
                     EmployeeId
                     firstname
                     lastname
                     image
                   }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getTeam_employee;
    } catch (err) {
        return { error: err };
    }
};
// view all teams
export const getOrganizationTeams = async (organizationId: any, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganization_Team_employee_approver(organizationId: "${organizationId}"){
                    team{
                      teamId
                      name
                      createdAt
                    }
                    member{
                      image
                      firstname
                      lastname
                    }
                    approver{
                      image
                      firstname
                      lastname
                    }
                  }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getOrganization_Team_employee_approver;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationSettingId = async (organizationId: any, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganization_GeneralSettings(organizationId: "${organizationId}"){
                    OrganizationId
                    month
                    date
                    dateFormat
                    daysExpire
                    settingId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getOrganization_GeneralSettings[0];
    } catch (err) {
        return { error: err };
    }
};

export const getOrgTeam = async (teamId: any, token: any) => {
    try {
        const query = {
            query: `query {
                getTeam_Employee_Approver(teamId: "${teamId}"){
                    team{
                      teamId
                      name
                      createdAt
                    }
                    member{
                      firstname
                      lastname
                      image
                    }
                    approver{
                      firstname
                      lastname
                      image
                    }
                  }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getTeam_Employee_Approver;
    } catch (err) {
        return { error: err };
    }
};

export const getHolidays = async (organizationId: any, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganization_Holiday(organizationId: "${organizationId}"){
                    holidayId
                    holidayName
                    date
                    start_date
                    end_date
                    year
                    isMultidayHoliday
                    organizationId
                  }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getOrganization_Holiday;
    } catch (err) {
        return { error: err };
    }
};

export const createOrganization = async (obj: any) => {
    try {
        const query = {
            query: `mutation {
                createOrganization(createOrganizationInput: {
                    organizationName: "${obj.organizationName}",
                    creator: "${obj.creator}",
                }) {
                    OrganizationId
                    organizationName
                    creator
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createOrganization;
    } catch (err) {
        return { error: err };
    }
};

export const createOrganizationEmployee = async (obj: any) => {
    try {
        const query = {
            query: `mutation {
                createOrganization_employee(createOrganization_employeeInput: {
                    organizationId: "${obj.organizationId}",
                    employeeId: "${obj.employeeId}",
                }) {
                    organizationId
                    employeeId {
                        EmployeeId
                    }
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createOrganization_employee;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationWorkweek = async (organizationId: string, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganization_workweek(organizationId: "${organizationId}"){
                    workweekId
                    sunday
                    monday
                    tuesday
                    wednesday
                    thursday
                    friday
                    saturday
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getOrganization_workweek;
    } catch (err) {
        return { error: err };
    }
};

export const createWorkweek = async (obj: any) => {
    try {
        const query = {
            query: `mutation {
                createWorkweek(createWorkweekInput: {
                    sunday: "${obj.sunday}",
                    monday: "${obj.monday}",
                    tuesday: "${obj.tuesday}",
                    wednesday: "${obj.wednesday}",
                    thursday: "${obj.thursday}",
                    friday: "${obj.friday}",
                    saturday: "${obj.saturday}",
                }){
                    workweekId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createWorkweek;
    } catch (err) {
        return { error: err };
    }
};

export const updateWorkweek = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateWorkweek(updateWorkweekInput: {
                    workweekId: "${obj.workweekId}",
                    sunday: "${obj.sunday}",
                    monday: "${obj.monday}",
                    tuesday: "${obj.tuesday}",
                    wednesday: "${obj.wednesday}",
                    thursday: "${obj.thursday}",
                    friday: "${obj.friday}",
                    saturday: "${obj.saturday}",
                }){
                    workweekId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateWorkweek;
    } catch (err) {
        return { error: err };
    }
};

export const createOrganizationWorkweek = async (obj: any) => {
    try {
        const query = {
            query: `mutation {
                createOrganization_workweek(createOrganization_workweekInput: {
                    organizationId: "${obj.organizationId}",
                    workweekId: "${obj.workweekId}",
                }) {
                    organizationId
                    workweekId {
                        workweekId
                    }
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createOrganization_workweek;
    } catch (err) {
        return { error: err };
    }
};

export const createGeneralSettings = async (obj: any) => {
    try {
        const query = {
            query: `mutation {
                createGeneralSettings(createGeneralSettingsInput: {
                    OrganizationId: "${obj.OrganizationId}",
                    dateFormat: "${obj.dateFormat}",
                    date: "${obj.date}",
                    month: "${obj.month}",
                    transfer: "${obj.transfer}",
                    daysExpire: "${obj.daysExpire}"
                }) {
                    OrganizationId
                    transfer
                    settingId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createGeneralSettings;
    } catch (err) {
        return { error: err };
    }
};

export const updateGeneralSettings = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateGeneralSettings(updateGeneralSettingsInput: {
                    settingId: "${obj.settingId}",
                    OrganizationId: "${obj.organizationId}",
                    dateFormat: "${obj.dateFormat}"
                }) {
                    settingId
                    OrganizationId
                    dateFormat
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateGeneralSettings;
    } catch (err) {
        return { error: err };
    }
};

export const createLeaveType = async (obj: any) => {
    try {
        const query = {
            query: `mutation {
                createLeaveType(createLeaveTypeInput: {
                    leaveTypeName: "${obj.leaveTypeName}",
                    organizationId: "${obj.organizationId}",
                    type: "${obj.type}",
                    active: "${obj.active}",
                    daysPerYear: "${obj.daysPerYear}",
                    approvalRequired: "${obj.approvalRequired}",
                    reasonRequired: "${obj.reasonRequired}",
                    allowHalfDay: "${obj.allowHalfDay}",
                }) {
                    LeaveTypeId
                    leaveTypeName
                    organizationId
                    type
                    active
                    daysPerYear
                    approvalRequired
                    reasonRequired
                    allowHalfDay
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createLeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const updateLeaveType = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateLeaveType(updateLeaveTypeInput: {
                    LeaveTypeId: "${obj.LeaveTypeId}",
                    leaveTypeName: "${obj.leaveTypeName}",
                    organizationId: "${obj.organizationId}",
                    type: "${obj.type}",
                    active: "${obj.active}",
                    daysPerYear: "${obj.daysPerYear}",
                    approvalRequired: "${obj.approvalRequired}",
                    reasonRequired: "${obj.reasonRequired}",
                    allowHalfDay: "${obj.allowHalfDay}"
                }) {
                    LeaveTypeId
                    leaveTypeName
                    organizationId
                    type
                    active
                    daysPerYear
                    approvalRequired
                    reasonRequired
                    allowHalfDay
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateLeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const deleteLeaveType = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                deleteLeaveType(LeaveTypeId: "${id}") {
                    LeaveTypeId
                    leaveTypeName
                    organizationId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.deleteLeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationLeaveType = async (id: any, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganization_LeaveType(organizationId: "${id}") {
                    LeaveTypeId
                    leaveTypeName
                    organizationId
                    active
                    approvalRequired
                    reasonRequired
                    allowHalfDay
                    daysPerYear
                    type
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Organization not found!');
        }
        if (jsonData.data.getOrganization_LeaveType === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.getOrganization_LeaveType;
    } catch (err) {
        return { error: err };
    }
    // return jsonData.data.getOrganization_LeaveType;
    // } catch (err) {
    //     return { error: err };
};

export const getOrganization = async (id: any) => {
    try {
        const query = {
            query: `query {
                getOrganization(OrganizationId: "${id}") {
                    organizationName,
                    OrganizationId,
                    creator,
                    webhookURL
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getOrganization;
    } catch (err) {
        return { error: err };
    }
};

export const sendEmail = async (obj: any) => {
    try {
        const query = {
            query: `mutation {
                createEmail(
                    from: "${obj.from}",
                    to: ${JSON.stringify(obj.emails)},
                    message: "${obj.message}",
                )
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};

export const getEmployeeLeaves = async (id: any, token: any) => {
    try {
        const query = {
            query: `query {
                getEmployee_leaves(employeeId: "${id}") {
                    LeavesId
                    category
                    reason
                    start_date
                    end_date
                    Time_of_Leave
                    approver
                    status
                    employeeId
                    badgename
                    LeaveCount
                    appshort
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error(jsonData.errors[0].message);
        }
        return jsonData.data.getEmployee_leaves;
    } catch (err) {
        return { Error: err.message };
    }
};

export const getOrganizationEmployeeLeaves = async (id: any, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganization_Employee_Leaves_Teams(OrganizationId: "${id}") {
                    employee {
                        EmployeeId
                        email
                        firstname
                        image
                        isAdmin
                        isapprover
                        lastname
                        teamname
                    }
                    leaves {
                        LeavesId
                        Time_of_Leave
                        approver
                        category
                        employeeId
                        end_date
                        start_date
                        status
                        reason
                    }
                    organization {
                        organizationName
                    }
                    teams {
                        teamId
                        name
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (data.status !== 200) {
            throw new Error('Session Expired!');
        }
        return jsonData.data.getOrganization_Employee_Leaves_Teams;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationEmployeeLeavesForAdmin = async (id: any, token: any) => {
    try {
        const query = {
            query: `query {
                  getOrganizationEmployeeLeavesForAdmin(OrganizationId: "${id}") {
                    employee {
                        EmployeeId
                        email
                        firstname
                        image
                        isAdmin
                        isapprover
                        lastname
                        teamname
                    }
                    leaves {
                        LeavesId
                        Time_of_Leave
                        approver
                        category
                        employeeId
                        end_date
                        start_date
                        status
                        reason
                    }
                    organization {
                        organizationName
                    }
                    teams {
                        teamId
                        name
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error(jsonData.errors[0].message);
        }
        return jsonData.data.getOrganizationEmployeeLeavesForAdmin;
    } catch (err) {
        return { Error: err.message };
    }
};

export const getOrganizationEmployeeLeavesForApprover = async (id: any, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganizationEmployeeLeavesForApprover(OrganizationId: "${id}") {
                    employee {
                        EmployeeId
                        email
                        firstname
                        image
                        isAdmin
                        isapprover
                        lastname
                        teamname
                    }
                    leaves {
                        LeavesId
                        Time_of_Leave
                        approver
                        category
                        employeeId
                        end_date
                        start_date
                        status
                        reason
                    }
                    organization {
                        organizationName
                    }
                    teams {
                        teamId
                        name
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error(jsonData.errors[0].message);
        }
        return jsonData.data.getOrganizationEmployeeLeavesForApprover;
    } catch (err) {
        return { Error: err.message };
    }
};

export const updateEmployeeType = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateEmployee(updateEmployeeInput: {
                    EmployeeId: "${obj.EmployeeId}"
                    isAdmin: "${obj.isAdmin}"
                }) {
                    EmployeeId
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateEmployee;
    } catch (err) {
        return { error: err };
    }
};

export const updateApproverType = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateEmployee(updateEmployeeInput: {
                    EmployeeId: "${obj.EmployeeId}"
                    isapprover: "${obj.isApprover}"
                }) {
                    EmployeeId
                    isapprover
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateEmployee;
    } catch (err) {
        return { error: err };
    }
};

export const updateLeaveCount = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateEmployee(updateEmployeeInput: {
                    EmployeeId: "${obj.EmployeeId}"
                }) {
                    EmployeeId
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateEmployee;
    } catch (err) {
        return { error: err };
    }
};

export const getLeave = async (id: any, token: any) => {
    try {
        const query = {
            query: `{
            getLeaves(LeavesId: "${id}") {
                start_date
                end_date
              }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getLeaves;
    } catch (err) {
        return { error: err };
    }
};

export const listHolidays = async (token: any) => {
    try {
        const query = {
            query: `query {
                listHolidaysData {
                    holidayDataId
                    holidayDataName
                    holidayDataDate
                    holidayDataDay
                    holidayDataYear
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error(jsonData.errors[0].message);
        }
        return jsonData.data.listHolidaysData;
    } catch (err) {
        return { Error: err };
    }
};

export const getOrganizationHoliday = async (id: string, token: any) => {
    try {
        const query = {
            query: `query {
                getOrganization_Holiday(organizationId: "${id}"){
                    year
                    organizationId
                    day
                    date
                    holidayId
                    holidayName
                    start_date
                    end_date
                    isMultidayHoliday
                    isHolidayforallTeams
                  }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error(jsonData.errors[0].message);
        }
        return jsonData.data.getOrganization_Holiday;
    } catch (err) {
        return { Error: err.message };
    }
};

export const deleteHoliday = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                deleteHoliday(holidayId: "${id}"){
                    holidayId
                  }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.deleteHoliday;
    } catch (err) {
        return { error: err };
    }
};

export const createHoliday = async (holiday: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                createHoliday(createHolidayInput: {
                    holidayName: "${holiday.holidayName}",
                    date: "${holiday.date}",
                    year: "${holiday.year}",
                    start_date: "${holiday.startDate}",
                    end_date: "${holiday.endDate}",
                    isMultidayHoliday: "${holiday.isMultidayHoliday}",
                    organizationId: "${holiday.organizationId}",
                    day: "${holiday.day}"
                }){
                    holidayId
                    date
                    year
                    start_date
                    end_date
                    isMultidayHoliday
                    organizationId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createHoliday;
    } catch (err) {
        return { error: err };
    }
};

//Edit Holiday
export const updateHoliday = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                updateHoliday(updateHolidayInput: {
                    holidayId: "${obj.holidayId}",
                    holidayName: "${obj.holidayName}",
                    date: "${obj.date}",
                    year: "${obj.year}",
                    day: "${obj.day}",
                    start_date: "${obj.startDate}",
                    end_date: "${obj.endDate}",
                    isMultidayHoliday: "${obj.isMultidayHoliday}",
                    organizationId: "${obj.organizationId}"
                }){
                    holidayId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.updateHoliday;
    } catch (err) {
        return { error: err };
    }
};

export const importHoliday = async (holiday: any, token: any) => {
    let myholiday: string = JSON.stringify(holiday);
    let length: number = holiday.length;
    myholiday = myholiday.replace('[', '');
    myholiday = myholiday.replace(']', '');
    while (length !== 0) {
        myholiday = myholiday.replace('"organizationId"', 'organizationId');
        myholiday = myholiday.replace('"holidayName"', 'holidayName');
        myholiday = myholiday.replace('"day"', 'day');
        myholiday = myholiday.replace('"date"', 'date');
        myholiday = myholiday.replace('"year"', 'year');
        myholiday = myholiday.replace('"startDate"', 'start_date');
        myholiday = myholiday.replace('"endDate"', 'end_date');
        myholiday = myholiday.replace('"isMultidayHoliday"', 'isMultidayHoliday');
        length = length - 1;
    }
    try {
        const query = {
            query: `mutation {
                    createImportHoliday(createHolidayInput: [${myholiday}])
                }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createImportHoliday;
    } catch (err) {
        return { error: err };
    }
};

export const addTeamMembers = async (myteam: any, token: any) => {
    let team: any = JSON.stringify(myteam);
    let length: number = team.length;
    team = team.replace('[', '');
    team = team.replace(']', '');
    while (length !== 0) {
        team = team.replace('"teamId"', 'teamId');
        // team = team.replace('[', '');
        team = team.replace('"employeeId"', 'employeeId');
        length = length - 1;
    }

    try {
        const query = {
            query: `mutation {
                createTeamMembers(createTeamMemberInput: [${team}])
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createTeamMembers;
    } catch (err) {
        return err;
    }
};
//Add Approvers to the Team
export const addTeamApprovers = async (teamdata1: any, token: any) => {
    let teamdata = JSON.stringify(teamdata1);
    let length: number = teamdata.length;
    teamdata = teamdata.replace('[', '');
    teamdata = teamdata.replace(']', '');
    while (length !== 0) {
        teamdata = teamdata.replace('"teamId"', 'teamId');
        // teamdata = teamdata.replace('[', '');
        teamdata = teamdata.replace('"employeeId"', 'employeeId');
        length = length - 1;
    }
    try {
        const query = {
            query: `mutation {
                createTeamApprovers(createTeamApproverInput: [${teamdata}])
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.createTeamApprovers;
    } catch (err) {
        return { error: err };
    }
};

export const getAllTeamEmployee = async (id: string, token: any) => {
    try {
        const query = {
            query: `query {
                getAllTeam_employee(employeeId: "${id}"){
                    teamId
                    name
                  }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getAllTeam_employee;
    } catch (err) {
        return { error: err };
    }
};

export const uploadImage = async (id: string, image: string, token: any) => {
    try {
        const query = {
            query: `mutation {
                uploadImage(EmployeeId: "${id}",image: "${image}")
                {
                  EmployeeId
                  image
                }
              }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.uploadImage.image;
    } catch (err) {
        return { error: err };
    }
};

export const getImage = async (id: string, token: any) => {
    try {
        const query = {
            query: `query{
                getImage(EmployeeId:"2bee65d2-17e1-4e31-88ad-3b7849831ab6")
              }`,
        };
        const data = await fetch('https://kntmwifkpzepne5c2imrsgvmfe.appsync-api.us-east-1.amazonaws.com/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'eyJraWQiOiIrNllLbE0yQ3ZDZ2tEVmZqY3Q3S0RFbVdGYThKQlFMXC9PaXlZVmkwQnhqRT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIyYmVlNjVkMi0xN2UxLTRlMzEtODhhZC0zYjc4NDk4MzFhYjYiLCJldmVudF9pZCI6IjM0MmRkN2FmLTUwOTEtNDNmMC1hM2YyLTJiNGMwMzIzNzcxZiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MDUwODY3OTYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3lSUHQ0S2dLdyIsImV4cCI6MTYwNTA5MDM5NiwiaWF0IjoxNjA1MDg2Nzk3LCJqdGkiOiI1MDk5Y2MzOS05MjI4LTQ1OTktYWZmZS1kNzY5YTRlODRmMTAiLCJjbGllbnRfaWQiOiIyMzBoa3BncmdjNzdsOTYwbGJpcWVkOXM0YyIsInVzZXJuYW1lIjoiYW5pa2V0In0.aNMHkqFeZCbJVziKptHYZ3uVBf1Sy9o337XN-_mBp2zkS0nekCS5PMJRz6NWCOC16_e4S-Z7xfy6JzCqHpllGSvi6fhJW48mKpt6lou-eJSbNZdLg7ArsKS2Tr16Behb07i4BzUV_S9PpkYj1DxAIMzEFDqxoLaAA51d2n837BYPf4Ick3ShibccKCedQaiFSdTv8UFWK9TWYBjclmN70j12J3Fy8lxE4k5CQPogMEJqWGcFDWBKMaMMBdbXujVVrIXNDWgGYbt43dH6linj4CTf7NNZ0GQLcq2GhRTZoXaUKNgQp4UVWCUhX2RTCmQFvmH8YQXPQlK1uDrAzmChMg',
                // 'x-api-key': 'da2-52ga3q3erzebhh3rgla7rd7hgy',
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getImage;
    } catch (err) {
        return { error: err };
    }
};

export const getLeaveTypeCount = async (id: string, token: any) => {
    try {
        const query = {
            query: ` query{
                    getLeaveTypeCount(EmployeeId:"${id}"){
                      category
                      status
                      employeeId
                      count
                      daysPerYear
                      remainingLeaves
                    }
                }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.getLeaveTypeCount;
    } catch (err) {
        return { error: err };
    }
};

export const deleteEmployee = async (id: any) => {
    try {
        const query = {
            query: `mutation {
                deleteEmployee(EmployeeId: "${id}"){
                    EmployeeId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.deleteEmployee;
    } catch (err) {
        return { error: err };
    }
};

export const deleteCognitoUser = async (username: string) => {
    try {
        const query = {
            query: `mutation {
                deleteCognitoUser(username: "${username}")
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.deleteEmployee;
    } catch (err) {
        return { error: err };
    }
};

export const updateOrganization = async (url: any, orgID: any, token: any) => {
    try {
        const query = {
            query: `mutation{
                updateOrganization(updateOrganizationInput:{
                  OrganizationId:"${orgID}",
                  webhookURL:"${url}"
                }){
                  OrganizationId
                  webhookURL
                }
              }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData;
    } catch (err) {
        return { error: err };
    }
};

export const checkSESForEmail = async (email: string) => {
    try {
        const query = {
            query: `query{
                verifySesEmail(email:"${email}")
              }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apikey,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.verifySesEmail;
    } catch (err) {
        return { error: err };
    }
};
