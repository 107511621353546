import { gql } from '@apollo/client';

export const getNotification = (organizationId: any, employeeId: any, isAdmin: boolean, isApprover: boolean) => {
    if (isAdmin) {
        return gql`
            subscription Notifications {
                timeoffdb_Notifications(where: {organizationId: {_eq: "${organizationId}"}, isAdmin: {_eq: "admin"}}, order_by: {leaveAppliedOn: desc}, limit: 7) {
                    notification
                    employeeId
                    leaveAppliedOn
                    notificationId
                    isAdmin
                    leaveId
                    organizationId
                    approverId
                    NotificationSeens {
                        notificationId
                        employeeId
                        notificationSeen
                    }
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }`;
    }
    if (isApprover) {
        return gql`
            subscription Notifications {
               timeoffdb_get_notificationApproverData(args: {employeeid: "${employeeId}", organizationid: "${organizationId}"}, order_by: {leaveAppliedOn: desc}, limit: 7) {
                    organizationId
                    notificationSeen
                    notificationId
                    notification
                    leaveId
                    leaveAppliedOn
                    isAdmin
                    employeeId
                    approverId
                    Notification {
                        NotificationSeens {
                            notificationId
                            employeeId
                            notificationSeen
                        }
                    }
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }`;
    }
    return gql`
        subscription Notifications {
            timeoffdb_Notifications(where: {organizationId: {_eq: "${organizationId}"}, _or: [{_and: [{approverId: {_eq: "${employeeId}"}}, {isAdmin: {_eq: "employee"}}]}, {_and: [{approverId: {_is_null: true}}, {employeeId: {_eq: "${employeeId}"}}, {isAdmin: {_eq: "employee"}}]}, {_and: [{employeeId: {_neq: "${employeeId}"}}, {isAdmin: {_eq: "allemps"}}]}]}, order_by: {leaveAppliedOn: desc}, limit: 7) {
                notification
                isAdmin
                employeeId
                notificationId
                leaveAppliedOn
                notificationSeen
                approverId
                Employee {
                    firstName
                    lastName
                    profileImage
                }
                NotificationSeens {
                    notificationId
                    employeeId
                    notificationSeen
                }
            }
        }`;
};
