import React, { useState } from 'react';
import {
    Box,
    Grid,
    Hidden,
    Button,
    Typography,
    Paper,
    FormLabel,
    FormControl,
    Input,
    InputAdornment,
    IconButton,
    InputLabel,
    Tooltip,
} from '@material-ui/core';
import useStyles from './style';
// import Page1Img from '../../Assets/images/Page1Img.png';
// import udyamo from '../../Assets/images/udyamo.png';
import { PopUp } from '../Modal/Modal';
import { Auth } from 'aws-amplify';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

interface Props {
    values?: any;
}

export default function ForgotPasswordPartTwo(props: Props) {
    const classes = useStyles();
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [severity, setSeverity] = useState<string>('');
    const [showPassword1, setShowPassword1] = useState<boolean>(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const history = useHistory();

    const passwordPolicyText =
        'Password must contain atleast:\nOne Uppercase letter\nOne lowercase letter\nOne digit [0-9]\n One special character [!@#$%^&*]';

    const isContainsUppercase = /^(?=.*[A-Z])/;
    const isContainsLowercase = /^(?=.*[a-z])/;
    const isContainsNumber = /^(?=.*[0-9])/;
    const isContainsSymbol = /^(?=.*[!@#$%^&*])/;

    /* istanbul ignore next */
    const verifyOTP = async (e: any) => {
        e.preventDefault();
        try {
            if (props.values.code === '' || props.values.password === '' || props.values.confirmPassword === '') {
                throw new Error('All fields are required');
            }
            if (props.values.code.length !== 6) {
                throw new Error('OTP lenght should be equal to 6 digits!');
            }
            if (props.values.password.length <= 8) {
                throw new Error('Password value should be greater than 8 characters');
            }
            if (!isContainsUppercase.test(props.values.password)) {
                throw new Error('Password must contain a capital letter!');
            }
            if (!isContainsLowercase.test(props.values.password)) {
                throw new Error('Password must contain a lower letter!');
            }
            if (!isContainsNumber.test(props.values.password)) {
                throw new Error('Password must contain a number!');
            }
            if (!isContainsSymbol.test(props.values.password)) {
                throw new Error('Password must contain a special character!');
            }
            if (props.values.password !== props.values.confirmPassword) {
                throw new Error('Password fields does not match!');
            }
            await Auth.forgotPasswordSubmit(props.values.emailId, props.values.code, props.values.password);
            props.values.setEmailId('');
            props.values.setCode('');
            props.values.setPassword('');
            props.values.setConfirmPassword('');
            setSeverity('success');
            setAlert(true);
            setAlertMessage('Password updated successfully!');
            if (localStorage.getItem('inviteUrl')) {
                const url = localStorage.getItem('inviteUrl')?.split('?')[1];
                setTimeout(() => history.push('/registerUser?' + url), 2000);
            } else {
                setTimeout(() => history.push('/login'), 2000);
            }
        } catch (error) {
            setSeverity('error');
            setAlert(true);
            if (typeof error === 'object') {
                const obj: any = error;
                setAlertMessage(obj?.message);
                return error;
            }
            return error;
        }
    };

    /* istanbul ignore next */
    const resendOTP = async () => {
        try {
            await Auth.resendSignUp(props.values.emailId || 'demo@gmail.com');
            setSeverity('success');
            setAlert(true);
            setAlertMessage('OTP has been sent to your email!');
        } catch (error) {
            setSeverity('error');
            setAlert(true);
            if (typeof error === 'object') {
                const obj: any = error;
                setAlertMessage(obj?.message);
            }
        }
    };

    return (
        <Box className={classes.root}>
            <Grid container direction="row">
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.registerPageImage}
                                    alt="Page1Img"
                                    style={{ height: '75vh' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                    alt="udyamoLogo"
                                    style={{ height: '2.5vh' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} lg={12} xl={7} className={classes.secondGrid}>
                    <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                    <Paper style={{ boxShadow: 'none' }} className={classes.paperStyle}>
                        <form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                            onSubmit={verifyOTP}
                        >
                            <FormLabel style={{ color: '#444444' }}>2/2</FormLabel>
                            <FormLabel className={classes.title}>Email Verification</FormLabel>
                            <h6 className={classes.font}>
                                {' '}
                                Enter the verification code sent to your email and Enter new password
                            </h6>
                            <Grid>
                                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                                    <InputLabel style={{ color: '#444444' }}>OTP</InputLabel>
                                    <Input
                                        type="text"
                                        value={props.values.code}
                                        onChange={
                                            /* istanbul ignore next */ (e: any) => props.values.setCode(e.target.value)
                                        }
                                        className={classes.passwordInput}
                                    />
                                </FormControl>
                                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                                    <InputLabel style={{ color: '#444444' }}>Password</InputLabel>
                                    <Tooltip
                                        disableHoverListener
                                        title={<span style={{ whiteSpace: 'pre-line' }}>{passwordPolicyText}</span>}
                                        placement="bottom-end"
                                        arrow
                                    >
                                        <Input
                                            type={/* istanbul ignore next */ showPassword1 ? 'text' : 'password'}
                                            value={props.values.password}
                                            onChange={
                                                /* istanbul ignore next */ (e: any) =>
                                                    props.values.setPassword(e.target.value)
                                            }
                                            className={classes.passwordInput}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            /* istanbul ignore next */ () =>
                                                                setShowPassword1(!showPassword1)
                                                        }
                                                        className={classes.passswordButtonStyle}
                                                    >
                                                        {/* istanbul ignore next */ showPassword1 ? (
                                                            <VisibilityOffIcon />
                                                        ) : (
                                                            <VisibilityIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </Tooltip>
                                </FormControl>
                                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                                    <InputLabel style={{ color: '#444444' }}>Confirm Password</InputLabel>
                                    <Tooltip
                                        disableHoverListener
                                        title={<span style={{ whiteSpace: 'pre-line' }}>{passwordPolicyText}</span>}
                                        placement="bottom-end"
                                        arrow
                                    >
                                        <Input
                                            type={/* istanbul ignore next */ showPassword2 ? 'text' : 'password'}
                                            value={props.values.confirmPassword}
                                            onChange={
                                                /* istanbul ignore next */ (e: any) =>
                                                    props.values.setConfirmPassword(e.target.value)
                                            }
                                            className={classes.passwordInput}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            /* istanbul ignore next */ () =>
                                                                setShowPassword2(!showPassword2)
                                                        }
                                                        className={classes.passswordButtonStyle}
                                                    >
                                                        {/* istanbul ignore next */ showPassword2 ? (
                                                            <VisibilityOffIcon />
                                                        ) : (
                                                            <VisibilityIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </Tooltip>
                                </FormControl>
                                <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes.hoverStyle}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        marginTop: 10,
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: '#3C48B5',
                                            cursor: 'pointer',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                        onClick={() => resendOTP()}
                                    >
                                        Resend OTP
                                    </Typography>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
