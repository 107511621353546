import React, { useState, useContext } from 'react';
import { Grid, Paper, FormLabel, TextField, Button, Chip, Hidden } from '@material-ui/core';
import Page3Img from '../../Assets/images/Page3Img.png';
import udyamo from '../../Assets/images/udyamo.png';
import useStyles from './newRegisterStyles';
import Stepper from './stepper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import AuthContext from 'context/AuthContext';
import { giveTz } from '../../helpers/timezone';

interface NewRegister {
    values?: any;
}
const RenderUTC = (option: any) => {
    return (
        <React.Fragment>
            {option.countryName} ({option.zoneName})
        </React.Fragment>
    );
};

export default function RegisterPartFourGoogle(props: NewRegister) {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const [orgNameError, setOrgNameError] = useState(false);
    const [orgNameErrorText, setOrgNameErrorText] = useState('');
    const [inputValue, setInputValue] = React.useState(
        giveTz(`${mycontext.country.gmtOffset}`) + '' + mycontext.country.zoneName,
    );

    const allCountries = useSelector((state: any) => state.country);

    const handleChange = (e: any, state: any) => {
        e.preventDefault();
        if (state) {
            props.values.setCountry(state);
        }
    };

    /* istanbul ignore next */
    const handleOrgName = (event: any) => {
        if (props.values.orgName.length === 1) {
            setOrgNameError(true);
            setOrgNameErrorText('Organisation name should consist of atleast 2 characters');
        } else if (props.values.orgName === '') {
            setOrgNameError(true);
            setOrgNameErrorText('Please enter your organisation name');
        } else {
            setOrgNameError(false);
            setOrgNameErrorText('');
        }
    };
    /* istanbul ignore next */
    const handleDelete = (id: number) => {
        props.values.setSlackTeams(props.values.slackTeams.filter((e: any, i: number) => i !== id));
    };
    /* istanbul ignore next */
    if (props.values.isLoading) {
        /* istanbul ignore next */ return (
            <div
                style={{
                    height: '100vh',
                    width: '100vw',
                }}
            >
                <Stepper task={props.values.task} isRegister={false} />
            </div>
        );
    }
    return (
        <div style={{ display: 'flex' }}>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img src={Page3Img} alt="Page3Img" style={{ height: '75vh' }} />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img src={udyamo} alt="udyamoLogo" style={{ height: '2.5vh' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} xl={7} className={classes.secondGrid}>
                    <Paper style={{ boxShadow: 'none' }} className={classes.orgPaperStyle}>
                        <Grid container direction="row">
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormLabel className={classes.title}>Register My Organisation</FormLabel>
                            </Grid>
                            <Grid item xs={12} className={classes.partFourContent}>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <TextField
                                        id="orgName"
                                        label="Organisation Name"
                                        error={orgNameError}
                                        helperText={orgNameErrorText}
                                        value={props.values.orgName}
                                        style={{ width: '100%' }}
                                        onBlur={(event: any) => handleOrgName(event.target.value)}
                                        onChange={(event: any) =>
                                            props.values.setOrgName((event.target as HTMLInputElement).value)
                                        }
                                        className={classes.formFields}
                                        autoFocus
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 20 }}>
                                    <Autocomplete
                                        onChange={(e: any, state: any) => handleChange(e, state)}
                                        autoComplete={true}
                                        autoSelect={true}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        id="controllable-states-demo"
                                        style={{ width: '100%' }}
                                        options={allCountries.countries}
                                        // classes={{
                                        //     option: classes.option,
                                        // }}
                                        autoHighlight
                                        getOptionLabel={(option: any) => option.countryName + ' ' + option.zoneName}
                                        // getOptionLabel={(option: any) =>
                                        //     option.countryName + ' ' + option.zoneName + giveTz(option.gmtOffset)
                                        // }
                                        renderOption={(option: any) => RenderUTC(option)}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                                className={classes.formFields}
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                        inputValue={inputValue}
                                        value={props.values.country}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', marginTop: '12%' }}>
                                    <FormLabel
                                        style={{
                                            fontSize: '13px',
                                            lineHeight: '19px',
                                            color: '#444444',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Select Teams
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12} className={classes.chipGrid}>
                                    {/* istanbul ignore next */ props.values.slackTeams.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        props.values.slackTeams.map((currentName: any, i: number) =>
                                            currentName === 'general' ? (
                                                <Chip
                                                    id={'chip-' + i}
                                                    key={i}
                                                    label={currentName}
                                                    disabled
                                                    className={classes.chipStyle}
                                                    color="primary"
                                                />
                                            ) : (
                                                <Chip
                                                    id={'chip-' + i}
                                                    key={i}
                                                    label={currentName}
                                                    className={classes.chipStyle}
                                                    onDelete={() => handleDelete(i)}
                                                    color="primary"
                                                />
                                            ),
                                        )
                                    )}
                                </Grid>
                                <Grid item xs={12} className={classes.registerButtonGrid}>
                                    <Button
                                        id="register"
                                        className={classes.registerButton}
                                        onClick={() => props.values.confirmSubmit()}
                                    >
                                        Register
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
