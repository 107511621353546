import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        fontFamily: theme.typography.fontFamily,
    },
    registerLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    userOrganizationLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    registerRight: {
        width: '100%',
        height: '100vh',
        // backgroundColor: theme.palette.primary.main,
        backgroundImage: `url("bgImg.png")`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    userOrganizationRight: {
        width: '100%',
        height: '100vh',
        // backgroundColor: theme.palette.primary.main,
        backgroundImage: `url("bgImg.png")`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentContainer: {
        width: '60%',
        // height: '80%',
        borderRadius: 10,
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '0 10px',
        '@media screen and (max-width: 1200px) and (min-width: 771px)': {
            width: '70%',
            height: '60%',
            padding: '0 50px',
            borderRadius: 10,
            transform: 'scale(1.1)',
        },
        '@media screen and (max-width: 770px) and (min-width: 561px)': {
            width: '70%',
            height: '70%',
            padding: '0 50px',
            borderRadius: 10,
            transform: 'scale(1.1)',
        },
        '@media screen and (max-width: 560px)': {
            width: '100%',
            height: '100vh',
            padding: '0 20px',
            borderRadius: 0,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
    },
    org: {
        width: 260,
        border: '1px solid #3C48B5',
        borderRadius: 5,
        height: 50,
        margin: '10px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        backgroundColor: '#3D49B6',
        cursor: 'pointer',
        padding: '5px 0',
    },
    orgOutline: {
        width: 260,
        border: '1px solid #D0D4EA',
        borderRadius: 5,
        height: 50,
        margin: '10px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        backgroundColor: '#fff',
        cursor: 'pointer',
        padding: '5px 0',
    },
    innerOrg: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: '#fff',
        fontSize: 12,
        fontFamily: 'Nunito Sans',
        flex: 0.7,
    },
    innerOrgOutline: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        fontSize: 12,
        fontFamily: 'Nunito Sans',
        flex: 0.7,
    },
    orgName: {
        color: '#fff',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 14,
        width: 90,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    orgNameOutline: {
        color: '#222222',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 14,
        width: 90,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    role: {
        color: '#fff',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 12,
    },
    roleOrgOutline: {
        color: '#888888',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 12,
    },
    bgImage: {
        height: '100vh',
        width: '100%',
        backgroundAttachment: 'fixed',
    },
    googleImg: {
        height: '100%',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: '10vh',
        marginRight: '20vh',
    },
    createLink: {
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: 600,
        marginTop: 30,
        '&:hover, &:focus': {
            textDecoration: 'none',
        },
    },
    SignUpheading: {
        textAlign: 'center',
        marginTop: '10vh',
        fontSize: 42,
        fontWeight: 600,
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamily,
    },
    heading: {
        fontFamily: theme.typography.fontFamily,
        color: '#fff',
        fontSize: 40,
        fontWeight: theme.typography.fontWeightBold,
    },
    subtitle: {
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'italic',
        fontSize: 20,
        fontWeight: theme.typography.fontWeightBold,
    },
    teamLogoSmall: {
        width: '70%',
        height: 80,
        marginBottom: '5px',
    },
    overlay: {
        display: 'flex',
        position: 'relative',
        bottom: '60vh',
        left: '8vw',
        alignItems: 'center',
        color: 'white',
    },
    overlay2: {
        display: 'flex',
        position: 'relative',
        bottom: '58vh',
        left: '8vw',
        fontSize: '1.2em',
        alignItems: 'center',
        color: 'white',
        fontStyle: 'italic',
    },
    firstNameCSS: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '3vh',
        marginLeft: '6vw',
        justifyContent: 'center',
    },
    emailCSS: {
        display: 'flex',
        marginTop: '1.5em',
        marginLeft: '6vw',
        marginRight: '6vw',
        alignItems: 'center',
        justifyContent: 'center',
    },
    passwordCSS: {
        display: 'flex',
        marginLeft: '6vw',
        marginTop: '1.5em',
        alignItems: 'center',
        justifyContent: 'center',
    },
    genderFieldCSS: {
        display: 'flex',
        marginLeft: '6vw',
        marginTop: '2em',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    hoverStyle: {
        '&:hover': {
            backgroundColor: '#3D49B6',
        },
    },
    signUpButtonStyle: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        '@media only screen and (min-width: 360px) and (max-width: 640px)': {
            marginLeft: '2em',
        },
        '@media only screen and (min-width: 400px) and (max-width: 735px)': {
            marginLeft: '3em',
            marginRight: '3em',
        },
        '@media only screen and (min-width: 400px) and (max-width: 825px)': {
            marginLeft: '4em',
            marginRight: '4em',
        },
        '@media only screen and (min-width: 300px) and (max-width: 570px)': {
            marginLeft: '2em',
            marginRight: '2em',
            marginTop: '1em',
        },
        '@media only screen and (min-width: 375px) and (max-width: 670px)': {
            marginLeft: '2.5em',
            marginRight: '2.5em',
            marginTop: '5em',
        },
        '@media only screen and (min-width: 410px) and (max-width: 740px)': {
            marginLeft: '4em',
            marginRight: '4em',
            marginTop: '6em',
        },
        '@media only screen and (min-width: 370px) and (max-width: 816px)': {
            marginLeft: '3em',
            marginRight: '3em',
            marginTop: '3em',
        },
        '@media only screen and (min-width: 760px) and (max-width: 1030px)': {
            marginLeft: '10em',
            marginRight: '10em',
            marginTop: '3em',
        },
        '@media only screen and (min-width: 540px) and (max-width: 730px)': {
            marginLeft: '5em',
            marginRight: '5em',
            marginTop: '3em',
        },
        '@media only screen and (min-width: 850px)': {
            marginLeft: '6em',
            marginRight: '6em',
            marginTop: '1.5em',
        },
    },
    googleDiv: {
        '@media only screen and (min-width: 300px) and (max-width: 650px)': {
            marginLeft: '2em',
            marginRight: '2em',
        },
        '@media only screen and (min-width: 400px) and (max-width: 735px)': {
            marginLeft: '3em',
            marginRight: '3em',
            marginTop: '1em',
        },
        '@media only screen and (min-width: 400px) and (max-width: 825px)': {
            marginLeft: '4em',
            marginRight: '4em',
        },
        '@media only screen and (min-width: 300px) and (max-width: 570px)': {
            marginLeft: '2em',
            marginRight: '2em',
            marginTop: '1em',
        },
        '@media only screen and (min-width: 375px) and (max-width: 670px)': {
            marginLeft: '2.5em',
            marginRight: '2.5em',
            marginTop: '5em',
        },
        '@media only screen and (min-width: 410px) and (max-width: 740px)': {
            marginLeft: '4em',
            marginRight: '4em',
            marginTop: '6em',
        },
        '@media only screen and (min-width: 370px) and (max-width: 816px)': {
            marginLeft: '3em',
            marginRight: '3em',
            marginTop: '2em',
        },
        '@media only screen and (min-width: 760px) and (max-width: 1030px)': {
            marginLeft: '10em',
            marginRight: '10em',
            marginTop: '2em',
        },
        '@media only screen and (min-width: 540px) and (max-width: 730px)': {
            marginLeft: '5em',
            marginRight: '5em',
            marginTop: '2em',
        },
        '@media only screen and (min-width: 850px)': {
            marginLeft: '6em',
            marginRight: '6em',
            marginTop: '1em',
        },
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    googleSignUp: {
        '& .MuiButton-sizeSmall': {
            paddingLeft: 48,
            paddingRight: 32,
            position: 'absolute',
            left: 16,
        },
        '& .MuiButton-startIcon': {
            position: 'absolute',
            left: 16,
        },
    },
    outerOrganizationDiv: {
        height: 200,
    },
    organizations: {
        maxHeight: 180,
        height: 180,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.2em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 3px rgba(0,0,0,0)',
            webkitBoxShadow: 'inset 0 0 3px #3C48B5',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#3C48B5',
            outline: 'none',
        },
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0px 10px',
        marginBottom: 20,
    },
    avatar: {
        verticalAlign: 'middle',
        width: 35,
        height: 35,
        borderRadius: '50%',
        color: '#444444',
        background: '#fff',
        textTransform: 'uppercase',
    },
    avatarOutline: {
        verticalAlign: 'middle',
        width: 35,
        height: 35,
        borderRadius: '50%',
        color: '#444444',
        background: '#fff',
        textTransform: 'uppercase',
        border: '0.5px solid #666666',
    },
}));

export default useStyles;
