import { makeStyles, Theme } from '@material-ui/core/styles';

export const TimeOffPolicyStyles = makeStyles((theme: Theme) => ({
    grid: {
        marginTop: 10,
        marginBottom: 25,
        display: 'flex',
        alignItems: 'center',
    },
    grid2: {
        display: 'flex',
        'line-height': '20px',
    },
    title: {
        fontSize: '15px',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
    },
    holiday: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    main: {
        flexGrow: 1,
        // width: '100%',
        // height: 'auto',
        padding: '15px 20px',
        fontFamily: theme.typography.fontFamily,
        height: 'calc(100vh - 175px)',
        '@media screen and (max-width: 768px)': {
            height: 'auto',
            padding: '10px 15px',
        },
    },
    Deskpara: {
        display: 'flex',
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'bold',
        fontSize: '14px',
        color: theme.palette.text.primary,
    },
    para: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '15px',
        fontStyle: 'normal',
        letterspacing: '0.02em',
        color: theme.palette.text.primary,
        paddingRight: '15px',
    },
    btn: {
        height: 35,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        marginRight: 10,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
        '@media screen and (max-width: 650px)': {
            margin: '10px 0',
            marginRight: 10,
        },
    },
    mobbtn: {
        height: 35,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    btnFixed: {
        height: 40,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        textTransform: 'capitalize',
        padding: '0 50px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        border: '1px solid white',
        marginRight: 10,
        borderRadius: 0,
        zIndex: 99,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            outline: 'none',
            border: '1px solid white',
        },
    },
    addtoslackbtn: {
        height: 36,
        textTransform: 'capitalize',
        padding: '0 40px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    screenSize: {
        display: 'none',
        '@media screen and (max-width: 767px)': {
            display: 'block',
        },
    },
}));
