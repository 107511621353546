import React, { useState, useEffect, useContext } from 'react';
import { Grid, Paper, FormLabel, Button, Hidden } from '@material-ui/core';
import Page3Img from '../../Assets/images/Page3Img.png';
import udyamo from '../../Assets/images/udyamo.png';
import useStyles from './newRegisterStyles';
import RegisterPartFourGoogle from './registerPartFourGoogle';
import { useHistory } from 'react-router-dom';
import ErrorGoogle from '../Error/ErrorGoogle';
import { PopUp } from '../Modal/Modal';
import AuthContext from '../../context/AuthContext';
import {
    registerAdmin,
    registerOrganization,
    createOrganizationRole,
    createOrganizationSettings,
    createDefaultOrganizationTeam,
    insertPendingActions,
    createDefaultLeaveType,
    // sendInvite,
    // isEmployeeInDatabase,
    isEmployeeInDatabaseEmployeeId,
    // fetchSlackUsers,
    createOrganization_employee_slack,
    registerSlackEmployees,
    insertTeamEmployeeSlack,
    fetchSlackUsers,
    addClients,
    isEmployeeInOrganizationSlack,
    // deleteEmployee,
} from '../../helpers/hasuraapi';
import { adminQuestion } from '../../helpers/hasuraapi3';
import { toAWSDateFormat } from '../../helpers/date';
import jwt_decode from 'jwt-decode';
import { LoaderPage } from '../Loader/loader';
import { getNameFromEmail } from 'helpers/getInitials';
import { useLocation } from 'react-router-dom';
import getUuidByString from 'uuid-by-string';
import { useFlagsmith } from 'flagsmith-react';

export default function SlackRegistration() {
    const classes = useStyles();
    const location: any = useLocation();
    const mycontext = useContext(AuthContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [show, setShow] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');
    const [orgName, setOrgName] = useState('');
    const [employeeEmail, setEmployeeEmail] = useState('');
    const [allEmailsArray, setAllEmailsArray] = useState<string[]>([]);
    const [checkedTC, setCheckedTC] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOrg, setIsLoadingOrg] = useState(false);
    const [country, setCountry] = useState({
        countryCode: 'IN',
        countryName: 'India',
        gmtOffset: 19800,
        timestamp: 1665487798,
        zoneName: 'Asia/Kolkata',
    });
    // const invitees: any = [];
    const [task, setTask] = useState({
        task1: false,
        task2: false,
        task3: false,
        task4: false,
        task5: false,
        task6: false,
        task7: false,
    });
    const history = useHistory();
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [severity, setSeverity] = useState<string>('');
    const [isDelete, setIsDelete] = useState<boolean>(false);
    // console.log(alert, alertMessage, severity);
    const [id, setId] = useState('');
    const [error, setError] = useState(false);
    // const [token, setToken] = useState('');
    const [slackTeams, setSlackTeams] = useState<string[]>([]);
    const [slackTeamEmployees, setSlackTeamsEmployees] = useState<string[]>([]);
    const [slackEmployees, setSlackEmployees] = useState({});
    const [workspaceId, setWorkspaceId] = useState({});
    const [slackAllTeams, setSlackAllTeams] = useState({});
    const { identify, getTrait, setTrait, hasFeature } = useFlagsmith();
    useEffect(() => {
        const signIn = async () => {
            try {
                setIsLoadingOrg(true);
                const token = location.state.token;
                // setToken(location.state.token);
                if (token != null) {
                    const decoded: any = jwt_decode(token);
                    const identifyUser = async (id: string, email: string) => {
                        await identify(id);
                        const hasEmail = !!getTrait('email');
                        if (!hasEmail) {
                            setTrait('email', email);
                        }
                    };
                    identifyUser(decoded.email, decoded.email);
                    localStorage.setItem('token', token);
                    const isUser: any = await isEmployeeInDatabaseEmployeeId(getUuidByString(decoded.sub), token);
                    const isUserInOrganization = await isEmployeeInOrganizationSlack(
                        getUuidByString(decoded.sub),
                        token,
                    );
                    if (isUser !== 0) {
                        if (isUserInOrganization === 0) {
                            setIsLoadingOrg(false);
                            setIsDelete(true);
                        }
                        if (isUserInOrganization !== 0) {
                            history.push({
                                pathname: '/myorganizationsslack',
                                state: {
                                    id: getUuidByString(decoded.sub),
                                    slackEmployeeId: decoded.sub,
                                    email: decoded.email,
                                    token: token,
                                    refreshToken: location.state.refreshToken,
                                },
                            });
                        }
                    } else {
                        //store employee in database
                        // if (!hasFeature('allow-create-organization')) {
                        const email = decoded.email;
                        const firstName = decoded.username.split(' ')[0];
                        const lastName = decoded.username.split(' ')[1];
                        const obj = {
                            email: email,
                            firstName: firstName ? firstName : 'no firstname',
                            lastName: lastName ? lastName : 'no lastname',
                            token: token,
                        };
                        await addClients(obj);
                        // }
                        const slackUsers = await fetchSlackUsers(decoded['accId']);
                        setWorkspaceId(slackUsers.workspaceDetails.team_id);
                        if (Object.keys(slackUsers.employees).length === 0) {
                            setAlert(true);
                            setSeverity('error');
                            setAlertMessage('Please try again in sometime');
                        } else {
                            setId(getUuidByString(decoded.sub));
                            setAllEmailsArray([]);
                            setOrgName(slackUsers.workspaceDetails.team);
                            setSlackEmployees(slackUsers.employees);
                            setSlackTeamsEmployees(slackUsers.team_employees_formatted);
                            setSlackAllTeams(slackUsers.teams);
                            for (const key in slackUsers.employees) {
                                if (slackUsers.employees.hasOwnProperty(key)) {
                                    const val = slackUsers.employees[key].email;
                                    setAllEmailsArray(allEmailsArray => [...allEmailsArray, val]);
                                }
                            }
                            for (const key in slackUsers.teams) {
                                if (slackUsers.teams.hasOwnProperty(key)) {
                                    const val = slackUsers.teams[key].teamName;
                                    setSlackTeams(slackTeams => [...slackTeams, val]);
                                }
                            }
                            setIsLoadingOrg(false);
                        }
                    }
                    // setToken(token);
                    setEmailId(decoded.email);
                }
            } catch (err) {
                mycontext.logout();
                history.push({
                    pathname: '/login',
                });
                setError(true);
            }
        };
        signIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const logoutUser = () => {
        mycontext.logout();
        history.push({
            pathname: '/login',
        });
    };
    // const handleDeleteEmployee = async () => {
    //     const decoded: any = jwt_decode(token);
    //     await deleteEmployee(getUuidByString(decoded.sub), token);
    //     mycontext.logout();
    //     history.push('/login');
    // };
    if (isLoadingOrg) {
        return (
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LoaderPage />
            </div>
        );
    }
    const confirmSubmit = async () => {
        try {
            if (orgName === '') {
                throw new Error('Please enter organisation name');
            } else if (orgName.length < 2) {
                throw new Error('Organisation name should consist of atleast two characters');
            }
            // const user = await Auth.currentAuthenticatedUser();
            // const token = user.storage.token;
            const token = location.state.token;
            const decoded: any = jwt_decode(token);

            setId(getUuidByString(decoded.sub));
            setEmailId(decoded.email);
            const employee: any = {
                empId: getUuidByString(decoded.sub),
                email: emailId,
                firstName:
                    slackEmployees[emailId].first_name !== undefined
                        ? slackEmployees[emailId].first_name
                        : getNameFromEmail(emailId),
                lastName: slackEmployees[emailId].last_name !== undefined ? slackEmployees[emailId].last_name : '',
                image: slackEmployees[emailId].image,
                joining: toAWSDateFormat(new Date()),
                token: token,
            };
            setIsLoading(true);
            await registerAdmin(employee);
            setTask({ ...task, task1: true });
            // const country = {
            //     countryCode: 'IN',
            //     countryName: 'India',
            //     gmtOffset: 19800,
            //     timestamp: 1665487798,
            //     zoneName: 'Asia/Kolkata',
            // };
            const organization = {
                employeeId: id,
                organizationName: orgName,
                token: token,
                location: country,
                workspaceId: workspaceId,
            };
            const org = await registerOrganization(organization);
            const empRole = {
                employeeId: getUuidByString(decoded.sub),
                organizationId: org.organizationId,
                role: 'admin',
                token: token,
                location: country,
                isAccountLinked: true,
                slackId: decoded.sub,
            };
            await createOrganizationRole(empRole);
            setTask({ ...task, task1: true, task2: true });
            const defaultSettings = {
                organizationId: org.organizationId,
                dateFormat: 'DD-MM-YYYY',
                displayLogo: false,
                approverCalledAs: 'approver',
                employeeCalledAs: 'employee',
                subscriptionPlan: '',
                teamsCalledAs: 'teams',
                workWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
                workingHours: 5,
                token: token,
            };
            await createOrganizationSettings(defaultSettings);
            const standupTemplate = {
                questions: ['What did you accomplish yesterday?', 'What is your goal for today?', 'Blockers'],
                employeeId: id,
                organizationId: org.organizationId,
                token: token,
            };
            const data = await adminQuestion(standupTemplate);
            mycontext.addStandupTempId(data?.standupTempId);
            setTask({
                ...task,
                task1: true,
                task2: true,
                task3: true,
                task4: true,
            });

            const teamsObj = {};
            const slackTeamList = slackAllTeams;
            for (const key in slackTeamList) {
                if (slackTeamList.hasOwnProperty(key)) {
                    if (slackTeams.includes(slackTeamList[key].teamName)) {
                        const teamObj = {
                            slackTeamId: slackTeamList[key].teamId,
                            teamName: slackTeamList[key].teamName,
                            organizationId: org.organizationId,
                            createdAt: toAWSDateFormat(new Date()),
                            token: token,
                        };
                        const newTeam = await createDefaultOrganizationTeam(teamObj);
                        teamsObj[newTeam.teamName] = newTeam.teamId;
                        const teamUser = {
                            employeeId: id,
                            isApprover: true,
                            teamId: newTeam.teamId,
                            token: token,
                        };
                        await insertTeamEmployeeSlack(teamUser);
                    }
                }
            }

            setTask({
                ...task,
                task1: true,
                task2: true,
                task3: true,
                task4: true,
                task5: true,
            });

            const empIdObject = {};
            for (let x = 0; x < allEmailsArray.length; x++) {
                const convertedUUID = getUuidByString(slackEmployees[allEmailsArray[x]].uid);
                const employee: any = {
                    employeeId: convertedUUID,
                    firstName:
                        slackEmployees[allEmailsArray[x]].first_name !== undefined
                            ? slackEmployees[allEmailsArray[x]].first_name
                            : getNameFromEmail(allEmailsArray[x]),
                    lastName:
                        slackEmployees[allEmailsArray[x]].last_name !== undefined
                            ? slackEmployees[allEmailsArray[x]].last_name
                            : '',
                    image: slackEmployees[allEmailsArray[x]].image,
                    email: allEmailsArray[x],
                    joining: toAWSDateFormat(new Date()),
                    token: token,
                };
                await registerSlackEmployees(employee);
                empIdObject[allEmailsArray[x]] = convertedUUID;
                setIsLoading(true);
            }
            setTask({
                ...task,
                task1: true,
                task2: true,
                task3: true,
                task4: true,
                task5: true,
                task6: true,
            });
            for (let x = 0; x < allEmailsArray.length; x++) {
                const empRole = {
                    organizationId: org.organizationId,
                    employeeId: empIdObject[allEmailsArray[x]],
                    role: 'employee',
                    location: country,
                    slackId: slackEmployees[allEmailsArray[x]].uid,
                    isAccountLinked: true,
                    workspaceId: workspaceId,
                    token: token,
                };
                await createOrganization_employee_slack(empRole);
            }
            for (let x = 0; x < allEmailsArray.length; x++) {
                for (const key in slackTeamEmployees) {
                    if (slackTeamEmployees.hasOwnProperty(key)) {
                        if (teamsObj[key]) {
                            if (slackTeamEmployees[key].includes(allEmailsArray[x])) {
                                const teamUser = {
                                    employeeId: empIdObject[allEmailsArray[x]],
                                    isApprover: false,
                                    teamId: teamsObj[key],
                                    token: token,
                                };
                                await insertTeamEmployeeSlack(teamUser);
                            }
                        }
                    }
                }
            }
            await insertPendingActions({
                organizationId: org.organizationId,
                token: token,
            });
            const leaveType1 = {
                organizationId: org.organizationId,
                token: token,
                leaveTypeName: 'Sick',
                isPaid: true,
                daysPerYear: 0,
                approvalRequired: true,
                active: true,
                unlimitedDays: true,
                defaultEnable: true,
                unified: '1f334',
                emoji: '🌴',
                activeSkinTone: 'neutral',
                originalUnified: '1f334',
            };
            const leaveType2 = {
                organizationId: org.organizationId,
                token: token,
                leaveTypeName: 'Casual',
                isPaid: false,
                daysPerYear: 25,
                approvalRequired: true,
                active: true,
                unlimitedDays: false,
                defaultEnable: true,
                unified: '1f334',
                emoji: '🌴',
                activeSkinTone: 'neutral',
                originalUnified: '1f334',
            };
            await createDefaultLeaveType(leaveType1);
            await createDefaultLeaveType(leaveType2);
            // await sendInvite(invitees, token);
            setTask({
                ...task,
                task1: true,
                task2: true,
                task3: true,
                task4: true,
                task5: true,
                task6: true,
                task7: true,
            });
            setIsLoading(false);
            if (token) {
                const decoded: any = jwt_decode(token);
                setId(getUuidByString(decoded.sub));
                // setToken(token);
                history.push({
                    pathname: '/myorganizationsslack',
                    state: {
                        id: getUuidByString(decoded.sub),
                        slackEmployeeId: decoded.sub,
                        token: token,
                        isSignup: true,
                    },
                });
            }
        } catch (err) {
            setSeverity('error');
            setAlert(true);
            setAlertMessage(err.message);
            return err;
        }
    };
    const values = {
        firstName: firstName,
        setFirstName: setFirstName,
        lastName: lastName,
        setLastName: setLastName,
        emailId: emailId,
        setEmailId: setEmailId,
        password: password,
        setPassword: setPassword,
        confirmPassword: confirmPassword,
        setConfirmPassword: setConfirmPassword,
        code: code,
        setCode: setCode,
        orgName: orgName,
        setOrgName: setOrgName,
        employeeEmail: employeeEmail,
        setEmployeeEmail: setEmployeeEmail,
        allEmailsArray: allEmailsArray,
        setAllEmailsArray: setAllEmailsArray,
        checkedTC: checkedTC,
        setCheckedTC: setCheckedTC,
        confirmSubmit: confirmSubmit,
        isLoading: isLoading,
        task: task,
        country: country,
        setCountry: setCountry,
        slackTeams: slackTeams,
        setSlackTeams: setSlackTeams,
    };
    if (error === true) {
        return <ErrorGoogle title="Something went wrong" />;
    }
    return (
        <>
            {show ? (
                <>
                    <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                    <RegisterPartFourGoogle values={values} />
                </>
            ) : (
                <>
                    <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                    <Grid container style={{ display: 'flex' }}>
                        <Hidden lgDown>
                            <Grid item xs={5} className={classes.firstGrid}>
                                <Grid container direction={'column'}>
                                    <Grid item xs={12} className={classes.imgOneStyle}>
                                        <img src={Page3Img} alt="Page3Img" style={{ height: '75vh' }} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.imgTwoStyle}>
                                        <img src={udyamo} alt="udyamoLogo" style={{ height: '2.5vh' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} xl={7} className={classes.secondGrid}>
                            <Paper style={{ boxShadow: 'none' }} className={classes.orgPaperStyle}>
                                <Grid container>
                                    <Grid container direction="row">
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {isDelete ? (
                                                <FormLabel className={classes.title}>OOPS</FormLabel>
                                            ) : (
                                                <FormLabel className={classes.title}>Welcome</FormLabel>
                                            )}
                                        </Grid>
                                        {!isDelete ? (
                                            <Grid container style={{ padding: '10px' }} className={classes.contentGrid}>
                                                <Grid item xs={12} className={classes.paraGridStyle}>
                                                    {hasFeature('allow-create-organization') ? (
                                                        <FormLabel className={classes.paraContent}>
                                                            Looks like you are new to Teamplus. Register your
                                                            organisation and add your employees to use time-off
                                                            management feature and get started with the efficient way of
                                                            working together.
                                                        </FormLabel>
                                                    ) : (
                                                        <FormLabel className={classes.paraContent}>
                                                            Looks like you are new to Teamplus. Thanks for showing
                                                            interest in Teamplus, One of our representative will contact
                                                            you soon !
                                                        </FormLabel>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container style={{ padding: '10px' }} className={classes.contentGrid}>
                                                <Grid item xs={12} className={classes.paraGridStyle}>
                                                    <FormLabel className={classes.paraContent}>
                                                        Looks like your admin deleted you from the organization or
                                                        deleted the entire organization.
                                                    </FormLabel>
                                                </Grid>
                                                <Grid item xs={12} className={classes.paraGridStyle}>
                                                    <FormLabel className={classes.paraContent}>
                                                        Ask them to Sync employees or create new organization in order
                                                        to add you back to the organization.
                                                    </FormLabel>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {!isDelete ? (
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                marginTop: '10%',
                                                padding: '24px 24px 0px 24px',
                                            }}
                                            className={classes.registerButtonGrid}
                                        >
                                            {hasFeature('allow-create-organization') ? (
                                                <Button
                                                    id="regNewOrg"
                                                    className={classes.registerButton}
                                                    onClick={() => setShow(true)}
                                                >
                                                    Register a new organisation
                                                </Button>
                                            ) : (
                                                <Button
                                                    id="regNewOrg"
                                                    className={classes.registerButton}
                                                    onClick={() => logoutUser()}
                                                >
                                                    Logout
                                                </Button>
                                            )}
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    padding: '24px 24px 0px 24px',
                                                }}
                                                className={classes.registerButtonGrid}
                                            >
                                                <Button
                                                    id="regNewOrg"
                                                    variant="outlined"
                                                    className={classes.registerButton}
                                                    onClick={() => logoutUser()}
                                                >
                                                    Logout
                                                </Button>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    padding: '24px 24px 0px 24px',
                                                }}
                                                className={classes.registerButtonGrid}
                                            >
                                                <Button
                                                    id="regNewOrg"
                                                    className={classes.deleteAccountButton}
                                                    onClick={() => handleDeleteEmployee()}
                                                >
                                                    Delete Account
                                                </Button>
                                            </Grid> */}
                                        </>
                                    )}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
