import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label1: {
            paddingTop: 2,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
        },
        inputStyle: {
            color: theme.palette.primary.contrastText,
            '& div': {
                '& input': {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                    borderRadius: '4px',
                },
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        label2: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '14px',
            fontFamily: theme.typography.fontFamily,
        },
        disablelabel: {
            paddingTop: 2,
            color: '#CCCCCC',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
        },
        paper: {
            padding: theme.spacing(1),
            paddingBottom: '5px',
            borderRadius: '5px',
            backgroundColor: theme.palette.background.paper,
        },
        btn: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontSize: 14,
            // marginLeft: '10px',
            marginRight: 10,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
                marginLeft: 0,
            },
        },
        btnFixed: {
            height: 40,
            width: '50%',
            position: 'fixed',
            bottom: 0,
            // right: 0,
            left: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            borderRadius: 0,
            zIndex: 99,
            color: '#3C48B5',
            backgroundColor: theme.palette.common.white,
            '&:hover': {
                color: '#3C48B5',
                backgroundColor: theme.palette.common.white,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        btnOutlined: {
            height: 35,
            textTransform: 'capitalize',
            marginRight: 10,
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
        },
        screenSize: {
            display: 'none',
            '@media screen and (max-width: 767px)': {
                display: 'block',
            },
        },
    }),
);
