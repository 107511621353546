import React from 'react';
// import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './EditDeleteHolidayStyle';
import { Button, TextField, FormLabel, FormControlLabel, Checkbox, Hidden, useTheme } from '@material-ui/core';
// import IOSSwitch from '../IOSSwitch/Iosswitch';
import { DateField } from '../ImportHoliday/FormInputs';
import MyModal from '../Modal/Modal';

interface Props {
    modal: any;
    toggle: any;
    open: any;
    setOpen: any;
    holidayname: any;
    setHolidayName: any;
    date: any;
    setDate: any;
    startdate: any;
    setstartDate: any;
    enddate: any;
    setendDate: any;
    state: any;
    handleChangeFirst: any;
    handleSubmit: any;
    setEdit: any;
    error: any;
    holidaytext: any;
    mulitpleDateError: any;
    errorMsg: any;
}
interface DeleteProps {
    isDelete: boolean;
    setDelete: any;
    open: boolean;
    setOpen: any;
    handleDelete?: any;
    selected: any;
    name?: string;
}
export default function AddHoliday(props: Props): JSX.Element {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div data-testid="table">
            <MyModal open={props.modal} setOpen={props.toggle} onModalClosed={props.toggle}>
                <>
                    <Form>
                        {props.error === true ? (
                            <>
                                <FormLabel component="legend" error>
                                    Invalid holiday name
                                </FormLabel>
                            </>
                        ) : (
                            ''
                        )}
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Label className={classes.label1}>Holiday Name</Form.Label>

                            <Form.Group controlId="exampleForm.ControlInput1">
                                {props.holidaytext ? (
                                    <TextField
                                        id="holiday-name"
                                        placeholder="Holiday Name"
                                        value={props.holidayname}
                                        variant="outlined"
                                        fullWidth
                                        error
                                        helperText="Please Enter Holiday Name"
                                        onChange={e => props.setHolidayName(e.target.value)}
                                        margin="dense"
                                        className={classes.inputStyle}
                                    />
                                ) : (
                                    <TextField
                                        id="holiday-name"
                                        placeholder="Holiday Name"
                                        value={props.holidayname}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        onChange={e => props.setHolidayName(e.target.value)}
                                        margin="dense"
                                        className={classes.inputStyle}
                                    />
                                )}
                            </Form.Group>
                        </Form.Group>
                        <Form.Group style={{ marginBottom: 0 }}>
                            <Grid container>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="check-multiday-holiday"
                                            checked={props.state.checkedA}
                                            onChange={props.handleChangeFirst}
                                            name="checkedA"
                                            style={{ color: theme.palette.primary.main }}
                                        />
                                    }
                                    // label="Is this a Multiday Holiday?"
                                    label="Add holiday for multiple days"
                                />
                            </Grid>
                        </Form.Group>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Form.Group controlId="exampleForm.SelectCustom">
                                    <Form.Label className={classes.label1}>From</Form.Label>
                                    <DateField id="start-holiday" date={props.startdate} setDate={props.setstartDate} />
                                </Form.Group>
                            </Grid>
                            {props.state.checkedA ? (
                                <Hidden only="xs">
                                    <Grid item sm={6}>
                                        <Form.Group controlId="exampleForm.SelectCustom1">
                                            <Form.Label className={classes.label1}>To</Form.Label>
                                            <DateField
                                                id="end-holiday"
                                                date={props.enddate}
                                                setDate={props.setendDate}
                                            />
                                        </Form.Group>
                                    </Grid>
                                </Hidden>
                            ) : (
                                <Hidden only="xs">
                                    <Grid item sm={6}>
                                        <Form.Group controlId="exampleForm.SelectCustom">
                                            <Form.Label className={classes.label1} style={{ color: '#CCCCCC' }}>
                                                To
                                            </Form.Label>
                                            <DateField
                                                id="holiday-date"
                                                date={props.startdate}
                                                setDate={props.setstartDate}
                                                // isHalf={true}
                                                disable={true}
                                            />
                                        </Form.Group>
                                    </Grid>
                                </Hidden>
                            )}
                        </Grid>
                        {props.mulitpleDateError === true ? (
                            <>
                                <FormLabel component="legend" error id="HolidayError">
                                    {props.errorMsg}
                                </FormLabel>
                            </>
                        ) : (
                            ''
                        )}
                        <Hidden smUp>
                            {props.state.checkedA ? (
                                <Grid item xs={12}>
                                    <Form.Group controlId="exampleForm.SelectCustom1">
                                        <Form.Label className={classes.label1}>To</Form.Label>
                                        <DateField id="end-holiday" date={props.enddate} setDate={props.setendDate} />
                                    </Form.Group>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label className={classes.label1} style={{ color: '#CCCCCC' }}>
                                            To
                                        </Form.Label>
                                        <DateField
                                            id="holiday-date"
                                            date={props.startdate}
                                            setDate={props.setstartDate}
                                            // isHalf={true}
                                            disable={true}
                                        />
                                    </Form.Group>
                                </Grid>
                            )}
                        </Hidden>
                    </Form>

                    <br />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => props.setEdit()}
                            className={classes.btnOutlined}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            id="updateHoliday"
                            color="primary"
                            onClick={() => props.handleSubmit()}
                            type="submit"
                            className={classes.btn}
                        >
                            Update
                        </Button>
                    </div>
                </>
            </MyModal>
        </div>
    );
}

export function DeleteModal(props: DeleteProps): JSX.Element {
    const classes = useStyles();
    return (
        <>
            <Grid container className={classes.deleteModalContainer}>
                <Grid item xs={12} sm={12} style={{ marginBottom: '20px', marginTop: '15px' }}>
                    <FormLabel className={classes.deltitle}>Delete Holiday</FormLabel>
                </Grid>

                <Grid item xs={12} sm={12} style={{ marginBottom: '35px' }}>
                    <p className={classes.delbod}>
                        Are you sure you want to delete <strong>{props.name} </strong> holiday?
                    </p>
                </Grid>

                <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.btnOutlined}
                        onClick={() => props.setDelete(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="confirm-delete"
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        onClick={() => props.handleDelete(props.selected)}
                    >
                        Delete
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
