import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCalendarData, getLeavesForApproverCalendar } from 'helpers/hasuraapi';

export const getCalendar: any = createAsyncThunk('calendar/getCalendar', async (user: any) => {
    if (user.isApprover) {
        const data: any = await getLeavesForApproverCalendar(user.employeeId, user.organizationId, user.token);
        const jsonData: any = await data.json();
        const j: any = jsonData.data;
        const a: any = j.timeoffdb_get_pendingdeniedapproverleaves.concat(j.timeoffdb_Leaves);
        const arr: any = [].concat(a);
        return arr;
    }
    const data: any = await fetchCalendarData(user.organizationId, user.token);
    const jsonData = await data.json();
    return jsonData.data.timeoffdb_Leaves;
});

interface State {
    leaves: Record<string, any>[];
    status: string;
}

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState: { leaves: [], status: 'loading' },
    reducers: {
        addCalendarLeave: (state: State, action: Record<string, any>) => {
            state.leaves.push(action.payload);
        },
        editCalendarLeave: (state, action) => {
            state.leaves.map((u: any) => {
                if (u.leaveId === action.payload.leaveId) {
                    u.approverId = action.payload.approverId;
                    u.denialReason = action.payload.denialReason;
                    u.denierId = action.payload.denierId;
                    u.leaveStatus = action.payload.leaveStatus;
                    u.startDate = action.payload.startDate;
                    u.endDate = action.payload.endDate;
                    u.startTime = action.payload.startTime;
                    u.endTime = action.payload.endTime;
                    u.noOfHours = action.payload.noOfHours;
                    u.reason = action.payload.reason;
                    return u;
                }
                return u;
            });
        },
        deleteCalendarLeave: (state, action) => {
            state.leaves = state.leaves.filter((u: any) => u.leaveId !== action.payload.leaveId);
        },
        removeCalendarLeave: state => {
            state.leaves = [];
            state.status = 'loading';
        },
    },
    extraReducers: {
        [getCalendar.pending]: state => {
            state.status = 'loading';
        },
        [getCalendar.fulfilled]: (state, action) => {
            state.leaves = action.payload;
            state.status = 'success';
        },
        [getCalendar.rejected]: state => {
            state.status = 'failed';
        },
    },
});

export const { editCalendarLeave, deleteCalendarLeave, removeCalendarLeave } = calendarSlice.actions;
export default calendarSlice.reducer;
