import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import bgImg from './bgImg.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            width: '100%',
            height: 70,
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0px 100px',
            zIndex: 99,
            '@media screen and (max-width: 650px)': {
                padding: '0 20px',
            },
        },
        counter: {
            width: 50,
            height: 50,
            backgroundColor: 'whitesmoke',
            color: theme.palette.primary.main,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        bgImage: {
            height: '100vh',
            width: '100%',
            padding: '0px 100px',
            background: `url("${bgImg}")`,
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'left',
            color: '#fff',
        },
        heading: {
            fontFamily: theme.typography.fontFamily,
            color: '#fff',
            fontSize: 40,
            fontWeight: theme.typography.fontWeightBold,
        },
        subtitle: {
            fontFamily: theme.typography.fontFamily,
            fontStyle: 'italic',
            fontSize: 20,
            fontWeight: theme.typography.fontWeightBold,
        },
        title: {
            fontFamily: 'Rye',
            color: theme.palette.secondary.main,
        },
        body: {
            width: '100%',
            height: '100vh',
        },
        main: {
            padding: '20px 100px',
            width: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            '@media screen and (max-width: 650px)': {
                padding: 20,
            },
        },
        mainPaper: {
            padding: '20px 50px',
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            '@media screen and (max-width: 650px)': {
                padding: 20,
            },
        },
        imageDiv: {
            width: '150px',
            height: '150px',
        },
        img: {
            maxWidth: '100%',
        },
        paper: {
            width: '400px',
            height: 'auto',
            background: 'transparent',
            padding: '10px 20px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '@media screen and (max-width: 650px)': {
                height: 'auto',
            },
        },
        teamLogoSmall: {
            maxWidth: '100%',
            height: 80,
            marginBottom: 5,
        },
        para: {
            marginTop: 10,
            width: '52%',
        },
        footerTag: {
            width: '100%',
            height: 60,
            padding: '20px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ABB2B9',
            '@media screen and (max-width: 650px)': {
                padding: '20px 0px',
                justifyContent: 'flex-start',
            },
        },
        footerTagAuth: {
            width: '100%',
            height: 60,
            padding: '20px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ABB2B9',
            '@media screen and (max-width: 650px)': {
                padding: '20px 0px',
                justifyContent: 'center',
            },
        },
        note: {
            padding: '20px 0px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        teamLogo: {
            width: 150,
            height: 25,
        },
        formControl: {
            color: '#A6ACAF',
            width: '57%',
            margin: '0px 20px',
            '@media only screen and (max-width: 650px)': {
                width: '100%',
                margin: '10px 0px',
            },
        },
        formControl2: {
            color: '#A6ACAF',
            width: 'auto',
            margin: '0px 10px',
            '@media only screen and (max-width: 650px)': {
                width: '100%',
                margin: '10px 0px',
            },
        },
        formControlLeft: {
            color: '#A6ACAF',
            width: '100%',
            margin: 0,
            '@media only screen and (max-width: 650px)': {
                width: '100%',
            },
        },
        formControlRight: {
            color: '#A6ACAF',
            width: '100%',
            margin: 0,
            '@media only screen and (max-width: 650px)': {
                width: '100%',
            },
        },
        formLabel: {
            display: 'flex',
            flexDirection: 'column',
            paddingRight: 20,
            '@media screen and (max-width: 650px)': {
                width: '100%',
            },
        },
        field: {
            padding: '10px 0px',
            '@media screen and (max-width: 650px)': {
                padding: '10px 0px',
            },
        },
        smallField: {
            padding: 0,
            '@media screen and (max-width: 650px)': {
                padding: '10px 0px',
            },
        },
        formLabelForm: {
            width: '100%',
            textAlign: 'left',
            color: 'black',
            fontWeight: 700,
            fontSize: '15px',
            opacity: 0.6,
            '@media (max-width: 650px)': {
                textAlign: 'left',
                paddingBottom: 0,
            },
        },
        p: {
            lineHeight: '20px',
            color: '#ABB2B9',
        },
        label: {
            color: 'black',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            opacity: 0.6,
            zIndex: 0,
        },
        content: {
            padding: '20px 0px',
            display: 'flex',
            alignItems: 'flex-start',
        },
        contentCenter: {
            padding: '20px 0px',
            display: 'flex',
            alignItems: 'center',
        },
        menuItem: {
            backgroundColor: '#fff',
            '&:focus': {
                backgroundColor: theme.palette.primary.light,
                color: '#fff',
            },
        },
        smallIBox: {
            width: '150px',
            margin: '0px 20px',
            '@media (max-width: 650px)': {
                width: '100%',
                margin: '10px 0px',
            },
        },
        daysLeave: {
            width: '150px',
            margin: '0px 20px',
            '@media (max-width: 650px)': {
                width: '100%',
                margin: '10px 0px',
            },
        },
        auth: {
            width: '100%',
            height: 'auto',
            // margin: '40px 0',
            // marginTop: '100px',
            paddingTop: '150px',
            display: 'flex',
            flexDirection: 'column',
            '@media (max-width: 1250px) and (min-width: 651px)': {
                width: '100%',
                paddingTop: '80px',
            },
            '@media (max-width: 650px)': {
                width: '100%',
                paddingTop: '80px',
            },
        },
        authGoogle: {
            width: '100%',
            height: '80vh',
            display: 'flex',
            flexDirection: 'row',
            // backgroundImage: 'url("googleBanner.png")',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            // backgroundPosition: 'bottom',
            padding: '50px 100px',
            '@media (max-width: 1250px) and (min-width: 651px)': {
                width: '100%',
                // padding: '10px 50px',
                flexDirection: 'column',
                backgroundImage: 'none',
            },
            '@media (max-width: 650px)': {
                width: '100%',
                padding: '10px 20px',
                flexDirection: 'column',
                backgroundImage: 'none',
            },
        },
        authGoogleLeft: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            '@media (max-width: 850px)': {
                width: '100%',
            },
        },
        authGoogleRight: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        googleHeading: {
            width: '100%',
            marginBottom: '20px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '40px',
            color: '#283747',
            '@media (max-width: 650px)': {
                fontSize: '30px',
            },
        },
        authGoogleImage: {
            maxWidth: '100%',
            height: '400px',
            '@media screen and (max-width: 650px)': {
                width: '100%',
                height: '300px',
            },
        },
        googleButton: {
            width: '300px',
            height: '40px',
            backgroundColor: '#374785',
            color: '#fff',
            fontWeight: 500,
            borderRadius: 3,
            textTransform: 'capitalize',
            fontStretch: 'standard',
            fontSize: 14,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '@media screen and (max-width: 650px)': {
                width: '80%',
            },
        },
        googleTitle: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            letterSpacing: 1,
            fontFamily: theme.typography.fontFamily,
            fontSize: 15,
        },
        googleIconDiv: {
            height: '100%',
            width: '50px',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid #374785',
            borderRadius: 5,
        },
        googleImage: {
            width: '30px',
            height: '30px',
        },
        card: {
            width: '100%',
            height: '60vh',
            padding: '20px 100px',
            '@media screen and (max-width: 650px)': {
                padding: '10px 20px',
                height: 'auto',
            },
        },
        googlePaper: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '20px',
            boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.3)',
            fontFamily: theme.typography.fontFamily,
            '@media screen and (max-width: 850px)': {
                flexDirection: 'column',
                height: 'auto',
            },
        },
        paperLeft: {
            width: '100%',
            height: '100%',
            color: 'whitesmoke',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundImage: 'url("leftbg.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '20px 0px 0px 20px',
            padding: '20px 50px',
            '@media screen and (max-width: 850px)': {
                height: '400px',
                padding: '10px 10px',
                borderRadius: '20px 20px 0px 0px',
            },
        },
        paperTitle: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'capitalize',
            fontSize: '25px',
        },
        paperDesc: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            textTransform: 'capitalize',
            fontSize: '18px',
        },
        paperHeader: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'capitalize',
            fontSize: '28px',
        },
        paperRight: {
            width: '100%',
            height: '100%',
            padding: '20px 50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (max-width: 850px)': {
                height: '400px',
                padding: '10px 10px',
            },
        },
        signUpBtn: {
            width: '300px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '5px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: 15,
            textTransform: 'capitalize',
            letterSpacing: 1,
            marginBottom: 10,
            cursor: 'pointer',
            '@media screen and (max-width: 650px)': {
                width: '80%',
            },
        },
        or: {
            margin: '20px',
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
        },
        hr: {
            width: '80%',
            height: '1px',
            color: theme.palette.primary.contrastText,
            '@media (max-width: 650px)': {
                width: '90%',
            },
        },
        atheader: {
            width: '100%',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            '@media (max-width: 650px)': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        authdata: {
            width: '100%',
            height: '100%',
            display: 'flex',
            '@media (max-width: 650px)': {
                flexDirection: 'column',
            },
        },
        authFirst: {
            padding: '50px 0px',
            width: '49%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            '@media (max-width: 650px)': {
                width: '100%',
                padding: '20px 0px',
                justifyContent: 'space-evenly',
            },
        },
        authSecond: {
            padding: '50px 0px',
            width: '49%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            '@media (max-width: 650px)': {
                width: '100%',
                padding: '20px 0px',
                height: 'auto',
                justifyContent: 'space-evenly',
            },
        },
        verticalLine: {
            width: '2%',
            height: '100%',
            padding: '20px 0px',
            '@media (max-width: 650px)': {
                display: 'none',
            },
        },
        line: {
            height: '100%',
            width: '1px',
            backgroundColor: '#E5E8E8',
        },
        h1: {
            fontWeight: 600,
            color: '#000',
            opacity: 0.5,
        },
        h6: {
            color: '#ABB2B9',
        },
        btn: {
            width: '100%',
            marginTop: 50,
            fontFamily: theme.typography.fontFamily,
            textTransform: 'capitalize',
            backgroundColor: theme.palette.primary.main,
            fontSize: 18,
            fontWeight: 700,
            borderRadius: 30,
            '&:hover': {
                outline: 'none',
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                marginTop: 0,
            },
            // marginRight: '10px',
        },
        textfield: {
            margin: '10px 0',
        },
        alignCenter: {
            display: 'flex',
            justifyContent: 'center',
            '@media (max-width: 650px)': {
                justifyContent: 'flex-start',
            },
        },
        paraCenter: {
            padding: '20px 100px',
            '@media (max-width: 650px)': {
                padding: '10px 0px',
            },
        },
        emailsDiv: {
            width: '100%',
            border: `1px solid ${theme.palette.secondary.main}`,
            padding: '10px 10px',
        },
        chip: {
            padding: '5px 5px',
            textDecoration: 'none',
            listStyle: 'none',
        },
        otpverify: {
            padding: '20px 100px',
            '@media (max-width: 650px)': {
                padding: '10px 20px',
            },
        },
        skip: {
            color: theme.palette.primary.light,
            fontWeight: 600,
            fontSize: 15,
            textTransform: 'uppercase',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        googleSignInDiv: {
            height: '70%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '100px',
            '@media screen and (max-width: 650px)': {
                padding: '50px 20px',
            },
        },
        verifyMessage: {
            margin: '20px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.success.main,
            fontFamily: theme.typography.fontFamily,
            textAlign: 'center',
        },
        verifyIcon: {
            color: theme.palette.success.main,
            marginRight: '10px',
        },
        btnPrimary: {
            height: 35,
            width: 100,
            margin: '0px 5px',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                outline: 'none',
            },
        },
        btnOutlined: {
            height: 35,
            width: 100,
            margin: '0px 5px',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            '&:focus': {
                outline: 'none',
            },
        },
    }),
);
export default useStyles;
