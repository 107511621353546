import React, { useState, useEffect, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { useStyles } from './ImportHolidayStyle';
import { Button, Hidden } from '@material-ui/core';
import { SelectComponent } from './FormInputs';
import { Typography, FormLabel, FormControl, FormGroup, FormControlLabel, Checkbox, useTheme } from '@material-ui/core';
import {
    importHoliday1,
    // importHoliday2
} from '../../helpers/hasuraapi';
import { ToStringFormat } from '../../helpers/date';
import AuthContext from '../../context/AuthContext';
import { PopUp } from '../Modal/Modal';
import MyModal from '../Modal/Modal';
import { createHoliday } from '../../helpers/hasuraapi';
// import { toYearFormat } from '../../helpers/date';
import { days } from '../../helpers/constants';
// import { getHolidays } from '../Redux/holidaysReducer';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
// import { useSelector } from 'react-redux';

interface Props {
    Addholidaybtn?: string;
    rows?: unknown[];
    importList?: any[];
    holidayName?: string;
    date?: string;
    setIsUpdating?: any;
}

// interface Holiday {
//     holidayName: string;
//     date?: string;
//     day?: string;
//     startDate?: string;
//     endDate?: string;
//     year?: string;
//     isMultidayHoliday?: boolean;
//     organizationId?: string;
//     setIsUpdating?: any;
// }

const currentYear = new Date().getFullYear();
const nextYear = currentYear + 1;

const Year = [
    {
        label: `${currentYear}`,
        value: `${currentYear}`,
    },
    {
        label: `${nextYear}`,
        value: `${nextYear}`,
    },
];

export default function ImportHoliday(props: Props) {
    const classes = useStyles();
    const theme = useTheme();
    // const dispatch = useDispatch();
    const mycontext = useContext(AuthContext);
    const [year, setYear] = useState<any>(currentYear);
    const [modal, setModal] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [closeAll, setCloseAll] = useState(false);
    const [holidayslistCY, setHolidayslistCY] = useState<any>([]);
    const [holidayslistNY, setHolidayslistNY] = useState<any>([]);
    const [error, setError] = useState<boolean>(false);
    const [selectedCheckbox, setSelectCheckbox] = useState<any>([]);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertm, setAlertm] = useState<boolean>(false);
    const [severity, setSeverity] = useState<string>('');
    const [list, setList] = useState<any>([]);
    const [holidaysBtn, setHolidaysBtn] = useState<boolean>(false);

    // const holidays: any = useSelector((state: any) => state.holidays);

    /* istanbul ignore next */
    const handleChangeCheckbox = (row: any) => {
        // console.log('row', row, selectedCheckbox);
        if (selectedCheckbox.find((obj: any) => obj.holidayName === row.holidayName) !== undefined) {
            setSelectCheckbox((prev: any) => {
                const remaining = prev.filter((obj: any) => obj.holidayName !== row.holidayName);
                return remaining;
            });
        } else {
            setSelectCheckbox((prev: any) => {
                const selected = [...prev, row];
                return selected;
            });
        }
        return false;
    };

    const error20 = [holidayslistCY?.checked].filter(v => v).length < 1;
    const toggle = () => {
        setModal(!modal);
        setError(false);
        setYear('');
    };
    /* istanbul ignore next */
    const toggleNested = () => {
        setNestedModal(!nestedModal);
        setCloseAll(false);
        setError(false);
    };
    /* istanbul ignore next */
    const findHolidays = () => {
        if (year === '') {
            setError(true);
        } else {
            if (holidayslistNY?.length === 0 && year === `${currentYear}`) {
                setHolidaysBtn(true);
            } else if (holidayslistCY?.length === 0 && year === `${nextYear}`) {
                setHolidaysBtn(true);
            } else {
                setHolidaysBtn(false);
            }
            toggleNested();
            setError(false);
        }
    };

    /* istanbul ignore next */
    const toggleNestedAll = () => {
        setNestedModal(!nestedModal);
        setCloseAll(true);
        setError(false);
        setModal(false);
    };

    /* istanbul ignore next */
    useEffect(() => {
        let isMounted = true;
        async function getData() {
            // When you want to fetch holidays from API then use importHolida2
            const holidaysCY: any = await importHoliday1(
                mycontext.token,
                // mycontext.country.countryCode,
                mycontext.country.countryCode.toLowerCase(),
                `${currentYear}`,
            );
            const holidaysNY: any = await importHoliday1(
                mycontext.token,
                mycontext.country.countryCode.toLowerCase(),
                `${nextYear}`,
            );
            // console.log("holiday:", holidaysCY);
            if (props.importList) {
                setList(props.importList);
            }

            if (holidaysCY?.error || holidaysNY?.error) {
                setError(true);
            } else {
                setHolidayslistCY(holidaysCY);
                setHolidayslistNY(holidaysNY);
            }
        }
        if (isMounted) {
            getData();
        }
        return () => {
            isMounted = false;
        };
    }, [mycontext.token, mycontext.country.countryCode, props.importList]);

    /* istanbul ignore next */
    const submit = async () => {
        props.setIsUpdating(true);
        // console.log("holidays ", props.importList);
        setSelectCheckbox(props.importList);
        setList(props.importList);
        if (selectedCheckbox.length === 0) {
            setError(true);
            props.setIsUpdating(false);
        } else {
            selectedCheckbox.map(async (selectedHoliday: any) => {
                // const obj = {
                //     holidayName: selectedHoliday.holidayName,
                //     date: selectedHoliday.date !== null ? selectedHoliday.date : selectedHoliday.startDate,
                //     day: selectedHoliday.day,
                //     startDate: selectedHoliday.startDate !== null ? selectedHoliday.startDate : selectedHoliday.date,
                //     endDate: selectedHoliday.endDate !== null ? selectedHoliday.endDate : selectedHoliday.date,
                //     year:
                //         selectedHoliday.date !== null
                //             ? toYearFormat(selectedHoliday.date)
                //             : toYearFormat(selectedHoliday.startDate),
                //     isMultidayHoliday: selectedHoliday.isMultidayHoliday,
                //     organizationId: selectedHoliday.organizationId,
                // };
                const obj = {
                    holidayName: selectedHoliday.holidayName,
                    date: selectedHoliday?.date,
                    day: selectedHoliday?.day,
                    startDate: selectedHoliday.startDate,
                    endDate: selectedHoliday.endDate,
                    year: year,
                    isMultidayHoliday: false,
                    organizationId: selectedHoliday.organizationId,
                };

                try {
                    if (
                        list.some(
                            (h: any) =>
                                (h.holidayName === obj.holidayName &&
                                    h.date === obj.date &&
                                    h.isMultidayHoliday === obj.isMultidayHoliday) ||
                                (h.holidayName === obj.holidayName &&
                                    h.startDate === obj.startDate &&
                                    h.endDate === obj.endDate &&
                                    h.isMultidayHoliday === obj.isMultidayHoliday),
                        )
                    ) {
                        setModal(false);
                        setCloseAll(true);
                        setNestedModal(!nestedModal);
                        setSeverity('error');
                        setAlertm(true);
                        setAlertMessage('Holiday Already Exists!');
                        props.setIsUpdating(false);
                    } else {
                        const data = await createHoliday(obj, mycontext.token);
                        if (data.error) {
                            setModal(false);
                            setCloseAll(true);
                            setNestedModal(!nestedModal);
                            setSeverity('error');
                            setAlertm(true);
                            setAlertMessage('Failed to import Holiday!');
                            props.setIsUpdating(false);
                        } else {
                            // await getHolidays();
                            setModal(false);
                            setCloseAll(true);
                            setNestedModal(!nestedModal);
                            setSeverity('success');
                            setAlertm(true);
                            setAlertMessage('Holiday Imported Successfully!');
                            // dispatch(
                            //     getHolidays({
                            //         organizationId: mycontext.organizationId,
                            //         token: mycontext.token,
                            //     }),
                            // );
                            props.setIsUpdating(false);
                            // window.location.reload();
                        }
                    }
                } catch (err) {
                    setSeverity('error');
                    setAlertm(true);
                    setAlertMessage(err.message);
                }
            });
        }
    };
    return (
        <div>
            <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
            <Hidden smDown>
                <Button variant="contained" color="primary" onClick={toggle} className={classes.btn}>
                    {props.Addholidaybtn}
                </Button>
            </Hidden>
            <div className={classes.screenSize}>
                <Button
                    variant="contained"
                    color="primary"
                    disableRipple={true}
                    onClick={toggle}
                    className={classes.btnFixed}
                >
                    {props.Addholidaybtn}
                </Button>
            </div>
            <MyModal open={modal} setOpen={toggle} onModalClosed={toggle}>
                <div className={classes.paper}>
                    <Form>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <FormLabel className={classes.label1}>Import Holidays</FormLabel>
                            {error ? (
                                <>
                                    <FormLabel component="legend" error id="selecterror">
                                        Select an appropriate option
                                    </FormLabel>
                                </>
                            ) : (
                                ''
                            )}
                            <div style={{ backgroundColor: '#f2f3f4' }}>
                                <SelectComponent
                                    value={year}
                                    setValue={setYear}
                                    options={Year}
                                    placeholder="Select a year"
                                    id="selectyear"
                                />
                            </div>
                        </Form.Group>
                    </Form>

                    <br />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button variant="outlined" color="primary" onClick={toggle} className={classes.btnOutlined}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={findHolidays}
                            className={classes.btn}
                            id="findholiday"
                        >
                            Find Holidays
                        </Button>
                    </div>
                </div>
            </MyModal>
            <MyModal
                open={nestedModal}
                setOpen={toggleNested}
                onClosed={/* istanbul ignore next */ closeAll ? toggle : undefined}
            >
                <>
                    <div className={classes.root}>
                        <Form>
                            <Form.Group controlId="exampleForm.SelectCustom">
                                <FormLabel className={classes.label1}>Import Holidays</FormLabel>
                                {error ? (
                                    <>
                                        <FormLabel component="legend" error id="selecterror">
                                            Select an appropriate option
                                        </FormLabel>
                                    </>
                                ) : (
                                    ''
                                )}
                                <div style={{ backgroundColor: '#f2f3f4' }}>
                                    <SelectComponent
                                        value={year}
                                        setValue={setYear}
                                        options={Year}
                                        placeholder="Select a year"
                                        id="selectyear"
                                    />
                                </div>
                            </Form.Group>
                        </Form>
                        {year === `${currentYear}` ? (
                            <>
                                <FormControl
                                    required
                                    error={error20}
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    {/* istanbul ignore next */ error ? (
                                        <>
                                            <FormLabel component="legend" id="MinimumSelectError">
                                                Select atleast one holiday
                                            </FormLabel>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <FormGroup>
                                        {holidayslistCY?.length === 0 ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: '-10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <img
                                                    src={upcomingTimeoff}
                                                    alt="No Holidays Found"
                                                    className={classes.notfoundImage}
                                                />
                                                <Typography className={classes.fontsty}>No Holidays Found</Typography>
                                            </div>
                                        ) : (
                                            <div className={classes.sidebysideDiv}>
                                                <Typography variant="h6" gutterBottom className={classes.heading}>
                                                    We found {holidayslistCY.length} holidays for{' '}
                                                    {mycontext.location.country}. Please select ones that you would want
                                                    to import.
                                                </Typography>
                                                {holidayslistCY?.map((row: any, i: number) =>
                                                    list.some(
                                                        (h: any) =>
                                                            //  h.holidayName === row.name && h.startDate === row.date.iso,
                                                            h.holidayName === row.holidayName &&
                                                            h.startDate === row.holidayDate,
                                                    ) ? (
                                                        <div key={i}>
                                                            <FormControlLabel
                                                                data-testid="formcontrol"
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={true}
                                                                        disabled={true}
                                                                        style={{
                                                                            color: theme.palette.primary.contrastText,
                                                                        }}
                                                                        onChange={() =>
                                                                            handleChangeCheckbox({
                                                                                organizationId:
                                                                                    mycontext.organizationId,
                                                                                //  holidayName: row.name,
                                                                                holidayName: row.holidayName,
                                                                                day:
                                                                                    days[
                                                                                        new Date(
                                                                                            //  `${row.date.iso}`,
                                                                                            `${row.holidayDate}`,
                                                                                        ).getDay()
                                                                                    ],
                                                                                date: row.holidayDate,
                                                                                year: row.holidayYear,
                                                                                startDate: row.holidayDate,
                                                                                endDate: row.holidayDate,
                                                                                isMultidayHoliday: false,
                                                                                // date: row.date.iso,
                                                                                // year: row.date.datetime.year,
                                                                                // startDate: row.date.iso,
                                                                                // endDate: row.date.iso,
                                                                                // isMultidayHoliday: false
                                                                            })
                                                                        }
                                                                        name="holiday 1 of current year"
                                                                    />
                                                                }
                                                                label={
                                                                    //  row.name +
                                                                    // ' | ' +
                                                                    // ToStringFormat(row.date.iso, mycontext.dateFormat)
                                                                    row.holidayName +
                                                                    ' | ' +
                                                                    ToStringFormat(
                                                                        row.holidayDate,
                                                                        mycontext.dateFormat,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div key={i}>
                                                            <FormControlLabel
                                                                id={'added' + i}
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={
                                                                            selectedCheckbox.find(
                                                                                (r: any) =>
                                                                                    // r.holidayName === row.name &&
                                                                                    // r.date === row.date.iso,
                                                                                    r.holidayName === row.holidayName &&
                                                                                    r.date === row.holidayDate,
                                                                            ) === undefined
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        onChange={() =>
                                                                            handleChangeCheckbox({
                                                                                organizationId:
                                                                                    mycontext.organizationId,
                                                                                holidayName: row.holidayName,
                                                                                day:
                                                                                    days[
                                                                                        new Date(
                                                                                            `${row.holidayDate}`,
                                                                                        ).getDay()
                                                                                    ],
                                                                                date: row.holidayDate,
                                                                                year: row.holidayYear,
                                                                                startDate: row.holidayDate,
                                                                                endDate: row.holidayDate,
                                                                                isMultidayHoliday: false,
                                                                                //old code
                                                                                // holidayName: row.name,
                                                                                // day:
                                                                                //     days[
                                                                                //         new Date(
                                                                                //             `${row.date.iso}`,
                                                                                //         ).getDay()
                                                                                //     ],
                                                                                // date: row.date.iso,
                                                                                // year: row.date.datetime.year,
                                                                                // startDate: row.date.iso,
                                                                                // endDate: row.date.iso,
                                                                                // isMultidayHoliday: false,
                                                                            })
                                                                        }
                                                                        name="holiday 1 of current year"
                                                                    />
                                                                }
                                                                label={
                                                                    // row.name +
                                                                    // ' | ' +
                                                                    // ToStringFormat(row.date.iso, mycontext.dateFormat)
                                                                    row.holidayName +
                                                                    ' | ' +
                                                                    ToStringFormat(
                                                                        row.holidayDate,
                                                                        mycontext.dateFormat,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormControl>
                            </>
                        ) : (
                            <>
                                <FormControl
                                    required
                                    component="fieldset"
                                    className={classes.formControl}
                                    error={error20}
                                >
                                    {error ? (
                                        <>
                                            <FormLabel component="legend" id="MinimumSelectError">
                                                Select atleast one holiday
                                            </FormLabel>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <FormGroup>
                                        {holidayslistNY?.length === 0 ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: '-10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <img
                                                    src={upcomingTimeoff}
                                                    alt="No Holidays Found"
                                                    className={classes.notfoundImage}
                                                />
                                                <Typography className={classes.fontsty}>No Holidays Found</Typography>
                                            </div>
                                        ) : (
                                            <div className={classes.sidebysideDiv}>
                                                <Typography variant="h6" gutterBottom className={classes.heading}>
                                                    We found {holidayslistNY.length} holidays for{' '}
                                                    {mycontext.location.country}. Please select ones that you would want
                                                    to import.
                                                </Typography>
                                                {holidayslistNY?.map((row: any, i: number) =>
                                                    list.some(
                                                        (h: any) =>
                                                            // h.holidayName === row.name && h.startDate === row.date.iso,
                                                            h.holidayName === row.holidayName &&
                                                            h.startDate === row.holidayDate,
                                                    ) ? (
                                                        <div key={i}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={true}
                                                                        disabled={true}
                                                                        style={{
                                                                            color: theme.palette.primary.contrastText,
                                                                        }}
                                                                        onChange={() =>
                                                                            handleChangeCheckbox({
                                                                                organizationId:
                                                                                    mycontext.organizationId,
                                                                                holidayName: row.holidayName,
                                                                                day:
                                                                                    days[
                                                                                        new Date(
                                                                                            `${row.holidayDate}`,
                                                                                        ).getDay()
                                                                                    ],
                                                                                date: row.holidayDate,
                                                                                year: row.holidayYear,
                                                                                startDate: row.holidayDate,
                                                                                endDate: row.holidayDate,
                                                                                isMultidayHoliday: false,

                                                                                //old code
                                                                                // holidayName: row.name,
                                                                                // day:
                                                                                //     days[
                                                                                //         new Date(
                                                                                //             `${row.date.iso}`,
                                                                                //         ).getDay()
                                                                                //     ],
                                                                                // date: row.date.iso,
                                                                                // year: row.date.datetime.year,
                                                                                // startDate: row.date.iso,
                                                                                // endDate: row.date.iso,
                                                                                // isMultidayHoliday: false,
                                                                            })
                                                                        }
                                                                        name="holiday 1 of current year"
                                                                    />
                                                                }
                                                                label={
                                                                    // row.name +
                                                                    // ' | ' +
                                                                    // ToStringFormat(row.date.iso, mycontext.dateFormat)
                                                                    row.holidayName +
                                                                    ' | ' +
                                                                    ToStringFormat(
                                                                        row.holidayDate,
                                                                        mycontext.dateFormat,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div key={i}>
                                                            <FormControlLabel
                                                                id={'added' + i}
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={
                                                                            selectedCheckbox.find(
                                                                                (r: any) =>
                                                                                    // r.holidayName === row.name &&
                                                                                    // r.date === row.date.iso,
                                                                                    r.holidayName === row.holidayName &&
                                                                                    r.date === row.holidayDate,
                                                                            ) === undefined
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        onChange={() =>
                                                                            handleChangeCheckbox({
                                                                                organizationId:
                                                                                    mycontext.organizationId,
                                                                                holidayName: row.holidayName,
                                                                                day:
                                                                                    days[
                                                                                        new Date(
                                                                                            `${row.holidayDate}`,
                                                                                        ).getDay()
                                                                                    ],
                                                                                date: row.holidayDate,
                                                                                year: row.holidayYear,
                                                                                startDate: row.holidayDate,
                                                                                endDate: row.holidayDate,
                                                                                isMultidayHoliday: false,

                                                                                //old code
                                                                                // holidayName: row.name,
                                                                                // day:
                                                                                //     days[
                                                                                //         new Date(
                                                                                //             `${row.date.iso}`,
                                                                                //         ).getDay()
                                                                                //     ],
                                                                                // date: row.date.iso,
                                                                                // year: row.date.datetime.year,
                                                                                // startDate: row.date.iso,
                                                                                // endDate: row.date.iso,
                                                                                // isMultidayHoliday: false,
                                                                            })
                                                                        }
                                                                        name="holiday 1 of current year"
                                                                    />
                                                                }
                                                                label={
                                                                    // row.name +
                                                                    // ' | ' +
                                                                    // ToStringFormat(row.date.iso, mycontext.dateFormat)
                                                                    row.holidayName +
                                                                    ' | ' +
                                                                    ToStringFormat(
                                                                        row.holidayDate,
                                                                        mycontext.dateFormat,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormControl>
                            </>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={toggleNestedAll}
                                className={classes.btnOutlined}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={holidaysBtn}
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={submit}
                                className={classes.btn}
                                id="importbtn"
                            >
                                Import Holidays
                            </Button>
                        </div>
                    </div>
                </>
            </MyModal>
        </div>
    );
}
