/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';

import Login from 'components/Login/Login';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import RegisterAdminMain from 'components/Admin/registerAdminMain';
import AuthLayout from 'components/Layout/AuthLayout';
// import Auth from 'components/Admin/Auth';
import GoogleSignIn from 'components/Admin/GooleSingIn';
import ErrorPage from 'components/Error/ErrorPage';
import ForgotPassword from 'components/ForgotPassword/main';
import RegisterUser from 'components/Register/registerMain';
import LandingPage from 'components/LandingPage/LandingPage';
import Admin from 'components/LandingPage/Admin';
import Employee from 'components/LandingPage/Employee';
import Faq from 'components/LandingPage/Faq';
import Help from 'components/LandingPage/Help';
import UserGuide from 'components/LandingPage/UserGuide';
import EmployeeGuide from 'components/LandingPage/EmployeeGuide';
import { empdata, admindata, approverdata } from 'components/LandingPage/GuideData';
import Policy from 'components/LandingPage/Policy';
import TeamplusPolicy from 'components/LandingPage/TeamplusPolicy';
import TermsOfService from 'components/LandingPage/TermsOfService';
import RefundCancellation from 'components/LandingPage/RefundCancellation';
import SlackLandingPage from 'components/LandingPage/SlackLandingPage';
import MyOrganizations from 'components/Register/UserOrganizations';
import MyOrganizationsSlack from 'components/Register/UserOrganizationsSlackLogin';
import SlackRegistration from 'components/Admin/slackRegistration';
import RegisterGoogleEmployee from 'components/Register/RegisterGoogleEmployee';
import GoogleSignupEmployee from 'components/Register/GoogleSignupEmployee';
import RegisterGetUser from 'components/Admin/registerGetUserAdmin';
import AuthContext from 'context/AuthContext';
// import useGeolocation from '../Hooks/useGeolocation';
import { getCountries } from 'components/Redux/countryReducer';
import Celebration from 'components/Celebration/Celebration';
import MicrosoftNewRegistration from 'components/Admin/microsoftNewRegistration';

// import useGeolocation from '../Hooks/useGeolocation';

import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function Routes() {
    const mycontext = useContext(AuthContext);
    // const location = useGeolocation();
    const dispatch = useDispatch();
    // React.useMemo(() => {
    //     if (location.loaded) {
    //         mycontext.addLocation({
    //             country: location.coordinates.country,
    //             code: location.coordinates.code,
    //             timezone: location.coordinates.timezone,
    //         });
    //     }
    //     // console.log('my country ', location.coordinates.country);
    // }, [location]);
    // React.useEffect(() => {
    //     dispatch(getCountries());
    //     mycontext.addCountry(mycontext.country);
    // }, []);

    React.useEffect(() => {
        // console.log(mycontext);
        if (mycontext.token) {
            const obj = {
                token: mycontext.token,
            };
            dispatch(getCountries(obj));
            mycontext.addCountry(mycontext.country);
        }
    }, [mycontext.token]);
    return (
        <div style={{ background: '#fff', minHeight: '100vh' }}>
            <Switch>
                {!mycontext.isLogedIn && <Redirect exact from="/settings" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/profile" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/teams" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/dashboard" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/add/leave" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/requests" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/leave" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/reports" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/profile" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/user/profile" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/employees" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/employees/profile" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/standups" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/holiday" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/thankyou" to="/login" />}
                {/* {!mycontext.isLogedIn && <Redirect exact from="/googleSignUpEmployee" to="/login" />} */}
                {!mycontext.isLogedIn && <Redirect exact from="/registerGetUser" to="/login" />}
                {!mycontext.isLogedIn && <Redirect exact from="/" to="/login" />}
                {/* {!mycontext.isLogedIn && (
                    <Route exact path="/">
                        <LandingPage />
                    </Route>
                )} */}
                {!mycontext.isLogedIn && (
                    <Route exact path="/login">
                        <AuthLayout btn="login">
                            <Login heading="Sign In" avatar={<LockOutlinedIcon />} btn="Sign In" link="Sign Up" />
                        </AuthLayout>
                    </Route>
                )}
                {!mycontext.isLogedIn && (
                    <Route exact path="/timeoff">
                        <AuthLayout btn="login">
                            <Login
                                heading="Sign In"
                                avatar={<LockOutlinedIcon />}
                                btn="Sign In"
                                link="Sign Up"
                                botType="timeoff"
                            />
                        </AuthLayout>
                    </Route>
                )}
                {!mycontext.isLogedIn && (
                    <Route exact path="/poll">
                        <AuthLayout btn="login">
                            <Login
                                heading="Sign In"
                                avatar={<LockOutlinedIcon />}
                                btn="Sign In"
                                link="Sign Up"
                                botType="poll"
                            />
                        </AuthLayout>
                    </Route>
                )}
                {!mycontext.isLogedIn && (
                    <Route exact path="/engage">
                        <AuthLayout btn="login">
                            <Login
                                heading="Sign In"
                                avatar={<LockOutlinedIcon />}
                                btn="Sign In"
                                link="Sign Up"
                                botType="engage"
                            />
                        </AuthLayout>
                    </Route>
                )}
                {!mycontext.isLogedIn && (
                    <Route exact path="/standup">
                        <AuthLayout btn="login">
                            <Login
                                heading="Sign In"
                                avatar={<LockOutlinedIcon />}
                                btn="Sign In"
                                link="Sign Up"
                                botType="standup"
                            />
                        </AuthLayout>
                    </Route>
                )}
                <Route exact path="/myorganizations">
                    <MyOrganizations />
                </Route>
                <Route exact path="/mymsteams">
                    <MicrosoftNewRegistration />
                </Route>
                <Route exact path="/myorganizationsslack">
                    <MyOrganizationsSlack />
                </Route>
                {!mycontext.isLogedIn && (
                    <Route exact path="/registerUser">
                        <AuthLayout btn="register">
                            <RegisterUser />
                        </AuthLayout>
                    </Route>
                )}
                {!mycontext.isLogedIn && (
                    <Route exact path="/forgotpassword">
                        <AuthLayout btn="Forgot password">
                            <ForgotPassword />
                        </AuthLayout>
                    </Route>
                )}
                <Route exact path="/slackRegistration">
                    <SlackRegistration />
                </Route>
                <Route exact path="/registerGoogleEmployee">
                    <RegisterGoogleEmployee />
                </Route>
                {!mycontext.isLogedIn && (
                    <Route exact path="/googleSignUpEmployee">
                        <GoogleSignupEmployee />
                    </Route>
                )}
                <Route exact path="/registerGetUser">
                    <RegisterGetUser />
                </Route>
                <Route exact path="/slackRegistration">
                    <SlackRegistration />
                </Route>
                <Route exact path="/">
                    <LandingPage />
                </Route>
                <Route exact path="/admin">
                    <Admin />
                </Route>
                <Route exact path="/celebration">
                    <Celebration />
                </Route>
                <Route exact path="/employee">
                    <Employee />
                </Route>
                <Route exact path="/faqs">
                    <Faq />
                </Route>
                <Route exact path="/help">
                    <Help />
                </Route>
                <Route exact path="/userguide">
                    <UserGuide />
                </Route>
                <Route exact path="/adminguide">
                    <EmployeeGuide data={admindata} title="Admin Guide" />
                </Route>
                <Route exact path="/employeeguide">
                    <EmployeeGuide data={empdata} title="Employee Guide" />
                </Route>
                <Route exact path="/approverguide">
                    <EmployeeGuide data={approverdata} title="Approver Guide" />
                </Route>
                <Route exact path="/privacypolicy">
                    <TeamplusPolicy />
                </Route>
                <Route exact path="/privacy-policy">
                    <Policy />
                </Route>
                <Route exact path="/terms-of-service">
                    <TermsOfService />
                </Route>
                <Route exact path="/refundpolicy">
                    <RefundCancellation />
                </Route>
                <Route exact path="/teamplus-bot">
                    <SlackLandingPage />
                </Route>
                {!mycontext.isLogedIn && (
                    <Route exact path="/googleSignIn">
                        <GoogleSignIn />
                    </Route>
                )}
                <Route exact path="/main">
                    <AuthLayout btn="login">
                        <Login heading="Sign In" avatar={<LockOutlinedIcon />} btn="Sign In" link="Sign Up" />
                    </AuthLayout>
                </Route>
                {!mycontext.isLogedIn && <ErrorPage />}
            </Switch>
        </div>
    );
}
