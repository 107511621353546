import { createStyles, makeStyles, Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnOutlined: {
            height: 35,
            textTransform: 'capitalize',
            marginRight: 10,
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
        },
        btn: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        formLabel: {
            color: theme.palette.text.primary,
            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
            marginBottom: 20,
        },
        formLabel1: {
            color: theme.palette.text.primary,
            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
            paddingTop: '10px',
        },
        on: {
            color: '#e8b923',
        },
        off: {
            color: '#ccc',
        },
        inputStyle: {
            '& div': {
                fontSize: '14px',
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.paper,
                },
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
            '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #AAAAAA',
            },
        },
    }),
);
export default useStyles;
