import React, { useContext, useState } from 'react';
import {
    Grid,
    Paper,
    FormLabel,
    TextField,
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    IconButton,
    Button,
    Hidden,
    Tooltip,
    Icon,
} from '@material-ui/core';
import useStyles from './newRegisterStyles';
// import { MBScreenStyle } from './MBScreenStyle';
// import Page1Img from '../../Assets/images/Page1Img.png';
// import udyamo from '../../Assets/images/udyamo.png';
// import passwordVisibility from '../../Assets/icons/PasswordVisibility.png';
// import passwordVisibilityOff from '../../Assets/icons/PasswordVisibilityOff.png';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AuthContext from '../../context/AuthContext';
import { Auth } from 'aws-amplify';
import { PopUp } from '../Modal/Modal';
import { isEmployeeInDatabase } from 'helpers/hasuraapi';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import google from '../Login/google.svg';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

interface NewRegister {
    setPageNumber?: any;
    values?: any;
}

export default function RegisterPartOne(props: NewRegister) {
    const classes = useStyles();
    localStorage.setItem('inviteUrl', window.location.href);
    // const styleclass = MBScreenStyle();
    const mycontext = useContext(AuthContext);
    const [firstNameErrorText, setFirstNameErrorText] = React.useState('');
    const [lastNameErrorText, setLastNameErrorText] = React.useState('');
    const [emailIdErrorText, setEmailIdErrorText] = React.useState('');
    const [passwordErrorText, setPasswordErrorText] = React.useState('');
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [isGoogle, setIsGoogle] = React.useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [severity, setSeverity] = useState<string>('');

    const emailRegex = /^([a-z\d.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;

    const passwordPolicyText =
        'Password must contain atleast:\nAtleast 8 characters\nOne Uppercase letter\nOne lowercase letter\nOne digit [0-9]\n One special character [!@#$%^&*]';

    const isContainsUppercase = /^(?=.*[A-Z])/;
    const isContainsLowercase = /^(?=.*[a-z])/;
    const isContainsNumber = /^(?=.*[0-9])/;
    const isContainsSymbol = /^(?=.*[!@#$%^&*_])/;
    const signIn = async () => {
        await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
        });
        // await mycontext.updateGoogle();

        // await getUser();
    };

    /* istanbul ignore next */
    const handleFirstName = async () => {
        // await props.values.setFirstName((event.target as HTMLInputElement).value);
        if (props.values.firstName.length === 1) {
            setFirstNameErrorText('Your first name should consist of atleast 2 characters');
            return {
                error: true,
                errorText: firstNameErrorText,
            };
        } else if (props.values.firstName === '') {
            setFirstNameErrorText('Firstname cannot be empty, please enter your firstname');
            return {
                error: true,
                errorText: firstNameErrorText,
            };
        } else {
            setFirstNameErrorText('');
            return {
                error: false,
                errorText: '',
            };
        }
    };

    /* istanbul ignore next */
    const handleLastName = async () => {
        // await props.values.setLastName((event.target as HTMLInputElement).value);
        if (props.values.lastName.length === 1) {
            setLastNameErrorText('Your last name should consist of atleast 2 characters');
            return {
                error: true,
                errorText: lastNameErrorText,
            };
        } else if (props.values.lastName === '') {
            setLastNameErrorText('Lastname cannot be empty, please enter your lastname');
            return {
                error: true,
                errorText: lastNameErrorText,
            };
        } else {
            setLastNameErrorText('');
            return {
                error: false,
                errorText: '',
            };
        }
    };

    /* istanbul ignore next */
    const handleEmailId = async () => {
        // await props.values.setEmailId((event.target as HTMLInputElement).value);
        // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (emailRegex.test(props.values.emailId)) {
            setEmailIdErrorText('');
            return {
                error: false,
                errorText: '',
            };
        } else {
            setEmailIdErrorText('Enter a valid email');
            return {
                error: true,
                errorText: emailIdErrorText,
            };
        }
    };

    /* istanbul ignore next */
    const handlePassword = () => {
        // props.values.setPassword((event.target as HTMLInputElement).value)
        if (props.values.password === '') {
            setPasswordErrorText('Password cannot be empty');
            return {
                error: true,
                errorText: passwordErrorText,
            };
        } else if (props.values.password.length < 8) {
            setPasswordErrorText('Password must have atleast 8 characters');
            return {
                error: true,
                errorText: passwordErrorText,
            };
        } else if (!isContainsUppercase.test(props.values.password)) {
            setPasswordErrorText('Password must contain atleast one uppercase letter');
            return {
                error: true,
                errorText: passwordErrorText,
            };
        } else if (!isContainsLowercase.test(props.values.password)) {
            setPasswordErrorText('Password must contain atleast one lowercase letter');
            return {
                error: true,
                errorText: passwordErrorText,
            };
        } else if (!isContainsNumber.test(props.values.password)) {
            setPasswordErrorText('Password must contain atleast one digit');
            return {
                error: true,
                errorText: passwordErrorText,
            };
        } else if (!isContainsSymbol.test(props.values.password)) {
            setPasswordErrorText('Password must contain atleast one special character');
            return {
                error: true,
                errorText: passwordErrorText,
            };
        } else {
            setPasswordErrorText('');
            return {
                error: false,
                errorText: '',
            };
        }
    };

    /* istanbul ignore next */
    const handleClickPassword = () => {
        setShowPassword(!showPassword);
    };

    /* istanbul ignore next */
    const handleConfirmPassword = () => {
        // await props.values.setConfirmPassword((event.target as HTMLInputElement).value)
        if (props.values.password !== props.values.confirmPassword) {
            setConfirmPasswordErrorText('Passwords do not match. Try again.');
            return {
                error: true,
                errorText: confirmPasswordErrorText,
            };
        } else {
            setConfirmPasswordErrorText('');
            return {
                error: false,
                errorText: '',
            };
        }
    };

    /* istanbul ignore next */
    const handleClickConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    /* istanbul ignore next */
    const cognitoRegister = async (e: any) => {
        e.preventDefault();
        if (!isGoogle) {
            try {
                const firstNameCheck = await handleFirstName();
                const lastNameCheck = await handleLastName();
                const emailIdCheck = await handleEmailId();
                const passwordCheck = handlePassword();
                const confirmPasswordCheck = handleConfirmPassword();
                if (firstNameCheck.error === true) {
                    throw new Error(firstNameCheck.errorText);
                } else if (lastNameCheck.error === true) {
                    throw new Error(lastNameCheck.errorText);
                } else if (emailIdCheck.error === true) {
                    throw new Error(emailIdCheck.errorText);
                } else if (passwordCheck.error === true) {
                    throw new Error(passwordCheck.errorText);
                } else if (confirmPasswordCheck.error === true) {
                    throw new Error(confirmPasswordCheck.errorText);
                }
                const signUpResponse = await Auth.signUp({
                    username: props.values.emailId,
                    password: props.values.password,
                    attributes: {
                        email: props.values.emailId,
                    },
                });
                mycontext.registerUser(signUpResponse.user, props.values.password);
                mycontext.addUserId(signUpResponse.userSub);
                setSeverity('success');
                setAlert(true);
                setAlertMessage('Registered successfully!');
                setTimeout(() => props.setPageNumber(props.values.pageNumber + 1), 2000);
            } catch (error) {
                // if (obj?.message === 'User already exists') {
                //     props.values.handleNewOrganization();
                //     return;
                // } else if (obj?.message === '') {
                //     setAlertMessage('Please fill all the details.');
                //     setSeverity('error');
                //     setAlert(true);
                //     return error;
                // }
                const obj: any = error;
                if (typeof error === 'object') {
                    const obj1: any = error;
                    if (obj1?.message) {
                        setAlertMessage(obj1?.message);
                    } else {
                        setAlertMessage('Please fill in all the details');
                    }
                }
                const isUser = await isEmployeeInDatabase(props.values.emailId);
                if (obj?.message === 'User already exists' && isUser === 0) {
                    try {
                        await Auth.signIn(props.values.emailId, props.values.password);
                    } catch (err) {
                        const obj2: any = err;
                        if (obj2?.message === 'Incorrect username or password.') {
                            setAlertMessage('You have entered wrong password!');
                            setSeverity('error');
                            setAlert(true);
                            return obj2;
                        }
                        if (obj2?.message === 'User is not confirmed.') {
                            setAlertMessage('Your user is not confirmed. Please verify OTP!');
                            setSeverity('warning');
                            setAlert(true);
                            setTimeout(() => props.setPageNumber(props.values.pageNumber + 1), 2000);
                            return obj2;
                        }
                        setAlertMessage('User already exist!');
                        setSeverity('error');
                        setAlert(true);
                        return error;
                    }
                } else {
                    // setAlertMessage('User already exists!');
                    setSeverity('error');
                    setAlert(true);
                    return error;
                }
            }
        } else {
            signIn();
        }
    };

    const customSvgGoogle = (
        <Icon fontSize="small" style={{ marginRight: 5 }}>
            <img src={google} className={classes.googleImg} alt="GoogleIcon"></img>
        </Icon>
    );

    return (
        <div style={{ display: 'flex' }}>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.registerPageImage}
                                    alt="Page1Img"
                                    style={{ height: '75vh' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                    alt="udyamoLogo"
                                    style={{ height: '2.5vh' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} xl={7} className={classes.secondGrid}>
                    <Paper style={{ boxShadow: 'none' }} className={classes.paperStyle}>
                        <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                        <Grid container direction="row">
                            <form id="registerForm" onSubmit={cognitoRegister}>
                                <Grid item xs={12} style={{ justifyContent: 'flex-end' }} className={classes.formGrids}>
                                    <FormLabel>1/2</FormLabel>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <FormLabel className={classes.titlePartOne}>Register</FormLabel>
                                </Grid>
                                <Grid item xs={12} className={classes.registerContainer}>
                                    <Grid item xs={12} className={classes.partOneGrids} style={{ marginTop: '0%' }}>
                                        <TextField
                                            name="firstName"
                                            id="firstName"
                                            value={props.values.firstName}
                                            onBlur={() => handleFirstName()}
                                            onChange={(event: any) =>
                                                props.values.setFirstName((event.target as HTMLInputElement).value)
                                            }
                                            label="First Name"
                                            style={{ width: '100%' }}
                                            className={classes.formFields}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.partOneGrids}>
                                        <TextField
                                            name="lastName"
                                            id="lastName"
                                            value={props.values.lastName}
                                            style={{ width: '100%' }}
                                            onBlur={() => handleLastName()}
                                            onChange={(event: any) =>
                                                props.values.setLastName((event.target as HTMLInputElement).value)
                                            }
                                            label="Last Name"
                                            className={classes.formFields}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.partOneGrids}>
                                        <TextField
                                            name="email"
                                            id="emailId"
                                            label="Email ID"
                                            value={props.values.emailId}
                                            style={{ width: '100%' }}
                                            onChange={handleEmailId}
                                            className={classes.formFields}
                                            InputProps={{
                                                className: classes.inputEmail,
                                            }}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.partOneGrids}>
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel>Password</InputLabel>
                                            <Tooltip
                                                disableHoverListener
                                                title={
                                                    <span style={{ whiteSpace: 'pre-line' }}>{passwordPolicyText}</span>
                                                }
                                                placement="bottom-end"
                                                arrow
                                            >
                                                <Input
                                                    name="password"
                                                    id="password"
                                                    type={/* istanbul ignore next */ showPassword ? 'text' : 'password'}
                                                    value={props.values.password}
                                                    onBlur={/* istanbul ignore next */ () => handlePassword()}
                                                    onChange={
                                                        /* istanbul ignore next */ (event: any) =>
                                                            props.values.setPassword(
                                                                (event.target as HTMLInputElement).value,
                                                            )
                                                    }
                                                    className={classes.passwordInput}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={handleClickPassword}
                                                                className={classes.passswordButtonStyle}
                                                            >
                                                                {/* istanbul ignore next */ showPassword ? (
                                                                    <VisibilityOffIcon />
                                                                ) : (
                                                                    <VisibilityIcon />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Tooltip>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} className={classes.partOneGrids}>
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel>Confirm Password</InputLabel>
                                            <Input
                                                name="confirmPassword"
                                                id="confirmPass"
                                                type={
                                                    /* istanbul ignore next */ showConfirmPassword ? 'text' : 'password'
                                                }
                                                value={props.values.confirmPassword}
                                                onBlur={
                                                    /* istanbul ignore next */ () => {
                                                        handleConfirmPassword();
                                                    }
                                                }
                                                onChange={
                                                    /* istanbul ignore next */ (event: any) =>
                                                        props.values.setConfirmPassword(
                                                            (event.target as HTMLInputElement).value,
                                                        )
                                                }
                                                className={classes.passwordInput}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleClickConfirmPassword}
                                                            /* istanbul ignore next */ className={
                                                                classes.passswordButtonStyle
                                                            }
                                                        >
                                                            {/* istanbul ignore next */ showConfirmPassword ? (
                                                                <VisibilityOffIcon />
                                                            ) : (
                                                                <VisibilityIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: '5%' }}>
                                    <Grid item xs={12} className={classes.registerButtonGrid}>
                                        <Button
                                            id="registerBtn"
                                            className={classes.registerButton}
                                            style={{ width: '100%' }}
                                            type="submit"
                                            // onClick={handleSubmit}
                                        >
                                            Register
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.registerButtonGrid}
                                        style={{ marginTop: '5%' }}
                                    ></Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.or}
                                        style={{ display: 'flex', justifyContent: 'center' }}
                                    >
                                        <Grid sm={5} xs={5} style={{ height: 1, background: '#9E9E9E' }}></Grid>
                                        <Grid sm={2} xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                            OR
                                        </Grid>
                                        <Grid sm={5} xs={5} style={{ height: 1, background: '#9E9E9E' }}></Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.registerButtonGrid}
                                        style={{ marginTop: '5%' }}
                                    ></Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    <Button
                                        id="registerBtn"
                                        className={classes.authBtn}
                                        // style={{ width: '100%' }}
                                        type="submit"
                                        onClick={() => setIsGoogle(true)}
                                    >
                                        {customSvgGoogle}Register with google
                                    </Button>
                                </Grid>
                                {/* </Grid> */}
                            </form>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {/* </Hidden> */}
        </div>
    );
}
