import React, { useState } from 'react';
import {
    Button,
    Grid,
    Hidden,
    IconButton,
    InputBase,
    // Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
    TextField,
    InputAdornment,
    Popover,
} from '@material-ui/core';
// import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
// import { SearchBar } from '../DashboardPage/DashboardPart';
import useStyles from './TeammemberStyles';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import { PopUp } from '../Modal/Modal';
import Skeleton from '@material-ui/lab/Skeleton';
import Loader from '../Loader/loader';
import { Icon } from '@iconify/react';
// import { sendInvite } from '../../helpers/hasuraapi';
// import AuthContext from '../../context/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import { inviteStatusList } from '../Redux/employeeListReducer';
import FilterLogo from '../Employees/FilterLogo.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface StatusProps {
    toggle(): void;
}

function InviteStatus(props: StatusProps) {
    const classes = useStyles();
    // const mycontext = useContext(AuthContext);
    const theme = useTheme();
    const [alertMessage] = useState<string>('');
    const [alertm, setAlertm] = useState<boolean>(false);
    const [severity] = useState<string>('');
    const [isUpdating, setIsUpdating] = React.useState(false);
    // const [isLoading, setIsLoading] = useState<boolean>(true);
    // const [inviteData, setInviteData] = useState<any>([]);
    const [searchEmail, setSearchEmail] = useState<string>('');
    const [anchorFilterEl, setAnchorFilterEl] = useState<null | HTMLElement>(null);
    const [filterVal, setFilterVal] = useState<string>('All');
    // const [filterData, setFilterData] = useState<any>([]);
    const handleFilter = (event: any) => {
        setAnchorFilterEl(event.currentTarget);
    };

    const inviteData = useSelector((state: any) => state.employeesList);

    const dispatch = useDispatch();

    const handleFilterClose = (event: any) => {
        const filterOn = event.target.innerText;
        setFilterVal(filterOn);
        setAnchorFilterEl(null);
        if (filterOn === 'Accepted') {
            const acceptedInvite = inviteData.employeesList.initialEmailInvites.filter(
                (fd: any) => fd.status.toLowerCase().toString() === 'accepted',
            );
            dispatch(inviteStatusList(acceptedInvite));
        } else if (filterOn === 'Pending') {
            const pendingInvite = inviteData.employeesList.initialEmailInvites.filter(
                (fd: any) => fd.status.toLowerCase().toString() === 'pending',
            );
            dispatch(inviteStatusList(pendingInvite));
        } else {
            dispatch(inviteStatusList(inviteData.employeesList.initialEmailInvites));
        }
    };
    const handleSearch = (event: any) => {
        setSearchEmail(event.target.value);
    };

    const handleInviteEmail = async (users: any) => {
        setIsUpdating(true);
        const Employee = {
            emailId: users.emailId,
            orgId: users.orgId,
            teams: users.teams,
        };
        const inviteEmpData = [];
        inviteEmpData.push(Employee);
        // const inviteResponse = await sendInvite(inviteEmpData, mycontext.token);
        // if (inviteResponse.error) {
        //     setSeverity('error');
        //     setAlertm(true);
        //     setAlertMessage('Something went wrong, try again!');
        //     setIsUpdating(false);
        // } else {
        //     setSeverity('success');
        //     setAlertm(true);
        //     setAlertMessage('Invites sent successfully!');
        //     setIsUpdating(false);
        // }
    };

    // useEffect(() => {
    //     const getAll = async () => {
    //         const data = await fetchInviteStatus(mycontext.organizationId, mycontext.token);
    //         if (data.Error) {
    //             setSeverity('error');
    //             setAlertm(true);
    //             setAlertMessage(data.Error);
    //             setIsLoading(false);
    //         } else {
    //             setInviteData(data);
    //             setFilterData(data);
    //             setIsLoading(false);
    //         }
    //     };
    //     getAll();
    // }, [mycontext]);

    return (
        <div style={{ height: 'calc(100vh - 170px)' }}>
            <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
            <Loader isUpdating={isUpdating} />
            <Popover
                id="filter-menu"
                aria-label={filterVal}
                className={classes.menusty}
                anchorEl={anchorFilterEl}
                open={Boolean(anchorFilterEl)}
                onClose={handleFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{ className: classes.popoverPropsTwo }}
            >
                <MenuItem className={classes.menuitem} onClick={handleFilterClose}>
                    &nbsp;All&nbsp;
                </MenuItem>
                <MenuItem className={classes.menuitem} onClick={handleFilterClose}>
                    Accepted
                </MenuItem>
                <MenuItem className={classes.menuitem} onClick={handleFilterClose}>
                    Pending
                </MenuItem>
            </Popover>
            <Hidden only={['xs']}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignContent: 'center',
                        padding: 10,
                    }}
                >
                    <div className={classes.invitePageSearch}>
                        <TextField
                            id="searchname"
                            placeholder="Search by email/status"
                            variant="outlined"
                            size="small"
                            onChange={handleSearch}
                            className={classes.searchBorder}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.searchIcon}>
                                        <span
                                            style={{
                                                marginRight: 10,
                                                fontSize: 20,
                                                fill: theme.palette.primary.contrastText,
                                            }}
                                            className="iconify"
                                            data-icon="octicon:search-16"
                                        ></span>
                                    </InputAdornment>
                                ),
                                classes: {
                                    root: classes.searchInput,
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.primary.contrastText,
                                },
                            }}
                        />
                    </div>
                    {/* <Button
                        id="filter"
                        variant="outlined"
                        onClick={handleFilter}
                        disableRipple
                        disableFocusRipple
                        disableElevation
                        aria-describedby="filter-popup"
                        className={classes.myFilter}
                        startIcon={
                            <span
                                className="iconify"
                                data-icon="bx:bx-filter-alt"
                                data-width="16"
                                data-height="20"
                            ></span>
                        }
                        endIcon={
                            <span
                                className="iconify"
                                data-icon="bx:bxs-down-arrow"
                                data-width="8"
                                data-height="8"
                            ></span>
                        }
                    >
                        Filter By
                    </Button> */}
                    <Button
                        id="filter"
                        variant="outlined"
                        onClick={handleFilter}
                        startIcon={<img src={FilterLogo} alt="Filter Logo" />}
                        endIcon={<ArrowDropDownIcon className={classes.IconSty} />}
                        className={classes.filterButton}
                    >
                        Filter by
                    </Button>
                </div>
                <div style={{ padding: '0px 10px', marginTop: 10 }}>
                    {inviteData.employeesList.filteredEmailInvites.length === 0 ? (
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader size="small" aria-label="small table" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.columnnam}>#</TableCell>
                                        <TableCell className={classes.columnnam}>Email Address</TableCell>
                                        <TableCell className={classes.columnnam}>Status</TableCell>
                                        <TableCell className={classes.columnnam}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inviteData.status === 'loading' && (
                                        <>
                                            {[1, 2, 3, 4, 5, 6].map((d: number) => (
                                                <TableRow key={d} className={classes.row}>
                                                    <TableCell className={classes.cellName}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    <TableCell className={classes.cellName}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    <TableCell className={classes.cellName}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.cellName}
                                                        style={{ paddingLeft: '45px' }}
                                                    >
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    )}
                                    {inviteData.status === 'loading' || (
                                        <TableRow className={classes.row}>
                                            <TableCell colSpan={4} className={classes.fontsty}>
                                                <div className={classes.displayCenter}>
                                                    <img
                                                        src={upcomingTimeoff}
                                                        alt="No Upcoming Leaves found"
                                                        className={classes.notfoundImage}
                                                    />
                                                    <Typography className={classes.notfoundText}>
                                                        No Data Found
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader size="small" aria-label="small table" className={classes.tableHeight}>
                                <TableHead>
                                    <TableRow key="heading">
                                        <TableCell
                                            className={classes.column}
                                            style={{ paddingLeft: '25px', textAlign: 'center' }}
                                        >
                                            #
                                        </TableCell>
                                        <TableCell style={{ padding: '6px 24px 6px 10px' }} className={classes.column}>
                                            Email Address
                                        </TableCell>
                                        <TableCell className={classes.column}>Status</TableCell>
                                        <TableCell className={classes.column}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inviteData.employeesList.filteredEmailInvites.filter(
                                        (searchval: any) =>
                                            searchval.emailId.toLowerCase().includes(searchEmail?.toLowerCase()) ||
                                            searchval.status.toLowerCase().includes(searchEmail?.toLowerCase()),
                                    ).length === 0 ? (
                                        <TableRow className={classes.row}>
                                            <TableCell
                                                colSpan={4}
                                                style={{ borderBottom: 'none' }}
                                                className={classes.fontsty}
                                            >
                                                <div className={classes.displayCenter}>
                                                    <img
                                                        src={upcomingTimeoff}
                                                        alt="No Upcoming Leaves found"
                                                        className={classes.notfoundImage}
                                                    />
                                                    <Typography className={classes.notfoundText}>
                                                        No Data Found
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        inviteData.employeesList.filteredEmailInvites
                                            .filter(
                                                (searchval: any) =>
                                                    searchval.emailId
                                                        .toLowerCase()
                                                        .includes(searchEmail?.toLowerCase()) ||
                                                    searchval.status.toLowerCase().includes(searchEmail?.toLowerCase()),
                                            )
                                            .map((dt: any, index: any) => (
                                                /* istanbul ignore next */
                                                <TableRow className={classes.row} key={index}>
                                                    <TableCell
                                                        className={classes.cellName}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell className={classes.cellName}>{dt.emailId}</TableCell>
                                                    <TableCell
                                                        className={classes.cellName}
                                                        style={{ paddingLeft: '15px', textTransform: 'capitalize' }}
                                                    >
                                                        {dt.status === 'accepted' ? (
                                                            <span className={classes.chipStyle1}>{dt.status}</span>
                                                        ) : (
                                                            <span className={classes.chipStyle2}>{dt.status}</span>
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.cellName}
                                                        style={{ paddingLeft: '25px' }}
                                                    >
                                                        {dt.status !== 'accepted' ? (
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => handleInviteEmail(dt)}
                                                                className={classes.emailEnable}
                                                            >
                                                                <span
                                                                    className="iconify"
                                                                    data-icon="fluent:mail-arrow-forward-20-regular"
                                                                    data-width="25"
                                                                    data-height="25"
                                                                ></span>
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton
                                                                aria-label="delete"
                                                                className={classes.emailDisable}
                                                            >
                                                                <span
                                                                    className="iconify"
                                                                    data-icon="fluent:mail-arrow-forward-20-regular"
                                                                    data-width="25"
                                                                    data-height="25"
                                                                ></span>
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
            </Hidden>
            <Hidden smUp>
                <div style={{ marginBottom: 45 }}>
                    <div style={{ marginTop: '10px' }}>
                        <Grid container justify="space-between" spacing={2}>
                            <Grid item xs={10}>
                                <Paper
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '95%',
                                        height: 40,
                                        paddingLeft: '10px',
                                    }}
                                >
                                    {/* <IconButton style={{ padding: 10 }} aria-label="menu">
                                        <SearchOutlinedIcon style={{ color: '#CCCCCC' }} />
                                    </IconButton> */}
                                    <Icon icon="octicon:search-16" width="20" color="#888" />
                                    <InputBase
                                        id="search-input"
                                        className={classes.searchInput}
                                        value={searchEmail}
                                        placeholder="Search by email/status"
                                        onChange={handleSearch}
                                        inputProps={{ 'aria-label': 'Search by email/status' }}
                                        style={{ padding: 10 }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid container item xs={2} justify="flex-end" alignItems="center">
                                <Paper style={{ height: 40 }}>
                                    <Button
                                        id="filter"
                                        variant="outlined"
                                        disableRipple
                                        disableFocusRipple
                                        disableElevation
                                        aria-describedby="filter-popup"
                                        onClick={handleFilter}
                                        style={{
                                            padding: 12,
                                            margin: 0,
                                            color: '#888888',
                                            width: '80%',
                                            border: 'none',
                                            outline: 'none',
                                        }}
                                        startIcon={
                                            <span
                                                className="iconify"
                                                data-icon="bx:bx-filter-alt"
                                                data-width="20"
                                                data-height="20"
                                                style={{ color: '#888888' }}
                                            ></span>
                                        }
                                        endIcon={
                                            <span
                                                className="iconify"
                                                data-icon="bx:bxs-down-arrow"
                                                style={{ color: '#888888' }}
                                                data-width="10"
                                                data-height="10"
                                            ></span>
                                        }
                                    ></Button>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                    {inviteData.employeesList.filteredEmailInvites.length === 0 ? (
                        <Paper style={{ borderRadius: '0px', marginTop: '8px' }}>
                            <div className={classes.paperfontstyle}>Empty List</div>
                        </Paper>
                    ) : inviteData.employeesList.filteredEmailInvites.filter((searchval: any) =>
                          searchval.emailId.toLowerCase().includes(searchEmail?.toLowerCase()),
                      ).length === 0 ? (
                        <Paper style={{ borderRadius: '0px', marginTop: '8px' }}>
                            <div className={classes.displayCenter}>
                                <img
                                    src={upcomingTimeoff}
                                    alt="No Upcoming Leaves found"
                                    className={classes.notfoundImage}
                                />
                                <Typography className={classes.notfoundText}>No Data Found</Typography>
                            </div>
                        </Paper>
                    ) : (
                        inviteData.employeesList.filteredEmailInvites
                            .filter((searchval: any) =>
                                searchval.emailId.toLowerCase().includes(searchEmail?.toLowerCase()),
                            )
                            .map((dt: any, index: number) => (
                                <Paper key={index} style={{ marginTop: 10, padding: '15px 15px', borderRadius: 0 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div
                                            className={classes.mobpapertext}
                                            style={{ color: theme.palette.primary.main }}
                                        >
                                            Email
                                        </div>
                                        <div>
                                            {dt.status !== 'accepted' ? (
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleInviteEmail(dt)}
                                                    className={classes.emailEnable}
                                                >
                                                    <span
                                                        className="iconify"
                                                        data-icon="fluent:mail-arrow-forward-20-regular"
                                                        data-width="25"
                                                        data-height="25"
                                                    ></span>
                                                </IconButton>
                                            ) : (
                                                <IconButton aria-label="delete" className={classes.emailDisable}>
                                                    <span
                                                        className="iconify"
                                                        data-icon="fluent:mail-arrow-forward-20-regular"
                                                        data-width="25"
                                                        data-height="25"
                                                    ></span>
                                                </IconButton>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.mobpapertext} style={{ color: theme.palette.text.primary }}>
                                        {dt.emailId}
                                    </div>
                                    <br />
                                    <div className={classes.mobpapertext} style={{ color: theme.palette.primary.main }}>
                                        Status
                                    </div>
                                    <div
                                        className={classes.mobpapertext}
                                        style={{ color: theme.palette.text.primary, textTransform: 'capitalize' }}
                                    >
                                        {dt.status}
                                    </div>
                                </Paper>
                            ))
                    )}
                    <Button
                        id="back"
                        variant="contained"
                        color="primary"
                        disableRipple
                        onClick={props.toggle}
                        className={classes.mobbtnFixed}
                        // className={classes.btnFixed}
                    >
                        Back
                    </Button>
                </div>
            </Hidden>
        </div>
    );
}

export default InviteStatus;
