import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import useStyles from './style';
import { Hidden, useTheme } from '@material-ui/core';
import vector from './Vector.png';
import { Typography } from '@material-ui/core';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import Mark from 'mark.js';
import { updateActiveTab } from 'components/Redux/activeTab';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

interface GuideProps {
    data: any;
    title: string;
}
export default function EmployeeGuide(props: GuideProps) {
    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const [active, setActive] = React.useState<number>(0);
    const dispatch = useDispatch();
    const theme = useTheme();

    const scrollToQues = (id: number) => {
        setActive(id);
        let offsetTop: number;
        const getId = document.getElementById('ques-' + id);
        if (getId !== null) {
            offsetTop = getId.offsetTop;
            window.scrollTo({
                top: offsetTop - 180,
                behavior: 'smooth',
            });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const highlightSearch = (value: any) => {
        setValue(value);

        const a = document.querySelectorAll('.question');
        if (a !== null) {
            const ob = new Mark(a);
            ob.unmark();
            ob.mark(value, { className: classes.mark });
        }
    };

    useEffect(() => {
        dispatch(updateActiveTab('/employeeguide' || '/adminguide' || '/approverguide'));
    }, [dispatch]);

    const dataLength = props.data.filter((l: any) => l.ques.toLowerCase().includes(value.toLowerCase())).length;
    const dataLength1 = props.data.filter((l: any) => l.ans.toLowerCase().includes(value.toLowerCase())).length;
    return (
        <>
            <Hidden only={['xs', 'sm']}>
                <Header />
                <div className={classes.adminGuideHeading}>
                    <h2>{props.title}</h2>
                    <h6>All you need to know about the Time-Off feature</h6>

                    {dataLength !== 0 ? (
                        <div className={classes.searchbarOuterDiv}>
                            <div className={classes.searchbarInnerDiv}>
                                <div className={classes.searchbarDiv}>
                                    <div className={classes.searchbarRect}>
                                        <span
                                            style={{ marginRight: 10, fontSize: 20, color: '#888888' }}
                                            className="iconify"
                                            data-icon="octicon:search-16"
                                        ></span>
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            value={value}
                                            onChange={(e: any) => highlightSearch(e.target.value)}
                                            className={classes.searchBarInput}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                                <div className={classes.quesOuterDiv}>
                                    {props.data
                                        .filter((l: any) => l.ques.toLowerCase().includes(value.toLowerCase()))
                                        .map((d: any) => (
                                            <div
                                                className={
                                                    active === d.id
                                                        ? classes.questionsLeftActive
                                                        : classes.questionsLeft
                                                }
                                                key={d.id}
                                            >
                                                <p className="question" onClick={() => scrollToQues(d.id)}>
                                                    {d.ques}
                                                </p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            {/* <img src={userguide} alt='' style={{ maxWidth: '100%', height: 200, marginTop: 30 }}/> */}

                            <div className={classes.quesAnsOuterDiv}>
                                <div
                                    style={{
                                        height: 75,
                                        backgroundColor: '#fff',
                                        position: 'sticky',
                                        top: '97px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: theme.typography.fontWeightBold,
                                            color: '#000',
                                            margin: '10px 0px 45px 0px',
                                            fontSize: '16px',
                                            paddingTop: '15.5px',
                                        }}
                                    >
                                        Resources {'>'}{' '}
                                        <Link to="/userguide" style={{ textDecoration: 'none', color: '#000' }}>
                                            User Guide
                                        </Link>{' '}
                                        {'>'}
                                        <span style={{ color: '#3D49B6' }}> {props.title}</span>
                                    </p>
                                </div>
                                {props.data
                                    .filter((l: any) => l.ques.toLowerCase().includes(value.toLowerCase()))
                                    .map((d: any) => (
                                        <div id={'ques-' + d.id} key={d.id}>
                                            <p className={`${'question'} ${classes.pques}`}>{d.ques}</p>
                                            <p className={`${'question'} ${classes.pans}`}>
                                                {d.ans} <span style={{ fontSize: 5 }}>{d?.bullet}</span> {d?.ans2}
                                            </p>
                                        </div>
                                    ))}
                                <img src={vector} alt="vector" onClick={() => scrollToTop()} />
                            </div>
                        </div>
                    ) : dataLength === 0 && dataLength1 !== 0 ? (
                        <div className={classes.searchbarOuterDiv}>
                            <div className={classes.searchbarInnerDiv1}>
                                <div className={classes.searchbarDiv}>
                                    {/* <SearchBar value={value} setValue={setValue} mode="rect" icon={true} /> */}
                                    <div className={classes.searchbarRect}>
                                        <span
                                            style={{ marginRight: 10, fontSize: 20, color: '#888888' }}
                                            className="iconify"
                                            data-icon="octicon:search-16"
                                        ></span>
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            value={value}
                                            onChange={(e: any) => highlightSearch(e.target.value)}
                                            className={classes.searchBarInput}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                                {/* <img src={userguide} alt='' style={{ maxWidth: '100%', height: 200, marginTop: 30 }}/> */}
                            </div>

                            <div className={classes.quesAnsOuterDiv}>
                                <div
                                    style={{
                                        height: 75,
                                        backgroundColor: '#fff',
                                        position: 'sticky',
                                        top: '97px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#000',
                                            margin: '10px 0px 45px 0px',
                                            fontSize: '16px',
                                            paddingTop: '15.5px',
                                        }}
                                    >
                                        Resources {'>'}{' '}
                                        <a href="/userguide" style={{ textDecoration: 'none', color: '#000' }}>
                                            User Guide
                                        </a>{' '}
                                        {'>'}
                                        <span style={{ color: '#3D49B6' }}> {props.title}</span>
                                    </p>
                                </div>
                                {props.data
                                    .filter((l: any) => l.ans.toLowerCase().includes(value.toLowerCase()))
                                    .map((d: any) => (
                                        <div id={'ques-' + d.id} key={d.id}>
                                            <p className={`${'question'} ${classes.pques}`}>{d.ques}</p>
                                            <p className={`${'question'} ${classes.pans}`}>
                                                {d.ans} <span style={{ fontSize: 5 }}>{d?.bullet}</span> {d?.ans2}
                                            </p>
                                        </div>
                                    ))}
                                <img src={vector} alt="vector" onClick={() => scrollToTop()} />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={classes.searchbarOuterDiv}>
                                <div className={classes.searchbarInnerDiv2}>
                                    <div className={classes.searchbarDiv}>
                                        <div className={classes.searchbarRect}>
                                            <span
                                                style={{ marginRight: 10, fontSize: 20, color: '#888888' }}
                                                className="iconify"
                                                data-icon="octicon:search-16"
                                            ></span>
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                value={value}
                                                onChange={(e: any) => highlightSearch(e.target.value)}
                                                className={classes.searchBarInput}
                                                autoFocus={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ padding: '10px 0px 0px 50px', width: '75%' }}>
                                    <p
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#000',
                                            margin: '10px 0px 45px 0px',
                                            fontSize: '16px',
                                            paddingTop: '15.5px',
                                        }}
                                    >
                                        Resources {'>'}{' '}
                                        <a href="/userguide" style={{ textDecoration: 'none', color: '#000' }}>
                                            User Guide
                                        </a>{' '}
                                        {'>'}
                                        <span style={{ color: '#3D49B6' }}> {props.title}</span>
                                    </p>
                                </div>
                            </div>
                            <img src={upcomingTimeoff} alt="No Search Data Found" className={classes.notfoundImage} />
                            <Typography className={classes.notfoundText}>No Search Data Found</Typography>
                        </>
                    )}
                </div>
                <Footer />
            </Hidden>

            <Hidden only={['md', 'lg', 'xl']}>
                <Header />
                <div className={classes.adminGuideHeadingSmall}>
                    <h2>{props.title}</h2>
                    <h6>All you need to know about the Time-Off feature</h6>

                    <p className={classes.resourcesSmall}>
                        Resources {'>'} <a href="/userguide">User Guide</a> {'>'}
                        <span> {props.title}</span>
                    </p>

                    <div className={classes.searchbarDiv}>
                        <div className={classes.searchbarRectSmall}>
                            <span
                                style={{ marginRight: 10, fontSize: 20, color: '#888888' }}
                                className="iconify"
                                data-icon="octicon:search-16"
                            ></span>
                            <input
                                type="text"
                                placeholder="Search"
                                value={value}
                                onChange={(e: any) => highlightSearch(e.target.value)}
                                className={classes.searchBarInput}
                                autoFocus={true}
                            />
                        </div>
                    </div>

                    {dataLength !== 0 ? (
                        <div className={classes.quesAnsDivSmall}>
                            {props.data
                                .filter((l: any) => l.ques.toLowerCase().includes(value.toLowerCase()))
                                .map((d: any) => (
                                    <div id={'ques-' + d.id} key={d.id}>
                                        <p className="question">{d.ques}</p>
                                        <p className="question">{d.ans}</p>
                                    </div>
                                ))}
                        </div>
                    ) : dataLength === 0 && dataLength1 !== 0 ? (
                        <div className={classes.quesAnsDivSmall}>
                            {props.data
                                .filter((l: any) => l.ans.toLowerCase().includes(value.toLowerCase()))
                                .map((d: any) => (
                                    <div id={'ques-' + d.id} key={d.id}>
                                        <p className="question">{d.ques}</p>
                                        <p className="question">{d.ans}</p>
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div>
                            <img
                                src={upcomingTimeoff}
                                alt="No Search Data Found"
                                className={classes.notfoundImageSmall}
                            />
                            <Typography className={classes.notfoundText}>No Search Data Found</Typography>
                        </div>
                    )}
                </div>
                <Footer />
            </Hidden>
        </>
    );
}
