/* eslint-disable no-console */
export const fetchMarketPlaceToken = (tenantId: string) => {
    const clientId = '6f7a9df0-c0e1-4d4d-b578-ed6860adbf06';
    const clientSecret = 'dYP8Q~QlyN5gYM7LsgYxqLUmM8mfnXE~q.4C_aRo';
    const resource = '20e940b3-4c77-4b0b-9a53-9e16a1b010a7';
    const requestBody = `grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}&resource=${resource}`;
    fetch(`https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            mode: 'no-cors',
        },
        body: requestBody,
    })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.log(error));
};

export const resolveSubscription = (xMsMarketPlaceToken: string, authorizationToken: string) => {
    fetch(`https://marketplaceapi.microsoft.com/api/saas/subscriptions/resolve?api-version=2018-08-31`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${authorizationToken}`,
            'x-ms-marketplace-token': `${xMsMarketPlaceToken}`,
        },
    })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.log(error));
};
export const activateSubscription = (planDetails: string, authorizationToken: string, subscriptionId: string) => {
    const body = {
        planId: `${planDetails}`,
    };
    fetch(
        `https://marketplaceapi.microsoft.com/api/saas/subscriptions/${subscriptionId}/activate?api-version=2018-08-31`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${authorizationToken}`,
            },
            body: JSON.stringify(body),
        },
    )
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.log(error));
};
export const fetchSubscriptionDetails = (subscriptionId: string, authorizationToken: string) => {
    fetch(`https://marketplaceapi.microsoft.com/api/saas/subscriptions/${subscriptionId}?api-version=2018-08-31`, {
        headers: {
            Authorization: `Bearer ${authorizationToken}`,
        },
    })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.log(error));
};
export const lambdaMsSubscription = async (bodyContents: any) => {
    try {
        let body = {};
        switch (bodyContents.action) {
            case 'FETCH':
                body = {
                    subscriptionId: bodyContents.subscriptionId,
                    botType: bodyContents.botType,
                };
                break;
            case 'RESOLVE':
                body = {
                    xMsMarketPlaceToken: bodyContents.subscriptionToken,
                    botType: bodyContents.botType,
                };
                break;
            case 'ACTIVATE':
                body = {
                    subscriptionId: bodyContents.subscriptionId,
                    planId: bodyContents.planId,
                    botType: bodyContents.botType,
                };
                break;
            default:
                break;
        }
        const url = `https://sls-swec-dev-teamplusapiprod.azurewebsites.net/api/teamplus-api/ms-subscription?code=QNovBAR7lyuJ3-M_1eQnMHax8BbRbkXfrc22IpmUcEl5AzFurVaulA==`;
        const updatedBody = { ...body, action: bodyContents.action, tenantId: bodyContents.tenantId };
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(updatedBody),
        });
        const result = await response.json();
        return result;
    } catch (err) {
        return { error: err };
    }
};
