import React, { useContext } from 'react';
import { Paper, Typography, Button, Grid, Avatar, Tooltip, Hidden } from '@material-ui/core';
import { useStyles } from './pollresultsStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Donut } from '../donut/donutOuter';
import { Popover } from '@material-ui/core';
// import { fetchPollResult } from 'helpers/hasuraapi2';
import Countdown from 'react-countdown';
import AuthContext from '../../context/AuthContext';
import Comment from './comments';
import { useSelector } from 'react-redux';
import comment from './comment.png';
import { getFullName } from 'helpers/getInitials';
import { useSubscription } from '@apollo/client';
import { getPollResult } from 'helpers/subscriptions/pollSubscriptions';
import { PopUp } from '../Modal/Modal';
// import { useContext } from 'react';
import { isoToLocal } from '../../helpers/timezone';
import { ToStringFormat } from '../../helpers/date';

export const Header = (props: {
    handleClick1?: any;
    setStep?: any;
    button?: number;
    title?: string;
    buttonText?: string;
}) => {
    const classes = useStyles();
    return (
        <>
            <Hidden smDown>
                <Paper elevation={0}>
                    <div>
                        <Grid container className={classes.paper1}>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <ArrowBackIcon
                                            className={classes.backArrow}
                                            data-testid="Arrow"
                                            onClick={() => props?.setStep(0)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.poll}>{props?.title}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {props?.button === 1 && (
                                <Grid>
                                    <Button className={classes.btn} onClick={props?.handleClick1}>
                                        {props?.buttonText}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Paper>
            </Hidden>
            <Hidden mdUp>
                <Grid container className={classes.paper1}>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <ArrowBackIcon className={classes.backArrow} onClick={() => props?.setStep(0)} />
                            </Grid>
                            <Grid item>
                                <div className={classes.poll}>{props?.title}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    {props?.button === 1 && (
                        <Grid item>
                            <img
                                // style={{ margin: '10px 5px 10px 0px' }}
                                src={comment}
                                alt="backArrow"
                                onClick={props?.handleClick1}
                            />
                        </Grid>
                    )}
                </Grid>
            </Hidden>
        </>
    );
};

interface AvtarProps {
    pollsData?: any;
    renderer?: any;
}
export const AvtarComponents = (props: AvtarProps) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    return (
        <>
            <Grid container>
                <Grid item>
                    <Grid container direction="row" style={{ padding: 12 }}>
                        <div>
                            <Avatar
                                data-testid="avatar"
                                alt="profile"
                                className={classes.avatarimage}
                                src={props?.pollsData?.Employee?.profileImage}
                            >
                                {getFullName(
                                    props?.pollsData?.Employee?.firstName + ' ' + props?.pollsData?.Employee?.lastName,
                                )}
                            </Avatar>
                        </div>
                        <div className={classes.item}>
                            {props?.pollsData?.Employee?.firstName} {props?.pollsData?.Employee?.lastName}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.maindiv}>
                <Grid container direction="row">
                    <Grid item xs={12} sm={12} md={9}>
                        <span data-testid="teams" className={classes.heading1}>
                            Teams
                        </span>
                        {props?.pollsData?.poll_teams?.length !== 1 ? (
                            <p className={classes.subheading1}>All</p>
                        ) : (
                            <p className={classes.subheading1}>{props?.pollsData?.poll_teams[0]?.Team?.teamName}</p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <span className={classes.heading1}>Remaining Time</span>
                        {props?.pollsData?.pollEndTime === null ? (
                            <div className={classes.subheading1}>
                                <span className="iconify" data-icon="bi:dash" style={{ width: 25, height: 25 }}></span>
                            </div>
                        ) : (
                            <p className={classes.subheading1}>
                                <Countdown
                                    daysInHours={true}
                                    date={props?.pollsData?.pollEndsDateTime}
                                    renderer={t => props?.renderer(t)}
                                />
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={9}>
                        <span className={classes.heading1}>Question</span>
                        <p style={{ maxWidth: '95%', wordWrap: 'break-word' }} className={classes.subheading1}>
                            {props?.pollsData?.question}
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <span className={classes.heading1}>Created On</span>
                        <p className={classes.subheading1}>
                            {ToStringFormat(
                                isoToLocal(props?.pollsData?.pollEndsDateTime, mycontext.country)
                                    .toString()
                                    .substring(3, 15),
                                mycontext.dateFormat,
                            )}
                        </p>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

interface ChoicesMobileProps {
    pollsData: any;
    arr: any;
    arr3: any;
    color: any;
    total: any;
    users: any;
    open: any;
    anchorEl: any;
    handleClose: any;
    handleClick: any;
    COLORS: any;
    currentChoice: any;
}

export const ChoicesMobileComponent = (props: ChoicesMobileProps) => {
    const classes = useStyles();
    return (
        <>
            <Hidden lgUp>
                <div className={classes.maindiv}>
                    <Grid container className={classes.grid}>
                        {props?.pollsData?.adminChoices?.map((n: any, i: any) => (
                            <Grid key={i}>
                                <Grid item className={classes.rectangle1}>
                                    <div style={{ background: `${props?.COLORS[i]}` }} className={classes.rectangle2}>
                                        <Typography className={classes.pollText}>{n}</Typography>
                                    </div>
                                </Grid>
                                {props?.pollsData?.isAnonymous === true ? (
                                    <Typography className={classes.response}>
                                        {props?.users?.filter((u: any) => u?.userChoice === n)?.length < 2 ? (
                                            <>
                                                {props?.users?.filter((u: any) => u?.userChoice === n)?.length} Response
                                            </>
                                        ) : (
                                            <>
                                                {props?.users?.filter((u: any) => u?.userChoice === n)?.length}{' '}
                                                Responses
                                            </>
                                        )}
                                    </Typography>
                                ) : (
                                    <>
                                        <Grid className={classes.avatar1}>
                                            {props?.users
                                                ?.filter((u: any) => u?.userChoice === n)
                                                ?.slice(0, 3)
                                                ?.map((user: any, index: number) => (
                                                    <div key={index}>
                                                        <Tooltip
                                                            key={index}
                                                            title={
                                                                <>
                                                                    {user?.firstName} {user?.lastName}
                                                                </>
                                                            }
                                                            placement="top"
                                                            classes={{ tooltip: classes.tooltip }}
                                                            arrow
                                                        >
                                                            <Avatar src={user?.image} className={classes.avatar2}>
                                                                {getFullName(user?.firstName + ' ' + user?.lastName)}
                                                            </Avatar>
                                                        </Tooltip>
                                                    </div>
                                                ))}
                                            {props?.users?.filter((u: any) => u?.userChoice === n)?.length > 3 && (
                                                <Button
                                                    className={classes.avatarButton}
                                                    onClick={(e: any) => props?.handleClick(e, n)}
                                                >
                                                    +{' '}
                                                    {props?.users?.filter((u: any) => u?.userChoice === n)?.length - 3}{' '}
                                                    More
                                                </Button>
                                            )}
                                        </Grid>
                                        <div>
                                            <Popover
                                                className={classes.popover1}
                                                open={props?.open && props?.currentChoice === n}
                                                anchorEl={props?.anchorEl}
                                                onClose={props?.handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                classes={{ paper: classes.triangle }}
                                            >
                                                <div className={classes.popover2}>
                                                    {props?.users
                                                        ?.filter((u: any) => u?.userChoice === n)
                                                        ?.map((user: any, index: number) => (
                                                            <div key={index}>
                                                                <Typography>
                                                                    <div className={classes.menuItem}>
                                                                        <Avatar
                                                                            src={user?.image}
                                                                            className={classes.popoverAvatar}
                                                                        >
                                                                            {getFullName(
                                                                                user?.firstName + ' ' + user?.lastName,
                                                                            )}
                                                                        </Avatar>
                                                                        <Typography className={classes.title}>
                                                                            {' '}
                                                                            {user?.firstName} {user?.lastName}
                                                                        </Typography>
                                                                    </div>
                                                                </Typography>
                                                            </div>
                                                        ))}
                                                </div>
                                            </Popover>
                                        </div>
                                    </>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Typography className={classes.donut}>
                                {props?.arr?.length === 0 ? (
                                    <div></div>
                                ) : (
                                    <Donut data={props?.arr} COLORS={props?.arr3} />
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Hidden>
        </>
    );
};

interface ChoicesProps {
    pollsData: any;
    arr: any;
    arr3: any;
    color: any;
    total: any;
    users: any;
    open: any;
    anchorEl: any;
    handleClose: any;
    handleClick: any;
    COLORS: any;
    currentChoice: any;
}

export const ChoicesComponents = (props: ChoicesProps) => {
    const classes = useStyles();

    return (
        <>
            <div style={{ marginLeft: '15px' }}>
                <Grid container>
                    <Grid container className={classes.container3}>
                        {props?.total === 1 ? (
                            <Typography className={classes.heading1}>
                                Choices (Total Response: {props?.total}){' '}
                            </Typography>
                        ) : (
                            <Typography className={classes.heading1}>
                                Choices (Total Responses: {props?.total}){' '}
                            </Typography>
                        )}
                    </Grid>
                    <Hidden mdDown>
                        <Grid container className={classes.container4}>
                            <Grid item lg={9} md={12}>
                                {props?.pollsData?.adminChoices?.map((n: any, i: any) => (
                                    <Grid className={classes.rectangle1} key={i}>
                                        <div
                                            style={{ backgroundColor: `${props?.COLORS[i]}` }}
                                            className={classes.rectangle2}
                                        >
                                            <Typography className={classes.pollText}>{n}</Typography>
                                        </div>
                                        {props?.pollsData?.isAnonymous === true ? (
                                            <Grid className={classes.avatar1}>
                                                <Typography className={classes.response}>
                                                    {props?.users?.filter((u: any) => u?.userChoice === n)?.length <
                                                    2 ? (
                                                        <>
                                                            {
                                                                props?.users?.filter((u: any) => u?.userChoice === n)
                                                                    ?.length
                                                            }{' '}
                                                            Response
                                                        </>
                                                    ) : (
                                                        <>
                                                            {
                                                                props?.users?.filter((u: any) => u?.userChoice === n)
                                                                    ?.length
                                                            }{' '}
                                                            Responses
                                                        </>
                                                    )}{' '}
                                                </Typography>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid className={classes.avatar1}>
                                                    {props?.users
                                                        ?.filter((u: any) => u?.userChoice === n)
                                                        ?.slice(0, 3)
                                                        ?.map((user: any, index: number) => (
                                                            <div key={index}>
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            {user?.firstName} {user?.lastName}
                                                                        </>
                                                                    }
                                                                    placement="top"
                                                                    classes={{ tooltip: classes.tooltip }}
                                                                    arrow
                                                                >
                                                                    <Avatar
                                                                        src={user?.image}
                                                                        className={classes.avatar2}
                                                                    >
                                                                        {getFullName(
                                                                            user?.firstName + ' ' + user?.lastName,
                                                                        )}
                                                                    </Avatar>
                                                                </Tooltip>
                                                            </div>
                                                        ))}
                                                    {props?.users?.filter((u: any) => u?.userChoice === n)?.length >
                                                        3 && (
                                                        <Button
                                                            className={classes.avatarButton}
                                                            onClick={(e: any) => props?.handleClick(e, n)}
                                                        >
                                                            +{' '}
                                                            {props?.users?.filter((u: any) => u?.userChoice === n)
                                                                ?.length - 3}{' '}
                                                            More
                                                        </Button>
                                                    )}
                                                </Grid>
                                                <div>
                                                    <Popover
                                                        className={classes.popover1}
                                                        classes={{ paper: classes.triangle }}
                                                        open={props?.open && props?.currentChoice === n}
                                                        anchorEl={props?.anchorEl}
                                                        onClose={props?.handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <div className={classes.popover2}>
                                                            {props?.users
                                                                ?.filter((u: any) => u?.userChoice === n)
                                                                ?.map((user: any, index: number) => (
                                                                    <div key={index}>
                                                                        <Typography>
                                                                            <div className={classes.menuItem}>
                                                                                <Avatar
                                                                                    src={user?.image}
                                                                                    className={classes.popoverAvatar}
                                                                                >
                                                                                    {getFullName(
                                                                                        user?.firstName +
                                                                                            ' ' +
                                                                                            user?.lastName,
                                                                                    )}
                                                                                </Avatar>
                                                                                <Typography className={classes.title}>
                                                                                    {user?.firstName} {user?.lastName}
                                                                                </Typography>
                                                                            </div>
                                                                        </Typography>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </Popover>
                                                </div>
                                            </>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item lg={3}>
                                <div className={classes.donut}>
                                    {props?.arr?.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        <Donut data={props?.arr} COLORS={props?.arr3} />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        </>
    );
};

export default function PollResults(props: { setStep: any }) {
    const classes = useStyles();
    const mycontext: any = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = React?.useState<HTMLButtonElement | null>(null);
    const [currentChoice, setCurrentChoice] = React?.useState('');
    const [users, setUsers] = React?.useState<any>([]);
    const [choices, setChoices] = React?.useState<any>([]);
    // const location: any = useLocation();
    // const pollsData: any = location?.state?.poll;
    const pollsData = mycontext?.currentPoll;
    const COLORS = ['#587EE0', '#4FB8B2', '#956FE4', '#FFBB28', '#6CD78A', '#BE66E8', '#F2890D'];
    const abcd = useSelector((state: any) => state?.polls);
    const open = Boolean(anchorEl);
    const [step, setStep] = React?.useState(0);
    const [alert, setAlert] = React?.useState(false);
    const [popup, setPopup] = React?.useState({
        alertMessage: '',
        severity: '',
    });

    const subDetails = useSelector((state: any) => state.subscription);

    let total: any = 0;
    choices?.forEach((a: any) => (total += a?.value));
    const renderer = (props: any) => {
        if (props?.completed) return <div>Expired</div>;
        if (props?.hours === 0 && props?.minutes === 0) return <div>{props?.seconds} sec</div>;
        if (props?.hours === 0)
            return (
                <div>
                    {props?.minutes} mins {props?.seconds} sec
                </div>
            );
        return (
            <div>
                {props?.hours} hrs {props?.minutes} mins
            </div>
        );
    };

    const pollResult = useSubscription(getPollResult(pollsData?.pollId));
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, choice: string) => {
        setAnchorEl(event.currentTarget);
        setCurrentChoice(choice);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const arr = choices?.filter((v: any) => (v?.value === 0 ? null : v?.name));

    const color: any = choices?.filter((a: any, i: number) => Object?.assign(a, { color: COLORS[i++] }));

    const arr2: any = color?.filter((v: any) => (v?.value !== 0 ? v : null));

    const arr3: any = arr2?.map((v: any) => v?.color);

    // useEffect(() => {
    //     async function fetchAll() {
    //         const data = await fetchPollResult(pollsData?.pollId, mycontext?.token);
    //         // console?.log(data);
    //         const a: any = [];
    //         const obj: any = [];
    //         pollsData?.adminChoices?.map((n: string) => {
    //             let c = 0;
    //             data?.map((d: any) => {
    //                 if (d?.userChoice?.includes(n)) {
    //                     c = c + 1;
    //                     return a?.push({
    //                         firstName: d?.Employee?.firstName,
    //                         lastName: d?.Employee?.lastName,
    //                         image: d?.Employee?.profileImage,
    //                         userChoice: n,
    //                     });
    //                 }
    //                 return null;
    //             });
    //             obj?.push({
    //                 name: n,
    //                 value: c,
    //             });
    //             return null;
    //         });
    //         // console?.log('filtered data ', a, obj);
    //         setUsers(a);
    //         setChoices(obj);
    //     }
    //     fetchAll();
    // }, [pollsData, mycontext]);

    React?.useMemo(() => {
        if (!pollResult?.loading) {
            const a: any = [];
            const obj: any = [];
            pollsData?.adminChoices?.map((n: string) => {
                let c = 0;
                pollResult?.data?.pollsdb_poll_result?.map((d: any) => {
                    if (d?.userChoice?.includes(n)) {
                        c = c + 1;
                        return a?.push({
                            firstName: d?.Employee?.firstName,
                            lastName: d?.Employee?.lastName,
                            image: d?.Employee?.profileImage,
                            userChoice: n,
                        });
                    }
                    return null;
                });
                obj?.push({
                    name: n,
                    value: c,
                });
                return null;
            });
            setUsers(a);
            setChoices(obj);
        }
    }, [pollResult, pollsData]);

    const handleClick1 = () => {
        try {
            const pollSubscriptions = subDetails.subscription.usermanagementdb_Subscription.filter((sub: any) =>
                sub.planId.startsWith('pbtp'),
            );

            if (pollSubscriptions.length === 0) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }

            const license = subDetails.userSubscriptions.usermanagementdb_tenant_employee[0].pollSubscriptionId;

            if (!license) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }

            const activeSub = pollSubscriptions.some((sub: any) => {
                if (sub.subscriptionId === license) {
                    if (sub.status === 'Subscribed') {
                        return true;
                    } else if (sub.status === 'Suspended') {
                        throw new Error(
                            'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                        );
                    } else if (sub.status === 'Unsubscribed') {
                        throw new Error(
                            'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                        );
                    }
                }
                return false;
            });

            if (!activeSub) {
                throw new Error('Somthing went wrong, please try again.');
            }
            const a: any = abcd?.polls?.map((b: any) => b?.pollId);

            if (a?.indexOf(pollsData?.pollId) === -1) {
                throw new Error('Poll does not exist it was deleted by creator');
            }
            setStep(1);
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    if (step === 1) {
        return <Comment setStep={setStep} pollId={pollsData?.pollId} />;
    }

    return (
        <>
            <Header
                handleClick1={handleClick1}
                setStep={props?.setStep}
                button={1}
                title="Poll Results"
                buttonText="Comment"
            />
            {/* <PollArrow handleClick1={handleClick1} setStep={props?.setStep} /> */}
            <PopUp open={alert} message={popup?.alertMessage} severity={popup?.severity} setOpen={setAlert} />
            <div>
                <Paper elevation={0} className={classes.paper} style={{ minHeight: 'calc(100vh - 195px' }}>
                    <div className={classes.scroll} style={{ paddingLeft: '15px' }}>
                        <AvtarComponents pollsData={pollsData} renderer={renderer} />
                        <ChoicesComponents
                            pollsData={pollsData}
                            arr={arr}
                            arr3={arr3}
                            color={color}
                            total={total}
                            users={users}
                            COLORS={COLORS}
                            open={open}
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            currentChoice={currentChoice}
                            handleClick={handleClick}
                        />
                        <ChoicesMobileComponent
                            pollsData={pollsData}
                            arr={arr}
                            arr3={arr3}
                            color={color}
                            total={total}
                            users={users}
                            COLORS={COLORS}
                            open={open}
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            currentChoice={currentChoice}
                            handleClick={handleClick}
                        />
                    </div>
                </Paper>
            </div>
        </>
    );
}
