import React from 'react';
import { Paper, Modal, Button, Grid, FormLabel } from '@material-ui/core';
import useStyles from './modalStyle';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    data?: any;
    setOpen?: any;
    open: boolean;
    onClosed?: any;
    handleSubmit?: any;
    onclick?: any;
    setStep?: any;
    timeend?: any;
    typerespond?: any;
}

interface DeleteProps {
    isDelete: boolean;
    setDelete: any;
    open: boolean;
    handleDelete?: any;
    currentPoll?: any;
}

export function DeleteModal(props: DeleteProps): JSX.Element {
    const classes = useStyles();
    return (
        <>
            <Modal open={props.open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div className={classes.modal1}>
                    <Paper className={classes.paper1}>
                        <Grid container className={classes.deleteModalContainer}>
                            <Grid item xs={12} sm={12} style={{ marginBottom: '20px', marginTop: '15px' }}>
                                <FormLabel className={classes.deltitle}>Delete Poll</FormLabel>
                            </Grid>

                            <Grid item xs={12} sm={12} style={{ marginBottom: '35px' }}>
                                <p style={{ maxWidth: '100%', wordWrap: 'break-word' }} className={classes.delbod}>
                                    Are you sure you want to delete<strong> {props?.currentPoll?.question}</strong> ?
                                </p>
                            </Grid>

                            <Grid item xs={12} sm={12} style={{ marginBottom: '15px' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.btnOutlined}
                                    onClick={() => props.setDelete(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id="confirm-delete"
                                    variant="contained"
                                    color="primary"
                                    className={classes.btn}
                                    onClick={() => props.handleDelete(props.currentPoll.pollId)}
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                        <CloseIcon
                            onClick={() => props.setDelete(false)}
                            style={{ position: 'absolute', top: 18, right: 18, cursor: 'pointer' }}
                        />
                    </Paper>
                </div>
            </Modal>
        </>
    );
}

export default function PollsModal(props: Props): JSX.Element {
    const classes = useStyles();

    const close = (): any => {
        props.setOpen(false);
    };

    const submit = (): any => {
        props.handleSubmit();
        props.setOpen(false);
    };

    return (
        <>
            {/* <Hidden mdDown> */}
            <Modal open={props.open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div className={classes.modal}>
                    <Paper className={classes.paper} elevation={0}>
                        <div className={classes.closeIcon}>
                            <CloseIcon onClick={() => close()} style={{ cursor: 'pointer' }} />
                        </div>

                        <div className={classes.mobileDiv}>
                            <div className={classes.scrollDiv}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center' }}>
                                    <div className={classes.text_item}> Created By: </div>&nbsp;
                                    <div className={classes.item2}>{props.data.Employee.firstName}</div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '3%' }}>
                                    <div className={classes.text_item}>Team:</div>&nbsp;
                                    <div className={classes.item2}>{props.data.pollrespond}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '3%' }}>
                                    <div className={classes.text_item}>This poll will expire at </div>&nbsp;
                                    <div className={classes.item2}>{props.timeend}</div>
                                </div>

                                <div style={{ marginTop: '3%' }}>
                                    <div className={classes.text_item}>Question:</div>
                                    <div className={classes.item2} style={{ marginTop: '2%', width: '100%' }}>
                                        <div>{props.data.question}</div>
                                    </div>
                                </div>

                                <div style={{ marginTop: '3%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <div className={classes.text_item}>Choices:</div> &nbsp;
                                        {props.typerespond === 'Single' ? (
                                            <div className={classes.item2}>(Single Choices)</div>
                                        ) : (
                                            <div className={classes.item2}>(Multiple Vote)</div>
                                        )}
                                    </div>
                                    <div className={classes.item3}>
                                        {props.data.choices.map((v: string, i: number) => (
                                            <div className={classes.label} key={i}>
                                                {i + 1}: {v}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.btnDiv} style={{ marginTop: '7%' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.btn1}
                                    onClick={() => close()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => submit()}
                                    variant="contained"
                                    color="primary"
                                    className={classes.btn2}
                                >
                                    Create
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </Modal>
            {/* </Hidden > */}
        </>
    );
}
