import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
const myStyle = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            width: '100%',
            height: '100%',
            borderRadius: 3,
        },
        top: {
            height: 45,
            marginBottom: 10,
            background: theme.palette.background.paper,
            borderRadius: 3,
            padding: '10px 20px',
            display: 'flex',
            alignItems: 'center',
            '@media screen and (max-width: 768px)': {
                padding: '10px 10px',
            },
        },
        bottom: {
            minHeight: 'calc(100% - 50px)',
            height: 'auto',
            width: '100%',
            background: theme.palette.background.paper,
            borderRadius: 3,
            padding: '10px 20px',
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
        },
        iconCol1: {
            color: theme.palette.text.primary,
            height: 10,
            marginRight: 10,
            cursor: 'pointer',
            '@media screen and (max-width: 768px)': {
                height: 16,
            },
        },
        iconCol2: {
            color: theme.palette.text.primary,
            height: 10,
            marginLeft: 10,
            cursor: 'pointer',
            '@media screen and (max-width: 768px)': {
                height: 16,
            },
        },
        heading: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
            '@media screen and (max-width: 768px)': {
                fontSize: 16,
            },
        },
        calendar: {
            display: 'flex',
            flexDirection: 'column',
        },
        calendarHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 0',
        },
        labels: {
            display: 'flex',
            alignItems: 'center',
        },
        chip: {
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
            padding: '0px 10px',
            marginLeft: 10,
            cursor: 'pointer',
        },
        chipText: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
        },
        month: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
        },
        monthMobile: {
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            background: theme.palette.background.paper,
        },
        days: {
            // display: 'grid',
            // gridTemplateColumns: 'repeat(7, 145px)',
            display: 'flex',
            flexWrap: 'wrap',
            // '& div': {
            //     height: '70px',
            //     backgroundColor: '#fff',
            //     border: '1px solid rgba(0, 0, 0, 0.8)',
            //     display: 'flex',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            // },
        },
        daysMobile: {
            // display: 'grid',
            // gridTemplateColumns: 'repeat(7, auto)',
            display: 'flex',
            flexWrap: 'wrap',
        },
        currentDay: {
            width: 26,
            height: 26,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            fontWeight: 500,
            marginBottom: 5,
        },
        currentDayM: {
            width: 20,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: '#dcdfff',
            color: '#14183e',
            fontWeight: 500,
            marginBottom: 5,
        },
        laterDay: {
            backgorund: 'transparent',
            marginBottom: 5,
        },
        prevDateM: {
            color: theme.palette.text.disabled,
            // height: '70px',
            width: 'calc(100% / 7)',
            height: 'calc(60vh / 5)',
            backgroundColor: theme.palette.background.paper,
            border: '0.5px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
            fontWeight: 500,
        },
        otherDayM: {
            height: 'calc(60vh / 5)',
            width: 'calc(100% / 7)',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            border: '0.5px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
            fontWeight: 500,
        },
        todayM: {
            height: 'calc(60vh / 5)',
            width: 'calc(100% / 7)',
            backgroundColor: theme.palette.background.paper,
            border: '0.5px solid #d9d9d9',
            color: theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 2,
        },
        otherDay: {
            // height: '70px',
            width: 'calc(100% / 7)',
            height: 'calc((100vh - 210px) / 5)',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            border: '0.5px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 5,
            fontWeight: 500,
        },
        date: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& p': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 16,
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.text.primary,
            },
        },
        weekDays: {
            // display: 'grid',
            // gridTemplateColumns: 'repeat(7, 145px)',
            display: 'flex',
            flexWrap: 'wrap',
            '& div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#DCDFFF',
                border: '0.5px solid #d9d9d9',
                color: '#262F83',
                width: 'calc(100% / 7)',
                height: 26,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        weekDaysMobile: {
            // display: 'grid',
            // gridTemplateColumns: 'repeat(7, auto)',
            display: 'flex',
            flexWrap: 'wrap',
            '& div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: '#DCDFFF',
                // border: '0.5px solid #d9d9d9',
                // color: theme.palette.text.primary, Ritik
                backgroundColor: '#DCDFFF',
                border: '0.5px solid #d9d9d9',
                color: '#262F83',
                textTransform: 'uppercase',
                height: 26,
                width: 'calc(100% / 7)',
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        prevDate: {
            color: theme.palette.text.disabled,
            // height: '70px',
            width: 'calc(100% / 7)',
            height: 'calc((100vh - 210px) / 5)',
            backgroundColor: theme.palette.background.paper,
            border: `0.5px solid #d9d9d9`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 5,
            fontWeight: 500,
        },
        nextDate: {
            color: theme.palette.text.primary,
            // height: '70px',
            height: 'calc((100vh - 210px) / 5)',
            // backgroundColor: '#fff',
            border: '0.5px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 5,
            fontWeight: 500,
        },
        eventDiv: {
            width: '90%',
            maxHeight: '40px',
            height: 'auto',
            overflow: 'auto',
            padding: '2px 3px',
            '&::-webkit-scrollbar': {
                width: 0.5,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.light,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: '#fff',
                height: 5,
            },
        },
        eventDivModal: {
            width: '100%',
            height: 'auto',
            paddingTop: '5px',
            display: 'flex',
            flexDirection: 'column',
        },
        eventDivMobile: {
            width: '90%',
            height: 'auto',
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        today: {
            width: 'calc(100% / 7)',
            height: 'calc((100vh - 210px) / 5)',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            border: '0.5px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 5,
        },
        denied: {
            width: '100%',
            borderRadius: 2,
            height: 15,
            fontSize: 8,
            color: '#000',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-evenly',
            backgroundColor: '#FAD9D9',
            marginBottom: 1,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 10,
        },
        approved: {
            borderRadius: 2,
            height: 15,
            fontSize: 8,
            color: '#000',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-evenly',
            backgroundColor: '#DAF6E7',
            width: '100%',
            marginBottom: 1,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 10,
        },
        pending: {
            borderRadius: 2,
            height: 15,
            fontSize: 8,
            color: '#000',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-evenly',
            marginBottom: 1,
            backgroundColor: '#FEF2DA',
            width: '100%',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 10,
        },
        holidayDiv: {
            borderRadius: 2,
            height: 15,
            fontSize: 8,
            color: '#695FDE',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#E0DEFF',
            width: '100%',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginBottom: 1,
        },
        holidayDivMobile: {
            width: '80%',
            borderRadius: 2,
            height: 2,
            fontSize: 8,
            color: '#000',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: '#695FDE',
            marginBottom: 1,
            cursor: 'pointer',
        },
        deniedMobile: {
            width: '80%',
            borderRadius: 2,
            height: 2,
            fontSize: 8,
            color: '#000',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: '#CE3B3B',
            marginBottom: 1,
            cursor: 'pointer',
        },
        approvedMobile: {
            borderRadius: 1,
            height: 2,
            fontSize: 8,
            color: '#000',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: '#088843',
            width: '80%',
            marginBottom: 1,
            cursor: 'pointer',
        },
        pendingMobile: {
            borderRadius: 1,
            height: 2,
            fontSize: 8,
            color: '#000',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: '#F39322',
            width: '80%',
            cursor: 'pointer',
        },
        deniedText: {
            color: '#CE3B3B',
            marginLeft: 5,
        },
        approvedText: {
            color: '#088843',
            marginLeft: 5,
        },
        pendingText: {
            color: '#F2890D',
            marginLeft: 5,
        },
        modalContent: {
            paddingTop: '10px',
            paddingRight: 10,
            paddingBottom: 10,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 400,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 3,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
        modalText: {
            display: 'inline-block',
            fontFamily: theme.typography.fontFamily,
            fontSize: 14,
            margin: '10px 0',
            color: theme.palette.text.primary,
        },
        btn: {
            textTransform: 'capitalize',
            height: 30,
            width: 150,
            fontSize: 12,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
            },
        },
        denyModalContent: {
            display: 'flex',
            '@media screen and (max-width: 768px)': {
                flexDirection: 'column',
            },
        },
        btnOutlined: {
            textTransform: 'capitalize',
            height: 30,
            width: 100,
            fontSize: 12,
            marginRight: 10,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
                marginRight: 5,
            },
        },
        btnOutlinedCancel: {
            textTransform: 'capitalize',
            height: 30,
            width: 120,
            fontSize: 12,
            marginRight: 10,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
                marginRight: 5,
            },
        },
        btnFilled: {
            textTransform: 'capitalize',
            height: 30,
            width: 100,
            fontSize: 12,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
            },
        },
        btnFilledEdit: {
            textTransform: 'capitalize',
            height: 30,
            width: 120,
            fontSize: 12,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                width: '100%',
                margin: '5px 0',
            },
        },
        inputSelectStyle: {
            '& div': {
                backgroundColor: theme.palette.background.paper,
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    backgroundColor: theme.palette.background.paper,
                    width: '450px',
                    height: '20px',
                    fontSize: '15px',
                    color: theme.palette.text.primary,
                },
            },
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        fieldDivDateTimeSecond: {
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            // marginBottom: '10px',
            '@media screen and (max-width: 768px)': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
        },
        inputtextStyle: {
            '& div': {
                backgroundColor: theme.palette.background.paper,
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    backgroundColor: theme.palette.background.paper,
                    // width: '450px',
                    // height: '20px',
                    fontSize: '15px',
                    color: theme.palette.text.primary,
                },
            },
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        editinputtextStyle: {
            // '.MuiFormControl-fullWidth': {
            //     width: '50%',
            // },
            // '.MuiFormControl-root': {
            //     width: '100%',
            //     '@media screen and (max-width: 768px)': {
            //         width: '50%',
            //     },
            // },
            '& div': {
                backgroundColor: theme.palette.background.paper,
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '15px',
                    color: theme.palette.text.primary,
                },
            },
            color: theme.palette.text.primary,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        formLabelStyle: {
            color: theme.palette.text.primary,
            fontWeight: 600,
            fontSize: '14px',
            textTransform: 'capitalize',
            marginBottom: 10,
            '@media screen and (max-width: 770px)': {
                lineHeight: '22px',
            },
        },
        formLabelStyle2: {
            color: theme.palette.text.primary,
            fontWeight: 600,
            fontSize: '14px',
            textTransform: 'capitalize',
            marginBottom: 0,
            '@media screen and (max-width: 770px)': {
                lineHeight: '22px',
            },
        },
        formControlTime: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            display: 'grid',
            gridTemplateColumns: '1fr 0.8fr',
            gridColumnGap: 10,
        },
        editformControlTime: {
            // width: '50%',
            background: 'red',
            // backgroundColor: theme.palette.background.paper,
        },
        inputReason: {
            width: '100%',
            height: 35,
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: theme.palette.text.primary,
            '@media screen and (max-width: 770px)': {
                width: '100%',
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        formControl: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        fieldDiv: {
            marginBottom: '10px',
        },
        radioNameStyle: {
            letterSpacing: '0.03em',
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.text.primary,
            marginTop: '5px',
        },
        radioGroupStyles: {
            marginTop: '0px',
        },
        errorMessage: {
            color: 'red',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
        },
    }),
);
export default myStyle;
