import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import NotFound from '../Shoutout/undraw_empty.svg';
import NotFoundDark from '../Shoutout/undraw_empty_dark.svg';
import React, { useContext, useState } from 'react';
import { useStyles } from './style';
import AuthContext from 'context/AuthContext';
import { Avatar, Grid, Hidden, Typography, useTheme } from '@material-ui/core';
import AddEmojiLight from '../Shoutout/addEmojiLight.svg';
import AddEmojiDark from '../Shoutout/addEmojiDark.svg';
import CommentLight from '../Shoutout/commentLight.svg';
import CommentDark from '../Shoutout/commentDark.svg';
import { emojis } from '../Shoutout/Main';
import {
    addCelebrationComment,
    addCelebrationReaction,
    deleteCelebrationReaction,
    getComment,
    getReaction,
    updateCelebrationReaction,
} from 'helpers/celebrationApi';
import MyModal from 'components/Modal/Modal';
import { Icon } from '@iconify/react';
import { getCurrentCountryDateTime, isoToLocal, localToISO } from 'helpers/timezone';
import { useSubscription } from '@apollo/client';
import { Reactions, Comment } from './rcfile';
import { getFullName } from 'helpers/getInitials';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';

export const AddEmoji = (props: {
    reactions: any;
    celebrationId: string;
    data?: any;
    setCardData?: any;
    setOpen?: any;
    mobile?: boolean;
    setCommentData?: any;
    setOption?: any;
    option?: any;
    open?: any;
}) => {
    // //console.log(props.open);
    const classes = useStyles();
    const theme = useTheme();
    const mycontext = useContext(AuthContext);
    const [open, setOpen] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [rdata, setRdata] = useState<any>([]);
    const [commentModal, setCommentModal] = useState<boolean>(false);
    const [reactionmodal, setReactionModal] = useState<boolean>(false);
    const comment = useSubscription(getComment(props.celebrationId));
    const reaction = useSubscription(getReaction(props.celebrationId));
    const [input, setInput] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    React.useMemo(() => {
        if (!reaction.loading) {
            const data: any = reaction?.data?.celebrationdb_celebration_reaction;
            // //console.log(data);
            if (data?.error) {
                // //console.log(data?.error);
                setIsError(true);
            } else {
                setRdata(data);
            }
        }
    }, [reaction]);

    // //console.log(rdata);
    React.useMemo(() => {
        if (!comment.loading) {
            const data: any = comment?.data?.celebrationdb_CelebrationComment;
            //console.log('data', data);
            if (data?.error) {
                // //console.log(data?.error);
                setIsError(true);
            } else {
                setData(data);
            }
        }
    }, [comment]);

    const handleReaction = () => {
        if (props.mobile === false) {
            setReactionModal(true);
        }
        if (props.option === 'comment') {
            props.setOption('reaction');
        }
        if (props.open === 0) {
            props.setOption('reaction');
            props.setOpen(3);
            props.setCardData(props.data);
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            if (input === '') {
                throw new Error('please type something to submit');
            }
            const obj = {
                celebrationId: props.celebrationId,
                commentText: input,
                commentDateTime: localToISO(
                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                    mycontext.country,
                ),
                employeeId: mycontext.EmployeeId,
                token: mycontext.token,
                Employee: {
                    firstName: mycontext.fname,
                    lastName: mycontext.lname,
                    profileImage: mycontext.profile,
                },
            };
            setInput('');
            const resp = await addCelebrationComment(obj);
            if (resp.error) {
                throw new Error('Comment was not added');
            }
            // //console.log(obj);
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err.message,
                severity: 'error',
            });
        }
    };

    const handleComment = () => {
        if (props.mobile === false) {
            setCommentModal(true);
        }
        if (props.option === 'reaction') {
            props.setOption('comment');
            props.setCommentData(data);
        }
        if (props.open === 0) {
            props.setOption('comment');
            props.setOpen(3);
            props.setCardData(props.data);
            props.setCommentData(data);
        }
    };

    const addReaction = async (emoji: any) => {
        // //console.log('inside reaction');
        const obj = {
            celebrationId: props?.celebrationId,
            employeeId: mycontext.EmployeeId,
            emoji: emoji,
            token: mycontext.token,
        };
        try {
            if (rdata?.find((r: any) => r.employeeId === mycontext.EmployeeId) === undefined) {
                const resp = await addCelebrationReaction(obj);
                // //console.log('inside add reaction');
                if (resp.error) {
                    throw new Error('unable to add reaction');
                }
            } else {
                if (
                    rdata?.find((r: any) => r.employeeId === mycontext.EmployeeId)?.reaction?.emoji === obj.emoji?.emoji
                ) {
                    const resp = await deleteCelebrationReaction(obj);
                    // //console.log('inside delete reaction');
                    if (resp.error) {
                        throw new Error('unable to delete reaction');
                    }
                } else {
                    const resp = await updateCelebrationReaction(obj);
                    // //console.log('inside update reaction');
                    if (resp.error) {
                        throw new Error('unable to update reaction');
                    }
                }
            }
        } catch (err) {
            return err;
        }
    };

    return (
        <>
            <MyModal
                setOpen={setReactionModal}
                open={reactionmodal}
                onModalClosed={() => {
                    setReactionModal(false);
                }}
                title="Reactions"
            >
                <Reactions reactions={rdata} />
            </MyModal>
            <MyModal
                setOpen={setCommentModal}
                open={commentModal}
                onModalClosed={() => {
                    setCommentModal(false);
                }}
                title="Comments"
            >
                <div style={{ height: 380 }}>
                    <div className={classes.modal}>
                        {data?.length === 0 || isError ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {mycontext.themeToggle ? (
                                    <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                                ) : (
                                    <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                                )}
                                <Typography className={classes.heading}>
                                    {isError ? 'something want wrong' : 'No comments found!'}
                                </Typography>
                            </div>
                        ) : (
                            <>
                                {data?.map((b: any) => (
                                    <Comment key={`comment${b + 1}`} comment={b} />
                                ))}
                            </>
                        )}
                    </div>
                    <div>
                        <form onSubmit={handleSubmit} className={classes.input}>
                            <input
                                type="text"
                                placeholder="Type your message..."
                                value={input}
                                onChange={(e: any) => setInput(e.target.value)}
                                className={classes.commentBox}
                            />
                            <button type="submit" className={classes.commentBtn}>
                                <Icon icon="carbon:send-filled" color={theme.palette.text.secondary} width="24" />
                            </button>
                        </form>
                    </div>
                </div>
            </MyModal>
            <div onMouseLeave={() => setOpen(false)} style={{ display: 'flex' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        position: 'relative',
                    }}
                    onMouseEnter={() => setOpen(true)}
                >
                    <div
                        className={open ? classes.emojiPicker1 : classes.empty}
                        style={{ marginTop: -10 }}
                        onMouseEnter={() => setOpen(true)}
                    >
                        {emojis.map((e: any, i: number) => (
                            <span
                                key={`emojiAdd${i}`}
                                className={
                                    rdata?.find((r: any) => r.employeeId === mycontext.EmployeeId)?.reaction?.emoji ===
                                    e.emoji
                                        ? classes.emojiactive
                                        : classes.none
                                }
                                style={{ fontSize: 18 }}
                                onClick={() => addReaction(e)}
                            >
                                {e.emoji}
                            </span>
                        ))}
                    </div>
                    {mycontext.themeToggle ? (
                        <img
                            src={AddEmojiDark}
                            alt="add-emoji"
                            style={{ width: 20, height: 20 }}
                            onClick={handleReaction}
                        />
                    ) : (
                        <img
                            src={AddEmojiLight}
                            alt="add-emoji"
                            style={{ width: 20, height: 20 }}
                            onClick={handleReaction}
                        />
                    )}
                    <span className={classes.footerText} onClick={handleReaction}>
                        {rdata?.length} reactions
                    </span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        paddingLeft: '50px',
                    }}
                    onClick={handleComment}
                >
                    {mycontext.themeToggle ? (
                        <img src={CommentDark} alt="add-emoji" style={{ width: 20, height: 20 }} />
                    ) : (
                        <img src={CommentLight} alt="add-emoji" style={{ width: 20, height: 20 }} />
                    )}
                    <span className={classes.footerText}>{data?.length} comments</span>
                </div>
            </div>
        </>
    );
};

export function CelebrationCard(props: {
    data: any;
    setCardData: any;
    setOpen: any;
    setCommentData: any;
    open: any;
    option: any;
    setOption: any;
    // subscriptionToday?: any;
    // subscriptionPast?: any;
}) {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const [page, setPage] = useState<number>(0);

    //console.log(typeof props?.data?.length);

    if (props?.data?.length === 0 || props?.data?.length === undefined) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '55px 10px 0px 10px',
                }}
            >
                {mycontext.themeToggle ? (
                    <img src={NotFoundDark} alt="" style={{ width: '50%', height: '60%' }} />
                ) : (
                    <img src={NotFound} alt="" style={{ width: '50%', height: '60%' }} />
                )}
                <Typography className={classes.heading}>No Celebration found</Typography>
            </div>
        );
    } else {
        return (
            <div className={classes.cardData}>
                <div>
                    <ArrowBackIos
                        data-testid="backward"
                        className={classes.forwardBtn}
                        style={page === 0 ? { color: 'grey' } : undefined}
                        onClick={() => (page !== 0 ? setPage(page - 1) : null)}
                    />
                </div>
                <div className={classes.card} style={{ backgroundColor: '#EA5B8E30' }}>
                    {props.data?.slice(page * 1, page * 1 + 1)?.map((a: any, i: number) => (
                        <div
                            key={`card${i}`}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar src={a?.category === 'other' ? null : a?.Employee?.profileImage}>
                                    {a?.category === 'other'
                                        ? `${a?.title.substring(0, 2)}`
                                        : `${getFullName(a?.Employee?.firstName + ' ' + a?.Employee?.lastName)}`}
                                </Avatar>
                                <div className={classes.heading} style={{ paddingLeft: 15 }}>
                                    <span>{a?.title}</span>
                                    <p>{a?.description}</p>
                                </div>
                            </div>
                            <img src={a?.attachmentFile} data-testid="myId" className={classes.image} alt="Giphy" />
                            <div>
                                <Hidden mdDown>
                                    <AddEmoji
                                        reactions={a?.celebration_reactions}
                                        celebrationId={a?.celebrationId}
                                        mobile={false}
                                    />
                                </Hidden>
                                <Hidden lgUp>
                                    <AddEmoji
                                        reactions={a?.celebration_reactions}
                                        celebrationId={a?.celebrationId}
                                        data={a}
                                        setCardData={props.setCardData}
                                        setOpen={props.setOpen}
                                        mobile={true}
                                        setCommentData={props.setCommentData}
                                        open={props.open}
                                        option={props.option}
                                        setOption={props.setOption}
                                    />
                                </Hidden>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <ArrowForwardIos
                        data-testid="Forward"
                        className={classes.forwardBtn}
                        onClick={() => (page !== props.data?.length - 1 ? setPage(page + 1) : null)}
                        style={page === props.data?.length - 1 ? { color: 'grey' } : undefined}
                    />
                </div>
            </div>
        );
    }
}

export const MobileCard = (props: {
    setOpen: any;
    cardData: any;
    commentData: any;
    option: any;
    setOption: any;
    setCommentData: any;
}) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    const [input, setInput] = useState<string>('');
    const theme = useTheme();
    const [data, setData] = useState<any>([]);
    const [rData, setRdata] = useState<any>([]);
    const reaction = useSubscription(getReaction(props.cardData?.celebrationId));
    //console.log(reaction);
    const [isError, setIsError] = useState<boolean>(false);
    const comment = useSubscription(getComment(props.cardData?.celebrationId));
    //console.log(props.option);
    React.useMemo(() => {
        if (!comment.loading) {
            const data: any = comment?.data?.celebrationdb_CelebrationComment;
            //console.log('data', data);
            if (data?.error) {
                //console.log(data?.error);
                setIsError(true);
            } else {
                setData(data);
            }
        }
    }, [comment]);

    React.useMemo(() => {
        if (!reaction.loading) {
            const data: any = reaction?.data?.celebrationdb_celebration_reaction;
            // //console.log(data);
            if (data?.error) {
                setIsError(true);
                //console.log(data?.error);
            } else {
                setRdata(data);
            }
        }
    }, [reaction]);
    //console.log(rData);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            if (input === '') {
                throw new Error('please type something to submit');
            }
            const obj = {
                celebrationId: props.cardData?.celebrationId,
                commentText: input,
                commentDateTime: localToISO(
                    getCurrentCountryDateTime(mycontext.country?.zoneName).date,
                    getCurrentCountryDateTime(mycontext.country?.zoneName).time,
                    mycontext.country,
                ),
                employeeId: mycontext.EmployeeId,
                token: mycontext.token,
                Employee: {
                    firstName: mycontext.fname,
                    lastName: mycontext.lname,
                    profileImage: mycontext.profile,
                },
            };
            setInput('');
            const resp = await addCelebrationComment(obj);
            if (resp.error) {
                throw new Error('Comment was not added');
            }
            //console.log(obj);
        } catch (err) {
            mycontext.togglePopup({
                alertMessage: err.message,
                severity: 'error',
            });
        }
    };
    // //console.log()

    return (
        <div>
            <Grid container style={{ marginBottom: 5 }}>
                <Grid item>
                    <ArrowBackIcon className={classes.backArrow} onClick={() => props.setOpen(0)} />
                </Grid>
                <Grid item>
                    <Typography className={classes.back}>
                        {props.cardData?.celebrationDate?.substring(0, 10) < new Date().toISOString()?.substring(0, 10)
                            ? 'Past’s Celebrations'
                            : 'Today’s Celebrations'}
                    </Typography>
                </Grid>
            </Grid>
            <div className={classes.mobile}>
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 40 }}>
                    <Avatar src={props.cardData.Employee.profileImage}>
                        {getFullName(props.cardData.Employee.firstName + ' ' + props.cardData.Employee.lastName)}
                    </Avatar>
                    <div style={{ paddingLeft: 8 }} className={classes.heading}>
                        {props.cardData.title} {props.cardData.description}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                    <AddEmoji
                        reactions={props.cardData?.celebration_reactions}
                        celebrationId={props.cardData?.celebrationId}
                        setOption={props.setOption}
                        option={props.option}
                        setCommentData={props.setCommentData}
                    />
                    <div className={classes.footerText} style={{ marginTop: 'auto' }}>
                        {props.cardData?.celebrationDate?.substring(0, 10) ===
                        new Date().toISOString()?.substring(0, 10)
                            ? 'Today'
                            : moment(isoToLocal(props.cardData?.celebrationDate, mycontext.country)).fromNow() ===
                              'a day ago'
                            ? 'Yesterday'
                            : moment(isoToLocal(props.cardData?.celebrationDate, mycontext.country)).fromNow()}
                    </div>
                </div>
                {props.option === 'comment' && (
                    <div
                        style={{
                            height: 380,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className={classes.modal}>
                            <div className={classes.heading}>Comments</div>
                            {data?.length === 0 || isError ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {mycontext.themeToggle ? (
                                        <img src={NotFoundDark} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                                    ) : (
                                        <img src={NotFound} alt="" style={{ maxWidth: '100%', height: '150px' }} />
                                    )}
                                    <Typography className={classes.heading}>
                                        {isError ? 'something want wrong' : 'No comments found!'}
                                    </Typography>
                                </div>
                            ) : (
                                <>
                                    {data?.map((b: any, i: number) => (
                                        <Comment key={`abcd${i + 1}`} comment={b} />
                                    ))}
                                </>
                            )}
                        </div>
                        <div>
                            <form onSubmit={handleSubmit} className={classes.input}>
                                <input
                                    type="text"
                                    placeholder="Type your message..."
                                    value={input}
                                    onChange={(e: any) => setInput(e.target.value)}
                                    className={classes.commentBox}
                                />
                                <button type="submit" className={classes.commentBtn}>
                                    <Icon icon="carbon:send-filled" color={theme.palette.text.secondary} width="24" />
                                </button>
                            </form>
                        </div>
                    </div>
                )}
                {props.option === 'reaction' && (
                    <div>
                        <div className={classes.heading}>Reaction</div>
                        <Reactions reactions={rData} />
                    </div>
                )}
            </div>
        </div>
    );
};
