import {
    Paper,
    Modal,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Button,
    Chip,
    FormLabel,
    InputAdornment,
} from '@material-ui/core';
import React, { useContext } from 'react';
import useStyles from './AddNoteStyles';
import CloseIcon from '@material-ui/icons/Close';
import AuthContext from 'context/AuthContext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { employeeTags, standupNote, updateStandup } from 'helpers/hasuraapi3';
import { getCurrentCountryDateTime, localToISO, isoToLocal } from '../../helpers/timezone';
import { ToStringFormat } from '../../helpers/date';
import { postFirstStandupMessage, postStandupMessage, updateStandupMessage } from './SendChatMessages';
// import { handleStandup } from 'helpers/standupNotif';

interface Props {
    modal: boolean;
    setModal: any;
    data?: any;
    tags?: any;
    // myData?: any;
    allStandups?: any;
    ans?: any;
    setAns?: any;
    date?: any;
    setDate?: any;
    todos?: any;
    setTodos?: any;
    check?: any;
    setCheck?: any;
    modalAns?: any;
    setModalAns?: any;
    myStandup?: any;
    setMyStandup?: any;
    questions?: any;
    setStep?: any;
    setAllTags?: any;
    userSubscriptionDetail?: any;
    todaysStandup?: any;
    myteams?: any;
}

const get_random_color = () => {
    let color = '';
    for (let i = 0; i < 3; i++) {
        const sub = Math.floor(Math.random() * 256).toString(16);
        color = color + (sub.length === 1 ? '0' + sub : sub);
    }
    return '#' + color;
};

export default function AddNotes(props: Props) {
    const classes = useStyles();
    // const [check, setCheck] = React.useState('');
    const [click, setClick] = React.useState(false);
    const [todo, setTodo] = React.useState<any>('');
    const [isError, setIsError] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [loading, setLoading] = React.useState<boolean>(false);
    // const [tag, setTags] = React.useState<any>([]);
    const mycontext = useContext(AuthContext);

    const getStandupsData = () => {
        let isStart = false;
        let threadId = null;
        let isStandup = false;
        let messageId = null;
        let standupNoteId = null;
        let myStandupNote = {};
        // console.log("myteams", props.myteams);
        const channels = props.myteams.filter(
            (t: any) => t.msChannelId !== null && t.isbotAdded['standup'] !== undefined,
        );
        if (channels.length !== 0) {
            if (props.todaysStandup.length === 0) {
                // console.log("line 355", props.todaysStandup);
                isStart = true;
            } else {
                // console.log("line 361 ", props.todaysStandup);
                isStart = false;
                // threadId = props.todaysStandup[0]?.threadId;
                threadId =
                    props.todaysStandup[0]?.threadId !== null
                        ? props.todaysStandup[0]?.threadId
                        : props.todaysStandup.flter((d: any) => d.threadId !== null)?.length === 0
                        ? null
                        : props.todaysStandup.flter((d: any) => d.threadId !== null)[0].threadId;
                if (threadId === null) {
                    isStart = true;
                }
                const current = props.todaysStandup.find((d: any) => d.employeeId === mycontext.EmployeeId);
                // console.log("current", current);
                if (current !== undefined) {
                    isStandup = true;
                    threadId = current.threadId;
                    messageId = current.msgId;
                    standupNoteId = current.standupNoteId;
                    myStandupNote = current;
                }
            }
            return {
                isStart,
                threadId,
                isStandup,
                messageId,
                standupNoteId,
                myStandupNote,
                channelId: channels[0]?.msChannelId,
                serviceUrl: channels.length === 0 ? undefined : channels[0]?.channelServiceUrl.standup,
            };
        } else {
            return {
                channelId: undefined,
            };
        }
    };

    const handleClick = () => {
        if (click === true) {
            setClick(false);
        } else {
            setClick(true);
        }
    };
    const handleClick2 = () => {
        if (click === true) {
            setClick(false);
        } else {
            setClick(true);
        }
    };

    const handleCheck = (event: any) => {
        props.setCheck(event.target.value);
    };
    const handleChips = (e: any) => {
        let check = false;
        props?.myStandup?.map((f: any) =>
            f.tags?.map((tag: any) => {
                if (e?.name?.includes(tag?.name)) {
                    // console.log(true);
                    check = true;
                }
                return null;
            }),
        );

        if (!props.todos?.includes(e) && !check) {
            props.setTodos((prev: any) => [...prev, e]);
        }
        return null;
    };

    const dateChange = (event: React.ChangeEvent<{ value: string }>) => {
        // // console.log("iam ", z)
        props.setDate(event.target.value as string);
        const date = event.target.value as string;
        const z: any = props.allStandups?.filter(
            (e: any) =>
                isoToLocal(e?.standupNoteDateTime, mycontext.country)
                    .toString()
                    .substring(0, 15) === new Date(date).toString().substring(0, 15) &&
                e.employeeId === mycontext.EmployeeId,
        );
        props.setMyStandup(z);
        // console.log(z);
        // if (z?.length === 0) {
        //     for (let i = 0; i < props?.data?.length; i++) {
        //         props.setAns({});
        //     }
        //     props.setCheck('Yes');
        //     props.setTodos([]);
        // }
        if (z?.length !== 0) {
            const answers: any = z[0]?.answers?.map((f: any) => f?.answer);
            if (z[0].answers?.length !== 0) {
                for (let i = 0; i < answers?.length; i++) {
                    props.setAns((prev: any) => ({ ...prev, [i]: answers[i] }));
                }
            }
            if (z[0].answers?.length === 0) {
                props.setAns({});
            }
            if (z[0].tags?.length !== 0) {
                props.setTodos([]);
                props.setTodos(z[0].tags);
            }
            if (z[0].tags?.length === 0) {
                props.setTodos([]);
            }
            if (z[0].isAccomplished === true) {
                props.setCheck('Yes');
            } else {
                props.setCheck('No');
            }
        } else {
            props.setAns({});
            props.setCheck('Yes');
            props.setTodos([]);
        }
    };

    const handleremove = async (name: any) => {
        const list = [...props.todos];
        const index = props.todos.findIndex((a: any) => a.name === name);

        list.splice(index, 1);
        props.setTodos(list);
    };

    const handleChange = (key: any, value: any) => {
        props.setAns((prev: any) => ({
            ...prev,
            [key]: value,
        }));
        setIsError(true);
        setMessage('');
    };
    // console.log('todo', props.todos);
    const handlePost = async () => {
        // clearReducers();
        try {
            const mydata = getStandupsData();
            setLoading(true);
            if (
                props.userSubscriptionDetail?.standupSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('sbtp'))
                    .length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.standupSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('sbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                props.userSubscriptionDetail?.standupSubscriptionId === null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('sbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                props.userSubscriptionDetail?.standupSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.standupSubscriptionId &&
                        s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                props.userSubscriptionDetail?.standupSubscriptionId !== null &&
                props.userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === props.userSubscriptionDetail?.standupSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            if (props?.data?.tags?.length < props.tags?.length) {
                const obj = {
                    tag: props?.tags,
                    employeeId: mycontext.EmployeeId,
                    organizationId: mycontext.organizationId,
                    token: mycontext.token,
                };
                // console.log(obj);
                const resp: any = await employeeTags(obj);
                if (resp?.error) {
                    throw new Error('failed to create tag');
                }
            }
            const arr: any = Object.values(props?.ans).map((v: any) => v);
            const b: any = props?.data.questions;
            const arr3: any = b?.reduce((accumulator: any, ele: any, index: number) => {
                return [...accumulator, { answer: arr[index], question: ele }];
            }, []);

            if (
                props.date >
                ToStringFormat(getCurrentCountryDateTime(mycontext.country.zoneName).dateTime, 'YYYY-MM-DD')
            ) {
                throw new Error("You Can't Add Standup For Upcoming Dates");
            }
            if (arr?.length !== b?.length) {
                throw new Error('Please Enter All Answers');
            }

            // for (let i = 0; i < arr3?.length; i++) {
            //     if (arr3[i]?.answer === '') {
            //         throw new Error('Please Enter All Answers');
            //     }
            // }
            else {
                const obj = {
                    answers: arr3,
                    questions: b,
                    standupNoteDate: props.date,
                    isAccomplished: props.check === 'Yes',
                    standupTempId: mycontext.standupTempId,
                    employeeId: mycontext.EmployeeId,
                    standupNoteTime: new Date().toString().slice(16, 21),
                    token: mycontext.token,
                    tag: props?.todos?.slice(0, 4),
                    standupNoteDateTime: localToISO(
                        props.date,
                        getCurrentCountryDateTime(mycontext.country.zoneName).time,
                        mycontext.country,
                    ),
                };
                // console.log('abcdgg', obj);
                const abcd: any = await standupNote(obj);
                props.setModal(false);
                // console.log('Insert standup notes response: ', JSON.stringify(abcd));
                if (abcd?.error) {
                    throw new Error('failed to create!');
                } else {
                    if (mydata.channelId !== undefined) {
                        if (mydata.isStart) {
                            await postFirstStandupMessage(
                                props.data?.startMessage,
                                props.data?.questions,
                                props.data?.standupTempId,
                                mycontext.token,
                                mydata.channelId,
                                mydata.serviceUrl,
                                arr3,
                                mycontext,
                                props.check === 'Yes',
                                abcd.standupNoteId,
                                props.date,
                                localToISO(
                                    props.date,
                                    getCurrentCountryDateTime(mycontext.country.zoneName).time,
                                    mycontext.country,
                                ),
                            );
                        } else {
                            await postStandupMessage(
                                mydata.threadId,
                                props.data?.questions,
                                props.data?.standupTempId,
                                mycontext.token,
                                mydata.channelId,
                                mydata.serviceUrl,
                                arr3,
                                mycontext,
                                props.check === 'Yes',
                                abcd.standupNoteId,
                                props.date,
                                localToISO(
                                    props.date,
                                    getCurrentCountryDateTime(mycontext.country.zoneName).time,
                                    mycontext.country,
                                ),
                            );
                        }
                    }
                    mycontext.togglePopup({
                        alertMessage: 'Standup added successfully!',
                        severity: 'success',
                    });
                }
            }

            // handleStandup({
            //     action: 'add',
            //     orgId: mycontext.organizationId,
            //     date: props.date,
            //     token: mycontext.token,
            //     userName: abcd?.Employee.firstName + ' ' + abcd?.Employee.lastName,
            //     noteId: abcd?.standupNoteId,
            //     isAccomplished: props.check,
            //     questions: b,
            //     answers: arr3,
            //     welcomeMessage: abcd?.StandupTemplate.startMessage,
            // });
        } catch (err) {
            setIsError(true);
            setMessage(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = async () => {
        // console.log("calling update note");
        try {
            const mydata = getStandupsData();
            setLoading(true);
            if (props?.data?.tags?.length < props.tags?.length) {
                const obj = {
                    tag: props.tags,
                    employeeId: mycontext.EmployeeId,
                    organizationId: mycontext.organizationId,
                    token: mycontext.token,
                };
                // console.log(obj);
                const resp: any = await employeeTags(obj);
                if (resp?.error) {
                    throw new Error('failed to create tag');
                }
            }

            const standupNoteId = props.allStandups
                ?.filter((e: any) => e?.standupNoteDate === props.date && e.employeeId === mycontext.EmployeeId)
                .map((a: any) => a.standupNoteId)
                .toString();
            const ques: any = props?.myStandup[0]?.answers?.map((g: any) => g.question);
            const abcd: any = Object.values(props.ans).map((a: any) => a);
            // }
            const arr3: any = ques?.reduce((accumulator: any, ele: any, index: number) => {
                return [...accumulator, { answer: abcd[index], question: ele }];
            }, []);

            // const arr2: any = props.todos.map((t: any) => t.tagId);

            if (
                props.date >
                ToStringFormat(getCurrentCountryDateTime(mycontext.country.zoneName).dateTime, 'YYYY-MM-DD')
            ) {
                throw new Error("You Can't Add Standup For Upcoming Dates");
            }
            for (let i = 0; i < arr3.length; i++) {
                if (arr3[i].answer === '') {
                    throw new Error('Please Enter All Answers');
                }
            }
            // if (arr2.length === 0) {
            //     throw new Error('Please Select Atleast One Tag');
            // }
            const obj = {
                answers: arr3,
                standupNoteDate: props.date,
                isAccomplished: props.check === 'Yes',
                standupNoteId: standupNoteId,
                employeeId: mycontext.EmployeeId,
                standupNoteTime: new Date().toString().slice(16, 21),
                token: mycontext.token,
                tags: props.todos.slice(0, 4),
                standupNoteDateTime: localToISO(
                    props.date,
                    getCurrentCountryDateTime(mycontext.country.zoneName).time,
                    mycontext.country,
                ),
            };
            const abcde = await updateStandup(obj);
            if (abcde?.error) {
                throw new Error('failed to update standupnote');
            }
            if (mydata.channelId !== undefined) {
                if (mydata.isStandup) {
                    await updateStandupMessage(
                        props.data?.questions,
                        arr3,
                        mydata.channelId,
                        mycontext,
                        props.date,
                        localToISO(
                            props.date,
                            getCurrentCountryDateTime(mycontext.country.zoneName).time,
                            mycontext.country,
                        ),
                        mydata.serviceUrl,
                        mydata.messageId,
                        props.check === 'Yes',
                    );
                }
            }
            props.setModal(false);
            props.setStep(0);
            mycontext.togglePopup({
                alertMessage: 'Standup updated successfully!',
                severity: 'success',
            });

            // handleStandup({
            //     action: 'edit',
            //     orgId: mycontext.organizationId,
            //     userName: abcde?.Employee.firstName + ' ' + abcde?.Employee.lastName,
            //     date: props.date,
            //     threadId: abcde?.threadId,
            //     noteId: standupNoteId,
            //     isAccomplished: props.check,
            //     questions: abcde?.StandupTemplate.questions,
            //     answers: arr3,
            //     timestamp: abcde?.timestamp,
            //     token: mycontext.token,
            // });
        } catch (err) {
            setIsError(true);
            setMessage(err.message);
        } finally {
            setLoading(false);
        }
    };

    // console.log(tag)
    const handleSubmit = async (e: any) => {
        // console.log('Abcd', props.tags);
        e.preventDefault();
        try {
            setMessage('');
            const chip: string = todo.trim();
            if (
                props.todos.filter((a: any) => a.name.toLowerCase() === chip.toLowerCase()).length > 0 ||
                props.tags.filter((a: any) => a.name.toLowerCase() === chip.toLowerCase()).length > 0
            ) {
                throw new Error('same tag already present');
            }
            if (chip.match(/[0-9,`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/)) {
                throw new Error('please type only alphabet in tags');
            }
            if (chip.length > 11) {
                throw new Error('tag length should be less than 10');
            }
            if (chip.match(/[ ]/)) {
                throw new Error('space in tags are not allowed');
            }
            if (chip === '') {
                // props.setModal(false);
                throw new Error('please type something in tags box or select tags');
            } else {
                const obj = {
                    name: chip,
                    color: get_random_color(),
                };
                props.setTodos((prev: any) => [...prev, obj]);
                props.setAllTags((prev: any) => [...prev, obj]);
                setTodo('');
            }
        } catch (err) {
            setMessage(err.message);
        }
    };

    return (
        <div>
            <Modal open={props.modal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div className={classes.modal}>
                    <Paper className={classes.paper1} elevation={0}>
                        {/* <div> */}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                position: 'relative',
                                width: '100%',
                                paddingRight: 30,
                            }}
                        >
                            <div>
                                <span className={classes.text}>Standup Notes for &nbsp;</span>
                                <TextField
                                    data-testid="test"
                                    id="date"
                                    type="date"
                                    inputProps={{ className: classes.icon }}
                                    value={props.date}
                                    onChange={dateChange}
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.icon,
                                    }}
                                />
                            </div>
                            {isError && (
                                <div style={{ marginTop: 5, marginLeft: 3 }}>
                                    <FormLabel component="legend" error>
                                        {message}
                                    </FormLabel>
                                </div>
                            )}
                        </div>
                        <div className={classes.new}>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={12} lg={8}>
                                    <div className={classes.text}>Did you accomplish yesterday’s task?</div>
                                </Grid>
                                <Grid item xs={12} lg={4} style={{ padding: 5 }}>
                                    <RadioGroup className={classes.set} value={props.check} onChange={handleCheck}>
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio checked={props.check === 'Yes'} />}
                                            label={<span className={classes.text}>Yes</span>}
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio checked={props.check === 'No'} />}
                                            label={<span className={classes.text}>No</span>}
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container direction="column">
                                {props.myStandup?.length === 0
                                    ? props?.data?.questions?.map((v: any, i: number) => (
                                          <Grid item key={i}>
                                              <div className={classes.text}>{v}</div>
                                              <TextField
                                                  className={classes.inputBox}
                                                  size="small"
                                                  variant="outlined"
                                                  fullWidth
                                                  multiline
                                                  value={props?.ans[i]}
                                                  onChange={(e: any) => handleChange(i, e.target.value)}
                                              />
                                          </Grid>
                                      ))
                                    : props.myStandup?.map((e: any, j: any) =>
                                          e?.answers?.map((m: any, i: number) => (
                                              <Grid item key={i}>
                                                  <div className={classes.text}>{m.question}</div>
                                                  <TextField
                                                      className={classes.inputBox}
                                                      size="small"
                                                      variant="outlined"
                                                      fullWidth
                                                      multiline
                                                      value={props?.ans[i]}
                                                      onChange={(e: any) => handleChange(i, e.target.value)}
                                                  />
                                              </Grid>
                                          )),
                                      )}
                            </Grid>
                            <Grid item>
                                <div className={classes.text} style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>Tags (Optional)</span>
                                </div>
                                <div onClick={handleClick}>
                                    <form onSubmit={handleSubmit} data-testid="open">
                                        <TextField
                                            value={todo}
                                            className={classes.inputScroll1}
                                            fullWidth
                                            onChange={e => setTodo(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {props?.todos?.slice(0, 4).map((e: any, i: number) => (
                                                            <div key={i}>
                                                                {e?.name?.indexOf() === -1 ? (
                                                                    <Chip
                                                                        key={i}
                                                                        label={e?.name}
                                                                        style={{
                                                                            background: e.color,
                                                                            color: 'white',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            marginLeft: 5,
                                                                            marginTop: 3,
                                                                            height: '2em',
                                                                        }}
                                                                        deleteIcon={
                                                                            <CloseIcon className={classes.closeIcon} />
                                                                        }
                                                                        onDelete={() => handleremove(e?.name)}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </InputAdornment>
                                                ),
                                                endAdornment: <ArrowDropDownIcon onClick={handleClick2} />,
                                            }}
                                        />
                                        <button type="submit"></button>
                                    </form>
                                    {click && (
                                        <div className={classes.inputScroll} data-testid="abcd">
                                            {props.tags
                                                ?.filter((x: any) =>
                                                    x?.name?.toLowerCase()?.includes(todo?.toLowerCase()),
                                                )
                                                ?.map((e: any, index: number) => (
                                                    <Chip
                                                        data-testid="chip"
                                                        key={index}
                                                        style={{
                                                            background: e.color,
                                                            color: 'white',
                                                            marginLeft: 5,
                                                        }}
                                                        label={e.name}
                                                        onClick={() => handleChips(e)}
                                                        clickable={true}
                                                    />
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </div>
                        <CloseIcon
                            data-testid="close"
                            onClick={() => {
                                props?.setModal(false);
                                setIsError(false);
                            }}
                            style={{ position: 'absolute', top: 18, right: 18, cursor: 'pointer' }}
                        />
                        <div className={classes.mainDiv}>
                            <Button
                                onClick={() => {
                                    props?.setModal(false);
                                    setIsError(false);
                                }}
                                variant="outlined"
                                className={classes.btn}
                            >
                                cancel
                            </Button>
                            {props.allStandups?.filter(
                                (e: any) => e?.standupNoteDate === props.date && e.employeeId === mycontext.EmployeeId,
                            ).length === 0 ? (
                                <Button
                                    data-testid="post"
                                    onClick={handlePost}
                                    variant="outlined"
                                    className={classes.btn1}
                                    disabled={loading}
                                >
                                    Post
                                </Button>
                            ) : (
                                <Button
                                    variant="outlined"
                                    className={classes.btn1}
                                    onClick={handleUpdate}
                                    disabled={loading}
                                >
                                    Update
                                </Button>
                            )}
                        </div>
                    </Paper>
                </div>
            </Modal>
        </div>
    );
}
