export const empdata = [
    {
        id: 1,
        ques: 'What is Time-Off?',
        ans:
            'Time-off is a unified feature that manages all the employee time-off related activities in single software. It streamlines the entire process and saves a lot of time for both employer and employee. Employees can apply for leave, check to leave status, check balance leave, leave type, and any relevant information at any time and any location.',
    },
    {
        id: 2,
        ques: 'Why Time-Off?',
        ans: `The tasks involved in managing time-offs can be tedious and tiring for the HRs/managers for these reasons:
        Keeping a record of every employee’s leave pattern manually 
        Updating and maintaining paperwork
        Making sure everyone in the organization follows the guidelines and company policies while taking leave
        Most of the above tasks require your HR team to stay running behind every employee and even with constant check-ins the probabilities of slipping details are very possible. This is exactly why you need a time-off tracking system for your company. It becomes all the more essential if you track time, attendance and hours worked to pay your employees compensation to avoid flaws, confusion, and as a result employee-employer conflict.
        With a robust leave management system, you can track time, attendance and hours worked to pay your employees compensation to avoid flaws, confusion, and as a result employee-employer conflict.        
        `,
    },
    {
        id: 3,
        ques: 'What are the benefits?',
        ans: `A time-off tracking system can assist you to establish your company’s leave policy/policies to its employees and also automate most of the methods ensuring glitches don't occur. It can also help in also have following benefits:
        Eliminates paperwork
        Removes manual interventions
        Improves communication
        Offers real-time visibility of data
        Ensures legal compliance
        Reflects your organization’s values
        Employee leave management system lets the workers apply for his/her own time-off, manage their leave balance and plan vacations beforehand. Making a crucial process simple for your employees increases their satisfaction with the organization.
        The time-off feature makes it possible for your employees, managers and HR personnel to access the system from anywhere. Employees can apply for leave from anywhere, approvers can approve/deny it from anywhere and HR personnel/managers can fetch information they need from anywhere, instantly.
        With Time-off, you can get information about the entire team’s availability.It is transparent, helps employees and managers decide time-off sensibly without creating dependencies. Miscommunication is completely taken off the table and vacations or leaves are planned beforehand without deters. Manual errors are completely made impossible with leave management software in situ.        
        `,
    },
    {
        id: 4,
        ques: 'How do I become part of Teamplus?',
        ans: `Teamplus admins send invite mail to employees of his/her organizations. Once, you receive the invite mail, you can click on the link.
        `,
    },
    {
        id: 5,
        ques: 'How can I apply a time-off?',
        ans: `Applying for a time-off is now possible with the Time-off feature. There is a simple form that the employees can fill to apply for a time-off. Employees can apply for a full day or custom time-off based on their requirement. They can select the reasons from the list of reasons available or write a custom reason.
        Once the leave has been applied,the employee can view it on their own dashboard. They can also view their other pending, approved and denied time-offs. The status of the applied time-off changes based on the approver’s action of approval or denial. An employee can cancel his/her own requested time-off in case he/she wishes to withdraw it.        
        `,
    },
    {
        id: 6,
        ques: 'How does the Dashboard help?',
        ans: `With dashboard, you can view your pending, approved and denied time-off requests at one place. Once the admin/ approver takes an action on your request, your status for the time-off request will change. This can help teams and well as individual employees to schedule events accordingly and be more productive.`,
    },
    {
        id: 7,
        ques: 'How can I view all the teams?',
        ans: `Employees can view the team list to understand more about the existing teams in the organization. Team details include team name, members and approvers in the team. To view team details you can go to a particular team and click on  '`,
        bullet: '⬤ ⬤ ⬤ ',
        ans2: `' > View Teams
        It will display you the list of employees in the respective team
        Admin can also remove a team member from the team by clicking on Delete Employee option.`,
    },
    {
        id: 8,
        ques: 'How can I view my profile?',
        ans: `To view your own profile, an employee can click on his/her own profile picture present on the right top corner of the navbar. On clicking it, a list of options appear, users can click on the Profile option to view their own profile.
        On Profile page you can: 
        View your name and profile photo
        View time-off status: Number of time-offs used and available are displayed on the pie chart as well as the count is displayed depending upon the time-off type.
        View time-off history: All the previous approved or denied time-offs applied till the current date can be seen.`,
    },
    {
        id: 9,
        ques: `How can I view another employee's profile?`,
        ans: `To view the profile of other employees within the organization, an employee can click on the profile picture of the employee and redirect to the respective profile page. You can also find the list of all the employees in your organization by going to the employees page. 
        As an employee you can:
        View the name of the employee
        View the profile photo
        View today’s time-offs and upcoming time-off for the respective employee        
        `,
    },
    {
        id: 10,
        ques: 'Can I view all the holidays of my organization?',
        ans: `Yes, you can view the holidays of the organization on this page.The “holiday list” option is available on your dashboard. You can also view the organization holidays in the calendar.
        `,
    },
    {
        id: 11,
        ques: 'Can I cancel my already approved time-off request?',
        ans: `Yes, you can deny an already approved time-off request before the date for which the time-off has been applied.`,
    },
    {
        id: 12,
        ques: 'How can an employee switch between organizations that he/she is part of?',
        ans: `Switch Department is used when you are part of multiple organizations. This feature is used for switching to another organization. When clicked on the  profile picture present on the right top corner of the navbar,  a list of options appear, users can click on the “Switch Department”,  and view a list of organizations of which he/she is a part of and can select the organization to which he/she wants to switch to.`,
    },
    {
        id: 13,
        ques: 'How can I change the password ?',
        ans: `You can change your password by using the “Change Password” option.
        Click on the top right corner of profile icon on navbar → Click Change Password → Click Send for OTP generation → Go to your registered email and get OTP → Redirected to Password Change →Enter OTP → Enter New password and Confirm Password → Click Update
        If you do not receive OTP on your registered email, go to the Password Change page again and click on “Resend OTP “.
        `,
    },
    {
        id: 14,
        ques: 'How does the calendar feature benefit me?',
        ans: `Calendar is one of the important features of Teamplus as it displays all the time-offs at one place. You can view your approved, denied, pending time-offs as well as the organization holidays. You can also view the approved time-offs of others in your organization. There are different legends on the top and one can click on any of those to view the respective type of time-offs. You can click on the employee name on a particular date tab to view more details regarding the time-off. An employee can cancel his/her own requested time-off in case he/she wishes to withdraw it before the date for which the time-off has been applied.
        `,
    },
    {
        id: 15,
        ques: 'How can I change the theme of my application?',
        ans: `Two themes are available one is Light mode and other is Dark mode.
        Click on the top right corner of image icon → Click on Theme → Enabled → Dark Mode. 
        Click on Theme →  Disabled → Light Mode        
        `,
    },
    {
        id: 16,
        ques: 'How can I logout of Teamplus?',
        ans: `Any logged in user can be logged out at any time. 
        Click on the top right corner of image icon → Click Logout —> Redirected to Login Page`,
    },
];

export const admindata = [
    {
        id: 1,
        ques: 'What is Time-Off?',
        ans:
            'Time-off is a unified feature that manages all the employee leave and time-off related activities in single software. It streamlines the entire process and saves a lot of time for both employer and employee.Employees can apply for leave, check to leave status, check balance leave, leave type, and any relevant information at any time and any location.',
    },
    {
        id: 2,
        ques: 'Why Time-Off?',
        ans: `The tasks involved in managing time-offs can be tedious and tiring for the HRs/managers for these reasons:
        Keeping record of every employee’s leave pattern manually 
        Updating and maintaining paperwork
        Making sure everyone in the organisation follows the guidelines and company policies while taking leave 
        Most of the above tasks require your HR team to stay running behind every employee and even with constant check-ins the probabilities of slipping details are very possible. This is exactly why you need a time-off tracking system for your company. It becomes all the more essential if you track time, attendance and hours worked to pay your employees compensation to avoid flaws, confusion, and as a result employee-employer conflict.
         
        It becomes all the more essential if you track time, attendance and hours worked to pay your employees compensation to avoid flaws, confusion, and as a result employee-employer conflict.
        `,
    },
    {
        id: 3,
        ques: 'What are the benefits?',
        ans: `A leave tracking system can assist you establish your company’s leave policy/policies to its employees and also automate most of the method ensuring glitches don't occur. It can also help in also have following benefits:
        Employee leave management system lets the workers apply for his or her own time-off, manage their leave balance, plan vacations beforehand and know the supply of their peers through an easy system. Making a crucial process like Leave Management simple for your employees increases their satisfaction with the organisation.
         
        The time-off feature makes it possible for your employees, managers and HR personnel to access the system from anywhere. Employees can apply for leave from anywhere, approvers can approve/deny it from anywhere and HR personnel/managers can fetch information they need from anywhere, instantly.
        With Time-off, you can get information about the entire team’s availability.It is transparent, helps employees and managers decide time-off sensibly without creating dependencies. Miscommunication is completely taken off the table and vacations or leaves are planned beforehand without deters.Manual errors are completely made impossible with leave management software in situ.
        `,
    },
    {
        id: 4,
        ques: 'How does the Dashboard help?',
        ans: `With dashboard, admins can now view, record and analyse time-offs taken by their employees and take steps accordingly, if required. Teamplus admins can approve or deny time-off requests sent by their employees. Once the request is approved or denied, it changes the time-off status on the employees’ side. The calendar also gets updated with the same. Admins can view the upcoming time-offs and today’s time-off on their dashboard. Admin/approver can deny already approved time-off from upcoming time-offs, if required.
        This can help teams and well as individual employees to schedule events accordingly and be more productive.
        `,
    },
    {
        id: 5,
        ques: 'As an admin, what settings can I configure for my organisation?',
        ans: `Settings are only visible to the admin of the registered organisation. This will consist of various features the admin has access to with respect to the organisation. Admin will be able to use features related to the organisation settings, holidays that he/she can import or add for the organisation, time-off policies to be used, the subscription plan and lastly the admin can view the slack integration feature as well.
        Following are the different settings that an admin can configure for his/her organisation:
        Department Settings
        Holidays
        Time-off policies 
        Subscription
        Add to Slack
        `,
    },
    {
        id: 6,
        ques: 'What organisation settings can I choose?',
        ans: `The Organisation Settings screen will have the details of the organisation. As an admin, you can view and update the settings for the organisation. Following are the settings:
        Name of the organisation.
        Organisation Logo
        Date Format for the organisation
        Working hours: Number of working hours per day.
        Work week
        Admin can configure what should be displayed on the navigation bar. Admin can select one from the two options; the navigation bar can either display the 'Organisation Logo' or the 'Organisation Name'.
        Admin can configure how they would like to address their employees. There are two options: 'Employee' and 'Team member'. Admin can select any one of the options depending on his/her choice.
        Admin can configure how they would like to address their approvers. Admin can either choose 'Approvers' or can provide some custom name for eg: ‘Managers’.
        Admin can configure how they would like to address their teams? Admin can either call them 'Teams' or can also provide some custom name for eg: 'Groups'.
        
        Admin can keep the default settings or update it based on his/her choice. Updating settings with the right information can help to get a personalised experience using the teamplus application.
        `,
    },
    {
        id: 7,
        ques: 'How can I set the time-off policies for my organisation?',
        ans: `Time-off policies are the categories of time-offs which can be used by the employees of an organisation while applying for a time-off. There are various attributes that are set to a particular time-off category. Admin can manage the time-offs by updating as required for his/her organisation. By default every organisation will have two time-off types “Sick Time-off” and “Casual Time-off”.The Time-off Policies tab will show the time-off name and its details.

        The time-off features for each time-off type are as follows:
        
        Time-off Type: Name of the time-off type which will be used by employees.
        
        Paid/Unpaid: This shows whether the time-off is paid or unpaid. Admin can decide and set this field for each time-off type.
        
        No. of Days per year: This field shows the number of days the employee will be able to apply time-off for each of the time-off types. This will also be set and can be updated by the admin. Additionally, admin can set this field as “Unlimited” for unlimited no of days for the particular time-off type.
        
        Enable/Disable: If this field is enabled; then employees can use this time-off  type while applying the time-off. If disabled; employees will not be able to see this time-off type while applying leave and hence cannot apply time-off. Admin can enable/disable any of the time-off types.
        
        Action : Admin can use this field to edit and delete the existing time-off types.
        
        Edit Icon: Used to edit time-off type. Casual and Sick Time-off type names cannot be edited , admin can edit or update other features for example, admin can update “Days per year”. New time-off policies that are added by the admin can be edited.
        
        Delete Icon: Admin can delete all the time-off policies added by him except the default time-offs (i.e Sick and Casual Time-off type)
        
            For adding a time-off policy: Refer above features while adding a new time-off policy.
        
        Click Add Time-off → Enter the time-off Name → Enter Days per Year → Paid/Unpaid → Click Create
        
        There is one more functionality: “Do you want to keep unlimited days for all time-offs ? “ ; if enabled, all the time-off policies or types added will have unlimited time-offs available.
        `,
    },
    {
        id: 8,
        ques: 'How can I add holidays?',
        ans: `An admin can add holidays for an organisation by importing it from a list of holidays or add their custom holidays for organisation. Once the holidays are added, admin can also 
        edit or delete it. They can also view the list of company-wide holidays and plan their time-offs or vacations accordingly. This can help employees to get an idea about their yearly holidays in advance.
        Import Holiday: A preset list of all the national holidays for the respective year will be displayed and the admin can import the holidays for his/her organisation from that list.
        
            For Import Holiday:
        
        Click Import Holiday → Select Year → Click Find Holidays → Select Holidays →  Click  Import Holidays
        
        
        Add Holiday: Admin can add holidays for the organisation for the particular year as required.
        
            For Add Holiday:
        
        Click Add Holiday → Enter Holiday Name → Enter Holiday Date (Can also add 
        multiple date holiday) →    Click  Create
        
        Edit Holiday( Edit icon) : Edit the existing holidays. 
        
                   Click Edit Icon → Edit Holiday Name → Edit Holiday Date →  Click Update
        
        Delete Holiday (Delete Icon): Admin can delete the holiday by clicking on the delete icon
        `,
    },
    {
        id: 9,
        ques: 'How can I view all the teams?',
        ans: `Admin can view the team list to understand more about the existing teams in the organisation. Team details include team name, members and approvers in the team. Admin can add members, admins and/or approvers from the existing team members. 
        To view team details you can go to a particular team and click on '
        `,
        bullet: '⬤ ⬤ ⬤ ',
        ans2: `'  > View Teams.
        It will display you the list of employees in the respective team
        Admin can also remove a team member from the team by clicking on Delete Employee option`,
    },
    {
        id: 10,
        ques: 'How can I create a new team?',
        ans: `To create new team in the organisation, admin can go through the following steps:
        Teams > Create new team
        When you create a new team you can add the members and the approvers in a team based on the list displayed. If you add an employee as an approver, by default he/she will also be added as an employee.
        `,
    },
    {
        id: 11,
        ques: 'How can I view all the employees in a team?',
        ans: `Admin can see all the registered employees and the teams in which the employees are present in his/her organisation. This page will also show the role of the employee (either employee or approver) in the teams he/she is a part of. Admin can also view employees by using the 'Search' bar or  can also use 'Filter By' based on the role. Other than viewing employees, admin can also perform following actions for each employee after clicking on the actions to be performed against each employee.`,
    },
    {
        id: 12,
        ques: 'Can I change the role of an employee?',
        ans: `Changes in roles are possible if there is a situation where the admin wishes to upgrade or downgrade the roles of the employees. To make/delete an admin, you can go to Employees > Go to particular employee > click Action icon > Make admin/remove Admin
        Admin can also remove any employee other than him/her from the organisation by clicking 'Delete Employee' for the respective employee.
        To make/delete an approver, you can go to Teams> Go to particular team> Go to particular employee > click Action icon > Make admin/remove approver`,
    },
    {
        id: 13,
        ques: 'How can I invite members to my organisation?',
        ans: `Admin can add employees by sending an invite to the employees. Teamplus provides different ways of doing so. Go to Employees page > Click 'Invite Employees'. 

        Email Invites: Admin can enter the email address and also the teams in which he/she wants to invite the user in. Admin can invite as many users as he wishes to invite. After entering email addresses, click on 'Send Invite'.
        
        Bulk Invites: Admin can invite a bulk of employees/users by uploading a csv file. Click 'Upload Document' for uploading the csv file. For reference of a CSV file ; admin can also download the template and add his own list of users. Once uploading is done,  click 'Send Invite' to send the invites.
        Invite Status: This functionality is used to send an invitation to the employees for joining the Teamplus application. This will show the invites which are sent and the status of the invites. It will show the 'Email Address' to which the email invite was sent and whether the invitation was pending or accepted in the 'Status' column. If the invitation is in pending status , admin can resend the invite by clicking the mail box icon for the respective email id.`,
    },
    {
        id: 14,
        ques: "How can an admin view an employee's profile?",
        ans: `To view profiles of other employees within the organisation, admin can click on the profile picture of the employee and redirect to the respective profile page. You can also find the list of your employees by going to the employees page. 
        As an admin you can be able to:
        View the name of the employee
        View the profile photo
        View time-off status: Number of time-offs used and available are displayed on the pie chart as well as the count is displayed depending upon the time-off type.
        View time-off history: All the previous approved or denied time-offs applied till the current date can be seen.
        `,
    },
    {
        id: 15,
        ques: 'How can an admin apply a time-off?',
        ans: `Admins cannot apply for a time-off from their admin account. To do so, they need to switch to Employee profile by following these steps:
        Click on the profile image present on the navbar of the teamplus header bar→ Click on Switch to Employee Profile
        Once you have switched to your employee profile, you can add a time-off from the option available on your dashboard.
        `,
    },
    {
        id: 16,
        ques: 'How can I take action on the time-off request?',
        ans: `As an admin, you will receive the time-off requests that your employees have applied for. On the dashboard, the admin can see the list of all the pending time-off requests. Admin can approve or deny their requests. Admin’s can deny a request with reason, so that the respective employees can view the denial reason. However, it is optional.`,
    },
    {
        id: 17,
        ques: 'Can I deny an already approved time-off request?',
        ans: `Yes, the admin can deny an already accepted time-off request before the date for which the time-off has been applied. The admin can also add the denial reason so that the respective employee can view the reason.`,
    },
    {
        id: 18,
        ques: 'Can an admin mention the reason for denial for the time-off request?',
        ans: `Yes, the admin can also add the denial reason so that the respective employee can view it and get to know the reason.
        `,
    },
    {
        id: 19,
        ques: 'How can an admin switch between organisations that he/she is part of?',
        ans: `Switch organisation is used when you are part of multiple organisations. This feature is used for switching to another organisation. After clicking the “Switch organisation”, a list of organisations of which you are a part of will pop up. And then you can select the organisation to which he/she wants to switch to.`,
    },
    {
        id: 20,
        ques: 'How can I change the password ?',
        ans: `You can change your password by using the “Change Password” option.
        Click on the top right corner of profile icon on navbar → Click Change Password → Click Send for OTP generation → Go to your registered email and get OTP → Redirected to Password Change →Enter OTP → Enter New password and Confirm Password → Click Update
        If you do not receive OTP on your registered email, go to the Password Change page again and click on “Resend OTP “.`,
    },
    {
        id: 21,
        ques: 'How does the calendar feature benefit me?',
        ans: `Calendar is one of the important features of Teamplus as it displays all the time-offs at one place. You can view approved, denied, pending time-offs as well as the organisation holidays. There are different legends on the top and one can click on any of those to view the respective type of time-offs. Admin/approver can click on the employee name to view more details regarding the time-off. They can also approve or deny a time-off request that is pending or can deny a request that has already been approved.`,
    },
    {
        id: 22,
        ques: 'How can I change the theme of my application?',
        ans: `Two themes are available one is Light mode and other is Dark mode.
        Click on the top right corner of image icon → Click on Theme → Enabled → Dark Mode 
        Click on Theme →  Disabled → Light Mode
        `,
    },
    {
        id: 23,
        ques: 'How can I logout of Teamplus?',
        ans: `Any logged in user can be logged out at any time. 
        Click on the top right corner of image icon → Click Logout —> Redirected to Login Page
        `,
    },
];

export const approverdata = [
    {
        id: 1,
        ques: 'What is Time-Off?',
        ans:
            'Time-off is a unified feature that manages all the employee leave and time-off related activities in single software. It streamlines the entire process and saves a lot of time for both employer and employee.Employees can apply for leave, check to leave status, check balance leave, leave type, and any relevant information at any time and any location.',
    },
    {
        id: 2,
        ques: 'Why Time-Off?',
        ans: `The tasks involved in managing time-offs can be tedious and tiring for the HRs/managers for these reasons:
        Keeping record of every employee’s leave pattern manually 
        Updating and maintaining paperwork
        Making sure everyone in the organisation follows the guidelines and company policies while taking leave 
        Most of the above tasks require your HR team to stay running behind every employee and even with constant check-ins the probabilities of slipping details are very possible. This is exactly why you need a time-off tracking system for your company. It becomes all the more essential if you track time, attendance and hours worked to pay your employees compensation to avoid flaws, confusion, and as a result employee-employer conflict.
         
        It becomes all the more essential if you track time, attendance and hours worked to pay your employees compensation to avoid flaws, confusion, and as a result employee-employer conflict.
        `,
    },
    {
        id: 3,
        ques: 'What are the benefits?',
        ans: `A leave tracking system can assist you establish your company’s leave policy/policies to its employees and also automate most of the method ensuring glitches don't occur. It can also help in also have following benefits:
        Teamplus lets the employees apply for his or her own time-off, manage their leave balance, plan vacations beforehand and know the availability of their peers through an easy system. Making such a crucial process simple for your employees increases their satisfaction with the organization.
        The time-off feature makes it possible for your employees, managers and HR personnel to access the system from anywhere. Employees can apply for leave from anywhere, approvers can approve/deny it from anywhere and HR personnel/managers can fetch information they need from anywhere, instantly.
        With Time-off, you can get information about the entire team’s availability.It is transparent, helps employees and managers decide time-off sensibly without creating dependencies. Miscommunication is completely taken off the table and vacations or leaves are planned beforehand without deters.Manual errors are completely made impossible with leave management software in situ.
        `,
    },
    {
        id: 4,
        ques: 'How does the Dashboard help?',
        ans: `With dashboard, you can now view, record and analyze time-offs taken by the employees in your team and take steps accordingly, if required. Teamplus approvers can approve or deny time-off requests sent by their employees in the team. Once the request is approved or denied, it changes the time-off status on the employees’ side. The calendar also gets updated with the same. Admins can view the upcoming time-offs and today’s time-off on their dashboard. Admin/approver can deny already approved time-off from upcoming time-offs, if required.Approvers can also view their personal time-off status on their dashboard. 
        This can help teams and well as individual employees to schedule events accordingly and be more productive.
        `,
    },
    {
        id: 5,
        ques: 'How can I view all the teams?',
        ans: `Approvers can view the team list to understand more about the existing teams in the organisation. Team details include team name, members and approvers in the team.
        `,
    },
    {
        id: 6,
        ques: 'How can I view all the employees in a team?',
        ans: `Approvers can view all the employees that are present in his/her organisation. This page will also show the role of the employee (either employee or approver) in the teams he/she is a part of. You can also view employees by using the 'Search' bar or  can also use 'Filter By' based on the role.
        `,
    },
    {
        id: 7,
        ques: 'How can an approver apply a time-off?',
        ans: `Applying for a time-off is now possible with the Time-off feature. There is a simple form that the approver can fill to apply for a time-off. Approver can apply for a full day or custom time-off based on their requirement. They can select the reasons from the list of reasons available or write a custom reason.
        Once the leave has been applied,the approver can view it on their own dashboard. They can also view their other pending, approved and denied time-offs. The status of the applied time-off changes based on the approver’s action of approval or denial. An approver can cancel his/her own requested time-off in case he/she wishes to withdraw it.
        `,
    },
    {
        id: 8,
        ques: 'How can I take action on the time-off request?',
        ans: `As an approver, you will receive the time-off requests that the employees of your teams have applied for. On the dashboard, the approvers can see the list of all the pending time-off requests from the employees of his teams.Approver can approve or deny their requests. Approvers can deny a request with reason, so that the respective employees can view the denial reason. However, it is optional.
        `,
    },
    {
        id: 9,
        ques: 'Can I deny an already approved time-off request?',
        ans: `Yes, the approver can only deny an already accepted time-off request which was earlier accepted by him before the date for which the time-off has been applied. The admin can also add the denial reason so that the respective employee can view the reason.
        `,
    },
    {
        id: 10,
        ques: 'Can an approver mention the reason for denial for the time-off request?',
        ans: `Yes, the approver can also add the denial reason so that the respective employee can view it and get to know the reason.
        `,
    },
    {
        id: 11,
        ques: 'How can I change the password?',
        ans: `You can change your password by using the “Change Password” option.
        Click on the top right corner of profile icon on navbar → Click Change Password → Click Send for OTP generation → Go to your registered email and get OTP → Redirected to Password Change →Enter OTP → Enter New password and Confirm Password → Click Update
        If you do not receive OTP on your registered email, go to the Password Change page again and click on “Resend OTP “.
        `,
    },
    {
        id: 12,
        ques: 'How does the calendar feature benefit me?',
        ans: `Calendar is one of the important features of Teamplus as it displays all the time-offs at one place. You can view approved, denied, pending time-offs as well as the organization holidays. There are different legends on the top and one can click on any of those to view the respective type of time-offs. Approver can click on the employee name to view more details regarding the time-off. They can also approve or deny a time-off request that is pending or can deny a request that has already been approved by them. Approvers can only approve the employees time-off request of the employees of his teams. Approvers can deny the time-off request they have already approvers from the calendar.
        `,
    },
    {
        id: 13,
        ques: 'Can I cancel my already approved time-off request?',
        ans: `Yes, you can deny an already approved time-off request before the date for which the time-off has been applied.
        `,
    },
    {
        id: 14,
        ques: 'How can an approver switch between organizations that he/she is part of?',
        ans: `Switch Department is used when you are part of multiple organizations. This feature is used for switching to another organization. When clicked on the  profile picture present on the right top corner of the navbar,  a list of options appear, users can click on the “Switch Department”,  and view a list of organizations of which he/she is a part of and can select the organization to which he/she wants to switch to.
        `,
    },
    {
        id: 15,
        ques: 'How can I change the theme of my application?',
        ans: `Two themes are available one is Light mode and other is Dark mode.
        Click on the top right corner of image icon → Click on Theme → Enabled → Dark Mode. 
        Click on Theme →  Disabled → Light Mode
        `,
    },
    {
        id: 16,
        ques: 'How can I logout of Teamplus?',
        ans: `Any logged in user can be logged out at any time. 
        Click on the top right corner of image icon → Click Logout —> Redirected to Login Page`,
    },
];
