import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import { useDispatch } from 'react-redux';
// import { Hidden } from '@material-ui/core';
import useStyles from './style';

export default function RefundCancellation() {
    const dispatch = useDispatch();
    const classes = useStyles();
    useEffect(() => {
        dispatch({ type: 'ACTIVE', payload: '/refundpolicy' });
    }, [dispatch]);

    const RefundBlock = () => {
        return (
            <div className={classes.policyDiv}>
                <h3 className={classes.policyHeading}>Refund and Cancellation Policy</h3>

                <br />

                <div className={classes.refundDescription}>
                    <p>
                        At Udyamo, owned by Udgama Software & Systems Private Limited, we prioritize on providing you
                        with the best services and product experience. We appreciate you for choosing us.
                    </p>

                    <p>
                        However, in case of dissatisfaction from our services, the clients have the liberty to cancel
                        their subscriptions or may ask for a refund, subject to following terms & conditions:
                    </p>

                    <ol type="1">
                        <li className={classes.refundDescription1}>
                            All the subscription plans namely monthly, quarterly, yearly are renewed automatically after
                            the end of billing period unless client opts for cancellation.
                        </li>
                        <li className={classes.refundDescription1}>
                            If a client cancels subscription during an on-going billing period, the cancellation shall
                            be effective immediately and the client will be charged on prorata basis.
                        </li>
                        <li className={classes.refundDescription1}>
                            The clients have the liberty to inform us to upgrade or downgrade their plans at any point
                            of time of their services:
                        </li>

                        <ol type="a">
                            <li className={classes.description1}>
                                The downgrade of subscription plan shall be applicable with immediate effect i.e., the
                                client shall be charged at the original plan & the downgraded plan on prorata basis
                                (depending upon number of days used) for remaining period
                            </li>
                            <li className={classes.description1}>
                                The upgrade of the subscription plan shall be effective immediately and it will be
                                prorated for the remaining period until the next billing cycle.
                            </li>
                        </ol>

                        <li className={classes.refundDescription1}>
                            Refund amount shall be processed within a period of 7 days from approval of refund request.
                        </li>
                        <li className={classes.refundDescription1}>
                            Refunds shall be issued to the original account through which payment of subscription to the
                            services were made.
                        </li>
                        <li className={classes.refundDescription1}>
                            No request for refund shall be entertained for Full or Prorated amount, if we Suspend or
                            Terminate your subscription to our services if suspected that you (by conviction,
                            settlement, insurance or investigation, or otherwise, forcefully removed our branding) or
                            have engaged in any fraudulent activity in connection with services offered by us.
                        </li>
                        <li className={classes.refundDescription1}>
                            Refund amount will be issued to you after deduction of minimal service charged.
                        </li>
                    </ol>

                    <br />
                    <p>In case of any queries, feel free to get in touch with us at support@udyamo.com</p>
                </div>
            </div>
        );
    };

    return (
        <div style={{ overflowY: 'hidden' }}>
            <Header />
            <RefundBlock />
            <Footer />
        </div>
    );
}
