import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: any) => ({
    modal: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '70%',
        backgroundColor: theme.palette.background.paper,
        padding: '50px 50px 20px 50px',
        top: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        maxHeight: '85%',
        '@media only screen and (min-width: 900px)': {
            width: '40%',
        },
        '@media only screen and (max-width:600px)': {
            position: 'realtive',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            width: '90%',
            padding: '40px 3px 30px 3px',
        },
    },
    paper1: {
        width: '60%',
        backgroundColor: theme.palette.background.paper,
        padding: '15px 40px 20px 40px',
        // top: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        maxHeight: '80%',
        '@media only screen and (min-width: 900px)': {
            width: '43%',
        },
        '@media only screen and (max-width:600px)': {
            position: 'realtive',
            display: 'flex',
            justifyContent: 'center',
            padding: '15px 10px 20px 10px',
            alignItems: 'center',
            left: 0,
            width: '96%',
        },
    },
    set: {
        '@media only screen and (min-width:900px)': {
            display: 'flex',
            flexDirection: 'row',
        },
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    text: {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        // paddingTop: 5,
        paddingBottom: 4,
    },
    inputBox: {
        paddingBottom: 20,
        paddingTop: 5,
        '& div': {
            fontSize: '14px',
            '& input': {
                fontFamily: theme.typography.fontFamily,
                fontSize: 'normal',
                fontWeight: theme.typography.fontWeightBold,
                borderRadius: '2px',
                letterSpacing: '0.03',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
    },
    mainDiv: {
        width: '100%',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '20px',
        '@media only screen and (min-width:390px)': {
            justifyContent: 'flex-end',
        },
    },
    btn: {
        position: 'relative',
        height: 35,
        width: 150,
        textTransform: 'capitalize',
        padding: '0 50px',
        fontSize: 14,
        // marginRight: 10,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
        '&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
        },
    },
    btn1: {
        position: 'relative',
        display: 'flex-end',
        textAlign: 'center',
        width: 150,
        height: '35px',
        marginLeft: '10px',
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    new: {
        overflowY: 'auto',
        overflowX: 'hidden',
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 10,
        // border: '1px solid',
        justifyContent: 'space-between',
        height: '50%',
        '@media only screen and (max-width:600px)': {
            width: '100%',
        },
        '&::-webkit-scrollbar': {
            // scrollMarginRight: 59,
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#D9DBE5',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    icon: {
        '&::-webkit-calendar-picker-indicator': {
            filter: 'invert(27%) sepia(22%) saturate(6129%) hue-rotate(216deg) brightness(92%) contrast(80%)',
            '-webkit-color': 'blue',
        },
        width: 110,
        fontSize: '16',
        color: 'blue',
        marginTop: -1,
    },
    closeIcon: {
        position: 'relative',
        color: 'white',
        height: '12px',
        '&:hover': {
            color: 'white',
        },
    },
    inputScroll: {
        display: 'flex',
        flexDirection: 'row',
        height: 40,
        alignItems: 'center',
        border: '1px solid',
        overflowX: 'auto',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
    },
    inputScroll1: {
        overflowX: 'auto',
        border: '1px solid',
        height: 35,
        borderRadius: 5,
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
    },
}));
export default useStyles;
