import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import { useDispatch } from 'react-redux';
// import { Hidden } from '@material-ui/core';
import useStyles from './style';
// import Questions from './questions';

export default function TermsOfService() {
    const dispatch = useDispatch();
    const classes = useStyles();
    useEffect(() => {
        dispatch({ type: 'ACTIVE', payload: '/terms-of-service' });
    }, [dispatch]);

    const TermsBlock = () => {
        return (
            <div className={classes.policyDiv}>
                <div>
                    <h3 className={classes.policyHeading}>Terms of Service</h3>
                </div>
                <div className={classes.policySubtitle}>
                    <h5 className={classes.policySubtitleheading}>Welcome To TeamPlus!</h5>
                    <br />
                    <p>
                        These terms of use outline the rules and regulations for use of Teamplus application, located at
                        teamplus.udyamo.com
                    </p>
                    <p>By accessing this website we assume you accept the terms and conditions of use.</p>
                    <p>
                        These Terms of Use constitute a legally binding agreement made between you, whether personally
                        or on behalf of an entity (“you”) and Teamplus Application (“we,” “us” or “our”), concerning
                        your access to and use of the Teamplus as well as any other media form, media channel, mobile
                        website or mobile application related, linked, or otherwise connected thereto (collectively, the
                        “Application”).{' '}
                    </p>
                    <p>
                        You agree that by accessing the Application, you have read, understood, and agree to be bound by
                        all of these Terms of Use.
                    </p>
                    <p>
                        Udyamo is a g-suite for all the applications under ownership of Udgama Softwares & Systems
                        Private Limited. Teamplus is one such application which is in ownership of Udgama Softwares &
                        Systems Private Ltd and is accessible through Udyamo Website at teamplus.udyamo.com
                    </p>
                    <p>
                        This Teamplus Application and the information, tools and material contained in it (this
                        “Application”) are not directed to, or intended for distribution to or use by, any person or
                        entity who is a citizen or resident of or located in any jurisdiction where such distribution,
                        publication, availability or use would be contrary to law or regulation or its affiliates
                        (collectively “US / WE”) to any registration or licensing requirement within such jurisdiction.
                    </p>
                    <p>
                        This Application is subject to periodic update and revision. Materials should only be considered
                        current as of the date of initial publication appearing thereon, without regard to the date on
                        which you may access the information. We maintain the right to delete or modify information on
                        this Site without prior notice.
                    </p>
                    <p>
                        IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
                        THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    </p>
                </div>
                <div className={classes.termsDiv}>
                    <h4 className={classes.titles}>User Policy</h4>
                    <p>
                        Subject to the terms and conditions set forth herein below, We hereby grant a non-exclusive,
                        non-transferable, limited right to access the Teamplus Application and the materials thereon.
                    </p>
                    <p>Users hereby agree and confirm that:</p>
                    <ul className={classes.ulist}>
                        <li>
                            User is 18 years of age or older. By accessing the Application and its services as a primary
                            user, you confirm that you are 18 years of age or over and any dependent children or adults
                            who access your account are under your care and that you take full responsibility for their
                            actions on this Website.
                        </li>
                        <li>
                            Users shall not interrupt or attempt to interrupt the operation of this Application in any
                            way.
                        </li>
                        <li>Users shall not intrude or attempt to intrude into this Application in any way.</li>
                        <li>Users shall not post any obscene, defamatory or annoying materials on this Application.</li>
                        <li>
                            Users shall not obscure any materials, including this notice, already posted on this
                            Application.
                        </li>
                        <li>
                            Users shall not use this Application or any contents thereof to defame, intimidate, annoy or
                            otherwise cause nuisance or breach the rights of any person.
                        </li>
                        <li>
                            We hereby authorize Users to view and download the information (“Materials”) at this
                            Application only for personal, non-commercial use.
                        </li>
                        <li className={classes.marginbottom}>
                            The Application is intended to be used for personal use only and as such, cannot be used for
                            commercial purposes or by businesses, companies, organisations and other group entities.
                        </li>
                    </ul>

                    <h4 className={classes.titles}>User Representations</h4>
                    <p>By using the Application, you represent and warrant that:</p>
                    <ul className={classes.ulist}>
                        <li>all registration information you submit will be true, accurate, current, and complete;</li>
                        <li>
                            you will maintain the accuracy of such information and promptly update such registration
                            information as necessary;
                        </li>
                        <li>you have the legal capacity and you agree to comply with these Terms of Use;</li>
                        <li>you are not a minor in the jurisdiction in which you reside;</li>
                        <li>
                            you will not access the Site through automated or non-human means, whether through a bot,
                            script or otherwise;
                        </li>
                        <li>you will not use the Site for any illegal or unauthorized purpose; and</li>
                        <li>your use of the Site will not violate any applicable law or regulation.</li>
                    </ul>
                    <p>
                        If you provide any information that is untrue, inaccurate, not current, or incomplete, we have
                        the right to suspend or terminate your account and refuse any and all current or future use of
                        the Site (or any portion thereof).
                    </p>
                    <p>
                        This authorization is not a transfer of title in the Materials and copies of the Materials and
                        is subject to the following:
                    </p>
                    <ul className={classes.ulist}>
                        <li>
                            Users shall not modify the Materials in any way nor reproduce or display, perform, or
                            distribute or otherwise use them for any public or commercial purpose.
                        </li>
                        <li>
                            Users shall not transfer the Materials to any other person unless notice of which is given
                            to us and the same is also accepted. Users also agree to abide by all additional
                            restrictions displayed on this Application, as it may be updated from time to time. This
                            Application, including all Materials, are copyrighted and protected by worldwide copyright
                            laws and treaty provisions. Users agree to comply with all copyright laws worldwide in the
                            use of this Application. Except as expressly provided herein, We do not grant any express or
                            implied right to the User under any patents, trademarks, copyrights or trade secret
                            information.
                        </li>
                        <li>
                            The information, material or services included in or available through this Application may
                            include inaccuracies or typographical errors. Changes are periodically made to the
                            application/services and to the information therein. We and/or Our respective suppliers may
                            make improvements and/or changes in this application/services at any time.
                        </li>
                        <li>
                            Users specifically agree that We shall not be responsible for unauthorized access to or
                            alteration of User transmissions or data, any material or data sent or received or not sent
                            or received, or any transactions entered into through this Application. Users specifically
                            agree that We are not responsible or liable for any threatening, defamatory, obscene,
                            offensive or illegal content or conduct of any other party or any infringement of another’s
                            rights, including intellectual property rights. Users specifically agree that We are not
                            responsible for any content sent using and/or included in this site by any third party.
                        </li>
                        <li>
                            The foregoing are subject to the laws of the Republic of India and the courts in Mumbai,
                            India shall have the exclusive jurisdiction on any dispute that may arise out of the use of
                            this site.
                        </li>
                        <li className={classes.marginbottom}>
                            Please proceed only if you accept all the conditions enumerated herein above, out of your
                            free will and consent.
                        </li>
                    </ul>

                    <h4 className={classes.titles}>Content Liability</h4>
                    <p className={classes.marginbottom}>
                        We shall not be held responsible for any content that appears on your website. You agree to
                        protect and defend us against all claims that may arise on your website. No link(s) should
                        appear on any website that may be interpreted as libelous, obscene or criminal, or which
                        infringes, otherwise violates, or advocates the infringement or other violation of any third
                        party rights.
                    </p>

                    <h4 className={classes.titles}>Data Ownership</h4>
                    <p className={classes.marginbottom}>
                        We respect your right to ownership of content created or stored by you. You own the content
                        created or stored by you. Unless specifically permitted by you, your use of the Services does
                        not grant Teamplus the license to use, reproduce, adapt, modify, publish or distribute the
                        content created by you or stored in your user account for Teamplus’s commercial, marketing or
                        any similar purpose. But you grant Teamplus permission to access, copy, distribute, store,
                        transmit, reformat, publicly display and publicly perform the content of your user account
                        solely as required for the purpose of providing the services to you.
                    </p>

                    <h4 className={classes.titles}>Submissions</h4>
                    <p>
                        You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other
                        information regarding the Application (“Submissions”) provided by you to us are non-confidential
                        and shall become our sole property.
                    </p>
                    <p className={classes.marginbottom}>
                        We shall own exclusive rights, including all intellectual property rights, and shall be entitled
                        to the unrestricted use and dissemination of these Submissions for any lawful purpose,
                        commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all
                        moral rights to any such Submissions, and you hereby warrant that any such Submissions are
                        original with you or that you have the right to submit such Submissions. You agree there shall
                        be no recourse against us for any alleged or actual infringement or misappropriation of any
                        proprietary right in your Submissions.
                    </p>

                    <h4 className={classes.titles}>Copyright</h4>
                    <p>
                        All text, images, and other materials, thus communicable via both digital, print or any other
                        media, on this Website are subject to the copyrights and other intellectual property rights of
                        TEAMPLUS under the g-suite UDYAMO owned by UDGAMA SOFTWARE & SYSTEMS PRIVATE LIMITED. Ownership
                        of the copyrights in the selection, coordination and arrangement of the materials on this
                        Application rests with us. These materials thus shall not be copied for commercial use or
                        distribution, nor shall these materials be modified or reposted to other sites.
                    </p>
                    <p>
                        Unless otherwise stated, Teamplus and/or its licensors own the intellectual property rights for
                        all material on Teamplus. All IPR are reserved. You may access this from Teamplus for your own
                        personal use subject to restrictions set in these terms and conditions of use.
                    </p>
                    <p>You must not, in any case:</p>
                    <ul className={classes.ulist}>
                        <li>Republish any material from Teamplus</li>
                        <li>Sell, rent or sub-license material from Teamplus</li>
                        <li>Reproduce, duplicate or copy material from Teamplus</li>
                        <li className={classes.marginbottom}>Redistribute content obtained from Teamplus</li>
                    </ul>

                    <h4 className={classes.titles}>Trademark</h4>
                    <p className={classes.marginbottom}>
                        All trademarks displayed on this Application are subject to the trademark rights of TEAMPLUS
                        under UDYAMO owned by UDGAMA SOFTWARE & SYSTEMS PRIVATE LIMITED. The unauthorized use of any
                        trademark displayed on this Application is strictly prohibited.
                    </p>

                    <h4 className={classes.titles}>Slack Login</h4>
                    <p>
                        As part of the functionality of the Site, you may link your account with online accounts you
                        have with third-party service providers (each such account, a “Third-Party Account”) by either:
                    </p>
                    <ul className={classes.ulist}>
                        <li>providing your Third-Party Account login information through the Application; or</li>
                        <li>
                            allowing us to access your Third-Party Account, as is permitted under the applicable terms
                            and conditions that govern your use of each Third-Party Account.
                        </li>
                    </ul>
                    <p>
                        You represent and warrant that you are entitled to disclose your Third-Party Account login
                        information to us and/or grant us access to your Third-Party Account, without breach by you of
                        any of the terms and conditions that govern your use of the applicable Third-Party Account, and
                        without obligating us to pay any fees or making us subject to any usage limitations imposed by
                        the third-party service provider of the Third-Party Account.
                    </p>
                    <p>By granting us access to any Third-Party Accounts, you understand that:</p>
                    <ul className={classes.ulist}>
                        <li>
                            We may access, make available, and store (if applicable) any content that you have provided
                            to and stored in your Third-Party Account (the “Social Network Content”) so that it is
                            available on and through the Application via your account, including without limitation any
                            friend lists and;
                        </li>
                        <li>
                            We may submit to and receive from your Third-Party Account additional information to the
                            extent you are notified when you link your account with the Third-Party Account.
                        </li>
                    </ul>
                    <p>
                        Depending on the Third-Party Accounts you choose and subject to the privacy settings that you
                        have set in such Third-Party Accounts, personally identifiable information that you post to your
                        Third-Party Accounts may be available on and through your account on the Site. Please note that
                        if a Third-Party Account or associated service becomes unavailable or our access to such
                        Third-Party Account is terminated by the third-party service provider, the Social Network
                        Content may no longer be available on and through the Site. You will have the ability to disable
                        the connection between your account on the Site and your Third-Party Accounts at any time.
                    </p>
                    <p>
                        PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR
                        THIRD-PARTY ACCOUNT IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
                        PROVIDERS.
                    </p>
                    <p className={classes.marginbottom}>
                        We make no effort to review any Social Network Content for any purpose, including but not
                        limited to, for accuracy, legality, or non-infringement, and we are not responsible for any
                        Social Network Content. You acknowledge and agree that we may access your email address book
                        associated with a Third-Party Account and your contacts list stored on your mobile device or
                        tablet computer solely for purposes of identifying and informing you of those contacts who have
                        also registered to use the Application. You can deactivate the connection between the
                        Application and your Third-Party Account by contacting us using the contact information below or
                        through your account settings (if applicable). We will attempt to delete any information stored
                        on our servers that was obtained through such a Third-Party Account, except the username and
                        profile picture that become associated with your account.
                    </p>

                    <h4 className={classes.titles}>Indemnification</h4>
                    <p className={classes.marginbottom}>
                        You agree to indemnify and hold harmless Teamplus, its officers, directors, employees,
                        suppliers, and affiliates, from and against any losses, damages, fines and expenses (including
                        attorney&apos;s fees and costs) arising out of or relating to any claims that you have used the
                        Services in violation of another party&apos;s rights, in violation of any law, in violations of
                        any provisions of the Terms, or any other claim related to your use of the Services, except
                        where such use is authorized by Teamplus.
                    </p>

                    <h4 className={classes.titles}>Suspension and Termination</h4>
                    <p className={classes.marginbottom}>
                        In addition, we reserve the right to suspend or terminate your user account and deny the
                        services upon reasonable belief that you have violated the Terms and to terminate/suspend your
                        access to any services in case of unexpected technical issues or discontinuation of the service.
                        You have the right to terminate your user account if Teamplus breaches its obligations under
                        these Terms and in such an event, you will be entitled to a prorated refund of any prepaid fees.
                        Termination of user account will include denial of access to all services, deletion of
                        information in your user account such as your email address and password and deletion of all
                        data in your user account.
                    </p>

                    <h4 className={classes.titles}>Limitation of Liability</h4>
                    <p className={classes.marginbottom}>
                        YOU AGREE THAT TEAMPLUS SHALL, IN NO EVENT, BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL,
                        INDIRECT, SPECIAL, PUNITIVE, OR OTHER LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS,
                        BUSINESS INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION, OR OTHER LOSS ARISING OUT
                        OF OR CAUSED BY YOUR USE OF OR INABILITY TO USE THE SERVICE, EVEN IF TEAMPLUS HAS BEEN ADVISED
                        OF THE POSSIBILITY OF SUCH DAMAGE. IN NO EVENT SHALL TEAMPLUS’S ENTIRE LIABILITY TO YOU IN
                        RESPECT OF ANY SERVICE, WHETHER DIRECT OR INDIRECT, EXCEED THE FEES PAID BY YOU TOWARDS SUCH
                        SERVICE.
                    </p>

                    <h4 className={classes.titles}>Miscellaneous</h4>
                    <p>
                        These Terms of Use and any policies or operating rules posted by us on the Application or in
                        respect to the Application constitute the entire agreement and understanding between you and us.
                        Our failure to exercise or enforce any right or provision of these Terms of Use shall not
                        operate as a waiver of such right or provision.
                    </p>
                    <p className={classes.marginbottom}>
                        These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of
                        our rights and obligations to others at any time. We shall not be responsible or liable for any
                        loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any
                        provision or part of a provision of these Terms of Use is determined to be unlawful, void, or
                        unenforceable, that provision or part of the provision is deemed severable from these Terms of
                        Use and does not affect the validity and enforceability of any remaining provisions. There is no
                        joint venture, partnership, employment or agency relationship created between you and us as a
                        result of these Terms of Use or use of the Application. You agree that these Terms of Use will
                        not be construed against us by virtue of having drafted them. You hereby waive any and all
                        defenses you may have based on the electronic form of these Terms of Use and the lack of signing
                        by the parties hereto to execute these Terms of Use.
                    </p>

                    <h4 className={classes.titles}>Grievance Redressal</h4>
                    <p>
                        In case of any grievance, the customer can contact us through our email{' '}
                        <b style={{ color: '#3C48B5', cursor: 'pointer' }}>support@udyamo.com</b>
                    </p>
                    <p style={{ marginBottom: '2.5rem' }}>
                        These Terms of Use shall be read in conjunction and run coterminous with the Privacy Policy.
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div style={{ overflowY: 'hidden' }}>
            <Header />
            <TermsBlock />
            <Footer />
        </div>
    );
}
