import { createStyles, makeStyles, Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            width: '100%',
            height: 50,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.background.default}`,
            zIndex: 99,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        headerLeft: {
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
            // textAlign: 'center',
            paddingLeft: '10px',
        },
        headerRight: {
            // position: 'relative',
            // display: 'flex',
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            paddingRight: '10px',
        },
        logobtn: {
            background: '#3C48B5',
            margin: 'auto 0px',
            padding: 'auto auto',
            height: '30px',
            maxWidth: '100%',
            borderRadius: '0px',
            fontStyle: 'normal',
            fontweight: 'bold',
            fontSize: '14px',
            color: '#FFFFFF',
        },
        divider: {
            width: '1.5px',
            margin: 'auto 15px',
            height: '30px',
            background: `${theme.palette.primary.contrastText}`,
        },
        divider1: {
            width: '1.5px',
            margin: 'auto 8px',
            height: '30px',
            background: `${theme.palette.primary.contrastText}`,
        },
        companyname: {
            fontFamily: theme.typography.fontFamily,
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: ' 16px',
            margin: 'auto 0px',
            color: theme.palette.success.contrastText,
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '@media (max-width: 700px )': {
                maxWidth: 300,
            },
        },
        pendingLogo: {
            color: theme.palette.success.contrastText,
            cursor: 'pointer',
        },
        addmore: {
            margin: 'auto 0px',
            height: '30px',
            maxWidth: '100%',
            fontFamily: theme.typography.fontFamily,
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '14px',
            textTransform: 'capitalize',
            padding: 'auto 2px',
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        addMoreMenu: {
            display: 'flex',
            alignItems: 'center',
            fontWeight: theme.typography.fontWeightMedium,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.contrastText,
        },
        addMoreImage: {
            width: 20,
            height: 20,
            marginRight: '10px',
            color: theme.palette.primary.contrastText,
        },
        teamslack: {
            height: '34px',
            maxWidth: '100%',
            margin: 'auto 0px',
        },
        teamslack2: {
            height: '38px',
            maxWidth: '100%',
            margin: 'auto 0px',
        },
        orgLogo: {
            maxHeight: '34px',
            maxWidth: '100%',
            margin: 'auto 0px',
        },
        poweredby: {
            height: 25,
            width: '100px',
            position: 'relative',
            // justifyContent: 'flex-end',
            // alignItems: 'bottom',
        },
        udyamo: {
            position: 'absolute',
            fontFamily: theme.typography.fontFamily,
            color: '#232323',
            bottom: -5,
            left: 10,
        },
        addicon: {
            alignItems: 'center',
            width: 'auto',
            color: theme.palette.primary.main,
            // height: '100%',
        },
        title: {
            fontSize: 26,
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontFamily: 'Montserrat',
        },
        vi: {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: 'grey',
        },
        addbtn: {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            height: '40px',
            width: '40px',
            padding: '5px',
            boxShadow: '0px 4px 3px 0px rgba(0, 0, 0, 0.2)',
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                outline: 'none',
                cursor: 'pointer',
                '& span svg': {
                    transform: 'scale(1.2) rotate(40deg)',
                },
                // vi: {
                //     backgroundColor: 'red',
                // },
            },
            '&:focus': {
                outline: 'none',
            },
            '&:active': {
                outline: 'none',
            },
            justifyContent: 'flex-center',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            letterSpacing: 0,
        },
        menu: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
        menuPaper2: {
            overflow: 'visible',
            minWidth: '180px',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: 15,
            '& .MuiAvatar-root': {
                width: 'auto',
                height: 32,
                ml: -0.5,
                mr: 1,
                zIndex: 9999,
            },
            '&:before': {
                content: '""',
                background: theme.palette.background.paper,
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
        menuPaperAddmore: {
            overflow: 'visible',
            minWidth: '180px',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: 15,
            '& .MuiAvatar-root': {
                width: 'auto',
                height: 32,
                ml: -0.5,
                mr: 1,
                zIndex: 9999,
            },
            '&:before': {
                content: '""',
                background: theme.palette.background.paper,
                display: 'block',
                position: 'absolute',
                top: 0,
                right: '45%',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
        menuPaperPAction: {
            overflow: 'visible',
            minWidth: '250px',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: 15,
            '& .MuiAvatar-root': {
                width: 'auto',
                height: 32,
                ml: -0.5,
                mr: 1,
                zIndex: 9999,
            },
            '&:before': {
                content: '""',
                background: theme.palette.background.paper,
                display: 'block',
                position: 'absolute',
                top: 0,
                right: '45%',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
        menuPaperPActionMobile: {
            overflow: 'visible',
            minWidth: '230px',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: 15,
            '& .MuiAvatar-root': {
                width: 'auto',
                height: 32,
                ml: -0.5,
                mr: 1,
                zIndex: 9999,
            },
            '&:before': {
                content: '""',
                background: theme.palette.background.paper,
                display: 'block',
                position: 'absolute',
                top: 0,
                right: '5%',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
        menuPaper3: {
            overflow: 'visible',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: 2,
            '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                zIndex: 999999,
            },
            '&:before': {
                content: '""',
                background: '#fff',
                display: 'block',
                position: 'absolute',
                top: 25,
                left: 0,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
        menuItem: {
            width: 40,
            height: 40,
            backgroundColor: 'red',
            borderRadius: '50%',
            margin: 5,
        },
        openDiv: {},
        closeDiv: {
            display: 'none',
        },
        // btn: {
        //     height: 35,
        //     textTransform: 'capitalize',
        //     padding: '0 30px',
        //     fontFamily: theme.typography.fontFamily,
        //     fontWeight: theme.typography.fontWeightBold,
        //     '&:hover': {
        //         backgroundColor: theme.palette.primary.main,
        //     },
        //     '&:focus': {
        //         textDecoration: 'none',
        //         border: 'none',
        //         outline: 'none',
        //     },
        // },
        error: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            color: 'red',
            textAlign: 'center',
        },
        text: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
        },
        label: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
        },
        textfield: {
            width: '100%',
            fontFamily: theme.typography.fontFamily,
            '& div': {
                '& input': {
                    fontStyle: 'normal',
                    fontWeight: 600,
                    color: theme.palette.text.primary,
                },
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        disabledTextfield: {
            width: '100%',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            '& input.Mui-disabled': {
                color: theme.palette.text.primary,
                fontWeight: 'bold',
                cursor: 'not-allowed',
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.text.disabled}`,
            },
        },
        teamLogo: {
            maxWidth: '100%',
            height: 45,
        },
        btn: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        btnOutlined: {
            height: 35,
            textTransform: 'capitalize',
            marginLeft: 10,
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
        },
        userDetail: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: 'auto',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        avatar: {
            width: 30,
            height: 30,
            marginRight: '10px',
        },
        email: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            fontWeight: theme.typography.fontWeightRegular,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
        },
        vr: {
            height: 30,
            width: 1,
            backgroundColor: '#AAAAAA',
            marginLeft: 20,
            marginRight: 50,
        },
        icon: {
            color: theme.palette.text.primary,
            cursor: 'pointer',
        },
        link: {
            textDecoration: 'none',
            // color: '#000',
            // color: theme.palette.primary.contrastText,
            // '&:hover': {
            //     // color: '#000',
            //     outline: 'none',
            //     textDecoration: 'none',
            // },
            '&:hover': {
                // color: '#fff',
                // backgroundColor: '#3C48B5',
                outline: 'none',
                textDecoration: 'none',
            },
        },
        menuitemtext: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            // '&:hover': {
            //     color: '#fff',
            //     backgroundColor: '#3C48B5',
            // },
        },
        noFoundText: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
        },
        modalText: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
        },
        emailText: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            fontFamily: theme.typography.fontFamily,
            fontSize: 14,
            fontStyle: 'italic',
            cursor: 'default',
            // '&:hover': {
            //     color: '#fff',
            //     backgroundColor: '#3C48B5',
            // },
        },
        createBtn: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            outline: 'none',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'capitalize',
            height: 22,
            width: 70,
            fontSize: '12px',
            // border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        deleteButton: {
            height: 23,
            width: 70,
            outline: 'none',
            position: 'relative',
            float: 'left',
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.contrastText,
            background: theme.palette.background.paper,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            marginRight: 5,
            fontSize: '12px',
            '&:hover': {
                outline: 'none',
                color: theme.palette.primary.contrastText,
                background: theme.palette.background.paper,
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '&:focus': {
                outline: 'none',
                color: theme.palette.primary.contrastText,
                background: theme.palette.background.paper,
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        // Change Role modal style
        roleModal: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(5px)',
        },
        roleModalContent: {
            position: 'absolute',
            top: '30%',
            left: '40%',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2, 4, 3),
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        modalTitle: {
            textAlign: 'center',
            fontStyle: 'normal',
            fontSize: 22,
            color: theme.palette.info.light,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            zIndex: 0,
            marginBottom: 10,
        },
        modalContent: {
            width: '90%',
            fontSize: 16,
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
        },
        modalButton: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: 'Nunito Sans',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        menuPaperNAction: {
            overflow: 'visible',
            // minWidth: '250px',
            marginTop: 15,
            '&:before': {
                content: '""',
                background: theme.palette.background.paper,
                display: 'block',
                position: 'absolute',
                top: 0,
                right: '23%',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
            '@media (max-width: 820px )': {
                minWidth: '230px',
                '&:before': {
                    content: '""',
                    background: theme.palette.background.paper,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    right: '12%',
                },
            },
        },
        notifBox: {
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
        notifPStyle: {
            fontFamily: theme.typography.fontFamily,
            display: 'flex',
            justifyContent: 'center',
        },
        notfoundDiv: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: 40,
        },
        notfoundImage: {
            maxWidth: '55%',
            height: '150px',
        },
        notFoundText: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 14,
            fontWeight: theme.typography.fontWeightMedium,
            color: '#AAAAAA',
            padding: '10px 0',
            display: 'flex',
            alignItems: 'top',
        },
        notifIcon: {
            '@media (max-width: 820px )': {
                paddingLeft: 9,
            },
        },
        // example: {
        //     background: 'red',
        //     transform: 'translateZ(0px)',
        //     flexGrow: 1,
        // },
        // exampleWrapper: {
        //     background: 'pink',
        //     position: 'relative',
        //     // marginTop: theme.spacing(3),
        //     height: 380,
        // },
        // speedDial: {
        //     position: 'absolute',
        //     right: 20,
        //     bottom: 20,
        //     zIndex: 99999,
        //     background: 'green',
        //     '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        //         bottom: theme.spacing(2),
        //         right: theme.spacing(2),
        //     },
        //     '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        //         top: theme.spacing(2),
        //         left: theme.spacing(2),
        //     },
        // },
        // topHeader: {
        //     width: '100%',
        //     height: '40px',
        //     background: '#fff',
        //     zIndex: 999,
        //     display: 'flex',
        //     flexDirection: 'row',
        //     justifyContent: 'space-between',
        //     padding: '0px 30px',
        //     backgroundColor: '#F2F4F4',
        // },
        // main: {
        //     width: '100%',
        //     height: 'auto',
        //     overflow: 'hidden',
        //     zIndex: 9999,
        //     background: '#fff',
        //     display: 'flex',
        //     flexDirection: 'column',
        //     borderBottom: '1px solid #d7dbdd',
        // },
        // mainFirst: {
        //     width: '100%',
        //     height: '50px',
        // },
        // divider: {
        //     width: '80%',
        //     marginBottom: 100,
        // },
        // link: {
        //     '&:hover': {
        //         textDecoration: 'none',
        //     },
        //     '@media (max-width: 812px )': {
        //         right: 0,
        //     },
        // },
        // rightGrid: {
        //     flex: 'end',
        // },
        // mainDiv: {
        //     width: 'calc(100% - 200px)',
        //     height: 'auto',
        //     zIndex: 99,
        //     position: 'fixed',
        //     top: 0,
        //     display: 'flex',
        //     flexDirection: 'column',
        //     '@media screen and (max-width: 650px )': {
        //         width: '100%',
        //     },
        // },
        // mainDiv1: {
        //     width: 'calc(100% - 50px)',
        //     height: 'auto',
        //     zIndex: 99,
        //     position: 'fixed',
        //     top: 0,
        //     display: 'flex',
        //     flexDirection: 'column',
        //     '@media screen and (max-width: 650px )': {
        //         width: '100%',
        //     },
        // },
        // box: {
        //     backgroundColor: '#fff',
        //     padding: '5px 15px',
        //     height: '60px',
        //     '@media (max-width: 650px )': {
        //         padding: '0 5px',
        //     },
        // },
        // innerBox: {
        //     margin: 'auto',
        //     padding: '2px 15px',
        //     '@media (max-width: 650px )': {
        //         padding: '0 10px',
        //         whiteSpace: 'nowrap',
        //         width: '35%',
        //         overflow: 'hidden',
        //         textOverflow: 'ellipsis',
        //     },
        // },
        // heading: {
        //     color: '#7F8C8D',
        //     fontSize: '25px',
        //     fontWeight: theme.typography.fontWeightLight,
        // },
        // subheading: {
        //     color: '#7F8C8D',
        //     fontSize: '20px',
        // },
        // username: {
        //     color: '#7F8C8D',
        //     fontSize: '15px',
        //     fontWeight: theme.typography.fontWeightLight,
        //     marginRight: 10,
        // },
        // small: {
        //     width: 26,
        //     height: 26,
        // },
    }),
);
export default useStyles;
