import React, { useState } from 'react';
import { useStyles } from './style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Grid,
    Avatar,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    useTheme,
    Popover,
} from '@material-ui/core';
import { SearchBar } from '../DashboardPage/DashboardPart';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
import { deleteOtherCelebration } from 'helpers/celebrationApi';
import { getFullName } from 'helpers/getInitials';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MyModal from 'components/Modal/Modal';
import { UpdateOtherCelebration } from './otherCelebration';
import { ToStringFormat } from 'helpers/date';
import { isoToLocal } from 'helpers/timezone';

interface DisplayCenterProps {
    children?: any;
}

export const DisplayCenter = (props: DisplayCenterProps) => {
    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {props.children}
        </div>
    );
};

interface AccordianProps {
    birthday?: any;
    work?: any;
    otherData?: any;
    myContext?: any;
}

export const UpcomingCelebration = (props: AccordianProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const [expanded, setExpanded] = useState<number>(0);
    const [search, setSearch] = useState<any>('');
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [current, setCurrent] = useState<any>({});
    const [current1, setCurrent1] = useState<any>({});
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [celebrationId, setCelebrationId] = useState<string>('');
    const [logo, setLogo] = useState<string>('');
    const [modal, setModal] = useState<boolean>(false);

    const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : panel === 2 ? (panel = 0) : panel + 1);
        setSearch('');
    };

    const handleDelete = async (id: any) => {
        try {
            const data: any = await deleteOtherCelebration(id, props.myContext.token);
            // console.log(data, 'hy');
            if (data.error) {
                throw new Error('unable to delete other celebration');
            }
            props.myContext.togglePopup({
                alertMessage: 'OtherCelebration deleted successfully!',
                severity: 'success',
            });
        } catch (err) {
            props.myContext.togglePopup({
                alertMessage: err?.message,
                severity: 'error',
            });
            return err;
        }
    };

    const editOtherCelebration = async (obj: any) => {
        setTitle(obj?.title);
        setDescription(obj?.description);
        setDate(obj?.celebrationDate?.substring(0, 10));
        setLogo(obj?.attachmentFile);
        setCelebrationId(obj?.celebrationId);
        setModal(true);
    };

    const handlePicker = (e: any, obj: any) => {
        setOpen(!open);
        setAnchorEl(e.currentTarget);
        setCurrent(obj?.celebrationId);
        setCurrent1(obj);
    };

    const handleSubmit = (value: string) => {
        if (value === 'Edit') {
            editOtherCelebration(current1);
        } else {
            handleDelete(current);
        }
    };

    return (
        <>
            <MyModal open={modal} setOpen={setModal} title="Other Celebration">
                <UpdateOtherCelebration
                    title={title}
                    setDate={setDate}
                    setLogo={setLogo}
                    setDescription={setDescription}
                    setTitle={setTitle}
                    description={description}
                    date={date}
                    logo={logo}
                    celebrationId={celebrationId}
                    setModal={setModal}
                />
            </MyModal>
            <Popover
                id="picker-popover"
                open={open}
                anchorEl={anchorEl}
                data-testid="popover"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setOpen(false);
                    setAnchorEl(null);
                }}
            >
                <ul className={classes.filterList2}>
                    {['Edit', 'Delete'].map((e: string) => (
                        <li
                            key={e}
                            onClick={() => {
                                setOpen(false);
                                setAnchorEl(null);
                                handleSubmit(e);
                            }}
                            className={classes.filterName}
                        >
                            {e}
                        </li>
                    ))}
                </ul>
            </Popover>
            <div
                style={{
                    gridTemplateRows:
                        expanded === 0 ? '1fr auto auto' : expanded === 1 ? 'auto 1fr auto' : 'auto auto 1fr',
                }}
                className={classes.leftSide}
            >
                <Accordion
                    data-testid="panel1"
                    expanded={expanded === 0}
                    onChange={handleChange(0)}
                    style={{ borderRadius: 5, margin: 0 }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#333333' }} />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className={classes.title}>Upcoming Birthdays</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 5,
                        }}
                    >
                        <SearchBar
                            value={search}
                            setValue={setSearch}
                            mode="rounded"
                            icon={true}
                            style={{ width: '100%' }}
                        />
                        <div className={classes.accordiondiv}>
                            <div style={{ width: '100%', flex: 1, overflow: 'hidden', padding: '20px 0px 0px 0px' }}>
                                {props.birthday?.length > 0 ? (
                                    props.birthday?.filter((b: any) =>
                                        (b?.Employee?.firstName + ' ' + b?.Employee?.lastName)
                                            ?.toLowerCase()
                                            .includes(search.toLowerCase()),
                                    ).length === 0 ? (
                                        <DisplayCenter>
                                            <img
                                                src={upcomingTimeoff}
                                                alt="No pending time-off found"
                                                className={classes.notfoundImage}
                                            />
                                            <Typography>No celebration found</Typography>
                                        </DisplayCenter>
                                    ) : (
                                        <>
                                            {props.birthday
                                                ?.filter((b: any) =>
                                                    (b?.Employee?.firstName + ' ' + b?.Employee?.lastName)
                                                        ?.toLowerCase()
                                                        .includes(search.toLowerCase()),
                                                )
                                                .map((b: any, i: number) => (
                                                    <Grid
                                                        container
                                                        key={i}
                                                        spacing={5}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <Avatar
                                                                style={{ cursor: 'pointer' }}
                                                                src={b?.Employee?.profileImage}
                                                            >
                                                                {getFullName(
                                                                    b?.Employee?.firstName +
                                                                        ' ' +
                                                                        b?.Employee?.lastName,
                                                                )}
                                                            </Avatar>
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                flex: 1,
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            <span className={classes.name}>
                                                                {b?.Employee?.firstName} {b?.Employee?.lastName}
                                                            </span>
                                                            <span className={classes.date}>
                                                                {ToStringFormat(
                                                                    isoToLocal(
                                                                        b?.celebrationDate,
                                                                        props.myContext?.country,
                                                                    )
                                                                        .toString()
                                                                        .substring(3, 15),
                                                                    props.myContext?.dateFormat,
                                                                )}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </>
                                    )
                                ) : (
                                    <DisplayCenter>
                                        <img
                                            src={upcomingTimeoff}
                                            alt="No pending time-off found"
                                            className={classes.notfoundImage}
                                        />
                                        <Typography>No celebration found</Typography>
                                    </DisplayCenter>
                                )}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    data-testid="panel2"
                    expanded={expanded === 1}
                    onChange={handleChange(1)}
                    style={{ borderRadius: 5, margin: 0 }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#333333' }} />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography className={classes.title}>Upcoming Work Anniversaries</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 5,
                        }}
                    >
                        <SearchBar
                            value={search}
                            setValue={setSearch}
                            mode="rounded"
                            icon={true}
                            style={{ width: '100%' }}
                        />
                        <div className={classes.accordiondiv}>
                            <div style={{ width: '100%', flex: 1, overflow: 'hidden', padding: '20px 0px 0px 0px' }}>
                                {props.work?.length > 0 ? (
                                    props.work?.filter((b: any) =>
                                        (b?.Employee?.firstName + ' ' + b?.Employee?.lastName)
                                            ?.toLowerCase()
                                            .includes(search.toLowerCase()),
                                    ).length === 0 ? (
                                        <DisplayCenter>
                                            <img
                                                src={upcomingTimeoff}
                                                alt="No pending time-off found"
                                                className={classes.notfoundImage}
                                            />
                                            <Typography>No celebration found</Typography>
                                        </DisplayCenter>
                                    ) : (
                                        <>
                                            {props.work
                                                ?.filter((w: any) =>
                                                    (w?.Employee?.firstName + ' ' + w?.Employee?.lastName)
                                                        ?.toLowerCase()
                                                        .includes(search.toLowerCase()),
                                                )
                                                .map((w: any, x: number) => (
                                                    <Grid
                                                        container
                                                        key={x}
                                                        spacing={5}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <Avatar
                                                                style={{ cursor: 'pointer' }}
                                                                src={w?.Employee?.profileImage}
                                                            >
                                                                {' '}
                                                                {getFullName(
                                                                    w?.Employee?.firstName +
                                                                        ' ' +
                                                                        w?.Employee?.lastName,
                                                                )}
                                                            </Avatar>
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                flex: 1,
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            <span className={classes.name}>
                                                                {w?.Employee?.firstName} {w?.Employee?.lastName}
                                                            </span>
                                                            <span className={classes.date}>
                                                                {ToStringFormat(
                                                                    isoToLocal(
                                                                        w?.celebrationDate,
                                                                        props.myContext?.country,
                                                                    )
                                                                        .toString()
                                                                        .substring(3, 15),
                                                                    props.myContext?.dateFormat,
                                                                )}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </>
                                    )
                                ) : (
                                    <DisplayCenter>
                                        <img
                                            src={upcomingTimeoff}
                                            alt="No pending time-off found"
                                            className={classes.notfoundImage}
                                        />
                                        <Typography>No celebration found</Typography>
                                    </DisplayCenter>
                                )}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    data-testid="panel3"
                    expanded={expanded === 2}
                    onChange={handleChange(2)}
                    style={{ borderRadius: 5, margin: 0 }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#333333' }} />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography className={classes.title}>Other Celebrations</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        data-testid="other"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 5,
                        }}
                    >
                        <SearchBar
                            value={search}
                            setValue={setSearch}
                            mode="rounded"
                            icon={true}
                            style={{ width: '100%' }}
                        />
                        <div className={classes.accordiondiv}>
                            <div style={{ width: '100%', flex: 1, overflow: 'hidden', padding: '20px 0px 0px 0px' }}>
                                {props.otherData?.length > 0 ? (
                                    props.otherData?.filter((o: any) =>
                                        o?.title?.toLowerCase().includes(search.toLowerCase()),
                                    ).length === 0 ? (
                                        <>
                                            <DisplayCenter>
                                                <img
                                                    src={upcomingTimeoff}
                                                    alt="No pending time-off found"
                                                    className={classes.notfoundImage}
                                                />
                                                <Typography>No celebration found</Typography>
                                            </DisplayCenter>
                                        </>
                                    ) : (
                                        <>
                                            {props.otherData
                                                ?.filter((o: any) =>
                                                    o?.title?.toLowerCase().includes(search.toLowerCase()),
                                                )
                                                .map((o: any, d: number) => (
                                                    <Grid key={d} container spacing={1} style={{ paddingTop: 8 }}>
                                                        <Grid item xs={11}>
                                                            <Grid
                                                                container
                                                                spacing={1}
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Grid item>
                                                                    <Avatar
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            marginRight: 5,
                                                                        }}
                                                                    >
                                                                        {o?.ttile?.substring(0, 1)}
                                                                    </Avatar>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        flex: 1,
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                    }}
                                                                >
                                                                    <Typography className={classes.name}>
                                                                        {o?.title}
                                                                    </Typography>
                                                                    <Typography className={classes.date}>
                                                                        {ToStringFormat(
                                                                            isoToLocal(
                                                                                o?.celebrationDate,
                                                                                props.myContext?.country,
                                                                            )
                                                                                .toString()
                                                                                .substring(3, 15),
                                                                            props.myContext?.dateFormat,
                                                                        )}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            {o?.employeeId === props.myContext?.EmployeeId && (
                                                                <MoreVertIcon
                                                                    style={{
                                                                        width: 22,
                                                                        height: 22,
                                                                        marginLeft: 2,
                                                                        cursor: 'pointer',
                                                                        color: theme.palette.text.primary,
                                                                    }}
                                                                    data-testid="more-vert-icon"
                                                                    onClick={e => handlePicker(e, o)}
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </>
                                    )
                                ) : (
                                    <>
                                        <DisplayCenter>
                                            <img
                                                src={upcomingTimeoff}
                                                alt="No pending time-off found"
                                                className={classes.notfoundImage}
                                            />
                                            <Typography>No celebration found</Typography>
                                        </DisplayCenter>
                                    </>
                                )}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
};
