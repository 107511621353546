/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { TeamplusLoader } from '../components/Loader/loader';

// import { LoaderPage } from '../components/Loader/loader';

export default function FetchingData() {
    const [isData, setIsData] = React.useState(true);
    const state = useSelector((state: any) => state);
    const history = useHistory();
    useEffect(() => {
        if (
            state.orgEmployees.status === 'loading' ||
            // calendarLeaves.loading ||
            // approverCalendarLeaves.loading ||
            state.employeesList.employeesList.initialStatus === 'loading' ||
            // state.employeesList.employeesList.filteredStatus === 'loading' ||
            state.employeesList.employeesList.initialInviteStatus === 'loading' ||
            // state.employeesList.employeesList.filteredInviteStatus === 'loading' ||
            // state.employeesList.employeesList.teamStatus === 'loading' ||
            state.orgLeaves.status === 'loading' ||
            state.empOnLeaves.status === 'loading' ||
            state.formDetails.status === 'loading' ||
            state.employeeStatus.status === 'loading' ||
            state.leaveTypes.status === 'loading' ||
            state.employeeLeaves.status === 'loading' ||
            state.teams.status === 'loading' ||
            state.graph.status === 'loading' ||
            state.userProfile.leaveHistoryStatus === 'loading' ||
            state.userProfile.userProfileStatus === 'loading' ||
            state.userProfile.empTeamStatus === 'loading' ||
            state.userProfile.newEmpTeamStatus === 'loading' ||
            state.userProfile.status === 'loading' ||
            state.memberProfile.leaveHistoryStatus === 'loading' ||
            state.memberProfile.userProfileStatus === 'loading' ||
            state.memberProfile.empTeamStatus === 'loading' ||
            state.memberProfile.newEmpTeamStatus === 'loading' ||
            // state.memberProfile.userDataStatus === 'loading' ||
            state.memberProfile.status === 'loading' ||
            state.selfProfile.selfProfileDataStatus === 'loading' ||
            state.selfProfile.selfEmpTeamsStatus === 'loading' ||
            state.selfProfile.selfEmpTotHrStatus === 'loading' ||
            state.selfProfile.selfOrgLeaveStatus === 'loading' ||
            state.selfProfile.selfTimeOffHistoryStatus === 'loading' ||
            state.holidays.status === 'loading' ||
            state.timeOffPolicy.status === 'loading' ||
            state.settings.status === 'loading' ||
            state.pendingActions.status === 'loading' ||
            state.dashboardLeaves.pstatus === 'loading' ||
            state.dashboardLeaves.tstatus === 'loading' ||
            state.dashboardLeaves.ustatus === 'loading' ||
            state.approverDashboardLeaves.status === 'loading' ||
            state.polls.status === 'loading' ||
            state.shoutouts.status === 'loading' ||
            state.standups.status === 'loading'
        ) {
            setIsData(true);
        } else {
            setIsData(false);
        }
    }, [state]);
    useMemo(() => {
        if (!isData) {
            history.push('/fetchData');
        }
    }, [isData]);
    return <TeamplusLoader />;
}
