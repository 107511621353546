// import { createStore } from 'redux';
// import { rootReducer } from './reducers';

// export const store = createStore(rootReducer);

import { configureStore } from '@reduxjs/toolkit';
import calendarReducer from './calendarReducer';
import activeTabReducer from './activeTab';
import sidebarOpenReducer from './sidebarReducer';
import organizationEmployeeReducer from './organizationEmployeeReducer';
import holidayReducer from './holidaysReducer';
import timeOffPolicyReducer from './timeOffPolicyReducer';
import employeeListReducer from './employeeListReducer';
import settingsReducer from './settingsReducer';
import organizationLeavesReducer from './organizationLeaves';
import formDetailsReducer from './formDetailsReducer';
import employeesOnLeaveReducer from './employeesOnLeaveReducer';
import pendingActionsReducer from './pendingActionsReducer';
import employeeStatusReducer from './employeeStatusReducer';
import leaveTypesReducer from './leaveTypesReducer';
import dashboardLeavesReducer from './dashboardLeavesReducer';
import approverDashboardLeaves from './approverDashboardLeavesReducer';
import employeeLeaves from './employeeLeavesReducer';
import teamsReducer from './teamsReducer';
import graphReducer from './graphReducer';
import userProfileReducer from './userProfileReducer';
import memberProfileReducer from './memberProfileReducer';
import selfProfileReducer from './selfProfileReducer';
// import pollsReducer from './pollReducer';
import subscriptionReducer from './subscriptionReducer';
import myorganizationsReducer from './myorganizationsReducer';
import pollsReducer from './pollsReducer';
import userStatusReducer from './userStatusReducer';
import invoicesReducer from './invoicesReducer';
import shoutoutReducer from './shoutoutReducer';
import countryReducer from './countryReducer';
import standupsReducer from './StandupRedux';
import tenantEmployeesReducer from './tenantEmployeesReducer';
import tenantMemberReducer from './tenantMemberReducer';
import celebrationReducer from './celebrationReducer';

export const store = configureStore({
    reducer: {
        calendarData: calendarReducer,
        activeTab: activeTabReducer,
        sideBar: sidebarOpenReducer,
        orgEmployees: organizationEmployeeReducer,
        holidays: holidayReducer,
        timeOffPolicy: timeOffPolicyReducer,
        employeesList: employeeListReducer,
        settings: settingsReducer,
        orgLeaves: organizationLeavesReducer,
        empOnLeaves: employeesOnLeaveReducer,
        formDetails: formDetailsReducer,
        pendingActions: pendingActionsReducer,
        employeeStatus: employeeStatusReducer,
        leaveTypes: leaveTypesReducer,
        dashboardLeaves: dashboardLeavesReducer,
        approverDashboardLeaves: approverDashboardLeaves,
        employeeLeaves: employeeLeaves,
        teams: teamsReducer,
        graph: graphReducer,
        userProfile: userProfileReducer,
        memberProfile: memberProfileReducer,
        selfProfile: selfProfileReducer,
        // polls: pollsReducer,
        subscription: subscriptionReducer,
        myorganizations: myorganizationsReducer,
        polls: pollsReducer,
        userStatus: userStatusReducer,
        invoices: invoicesReducer,
        shoutouts: shoutoutReducer,
        country: countryReducer,
        standups: standupsReducer,
        tenantEmployees: tenantEmployeesReducer,
        tenantMembers: tenantMemberReducer,
        celebrations: celebrationReducer,
    },
});
