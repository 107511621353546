// import { red } from '@material-ui/core/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const ModalStyles = makeStyles((theme: Theme) => ({
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
    },
    inputadorementstyle: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 600,
    },
    fontsty: {
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '15px',
        color: theme.palette.text.primary,
    },
    notfoundImage: {
        maxWidth: '75%',
        height: '180px',
        color: theme.palette.text.primary,
    },
    griedibtn: {
        direction: 'initial',
        justifyContent: 'flex-end',
        alignItems: 'center',
        // paddingBottom: '40px',
        // paddingRight: '30px',
        paddingTop: '15px',
    },

    btn: {
        height: 35,
        textTransform: 'capitalize',
        padding: '0 30px',
        marginRight: 10,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },

    mobbtnOutlined: {
        height: 35,
        textTransform: 'capitalize',
        marginLeft: 40,
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
        '&:active,&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
        },
    },

    delcloic: {
        marginLeft: 'auto',
        marginRight: '20px',
        marginTop: '15px',
        outline: 'none',
        color: '#888888',
        '&:hover': {
            color: '#3C48B5',
        },
    },

    grid: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: 14,
        fontWeight: 700,
        fontStyle: 'normal',
        fontFamily: theme.typography.fontFamily,
    },
    deltitle: {
        textAlign: 'center',
        fontStyle: 'normal',
        fontSize: 22,
        color: theme.palette.info.light,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    delbod: {
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: 'center',
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    delbtn: {
        height: 35,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },

    delmobbtnOutlined: {
        height: 35,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
        '&:active,&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
        },
        '&:focus': {
            outline: 'none',
        },
    },

    formLabelStyle: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontSize: '15px',
        color: theme.palette.text.primary,
        fontWeight: 600,
        marginTop: '20px',
    },
    emojiIcon: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontSize: '15px',
        color: theme.palette.text.primary,
        fontWeight: 600,
        padding: 0,
        '&:active,&:hover,&:focus': {
            border: 'none',
            outline: 'none',
        },
    },
    emojiStyle: {
        '& input': {
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            letterSpacing: '0.03em',
            backgroundColor: theme.palette.background.paper,
            width: '450px',
            height: '20px',
            fontSize: '20px',
            color: theme.palette.text.primary,
        },
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
    },
    inputStyle: {
        '& div': {
            '& input': {
                fontFamily: 'Nunito Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                letterSpacing: '0.03em',
                backgroundColor: theme.palette.background.paper,
                width: '450px',
                height: '20px',
                fontSize: '15px',
                color: theme.palette.text.primary,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
    },

    activebtn: {
        fontSize: '1.5rem',
        color: theme.palette.primary.contrastText,
        '&:hover': {
            color: '#3C48B5',
        },
    },
    disablebtn: {
        color: '#CCCCCC',
    },
    radioGroupStyles: {
        marginTop: '10px',
    },
    otherFields: {
        marginTop: '30px',
    },

    radioNameStyle: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontSize: '15px',
        color: theme.palette.text.primary,
        fontWeight: 600,
        // marginTop: 10,
    },
    holiday: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    addtotimeoffbtn: {
        height: 35,
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        // borderRadius: '5px',
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    btnFixed: {
        height: 40,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        textTransform: 'capitalize',
        padding: '0 50px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        marginRight: 10,
        borderRadius: 0,
        zIndex: 99,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    btnOutlined: {
        height: 35,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
        '&:active,&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
        },
    },
    delgribtn: {
        marginBottom: '30px',
        marginLeft: 'auto',
        marginRight: '40px',
        marginTop: '20px',
    },
    authea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#444444',
    },
    autpara: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: '#666666',
        marginTop: 20,
        marginBottom: 25,
    },
    formLabelStyle2: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontSize: '15px',
        color: theme.palette.text.primary,
        fontWeight: 600,
    },
    gotoname: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: ' 14px',
        margin: 'auto 10px',
        color: '#3D49B6',
    },
    tableContainer: {
        height: 'calc(100vh - 270px)',
        marginTop: '15px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.grey,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        '@media screen and (max-width: 760px)': {
            height: 'auto',
        },
    },
    table: {
        maxHeight: 'calc(100vh - 200px)',
        width: '100%',
    },
    row: {
        border: 'none',
        '&:hover': {
            boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
            color: theme.palette.primary.main,
            border: 'none',
            outline: 'none',
            borderRadius: 5,
            cursor: 'default',
            backgroundColor: theme.palette.info.main,
        },
    },
    cellName: {
        padding: '6px 24px 6px 16px',
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        textTransform: 'none',
        borderBottom: 'none',
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    column: {
        color: '#262F83',
        backgroundColor: '#DCDFFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        textAlign: 'center',
        padding: '6px',
    },
    columnnam: {
        color: '#262F83',
        backgroundColor: '#DCDFFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '16px',
        textAlign: 'center',
    },
    columnname: {
        color: '#262F83',
        backgroundColor: '#DCDFFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        textAlign: 'center',
    },
    editBtn: {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        width: '98%',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'capitalize',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
        },
        '&:focus': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
            outline: 'none',
        },
        zIndex: 0,
    },
    deleteBtn: {
        backgroundColor: '#fff',
        color: theme.palette.error.main,
        width: '98%',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontStyle: 'normal',
        textTransform: 'capitalize',
        '&:hover': {
            color: theme.palette.error.main,
            backgroundColor: '#fff',
        },
        '&:focus': {
            color: theme.palette.error.main,
            backgroundColor: '#fff',
            outline: 'none',
        },
        zIndex: 0,
    },
    inputStyle2: {
        '& div': {
            '& input': {
                fontFamily: 'Nunito Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                letterSpacing: '0.03em',
                color: theme.palette.text.primary,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AAAAAA',
        },
    },
    inputadorementtext: {
        '& p': {
            color: theme.palette.primary.contrastText,
        },
    },
    disableinput: {
        '& div': {
            '& input': {
                fontFamily: 'Nunito Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                letterSpacing: '0.03em',
                backgroundColor: theme.palette.background.paper,
                width: '450px',
                height: '20px',
                fontSize: '15px',
                color: theme.palette.primary.contrastText,
                borderColor: theme.palette.primary.contrastText,
                cursor: 'not-allowed',
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AAAAAA',
        },
    },
    screenSize: {
        display: 'none',
        '@media screen and (max-width: 767px)': {
            display: 'block',
        },
    },
}));
