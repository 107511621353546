import React, { useState } from 'react';
import { Grid, Paper, FormLabel, FormControl, Input, InputLabel, Button, Hidden } from '@material-ui/core';
import useStyles from './style';
// import { MBScreenStyle } from './MBScreenStyle';
// import Page1Img from '../../Assets/images/Page1Img.png';
// import udyamo from '../../Assets/images/udyamo.png';
import { Auth } from 'aws-amplify';
import Validate from '../../Validator/validateRegister';
import { PopUp } from '../Modal/Modal';
import { Link } from 'react-router-dom';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

interface ForgotPassword {
    values: any;
}

export default function ForgotPasswordPartOne(props: ForgotPassword) {
    const classes = useStyles();

    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [severity, setSeverity] = useState<string>('');

    /* istanbul ignore next */
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const state = {
            email: props.values.emailId,
        };
        const error1 = await Validate(state);
        /* istanbul ignore next */
        if (error1) {
            setAlert(true);
            setAlertMessage('Please enter valid email!');
            setSeverity('error');
            return;
        } else {
            try {
                await Auth.forgotPassword(props.values.emailId);
                setAlert(true);
                setAlertMessage('OTP sent to your email!');
                setSeverity('success');
                setTimeout(() => props.values.setPageNumber(1), 2000);
            } catch (error) {
                setAlert(true);
                setAlertMessage(error?.message);
                setSeverity('error');
                return error;
            }
        }
    };

    return (
        <div style={{ display: 'flex' }} data-testid="partOne">
            <Grid item xs={12} style={{ display: 'flex' }}>
                <Hidden lgDown>
                    <Grid item xs={5} className={classes.firstGrid}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} className={classes.imgOneStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.registerPageImage}
                                    alt="Page1Img"
                                    style={{ height: '75vh' }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.imgTwoStyle}>
                                <img
                                    src={s3Bucket + registerLoginPageImages.udyamoBlack}
                                    alt="udyamoLogo"
                                    style={{ height: '2.5vh' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} xl={7} className={classes.secondGrid}>
                    <Paper style={{ boxShadow: 'none' }} className={classes.paperStyle}>
                        <PopUp open={alert} setOpen={setAlert} message={alertMessage} severity={severity} />
                        {/* <Grid container direction="column"> */}
                        <form
                            data-testid="registerForm"
                            id="registerForm"
                            onSubmit={handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                style={{
                                    height: '80%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                }}
                                className={classes.formGrids}
                            >
                                <Grid style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 10 }}>
                                    <FormLabel style={{ color: '#444444' }}>1/2</FormLabel>
                                </Grid>
                                <FormLabel className={classes.titlePartOne} id="forgotpass">
                                    Forgot Password
                                </FormLabel>
                                <FormControl style={{ width: '100%', marginTop: 10 }}>
                                    <InputLabel style={{ color: '#444444' }}>Email</InputLabel>
                                    <Input
                                        type="email"
                                        color="primary"
                                        value={props.values.emailId}
                                        onChange={
                                            /* istanbul ignore next */ (e: any) =>
                                                props.values.setEmailId(e.target.value)
                                        }
                                        className={classes.passwordInput}
                                        margin="dense"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    id="registerBtn"
                                    className={classes.registerButton}
                                    style={{ width: '100%', height: 40 }}
                                    type="submit"
                                >
                                    Send OTP
                                </Button>
                                <Link to="/login" style={{ marginTop: 10, color: '#3C48B5' }}>
                                    Back to Login
                                </Link>
                            </Grid>
                        </form>
                        {/* </Grid> */}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
