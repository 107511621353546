import { makeStyles, Theme } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) => ({
    backArrow: {
        cursor: 'pointer',
        color: theme.palette.text.primary,
        margin: '10px 8px 10px 8px',
        '@media only screen and (max-width: 600px)': {
            margin: '10px 8px 10px -4px',
        },
    },
    poll: {
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        paddingTop: 8,
        color: theme.palette.text.primary,
        letterSpacing: 0.9,
    },
    border: {
        position: 'relative',
        width: '100%',
        height: '90%',
        '@media only screen and (min-width: 1320px)': {
            height: '96%',
        },
    },
    paper: {
        position: 'relative',
        width: '100%',
        // height: '100%',
        minHeight: 'calc(100vh - 190px)',
        background: theme.palette.background.paper,
        padding: '18px 0px 0px 5px',
    },
    paper2: {
        position: 'relative',
        width: '100%',
        // height: '100%',
        minHeight: 'calc(100vh - 190px)',
        background: theme.palette.background.paper,
        // padding: '10px 0px 0px 5px',
    },
    scroll: {
        position: 'relative',
        top: -10,
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    item: {
        fontSize: '16px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        padding: '5px 8px',
    },
    maindiv: {
        padding: '10px 0px 0px 15px',
        // '@media screen and (min-width: 1000px) and (max-width: 1250px)': {
        //     paddingTop: '5px',
        // },
        // '@media only screen and (min-width: 1320px)': {
        //     paddingTop: '30px',
        // },
    },
    avatarimage: {
        height: 30,
        width: 30,
        fontSize: 12,
        border: 'none',
        backgroundColor: '#EDEFFA',
        color: '#444444',
        textTransform: 'uppercase',
    },
    paper1: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 10,
        padding: '0px 18px 0px 5px',
        height: 50,
        '@media only screen and (max-width: 600px)': {
            marginBottom: 0,
            padding: 0,
        },
    },
    grid: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 0px 0px 0px',
    },
    heading1: {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.primary.contrastText,
    },
    subheading1: {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        paddingTop: 5,
        paddingBottom: 25,
    },
    container3: {
        paddingTop: 10,
        '@media screen and (min-width: 1000px) and (max-width: 1250px)': {
            paddingTop: 6,
        },
    },
    container4: {
        paddingTop: 12,
        '@media screen and (min-width: 1000px) and (max-width: 1250px)': {
            paddingTop: 6,
        },
        '@media only screen and (min-width: 1320px)': {
            paddingTop: 10,
        },
    },
    tooltip: {
        fontSize: 11,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    rectangle1: {
        width: 425,
        margin: '3px 95px 5px 0px',
        height: 40,
        background: theme.palette.background.default,
        left: 200,
        '@media only screen and (min-width: 320px) and (max-width: 500px)': {
            width: 280,
            // minWidth: 200,
            height: '40px',
            fontSize: '15px',
            fontFamily: 'Nunito Sans',
            fontWeight: 700,
            alignItems: 'center',
            whiteSpace: 'nowrap',
            background: theme.palette.background.default,
            color: '#222222',
        },
        '@media only screen and (min-width: 1320px)': {
            height: 35,
            width: 600,
        },
    },
    rectangle2: {
        width: 6,
        borderRadius: '0px 8px 8px 0px',
        height: 40,
        background: '#F9FAFC',
        '@media screen and (min-width: 350px) and (max-width: 500px)': {
            position: 'relative',
            height: '40px',
            width: '6px',
            borderRadius: '0px 8px 8px 0px',
            padding: '10px 0px 0px 0px',
        },
        '@media only screen and (min-width: 1320px)': {
            height: 35,
        },
    },
    btn: {
        position: 'relative',
        textAlign: 'center',
        width: 130,
        // marginTop: 3,
        // marginRight: '40px',
        height: '35px',
        fontSize: 14,
        textTransform: 'capitalize',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    pollText: {
        position: 'absolute',
        fontWeight: 700,
        margin: '0px 20px 20px 20px',
        paddingTop: 7,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        '@media screen and (min-width: 350px) and (max-width: 768px)': {
            padding: '2% 0% 0% 0%',
            fontSize: '14px',
            fontWeight: 600,
        },
        '@media only screen and (min-width: 768px) and (max-width: 1250px) ': {
            paddingTop: 5,
        },
    },
    response: {
        padding: '5px 0px 0px 0px',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        '@media screen and (min-width: 350px) and (max-width: 821px)': {
            padding: '10px 0px 22px 5px',
        },
    },
    avatar1: {
        margin: '-36px 0px 70px 435px',
        display: 'flex',
        flexDirection: 'row',
        '@media screen and (min-width: 350px) and (max-width: 700px)': {
            fontWeight: 700,
            fontSize: '13px',
            margin: '12px 0px 24px 6px',
            display: 'flex',
            flexdirection: 'row',
            color: theme.palette.text.primary,
        },
        '@media screen and (min-width: 768px) and (max-width: 900px)': {
            margin: '-34px 0px 24px 440px',
        },
        '@media only screen and (min-width: 1050px) and (max-width: 1320px) ': {
            margin: '-33px 0px 70px 440px',
        },
        '@media only screen and (min-width: 1320px)': {
            margin: '-33px 0px 70px 620px',
        },
    },
    avatar2: {
        height: '30px',
        width: '30px',
        fontSize: '14px',
        margin: '0px -5px 0px 0px',
        display: 'flex',
        flexDirection: 'row',
    },
    avatarButton: {
        top: -2,
        left: 15,
        fontWeight: 600,
        background: 'transperent',
        fontSize: '13px',
        letterSpacing: '0.02em',
        whiteSpace: 'nowrap',
        fontFamily: theme.typography.fontFamily,
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (min-width: 350px) and (max-width: 820px)': {
            bottom: '2px',
            left: '8px',
        },
        '@media screen and (min-width: 600px)': {
            fontSize: '15px',
        },
    },
    popover2: {
        backgroundColor: theme.palette.background.paper,
        width: '170px',
        height: '150px',
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: 2,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.background.paper,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundClip: 'padding-box',
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
        '@media screen and (min-width: 350px) and (max-width: 800px)': {
            height: '130px',
            width: '165px',
        },
    },
    popover1: {
        margin: '-40px 0px 0px 190px',
        '@media only screen and (min-width: 768px) and (max-width: 1000px) ': {
            margin: '-23px 0px 0px 150px',
        },
        '@media only screen and (min-width: 350px) and (max-width: 768px)': {
            margin: '-10px 0px 0px -40px',
        },
    },
    title: {
        margin: '7% 0% 0% 0%',
        fontWeight: 600,
        '@media screen and (min-width: 350px) and (max-width: 800px)': {
            margin: '5% 0% 0% 0%',
        },
    },
    menuItem: {
        fontSize: 14,
        display: 'flex',
        flexDirection: 'row',
        padding: 4,
        backgroundColor: theme.palette.background.paper,
        '@media screen and (min-width: 350px) and (max-width: 768px)': {
            padding: '7px 0px 0px 7px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 1250px) ': {
            padding: 7,
        },
    },
    triangle: {
        overflow: 'visible',
        margin: '35px 20px 0px 0px',
        backgroundColor: theme.palette.background.paper,
        opacity: 0.5,
        '&:before': {
            content: '""',
            background: theme.palette.background.paper,
            display: 'block',
            position: 'absolute',
            top: '55%',
            left: -8,
            width: 16,
            height: 16,
            transform: 'translateY(-60%) rotate(135deg)',
            zIndex: 0,
        },
        '@media screen and (min-width: 350px) and (max-width: 900px)': {
            '&:before': {
                left: 70,
                top: 0,
                width: 14,
                height: 14,
                transform: 'translateY(-50%) rotate(45deg)',
            },
        },
    },
    popoverAvatar: {
        position: 'relative',
        display: 'inline-flex',
        height: '30px',
        width: '30px',
        fontSize: '1rem',
        marginTop: '6%',
        margin: '5px',
        '@media screen and (min-width: 350px) and (max-width: 900px)': {
            margin: '0px 7px 6px 0px',
        },
    },
    donut: {
        marginTop: -20,
        position: 'relative',
        display: 'flex',
        '@media screen and (min-width: 350px) and (max-width: 768px)': {
            padding: '5% 0% 25% 15%',
        },
        '@media only screen and (min-width: 768px) and (max-width: 820px) ': {
            padding: '30% 0% 15% 65%',
        },
        '@media only screen and (min-width: 820px) and (max-width: 950px) ': {
            margin: '5% 0% 25% 15%',
        },
    },
    text1: {
        position: 'absolute',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        left: 470,
        top: 8,
        display: 'flex',
        flexDirection: 'row',
        '@media screen and (min-width: 1320px)': {
            left: 650,
        },
    },
    text: {
        position: 'absolute',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        right: 0,
        top: 10,
    },
    result: {
        position: 'relative',
        width: 550,
        '@media screen and (min-width: 1320px)': {
            width: 720,
        },
    },
    btn1: {
        width: 170,
        height: 40,
        // color: '#FFFFFF',
        right: 30,
        fontSize: 14,
        textTransform: 'capitalize',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:focus': {
            outline: 'none',
        },
        '@media only screen and (max-width:600px)': {
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: 42,
            right: 0,
            borderRadius: 0,
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    btnMain: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));
