import React from 'react';
import useStyles from './styles';

interface Props {
    formerrors?: any;
}

function FormErrors(props: Props): JSX.Element {
    const classes = useStyles();
    if (
        props.formerrors &&
        (props.formerrors.blankfield ||
            props.formerrors.passwordmatch ||
            props.formerrors.passwordLength ||
            props.formerrors.otplength)
    ) {
        return (
            <div className={classes.container}>
                <div className={classes.row} id="formError">
                    {props.formerrors.passwordmatch ? 'Password value does not match confirm password value' : ''}
                    {props.formerrors.blankfield ? 'All fields are required' : ''}
                    {props.formerrors.blank ? 'Select atleast one holiday' : ''}
                    {props.formerrors.blankMember ? 'Select atleast one member' : ''}
                    {props.formerrors.passwordLength
                        ? 'Password length should be greater than or equal to 8 characters'
                        : ''}
                    {props.formerrors.otplength ? 'Please check the length of your OTP' : ''}
                </div>
            </div>
        );
    } else if (props.formerrors && props.formerrors.cognito) {
        return (
            <div className={classes.container}>
                <div className={classes.row} id="cognitoFormError">
                    {props.formerrors.cognito.message}
                </div>
            </div>
        );
    } else {
        return <span></span>;
    }
}

export default FormErrors;
