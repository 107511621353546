import { makeStyles } from '@material-ui/core/styles';

const myStyle = makeStyles(
    theme => ({
        root: {
            padding: '20px',
            minHeight: 'calc(100vh - 140px)',
            maxHeight: 'calc(100vh - 130px)',
            marginTop: 10,
            boxSizing: 'border-box',
        },
        mainRoot: {
            width: '100%',
            minHeight: 'calc(100vh - 250px)',
            maxHeight: 'calc(100vh - 230px)',
            '@media only screen and (max-width: 800px)': {
                textAlign: 'center',
                height: 'auto',
            },
        },
        formLabelStyle: {
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '13px',
            color: theme.palette.text.primary,
        },
        formLabelStyle2: {
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontSize: '15px',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            whiteSpace: 'pre-line',
            // textTransform: 'capitalize',
        },
        myFilter: {
            // width: '90%',
            color: theme.palette.text.primary,
            height: 35,
            fontSize: 14,
            marginLeft: '20%',
            fontWeight: 600,
            textTransform: 'capitalize',
            border: `1px solid ${theme.palette.primary.contrastText}`,
            padding: 0,
            // borderRadius: 20,
            '& span': {
                fontWeight: theme.typography.fontWeightRegular,
            },
            '&:hover, &:focus': {
                outline: 'none',
                // background: '#fff',
            },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: 0,
            },
            '@media screen and (max-width: 780px)': {
                width: '100%',
            },
            '@media only screen and (min-width: 1238px)': {
                minWidth: '72%',
            },
            '@media only screen and  (max-width: 1237px)': {
                minWidth: '75%',
            },
        },
        filterBtn: {
            width: '22%',
            color: theme.palette.primary.contrastText,
            height: 30,
            fontSize: 14,
            fontWeight: 600,
            textTransform: 'capitalize',
            border: `1px solid ${theme.palette.primary.contrastText}`,
            padding: 0,
            // borderRadius: 20,
            '&:hover, &:focus': {
                outline: 'none',
                // background: '#fff',
            },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: 0,
            },
        },
        menuitemSelected: {
            // marginLeft: 5,
            // marginRight: 5,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontStyle: 'normal',
            fontSize: '16px',
            textTransform: 'capitalize',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        toolbarRow: {
            display: 'flex',
            height: 45,
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '1px 0px',
            padding: '5px 10px 5px 0px',
            '@media screen and (max-width: 650px)': {
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
        },
        teamTitle: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            fontFamily: theme.typography.fontFamily,
        },
        button: {
            height: 35,
            width: '100%',
            fontSize: 14,
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 650px)': {
                marginRight: '20px',
                borderRadius: '30px',
            },
        },
        tab: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            color: theme.palette.text.primary,
            fontStyle: 'normal',
            fontFamily: theme.typography.fontFamily,
            paddingLeft: '20px',
            '@media only screen and (max-width:800px)': {
                paddingLeft: '0px',
            },
        },
        teampaper: {
            height: 50,
            // boxSizing: 'border-box',
            paddingRight: '20px',
            // borderRadius: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.paper,
        },
        top: {
            // background: '#fff',
            display: 'flex',
            alignItems: 'center',
            marginLeft: 10,
        },
        backArrow: {
            // width: 20,
            // height: 12,
            cursor: 'pointer',
            // color: theme.palette.text.disabled,
            color: theme.palette.text.primary,
            // '@media screen and (max-width: 768px)': {
            //     width: 18,
            //     height: 8,
            // },
        },
        heading: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
            marginLeft: 10,
            '@media screen and (max-width: 768px)': {
                fontSize: 16,
            },
        },
        btn: {
            height: 35,
            padding: '0 25px',
            marginLeft: 10,
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        btnOutlined: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 25px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
        },
        btnHalfFixed: {
            height: 40,
            width: '50%',
            position: 'fixed',
            bottom: 0,
            right: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            borderRadius: 0,
            zIndex: 99,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        btnFixed: {
            height: 40,
            // width: '50%',
            width: '100%',
            position: 'fixed',
            bottom: 0,
            right: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            borderRadius: 0,
            zIndex: 99,
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '&:focus': {
                textDecoration: 'none',
                outline: 'none',
                border: '1px solid #3C48B5',
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
            },
        },
        btnOutlined2: {
            height: 40,
            width: '50%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            // border: '1px solid #3C48B5',
            borderRadius: 0,
            zIndex: 99,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
        },
        mobpaperstyle: {
            padding: '10px 20px',
            // margin: '15px 0px',
            margin: '5px 0px',
            borderRadius: 5,
        },
        fontsty: {
            textAlign: 'center',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            alignItems: 'center',
            opacity: 0.7,
        },
        Team: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            // border: 'solid red'
        },
        fab: {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
            height: 15,
            width: 20,
            fontSize: '12px',
            padding: 0,
            '&:hover': {
                cursor: 'pointer',
            },
        },
        Members: {
            display: 'flex',
            flexWrap: 'wrap',
            columnCount: 2,
        },
        teamList: {
            maxHeight: '120px',
            overflowY: 'auto',
            margin: '0px 5px',
            '&::-webkit-scrollbar': {
                width: 2,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        textName: {
            color: theme.palette.text.primary,
        },
        IconSty: {
            color: theme.palette.primary.contrastText,
            '&:hover,&:focus,&:active': {
                color: theme.palette.primary.main,
            },
        },
        userteam: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontStyle: 'normal',
        },
        username: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 14,
        },
        fontright: {
            float: 'right',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            alignItems: 'center',
            opacity: 0.7,
            color: theme.palette.text.primary,
        },
        rowmessage: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '50px',
            color: theme.palette.text.primary,
        },
        teamtitle1: {
            color: theme.palette.secondary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '14px',
            fontFamily: theme.typography.fontFamily,
            paddingTop: '3%',
            paddingBottom: '2%',
            textAlign: 'left',
        },
        table: {
            maxHeight: 'calc(100vh - 200px)',
            width: '100%',
        },
        detailsPaper: {
            minHeight: 'calc(100vh - 140px)',
            maxHeight: 'calc(100vh - 130px)',
            marginTop: 10,
            padding: '20px 20px',
        },
        row: {
            border: 'none',
            '&:hover': {
                boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
                color: theme.palette.primary.main,
                border: 'none',
                outline: 'none',
                borderRadius: 5,
                cursor: 'pointer',
                backgroundColor: theme.palette.info.main,
            },
        },
        headerId: {
            color: '#262F83',
            height: 30,
            backgroundColor: '#DCDFFF',
            width: 50,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            // textAlign: 'center',
            zIndex: 999,
            padding: '7px',
        },
        headerName: {
            color: '#262F83',
            height: 36,
            backgroundColor: '#DCDFFF',
            minWidth: 190,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            // textAlign: 'center',
            zIndex: 999,
            padding: '7px',
        },
        headerAppEmp: {
            color: '#262F83',
            height: 30,
            backgroundColor: '#DCDFFF',
            minWidth: 150,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            // textAlign: 'center',
            zIndex: 999,
            padding: '7px',
        },
        headerAction: {
            color: '#262F83',
            height: 30,
            backgroundColor: '#DCDFFF',
            minWidth: 100,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            // textAlign: 'center',
            zIndex: 999,
        },
        idColumn: {
            height: 30,
            color: '#262F83',
            // maxWidth: 80,
            minWidth: 100,
            backgroundColor: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            // textAlign: 'center',
            zIndex: 999,
        },
        hashColumn: {
            height: 30,
            color: theme.palette.text.primary,
            // maxWidth: 80,
            minWidth: 100,
            // padding: 10,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            borderBottom: 'none',
            fontSize: '14px',
            zIndex: 999,
            padding: '7px',
        },
        columnnam: {
            color: '#262F83',
            height: 30,
            backgroundColor: '#DCDFFF',
            minWidth: 150,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            // textAlign: 'center',
            zIndex: 999,
        },
        column: {
            color: '#262F83',
            backgroundColor: '#DCDFFF',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
            textAlign: 'center',
        },
        menuactive: {
            '&:hover': {
                color: theme.palette.primary.main,
            },
            '&:active': {
                color: theme.palette.primary.main,
            },
            '&:focus': {
                color: theme.palette.primary.main,
            },
        },
        menusty: {
            borderRadius: 0,
        },
        menuitem: {
            // marginLeft: 5,
            // marginRight: 5,
            // fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            // fontStyle: 'normal',
            // fontSize: '16px',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            textTransform: 'capitalize',
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:active': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
        },
        menucontainer: {
            borderRadius: 2,
            zIndex: 999999,
            marginTop: 30,
            maxHeight: 30 * 4.5,
            width: '20ch',
            padding: '0px 5px',
            '&::-webkit-scrollbar': {
                width: '4px',
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
                '&:hover': {
                    borderRadius: 10,
                    backgroundColor: theme.palette.secondary.main,
                },
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
                '&:hover': {
                    borderRadius: 10,
                    backgroundColor: theme.palette.primary.main,
                    height: 5,
                },
            },
        },
        teamMenuTitle: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            fontFamily: theme.typography.fontFamily,
            textAlign: 'left',
        },
        tableContainer: {
            height: 'calc(100vh - 300px)',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
        image: {
            border: 'none',
            outline: 'none',
            width: 30,
            height: 30,
        },
        avatarimage: {
            height: 30,
            width: 30,
            fontSize: 12,
            border: 'none',
            backgroundColor: '#EDEFFA',
            color: '#444444',
            textTransform: 'uppercase',
        },
        avatarcenter: {
            display: 'flex',
            justifyContent: 'center',
            border: 'none',
            '& .MuiAvatarGroup-avatar': {
                width: 30,
                height: 30,
                bottom: '1px',
            },
        },
        cellName: {
            padding: '7px',
            minWidth: 150,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            // textTransform: 'capitalize',
            borderBottom: 'none',
            color: theme.palette.text.primary,
        },
        deltitle: {
            textAlign: 'center',
            fontStyle: 'normal',
            fontSize: 22,
            color: theme.palette.info.light,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            zIndex: 0,
            marginBottom: 10,
        },
        delbod: {
            width: '70%',
            fontSize: 16,
            textAlign: 'center',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
        },
        // delbodOption: {
        //     fontSize: 14,
        //     textAlign: 'center',
        //     fontWeight: theme.typography.fontWeightMedium,
        //     color: theme.palette.primary.contrastText,
        //     fontStyle: 'normal',
        //     fontFamily: theme.typography.fontFamily,
        //     margin: '10px 0',
        // },
        delbodOption: {
            fontSize: 14,
            textAlign: 'center',
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.primary.contrastText,
            fontStyle: 'normal',
            fontFamily: theme.typography.fontFamily,
            margin: '10px 0',
            letterSpacing: '0.04em',
        },
        delbtn: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        delmobbtnOutlined: {
            height: 35,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
        },
        boxtext: {
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            textTransform: 'capitalize',
        },
        notfoundImage: {
            maxWidth: '75%',
            height: '180px',
        },
        notfoundText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 16,
            fontWeight: 600,
        },
        paperfontstyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '120px',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '18px',
            color: theme.palette.text.primary,
            fontStyle: 'normal',
            fontFamily: theme.typography.fontFamily,
            marginTop: 5,
        },
        rowteam: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            color: '#333333',
            fontStyle: 'normal',
            fontFamily: theme.typography.fontFamily,
        },
        inputStyle: {
            marginTop: '0px',
            minWidth: 250,
            width: '100%',
            '& div': {
                height: '35px',
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '15px',
                    color: theme.palette.text.primary,
                },
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media screen and (min-width: 900px) and (max-width: 1055px)': {
                '& div': {
                    height: '35px',
                    maxWidth: 200,
                    width: '100%',
                    '& input': {
                        fontFamily: 'Nunito Sans',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        letterSpacing: '0.03em',
                        backgroundColor: theme.palette.background.paper,
                        fontSize: '15px',
                        color: theme.palette.text.primary,
                    },
                },
            },
            // '@media screen and (min-width: 500px) and (max-width: 900px)': {
            //     width: '120px',
            // },
        },
        inputStyle1: {
            // border:'1px solid red',
            height: '35px',
            marginTop: '0px',
            // paddingTop:'1px',
            minWidth: 250,
            width: '100%',
            '& div': {
                // height: '35px',
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '15px',
                    color: '#222222',
                },
            },
            '& fieldset': {
                // border:'1px solid red',
                height: '40px',

                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media screen and (min-width: 900px) and (max-width: 1055px)': {
                // '& div': {
                //     height: '35px',
                //     maxWidth: 200,
                //     width: '100%',
                // },
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '15px',
                    color: '#222222',
                },
            },
        },
        listbtn: {
            height: 25,
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
            outlineColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
                outlineColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'primary',
                outline: 'none',
            },
        },
        autocompletelist: {
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
            backgroundColor: theme.palette.background.paper,
        },
        autotext: {
            '& div': {
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    background: '#FFFFFF',
                    fontSize: '15px',
                    color: '#222222',
                },
                '& div': {
                    '& button': {
                        color: theme.palette.primary.contrastText,
                    },
                },
            },
        },
        autobox: {
            '& .MuiAutocomplete-input': {
                padding: '0px',
                paddingLeft: '10px',
                height: '2px',
                width: '100%',
                // width: '200%'
            },
            '& .MuiAutocomplete-inputRoot': {
                // width: '280px',
                minWidth: 280,
                width: '100%',
            },
            '& div': {
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '15px',
                    color: theme.palette.text.primary,
                },
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media screen and (min-width: 900px) and (max-width: 1055px)': {
                '& .MuiAutocomplete-input': {
                    padding: '0px',
                    paddingLeft: '10px',
                    height: '2px',
                    width: '100%',
                },
                '& .MuiAutocomplete-inputRoot': {
                    // width: '280px',
                    minWidth: 200,
                    width: '100%',
                },
            },
            // '@media screen and (min-width: 500px) and (max-width: 900px)': {
            //     '& .MuiAutocomplete-inputRoot': {
            //         // width: '150px',
            //         with: '50%',
            //     },
            // },
        },
        searchposmob: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 5,
            padding: '0px',
        },
        searchInput: {
            height: 35,
            width: '100%',
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.text.primary,
            '&:hover, &:focus': {
                outline: 'none',
                color: theme.palette.text.primary,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: 0,
                '&:hover, &:focus': {
                    outline: 'none',
                    color: theme.palette.text.primary,
                },
            },
        },
        listTitle: {
            padding: '0px 10px',
            textTransform: 'capitalize',
            textAlign: 'center',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
            fontSize: 14,
        },
        selectorPaper: {
            padding: '20px',
            // minHeight: 'calc(100vh - 134px)',
            // maxHeight: 'calc(100vh - 130px)',
            height: 'calc(100vh - 130px)',
            marginTop: 10,
            boxSizing: 'border-box',
            '@media screen and (max-width: 768px)': {
                height: 'auto',
            },
        },
        searchInput1: {
            height: 35,
            width: '100%',
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            '&:hover, &:focus, &:active': {
                outline: 'none',
                color: theme.palette.text.primary,
                '& div': {
                    '& fieldset': {
                        border: '1px solid red',
                    },
                },
            },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: 0,
                '&:hover, &:focus': {
                    outline: 'none',
                    color: theme.palette.text.primary,
                },
            },
        },
        searchIcon: {
            '@media screen and (min-width:912px) and (max-width: 1024px)': {
                marginLeft: 10,
            },
        },
        IconSty1: {
            color: theme.palette.primary.contrastText,
        },
        filterButton: {
            letterSpacing: '0.05em',
            textTransform: 'initial',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '&:focus': {
                outline: 'none',
            },
        },
        searBorder: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&:hover fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
        },
        menubar: {
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
            maxHeight: 400,
        },
    }),
    { index: 1 },
);

export default myStyle;
