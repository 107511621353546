const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        // console.log('registering service worker....');
        navigator.serviceWorker
            .register('./firebase-messaging-sw.js', {
                scope: '/',
            })
            .then(function(registration) {
                // console.log('Registration successful, scope is:', registration.scope);
                return registration.scope;
            })
            .catch(function(err) {
                // console.log('Service worker registration failed, error:', err);
                return { error: err };
            });
    }
};

export { registerServiceWorker };
