/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MuiThemeProvider, Button, Modal } from '@material-ui/core';
import { darkTheme, lightTheme } from '../theme/newTheme';
// import { useFlagsmith } from 'flagsmith-react';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from 'components/Header/HeaderStyles';
import { PopUp } from 'components/Modal/Modal';

// import { messaging } from 'init-fcm';
// import { saveDeviceToken } from 'components/WebNotification/Notification';
import flagsmith from 'flagsmith';
import moment from 'moment';
import Context from './AuthContext';

// Import for redux fetch part
import { fetchPeople } from 'components/Redux/peopleReducer';
// import { getSubscription } from 'components/Redux/subscriptionReducer';
import { getInvoices } from 'components/Redux/invoicesReducer';
import { LoaderPage } from 'components/Loader/loader';
import { toDate, toAWSDateFormat } from 'helpers/date';
import { getOrgEmployees, getOrgApprovers } from 'components/Redux/organizationEmployeeReducer';
// import { getCalendar, removeCalendarLeave } from 'components/Redux/calendarReducer';
// import { getHolidays } from 'components/Redux/holidaysReducer';
// import { getSettings } from 'components/Redux/settingsReducer';
// import { getFormDetails } from 'components/Redux/formDetailsReducer';
import { getEmployeesOnLeave } from 'components/Redux/employeesOnLeaveReducer';
import { getOrgLeave } from 'components/Redux/organizationLeaves';

// import { getPendingActions } from 'components/Redux/pendingActionsReducer';
import { getTotalLeaveCount, getLeaveCountToday } from 'components/Redux/employeeStatusReducer';
import { getLeaveTypes, getDaysPerYear, getLeaveTypeData } from 'components/Redux/leaveTypesReducer';
// import { getPendingLeaves, getTodaysLeaves, getUpcomingLeaves } from 'components/Redux/dashboardLeavesReducer';
// import { getEmployeeLeaves } from 'components/Redux/employeeLeavesReducer';
// import { getApproverLeaves } from 'components/Redux/approverDashboardLeavesReducer';
// import { getReduxTeams } from './components/Redux/teamsReducer';
import { getTimeOffORGData, getTeamData, getLeaveTypesData } from 'components/Redux/graphReducer';
import {
    getUserProfile,
    getUsersTodaysLeaves,
    getUsersUpcomingLeaves,
    getEmpTeams,
    getNewEmpTeams,
    getEmpLeaveHistory,
} from 'components/Redux/userProfileReducer';
// import {
//     getOrganizationTeamsSubscription,
//     fetchtimeoffSubscription,
//     getEmployeeSubscription,
//     getOrganizationApproversSubscription,
//     getOrganizationEmployeesSubscription,
//     getSubscription_Subscription,
// } from './helpers/hasuraapi';
// import { getTimeOffPolicy } from './components/Redux/timeOffPolicyReducer';
import { getAllOrgTeams, updateProfileImage, updateProfileName } from 'components/Redux/employeeListReducer';
// import { fetchInviteStatus, getOrganizationEmployeesTeams, fetchPendingActionsSubscription } from './helpers/hasuraapi';
import {
    selfProfileData,
    selfEmployeeTeams,
    selfEmployeeTotalHours,
    selfOrgLeaveTypes,
    selfTimeOffHistory,
} from 'components/Redux/selfProfileReducer';
import { getMyOrganizations } from 'components/Redux/myorganizationsReducer';
// import { getStandups } from 'components/Redux/StandupRedux';
// import { getTimeOffPolicy } from 'components/Redux/timeOffPolicyReducer';
// import { getReduxTeams } from 'components/Redux/teamsReducer';
// import { getEmployeesList, getInviteStatus, getAllOrgTeams } from './components/Redux/employeeListReducer';
// import {
//     fetchPendingLeavesSubscription,
//     fetchApproverUpcomingLeavesSubscription,
//     fetchTodaysLeavesSubscription,
//     fetchUpcomingLeavesSubscription,
//     fetchEmployeeLeavesSubscription,
//     // peopleOnLeaveCountSubscription,
// } from './helpers/hasuraapi';
import { refreshSlackLoginToken } from '../helpers/hasuraapi';

// let deviceToken: any;

interface Props {
    username?: any;
    isAdmin?: any;
    isSuperAdmin?: any;
    mainAdmin?: any;
    isGoogle?: any;
    isApprover?: any;
    firstname?: any;
    email?: any;
    isLogedIn?: any;
    profile?: string;
    token?: string;
    refreshToken?: string;
    uuid?: string;
    joining?: string;
    teamname?: string;
    statustype?: string;
    sickdaysused?: any;
    sickdaystotal?: any;
    fname?: string;
    lname?: string;
    EmployeeId?: string;
    dateFormat?: string;
    webhookUrl?: string;
    gender?: string;
    subscriptionExpired?: string;
    subscriptionId?: string;
    warningDate?: any;
    hardExpireDate?: any;
    initialSignIn?: any;
    standupTempId?: string;
    tenantId?: any;
    organizationLogo?: any;
    tenantTableId?: any;
}

interface Props {
    children: any;
}

export const AuthContext = (props: Props) => {
    const [username, setUsername] = useState<any>();
    const [password, setPassword] = useState<any>();
    const [userId, setUserId] = useState<any>();
    const [employeeId, setEmployeeId] = useState<any>();
    const [fname, setFname] = useState<any>('');
    const [lname, setLname] = useState<any>('');
    const [email, setEmail] = useState<any>('');
    const [isAdmin, setIsadmin] = useState<any>(false);
    const [isSuperAdmin, setIsSuperadmin] = useState<any>(false);
    const [mainAdmin, setMainadmin] = useState<any>(false);
    const [isLogedIn, setIslogedin] = useState<any>(false);
    const [isApprover, setIsapprover] = useState<any>(false);
    const [profile, setProfile] = useState<any>();
    const [statustype, setStatusType] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [token, setToken] = useState<any>();
    const [refreshToken, setRefreshToken] = useState<any>();
    const [uuid, setUuid] = useState<any>();
    const [joining, setJoining] = useState<any>();

    const [organizationId, setOrganizationId] = useState<any>('');
    const [settingsId, setSettingsId] = useState<any>('');
    const [tenantTableId, settenantTableId] = useState<any>('');
    const [organizationName, setOrganizationName] = useState<any>();
    const [tenantName, setTenantName] = useState<any>();
    const [teamId, setTeamId] = useState<any>();
    const [teamName, setTeamName] = useState<any>('');
    const [tenantId, setTenantId] = useState<any>('');
    const [userProfile, setUserProfile] = useState<any>('');
    const [isGoogle, setIsGoogle] = useState<boolean>(false);
    const [dateFormat, setDateFormat] = useState<any>('');
    const [webhookUrl, setWebhookUrl] = useState<any>('');
    const [expires, setExpires] = useState<boolean>(false);
    const [themeToggle, setThemeToggle] = useState<boolean>(false);
    const [organizationLogo, setOrganizationLogo] = useState<any>('');
    const [settings, setSettings] = useState<any>(false);
    const [standupTempId, setStandupTempId] = useState<any>('');
    const [location, setLocation] = useState<any>({
        loaded: false,
        country: '',
        code: '',
        timezone: '',
    });

    const [approverCalledAs, setApproverCalledAs] = useState<any>('Approver');
    const [employeeCalledAs, setEmployeeCalledAs] = useState<any>('Employee');
    const [teamsCalledAs, setTeamsCalledAs] = useState<any>('Teams');
    const [features, setFeatures] = useState<any>({
        isApproverCalledAs: false,
        isPolls: false,
        isSubscription: false,
        employeeProfilePolls: false,
        employeeProfileStandup: false,
        employeeProfileShoutOut: false,
    });
    const [workingHours, setWorkingHours] = useState<any>(0);
    const [teamsPage, setTeamsPage] = useState<boolean>(false);
    const [subscriptionExpired, setSubscriptionExpired] = useState<any>('');
    const [warningDate, setWarningDate] = useState<any>('');
    const [hardExpireDate, setHardExpireDate] = useState<any>('');
    const [initialSignIn, setInitialSignIn] = useState<boolean>(false);
    const [subscriptionId, setSubscriptionId] = useState<any>('');
    const [pollResult, setPollResult] = useState<boolean>(false);
    const [currentPoll, setCurrentPoll] = useState<any>({});
    const [country, setCountry] = useState<any>({
        countryCode: 'IN',
        countryName: 'India',
        gmtOffset: 19800,
        timestamp: 1665487798,
        zoneName: 'Asia/Kolkata',
    });
    const history: any = useHistory();

    const [popup, setPopup] = useState({
        alertMessage: '',
        severity: '',
    });
    const [alert, setAlert] = useState(false);

    const state = useSelector((state: any) => state);
    const classes = useStyles();

    const dispatch = useDispatch();

    const addCountry = (obj: any) => {
        // console.log('THIS IS OBJ', obj);
        const cObj = {
            countryCode: obj.countryCode,
            countryName: obj.countryName,
            zoneName: obj.zoneName,
            gmtOffset: obj.gmtOffset,
            timestamp: obj.timestamp,
        };
        setCountry(cObj);
        localStorage.setItem('country', JSON.stringify(cObj));
    };

    const togglePopup = (obj: any) => {
        setAlert(true);
        setPopup({
            alertMessage: obj.alertMessage,
            severity: obj.severity,
        });
    };

    const addLocation = (location: any) => {
        setLocation({
            loaded: true,
            country: location?.country,
            code: location?.code,
            timezone: location?.timezone,
        });
        localStorage.setItem('location', JSON.stringify(location));
    };

    const firstSignIn = async (props: Props) => {
        setInitialSignIn(props.initialSignIn);
    };

    const toggleTheme = () => {
        setThemeToggle(!themeToggle);
        localStorage.setItem('themeToggle', (!themeToggle).toString());
    };
    const registerUser = (user: any, password: string) => {
        setUsername(user.username);
        setPassword(password);
        // setEmployeeId(employeeId);
        // setToken(token);
        localStorage.setItem('username', `${user.username}`);
        localStorage.setItem('password', `${password}`);
        // localStorage.setItem('token', `${token}`);
        // localStorage.setItem('employeeid', `${employeeId}`);
    };

    const addUserId = (userId: any) => {
        setUserId(userId);
        localStorage.setItem('userid', `${userId}`);
    };
    const updateUsername = (username: any) => {
        setUsername(username);
        localStorage.setItem('username', `${username}`);
    };

    const updateName = (firstname: string, lastname: string) => {
        setFname(firstname);
        setLname(lastname);
        localStorage.setItem('fname', `${firstname}`);
        localStorage.setItem('lname', `${lastname}`);
    };

    const updatePollResult = (value: boolean) => {
        setPollResult(value);
        localStorage.setItem('pollResult', value.toString());
    };
    const addEmail = (email: any) => {
        setEmail(email);
        localStorage.setItem('email', `${email}`);
    };

    const updateSubscriptionExpired = (status: any) => {
        setSubscriptionExpired(status);
        localStorage.setItem('subscriptionExpired', `${subscriptionExpired}`);
    };
    const updateWarningDate = (warningD: any) => {
        setWarningDate(warningD);
        localStorage.setItem('warningDate', `${warningDate}`);
    };
    const updateHardExpireDate = (hardExpireD: any) => {
        setHardExpireDate(hardExpireD);
        localStorage.setItem('hardExpireDate', `${hardExpireDate}`);
    };
    const updateSubscriptionId = (subscriptionId: any) => {
        setSubscriptionId(subscriptionId);
        localStorage.setItem('subscriptionId', `${subscriptionId}`);
    };

    const addRefreshToken = (refreshToken: string, token: string) => {
        setRefreshToken(refreshToken);
        setToken(token);
        localStorage.setItem('token', `${token}`);
        localStorage.setItem('refreshToken', `${refreshToken}`);
        window.location.reload();
    };

    const addOrganizationId = (id: any) => {
        // await new Promise(resolve => {
        setOrganizationId(id);
        localStorage.setItem('organizationId', `${id}`);
        // });
    };

    const addTenantId = (id: any) => {
        setTenantId(id);
        localStorage.setItem('tenantId', id);
    };

    const addStandupTempId = (id: any) => {
        setStandupTempId(id);
        localStorage.setItem('standupTempId', `${id}`);
    };

    const addTeamId = (id: any) => {
        setTeamId(id);
        localStorage.setItem('teamId', `${id}`);
    };

    const addSettingsId = (id: any) => {
        setSettingsId(id);
        localStorage.setItem('settingsId', `${id}`);
    };
    const addTenantTableId = (id: any) => {
        settenantTableId(id);
        localStorage.setItem('tenantTableId', `${id}`);
    };
    const addOrganizationName = (name: any) => {
        setOrganizationName(name);
        localStorage.setItem('organizationName', `${name}`);
    };

    const addTenantName = (name: any) => {
        setTenantName(name);
        localStorage.setItem('tenantName', `${name}`);
    };

    const addOrganizationLogo = (organizationLogo: any) => {
        setOrganizationLogo(organizationLogo);
        localStorage.setItem('organizationLogo', `${organizationLogo}`);
    };

    const addSettings = (settings: any) => {
        setSettings(settings);
        localStorage.setItem('settings', `${settings}`);
    };

    const addOtherSettings = (data: any) => {
        setApproverCalledAs(data.approverCalledAs);
        setTeamsCalledAs(data.teamsCalledAs);
        setEmployeeCalledAs(data.employeeCalledAs);
        setWorkingHours(data.workingHours);
        localStorage.setItem('approverCalledAs', `${data.approverCalledAs}`);
        localStorage.setItem('employeeCalledAs', `${data.employeeCalledAs}`);
        localStorage.setItem('teamsCalledAs', `${data.teamsCalledAs}`);
        localStorage.setItem('workingHours', `${data.workingHours}`);
    };

    const updateTeam = (team: any) => {
        setTeamName(team.name);
        setTeamId(team.teamId);
        localStorage.setItem('teamName', `${team.name}`);
        localStorage.setItem('teamId', `${team.teamId}`);
    };

    const addUserProfile = (id: any) => {
        setUserProfile(id);
        localStorage.setItem('userProfile', `${id}`);
    };

    const updateUserProfile = (url: any) => {
        setProfile(url);
        localStorage.setItem('profile', `${url}`);
    };

    const updateGoogle = () => {
        setIsGoogle(!isGoogle);
        localStorage.setItem('isGoogle', isGoogle ? 'true' : 'false');
    };

    const updateDateFormat = (dateFormat: any) => {
        setDateFormat(dateFormat);
        localStorage.setItem('dateFormat', dateFormat);
    };

    const addWebhookUrl = (url: any) => {
        setWebhookUrl(url);
        localStorage.setItem('webhookUrl', `${url}`);
    };

    const sessionExpires = () => {
        setExpires(true);
        localStorage.setItem('expires', `${true}`);
    };

    const updateIsAdmin = (isadmin: boolean) => {
        setIsadmin(isadmin);
        localStorage.setItem('isAdmin', `${isadmin}`);
    };

    const addCurrentPoll = (obj: any) => {
        setCurrentPoll(obj);
        localStorage.setItem('currentPoll', JSON.stringify(obj));
    };

    const handleTeamsPage = () => {
        setTimeout(() => {
            setTeamsPage(!teamsPage);
        }, 2000);
    };

    const handleProfile = (userId: string) => {
        dispatch({ type: 'PROFILE_ID', payload: userId });
        addUserProfile(userId);
        if (userId === employeeId) {
            history.push({
                pathname: '/user/profile',
                state: {
                    id: userId,
                    userLink: false,
                    backTo: history.location.pathname.slice(1),
                },
            });
        } else {
            history.push({
                pathname: `/employees/${userId}`,
                state: {
                    id: userId,
                    userLink: false,
                    backTo: history.location.pathname.slice(1),
                },
            });
        }
    };

    const fetchAllData = () => {
        dispatch(
            getOrgEmployees({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getOrgApprovers({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        // dispatch(
        //     getHolidays({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        // dispatch(
        //     getCalendar({
        //         employeeId: localStorage.getItem('employeeid'),
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //         isApprover: localStorage.getItem('isApprover') === 'true' ? true : false,
        //     }),
        // );
        // dispatch(
        //     getSettings({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        // dispatch(
        //     getSubscription({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        dispatch(getInvoices(localStorage.getItem('subscriptionId') || ''));
        // dispatch(
        //     getFormDetails({
        //         organizationId: localStorage.getItem('organizationId'),
        //         employeeId: localStorage.getItem('employeeid'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        dispatch(
            getEmployeesOnLeave({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getOrgLeave({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        // dispatch(
        //     getPendingActions({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        dispatch(
            getLeaveTypes({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
                startDate: toDate(
                    moment()
                        .clone()
                        .startOf('month')
                        .startOf('day'),
                ),
                endDate: toDate(
                    moment()
                        .clone()
                        .endOf('month')
                        .endOf('day'),
                ),
            }),
        );
        dispatch(
            getDaysPerYear({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getTotalLeaveCount({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getLeaveCountToday({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
                date: toAWSDateFormat(new Date()),
            }),
        );
        // peopleOnLeaveCountSubscription(
        //     localStorage.getItem('organizationId'),
        //     localStorage.getItem('token'),
        //     toAWSDateFormat(new Date()),
        // );
        // dispatch(
        //     getPendingLeaves({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        // dispatch(
        //     getTodaysLeaves({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //         date: toAWSDateFormat(new Date()),
        //     }),
        // );
        // dispatch(
        //     getUpcomingLeaves({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //         date: toAWSDateFormat(new Date()),
        //     }),
        // );
        // dispatch(
        //     getEmployeeLeaves({
        //         organizationId: localStorage.getItem('organizationId'),
        //         employeeId: localStorage.getItem('employeeid'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        // dispatch(
        //     getApproverLeaves({
        //         organizationId: localStorage.getItem('organizationId'),
        //         employeeId: localStorage.getItem('employeeid'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        dispatch(
            getTimeOffORGData({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getTeamData({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getLeaveTypesData({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getUserProfile({
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getUsersTodaysLeaves({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
                date: toAWSDateFormat(new Date()),
            }),
        );
        dispatch(
            getUsersUpcomingLeaves({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
                date: toAWSDateFormat(new Date()),
            }),
        );
        dispatch(
            getEmpTeams({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getNewEmpTeams({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
            }),
        );
        // dispatch(
        //     getTimeOffPolicy({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        // fetchtimeoffSubscription({
        //     organizationId: localStorage.getItem('organizationId'),
        //     token: localStorage.getItem('token'),
        // });
        // dispatch(
        //     getEmployeesList({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        // dispatch(
        //     getInviteStatus({
        //         organizationId: localStorage.getItem('organizationId'),
        //     }),
        // );
        dispatch(
            getAllOrgTeams({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        // fetchInviteStatus({
        //     organizationId: localStorage.getItem('organizationId'),
        //     token: localStorage.getItem('token'),
        // });
        // getOrganizationEmployeesTeams({
        //     organizationId: localStorage.getItem('organizationId'),
        //     token: localStorage.getItem('token'),
        // });
        dispatch(
            getEmpLeaveHistory({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
                date: toAWSDateFormat(new Date()),
            }),
        );
        dispatch(
            getLeaveTypeData({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(updateProfileImage({ image: localStorage.getItem('profile') }));
        dispatch(
            updateProfileName({ firstName: localStorage.getItem('fname'), lastName: localStorage.getItem('lname') }),
        );
        dispatch(
            selfProfileData({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            selfEmployeeTeams({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
            }),
        );
        // dispatch(
        //     getReduxTeams({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        // getOrganizationTeamsSubscription({
        //     organizationId: localStorage.getItem('organizationId'),
        //     token: localStorage.getItem('token'),
        // });
        // getSubscription_Subscription(
        //     localStorage.getItem('organizationId') || '',
        //     localStorage.getItem('token') || '',
        //     updateSubscriptionExpired,
        //     updateWarningDate,
        //     updateHardExpireDate,
        // );
        // getOrganizationEmployeesSubscription(
        //     localStorage.getItem('organizationId') || '',
        //     localStorage.getItem('token') || '',
        // );
        // getOrganizationApproversSubscription(
        //     localStorage.getItem('organizationId') || '',
        //     localStorage.getItem('token') || '',
        // );
        // dispatch(
        //     getTimeOffPolicy({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        dispatch(
            selfEmployeeTotalHours({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
            }),
        );
        // fetchPendingLeavesSubscription(localStorage.getItem('organizationId'), localStorage.getItem('token'));
        // getEmployeeSubscription(
        //     localStorage.getItem('organizationId'),
        //     localStorage.getItem('employeeid'),
        //     localStorage.getItem('token'),
        // );
        // fetchApproverUpcomingLeavesSubscription(
        //     localStorage.getItem('organizationId'),
        //     localStorage.getItem('token'),
        //     localStorage.getItem('employeeid'),
        // );
        // fetchTodaysLeavesSubscription(
        //     localStorage.getItem('organizationId'),
        //     localStorage.getItem('token'),
        //     toAWSDateFormat(new Date()),
        // );
        // fetchUpcomingLeavesSubscription(
        //     localStorage.getItem('organizationId'),
        //     localStorage.getItem('token'),
        //     toAWSDateFormat(new Date()),
        // );
        // fetchEmployeeLeavesSubscription(
        //     localStorage.getItem('organizationId'),
        //     localStorage.getItem('token'),
        //     localStorage.getItem('employeeid'),
        // );
        dispatch(
            selfOrgLeaveTypes({
                organizationId: localStorage.getItem('organizationId'),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            selfTimeOffHistory({
                organizationId: localStorage.getItem('organizationId'),
                employeeId: localStorage.getItem('employeeid'),
                date: toAWSDateFormat(new Date()),
                token: localStorage.getItem('token'),
            }),
        );
        dispatch(
            getMyOrganizations({
                employeeId: localStorage.getItem('employeeid'),
                token: localStorage.getItem('token'),
            }),
        );
        // dispatch(
        //     getStandups({
        //         standupTempId: localStorage.getItem('standupTempId'),
        //         token: localStorage.getItem('token'),
        //     }),
        // );
        // fetchPendingActionsSubscription(localStorage.getItem('organizationId'), localStorage.getItem('token'));
        // dispatch(
        //     getPolls({
        //         organizationId: localStorage.getItem('organizationId'),
        //         token: localStorage.getItem('token'),
        //         employeeId: localStorage.getItem('employeeid'),
        //         isAdmin: localStorage.getItem('isAdmin') === 'false' ? false : true,
        //     }),
        // );
        fetchPeople(
            dispatch,
            localStorage.getItem('organizationId'),
            localStorage.getItem('employeeid'),
            localStorage.getItem('token'),
        );
    };

    const login = async (props: Props) => {
        setTenantId(props.tenantId);
        setUsername(props.username);
        setFname(props.fname);
        setEmployeeId(props.EmployeeId);
        setLname(props.lname);
        setEmail(props.email);
        setIsadmin(props.isAdmin);
        setIsSuperadmin(props.isSuperAdmin);
        setMainadmin(props.isAdmin);
        setIsapprover(props.isApprover);
        setIslogedin(props.isLogedIn);
        setProfile(props.profile);
        setToken(props.token);
        setRefreshToken(props.refreshToken);
        setJoining(props.joining);
        setUuid(props.uuid);
        setStatusType(props.statustype);
        setEmail(props.email);
        setIsGoogle(props.isGoogle);
        setSubscriptionExpired(props.subscriptionExpired);
        setWarningDate(props.warningDate);
        setHardExpireDate(props.hardExpireDate);
        setSubscriptionId(props.subscriptionId);
        setStandupTempId(props.standupTempId);
        settenantTableId(props.tenantTableId);
        // fetchAllData();

        localStorage.setItem('isGoogle', props.isGoogle ? 'true' : 'false');
        localStorage.setItem('username', `${props.username}`);
        localStorage.setItem('isAdmin', `${props.isAdmin}`);
        localStorage.setItem('isSuperAdmin', `${props.isSuperAdmin}`);
        localStorage.setItem('mainAdmin', `${props.isAdmin}`);
        localStorage.setItem('isApprover', `${props.isApprover}`);
        localStorage.setItem('isLogedIn', `${props.isLogedIn}`);
        localStorage.setItem('profile', `${props.profile}`);
        localStorage.setItem('token', `${props.token}`);
        localStorage.setItem('refreshToken', `${props.refreshToken}`);
        localStorage.setItem('uuid', `${props.uuid}`);
        localStorage.setItem('fname', `${props.fname}`);
        localStorage.setItem('lname', `${props.lname}`);
        localStorage.setItem('email', `${props.email}`);
        localStorage.setItem('joining', `${props.joining}`);
        localStorage.setItem('employeeid', `${props.EmployeeId}`);
        localStorage.setItem('statustype', `${props.statustype}`);
        localStorage.setItem('email', `${props.email}`);
        localStorage.setItem('subscriptionExpired', `${props.subscriptionExpired}`);
        localStorage.setItem('warningDate', `${props.warningDate}`);
        localStorage.setItem('hardExpireDate', `${props.hardExpireDate}`);
        localStorage.setItem('standupTempId', `${props.standupTempId}`);
        localStorage.setItem('tenantId', `${props.tenantId}`);
        localStorage.setItem('tenantTableId', `${props.tenantTableId}`);
    };

    const logout = async (err?: any) => {
        localStorage.removeItem('username');
        localStorage.removeItem('isAdmin');
        localStorage.removeItem('isSuperAdmin');
        localStorage.removeItem('mainAdmin');
        localStorage.removeItem('isApprover');
        localStorage.removeItem('isLogedIn');
        localStorage.removeItem('profile');
        localStorage.removeItem('token');
        localStorage.removeItem('uuid');
        localStorage.removeItem('subscriptionId');
        localStorage.removeItem('fname');
        localStorage.removeItem('lname');
        localStorage.removeItem('email');
        localStorage.removeItem('joining');
        localStorage.removeItem('password');
        localStorage.removeItem('employeeid');
        localStorage.removeItem('organizationId');
        localStorage.removeItem('organizationName');
        localStorage.removeItem('tenantName');
        localStorage.removeItem('teamId');
        localStorage.removeItem('teamName');
        localStorage.removeItem('userProfile');
        localStorage.removeItem('profile');
        localStorage.removeItem('isGoogle');
        localStorage.removeItem('dateFormat');
        localStorage.removeItem('webhookUrl');
        localStorage.removeItem('holiday');
        localStorage.removeItem('teams');
        localStorage.removeItem('people');
        localStorage.removeItem('ltypes');
        localStorage.removeItem('ladmin');
        localStorage.removeItem('lapprover');
        localStorage.removeItem('empteams');
        localStorage.removeItem('empleavecount');
        localStorage.removeItem('empleaves');
        localStorage.removeItem('expires');
        localStorage.removeItem('statustype');
        localStorage.removeItem('email');
        localStorage.removeItem('isGoogle');
        localStorage.removeItem('userid');
        localStorage.removeItem('SettingTabVal');
        localStorage.removeItem('settings');
        localStorage.removeItem('organizationLogo');
        localStorage.removeItem('approverCalledAs');
        localStorage.removeItem('employeeCalledAs');
        localStorage.removeItem('teamsCalledAs');
        localStorage.removeItem('themeToggle');
        localStorage.removeItem('workingHours');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('subscriptionExpired');
        localStorage.removeItem('warningDate');
        localStorage.removeItem('hardExpireDate');
        localStorage.removeItem('invoice');
        localStorage.removeItem('slackChannelId');
        localStorage.removeItem('slackWorkspace');
        localStorage.removeItem('slackUserId');
        localStorage.removeItem('slackUrl');
        localStorage.removeItem('startDate');
        localStorage.removeItem('endDate');
        localStorage.removeItem('category');
        localStorage.removeItem('leaveType');
        localStorage.removeItem('startTime');
        localStorage.removeItem('endTime');
        localStorage.removeItem('oneday');
        localStorage.removeItem('reason');
        localStorage.removeItem('customReason');
        localStorage.removeItem('standupTempId');
        localStorage.removeItem('currentPoll');
        localStorage.removeItem('location');
        localStorage.removeItem('tenantId');
        // dispatch(removeCalendarLeave());

        setThemeToggle(false);
        setUsername('');
        setPassword('');
        setUserId('');
        setEmail('');
        setIsadmin(false);
        setIsSuperadmin(false);
        setMainadmin(false);
        setIsapprover(false);
        setIslogedin(false);
        setToken('');
        setRefreshToken('');
        setIsGoogle(false);
        setUuid('');
        setFname('');
        setLname('');
        setStatusType('');
        setJoining('');
        setEmployeeId('');
        setEmail('');
        setOrganizationId('');
        setSettingsId('');
        settenantTableId('');
        setOrganizationName('');
        setTenantName('');
        setTeamId('');
        setTeamName('');
        setUserProfile('');
        setProfile('');
        setIsGoogle(false);
        setDateFormat('');
        setWebhookUrl('');
        setExpires(false);
        setEmail('');
        // setOrganizationLogo('');
        setSettings(false);
        setApproverCalledAs('');
        setEmployeeCalledAs('');
        setTeamsCalledAs('');
        setFeatures({});
        setWorkingHours(0);
        setSubscriptionExpired('');
        setWarningDate('');
        setHardExpireDate('');
        setStandupTempId('');
        setTenantId('');
        setCurrentPoll({});
        setLocation({
            loaded: false,
            country: '',
            code: '',
            timezone: '',
        });
        history.push('/login');
    };

    // const updateRefreshToken = async () => {
    //     try {
    //         const cognitoUser = await Auth.currentAuthenticatedUser();
    //         const currentSession: any = await Auth.currentSession();
    //         cognitoUser.refreshSession(currentSession.refreshToken, (err: unknown, session: any) => {
    //             const { idToken, refreshToken } = session;
    //             addRefreshToken(refreshToken?.token, idToken?.jwtToken);
    //         });
    //         setTimeout(() => setLoading(false), 1000);
    //     } catch (e) {
    //         return e;
    //     }
    // };
    const updateRefreshToken = async () => {
        try {
            const res: any = await refreshSlackLoginToken(localStorage.getItem('refreshToken'));
            if (res.refresh_token === undefined || res.token === undefined) {
                Auth.signOut();
                logout();
                setTimeout(() => {
                    history.push('/login');
                }, 2000);
            } else {
                addRefreshToken(res.refresh_token, res.token);
            }
        } catch (e) {
            Auth.signOut();
            logout();
            setTimeout(() => {
                history.push('/login');
            }, 2000);
        }
    };

    // const notifGetToken = async () => {
    //     try {
    //         const empId = localStorage.getItem('employeeid');
    //         const empToken = localStorage.getItem('token');
    //         if (Notification.permission === 'default' || Notification.permission === 'denied') {
    //             Notification.requestPermission().then(async function () {
    //                 if (Notification.permission === 'granted') {
    //                     deviceToken = await messaging?.getToken();
    //                     const obj = {
    //                         empId: empId,
    //                         deviceToken: deviceToken,
    //                         token: empToken,
    //                     };
    //                     await saveDeviceToken(obj);
    //                 } else {
    //                     const obj = {
    //                         empId: empId,
    //                         deviceToken: null,
    //                         token: empToken,
    //                     };
    //                     await saveDeviceToken(obj);
    //                 }
    //             });
    //         } else if (Notification.permission === 'granted') {
    //             deviceToken = await messaging?.getToken();
    //             const obj = {
    //                 empId: empId,
    //                 deviceToken: deviceToken,
    //                 token: empToken,
    //             };
    //             await saveDeviceToken(obj);
    //         }
    //     } catch (e) {
    //         return e;
    //     }
    // };

    useEffect(() => {
        let isMounted = true;
        async function getAll() {
            if (localStorage.getItem('token') !== null) {
                const t: any = jwt_decode(localStorage.getItem('token') || '');
                if (new Date(t.exp * 1000).getTime() < new Date().getTime()) {
                    setLoading(true);
                    updateRefreshToken();
                } else {
                    fetchAllData();
                    // notifGetToken();
                    // fetchLeaves(dispatch, localStorage.getItem('organizationId'), localStorage.getItem('token'));
                    setTenantId(localStorage.getItem('tenantId'));
                    setUsername(localStorage.getItem('username'));
                    setPassword(localStorage.getItem('password'));
                    setEmail(localStorage.getItem('email'));
                    setIsadmin(localStorage.getItem('isAdmin') === 'true' ? true : false);
                    setIsSuperadmin(localStorage.getItem('isSuperAdmin') === 'true' ? true : false);
                    setMainadmin(localStorage.getItem('mainAdmin') === 'true' ? true : false);
                    setIsapprover(localStorage.getItem('isApprover') === 'true' ? true : false);
                    setIslogedin(localStorage.getItem('isLogedIn') === 'true' ? true : false);
                    setProfile(localStorage.getItem('profile'));
                    setToken(localStorage.getItem('token'));
                    setRefreshToken(localStorage.getItem('refreshToken'));
                    setUuid(localStorage.getItem('uuid'));
                    setStatusType(localStorage.getItem('statustype'));
                    setFname(localStorage.getItem('fname'));
                    setLname(localStorage.getItem('lname'));
                    setJoining(localStorage.getItem('joining'));
                    setEmployeeId(localStorage.getItem('employeeid'));
                    setOrganizationId(localStorage.getItem('organizationId'));
                    setSettingsId(localStorage.getItem('settingsId'));
                    settenantTableId(localStorage.getItem('tenantTableId'));
                    setOrganizationName(localStorage.getItem('organizationName'));
                    setTenantName(localStorage.getItem('tenantName'));
                    setTeamId(localStorage.getItem('teamId'));
                    setTeamName(localStorage.getItem('teamName'));
                    setUserProfile(localStorage.getItem('userProfile'));
                    setProfile(localStorage.getItem('profile'));
                    // fetchSettings(dispatch, localStorage.getItem('organizationId'), localStorage.getItem('token'));
                    setLoading(false);
                    setIsGoogle(localStorage.getItem('isGoogle') === 'true' ? true : false);
                    setDateFormat(localStorage.getItem('dateFormat'));
                    setWebhookUrl(localStorage.getItem('webhookUrl'));
                    setExpires(localStorage.getItem('expires') === 'true' ? true : false);
                    setEmail(localStorage.getItem('email'));
                    setOrganizationLogo(localStorage.getItem('organizationLogo'));
                    setSettings(localStorage.getItem('settings') === 'true' ? true : false);
                    setApproverCalledAs(localStorage.getItem('approverCalledAs'));
                    setEmployeeCalledAs(localStorage.getItem('employeeCalledAs'));
                    setTeamsCalledAs(localStorage.getItem('teamsCalledAs'));
                    setWorkingHours(parseInt(localStorage.getItem('workingHours') || '0'));
                    setThemeToggle(localStorage.getItem('themeToggle') === 'true' ? true : false);
                    setSubscriptionExpired(localStorage.getItem('subscriptionExpired'));
                    setWarningDate(localStorage.getItem('warningDate'));
                    setHardExpireDate(localStorage.getItem('hardExpireDate'));
                    setPollResult(localStorage.getItem('pollResult') === 'true' ? true : false);
                    setStandupTempId(localStorage.getItem('standupTempId') || '');
                    // console.log(localStorage.getItem('currentPoll'));
                    setCurrentPoll(
                        localStorage.getItem('currentPoll') !== null
                            ? JSON.parse(localStorage.getItem('currentPoll') || '')
                            : {},
                    );
                    setCountry(
                        localStorage.getItem('country') ? JSON.parse(localStorage.getItem('country') || '') : country,
                    );
                    const location =
                        localStorage.getItem('location') !== null
                            ? JSON.parse(localStorage.getItem('location') || '')
                            : {};
                    if (location.country !== '' || location.country !== null) {
                        setLocation({
                            loaded: true,
                            country: location?.country,
                            code: location?.code,
                            timezone: location?.timezone,
                        });
                    }
                    flagsmith.init({
                        environmentID: 'h8yUjPrc2fMDPR4PPGrmJ4',
                        cacheFlags: true,
                        enableAnalytics: true,
                        onChange: (oldFlags, params) => {
                            setFeatures({
                                isApproverCalledAs: flagsmith.hasFeature('approvercalledas'),
                                isPolls: flagsmith.hasFeature('ispolls'),
                                isSubscription: flagsmith.hasFeature('issubscription'),
                                employeeProfilePolls: flagsmith.hasFeature('employeeprofilepolls'),
                                employeeProfileStandup: flagsmith.hasFeature('employeeprofilestandup'),
                                employeeProfileShoutOut: flagsmith.hasFeature('employeeprofileshoutout'),
                            });
                        },
                    });
                }
            } else {
                setLoading(false);
            }
        }
        if (isMounted) {
            getAll();
        }
        return () => {
            isMounted = false;
        };
    }, []);
    if (loading) {
        return <LoaderPage />;
    } else {
        return (
            <Context.Provider
                value={{
                    isAdmin: isAdmin,
                    isSuperAdmin: isSuperAdmin,
                    mainAdmin: mainAdmin,
                    username: username,
                    email: email,
                    userId: userId,
                    EmployeeId: employeeId,
                    password: password,
                    fname: fname,
                    lname: lname,
                    isLogedIn: isLogedIn,
                    profile: profile,
                    token: token,
                    refreshToken: refreshToken,
                    uuid: uuid,
                    joining: joining,
                    isApprover: isApprover,
                    statustype: statustype,
                    isGoogle: isGoogle,
                    organizationId: organizationId,
                    settingsId: settingsId,
                    tenantTableId: tenantTableId,
                    organizationName: organizationName,
                    tenantName: tenantName,
                    teamId: teamId,
                    tenantId: tenantId,
                    teamName: teamName,
                    userProfile: userProfile,
                    dateFormat: dateFormat,
                    webhookUrl: webhookUrl,
                    loading: loading,
                    expires: expires,
                    organizationLogo: organizationLogo,
                    settings: settings,
                    approverCalledAs: approverCalledAs,
                    employeeCalledAs: employeeCalledAs,
                    teamsCalledAs: teamsCalledAs,
                    workingHours: workingHours,
                    features: features,
                    themeToggle: themeToggle,
                    teamsPage: teamsPage,
                    initialSignIn: initialSignIn,
                    pollResult: pollResult,
                    standupTempId: standupTempId,
                    currentPoll: currentPoll,
                    location: location,
                    country: country,
                    firstSignIn: firstSignIn,
                    handleTeamsPage: handleTeamsPage,
                    sessionExpires: sessionExpires,
                    registerUser: registerUser,
                    updateUsername: updateUsername,
                    updateName: updateName,
                    addEmail: addEmail,
                    addTenantId: addTenantId,
                    addOrganizationId: addOrganizationId,
                    addSettingsId: addSettingsId,
                    addTenantTableId: addTenantTableId,
                    addTeamId: addTeamId,
                    updateTeam: updateTeam,
                    login: login,
                    logout: logout,
                    addOrganizationName: addOrganizationName,
                    addTenantName: addTenantName,
                    addUserProfile: addUserProfile,
                    updateUserProfile: updateUserProfile,
                    updateGoogle: updateGoogle,
                    updateDateFormat: updateDateFormat,
                    addWebhookUrl: addWebhookUrl,
                    addUserId: addUserId,
                    updateIsAdmin: updateIsAdmin,
                    toggleTheme: toggleTheme,
                    addOrganizationLogo: addOrganizationLogo,
                    addSettings: addSettings,
                    addOtherSettings: addOtherSettings,
                    fetchAllData: fetchAllData,
                    addRefreshToken: addRefreshToken,
                    subscriptionExpired: subscriptionExpired,
                    warningDate: warningDate,
                    hardExpireDate: hardExpireDate,
                    updateSubscriptionExpired: updateSubscriptionExpired,
                    updateWarningDate: updateWarningDate,
                    updateHardExpireDate: updateHardExpireDate,
                    togglePopup: togglePopup,
                    subscriptionId: subscriptionId,
                    updateSubscriptionId: updateSubscriptionId,
                    handleProfile: handleProfile,
                    updatePollResult: updatePollResult,
                    addStandupTempId: addStandupTempId,
                    addCurrentPoll: addCurrentPoll,
                    addLocation: addLocation,
                    addCountry: addCountry,
                }}
            >
                <MuiThemeProvider theme={themeToggle ? darkTheme : lightTheme}>
                    <Modal
                        open={
                            (state.userStatus?.role?.toLowerCase() === 'admin' &&
                                localStorage.getItem('isAdmin') === 'false' &&
                                localStorage.getItem('mainAdmin') === 'false' &&
                                localStorage.getItem('isLogedIn') === 'true') ||
                            (state.userStatus?.role?.toLowerCase() === 'employee' &&
                                localStorage.getItem('isAdmin') === 'true' &&
                                localStorage.getItem('isLogedIn') === 'true') ||
                            (state.userStatus?.role?.toLowerCase() === 'approver' &&
                                localStorage.getItem('isApprover') === 'false' &&
                                localStorage.getItem('isLogedIn') === 'true') ||
                            (state.userStatus?.role?.toLowerCase() === 'employee' &&
                                localStorage.getItem('isApprover') === 'true' &&
                                localStorage.getItem('isLogedIn') === 'true')
                        }
                        onClose={() => {
                            return null;
                        }}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <div className={classes.roleModalContent}>
                            {' '}
                            <h4 className={classes.modalTitle}>Role Updated</h4>
                            <p className={classes.modalContent}>
                                Your role has been now changed admin/approver of{' '}
                                {localStorage.getItem('organizationName')}. Login to update your role
                            </p>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => logout()}
                                className={classes.modalButton}
                            >
                                Login
                            </Button>
                        </div>
                    </Modal>
                    <PopUp open={alert} setOpen={setAlert} message={popup.alertMessage} severity={popup.severity} />
                    {props.children}
                </MuiThemeProvider>
            </Context.Provider>
        );
    }
};
