import React, { useContext, useState } from 'react';
import useStyles from './tableStyles';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    Button,
    Hidden,
    Paper,
    Avatar,
    Grid,
    Card,
    Tooltip,
    TextField,
    InputAdornment,
    useTheme,
    InputBase,
} from '@material-ui/core';
import Countdown from 'react-countdown';
// import { SearchBar } from '../DashboardPage/DashboardPart';
import AuthContext from 'context/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
// import MyModal from '../Modal/Modal';
import { updateActiveTab } from 'components/Redux/activeTab';
import { deletePoll, pollBot } from 'helpers/hasuraapi2';
import { DeleteModal } from './modal';
import upcomingTimeoff from '../../Assets/images/upcomingTimeoff.png';
// import { removePoll } from 'components/Redux/pollsReducer';
import Skeleton from '@material-ui/lab/Skeleton';
// import { Icon } from '@iconify/react';
import { ToStringFormat } from '../../helpers/date';
import { RiDeleteBinLine } from 'react-icons/ri';
import PollForm from './pollForm';
// import { useHistory } from 'react-router-dom';
import { getFullName } from 'helpers/getInitials';
// import ReplyPoll from './employeePoll';
import { PopUp } from '../Modal/Modal';
import ErrorPage from 'components/Error/ErrorPage';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import Response from 'components/Polls/response';
import PollResult from 'components/Polls/pollresults';
import { useFlagsmith } from 'flagsmith-react';
import { isoToLocal } from 'helpers/timezone';
// import moment from 'moment';

const SearchBarCompMobile = (Props: { search: any; setSearch: any }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <>
            <Hidden lgUp>
                {/* <div style={{ paddingTop: 10 }}>
                    <div className={classes.searchbarRounded}>
                        <span
                            style={{ marginRight: 10, fontSize: 15, color: '#888888' }}
                            className="iconify"
                            data-icon="octicon:search-16"
                        ></span>
                        <input
                            type="text"
                            placeholder="Search"
                            value={Props.search}
                            onChange={(e: any) => Props.setSearch(e.target.value)}
                            className={classes.searchBarInput}
                        />
                    </div>
                </div> */}
                <div className={classes.searchbarDiv}>
                    <span
                        style={{
                            marginRight: 10,
                            fontSize: 20,
                            fill: theme.palette.text.primary,
                        }}
                        className="iconify"
                        data-icon="octicon:search-16"
                    ></span>
                    <InputBase
                        id="search-input"
                        className={classes.searchInput}
                        defaultValue=""
                        value={Props.search}
                        placeholder="Search"
                        onChange={(e: any) => Props.setSearch(e.target.value)}
                    />
                </div>
            </Hidden>
        </>
    );
};

interface SearbarProps {
    search: any;
    setSearch: any;
    setStep: any;
    hasFeature: any;
}

export const SearchBarComp = (Props: SearbarProps) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <>
            <div className={classes.src}>
                <TextField
                    id="searchname"
                    placeholder="Search"
                    variant="outlined"
                    size="small"
                    value={Props.search}
                    onChange={(e: any) => Props.setSearch(e.target.value)}
                    className={classes.searchBorder}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" className={classes.searchIcon}>
                                <span
                                    style={{
                                        marginRight: 10,
                                        fontSize: 20,
                                        fill: theme.palette.primary.contrastText,
                                    }}
                                    className="iconify"
                                    data-icon="octicon:search-16"
                                ></span>
                            </InputAdornment>
                        ),
                        classes: {
                            root: classes.searchInput,
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: theme.palette.primary.contrastText,
                        },
                    }}
                />
                {/* {Props.hasFeature('create-poll') && ( */}
                <Button className={classes.btn} variant="contained" color="primary" onClick={() => Props.setStep(1)}>
                    Create New Poll
                </Button>
                {/* )} */}
            </div>
        </>
    );
};

interface TableMobileProps {
    pollsData?: any;
    search?: any;
    setStep?: any;
    handleClick?: any;
    mycontext?: any;
    renderer?: any;
    handleModal?: any;
    hasFeature?: any;
}

export const TableMobile = (Props: TableMobileProps) => {
    const classes = useStyles();
    return (
        <>
            <Hidden lgUp>
                {Props.pollsData.polls === undefined ? (
                    <>
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: 5, marginBottom: 40 }}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            {[1].map((i: number) => (
                                <Grid item key={i}>
                                    <Card elevation={0} className={classes.car}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Skeleton height={20} />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="column" style={{ paddingTop: 8 }}>
                                            <Grid item xs={12}>
                                                <span className={classes.item1}>Question</span>
                                                <Skeleton height={20} width={100} />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6} sm={8}>
                                                <span className={classes.item1}>Created On</span>
                                                <Skeleton height={20} width={100} />
                                            </Grid>
                                            <Grid item xs={6} sm={4}>
                                                <span className={classes.item1}>Time Remaining</span>
                                                <Skeleton height={20} width={100} />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                ) : (
                    <>
                        {Props.pollsData.polls?.length === 0 ? (
                            <>
                                <div id="noResultFound" className={classes.fontsty} style={{ borderBottom: 'none' }}>
                                    <img src={upcomingTimeoff} alt="No poll found" className={classes.notfoundImage} />
                                    <div className={classes.notfoundText}>No poll found</div>
                                </div>
                            </>
                        ) : (
                            <>
                                {Props.pollsData?.polls?.filter((row: any) =>
                                    row?.question?.toLowerCase().includes(Props.search?.toLowerCase()),
                                ).length === 0 ? (
                                    <>
                                        <div
                                            id="noResultFound"
                                            className={classes.fontsty}
                                            style={{ borderBottom: 'none' }}
                                        >
                                            <img
                                                src={upcomingTimeoff}
                                                alt="No poll found"
                                                className={classes.notfoundImage}
                                            />
                                            <div className={classes.notfoundText}>No poll found</div>
                                        </div>
                                    </>
                                ) : (
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ marginTop: 5, marginBottom: 40 }}
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        {Props.pollsData.polls
                                            ?.filter((row: any) =>
                                                row?.question?.toLowerCase().includes(Props.search?.toLowerCase()),
                                            )
                                            .map((d: any, i: number) => (
                                                <Grid item key={i}>
                                                    <Card elevation={0} className={classes.car}>
                                                        <Grid container style={{ position: 'relative' }}>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingTop: 10,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                                xs={10}
                                                                md={10}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    onClick={() => Props.handleClick(d)}
                                                                >
                                                                    <div>
                                                                        {d.Employee.profileImage !== null ? (
                                                                            <Avatar
                                                                                src={d.Employee.profileImage}
                                                                                alt={d.Employee.firstName}
                                                                                className={classes.avatarimage}
                                                                            />
                                                                        ) : (
                                                                            <Avatar className={classes.avatarimage}>
                                                                                {getFullName(
                                                                                    d.Employee.firstName +
                                                                                        ' ' +
                                                                                        d.Employee.lastName,
                                                                                )}
                                                                            </Avatar>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        style={{ padding: 4 }}
                                                                        className={classes.item}
                                                                    >
                                                                        {d.Employee.firstName} {d.Employee.lastName}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            {d.createdBy === Props.mycontext.EmployeeId ? (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        bottom: 6,
                                                                        right: 20,
                                                                    }}
                                                                >
                                                                    <RiDeleteBinLine
                                                                        data-testid="delete"
                                                                        onClick={() => Props.handleModal(d)}
                                                                        // icon="fluent:delete-24-filled"
                                                                        className={classes.icon}
                                                                    />
                                                                </Grid>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        bottom: 6,
                                                                        right: 20,
                                                                    }}
                                                                >
                                                                    <RiDeleteBinLine
                                                                        data-testid="delete"
                                                                        style={{ opacity: 0.5 }}
                                                                        className={classes.icon}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            style={{ paddingTop: 8 }}
                                                            onClick={() => Props.handleClick(d)}
                                                        >
                                                            <Grid item xs={12}>
                                                                <span className={classes.item1}>Question</span>
                                                                <Tooltip
                                                                    title={d.question}
                                                                    placement="top-start"
                                                                    classes={{ tooltip: classes.tooltip }}
                                                                    arrow
                                                                >
                                                                    <p
                                                                        style={{
                                                                            maxWidth: '100%',
                                                                            wordWrap: 'break-word',
                                                                        }}
                                                                        className={classes.item}
                                                                    >
                                                                        {d.question.length > 60
                                                                            ? `${d.question.substring(0, 60)}...`
                                                                            : d.question}
                                                                    </p>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container onClick={() => Props.handleClick(d)}>
                                                            <Grid item xs={6} sm={8}>
                                                                <span className={classes.item1}>Created On</span>
                                                                <p className={classes.item}>
                                                                    {ToStringFormat(
                                                                        isoToLocal(
                                                                            d?.pollEndsDateTime,
                                                                            Props.mycontext.country,
                                                                        )
                                                                            .toString()
                                                                            .substring(3, 15),
                                                                        Props.mycontext.dateFormat,
                                                                    )}
                                                                </p>
                                                            </Grid>
                                                            <Grid item xs={6} sm={4}>
                                                                <span className={classes.item1}>Time Remaining</span>
                                                                {d.pollEndTime === null ? (
                                                                    <span
                                                                        className="iconify"
                                                                        data-icon="bi:dash"
                                                                        style={{ width: 25, height: 25 }}
                                                                    ></span>
                                                                ) : (
                                                                    <div className={classes.item}>
                                                                        <Countdown
                                                                            daysInHours={true}
                                                                            date={d?.pollEndsDateTime}
                                                                            renderer={t => Props.renderer(t)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            ))}
                                    </Grid>
                                )}
                            </>
                        )}
                        {Props.hasFeature('create-poll') && (
                            <Button
                                className={classes.btn}
                                onClick={() => Props.setStep(1)}
                                variant="contained"
                                color="primary"
                            >
                                create new poll
                            </Button>
                        )}
                    </>
                )}
            </Hidden>
        </>
    );
};

interface TableProps {
    pollsData?: any;
    search?: any;
    handleClick?: any;
    mycontext?: any;
    renderer?: any;
    handleModal?: any;
}

export const TableComponent = (Props: TableProps) => {
    const classes = useStyles();

    // const time: any = Props?.pollsData?.polls
    // console.log(time[0]);
    return (
        <>
            <TableContainer component="div" className={classes.tableBorder}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell} align="center">
                                #
                            </TableCell>
                            <TableCell className={classes.cell} align="left">
                                Question
                            </TableCell>
                            <TableCell className={classes.cell} align="left">
                                Created On
                            </TableCell>
                            <TableCell className={classes.cell} align="left">
                                Time Remaining
                            </TableCell>
                            <TableCell className={classes.cell} align="left">
                                Created By
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {Props.pollsData.polls === undefined ? (
                        <TableBody>
                            {[1, 2, 3, 4, 5].map((i: number) => (
                                <TableRow key={i}>
                                    <TableCell className={classes.cell1}>
                                        <Skeleton height={20} />
                                    </TableCell>
                                    <TableCell className={classes.cell1}>
                                        <Skeleton height={20} />
                                    </TableCell>
                                    <TableCell className={classes.cell1}>
                                        <Skeleton height={20} />
                                    </TableCell>
                                    <TableCell className={classes.cell1}>
                                        <Skeleton height={20} />
                                    </TableCell>
                                    <TableCell className={classes.cell1}>
                                        <Skeleton height={20} />
                                    </TableCell>
                                    <TableCell className={classes.cell1}>
                                        <Skeleton height={20} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <>
                            {Props.pollsData.polls?.length === 0 ? (
                                <>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                id="noResultFound"
                                                colSpan={6}
                                                className={classes.fontsty}
                                                style={{ borderBottom: 'none' }}
                                            >
                                                <img
                                                    src={upcomingTimeoff}
                                                    alt="No Teams found"
                                                    className={classes.notfoundImage}
                                                />
                                                <div className={classes.notfoundText}>No poll found</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </>
                            ) : (
                                <>
                                    {Props.pollsData.polls?.filter((row: any) =>
                                        row?.question?.toLowerCase().includes(Props.search?.toLowerCase()),
                                    ).length === 0 ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    id="noResultFound"
                                                    colSpan={6}
                                                    className={classes.fontsty}
                                                    style={{ borderBottom: 'none' }}
                                                >
                                                    <img
                                                        src={upcomingTimeoff}
                                                        alt="No poll found"
                                                        className={classes.notfoundImage}
                                                    />
                                                    <div className={classes.notfoundText}>No poll found</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {Props.pollsData.polls
                                                ?.filter((row: any) =>
                                                    row?.question?.toLowerCase().includes(Props.search?.toLowerCase()),
                                                )
                                                .map((d: any, i: number) => (
                                                    <TableRow className={classes.row} key={i}>
                                                        <TableCell
                                                            onClick={() => Props.handleClick(d)}
                                                            className={classes.cell1}
                                                            align="center"
                                                        >
                                                            {i + 1}
                                                        </TableCell>
                                                        <Tooltip
                                                            title={d.question}
                                                            placement="top"
                                                            classes={{ tooltip: classes.tooltip }}
                                                            arrow
                                                        >
                                                            <TableCell
                                                                onClick={() => Props.handleClick(d)}
                                                                className={classes.cell1}
                                                                align="left"
                                                            >
                                                                {d.question.length > 50
                                                                    ? `${d.question.substring(0, 50)}...`
                                                                    : d.question}
                                                            </TableCell>
                                                        </Tooltip>
                                                        <TableCell
                                                            onClick={() => Props.handleClick(d)}
                                                            className={classes.cell1}
                                                            align="left"
                                                        >
                                                            {/* {ToStringFormat(d?.pollEndsDateTime, Props.mycontext.dateFormat)} */}
                                                            {/* {isoToLocal(
                                                                getCurrentCountryDate(d?.pollEndsDateTime, mycontext.country.zoneName).date
                                                            )} */}
                                                            {ToStringFormat(
                                                                isoToLocal(d?.pollEndsDateTime, Props.mycontext.country)
                                                                    .toString()
                                                                    .substring(3, 15),
                                                                Props.mycontext.dateFormat,
                                                            )}
                                                        </TableCell>

                                                        <TableCell
                                                            onClick={() => Props.handleClick(d)}
                                                            className={classes.cell1}
                                                            align="left"
                                                        >
                                                            <Countdown
                                                                daysInHours={true}
                                                                date={d?.pollEndsDateTime}
                                                                renderer={t => Props.renderer(t)}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={() => Props.handleClick(d)}
                                                            className={classes.cell2}
                                                            align="center"
                                                        >
                                                            <Grid container>
                                                                <Grid item>
                                                                    {d.Employee.profileImage !== null ? (
                                                                        <Avatar
                                                                            src={d.Employee.profileImage}
                                                                            alt={d.Employee.firstName}
                                                                            className={classes.avatarimage}
                                                                        />
                                                                    ) : (
                                                                        <Avatar className={classes.avatarimage}>
                                                                            {getFullName(
                                                                                d.Employee.firstName +
                                                                                    ' ' +
                                                                                    d.Employee.lastName,
                                                                            )}
                                                                        </Avatar>
                                                                    )}
                                                                </Grid>
                                                                <Grid item>
                                                                    <div
                                                                        style={{
                                                                            paddingLeft: 15,
                                                                            paddingTop: 5,
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        {d.Employee.firstName} {d.Employee.lastName}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        {d.createdBy === Props.mycontext.EmployeeId ? (
                                                            <TableCell className={classes.cell1} align="center">
                                                                <RiDeleteBinLine
                                                                    data-testid="delete"
                                                                    onClick={() => Props.handleModal(d)}
                                                                    className={classes.icon}
                                                                />
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell className={classes.cell1} align="center">
                                                                <RiDeleteBinLine
                                                                    data-testid="delete"
                                                                    style={{ opacity: 0.5 }}
                                                                    className={classes.icon}
                                                                />
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Table>
            </TableContainer>
        </>
    );
};

interface DataTableP {
    setStep?: any;
    step?: any;
}

export default function DataTable(props: DataTableP) {
    const classes = useStyles();
    const [search, setSearch] = React.useState('');
    const dispatch = useDispatch();
    const mycontext = useContext(AuthContext);
    // const history = useHistory();
    const [isDelete, setDelete] = useState(false);
    // const [step, setStep] = useState(0);
    // const [next, setNext] = useState(0);
    const pollsData = useSelector((state: any) => state.polls);
    const memberProfile = useSelector((state: any) => state.memberProfile);
    const subDetails = useSelector((state: any) => state.subscription);
    const [currentPoll, setCurrentPoll] = useState<any>();
    const [alert, setAlert] = React.useState(false);
    const [popup, setPopup] = React.useState({
        alertMessage: '',
        severity: '',
    });
    // const location: any = useLocation();

    //flagsmith
    const { identify, isIdentified, getTrait, setTrait, hasFeature } = useFlagsmith();
    const identifyUser = async (id: string, email: string) => {
        await identify(id);
        const hasEmail = !!getTrait('email');
        if (!hasEmail) {
            setTrait('email', email);
        }
    };
    if (!isIdentified) {
        identifyUser(mycontext.email, mycontext.email);
    }

    React.useEffect(() => {
        dispatch(updateActiveTab('/dashboard'));
    }, [dispatch]);

    const renderer = (props: any) => {
        if (props.completed) return <div>Expired</div>;
        if (props.hours === 0 && props.minutes === 0) return <div>{props.seconds} sec</div>;
        if (props.hours === 0)
            return (
                <div>
                    {props.minutes} mins {props.seconds} sec
                </div>
            );
        else {
            return (
                <div>
                    {props.hours}hrs {props.minutes}
                    mins
                </div>
            );
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const data: any = await deletePoll(id, mycontext.token);
            if ('polls' in memberProfile?.empTeams?.usermanagementdb_Team[0].isbotAdded) {
                const pollData = {
                    action: 'delete',
                    conversationId: currentPoll.pollMetaData.conversationId,
                    replyToId: currentPoll.replyToId,
                };
                await pollBot({
                    pollId: id,
                    pollData: pollData,
                });
            }
            if (data.error) {
                throw new Error('error');
            } else {
                setDelete(false);
            }
            setAlert(true);
            setPopup({
                alertMessage: 'Poll Deleted successfully!',
                severity: 'success',
            });
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };

    const handleModal = (obj: any) => {
        try {
            const pollSubscriptions = subDetails.subscription.usermanagementdb_Subscription.filter((sub: any) =>
                sub.planId.startsWith('pbtp'),
            );

            if (pollSubscriptions.length === 0) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }

            const license = subDetails.userSubscriptions.usermanagementdb_tenant_employee[0].pollSubscriptionId;

            if (!license) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team administrator to assign you a license",
                );
            }

            const activeSub = pollSubscriptions.some((sub: any) => {
                if (sub.subscriptionId === license) {
                    if (sub.status === 'Subscribed') {
                        return true;
                    } else if (sub.status === 'Suspended') {
                        throw new Error(
                            'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                        );
                    } else if (sub.status === 'Unsubscribed') {
                        throw new Error(
                            'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                        );
                    }
                }
                return false;
            });

            if (!activeSub) {
                throw new Error('Somthing went wrong, please try again.');
            }
            setCurrentPoll(obj);
            setDelete(true);
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };

    const handleClick = (d: any) => {
        if (new Date() > new Date(`${d.pollEndsDateTime}`)) {
            mycontext.updatePollResult(true);
        } else {
            mycontext.updatePollResult(false);
        }
        // history.push({
        //     pathname: `/polls/${d.pollId}`,
        //     state: {
        //         poll: d,
        //         backTo: 'polls',
        //     },
        // });
        mycontext.addCurrentPoll(d);

        props.setStep(2);
    };
    if (pollsData.status === 'failed') {
        return <ErrorPage title="Something went wrong!" />;
    }
    if (props.step === 1) {
        return <PollForm setStep={props.setStep} />;
    }

    if (props.step === 2) {
        return mycontext.isAdmin || mycontext.pollResult ? (
            <PollResult setStep={props.setStep} />
        ) : (
            <Response setStep={props.setStep} />
        );
    }

    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        mycontext.isAdmin ? (
            <SubscriptionHardExpired
                content="Looks like your subscription is expired, Please renew it !"
                showRenew={true}
            />
        ) : (
            <SubscriptionHardExpired
                content="Subscription expired, Please contact your admin to renew subscription"
                showRenew={false}
            />
        )
    ) : (
        <>
            <DeleteModal
                open={isDelete}
                isDelete={isDelete}
                setDelete={setDelete}
                handleDelete={handleDelete}
                currentPoll={currentPoll}
            />
            <PopUp open={alert} message={popup.alertMessage} severity={popup.severity} setOpen={setAlert} />
            <SearchBarCompMobile search={search} setSearch={setSearch} />

            <div className={classes.border}>
                <Hidden mdDown>
                    <Paper elevation={0} color="inherit" className={classes.paper}>
                        <div>
                            <SearchBarComp
                                search={search}
                                setSearch={setSearch}
                                setStep={props.setStep}
                                hasFeature={hasFeature}
                            />
                            <TableComponent
                                pollsData={pollsData}
                                search={search}
                                handleClick={handleClick}
                                mycontext={mycontext}
                                renderer={renderer}
                                handleModal={handleModal}
                            />
                        </div>
                    </Paper>
                </Hidden>
                <TableMobile
                    pollsData={pollsData}
                    search={search}
                    handleClick={handleClick}
                    mycontext={mycontext}
                    renderer={renderer}
                    handleModal={handleModal}
                    hasFeature={hasFeature}
                    setStep={props.setStep}
                />
            </div>
            <Hidden lgUp>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => props.setStep(1)}
                    className={classes.createPollMobileBtn}
                    data-testid="createPoll"
                >
                    Create New Poll
                </Button>
            </Hidden>
        </>
    );
}
