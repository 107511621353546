import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
    FormControl,
    FormLabel,
    Hidden,
    TextField,
    Box,
    Grid,
    Button,
    Paper,
    Avatar,
    Tooltip,
    Typography,
    MenuItem,
} from '@material-ui/core';
import myStyle from './TeamStyle';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles, Theme } from '@material-ui/core/styles';
// import { getEmpTeamSelectBox } from 'helpers/hasuraapi';
import AuthContext from '../../context/AuthContext';
import Autocomplete, { AutocompleteCloseReason } from '@mui/material/Autocomplete';
import getInitials from '../../helpers/getInitials';
import TeamsTable from './TeamsTable';
import { PopUp } from '../Modal/Modal';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { channelsofTeam, MembersofChannel } from '../Admin/graph';
import { ifTeamExists, getTeamEmployees, ifSlackTeamExists, fetchSlackUsers } from 'helpers/hasuraapi';
import getUuidByString from 'uuid-by-string';

interface Props {
    current?: any;
    setCurrent?: any;
    teamDetails?: any;
    setTeamDetails?: any;
    teamname?: any;
    setteamname?: any;
    selectedTeam?: any;
    setSelectedTeam?: any;
    teamNameValid?: any;
    teamId?: any;
    setAlertMessage?: any;
    setAlertm?: any;
    setSeverity?: any;
}

function Selectors(props: Props) {
    const classes = myStyle();
    const mycontext = useContext(AuthContext);
    const store: any = useSelector((store: any) => store.orgEmployees);
    const [action] = React.useState<any>(props.current);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<any>('');
    const [isLoading, setLoading] = useState<boolean>(true);
    // const [allTeams, setAllTeams] = React.useState<any>([]);
    const [currentApprovers, setCurrentApprovers] = React.useState<any>([]);
    const [currentMembers, setCurrentMembers] = React.useState<any>([]);
    const [approvers, setApprovers] = React.useState<any>([]);
    const [members, setMembers] = React.useState<any>([]);
    const [empCount] = React.useState<any>(0);
    const [newTeamName, setNewTeamName] = React.useState<any>(''); //props.teamname
    const [hasuraToken, setHasuraToken] = useState('');
    const [msTeams, setmsTeams] = React.useState<any>([]);
    const [channelUser, setChannelUser] = React.useState<any>([]);
    const [slackTeams, setSlackTeams] = useState<string[]>([]);
    const [fetched, setFetched] = useState<boolean>(false);
    const [slackTeamEmployees, setSlackTeamEmployees] = React.useState<any>({});
    const loginMethod = localStorage.getItem('loginMethod');
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const loginRequest = {
        scopes: ['User.ReadBasic.All'],
    };
    const loginrequest = {
        ...loginRequest,
        account: accounts[0],
    };

    const apList = new Set(approvers.map((d: any) => d.Employee.employeeId));
    const empList = {
        teamName: newTeamName,
        teamId: props.teamDetails !== null ? props.teamDetails.teamId : '',
        createdAt: props.teamDetails !== null ? props.teamDetails.createdAt : '',
        organizationId: props.teamDetails !== null ? props.teamDetails.organizationId : '',
        employees: [...approvers, ...members.filter((m: any) => !apList.has(m.employeeId))],
    };
    const [tableData, setTableData] = React.useState<any>(empList);

    const EmployeeDataSet = useCallback(() => {
        setTableData(empList);
        props.setTeamDetails(empList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empCount]);

    useEffect(() => {
        EmployeeDataSet();
    }, [EmployeeDataSet]);

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            // props.setteamname('');
            // setNewTeamName('');
            // const teamEmployeeData = await getEmpTeamSelectBox(mycontext.organizationId, mycontext.token);
            if (store.status === 'failed') {
                setError(true);
                setErrorMessage('Something went wrong!');
            } else if (store.status === 'success') {
                // setAllTeams(store.employees);
                if (props.current === 'create') {
                    // props.setteamname('');
                    // setNewTeamName('');
                }
                if (props.current === 'edit') {
                    if (props.teamDetails !== null) {
                        props.setteamname(props.teamDetails.teamName);
                        setNewTeamName(props.teamDetails.teamName);
                        props.setSelectedTeam(props.teamDetails.teamId);
                        if (props.teamDetails.team_employees.length > 0) {
                            const empList = {
                                teamId: props.teamDetails.teamId,
                                createdAt: props.teamDetails.createdAt,
                                organizationId: props.teamDetails.organizationId,
                                teamName: props.teamDetails.teamName,
                                employees: props.teamDetails.team_employees,
                            };
                            setTableData(empList);
                            props.setTeamDetails(empList);
                            if (props.teamDetails.team_employees.length > 0) {
                                const myApprovers: any = [];
                                const myMembers: any = [];
                                props.teamDetails.team_employees.map((td: any) => {
                                    // if (td.Employee.organization_employees[0].role !== 'admin') {
                                    //     if (td.isApprover) {
                                    //         myApprovers.push(td);
                                    //     } else {
                                    //         myMembers.push(td);
                                    //     }
                                    // }
                                    if (td.isApprover) {
                                        myApprovers.push(td);
                                    } else {
                                        myMembers.push(td);
                                    }
                                    return null;
                                });
                                setCurrentApprovers(myApprovers);
                                setCurrentMembers(myMembers);
                            }
                        }
                    }
                }
                setLoading(false);
            }
        };

        if (isMounted) {
            getData();
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.status]);

    const fetchChannels = async (accessToken: any) => {
        setHasuraToken(accessToken);
        const data = await channelsofTeam(accessToken, mycontext.organizationId);
        if (data.error) {
            setError(true);
            setErrorMessage('You don`t have necessary permissions to create team!');
        }
        // data.value.map(async (val: any) => {
        data.map(async (val: any) => {
            const count = await ifTeamExists(mycontext.organizationId, val.id, mycontext.token);
            if (count.aggregate.count === 0) {
                setmsTeams((current: any) => [...current, val]);
            }
        });
    };

    const fetchSlackChannels = async (accessToken: any) => {
        setHasuraToken(accessToken);
        const slackUsersData = await fetchSlackUsers(accessToken);
        setSlackTeamEmployees(slackUsersData.team_employees);
        if (Object.keys(slackUsersData.employees).length === 0) {
            setError(true);
            setErrorMessage('Please try again in sometime');
            setTimeout(() => props.setCurrent('teams'), 2000);
            return;
        } else {
            setFetched(true);
            for (const key in slackUsersData.teamIds) {
                if (slackUsersData.teamIds.hasOwnProperty(key)) {
                    const val = {
                        id: key,
                        name: slackUsersData.teamIds[key],
                    };
                    const count = await ifSlackTeamExists(mycontext.organizationId, key, mycontext.token);
                    if (count.aggregate.count === 0) {
                        setSlackTeams((current: any) => [...current, val]);
                    }
                }
            }
        }
    };
    const fetchEmps = async () => {
        const teamEmployees = await getTeamEmployees(props.teamDetails.teamId, mycontext.token);
        const userIDArray: any = [];
        await teamEmployees.map(async (val: any) => {
            await userIDArray.push(val.employeeId);
        });
        setChannelUser(userIDArray);
    };
    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            const decoded: any = jwt_decode(mycontext.token);
            if (decoded.isMicrosoft) {
                if (isAuthenticated) {
                    instance
                        .acquireTokenSilent(loginrequest)
                        .then(async (response: any) => {
                            if (props.current === 'create') {
                                await fetchChannels(response.accessToken);
                                setFetched(true);
                            } else {
                                await fetchEmps();
                            }
                        })
                        .catch((e: any) => {
                            setError(true);
                            setErrorMessage('You don`t have necessary permissions to create team!');
                        });
                } else {
                    instance
                        .acquireTokenPopup(loginrequest)
                        .then(async (response: any) => {
                            if (props.current === 'create') {
                                await fetchChannels(response.accessToken);
                                setFetched(true);
                            } else {
                                await fetchEmps();
                            }
                        })
                        .catch((e: any) => {
                            setError(true);
                            setErrorMessage('You don`t have necessary permissions to create team!');
                        });
                }
            } else {
                if (props.current === 'create') {
                    await fetchSlackChannels(decoded['accId']);
                } else {
                    await fetchEmps();
                }
            }
        };
        if (isMounted) {
            fetchData();
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUserData = async () => {
        const aps = currentApprovers.map((ap: any) => ({ ...ap, isApprover: true, matchedData: false }));
        const mems = currentMembers.map((m: any) => ({ ...m, isApprover: false, matchedData: false }));
        setApprovers(aps);
        setMembers(mems);

        const apList = new Set(aps.map((d: any) => d.Employee.employeeId));
        const empList = {
            teamName: newTeamName,
            teamId: props.teamDetails !== null ? props.teamDetails.teamId : '',
            createdAt: props.teamDetails !== null ? props.teamDetails.createdAt : '',
            organizationId: props.teamDetails !== null ? props.teamDetails.organizationId : '',
            employees: [...aps, ...mems.filter((m: any) => !apList.has(m.Employee.employeeId))],
        };
        setTableData(empList);
        props.setTeamDetails(empList);
    };

    const handleUserData1 = async () => {
        const members: any = [];
        if (props.current === 'edit') {
            await store.employees.map(async (member: any) => {
                if (channelUser.includes(member.employeeId)) {
                    members.push(member);
                }
            });

            setCurrentMembers(members);
        }

        const aps = currentApprovers.map((ap: any) => ({ ...ap, isApprover: true, matchedData: false }));
        const mems =
            props.current === 'edit'
                ? members.map((m: any) => ({ ...m, isApprover: false, matchedData: false }))
                : currentMembers.map((m: any) => ({ ...m, isApprover: false, matchedData: false }));
        setApprovers(aps);
        setMembers(mems);

        const apList = new Set(aps.map((d: any) => d.Employee.employeeId));
        const empList = {
            teamName: newTeamName,
            teamId: props.teamDetails !== null ? props.teamDetails.teamId : '',
            createdAt: props.teamDetails !== null ? props.teamDetails.createdAt : '',
            organizationId: props.teamDetails !== null ? props.teamDetails.organizationId : '',
            employees: [...aps, ...mems.filter((m: any) => !apList.has(m.Employee.employeeId))],
        };
        setTableData(empList);
        props.setTeamDetails(empList);
    };

    useEffect(() => {
        handleUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newTeamName]);

    const handleTeamName = async (name: string) => {
        setNewTeamName(name);
        await props.setteamname(name);
    };

    const setTeam = async (id: any, name: any) => {
        const userIDArray: any = [];
        const d = await MembersofChannel(hasuraToken, mycontext.organizationId, id);
        // await d?.value?.map(async (val: any) => {
        //     await userIDArray.push(val.userId);
        // });
        await d?.map(async (val: any) => {
            await userIDArray.push(val.userId);
        });
        setChannelUser(userIDArray);
        const members: any = [];

        await store.employees.map(async (member: any) => {
            if (userIDArray.includes(member.employeeId)) {
                members.push(member);
            }
        });

        setCurrentApprovers(currentApprovers);
        setCurrentMembers(members);
        const aps = await currentApprovers.map((ap: any) => ({ ...ap, isApprover: true, matchedData: false }));
        const mems = await members.map((m: any) => ({ ...m, isApprover: false, matchedData: false }));

        setApprovers(aps);
        setMembers(mems);
        const apList = new Set(aps.map((d: any) => d.Employee.employeeId));
        const empList = {
            teamName: name,
            teamId: '',
            channelId: id,
            createdAt: '',
            organizationId: mycontext.organizationId,
            employees: [...aps, ...mems.filter((m: any) => !apList.has(m.Employee.employeeId))],
        };

        setTableData(empList);
        props.setTeamDetails(empList);
    };
    const setSlackTeam = async (id: any, name: any) => {
        const userIDArray: any = [];
        const d = slackTeamEmployees[id];
        await d.map(async (val: any) => {
            await userIDArray.push(getUuidByString(val));
        });
        setChannelUser(userIDArray);
        const members: any = [];
        await store.employees.map(async (member: any) => {
            if (userIDArray.includes(member.employeeId)) {
                members.push(member);
            }
        });

        setCurrentApprovers(currentApprovers);
        setCurrentMembers(members);
        const aps = await currentApprovers.map((ap: any) => ({ ...ap, isApprover: true, matchedData: false }));
        const mems = await members.map((m: any) => ({ ...m, isApprover: false, matchedData: false }));

        setApprovers(aps);
        setMembers(mems);
        const apList = new Set(aps.map((d: any) => d.Employee.employeeId));
        const empList = {
            teamName: name,
            teamId: '',
            channelId: id,
            createdAt: '',
            organizationId: mycontext.organizationId,
            employees: [...aps, ...mems.filter((m: any) => !apList.has(m.Employee.employeeId))],
        };

        setTableData(empList);
        props.setTeamDetails(empList);
    };

    const handleTeamName1 = (name: any) => {
        setNewTeamName(name);
        if (loginMethod === 'Slack') {
            const newNameObj = {
                slackTeamId: name?.id,
                name: name?.name,
            };
            props.setteamname(newNameObj);
            setSlackTeam(name?.id, name?.name);
        } else {
            props.setteamname(name);
            setTeam(name?.id, name?.displayName);
        }
    };

    if (isLoading) {
        return (
            <>
                <Hidden mdDown>
                    <Paper elevation={0} className={classes.selectorPaper}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <FormControl>
                                    <FormLabel className={classes.formLabelStyle}>Team Name:</FormLabel>
                                    <Skeleton height={20} width={100} />
                                </FormControl>
                            </div>

                            <div>
                                <FormControl>
                                    <FormLabel className={classes.formLabelStyle} id="approvers-label">
                                        Team Approvers:&nbsp;
                                        <LightTooltip
                                            title="You cannot add admin as an approver to a team"
                                            placement="top-start"
                                        >
                                            <InfoIcon id="infoicon" fontSize="inherit" style={{ color: '#515ECE' }} />
                                        </LightTooltip>
                                    </FormLabel>
                                    <Skeleton height={20} width={100} />
                                </FormControl>
                            </div>

                            <div>
                                <FormControl>
                                    <FormLabel className={classes.formLabelStyle} id="members-label">
                                        Team Members:
                                    </FormLabel>
                                    <Skeleton height={20} width={100} />
                                </FormControl>
                            </div>
                        </div>

                        <div style={{ margin: '10px 0px' }}>
                            <Typography className={classes.teamTitle}>Employee List</Typography>
                        </div>
                    </Paper>
                </Hidden>

                <Hidden lgUp>
                    <Paper elevation={0} style={{ padding: '15px' }}>
                        <div>
                            <div>Team Name</div>
                            <Skeleton height={20} width={100} />
                        </div>
                        <div>
                            <div>
                                Team Approvers:&nbsp;
                                <LightTooltip
                                    title="You cannot add admin as an approver to a team"
                                    placement="top-start"
                                >
                                    <InfoIcon id="infoicon" fontSize="inherit" style={{ color: '#515ECE' }} />
                                </LightTooltip>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <Skeleton height={20} width={100} />
                            </div>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <div>Team Members</div>
                            <div style={{ marginTop: '10px' }}>
                                <Skeleton height={20} width={100} />
                            </div>
                        </div>
                    </Paper>
                </Hidden>
            </>
        );
    }
    return (
        <>
            <PopUp open={error} setOpen={setError} message={errorMessage} severity="error" />
            <Hidden mdDown>
                <Paper elevation={0} className={classes.selectorPaper}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <FormControl style={{ marginTop: '4px', paddingRight: '20px' }}>
                                <FormLabel className={classes.formLabelStyle}>Team Name:</FormLabel>
                                {props.current === 'edit' ? (
                                    <TextField
                                        id="team-name"
                                        placeholder="Enter Team Name"
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.inputStyle}
                                        value={newTeamName}
                                        error={props.teamNameValid ? true : false}
                                        onChange={(e: any) => handleTeamName(e.target.value)}
                                        disabled={true}
                                    />
                                ) : (
                                    <TextField
                                        select
                                        id="team-name"
                                        label={newTeamName ? '' : 'Select team'}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.inputStyle1}
                                        value={newTeamName}
                                        InputLabelProps={{
                                            style: { color: '#444444', opacity: 0.5, fontWeight: 600 },
                                        }}
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: { className: classes.menubar },
                                            },
                                        }}
                                        // error={props.teamNameValid ? true : false}
                                        onChange={(e: any) => handleTeamName1(e.target.value)}
                                    >
                                        {loginMethod === 'Slack' ? (
                                            slackTeams.length > 0 && fetched ? (
                                                slackTeams?.map((e: any, key: any) => (
                                                    <MenuItem value={e} key={key}>
                                                        {e.name}
                                                    </MenuItem>
                                                ))
                                            ) : fetched ? (
                                                <MenuItem disabled>No Teams to create</MenuItem>
                                            ) : (
                                                <MenuItem disabled>Loding, please wait</MenuItem>
                                            )
                                        ) : msTeams.length > 0 ? (
                                            msTeams?.map((e: any, key: any) => (
                                                <MenuItem value={e} key={key}>
                                                    {e.displayName}
                                                </MenuItem>
                                            ))
                                        ) : fetched ? (
                                            <MenuItem disabled>No Teams to create</MenuItem>
                                        ) : (
                                            <MenuItem disabled>Loding, please wait</MenuItem>
                                        )}
                                    </TextField>
                                )}
                            </FormControl>
                        </div>

                        <div>
                            <FormControl>
                                <FormLabel className={classes.formLabelStyle} id="approvers-label">
                                    Team Approvers:&nbsp;
                                    <LightTooltip
                                        title="You cannot add admin as an approver to a team"
                                        placement="top-start"
                                    >
                                        <InfoIcon id="infoicon" fontSize="inherit" style={{ color: '#515ECE' }} />
                                    </LightTooltip>
                                </FormLabel>
                                <Autocomplete
                                    id="approversList"
                                    multiple={true}
                                    value={currentApprovers}
                                    onChange={(event: any, newValue: any, reason: any) => {
                                        if (
                                            event.type === 'keydown' &&
                                            (event as React.KeyboardEvent).key === 'Backspace' &&
                                            reason === 'removeOption'
                                        ) {
                                            return;
                                        }
                                        setCurrentApprovers(newValue);

                                        setCurrentMembers(currentMembers);
                                    }}
                                    onClose={(event: any, reason: AutocompleteCloseReason) => {
                                        if (reason) {
                                            setCurrentApprovers(currentApprovers);
                                            setCurrentMembers(currentMembers);
                                            handleUserData1();
                                        }
                                    }}
                                    disableCloseOnSelect
                                    noOptionsText="No Value Match"
                                    renderTags={() => null}
                                    renderOption={(props, option, { selected }) => {
                                        return (
                                            <>
                                                <li
                                                    {...props}
                                                    style={
                                                        option.Employee?.organization_employees.length !== 0
                                                            ? selected
                                                                ? {}
                                                                : { pointerEvents: 'none', opacity: '0.6' }
                                                            : {}
                                                    }
                                                >
                                                    <Grid
                                                        container
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            alignContent: 'center',
                                                        }}
                                                    >
                                                        <Grid item sm={2} xs={2}>
                                                            {option.Employee.profileImage ? (
                                                                <Avatar
                                                                    alt={option.Employee.firstName}
                                                                    src={option.Employee.profileImage}
                                                                    className={classes.image}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    alt={option.Employee.firstName}
                                                                    src={getInitials(option.Employee.firstName)}
                                                                    className={classes.image}
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item sm={6} xs={6}>
                                                            <Box className={classes.boxtext}>
                                                                {option.Employee.firstName} {option.Employee.lastName}
                                                            </Box>
                                                        </Grid>
                                                        <Grid item sm={4} xs={4}>
                                                            {selected ? (
                                                                <Button
                                                                    id="addbtn"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    className={classes.listbtn}
                                                                >
                                                                    {action === 'edit' ? 'Remove' : 'Cancel'}
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    id="addbtn"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    className={classes.listbtn}
                                                                >
                                                                    Add
                                                                </Button>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </li>
                                            </>
                                        );
                                    }}
                                    options={[...store.employees].filter((member: any) => {
                                        if (channelUser.includes(member.employeeId)) {
                                            return member;
                                        }
                                        return null;
                                    })}
                                    getOptionLabel={option => option.Employee.firstName}
                                    isOptionEqualToValue={(option: any, value: any) =>
                                        option.Employee.employeeId === value.Employee.employeeId
                                    }
                                    disableClearable
                                    ListboxProps={{ className: classes.autocompletelist }}
                                    className={classes.autobox}
                                    renderInput={params => (
                                        <TextField
                                            ref={params.InputProps.ref}
                                            className={classes.autotext}
                                            variant="outlined"
                                            placeholder="Select Approvers"
                                            {...params}
                                        />
                                    )}
                                />
                            </FormControl>
                        </div>

                        {/* <div>
                            <FormControl>
                                <FormLabel className={classes.formLabelStyle} id="members-label">
                                    Team Members:
                                </FormLabel>
                                <Autocomplete
                                    id="membersList"
                                    multiple={true}
                                    value={currentMembers}
                                    onChange={(event: any, newValue: any, reason: any) => {
                                        if (
                                            event.type === 'keydown' &&
                                            (event as React.KeyboardEvent).key === 'Backspace' &&
                                            reason === 'removeOption'
                                        ) {
                                            return;
                                        }
                                        setCurrentMembers(newValue);
                                        setCurrentApprovers(currentApprovers);
                                    }}
                                    onClose={(event: any, reason: AutocompleteCloseReason) => {
                                        if (reason) {
                                            setCurrentApprovers(currentApprovers);
                                            setCurrentMembers(currentMembers);
                                            handleUserData();
                                        }
                                    }}
                                    disableCloseOnSelect
                                    noOptionsText="No Value Match"
                                    renderTags={() => null}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Grid
                                                container
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    alignContent: 'center',
                                                }}
                                            >
                                                <Grid item sm={2} xs={2}>
                                                    {option.Employee.profileImage ? (
                                                        <Avatar
                                                            alt={option.Employee.firstName}
                                                            src={option.Employee.profileImage}
                                                            className={classes.image}
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            alt={option.Employee.firstName}
                                                            src={getInitials(option.Employee.firstName)}
                                                            className={classes.image}
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <Box className={classes.boxtext}>
                                                        {option.Employee.firstName} {option.Employee.lastName}
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={4} xs={4}>
                                                    {selected ? (
                                                        <Button
                                                            id="addbtn"
                                                            variant="outlined"
                                                            color="primary"
                                                            className={classes.listbtn}
                                                        >
                                                            {action === 'edit' ? 'Remove' : 'Cancel'}
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            id="addbtn"
                                                            variant="outlined"
                                                            color="primary"
                                                            className={classes.listbtn}
                                                        >
                                                            Add
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </li>
                                    )}
                                    options={[...store.employees].filter((member: any) => {
                                        return !approvers.find(
                                            (ap: any) => ap.Employee.employeeId === member.Employee.employeeId,
                                        );
                                    })}
                                    isOptionEqualToValue={(option: any, value: any) =>
                                        option.Employee.employeeId === value.Employee.employeeId
                                    }
                                    getOptionLabel={option => option.Employee.firstName}
                                    disableClearable
                                    ListboxProps={{ className: classes.autocompletelist }}
                                    className={classes.autobox}
                                    renderInput={params => (
                                        <TextField
                                            ref={params.InputProps.ref}
                                            className={classes.autotext}
                                            variant="outlined"
                                            placeholder="Select Members"
                                            {...params}
                                        />
                                    )}
                                />
                            </FormControl>
                        </div> */}
                    </div>

                    <div style={{ margin: '22px 0px' }}>
                        <Typography className={classes.teamTitle}>Employee List</Typography>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <TeamsTable
                            current={props.current}
                            teamID={props.teamId}
                            teamEmployeeList={tableData.employees}
                            selectedTeam={props.selectedTeam}
                            setSelectedTeam={props.setSelectedTeam}
                            setAlertMessage={props.setAlertMessage}
                            setAlertm={props.setAlertm}
                            setSeverity={props.setSeverity}
                        />
                    </div>
                </Paper>
            </Hidden>

            <Hidden lgUp>
                <Paper elevation={0} style={{ padding: '15px' }}>
                    <div style={{ marginTop: '10px' }}>
                        <div>Team Name</div>
                        {props.current === 'edit' ? (
                            <TextField
                                id="team-name"
                                placeholder="Enter Team Name"
                                variant="outlined"
                                margin="dense"
                                className={classes.inputStyle}
                                value={newTeamName}
                                error={props.teamNameValid ? true : false}
                                onChange={(e: any) => handleTeamName(e.target.value)}
                                disabled={true}
                            />
                        ) : (
                            <TextField
                                select
                                id="team-name"
                                placeholder="Select Team"
                                label={newTeamName ? '' : 'Select team'}
                                variant="outlined"
                                margin="dense"
                                className={classes.inputStyle1}
                                value={newTeamName}
                                InputLabelProps={{
                                    style: { color: '#444444', opacity: 0.5, fontWeight: 600 },
                                }}
                                // error={props.teamNameValid ? true : false}
                                onChange={(e: any) => handleTeamName1(e.target.value)}
                            >
                                {loginMethod === 'Slack' ? (
                                    slackTeams.length > 0 && fetched ? (
                                        slackTeams?.map((e: any, key: any) => (
                                            <MenuItem value={e} key={key}>
                                                {e.name}
                                            </MenuItem>
                                        ))
                                    ) : fetched ? (
                                        <MenuItem disabled>No Teams to create</MenuItem>
                                    ) : (
                                        <MenuItem disabled>Loding, please wait</MenuItem>
                                    )
                                ) : msTeams.length > 0 ? (
                                    msTeams?.map((e: any, key: any) => (
                                        <MenuItem value={e} key={key}>
                                            {e.displayName}
                                        </MenuItem>
                                    ))
                                ) : fetched ? (
                                    <MenuItem disabled>No Teams to create</MenuItem>
                                ) : (
                                    <MenuItem disabled>Loding, please wait</MenuItem>
                                )}
                            </TextField>
                        )}
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <div>
                            Team Approvers:&nbsp;
                            <LightTooltip title="You cannot add admin as an approver to a team" placement="top-start">
                                <InfoIcon id="infoicon" fontSize="inherit" style={{ color: '#515ECE' }} />
                            </LightTooltip>
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <Autocomplete
                                id="approversList"
                                multiple={true}
                                value={currentApprovers}
                                onChange={(event: any, newValue: any, reason: any) => {
                                    if (
                                        event.type === 'keydown' &&
                                        (event as React.KeyboardEvent).key === 'Backspace' &&
                                        reason === 'removeOption'
                                    ) {
                                        return;
                                    }
                                    setCurrentApprovers(newValue);
                                    setCurrentMembers(currentMembers);
                                }}
                                onClose={(event: any, reason: AutocompleteCloseReason) => {
                                    if (reason) {
                                        setCurrentApprovers(currentApprovers);
                                        setCurrentMembers(currentMembers);
                                        handleUserData1();
                                    }
                                }}
                                disableCloseOnSelect
                                noOptionsText="No Value Match"
                                renderTags={() => null}
                                renderOption={(props, option, { selected }) => {
                                    return (
                                        <li
                                            {...props}
                                            style={
                                                option.Employee?.organization_employees.length !== 0
                                                    ? selected
                                                        ? {}
                                                        : { pointerEvents: 'none', opacity: '0.6' }
                                                    : {}
                                            }
                                        >
                                            <Grid
                                                container
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    alignContent: 'center',
                                                }}
                                            >
                                                <Grid item sm={2} xs={2}>
                                                    {option.Employee.profileImage ? (
                                                        <Avatar
                                                            alt={option.Employee.firstName}
                                                            src={option.Employee.profileImage}
                                                            className={classes.image}
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            alt={option.Employee.firstName}
                                                            src={getInitials(option.Employee.firstName)}
                                                            className={classes.image}
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <Box className={classes.boxtext}>
                                                        {option.Employee.firstName} {option.Employee.lastName}
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={4} xs={4}>
                                                    {selected ? (
                                                        <Button
                                                            id="addbtn"
                                                            variant="outlined"
                                                            color="primary"
                                                            className={classes.listbtn}
                                                        >
                                                            {action === 'edit' ? 'Remove' : 'Cancel'}
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            id="addbtn"
                                                            variant="outlined"
                                                            color="primary"
                                                            className={classes.listbtn}
                                                        >
                                                            Add
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </li>
                                    );
                                }}
                                options={[...store.employees].filter((member: any) => {
                                    if (channelUser.includes(member.employeeId)) {
                                        return member;
                                    }
                                    return null;
                                })}
                                getOptionLabel={option => option.Employee.firstName}
                                isOptionEqualToValue={(option: any, value: any) =>
                                    option.Employee.employeeId === value.Employee.employeeId
                                }
                                disableClearable
                                ListboxProps={{ className: classes.autocompletelist }}
                                className={classes.autobox}
                                renderInput={params => (
                                    <TextField
                                        ref={params.InputProps.ref}
                                        className={classes.autotext}
                                        variant="outlined"
                                        placeholder="Select Approvers"
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    {/* <div style={{ marginTop: 10 }}>
                        <div>Team Members</div>
                        <div style={{ marginTop: 5 }}>
                            <Autocomplete
                                id="membersList"
                                multiple={true}
                                value={currentMembers}
                                onChange={(event: any, newValue: any, reason: any) => {
                                    if (
                                        event.type === 'keydown' &&
                                        (event as React.KeyboardEvent).key === 'Backspace' &&
                                        reason === 'removeOption'
                                    ) {
                                        return;
                                    }
                                    setCurrentMembers(newValue);
                                    setCurrentApprovers(currentApprovers);
                                }}
                                onClose={(event: any, reason: AutocompleteCloseReason) => {
                                    if (reason) {
                                        setCurrentApprovers(currentApprovers);
                                        setCurrentMembers(currentMembers);
                                        handleUserData();
                                    }
                                }}
                                disableCloseOnSelect
                                noOptionsText="No Value Match"
                                renderTags={() => null}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Grid
                                            container
                                            style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}
                                        >
                                            <Grid item sm={2} xs={2}>
                                                {option.Employee.profileImage ? (
                                                    <Avatar
                                                        alt={option.Employee.firstName}
                                                        src={option.Employee.profileImage}
                                                        className={classes.image}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        alt={option.Employee.firstName}
                                                        src={getInitials(option.Employee.firstName)}
                                                        className={classes.image}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item sm={6} xs={6}>
                                                <Box className={classes.boxtext}>
                                                    {option.Employee.firstName} {option.Employee.lastName}
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4} xs={4}>
                                                {selected ? (
                                                    <Button
                                                        id="addbtn"
                                                        variant="outlined"
                                                        color="primary"
                                                        className={classes.listbtn}
                                                    >
                                                        {action === 'edit' ? 'Remove' : 'Cancel'}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        id="addbtn"
                                                        variant="outlined"
                                                        color="primary"
                                                        className={classes.listbtn}
                                                    >
                                                        Add
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </li>
                                )}
                                options={[...store.employees].filter((member: any) => {
                                    return !approvers.find(
                                        (ap: any) => ap.Employee.employeeId === member.Employee.employeeId,
                                    );
                                })}
                                isOptionEqualToValue={(option: any, value: any) =>
                                    option.Employee.employeeId === value.Employee.employeeId
                                }
                                getOptionLabel={option => option.Employee.firstName}
                                disableClearable
                                ListboxProps={{ className: classes.autocompletelist }}
                                className={classes.autobox}
                                renderInput={params => (
                                    <TextField
                                        ref={params.InputProps.ref}
                                        className={classes.autotext}
                                        variant="outlined"
                                        placeholder="Select Members"
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    </div> */}
                </Paper>

                <div style={{ margin: '10px 0px' }}>
                    <Typography className={classes.teamTitle}>Employees</Typography>
                </div>

                <div style={{ marginTop: 10, marginBottom: 40 }}>
                    <TeamsTable
                        current={props.current}
                        teamID={props.teamId}
                        teamEmployeeList={tableData.employees}
                        selectedTeam={props.selectedTeam}
                        setSelectedTeam={props.setSelectedTeam}
                        setAlertMessage={props.setAlertMessage}
                        setAlertm={props.setAlertm}
                        setSeverity={props.setSeverity}
                    />
                </div>
            </Hidden>
        </>
    );
}
const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        color: '#444444',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        width: 180,
    },
}))(Tooltip);
export default Selectors;
