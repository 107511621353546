import { makeStyles, Theme } from '@material-ui/core/styles';

export const AddToSlackStyles = makeStyles((theme: Theme) => ({
    line1: {
        padding: '30px 60px 80px 30px',
    },
    image: {
        width: '300px',
        height: '300px',
    },
    notification: {
        fontSize: '17px',
        marginBottom: '10px',
        fontStyle: 'bold',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
    },
    notification1: {
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
    },
    text: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '15px',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        paddingBottom: 10,
    },
    line2: {
        paddingBottom: 20,
        fontFamily: theme.typography.fontFamily,
        fontSize: '15px',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
    },
    disabledLink: {
        alignItems: 'center',
        marginTop: 25,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: 4,
        display: 'inline-flex',
        fontSize: 16,
        fontWeight: 600,
        height: 40,
        justifyContent: 'center',
        textDecoration: 'none',
        width: 180,
        pointerEvents: 'none',
        opacity: '0.6',
        '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.primary}`,
            textDecoration: 'none',
            pointerEvents: 'none',
        },
    },
    slackLink: {
        alignItems: 'center',
        marginTop: 25,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: 4,
        display: 'inline-flex',
        fontSize: 16,
        fontWeight: 600,
        height: 40,
        justifyContent: 'center',
        textDecoration: 'none',
        width: 180,
        '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.primary}`,
            textDecoration: 'none',
        },
    },
    addtoslackbtn: {
        height: 40,
        width: 180,
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    addtoslackmobbtn: {
        display: 'flex',
        justifyContent: 'center',
        height: 35,
        width: '100%',
        textTransform: 'capitalize',
        padding: '0 30px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    slackpaper: {
        borderRadius: '0px',
        backgroundColor: theme.palette.background.paper,
        '@media screen and (min-width: 821px)': {
            minHeight: 'calc(100vh - 175px)',
            maxHeight: 'calc(100vh - 170px)',
        },
        '@media screen and (min-width: 1240px)': {
            minHeight: 'calc(100vh - 175px)',
            maxHeight: 'calc(100vh - 170px)',
        },
        '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
            minHeight: 'calc(100vh - 18vh)',
        },
        '@media only screen and (min-width: 820px) and (max-width: 820px)': {
            minHeight: 'calc(100vh - 25vh)',
        },
    },
    imgDiv: {
        '@media screen and (min-width: 767px)': {
            float: 'left',
            width: '45%',
        },
    },
    contentDiv: {
        padding: '20px 10px',
    },
    screenSize: {
        display: 'none',
        '@media screen and (max-width: 767px)': {
            display: 'block',
        },
    },
    disabledLink1: {
        alignItems: 'center',
        marginTop: 25,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: 4,
        display: 'inline-flex',
        fontSize: 16,
        fontWeight: 600,
        height: 40,
        justifyContent: 'center',
        textDecoration: 'none',
        width: 210,
        pointerEvents: 'none',
        opacity: '0.6',
        marginLeft: 25,
        '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.primary}`,
            textDecoration: 'none',
            pointerEvents: 'none',
        },
        '@media screen and (max-width: 767px)': {
            marginLeft: 0,
        },
    },
    slackLink1: {
        alignItems: 'center',
        marginTop: 25,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: 4,
        display: 'inline-flex',
        fontSize: 16,
        fontWeight: 600,
        height: 40,
        justifyContent: 'center',
        textDecoration: 'none',
        width: 210,
        marginLeft: 25,
        '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.primary}`,
            textDecoration: 'none',
        },
        '@media screen and (max-width: 767px)': {
            marginLeft: 0,
        },
    },
}));
