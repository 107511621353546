import React from 'react';

import ForgotPasswordPartOne from './partOne';
import ForgotPasswordPartTwo from './partTwo';
// import { Auth } from 'aws-amplify';
// import Validate from '../../Validator/validateRegister';
// import AuthContext from '../../context/AuthContext';

export default function ForgotPasswordMain() {
    const [pageNumber, setPageNumber] = React.useState(0);
    const [emailId, setEmailId] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [code, setCode] = React.useState<string>('');

    const values = {
        emailId: emailId,
        setEmailId: setEmailId,
        password: password,
        setPassword: setPassword,
        confirmPassword: confirmPassword,
        setConfirmPassword: setConfirmPassword,
        code: code,
        setCode: setCode,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
    };

    /* istanbul ignore next */
    switch (pageNumber) {
        case 0:
            return <ForgotPasswordPartOne values={values} />;
        case 1 /* istanbul ignore next */:
            return <ForgotPasswordPartTwo values={values} />;
        default:
            /* istanbul ignore next */
            return <div></div>;
    }
}
