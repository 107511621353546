import { makeStyles, Theme } from '@material-ui/core/styles';

export const SubscriptionCardStyle = makeStyles((theme: Theme) => ({
    mainCard: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 3,
        border: `0.5px solid ${theme.palette.text.secondary}`,

        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25)',
            transform: 'scale(1.01)',
        },
        '@media only screen and (min-width: 360px) and ( max-width: 540px)': {
            '&:hover': {
                boxShadow: '0 14px 28px rgba(0,0,0,0.25)',
                transform: 'scale(1)',
            },
        },
    },
    planButton: {
        width: '100%',
        height: '80%',
    },
    price: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    features: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
    },
    cardContainer: {
        height: 'calc(100vh - 175px)',
        '@media screen and (max-width: 768px)': {
            height: 'auto',
            padding: '10px 15px',
        },
        backgroundColor: theme.palette.background.paper,
    },
    detailsContainer: {
        backgroundColor: theme.palette.background.paper,
        height: 'auto',
        padding: '2%',
    },
    btnGroup: {
        whiteSpace: 'nowrap',
        // border: `1px solid ${theme.palette.text.secondary}`,
        '& span': {
            fontWeight: 'bold',
            fontStyle: 'normal',
            textTransform: 'capitalize',
            letterSpacing: '0.05em',
            color: theme.palette.text.secondary,
        },
    },
    intermediateCard: {
        backgroundColor: theme.palette.background.default,
        boxShadow: '0 14px 28px rgba(0,0,0,0.25)',
        transform: 'scale(1.12)',
        borderRadius: 5,
        '@media only screen and (min-width: 360px) and ( max-width: 540px)': {
            boxShadow: '0 0px 0px',
            border: `0.5px solid ${theme.palette.text.secondary}`,
            transform: 'scale(1)',
        },
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25)',
        },
    },
    details: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardHeader: {
        paddingTop: theme.spacing(3),
        backgroundColor: '#DCDFFF',
        color: '#262F83',
        textAlign: 'center',
        fontWeight: 'bold',
        fontStyle: 'normal',
        textTransform: 'capitalize',
        letterSpacing: '0.05em',
    },
    intermediateCardHeader: {
        paddingTop: theme.spacing(3),
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontWeight: 'bold',
        fontStyle: 'normal',
        textTransform: 'capitalize',
        letterSpacing: '0.05em',
    },
    tableContainer: {
        maxHeight: 'calc(100vh - 350px)',
        '&::-webkit-scrollbar': {
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.background.paper,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },
    table: {
        maxHeight: 'calc(100vh - 200px)',
        width: '100%',
    },
    row: {
        border: 'none',
        '&:hover': {
            boxShadow: `0 4px 10px 0 ${theme.palette.background.default}`,
            // color: theme.palette.primary.main,fedit
            border: 'none',
            outline: 'none',
            borderRadius: 5,
            cursor: 'pointer',
        },
    },
    cellName: {
        padding: 'none',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        textTransform: 'none',
        borderBottom: 'none',
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    column: {
        color: '#262F83',
        backgroundColor: '#DCDFFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '16px',
        textAlign: 'center',
    },
    columnname: {
        color: '#262F83',
        backgroundColor: '#DCDFFF',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '16px',
        textAlign: 'left',
    },
    subsLeft: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase',
        color: '#AAAAAA',
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
    },
    subsText: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
    },
    editIcon: {
        // marginLeft: '10%',
        color: theme.palette.primary.contrastText,
        '&:active, &:focus, &:hover': {
            color: '#3C48B5',
            border: 'none',
            outline: 'none !important',
        },
    },
    manageBtn: {
        backgroundColor: '#fff',
        color: theme.palette.warning.main,
        width: '100%',
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'capitalize',
        '&:hover': {
            color: theme.palette.warning.main,
            backgroundColor: '#fff',
        },
        '&:focus': {
            color: theme.palette.warning.main,
            backgroundColor: '#fff',
            outline: 'none',
        },
        zIndex: 0,
    },
    invoiceBtn: {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        width: '100%',
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'capitalize',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
        },
        '&:focus': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
            outline: 'none',
        },
        zIndex: 0,
    },
    btnDesign: {
        textAlign: 'center',
    },
    outerGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        paddingTop: '2%',
    },
    topGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '1%',
    },
    buyPlan: {
        borderRadius: 15,
        width: '90%',
        height: '80%',
    },
    intermediateButton: {
        borderRadius: 15,
        width: '90%',
        height: '80%',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        outline: 'none',
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    normalButton: {
        borderRadius: 15,
        width: '90%',
        height: '80%',
        color: theme.palette.text.secondary,
        outline: 'none',
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            outline: 'none',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        '&:focus': {
            outline: 'none',
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        },
    },
    unSelected: {},
}));
