import React, { useContext } from 'react';
import { Button, Hidden, TextField, Typography, Grid, Chip } from '@material-ui/core';
import useStyles from './StandupsSettingStyles';
import { fetchQuestion, updateAdminQuestion } from 'helpers/hasuraapi3';
import AuthContext from 'context/AuthContext';
import { RiDeleteBinLine } from 'react-icons/ri';
import CloseIcon from '@material-ui/icons/Close';
import SubscriptionHardExpired from 'components/Settings/SubscriptionHardExpired';
import { updateActiveTab } from '../Redux/activeTab';
import { useDispatch, useSelector } from 'react-redux';
import { PopUp } from '../Modal/Modal';

const get_random_color = () => {
    let color = '';
    for (let i = 0; i < 3; i++) {
        const sub = Math.floor(Math.random() * 256).toString(16);
        color = color + (sub.length === 1 ? '0' + sub : sub);
    }
    return '#' + color;
};

interface ComponentProps {
    inputList?: any;
    // inputList2?: any;
    handleInputChange?: any;
    handleRemoveTextField?: any;
    addMore?: any;
    subscriptionToNewStandups?: any;
}
export const Component = (props: ComponentProps) => {
    const classes = useStyles();
    return (
        <div data-testid="div" className={classes.newBorder}>
            <Typography className={classes.text} variant="h6">
                Template
            </Typography>
            <div className={classes.field}>
                <TextField
                    // error
                    data-testid="abcd"
                    size="small"
                    variant="outlined"
                    value="Did you accomplish yesterday's task?"
                    className={classes.inputTextField}
                />
                {Object.keys(props?.inputList)?.map((key: any, v: number) => (
                    <div key={key}>
                        <TextField
                            data-testid="xyz"
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={props.inputList[key]}
                            onChange={(a: any) => props.handleInputChange(key, a.target.value)}
                            className={classes.inputTextField}
                        />
                        <RiDeleteBinLine
                            className={classes.deleteIcon}
                            fontSize="medium"
                            id={'clearIcon-' + v}
                            onClick={() => props.handleRemoveTextField(v)}
                        ></RiDeleteBinLine>
                    </div>
                ))}
                <Button className={classes.addanother} onClick={props.addMore}>
                    + Add more
                </Button>
            </div>
        </div>
    );
};

interface TagsComponentMobileProps {
    handleAdd: any;
    todo: any;
    setTodo: any;
    handleremove: any;
    todos: any;
    // tag: any;
}
export function TagsComponentMobile(props: TagsComponentMobileProps) {
    const classes = useStyles();
    return (
        <div data-testid="div2" className={classes.mobile}>
            <div className={classes.newBorder}>
                <Typography className={classes.text} variant="h6">
                    Create your tags
                </Typography>
                <div className={classes.field}>
                    <Grid container className={classes.firstfield}>
                        <Grid item xs={12}>
                            <form onSubmit={(e: any) => props.handleAdd(e)}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        autoFocus
                                        variant="outlined"
                                        size="small"
                                        value={props.todo}
                                        onChange={(e: any) => props.setTodo(e.target.value)}
                                        placeholder="Type here to create your tag"
                                        className={classes.inputTextField}
                                    ></TextField>
                                    <button type="submit"></button>
                                </Grid>
                            </form>
                            {props.todos?.map((t: any, index: any) => (
                                <Chip
                                    data-testid="chip"
                                    key={index}
                                    size="small"
                                    label={t.name}
                                    onDelete={() => props.handleremove(t.name)}
                                    deleteIcon={<CloseIcon className={classes.closeIcon} />}
                                    style={{
                                        backgroundColor: `${t.color}`,
                                        color: `white`,
                                        position: 'relative',
                                        marginTop: '20px',
                                        marginRight: '5px',
                                    }}
                                ></Chip>
                            ))}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

interface TagsComponentProps {
    handleAdd: any;
    todo: any;
    setTodo: any;
    handleremove: any;
    todos: any;
    // tag: any;
}

export function TagsComponent(props: TagsComponentProps) {
    const classes = useStyles();

    return (
        <>
            <div
                data-testid="div3"
                style={{ position: 'relative', width: 1, height: '100%', background: 'grey' }}
            ></div>
            <div className={classes.newBorder}>
                <Typography className={classes.text} variant="h6">
                    Create your tags
                </Typography>
                <div className={classes.field}>
                    <Grid container className={classes.firstfield} spacing={4}>
                        <Grid item xs={12}>
                            <form data-testid="count" onSubmit={(e: any) => props.handleAdd(e)}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        autoFocus
                                        variant="outlined"
                                        size="small"
                                        value={props.todo}
                                        onChange={(e: any) => props.setTodo(e.target.value)}
                                        placeholder="Type here to create your tag"
                                        className={classes.inputTextField}
                                    />
                                    <button type="submit"></button>
                                </Grid>
                            </form>
                            {props.todos?.map((t: any, index: any) => (
                                <Chip
                                    key={index}
                                    size="small"
                                    label={t?.name}
                                    onDelete={() => props.handleremove(t.name)}
                                    deleteIcon={<CloseIcon className={classes.closeIcon} />}
                                    style={{
                                        backgroundColor: `${t?.color}`,
                                        color: 'white',
                                        position: 'relative',
                                        marginTop: '20px',
                                        marginRight: '5px',
                                    }}
                                ></Chip>
                            ))}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default function StandupSetting() {
    const classes = useStyles();
    const [data, setData] = React.useState<any>({});
    const mycontext = useContext(AuthContext);
    // const [open, setOpen] = useState(false);
    const [todo, setTodo] = React.useState<any>('');
    const [todos, setTodos] = React.useState<any[]>([]);
    const [inputList, setInputList] = React.useState<any>({});
    const [isDisable, setIsDisable] = React.useState<boolean>(true);
    const [alert, setAlert] = React.useState(false);
    const state: any = useSelector(state => state);
    const userSubscriptionDetails = state?.subscription.userSubscriptions;
    const [popup, setPopup] = React.useState({
        alertMessage: '',
        severity: '',
    });
    const dispatch = useDispatch();

    React.useEffect(() => {
        const fetchData = async () => {
            const data: any = await fetchQuestion(mycontext?.organizationId, mycontext?.token);
            // console.log(data?.organizationId)
            mycontext?.addStandupTempId(data?.standupTempId);
            setData(data);
            data?.questions?.map((q: string, i: number) => {
                setInputList((prev: any) => ({
                    ...prev,
                    [`input${i + 1}`]: q,
                }));
            });
            setTodos(data?.tags);
        };
        fetchData();
    }, [mycontext]);

    React.useEffect(() => {
        dispatch(updateActiveTab('/settings'));
    }, [dispatch]);

    const handleRemoveTextField = (i: number) => {
        const list: any = Object.values({ ...inputList });
        list.splice(i, 1);
        // console.log("remove", list)
        setInputList(list);
        setIsDisable(false);
    };

    // console.log('ab', inputList);

    const addMore = () => {
        const length = Object.keys(inputList).length;
        setInputList((prev: any) => ({
            ...prev,
            [`input${length + 1}`]: '',
        }));
        setIsDisable(false);
    };

    const handleremove = async (name: any) => {
        setIsDisable(false);
        const list = [...todos];
        const index = todos.findIndex((a: any) => a.name === name);
        list.splice(index, 1);
        setTodos(list);
    };

    const handleAdd = (e: any) => {
        e.preventDefault();
        try {
            if (todos !== null && todos.find((a: any) => a.name.toLowerCase() === todo.toLowerCase())) {
                throw new Error('same tag already present');
            }
            if (todo === '') {
                throw new Error('please type something in tags box');
            }
            if (todo.length > 11) {
                throw new Error('tag length should be less than 10');
            }
            if (todo.match(/[0-9,`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/)) {
                throw new Error('please type only alphabet in tags');
            }
            if (todo.match(/[ ]/)) {
                throw new Error('space in tags are not allowed');
            }
            const obj = {
                name: todo,
                color: get_random_color(),
                // id: Math.random(),
            };
            // console.log(obj);
            if (todos === null) {
                setTodos([obj]);
            } else {
                setTodos((prev: any) => [...prev, obj]);
            }
            setTodo('');
            setIsDisable(false);
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };

    // console.log(tag);
    const handleInputChange = (key: any, value: any) => {
        setIsDisable(false);
        setInputList((prev: any) => ({
            ...prev,
            [key]: value,
        }));
    };

    // console.log('tag', todos);
    const handleUpdate = async () => {
        const arr: any = Object.values(inputList).map((v: any) => v);
        try {
            const userSubscriptionDetail = userSubscriptionDetails.usermanagementdb_tenant_employee[0];
            if (
                userSubscriptionDetail?.standupSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter((s: any) => s.planId.includes('sbtp')).length === 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.standupSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('sbtp') && s.status !== 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Sorry! It looks like you don't have a subscription yet. Subscribe now to get started!",
                );
            }
            if (
                userSubscriptionDetail?.standupSubscriptionId === null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) => s.planId.includes('sbtp') && s.status === 'Subscribed',
                ).length !== 0
            ) {
                throw new Error(
                    "Oops! It seems like you haven't been assigned a license yet. To use this app, kindly request your team admin to assign you a license",
                );
            }
            if (
                userSubscriptionDetail?.standupSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.standupSubscriptionId && s.status === 'Suspended',
                ).length !== 0
            ) {
                throw new Error(
                    'Uh oh! Your account is on hold. Kindly request your team admin to update subscription details.',
                );
            }
            if (
                userSubscriptionDetail?.standupSubscriptionId !== null &&
                userSubscriptionDetail?.Tenant.Subscriptions.filter(
                    (s: any) =>
                        s.subscriptionId === userSubscriptionDetail?.standupSubscriptionId &&
                        s.status === 'Unsubscribed',
                ).length !== 0
            ) {
                throw new Error(
                    'Sorry, your subscription has expired. Please contact your team admin to buy a new subscription.',
                );
            }
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === '') {
                    throw new Error('please fill template question');
                }
            }
            const obj = {
                questions: arr,
                tag: todos,
                employeeId: mycontext.EmployeeId,
                organizationId: mycontext.organizationId,
                token: mycontext.token,
            };
            // console.log(obj);
            const resp = await updateAdminQuestion(obj);
            if (resp.error) {
                throw new Error('standup template was not updated');
            }
            mycontext.togglePopup({
                alertMessage: 'Standup template was updated successfully!',
                severity: 'success',
            });
            setIsDisable(true);
            setInputList({});
        } catch (err) {
            setAlert(true);
            setPopup({
                alertMessage: err.message,
                severity: 'error',
            });
            return err;
        }
    };

    const handleReset = () => {
        setInputList({});
        data?.questions?.map((q: string, i: number) => {
            setInputList((prev: any) => ({
                ...prev,
                [`input${i + 1}`]: q,
            }));
        });
        setTodo('');
        if (todos === null) {
            setTodos([]);
        } else {
            setTodos(data?.tags);
        }
        setIsDisable(true);
    };

    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        mycontext.isAdmin ? (
            <SubscriptionHardExpired
                content="Looks like your subscription is expired, Please renew it !"
                showRenew={true}
            />
        ) : (
            <SubscriptionHardExpired
                content="Subscription expired, Please contact your admin to renew subscription"
                showRenew={false}
            />
        )
    ) : (
        <div data-testid="div4" className={classes.border}>
            <PopUp open={alert} message={popup.alertMessage} severity={popup.severity} setOpen={setAlert} />
            <div className={classes.main}>
                <Component
                    inputList={inputList}
                    // inputList2={inputList2}
                    addMore={addMore}
                    handleRemoveTextField={handleRemoveTextField}
                    handleInputChange={handleInputChange}
                    // data={data}
                />
                <Hidden mdDown>
                    <TagsComponent
                        handleAdd={handleAdd}
                        todo={todo}
                        setTodo={setTodo}
                        handleremove={handleremove}
                        todos={todos}
                        // tag={tag}
                    />
                </Hidden>
            </div>
            <Hidden lgUp>
                <TagsComponentMobile
                    handleAdd={handleAdd}
                    todo={todo}
                    setTodo={setTodo}
                    handleremove={handleremove}
                    todos={todos}
                    // tag={tag}
                />
            </Hidden>
            <div className={classes.mainDiv}>
                <Button variant="outlined" onClick={handleReset} className={classes.btn}>
                    Reset
                </Button>
                <Button
                    type="button"
                    className={isDisable ? classes.isDisable : classes.btn1}
                    onClick={handleUpdate}
                    variant="contained"
                    disabled={isDisable}
                >
                    Update
                </Button>
            </div>
        </div>
    );
}
