import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    selectInput: {
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
    },
    empmain: {
        overflow: 'auto',
        background: theme.palette.background.paper,
        padding: 10,
        '@media screen and (min-width: 769px)': {
            height: '100%',
            display: 'flex',
            alignContent: 'center',
            // justifyContent: 'center',
        },
    },
    polls1: {
        width: '100%',
        height: '288px',
        background: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cont: {
        margin: 0,
        padding: 0,
        '@media screen and (min-width: 769px)': {
            display: 'grid',
            // justifyContent: 'center',
            alignItems: 'center',
        },
    },
    polls: {
        width: '100%',
        height: '270px',
        background: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    weekstyles: {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 600,
    },
    weekstyles1: {
        color: theme.palette.text.primary,
        fontSize: '13px',
        fontWeight: 'bold',
    },

    tabs: {
        minHeight: '30px',
        // lineheight: '25px',
        // borderradius: '5px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        '@media screen and (max-width: 540px)': {
            marginTop: '10px',
        },

        '& div': {
            opacity: '4',
        },
    },
    tab: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        textTransform: 'initial',
        minHeight: '100%',
        fontSize: '15px',
        padding: '10px 20px',
        '&.Mui-selected': {
            outline: 'none',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
        },
    },
    main: {
        width: '100%',
        // height: 'calc(100vh - 400px)',
        // height: '88vh',
        display: 'flex',
        flexDirection: 'column',
        // background: 'red',
    },
    profileAndStatus: {
        display: 'grid',
        margin: '10px 0',
        width: '100%',
        '@media screen and (min-width: 1024px)': {
            gridTemplateColumns: '2.5fr 1fr',
            gridColumnGap: '10px',
        },
        '@media screen and (max-width: 531px)': {
            display: 'flex',
            flexDirection: 'column',
        },
    },

    profileMain2: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        flex: 0.9,
        padding: '10px 20px',
        flexDirection: 'column',
    },
    profileAndPieChart: {
        flex: 0.9,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        padding: '10px 20px',
        minHeight: '200px',
        '@media screen and (max-width: 780px)': {
            width: '100%',
        },
    },
    timeOffStatusMain: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timeOffStatusText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    weekDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: theme.palette.background.paper,
        padding: '15px 0px',
    },
    workweeks: {
        height: '30px',
        display: 'flex',
        alignItems: 'center',
    },
    workweekfont: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
    },
    week: {
        paddingTop: 10,
        margin: 0,
        padding: 0,
        display: 'flex',
        '@media screen and (max-width: 821px)': {
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    week1: {
        paddingTop: 10,
        margin: 0,
        padding: 0,
        display: 'flex',
        '@media screen and (max-width: 400px)': {
            flexDirection: 'column',
            display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
        },
    },
    timezone: {
        display: 'flex',
        alignItems: 'center',
        '@media screen and (max-width: 378px)': {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'flex-start',
        },
    },

    container1: {
        '@media screen and (min-width: 769px)': {
            marginBottom: '10px',

            // height: 'auto'
        },
        '@media screen and (min-width: 545px)': {
            // border: '1px solid red',
            // maxHeight: '250px'
        },
    },
    container3: {
        // display: 'grid',
        // gridTemplateColumns: '1fr 0.5fr',
        // gridColumnGap: 10,
        marginLeft: 0,
        marginRight: 0,
        maxWidth: '100%',
        width: '100%',
        padding: 0,

        height: '80vh',
        '@media screen and (min-width: 769px)': {
            marginTop: '10px',
        },
    },
    container4: {
        padding: 0,
        // paddingTop: 10,
        width: '100%',
        height: '80vh',
        marginLeft: 0,
        marginRight: 0,
        maxWidth: '100%',
        '@media screen and (min-width: 769px)': {
            marginTop: '10px',
        },
    },
    container2: {
        margin: 0,
        padding: 0,
        borderRadius: '0px 0px 0px 0px',

        '@media screen and (min-width: 769px)': {
            height: '80vh',
            gridTemplateRows: '2.5fr 0.5fr',
            gridColumnGap: '10px',
            display: 'grid',
            paddingRight: '10px',
        },

        '@media screen and (min-width: 769px) and (max-width: 1150px)': {
            overflowY: 'auto',
        },
    },
    container5: {
        margin: 0,
        padding: 0,
        borderRadius: '0px 0px 0px 0px',
        '@media screen and (min-width: 769px)': {
            height: '80vh',
            gridTemplateRows: '2.5fr 0.5fr',
            gridColumnGap: '10px',
            display: 'grid',
            paddingRight: '10px',
        },
    },
    profilecontainer: {
        position: 'relative',
        width: '100%',
        margin: 0,
        padding: 0,

        '@media screen and (min-width: 769px)': {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
        },
    },
    profileimage: {
        '@media screen and (min-width: 769px)': {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
        },
    },
    block: {
        marginTop: 15,
        '@media screen and (max-width: 540px)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },

        minWidth: '200px',
    },
    block1: {
        marginTop: 15,
        '@media screen and (max-width: 598px)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },

        minWidth: '150px',
        // border: '1px solid red',
    },
    datediv: {
        '@media screen and (min-width: 767px)': {
            display: 'flex',
        },
    },
    info1: {
        width: '100%',
        margin: 0,
        padding: 0,
        display: 'flex',

        '@media screen and (max-width: 768px)': {
            flexDirection: 'column',
        },
    },
    info: {
        width: '100%',
        margin: 0,
        padding: 0,
        display: 'flex',

        '@media screen and (max-width: 599px)': {
            flexDirection: 'column',
        },
    },
    day: {
        fontSize: 10,
        height: '20px',
        padding: '0px 10px 0px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        border: `0.4px solid #DCDFFF`,
    },
    dayFirst: {
        height: '20px',
        padding: '0px 10px 0px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        border: `0.4px solid #DCDFFF`,
        borderRadius: '5px 0 0 5px',
        fontSize: 10,
    },
    dayLast: {
        height: '20px',
        padding: '0px 10px 0px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        border: `0.4px solid #DCDFFF`,
        borderRadius: '0 5px 5px 0',
        fontSize: 10,
    },
    activeDay: {
        height: '20px',
        padding: '0px 10px 0px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ' #DCDFFF',
        color: theme.palette.primary.main,
        fontSize: 10,
        fontWeight: 600,
        border: `0.4px solid #DCDFFF`,
    },
    activeDayFirst: {
        height: '20px',
        padding: '0px 10px 0px 10px',
        display: 'flex',
        fontSize: 10,
        fontWeight: 600,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ' #DCDFFF',
        opacity: 1,
        color: theme.palette.primary.main,
        border: `0.4px solid #DCDFFF`,
        borderRadius: '5px 0 0 5px',
    },
    activeDayLast: {
        height: '20px',
        padding: '0px 10px 0px 10px',
        fontSize: 10,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#DCDFFF',
        color: theme.palette.primary.main,
        border: `0.4px solid #DCDFFF`,
        borderRadius: '0 5px 5px 0',
    },

    divider1: {
        borderBottomWidth: 0.8,
        background: theme.palette.background.paper,
    },
    IconSty: {
        color: theme.palette.primary.contrastText,
    },
    commonStyle1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '@media screen and (max-width: 680px)': {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
    },
    commonStyleHead: {
        padding: '5px 10px 5px 10px',
        background: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media screen and (max-width: 600px)': {
            marginTop: '5px',
        },
    },
    commonStyleHead1: {
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media screen and (max-width: 600px)': {
            marginTop: '5px',
        },
    },
    gridmain: {
        gap: '10px',
        display: 'grid',

        '@media screen and (min-width: 600px)': {
            gridTemplateRows: '1fr 2.3fr',
            gap: '10px',
            display: 'grid',
            height: 'calc(100vh - 130px)',
        },
    },

    commonStyle17: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '1%',
        height: '35vh',
        '@media screen and (max-width: 680px)': {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        '@media screen and (min-width: 760px) and (max-width: 915px)': {
            background: theme.palette.background.paper,
            height: '25vh',
        },
    },
    background: {
        // border: '1px solid red',
        '@media screen and (min-width: 600px)': {
            background: theme.palette.background.paper,
        },
    },
    commonStyle6: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media screen and (max-width: 680px)': {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
    },

    commonStyle12: {
        '@media screen and (max-width: 680px)': {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: '1vh',
        },
    },
    rightbox: {
        paddingTop: '20px',
        height: '70.6vh',
        '@media screen and (max-width: 915px)': {
            height: '50vh',
        },
    },

    commonStyle345: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70%',
        '@media screen and (max-width: 780px)': {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
    },
    commonStyle2: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    commonStyle3: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingRight: '5px',
        '@media screen and (max-width: 780px)': {
            flexDirection: 'column',
            alignItems: 'normal',
        },
    },
    commonStyle4: {
        display: 'flex',
        justifyContent: 'flex-start',
        '@media screen and (max-width: 780px)': {
            flexDirection: 'column',
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            flexDirection: 'unset',
        },
    },
    commonStyle5: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '@media screen and (max-width: 779.5px)': {
            flexDirection: 'column',
            alignItems: 'normal',
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            flexDirection: 'unset',
        },
    },

    leaveHistoryTableHover: {
        border: 'none',
        '&:hover': {
            boxShadow: `0 4px 10px 0 rgb(0,0,0, 0.1)`,
            color: theme.palette.primary.main,
            border: 'none',
            outline: 'none',
            borderRadius: 5,
            cursor: 'pointer',
            backgroundColor: theme.palette.info.main,
        },
    },
    gridCommonStyle1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        marginTop: '5px',

        // padding: '2%',
        // border: '1px solid red'
    },

    leaveHistoryCardTitle: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.05em',
        color: theme.palette.secondary.contrastText,
    },
    headerStyle: {
        backgroundColor: theme.palette.background.paper,
        '@media screen and (min-width: 800px)': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2%',
        },
        '@media screen and (max-width: 780px)': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    headerTitle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '16px',
        lineHeight: '25px',
        letterSpacing: '0.05em',
        '@media screen and (max-width: 780px)': {
            color: theme.palette.text.primary,
        },
        '@media screen and (min-width: 1024px)': {
            paddingLeft: '1%',
            color: theme.palette.text.primary,
        },
    },
    headerTitle2: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '16px',
        lineHeight: '25px',
        letterSpacing: '0.05em',
        paddingLeft: '7%',
        '@media screen and (max-width: 780px)': {
            color: theme.palette.text.primary,
        },
        '@media screen and (min-width: 1024px)': {
            paddingLeft: '1%',
            color: theme.palette.text.primary,
        },
    },

    headerTitle1: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '16px',
        lineHeight: '25px',
        letterSpacing: '0.05em',
        paddingLeft: '5%',
        paddingTop: '5%',
        color: theme.palette.text.primary,
    },
    historytab: {
        height: '50%',
        // marginTop: 10,
        borderRadius: '5px',
        '@media screen and (min-width: 769px)': {
            // height: 'auto',
        },

        '@media screen and (max-width: 600px)': {},
        '@media only screen and (min-width: 1023px) and (max-width: 1300px)': {},
        '@media only screen and (min-width: 601px) and (max-width: 830px)': {},
    },
    insights: {
        height: '80%',
        padding: '0px 0px 0px 10px',
        width: '100%',

        '@media screen and (min-width: 769px)': {
            height: '80vh',
            background: theme.palette.background.paper,
        },

        // background: theme.palette.background.paper,
        '@media only screen and (max-width: 545px)': {
            // border: '1px solid green',
            padding: 3,
            margin: 0,
            // marginRight: 5,
            // padding: '15px 15px 15px 15px',
        },
    },
    bg: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '80%',
        // border: '1px solid red',
        '@media screen and (min-width: 769px)': {
            height: 'calc(100vh - 130px)',
        },
    },
    insightbg: {
        background: theme.palette.background.paper,
        width: '100%',
    },

    tableCellStyle: {
        borderBottom: 'none',
    },
    pieChartTitle: {
        fontSize: '16px',
        lineHeight: '35px',
        letterSpacing: '0.02em',
        color: theme.palette.text.primary,
    },
    tableScrollbar: {
        boxShadow: 'none',
        maxHeight: '220px',
        padding: '0px 10px 5px 10px',
        // border: '1px solid green',

        '&::-webkit-scrollbar': {
            width: 3,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.grey,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },
    filterBtn: {
        width: '80%',
        height: 35,
        fontSize: 14,
        fontWeight: 600,
        textTransform: 'capitalize',
        border: '1px solid #C4C4C4',
        padding: 0,
        '&:hover, &:focus': {
            outline: 'none',
            background: '#fff',
        },
        '@media screen and (min-width: 768px) and (max-width: 1024px)': {
            fontSize: 12,
            padding: 0,
        },
    },
    currentSelect: {
        color: '#fff',
        background: theme.palette.primary.main,
        border: 'none',
        outline: 'none',
        '&:hover, &:focus': {
            color: '#fff',
            background: theme.palette.primary.main,
            border: 'none',
            outline: 'none',
        },
    },
    selectedMenus: {
        color: '#000',
        '&:hover, &:focus, &:active': {
            background: theme.palette.primary.main,
            border: 'none',
            outline: 'none',
            color: '#fff',
            '& a': {
                color: '#fff',
            },
        },
    },
    card: {
        width: 300,
        backgroundColor: '#fff',
        boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.5)',
        color: theme.palette.primary.main,
        padding: 50,
        zIndex: 1,
    },
    mobileUICardTheme: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2%',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: 10,
    },
    filterButtonWidth: {
        '@media screen and (max-width: 780px)': {
            width: '100%',
        },
        '@media only screen and (min-width: 1023.5px) and (max-width: 1364.5px)': {
            width: '40%',
        },
        '@media only screen and (min-width: 767.5px) and (max-width: 1023.5px)': {
            width: '40%',
        },
    },
    accordionGridStyle: {
        '@media only screen and (min-width: 1023.5px) and (max-width: 1364.5px)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
        },
        '@media screen and (max-width: 780px)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        '@media screen and (min-width: 1024.5px)': {
            display: 'grid',
            gap: '10px',
            gridTemplateColumns: '1fr 2fr',
        },
    },

    accordionGridStyle1: {
        marginTop: '10px',

        '@media only screen and (min-width: 1023.5px) and (max-width: 1364.5px)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
        },
        '@media screen and (max-width: 780px)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        '@media screen and (min-width: 1024.5px)': {
            display: 'grid',
            gap: '10px',
            gridTemplateColumns: '2fr 1fr',
        },
    },
    editPen: {
        width: 12,
        height: 12,
        background: '#FFFFFF',
        border: 'none',
        '&:hover, &:focus': {
            background: '#FFFFFF',
            border: 'none',
            outline: 'none !important',
        },
    },
    userImg: {
        borderColor: theme.palette.background.default,
        borderRadius: '50%',
        height: '110px',
        width: '110px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    formLabel: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '16px',
        zIndex: 0,
    },
    inputbox: {
        display: 'flex',
        padding: '10px 0px',
        fontSize: '14px',
    },
    uploadfileDiv: {
        padding: '10px 0px',
    },
    image: {
        '@media only screen and (min-width: 660px)': {
            maxWidth: '100%',
            height: '250px',
            marginBottom: '10%',
            marginTop: '10px',
            '& img': {
                maxWidth: '100%',
                height: '250px',
            },
        },
        '@media only screen and (max-width: 650px)': {
            maxWidth: '100%',
            height: '210px',
            marginBottom: '10%',
            marginTop: '10px',
            '& img': {
                maxWidth: '100%',
                height: '210px',
            },
        },
    },
    cancelBtn: {
        textTransform: 'capitalize',
        fontFamily: theme.typography.fontFamily,
        marginRight: '20px',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
        '&:active,&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            outline: 'none',
        },
    },
    submitBtn: {
        textTransform: 'capitalize',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
    },
    formLabelForm: {
        width: '100%',
        textAlign: 'left',
        color: theme.palette.text.primary,
        fontWeight: 700,
        fontSize: '15px',
        fontFamily: theme.typography.fontFamily,
        opacity: 0.6,
        '@media (max-width: 650px)': {
            textAlign: 'left',
            paddingBottom: 0,
        },
    },
    typotext: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: '15px',
        fontFamily: theme.typography.fontFamily,
    },
    formControlLeft: {
        width: '100%',
        '& div': {
            '& input': {
                fontFamily: 'Nunito Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                letterSpacing: '0.03em',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
            },
        },
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '@media only screen and (max-width: 650px)': {
            width: '100%',
        },
    },
    formControlRight: {
        width: '100%',
        '& div': {
            '& input': {
                fontFamily: 'Nunito Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                letterSpacing: '0.03em',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
            },
        },
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '@media only screen and (max-width: 650px)': {
            width: '100%',
        },
    },
    smallField: {
        padding: 0,
        '@media screen and (max-width: 650px)': {
            padding: '10px 0px',
        },
    },
    smallField1: {
        paddingTop: 10,
        '@media screen and (max-width: 650px)': {
            padding: '10px 0px',
        },
    },
    field: {
        padding: '10px 0px',
        '@media screen and (max-width: 650px)': {
            padding: '10px 0px',
        },
    },
    editicon: {
        position: 'absolute',
        right: 5,
        top: 5,
        transform: 'scale(0.9)',
        outline: 'none',
        color: theme.palette.text.primary,
        '& svg': {
            fontSize: 13,
        },
        '&:focus': {
            color: theme.palette.text.primary,
            outline: 'none',
        },
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            outline: 'none',
            transform: 'scale(1.1)',
        },
        zIndex: 10,
    },
    backArrowStyle: {
        cursor: 'pointer',
        color: theme.palette.text.primary,
    },
    approverProfileGrid: {
        backgroundColor: theme.palette.background.paper,
        padding: '20px 0px 20px 0px',

        // border: '5px solid yellow',
        // height: 'calc(100vh - 130px)',
        // borderRadius: 5,
        display: 'grid',
    },
    approverProfileGrid1: {
        // backgroundColor: theme.palette.background.paper,
        padding: '0px 0px 0px 0px',
        // borderRadius: 5,
        display: 'grid',
        '@media screen and (min-width: 780px) and (max-width: 1023px)': {
            marginTop: '10px',
        },
        '@media screen and (min-width: 600px)': {
            backgroundColor: theme.palette.background.paper,
        },
    },
    tabbg: {
        backgroundColor: theme.palette.background.paper,
    },
    rightgrid: {
        // display: 'grid',
        height: '78vh',
        backgroundColor: theme.palette.background.paper,
    },
    fab: {
        background: theme.palette.background.paper,
        color: theme.palette.secondary.contrastText,
        height: 22,
        cursor: 'pointer',
        width: 20,
        fontSize: 12,
        display: 'flex',
        '&:hover': {
            color: theme.palette.secondary.contrastText,
            transform: 'scale(1.2)',
        },
    },
    skeletonStyle: {
        boxShadow: 'none',
        height: '270px',
        '@media screen and (max-width: 1024px)': {
            height: 'calc(100vh - 400px)',
        },
    },

    teamList: {
        maxHeight: '100px',
        overflowY: 'auto',
        margin: '0px 5px',
        '&::-webkit-scrollbar': {
            width: 2,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.secondary.main,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },
    teamMenuTitle: {
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '15px',
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'bold',
        textAlign: 'center',
        padding: '0px 10px',
    },
    icon: {
        fontSize: '30px',
        color: theme.palette.primary.main,
    },
    formLabelStyle: {
        color: theme.palette.primary.contrastText,
        fontWeight: 600,
        fontSize: '14px',
        textTransform: 'capitalize',
        marginBottom: 10,
        '@media screen and (max-width: 770px)': {
            lineHeight: '22px',
        },
        paddingTop: 20,
        width: '100%',
    },
    formControl: {
        width: '100%',
    },
    inputSelectStyleOther: {
        width: '100%',
        height: 35,
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '0.03em',
        color: 'theme.palette.text.primary',
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
        '@media screen and (max-width: 770px)': {
            width: '100%',
        },
        '& svg': {
            color: theme.palette.primary.contrastText,
        },
    },
    fieldDivDateTime: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: 10,
        marginBottom: '15px',
        '@media screen and (max-width: 820px)': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
    },
    mobileToPart: {
        marginTop: 0,
        '@media screen and (max-width: 768px)': {
            marginTop: 15,
        },
    },
    btn2: {
        float: 'right',
        marginTop: 10,
        textTransform: 'capitalize',
        width: 100,
        height: 35,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        outline: 'none',
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
        },
        marginBottom: 15,
    },
    btnFixed: {
        textTransform: 'capitalize',
        width: 150,
        height: 30,
        color: theme.palette.common.white,
        '&:hover': {
            outline: 'none',
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            outline: 'none',
        },
        '@media screen and (max-width: 650px)': {
            width: '100%',
            margin: '5px 0',
        },
    },
    resetButton: {
        '&:focus': {
            outline: 'none',
        },
    },
    buttonStyle: {
        whiteSpace: 'nowrap',
        width: 100,
        height: 35,
        marginTop: 10,
        marginRight: 10,
        '& span': {
            fontWeight: 'bold',
            fontStyle: 'normal',
            textTransform: 'capitalize',
            letterSpacing: '0.05em',
            color: theme.palette.text.secondary,
        },
    },
    filterList: {
        maxHeight: 160,
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: 2,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.secondary.main,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            height: 20,
        },
    },

    filterName: {
        padding: '2px 10px',
        width: '160px',
        // color: theme.palette.primary.contrastText,
        background: theme.palette.background.paper,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        fontSize: 14,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#3C48B5',
            color: '#fff',
        },
    },
    pixels: {
        height: '19px',
        width: '19px',
        '@media screen and (max-width: 1280px)': {
            height: '13px',
            width: '13px',
        },
    },

    monthName: {
        width: '900px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        '@media screen and (max-width: 1280px)': {
            width: '700px',
        },
    },

    monthStyle: {
        marginLeft: '31px',
        marginRight: '31px',
        fontSize: '15px',
        color: theme.palette.primary.contrastText,
        '@media screen and (max-width: 1280px)': {
            marginLeft: '18px',
            marginRight: '18px',
            fontSize: '13px',
        },
    },

    pixelDiv: {
        width: '1050px',
        height: '135px',
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        '@media screen and (max-width: 1280px)': {
            width: '700px',
            height: '95px',
        },
    },

    heatMapDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '250px',
        marginTop: '2%',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        '@media screen and (max-width: 1280px)': {
            height: '150px',
        },
    },
    heatMapDiv2: {
        display: 'flex',
        overflowX: 'scroll',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        width: 'calc(100vw - 40px)',
        marginTop: '2%',
    },
    heatMapContainer: {
        width: 'calc(100vw - 20px)',
        backgroundColor: theme.palette.background.paper,
        marginTop: '2%',
        padding: '10px',
    },
}));

const timeOffStatusStyles = makeStyles(theme => ({
    numbersStyle: {
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        letterSpacing: '0.03em',
    },
    numberColorOne: {
        color: '#088843',
    },
    numberColorTwo: {
        color: '#CE3B41',
    },
    numberColorThree: {
        color: '#3C48B5',
    },
    titleOneStyle: {
        fontSize: '16px',
        lineHeight: '35px',
        letterSpacing: '0.02em',
        color: theme.palette.text.primary,
    },
    titleStyle: {
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '30px',
        letterSpacing: '0.03em',
        color: theme.palette.text.primary,
    },
    selectStyle: {
        // color: '#3C48B5',
        '&::before': {
            color: '#3C48B5',
            borderBottom: '1px solid #3C48B5',
        },
        fontWeight: 600,
        '& select': {
            width: '60px',
        },
        '& .MuiSelect-icon': {
            color: theme.palette.primary.contrastText,
        },
    },
}));

const profileStyle = makeStyles(theme => ({
    nameStyle: {
        textTransform: 'capitalize',
        fontSize: '18px',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        letterSpacing: '0.02em',
        lineHeight: '18px',
        color: theme.palette.text.primary,
    },

    designationTwoStyle: {
        fontSize: '14px',
        fontWeight: 600,
        color: theme.palette.text.primary,
        letterSpacing: '0.03em',
        lineHeight: '27px',
        textTransform: 'capitalize',
    },
    nameStyle1: {
        textTransform: 'capitalize',
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        letterSpacing: '0.02em',
        lineHeight: '18px',
        color: theme.palette.text.primary,
    },

    designationTwoStyle1: {
        fontSize: '12px',
        fontWeight: 600,
        color: theme.palette.text.primary,
        letterSpacing: '0.03em',
        lineHeight: '27px',
        textTransform: 'capitalize',
    },
    rolesStyle: {
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '0.025em',
        lineHeight: '22px',
        width: '95px',
        textAlign: 'right',
        color: theme.palette.text.primary,
    },
    rolesStyle1: {
        fontSize: '13px',
        fontWeight: 'bold',
        letterSpacing: '0.025em',
        lineHeight: '22px',
        // width: '90px',
        textAlign: 'left',
        color: theme.palette.text.primary,
        // border: '1px solid red',
    },
    rolesValuesStyle: {
        fontSize: '13px',
        letterSpacing: '0.02em',
        lineHeight: '22px',
        color: theme.palette.text.primary,
    },
    rolesValuesStyle1: {
        fontSize: '11px',
        letterSpacing: '0.02em',
        lineHeight: '22px',
        color: theme.palette.text.primary,
        paddingTop: '1px',
        // border: '1px solid red',
    },
    inputStyle: {
        color: theme.palette.text.primary,
        '& fieldset': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
            height: '35px',
            marginTop: '3px',
        },
    },
}));

const leaveTableStyle = makeStyles(theme => ({
    leaveTableBackground: {
        // paddingLeft: 10,
        // paddingRight: 10,
        paddingTop: 5,

        '@media screen and (max-width: 780px)': {},
        '@media screen and (min-width: 760px) and (max-width: 1024px)': {
            backgroundColor: theme.palette.background.paper,
            height: 'auto',
        },
        '@media screen and (min-width: 1024.5px)': {
            backgroundColor: theme.palette.background.paper,
            height: 'auto',
        },
    },
    leaveTableBackground1: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        height: '43vh',
        // border: '1px solid black',
        '@media screen and (max-width: 680px)': {
            height: '0vh',
        },
        '@media screen and (min-width: 760px) and (max-width: 915px)': {
            background: theme.palette.background.paper,
            height: '30vh',
        },
        '@media screen and (min-width: 1024.5px)': {
            backgroundColor: theme.palette.background.paper,
        },
    },

    leaveTableColumns: {
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '0.02em',
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
    },
    leaveTableColumnsReason: {
        maxWidth: '180px',
        width: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '0.02em',
        paddingRight: 10,
        color: theme.palette.text.primary,
    },
    leaveTableCards: {
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '0.02em',
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
    },
    filterButton: {
        letterSpacing: '0.05em',
        textTransform: 'initial',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        whiteSpace: 'nowrap',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        '&:focus': {
            outline: 'none',
        },
    },

    menuitemtext: {
        color: theme.palette.primary.contrastText,
        background: theme.palette.background.paper,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        '&:hover': {
            color: '#fff',
            backgroundColor: '#3C48B5',
        },
    },
}));

const skeletonStyles = makeStyles(theme => ({
    div1Grid: {
        display: 'grid',
        gridTemplateColumns: '1fr',
    },
    div1Components: {
        display: 'flex',
        padding: '10px',
        backgroundColor: theme.palette.background.paper,
    },

    profileGrid1: {
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        backgroundColor: theme.palette.background.paper,
        minHeight: 150,
        marginBottom: '5px',
    },

    mobileUILeaveCards: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        padding: '5%',
        margin: '1%',
        minHeight: '200px',
    },
    employeeProfile: {
        backgroundColor: theme.palette.background.paper,
        padding: '20px',
        minHeight: 'calc(100vh - 70%)',
    },
    accordionOne: {
        backgroundColor: theme.palette.background.paper,
        padding: '20px',
        minHeight: '30px',
    },
    accordionTwo: {
        backgroundColor: theme.palette.background.paper,
        padding: '20px',
        minHeight: 'calc(100vh - 200px)',
    },
    skeleton: {
        display: 'grid',
        width: '100%',
        justifyItems: 'center',
        gap: '40px',
        height: '75vh',
        background: theme.palette.background.paper,
        '@media screen and (min-width: 760px) and (max-width: 915px)': {
            background: theme.palette.background.paper,
            height: '45vh',
        },
    },
}));

export { useStyles, timeOffStatusStyles, profileStyle, leaveTableStyle, skeletonStyles };
