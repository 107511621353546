import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            height: 'calc(100vh - 50px)',
            display: 'flex',
            '@media only screen and (max-width:899px)': {
                flexDirection: 'column',
                marginBottom: 40,
                height: 'auto',
            },
        },
        leftSide: {
            width: '33%',
            height: '100%',
            display: 'grid',
            gridRowGap: '10px',
            '@media screen and (max-width: 900px)': {
                width: '100%',
            },
        },
        accordiondiv: {
            height: 'calc(100vh - 310px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
        },
        name: {
            color: theme.palette.secondary.contrastText,
            letterSpacing: '0.02em',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
        },
        date: {
            color: theme.palette.text.primary,
            letterSpacing: '0.02em',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
        },
        title: {
            color: theme.palette.text.primary,
            letterSpacing: '0.02em',
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
        },
        btn: {
            position: 'relative',
            display: 'flex-end',
            textAlign: 'center',
            height: '35px',
            textTransform: 'capitalize',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '@media only screen and (max-width:899px)': {
                position: 'fixed',
                bottom: 0,
                width: '100%',
                height: 42,
                borderRadius: 0,
                marginLeft: -11,
            },
        },
        resetButton2: {
            position: 'relative',
            display: 'flex-end',
            textAlign: 'center',
            height: '35px',
            textTransform: 'capitalize',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.contrastText,
            '&:focus': {
                outline: 'none',
            },
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '@media only screen and (max-width:899px)': {
                position: 'fixed',
                bottom: 0,
                width: '100%',
                height: 42,
                borderRadius: 0,
                marginLeft: -11,
            },
        },
        tab: {
            display: 'flex',
            justifyContent: 'center',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            textTransform: 'initial',
            minHeight: '50%',
            fontSize: '15px',
            '&.Mui-selected': {
                outline: 'none',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
        },
        tabbar: {
            width: '66%',
            marginRight: '1%',
            background: theme.palette.background.paper,
            padding: 10,
            '@media only screen and (max-width: 900px)': {
                minHeight: 0,
                width: '100%',
                height: 400,
                padding: 0,
                marginBottom: 10,
            },
        },
        heading: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: 16,
        },
        GifiImage: {
            width: '116px',
            height: '116px',
            margin: 20,
            cursor: 'pointer',
            borderRadius: 10,
            '@media only screen and ( min-width: 1600px)': {
                width: '120px',
                height: '120px',
                margin: 15,
                cursor: 'pointer',
                borderRadius: 10,
            },
        },
        searchGifRounded: {
            width: '57.5%',
            height: 30,
            borderRadius: '5px',
            alignSelf: 'center',
            marginBottom: 5,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 10px',
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            outline: 'none',
            // zIndex: 99,
            backgroundColor: theme.palette.background.paper,
            '@media only screen and ( max-width: 450px)': {
                width: '90%',
            },
        },
        searchBarInput: {
            flex: 1,
            outline: 'none',
            border: 'none',
            fontSize: 16,
            fontFamily: 'Nunito Sans',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
        },
        filterList2: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            margin: 0,
        },
        cardData: {
            height: 'calc(100vh - 150px)',
            display: 'flex',
            padding: '35px 10px 0px 10px',
            alignItems: 'center',
            '@media screen and (max-width: 900px)': {
                height: 355,
                padding: 10,
            },
        },
        card: {
            borderRadius: 12,
            height: '100%',
            width: '92%',
            marginLeft: 10,
            marginRight: 10,
            padding: 30,
            '@media only screen and (max-width: 600px)': {
                padding: 20,
                width: '100%',
            },
        },
        image: {
            width: '30%',
            height: '50%',
            borderRadius: '5px',
            marginLeft: 56,
            marginBottom: 45,
            '@media screen and (max-width: 900px)': {
                width: 130,
            },
        },
        emojiactive: {
            height: 30,
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
            padding: '2px',
            textDecoration: 'none',
            listStyle: 'none',
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            cursor: 'pointer',
            color: theme.palette.text.primary,
        },
        footerText: {
            fontSize: 12,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            marginTop: 5,
        },
        none: {
            fontSize: '16px',
        },
        emojiPicker1: {
            width: 150,
            height: 36,
            position: 'absolute',
            top: -25,
            left: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.background.paper,
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderRadius: '15px',
        },
        empty: {
            display: 'none',
        },
        tabs: {
            width: '100%',
            height: 30,
            display: 'flex',
            overflow: 'hidden',
            justifyContent: 'flex-start',
            color: theme.palette.text.primary,
        },
        activeTab: {
            height: 30,
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
            padding: '10px',
            textDecoration: 'none',
            listStyle: 'none',
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            cursor: 'pointer',
            color: theme.palette.text.primary,
        },
        reactionTab: {
            height: 30,
            display: 'flex',
            alignItems: 'center',
            fontSize: 15,
            padding: '10px',
            textDecoration: 'none',
            listStyle: 'none',
            cursor: 'pointer',
            color: theme.palette.text.primary,
        },
        emojiCount: {
            fontSize: 13,
            marginLeft: 2,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
        },
        reactionList: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.text.primary,
        },
        scroll: {
            width: '100%',
            height: 'calc(100vh - 260px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
                display: 'none',
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
            '@media screen and (width: 1024px) and (height: 600px)': {
                height: 'calc(100vh - 230px)',
            },
            '@media screen and (width: 1280px) and (height: 800px)': {
                height: 'calc(100vh - 410px)',
            },
            '@media only screen and ( min-width: 1400px)': {
                height: 'calc(100vh - 470px)',
            },
        },

        commentBox: {
            width: '100%',
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
            padding: 10,
            height: 40,
            outline: 'none',
            border: 'none',
            background: 'transparent',
        },

        commentBtn: {
            width: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'transparent',
        },
        modal: {
            height: 330,
            overflowY: 'auto',
            // marginBottom: 15,
            '&::-webkit-scrollbar': {
                width: 4,
                display: 'none',
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
        },
        dobPaper: {
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.background.paper,
            borderRadius: 5,
            padding: '15px',
        },
        addTitle: {
            width: '100%',
            fontSize: 16,
            fontWeight: 600,
            color: '#888888',
            fontFamily: theme.typography.fontFamily,
        },
        dobBtn: {
            textTransform: 'capitalize',
            position: 'relative',
            width: 135,
            height: 30,
            marginRight: 10,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        dobBtn2: {
            textTransform: 'capitalize',
            position: 'relative',
            width: 135,
            height: 30,
            color: theme.palette.common.white,
            '&:hover': {
                outline: 'none',
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                outline: 'none',
            },
        },
        backArrow: {
            cursor: 'pointer',
            color: theme.palette.text.primary,
            marginRight: 0,
        },
        form: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: 3,
            padding: '5px 10px',
        },
        savebtn: {
            color: '#fff',
            height: 36,
            backgroundColor: theme.palette.primary.main,
            outline: 'none',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            marginLeft: 10,
            '&:hover': {
                color: '#fff',
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
            },
            '&:focus': {
                color: '#fff',
                backgroundColor: theme.palette.primary.main,
                outline: 'none',
            },
        },
        filterBtn: {
            height: 35,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.background.paper,
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.primary.contrastText}`,
            marginLeft: 10,
            outline: 'none',
            '&:hover': {
                outline: 'none',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        filterName: {
            padding: '2px 10px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
            },
        },
        fontsty: {
            textAlign: 'center',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '15px',
            color: theme.palette.text.primary,
        },
        cell: {
            background: '#DCDFFF',
            fontSize: 14,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            color: '#262F83',
            border: 'none',
        },
        tableBorder: {
            position: 'relative',
            width: '100%',
            // top: 10,
            marginTop: 25,
            height: 'calc(100vh - 275px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        cell1: {
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            border: 'none',
        },
        notfoundImage: {
            maxWidth: '75%',
            height: '180px',
        },
        notfoundText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: 16,
            fontWeight: 600,
        },
        backBtn: {
            height: 40,
            width: '50%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: '#FFFFFF',
            color: theme.palette.primary.main,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            borderRadius: 0,
            zIndex: 99,
            '&:hover, &:focus': {
                textDecoration: 'none',
                border: '1px solid #3C48B5',
                outline: 'none',
                backgroundColor: '#FFFFFF',
                color: theme.palette.primary.main,
            },
        },
        sendBtn: {
            height: 40,
            width: '50%',
            position: 'fixed',
            bottom: 0,
            right: 0,
            textTransform: 'capitalize',
            padding: '0 30px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '16px',
            border: '1px solid #3C48B5',
            borderRadius: 0,
            color: '#FFFFFF',
            zIndex: 99,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                outline: 'none !important',
                border: '1px solid #3C48B5',
            },
        },
        downloadBtn: {
            textTransform: 'capitalize',
            marginTop: 5,
            marginBottom: 5,
            height: 40,
            width: 200,
            color: theme.palette.primary.main,
            marginRight: 10,
            backgroundColor: '#fff',
            '&:hover, &:focus': {
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
            },
        },
        uploadBtn: {
            textTransform: 'capitalize',
            margin: 5,
            height: 40,
            width: 200,
            color: '#FFFFFF',
            backgroundColor: theme.palette.primary.main,
            '&:hover, &:focus': {
                color: '#FFFFFF',
                backgroundColor: theme.palette.primary.main,
            },
        },
        innerContainer: {
            minHeight: 'calc(100vh - 400px)',
            maxHeight: 'calc(100vh - 300px)',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.secondary.main,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 20,
            },
        },
        emailList: {
            maxHeight: 'calc(100vh - 300px)',
            height: '100%',
            color: '#666666',
            fontFamily: theme.typography.fontFamily,
            '&:last-child th, &:last-child td': {
                borderBottom: 0,
            },
        },
        titles: {
            color: theme.palette.secondary.contrastText,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            marginLeft: 8,
        },
        item2: {
            marginTop: 3,
            fontSize: 14,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            border: 'none',
            marginLeft: 8,
        },
        dashedContainer: {
            border: '1px dashed #ABABAB',
            width: '100%',
            padding: '70px 50px',
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                padding: '50px 20px',
            },
        },
        bulkContainer: {
            padding: '20px 20px',
            minHeight: 'calc(100vh - 350px)',
            maxHeight: 'calc(100vh - 300px)',
            background: theme.palette.background.paper,
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                minHeight: 'calc(100vh - 350px)',
                maxHeight: 'calc(100vh - 300px)',
                padding: 50,
            },
        },

        dragTitle: {
            width: '100%',
            fontSize: 15,
            fontWeight: theme.typography.fontWeightMedium,
            color: '#888888',
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
        },

        csvLink: {
            textDecoration: 'none',
            '&:hover, &:focus': {
                textDecoration: 'none',
            },
        },
        send: {
            textTransform: 'capitalize',
            height: 40,
            minWidth: 150,
            color: '#FFFFFF',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: '#FFFFFF',
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                outline: 'none !important',
                color: '#FFFFFF',
                backgroundColor: theme.palette.primary.main,
            },
        },
        forwardBtn: {
            width: '30px',
            height: '30px',
            cursor: 'Pointer',
            color: '#3D49B6',
            '@media only screen and (max-width: 600px)': {
                width: '25px',
                height: '25px',
            },
        },
        avatarimage: {
            height: 30,
            width: 30,
            fontSize: 12,
            border: 'none',
            backgroundColor: '#EDEFFA',
            color: '#444444',
            textTransform: 'uppercase',
        },
        item1: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
        },
        item: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            padding: 7,
        },
        car: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: 'calc(98vw - 15px)',
            borderRadius: 5,
            height: 230,
            padding: 20,
        },
        modalPage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        },
        data: {
            width: '90%',
            height: '85%',
            background: theme.palette.background.paper,
            padding: 20,
            position: 'relative',
        },
        modalTitle: {
            fontStyle: 'normal',
            fontSize: '16px',
            color: theme.palette.text.primary,
            fontWeight: 600,
        },
        inputTag: {
            color: 'white',
            textColor: 'white',
            calendarTextColor: 'grey',
            selectTextColor: 'white',
            '& div': {
                fontSize: '14px',
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
            calendarYearBackgroundColor: 'white',
            margin: 0,
        },
        cele: {
            '@media only screen and (max-width:900px)': {
                display: 'flex',
                paddingTop: 10,
                alignItems: 'center',
            },
        },
        celeDate: {
            '@media only screen and (max-width:900px)': {
                width: '50%',
                paddingLeft: 5,
            },
        },
        maindivFirst: {
            height: 'calc(100vh - 180px)',
            padding: 10,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                width: '0.1px',
            },
            '@media only screen and ( max-width: 900px)': {
                height: 500,
                padding: 0,
                paddingTop: 10,
            },
        },
        second: {
            display: 'flex',
            justifyContent: 'space-between',
            '@media only screen and (max-width:900px)': {
                flexDirection: 'column',
            },
        },
        enter: {
            width: '80%',
            paddingRight: 50,
            '@media only screen and (max-width:900px)': {
                width: '100%',
                paddingRight: 0,
                marginBottom: 10,
            },
        },
        update: {
            height: 500,
            paddingTop: 10,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                width: '0.1px',
            },
        },
        btnDiv: {
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative',
            '@media screen and (min-width: 300px) and (max-width: 899.99px)': {
                marginLeft: '20px',
            },
        },
        btn1: {
            height: 40,
            textTransform: 'capitalize',
            padding: '0 45px',
            right: 20,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:active,&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
            },
        },
        btn2: {
            height: 40,
            textTransform: 'capitalize',
            padding: '0 45px',
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
                marginLeft: 0,
            },
        },
        back: {
            position: 'relative',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
            // paddingTop: 8,
            marginLeft: 8,
            color: theme.palette.text.primary,
            letterSpacing: 0.9,
            '@media screen and (max-width: 900px)': {
                padding: 0,
            },
        },
        mobile: {
            width: '100%',
            height: '100%',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
        },
        input: {
            width: '100%',
            border: `1px solid ${theme.palette.primary.contrastText}`,
            height: 40,
            display: 'flex',
            alignItems: 'center',
        },
        searchBorder: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&:hover fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
        },
        searchIcon: {
            '@media screen and (min-width:912px) and (max-width: 1024px)': {
                marginLeft: 10,
            },
        },
        searchInput: {
            height: 35,
            width: '100%',
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '&:hover, &:focus': {
                outline: 'none',
                color: theme.palette.text.primary,
            },
            // '@media screen and (min-width: 768px)': {
            //     marginLeft: '10px',
            // },
            '@media screen and (min-width: 768px) and (max-width: 1024px)': {
                fontSize: 12,
                padding: '0px 14px',
                '&:hover, &:focus': {
                    outline: 'none',
                    color: theme.palette.text.primary,
                },
            },
        },
    }),
);
