import { createSlice } from '@reduxjs/toolkit';

export const userStatusSlice = createSlice({
    name: 'userStatus',
    initialState: { role: '' },
    reducers: {
        clearUserStatus: (state: any) => {
            state.role = '';
        },
        updateUserStatus: (state: any, action: any) => {
            state.role = action.payload?.role;
        },
    },
});

export const { updateUserStatus, clearUserStatus } = userStatusSlice.actions;
export default userStatusSlice.reducer;
