import React, { useContext } from 'react';
import useStyles from './AddLeaveStyle';
import { Typography } from '@material-ui/core';
// import Arrow from './leftArrow.png';
import Tabs from '../Tabbars/Tabs';
import Calender from '../calendar/smallCalender';
import logo from '../../Assets/images/profile1.png';
import EmployeeForm from './Form';
import {
    ToStringFormat,
    // DateDifference,
    // getTimeDifference,
    // convertTime,
    getTimeDifferenceMinute,
    toAWSDateFormat,
    // convertTimeInTZ,
} from '../../helpers/date';
import { isoToLocal, isoToLocalTime } from 'helpers/timezone';
import AuthContext from '../../context/AuthContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface AddLeaveProps {
    setStep?: any;
    holidays?: any;
    organizationLeaves?: any;
    employeeLeaves?: any;
    data?: any;
    leaves?: any;
    workWeek?: any;
    userSubscriptionDetails?: any;
}

interface EMPProps {
    employee?: any;
}
const Employee = (props: EMPProps) => {
    const classes = useStyles();
    const mycontext = useContext(AuthContext);
    return (
        <div className={classes.employee}>
            <img
                src={props.employee.Employee?.profileImage === null ? logo : props.employee?.Employee?.profileImage}
                alt={props.employee.Employee?.firstName + ' ' + props.employee.Employee?.lastName}
            />
            <div className={classes.employeeData}>
                <Typography className={classes.employeeName}>
                    {props.employee.Employee?.firstName + ' ' + props.employee.Employee?.lastName}
                </Typography>
                {(props.employee?.startTime === 'null' || props.employee?.startTime === null) &&
                (props.employee?.endTime === 'null' || props.employee?.endTime === null) ? (
                    <div className={classes.employeeDates}>
                        {/* {DateDifference(props.employee.startDate, props.employee.endDate) >= 1 ? (
                            <span className={classes.date}>
                                from:{' '}
                                {ToStringFormat(
                                    isoToLocal(props.employee.isoString?.startTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}{' '}
                                to:{' '}
                                {ToStringFormat(
                                    isoToLocal(props.employee.isoString?.endTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}
                            </span>
                        ) : (
                            <span className={classes.date}>
                                on:{' '}
                                {ToStringFormat(
                                    isoToLocal(props.employee.isoString?.startTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}
                            </span>
                        )}{' '} */}
                        {parseInt(props.employee.noOfHours) === mycontext.workingHours ? (
                            toAWSDateFormat(isoToLocal(props.employee.isoString?.startTime, mycontext.country)) ===
                            toAWSDateFormat(isoToLocal(props.employee.isoString?.endTime, mycontext.country)) ? (
                                <span className={classes.date}>
                                    on:{' '}
                                    {ToStringFormat(
                                        isoToLocal(props.employee.isoString?.startTime, mycontext.country),
                                        mycontext.dateFormat,
                                    )}
                                </span>
                            ) : (
                                <span className={classes.date}>
                                    from:{' '}
                                    {ToStringFormat(
                                        isoToLocal(props.employee.isoString?.startTime, mycontext.country),
                                        mycontext.dateFormat,
                                    )}{' '}
                                    {isoToLocalTime(props.employee.isoString?.startTime, mycontext.country)} to:{' '}
                                    {ToStringFormat(
                                        isoToLocal(props.employee.isoString?.endTime, mycontext.country),
                                        mycontext.dateFormat,
                                    )}{' '}
                                    {isoToLocalTime(props.employee.isoString?.endTime, mycontext.country)}
                                </span>
                            )
                        ) : isoToLocalTime(props.employee.isoString?.startTime, mycontext.country) === '12:00 AM' ? (
                            <span className={classes.date}>
                                from:{' '}
                                {ToStringFormat(
                                    isoToLocal(props.employee.isoString?.startTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}{' '}
                                to:{' '}
                                {ToStringFormat(
                                    isoToLocal(props.employee.isoString?.endTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}
                            </span>
                        ) : (
                            <span className={classes.date}>
                                from:{' '}
                                {ToStringFormat(
                                    isoToLocal(props.employee.isoString?.startTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}{' '}
                                {isoToLocalTime(props.employee.isoString?.startTime, mycontext.country)} to:{' '}
                                {ToStringFormat(
                                    isoToLocal(props.employee.isoString?.endTime, mycontext.country),
                                    mycontext.dateFormat,
                                )}{' '}
                                {isoToLocalTime(props.employee.isoString?.endTime, mycontext.country)}
                            </span>
                        )}
                        <span className={classes.daysCount}>
                            {parseInt(props.employee.noOfHours) > mycontext.workingHours
                                ? `${parseInt(props.employee.noOfHours) / mycontext.workingHours} Days`
                                : `${parseInt(props.employee.noOfHours) / mycontext.workingHours} Day`}
                        </span>
                    </div>
                ) : toAWSDateFormat(isoToLocal(props.employee.isoString?.startTime, mycontext.country)) ===
                  toAWSDateFormat(isoToLocal(props.employee.isoString?.endTime, mycontext.country)) ? (
                    <div className={classes.employeeDates}>
                        <span className={classes.date}>
                            from: {isoToLocalTime(props.employee.isoString?.startTime, mycontext.country)} to:{' '}
                            {isoToLocalTime(props.employee.isoString?.endTime, mycontext.country)} on{' '}
                            {ToStringFormat(
                                isoToLocal(props.employee.isoString?.startTime, mycontext.country),
                                mycontext.dateFormat,
                            )}
                        </span>{' '}
                        <span className={classes.daysCount}>
                            {getTimeDifferenceMinute(props.employee?.startTime, props.employee?.endTime)}
                        </span>
                    </div>
                ) : (
                    <div className={classes.employeeDates}>
                        <span className={classes.date}>
                            from:{' '}
                            {ToStringFormat(
                                isoToLocal(props.employee.isoString?.startTime, mycontext.country),
                                mycontext.dateFormat,
                            )}{' '}
                            ({isoToLocalTime(props.employee.isoString?.startTime, mycontext.country)}) to:{' '}
                            {ToStringFormat(
                                isoToLocal(props.employee.isoString?.endTime, mycontext.country),
                                mycontext.dateFormat,
                            )}{' '}
                            ({isoToLocalTime(props.employee.isoString?.endTime, mycontext.country)}) on{' '}
                        </span>{' '}
                        <span className={classes.daysCount}>
                            {getTimeDifferenceMinute(props.employee?.startTime, props.employee?.endTime)}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

interface AddLeaveFormProps {
    setStep?: any;
    data?: any;
    leaves?: any;
    workWeek?: any;
    holidays?: any;
    userSubscriptionDetails?: any;
}

const AddLeaveForms = (props: AddLeaveFormProps) => {
    const components = [
        {
            label: 'Personal Time-Off',
            component: (
                <EmployeeForm
                    isRequest={false}
                    data={props.data}
                    setStep={props.setStep}
                    leaves={props.leaves}
                    workWeek={props.workWeek}
                    holidays={props.holidays}
                    userSubscriptionDetail={props.userSubscriptionDetails}
                />
            ),
        },
        {
            label: 'Time-Off for other employee',
            component: (
                <EmployeeForm
                    isRequest={true}
                    data={props.data}
                    setStep={props.setStep}
                    leaves={props.leaves}
                    workWeek={props.workWeek}
                    holidays={props.holidays}
                    userSubscriptionDetail={props.userSubscriptionDetails}
                />
            ),
        },
    ];
    return <Tabs components={components} />;
};

export default function AddLeave(props: AddLeaveProps) {
    const classes = useStyles();
    // const leaveRef: any = React.useRef(null);
    return (
        <div className={classes.main} id="addleave">
            <div className={classes.top}>
                {/* <img src={Arrow} alt="backButton" onClick={() => props.setStep(0)} className={classes.backArrow} /> */}
                <ArrowBackIcon onClick={() => props.setStep(0)} className={classes.arrowBackIcon} />
                <Typography className={classes.heading}>Time-Offs Management</Typography>
            </div>
            <div className={classes.bottom}>
                <div className={classes.left}>
                    <AddLeaveForms
                        data={props.data}
                        setStep={props.setStep}
                        leaves={props.leaves}
                        workWeek={props.workWeek}
                        holidays={props.holidays}
                        userSubscriptionDetails={props.userSubscriptionDetails}
                    />
                </div>
                <div className={classes.right}>
                    <div className={classes.calender}>
                        <Calender holidays={props.holidays} organizationLeaves={props.organizationLeaves} />
                        <div className={classes.calenderLabel}>
                            <div className={classes.labelDiv}>
                                <span className={classes.holidaySpan}></span>
                                <Typography className={classes.label}>Holidays</Typography>
                            </div>
                            <div className={classes.labelDiv}>
                                <span className={classes.leaveSpan}></span>
                                <Typography className={classes.label}>Applied Time-Off</Typography>
                            </div>
                        </div>
                    </div>
                    <div className={classes.leaves}>
                        <div className={classes.leaveHeadingDiv}>
                            <Typography className={classes.leaveHeading}>Employees on Time-Off</Typography>
                        </div>
                        <div className={classes.calenderBottomDiv}>
                            <div
                                className={classes.displayLeaves}
                                // style={{ maxHeight: `${leaveRef.current?.clientHeight - 345}px` }}
                            >
                                {props.employeeLeaves.length === 0 ? (
                                    <p className={classes.leaveText}>No one has taken Time-Off in current month</p>
                                ) : (
                                    props.employeeLeaves.map((emp: any, i: number) => (
                                        <Employee employee={emp} key={i} />
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
