/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import myStyle from './TeamStyle';
import { Paper, Hidden, InputAdornment, TextField, useTheme } from '@material-ui/core';
import Toolbar from './Toolbar';
import ErrorPage from '../Error/ErrorPage';
import { SearchBar } from '../DashboardPage/DashboardPart';
// import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import DetailsTable from './DetailsTable';
import AuthContext from '../../context/AuthContext';
import {
    // getOrganizationTeams,
    CreateTeam,
    updateTeamName,
    getTeamEmployees,
    deleteTeamEmployees,
    createDefaultOrganizationTeam1,
    updateCreateNewTeamPendingAction,
    getMyTeams,
    updateUserToEmployee,
} from '../../helpers/hasuraapi';
import { PopUp } from '../Modal/Modal';
import TeamDetails from './TeamDetails';
import Selectors from './Selectors';
import DeleteModal from './DeleteModal';
// import { updateActiveTab } from '../Redux/activeTab';
import Loader from '../Loader/loader';
import { updateActiveTab } from '../Redux/activeTab';
import { editTeam } from '../Redux/teamsReducer';
// import { useTheme } from '@emotion/react';
// import { theme } from '@chakra-ui/react';
import SubscriptionHardExpired from '../Settings/SubscriptionHardExpired';
// import { fetchTeams } from '../Redux/teamsReducer';

const Teams = (props: any) => {
    const classes = myStyle();
    const theme = useTheme();
    const dispatch = useDispatch();
    // const [teams, setTeams] = React.useState<any>([]);
    // const [error, setError] = React.useState<boolean>(false);
    // const [errorMessage, setErrorMessage] = React.useState<any>('');
    // const [isLoading, setLoading] = React.useState<boolean>(true);
    const mycontext = useContext(AuthContext);
    const state = useSelector((state: any) => state.teams);
    const teams = useSelector((state: any) => state.teams.teams);
    const [currentPage, setCurrentPage] = React.useState<string>('teams');
    const [alertMessage, setAlertMessage] = React.useState<string>('');
    const [alertm, setAlertm] = React.useState<boolean>(false);
    const [severity, setSeverity] = React.useState<string>('');
    const [teamID, setteamID] = React.useState<any>('');
    const [teamname, setteamname] = React.useState<any>('');
    const [teamDetails, setTeamDetails] = React.useState<any>(null);
    const [teamNameValid, setTeamNameValid] = React.useState<boolean>(false);
    const [value, setValue] = React.useState('');
    const [selectedTeam, setSelectedTeam] = React.useState<any>(null);
    const [deleteTeam, setDeleteTeam] = React.useState<boolean>(false);
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

    useEffect(() => {
        dispatch(updateActiveTab('/teams'));
        // if (!props.teams?.loading) {
        //     dispatch(fetchTeams(props.teams));
        // }
    }, []);

    const handleCreateTeam = async () => {
        if (teamname !== '') {
            const sameTeams = teams.filter((t: any) => t.teamName === teamname);
            if (sameTeams.length > 0) {
                setSeverity('error');
                setAlertm(true);
                setAlertMessage('Team with this name already exists!');
            } else {
                setIsUpdating(true);
                const todayDate = new Date().toISOString().slice(0, 10);
                const team = {
                    teamName: teamname.displayName || teamname.name,
                    organizationId: mycontext.organizationId,
                    createdAt: todayDate,
                    id: teamname.id || null,
                    slackTeamId: teamname.slackTeamId || null,
                    token: mycontext.token,
                };
                const teamNameData = await createDefaultOrganizationTeam1(team);
                const teamsData: any = [];
                try {
                    if (teamNameData.error) {
                        throw new Error('Something went wrong, try again!');
                    } else {
                        if (teamDetails.employees.length > 0) {
                            teamDetails.employees.map((emp: any) => {
                                return teamsData.push({
                                    employeeId: emp.Employee.employeeId,
                                    teamId: teamNameData.teamId,
                                    isApprover: emp.isApprover,
                                });
                            });

                            const CreateTeamData = await CreateTeam(teamsData, mycontext.token);
                            try {
                                if (CreateTeamData.error) {
                                    throw new Error('Something went wrong, try again!');
                                } else {
                                    await updateCreateNewTeamPendingAction(mycontext.organizationId, mycontext.token);
                                    // dispatch(
                                    //     addTeam({
                                    //         createdAt: teamNameData.createdAt,
                                    //         organizationId: teamNameData.organizationId,
                                    //         teamId: teamNameData.teamId,
                                    //         teamName: teamNameData.teamName,
                                    //         team_employees: teamDetails.employees,
                                    //     }),
                                    // );

                                    if (teamsData.length > 0) {
                                        teamsData.map(async (data: any) => {
                                            const myTeams = await getMyTeams(
                                                data.employeeId,
                                                mycontext.organizationId,
                                                mycontext.token,
                                            );
                                            const empRole = myTeams.usermanagementdb_organization_employee[0].role;
                                            if (data.isApprover) {
                                                if (empRole !== 'admin' || empRole !== 'approver') {
                                                    const updateResponse = await updateUserToEmployee(
                                                        {
                                                            empId: data.employeeId,
                                                            orgId: mycontext.organizationId,
                                                            role: empRole === 'admin' ? 'admin' : 'approver',
                                                        },
                                                        mycontext.token,
                                                    );
                                                    if (updateResponse.error) {
                                                        throw new Error('Something went wrong, try again!');
                                                    } else {
                                                        setIsUpdating(false);
                                                        setSeverity('success');
                                                        setAlertm(true);
                                                        setAlertMessage('Team created successfully!');
                                                        setCurrentPage('teams');
                                                        // window.location.reload();
                                                    }
                                                }
                                            } else {
                                                if (empRole !== 'admin' || empRole !== 'approver') {
                                                    const updateResponse = await updateUserToEmployee(
                                                        {
                                                            empId: data.employeeId,
                                                            orgId: mycontext.organizationId,
                                                            role: empRole === 'admin' ? 'admin' : 'employee',
                                                        },
                                                        mycontext.token,
                                                    );
                                                    if (updateResponse.error) {
                                                        throw new Error('Something went wrong, try again!');
                                                    } else {
                                                        setIsUpdating(false);
                                                        setSeverity('success');
                                                        setAlertm(true);
                                                        setAlertMessage('Team created successfully!');
                                                        setCurrentPage('teams');
                                                        // window.location.reload();
                                                    }
                                                }
                                            }
                                            return null;
                                        });
                                    }
                                }
                            } catch (err) {
                                // new Error('Something went wrong, try again!');
                                setIsUpdating(false);
                                setSeverity('error');
                                setAlertm(true);
                                setAlertMessage(err.message);
                            }
                            // window.location.reload();
                        } else {
                            setIsUpdating(false);
                            // show snackbar of team creation success
                            setSeverity('success');
                            setAlertm(true);
                            setAlertMessage('Team created successfully!');
                            setCurrentPage('teams');
                            // window.location.reload();
                        }
                    }
                } catch (err) {
                    new Error('Something went wrong, try again!');
                }
            }
        } else {
            setTeamNameValid(true);
        }
    };

    // const handleEditTeam = async () => {
    //     setIsUpdating(true);
    //     const existingEmployees = await getTeamEmployees(teamDetails.teamId, mycontext.token);
    //     if (existingEmployees.error) {
    //         throw new Error('Error while fetching team employees.');
    //     } else {
    //         if (teamDetails.teamName !== '') {
    //             const sameTeams = teams.filter((t: any) => {
    //                 if (t.teamName === teamDetails.teamName) {
    //                     if (t.teamId !== teamDetails.teamId) {
    //                         return t;
    //                     }
    //                 }
    //                 return null;
    //             });

    //             if (sameTeams.length > 0) {
    //                 setIsUpdating(false);
    //                 setSeverity('error');
    //                 setAlertm(true);
    //                 setAlertMessage('Team with this name already exists!');
    //             } else {
    //                 const updatedName = await updateTeamName(
    //                     {
    //                         teamId: teamDetails.teamId,
    //                         teamName: teamDetails.teamName,
    //                     },
    //                     mycontext.token,
    //                 );
    //                 const teamsData: any = [];
    //                 if (updatedName.error) {
    //                     throw new Error('Something went wrong while updating team.');
    //                 } else {
    //                     const deleteResponse = await deleteTeamEmployees(teamDetails.teamId, mycontext.token);
    //                     if (deleteResponse.error) {
    //                         throw new Error('Something went wrong!');
    //                     } else {
    //                         if (teamDetails.employees.length > 0) {
    //                             teamDetails.employees.map((emp: any) => {
    //                                 return teamsData.push({
    //                                     employeeId: emp.Employee.employeeId,
    //                                     teamId: teamDetails.teamId,
    //                                     isApprover: emp.isApprover,
    //                                 });
    //                             });
    //                             const insetTeamEmp = await CreateTeam(teamsData, mycontext.token);
    //                             if (insetTeamEmp.error) {
    //                                 throw new Error('Something went wrong when updating team!');
    //                             } else {
    //                                 dispatch(
    //                                     editTeam({
    //                                         createdAt: teamDetails.createdAt,
    //                                         organizationId: teamDetails.organizationId,
    //                                         teamId: teamDetails.teamId,
    //                                         teamName: teamDetails.teamName,
    //                                         team_employees: teamDetails.employees,
    //                                     }),
    //                                 );

    //                                 if (teamsData.length > 0) {
    //                                     teamsData.map(async (data: any) => {
    //                                         const myTeams = await getMyTeams(
    //                                             data.employeeId,
    //                                             mycontext.organizationId,
    //                                             mycontext.token,
    //                                         );
    //                                         const empRole = myTeams.usermanagementdb_organization_employee[0].role;
    //                                         if (data.isApprover) {
    //                                             if (empRole !== 'admin' || empRole !== 'approver') {
    //                                                 const updateResponse = await updateUserToEmployee(
    //                                                     {
    //                                                         empId: data.employeeId,
    //                                                         role: empRole === 'admin' ? 'admin' : 'approver',
    //                                                     },
    //                                                     mycontext.token,
    //                                                 );
    //                                                 if (updateResponse.error) {
    //                                                     throw new Error('Something went wrong, try again!');
    //                                                 } else {
    //                                                     setIsUpdating(false);
    //                                                     setSeverity('success');
    //                                                     setAlertm(true);
    //                                                     setAlertMessage('Team updated successfully!');
    //                                                     setCurrentPage('teams');
    //                                                     // window.location.reload();
    //                                                 }
    //                                             }
    //                                         } else {
    //                                             if (empRole !== 'admin' || empRole !== 'approver') {
    //                                                 const updateResponse = await updateUserToEmployee(
    //                                                     {
    //                                                         empId: data.employeeId,
    //                                                         role: empRole === 'admin' ? 'admin' : 'employee',
    //                                                     },
    //                                                     mycontext.token,
    //                                                 );
    //                                                 if (updateResponse.error) {
    //                                                     throw new Error('Something went wrong, try again!');
    //                                                 } else {
    //                                                     setIsUpdating(false);
    //                                                     setSeverity('success');
    //                                                     setAlertm(true);
    //                                                     setAlertMessage('Team updated successfully!');
    //                                                     setCurrentPage('teams');
    //                                                     // window.location.reload();
    //                                                 }
    //                                             }
    //                                         }
    //                                         return null;
    //                                     });
    //                                 }
    //                             }
    //                         } else {
    //                             setIsUpdating(false);
    //                             setSeverity('success');
    //                             setAlertm(true);
    //                             setAlertMessage('Team updated successfully!');
    //                             setCurrentPage('teams');
    //                             // window.location.reload();
    //                         }
    //                     }
    //                 }
    //             }
    //         } else {
    //             setTeamNameValid(true);
    //         }
    //     }
    // };

    const handleEditTeam = async () => {
        setIsUpdating(true);
        const existingEmployees = await getTeamEmployees(teamDetails.teamId, mycontext.token);
        if (existingEmployees.error) {
            throw new Error('Error while fetching team employees.');
        } else {
            if (teamDetails.teamName !== '') {
                const sameTeams = teams.filter((t: any) => {
                    if (t.teamName === teamDetails.teamName) {
                        if (t.teamId !== teamDetails.teamId) {
                            return t;
                        }
                    }
                    return null;
                });

                if (sameTeams.length > 0) {
                    setIsUpdating(false);
                    setSeverity('error');
                    setAlertm(true);
                    setAlertMessage('Team with this name already exists!');
                } else {
                    const updatedName = await updateTeamName(
                        {
                            teamId: teamDetails.teamId,
                            teamName: teamDetails.teamName,
                        },
                        mycontext.token,
                    );
                    const teamsData: any = [];
                    if (updatedName.error) {
                        throw new Error('Something went wrong while updating team.');
                    } else {
                        const deleteResponse = await deleteTeamEmployees(teamDetails.teamId, mycontext.token);
                        if (deleteResponse.error) {
                            throw new Error('Something went wrong!');
                        } else {
                            if (teamDetails.employees.length > 0) {
                                teamDetails.employees.map((emp: any) => {
                                    return teamsData.push({
                                        employeeId: emp.Employee.employeeId,
                                        teamId: teamDetails.teamId,
                                        isApprover: emp.isApprover,
                                    });
                                });
                                // console.log("Teams data: ", teamsData);
                                const insetTeamEmp = await CreateTeam(teamsData, mycontext.token);
                                if (insetTeamEmp.error) {
                                    throw new Error('Something went wrong when updating team!');
                                } else {
                                    dispatch(
                                        editTeam({
                                            createdAt: teamDetails.createdAt,
                                            organizationId: teamDetails.organizationId,
                                            teamId: teamDetails.teamId,
                                            teamName: teamDetails.teamName,
                                            team_employees: teamDetails.employees,
                                        }),
                                    );

                                    if (teamsData.length > 0) {
                                        teamsData.map(async (data: any) => {
                                            const myTeams = await getMyTeams(
                                                data.employeeId,
                                                mycontext.organizationId,
                                                mycontext.token,
                                            );
                                            const empRole = myTeams.usermanagementdb_organization_employee[0].role;
                                            // console.log("Employee role: ", empRole, data.isApprover, myTeams.usermanagementdb_Team);
                                            if (data.isApprover) {
                                                // console.log('making user as approver: ', data);
                                                const updateResponse = await updateUserToEmployee(
                                                    {
                                                        empId: data.employeeId,
                                                        orgId: mycontext.organizationId,
                                                        role: empRole === 'admin' ? 'admin' : 'approver',
                                                    },
                                                    mycontext.token,
                                                );
                                                if (updateResponse.error) {
                                                    throw new Error('Something went wrong, try again!');
                                                } else {
                                                    setIsUpdating(false);
                                                    setSeverity('success');
                                                    setAlertm(true);
                                                    setAlertMessage('Team updated successfully!');
                                                    setCurrentPage('teams');
                                                }
                                            } else {
                                                const otherTeams = myTeams.usermanagementdb_Team.filter(
                                                    (team: any) => team.teamId !== teamDetails.teamId,
                                                );
                                                // console.log("Other teams: ", otherTeams);

                                                // console.log("making user as someone: ", data);
                                                const updateResponse = await updateUserToEmployee(
                                                    {
                                                        empId: data.employeeId,
                                                        orgId: mycontext.organizationId,
                                                        role:
                                                            empRole === 'admin'
                                                                ? 'admin'
                                                                : otherTeams.length > 0
                                                                ? 'approver'
                                                                : 'employee',
                                                    },
                                                    mycontext.token,
                                                );
                                                if (updateResponse.error) {
                                                    throw new Error('Something went wrong, try again!');
                                                } else {
                                                    setIsUpdating(false);
                                                    setSeverity('success');
                                                    setAlertm(true);
                                                    setAlertMessage('Team updated successfully!');
                                                    setCurrentPage('teams');
                                                }
                                            }
                                            return null;
                                        });
                                    }
                                }
                            } else {
                                setIsUpdating(false);
                                setSeverity('success');
                                setAlertm(true);
                                setAlertMessage('Team updated successfully!');
                                setCurrentPage('teams');
                                // window.location.reload();
                            }
                        }
                    }
                }
            } else {
                setTeamNameValid(true);
            }
        }
    };

    const handleDeleteTeam = () => {
        setDeleteTeam(true);
    };

    useEffect(() => {
        setCurrentPage('teams');
    }, [mycontext.teamsPage]);

    if (state.status === 'failed') {
        return <ErrorPage title="Server Down" img="/error.png" msg="Something went wrong, please try again" />;
    }
    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        mycontext.isAdmin ? (
            <SubscriptionHardExpired
                content="Looks like your subscription is expired, Please renew it !"
                showRenew={true}
            />
        ) : (
            <SubscriptionHardExpired
                content="Subscription expired, Please contact your admin to renew subscription"
                showRenew={false}
            />
        )
    ) : (
        <>
            {deleteTeam && (
                <DeleteModal
                    isDelete={deleteTeam}
                    setDelete={setDeleteTeam}
                    teamId={teamID}
                    teamname={teamname}
                    setAlertMessage={setAlertMessage}
                    setAlertm={setAlertm}
                    setSeverity={setSeverity}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
            <Loader isUpdating={isUpdating} />
            <PopUp open={alertm} setOpen={setAlertm} message={alertMessage} severity={severity} />
            <Hidden mdDown>
                <Paper elevation={0}>
                    <Toolbar
                        current={currentPage}
                        setCurrent={setCurrentPage}
                        handleCreateTeam={handleCreateTeam}
                        handleEditTeam={handleEditTeam}
                        handleDeleteTeam={handleDeleteTeam}
                        teamDetails={teamDetails}
                    />
                </Paper>
            </Hidden>

            <Hidden lgUp>
                <Toolbar
                    current={currentPage}
                    setCurrent={setCurrentPage}
                    handleCreateTeam={handleCreateTeam}
                    handleEditTeam={handleEditTeam}
                    handleDeleteTeam={handleDeleteTeam}
                    teamDetails={teamDetails}
                />
            </Hidden>

            {currentPage === 'teams' && (
                <>
                    <Hidden mdDown>
                        <Paper elevation={0} className={classes.root}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                {/* <Typography className={classes.teamTitle} id="typo">
                                    Team List
                                </Typography> */}
                                <TextField
                                    id="searchname"
                                    placeholder="Search by name"
                                    variant="outlined"
                                    size="small"
                                    value={value}
                                    onChange={(event: any) => setValue(event.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" className={classes.searchIcon}>
                                                <span
                                                    style={{
                                                        marginRight: 10,
                                                        fontSize: 20,
                                                        fill: theme.palette.primary.contrastText,
                                                    }}
                                                    className="iconify"
                                                    data-icon="octicon:search-16"
                                                ></span>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            root: classes.searchInput1,
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: theme.palette.primary.contrastText,
                                        },
                                    }}
                                    className={classes.searBorder}
                                />
                            </div>
                            <div style={{ marginTop: '16px' }}>
                                <DetailsTable
                                    users={teams}
                                    isLoading={state.status === 'loading'}
                                    error={state.status === 'failed'}
                                    errorMessage="Something went wrong!"
                                    searchvalue={value}
                                    setCurrent={setCurrentPage}
                                    setAlertMessage={setAlertMessage}
                                    setAlertm={setAlertm}
                                    setSeverity={setSeverity}
                                    setTeamDetails={setTeamDetails}
                                    teamID={teamID}
                                    setteamID={setteamID}
                                    teamname={teamname}
                                    setteamname={setteamname}
                                    selectedTeam={selectedTeam}
                                    setSelectedTeam={setSelectedTeam}
                                    teamDetails={teamDetails}
                                />
                            </div>
                        </Paper>
                    </Hidden>
                    <Hidden lgUp>
                        <div id="searchTeam" className={classes.searchposmob}>
                            <SearchBar
                                value={value}
                                setValue={setValue}
                                mode="rect"
                                icon={true}
                                style={{
                                    border: 'none',
                                    width: '100%',
                                    height: '40px',
                                    margin: '0px',
                                    paddingRight: '0px',
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <DetailsTable
                                users={teams}
                                isLoading={state.status === 'loading'}
                                error={state.status === 'failed'}
                                errorMessage="Something went wrong!"
                                searchvalue={value}
                                setCurrent={setCurrentPage}
                                setAlertMessage={setAlertMessage}
                                setAlertm={setAlertm}
                                setSeverity={setSeverity}
                                setTeamDetails={setTeamDetails}
                                teamID={teamID}
                                setteamID={setteamID}
                                teamname={teamname}
                                setteamname={setteamname}
                                selectedTeam={selectedTeam}
                                setSelectedTeam={setSelectedTeam}
                                teamDetails={teamDetails}
                            />
                        </div>
                    </Hidden>
                </>
            )}
            {currentPage === 'details' && (
                <TeamDetails
                    current={currentPage}
                    setCurrent={setCurrentPage}
                    teamDetails={teamDetails}
                    setTeamDetails={setTeamDetails}
                    setAlertMessage={setAlertMessage}
                    setAlertm={setAlertm}
                    setSeverity={setSeverity}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                />
            )}
            {currentPage === 'create' && (
                <>
                    <PopUp
                        open={teamNameValid}
                        setOpen={setTeamNameValid}
                        message="Please enter team Name"
                        severity="error"
                    />
                    <Selectors
                        current={currentPage}
                        setCurrent={setCurrentPage}
                        teamDetails={teamDetails}
                        setTeamDetails={setTeamDetails}
                        teamname={teamname}
                        setteamname={setteamname}
                        teamNameValid={teamNameValid}
                        teamId={teamID}
                        setAlertMessage={setAlertMessage}
                        setAlertm={setAlertm}
                        setSeverity={setSeverity}
                    />
                </>
            )}
            {currentPage === 'edit' && (
                <>
                    <PopUp
                        open={teamNameValid}
                        setOpen={setTeamNameValid}
                        message="Please enter team Name"
                        severity="error"
                    />
                    <Selectors
                        current={currentPage}
                        setCurrent={setCurrentPage}
                        teamDetails={teamDetails}
                        setTeamDetails={setTeamDetails}
                        teamname={teamname}
                        setteamname={setteamname}
                        selectedTeam={selectedTeam}
                        setSelectedTeam={setSelectedTeam}
                        teamId={teamID}
                        setAlertMessage={setAlertMessage}
                        setAlertm={setAlertm}
                        setSeverity={setSeverity}
                    />
                </>
            )}
        </>
    );
};

export default Teams;
