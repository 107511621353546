import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import StepContent from '@material-ui/core/StepContent';
// import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import loginWelcome from '../Login/login_welcome.png';
// import udyamo from '../Login/udyamo.png';
import { s3Bucket, registerLoginPageImages } from '../../helpers/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100vh',
            fontFamily: theme.typography.fontFamily,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
            // backgroundColor: '#F2F3F4',
        },
        leftSide: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        rightSide: {
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
        },
        stepperNumberStyle: {
            color: '#FFFFFF',
            '& span': {
                '& svg': {
                    '& text': {
                        fill: '#FFFFFF',
                    },
                },
            },
        },
    }),
);

function getSteps() {
    return [
        'Creating Organization',
        'Adding your employees to the organization',
        'Creating Departments for you',
        'Creating default settings for you',
        'Creating default leave types for you',
        'Creating default standup template for you',
        'Creating Teams for you',
        'Almost Done !',
    ];
}

function getStepsRegister() {
    return [
        'Adding you to the organization',
        'Fetching default teams of your organization',
        'Fetching default workweek of your organization',
    ];
}

// function getStepContent(step: number) {
//     switch (step) {
//         case 0:
//             return `We will create a organization for you`;
//         case 1:
//             return 'Every organization will have a default team. The name of the default team will be General';
//         case 2:
//             return `We will create default work week for you that work week will be monday to saturday. If you want to change then you can update that from the settings tab.`;
//         case 3:
//             return `We will create default leave types for you. First will be Paid time off, Casual leave and Sick leave`;
//         default:
//             return 'Unknown step';
//     }
// }

// function getStepContentRegister(step: number) {
//     switch (step) {
//         case 0:
//             return `We will fetch your organization details.`;
//         case 1:
//             return 'Fetch the default teams from your organization and will add you in that team.';
//         case 2:
//             return `Fetch the default workweek of your organization and assign that workweek to you.`;
//         default:
//             return 'Unknown step';
//     }
// }

interface StepperProps {
    task?: any;
    isRegister?: boolean;
}
export default function VerticalLinearStepper(props: StepperProps) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = props.isRegister ? getStepsRegister() : getSteps();
    const [percentage, setPercentage] = React.useState(0);

    // const handleNext = () => {
    //     setPercentage(prev => prev + 25);
    //     setActiveStep(prevActiveStep => prevActiveStep + 1);
    // };

    React.useEffect(() => {
        if (props.task.task1) {
            setPercentage(10);
            setActiveStep(1);
        }
        if (props.task.task2) {
            setPercentage(30);
            setActiveStep(2);
        }
        if (props.task.task3) {
            setPercentage(40);
            setActiveStep(3);
        }
        if (props.task.task4) {
            setPercentage(60);
            setActiveStep(4);
        }
        if (props.task.task5) {
            setPercentage(70);
            setActiveStep(5);
        }
        if (props.task.task6) {
            setPercentage(80);
            setActiveStep(6);
        }
        if (props.task.task7) {
            setPercentage(90);
            setActiveStep(7);
        }
        if (props.task.task8) {
            setPercentage(100);
            setActiveStep(7);
        }
    }, [props.task]);
    return (
        <Grid container>
            <Hidden lgDown>
                <Grid item lg={6} className={classes.leftSide}>
                    <img
                        src={s3Bucket + registerLoginPageImages.loginWelcomeImage}
                        alt="login"
                        style={{ width: '65%' }}
                    />
                    <img
                        src={s3Bucket + registerLoginPageImages.udyamoBlack}
                        alt="udyamo"
                        style={{ height: 15, maxWidth: '100%' }}
                    />
                </Grid>
            </Hidden>
            <Grid item lg={6} xs={12} sm={12} className={classes.rightSide}>
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel className={classes.stepperNumberStyle}>{label}</StepLabel>
                                {/* <StepContent>
                                    <Typography>
                                        {props.isRegister ? getStepContentRegister(index) : getStepContent(index)}
                                    </Typography>
                                </StepContent> */}
                            </Step>
                        ))}
                    </Stepper>
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 20 }}>
                        <div style={{ width: '60%', height: 25, backgroundColor: 'grey', marginRight: 10 }}>
                            <div
                                style={{
                                    backgroundColor: 'green',
                                    width: `${percentage.toString() + '%'}`,
                                    height: 25,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#fff',
                                    transition: '1s',
                                }}
                            ></div>
                        </div>
                        {percentage.toString() + ' %'}
                    </div>
                    {activeStep >= steps.length && (
                        <Paper square elevation={0} className={classes.resetContainer}>
                            <Typography>All steps completed - you&apos;re done.</Typography>
                        </Paper>
                    )}
                </div>
            </Grid>
        </Grid>
    );
}
