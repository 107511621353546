import React from 'react';
import useStyles from './style';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
interface SignUp {
    title?: string;
}
export default function SignUp(props: SignUp) {
    const classes = useStyles();
    const signIn = async () => {
        await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
        });
        // await mycontext.updateGoogle();

        // await getUser();
    };
    return (
        <div className={classes.signup}>
            <div className={classes.content}>
                <h3 className={classes.contentText}>{props.title}</h3>
            </div>
            <div className={classes.signUpContent}>
                <button className={classes.btn}>
                    <Link to="/main" style={{ textDecoration: 'none', color: 'white' }}>
                        Register
                    </Link>
                </button>
                <button className={classes.signUpBtn} onClick={() => signIn()}>
                    <img src="google-icon.svg" alt="" className={classes.googleImg} /> Register With Google
                </button>
                <p>
                    Already an existing User?{' '}
                    <span>
                        <Link to="/login" style={{ textDecoration: 'none', color: 'white' }}>
                            Login
                        </Link>
                    </span>
                </p>
            </div>
        </div>
    );
}
