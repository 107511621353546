import { gql } from '@apollo/client';

export function getPolls(organizationId: string) {
    return gql`
        subscription Polls {
            pollsdb_Polls(where: {orgId: {_eq: "${organizationId}"}}, order_by: {pollEndsDateTime: desc}) {
                    pollId
                    pollEndTime
                    timeStamp
                    createdOn
                    createdBy
                    isAnonymous
                    adminChoices
                    poll_teams {
                        Team {
                            teamName
                            teamId
                            slackTeamId
                        }
                    }
                    isSingleVote
                    question
                    pollEndsDateTime
                    replyToId
                    pollMetaData
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }
    `;
}

export function getEmployeePolls(organizationId: string, employeeId: string) {
    return gql`
        subscription Polls {
                pollsdb_Polls(where: {Organization: {organizationId: {_eq: "${organizationId}"}}, poll_teams: {Team: {team_employees:  {employeeId: {_eq: "${employeeId}"}}}}}, order_by: {pollEndsDateTime: desc}) {
                    orgId
                    pollId
                    timeStamp
                    isAnonymous
                    isSingleVote
                    pollEndTime
                    createdOn
                    pollEndsDateTime
                    createdBy
                    adminChoices
                    question
                    replyToId
                    pollMetaData
                    poll_teams {
                        Team {
                            teamName
                            teamId
                            slackTeamId
                        }
                    }
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }
    `;
}

export function getPollResult(pollId: string) {
    return gql`
        subscription MyQuery {
            pollsdb_poll_result(where: {pollId: {_eq: "${pollId}"}}) {
                userChoice
                Employee {
                    employeeId
                    firstName
                    lastName
                    profileImage
                }
            }
        }
    `;
}

export function getComment(pollId: any) {
    return gql`
        subscription MyQuery {
            pollsdb_poll_comment(where: {pollId: {_eq: "${pollId}"}}, order_by: {commentDateTime: asc}) {
                pollId
                commentId
                commentText
                commentDate
                employeeId
                commentDateTime
                Employee {
                    firstName
                    lastName
                    profileImage
                }
            }
        }
    `;
}
