import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

export default function Celebration() {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', border: '1px solid' }}>
            <div style={{ width: '50%' }}>
                <Accordion>
                    <AccordionSummary>
                        <Typography>Upcoming Birthdays</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, excepturi ipsum. Accusamus
                            eius fugiat voluptatibus obcaecati explicabo quasi, nobis possimus reprehenderit ab mollitia
                            assumenda modi, soluta cum maxime, suscipit nemo.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}
