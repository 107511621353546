import React, { useCallback, useState } from 'react';
import { PieChart, Pie, Cell, Sector } from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, useTheme } from '@material-ui/core';

const CustomTooltip = withStyles({
    tooltip: {
        // backgroundColor: '#3D49B6',
        // color: 'white',
        fontSize: 11,
        fontFamily: 'Nunito Sans',
    },
})(Tooltip);
const renderActiveShape = (props: any) => {
    // const RADIAN = Math.PI / 180;
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    // const sin = Math.sin(-RADIAN * midAngle);
    // const cos = Math.cos(-RADIAN * midAngle);
    // const sx = cx + (outerRadius + 10) * cos;
    // const sy = cy + (outerRadius + 10) * sin;
    // const mx = cx + (outerRadius - 50) * cos;
    // const my = cy + (outerRadius - 50) * sin;
    // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    // const ey = my;
    // const textAnchor = cos >= 0 ? 'start' : 'end';
    // console.log(value, textAnchor, sx, sy, ex, ey, percent, payload);
    return (
        <g>
            {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                <text>{payload.name}</text>
                <text>{`${(percent * 100).toFixed(2)}%`}</text>
            </text> */}
            <circle
                cx={cx}
                cy={cy}
                r={35}
                fill={props.theme.palette.background.paper}
                stroke={props.theme.palette.background.default}
                strokeWidth="3"
                overflow="hidden"
            ></circle>
            <text x={cx} y={cy - 10} dy={8} fontSize="18" fontWeight="bold" textAnchor="middle" fill={fill}>
                {payload.value}
            </text>
            <CustomTooltip title={payload.name} placement="top" arrow>
                <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fontSize="9" fill={fill}>
                    {payload.name.length > 10 ? payload.name.slice(0, 10) + '...' : payload.name.slice(0, 10)}
                </text>
            </CustomTooltip>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius - 1}
                outerRadius={outerRadius + 1}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            {/* <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            /> */}
            {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" /> */}
            {/* <circle cx={ex} cy={ey} r={50} fill="white" stroke="silver" stroke-width="3">
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
            </circle> */}
            {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text> */}
        </g>
    );
};

const renderActiveShape2 = (props: any) => {
    // const RADIAN = Math.PI / 180;
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    // const sin = Math.sin(-RADIAN * midAngle);
    // const cos = Math.cos(-RADIAN * midAngle);
    // const sx = cx + (outerRadius + 10) * cos;
    // const sy = cy + (outerRadius + 10) * sin;
    // const mx = cx + (outerRadius - 50) * cos;
    // const my = cy + (outerRadius - 50) * sin;
    // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    // const ey = my;
    // const textAnchor = cos >= 0 ? 'start' : 'end';
    // console.log(value, textAnchor, sx, sy, ex, ey, percent, payload);
    return (
        <g>
            {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                <text>{payload.name}</text>
                <text>{`${(percent * 100).toFixed(2)}%`}</text>
            </text> */}
            {/* <circle
                cx={cx}
                cy={cy}
                r={35}
                fill={props.theme.palette.background.paper}
                stroke={props.theme.palette.background.default}
                strokeWidth="3"
                overflow="hidden"
            ></circle> */}
            <text x={cx} y={cy - 25} dy={8} fontSize="18" fontWeight="bold" textAnchor="middle" fill={fill}>
                {payload.value}
            </text>
            <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fontSize="9" fill={fill}>
                {payload.name.length > 10 ? payload.name.slice(0, 10) + '...' : payload.name.slice(0, 10)}
            </text>
            {/* <CustomTooltip title={payload.name} placement="top" arrow>
                <text x={cx} y={cy + 20} dy={8} textAnchor="middle" fontSize="9" fill={fill}>
                    {payload.name.length > 10 ? payload.name.slice(0, 10) + '...' : payload.name.slice(0, 10)}
                </text>
            </CustomTooltip> */}
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius - 1}
                outerRadius={outerRadius + 1}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            {/* <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            /> */}
            {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" /> */}
            {/* <circle cx={ex} cy={ey} r={50} fill="white" stroke="silver" stroke-width="3">
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
            </circle> */}
            {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text> */}
        </g>
    );
};
// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const MYCOLORS = ['#B2BABB', '#E5E8E8'];
export type DonutProps = {
    data?: any;
    COLORS?: any;
    text?: string;
    lTypes?: any;
};
export function Donut(props: DonutProps) {
    const mydata = [{ name: 'Time-off', value: 0, mvalue: 1 }];
    const [activeIndex, setActiveIndex] = useState(props?.data[0]?.value === 0 ? 1 : 0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex],
    );
    const theme: any = useTheme();

    return (
        <PieChart
            width={180}
            height={160}
            margin={{ top: -60, left: -50 }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Pie
                activeIndex={activeIndex}
                activeShape={(obj: any) => renderActiveShape({ ...obj, theme: theme })}
                data={props.data.length === 0 || props.data === [] ? mydata : props.data}
                cx={140}
                cy={140}
                innerRadius={45}
                outerRadius={65}
                fill="#8884D8"
                dataKey={props.data.length === 0 ? 'mvalue' : 'value'}
                onMouseEnter={onPieEnter}
            >
                {props.data.length === 0
                    ? mydata.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={MYCOLORS[index % MYCOLORS.length]} />
                      ))
                    : props.lTypes
                    ? props.data.map((entry: any, index: number) => (
                          <Cell
                              key={`cell-${index}`}
                              fill={
                                  props.COLORS[props.lTypes.findIndex((v: any) => entry.name === v.leaveTypeName)] ||
                                  'silver'
                              }
                          />
                      ))
                    : props.data.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={props.COLORS[index % props.COLORS.length]} />
                      ))}
            </Pie>
        </PieChart>
    );
}

export function Donut1(props: DonutProps) {
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex],
    );
    const theme: any = useTheme();
    const mydata = [{ name: 'No response', value: 0, mvalue: 1 }];

    return (
        <PieChart
            width={180}
            height={160}
            margin={{ top: -60, left: -50 }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Pie
                activeIndex={activeIndex}
                activeShape={(obj: any) => renderActiveShape({ ...obj, theme: theme })}
                data={props.data.length === 0 || props.data === [] ? mydata : props.data}
                cx={140}
                cy={140}
                innerRadius={45}
                outerRadius={65}
                fill="#8884D8"
                dataKey={props.data.length === 0 ? 'mvalue' : 'value'}
                onMouseEnter={onPieEnter}
            >
                {props.data.length === 0
                    ? mydata.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={MYCOLORS[index % MYCOLORS.length]} />
                      ))
                    : props.lTypes
                    ? props.data.map((entry: any, index: number) => (
                          <Cell
                              key={`cell-${index}`}
                              fill={
                                  props.COLORS[props.lTypes.findIndex((v: any) => entry.name === v.leaveTypeName)] ||
                                  'silver'
                              }
                          />
                      ))
                    : props.data.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={props.COLORS[index % props.COLORS.length]} />
                      ))}
            </Pie>
        </PieChart>
    );
}

export function Donut2(props: DonutProps) {
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex],
    );
    const theme: any = useTheme();
    const mydata = [{ name: 'No Values', value: 0, mvalue: 1 }];

    return (
        <PieChart
            width={180}
            height={160}
            margin={{ top: -60, left: -50 }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Pie
                activeIndex={activeIndex}
                activeShape={(obj: any) => renderActiveShape({ ...obj, theme: theme })}
                data={
                    props?.data[0]?.value === 0 && props?.data[1]?.value === 0 && props?.data[2]?.value === 0
                        ? mydata
                        : props.data
                }
                cx={140}
                cy={140}
                innerRadius={45}
                outerRadius={65}
                fill="#8884D8"
                dataKey={
                    props?.data[0]?.value === 0 && props?.data[1]?.value === 0 && props?.data[2]?.value === 0
                        ? 'mvalue'
                        : 'value'
                }
                onMouseEnter={onPieEnter}
            >
                {props?.data[0]?.value === 0 && props?.data[1]?.value === 0 && props?.data[2]?.value === 0
                    ? mydata.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={MYCOLORS[index % MYCOLORS.length]} />
                      ))
                    : props.lTypes
                    ? props.data.map((entry: any, index: number) => (
                          <Cell
                              key={`cell-${index}`}
                              fill={
                                  props.COLORS[props.lTypes.findIndex((v: any) => entry.name === v.leaveTypeName)] ||
                                  'silver'
                              }
                          />
                      ))
                    : props.data.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={props.COLORS[index % props.COLORS.length]} />
                      ))}
            </Pie>
        </PieChart>
    );
}

export function Donut3(props: DonutProps) {
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex],
    );
    const theme: any = useTheme();
    const mydata = [{ name: 'No Shoutouts', value: 0, mvalue: 1 }];

    return (
        <PieChart
            width={180}
            height={160}
            margin={{ top: -60, left: -50 }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Pie
                activeIndex={activeIndex}
                activeShape={(obj: any) => renderActiveShape({ ...obj, theme: theme })}
                data={props?.data[0]?.value === 0 && props?.data[1]?.value === 0 ? mydata : props.data}
                cx={140}
                cy={140}
                innerRadius={45}
                outerRadius={65}
                fill="#8884D8"
                dataKey={props?.data[0]?.value === 0 && props?.data[1]?.value === 0 ? 'mvalue' : 'value'}
                onMouseEnter={onPieEnter}
            >
                {props?.data[0]?.value === 0 && props?.data[1]?.value === 0
                    ? mydata.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={MYCOLORS[index % MYCOLORS.length]} />
                      ))
                    : props.lTypes
                    ? props.data.map((entry: any, index: number) => (
                          <Cell
                              key={`cell-${index}`}
                              fill={
                                  props.COLORS[props.lTypes.findIndex((v: any) => entry.name === v.leaveTypeName)] ||
                                  'silver'
                              }
                          />
                      ))
                    : props.data.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={props.COLORS[index % props.COLORS.length]} />
                      ))}
            </Pie>
        </PieChart>
    );
}

export function Donut4(props: DonutProps) {
    const mydata = [{ name: 'Time-off', value: 0, mvalue: 1 }];
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex],
    );
    const theme: any = useTheme();

    return (
        <PieChart
            width={180}
            height={100}
            margin={{ top: -40, left: -50 }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Pie
                activeIndex={activeIndex}
                activeShape={(obj: any) => renderActiveShape2({ ...obj, theme: theme })}
                data={props.data.length === 0 || props.data === [] ? mydata : props.data}
                cx={140}
                cy={140}
                innerRadius={65}
                outerRadius={85}
                startAngle={0}
                endAngle={180}
                fill="#8884D8"
                dataKey={props.data.length === 0 ? 'mvalue' : 'value'}
                onMouseEnter={onPieEnter}
            >
                {props.data.length === 0
                    ? mydata.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={MYCOLORS[index % MYCOLORS.length]} />
                      ))
                    : props.lTypes
                    ? props.data.map((entry: any, index: number) => (
                          <Cell
                              key={`cell-${index}`}
                              fill={
                                  props.COLORS[props.lTypes.findIndex((v: any) => entry.name === v.leaveTypeName)] ||
                                  'silver'
                              }
                          />
                      ))
                    : props.data.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={props.COLORS[index % props.COLORS.length]} />
                      ))}
            </Pie>
        </PieChart>
    );
}
