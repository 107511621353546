import { newurl } from './hasuraapi';
// const newurl: any = 'https://udyamo-teamplus.hasura.app/v1/graphql';

export const fetchPollsData = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                pollsdb_Polls(where: {orgId: {_eq: "${organizationId}"}}, order_by: {createdOn: desc}) {
                    pollId
                    pollEndTime
                    createdOn
                    createdBy
                    isAnonymous
                    adminChoices
                    poll_teams {
                        Team {
                            teamName
                            teamId
                        }
                    }
                    isSingleVote
                    question
                    Employee {
                        firstName 
                        lastName
                        profileImage
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return err;
    }
};
export const deletePoll = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                delete_pollsdb_poll_comment(where: {pollId: {_eq: "${id}"}}) {
                    affected_rows
                    returning {
                        pollId
                    }
                }
                delete_pollsdb_poll_team(where: {pollId: {_eq: "${id}"}}) {
                    affected_rows
                    returning {
                        pollId
                    }
                }
                delete_pollsdb_poll_result(where: {pollId: {_eq: "${id}"}}) {
                    affected_rows
                    returning {
                        pollId
                    }
                }
                delete_pollsdb_Polls(where: {pollId: {_eq: "${id}"}}) {
                    affected_rows
                    returning {
                        pollId
                    }
                }
            }`,
        };
        // console.log('query', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Poll not deleted!');
        }
        return jsonData.data.delete_pollsdb_Polls;
    } catch (err) {
        return { error: err };
    }
};
export const fetchPollsDataEmployees = async (organizationId: string, employeeId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                pollsdb_Polls(where: {Organization: {organizationId: {_eq: "${organizationId}"}}, poll_teams: {Team: {team_employees:  {employeeId: {_eq: "${employeeId}"}}}}, , order_by: {createdOn: desc}}) {
                    orgId
                    pollId
                    isAnonymous
                    isSingleVote
                    pollEndTime
                    createdOn
                    createdBy
                    adminChoices
                    question
                    poll_teams {
                        Team {
                            teamName
                            teamId
                        }
                    }
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return err;
    }
};
export const createPoll = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_pollsdb_Polls(objects: {question: "${obj.question}", 
                isAnonymous: ${obj.isAnonymous}, isSingleVote: ${obj.isSingleVote}, adminChoices: ${JSON.stringify(
                obj.choices,
            )}, createdBy: "${obj.createdBy}", pollEndTime: "${obj.pollEndTime}", pollEndsDateTime: "${
                obj.pollEndsDateTime
            }" orgId: "${obj.organizationId}"}) {
                    affected_rows
                    returning {
                        pollId
                    }
                }
            }`,
        };
        // console.log('deepak', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to create poll!');
        }
        return jsonData.data.insert_pollsdb_Polls.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const pollBot = async (obj: any) => {
    const azureUrl =
        'https://sls-swec-prod-azurepollbot.azurewebsites.net/frontend?code=NAykJ9jcVgipjmJG-xqHDHfnL8VFb-gSXM1K7TxVZgIBAzFugDRrcQ==';
    try {
        const data = await fetch(azureUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Failed to call azure function app!');
        }
        return jsonData.data;
    } catch (error) {
        return error;
    }
};

export const createPollTeam = async (obj: any) => {
    try {
        let usersData: string = JSON.stringify(obj.teams);
        let length: number = usersData.length;
        while (length !== 0) {
            usersData = usersData.replace('"pollId"', 'pollId');
            usersData = usersData.replace('"teamId"', 'teamId');
            length = length - 1;
        }
        const query = {
            query: `mutation MyMutation2 {
                insert_pollsdb_poll_team(objects: ${usersData}) {
                    returning {
                        pollId
                        teamId
                    }
                }
            }`,
        };
        // console.log('query', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to create poll!');
        }
        return jsonData.data.insert_pollsdb_poll_team;
    } catch (err) {
        return { error: err };
    }
};
export const createChoices = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_pollsdb_poll_result(objects: {pollId: "${obj.pollId}", employeeId: "${
                obj.employeeId
            }", userChoice: ${JSON.stringify(obj.userChoices)}}) {
                    returning {
                        employeeId
                        userChoice
                        pollId
                    }
                }
            }`,
        };
        // console.log('jaya', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to create Choices!');
        }
        return jsonData.data.insert_pollsdb_poll_result.returning;
    } catch (err) {
        return { error: err };
    }
};
export const updateChoices = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_pollsdb_poll_result(where: {pollId: {_eq: "${obj.pollId}"}, employeeId: {_eq: "${
                obj.employeeId
            }"}}, _set: {userChoice: ${JSON.stringify(obj.userChoices)}}) {
                    affected_rows
                        returning {
                            employeeId
                            pollId
                            userChoice
                        }
                    }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data', jsonData);
        if (jsonData.errors) {
            throw new Error('failed to update Choices!');
        }
        return jsonData.data.update_pollsdb_poll_result.returning;
    } catch (err) {
        return err;
    }
};

export const fetchPollResult = async (pollId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                pollsdb_poll_result(where: {pollId: {_eq: "${pollId}"}}) {
                    userChoice
                    Employee {
                        employeeId
                        firstName
                        lastName
                        profileImage
                    }
                }
                }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Poll Data not found!');
        }
        return jsonData.data.pollsdb_poll_result;
    } catch (err) {
        return err;
    }
};
export const createComment = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation2 {
                insert_pollsdb_poll_comment(objects: {pollId: "${obj.pollId}", commentText: "${obj.commentText}", employeeId: "${obj.employeeId}", commentDateTime: "${obj.commentDateTime}", commentDate: "${obj.commentDate}"}) {
                    returning {
                            commentDate
                            commentId
                            commentText
                            employeeId
                            commentDateTime
                            pollId
                        }
                    }
            }`,
        };
        // console.log(query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('my data', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to create poll!');
        }
        return jsonData.data.insert_pollsdb_poll_comment;
    } catch (err) {
        return { error: err };
    }
};

export const fetchComment = async (pollId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                        pollsdb_poll_comment(where: {pollId: {_eq: "${pollId}"}}, order_by: {commentDate: asc}) {
                            pollId
                            commentId
                            commentText
                            commentDate
                            employeeId
                            Employee {
                                firstName
                                lastName
                                profileImage
                            }
                        }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('my data', jsonData);
        if (jsonData.errors) {
            throw new Error('Failed to create poll!');
        }
        return jsonData.data.pollsdb_poll_comment;
    } catch (err) {
        return { error: err };
    }
};

export const deleteComment = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                delete_pollsdb_poll_comment(where: {commentId: {_eq: "${id}"}}) {
                    affected_rows
                }
            }`,
        };
        // console.log('query', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Comment not deleted!');
        }
        return jsonData.data.delete_pollsdb_poll_comment;
    } catch (err) {
        return { error: err };
    }
};

export const fetchStandupThread = async (organizationId: string, date: any, token: string) => {
    const workspace = localStorage.getItem('slackWorkspaceId');
    try {
        const query = {
            query: `query fetchSlackChannel {
                usermanagementdb_Organization(where: {organizationId: {_eq: "${organizationId}"}}) {
                    standupChannel
                }
                usermanagementdb_SlackBot(where: {workspaceId: {_eq: "${workspace}"}}) {
                    standupToken
                }
                standupdb_StandupNotes(where: {standupNoteDate: {_eq: "${date}"}, StandupTemplate: {organizationId: {_eq: "${organizationId}"}}}) {
                    standupNoteId
                    threadId
                }
            }`,
        };
        const data: any = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};

export const updatePollTable = async (pollId: any, channel: any, timeStamp: any, token: any) => {
    try {
        const mutation = {
            query: `mutation MyMutation {
                        update_pollsdb_Polls(where: {pollId: {_eq: "${pollId}"}}, _set: {channel: "${channel}", timeStamp: "${timeStamp}"}) {
                        returning {
                            channel
                            timeStamp
                        }
                    }
                }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(mutation),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Poll table not updated');
        }
        return jsonData.data.update_pollsdb_Polls;
    } catch (err) {
        return { error: err };
    }
};
