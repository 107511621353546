import { SubscriptionClient } from 'graphql-subscriptions-client';
import { store } from '../components/Redux/store';
import { fetchPendingAction } from '../components/Redux/pendingActionsReducer';
import { getEmployeesList } from '../components/Redux/employeeListReducer';

// const GRAPHQL_ENDPOINT = 'wss://teamplus-api.udyamo.net/v1/graphql';
// const newurlSubscription = 'wss://teamplus-api.udyamo.net/v1/graphql';

const GRAPHQL_ENDPOINT = 'wss://udyamo-hasura-teamplus.udyamo.net/v1/graphql';
const newurlSubscription = 'wss://udyamo-hasura-teamplus.udyamo.net/v1/graphql';

const newurl: any = 'https://udyamo-hasura-teamplus.udyamo.net/v1/graphql';
// const newurl: any = 'https://teamplus-api.udyamo.net/v1/graphql'; // Latest updated URL
// const newurl: any = 'https://teamplus-api-2.udyamo.com/v1/graphql'; // Latest updated URL

// const newurlSubscription = 'wss://udyamo-teamplus.hasura.app/v1/graphql'; // Main subscription one
// const newurlSubscription = 'wss://teamplus-api.udyamo.com/v1/graphql';
// const newurlSubscription = 'wss://teamplus-api-2.udyamo.com/v1/graphql';

// const GRAPHQL_ENDPOINT = 'wss://krdvjolfqoxeyhzgzojx.hasura.ap-south-1.nhost.run/v1/graphql';
// const newurlSubscription = 'wss://krdvjolfqoxeyhzgzojx.hasura.ap-south-1.nhost.run/v1/graphql';
// const newurl: any = 'https://krdvjolfqoxeyhzgzojx.hasura.ap-south-1.nhost.run/v1/graphql'; // Latest updated URL
// const GRAPHQL_ENDPOINT = 'wss://teamplus-backup-dg.udyamo.com/v1/graphql';
// const newurl: any = 'https://teamplus-backup-dg.udyamo.com/v1/graphql';
// const newurlSubscription = 'wss://teamplus-backup-dg.udyamo.com/v1/graphql';
// if (process.env.NODE_ENV === 'development') {
//     newurl = 'https://udyamo-teamplus.hasura.app/v1/graphql';
// } else {
//     newurl = 'https://udyamo-testing.hasura.app/v1/graphql';
// }

// let newurl: any;
// if (process.env.NODE_ENV === 'production') {
//     newurl = 'https://udyamo-teamplus.hasura.app/v1/graphql';
// } else {
//     newurl = 'https://udyamo-testing.hasura.app/v1/graphql';
// }

// if (process.env.NODE_ENV === 'development') {
//     newurl = 'https://udyamo-teamplus.hasura.app/v1/graphql';
// } else {
//     newurl = 'https://udyamo-testing.hasura.app/v1/graphql';
// }
// export const newurl = 'https://udyamo-prod-trial.hasura.app/v1/graphql';
// const websocket = new WebSocket(newurl);
export { newurl, newurlSubscription };
export const getTenantIdForClient = async (id: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
            usermanagementdb_CustomClient(where: {tenantId: {_eq: "${id}"}}) {
                tenantId
            }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.usermanagementdb_CustomClient === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.usermanagementdb_CustomClient[0];
    } catch (err) {
        return { error: err };
    }
};
export const getTenantEmployees = async (id: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
            usermanagementdb_tenant_employee(where: {tenantId: {_eq: "${id}"}}) {
                employeeId
            }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.usermanagementdb_tenant_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.usermanagementdb_tenant_employee;
    } catch (err) {
        return { error: err };
    }
};
export const getEmployee = async (id: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Employee(where: {employeeId: {_eq: "${id}"}}) {
                    tenant_employees{
                      role
                    }
                    status
                    profileImage
                    lastName
                    joining
                    firstName
                    email
                    employeeId
                    organization_employees {
                        Organization {
                            organizationId
                            organizationName
                        }
                        role
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log(jsonData);
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.usermanagementdb_Employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.usermanagementdb_Employee[0];
    } catch (err) {
        return { error: err };
    }
};

export const getEmployee2 = async (id: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Employee(where: {employeeId: {_eq: "${id}"}}) {
                    status
                    profileImage
                    lastName
                    joining
                    firstName
                    email
                    employeeId
                    organization_employees {
                        Organization {
                            organizationId
                            organizationName
                        }
                        role
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const getEmployeeRole = async (id: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${id}"}}) {
                    role
                    Organization {
                        organizationId
                        organizationName
                        Tenant {
                            name
                            workspaceId
                            tenant_employees(where: {employeeId: {_eq: "${id}"}}){
                            role
                            }
                        }
                    }

                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error(jsonData.errors);
        }
        if (jsonData.data.usermanagementdb_organization_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.usermanagementdb_organization_employee;
    } catch (err) {
        return { error: err };
    }
};

export const getEmployeeRole2 = async (id: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${id}"}}) {
                    role
                    Organization {
                        organizationId
                        organizationName
                        Tenant {
                            name
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};
// Admin registration queries
export const registerAdmin = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_Employee(objects: {email: "${obj.email}", firstName: "${obj.firstName}", joining: "${obj.joining}", lastName: "${obj.lastName}", status: "active", profileImage: "${obj.image}", employeeId: "${obj.empId}"}) {
                    returning {
                        employeeId
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.insert_usermanagementdb_Employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_Employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};
export const registerSlackEmployees = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_Employee(objects: {employeeId:"${obj.employeeId}",email: "${obj.email}", firstName: "${obj.firstName}", joining: "${obj.joining}", lastName: "${obj.lastName}",profileImage:"${obj.image}", status: "active"}) {
                    returning {
                        employeeId
                        email
                        firstName
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.insert_usermanagementdb_Employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_Employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};
export const fetchSlackLoginToken = async (slackUserId: any, token: any) => {
    try {
        const response = await fetch(
            'https://sls-swec-dev-tokenforprod.azurewebsites.net/api/token?code=qvfMsYlPFRGK5CVuHluzs1NbFY6Y6PT9SRtDsnhP4zUQAzFuN4xntA==',
            {
                method: 'POST',
                body: JSON.stringify({
                    token: token,
                    source: 'slack',
                    user: slackUserId,
                    secret: 'dEhpc0lzbXkkRWNSMytrRXlGT1J0ZUFtcExVc0B1dGgzTishKGFUaTBu',
                }),
            },
        );
        const d = await response.json();
        return d.body;
    } catch (err) {
        return { error: err };
    }
};

export const fetchMSLoginToken = async (token: any) => {
    try {
        const response = await fetch(
            'https://sls-swec-dev-tokenforprod.azurewebsites.net/api/token?code=qvfMsYlPFRGK5CVuHluzs1NbFY6Y6PT9SRtDsnhP4zUQAzFuN4xntA==',
            {
                method: 'POST',
                body: JSON.stringify({
                    token: token,
                    source: 'teams',
                    secret: 'dEhpc0lzbXkkRWNSMytrRXlGT1J0ZUFtcExVc0B1dGgzTishKGFUaTBu',
                }),
            },
        );

        const d = await response.json();
        // //console.log('THIS IS D', d);
        return d.body;
    } catch (err) {
        return { error: err };
    }
};

export const refreshSlackLoginToken = async (refreshToken: any) => {
    try {
        const response = await fetch(
            'https://sls-swec-dev-tokenforprod.azurewebsites.net/api/token?code=qvfMsYlPFRGK5CVuHluzs1NbFY6Y6PT9SRtDsnhP4zUQAzFuN4xntA==',
            {
                method: 'POST',
                body: JSON.stringify({
                    token: refreshToken,
                    source: 'slack',
                    grant_type: 'refresh_token',
                    secret: 'dEhpc0lzbXkkRWNSMytrRXlGT1J0ZUFtcExVc0B1dGgzTishKGFUaTBu',
                }),
            },
        );
        const d: any = await response.json();
        return d.body;
    } catch (err) {
        return { error: err };
    }
};
export const registerOrganization = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_Organization(objects: {organizationName: "${obj.organizationName}", country: { countryName: "${obj.location?.countryName}", countryCode: "${obj.location?.countryCode}", zoneName: "${obj.location?.zoneName}", gmtOffset: "${obj.location.gmtOffset}", timestamp: "${obj.location?.timestamp}"}}) {
                    returning {
                        organizationId
                        organizationName
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_usermanagementdb_Organization === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_Organization.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const createOrganizationRole = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_organization_employee(objects: {organizationId: "${obj.organizationId}", employeeId:"${obj.employeeId}",role: "${obj.role}", location: { countryName: "${obj.location?.countryName}", countryCode: "${obj.location?.countryCode}", zoneName: "${obj.location?.zoneName}", gmtOffset: "${obj.location.gmtOffset}", timestamp: "${obj.location?.timestamp}"},slackId:"${obj.slackId}",isAccountLinked:"${obj.isAccountLinked}"}) {
                    returning {
                        organizationId
                        role
                        employeeId
                        location
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_usermanagementdb_organization_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_organization_employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};
export const createOrganization_employee_slack = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_organization_employee(objects: {organizationId: "${obj.organizationId}", employeeId:"${obj.employeeId}",role: "${obj.role}",location: { countryName: "${obj.location?.countryName}", countryCode: "${obj.location?.countryCode}", zoneName: "${obj.location?.zoneName}", gmtOffset: "${obj.location.gmtOffset}", timestamp: "${obj.location?.timestamp}"},isAccountLinked:${obj.isAccountLinked},slackId:"${obj.slackId}"}) {
                    returning {
                        organizationId
                        role
                        employeeId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_usermanagementdb_organization_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_organization_employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};
export const createOrganizationSettings = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_timeoffdb_GeneralSettings(objects: {workingHours: ${
                    obj.workingHours
                }, workWeek: ${JSON.stringify(obj.workWeek)}, dateFormat: "${obj.dateFormat}", organizationId: "${
                obj.organizationId
            }"}) {
                    affected_rows
                    returning {
                        settingId
                        organizationId
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log('TIS IS josn', jsonData);
        if (jsonData.errors) {
            return jsonData.errors;
        }
        if (jsonData.data.insert_timeoffdb_GeneralSettings === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_timeoffdb_GeneralSettings.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const createDefaultOrganizationTeam = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_Team(objects: {teamName: "${obj.teamName}", organizationId: "${obj.organizationId}", createdAt: "${obj.createdAt}",slackTeamId:"${obj.slackTeamId}"}) {
                    returning {
                        teamId
                        teamName
                        organizationId
                        teamCreator
                        createdAt
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_usermanagementdb_Team === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_Team.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const checkIfSuperadmin = async (tenantId: any, token: any) => {
    try {
        const query = {
            query: `query fetchSuperAdmin {
                usermanagementdb_tenant_employee(where: {tenantId: {_eq: "${tenantId}"}, role: {_eq: "superadmin"}}) {
                    employeeId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            return jsonData.errors;
        }
        return jsonData.data.usermanagementdb_tenant_employee;
    } catch (err) {
        return { error: err };
    }
};

export const insertTeamEmployee = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_team_employee(objects: {employeeId: "${obj.employeeId}", isApprover: ${obj.isApprover}, teamId: "${obj.teamId}"}) {
                    returning {
                        employeeId
                        isApprover
                        teamId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_usermanagementdb_team_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_team_employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};
export const insertTeamEmployeeSlack = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_team_employee(objects: {employeeId: "${obj.employeeId}", isApprover: ${obj.isApprover}, teamId: "${obj.teamId}"}) {
                    returning {
                        employeeId
                        isApprover
                        teamId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_usermanagementdb_team_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_team_employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const insertPendingActions = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_timeoffdb_PendingActions(objects: {createNewTeam: false, organizationDetails: false, organizationId: "${obj.organizationId}", timeOffPolicy: false}) {
                    affected_rows
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_timeoffdb_PendingActions === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_timeoffdb_PendingActions.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const createDefaultLeaveType = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_timeoffdb_LeaveType(objects: {organizationId: "${obj.organizationId}", leaveTypeName: "${
                obj.leaveTypeName
            }", isPaid: ${obj.isPaid}, daysPerYear: ${obj.daysPerYear}, approvalRequired: ${
                obj.approvalRequired
            }, active: ${obj.active}, unlimitedDays: ${obj.unlimitedDays}, defaultEnable: ${
                obj.defaultEnable
            }, forYear: "${new Date().getFullYear()}", leaveTypeEmoticon: { emoji: "${obj.emoji}", activeSkinTone: "${
                obj.activeSkinTone
            }", unified: "${obj.unified}", originalUnified: "${obj.originalUnified}"}}) {
                    returning {
                        leaveTypeId
                        leaveTypeName
                        daysPerYear
                        approvalRequired
                        active
                        isPaid
                        unlimitedDays
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_timeoffdb_LeaveType === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_timeoffdb_LeaveType.returning[0];
    } catch (err) {
        return { error: err };
    }
};
//end of admin registration

export const fetchPendingLeaves = async (organization_id: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organization_id}"}, leaveStatus: {_eq: "pending"}}) {
                    category
                    employeeId
                    endDate
                    endTime
                    leaveId
                    leaveStatus
                    organizationId
                    reason
                    startDate
                    startTime
                    leaveAppliedBy
                    approverId
                    denierId
                    denialReason
                    gmt
                    noOfHours
                    isoString
                    Employee {
                        email
                        employeeId
                        firstName
                        lastName
                        profileImage
                        team_employees {
                            Team {
                                teamName
                            }
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('Something went wrong');
        // }
        // if (jsonData.data.timeoffdb_Leaves === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.timeoffdb_Leaves;
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const fetchTodaysLeaves = async (organization_id: string, token: string, todays_date: any) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organization_id}"}, leaveStatus: {_eq: "approved"}, startDate: {_lte: "${todays_date}"}, endDate: {_gte: "${todays_date}"}}) {
                    category
                    employeeId
                    endDate
                    endTime
                    leaveId
                    leaveStatus
                    organizationId
                    reason
                    startDate
                    startTime
                    leaveAppliedBy
                    approverId
                    denierId
                    denialReason
                    gmt
                    noOfHours
                    isoString
                    Employee {
                        email
                        employeeId
                        firstName
                        lastName
                        profileImage
                        team_employees {
                            Team {
                                teamName
                            }
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('User not found!');
        // }
        // if (jsonData.data.timeoffdb_Leaves === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.timeoffdb_Leaves;
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const fetchUpcomingLeaves = async (organization_id: string, token: string, todays_date: any) => {
    try {
        const query = {
            query: `query Myquery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organization_id}"}, leaveStatus: {_eq: "approved"}, endDate: {_gt: "${todays_date}"}}) {
                    category
                    employeeId
                    endDate
                    endTime
                    leaveId
                    leaveStatus
                    organizationId
                    reason
                    startDate
                    startTime
                    leaveAppliedBy
                    approverId
                    denierId
                    denialReason
                    gmt
                    noOfHours
                    isoString
                    Employee {
                        email
                        employeeId
                        firstName
                        lastName
                        profileImage
                        team_employees {
                            Team {
                                teamName
                            }
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('User not found!');
        // }
        // if (jsonData.data.timeoffdb_Leaves === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.timeoffdb_Leaves;
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const fetchEmployeeLeaves = async (organizationId: string, token: string, employeeId: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}}) {
                    organizationId
                    leaveCount
                    leaveStatus
                    leaveId
                    category
                    reason
                    startDate
                    endDate
                    leaveAppliedBy
                    startTime
                    endTime
                    approverId
                    denierId
                    denialReason
                    gmt
                    noOfHours
                    isoString
                    Employee{
                        firstName
                        lastName
                        employeeId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'x-hasura-admin-secret': '5EP7zKFwg1vLTjy8Du3rg28TbGG9WpJGSPB58P33YmVoZChR1KTapy8pfN1laCbI',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};

// export const fetchEmployeeLeavesSubscription = async (organizationId: any, token: any, employeeId: any) => {
//     const query = `subscription MySubscription {
//             timeoffdb_Leaves(order_by: {startDate: asc}, where: {employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}}) {
//                 organizationId
//                 leaveCount
//                 leaveStatus
//                 leaveId
//                 category
//                 reason
//                 startDate
//                 endDate
//                 leaveAppliedBy
//                 startTime
//                 endTime
//                 approverId
//                 denierId
//                 denialReason
//                 gmt
//                 noOfHours
//                 isoString
//                 Employee{
//                     firstName
//                     lastName
//                     employeeId
//                 }
//             }
//         }`;
//     const client: any = new SubscriptionClient(newurlSubscription, {
//         reconnect: true,
//         lazy: true,
//         connectionParams: {
//             headers: {
//                 'content-type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//                 // 'x-hasura-admin-secret': 'lcgW6TLyj2Esen8Nln9oPVlPWanarWyPjqqPs1kDQDgU3OnvBmVtKkhsryPfOttH',
//             },
//         },
//     });
//     client.maxConnectTimeGenerator.setMin(7000);
//     client.request({ query }).subscribe({
//         next(data: any) {
//             if (data.data !== null) {
//                 store.dispatch(setEmployeeLeave(data.data.timeoffdb_Leaves));
//             }
//         },
//     });
//     return null;
//     // try {
//     //     const query = {
//     //         query: `query MyQuery {
//     //             timeoffdb_Leaves(where: {employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}}) {
//     //                 organizationId
//     //                 leaveCount
//     //                 leaveStatus
//     //                 leaveId
//     //                 category
//     //                 reason
//     //                 startDate
//     //                 endDate
//     //                 leaveAppliedBy
//     //                 startTime
//     //                 endTime
//     //                 approverId
//     //                 denierId
//     //                 denialReason
//     //                 Employee{
//     //                     firstName
//     //                     lastName
//     //                     employeeId
//     //                 }
//     //             }
//     //         }`,
//     //     };
//     //     const data = await fetch(newurl, {
//     //         method: 'POST',
//     //         headers: {
//     //             'Content-Type': 'application/json',
//     //             Authorization: 'Bearer ' + token,
//     //         },
//     //         body: JSON.stringify(query),
//     //     });
//     //     return data;
//     //     // const jsonData = await data.json();
//     //     // // // //console.log('fetch employee ', jsonData);
//     //     // if (jsonData.errors) {
//     //     //     throw new Error('User not found!');
//     //     // }
//     //     // if (jsonData.data.timeoffdb_Leaves === null) {
//     //     //     throw new Error('Something went wrong, please try again!');
//     //     // }
//     //     // return jsonData.data.timeoffdb_Leaves;
//     // } catch (err) {
//     //     return { error: err };
//     // }
// };

export const fetchEmployeeTodaysLeaves = async (
    organizationId: string,
    token: string,
    employeeId: string,
    date: string,
) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {leaveStatus: {_eq: "approved"}, employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}, startDate: {_lte: "${date}"}, endDate: {_gte: "${date}"}}) {
                    organizationId
                    leaveCount
                    leaveStatus
                    leaveId
                    category
                    reason
                    startDate
                    endDate
                    leaveAppliedBy
                    startTime
                    endTime
                    approverId
                    denierId
                    denialReason
                    gmt
                    noOfHours
                    isoString
                    Employee{
                        firstName
                        lastName
                        employeeId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // // // //console.log('fetch employee ', jsonData);
        // if (jsonData.errors) {
        //     throw new Error('User not found!');
        // }
        // if (jsonData.data.timeoffdb_Leaves === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.timeoffdb_Leaves;
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const fetchEmployeeUpcomingLeaves = async (
    organizationId: string,
    token: string,
    employeeId: string,
    date: string,
) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {leaveStatus: {_eq: "approved"}, employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}, endDate: {_gt: "${date}"}}) {
                    organizationId
                    leaveCount
                    leaveStatus
                    leaveId
                    category
                    reason
                    startDate
                    endDate
                    leaveAppliedBy
                    startTime
                    endTime
                    approverId
                    denierId
                    denialReason
                    gmt
                    noOfHours
                    isoString
                    Employee{
                        firstName
                        lastName
                        employeeId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const totalPeopleCount = async (organization_id: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee_aggregate(where: {organizationId: {_eq: "${organization_id}"}}) {
                    aggregate {
                        count
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
        // const jsonData = await data.json();
        // if (jsonData.data.usermanagementdb_organization_employee_aggregate.aggregate.count === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.usermanagementdb_organization_employee_aggregate.aggregate.count;
    } catch (err) {
        return err;
    }
};

export const peopleOnLeaveCount = async (organization_id: string, token: string, todays_date: any) => {
    try {
        // const query = {
        //     query: `query MyQuery {
        //         timeoffdb_Leaves_aggregate(where: {organizationId: {_eq: "${organization_id}"}, _and: {startDate: {_lte: "${todays_date}"}, endDate: {_gte: "${todays_date}"}, leaveStatus: {_eq: "approved"}}}) {
        //             aggregate {
        //                 count(columns: employeeId, distinct: true)
        //             }
        //         }
        //     }`,
        // };
        const query2 = {
            query: `query MyQuery {
                timeoffdb_Leaves_aggregate(where: {organizationId: {_eq: "${organization_id}"}, startDate: {_lte: "${todays_date}"}, endDate: {_gte: "${todays_date}"}, _or: [{startTime: {_eq: "null"}}, {startTime: {_lte: "${new Date()
                .toString()
                .substring(
                    16,
                    21,
                )}"}}], _and: {_or: [{endTime: {_eq: "null"}}, {endTime: {_gte: "${new Date()
                .toString()
                .substring(16, 21)}"}}]}, leaveStatus: {_eq: "approved"}}) {
                        aggregate {
                            count(columns: employeeId, distinct: true)
                        }
                    }
                }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                'x-hasura-role': 'teamplusadmin',
            },
            body: JSON.stringify(query2),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};

// export const peopleOnLeaveCountSubscription = async (organization_id: any, token: any, todays_date: any) => {
//     try {
//         const query = `subscription MySubscription {
//             timeoffdb_Leaves_aggregate(where: {organizationId: {_eq: "${organization_id}"}, startDate: {_lte: "${todays_date}"}, endDate: {_gte: "${todays_date}"}, _or: [{startTime: {_eq: "null"}}, {startTime: {_lte: "${new Date()
//             .toString()
//             .substring(
//                 16,
//                 21,
//             )}"}}], _and: {_or: [{endTime: {_eq: "null"}}, {endTime: {_gte: "${new Date()
//             .toString()
//             .substring(16, 21)}"}}]}, leaveStatus: {_eq: "approved"}}) {
//                 aggregate {
//                     count(columns: employeeId, distinct: true)
//                 }
//             }
//         }`;
//         const client: any = new SubscriptionClient(newurlSubscription, {
//             reconnect: true,
//             lazy: true,
//             connectionParams: {
//                 headers: {
//                     'content-type': 'application/json',
//                     Authorization: 'Bearer ' + token,
//                     // 'x-hasura-admin-secret': 'lcgW6TLyj2Esen8Nln9oPVlPWanarWyPjqqPs1kDQDgU3OnvBmVtKkhsryPfOttH',
//                 },
//             },
//         });
//         client.maxConnectTimeGenerator.setMin(7000);
//         client.request({ query }).subscribe({
//             next(data: any) {
//                 if (data.data.timeoffdb_Leaves_aggregate !== null) {
//                     store.dispatch(updateLeaveCountToday(data.data.timeoffdb_Leaves_aggregate.aggregate.count));
//                 }
//             },
//         });
//         return null;
//     } catch (err) {
//         return { error: err };
//     }
// };

export const fetchLeaveTypesByMonthNew = async (
    organization_id: string,
    token: string,
    startDate: string,
    endDate: string,
) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_get_leavetype_count4(order_by: { name: asc }, args: {enddate: "${endDate}", organizationid: "${organization_id}", startdate: "${startDate}"}) {
                    name
                    value
                }
                timeoffdb_get_MonthlyLeaves(order_by: { name: asc }, args: {organizationid: "${organization_id}", startdate: "${startDate}", enddate: "${endDate}"}) {
                    name
                    value
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const fetchLeaveTypesByMonthNew2 = async (
    organization_id: string,
    token: string,
    startDate: string,
    endDate: string,
) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_get_leavetype_count4(order_by: { name: asc }, args: {enddate: "${endDate}", organizationid: "${organization_id}", startdate: "${startDate}"}) {
                    name
                    value
                }
                timeoffdb_get_MonthlyLeaves(order_by: { name: asc }, args: {organizationid: "${organization_id}", startdate: "${startDate}", enddate: "${endDate}"}) {
                    name
                    value
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.error) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};

export const fetchPendingActionsSubscription = async (organization_id: any, token: any) => {
    const client: any = new SubscriptionClient(newurlSubscription, {
        reconnect: true,
        lazy: true,
        connectionParams: {
            headers: {
                'content-type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        },
    });

    client.maxConnectTimeGenerator.setMin(7000);

    const query = `subscription MySubscription {
                    timeoffdb_PendingActions(where: {organizationId: {_eq: "${organization_id}"}}) {
                        createNewTeam
                        organizationDetails
                        pendingActionId
                        timeOffPolicy
                    }
                }`;
    client.request({ query }).subscribe({
        next({ data }: { data: any }) {
            if (data != null) {
                store.dispatch(fetchPendingAction(data.timeoffdb_PendingActions[0]));
            }
        },
    });
};

// Query and mutation for settings/organizationSettings page
export const fetchOrganizationSettings = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_GeneralSettings(where: {organizationId: {_eq: "${organizationId}"}}) {
                    approverCalledAs
                    dateFormat
                    employeeCalledAs
                    subscriptionPlan
                    teamsCalledAs
                    workWeek
                    workingHours
                    settingId
                    organizationId
                    Organization {
                        organizationName
                        Tenant {
                            name
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.timeoffdb_GeneralSettings === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.timeoffdb_GeneralSettings[0];
    } catch (err) {
        return err;
    }
};

export const updateOrganizationSettings = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_timeoffdb_GeneralSettings(where: {Organization: {organizationId: {_eq: "${
                    obj.organizationId
                }"}}}, _set: {approverCalledAs: "${obj.approverCalledAs}", dateFormat: "${
                obj.dateFormat
            }", employeeCalledAs: "${obj.employeeCalledAs}", subscriptionPlan: "${
                obj.subscriptionPlan
            }", teamsCalledAs: "${obj.teamsCalledAs}", workWeek: ${JSON.stringify(obj.workWeek)}, workingHours: ${
                obj.workingHours
            }}) {
                    returning {
                        approverCalledAs
                        dateFormat
                        employeeCalledAs
                        organizationId
                        settingId
                        subscriptionPlan
                        teamsCalledAs
                        workWeek
                        workingHours
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_timeoffdb_GeneralSettings === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_timeoffdb_GeneralSettings.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateOrganization = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_usermanagementdb_Organization(where: {organizationId: {_eq: "${obj.organizationId}"}}, _set: {organizationName: "${obj.organizationName}"}) {
                    returning {
                        organizationId
                        organizationName
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_usermanagementdb_Organization === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_usermanagementdb_Organization.returning[0];
    } catch (err) {
        return { error: err };
    }
};

// update pending actions
export const updateOrganizationPendingAction = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_timeoffdb_PendingActions(where: {organizationId: {_eq: "${organizationId}"}}, _set: {organizationDetails: true}) {
                    returning {
                        createNewTeam
                        organizationId
                        organizationDetails
                        timeOffPolicy
                        pendingActionId
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_timeoffdb_PendingActions === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_timeoffdb_PendingActions.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateCreateNewTeamPendingAction = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_timeoffdb_PendingActions(where: {organizationId: {_eq: "${organizationId}"}}, _set: {createNewTeam: true}) {
                    returning {
                        createNewTeam
                        organizationId
                        organizationDetails
                        timeOffPolicy
                        pendingActionId
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_timeoffdb_PendingActions === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_timeoffdb_PendingActions.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateTimeOffPolicyPendingAction = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_timeoffdb_PendingActions(where: {organizationId: {_eq: "${organizationId}"}}, _set: {timeOffPolicy: true}) {
                    returning {
                        createNewTeam
                        organizationId
                        organizationDetails
                        timeOffPolicy
                        pendingActionId
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_timeoffdb_PendingActions === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_timeoffdb_PendingActions.returning[0];
    } catch (err) {
        return { error: err };
    }
};

// fetch country wise holiday from HolidayData table

export const importHoliday1 = async (token: string, code: string, year: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_HolidayData(where: {countryCode: {_eq: "${code}"}, holidayYear: {_eq: "${year}"}}) {
                    countryName
                    holidayDataId
                    holidayDate
                    holidayDay
                    holidayName
                    holidayYear
                    countryCode
                }
                }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // //console.log('query:', query);
        const jsonData = await data.json();
        // //console.log('my json data', jsonData);
        return jsonData.data?.timeoffdb_HolidayData;
    } catch (err) {
        // //console.log('error:', err);
        return { error: err };
    }
};

export const importHoliday2 = async (token: string, code: string, year: string) => {
    // //console.log('my data', token, year, code);
    try {
        const data = await fetch(
            `https://calendarific.com/api/v2/holidays?&api_key=af1b0134c1c1affceed252acd8713089ecf7c72f
&country=${code.toUpperCase()}&year=${year}`,
        );
        const jsonData = await data.json();
        // //console.log('my json data', jsonData);
        if (jsonData?.meta?.code !== 200) {
            throw new Error('Import Holidays not found!');
        }
        return jsonData.response?.holidays;
    } catch (err) {
        return { error: err };
    }
};

export const addAllHolidaysToDb = async (obj: any, token: string) => {
    // //console.log('called ', obj, token);
    try {
        let holidays = JSON.stringify(obj);
        let length = holidays.length;
        while (length !== 0) {
            holidays = holidays.replace('"holidayName"', 'holidayName');
            holidays = holidays.replace('"holidayDate"', 'holidayDate');
            holidays = holidays.replace('"holidayDay"', 'holidayDay');
            holidays = holidays.replace('"holidayYear"', 'holidayYear');
            holidays = holidays.replace('"countryName"', 'countryName');
            holidays = holidays.replace('"countryCode"', 'countryCode');
            length = length - 1;
        }
        const query = {
            query: `mutation MyMutation {
                insert_timeoffdb_HolidayData(objects: ${holidays}) {
                    affected_rows
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log('jsonData ', jsonData);
        return jsonData;
    } catch (err) {
        // //console.log('Error', err);
        return err;
    }
};

export const deleteHoliday = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                delete_timeoffdb_Holiday(where: {holidayId: {_eq: "${id}"}}) {
                  affected_rows
                  returning {
                    holidayId
                    holidayName
                  }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData;
    } catch (err) {
        return { error: err };
    }
};

export const createHoliday = async (holiday: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                insert_timeoffdb_Holiday(objects: {
                    holidayName: "${holiday.holidayName}",
                    isMultidayHoliday: "${holiday.isMultidayHoliday}",
                    startDate: "${holiday.startDate}",
                    endDate: "${holiday.endDate}",
                    organizationId: "${holiday.organizationId}"
                    holidayDay: "${holiday.day}"
                }) {
                  affected_rows
                  returning {
                    holidayId
                    holidayName
                    organizationId
                    holidayDay
                    isMultidayHoliday
                    startDate
                    endDate
                  }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // // //console.log('Creating holiday with data: ', jsonData);
        return jsonData.data.insert_timeoffdb_Holiday.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const updateHoliday = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                update_timeoffdb_Holiday(where: {holidayId: {_eq: "${obj.holidayId}"}}, _set: {
                    holidayName: "${obj.holidayName}",
                    startDate: "${obj.startDate}",
                    endDate: "${obj.endDate}",
                    isMultidayHoliday: "${obj.isMultidayHoliday}"
                    holidayDay: "${obj.day}"
                }) {
                    affected_rows
                    returning {
                      holidayId
                      startDate
                      endDate
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData.data.update_timeoffdb_Holiday;
    } catch (err) {
        return { error: err };
    }
};
// export const fetchtimeoffSubscription = async (obj: any) => {
//     const client: any = new SubscriptionClient(GRAPHQL_ENDPOINT, {
//         reconnect: true,
//         lazy: true,
//         // connectionCallback: error => // //console.error(error),
//         connectionParams: {
//             headers: {
//                 'content-type': 'application/json',
//                 Authorization: 'Bearer ' + obj.token,
//             },
//         },
//     });

//     client.maxConnectTimeGenerator.setMin(7000);

//     const query = `subscription {
//                 timeoffdb_LeaveType(where: {organizationId: {_eq: "${
//                     obj.organizationId
//                 }"}, forYear: {_eq: "${new Date().getFullYear()}"}}, order_by: {leaveTypeName: asc}) {
//                     approvalRequired
//                     daysPerYear
//                     isPaid
//                     leaveTypeId
//                     leaveTypeName
//                     leaveTypeEmoticon
//                     organizationId
//                     unlimitedDays
//                     active
//                     defaultEnable
//                 }
//             }`;
//     client.request({ query }).subscribe({
//         next({ data }: { data: any }) {
//             if (data != null) {
//                 store.dispatch(fetchTimeOff(data.timeoffdb_LeaveType));
//             }
//         },
//     });
// };

export const fetchtimeoff = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_LeaveType(where: {organizationId: {_eq: "${organizationId}"}, forYear: {_eq: "${new Date().getFullYear()}"}}, order_by: {leaveTypeName: asc}) {
                    approvalRequired
                    daysPerYear
                    isPaid
                    leaveTypeId
                    leaveTypeName
                    organizationId
                    unlimitedDays
                    active
                    defaultEnable
                    leaveTypeEmoticon
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // // // //console.log('My data =', jsonData);
        // if (jsonData.errors) {
        //     throw new Error('TimeOFF Data not found!');
        // }
        // return jsonData.data.timeoffdb_LeaveType;
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const autoapprovetimeoff = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_timeoffdb_LeaveType(where: {organizationId: {_eq: "${
                    obj.organizationId
                }", forYear: {_eq: "${new Date().getFullYear()}"}}, leaveTypeId: {_eq: "${
                obj.leaveTypeId
            }"}}, _set: {approvalRequired: false}) {
                    affected_rows
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // // //console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Auto Approve TimeOff Data Not Found!');
        }
        return jsonData.data.update_timeoffdb_LeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const edittimeoff = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_timeoffdb_LeaveType(where: {organizationId: {_eq: "${
                    obj.organizationId
                }"}, leaveTypeId: {_eq: "${
                obj.leaveTypeId
            }"}, forYear: {_eq: "${new Date().getFullYear()}"}}, _set: {leaveTypeName: "${
                obj.leaveTypeName
            }", isPaid: ${obj.isPaid}, unlimitedDays: ${obj.unlimitedDays}, daysPerYear: ${
                obj.daysPerYear
            }, leaveTypeEmoticon: { emoji: "${obj.emoji}", activeSkinTone: "${obj.activeSkinTone}", unified: "${
                obj.unified
            }", originalUnified: "${obj.originalUnified}" } }) {
                    returning {
                       leaveTypeId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Auto Approve TimeOff Data Not Found!');
        }
        return jsonData.data.update_timeoffdb_LeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const timeoffLeaveStatus = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_timeoffdb_LeaveType(where: {organizationId: {_eq: "${obj.organizationId}"}, leaveTypeId: {_eq: "${obj.leaveTypeId}"}}, _set: {active: ${obj.active}, approvalRequired: ${obj.approvalRequired}}) {
                    returning {
                        active
                        leaveTypeName
                        leaveTypeId
                        approvalRequired
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // // //console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('TimeOFF Data not found!');
        }
        return jsonData.data.update_timeoffdb_LeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const Addtimeoff = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_timeoffdb_LeaveType(objects: {organizationId: "${obj.organizationId}", unlimitedDays: ${
                obj.unlimitedDays
            }, daysPerYear: ${obj.daysPerYear}, leaveTypeName: "${obj.leaveTypeName}", isPaid: ${
                obj.isPaid
            }, forYear: "${new Date().getFullYear()}", leaveTypeEmoticon: { emoji: "${obj.emoji}", activeSkinTone: "${
                obj.activeSkinTone
            }", unified: "${obj.unified}", originalUnified: "${obj.originalUnified}" } }) {
                   returning {
                    leaveTypeName
                    leaveTypeId
                    unlimitedDays
                    leaveTypeEmoticon
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log('abcd', jsonData);
        if (jsonData.errors) {
            throw new Error('Insert Query Wrong');
        }
        return jsonData.data.insert_timeoffdb_LeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const DeleteTimeOff = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                delete_timeoffdb_LeaveType(where: {organizationId: {_eq: "${obj.organizationId}"}, leaveTypeId: {_eq: "${obj.leaveTypeId}"}, defaultEnable: {_eq: false}}) {
                    returning {
                        leaveTypeId
                        leaveTypeName
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log('abcd', jsonData);
        if (jsonData.errors) {
            throw new Error('Delete TimeOff Data Not Found!');
        }
        return jsonData.data.delete_timeoffdb_LeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const UnlimitedDayStatus = async (obj: any) => {
    try {
        // const query = {
        //     query: `mutation MyMutation {
        //         update_timeoffdb_LeaveType(where: {organizationId: {_eq: "${obj.organizationId}"}}, _set: {unlimitedDays: ${obj.unlimitedDays}}) {
        //             returning {
        //                 leaveTypeName
        //                 isPaid
        //                 active
        //                 daysPerYear
        //                 unlimitedDays
        //             }
        //         }
        //     }`,
        // };
        const query = {
            query: `mutation MyMutation {
                update_timeoffdb_LeaveType(where: {organizationId: {_eq: "${obj.organizationId}"}, daysPerYear: {_gt: 0}}, _set: {unlimitedDays: ${obj.unlimitedDays}}) {
                    returning {
                        leaveTypeName
                        isPaid
                        active
                        daysPerYear
                        unlimitedDays
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('TimeOFF Data not found!');
        }
        return jsonData.data.update_timeoffdb_LeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationEmployeesTeams = async (obj: any) => {
    let a: any;
    const client: any = new SubscriptionClient(GRAPHQL_ENDPOINT, {
        reconnect: true,
        lazy: true,
        // connectionCallback: error => // //console.error(error),
        connectionParams: {
            headers: {
                'content-type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
        },
    });

    client.maxConnectTimeGenerator.setMin(7000);

    const query = `subscription {
                        usermanagementdb_organization_employee(order_by: {Employee: {firstName: asc}}, where: {organizationId: {_eq: "${obj.organizationId}"}}) {
                            role
                            Employee {
                                firstName
                                lastName
                                employeeId
                                email
                                status
                                profileImage
                                team_employees(where: {Team: {organizationId: {_eq: "${obj.organizationId}"}}}) {
                                    employeeId
                                    isApprover
                                    teamId
                                    Team {
                                        teamId
                                        teamName
                                    }
                                }
                            }
                        }
                    }`;
    client.request({ query }).subscribe({
        next({ data }: { data: any }) {
            if (data != null) {
                // //console.log('Data 2080: ', data);
                a = data;
                store.dispatch(getEmployeesList(data));
            }
        },
    });
    return a;
};

export const updateOrganizationEmployee = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                update_usermanagementdb_organization_employee(where: {organizationId: {_eq: "${obj.orgId}"}, employeeId: {_eq: "${obj.empId}"}}, _set: {role: "${obj.role}"}) {
                    returning {
                        employeeId
                        role
                        organizationId
                    }
                    affected_rows
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                'x-hasura-role': 'teamplusadmin',
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // // //console.log('Json data: ', jsonData);
        return jsonData.data.update_usermanagementdb_organization_employee;
    } catch (err) {
        return { error: err };
    }
};
// Fetch organization approved leaves
export const fetchOrganizationLeaves2 = async (organizationId: string, token: string, leaveStatus: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${organizationId}"}, leaveStatus: {_eq: "${leaveStatus}"}}) {
                    leaveId
                    startDate
                    endDate
                    category
                    gmt
                    isoString
                    startTime
                    endTime
                    employeeId
                    noOfHours
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // // // //console.log('My data ', jsonData);
        // if (jsonData.errors) {
        //     throw new Error('Insert Query Wrong');
        // }
        // return jsonData.data.timeoffdb_Leaves;
        return data;
    } catch (err) {
        return { error: err };
    }
};

// get employees on leave for current month
export const fetchEmployeesOnLeave2 = async (obj: any) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {organizationId: {_eq: "${obj.organizationId}"}, startDate: {_gte: "${obj.startDate}"}, endDate: {_lte: "${obj.endDate}"}, leaveStatus: {_eq: "${obj.leaveStatus}"}}) {
                    leaveId
                    startDate
                    endDate
                    startTime
                    endTime
                    leaveAppliedBy
                    gmt
                    noOfHours
                    isoString
                    Employee {
                        firstName
                        lastName
                        employeeId
                        profileImage
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // // // //console.log('My data ', jsonData);
        // if (jsonData.errors) {
        //     throw new Error('Insert Query Wrong');
        // }
        // return jsonData.data.timeoffdb_Leaves;
        return data;
    } catch (err) {
        return { error: err };
    }
};

// To add leave

export const createLeave = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_timeoffdb_Leaves(objects: {organizationId: "${obj.organizationId}", employeeId: "${obj.employeeId}", category: "${obj.category}", startDate: "${obj.startDate}", endDate: "${obj.endDate}", startTime: "${obj.startTime}", endTime: "${obj.endTime}", gmt: "${obj.gmt}", noOfHours: ${obj.noOfHours}, leaveStatus: "${obj.leaveStatus}", reason: "${obj.reason}", isoString: { startTime: "${obj.isoString.startTime}", endTime: "${obj.isoString.endTime}"}}) {
                    affected_rows
                    returning {
                        organizationId
                        leaveId
                        category
                        employeeId
                        leaveAppliedBy
                        reason
                        leaveStatus
                        noOfHours
                        startDate
                        endDate
                        startTime
                        endTime
                        denierId
                        denialReason
                        approverId
                        gmt
                        isoString
                        Employee {
                            employeeId
                            firstName
                            lastName
                            profileImage
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        // //console.log('sadfdsf', data);
        const jsonData = await data.json();
        // //console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Insert Query Wrong');
        }
        return jsonData.data.insert_timeoffdb_Leaves;
    } catch (err) {
        return { error: err };
    }
};

export const daysPerYearForLeaveTypes = async (organizationId: any, token: any) => {
    const query = {
        query: `query MyQuery {
            timeoffdb_LeaveType(order_by: {leaveTypeName: asc}, where: {organizationId: {_eq: "${organizationId}"},forYear: { _eq: "${new Date().getFullYear()}" }}) {
                leaveTypeName
                daysPerYear
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        if (resp.status !== 200) {
            const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
            throw error;
        }
        // const daysPerYear = await resp.json();
        // // // //console.log('days per year ', daysPerYear);
        // return daysPerYear.data.timeoffdb_LeaveType;
        return resp;
    } catch (err) {
        return err?.message;
    }
};

export const getAllOrganizationTeams = async (orgId: string, token: string) => {
    try {
        const query = {
            query: `query GetTeams {
                usermanagementdb_Organization(where: {organizationId: {_eq: "${orgId}"}}) {
                    organizationId
                    organizationName
                    Tenant {
                        name
                    }
                    Teams {
                        teamId
                        teamName
                        msChannelId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('Teams not found!');
        // }
        // if (jsonData.data.usermanagementdb_Organization === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.usermanagementdb_Organization[0].Teams;
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const fetchApproverList = async (organizationId: any, token: any) => {
    const query = {
        query: `query MyQuery {
            timeoffdb_get_approverdeniername1(args: {organizationid: "${organizationId}"}) {
                approverId
                denierId
                firstName
                lastName
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // if (resp.status !== 200) {
        //     const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
        //     throw error;
        // }
        // const jsonData = await resp.json();
        // return jsonData.data.timeoffdb_get_approverdeniername1;
        return resp;
    } catch (err) {
        return err?.message;
    }
};

// delet and update leave (new for current PR)
export const deleteLeave = async (leaveId: any, token: any) => {
    // console.log(leaveId);
    const query = {
        query: `
        mutation MyMutation {
            delete_timeoffdb_NotificationSeen(where: {Notification: {leaveId: {_eq: "${leaveId}"}}}) {
                affected_rows
                returning {
                    employeeId
                }
            }
            delete_timeoffdb_Notifications(where: {leaveId: {_eq: "${leaveId}"}}) {
                affected_rows
                returning {
                    employeeId
                    leaveId
                }
            }
            delete_timeoffdb_Leaves(where: {leaveId: {_eq: "${leaveId}"}}) {
                returning {
                    employeeId
                    leaveId
                }
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                // 'x-hasura-role': 'admin'
            },
            body: JSON.stringify(query),
        });
        if (resp.status !== 200) {
            const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
            throw error;
        }
        const jsonData = await resp.json();
        // console.log('deleteLeave', jsonData);
        return jsonData.data.delete_timeoffdb_Leaves;
    } catch (err) {
        return err?.message;
    }
};

export const updateLeaveAdmin = async (obj: any, isAdmin: boolean) => {
    // console.log(isAdmin)
    const query = {
        query: `mutation MyMutation {
            update_timeoffdb_Leaves(where: {leaveId: {_eq: "${obj.leaveId}"}}, _set: {approverId: "${obj.approverId}", denialReason: "${obj.denialReason}", denierId: "${obj.denierId}", leaveStatus: "${obj.leaveStatus}"}) {
                returning {
                    leaveId
                    leaveStatus
                    startDate
                    startTime
                    endDate
                    endTime
                    category
                    gmt
                }
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-hasura-role': isAdmin ? 'teamplusadmin' : 'approver',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        if (resp.status !== 200) {
            const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
            throw error;
        }
        const jsonData = await resp.json();
        // console.log(jsonData);
        return jsonData.data.update_timeoffdb_Leaves.returning[0];
    } catch (err) {
        return { errors: err };
    }
};

export const updateLeaveAdminMultiple = async (obj: any, isAdmin: boolean) => {
    const query = {
        query: `mutation MyMutation {
            update_timeoffdb_Leaves(where: {leaveId: {_in: ${JSON.stringify(obj.leaveIds)}}}, _set: {approverId: "${
            obj.approverId
        }", denialReason: "${obj.denialReason}", denierId: "${obj.denierId}", leaveStatus: "${obj.leaveStatus}"}) {
                returning {
                    leaveId
                    leaveStatus
                    startDate
                    endDate
                    startTime
                    endTime
                    employeeId
                    gmt
                }
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-hasura-role': isAdmin ? 'teamplusadmin' : 'approver',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        if (resp.status !== 200) {
            const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
            throw error;
        }
        const jsonData = await resp.json();
        return jsonData.data.update_timeoffdb_Leaves.returning;
    } catch (err) {
        return { errors: err };
    }
};

export const updateLeaveEmployee = async (obj: any, isApprover: boolean) => {
    const query = {
        query: `mutation MyMutation {
            update_timeoffdb_Leaves(where: {leaveId: {_eq: "${obj.leaveId}"}}, _set: {category: "${obj.category}", endDate: "${obj.endDate}", endTime: "${obj.endTime}", noOfHours: ${obj.noOfHours}, reason: "${obj.reason}", startDate: "${obj.startDate}", startTime: "${obj.startTime}", gmt: "${obj.gmt}", isoString: { startTime: "${obj.isoString.startTime}", endTime: "${obj.isoString.endTime}"}}) {
                returning {
                    approverId
                    category
                    denialReason
                    denierId
                    employeeId
                    endDate
                    endTime
                    leaveAppliedBy
                    leaveId
                    leaveStatus
                    noOfHours
                    organizationId
                    reason
                    startDate
                    startTime
                    gmt
                }
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-hasura-role': isApprover ? 'approver' : 'employee',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        if (resp.status !== 200) {
            const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
            throw error;
        }
        const jsonData = await resp.json();
        return jsonData.data.update_timeoffdb_Leaves.returning[0];
    } catch (err) {
        return err?.message;
    }
};

export const getEmployeeTeams = async (employeeID: any, organizationID: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Team(where: {organizationId: {_eq: "${organizationID}"}}) {
                    teamId
                    teamName
                    slackTeamId
                    msChannelId
                    isbotAdded
                    team_employees(where: {employeeId: {_eq: "${employeeID}"}}) {
                        isApprover
                        Employee {
                            employeeId
                            firstName
                            lastName
                            profileImage
                        }
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('User not found!');
        // }
        // if (jsonData === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data;
    } catch (err) {
        return err;
    }
};

export const leaveTypeName = async (organizationID: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_LeaveType(where: {organizationId: {_eq: "${organizationID}"}}) {
                    leaveTypeName
                    daysPerYear
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.timeoffdb_LeaveType;
    } catch (err) {
        return err;
    }
};

export const leaveTypeData = async (employeeID: any, organizationID: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_get_totalhours(args: {employeeid: "${employeeID}", organizationid: "${organizationID}"}) {
                    employeeId
                    leaveType
                    totalHoursLeave
                    workingHours
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.timeoffdb_get_totalhours;
    } catch (err) {
        return err;
    }
};

export const leaveTypeData2 = async (employeeID: any, organizationID: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_get_totalhours(args: {employeeid: "${employeeID}", organizationid: "${organizationID}"}) {
                    employeeId
                    leaveType
                    totalHoursLeave
                    workingHours
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('User not found!');
        // }
        // if (jsonData === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.timeoffdb_get_totalhours;
    } catch (err) {
        return err;
    }
};

export const employeeLeaveHistory = async (
    employeeID: any,
    organizationID: string,
    currentDate: string,
    token: string,
) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(order_by: {startDate: asc}, where: {employeeId: {_eq: "${employeeID}"}, organizationId: {_eq: "${organizationID}"}, startDate: {_lte: "${currentDate}"}}) {
                    leaveId
                    startDate
                    endDate
                    startTime
                    endTime
                    category
                    leaveAppliedBy
                    leaveStatus
                    reason
                    gmt
                    noOfHours
                    isoString
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('User not found!');
        // }
        // if (jsonData.data.timeoffdb_Leaves === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.timeoffdb_Leaves;
    } catch (err) {
        return err;
    }
};

export const uploadImage = async (employeeID: any, base64Image: any, token: string) => {
    try {
        const query = {
            query: `mutation MyMutation {
                        uploadImage(employeeId: "${employeeID}", profileImage: "${base64Image}") {
                            message
                        }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Error in uploading image. Please try again.');
        }
        return jsonData.data.uploadImage.message;
    } catch (err) {
        return err;
    }
};

export const EditNameProfile = async (obj: any) => {
    let abc: any;
    let def: any;
    if (obj.workAnniversary === null) {
        abc = null;
    } else {
        abc = JSON.stringify(obj.workAnniversary);
    }

    if (obj.dob === null) {
        def = null;
    } else {
        def = JSON.stringify(obj.dob);
    }

    try {
        const query = {
            query: `mutation MyMutation {
                        update_usermanagementdb_Employee(where: {employeeId: {_eq: "${obj.EmployeeId}"}}, _set: {firstName: "${obj.firstname}", lastName: "${obj.lastname}",dateOfBirth: ${def}}) {
                            affected_rows
                            returning {
                                employeeId
                                firstName
                                lastName
                                dateOfBirth
                            }
                        }
                        update_usermanagementdb_tenant_employee(where: {employeeId: {_eq: "${obj.EmployeeId}"}, tenantId: {_eq: "${obj.tenantId}"}}, _set: {workAnniversary: ${abc}, isDOB: ${obj.isDOB},isYear: ${obj.isYear}}) {
                            returning {
                                workAnniversary
                            }
                        }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log(jsonData);
        if (jsonData.errors) {
            throw new Error('Error in updating name. Please try again.');
        }
        return jsonData.data.update_usermanagementdb_Employee;
    } catch (err) {
        return { error: err };
    }
};

export const updateOrganizationWebhook = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_usermanagementdb_Organization(where: {organizationId: {_eq: "${obj.orgId}"}}, _set: {webhookURL: "${obj.webhookURL}"}) {
                    affected_rows
                    returning {
                        organizationId
                        organizationName
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Webhook URL updation failed.');
        }
        return jsonData.data.update_usermanagementdb_Organization.affected_rows;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationWebhook = async (orgId: any, token: any) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_Organization(where: {organizationId: {_eq: "${orgId}"}}) {
                    organizationId
                    organizationName
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Webhook URL fetching failed.');
        }
        return jsonData.data.usermanagementdb_Organization[0];
    } catch (err) {
        return { error: err };
    }
};

// export const deleteEmployeeFromTeams = async (empId: any, token: any) => {
//     try {
//         const query = {
//             query: `mutation {
//                 delete_usermanagementdb_team_employee(where: {employeeId: {_eq: "${empId}"}}) {
//                     affected_rows
//                 }
//             }`,
//         };

//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//             },
//             body: JSON.stringify(query),
//         });

//         const jsonData = await data.json();
//         if (jsonData.errors) {
//             throw new Error('Unable to delete employee from teams.');
//         }
//         return jsonData.data.delete_usermanagementdb_team_employee;
//     } catch (err) {
//         return { error: err };
//     }
// };

export const ifTeamExists = async (orgId: any, teamId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                    usermanagementdb_Team_aggregate(where: {organizationId: {_eq: "${orgId}"}, msChannelId: {_eq: "${teamId}"}}) {
                        aggregate {
                        count
                        }
                    }
                    }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Webhook URL fetching failed.');
        }
        return jsonData.data.usermanagementdb_Team_aggregate;
    } catch (err) {
        return { error: err };
    }
};

export const getTenantId = async (workspaceId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Tenant(where: {workspaceId: {_eq: "${workspaceId}"}}) {
                    id
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();

        if (jsonData.errors) {
            return jsonData.errors;
        }
        return jsonData.data.usermanagementdb_Tenant;
    } catch (err) {
        return { error: err };
    }
};

export const ifSlackTeamExists = async (orgId: any, teamId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                    usermanagementdb_Team_aggregate(where: {organizationId: {_eq: "${orgId}"}, slackTeamId: {_eq: "${teamId}"}}) {
                        aggregate {
                        count
                        }
                    }
                    }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Webhook URL fetching failed.');
        }
        return jsonData.data.usermanagementdb_Team_aggregate;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationTeams = async (organizationId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Team(where: {organizationId: {_eq: "${organizationId}"}}) {
                    teamId
                    teamName
                    createdAt
                    organizationId
                    msChannelId
                    team_employees {
                    Employee {
                        employeeId
                        email
                        firstName
                        lastName
                        status
                        profileImage
                        organization_employees(where: {role: {_eq: "admin"}, organizationId: {_eq: "${organizationId}"}}) {
                        role
                        }
                    }
                    isApprover
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('usermanagementdb_Team Data not found!');
        }
        return jsonData.data.usermanagementdb_Team;
    } catch (err) {
        return { error: err };
    }
};

export const DeleteTeam = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                delete_usermanagementdb_team_employee(where: {teamId: {_eq: "${obj.teamId}"}}) {
                    affected_rows
                    returning {
                        employeeId
                    }
                }
                delete_pollsdb_poll_team(where: {teamId: {_eq: "${obj.teamId}"}}) {
                    affected_rows
                }
                delete_usermanagementdb_Team(where: {organizationId: {_eq: "${obj.organizationId}"}, teamId: {_eq: "${obj.teamId}"}, teamName: {_neq: "general"}}) {
                    returning {
                        teamId
                        teamName
                        organizationId
                    }
                    affected_rows
                }
                delete_pollsdb_poll_team(where: {teamId: {_eq: "${obj.teamId}"}}) {
                    affected_rows
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Delete Team Data Not Found!');
        }
        return jsonData.data.delete_usermanagementdb_Team;
    } catch (err) {
        return { error: err };
    }
};

export const CreateTeam = async (teamData: any, token: any) => {
    let usersData: string = JSON.stringify(teamData);
    let length: number = usersData.length;
    while (length !== 0) {
        usersData = usersData.replace('"employeeId"', 'employeeId');
        usersData = usersData.replace('"isApprover"', 'isApprover');
        usersData = usersData.replace('"teamId"', 'teamId');
        length = length - 1;
    }
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_team_employee(objects: ${usersData}) {
                    affected_rows
                    returning {
                        employeeId
                        isApprover
                        teamId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('insert_usermanagementdb_team_employee Data Not found!');
        }
        return jsonData.data.insert_usermanagementdb_team_employee;
    } catch (err) {
        return { error: err };
    }
};

export const EmployeeRole = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_usermanagementdb_team_employee(where: {teamId: {_eq: "${obj.teamId}"}, employeeId: {_eq: "${obj.employeeId}"}}, _set: {isApprover: ${obj.isApprover}}) {
                    affected_rows
                    returning {
                        employeeId
                        isApprover
                        teamId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('update_usermanagementdb_team_employee Data not found!');
        }
        return jsonData.data.update_usermanagementdb_team_employee;
    } catch (err) {
        return { error: err };
    }
};
export const UpdateUserLicenses = async (obj: any) => {
    // console.log(obj.subscriptionType)
    try {
        const subscriptionId = obj.subscriptionId ? `"${obj.subscriptionId}"` : null;
        const query = {
            query: `mutation MyMutation {
                    update_usermanagementdb_tenant_employee(where: {employeeId: {_in: ${JSON.stringify(
                        obj.empIdArray,
                    )}}}, _set: {${obj.subscriptionType}: ${subscriptionId}}) {
                        returning {
                        employeeId
                        standupSubscriptionId
                        timeoffSubscriptionId
                        }
                    }
                    }`,
        };
        // console.log(query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log(jsonData);
        if (jsonData.errors) {
            throw new Error('Unable to update user license');
        }
        return jsonData.data.update_usermanagementdb_tenant_employee;
    } catch (err) {
        return { error: err };
    }
};
export const updateIsBotAddedDatabase = async (id: any, updatedBot: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                    update_usermanagementdb_Tenant(where: {id: {_eq: "${id}"}}, _append: {isBotAdded:{${updatedBot}:true} }) {
                        affected_rows
                    }
                    }
`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Unable to update user is bot added');
        }
        return jsonData.data.update_usermanagementdb_Tenant;
    } catch (err) {
        return { error: err };
    }
};
export const getEmpTeamSelectBox = async (organizationId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee(where: {organizationId: {_eq: "${organizationId}"}}) {
                    organizationId
                    employeeId
                    Employee {
                        employeeId
                        firstName
                        lastName
                        email
                        profileImage
                        status
                        organization_employees{
                            role
                        }
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('usermanagementdb_organization_employee Data not found!');
        }
        return jsonData.data.usermanagementdb_organization_employee;
    } catch (err) {
        return { error: err };
    }
};

export const getTeamEmployees = async (teamId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_team_employee(where: {teamId: {_eq: "${teamId}"}}) {
                    employeeId
                    teamId
                    isApprover
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Failed to fetch team employees.');
        }
        return jsonData.data.usermanagementdb_team_employee;
    } catch (err) {
        return { error: err };
    }
};

export const updateTeamName = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                update_usermanagementdb_Team(where: {teamId: {_eq: "${obj.teamId}"}}, _set: {teamName: "${obj.teamName}"}) {
                    affected_rows
                    returning {
                        teamId
                        teamName
                        organizationId
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Team name updation failed.');
        }
        return jsonData.data.update_usermanagementdb_Team;
    } catch (err) {
        return { error: err };
    }
};

export const deleteTeamEmployees = async (teamId: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                delete_usermanagementdb_team_employee(where: {teamId: {_eq: "${teamId}"}}) {
                    affected_rows
                    returning {
                        employeeId
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Team employee deletion failed.');
        }
        return jsonData.data.delete_usermanagementdb_team_employee;
    } catch (err) {
        return { error: err };
    }
};

export const fetchCalendarData = async (organizationId: string, token: string) => {
    const query = {
        query: `query MyQuery {
            timeoffdb_Leaves(where: {organizationId: {_eq: "${organizationId}"}}) {
                leaveId
                category
                employeeId
                leaveAppliedBy
                reason
                leaveStatus
                noOfHours
                startDate
                endDate
                startTime
                endTime
                denierId
                denialReason
                approverId
                gmt
                isoString
                Employee {
                    firstName
                    lastName
                    employeeId
                    profileImage
                }
            }
            timeoffdb_Holiday(where: {organizationId: {_eq: "${organizationId}"}}) {
                holidayId
                holidayName
                holidayDay
                isMultidayHoliday
                startDate
                endDate
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await resp.json();
        // if (jsonData.errors) {
        //     throw new Error('Something went wrong!');
        // }
        // return jsonData;
        return resp;
    } catch (err) {
        return err;
    }
};
export const updateSuperAdminRole = async (obj: any) => {
    try {
        const query = {
            query: `mutation updateTenantEmployee {
                update_usermanagementdb_tenant_employee(where: {employeeId: {_eq: "${obj.employeeId}"}, Tenant: {workspaceId: {_eq: "${obj.tenantWorkspace}"}}}, _set: {role: "${obj.role}"}) {
                    affected_rows
                    returning {
                        employeeId
                        role
                        tenantId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.update_usermanagementdb_tenant_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_usermanagementdb_tenant_employee;
    } catch (err) {
        return { error: err };
    }
};

export const updateAdminRole = async (obj: any) => {
    try {
        const query = {
            query: `mutation updateOrganizationEmployee {
                update_usermanagementdb_organization_employee(where: {employeeId: {_eq: "${obj.employeeId}"}}, _set: {role: "${obj.role}"}) {
                    affected_rows
                    returning {
                        role
                        organizationId
                        employeeId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
                'x-hasura-role': 'teamplusadmin',
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.update_usermanagementdb_organization_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_usermanagementdb_organization_employee;
    } catch (err) {
        return { error: err };
    }
};

// export const fetchAddLeaveFormDetails2 = async (organizationId: string, employeeId: string, token: string) => {
//     const query = {
//         query: `query MyQuery {
//             timeoffdb_LeaveType(where: {organizationId: {_eq: "${organizationId}"}, active: {_eq: true}}) {
//                 leaveTypeName
//                 daysPerYear
//                 unlimitedDays
//                 approvalRequired
//             }
//             usermanagementdb_organization_employee(where: {organizationId: {_eq: "${organizationId}"}}) {
//                 Employee {
//                     employeeId
//                     firstName
//                     lastName
//                 }
//             }
//             timeoffdb_get_totalhours(args: {employeeid: "${employeeId}", organizationid: "${organizationId}"}) {
//                 employeeId
//                 leaveType
//                 totalHoursLeave
//                 workingHours
//             }
//             timeoffdb_get_totalhours_org(args: {organizationid: "${organizationId}"}) {
//                 employeeId
//                 leaveType
//                 totalHoursLeave
//                 workingHours
//             }
//         }`,
//     };
//     try {
//         const resp = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//             },
//             body: JSON.stringify(query),
//         });
//         // if (resp.status !== 200) {
//         //     const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
//         //     throw error;
//         // }
//         // const people = await resp.json();
//         return resp;
//     } catch (err) {
//         return err;
//     }
// };

// export const getPendingLeavesForApproverDashboard = async (
//     employeeId: string,
//     organizationId: string,
//     token: string,
// ) => {
//     const query = {
//         query: `query MyQuery {
//             timeoffdb_get_pendingdeniedapproverleaves(order_by: {startDate: asc}, args: {employeeid: "${employeeId}", organizationid: "${organizationId}"}, where: {leaveStatus: {_eq: "pending"}}) {
//                 leaveId
//                 category
//                 employeeId
//                 leaveAppliedBy
//                 reason
//                 leaveStatus
//                 noOfHours
//                 startDate
//                 endDate
//                 startTime
//                 endTime
//                 denierId
//                 denialReason
//                 approverId
//                 gmt
//                 Employee {
//                     employeeId
//                     firstName
//                     lastName
//                     profileImage
//                 }
//             }
//         }`,
//     };
//     try {
//         const resp = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//             },
//             body: JSON.stringify(query),
//         });
//         // if (resp.status !== 200) {
//         //     const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
//         //     throw error;
//         // }
//         // const jsonData = await resp.json();
//         // return jsonData.data.timeoffdb_get_pendingdeniedapproverleaves;
//         return resp;
//     } catch (err) {
//         return err;
//     }
// };

export const getLeavesForApproverCalendar = async (employeeId: string, organizationId: string, token: string) => {
    const query = {
        query: `query MyQuery {
            timeoffdb_get_pendingdeniedapproverleaves(args: {employeeid: "${employeeId}", organizationid: "${organizationId}"}) {
                leaveId
                category
                employeeId
                leaveAppliedBy
                reason
                leaveStatus
                noOfHours
                startDate
                endDate
                startTime
                endTime
                denierId
                denialReason
                approverId
                gmt
                isoString
                Employee {
                    employeeId
                    firstName
                    lastName
                    profileImage
                }
            }
            timeoffdb_Leaves(where: {organizationId: {_eq: "${organizationId}"}, leaveStatus: {_eq: "approved"}}) {
                leaveId
                category
                employeeId
                leaveAppliedBy
                reason
                leaveStatus
                noOfHours
                startDate
                endDate
                startTime
                endTime
                denierId
                denialReason
                approverId
                gmt
                isoString
                Employee {
                    employeeId
                    firstName
                    lastName
                    profileImage
                }
            }
            timeoffdb_Holiday(where: {organizationId: {_eq: "${organizationId}"}}) {
                holidayId
                holidayName
                holidayDay
                isMultidayHoliday
                startDate
                endDate
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // if (resp.status !== 200) {
        //     const error = new Error('Error ' + resp.status + ': ' + resp.statusText);
        //     throw error;
        // }
        // const jsonData = await resp.json();
        // return jsonData.data;
        return resp;
    } catch (err) {
        return err;
    }
};

export const getMyTeams = async (empId: any, organizationId: string, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Team(where: {team_employees: {employeeId: {_eq: "${empId}"}, isApprover: {_eq: true}}, organizationId: {_eq: "${organizationId}"}}) {
                    teamId
                    teamName
                    organizationId
                }
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${empId}"}, organizationId: {_eq: "${organizationId}"}}) {
                    role
                    organizationId
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Failed to fetch teams');
        }
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};

export const updateUserToEmployee = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_usermanagementdb_organization_employee(where: {employeeId: {_eq: "${obj.empId}"}, organizationId: {_eq: "${obj.orgId}"}}, _set: {role: "${obj.role}"}) {
                    affected_rows
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                'x-hasura-role': 'teamplusadmin',
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Updation of organization emplyoee role failed.');
        }
        return jsonData.data.update_usermanagementdb_organization_employee;
    } catch (err) {
        return { error: err };
    }
};

export const getMyTeamEmployees = async (teamId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Team(where: {teamId: {_eq: "${teamId}"}}) {
                    teamId
                    teamName
                    team_employees(where: {isApprover: {_eq: true}, Employee: {organization_employees: {role: {_eq: "approver"}}}}) {
                        isApprover
                        Employee {
                            employeeId
                            email
                            firstName
                            lastName
                            profileImage
                            status
                            organization_employees {
                                role
                            }
                            team_employees(where: {isApprover: {_eq: true}, teamId: {_neq: "${teamId}"}}) {
                                isApprover
                            }
                        }
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Unable to fetch the employees of team.');
        }
        return jsonData.data.usermanagementdb_Team;
    } catch (err) {
        return { error: err };
    }
};

export const changeEmployeeRoles = async (obj: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                update_usermanagementdb_organization_employee(where: {employeeId: {_in: ${JSON.stringify(
                    obj,
                )}}}, _set: {role: "employee"}) {
                    affected_rows
                    returning {
                        employeeId
                        organizationId
                        role
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                'x-hasura-role': 'teamplusadmin',
            },
            body: JSON.stringify(query),
        });
        const responseData = await data.json();
        return responseData.data.update_usermanagementdb_organization_employee;
    } catch (err) {
        return { error: err };
    }
};

export const isEmployeeInOrganization = async (organizationId: string, email: string) => {
    // usermanagementdb_organization_employee_aggregate(where: {_and: {employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}}}) {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee_aggregate(where: {Employee: {email: {_eq: "${email}"}}, organizationId: {_eq: "${organizationId}"}}) {
                    aggregate {
                        count
                    }
                }
                usermanagementdb_Employee_aggregate(where: {email: {_eq: "${email}"}}) {
                    aggregate {
                        count
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'x-hasura-admin-secret': adminSecret,
            },
            body: JSON.stringify(query),
        });
        const responseData = await data.json();
        return responseData.data;
    } catch (err) {
        return { error: err };
    }
};

export const isEmployeeInDatabase = async (email: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Employee_aggregate(where: {email: {_eq: "${email}"}}) {
                    aggregate {
                        count
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'x-hasura-admin-secret': adminSecret,
            },
            body: JSON.stringify(query),
        });
        const responseData = await data.json();
        return responseData.data.usermanagementdb_Employee_aggregate?.aggregate?.count;
    } catch (err) {
        return { error: err };
    }
};
export const isEmployeeInOrganizationSlack = async (employeeId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee_aggregate(where: {employeeId: {_eq: "${employeeId}"}}) {
                    aggregate {
                        count
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const responseData = await data.json();
        return responseData.data.usermanagementdb_organization_employee_aggregate?.aggregate?.count;
    } catch (err) {
        return { error: err };
    }
};
export const isEmployeeInDB = async (id: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                    usermanagementdb_Employee_aggregate(where: {employeeId: {_eq: "${id}"}}) {
                        aggregate {
                        count
                        }
                    }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const responseData = await data.json();
        return responseData.data.usermanagementdb_Employee_aggregate?.aggregate?.count;
    } catch (err) {
        return { error: err };
    }
};

export const registerTenant = async (obj: any) => {
    try {
        let employeesData = JSON.stringify(obj.emps);
        let length = employeesData.length;
        while (length !== 0) {
            employeesData = employeesData.replace('"employeeId"', 'employeeId');
            employeesData = employeesData.replace('"email"', 'email');
            employeesData = employeesData.replace('"firstName"', 'firstName');
            employeesData = employeesData.replace('"lastName"', 'lastName');
            length = length - 1;
        }

        let usersData = JSON.stringify(obj.users);
        let userLength = usersData.length;
        while (userLength !== 0) {
            usersData = usersData.replace('"tenantId"', 'tenantId');
            usersData = usersData.replace('"employeeId"', 'employeeId');
            usersData = usersData.replace('"role"', 'role');
            userLength = userLength - 1;
        }

        let deptData = JSON.stringify(obj.departments);
        let deptLength = deptData.length;
        while (deptLength !== 0) {
            deptData = deptData.replace('"tenantId"', 'tenantId');
            deptData = deptData.replace('"organizationId"', 'organizationId');
            deptData = deptData.replace('"organizationName"', 'organizationName');
            deptData = deptData.replace('"country"', 'country');
            deptData = deptData.replace('"countryCode"', 'countryCode');
            deptData = deptData.replace('"countryName"', 'countryName');
            deptData = deptData.replace('"gmtOffset"', 'gmtOffset');
            deptData = deptData.replace('"timestamp"', 'timestamp');
            deptData = deptData.replace('"zoneName"', 'zoneName');
            deptLength = deptLength - 1;
        }

        let deptEmpData = JSON.stringify(obj.deptEmps);
        let deptEmpLength = deptEmpData.length;
        while (deptEmpLength !== 0) {
            deptEmpData = deptEmpData.replace('"employeeId"', 'employeeId');
            deptEmpData = deptEmpData.replace('"role"', 'role');
            deptEmpData = deptEmpData.replace('"organizationId"', 'organizationId');
            deptEmpData = deptEmpData.replace('"location"', 'location');
            deptEmpData = deptEmpData.replace('"countryCode"', 'countryCode');
            deptEmpData = deptEmpData.replace('"countryName"', 'countryName');
            deptEmpData = deptEmpData.replace('"gmtOffset"', 'gmtOffset');
            deptEmpData = deptEmpData.replace('"timestamp"', 'timestamp');
            deptEmpData = deptEmpData.replace('"zoneName"', 'zoneName');
            deptEmpLength = deptEmpLength - 1;
        }

        let teamData = JSON.stringify(obj.teams);
        let teamLength = teamData.length;
        while (teamLength !== 0) {
            teamData = teamData.replace('"teamId"', 'teamId');
            teamData = teamData.replace('"teamName"', 'teamName');
            teamData = teamData.replace('"organizationId"', 'organizationId');
            teamData = teamData.replace('"msChannelId"', 'msChannelId');
            teamLength = teamLength - 1;
        }

        let teamEmpData = JSON.stringify(obj.teamEmps);
        let teamEmpLength = teamEmpData.length;
        while (teamEmpLength !== 0) {
            teamEmpData = teamEmpData.replace('"teamId"', 'teamId');
            teamEmpData = teamEmpData.replace('"employeeId"', 'employeeId');
            teamEmpData = teamEmpData.replace('"isApprover"', 'isApprover');
            teamEmpLength = teamEmpLength - 1;
        }

        let settingsData = JSON.stringify(obj.settings);
        let settingsLength = settingsData.length;
        while (settingsLength !== 0) {
            settingsData = settingsData.replace('"organizationId"', 'organizationId');
            settingsData = settingsData.replace('"dateFormat"', 'dateFormat');
            settingsData = settingsData.replace('"workWeek"', 'workWeek');
            settingsData = settingsData.replace('"workingHours"', 'workingHours');
            settingsLength = settingsLength - 1;
        }

        let leaveTypeData = JSON.stringify(obj.leaveTypes);
        let leaveTypeLength = leaveTypeData.length;
        while (leaveTypeLength !== 0) {
            leaveTypeData = leaveTypeData.replace('"organizationId"', 'organizationId');
            leaveTypeData = leaveTypeData.replace('"leaveTypeName"', 'leaveTypeName');
            leaveTypeData = leaveTypeData.replace('"isPaid"', 'isPaid');
            leaveTypeData = leaveTypeData.replace('"daysPerYear"', 'daysPerYear');
            leaveTypeData = leaveTypeData.replace('"approvalRequired"', 'approvalRequired');
            leaveTypeData = leaveTypeData.replace('"active"', 'active');
            leaveTypeData = leaveTypeData.replace('"unlimitedDays"', 'unlimitedDays');
            leaveTypeData = leaveTypeData.replace('"defaultEnable"', 'defaultEnable');
            leaveTypeData = leaveTypeData.replace('"forYear"', 'forYear');
            leaveTypeData = leaveTypeData.replace('"leaveTypeEmoticon"', 'leaveTypeEmoticon');
            leaveTypeData = leaveTypeData.replace('"emoji"', 'emoji');
            leaveTypeData = leaveTypeData.replace('"activeSkinTone"', 'activeSkinTone');
            leaveTypeData = leaveTypeData.replace('"originalUnified"', 'originalUnified');
            leaveTypeData = leaveTypeData.replace('"unified"', 'unified');
            leaveTypeLength = leaveTypeLength - 1;
        }

        let templateData = JSON.stringify(obj.templates);
        let templateLength = templateData.length;
        while (templateLength !== 0) {
            templateData = templateData.replace('"organizationId"', 'organizationId');
            templateData = templateData.replace('"employeeId"', 'employeeId');
            templateData = templateData.replace('"questions"', 'questions');
            templateLength = templateLength - 1;
        }

        const query = {
            query: `mutation addTenant {
                insert_usermanagementdb_Tenant(objects: {
                    isGlobalNotif: false,
                    id: "${obj.id}",
                    name: "${obj.name}",
                    workspaceId: "${obj.workspaceId}",
                    displayLogo: false
                },
                    on_conflict: {constraint: Tenant_workspaceId_key}
                ) {
                    affected_rows
                    returning {
                        id
                    }
                }
                insert_usermanagementdb_Employee(objects: ${employeesData}, on_conflict: {constraint: Employee_pkey}) {
                    affected_rows
                    returning {
                        employeeId
                    }
                }
                insert_usermanagementdb_tenant_employee(objects: ${usersData}, on_conflict: {constraint: tenant_employee_pkey}) {
                    affected_rows
                    returning {
                        tenantId
                        employeeId
                    }
                }
                insert_usermanagementdb_Organization(objects: ${deptData}) {
                    affected_rows
                    returning {
                        organizationId
                        organizationName
                    }
                }
                insert_usermanagementdb_organization_employee(objects: ${deptEmpData}) {
                    affected_rows
                    returning {
                        organizationId
                        employeeId
                    }
                }
                insert_usermanagementdb_Team(objects: ${teamData}) {
                    affected_rows
                    returning {
                        teamId
                    }
                }
                insert_usermanagementdb_team_employee(objects: ${teamEmpData}) {
                    affected_rows
                    returning {
                        teamId
                        employeeId
                    }
                }
                insert_timeoffdb_GeneralSettings(objects: ${settingsData}) {
                    affected_rows
                    returning {
                        settingId
                        organizationId
                    }
                }
                insert_timeoffdb_LeaveType(objects: ${leaveTypeData}) {
                    affected_rows
                    returning {
                        leaveTypeId
                        organizationId
                    }
                }
                insert_standupdb_StandupTemplate(objects: ${templateData}) {
                    affected_rows
                    returning {
                        standupTempId
                    }
                }
            }`,
        };
        // console.log("Query: ", JSON.stringify(query));
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log('Json data: ', jsonData);

        return jsonData;
    } catch (err) {
        return { error: err };
    }
};
export const getTenantSubscription = async (tenantId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Subscription(where: {tenantId: {_eq: "${tenantId}"}}) {
                    subscriptionId
                }
                }`,
        };
        // //console.log(query);

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();

        if (jsonData.errors) {
            return jsonData.errors;
        }
        return jsonData.data.usermanagementdb_Subscription;
    } catch (err) {
        return { error: err };
    }
};
export const getTenantSubscriptionForPlan = async (tenantId: any, planId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
            usermanagementdb_Subscription(where: {tenantId: {_eq: "${tenantId}"}, planId: {_like: "%${planId}%"}}) {
                planId
                status
                subscriptionId
            }
            }
`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();

        if (jsonData.errors) {
            return jsonData.errors;
        }
        return jsonData.data.usermanagementdb_Subscription;
    } catch (err) {
        return { error: err };
    }
};
export const insertSubscription = async (subscriptionDetails: any, cdata: any) => {
    try {
        const query = {
            query:
                cdata === 'client'
                    ? `mutation MyMutation {
                    insert_usermanagementdb_Subscription(objects: {tenantId: "${subscriptionDetails.tenantId}", status: "${subscriptionDetails.status}", showWarning: "${subscriptionDetails.showWarning}", quantity: "${subscriptionDetails.quantity}", purchasedBy: "${subscriptionDetails.purchasedBy}", planId: "${subscriptionDetails.planId}", nextBillingAt: "${subscriptionDetails.nextBillingAt}", hardExpireDate: "${subscriptionDetails.hardExpireDate}", currentTermStart: "${subscriptionDetails.currentTermStart}", currentTermEnd: "${subscriptionDetails.currentTermEnd}", autoRenew: ${subscriptionDetails.autoRenew}}) {
                        returning {
                        subscriptionId
                        quantity
                        }
                    }
                    }`
                    : `mutation MyMutation {
                    insert_usermanagementdb_Subscription(objects: {subscriptionId: "${subscriptionDetails.subscriptionId}", tenantId: "${subscriptionDetails.tenantId}", status: "${subscriptionDetails.status}", showWarning: "${subscriptionDetails.showWarning}", quantity: "${subscriptionDetails.quantity}", purchasedBy: "${subscriptionDetails.purchasedBy}", planId: "${subscriptionDetails.planId}", nextBillingAt: "${subscriptionDetails.nextBillingAt}", hardExpireDate: "${subscriptionDetails.hardExpireDate}", currentTermStart: "${subscriptionDetails.currentTermStart}", currentTermEnd: "${subscriptionDetails.currentTermEnd}", autoRenew: ${subscriptionDetails.autoRenew}}) {
                        returning {
                        subscriptionId
                        quantity
                        }
                    }
                    }
                `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + subscriptionDetails.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log(jsonData);
        if (jsonData.errors) {
            return jsonData.errors;
        }
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};
export const deleteSubscription = async (subscriptionId: any, token: any) => {
    try {
        const query = {
            query: `mutation Mymutation {
                     delete_usermanagementdb_Subscription(where: {subscriptionId: {_eq: "${subscriptionId}"}}) {
                     affected_rows
                     }
                    }`,
        };
        // //console.log('Insert subscription query', query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log('delete subs', jsonData);
        // //console.log(jsonData);
        if (jsonData.errors) {
            return jsonData.errors;
        }
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};
export const removeAllTenantLicenses = async (tenantId: any, subscriptionType: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                            update_usermanagementdb_tenant_employee(where: {tenantId: {_eq: "${tenantId}"}}, _set: {${subscriptionType}: null}) {
                                returning {
                                engageSubscriptionId
                                pollSubscriptionId
                                standupSubscriptionId
                                timeoffSubscriptionId
                                }
                            }
                            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Unable to update user license');
        }
        return jsonData.data.update_usermanagementdb_tenant_employee;
    } catch (err) {
        return { error: err };
    }
};
export const registerTenantEmployee = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_tenant_employee(objects: {employeeId: "${obj.employeeId}", role: "${obj.role}", tenantId: "${obj.tenantId}"}) {
                    affected_rows
                }
                }
                `,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log(jsonData);
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_usermanagementdb_Organization === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};

export const createOrganizationRole1 = async (obj: any) => {
    // //console.log(obj);
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_organization_employee(objects: {employeeId: "${obj.employeeId}", location: { countryName: "${obj.location?.countryName}", countryCode: "${obj.location?.countryCode}", zoneName: "${obj.location?.zoneName}", gmtOffset: "${obj.location.gmtOffset}", timestamp: "${obj.location?.timestamp}"}, organizationId: "${obj.organizationId}", role: "${obj.role}"}) {
                    affected_rows
                    returning {
                        employeeId
                        organizationId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            return jsonData.errors;
        }
        if (jsonData.data.insert_usermanagementdb_organization_employee === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_organization_employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const createDefaultOrganizationTeam1 = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_Team(objects: {teamName: "${obj.teamName}", organizationId: "${obj.organizationId}", createdAt: "${obj.createdAt}",msChannelId:"${obj.id}",slackTeamId:"${obj.slackTeamId}"}) {
                    returning {
                        teamId
                        teamName
                        organizationId
                        teamCreator
                        createdAt
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log(jsonData);
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_usermanagementdb_Team === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_Team.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const registerEmployee = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_Employee(objects: {email: "${obj.email}", firstName: "${obj.firstName}", joining: "${obj.joining}", lastName: "${obj.lastName}", status: "active",,employeeId:"${obj.id}"}) {
                    returning {
                        employeeId
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            return jsonData.errors;
        }

        return jsonData.data.insert_usermanagementdb_Employee.returning[0];
    } catch (err) {
        return { error: err };
    }
};

export const isEmployeeInDatabaseEmployeeId = async (employeeId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Employee_aggregate(where: {employeeId: {_eq: "${employeeId}"}}) {
                    aggregate {
                        count
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const responseData = await data.json();
        return responseData.data?.usermanagementdb_Employee_aggregate?.aggregate?.count;
    } catch (err) {
        return { error: err };
    }
};

export const GetOrganizations = async (token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                        usermanagementdb_Organization {
                            organizationId
                            organizationName
                            country
                            tenantId
                            Tenant {
                                name
                            }
                            Teams{
                            teamId
                            msChannelId
                            }
                        }
                        }
                    `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const responseData = await data.json();
        return responseData;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganizationEmployees = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee(where: {organizationId: {_eq: "${organizationId}"}}) {
                    employeeId
                    organizationId
                    Employee {
                        firstName
                        lastName
                        profileImage
                        employeeId
                        organization_employees(where: {role: {_eq: "admin"}, organizationId: {_eq: "${organizationId}"}}) {
                            role
                        }
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('User not found!');
        // }
        // if (jsonData.data.usermanagementdb_organization_employee === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.usermanagementdb_organization_employee;
        return data;
    } catch (err) {
        return { error: err };
    }
};

// export const deleteEmployeeLeaves = async (obj: any, token: any) => {
//     try {
//         const query = {
//             query: `mutation {
//                 delete_timeoffdb_Leaves(where: {organizationId: {_eq: "${obj.orgId}"}, employeeId: {_eq: "${obj.empId}"}}) {
//                     affected_rows
//                 }
//             }`,
//         };

//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//             },
//             body: JSON.stringify(query),
//         });

//         const jsonData = await data.json();
//         if (jsonData.errors) {
//             throw new Error('Unable to delete employee leaves.');
//         }
//         return jsonData.data.delete_timeoffdb_Leaves;
//     } catch (err) {
//         return { error: err };
//     }
// };

export const deleteTeamplusUser = async (empId: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                delete_usermanagementdb_Employee(where: {employeeId: {_eq: "${empId}"}}) {
                    affected_rows
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Unable to delete teamplus user.');
        }
        return jsonData.data.delete_usermanagementdb_Employee;
    } catch (err) {
        return { error: err };
    }
};

export const getUserApproverTeamCount = async (obj: any, token: any) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_Team_aggregate(where: {organizationId: {_eq: "${obj.orgId}"}, team_employees: {_and: {employeeId: {_eq: "${obj.empId}"}}, isApprover: {_eq: true}}}) {
                    aggregate {
                        count(columns: teamId, distinct: true)
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Unable to fetch count.');
        }
        return jsonData.data.usermanagementdb_Team_aggregate.aggregate.count;
    } catch (err) {
        return { error: err };
    }
};

export const getTeams = async (employeeID: any, organizationID: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                        usermanagementdb_Team(where: {_and: {organizationId: {_eq: "${organizationID}"}, team_employees: {employeeId: {_eq: "${employeeID}"}}}}) {
                            teamId
                            teamName
                            slackTeamId
                            msChannelId
                            team_employees(where: {employeeId: {_eq: "${employeeID}"}}) {
                                isApprover
                            }
                        }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('Team not found!');
        // }
        // if (jsonData === null) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data;
    } catch (err) {
        return err;
    }
};

// export const fetchInviteStatus = async (obj: any) => {
//     let a: any;
//     const client: any = new SubscriptionClient(GRAPHQL_ENDPOINT, {
//         reconnect: true,
//         lazy: true,
//         // connectionCallback: error => // //console.error(error),
//         connectionParams: {
//             headers: {
//                 'content-type': 'application/json',
//                 Authorization: 'Bearer ' + obj.token,
//             },
//         },
//     });

//     client.maxConnectTimeGenerator.setMin(7000);

//     const query = `subscription MyQuery {
//                         timeoffdb_Invites(where: {orgId: {_eq: "${obj.organizationId}"}}, distinct_on: emailId) {
//                             emailId
//                             status
//                             orgId
//                             inviteId
//                             teams
//                         }
//                     }`;
//     client.request({ query }).subscribe({
//         next({ data }: { data: any }) {
//             if (data != null) {
//                 a = data;
//                 store.dispatch(getInviteStatus(data));
//             }
//         },
//     });
//     return a;
// };

// export const deleteEmployeeInvites = async (obj: any, token: string) => {
//     try {
//         const query = {
//             query: `mutation {
//                 delete_timeoffdb_Invites(where: {orgId: {_eq: "${obj.orgId}"}, emailId: {_eq: "${obj.empId}"}}) {
//                     affected_rows
//                 }
//             }`,
//         };
//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await data.json();
//         if (jsonData.errors) {
//             throw new Error('Unable to delete employee invites!');
//         }
//         return jsonData.data.delete_timeoffdb_Invites;
//     } catch (err) {
//         return { error: err };
//     }
// };

export const timeoffORG = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_get_graphhistogram1(args: {organizationid: "${organizationId}"}) {
                    leaveTypeName
                    teamName
                    leaveCount
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('timeoffORG Data not found!');
        // }
        // return jsonData.data.timeoffdb_get_graphhistogram1;
    } catch (err) {
        return { error: err };
    }
};

export const leaveTypeORG = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                        timeoffdb_LeaveType(where: {organizationId: {_eq: "${organizationId}"}}) {
                            leaveTypeName
                        }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('leaveTypeORG Data not found!');
        // }
        // return jsonData.data.timeoffdb_LeaveType;
    } catch (err) {
        return { error: err };
    }
};

export const teamORG = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_Team(where: {organizationId: {_eq: "${organizationId}"}}) {
                    teamName
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('usermanagementdb_Team Data not found!');
        }
        return jsonData.data.usermanagementdb_Team;
    } catch (err) {
        return { error: err };
    }
};

export const getOrganization = async (orgId: string) => {
    try {
        const query = {
            query: `query GetOrganization {
                usermanagementdb_Organization(where: {organizationId: {_eq: "${orgId}"}}) {
                    organizationName
                    organizationId
                    country
                    Tenant {
                        id
                        name
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        if (jsonData.data.usermanagementdb_Organization === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.usermanagementdb_Organization[0];
    } catch (err) {
        return { error: err };
    }
};

export const basicSelfProfile = async (organizationId: string, employeeId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                        usermanagementdb_organization_employee(where: {employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}}) {
                            employeeId
                            role
                            Employee {
                                firstName
                                lastName
                                profileImage
                                employeeId
                                dateOfBirth

                            }
                            workAnniversary
                            isDOB
                            isYear
                        }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        // const jsonData = await data.json();
        // if (jsonData.errors) {
        //     throw new Error('usermanagementdb_Team Data not found!');
        // }
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const newSelfEmployeeTeams = async (organizationId: string, employeeId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                        usermanagementdb_Team(where: {_and: {organizationId: {_eq: "${organizationId}"}, team_employees: {employeeId: {_eq: "${employeeId}"}}}}) {
                            teamId
                            teamName
                            team_employees(where: {employeeId: {_eq: "${employeeId}"}}) {
                            isApprover
                            }
                        }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const newEmpTotalHours = async (organizationId: string, employeeId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                        timeoffdb_get_totalhours(args: {employeeid: "${employeeId}", organizationid: "${organizationId}"}, order_by: {leaveType: asc}) {
                            leaveType
                            totalHoursLeave
                        }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const newOrgLeaveTypes = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                        timeoffdb_LeaveType(where: {organizationId: {_eq: "${organizationId}"}, forYear: { _eq: "${new Date().getFullYear()}" }}, order_by: {leaveTypeName: asc}) {
                            leaveTypeName
                            daysPerYear
                        }
                    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};

export const newTimeOffHistory = async (organizationId: string, employeeId: string, date: any, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(where: {employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}, endDate: {_lt: "${date}"}}) {
                    leaveId
                    startDate
                    endDate
                    startTime
                    endTime
                    category
                    leaveAppliedBy
                    leaveStatus
                    approverId
                    denierId
                    reason
                    noOfHours
                    gmt
                    isoString
                }
    }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        return data;
    } catch (err) {
        return { error: err };
    }
};

// export const getGoogleUser = async (obj: any) => {
//     try {
//         const getGoogleUserAPI = 'https://ay4khvbhlg.execute-api.eu-north-1.amazonaws.com/getGoogleUser';
//         const objAdminGetUser = {
//             username: obj.username,
//             userpoolId: obj.userpoolId,
//         };
//         const data = await fetch(getGoogleUserAPI, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(objAdminGetUser),
//         });
//         const jsonData = await data.json();

//         return jsonData;
//     } catch (err) {
//         return { error: err };
//     }
// };

// export const storeSubscription = async (obj: any) => {
//     try {
//         const query = {
//             query: `mutation MyMutation {
//                 insert_usermanagementdb_Subscription(objects: {subscriptionId: "${obj.subscriptionId}", purchasedBy: "${obj.EmployeeId}", organizationId: "${obj.organizationId}",currentTermStart:"${obj.currentTermStart}",currentTermEnd:"${obj.currentTermEnd}",nextBillingAt:"${obj.nextBillingAt}",status:"${obj.status}"}) {
//                     returning {
//                         organizationId
//                         purchasedBy
//                         subscriptionId
//                         invoiceId
//                         currentTermStart
//                         currentTermEnd
//                         nextBillingAt
//                         status
//                     }
//                 }
//             }`,
//         };
//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + obj.token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await data.json();
//         if (jsonData.errors) {
//             throw new Error('Something went wrong');
//         }
//         if (jsonData.data.insert_usermanagementdb_Organization === null) {
//             throw new Error('Something went wrong, please try again!');
//         }
//         return jsonData.data.insert_usermanagementdb_Organization.returning[0];
//     } catch (err) {
//         return { error: err };
//     }
// };
export const addClients = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_usermanagementdb_Clients(objects: {email: "${obj.email}", firstName: "${obj.firstName}", lastName: "${obj.lastName}"}) {
                    returning {
                            email
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.insert_usermanagementdb_Clients === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.insert_usermanagementdb_Clients.returning[0];
    } catch (err) {
        return { error: err };
    }
};
export const updateSubscription = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                            update_usermanagementdb_Subscription(_set: {currentTermEnd: "${obj.currentTermEnd}", currentTermStart: "${obj.currentTermStart}", nextBillingAt: "${obj.nextBillingAt}", status: "${obj.status}", hardExpireDate: ${obj.hardExpireDate}, showWarning:${obj.showWarning}}, where: {subscriptionId: {_eq: "${obj.subscriptionId}"}}) {
                                returning {
                                    organizationId
                                    purchasedBy
                                    subscriptionId
                                    currentTermStart
                                    currentTermEnd
                                    nextBillingAt
                                    showWarning
                                    hardExpireDate
                                    subscriptionItems
                                    status
                                }
                            }
                        }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_usermanagementdb_Subscription === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.update_usermanagementdb_Subscription.returning[0];
    } catch (err) {
        return { error: err };
    }
};
export const updateSubscriptionMs = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                  update_usermanagementdb_Subscription(where: {subscriptionId: {_eq: "${obj.subscriptionId}"}}, _set: {  showWarning: "${obj.showWarning}", hardExpireDate: "${obj.hardExpireDate}", currentTermStart: "${obj.currentTermStart}", currentTermEnd: "${obj.currentTermEnd}"}) {
                    returning {
                      planId
                      quantity
                      subscriptionId
                    }
                  }
                } `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.update_usermanagementdb_Subscription === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};
// export const getSubscriptionDetails = async (obj: any) => {
//     try {
//         const query = {
//             query: `query MyQuery {
//                     usermanagementdb_Subscription(where: {organizationId: {_eq: "${obj.organizationId}"}}) {
//                         organizationId
//                         purchasedBy
//                         subscriptionId
//                         currentTermStart
//                         currentTermEnd
//                         nextBillingAt
//                         status
//                         showWarning
//                         hardExpireDate
//   }
// }`,
//         };

//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + obj.token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await data.json();
//         return jsonData;
//     } catch (err) {
//         return { error: err };
//     }
// };
// export const getSubscriptionExpired = async (obj: any) => {
//     try {
//         const query = {
//             query: `query MyQuery {
//                 usermanagementdb_Subscription(where: {tenantId: {_eq: "${obj.organizationId}"}}){
//                     subscriptionId
//                     status
//                     showWarning
//                     hardExpireDate
//                 }
//             }`,
//         };

//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + obj.token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await data.json();
//         return jsonData;
//     } catch (err) {
//         return { error: err };
//     }
// };
// export const getSubscriptionDetailsData = async (obj: any) => {
//     try {
//         const query = {
//             query: `query MyQuery {
//                     usermanagementdb_Subscription(where: {organizationId: {_eq: "${obj.organizationId}"}}) {
//                         organizationId
//                         purchasedBy
//                         subscriptionId
//                         currentTermStart
//                         currentTermEnd
//                         nextBillingAt
//                         status
//                         showWarning
//                         hardExpireDate
//   }
// }

// `,
//         };

//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + obj.token,
//             },
//             body: JSON.stringify(query),
//         });
//         return data;
//     } catch (err) {
//         return { error: err };
//     }
// };
// export const getSubscription_Subscription = async (
//     organizationId: string,
//     token: string,
//     subscriptionExpiredContext: any,
//     updateWarningDateContext: any,
//     updateHardExpireDateContext: any,
// ) => {
//     let a: any;
//     const client: any = new SubscriptionClient(GRAPHQL_ENDPOINT, {
//         reconnect: true,
//         lazy: true,
//         // connectionCallback: error => // //console.error(error),
//         connectionParams: {
//             headers: {
//                 'content-type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//             },
//         },
//     });
//     client.maxConnectTimeGenerator.setMin(7000);
//     const query = `subscription MyQuery {
//                     usermanagementdb_Subscription(where: {organizationId: {_eq: "${organizationId}"}}) {
//                         organizationId
//                         purchasedBy
//                         subscriptionId
//                         currentTermStart
//                         currentTermEnd
//                         nextBillingAt
//                         status
//                         showWarning
//                         hardExpireDate
//   }
// }`;

//     client.request({ query }).subscribe({
//         next({ data }: { data: any }) {
//             if (
//                 data.usermanagementdb_Subscription.length !== 0 &&
//                 localStorage.getItem('organizationId') === organizationId
//             ) {
//                 subscriptionExpiredContext(data.usermanagementdb_Subscription[0].status);
//                 updateWarningDateContext(data.usermanagementdb_Subscription[0].showWarning);
//                 updateHardExpireDateContext(data.usermanagementdb_Subscription[0].hardExpireDate);
//             } else {
//                 subscriptionExpiredContext('not active');
//                 updateWarningDateContext(0);
//                 updateHardExpireDateContext(0);
//             }
//         },
//     });
//     return a;
// };
export const fetchSubscriptionDetailsChargebee = async (subscriptionId: any) => {
    try {
        const url = `https://00k18abhk4.execute-api.us-east-1.amazonaws.com/fetchSubscriptionDetails?${subscriptionId}`;
        const response = await fetch(url, {
            method: 'GET',
        });
        const result = await response.json();
        return result;
    } catch (err) {
        return { error: err };
    }
};
export const fetchSlackUsers = async (token: any) => {
    try {
        const url = `https://ay4khvbhlg.execute-api.eu-north-1.amazonaws.com/fetchSlackUsers?${token}`;
        const response = await fetch(url, {
            method: 'GET',
        });
        const result = await response.json();
        return result;
    } catch (err) {
        return { error: err };
    }
};

export const fetchInvoice = async (invoiceId: any) => {
    try {
        const url = `https://00k18abhk4.execute-api.us-east-1.amazonaws.com/fetchInvoice?${invoiceId}`;
        const response = await fetch(url, {
            method: 'GET',
        });
        const result = await response.json();
        return result;
    } catch (err) {
        return { error: err };
    }
};
// export const fetchAllInvoices = async (subscriptionId: any) => {
//     try {
//         const url = `https://00k18abhk4.execute-api.us-east-1.amazonaws.com/fetchAllInvoices?${subscriptionId}`;
//         const response = await fetch(url, {
//             method: 'GET',
//         });
//         const result = await response.json();
//         return result;
//     } catch (err) {
//         return { error: err };
//     }
// };
export const fetchAllInvoicesData = async (subscriptionId: any) => {
    try {
        if (!subscriptionId) {
            return null;
        } else {
            const url = `https://00k18abhk4.execute-api.us-east-1.amazonaws.com/fetchAllInvoices?${subscriptionId}`;
            const response = await fetch(url, {
                method: 'GET',
            });
            return response;
        }
    } catch (err) {
        return { error: err };
    }
};

export const sendFeedback = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_timeoffdb_Feedback(objects: {tenantId: "${obj.tenantId}", rating: ${obj.rating}, experienceOfApp: "${obj.experienceOfApp}"}) {
                    affected_rows
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // // //console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Insert Query Wrong');
        }
        return jsonData.data.insert_timeoffdb_Feedback;
    } catch (err) {
        return { error: err };
    }
};

//to send contact support info
export const sendGeneral = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                insert_timeoffdb_ContactUs(objects: {tenantId: "${obj.tenantId}", title: "${obj.title}", description: "${obj.desc}", severity: "${obj.severity}", isBugPresent: ${obj.bug}}) {
                    affected_rows
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // // //console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Insert Query Wrong');
        }
        return jsonData.data.insert_timeoffdb_ContactUs;
    } catch (err) {
        return { error: err };
    }
};

export const getAdminApprovers = async (obj: any, token: string) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${obj.empId}"}, organizationId: {_eq: "${obj.orgId}"}}) {
                    employeeId
                    isAccountLinked
                    slackId
                    Employee {
                        firstName
                        lastName
                    }
                    Organization {
                        organization_employees(where: {role: {_neq: "employee"}, slackId: {_is_null: false}, isAccountLinked: {_eq: true}}) {
                            slackId
                            isAccountLinked
                            role
                        }
                    }
                }
                usermanagementdb_Organization(where: {organization_employees: {employeeId: {_eq: "${obj.otherEmp}"}, organizationId: {_eq: "${obj.orgId}"}}}) {
                    organization_employees(where: {employeeId: {_eq: "${obj.otherEmp}"}, organizationId: {_eq: "${obj.orgId}"}}) {
                        slackId
                        isAccountLinked
                        Employee {
                            firstName
                            lastName
                        }
                    }
                }
                usermanagementdb_Team(where: {Organization: {organization_employees: {employeeId: {_eq: "${obj.otherEmp}"}, organizationId: {_eq: "${obj.orgId}"}}},
                    team_employees: {Employee: {organization_employees: {employeeId: {_eq: "${obj.otherEmp}"}, organizationId: {_eq: "${obj.orgId}"}}}}}) {
                        teamName
                        team_employees(where: {isApprover: {_eq: true}}) {
                            Employee {
                            firstName
                                organization_employees(where: {organizationId: {_eq: "${obj.orgId}"}, slackId: {_is_null: false}}) {
                                    slackId
                                }
                            }
                            isApprover
                        }
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data;
        // if (jsonData.data.usermanagementdb_organization_employee.length <= 0) {
        //     throw new Error('Something went wrong, please try again!');
        // }
        // return jsonData.data.usermanagementdb_organization_employee[0];
    } catch (err) {
        return { error: err };
    }
};

export const getApproverCancelInfo = async (obj: any, token: string) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${obj.empId}"}, organizationId: {_eq: "${obj.orgId}"}, isAccountLinked: {_eq: true}}) {
                    isAccountLinked
                    slackId
                    Employee {
                        firstName
                        lastName
                    }
                }
                usermanagementdb_Tenant(where: tenantId: {_eq: "${obj.orgId}"})
                    timeoff
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData;
    } catch (err) {
        return { error: err };
    }
};
export const getApproverInfo = async (obj: any, token: string) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${obj.empId}"}, organizationId: {_eq: "${obj.orgId}"}, isAccountLinked: {_eq: true}}) {
                    isAccountLinked
                    slackId
                    Employee {
                        firstName
                        lastName
                    }
                }
                usermanagementdb_Tenant(where: {tenantId: {_eq: "${obj.orgId}"}}) {
                    timeoff
                }
                timeoffdb_Leaves(where: {leaveId: {_eq: "${obj.leaveId}"}}) {
                    Organization {
                        timeoffChannel
                        organization_employees(where: {employeeId: {_eq: "${obj.actorId}"}, organizationId: {_eq: "${obj.orgId}"}}) {
                            slackId
                            isAccountLinked
                        }
                    }
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData;
    } catch (err) {
        return { error: err };
    }
};

// export const getEmployeeSubscription = async (organizationId: any, employeeId: any, token: any) => {
//     const query = `subscription MySubscription {
//         usermanagementdb_organization_employee(where: {Employee: {employeeId: {_eq: "${employeeId}"}}, Organization: {organizationId: {_eq: "${organizationId}"}}}) {
//             employeeId
//             role
//             organizationId
//         }
//     }`;
//     const client: any = new SubscriptionClient(newurlSubscription, {
//         reconnect: true,
//         lazy: true,
//         connectionParams: {
//             headers: {
//                 'content-type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//                 // 'x-hasura-admin-secret': 'lcgW6TLyj2Esen8Nln9oPVlPWanarWyPjqqPs1kDQDgU3OnvBmVtKkhsryPfOttH',
//             },
//         },
//     });
//     client.maxConnectTimeGenerator.setMin(7000);
//     client.request({ query }).subscribe({
//         next(data: any) {
//             if (data.data.usermanagementdb_organization_employee.length !== 0) {
//                 store.dispatch(updateUserStatus(data.data.usermanagementdb_organization_employee[0]));
//             }
//             // if (data.data !== null) {
//             //     store.dispatch(updateUserStatus(data.data.usermanagementdb_organization_employee[0]));
//             // }
//         },
//     });
//     return null;
// };

// to get approved upcoming leaves based on employeeId
export const getOtherEmployeeLeaves = async (obj: any) => {
    try {
        const query = {
            query: ` query MyQuery {
                timeoffdb_Leaves(where: {leaveStatus: {_neq: "denied"}, employeeId: {_eq: "${obj.userId}"}, organizationId: {_eq: "${obj.organizationId}"}}) {
                    leaveId
                    startDate
                    endDate
                    startTime
                    endTime
                    category
                    leaveAppliedBy
                    leaveStatus
                    gmt
                    noOfHours
                    isoString
                }
             }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data.timeoffdb_Leaves;
    } catch (err) {
        return { error: err };
    }
};
export const listOrganizationEmployees = async (orgId: any, token: any) => {
    try {
        const query = {
            query: ` query MyQuery {
                usermanagementdb_organization_employee(where: {organizationId: {_eq: "${orgId}"}}) {
                    employeeId
                    Employee{
                        email
                    }
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data?.usermanagementdb_organization_employee;
    } catch (err) {
        return { error: err };
    }
};

export const deleteMSOrganization = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation deleteDepartment {
                delete_timeoffdb_Holiday(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_timeoffdb_PendingActions(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_pollsdb_poll_comment(where: {Poll: {orgId: {_eq: "${id}"}}}) {
                    affected_rows
                }
                delete_pollsdb_poll_result(where: {Poll: {orgId: {_eq: "${id}"}}}) {
                    affected_rows
                }
                delete_pollsdb_poll_team(where: {Poll: {orgId: {_eq: "${id}"}}}) {
                    affected_rows
                }
                delete_pollsdb_Polls(where: {orgId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_timeoffdb_NotificationSeen(where: {Notification: {organizationId: {_eq: "${id}"}}}) {
                affected_rows
            }
            delete_timeoffdb_Notifications(where: {organizationId: {_eq: "${id}"}}) {
                affected_rows
            }
                delete_timeoffdb_Leaves(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_standupdb_StandupNotes(where: {StandupTemplate: {organizationId: {_eq: "${id}"}}}) {
                    affected_rows
                }
                delete_standupdb_StandupTemplate(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_shoutoutdb_shoutout_reaction(where: {Shoutout: {organizationId: {_eq: "${id}"}}}) {
                    affected_rows
                }
                delete_shoutoutdb_shoutout_comment(where: {Shoutout: {organizationId: {_eq: "${id}"}}}) {
                    affected_rows
                }
                delete_shoutoutdb_shoutout_employee_team(where: {Shoutout: {organizationId: {_eq: "${id}"}}}) {
                    affected_rows
                }
                delete_shoutoutdb_Shoutout(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_timeoffdb_LeaveType(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_timeoffdb_GeneralSettings(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_usermanagementdb_team_employee(where: {Team: {organizationId: {_eq: "${id}"}}}) {
                    affected_rows
                }
                delete_usermanagementdb_Team(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_usermanagementdb_organization_employee(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
                delete_usermanagementdb_Organization(where: {organizationId: {_eq: "${id}"}}) {
                    affected_rows
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        return jsonData;
    } catch (err) {
        return {
            errors: err,
        };
    }
};

// export const getOrganizationEmployeesSubscription = async (organizationId: string, token: string) => {
//     let a: any;
//     const client: any = new SubscriptionClient(GRAPHQL_ENDPOINT, {
//         reconnect: true,
//         lazy: true,
//         // connectionCallback: error => // //console.error(error),
//         connectionParams: {
//             headers: {
//                 'content-type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//             },
//         },
//     });
//     client.maxConnectTimeGenerator.setMin(7000);
//     const query = `subscription MyQuery {
//                 usermanagementdb_organization_employee(where: {organizationId: {_eq: "${organizationId}"}}) {
//                     employeeId
//                     organizationId
//                     Employee {
//                         firstName
//                         lastName
//                         profileImage
//                         employeeId
//                         organization_employees(where: {role: {_eq: "admin"}, organizationId: {_eq: "${organizationId}"}}) {
//                         role
//                         }

//                     }
//                 }
//             }`;
//     client.request({ query }).subscribe({
//         next({ data }: { data: any }) {
//             if (data != null) {
//                 a = data;
//                 store.dispatch(fetchEmployees(data.usermanagementdb_organization_employee));
//             }
//         },
//     });
//     return a;
// };
// export const getOrganizationApproversSubscription = async (organizationId: string, token: string) => {
//     let a: any;
//     const client: any = new SubscriptionClient(GRAPHQL_ENDPOINT, {
//         reconnect: true,
//         lazy: true,
//         // connectionCallback: error => // //console.error(error),
//         connectionParams: {
//             headers: {
//                 'content-type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//             },
//         },
//     });
//     client.maxConnectTimeGenerator.setMin(7000);
//     const query = `subscription MyQuery {
//             timeoffdb_get_approverdeniername1(args: {organizationid: "${organizationId}"}) {
//                 approverId
//                 denierId
//                 firstName
//                 lastName
//             }
//         }`;
//     client.request({ query }).subscribe({
//         next({ data }: { data: any }) {
//             if (data != null) {
//                 a = data;
//                 store.dispatch(fetchApprovers(data.timeoffdb_get_approverdeniername1));
//             }
//         },
//     });
//     return a;
// };

export const getSlackChannel = async (obj: any, token: string) => {
    try {
        const query = {
            query: `query fetchSlackChannel {
                usermanagementdb_organization_employee(where: {organizationId: {_eq: "${obj.orgId}"}, employeeId: {_eq: "${obj.empId}"}}) {
                    slackId
                    Employee {
                        firstName
                        lastName
                    }
                    isAccountLinked
                }
                timeoffdb_LeaveType(where: {organizationId: {_eq: "${obj.orgId}"}}) {
                    leaveTypeName
                    organizationId
                    leaveTypeEmoticon
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};
export const getSlackWorkspace = async (orgId: any, token: string) => {
    try {
        const query = {
            // TODO: update query
            query: `query getSlackWorkspace {
                usermanagementdb_Organization(where: {organizationId: {_eq: "${orgId}"}}) {
                    timeoffChannel
                    workspaceId
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data.usermanagementdb_Organization;
    } catch (err) {
        return { error: err };
    }
};
// export const updatePlugin = async (obj: any) => {
//     try {
//         const query = {
//             query: `mutation MyMutation {
//                     update_usermanagementdb_Organization(where: {organizationId: {_eq: "${obj.organizationId}"}}, _set: ${obj.updateObj}) {
//                         returning {
//                         pollChannel
//                         standupChannel
//                         timeoffChannel
//                         engageChannel
//                         }
//                     }
//                     }`,
//         };
//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + obj.token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await data.json();
//         // // //console.log('My data ', jsonData);
//         if (jsonData.errors) {
//             throw new Error('Unable to add subscription');
//         }
//         return jsonData.data.update_usermanagementdb_Organization;
//     } catch (err) {
//         return { error: err };
//     }
// };
// export const updatePluginMs = async (obj: any) => {
//     try {
//         const query = {
//             query: `mutation MyMutation {
//                     update_usermanagementdb_organization_employee(where: {organizationId: {_eq: "${obj.organizationId}"}}, _set: ${obj.updateObj}) {
//                         returning {
//                         pollsMsBot
//                         standupMsBot
//                         timeOffMsBot
//                         shoutoutMsBot
//                         }
//                     }
//                     }`,
//         };
//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + obj.token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await data.json();
//         // // //console.log('My data ', jsonData);
//         if (jsonData.errors) {
//             throw new Error('Unable to add subscription');
//         }
//         return jsonData.data.update_usermanagementdb_Organization;
//     } catch (err) {
//         return { error: err };
//     }
// };
export const getAllLeavesReports = async (obj: any, token: any) => {
    const usersData: any = JSON.stringify(obj);
    const data2: any = JSON.parse(usersData);
    const arr = [JSON.stringify(data2.employees)];
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_Leaves(where: {employeeId: {_in: ${arr}}, organizationId: {_eq: "${data2.orgId}"}, _or: [{startDate: {_eq: "${data2.startDate}"}}, {startDate: {_lte: "${data2.endDate}"}}], _and: {_or: [{endDate: {_eq: "${data2.startDate}"}}, {endDate: {_gte: "${data2.startDate}"}}]}}, order_by: {startDate: desc}) {
                    Employee {
                        firstName
                        lastName
                    }
                    startDate
                    endDate
                    startTime
                    endTime
                    category
                    leaveCount
                    leaveStatus
                    denialReason
                    noOfHours
                    reason
                    approverId
                    noOfHours
                }
            }`,
        };
        // // //console.log(query);
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // // //console.log("THIS IS JSON DATA", jsonData)
        return jsonData.data;
    } catch (err) {
        // // //console.log("THIS IS ERR", err)
        return {
            error: err,
        };
    }
};

export const getLeavesBalanceReport = async (obj: any, token: any) => {
    const usersData: any = JSON.stringify(obj);
    const data2: any = JSON.parse(usersData);
    const arr = [JSON.stringify(data2.employees)];
    try {
        // const query = {
        //     query: `query MyQuery {
        //         timeoffdb_get_totalhours_org(args: {organizationid: "${data2.orgId}"}, where: {employeeId: {_in: ${arr}}}) {
        //             employeeId
        //             leaveType
        //             totalHoursLeave
        //             workingHours
        //             Employee {
        //             firstName
        //             lastName
        //             organization_employees(distinct_on: employeeId) {
        //                 Organization {
        //                 LeaveTypes {
        //                     leaveTypeName
        //                     daysPerYear
        //                     unlimitedDays
        //                 }
        //                 }
        //             }
        //             }
        //         }
        //         }`,
        // };
        const query1 = {
            query: `query MyQuery {
                        timeoffdb_get_totalhoursbydate_org(args: {enddate: "${data2.endDate}", startdate: "${data2.startDate}", organizationid: "${data2.orgId}"}, where: {employeeId: {_in: ${arr}}}) {
                            leaveType
                            totalHoursLeave
                            workingHours
                            employeeId
                            Employee {
                            firstName
                            lastName
                            organization_employees(distinct_on: employeeId) {
                                Organization {
                                LeaveTypes {
                                    leaveTypeName
                                    daysPerYear
                                    unlimitedDays
                                }
                                }
                            }
                            }
                        }
                        }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query1),
        });
        const jsonData = await data.json();
        return jsonData.data;
    } catch (err) {
        return {
            error: err,
        };
    }
};

// export const fetchLeavesForMonth = async (
//     organization_id: string,
//     token: string,
//     startDate: string,
//     endDate: string,
// ) => {
//     try {
//         const query = {
//             query: `query MyQuery {
//                 timeoffdb_get_MonthlyLeaves(args: {organizationid: "${organization_id}", startdate: "${startDate}", enddate: "${endDate}"}) {
//                     name
//                     value
//                 }
//             }`,
//         };

//         const data = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await data.json();
//         if (jsonData.errors) {
//             throw new Error('Something went wrong!');
//         }
//         if (jsonData.data.timeoffdb_get_MonthlyLeaves === null) {
//             throw new Error('Something went wrong, please try again!');
//         }
//         return jsonData.data.timeoffdb_get_MonthlyLeaves;
//     } catch (err) {
//         return { error: err };
//     }
// };

//to store notification on leave applied
export const storeNotif = async (obj: any) => {
    try {
        //console.log(obj.empId, obj.leaveId, obj.approverId);
        let query: any;
        if (obj.approverId === null) {
            //console.log('if');
            query = {
                query: `
                mutation MyMutation {
                    insert_timeoffdb_Notifications(objects: {employeeId: "${obj.empId}", leaveId: "${obj.leaveId}", notification: "${obj.notif}", organizationId: "${obj.orgId}", leaveAppliedOn: "${obj.notifDate}", isAdmin: "${obj.isAdmin}"}) {
                            affected_rows
                            returning {
                                notificationId
                            }
                        }
                    }`,
            };
        } else {
            //console.log('else');
            query = {
                query: `
                mutation MyMutation {
                    insert_timeoffdb_Notifications(objects: {employeeId: "${obj.empId}", leaveId: "${obj.leaveId}", notification: "${obj.notif}", organizationId: "${obj.orgId}", leaveAppliedOn: "${obj.notifDate}", isAdmin: "${obj.isAdmin}", approverId: "${obj.approverId}"}) {
                            affected_rows
                            returning {
                                notificationId
                            }
                        }
                    }`,
            };
        }
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
                //'x-hasura-role': 'admin'
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        //console.log('My data ', jsonData);
        if (jsonData.errors) {
            throw new Error('Insert Query Wrong');
        }
        return jsonData.data.insert_timeoffdb_Notifications;
    } catch (err) {
        return { error: err };
    }
};

export const fetchAdminNotif = async (orgId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                        timeoffdb_Notifications(where: {organizationId: {_eq: "${orgId}"}, isAdmin: {_eq: "admin"}}, order_by: {leaveAppliedOn: desc}, limit: 7) {
                            notification
                            employeeId
                            leaveAppliedOn
                            notificationId
                            isAdmin
                            leaveId
                            organizationId
                            approverId
                            NotificationSeens {
                                notificationId
                                employeeId
                                notificationSeen
                            }
                            Employee {
                                firstName
                                lastName
                                profileImage
                            }
                        }
                    }
                `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                //'x-hasura-role': 'admin'
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('fetchAdminNotif', jsonData);
        const notif = jsonData.data.timeoffdb_Notifications;
        //console.log(notif);
        if (jsonData.errors) {
            throw new Error('Fetch query wrong');
        }
        return notif;
    } catch (err) {
        return err;
    }
};

export const fetchEmpNotif = async (orgId: any, empId: any, token: any) => {
    try {
        const query = {
            // query: `query MyQuery {
            //         timeoffdb_Notifications(where: {organizationId: {_eq: "${orgId}"}, _or: [{_and: [{approverId: {_eq: "${empId}"}}, {isAdmin: {_eq: "employee"}}]}, {_and: [{employeeId: {_neq: "${empId}"}}, {isAdmin: {_eq: "allemps"}}]}]}, order_by: {leaveAppliedOn: desc}, limit: 7) {
            //             notification
            //             isAdmin
            //             employeeId
            //             notificationId
            //             leaveAppliedOn
            //             notificationSeen
            //             Employee {
            //                 firstName
            //                 lastName
            //                 profileImage
            //             }
            //             NotificationSeens {
            //                 notificationId
            //                 employeeId
            //                 notificationSeen
            //             }
            //         }
            //     }
            // `,
            query: `query MyQuery {
                    timeoffdb_Notifications(where: {organizationId: {_eq: "${orgId}"}, _or: [{_and: [{approverId: {_eq: "${empId}"}}, {isAdmin: {_eq: "employee"}}]}, {_and: [{approverId: {_is_null: true}}, {employeeId: {_eq: "${empId}"}}, {isAdmin: {_eq: "employee"}}]}, {_and: [{employeeId: {_neq: "${empId}"}}, {isAdmin: {_eq: "allemps"}}]}]}, order_by: {leaveAppliedOn: desc}, limit: 7) {
                        notification
                        isAdmin
                        employeeId
                        notificationId
                        leaveAppliedOn
                        notificationSeen
    					approverId
                        Employee {
                            firstName
                            lastName
                            profileImage
                        }
                        NotificationSeens {
                            notificationId
                            employeeId
                            notificationSeen
                        }
                    }
                }
            `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                //'x-hasura-role': 'admin'
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('fetchEmpNotif', jsonData);
        const notif = jsonData.data.timeoffdb_Notifications;
        if (jsonData.errors) {
            throw new Error('Fetch query wrong');
        }
        return notif;
    } catch (err) {
        return err;
    }
};

export const fetchApproverNotif = async (orgId: any, empId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_get_notificationApproverData(args: {employeeid: "${empId}", organizationid: "${orgId}"}, order_by: {leaveAppliedOn: desc}, limit: 7) {
                    organizationId
                    notificationSeen
                    notificationId
                    notification
                    leaveId
                    leaveAppliedOn
                    isAdmin
                    employeeId
                    approverId
                    Notification {
                        NotificationSeens {
                            notificationId
                            employeeId
                            notificationSeen
                        }
                    }
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }
        `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                // 'x-hasura-role': 'admin'
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('fetchApproverNotif', jsonData);
        // // //console.log('jsonData: ', jsonData.data.timeoffdb_get_notificationApproverData);
        const notif = jsonData.data.timeoffdb_get_notificationApproverData;
        if (jsonData.errors) {
            throw new Error('Fetch query wrong');
        }
        return notif;
    } catch (err) {
        return err;
    }
};

export const fetchEmpIdFromNotifSeen = async (notifId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                    timeoffdb_NotificationSeen(where: {notificationId: {_eq: "${notifId}"}}) {
                        employeeId
                    }
                }
            `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                //'x-hasura-role': 'admin'
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // console.log('fetchEmpForNotifSeen', jsonData);
        const notif = jsonData.data.timeoffdb_NotificationSeen;
        if (jsonData.errors) {
            throw new Error('Fetch query wrong');
        }
        return notif;
    } catch (err) {
        return err;
    }
};

export const updateNotifSeen = async (notifId: any, empId: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation2 {
                    insert_timeoffdb_NotificationSeen(objects: {notificationId: "${notifId}", employeeId: "${empId}", notificationSeen: true}) {
                        affected_rows
                    }
                }
            `,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                //'x-hasura-role': 'admin'
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        //console.log('updateNotifSeen', jsonData);
        if (jsonData.errors) {
            throw new Error('Unable to update notificationseen.');
        }
        return jsonData.data.insert_timeoffdb_NotificationSeen;
    } catch (err) {
        return { error: err };
    }
};

export const fetchShoutouts = async (orgId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                    shoutoutdb_Shoutout(where: {organizationId: {_eq: "${orgId}"}}) {
                        shoutoutId
                        createdBy
                        shoutout_employee_teams {
                        Employee {
                            employeeId
                        }
                        Team {
                            teamId
                             team_employees{
                            employeeId
                          }
                        }
                        }
                    }
                    }

            `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Fetch query wrong');
        }
        return jsonData;
    } catch (err) {
        return err;
    }
};

export const deleteEmpShoutouts = async (shoutouts: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                    delete_shoutoutdb_shoutout_comment(where: {shoutoutId: {_in: ${JSON.stringify(shoutouts)}}}) {
                        affected_rows
                    }
                    delete_shoutoutdb_shoutout_reaction(where: {shoutoutId: {_in: ${JSON.stringify(shoutouts)}}}) {
                        affected_rows
                    }
                    delete_shoutoutdb_shoutout_employee_team(where: {shoutoutId: {_in: ${JSON.stringify(shoutouts)}}}) {
                        affected_rows
                    }
                    delete_shoutoutdb_Shoutout(where: {shoutoutId: { _in: ${JSON.stringify(shoutouts)}}}) {
                        affected_rows
                    }
                    }

            `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Fetch query wrong');
        }
        return jsonData;
    } catch (err) {
        return err;
    }
};

export const deleteEmpShoutoutData = async (shoutoutId: any, empId: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                delete_shoutoutdb_shoutout_comment(where: {shoutoutId: {_eq: "${shoutoutId}"}, employeeId: {_eq: "${empId}"}}) {
                    affected_rows
                }
                delete_shoutoutdb_shoutout_reaction(where: {shoutoutId: {_eq: "${shoutoutId}"}, employeeId: {_eq: "${empId}"}}) {
                    affected_rows
                }
                delete_shoutoutdb_shoutout_employee_team(where: {shoutoutId: {_eq: "${shoutoutId}"}, employeeId: {_eq: "${empId}"}}) {
                    affected_rows
                }
                }
            `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('query wrong');
        }
        return jsonData;
    } catch (err) {
        return err;
    }
};

export const deleteComReac = async (shoutoutId: any, empId: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                delete_shoutoutdb_shoutout_comment(where: {shoutoutId: {_eq: "${shoutoutId}"}, employeeId: {_eq: "${empId}"}}) {
                    affected_rows
                }
                delete_shoutoutdb_shoutout_reaction(where: {shoutoutId: {_eq: "${shoutoutId}"}, employeeId: {_eq: "${empId}"}}) {
                    affected_rows
                }

                }
            `,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('query wrong');
        }
        return jsonData;
    } catch (err) {
        return err;
    }
};

export const deleteEmployeeFromOrg = async (empId: any, token: any, orgId: any, email: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                delete_pollsdb_poll_comment(where: {_or:[{Poll:{orgId:{_eq:"${orgId}"}},employeeId:{_eq:"${empId}"}},{Poll:{orgId:{_eq:"${orgId}"},createdBy:{_eq:"${empId}"}}}]}) {
                    affected_rows
                }
                delete_pollsdb_poll_result(where: {_or:[{Poll:{orgId:{_eq:"${orgId}"}},employeeId:{_eq:"${empId}"}},{Poll:{orgId:{_eq:"${orgId}"},createdBy:{_eq:"${empId}"}}}]}) {
                    affected_rows
                }
                delete_pollsdb_poll_team(where: {Poll: {orgId: {_eq: "${orgId}"}, createdBy: {_eq: "${empId}"}}}) {
                    affected_rows
                }
                delete_timeoffdb_Leaves(where: {organizationId: {_eq: "${orgId}"}, employeeId: {_eq: "${empId}"}}) {
                    affected_rows
                }
                delete_standupdb_StandupNotes(where: {StandupTemplate: {organizationId: {_eq: "${orgId}"}}, employeeId: {_eq: "${empId}"}}) {
                    affected_rows
                }
                delete_pollsdb_Polls(where: {orgId: {_eq: "${orgId}"}, createdBy: {_eq: "${empId}"}}) {
                    affected_rows
                }
                delete_usermanagementdb_team_employee(where: {employeeId: {_eq: "${empId}"}}) {
                    affected_rows
                }
                delete_timeoffdb_PendingActions(where: {organizationId: {_eq: "${orgId}"}, Organization: {Employee: {employeeId: {_eq: "${empId}"}}}}) {
                    affected_rows
                }
                delete_usermanagementdb_organization_employee(where: {employeeId: {_eq: "${empId}"}, organizationId: {_eq: "${orgId}"}}) {
                    affected_rows
                    returning {
                    organizationId
                    employeeId
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Unable to delete employee.');
        }

        return jsonData;
    } catch (err) {
        return { error: err };
    }
};

export const fetchTimeoffStatus = async (leave: any, token: any) => {
    try {
        const query = {
            query: `query fetchTimeoffStatus {
                timeoffdb_Leaves(where: {leaveId: {_eq: "${leave}"}}) {
                    leaveId
                    leaveStatus
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            return null;
        } else {
            if (jsonData.data.timeoffdb_Leaves.length > 0) {
                return jsonData.data.timeoffdb_Leaves;
            } else {
                return null;
            }
        }
    } catch (err) {
        return {
            error: err,
        };
    }
};

export const updateUserLocation = async (organizationId: string, employeeId: string, location: any, token: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_usermanagementdb_organization_employee(where: {employeeId: {_eq: "${employeeId}"}, organizationId: {_eq: "${organizationId}"}}, _set: {location: { countryName: "${location?.countryName}", countryCode: "${location?.countryCode}", zoneName: "${location?.zoneName}", gmtOffset: "${location.gmtOffset}", timestamp: "${location?.timestamp}"}}) {
                    returning {
                        employeeId
                        location
                        organizationId
                    }
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });

        const jsonData = await data.json();
        if (jsonData.errors) {
            return null;
        } else {
            return jsonData.data.returning[0];
        }
    } catch (err) {
        return {
            error: err,
        };
    }
};

//ms teams
export const getMsData = async (organizationId: string, token: string) => {
    try {
        const query = {
            query: `query MyQuery {
                timeoffdb_PendingActions(where: {organizationId: {_eq: "${organizationId}"}}) {
                    organizationId
                }
            }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        // //console.log('jsondata: ', jsonData.data.timeoffdb_PendingActions[0].integrateWithTeams);
        if (jsonData.errors) {
            throw new Error('Something went wrong');
        }
        if (jsonData.data.timeoffdb_PendingActions === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.timeoffdb_PendingActions[0].integrateWithTeams;
    } catch (err) {
        return { error: err };
    }
};

export const getMsApproverInfo = async (obj: any, token: string) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${obj.empId}"}, organizationId: {_eq: "${obj.orgId}"}}) {
                    Employee {
                        firstName
                        lastName
                        timeoffMsBot
                    }
                    Organization {
                        Teams(where: {isbotAdded: {_contains: {timeoff: true}}}) {
                            msChannelId
                            channelServiceUrl
                            teamName
                        }
                    }
                }
                timeoffdb_Leaves(where: {leaveId: {_eq: "${obj.leaveId}"}}) {
                    Organization {
                       organization_employees(where: {employeeId: {_eq: "${obj.actorId}"}, organizationId: {_eq: "${obj.orgId}"}}) {
                            Employee {
                                employeeId
                                firstName
                                lastName
                                timeoffMsBot
                            }
                        }
                    }
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData;
    } catch (err) {
        return { error: err };
    }
};

export const getMsAdminApprovers = async (obj: any, token: string) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${obj.empId}"}, organizationId: {_eq: "${obj.orgId}"}}) {
                    employeeId
                    Employee {
                        firstName
                        lastName
                        timeoffMsBot
                    }
                    Organization {
                        organizationName
                        organization_employees(where: {role: {_neq: "employee"}, Employee: {timeoffMsBot: {_is_null: false}}}) {
                            employeeId
                            role
                            Employee {
                                employeeId
                                timeoffMsBot
                            }
                        }
                        Teams(where: {isbotAdded: {_contains: {timeoff: true}}}) {
                            msChannelId
                            channelServiceUrl
                            teamName
                        }
                    }
                }
                usermanagementdb_Organization(where: {organization_employees: {employeeId: {_eq: "${obj.otherEmp}"}, organizationId: {_eq: "${obj.orgId}"}}}) {
                    organization_employees(where: {employeeId: {_eq: "${obj.otherEmp}"}, organizationId: {_eq: "${obj.orgId}"}}) {
                        Employee {
                            firstName
                            lastName
                            timeoffMsBot
                        }
                    }
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        // //console.log('jsonData: ', jsonData);
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};

// get user organization details
export const getMyCountry = async (obj: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee(where: {organizationId: {_eq: "${obj.organizationId}"}, employeeId: {_eq: "${obj.employeeId}"}}) {
                    employeeId
                    organizationId
                    role
                    location
                }
            }`,
        };
        // //console.log('query mschannel: ', query);
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        // //console.log("jsonData ", jsonData);
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data.usermanagementdb_organization_employee[0];
    } catch (err) {
        return { error: err };
    }
};

// export const getPluginsData = async (obj: any) => {
//     try {
//         const query = {
//             query: `query getPluginsData {
//                 usermanagementdb_Organization(where: {workspaceId: {_eq:"${obj.workspaceId}"}}) {
//                     timeoffChannel
//                     pollChannel
//                     engageChannel
//                     standupChannel
//                 }
//             }`,
//         };
//         const responseData = await fetch(newurl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + obj.token,
//             },
//             body: JSON.stringify(query),
//         });
//         const jsonData = await responseData.json();
//         if (jsonData.errors) {
//             throw new Error('Something went wrong!');
//         }
//         return jsonData.data.usermanagementdb_Organization[0];
//     } catch (err) {
//         return { error: err };
//     }
// };

export const deleteEmployee = async (id: any, token: any) => {
    try {
        const query = {
            query: `mutation {
                delete_usermanagementdb_Employee(where: {employeeId: {_eq: "${id}"}}) {
                        affected_rows
                    }
                }`,
        };
        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('Unable to delete Employee.');
        }
        return jsonData.data;
    } catch (err) {
        return {
            error: err,
        };
    }
};

export const refreshMSLoginToken = async (refreshToken: any) => {
    try {
        const response = await fetch(
            'https://sls-swec-dev-tokenforprod.azurewebsites.net/api/token?code=qvfMsYlPFRGK5CVuHluzs1NbFY6Y6PT9SRtDsnhP4zUQAzFuN4xntA==',
            {
                method: 'POST',
                body: JSON.stringify({
                    token: refreshToken,
                    source: 'teams',
                    grant_type: 'refresh_token',
                    secret: 'dEhpc0lzbXkkRWNSMytrRXlGT1J0ZUFtcExVc0B1dGgzTishKGFUaTBu',
                }),
            },
        );
        const d: any = await response.json();
        return d.body;
    } catch (err) {
        return { error: err };
    }
};

export const getApproverAcc = async (empId: any, orgId: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${empId}"}, organizationId: {_eq: "${orgId}"}}) {
                    employeeId
                    Employee {
                        firstName
                        lastName
                        timeoffMsBot
                    }
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data.usermanagementdb_organization_employee[0];
    } catch (error) {
        return { error: error };
    }
};

export const getUserInfoForCancelRequest = async (obj: any, token: string) => {
    try {
        const query = {
            query: `query {
                usermanagementdb_organization_employee(where: {employeeId: {_eq: "${obj.empId}"}, organizationId: {_eq: "${obj.orgId}"}}) {
                    employeeId
                    Employee {
                        firstName
                        lastName
                        timeoffMsBot
                    }
                    Organization {
                        Teams(where: {isbotAdded: {_contains: {timeoff: true}}}) {
                            msChannelId
                            channelServiceUrl
                            teamName
                        }
                    }
                }
            }`,
        };
        const responseData = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await responseData.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData;
    } catch (error) {
        return { error: error };
    }
};

export const listTenantEmployees = async (tid: any, token: any) => {
    try {
        const query = {
            query: `query MyQuery {
                usermanagementdb_tenant_employee {
                    employeeId
                    role
                    Employee {
                        lastName
                        firstName
                        email
                        employeeId
                    }
                    tenantId
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            return jsonData.errors;
        }

        return jsonData.data;
    } catch (err) {
        return { error: err };
    }
};

export const updateTenantSettings = async (obj: any) => {
    try {
        const query = {
            query: `mutation MyMutation {
                update_usermanagementdb_Tenant(where: {workspaceId: {_eq: "${obj.tenantWorkspace}"}}, _set: {displayLogo: ${obj.isLogo}}) {
                    affected_rows
                    returning {
                        logo
                        displayLogo
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        if (jsonData.data.updateTenantSettings === null) {
            throw new Error('Something went wrong, please try again!');
        }
        return jsonData.data.updateTenantSettings.affected_rows;
    } catch (err) {
        return { error: err };
    }
};

export const fetchTenantEmployees = async (obj: any) => {
    try {
        const query = {
            query: `query fetchTenantEmployees {
                usermanagementdb_tenant_employee(where: { Tenant:{ workspaceId:{ _eq:"${obj.tenantWorkspace}" } } }) {
                    employeeId
                    role
                    Employee {
                        firstName
                        lastName
                        profileImage
                    }
                }
            }`,
        };

        const data = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + obj.token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await data.json();
        if (jsonData.errors) {
            throw new Error('User not found!');
        }
        return jsonData.data.usermanagementdb_tenant_employee;
    } catch (err) {
        return { error: err };
    }
};

export const uploadTenantImage = async (tenantWorkspace: string, image: string, token: string) => {
    const query = {
        query: `mutation uploadTenantImage {
            uploadTenantImage(id: "${tenantWorkspace}", logo: "${image}") {
                message
            }
        }`,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await resp.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data.uploadTenantImage.message;
    } catch (err) {
        return err;
    }
};

export const fetchOrg = async (tenantId: string, token: string) => {
    const query = {
        query: `query MyQuery {
                usermanagementdb_Organization(where: {tenantId: {_eq: "${tenantId}"}}) {
                        organizationId
                    }
                }
            `,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await resp.json();
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data.usermanagementdb_Organization;
    } catch (err) {
        return err;
    }
};

export const deleteTenant = async (tenantId: string, token: string) => {
    const query = {
        query: `mutation MyMutation {                    
                    delete_celebrationdb_CelebrationComment(where: {Celebration: {tenantId: {_eq: "${tenantId}"}}}) {
                        affected_rows
                    }
                    delete_celebrationdb_celebration_reaction(where: {Celebration: {tenantId: {_eq: "${tenantId}"}}}) {
                        affected_rows
                    }
                    delete_celebrationdb_Celebration(where: {tenantId: {_eq: "${tenantId}"}}) {
                        affected_rows
                    }
                    delete_usermanagementdb_Subscription(where: {tenantId: {_eq: "${tenantId}"}}) {
                        affected_rows
                    }
                    delete_usermanagementdb_tenant_employee(where: {tenantId: {_eq: "${tenantId}"}}) {
                        affected_rows
                        returning {
                            employeeId
                        }
                    }
                }
            `,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await resp.json();
        //console.log(jsonData);
        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        return jsonData.data;
    } catch (err) {
        return err;
    }
};

export const deleteOrg = async (tenantId: string, token: string) => {
    const query = {
        query: `mutation MyMutation {                    
                    delete_usermanagementdb_Tenant(where: {id: {_eq: "${tenantId}"}}) {
                        affected_rows                       
                    }
                }
            `,
    };
    try {
        const resp = await fetch(newurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(query),
        });
        const jsonData = await resp.json();

        if (jsonData.errors) {
            throw new Error('Something went wrong!');
        }
        //console.log('4324234', jsonData);
        return jsonData.data;
    } catch (err) {
        return err;
    }
};
