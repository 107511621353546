import { makeStyles, Theme } from '@material-ui/core/styles';

export const SettingStyles = makeStyles(
    (theme: Theme) => ({
        mainDiv: {
            maxHeight: '100%',
            backgroundColor: theme.palette.background.paper,
            '@media screen and (max-width: 770px)': {
                paddingRight: '20px',
            },
        },
        leftGrid: {
            '@media screen and (min-width: 1024px)': {
                padding: '12px 32px 12px 32px',
            },
            '@media screen and (max-width: 770px)': {
                marginLeft: '20px',
            },
        },
        middleGrid: {
            marginTop: '20px',
            maxWidth: '2%',
        },
        rightGrid: {
            '@media screen and (min-width: 1024px)': {
                // marginTop: '20px',
                padding: '0px 16px 16px 16px',
                flexGrow: 0,
                maxWidth: '45%',
                flexBasis: '60%',
            },
            '@media screen and (max-width: 770px)': {
                marginLeft: '20px',
            },
        },
        firstField: {
            '@media screen and (min-width: 1024px)': {
                width: '85%',
            },
            '@media screen and (max-width: 770px)': {
                width: '100%',
                marginTop: '3%',
            },
        },
        orgLogo: {
            '@media screen and (min-width: 1024px)': {
                marginTop: '1%',
            },
            '@media screen and (max-width: 770px)': {
                marginTop: '3%',
            },
        },
        dateFormatGrid: {
            '@media screen and (min-width: 1024px)': {
                marginTop: '1%',
            },
            '@media screen and (max-width: 770px)': {
                marginTop: '3%',
            },
        },
        workingHoursGrid: {
            '@media screen and (min-width: 1024px)': {
                // marginTop: '4%',
            },
            '@media screen and (max-width: 770px)': {
                // marginTop: '8%',
            },
        },
        workWeekGrid: {
            '@media screen and (min-width: 1024px)': {
                marginTop: '1%',
                // marginBottom: '8%',
            },
            '@media screen and (max-width: 770px)': {
                marginTop: '3%',
            },
        },
        orgLogoBoxStyle: {
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media only screen and (min-width: 820px) and (max-width: 820px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media screen and (min-width: 1024px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
                boxSizing: 'border-box',
                marginRight: '5%',
                height: '60px',
                width: '85%',
            },
            '@media screen and (max-width: 770px)': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
                boxSizing: 'border-box',
                height: '50px',
            },
        },
        firstFieldThirdGrid: {
            '@media screen and (max-width: 770px)': {
                // marginTop: '8%',
            },
        },
        otherFields: {
            '@media screen and (min-width: 1024px)': {
                // marginTop: '1%',
            },
            '@media screen and (max-width: 770px)': {
                // marginTop: '8%',
            },
        },
        lastField: {
            '@media screen and (min-width: 1024px)': {
                marginTop: '1%',
            },
            '@media screen and (max-width: 770px)': {
                // marginTop: '10%',
            },
        },
        formLabelStyle: {
            color: theme.palette.primary.contrastText,
            fontWeight: 600,
            fontSize: '14px',
            '@media screen and (max-width: 770px)': {
                lineHeight: '22px',
            },
        },
        radioNameStyle: {
            letterSpacing: '0.03em',
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.text.primary,
            marginTop: '5px',
        },
        radioGroupStyles: {
            marginTop: '10px',
        },
        imagePreviewStyle: {
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                '& img': {
                    width: 100,
                    height: 30,
                },
            },
            '@media only screen and (min-width: 820px) and (max-width: 820px)': {
                '& img': {
                    width: 100,
                    height: 30,
                },
            },
            '@media screen and (min-width: 1024px)': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '13px',
                height: '60px',
                '& img': {
                    maxWidth: '100%',
                    maxHeight: '100%',
                    padding: '10px 0px 10px 0px',
                },
            },
            '@media screen and (max-width: 770px)': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '13px',
                height: '50px',
                '& img': {
                    maxWidth: '100%',
                    maxHeight: '100%',
                    padding: '10px 0px 10px 0px',
                },
            },
        },
        editIconStyle: {
            display: 'flex',
            height: '50px',
            justifyContent: 'center',
            alignSelf: 'center',
            outline: 'none',
        },
        inputButton: {
            // display: 'none',
        },
        inputStyle: {
            '& div': {
                fontSize: '14px',
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.paper,
                },
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
            '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #AAAAAA',
            },
        },
        inputSelectStyle: {
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.03em',
            // color: '#AAAAAA',
            color: theme.palette.text.primary,
            '& .MuiSelect-icon': {
                color: theme.palette.primary.contrastText,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.primary.contrastText}`,
            },
            '@media screen and (max-width: 770px)': {
                height: '40px',
                '& fieldset': {
                    height: '40px',
                    borderRadius: '2px',
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
                '& div': {
                    marginBottom: '3px',
                    letterSpacing: '0.05em',
                },
            },
            '@media screen and (min-width: 1024px)': {
                height: '40px',
                '& fieldset': {
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                },
            },
        },
        checkBoxLabelStyle: {
            color: theme.palette.text.primary,
            '& span': {
                letterSpacing: '0.05em',
                textTransform: 'capitalize',
                fontWeight: 600,
            },
        },
        buttonStyle: {
            whiteSpace: 'nowrap',
            // border: `1px solid ${theme.palette.text.secondary}`,
            '& span': {
                fontWeight: 'bold',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                letterSpacing: '0.05em',
                color: theme.palette.text.secondary,
            },
        },
        updateButtonStyle: {
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.primary.main}`,
            '& span': {
                fontWeight: 'bold',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                letterSpacing: '0.05em',
                color: theme.palette.common.white,
            },
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.main,
                textDecoration: 'none',
            },
        },
        passwordChangeModal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            padding: '20px',
            borderRadius: '0px',
            '@media screen and (min-width: 1024px)': {
                height: 500,
            },
            '@media screen and (max-width: 770px)': {
                width: '95%',
            },
        },
        passwordChangeTitle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '8%',
        },
        modalOTP: {
            '& div': {
                '& input': {
                    letterSpacing: '0.5em',
                    color: '#0F0F0F',
                    fontWeight: 600,
                    fontSize: '22px',
                },
            },
        },
        modalFieldLabel: {
            '@media screen and (min-width: 1024px)': {
                width: '75%',
                '& label': {
                    fontWeight: 600,
                    lineHeight: '19px',
                    color: '#444444',
                },
                '& div': {
                    '& input': {
                        '&:focus': {
                            fontWeight: 600,
                            lineHeight: '19px',
                            color: '#444444',
                        },
                    },
                },
            },
            '@media screen and (max-width: 770px)': {
                width: '85%',
            },
        },
        modalCancelButtonGrid: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '10px',
        },
        modalCancelButtonStyle: {
            '@media screen and (min-width: 1024px)': {
                width: '73%',
            },
            textTransform: 'capitalize',
            fontWeight: 600,
            lineHeight: '22px',
            border: '1px solid #3C48B5',
            borderRadius: '5px',
            backgroundColor: '#FFFFFF',
            color: '#3C48B5',
            '&:hover': {
                backgroundColor: '#FFFFFF',
            },
            '&:focus': {
                textDecoration: 'none',
                border: '1px solid #3C48B5',
                outline: 'none',
            },
            '@media screen and (max-width: 770px)': {
                width: '83%',
            },
        },
        modalUpdateButtonGrid: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        modalUpdateButtonStyle: {
            width: '75%',
            fontWeight: 'bold',
            lineHeight: '22px',
            textTransform: 'capitalize',
            backgroundColor: '#3C48B5',
            color: '#FFFFFF',
            borderRadius: '5px',
            '&:hover': {
                backgroundColor: '#3C48B5',
            },
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
            '@media screen and (max-width: 770px)': {
                width: '85%',
            },
        },
        modalResendOTPStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 500,
            fontStyle: 'italic',
            '@media screen and (min-width: 1024px)': {
                marginTop: '2%',
                marginBottom: '8%',
            },
            '@media screen and (max-width: 770px)': {
                marginTop: '8%',
                marginBottom: '5%',
            },
        },
        approverInfoModal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            backgroundColor: theme.palette.background.paper,
            transform: 'translate(-50%, -50%)',
            '@media screen and (min-width: 1024px)': {
                width: '40%',
                padding: '25px',
            },
            '@media screen and (max-width: 770px)': {
                width: '90%',
                paddingTop: '7%',
                paddingLeft: '3%',
                paddingBottom: '2%',
                paddingRight: '6%',
                height: '403px',
            },
        },
        approverInfoTitle: {
            '@media screen and (min-width: 1024px)': {
                color: theme.palette.primary.main,
                fontSize: '24px',
                lineHeight: '33px',
                fontWeight: 'bold',
            },
            '@media screen and (max-width: 770px)': {
                color: theme.palette.primary.main,
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '33px',
            },
        },
        approverInfoGrid: {
            '@media screen and (min-width: 1024px)': {
                paddingLeft: '15px',
                '& label': {
                    color: theme.palette.text.primary,
                    lineHeight: '20px',
                },
            },
            '@media screen and (max-width: 770px)': {
                paddingLeft: '15px',
                '& label': {
                    color: theme.palette.text.primary,
                    lineHeight: '20px',
                },
            },
        },
        showHidePassword: {
            '& input': {
                // '&::-ms-clear': {
                //     display: 'none'
                // },
                '&::-ms-reveal': {
                    display: 'none',
                },
            },
        },
        passswordButtonStyle: {
            '&:focus': {
                textDecoration: 'none',
                border: 'none',
                outline: 'none',
            },
        },
        buttonGroup: {
            display: 'flex',
            justifyContent: 'flex-end',
            // marginBottom: '20px',
            marginTop: '8%',
            '@media screen and (min-width: 1024px)': {
                // marginTop: '55%',
            },
            '@media screen and (max-width: 770px)': {
                marginTop: '35%',
                marginBottom: '5%',
            },
        },
        passwordChangeButtonGrid: {
            '@media screen and (min-width: 1024px)': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            },
            '@media screen and (max-width: 770px)': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        passwordChangeButton: {
            fontSize: '13px',
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width: 770px)': {
                width: '100%',
                '& span': {
                    fontSize: '16px',
                },
            },
        },
        resetButtonGrid: {
            display: 'flex',
            alignSelf: 'flex-end',
            '@media screen and (min-width: 1024px)': {
                paddingRight: '10px',
            },
            '@media screen and (max-width: 770px)': {
                marginTop: '8%',
            },
        },
        resetButton: {
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (min-width: 1024px)': {
                width: '140px',
            },
            '@media screen and (max-width: 770px)': {
                width: '100%',
                marginRight: '12px',
            },
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                width: '150px',
                height: '35px',
            },
            '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
                width: '80%',
                height: '38px',
            },
        },
        resetButton2: {
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.text.secondary}`,
            '& span': {
                fontWeight: 'bold',
                fontStyle: 'normal',
                textTransform: 'capitalize',
                letterSpacing: '0.05em',
                color: theme.palette.text.secondary,
            },
            '&:hover, &:focus': {
                // backgroundColor: theme.palette.primary.main,
                cursor: 'not-allowed',
                textDecoration: 'none',
            },
        },
        updateButton: {
            outline: 'none',
            '@media screen and (max-width: 770px)': {
                width: '156px',
            },
        },
        modal: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 999999,
        },
        modalOff: {
            display: 'none',
        },
        loader: {
            border: '16px solid #f3f3f3',
            borderRadius: '50%',
            borderTop: '16px solid #3498db',
            width: '120px',
            height: '120px',
            animation: 'spin 2s linear infinite',
        },
        activeinput: {
            '& div': {
                fontSize: '14px',
                '& input': {
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '0.03em',
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.paper,
                },
                '& fieldset': {
                    border: `2px solid #3C48B5`,
                },
            },
        },
        newDivStyle: {
            backgroundColor: theme.palette.background.paper,
            minHeight: 'calc(100vh - 180px)',
            // '@media screen and (min-width: 821px)': {
            //     minHeight: 'calc(100vh - 163px)',
            //     maxHeight: 'calc(100vh - 130px)',
            // },
            // '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
            //     padding: '0px 20px',
            // },
            // '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
            //     minHeight: 'calc(100vh + 60vh)',
            // },
            // '@media only screen and (min-width: 820px) and (max-width: 820px)': {
            //     minHeight: 'calc(100vh + 10vh)',
            //     padding: '0px 20px',
            // },
            '@media screen and (max-width: 770px)': {
                display: 'grid',
                gridTemplateColumns: '1fr',
                backgroundColor: theme.palette.background.paper,
                padding: '0% 5% 5% 5%',
            },
            '@media screen and (min-width: 1024px)': {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                backgroundColor: theme.palette.background.paper,
                padding: '2.5%',
                // height: 'calc(100vh - 130px)',
            },
        },
        borderDiv: {
            '@media screen and (min-width: 1024px)': {
                borderRight: '1px solid #DDDDDD',
            },
        },
        newInnerDiv: {
            position: 'relative',
            '@media screen and (min-width: 1024px)': {
                paddingLeft: '8%',
            },
            // '@media only screen and (min-width: 768px) and ( max-width: 768px)': {
            //     marginTop: '-340px',
            // },
        },
        newButtonGroup: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            '@media screen and (min-width: 1024px)': {
                position: 'absolute',
                bottom: 10,
                right: 0,
            },
            '@media only screen and (min-width: 912px) and ( max-width: 912px)': {
                marginTop: '20px',
            },
            '@media only screen and (min-width: 820px) and ( max-width: 820px)': {
                marginTop: '20px',
            },
        },
    }),
    { index: 1 },
);
