import { createSlice } from '@reduxjs/toolkit';
// import { fetchOrganizationSettingsData } from 'helpers/hasuraapi';

// export const getSettings: any = createAsyncThunk('settings/getSettings', async (user: any) => {
//     const data: any = await fetchOrganizationSettingsData(user.organizationId, user.token);
//     const jsonData: any = await data.json();
//     return jsonData.data.timeoffdb_GeneralSettings[0];
// });

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: { settings: {}, status: 'loading' },
    reducers: {
        editSettings: (state, action) => {
            state.settings = action.payload;
        },
        fetchSettings: (state: any, action: any) => {
            if (action.payload?.loading) {
                state.status = 'loading';
            }
            if (action.payload?.error) {
                state.status = 'failed';
            } else {
                state.status = 'success';
                state.settings = action.payload?.data?.timeoffdb_GeneralSettings[0];
            }
        },
    },
    // extraReducers: {
    //     [getSettings.pending]: state => {
    //         state.status = 'loading';
    //     },
    //     [getSettings.fulfilled]: (state, action) => {
    //         state.settings = action.payload;
    //         state.status = 'success';
    //     },
    //     [getSettings.rejected]: state => {
    //         state.status = 'failed';
    //     },
    // },
});

export const { editSettings, fetchSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
