/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import myStyle from './TeamStyle';
import { Paper, Hidden, InputAdornment, TextField, useTheme } from '@material-ui/core';
import Toolbar from './Toolbar';
import ErrorPage from '../Error/ErrorPage';
import { SearchBar } from '../DashboardPage/DashboardPart';
// import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import DetailsTable from './DetailsTable';
import AuthContext from '../../context/AuthContext';
import { updateActiveTab } from '../Redux/activeTab';
// import { useTheme } from '@emotion/react';
// import { theme } from '@chakra-ui/react';
import SubscriptionHardExpired from '../Settings/SubscriptionHardExpired';
import TeamDetails from './TeamDetails';
// import { fetchTeams } from '../Redux/teamsReducer';

const EmployeeTeams = (props: any) => {
    const classes = myStyle();
    const theme = useTheme();
    const dispatch = useDispatch();
    const mycontext = useContext(AuthContext);
    const state = useSelector((state: any) => state.teams);
    const teams = useSelector((state: any) => state.teams.teams);
    const [currentPage, setCurrentPage] = React.useState<string>('teams');
    const [teamID, setteamID] = React.useState<any>('');
    const [teamname, setteamname] = React.useState<any>('');
    const [teamDetails, setTeamDetails] = React.useState<any>(null);
    const [value, setValue] = React.useState('');
    const [selectedTeam, setSelectedTeam] = React.useState<any>(null);

    useEffect(() => {
        dispatch(updateActiveTab('/teams'));
        // if (!props.teams?.loading) {
        //     dispatch(fetchTeams(props.teams));
        // }
    }, []);

    useEffect(() => {
        setCurrentPage('teams');
    }, [mycontext.teamsPage]);

    if (state.status === 'failed') {
        return <ErrorPage title="Server Down" img="/error.png" msg="Something went wrong, please try again" />;
    }
    return mycontext.subscriptionExpired === 'cancelled' &&
        Math.floor(new Date().getTime() / 1000) > parseInt(mycontext.hardExpireDate) ? (
        mycontext.isAdmin ? (
            <SubscriptionHardExpired
                content="Looks like your subscription is expired, Please renew it !"
                showRenew={true}
            />
        ) : (
            <SubscriptionHardExpired
                content="Subscription expired, Please contact your admin to renew subscription"
                showRenew={false}
            />
        )
    ) : (
        <>
            <Hidden mdDown>
                <Paper elevation={0}>
                    <Toolbar current={currentPage} setCurrent={setCurrentPage} teamDetails={teamDetails} />
                </Paper>
            </Hidden>

            <Hidden lgUp>
                <Toolbar current={currentPage} setCurrent={setCurrentPage} teamDetails={teamDetails} />
            </Hidden>

            {currentPage === 'teams' && (
                <>
                    <Hidden mdDown>
                        <Paper elevation={0} className={classes.root}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                {/* <Typography className={classes.teamTitle} id="typo">
                                    Team List
                                </Typography> */}
                                <TextField
                                    id="searchname"
                                    placeholder="Search by name"
                                    variant="outlined"
                                    size="small"
                                    value={value}
                                    onChange={(event: any) => setValue(event.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" className={classes.searchIcon}>
                                                <span
                                                    style={{
                                                        marginRight: 10,
                                                        fontSize: 20,
                                                        fill: theme.palette.primary.contrastText,
                                                    }}
                                                    className="iconify"
                                                    data-icon="octicon:search-16"
                                                ></span>
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            root: classes.searchInput1,
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: theme.palette.primary.contrastText,
                                        },
                                    }}
                                    className={classes.searBorder}
                                />
                            </div>
                            <div style={{ marginTop: '16px' }}>
                                <DetailsTable
                                    users={teams}
                                    isLoading={state.status === 'loading'}
                                    error={state.status === 'failed'}
                                    errorMessage="Something went wrong!"
                                    searchvalue={value}
                                    setCurrent={setCurrentPage}
                                    setTeamDetails={setTeamDetails}
                                    teamID={teamID}
                                    setteamID={setteamID}
                                    teamname={teamname}
                                    setteamname={setteamname}
                                    selectedTeam={selectedTeam}
                                    setSelectedTeam={setSelectedTeam}
                                    teamDetails={teamDetails}
                                />
                            </div>
                        </Paper>
                    </Hidden>
                    <Hidden lgUp>
                        <div id="searchTeam" className={classes.searchposmob}>
                            <SearchBar
                                value={value}
                                setValue={setValue}
                                mode="rect"
                                icon={true}
                                style={{
                                    border: 'none',
                                    width: '100%',
                                    height: '40px',
                                    margin: '0px',
                                    paddingRight: '0px',
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <DetailsTable
                                users={teams}
                                isLoading={state.status === 'loading'}
                                error={state.status === 'failed'}
                                errorMessage="Something went wrong!"
                                searchvalue={value}
                                setCurrent={setCurrentPage}
                                setTeamDetails={setTeamDetails}
                                teamID={teamID}
                                setteamID={setteamID}
                                teamname={teamname}
                                setteamname={setteamname}
                                selectedTeam={selectedTeam}
                                setSelectedTeam={setSelectedTeam}
                                teamDetails={teamDetails}
                            />
                        </div>
                    </Hidden>
                </>
            )}

            {currentPage === 'details' && (
                <TeamDetails
                    current={currentPage}
                    setCurrent={setCurrentPage}
                    teamDetails={teamDetails}
                    setTeamDetails={setTeamDetails}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                />
            )}
        </>
    );
};

export default EmployeeTeams;
