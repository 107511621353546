import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const myStyle = makeStyles((theme: Theme) =>
    createStyles({
        btnStyle: {
            width: '120px',
            height: '22px',
            fontSize: 12,
            marginRight: 5,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                outline: 'none',
            },
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            // background: theme.palette.primary.main,
            border: `1px solid ${theme.palette.text.secondary}`,
        },
        btnStyle1: {
            height: 22,
            width: 100,
            fontSize: 12,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            borderRadius: 0,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.text.secondary}`,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.text.secondary}`,
                outline: 'none',
                textDecoration: `underline ${theme.palette.background.paper}`,
            },
        },
        filterBtn: {
            width: '120px',
            height: '28px',
        },
        graphDiv: {
            width: 600,
            overflowX: 'auto',
            height: 'calc(100vh - 390px)',
            // overflowY: 'auto',
            paddingBottom: 5,
            // border: '1px solid',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '@media screen and (max-width: 768px)': {
                width: 'auto',
            },
            '@media screen and (min-width: 769px) and (max-width: 1250px)': {
                width: '350px',
            },
            '@media screen and (min-width:1600px)': {
                minWidth: 950,
            },
        },
        graph: {
            minWidth: 480,
            width: 'auto',
            // border: '1px solid blue',
            height: 'calc(100vh - 420px)',
            background: theme.palette.background.paper,
            display: 'flex',
            justifyContent: 'flex-start',
            '@media screen and (max-width: 1250px)': {
                minWidth: 250,
                width: 'auto',
            },
            '@media screen and (max-width: 768px)': {
                paddingRight: 15,
            },
        },
        graphSet: {
            width: 'auto',
            height: '100%',
            padding: '0px 15px',
            display: 'flex',
            alignItems: 'flex-end',
            position: 'relative',
            '& div': {
                position: 'absolute',
                bottom: -25,
                marginTop: 5,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& p': {
                    fontSize: 12,
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.text.primary,
                },
            },
        },
        searchbarRounded: {
            // marginTop: -10,
            width: '90%',
            // background: 'red',
            height: 35,
            borderRadius: '35px',
            alignSelf: 'center',
            marginBottom: 5,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 10px',
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            outline: 'none',
            // zIndex: 99,
            backgroundColor: theme.palette.background.paper,
        },
        searchBarInput: {
            // flex: 1,
            outline: 'none',
            border: 'none',
            fontSize: 14,
            width: '100%',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
        },
        accordionTitle: {
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            '@media screen and (max-width: 768px)': {
                fontSize: 14,
            },
        },
        accordionHeaderStyle: {
            backgroundColor: theme.palette.background.paper,
            padding: '0 20px',
            borderRadius: 5,
        },
        accordianDetails: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            '@media screen and (max-width: 768px)': {
                height: 'auto',
            },
        },
        scrollDivAccordian: {
            height: 'calc(100vh - 372px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
        },
        accordianMain: {
            '@media screen and (max-width: 768px)': {
                marginBottom: 10,
                height: 'calc(100vh-85px)',
            },
            '@media screen and (min-width: 1250px)': {
                height: 'calc(100vh - 115px)',
            },
            height: 'calc(100vh - 110px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 0,
            },
        },
        ParentDiv: {
            display: 'grid',
            // height: "calc(100vh -620px)",
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: '10px',
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                width: '100%',
                flexDirection: 'column-reverse',
            },
        },
        leftDiv: {
            height: 'auto',
            display: 'grid',
            gridTemplateRows: 'auto auto',
            gridRowGap: '10px',
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
            },
        },
        graphLeft: {
            height: 'calc(100vh - 300px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: theme.palette.background.paper,
            borderRadius: 5,
            padding: 10,
            // margin: '10px 0',
            '@media screen and (max-width: 768px)': {
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
            },
        },
        HideDiv: {
            '@media screen and (min-width:768px)': {
                display: 'none',
            },
        },
        topdiv: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 5,
        },
        HideButton: {
            '@media screen and (max-width:768px)': {
                display: 'none',
            },
        },
        ChipDiv: {
            height: '45px',
            display: 'flex',
            width: '100%',
            // justifyContent: 'space-evenly',
            padding: '0 0 0 20px',
            borderRadius: 5,
            alignItems: 'center',
            backgroundColor: theme.palette.background.paper,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                width: 0,
                height: 0,
            },
        },
        Chip: {
            height: '25px',
            backgroundColor: '#4FB8B2',
            color: 'white',
            cursor: 'pointer',
            marginRight: 30,
        },
        WarningIcon: {
            height: '40px',
            color: '#E61D1D',
            cursor: 'pointer',
            marginRight: 20,
        },
        employeeStatistics: {
            height: '220px',
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.background.paper,
            padding: '5px 15px 10px 15px',
            borderRadius: 5,
        },
        MobileDiv: {
            width: '100%',
            // display: 'flex-end',
            textAlign: 'center',
            height: 40,
            position: 'fixed',
            right: 0,
            bottom: 0,
            fontSize: 14,
            borderRadius: 0,
            textTransform: 'capitalize',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '@media screen and (min-width:769px)': {
                display: 'none',
            },
        },
        firstDivText: {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            paddingLeft: 10,
            color: theme.palette.text.primary,
            // textAlign: 'center',
            '@media screen and (max-width:450px)': {
                fontSize: 12,
                paddingLeft: 3,
                // paddingTop: 2
            },
        },
        bulletDiv: {
            width: '50%',
            height: '25px',
            paddingLeft: 25,
            // border: '1px  solid',
            alignText: 'center',
            alignItem: 'center',
            display: 'flex',
            '@media screen and (max-width:500px)': {
                paddingLeft: 15,
            },
        },
        bullets: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            '@media screen and (max-width:450px)': {
                width: '8px',
                height: '8px',
            },
        },
        line: {
            paddingLeft: 20,
            paddingRight: 30,
        },
        TodayGoalText: {
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
            letterSpacing: '0.02em',
            paddingLeft: 10,
            color: theme.palette.text.primary,
        },
        Label: {
            transform: 'translateY(40px)',
            fontSize: '18px',
        },
        Label1: {
            transform: 'translateY(-5px)',
            fontSize: '12px',
        },
        SecondDivText: {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            // marginBottom: 10,
            paddingLeft: '15px',
            color: theme.palette.text.primary,
            '@media screen and (max-width: 900px)': {
                marginBottom: 10,
            },
        },
        ExpandIcon: {
            color: theme.palette.text.primary,
        },
        OnLine: {
            border: `0.5px solid ${theme.palette.primary.contrastText}`,
        },
        graphLine: {
            background: theme.palette.background.paper,
        },
        selectedMenus: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                border: 'none',
                outline: 'none',
                color: '#fff',
            },
        },
        filterButton: {
            textTransform: 'initial',
            height: '24px',
            width: '110px',
            borderRadius: 5,
            fontWeight: 600,
            fontSize: '11px',
            marginRight: 8,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width: 400px)': {
                width: 95,
            },
        },
        IconSty: {
            color: theme.palette.primary.contrastText,
        },
        popoverPropsOne: {
            width: 'auto',
        },
        popoverPropsTwo: {
            '@media screen and (max-width: 699px)': {
                width: '35%',
            },
            '@media screen and (min-width: 700px) and (max-width: 1024px)': {
                width: '20%',
            },
            '@media screen and (min-width: 1200px)': {
                width: '10%',
            },
        },
        filterscroll: {
            maxHeight: '100px',
            // height: '100%',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 4,
            },
            '&::-webkit-scrollbar-track': {
                borderRadius: 10,
                backgroundColor: theme.palette.grey,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                height: 5,
            },
        },
        filterButtonsDivMobileMain: {
            paddingBottom: 20,
            // paddingRight: 70,
            position: 'absolute',
            right: 0,
            border: '3px solid',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.default,
            '@media screen and (min-width: 769px)': {
                display: 'none',
            },
        },
        img: {
            height: 13,
            width: 13,
            color: theme.palette.text.primary,
        },
        notfoundText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.disabled,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
        },
        notfoundImage2: {
            height: 'calc(100vh - 400px)',
            maxWidth: '100%',
        },
        notfoundAccordion: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            height: '100%',
        },
        notfoundgraph: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 330px)',
            width: '100%',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        graphXAxis: {
            transform: 'rotate(270deg)',
            position: 'absolute',
            marginTop: '7%',
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '-65px',
            color: theme.palette.text.primary,
            '@media only screen and (min-width: 1310px)': {
                marginTop: '10%',
            },
            '@media only screen and (min-height: 721px) and (max-height: 1067px) and (min-width: 300px) and (max-width: 768px)': {
                marginTop: '32%',
            },
            '@media only screen and (min-width: 800px) and (max-width: 920px)': {
                marginTop: '40%',
            },
            '@media only screen and (max-height: 720px) and (min-width: 300px) and (max-width: 768px)': {
                marginTop: '17%',
            },
        },
    }),
);

export default myStyle;
