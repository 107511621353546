import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchEmployeeTodaysLeaves,
    fetchEmployeeUpcomingLeaves,
    getEmployee2,
    getEmployeeTeams,
    getTeams,
    employeeLeaveHistory,
} from 'helpers/hasuraapi';

export const getMembersProfile: any = createAsyncThunk('userProfile/getUserProfile', async (user: any) => {
    const data: any = await getEmployee2(user.employeeId, user.token);
    const jsonData = await data.json();
    return jsonData.data.usermanagementdb_Employee[0];
});

export const getMembersTodaysLeaves: any = createAsyncThunk('userProfile/getUsersTodaysLeaves', async (user: any) => {
    const data: any = await fetchEmployeeTodaysLeaves(user.organizationId, user.token, user.employeeId, user.date);
    const jsonData = await data.json();
    return jsonData.data.timeoffdb_Leaves;
});

export const getMembersUpcomingLeaves: any = createAsyncThunk(
    'userProfile/getUsersUpcomingLeaves',
    async (user: any) => {
        const data: any = await fetchEmployeeUpcomingLeaves(
            user.organizationId,
            user.token,
            user.employeeId,
            user.date,
        );
        const jsonData = await data.json();
        return jsonData.data.timeoffdb_Leaves;
    },
);

export const getMembersTeams: any = createAsyncThunk('userProfile/getEmpTeams', async (user: any) => {
    const data: any = await getEmployeeTeams(user.employeeId, user.organizationId, user.token);
    const jsonData = await data.json();
    return jsonData.data;
});

export const getNewMembersTeams: any = createAsyncThunk('userProfile/getNewEmpTeams', async (user: any) => {
    const data: any = await getTeams(user.employeeId, user.organizationId, user.token);
    const jsonData = await data.json();
    return jsonData.data;
});

export const getMembersLeaveHistory: any = createAsyncThunk('userProfile/getEmpLeaveHistory', async (user: any) => {
    const data: any = await employeeLeaveHistory(user.employeeId, user.organizationId, user.date, user.token);
    const jsonData = await data.json();
    return jsonData.data.timeoffdb_Leaves;
});

export const memberProfileSlice = createSlice({
    name: 'memberProfile',
    initialState: {
        empTeams: [],
        newEmpTeams: [],
        empDetail: {},
        todaysLeaves: [],
        upcomingLeaves: [],
        empLeaveHistory: [],
        userData: [],
        leaveHistoryStatus: 'loading',
        userProfileStatus: 'loading',
        empTeamStatus: 'loading',
        newEmpTeamStatus: 'loading',
        userDataStatus: 'loading',
        status: 'loading',
    },
    reducers: {},
    extraReducers: {
        [getMembersProfile.pending]: state => {
            state.userProfileStatus = 'loading';
        },
        [getMembersProfile.fulfilled]: (state, action) => {
            state.empDetail = action.payload;
            state.userProfileStatus = 'success';
        },
        [getMembersProfile.rejected]: state => {
            state.userProfileStatus = 'failed';
        },
        [getMembersTeams.pending]: state => {
            state.empTeamStatus = 'loading';
        },
        [getMembersTeams.fulfilled]: (state, action) => {
            state.empTeams = action.payload;
            state.empTeamStatus = 'success';
        },
        [getMembersTeams.rejected]: state => {
            state.empTeamStatus = 'failed';
        },
        [getNewMembersTeams.pending]: state => {
            state.newEmpTeamStatus = 'loading';
        },
        [getNewMembersTeams.fulfilled]: (state, action) => {
            state.newEmpTeams = action.payload;
            state.newEmpTeamStatus = 'success';
        },
        [getNewMembersTeams.rejected]: state => {
            state.newEmpTeamStatus = 'failed';
        },
        [getMembersUpcomingLeaves.pending]: state => {
            state.status = 'loading';
        },
        [getMembersUpcomingLeaves.fulfilled]: (state, action) => {
            state.upcomingLeaves = action.payload;
            state.status = 'success';
        },
        [getMembersUpcomingLeaves.rejected]: state => {
            state.status = 'failed';
        },
        [getMembersTodaysLeaves.pending]: state => {
            state.status = 'loading';
        },
        [getMembersTodaysLeaves.fulfilled]: (state, action) => {
            state.todaysLeaves = action.payload;
            state.status = 'success';
        },
        [getMembersTodaysLeaves.rejected]: state => {
            state.status = 'failed';
        },
        [getMembersLeaveHistory.pending]: state => {
            state.leaveHistoryStatus = 'loading';
        },
        [getMembersLeaveHistory.fulfilled]: (state, action) => {
            state.empLeaveHistory = action.payload;
            state.leaveHistoryStatus = 'success';
        },
        [getMembersLeaveHistory.rejected]: state => {
            state.leaveHistoryStatus = 'failed';
        },
    },
});

export default memberProfileSlice.reducer;
