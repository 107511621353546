import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLeaveTypesByMonthNew, daysPerYearForLeaveTypes, leaveTypeData2 } from 'helpers/hasuraapi';

export const getLeaveTypes: any = createAsyncThunk('leaveTypes/leaveTypes', async (user: any) => {
    const data: any = await fetchLeaveTypesByMonthNew(user.organizationId, user.token, user.startDate, user.endDate);
    const jsonData: any = await data.json();
    return jsonData.data;
});

export const getDaysPerYear: any = createAsyncThunk('leaveTypes/getDaysPerYear', async (user: any) => {
    const data: any = await daysPerYearForLeaveTypes(user.organizationId, user.token);
    const jsonData: any = await data.json();
    return jsonData.data.timeoffdb_LeaveType;
});

export const getLeaveTypeData: any = createAsyncThunk('leaveTypes/getLeaveTypeData', async (user: any) => {
    const data: any = await leaveTypeData2(user.employeeId, user.organizationId, user.token);
    const jsonData: any = await data.json();
    return jsonData.data.timeoffdb_get_totalhours;
});

export const leaveTypesSlice = createSlice({
    name: 'leaveTypes',
    initialState: { lTypes: [], leaveTypes: [], leaveTypeData: [], leaveTypeDataStatus: 'loading', status: 'loading' },
    reducers: {},
    extraReducers: {
        [getLeaveTypes.pending]: state => {
            state.status = 'loading';
        },
        [getLeaveTypes.fulfilled]: (state, action) => {
            const po: any = [];
            let ar1 =
                action.payload?.timeoffdb_get_MonthlyLeaves?.concat(action.payload?.timeoffdb_get_leavetype_count4) ||
                [];
            action.payload?.timeoffdb_get_MonthlyLeaves?.map((p: any) => {
                action.payload?.timeoffdb_get_leavetype_count4?.map((c: any) => {
                    if (c.name === p.name) {
                        po.push({
                            name: c.name,
                            value: c.value + p.value,
                        });
                        ar1 = ar1.filter((o: any) => o.name !== c.name);
                    }
                });
            });
            state.leaveTypes = po.concat(ar1);
            state.status = 'success';
        },
        [getLeaveTypes.rejected]: state => {
            state.status = 'failed';
        },
        [getDaysPerYear.pending]: state => {
            state.status = 'loading';
        },
        [getDaysPerYear.fulfilled]: (state, action) => {
            state.lTypes = action.payload;
            state.status = 'success';
        },
        [getDaysPerYear.rejected]: state => {
            state.status = 'failed';
        },
        [getLeaveTypeData.pending]: state => {
            state.leaveTypeDataStatus = 'loading';
        },
        [getLeaveTypeData.fulfilled]: (state, action) => {
            state.leaveTypeData = action.payload;
            state.leaveTypeDataStatus = 'success';
        },
        [getLeaveTypeData.rejected]: state => {
            state.leaveTypeDataStatus = 'failed';
        },
    },
});

export default leaveTypesSlice.reducer;
